import styled from "styled-components";

export const ProjectDetailsForm = styled.div`
display: flex;
width: 624px;
gap: 16px 24px;
flex-wrap: wrap;
`;

export const Label = styled.label`
color: #A9A9A9;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
display: block;
`;

export const RequiredMark = styled.span`
color: #FF8B8B;
`;

export const Input = styled.input`
margin-top: 8px;
display: block;
width: ${(props) => props.wantBig ? "624px" : "300px"};
height: ${(props) => props.wantBig ? "60px" : "34px"};
flex-shrink: 0;
border-radius: 4px;
border: ${(props) => props.hasError ? "1px solid #F04438" : props.isFocussed ? "1px solid #FFB258" : "1px solid #D0D5DD"};
box-shadow: ${(props) => props.isFocussed ? "0px 1px 2px 0px rgba(28, 28, 28, 0.05), 0px 0px 4px 0px rgba(255, 178, 88, 0.25)" : "unset"};
caret-color: #FFB258;
background: #F6F7FB;
outline: none;
color: rgba(28, 28, 28, 0.90);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;

pointer-events: ${(props) => props.inActive ? "none" : "unset"};

::focus{
    outline: none;
}
::placeholder{
    color: rgba(28, 28, 28, 0.50);
}
padding: 8px;
`;

