import styled from "styled-components";

export const Wrapper = styled.div``;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  pointer-events: none;
  opacity: 0.4;

  span {
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
    color: #1c1c1c;
  }
  button {
    border-radius: 6px;
    background: #ff711e;
    outline: none;
    border: none;
    color: #fff;
    width: 144px;
    height: 44px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const ContentContainer = styled.div`
  margin-top: 10px;
`;

export const GeneratingUi = styled.div`
  height: 75px;
  display: flex;
  border-radius: var(--8, 8px);
  background: rgba(255, 255, 255, 0.5);
  gap: 16px;
  margin-bottom: 5px;
  align-items: center;
  img {
    height: 70px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    color: #1c1c1c;
    line-height: 20px;
  }
  p.tryAgain {
    padding-left: 20px;
    span{
      font-style: italic;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const ResultsContainer = styled.div``;

export const CardsRow = styled.div`
  width: fit-content;
`;
export const ShowMore = styled.div`
  display: flex;
  justify-content: center;
  text-decoration: underline;
  color: rgb(28, 28, 28);
  padding: 15px 0px;

  p {
    padding: 10px 5px;
    cursor: pointer;
  }
`;

export const AdCard = styled.div`
  width: 300px;
  height: 300px;

  .outerHtml {
    zoom: 0.5;
  }
  div.a59e2,
  div.fd9,
  div.c83 {
    /* scale: 1; */
    /* width: 100%;
    height: 100%; */
  }
`;

export const CardRowHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 10px;
  p {
    font-size: 16px;
    font-weight: 400;
    color: #1c1c1c;
  }
`;

export const TypeOfAd = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const CardRowContent = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  .outerHtml {
    zoom: 0.5;
  }
`;

export const TemplateDetailsContainer = styled.div`
  position: absolute;
  background: #0000005e;
  height: calc(100vh - 65px);
  top: 65px;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  z-index: 8;
`;

export const TemplateDetailsWrapper = styled.div`
  max-width: 400px;
  background: #fff;
  height: 100%;
  padding: 20px;
`;

export const TemplateDetailsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  h2 {
    font-size: 16px;
    font-weight: 400;
    color: #1c1c1c;
  }
  img {
    height: 24px;
    cursor: pointer;
  }
`;

export const TemplateDetailsContent = styled.div`
  /* img {
    height: 128px;
    margin-bottom: 50px;
  } */
  p.detailText {
    font-size: 16px;
    font-weight: 400;
    color: #1c1c1c;
    line-height: 150%;
  }
`;

export const EditTemplateButton = styled.button`
  border-radius: 7px;
  background: #ff711e;
  width: 100%;
  height: 50px;
  color: #fff;
  border: none;
  outline: none;
  margin-top: 30px;
`;
