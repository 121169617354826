import React from "react";

import { AdIntegrationProvider } from "./context/adintegration";
import { Route, Routes } from 'react-router-dom'
import NotFound from '../NotFoundPage';
import Layout from "../../layout"
import AdIntegrationHome from "./Home";
import AdPush from "./AdPush";

const AdIntegrationComponent = () => {
    return <Routes>
            <Route path='*' element={
                <NotFound />
            } />
            <Route path="/" element={
                <Layout>
                    <AdIntegrationHome/>
                </Layout>
            } />
            <Route path="pushads" element={
                <Layout>
                    <AdPush />
                </Layout>
            } />
        </Routes>
};

const AdIntegrationProviderWrapper = () => {
    return <AdIntegrationProvider>
        <AdIntegrationComponent/>
    </AdIntegrationProvider>
}

export default AdIntegrationProviderWrapper