import React, { useState } from "react";
import {
  Container,
  SelectContainer,
  Heading,
  PlatformsContainer,
  PlatformAvailable,
  Footer,
  ContinueButton,
} from "./index.styled";
import { useMutation } from "@tanstack/react-query";

import { appClient } from "../../../../../../../utils/axios-utils";
import { useAuth } from "../../../../../../../context/auth";
import { useNavigate } from "react-router-dom";
import { useGetUserInfo } from "../../../../../../hooks/useGetUserInfo";

// const currentUser = () => {
//   const config = {
//     url: "/auth/current-user",
//     method: "GET",
//   };

//   return appClient(config);
// };

const selectBasicPlatform = async (data) => {
  const config = {
    url: "/payment/basic-plan-platform",
    method: "POST",
    data,
  };

  return appClient(config);
};

const SelectPlatform = () => {
  const { authState, authDispatch } = useAuth();
  const navigate = useNavigate();
  const useGetUserInfoHook = useGetUserInfo()

  const [currentSelected, setCurrentSelected] = useState("");

  // const handleSuccess = (data) => {
  //   let renamedRoutes = {
  //     facebook: "meta",
  //     "bing-ecom": "bingpla",
  //     "google-ecom": "googlepla",
  //   };

  //   if (data.features) {
  //     data = produce(data, (draft) => {
  //       let alteredArray = data?.features?.allowedPlatforms.map((platform) => {
  //         if (platform in renamedRoutes) {
  //           return renamedRoutes[platform];
  //         }

  //         return platform;
  //       });

  //       draft.features.allowedPlatforms = alteredArray;
  //     });
  //   }

  //   authDispatch({ type: "CURRENT_USER_UPDATE", data, isAuthenticated: true });
  //   authDispatch({ type: "STOP_LOADER" });
  //   navigate("/adspy");
  // };

  // const handleAcquireError = (err) => {
  //   // console.log(err);
  //   authDispatch({ type: "LOGOUT_SUCCESS" });
  //   authDispatch({ type: "STOP_LOADER" });
  // };

  // let currentUserQuery = useQuery(["current-user"], currentUser, {
  //   retry: false,
  //   // refetchInterval: 20000,
  //   refetchOnWindowFocus: false,
  //   refetchOnMount: false,
  //   enabled: false,
  //   onSuccess: handleSuccess,
  //   onError: handleAcquireError,
  // });

  const { mutate: selectBasicPlatformMutate, isLoading: isPaymentLoading } =
    useMutation(selectBasicPlatform, {
      onSuccess: (data) => {
        // authDispatch({ type: "SET_PLAN_DETAILS", value: data.plan });
        // currentUserQuery.refetch();
        useGetUserInfoHook.getCurrentUser({redirect: true})
      },
      onError: (err) => {
        // console.log(err);
      },
    });

  const onProceed = () => {
    selectBasicPlatformMutate({
        platform: currentSelected
    });
  };

  return (
    <Container>
      <SelectContainer>
        {/* <CloseButton onClick={() => setShow(false)}>x</CloseButton> */}
        <Heading>
          Please choose a platform with the{" "}
          <span>Basic</span> plan
        </Heading>
        <PlatformsContainer>
          <PlatformAvailable
            isSelected={currentSelected == "google"}
            onClick={() => setCurrentSelected("google")}
          >
            Google Search ads
          </PlatformAvailable>
          <PlatformAvailable
            isSelected={currentSelected == "facebook"}
            onClick={() => setCurrentSelected("facebook")}
          >
            Meta ads
          </PlatformAvailable>
        </PlatformsContainer>
        <Footer>
          {/* <CancelButton onClick={() => setShow(false)}>Cancel</CancelButton> */}
          <ContinueButton
            disabled={currentSelected.length == 0}
            onClick={() => {
              onProceed();
            }}
          >
            Choose platform and proceed
          </ContinueButton>
        </Footer>
      </SelectContainer>
    </Container>
  );
};

export default SelectPlatform;
