import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    h1{
        font-size: 18px;
        font-weight: 500;
        color: #2E2E2E;
        padding: 20px 4px;
        margin: 0;
        span{
            color: #808080;
            font-weight: 300;
            text-decoration: underline;
            margin-left: 8px;
        }
    }
`;

export const Wrapper = styled.div`
    display: flex;
    gap: 40px;
`;

export const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    max-width: 966px;
    width: 100%;
    h2{
        color: rgba(28, 28, 28, 0.50);
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        width: 70%;
    }
`;

export const ToolTip = styled.div`
    background: #555555;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 300;
    position: absolute;
    bottom: -34px;
    padding: 4px 8px;
    width: 125px;
    // display: none;
`;

export const InputContainer = styled.div`
    position: relative;
    height: 40px;
`;

export const Input = styled.input`
    width: 600px;
    height: 40px;
    background-color: white;
    border: 1px solid #ECECEC;
    border-radius: 8px;
    box-sizing: border-box;

    padding: 0 12px;
    outline: none;
    color: #2E2E2E;
    color: #2E2E2E;
    font-size: 14px;
    font-weight: 300;
    padding-right: 30px;
    border: ${props => props.error ? "1px solid #FF5180" : "1px solid #ECECEC"};
    outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};
    &:focus{
        border: ${props => props.error ? "1px solid #FF5180" : "1px solid #5AB3FF"};
        outline: ${props => props.error ? "2px solid #FFB7CB" : "2px solid #B9DDFF"};
    }
`;

export const ToolTipImageContainer = styled.div`
    position: absolute;
    right: 8px;
    top: 12px;
    display: ${props => props.error ? "flex": "none"};
    img{
        filter: contrast(1);
    }

    &:hover{
        > div{
            display: flex;
        }

        img{
            display: block;
        }
    }
`;

export const Button = styled.button`
    width: 75px;
    height: 40px;
    margin-left: 8px;

    border-radius: 8px;
    background-color: #FF711E;
    color: white;
    font-size: 14px;
    border: none;
    cursor: pointer;
    :hover {
        filter: brightness(90%);
    }
`;

// export const ResultsContainer = styled.div`
//     display: flex;
//     gap: 12px;
//     height: calc(100vh - 245px);
//     background: #006ecc00;
//     position: absolute;
//     left: 0;
//     top: 0;
//     .loader{
//         height: 200px;
//         margin: auto;
//     }
// `;

export const RecentScreenshotContainer = styled.div`
    margin-top: 20px;
    .storetray {
        background: #e8e3e3;
        height: 5px;
        margin: 5px auto; 
        width: 220px;
        border-radius:100px;
    }

    .storeindicator {
        background: #fff;
        background: #006ECC;
        height: 5px;
        position: relative;
        width: 0;
        border-radius:100px;
    }
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ViewCalander = styled.button`
    border-radius: 8px;
    background: #0A66C2;
    color: #FFF;
    font-size: 14px;
    font-weight: 300;
    height: 32px;
    width: 126px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    margin-left: 16px;
`;

export const HeaderRight = styled.div`
    display: flex;
    .arrow{
        cursor: pointer;
        border-radius: 16px;
        background: #ECECEC;
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #2E2E2E;
        font-size: 18px;
        font-weight: 400;
    }
    .backArrow{
        margin-right: 8px;
    }
    .nextArrow{

    }
`;

export const HeaderLeft = styled.div`
    display: flex;
    align-items: center;
    h1{
        padding: 0px;
    }
`;

export const ScreenshotsList = styled.div`
    display: flex;
    width: 100%;
    overflow-x: scroll;
    margin-top: 24px;
    gap: 20px;

    ::-webkit-scrollbar {
        display: none;
    }

    .storetray {
        background: #e8e3e3;
        height: 5px;
        margin: 5px auto; 
        width: 110px;
        border-radius:100px;
    }

    .storeindicator {
        background: #fff;
        background: gray;
        height: 5px;
        position: relative;
        width: 0;
        border-radius:100px;
    }
`;

export const ScreenshotCard = styled.div`
    height: 465px;
`;

export const ScreenshotDate = styled.div`
    color: #2E2E2E;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
`;

export const ScreenshotImageContainer = styled.div`
flex-direction: column;
border-radius: 15.063px;
border: 0.941px solid var(--gray-10, rgba(0, 0, 0, 0.10));
box-shadow: 0px 2.605px 2.084px 0px rgba(0, 0, 0, 0.02), 0px 6.26px 5.008px 0px rgba(0, 0, 0, 0.03), 0px 11.788px 9.43px 0px rgba(0, 0, 0, 0.04), 0px 21.028px 16.822px 0px rgba(0, 0, 0, 0.04), 0px 39.33px 31.464px 0px rgba(0, 0, 0, 0.05), 0px 94.141px 75.313px 0px rgba(0, 0, 0, 0.07);
    background: #FFF;
    padding: 0 24px 20px 24px;
    margin: auto;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    max-width: 966px;
    width: 90%;
    height: auto;
    margin-top: 24px;
    img{
        width: 100%;
        object-fit: contain;    
    }
`;
export const TopBarWrap = styled.div`
display: flex;
border-radius: 7.531px;
background: var(--Light-Gray, #F1F1F1);
width: 451.875px;
height: 28.242px;
margin: 12px 0 24px 0;
align-items: center;
img{
    height: 15px;
    width: 15px;
    cursor: pointer;
}
`
export const AddressWrap = styled.div`
display: flex;
width: 94%;
justify-content: center;
column-gap: 3px;
align-items: end;

p{
    color: var(--Black, #151515);
text-align: center;
font-family: 'Inter';
font-size: 12.238px;
font-weight: 400;
}
`
export const CalenderViewContainer = styled.div`
    display: flex;
    gap: 20px;
`;

export const BackButton = styled.div`
    border-radius: 8px;
    background: #ECECEC;
    color: #AAA;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    padding: 9px 12px;
    margin-left: 10px;
    cursor: pointer;
`;


export const PopupContainer = styled.div`
    position: absolute;
    background: #00000045;
    left: 0;
    /* top: 54px; */
    width: 100%;
    height: calc(100vh - 54px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
`;

export const PopupBody = styled.div`
    max-width: 80vw;
    max-height: 85vh;
    width: 80%;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 6px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    padding: 50px;
    position: relative;
    img{
        width: 100%;
        margin-bottom: 24px;
    }
    h2{
        margin-bottom: 16px;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        color: #2E2E2E;       
    }
    p{
        margin-bottom: 30px;
        font-size: 14px;
        font-weight: 300;
        color: #4E4E4E;
        text-align: center;
        line-height: 21px;
    }
    button{
        margin-bottom: 15px;
        width: 25px;
        border: none;
        position: absolute;
        top: 16px;
        background: none;
        right: 20px;
        font-size: 20px;
    }
`;

export const ModalScreenshotContainer = styled.div`
    width: 100%;
    overflow-y: scroll;

    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const DateContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 7px;
    width: 100%;
    justify-content: end;
`;

export const LeftArrow = styled.div`
    cursor: pointer;
    color: #1C1C1C4D;
`;

export const RightArrow = styled.div`
    cursor: pointer;
    color: #1C1C1C4D;
`;

export const DateValue = styled.div`
    display: flex;
    gap: 5px;
    justify-content: space-between;
    width: 113px;
    input{
        border: none;
        font-size: 16px;
        background: none;
        outline: none;
        color: rgba(28, 28, 28, 0.70);
        font-weight: 500;
        line-height: 24px;
        width: 100px;
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    width: 100%;
    background: #E3E8EE;
    height: 50px;
    /* margin-bottom: 20px; */
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
`;

export const Description = styled.p`
    color: rgba(28, 28, 28, 0.80);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 16px 0px;
    max-width: 966px;
    width: 90%;
    padding: 16px 0px;
    margin: auto;
    span{
        color: rgba(115, 74, 0, 0.80);
    }
`;

export const AdsCount = styled.div`
    border-radius: 12px;
    border: 1px solid rgba(143, 109, 46, 0.69);
    background: #FFF7E8;
    padding: 20px 24px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 966px;
    width: 90%;
    p{
        font-size: 16px;
        color: rgba(115, 74, 0, 0.80);
        font-weight: 500;
        line-height: 20px;
    }
    button{
        border-radius: 6px;
        background: #FF711E;
        outline: none;
        border: none;
        color: #FFF;
        padding: 8px 24px;
        cursor: pointer;
    }
`;

// export const LoadingScreenWrap = styled.div`
    
//     display: flex;
//     justify-content: center;
//     margin: 0 20px;

// `;
// export const LoadingScreen = styled.div`
// margin-top: 24px;
// width: 966px;
// height: 314px;
// border-radius: 16px;
// background: #FFF;
// display: flex;
// align-items: center;
// flex-direction: column;
// h2{
//     text-align: center;
//     margin-top: 36px;
//     color: rgba(28, 28, 28, 0.60);
// font-family: 'Inter';
// font-size: 18px;
// font-weight: 600;
// }
// p{
//     color: rgba(28, 28, 28, 0.50);
// text-align: center;
// font-family: 'Inter';
// font-size: 14px;
// font-weight: 400;
//     margin-top: 47px;
//     max-width: 611px;
//     line-height: 22px; 
// }
// `;

// export const Progress = styled.progress`
//     width: 495px;
//     height: 30px;
//     margin-top: 44px;
//     appearance: none;
//     border: none;
//     border-radius: 30px;

//     &::-webkit-progress-bar {
//         background-color: #ddd;
//         border-radius: 30px;

//         // height: 8px;
//     }

//     &::-webkit-progress-value {
//         background-color: #EE8C22;
//         border-radius: 30px;

//         // height: 8px;
//     }

//     &::-moz-progress-bar {
//         background-color: #3498db;
//         border-radius: 30px;

//         // height: 8px;
//     }

//     &::-ms-fill {
//         background-color: #3498db;
//         border-radius: 30px;

//         // height: 8px;
//     }
// `;

// export const BackToDashboard = styled.div`
// color: rgba(28, 28, 28, 0.40);
// font-family: 'Inter';
// font-size: 14px;
// font-weight: 400;
// display: flex;
// align-items: center;
// column-gap: 8px;
// margin-top: 28px;
// cursor: pointer;
// `;