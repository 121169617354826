import React from 'react'
import { useReducer, createContext, useContext } from 'react'

import produce from 'immer'

const initialState = {
    searchUrl: '',
    searchMode: 'path',
    domain: '',

    error: false,
    isValidUrl: false,

    showDomainsList: false,

    domainUrls: [],
    selectedUrl: '',
    selectedSimilarUrl: '',
 
    adsDistribution: [],
    activeCampaigns: {
        totalCampaigns: 0,
        totalCampaignAds: 0
    },
    mapdata:[],
    mapTableData: [],
    keywordStats: [],
    domainKeywords: [],

    adCopyPlatform: '',
    ads: [],
    adDetails: {

    },

    queryId: '',
    detailsModal: false,

    heatmapData:[],
    funnelOpen: false,
    funnelAds: [],
    funnelCtaData: [],
    funnelKeywordsData: [],
    funnelTargetAudienceData: {
        demographicAudience: [],
        psychographicAudience: []
    },
    funnelAdsAnalysisLimit: 200,
    funnelAdCopyLimitPopup: false,
    initialFunnelLoading: false,
    loadingFunnelAds: false,
    loadingFunnelStats: false,
    unFunnelSupportedPlatform: false,
    selectedFunnel: 'Awareness',
    selectedFunnelSubFilter: 'Ad Copies',
    funnelAdsCount: {

    }
}

const UrlAnalysisReducer = produce((draft, action) => {
    switch(action.type) {
        case 'SET_SEARCH_URL':
            draft.searchUrl = action.value
            break
        case 'SET_SEARCH_MODE':
            draft.searchMode = action.value
            break
        case 'SET_ERROR':
            draft.error = action.value
            break
        case 'SET_IS_VALID_URL':
            draft.isValidUrl = action.value
            break

        case 'SET_SHOW_DOMAINS_LIST':
            draft.showDomainsList = action.value
            break
        case 'SET_DOMAIN_NAME':
            draft.domain = action.value
            break

        case 'SET_RESET_URL_ANALYSIS':
            draft.searchUrl = ''
            draft.domain = ''

            draft.error = false
            draft.isValidUrl = false

            draft.showDomainsList = false

            draft.domainUrls = []
            draft.selectedUrl = ''
            draft.selectedSimilarUrl = ''

            draft.urlDomains = {

            }
            break

        case 'SET_STATS':
            draft.adsDistribution = action.value.adsDistribution ? action.value.adsDistribution : []
            draft.activeCampaigns = action.value.activeCampaigns ? action.value.activeCampaigns : initialState.activeCampaigns
            break

        case 'SET_GEO_STATS':
            draft.mapdata = action.value.mapDistribution ? action.value.mapDistribution : []
            draft.mapTableData = action.value.countriesPercentage ? action.value.countriesPercentage : []
            break

        case 'SET_KEYWORD_STATS':
            draft.keywordStats = action.value
            break

        case 'SET_DOMAIN_KEYWORDS':
            draft.domainKeywords = action.value
            break
        
        case 'SET_AD_COPIES':
            draft.ads = action.value
            break

        case 'SET_AD_COPY_PLATFORM':
            draft.adCopyPlatform = action.value
            break

        case 'UPDATE_DETAILS_MODAL':
            draft.detailsModal = action.value
            break

        case 'SET_AD_DETAILS':
            draft.adDetails = action.value
            break

        case 'SET_EMPTY_RESULT_ID':
            draft.queryId = action.value
            break
        
        case 'SET_HEATMAP_DATA':
            draft.heatmapData = action.value
            break
            
        case 'SET_FUNNEL_OPEN':
            draft.funnelOpen = action.value
            break

        case 'SET_FUNNEL_ADS':
            draft.funnelAds = action.value
            break

        case 'SET_FUNNEL_CTA_DATA':
            draft.funnelCtaData = action.value
            break

        case 'SET_FUNNEL_KEYWORDS_DATA':
            draft.funnelKeywordsData = action.value
            break

        case 'SET_FUNNEL_TARGET_AUDIENCE_DATA':
            draft.funnelTargetAudienceData = action.value
            break
        
        case 'SET_FUNNEL_ADS_ANALYSIS_LIMIT':
            draft.funnelAdsAnalysisLimit = action.value
            break

        case 'SET_FUNNEL_ADCOPY_LIMIT_POPUP':
            draft.funnelAdCopyLimitPopup = action.value
            break

        case 'SET_INITIAL_FUNNEL_LOADING':
            draft.initialFunnelLoading = action.value
            break

        case 'SET_UNSUPPORTED_FUNNEL_PLATFORM':
            draft.unFunnelSupportedPlatform = action.value
            break
            
        case 'SET_FUNNEL_ADS_LOADING':
            draft.loadingFunnelAds = action.value
            break

        case 'SET_FUNNEL_STATS_LOADING':
            draft.loadingFunnelStats = action.value
            break

        case 'SET_SELECTED_FUNNEL':
            draft.selectedFunnel = action.value
            break

        case 'SET_SELECTED_FUNNEL_SUB_FILTER':
            draft.selectedFunnelSubFilter = action.value
            break

        case 'SET_FUNNEL_ADS_COUNT':
            draft.funnelAdsCount = action.value
            break
            
        default:
            break
    }
})

const useUrlAnalysisReducer = () => {
    return useReducer(UrlAnalysisReducer, initialState)
}

const UrlAnalysisContext = createContext()

export const UrlAnalysisProvider = ({ children }) => {
    const [state, dispatch] = useUrlAnalysisReducer()
    
    const value = {
        urlAnalysisState: state,
        urlAnalysisDispatch: dispatch
    }
    
    return <UrlAnalysisContext.Provider value={value}>
        {children}
    </UrlAnalysisContext.Provider>
}

export const useUrlAnalysis = () => {
    return useContext(UrlAnalysisContext)
}
