import styled from "styled-components";

export const PasswordChangeConfirmation = styled.div`
    margin: 0 auto;
    max-width: 452px;
    h3{
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    p{
        color: #707070;
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
        margin-bottom: 32px;
    }
`;


export const ProceedButton = styled.div`
    background: #FF711E;
    border-radius: 8px;
    width: 100%;
    height: 40px;
    outline: none;
    border: none;
    color: ${props => props.theme.colors.white};
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover{
        background: #E75500;
    }

    &:active{
        background: #C74A00;
    }
`;
