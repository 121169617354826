import { appClient } from "../../../../../../utils/axios-utils";
import { useMutation } from "@tanstack/react-query";
import { useGetUserInfo } from "../../../../../hooks/useGetUserInfo";
import { useSearch } from "../../context/search";
import { useDisplay } from "../context/display";
const urlDomainFilterMatch = async (data) => {
    const config = {
        url: "/search/display/url/domain/filter",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlTextFilterMatch = async (data) => {
    const config = {
        url: "/search/display/url/text/filter",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlExactFilterMatch = async (data) => {
    const config = {
        url: "/search/display/url/exact/filter",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlDomainMatch = async (data) => {
    const config = {
        url: "/search/display/url/domain",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlExactMatch = async (data) => {
    const config = {
        url: "/search/display/url/exact",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlTextMatch = async (data) => {
    const config = {
        url: "/search/display/url/text",
        method: "POST",
        data
    }

    return appClient(config)
}

export const useDisplayInfo = () => {
    const { displayState, displayDispatch } = useDisplay()
    const {searchState, searchDispatch} = useSearch()

    const userInfoHook = useGetUserInfo()


    const { mutate: urlDomainFilterMutate, isLoading: isUrlDomainFilterLoading } = useMutation(urlDomainFilterMatch, {
        onSuccess: (data) => {
            displayDispatch({ type: "SET_ADS", value: data.documents })
            displayDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            displayDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                displayDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                displayDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                displayDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                displayDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                displayDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                displayDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            displayDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            displayDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const { mutate: urlTextFilterMutate, isLoading: isUrlTextFilterLoading } = useMutation(urlTextFilterMatch, {
        onSuccess: (data) => {
            displayDispatch({ type: "SET_ADS", value: data.documents })
            displayDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            displayDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                displayDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                displayDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                displayDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                displayDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                displayDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                displayDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            displayDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            displayDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const { mutate: urlExactFilterMatchMutate, isLoading: isUrlExactFilterLoading } = useMutation(urlExactFilterMatch, {
        onSuccess: (data) => {
            displayDispatch({ type: "SET_ADS", value: data.documents })
            displayDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            displayDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                displayDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                displayDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                displayDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                displayDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                displayDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                displayDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            displayDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            displayDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const {mutate: urlDomainMutate, isLoading: isUrlDomainLoading} = useMutation(urlDomainMatch, {
        onSuccess: (data) => {
            // displayDispatch({type: "CLEAR_FILTERS"}) // Clear filter before main search data updated
    
            // Update search results
            displayDispatch({type: "SET_ADS", value: data.documents})
            displayDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            displayDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})
    
            if(data.documents && data.documents.length > 0){
                displayDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                displayDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                displayDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                displayDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                displayDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                displayDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            displayDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            displayDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }})
    
    const {mutate: urlExactMutate, isLoading: isUrlExactLoading} = useMutation(urlExactMatch, {
        onSuccess: (data) => {
            // displayDispatch({type: "CLEAR_FILTERS"}) // Clear filter before main search data updated
            displayDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})
    
            // Update search results
            displayDispatch({type: "SET_ADS", value: data.documents})
            displayDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            displayDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})
    
            if(data.documents && data.documents.length > 0){
                displayDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                displayDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                displayDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                displayDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                displayDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                displayDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            displayDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            displayDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })
    
    const {mutate: urlTextMutate, isLoading: IsUrlTextLoading} = useMutation(urlTextMatch, {
        onSuccess: (data) => {
            // displayDispatch({type: "CLEAR_FILTERS"}) // Clear filter before main search data updated
            displayDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})
    
            // Update search results
            displayDispatch({type: "SET_ADS", value: data.documents})
            displayDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            displayDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})
    
            if(data.documents && data.documents.length > 0){
                displayDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                displayDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                displayDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                displayDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                displayDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                displayDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            displayDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            displayDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    return {
        urlDomainFilterMutate,
        isUrlDomainFilterLoading,
        urlTextFilterMutate,
        isUrlTextFilterLoading,
        urlExactFilterMatchMutate,
        isUrlExactFilterLoading,
        urlDomainMutate,
        isUrlDomainLoading,
        urlExactMutate,
        isUrlExactLoading,
        urlTextMutate,
        IsUrlTextLoading
    };
};
    