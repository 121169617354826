import { Header, Image, ImageContainer, ImageSlider, Logo, LogoContainer, Title, TopImageAdsContainer, ViewAll, EmptyState } from "./index.styled";

import googleLogo from "./assets/googleLogo.svg"
import metaLogo from "./assets/metaAdLogo.svg"
import bingLogo from "./assets/bingLogo.svg"
import linkedInLogo from "./assets/linkedInLogo.svg"

import { useMutation } from "@tanstack/react-query"
import { appClient } from "../../../../../utils/axios-utils"
import { useOverview } from "../../context/overview";
import { useEffect } from "react";
import { LineLoader } from "../index.styled";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function TopImageAds() {
    const { overviewState: { projectDetails, topImageAds, isOnboardingPopupActive }, overviewDispatch } = useOverview()
    const navigate = useNavigate()
    const getImageAds = (data) => {
        const config = {
            url: `/dashboard-overview/top-ads/image-ads`,
            method: "POST",
            data
        }
        return appClient(config)
    }
    const { mutate: getImageAdsMutate, isLoading } = useMutation(getImageAds, {
        onSuccess: (data) => {
            overviewDispatch({
                type: "SET_TOP_IMAGE_ADS",
                value: data.topImageAds
            })
        },
        onError: (e) => console.log(e)
    })
    const renderSlider = () => {
        return (
            <ImageSlider>
                <Carousel autoPlay infiniteLoop interval={5000} transitionTime={500} >
                    {topImageAds.map((ad) => {
                        return (
                            <ImageContainer>
                                <Image src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${ad.id}`} alt="img" />
                                <LogoContainer>
                                    <Logo src={ad.platform === "facebook"
                                        ? metaLogo
                                        : ad.platform.includes("google")
                                            ? googleLogo
                                            : ad.platform.includes("bing")
                                                ? bingLogo
                                                : linkedInLogo} />
                                </LogoContainer>
                            </ImageContainer>
                        )
                    })}
                </Carousel>
            </ImageSlider>
        )
    }
    const extractDomain = (inputUrl) => {
        const parsedUrl = new URL(inputUrl)
        return parsedUrl.hostname 
    }
    const handleViewAll = () => {
        navigate(`/adspy/display?searchMode=url&urlSearchType=domain&domainUrlInput=${extractDomain(projectDetails.website)}&page=1&sortType=relevant`)
    }

    useEffect(() => {
        if (projectDetails?.projectId)
            getImageAdsMutate({ projectId: projectDetails.projectId })
    }, [projectDetails?.projectId, isOnboardingPopupActive])

    if(isOnboardingPopupActive || isLoading ){
        return <LineLoader width={326} height={232} />
    }
    return (
        <TopImageAdsContainer>
            <Header>
                <Title>Top image ads</Title>
                <ViewAll onClick={handleViewAll} >View all</ViewAll>
            </Header>
            { topImageAds.length ? renderSlider() : <EmptyState>No image ads found for this domain!</EmptyState>}
        </TopImageAdsContainer>
    );
}

export default TopImageAds;