import React from "react";

import { Container, SearchInList, SearchInOption, CheckBox } from "./index.styled"
import { useYoutube } from "../../../context/youtube";

const SearchInFilter = () => {
    const { youtubeState, youtubeDispatch } = useYoutube()


    const handleCheckedChange = (event) => {
        if(event.target.name === "title") {
            youtubeDispatch({ type: "SET_BROAD_SEARCH_IN", payload: { name: "Title", value: "title", checked: event.target.checked} })
        } else if (event.target.name === "content") {
            youtubeDispatch({ type: "SET_BROAD_SEARCH_IN", payload: { name: "Content", value: "content", checked: event.target.checked} })
        }

        youtubeDispatch({type: 'SET_SEARCH_IN_ERROR', value: false})
    }
    
    return <Container>
        <SearchInList>
            {youtubeState?.broadSearchIn?.map((cb, index) => (
                <SearchInOption>
                    <CheckBox name={cb?.value} type="checkbox" id={`ad-${cb?.value}`} checked={cb?.checked} onChange={handleCheckedChange}/>
                    <label htmlFor={`ad-${cb?.value}`}>{cb?.name}</label>
                </SearchInOption>    
            ))}
        </SearchInList>
    </Container>
}

export default SearchInFilter