import React, { useEffect } from 'react'
import Tracking from './component/tracking'
import { AdsContainer, GraphWrapper, Wrapper } from './index.styled'
import ActiveCampaign from './component/activeCampaign'
import TotalAds from './component/totalAds'
import TopDomains from './component/topDomains'
import AdDistribution from './component/adDistribution'
import Trend from './component/trend/index'
import { useDomainTracking } from '../context/domaintracking'
import { getSearchParams } from '../../../../utils/helper'
import { useLocation } from 'react-router-dom'
import { useDomainTrackingInfo } from '../hooks/useDomainTrackingInfo'

const KeywordTrackingDetails = () => {
  const { domainTrackingState, domainTrackingDispatch } = useDomainTracking()
  const location = useLocation()
  const useDomainTrackingInfoHook = useDomainTrackingInfo()

  useEffect(() => {
    if (!domainTrackingState.domainInputModalOpen) {
      const searchParamFromState = getSearchParams(location.search)

      const {
        domainUserRefId
      } = searchParamFromState

      if (domainUserRefId) {
        useDomainTrackingInfoHook.getTrackingDetailsMutation({ domainUserRefId })
      }
    }
  }, [domainTrackingState.domainInputModalOpen])

  return (
    <Wrapper>
      {useDomainTrackingInfoHook.isGetTrackingDetailsLoading ? ""
        :
        <>
          <Tracking />
          <AdsContainer>
            <GraphWrapper>
              <ActiveCampaign isGetAdStatsLoading={useDomainTrackingInfoHook.isGetAdStatsLoading} />
              <TotalAds isGetAdStatsLoading={useDomainTrackingInfoHook.isGetAdStatsLoading} />
            </GraphWrapper>
            <TopDomains isTopDomainsLoading={useDomainTrackingInfoHook.isGetTopDomainsLoading} />
          </AdsContainer>
          <AdDistribution isMapDistributionLoading={useDomainTrackingInfoHook.isGetMapDataLoading} />
          <Trend isHeatMapDetailsLoading={useDomainTrackingInfoHook.isGetHeatmapDataLoading} isTableDataLoading={useDomainTrackingInfoHook.isGetAdStatsLoading} />
        </>
      }
    </Wrapper>
  )
}

export default KeywordTrackingDetails   