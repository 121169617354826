import { Route, Routes } from 'react-router-dom'
import { useImageAdGenerator } from '../context/imageAdGeneration'
import HomePage from '../../Components/HomePage'
import SampleHistoryImage from '../../../../../media/Dashboard/dummy-downloaded.png'
import InputPage from '../../Components/InputPage'
import AdsDraft from '../../Components/DraftsPage'
import ResultPage from './resultPage'
// import InputPage from './InputPage'

const Root = () => {
    const { imageAdGeneratorState, imageAdGeneratorDispatch } = useImageAdGenerator()
    
    const historyData = [
        {
            image: SampleHistoryImage
        },
        {
            image: SampleHistoryImage
        },
        {
            image: SampleHistoryImage
        },
        {
            image: SampleHistoryImage
        },
        {
            image: SampleHistoryImage
        },
        {
            image: SampleHistoryImage
        }
    ]

    const renderingComponent = () => {
        const { currentPage } = imageAdGeneratorState;

        switch (currentPage) {
            case "home":
                return <HomePage historyData={historyData} adType={"imagead"}/>

            case "input-page":
                return <InputPage forTextAd={false} />
                
            default:
                break;
        }
    }

    return <>
        <Routes>
            <Route path="/" element={renderingComponent()} />
            <Route path="/input" element={<InputPage forTextAd={false} />} />
            <Route path="/drafts" element={<AdsDraft adType={"imagead"}/>}/>
            <Route path="/result" element={<ResultPage />} />
        </Routes>
    </>
}

export default Root