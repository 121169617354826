import React from 'react'
import { useReducer, createContext, useContext } from 'react'

import produce from 'immer'

const initialState = {
    selectedAdType: null,
    selectedAdAccount: null,
    adAccountDropDownOpen: false,
    campaignName: null,

    currentCampaignStep: 2,
    activeAdInputTab: 1,

    adGroupList: [],

    keywordsList: [],
    openedKeywordTypeDropDown: null,

    locationsList: [],
    openedLocationSuggestion: false,

    languageList: [],
    openedLanguageSuggestion: false,

    selectedStrategy: null,
    openedStrategyDropDown: false,
    
    isSelectPlatform: false,
    isAdAccount: false,
    isInputPage: true,
    isAdAccountList: [],
    isSetGoogleLoggedIn: false,
    isListAdAccounts: [],
    isSetIntegrationId: null,
    isSelectAdTypeToggle: false,
    isSingleAdGroupData:[],
    isBudgetData: null,
    isConnectedData: [],
    isCreateAdHeadline:[],
    isCreateAdDescription: [],
    isTransformedKeyword:[],
    isCreateCampaign: {},
    isTitleAdGroup: null,
    isAdDataPage: false,
    isTitleValidation: false,
    isDescripValidation: false,
    isErrorMsg: null,
    isInputError: null,
    isCheckEmailPopup: false,
    isManagerId: null,
    isCheckEmailError: false,
    isCampaignPublished: false,
    isDestinationUrl: null,
    isDestinationError: null
}

const adManagementTypeReducer = produce((draft, action) => {
    
    switch(action.type) {
        case "SET_SELECTED_ADTYPE":
            draft.selectedAdType = action.value
        break

        case "SET_SELECTED_ACCOUNT":
            draft.selectedAdAccount = action.value
        break

        case "SET_CAMPAIGN_NAME":
            draft.campaignName = action.value
        break

        case "SET_ADACCOUNT_DROPDOWN_OPEN":
            draft.adAccountDropDownOpen = action.value
        break

        case "SET_CURRENT_CAMPAIGN_STEP":
            draft.currentCampaignStep = action.value
        break

        case "SET_ACTIVE_ADINPUT_TAB":
            draft.activeAdInputTab = action.value
        break

        case "SET_ADGROUP_LIST":
            draft.adGroupList = action.value
        break

        case "SET_KEYWORDS_LIST":
            draft.keywordsList = action.value
        break

        case "SET_OPENED_KEYWORD_TYPE_DROPDOWN":
            draft.openedKeywordTypeDropDown = action.value
        break

        case "SET_LOCATIONS_LIST":
            draft.locationsList = action.value
        break

        case "SET_OPENED_LOCATION_SUGGESTION":
            draft.openedLocationSuggestion = action.value
        break

        case "SET_LANGUAGE_LIST":
            draft.languageList = action.value
        break

        case "SET_OPENED_LANGUAGE_SUGGESTION":
            draft.openedLanguageSuggestion = action.value
        break

        case "SET_SELECTED_STRATEGY":
            draft.selectedStrategy = action.value
        break

        case "SET_OPENED_STRATEGY_DROPDOWN":
            draft.openedStrategyDropDown = action.value
        break
        case 'SET_INPUT_PAGE':
            draft.isInputPage = action.value
        break
        case 'SET_SELECT_A_PLATFORM':
            draft.isSelectPlatform = action.value
        break
        case 'SET_SELECT_AD_ACCOUNT':
            draft.isAdAccount = action.value
        break
        case 'SET_AD_ACCOUNT_LIST':
            draft.isAdAccountList = action.value
        break
        case 'SET_GOOGLE_LOGGED_IN':
            draft.isSetGoogleLoggedIn = action.value
        break
        case 'SET_LIST_AD_ACCOUNTS':
            draft.isListAdAccounts = action.value
        break
        case 'SET_INTEGRATION_ID':
            draft.isSetIntegrationId = action.value
        break
        case 'SET_SELECTAD_TYPE_TOGGLE':
            draft.isSelectAdTypeToggle = action.value
        break
        case 'SET_SINGLE_AD_GROUP':
            draft.isSingleAdGroupData = action.value
        break
        case 'SET_BUDGET_DATA':
            draft.isBudgetData = action.value
        break
        case 'SET_CONNECTED_DATA':
            draft.isConnectedData = action.value
        break
        case 'SET_CREATE_AD_HEADLINE':
            draft.isCreateAdHeadline = action.value
        break
        case 'SET_CREATE_AD_DESCRIPTION':
            draft.isCreateAdDescription = action.value
        break
        case 'SET_TRANSFORMED_KEYWORD':
            draft.isTransformedKeyword = action.value
        break
        case 'SET_CREATE_CAMPAIGN':
            draft.isCreateCampaign = action.value
        break
        case 'SET_TITLE_AD_GROUP':
            draft.isTitleAdGroup = action.value
        break
        case 'SET_AD_DATA_PAGE':
            draft.isAdDataPage = action.value
        break
        case 'SET_ERROR_MESSAGE':
            draft.isErrorMsg = action.value
        break
        case 'SET_INPUT_ERROR':
            draft.isInputError = action.value
        break
        case 'SET_CHECK_EMAIL_POPUP':
            draft.isCheckEmailPopup = action.value
        break
        case 'SET_MANAGER_CUSTOMER_ID':
            draft.isManagerId = action.value
        break
        case 'SET_CHECK_EMAIL_ERROR':
            draft.isCheckEmailError = action.value
        break
        case 'SET_CAMPAIGN_PUBLISHED':
            draft.isCampaignPublished = action.value
        break
        case 'SET_DESTINATION_URL':
            draft.isDestinationUrl = action.value
        break
        case 'SET_DESTINATION_ERROR':
            draft.isDestinationError = action.value
        break
        // case 'SET_KEYWORD_VALIDATION':
        //     draft.isKeywordValidation = action.value
        // break
        default: 
        break
    }
})

const AdManagementReducer = () => {
    return useReducer(adManagementTypeReducer, initialState)
}

const AdManagementContext = createContext()

export const AdManagementProvider = ({children}) => {
    const [state, dispatch] = AdManagementReducer()
    const value = { adManagementState: state, adManagementDispatch: dispatch }

    return <AdManagementContext.Provider value={value}>
        {children}
    </AdManagementContext.Provider>
}

export const useAdManagement = () => {
    return useContext(AdManagementContext)
}