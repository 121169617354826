import toast from 'react-hot-toast'

export const customToast = ({ message, type, position = 'top-center', duration = 3000 }) => {
    toast(message, {
        type,
        duration,
        position,
        style: {
            borderRadius: '4px',
            textAlign: 'center'
        }
    })
}