import InputPages from "./InputPages";
import { useImageAdGenerator } from "../context/imageAdGeneration";
import GeneratingPage from "./GeneratingPage";
import ResultPage from "./ResultPage";
import { Route, Routes} from 'react-router-dom'

function Root() {
    const { imageAdGeneratorState, imageAdGeneratorDispatch } = useImageAdGenerator()

    const renderingComponent = () => {
        const { no } = imageAdGeneratorState.currentPage;
        if(no <= 4) return <InputPages />
        if(no === 6) return <GeneratingPage />
    }

    return (
        <>
            <Routes>
                <Route path="/" element={renderingComponent()}/>
                <Route path="result" element={<ResultPage />} />
            </Routes>
        </>
    );
}

export default Root;