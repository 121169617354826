import styled from "styled-components";

export const Navbar = styled.div`
    width: 775px;
    display: flex;
    gap: 60px;
    border-bottom: 2px solid #E3E8EE;
    padding-left: 21px;
`;

export const NavItemContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    cursor: pointer;
`;

export const NavItem = styled.div`
    color: ${(props) => props.isActive ? "#1C1C1C" : "rgba(28, 28, 28, 0.80)"};
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: ${(props) => props.isActive ? "500" : "400"};
    line-height: 26px;
    padding: 0 8px;
`;

export const UnderLine = styled.div`
    width: 100%;
    height: 4px;
    border-radius: 4px 4px 0px 0px;
    background-color: rgba(28, 28, 28, 0.40);
    visibility: ${(props) => props.isActive ? "auto" : "hidden"};
`;