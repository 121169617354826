import React from "react";
import styled from "styled-components";
import { useUrlAnalysis } from "../../../context/urlanalysis";

const PopupContainer = styled.div`
    position: absolute;
    background: #00000045;
    left: 0;
    top: 54px;
    width: 100vw;
    height: calc(100vh - 54px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
`;

const PopupBody = styled.div`
    max-width: 334px;
    width: 80%;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px #0000003D;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 40px;
    position: relative;

    h2{
        margin-bottom: 16px;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        color: #2E2E2E;
        margin-bottom: 24px;
    }

`;

const OptionsList = styled.div`
    display: flex;
    gap: 12px;
    flex-direction: column;
`;

const Label = styled.div`
    border-radius: 8px;
    background: #FFF;
    padding: 16px 20px;
    cursor: pointer;
    border: ${props => props.isDisabled ? "1px solid #ECECEC" : props.isSelected ? "1px solid #B4DAFF": "1px solid #FFF"};
    background: ${props => props.isDisabled ? "#FFF" : props.isSelected ? "#F0F7FF": "#ECECEC"};

    input{
        cursor: pointer;
    }
    span{
        margin-left: 8px;
        color: ${props => props.isDisabled ? "#C4C4C4": props.isSelected ? "#034CA0" : "#4D4D4D"};
    }
`;

const Footer = styled.div`
    margin-top: 32px;
    display: flex;
`;

const Cancel = styled.div`
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    color: #2E2E2E;
    padding: 6px 12px;
    cursor: pointer;
`;

const ViewFunnelAnalytics = styled.div`
    overflow: hidden;
    color: #FFF;
    text-align: center;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    border-radius: 8px;
    background: #0A66C2;
    padding: 6px 12px;
    cursor: pointer;
`;

const AdcopyInputPopup = ({}) => {
    const {urlAnalysisState, urlAnalysisDispatch} = useUrlAnalysis()
    // const navigate = useNavigate()

    return <PopupContainer>
        <PopupBody>
            <h2>Select the number of recent ad copies for analysis.</h2>
            <OptionsList>
                <Label isDisabled={false} isSelected={urlAnalysisState.funnelAdsAnalysisLimit == 200}  
                onClick={()=>urlAnalysisDispatch({type: 'SET_FUNNEL_ADS_ANALYSIS_LIMIT', value: 200})}>
                    <input type="radio" value="200" checked={urlAnalysisState.funnelAdsAnalysisLimit == 200} />
                    <span>Analyze 200 ad copies</span>
                </Label>
                <Label isDisabled={true} isSelected={urlAnalysisState.funnelAdsAnalysisLimit == 1000} 
                // onClick={()=>urlAnalysisDispatch({type: 'SET_FUNNEL_ADS_ANALYSIS_LIMIT', value: 1000})}
                >
                    <input type="radio" value="1000" checked={urlAnalysisState.funnelAdsAnalysisLimit == 1000} />
                    <span>Analyze 1000 ad copies</span>
                </Label>
                <Label isDisabled={true} isSelected={urlAnalysisState.funnelAdsAnalysisLimit == 'all'} 
                // onClick={()=>urlAnalysisDispatch({type: 'SET_FUNNEL_ADS_ANALYSIS_LIMIT', value: 'all'})}
                >
                    <input type="radio" value="all" checked={urlAnalysisState.funnelAdsAnalysisLimit == 'all'} />
                    <span>Analyze All ad copies</span>
                </Label>
            </OptionsList>
            <Footer>
                <Cancel onClick={()=>{
                    urlAnalysisDispatch({type:'SET_FUNNEL_ADCOPY_LIMIT_POPUP', value: false})
                    urlAnalysisDispatch({type:'SET_FUNNEL_OPEN', value: false})
                }}>
                    Cancel
                </Cancel>
                <ViewFunnelAnalytics onClick={()=>{
                    urlAnalysisDispatch({type:'SET_FUNNEL_ADCOPY_LIMIT_POPUP', value: false})
                    urlAnalysisDispatch({ type: 'SET_FUNNEL_OPEN', value: true})}
                    }>
                    View Funnel Analytics
                </ViewFunnelAnalytics>
            </Footer>
        </PopupBody>
    </PopupContainer>
}

export default AdcopyInputPopup