import styled from "styled-components";

export const KeywordsListContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    height: 65%;
    overflow-y: auto;
`;

export const KeywordsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    gap: 15px;
    border: 1px solid #DADFE4;
    background: #FFF;

    color: #1C1C1C;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0 22px;
    cursor: pointer;
`;

export const CopyButton = styled.img`
    display: none;
`;