import { appClient } from '../../../../utils/axios-utils';
import {useMutation} from "@tanstack/react-query"
import { useLandingPageAnalysis } from '../context/landingPageAnalysis';
import { useLocation } from 'react-router-dom';
import { getSearchParams } from '../../../../utils/helper';
import { ISTDateformat, getNumberDateFromInput, isDateToday } from '../../../../utils/dateFormat';

const getLandingPageHistory = async (data) => {
    // console.log(data)
    const config = {
        url: "/landing/snapshots-history",
        method: "GET",
    }

    return appClient(config)
}

const dateScreenshot = async (data) => {
    const config = {
        url: "/landing/individual-snapshot",
        method: "POST",
        data
    }

    return appClient(config)
}

const adsAvailability = async (data) => {
    const config = {
        url: "/search/ads-availability",
        method: "POST",
        data
    }

    return appClient(config)
}

const sessionDetails = async (data) => {
    const config = {
        url: `/landing/session/${data}`,
        method: "GET"
    }

    return appClient(config)
}

const platforms = [{
    path: "google",
    name: "google",
    platforms: ["google"],
    width: "16px",
    height: "16px"
},{
    path: "bing",
    name: "bing",
    platforms: ["bing"],
    width: "16px",
    height: "16px"
},
// {
//     path: "youtube",
//     platforms: ["youtube"],
//     width: "23px",
//     height: "16px"
// },
{
    path: "meta",
    name: "facebook",
    platforms: ["facebook"],
    width: "21px",
    height: "14px"
},
// {
//     path: "linkedin",
//     platforms: ["linkedin"],
//     width: "16px",
//     height: "16px"
// },
// {
//     path: "reddit",
//     platforms: ["reddit"],
//     width: "16px",
//     height: "16px"
// },
{
    path: "googlepla",
    name: "googleEcom",
    platforms: ["googleecom"],
    width: "53px",
    height: "16px"
},{
    path: "bingpla",
    name: "bingEcom",
    platforms: ["bingecom"],
    width: "53px",
    height: "16px"
}
// ,{
//     path: "flipkart",
//     platforms: ["flipkart"],
//     width: "16px",
//     height: "16px"
// },{
//     path: "amazon",
//     platforms: ["amazon"],
//     width: "16px",
//     height: "16px"
// }
,{
    path: "display",
    name: "display",
    platforms: ["display"],
    width: "16px",
    height: "16px"
}
]

export const useLandingPageAnalysisInfo = () => {
    const {landingPageAnalysisState, landingPageAnalysisDispatch} = useLandingPageAnalysis()
    const location = useLocation()

    const { mutate: getLandingPageHistoryMutation, isLoading: isGetLandingPageHistoryLoading } = useMutation(getLandingPageHistory, {
        onSuccess: (data) => {
            if(data.length > 0){
                landingPageAnalysisDispatch({type: 'SET_LANDING_TABLE_OPEN', value: true})
                landingPageAnalysisDispatch({type: 'SET_LANDING_TABLE_LIST', value: data})
            }else{
                landingPageAnalysisDispatch({type: 'SET_LANDING_TABLE_OPEN', value: false})
                landingPageAnalysisDispatch({type: 'SET_LANDING_TABLE_LIST', value: []})
            }
        },
        onError: (err) => {
            
        }
    })

    const { mutate: getDateScreenshotMutate, isLoading: isDateScreenshotLoading } = useMutation(dateScreenshot, {
        onSuccess: (data) => {
            // console.log(data)
            landingPageAnalysisDispatch({type: 'SET_FETCH_SCREENSHOT_ERROR', value: ""})
            const searchParamFromState = getSearchParams(location.search)
            const {
                date
            } = searchParamFromState
            if(data.landerUrl == null || !data.captured){
                landingPageAnalysisDispatch({type:'SET_DATA_CAPTURED', value: false})

                setTimeout(() => {
                    landingPageAnalysisDispatch({type: 'SET_IS_SCREENSHOT_LOADING', value: true})
                    getDateScreenshotMutate({date: ISTDateformat(date), query: landingPageAnalysisState.searchUrl})
                    
                }, isDateToday(date) ? 5000 : 10000);
            }else{
                landingPageAnalysisDispatch({type:'SET_DATA_CAPTURED', value: true})


                landingPageAnalysisDispatch({type: 'SET_SCREENSHOT_URL', value: data.landerUrl})
                let newDate = data.date.split("T")[0]
                landingPageAnalysisDispatch({type: 'SET_SCREENSHOT_DATE', value: newDate})
                landingPageAnalysisDispatch({type: 'SET_IS_SCREENSHOT_LOADING', value: false})
                landingPageAnalysisDispatch({type: 'SET_FETCH_SCREENSHOT_ERROR', value: ''})
            }
            
        },
        onError: (err) => {
            landingPageAnalysisDispatch({type: 'SET_FETCH_SCREENSHOT_ERROR', value: err.response.data.errors[0].message})
            landingPageAnalysisDispatch({type: 'SET_IS_SCREENSHOT_LOADING', value: false})
        }
    })

    const { mutate: getAdsAvailabilityMutate, isLoading: isAdsAvalabilityLoading } = useMutation(adsAvailability, {
        onSuccess: (data) => {
            // console.log(data)
            landingPageAnalysisDispatch({type: 'SET_ADS_AVAILABILITY', value: data})
            let platformWithAds =  platforms.filter((platform) => {
                if(data[platform.name])
                    return platform
            })

            landingPageAnalysisDispatch({type: 'UPDATE_NO_ADS', value: platformWithAds.length > 0 ? false : true})

            if(platformWithAds.length > 0){
                landingPageAnalysisDispatch({ type: 'SET_AD_COPY_PLATFORM', value: platformWithAds[0].path})
            }
        },
        onError: (err) => {

        }
    })

    const { mutate: getSessionDetailsMutate, isLoading: isSessionDetailsLoading } = useMutation(sessionDetails, {
        onSuccess: (data) => {
            if(data.date){
                let finalDate = data.date.replace(",","").replace("AM","GMT+0530 (India Standard Time)")
                console.log("finalDate",finalDate)
                finalDate = getNumberDateFromInput(finalDate)
                landingPageAnalysisDispatch({type: 'SET_IS_SCREENSHOT_LOADING', value: true})

                // landingPageAnalysisDispatch({type: 'SET_SEARCH_URL', value: data.url})
                // landingPageAnalysisDispatch({type: 'SET_DOMAIN_NAME', value: getDomainName(data.url)})
                landingPageAnalysisDispatch({type: 'SET_SELECTED_YEAR', value: finalDate.split("-")[0]})
                landingPageAnalysisDispatch({type: 'SET_SELECTED_MONTH', value: finalDate.split("-")[1]})
                landingPageAnalysisDispatch({type: 'SET_SELECTED_DAY', value: finalDate.split("-")[2]})
                // getDomainKeywords({query: url})
                // landingPageAnalysisDispatch({type: 'SET_IS_SCREENSHOT_LOADING', value: true})
                // getDateScreenshotMutate({date: ISTDateformat(finalDate), query: data.url})
                landingPageAnalysisDispatch({ type: 'SET_CURRENT_DETAILS_TYPE', value: 'landingpage'})
                landingPageAnalysisDispatch({type: 'SET_SCREENSHOT_URL', value: data.landerUrl})
                landingPageAnalysisDispatch({type: 'SET_FETCH_SCREENSHOT_ERROR', value: ''})
                landingPageAnalysisDispatch({type: 'SET_IS_SCREENSHOT_LOADING', value: false})


                landingPageAnalysisDispatch({type:'SET_DATA_CAPTURED', value: true})

                // getAdsAvailability({query: data.url, startDate: `${finalDate.split("-")[0]}-${finalDate.split("-")[1]}-${finalDate.split("-")[2]}`})
            }
        },
        onError: (err) => {

        }
    })

    return {
        getLandingPageHistoryMutation,
        isGetLandingPageHistoryLoading,
        getSessionDetailsMutate,
        isSessionDetailsLoading,
        getAdsAvailabilityMutate,
        isAdsAvalabilityLoading,
        getDateScreenshotMutate,
        isDateScreenshotLoading
    };
};
