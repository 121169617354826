import React from "react";
import GoogleCard from "../components/GoogleCard";
import BingCard from "../components/BingCard";
import YoutubeCard from "../components/YoutubeCard";
import FacebookCard from "../components/FacebookCard";
import LinkedinCard from "../components/Linkedin";
import RedditCard from "../components/RedditCard";
import GoogleEcomCard from "../components/GoogleEcomCard";
import BingEcomCard from "../components/BingEcomCard";
import FlipkartCard from "../components/FlipkartCard";
import DisplayCard from "../components/Display";
import AmazonCard from "../components/Amazon";
const AdCard = ({ad}) => {
    if(ad.platform == "google"){
        return <GoogleCard ad={ad}/>
    }else if(ad.platform == "bing"){
        return <BingCard ad={ad}/>
    }else if(ad.platform == "youtube"){
        return <YoutubeCard ad={ad}/>
    }else if(ad.platform == "reddit"){
        return <RedditCard ad={ad}/>
    }else if(ad.platform == "facebook"){
        return <FacebookCard ad={ad}/>
    }else if(ad.platform == "linkedin"){
        return <LinkedinCard ad={ad}/>
    }else if(ad.platform == "google-ecom"){
        return <GoogleEcomCard ad={ad} />
    }else if(ad.platform == "bing-ecom"){
        return <BingEcomCard ad={ad}/>
    }else if(ad.platform == "flipkart"){
        return <FlipkartCard ad={ad}/>
    }else if(ad.platform == "display"){
        return <DisplayCard ad={ad}/>
    }else if(ad.platform == "amazon"){
        return <AmazonCard ad={ad}/>
    }
}

export default AdCard