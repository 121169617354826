import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom"
import { useOverview } from "../context/overview";
import Competitors from "./Competitors";
import ClientLibrary from "./ClientLibrary";
import { ContentGrid, ContentHeader, Header, HeaderLoader, LineLoader, Name, Overview, ProjectDetails, ProjectDropdownButton, ProjectDropdownContainer, ProjectList, ProjectName, RootContainer, Today, Welcome } from "./index.styled";
import PromotionBanners from "./Promotions";
import RelatedKeywords from "./RelatedKeywords";
import SavedAds from "./SavedAds";
import TopImageAds from "./TopImageAds";
import TopTextAds from "./TopTextAds";
import { useAuth } from "../../../../context/auth";
import { useMutation } from "@tanstack/react-query"
import { appClient } from "../../../../utils/axios-utils"
import Popup from "./Popup";
import dropdownIcon from "../assets/downArrow.svg"
import { useGetUserInfo } from "../../../hooks/useGetUserInfo";
const getFormattedDate = () => {
    // Get today's date
    var today = new Date();

    // Define an array of month names
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Define an array of day names
    var dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    // Format the date
    var formattedDate = dayNames[today.getDay()] + ", " +
        today.getDate() + " " +
        monthNames[today.getMonth()] + " " +
        today.getFullYear();

    return formattedDate;
}


function    Root() {
    const { authState: { username, defaultProjectInfo, activeProjectInfo  }, authDispatch } = useAuth()
    const { overviewState: { projectDetails, isOnboardingPopupActive, brandAssets, projects }, overviewDispatch } = useOverview()
    const dropdownRef = useRef(null)
    const { getCurrentUser } = useGetUserInfo()
    const navigate = useNavigate()
    const getProjects = () => {
        const config = {
            url: `/dashboard-overview/client-profile/get-projects`,
            method: "GET",
        }
        return appClient(config)
    }
    const getProjectDetails = (projectId) => {
        const config = {
            url: `/dashboard-overview/client-profile/status/${projectId}`,
            method: "GET"
        }
        return appClient(config)
    }
    const pinProject = (data) => {
        const config = {
            url: `/dashboard-overview/client-profile/pin-project`,
            method: "POST",
            data
        }
        return appClient(config)
    }
    const { mutate: getProjectDetailsMutate } = useMutation(getProjectDetails, {
        onSuccess: (data) => {
            overviewDispatch({
                type: "SET_PROJECT_DETAILS",
                value: {
                    projectId: data.id,
                    projectName: data.projectName,
                    budget: data.budget,
                    website: data.website,
                    projectDescription: data.description,
                    projectObjective: data.objective,
                    deadline: data.deadline,
                    pinned: data.pinned
                }
            })
            overviewDispatch({
                type: "SET_CLIENT_PROFILE",
                value: {
                    companyName: data.companyName,
                    category: data.aiDomainCategory,
                    website: data.website,
                    location: data.location
                }
            })
            overviewDispatch({
                type: "SET_BRAND_ASSETS",
                value: {
                    brandLogo: {logo: data.logo, ...brandAssets?.brandLogo},
                    brandColor: data.color,
                    fontFamily: {font: data.fontFamily},
                    existingLogo: data.logo
                }
            })
            overviewDispatch({
                type: "SET_CLIENT_PROFILE_UPDATED",
                value: data.updated
            })

        },
        onError: (e) => console.log(e)
    })
    const { mutate: getProjectsMutate } = useMutation(getProjects, {
        onSuccess: (data) => {
            overviewDispatch({
                type: "SET_PROJECTS",
                value: data.projects
            })
        },
        onError: (e) => console.log(e)
    })
    const { mutate: pinProjectMutate } = useMutation(pinProject, {
        onSuccess: (data) => {

            // getProjectsMutate()
            // navigate('/overview')
            getCurrentUser()
        },
        onError: (e) => console.log(e)
    })
    const activateDropdown = (e) => {
        if(dropdownRef.current){
            e.stopPropagation()
            dropdownRef.current.style.display = "unset"
        }
    }

    const deActivateDropdown = () => {
        if(dropdownRef.current)
        dropdownRef.current.style.display = "none"
    }

    const selectProject = (e,project) => {
        e.stopPropagation()
        deActivateDropdown()
        // console.log
        if(project.id !== defaultProjectInfo.projectId)
            pinProjectMutate({projectId: project.id ?? project.projectId})

    }

    useEffect(() => {
        getCurrentUser()
    }, [])
    
    useEffect(() => {
        // console.log('defaultProjectInfo', defaultProjectInfo)
        let popupActiveShoulBe = (!defaultProjectInfo.projectId || !defaultProjectInfo.projectName || !defaultProjectInfo.website)

        overviewDispatch({
            type: "SET_ONBOARDING_POPUP_ACTIVE",
            value:  popupActiveShoulBe
        })
        if(!isOnboardingPopupActive && defaultProjectInfo.projectId){
            // if(activeProjectInfo){
            //     getProjectDetailsMutate(activeProjectInfo.projectId)
            //     overviewDispatch({
            //         type: "SET_PROJECT_DETAILS",
            //         value: activeProjectInfo
            //     })
            //     return
            // }

            overviewDispatch({
                type: "SET_PROJECT_DETAILS",
                value: {...defaultProjectInfo}
            })
            getProjectsMutate()
            getProjectDetailsMutate(defaultProjectInfo.projectId)            
        }
    },[isOnboardingPopupActive, defaultProjectInfo])
    
    return (
        <RootContainer onClick={deActivateDropdown} >
            {isOnboardingPopupActive
                ? 
                    <HeaderLoader>
                        <LineLoader width={145} height={18} />
                        <LineLoader width={290} height={46} />
                        <LineLoader width={214} height={18} />
                    </HeaderLoader>
                :
                    <Header isLoading={true}>
                        <Today>{getFormattedDate()}</Today>
                        <Welcome>{`Welcome back ${username}!`}</Welcome>
                        <ProjectDetails>
                            <Name>{`Here's an overview of the project `}</Name>
                            <Overview onClick={(e) => activateDropdown(e)} >{projectDetails?.projectName}</Overview>
                            {projects && projects.length > 1 && 
                            <>
                                <ProjectDropdownButton src={dropdownIcon} alt="dd" onClick={(e) => activateDropdown(e)} />
                                <ProjectDropdownContainer ref={dropdownRef} >
                                    <ProjectList>
                                        {projects.map((project) => {
                                            return <ProjectName onClick={(e) => selectProject(e,project)} >{project.projectName}</ProjectName>
                                        })}
                                        {/* <ProjectName>Couponswala</ProjectName> */}
                                    </ProjectList>
                                </ProjectDropdownContainer>
                            </>
                            }
                        </ProjectDetails>
                    </Header>
            }
                        
            <PromotionBanners />
            <ContentHeader>
                {isOnboardingPopupActive 
                    ? <LineLoader width={83} height={22} />
                    : "Dashboard"
                }
            </ContentHeader>
            <ContentGrid>
                <ClientLibrary />
                <TopTextAds />
                <TopImageAds />
                <RelatedKeywords />
                <SavedAds />
                <Competitors />
            </ContentGrid>
            <Popup />
        </RootContainer>
    );
}

export default Root;