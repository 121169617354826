import styled from "styled-components";

export const AdTypesContainer = styled.div`
    width: 738px;
   
    flex-shrink: 0;
    border-radius: 10px;
    background: #FFF;
    padding: 0 35px 30px 35px;
    display: flex;
    column-gap: 12px;
    row-gap: 14px;
    flex-wrap: wrap;
`;

export const AdTypeContainer = styled.div`
    width: 158px;
    height: 120px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid ${(props) => props.isSelected ? "#1679DB" : "#F0F0F0"};
    background: ${(props) => props.isSelected ? "rgba(22, 121, 219, 0.02)" : "unset"};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
`;

export const AdTypeIconContainer = styled.div`
    position: absolute;
    top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 30px;
    background: ${(props) => props.isSelected ? "rgba(22, 121, 219, 0.02)" : "#F0F0F0"};
`;

export const AdTypeIcon = styled.img`
`;

export const AdTypeName = styled.div`
    color: ${(props) => props.isSelected ? "#1679DB" : "#000"};
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    position: absolute;
    top: 73px;
`;
export const Header = styled.div`
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 38px;
    margin: 35px 0 16px 35px;
`
export const AdTypeWrapper = styled.div`
    height:536px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    /* justify-content: end; */
    align-items: end;
`

export const FooterWrap = styled.div`
    position: relative;
    width: 200px;
    height: 80px;
`