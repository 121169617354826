import styled from "styled-components";

export const MainContainer = styled.div`
width:100%;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
height: 100%;
justify-content: space-between;
`;

export const SignUPContainer = styled.div`
    width: 100%;
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
      @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {
        width: 100%;
    }
    @media(max-width:700px){
        width:98%;
    }`;

export const LoginHeader = styled.div`
   margin-bottom: 24px;
    width: 100%;
    p{
        color: #2C2C2C;
        text-align: center;
        font-family: Nunito;
        font-size: 36px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
    }
`;

export const FormDivContainer = styled.div`
    max-width: 452px;
    width: 80%;
`;
export const SocialLogin = styled.div`
    margin-bottom: 24px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 8px;
    cursor: pointer;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    p{
        display: flex;
    }
    img{
        margin-right: 8px;
    }

    &:hover{
        background: #F9F9F9;
        border: 1px solid #ECECEC;
    }
`;

export const FormContainer = styled.form`
        margin-top: 20px;
        width: 100%;
`;

export const Divider = styled.div`
    width: 100%;
    stroke-width: 1px;
stroke: #DADFE4;


`;

export const DividerWrapper = styled.div`
    border-bottom: 1px solid #ECECEC;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0px;
    span{
        // color: ${props => props.theme.colors.lightBlack};
        background: #f9f9f9;
        padding: 0px 20px;
 color: #DADFE4;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
letter-spacing: 0.32px;
    }
`;

export const MailTextContainer = styled.div`
    width: 100%;
    height: 40px;
    margin-top: 8px;
    background:#F9F8F8;
    border:1px solid #DADFE4;
    border-radius:4px;
    color:#646464;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    height: 52px;
    text-align: start;
    padding: 12px 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const EmailInputContainer = styled.div`
    margin-bottom: 20px;
    label{
        display: flex;
        align-items: center;
        color: ${props => props.theme.colors.Grey};
        font-size: 16px;
        margin-bottom: 8px;
    }

    label > p{
        font-size: 12px;
        font-weight: 300;
        background: #F6F6F6;
        border-radius: 4px;
        padding: 3px 6px;
        margin-left: 8px;
    }

    label > p > span {
        color: #FF711E;
        margin-left: 3px;
    }
`;

export const EmailInput = styled.input`
    background: #FFFFFF;
    border: ${props => props.error ? "1px solid #FF5180" : "1px solid #ECECEC"};
    outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};
    border-radius: 8px;
    width: 100%;
    height: 40px;
    margin-top: 8px;
    padding: 9px 12px;
    color: ${props => props.theme.colors.lightBlack};
    font-weight: 300;
    &:focus{
        border: ${props => props.error ? "1px solid #FF5180" : "1px solid #5AB3FF"};
        outline: ${props => props.error ? "2px solid #FFB7CB" : "2px solid #B9DDFF"};
    }
`;

export const PasswordInputContainer = styled.div`
    margin-bottom: 8px;
    label{
            color: ${props => props.theme.colors.Grey};
            font-size: 16px;
            margin-bottom: 8px;
        }
`;

export const PasswordContainer = styled.div`
    position: relative;
    #eye{
        position: absolute;
        right: 11px;
        top: 34px;
        cursor: pointer;
        color: #a9a9a9;
    }
`;

export const PasswordInput = styled.input`
    /* border: ${props => props.error ? "1px solid #FF5180" : "1px solid #ECECEC"};
    outline: ${props => props.error ? "2px solid #FFB7CB" : "none"}; */
    border: ${props => props.error ? "1px solid #FF711E" : "1px solid #ECECEC"};
    outline: none;
    background: #FFFFFF;
    border-radius: 4px;
    width: 100%;
    height: 52px;
    margin-top: 16px;
    color: #646464;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: start;
    padding: 12px 22px;

    &:focus{
        /* border: ${props => props.error ? "1px solid #FF5180" : "1px solid #5AB3FF"};
        outline: ${props => props.error ? "2px solid #FFB7CB" : "2px solid #B9DDFF"}; */
        border: ${props => props.error ? "1px solid #FF5180" : "1px solid #FF711E"};

    }


`;

export const CtaContainer = styled.div`

`;

export const LoginButton = styled.button`
    background: #FF711E;
    border-radius: 4px;
    width: 100%;
    height: 44px;
    outline: none;
    border: none;
    color: ${props => props.theme.colors.white};
    font-size: 16px;
    margin-top: 75px;
    opacity: ${(props)=>props.isActive ? '':'0.5'};
    pointer-events: ${(props)=>props.isActive ?'': 'none'};
    &:hover{
        background: #E75500;
    }

    &:active{
        background: #C74A00;
    }
`;

export const Error = styled.p`
    color: #FF0045;
    margin-top: 6px;
    font-size: 14px;
    font-weight: 300;
`;

export const SignupError = styled.div`
    display: flex;
    align-items: flex-start;
    background: #FFF3F7;
    border: 1px solid #FFB7CB;
    border-radius: 8px;
    padding: 12px;
    font-size: 14px;
    font-weight: 300;
    color: #2E2E2E;
    line-height: 21px;
    margin-top: 20px;
    p{
        margin-left: 8px;
    }
    a{
        color: #003FBC;
    }
`;



export const LayoutFooter = styled.div`
    width:100%;
    display:flex;
    margin-top: 20px;
    justify-content:space-evenly;
    margin-bottom: 130px;
    gap: 18px;
`;

export const LayoutEle = styled.div`
    display:flex;
    gap:3px;
    p{
    display:flex;
    justify-content:center;
    align-items:center;
    color: #929292;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: -0.16px;
    }
    @media(max-width:1100px){
        font-size:8px;
    }
    @media(max-width:700px){
        font-size:4px;
    }

`

export const TermsAndConditions = styled.div`
    color: rgb(146, 146, 146);
    font-size: 14px;
    font-weight: 300;
    line-height: 150%;
    letter-spacing: -0.14px;
    padding: 10px 10px 0 10px;
    input[type="checkbox"] {
        margin-right: 5px;
        cursor: pointer;
    }
    a{
        margin-left: 3px;
    }
`
