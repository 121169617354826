import styled from "styled-components";

export const BackgroundContainer = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 34px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 53px;
`;

export const MainContainer = styled.div`
    width: 966px;
    height: 293px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #FFF;
    padding-top: 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Header = styled.div`
    color: #000;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    margin-bottom: 9px;
`;

export const Description = styled.div`
    color: rgba(28, 28, 28, 0.50);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 29px;
    width: 559px;
`;

export const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-bottom: 17px;
`;

export const InputContainer = styled.div`
    width: calc(100% - 86px - 14px);
    height: 44px;
    border-radius: 8px;
    border: ${(props) => props.isFocussed ? "1px solid #FFB258" : "1px solid #D0D5DD"};
    box-shadow: ${(props) => props.isFocussed ? "0px 1px 2px 0px rgba(28, 28, 28, 0.05), 0px 0px 4px 0px rgba(255, 178, 88, 0.25)" : "unset"};
    background: #FFF;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 8px;
`;

export const Icon = styled.img`
    margin-right: 16px;
`;

export const Category = styled.input`
    width: 435px;
    color: rgba(28, 28, 28, 0.90);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
    border: none;
    outline: none;
`;

export const DropdownIcon = styled.img`
    position: absolute;
    top: 14px;
    right: 14px;
    display: ${(props) => props.isActive ? "unset" : "none"};
`;

export const DropdownContainer = styled.div`
    width: 100%;
    height: 256px;
    border-radius: 0px 0px 12px 12px;
    background: #FFF;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: calc(100% + 5px);
    display: ${(props) => props.isActive ? "unset" : "none"};
    z-index: 1;
    overflow-y: auto;
    padding-bottom: 20px;
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;
export const DropdownTitle = styled.div`
    margin: 16px 16px 8px 16px;
    color: rgba(28, 28, 28, 0.50);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;

export const DropdownItem = styled.div`
    width: 100%;
    height: 39px;
    padding: 10px 17px;
    :hover{
        background: #D6EDFE;
    }
    display: flex;
    gap: 14px;

    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */
    cursor: pointer;
`;

export const CategoryIcon = styled.img``;

export const TrackButton = styled.button`
  border-radius: 6px;
  background: ${(props) => props.isActive ? "#FF711E" : "#DADFE4"};
  pointer-events: ${(props) => props.isActive ? "unset" : "none" };
  height: 44px;
  color: #FFF;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  width: 86px;
  :hover {
    filter: brightness(90%);
  }
`;

export const ExamplesContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 17px;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 48px;
`;
export const Label = styled.div`
    color: #1C1C1C;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; 
`;

export const Example = styled.div`
    color: #368CD5;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
`;

export const Container = styled.div`
    width: 100%;
    border-radius: 8px;
`;

export const SearchContainer = styled.div`
    display: flex;
    /* height: 62px; */
    /* margin-top: 32px; */
    align-items: center;
    width: calc(100% - 205px);
    gap: 14px;
    ${(props) =>
    !props.isNavCollapsed
      ? `
          @media screen and (max-width: 1332px) {
              width: 100%;
          }
        `
      : `
          @media screen and (max-width: 1147px) {
              width: 100%;
          }
        `
    }
`;

export const Wrapper = styled.div`
  min-Height: 368px;
  max-width: 996px;
  border-radius: 16px;
  background: #fff;
  padding: 32px 40px;
  margin: 24px 24px 24px 200px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1700px) {
    margin: auto;
    margin-top: 24px;
  };
  ${(props) =>
    !props.isNavCollapsed
      ? `
          @media screen and (max-width: 1332px) {
              margin: 24px 35px 0 30px;
          }
        `
      : `
          @media screen and (max-width: 1147px) {
              margin: 24px 35px 0 30px;
          }
        `
    }
  
`;

export const HeadingWrapper = styled.div`
  display: flex;
  margin-bottom: 40px;
  column-gap: 100px ;
`;

export const HeadingLeft = styled.div`
  h3 {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #ff711ecc;
    margin-bottom: 24px;
  }
  h2 {
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    color: #1c1c1c;
    /* margin-bottom: 20px; */
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px; /* 190% */
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #1c1c1c80;
  }
`;

export const HeadLeftWrap = styled.div`
  display: flex;
  column-gap: 14px;
  align-items: center;
  margin-bottom: 16px;
`;

export const HowItWork = styled.div`
  width: 104px;
  height: 24px;
  border-radius: 16px;
  background: #f5f6fa;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8592a2;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
`;

export const HeaderRight = styled.div`
  /* padding: 10px 30px; */
  // border-radius: 10px;
  // background: #f7f7f7;
  // display: flex;
  // align-items: center;
  img {
    width: 205px;
    height: 135px;
  }
`;
