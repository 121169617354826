import React,{useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { Container, Loginbutton, LoginContainer, PolicyContainer } from "./index.styled";

const AdIntegrationHome = () => {
    const [adAccountList, setAdAccountList] = useState([])
    const [loggedIn, setLoggedIn] = useState(false)
    const [loading, setLoading] = useState(false)
    const [acceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(false)
    const navigate = useNavigate()

    const FetchAccount = () => {
        fetch('http://localhost:8000/get-adaccounts') // api for the get request
                .then(response => response.json())
                .then(data => {
                    setAdAccountList(data)
                    setLoading(false)
                });
    }

    const LoginUser = () => {
        fetch('http://localhost:8000/auth/ads') // api for the get request
                .then(response => response.text())
                .then(data => {
                    localStorage.setItem("isLoggedIn", true)
                    window.open(data)
                });
    }

    const connectAccount = (id) => {
        fetch(`http://localhost:8000/connect-account/${id}`) // api for the get request
                .then(response => response.text())
                .then(data => {
                    navigate(`/adintegration/pushads?customerId=${id}`)
                });
    }

    useEffect(() => {
        setLoading(true)
        let isLoggedin = localStorage.getItem("isLoggedIn")
        let connectedAccount = localStorage.getItem("connectedAccount");

        if(isLoggedin){
            setLoggedIn(true)
            if(connectedAccount){
                navigate(`/adintegration/pushads?customerId=${connectedAccount}`)
            }else{
                FetchAccount()
            }
        }else{
            setLoading(false)
        }

    }, [])

    return <Container>
        {
            loading ? <h2>Loading...</h2> : 
            loggedIn
             ? 
                adAccountList.length > 0 
                    ?
                     <>
                        <label>Select Ad Account</label>
                        <div>
                            {adAccountList.map((data,idx)=>{
                                return <button onClick={(e)=>{
                                    localStorage.setItem("connectedAccount", data)
                                    connectAccount(data)
                                }}>{data}</button>
                            })}
                        </div>
                    </>
                    :
                      ""
                    : 
                    <LoginContainer>
                        <PolicyContainer>
                            <input value={acceptPrivacyPolicy} onClick={()=>setAcceptPrivacyPolicy(prev => !prev)} type="checkbox"/>
                            <p>I agree to the sharing of my data with AI models and other third-party tools, as detailed in the <a href="https://adspyder.io/privacy-policy/#:~:text=please%20contact%20us.-,Data%20Sharing%20with%20Third%2DParty%20Tools,-In%20our%20ongoing" target="__blank">Privacy Policy</a>, for the purpose of enhancing functionality and personalizing my experience within the app.</p>
                        </PolicyContainer>
                        <Loginbutton isActive={acceptPrivacyPolicy} onClick={LoginUser}>
                            Login to Ads Account
                        </Loginbutton>
                    </LoginContainer>
                    
        }
            
        </Container>
    }


export default AdIntegrationHome