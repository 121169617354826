import React, { useEffect } from "react";
import {
  AccountDataContainer,
  AccountDataDiv,
  AccountDataWrap,
  IncreaseWrap,
  SpendValue,
} from "./index.styled";
import adResultData from "../../../../static";
import increaseArrow from "../../../../../../../../media/Dashboard/arrow-up-green.svg";
import AccountDetailsGraph from "./AccountDetailsGraph";
import { useAdIntegration } from "../../../../context/AdManagementContext";
import { useAuth } from "../../../../../../../../context/auth";
import { useAdIntegrationInfo } from "../../../../hooks/useAdManagementInfo";
const AccountDataDetails = () => {
  const { metricDetails } = adResultData;
  const {
    newAdManagementState: { connectByProjectId, dateStartEnd,isStatusDetail },
    newAdManagementDispatch,
  } = useAdIntegration();
  const { setProjectIdByConnect } = useAdIntegrationInfo();
  const { authState, authDispatch } = useAuth();

  useEffect(() => {
    setProjectIdByConnect({
      startDate: dateStartEnd.start.format("YYYY-MM-D"),
      endDate: dateStartEnd.end.format("YYYY-MM-D"),
      projectId: authState.defaultProjectInfo.projectId,
    });
  }, [dateStartEnd]);

  return (
    <AccountDataWrap>
      <AccountDataContainer>
        {/* {metricDetails.map((item) => ( */}
        <AccountDataDiv>
          <p>Return on Ad Spend (ROAS)</p>
          <SpendValue>
            {connectByProjectId && connectByProjectId?.overviewDetails[isStatusDetail]?.roas}x
          </SpendValue>
          {/* <IncreaseWrap>
              <img src={increaseArrow} />
              <span>14%</span>
              vs Last month
            </IncreaseWrap> */}
        </AccountDataDiv>
        <AccountDataDiv>
          <p>Cost-per-click (CPC)</p>
          <SpendValue>
            ${connectByProjectId && connectByProjectId?.overviewDetails[isStatusDetail]?.cpc}
          </SpendValue>
          {/* <IncreaseWrap>
              <img src={increaseArrow} />
              <span>14%</span>
              vs Last month
            </IncreaseWrap> */}
        </AccountDataDiv>
        <AccountDataDiv>
          <p>Conversion</p>
          <SpendValue>
            {connectByProjectId && connectByProjectId?.overviewDetails[isStatusDetail]?.conversionRate}%
          </SpendValue>
          {/* <IncreaseWrap>
              <img src={increaseArrow} />
              <span>14%</span>
              vs Last month
            </IncreaseWrap> */}
        </AccountDataDiv>
        {/* ))} */}
      </AccountDataContainer>
      <AccountDetailsGraph />
    </AccountDataWrap>
  );
};

export default AccountDataDetails;
