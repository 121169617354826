import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    height: 100vh;
`;

export const Left = styled.div`
    padding: 50px;
    width: 40%;

    @media screen and (max-width: ${props => props.theme.breakPoints.desktop}) {
        width: 100%;
        padding: 28px;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {
        padding: 40px;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileSmall}) {
        padding: 20px;
    }
`;

export const LogoContainer = styled.div`
    img{
        width: 78px;
    }
`;

export const ChildrenHolder = styled.div`
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: -20px auto;
    @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {
        width: 100%;
    }
`;

export const Right = styled.div`
    width: 60%;
    background: #F9F9F9;
    @media screen and (max-width: ${props => props.theme.breakPoints.desktop}) {
        display: none;
    }
`;

export const ChildrenContainer = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;