import React, { useState, useRef, useEffect } from 'react';
import { DropdownContainer, DropdownButton, DropdownItem, DropdownList, MainContainer, Checkbox, CheckboxItem } from "./Custom.styled"


const CustomDropdown = ({ options, value, onChange, placeholder, dropdownname, isMulti, gap }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleToggle = () => setIsOpen(!isOpen);

    const handleSelect = (option) => {
        if (isMulti) {
            const newValue = value.includes(option)
                ? value.filter(item => item !== option)
                : [...value, option];
            onChange(newValue);
        } else {
            onChange(option);
            setIsOpen(false);
        }
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const displayValue = isMulti
        ? value.length > 0 ? `${value.length} selected` : placeholder
        : value || placeholder;

    return (
        <MainContainer gap={gap}>
            <div>{dropdownname}</div>
            <DropdownContainer ref={dropdownRef}>
                <DropdownButton onClick={handleToggle}>
                    {displayValue}
                </DropdownButton>
                {isOpen && (
                    <DropdownList>
                        {options.map((option) => (
                            isMulti ? (
                                <CheckboxItem key={option} onClick={() => handleSelect(option)}>
                                    <Checkbox
                                        type="checkbox"
                                        checked={value.includes(option)}
                                        onChange={() => { }}
                                    />
                                    {option}
                                </CheckboxItem>
                            ) : (
                                <DropdownItem key={option} onClick={() => handleSelect(option)}>
                                    {option}
                                </DropdownItem>
                            )
                        ))}
                    </DropdownList>
                )}

            </DropdownContainer>
        </MainContainer>
    );
};

export default CustomDropdown;