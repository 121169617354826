import React from "react";
import {
    CampaignCount,
    ActiveCampaigns,
    Divider,
    TotalAds
} from "./index.styled";
import { useAuth } from "../../../../../../context/auth";
import Skeleton from 'react-loading-skeleton'
import { useUrlAnalysis } from "../../../context/urlanalysis";

const CampaignData = ({ colorsArray }) => { 
  const { authState } = useAuth();
  const { urlAnalysisState, urlAnalysisDispatch  } = useUrlAnalysis()

  return (
    <CampaignCount>
        <h4>Active Campaigns</h4>
        <ActiveCampaigns>
            {urlAnalysisState.domainStatsLoading ? <p><Skeleton style={{
        lineHeight: 2,
        width: 100,
      }}/></p> : <p>{urlAnalysisState.activeCampaigns.totalCampaigns}
      </p>
      }
        </ActiveCampaigns>
        <Divider/>
        <h4>Total ads</h4>
        <TotalAds>
          {urlAnalysisState.domainStatsLoading ? <p><Skeleton style={{
        lineHeight: 2,
        width: 100,
      }}/></p> :<p>{urlAnalysisState.activeCampaigns.totalCampaignAds}</p>}
        </TotalAds>
    </CampaignCount>
  );
};

export default CampaignData;




