import React, {useState, useEffect, useRef} from "react";
import {
    useMutation
} from "@tanstack/react-query";
import toast from 'react-hot-toast'
import { PopupContainer, PopupBody, PopupHeader, ToggleTagsContainer, PopupContent, TagsInputContainer, Container, ArrowButtons, ToolTipImageContainer, ToolTip, Tag, TagsList, TagsSelectionContainer, LibraryContainer, AddToLibrabryBtn, CollectionContainer, LibraryList, Library, AddLibrary, PlusIcon, AddLibraryInputContainer, NameInput, AddLibraryFooter, AddLibraryCancel, CreateLibraryButton, Footer, CancelPopup, SaveCollectionButton } from "./index.styled"

import DownArrow from "../../../media/Dashboard/uparrow.png"
import ToolTipLogo from "../../../media/Dashboard/tooltip.svg"
import { useAuth } from "../../../context/auth";
import { TagsInput } from "react-tag-input-component"

import {appClient} from '../../../utils/axios-utils'
import { useGetUserInfo } from "../../hooks/useGetUserInfo";


function useOutsideAlerter(ref, setNotificationOpen) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setNotificationOpen(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref])
}

const addToFavourite = async (data) => {
    const config = {
        url: `/adgeneration/project/save-ad`,
        method: "POST",
        data
    }

    return appClient(config)
}

const getTags = async () => {
    const config = {
        url: `/adgeneration/tag/all`,
        method: "GET"
    }

    return appClient(config)
}

const getCollecions = async () => {
    const config = {
        url: `/adgeneration/project/all`,
        method: "GET"
    }

    return appClient(config)
}

const postNewCollecions = async (data) => {
    const config = {
        url: `/adgeneration/project/add`,
        method: "POST",
        data
    }

    return appClient(config)
}

const SaveAdsPopup = ({ad, platform, noMarginTop}) => {
    const {authState, authDispatch} = useAuth()
    const [initialClassNames, setInitialClassNames] = useState({})
    const [inputExpand, setInputExpand] = useState(false)
    const [newCollectionOpen, setNewCollectionOpen] = useState(false)
    const [collectionName, setCollectionName] = useState('')
    const [enableTags, setEnableTags] = useState(true)
    const userInfoHook = useGetUserInfo()
    const wrapperRef = useRef(null)


    useOutsideAlerter(wrapperRef, setInputExpand)

    const {mutate: addFavouriteMutate, isLoading: isAddFavouriteLoading} = useMutation(addToFavourite, {
        onSuccess: (data) => {
            
        },
        onError:  ((e)=>{
           
        })
    })

    const {mutate: getTagsMutate, isLoading: isTagsLoading} = useMutation(getTags, {
        onSuccess: (data) => {
            let finalData = []
            data.map((k)=>{
                finalData.push(k.name)
            })
            authDispatch({type: 'UPDATE_PROJECT_TAGS', value: finalData})
        },
        onError:  ((e)=>{
           
        })
    })

    const {mutate: getCollecionsMutate, isLoading: isCollectionLoading} = useMutation(getCollecions, {
        onSuccess: (data) => {
            authDispatch({type: 'UPDATE_PROJECT_COLLECTIONS', value: data})
        },
        onError:  ((e)=>{
           
        })
    })

    const {mutate: postNewCollecionsMutate, isLoading: isPostNewCollectionLoading} = useMutation(postNewCollecions, {
        onSuccess: (data) => {
            let finalData = [...authState.project.collections,{id: data.projectId, name:collectionName}]
            authDispatch({type:'UPDATE_PROJECT_COLLECTIONS', value: finalData})
            setNewCollectionOpen(false)
        },
        onError:  ((e)=>{
           
        })
    })

    const onFavouriteClick = () => {
        addFavouriteMutate({
            adId: authState.selectedFavouriteAdId,
            selectedTags: authState.project.selectedTags,
            selectedProjects: authState.project.selectedCollections,
            platform: authState.project.selectedPlatform
        })
        userInfoHook.getUserFavourite()
        authDispatch({type:'UPDATE_PROJECT_SELECTED_TAGS', value: []})
        authDispatch({type:'UPDATE_PROJECT_SELECTED_COLLECTIONS', value: []})
        authDispatch({type:'UPDATE_SAVE_AD_POPUP', value: false})
        toast.success("Ad Saved Successfully")
    }

    const toggleLibraryData = (id) => {
        if(authState.project.selectedCollections.includes(id)){
            let finalData = authState.project.selectedCollections.filter((item) => item !== id);
            authDispatch({type:'UPDATE_PROJECT_SELECTED_COLLECTIONS', value: finalData})
        }else{
            let finalData = [...authState.project.selectedCollections,id]
            authDispatch({type:'UPDATE_PROJECT_SELECTED_COLLECTIONS', value: finalData})
        }
    }

    const toggleTagsData = (tags) => {
        if(authState.project.tags.includes(tags[tags.length - 1])){
            
        }else{
            let finalData = [...authState.project.tags,tags[tags.length - 1]]
            authDispatch({type:'UPDATE_PROJECT_TAGS', value: finalData})
        }
    }

    const AddSelectedTag = (tag) => {
        if(authState.project.selectedTags.includes(tag)){
            let finalData = authState.project.selectedTags.filter((d,idx)=>{
                if(d != tag){
                    return d
                }
            })
            authDispatch({type: 'UPDATE_PROJECT_SELECTED_TAGS', value: finalData})
        }else{
            authDispatch({type: 'UPDATE_PROJECT_SELECTED_TAGS', value: [...authState.project.selectedTags,tag]})
        }
    }

    const onCreateLibrary = () => {
        postNewCollecionsMutate({project: collectionName})
    }

    useEffect(() => {
        if(authState.project.tags.length >= 5) {
            setInitialClassNames({input: "rti-remove-input"})
        } else {
            setInitialClassNames({})
        }
    }, [authState.project.tags])

    useEffect(() => {
        getTagsMutate()
        getCollecionsMutate()
    }, [])
    
    return <PopupContainer noMarginTop={noMarginTop}>
        <PopupBody ref={wrapperRef}>
            <PopupHeader>
                <h2>Save ad to</h2>

                <ToggleTagsContainer>
                    <span>Tags</span>
                    <input type="checkbox" id="switch" onChange={()=>setEnableTags(prev => !prev)} checked={enableTags}/><label for="switch">Toggle</label>
                </ToggleTagsContainer>
            </PopupHeader>
            {isTagsLoading || isCollectionLoading ? <p>Loading...</p> : <PopupContent>
                {enableTags && <TagsInputContainer>
                    <h3>
                        Add Tags
                    </h3>
                    <Container error={false} inputExpand={inputExpand} >
                        <TagsInput
                            error={false} 
                            value={authState.project.selectedTags}
                            onChange={(newkeywords) => {
                                authDispatch({type:'UPDATE_PROJECT_SELECTED_TAGS', value: newkeywords})
                            }}
                            // maxTags={20}
                            name="keywords"
                            placeHolder={authState.project.tags.length == 0 ? "Enter up to 10 keywords" : "Enter up to 10 keywords"}
                            separators={["Enter", " ", ","]}
                            classNames={initialClassNames}
                        /> 
                        {/* {keywords.length > 0 && <ArrowButtons onClick={()=>setInputExpand(!inputExpand)}>
                            <img src={DownArrow}/>
                        </ArrowButtons>} */}
                        <ToolTipImageContainer error={false} className="tooltip-container">
                            <img src={ToolTipLogo}/>
                            <ToolTip>
                                You can enter upto 10 keywords
                            </ToolTip>
                        </ToolTipImageContainer>
                    </Container>
                </TagsInputContainer>}
                {enableTags && authState.project.tags.length > 0 && <TagsSelectionContainer>
                    <h3>
                        Add Tags
                    </h3>
                    <TagsList>
                        {authState.project.tags.map((t, idx)=>{
                            return <Tag onClick={()=>AddSelectedTag(t)}>
                                {t}
                            </Tag>
                        })}
                    </TagsList>
                </TagsSelectionContainer>}
                {/* <LibraryContainer>
                    <label><input type="checkbox"/> My Library</label>
                    <AddToLibrabryBtn>
                        Save to Library
                    </AddToLibrabryBtn>
                </LibraryContainer> */}
                <CollectionContainer>
                    <h3>
                        Library Collection
                    </h3>
                    <LibraryList>
                        {authState.project.collections.length > 0 && authState.project.collections.map((lib, idx)=>{
                            return <Library>
                                <label><input type="checkbox" onChange={()=>toggleLibraryData(lib.id)} checked={authState.project.selectedCollections.includes(lib.id)}/>{lib.name}</label>
                            </Library>
                        })}
                    </LibraryList>
                    {!newCollectionOpen && <AddLibrary onClick={()=>setNewCollectionOpen(prev => !prev)}>
                        <PlusIcon>
                            +
                        </PlusIcon>
                        New Collection
                    </AddLibrary>}
                    {newCollectionOpen && <AddLibraryInputContainer>
                        <h3>
                            Name
                        </h3>
                        <NameInput onChange={(e)=>setCollectionName(e.target.value)} placeholder="Enter collection name"/>
                        <AddLibraryFooter>
                            <AddLibraryCancel onClick={()=>setNewCollectionOpen(false)}>
                                cancel
                            </AddLibraryCancel>
                            <CreateLibraryButton isDisabled={false} onClick={()=>onCreateLibrary()}>
                                Create
                            </CreateLibraryButton>
                        </AddLibraryFooter>
                    </AddLibraryInputContainer>}
                </CollectionContainer>
                <Footer>
                    <CancelPopup onClick={()=>authDispatch({type:'UPDATE_SAVE_AD_POPUP', value: false})}>
                        cancel
                    </CancelPopup>
                    <SaveCollectionButton onClick={()=> onFavouriteClick()} isDisabled={!(authState.project.selectedCollections.length > 0)}>
                        Save to Collection
                    </SaveCollectionButton>
                </Footer>
            </PopupContent>}
        </PopupBody>
    </PopupContainer>
}

export default SaveAdsPopup