import React from "react";

import { AdContainer, ImageContainer, FloatingButtonsContainer, AdBodyContainer, ProductName, ProductPrice, WebsiteUrl, AdBodyBottomContainer, AdDate, AdCountries,DetailsButton, CardContainer, LinkWrap, CountryWrap, Country, HoverCardWrapper, HoverCard, AdDetailsButton  } from "./index.styled"


import RightUpperBlackArrow from "../../../../../../media/Dashboard/RightUpperBlackArrow.svg"

import DateIcon from "../../../../../../media/Images/DateIcon.svg"
import CountryIcon from "../../../../../../media/Images/CountryIcon.svg"
import AltImage from '../../../../../../media/Dashboard/altimage.png'
import locationImg from '../../../../../../media/Dashboard/locationCard.svg'
import { useSavedAds } from "../../../context/savedads";
const GoogleEcomCard = ({ad}) => {
    const { savedAdsDispatch } = useSavedAds()

    const image = ad.image ? <img src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${ad.id}`} onError={() => {}}/> : <img src={AltImage} onError={() => {}}/>;

    const convertTimestamp = (timestamp) => {
        if(!timestamp){
            return "-"
        }
        // Create a new Date object based on the timestamp
        var date = new Date(Number(timestamp));
      
        // Get the month name
        var monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        var month = monthNames[date.getMonth()];
      
        // Get the year
        var year = date.getFullYear();
      
        // Assemble the formatted date string
        var formattedDate = date.getDate() + ' ' + month  + ' ' + year;
      
        return formattedDate;
    }

    const toggleDetailsModal = (ad) => {
        savedAdsDispatch({type: 'SET_SELECTED_AD_FOR_DETAIL', value: ad})
        savedAdsDispatch({ type: 'UPDATE_DETAILS_MODAL', value: true })
    }

      return (
      <CardContainer /* noResults={googleEcomSearchState.noResults} detaislOpen={googleEcomSearchState.adDetails.id == ad.id} */>
          <HoverCard className='hover-element' onClick={() => toggleDetailsModal(ad)}>
            <HoverCardWrapper>
              <AdDetailsButton onClick={() => toggleDetailsModal(ad)}>
                <img src={RightUpperBlackArrow} />
                <span>Ad Details</span>
              </AdDetailsButton>
            </HoverCardWrapper>
          </HoverCard>
          <AdContainer>
            <ImageContainer>
              {image}
            </ImageContainer>
            <AdBodyContainer>
              <ProductName>
                {ad.title}
              </ProductName>
              <LinkWrap>
                {ad.url}
              </LinkWrap>
              <AdDate>
                {convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
              </AdDate>
              <AdBodyBottomContainer>
                <CountryWrap>
                  <img src={locationImg} />
                  <Country>{ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : "--"}</Country>
                </CountryWrap>
                <ProductPrice>
                  {ad.price}
                </ProductPrice>
              </AdBodyBottomContainer>
            </AdBodyContainer>
          </AdContainer>
      </CardContainer>
  )

    return(
      <CardContainer>
        <AdContainer>
          <ImageContainer>{image}</ImageContainer>
        </AdContainer>
        <AdBodyContainer>
            <ProductName>
              {ad.title}
            </ProductName>
            <LinkWrap>
              {ad.url}
            </LinkWrap>
            <AdDate>
              {convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
            </AdDate>
            {/* <ProductPrice>
                {ad.price}
              </ProductPrice> */}
            {/* <WebsiteUrl>
                {ad.sitename}
              </WebsiteUrl> */}
            <AdBodyBottomContainer>
              <CountryWrap>
                <img src={locationImg} />
                <Country>{ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : "--"}</Country>
              </CountryWrap>
              <ProductPrice>
                {ad.price}
              </ProductPrice>
              {/* <GoogleIcon>
                    <img src={googleImg}/>
                </GoogleIcon> */}
              {/* <AdDate>
                {ad.firstSeen && ad.lastSeen && <img src={DateIcon}/>}{convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
                </AdDate> */}
              {/* <AdCountries>
                  <img src={CountryIcon}/>{ ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
                </AdCountries> */}
            </AdBodyBottomContainer>
            {/* <DetailsButton onClick={()=>toggleDetailsModal(ad)}>
              {(googleEcomSearchState.adDetails.id == ad.id) && googleEcomSearchState.detailsModal ? "Close details" : "More details"}
                </DetailsButton> */}
          </AdBodyContainer>
      </CardContainer>
    )
    
    return <CardContainer><AdContainer>
    <ImageContainer>
      {image}
    </ImageContainer>
    <FloatingButtonsContainer>
      {/* <Favorite isFav={idx == 1} onClick={()=>{
            authDispatch({type:'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'google-ecom'})
            authDispatch({type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id})
            authDispatch({type:'UPDATE_SAVE_AD_POPUP', value: true})}}>
          <img src={authState.favAdsList["google-ecom"]?.includes(ad.id) ? FavouriteIconActive : FavorietIcon}/>
      </Favorite> */}
      {/* |
      <DropdownIcon>
          <span/>
      </DropdownIcon> */}
    </FloatingButtonsContainer>
    <AdBodyContainer>
      <ProductName>
        {ad.title}
      </ProductName>
      <ProductPrice>
        {ad.price}
      </ProductPrice>
      <WebsiteUrl>
        {ad.sitename}
      </WebsiteUrl>
      <AdBodyBottomContainer>
        <AdDate>
        {ad.firstSeen && ad.lastSeen && <img src={DateIcon}/>}{convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
        </AdDate>
        <AdCountries>
          <img src={CountryIcon}/>{ ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
        </AdCountries>
      </AdBodyBottomContainer>
      <DetailsButton >
      {/* {(googleEcomSearchState.adDetails.id == ad.id) && googleEcomSearchState.detailsModal ? "Close details" : "More details"} */}
        </DetailsButton>
    </AdBodyContainer>
  </AdContainer>
  </CardContainer>
}

export default GoogleEcomCard