import styled from "styled-components";

export const Wrapper = styled.div`
    font-size: 18px;
    font-weight: 600;
    line-height: 20px; 
    h2{
        margin-bottom: 12px;
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        color: #1C1C1C;
    } 
`;

export const ColorDataContainer = styled.div`
        border-bottom: solid 1px #E8E8E8;
        padding: 16px 12px;
`;

export const Header = styled.div`
    margin-bottom: 16px;
    label{
        font-size: 16px;
        font-weight: 400;
        color: #1C1C1C;
    }
`;


export const ColorListContainer = styled.div`
    height: 40px;
    display: flex;
`;


export const ColorContainerWrapper = styled.div`
    height: 100%;
    width: 40px;
    padding: 4px;
    cursor: pointer;
    border-radius: 10px;
    outline: ${props => props.isSelected ? "solid 1px #1679DB": "none"};
`;


export const ColorContainer = styled.div`
    height: 100%;
    width: 100%;
    border-radius: 8px;
    border: solid 1px #CDCDCD;
    cursor: pointer;
`;