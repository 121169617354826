import React, { useMemo } from 'react'
import { useReducer, createContext, useContext } from 'react'

import produce from 'immer'

const initialState = {
    currentSelectedOption: "",
    searchMode: "saved ads",
    selectedProject: {},
    projectAds: [],
    selectedAds: [],
    isPromptPageOpen: false,
    currentOpenPrompt: "home",
    selectedFormula: "",
    selectedLanguage: "english",
    selectedFunnelType: "",
    selectedTone: "",
    selectProjectAdsPopup: false,

    selectedImageAdCategory: "",
    selectedImageAdPersona: "",
    selectedImageAdSubPersona: "",
    categoryData: [],
    personaData: [],
    subPersonaData: [],

    productName: "",
    adGoal: "",

    generatedAds: [],
    savedAds: []
}

const adGenratorReducer = produce((draft, action) => {
    switch(action.type) {
        case 'SET_CURRENT_SELECTED_OPTION':
            draft.currentSelectedOption = action.value
            break
            
        case 'SET_SEARCH_MODE':
            draft.searchMode = action.value
            break

        case 'SET_SELECTED_PROJECT':
            draft.selectedProject = action.value
            break
                
        case 'SET_PROJECT_ADS':
            draft.projectAds = action.value
            break

        case 'SET_SELECTED_ADS':
            draft.selectedAds = action.value
            break
            
        case 'SET_PROMPT_PAGE_OPEN':
            draft.isPromptPageOpen = action.value
            break

        case 'SET_CURRENT_PROMPT':
            draft.currentOpenPrompt = action.value
            break

        case 'SET_SELECTED_FORMULA':
            draft.selectedFormula = action.value
            break

        case 'SET_SELECTED_FUNNEL_TYPE':
            draft.selectedFunnelType = action.value
            break

        case 'SET_SELECTED_LANGUAGE':
            draft.selectedLanguage = action.value
            break

        case 'SET_SELECTED_TONE':
            draft.selectedTone = action.value
            break

        case 'SET_SELECT_PROJECT_ADS_OPEN':
            draft.selectProjectAdsPopup = action.value
            break

        case 'SET_PRODUCT_NAME':
            draft.productName = action.value
            break
    
        case 'SET_AD_GOAL':
            draft.adGoal = action.value
            break
        
        case 'SET_GENERATED_ADS':
            draft.generatedAds = action.value
            break

        case 'SET_SAVED_ADS':
            draft.savedAds = action.value
            break

        case 'SET_SELECTED_CATEGORY':
            draft.selectedImageAdCategory = action.value
            break
            
        case 'SET_SELECTED_PERSONA':
            draft.selectedImageAdPersona = action.value
            break

        case 'SET_SELECTED_SUB_PERSONA':
            draft.selectedImageAdSubPersona = action.value
            break

        case 'SET_CATEGORY_DATA':
            draft.categoryData = action.value
            break

        case 'SET_PERSONA_DATA':
            draft.personaData = action.value
            break

        case 'SET_SUB_PERSONA_DATA':
            draft.subPersonaData = action.value
            break
        
        default:
            break
    }
})

const useAdGenratorReducer = () => {
    return useReducer(adGenratorReducer, initialState)
}

const AdGenratorContext = createContext()

export const AdGenratorProvider = ({ children }) => {
    const [state, dispatch] = useAdGenratorReducer()
    
    const value = { adGenratorState: state, adGenratorDispatch: dispatch }
    
    return <AdGenratorContext.Provider value={value}>
        {children}
    </AdGenratorContext.Provider>
}

export const useAdGenrator = () => {
    return useContext(AdGenratorContext)
}
