import React from "react";
import { Route, Routes } from "react-router-dom";

import { Helmet } from "react-helmet";
import Layout from "../../layout";

import NotFound from "../NotFoundPage";
import AdManagementComponent from "./AdCampaign";

const AdManagementPage = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route
        path="ad-campaigns/*"
        element={
          <Layout>
            <AdManagementComponent />
          </Layout>
        }
      />
    </Routes>
  );
};

const AdManagementWrapperNew = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ad Generator | AdSpyder</title>
        <meta name="title" content="Ad Generator | AdSpyder" />
        <meta
          name="description"
          content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. "
        />
      </Helmet>
      <AdManagementPage />
    </>
  );
};

export default AdManagementWrapperNew;
