import styled from 'styled-components'

export const ResetPasswordHeader = styled.div`
    margin-bottom: 20px;
    width: 100%;
    p{
        color: #2E2E2E;
        font-size: 20px;
    }
`;

export const EmailSelected = styled.p`
    margin-top: 12px;
    line-height: 21px;
    p{
        color: ${props => props.theme.colors.lightBlack};
        font-size: 14px;
        line-height: 21px;
        font-weight: 300;
    }
    span{
        font-weight: 500;
    }
    a{
        margin-left: 8px;
        color: ${props => props.theme.colors.linkBlue};
    }
`;

export const FormContainer = styled.form`
        /* margin-top: 20px; */
        width: 100%;
`;


export const OtpInputContainer = styled.div`
    margin-bottom: 20px;
    
    label{
            color: ${props => props.theme.colors.Grey};
            font-size: 16px;
            margin-bottom: 8px;
        }


    /* button{
        display: flex;
        font-weight: 300;
        background: none;
        border: none;
        outline: none;
        color: ${props => props.theme.colors.linkBlue};
        font-size: 14px;
        margin-right: 8px;
    }

    button:disabled{
        cursor: not-allowed;
        color: ${props => props.theme.colors.lightDimGrey};
    } */
`;


export const ResendBtn = styled.div`
    display: flex;
    background: none;
    border: none;
    outline: none;
    color: ${props => props.invisible == "true" ? "#929292" : "#3C3B3B"};
    margin-right: 8px;
    cursor: pointer;
    pointer-events: ${props => props.invisible == "true" ? "none": "all"};
    letter-spacing: -0.15px;
    text-decoration-line: underline;
    cursor:pointer;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.14px;
`;

export const TimerSection = styled.div`
    display: flex;
    margin-top: 8PX;

    span{
        margin-left: 3px;
        width: 40px;
    }

    p{
        width: 100%;
        display: flex;
        justify-content: center;
    }
`;

export const OtpInput = styled.input`
    background: #FFFFFF;
    border: ${props => props.error ? "1px solid #FF5180" : "1px solid #ECECEC"};
    outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};
    border-radius: 8px;
    width: 100%;
    height: 40px;
    margin-top: 8px;
    outline: none;
    padding: 9px 12px;
    color: ${props => props.theme.colors.lightBlack};
    &:focus{
        border: ${props => props.error ? "1px solid #FF5180" : "1px solid #5AB3FF"};
        outline: ${props => props.error ? "2px solid #FFB7CB" : "2px solid #B9DDFF"};
    }

`;


export const CtaContainer = styled.div`

`;


export const ChangePasswordButton = styled.button`
    background: #FF711E;
    border-radius: 8px;
    width: 100%;
    height: 40px;
    outline: none;
    border: none;
    color: ${props => props.theme.colors.white};
    font-size: 16px;

    &:hover{
        background: #E75500;
    }

    &:active{
        background: #C74A00;
    }
`;


export const FooterContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    line-height: 21px;
    p{
        color: ${props => props.theme.colors.lightBlack};
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
    }

    a{
        color: ${props => props.theme.colors.linkBlue};
        margin-left: 4px;
    }

    &:hover{
        a{
            text-decoration: underline;
        }
    }
`;


export const BusinessMailConfirmation = styled.div`
    h3{
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    p{
        color: #707070;
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
        margin-bottom: 32px;
    }
`;

export const ProceedButton = styled.div`
    background: #FF711E;
    border-radius: 8px;
    width: 100%;
    height: 40px;
    outline: none;
    border: none;
    color: ${props => props.theme.colors.white};
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover{
        background: #E75500;
    }

    &:active{
        background: #C74A00;
    }
`;


export const Error = styled.p`
    color: #FF0045;
    margin-top: 6px;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 16px;
`;

export const OtpError = styled.div`
    display: flex;
    align-items: flex-start;
    background: #FFF3F7;
    border: 1px solid #FFB7CB;
    border-radius: 8px;
    padding: 12px;
    font-size: 14px;
    font-weight: 300;
    color: #2E2E2E;
    line-height: 21px;
    margin-top: 20px;
    margin-bottom: 17px;
    p{
        margin-left: 8px;
    }
    a{
        color: #003FBC;
    }
`;


export const Container = styled.div`
     width:100%;
     margin-top:102px;
     display:flex;
     flex-direction:column;
     justify-content:center;
     align-items:center;
      @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {
        width: 100%;
    }
    @media(max-width:700px){
        width:98%;
    }
`;

export const Header = styled.div`
width:100%;
.headline{
color: #2C2C2C;
font-family: Nunito;
font-size: 54px;
font-style: normal;
font-weight: 400;
line-height: 150%; 
};
a{
    text-decoration-line: underline;
}
.notify-msg{
    color: #2C2C2C;

font-family: Nunito;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 150%; /* 24px */
letter-spacing: 0.16px;
}
p{
    text-align:center;
};

`;

export const SubContainer = styled.div`

width:76.35%;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
`;

export const FormDivContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 45px;
    input{
        background-color: #F7F7F6;
        width: 70px!important;
        height: 94px;
        flex-shrink: 0;
        border:none;
        border-bottom:1px solid rgba(0, 0, 0, 0.50);
        outline:none;
        text-align:center;
        color: #000;
        font-family: Nunito;
        font-size: 26px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; /* 39px */
        letter-spacing: 0.26px;
        &:focus{
            border-bottom: 1px solid #000;
        }
    }
    .InputListContainer{
        gap: 16px;
    }
    `;

export const FormDiv = styled.form`
     margin-top: 20px;`;

export const VerifyBtn = styled.button`
width: 328px;
height: 52px;
flex-shrink: 0;
background: #0D0E10;
color: #FFF;
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 150%;`;

export const FooterText = styled.div`
flex-direction: column;
align-items: center;
width:100%;
margin-top:50px;
display:flex;
gap:3px;
justify-content:center;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 21px */

p{
color: #929292;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 21px */
letter-spacing: -0.14px;
}
span{
color: #3C3B3B;
letter-spacing: -0.15px;
/* text-decoration-line: underline;
cursor:pointer; */
}

a{
    text-decoration-line: underline;
}
`