import { appClient } from '../../../../utils/axios-utils';
import {useMutation} from "@tanstack/react-query"
import { useKeywordTracking } from '../context/keywordtracking';


const getTrackingKeywords = async (data) => {
    // console.log(data)
    const config = {
        url: "/track/keyword/all",
        method: "GET",
    }

    return appClient(config)
}

const addTrackingKeyword = async (data) => {
    // console.log(data)
    const config = {
        url: "/track/keyword/add",
        method: "POST",
        data
    }

    return appClient(config)
}

const updateTrackingKeyword = async (data) => {
    
    const config = {
        url: "/track/keyword/update-status",
        method: "POST",
        data
    }

    return appClient(config)
}

const getTrackingDetails = async (data) => {
    // console.log(data)
    const config = {
        url: "/track/keyword/get-details",
        method: "POST",
        data
    }
  
    return appClient(config)
  }
  
const getAdStats = async (data) => {
    // console.log(data)
    const config = {
        url: "/aggs/keyword/track/adstat",
        method: "POST",
        data
    }

    return appClient(config)
}

const getTopDomains = async (data) => {
    // console.log(data)
    const config = {
        url: "/aggs/keyword/track/top-domains",
        method: "POST",
        data
    }

    return appClient(config)
}

const getHeatmapData = async (data) => {
    const config = {
        url: `aggs/keyword/track/heatmap`,
        method: "POST",
        data
    }

    return appClient(config)
}

export const useKeywordTrackingInfo = () => {
    const {keywordTrackingState, keywordTrackingDispatch} = useKeywordTracking()

    const { mutate: getTrackingKeywordsMutation, isLoading: isGetTrackingKeywordsLoading } = useMutation(getTrackingKeywords, {
        onSuccess: (data) => {
            console.log(data)
            if(data.length > 0){
                keywordTrackingDispatch({type: 'SET_KEYWORD_TABLE_OPEN', value: true})
                keywordTrackingDispatch({type: 'SET_KEYWORD_TABLE_LIST', value: data})
            }else{
                keywordTrackingDispatch({type: 'SET_KEYWORD_TABLE_OPEN', value: false})
                keywordTrackingDispatch({type: 'SET_KEYWORD_TABLE_LIST', value: []})
            }
            keywordTrackingDispatch({type: 'SET_KEYWORD_INPUT_MODAL_OPEN', value: false})
        },
        onError: (err) => {
            
        }
    })


    const { mutate: addTrackingKeywordMutation, isLoading: isAddTrackingKeywordLoading } = useMutation(addTrackingKeyword, {
        onSuccess: (data) => {
            getTrackingKeywordsMutation()
        },
        onError: (err) => {
            
        }
    })

    const { mutate: updateTrackingKeywordMutation, isLoading: isUpdateTrackingKeywordLoading } = useMutation(updateTrackingKeyword, {
        onSuccess: (data) => {
            getTrackingKeywordsMutation()
        },
        onError: (err) => {
            
        }
    })

    const { mutate: getTrackingDetailsMutation, isLoading: isGetTrackingDetailsLoading } = useMutation(getTrackingDetails, {
        onSuccess: (data) => {
          keywordTrackingDispatch({type: 'SET_KEYWORD_TRACKING_DATA', value: data})
          keywordTrackingDispatch({type: 'SET_KEYWORD_INPUT', value: data?.keyword})
          getAdStatsMutation({ keyword: data.keyword, country: data.country });
          getTopDominsMutation({ keyword: data.keyword,  platform: data.platform ,country: data.country });
          getHeatmapDataMutation({ keyword: data.keyword, country: data.country, platform: data.platform })
        },
        onError: (err) => {
            
        }
      })
    
      const { mutate: getAdStatsMutation, isLoading: isGetAdStatsLoading } = useMutation(getAdStats, {
        onSuccess: (data) => {
            keywordTrackingDispatch({type: 'SET_ADDISTRIBUTION_LIST', value: data})
        },
        onError: (err) => {
            
        }
      })
    
      const { mutate: getTopDominsMutation, isLoading: isGetTopDominsLoading } = useMutation(getTopDomains, {
          onSuccess: (data) => {
              keywordTrackingDispatch({type: 'SET_TOPDOMAINS_LIST', value: data})
          },
          onError: (err) => {
              
          }
      })
    
      const { mutate: getHeatmapDataMutation, isLoading: isGetHeatmapDataLoading } = useMutation(getHeatmapData, {
        onSuccess: (data) => {
          keywordTrackingDispatch({type: 'SET_HEATMAP_DATA', value: data})
        },
        onError: (err) => {
            
        }
      })

    return {
        getTrackingKeywordsMutation,
        isGetTrackingKeywordsLoading,
        addTrackingKeywordMutation,
        isAddTrackingKeywordLoading,
        updateTrackingKeywordMutation,
        isUpdateTrackingKeywordLoading,
        getTrackingDetailsMutation,
        isGetTrackingDetailsLoading,
        getAdStatsMutation,
        isGetAdStatsLoading,
        getTopDominsMutation,
        isGetTopDominsLoading,
        getHeatmapDataMutation,
        isGetHeatmapDataLoading
    };
};
