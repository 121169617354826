
const { default: produce } = require("immer")
const { useReducer, createContext, useContext } = require("react")

const initialState = {
    projects: null,
    isPopupActive: false
}

const manageProjectsReducer = produce((draft, action) => {
    switch (action.type) {
        case "SET_PROJECTS":
            draft.projects = action.value
            break

        case "SET_POPUP_ACTIVE":

        draft.isPopupActive = action.value
            break

        default:
            break
    }
})

const useManageProjectsReducer = () => useReducer(manageProjectsReducer, initialState)

const ManageProjectsContext = createContext()

export const ManageProjectsProvider = ({ children }) => {
    const [state, dispatch] = useManageProjectsReducer()

    const value = {
        manageProjectsState: state,
        manageProjectsDispatch: dispatch
    }

    return (
        <ManageProjectsContext.Provider value={value} >
            {children}
        </ManageProjectsContext.Provider>
    )
}

export const useManageProjects = () => useContext(ManageProjectsContext)