import { applyStyle } from "@fullcalendar/core/internal";
import styled from "styled-components";
import { keyframes } from 'styled-components'

export const Container = styled.div`
  /* max-width: 996px; */
  width: 100%;
  border-radius: 8px;
  /* align-items: flex-end; */
`;

export const ResultsContainer = styled.div`
  display: flex;
  gap: 12px;
  height: calc(100vh - 245px);
  background: #006ecc00;
  position: absolute;
  left: 0;
  top: 0;
  .loader {
    height: 200px;
    margin: auto;
  }
`;

export const Header = styled.div`
  /* test design */
  /* display: flex;
    align-items: center;
    flex-direction: column; */

  padding: 28px 32px;
  background: #ffffff;
  h1 {
    font-size: 20px;
    color: #12558d;
    font-weight: 600;
    margin: 0;
  }
  h3 {
    font-size: 16px;
    font-weight: 300;
    color: #2e2e2e;
    margin-top: 4px;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  /* height: 62px; */
  /* margin-top: 32px; */

  /* test design */
  /* margin: 50px 0px; */
`;

export const Separator = styled.div`
  width: 1.5px;
  height: 15px;
  background-color: #d4d4d4;
  border-radius: 8px;

  /* margin: 0 8px; */
  margin: 36px 10px 10px 10px;
`;

export const Content = styled.div`
  padding: 28px 32px;
  background: #ffffff;
  margin-top: 36px;
  padding: 48px auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: auto;
  height: 65vh;

  ::-webkit-scrollbar-track {
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
    background-color: rgb(245 245 245 / 28%);
    border-radius: 10px;
  }

  ::-webkit-scrollbar {
    width: 5px;
    background-color: rgb(245 245 245 / 92%);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(0 0 0 / 7%);
    border-radius: 10px;
  }
`;

export const CreativeContainer = styled.div`
  display: flex;
  width: 800px;
  gap: 24px;
`;

export const CreativeLeft = styled.div`
  h2 {
    color: #595959;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
  }

  p {
    color: #666;
    font-size: 16px;
    font-weight: 300;
    line-height: normal;
  }

  img {
    width: 400px;
  }
`;

export const CreativeRight = styled.div`
  h2 {
    color: #595959;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
  }

  p {
    color: #666;
    font-size: 16px;
    font-weight: 300;
    line-height: normal;
  }

  img {
    width: 400px;
  }
`;

export const Wrapper = styled.div`
  max-width: 996px;
  border-radius: 16px;
  background: #fff;
  padding: 32px 40px;
  margin: 24px 24px 24px 200px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1700px) {
    margin: auto;
    margin-top: 24px;
  };
  ${(props) =>
    !props.isNavCollapsed
      ? `
          @media screen and (max-width: 1332px) {
              margin: 24px 35px 0 30px;
          }
        `
      : `
          @media screen and (max-width: 1147px) {
              margin: 24px 35px 0 30px;
          }
        `
    }
  
`;


export const HeadingWrapper = styled.div`
  display: flex;
  margin-bottom: 46px;
  column-gap: 100px ;
`;

export const HeadingLeft = styled.div`
  h3 {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #ff711ecc;
    margin-bottom: 24px;
  }
  h2 {
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    color: #1c1c1c;
    /* margin-bottom: 20px; */
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px; /* 190% */
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #1c1c1c80;
  }
`;

export const HeadLeftWrap = styled.div`
  display: flex;
  column-gap: 14px;
  align-items: center;
  margin-bottom: 16px;
`;

export const HowItWork = styled.div`
  width: 104px;
  height: 24px;
  border-radius: 16px;
  background: #f5f6fa;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8592a2;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
`;

export const ContentWrapper = styled.div`
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #dadfe4;
  background: #fff;
  box-shadow: -4px 6px 100px 0px rgba(0, 0, 0, 0.1);
  h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    color: #1c1c1c;
    margin-bottom: 10px;
  }
`;

export const Tips = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #1c1c1cb2;
  margin-bottom: 20px;
  span {
    margin-left: 8px;
    font-weight: 500;
    line-height: 22px;
    color: #1c1c1ccc;
  }
`;

export const HeaderRight = styled.div`
  /* padding: 10px 30px; */
  // border-radius: 10px;
  // background: #f7f7f7;
  img {
    width: 205px;
    height: 135px;
  }
`;

export const ExampleSearchContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 14px;
  h3 {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: #1c1c1c;
    margin-right: 6px;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #368cd5;
    cursor: pointer;
  }
`;

export const PlatformWrapper = styled.div`
  width: 100%;
  max-width: 678px;
  border-bottom: solid 1px #D0D5DD;
  margin-bottom: 24px;
`;


export const PlatformContainer = styled.div`
  display: flex;
`;

const breatheAnimation = keyframes`
 0% { width: 0%; opacity: 0}
 30% { width: 30%; opacity: 0.3 }
 40% { width: 40%; opacity: 0.4 }
 100% { width: 100%; opacity: 1 }
`

export const Platform = styled.div`
  padding: 6px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  gap: 6px;
  img{
    height: 20px;
  }

  p{
    height: 24px;
    display: ${props => props.platformSelected ? "flex": "none"};
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #FF711E;
    text-transform: capitalize;
  }

  .platform-name{
    animation-name: ${breatheAnimation};
    animation-duration: .3s;
  }

`;
 


export const ActiveBar = styled.div`
  display: ${props => props.platformSelected ? "flex": "none"};
  position: absolute;
  height: 3.5px;
  background: #FF711E;
  width: 100%;
  bottom: -2px;
  border-radius: 2px;
  left: 0px;
  animation-name: ${breatheAnimation};
  animation-duration: .3s;
`;