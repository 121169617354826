import React, {useState} from "react";
import {appClient} from '../../../../../../utils/axios-utils'
import {useNavigate} from 'react-router-dom'
import {useForm} from "react-hook-form"
import {useMutation} from "@tanstack/react-query"
import {
    ResetPasswordHeader,
    FormContainer,
    BackButton,
    EmailInputContainer,
    EmailInput,
    CtaContainer,
    SendOtpButton,
    Error,
    OtpError
} from './index.styled'
import toast from "react-hot-toast"
import WarningIcon from '../../../../../../media/Dashboard/warningicon.svg'

import { usePasswordChange } from './../../context/passwordChange'

const getVerificationCode = (data) => {
    const config = {
        url: "/auth/local/cp-email-verification-code",
        method: "POST",
        data
    }

    return appClient(config)
}

const GetOTP = () => {
    const [tempEmail, setTempEmail] = useState('')
    const [otpError, setOtpError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const { passwordChangeState, passwordChangeDispatch } = usePasswordChange()

    const {register, handleSubmit, formState: {
            errors
        }} = useForm()

    const navigate = useNavigate()

    let toastId;

    // localStorage.setItem('resetPasswordEmail',null)
    // localStorage.setItem('resetPasswordTimer',null)

    const {mutate: onSubmit} = useMutation(()=>{
        passwordChangeDispatch({type: 'SET_EMAIL', value: tempEmail})
        return getVerificationCode({email: tempEmail})
    }, {
        onSuccess: (data) => {
            passwordChangeDispatch({type: 'UPDATE_TIMER', value: Math.floor((new Date(data.tokenExpiresAt).getTime() - Date.now())/1000)})
            passwordChangeDispatch({type: 'SET_OTP_SENT', value: true})
            toast.dismiss(toastId)
        },
        onError: (err) => {
            // console.log(err)
            if(err.response.data.errors[0].tokenExpiresAt){
                passwordChangeDispatch({type: 'SET_OTP_SENT', value: true})
                passwordChangeDispatch({type: 'UPDATE_TIMER', value: Math.floor((new Date(err.response.data.errors[0].tokenExpiresAt).getTime() - Date.now())/1000)})
                toast.success("Email sent already!")
            }else{
                setOtpError(true)
                setErrorMessage(err.response.data.errors[0].message)
            }
            toast.dismiss(toastId)
        },
        onMutate: () => {
            toastId = toast.loading("Sending OTP...")
        }
    })

    // const onSubmit = () => {
    //     passwordChangeDispatch({type: 'SET_EMAIL', value: tempEmail})
    //     passwordChangeDispatch({type: 'SET_OTP_SENT', value: true})
    // }

    const handleEmailInput = (e) => {
        if(e.target.className.includes("otp-email")){
            setTempEmail(e.target.value)
        }
    }

    return <>
        <ResetPasswordHeader>
            <p>Reset your password</p>
        </ResetPasswordHeader>
        <FormContainer onChange={(e)=>handleEmailInput(e)}
            onSubmit={
                        handleSubmit(onSubmit)
                    }>
            <EmailInputContainer>
                <label>Email</label>
                <EmailInput // placeholder="Enter your email"
                    type="text"
                    error={errors.email}
                    className='otp-email'
                    {...register("email", { required: {
                    value: true, 
                    message: "Enter your email"
                } })}
                />
                {errors.email && <Error>
                        {errors.email.message}
                    </Error>}
            </EmailInputContainer>
            {otpError && <OtpError>
                <img src={WarningIcon}/> <p>{errorMessage}</p>
            </OtpError>}
            <CtaContainer>
                <SendOtpButton>Send OTP</SendOtpButton>
                <BackButton>
                    <a onClick={
                        () => {
                            navigate("/")
                        }
                    }>Back to login</a>
                </BackButton>
            </CtaContainer>
        </FormContainer>
    </>
}

export default GetOTP