import React, { useRef, useEffect, useState } from 'react'
import NoResultsLogo from "../../../../../../../media/Dashboard/noresults.svg"
import { useRequestData } from "../../../../../../hooks/useRequestData"

// Styles
import {
    AdCountries,
    AdDate,
    FooterLeft,
    CardFooter,
    CardBody,
    Card,
    CardsList,
    ResultsContainer,
    ResultsWraper,
    ImageContainer,
    NoResults,
    RequestDataBtn,
    HoverCard,
    HoverCardWrapper,
    AdDetailsButton,
    SaveAdsButton
} from "./index.styled"

import { useDisplay } from "../../context/display"
import SearchLoader from '../../../../../../HOC/SearchLoader'
import RightUpperBlackArrow from "../../../../../../../media/Dashboard/RightUpperBlackArrow.svg"


import CountryIcon from "../../../../../../../media/Dashboard/locationCard.svg"
import AltImage from '../../../../../../../media/Dashboard/altimage.png'
import LimitExceededPopup from '../../../../../../components/limitExceededPopup'
import TickImg from '../../../../../../../media/Dashboard/tick-grey.svg'
import AdDetails from '../../../../../../components/AdDetailsModal'

import PlatformRestrictedPopup from '../../../../../../components/platformRestrictionPopup'
import SaveAdsPopup from '../../../../../../components/SaveAdsPopup'

import { useAuth } from '../../../../../../../context/auth'
import { useSearch } from '../../../context/search'
import { useSaveAds } from '../../../../../../hooks/useSaveAds'
import colourCalender from '../../../../../../../media/Dashboard/colourCalender.svg'
const CardElement = ({ ad, idx, containerRef }) => {

    const { displayState, displayDispatch } = useDisplay()
    const { authState, authDispatch } = useAuth()
    const useSaveAdsHook = useSaveAds()

    const [height, setHeight] = useState('')
    const [width, setWidth] = useState('')

    const scrollToElement = (index) => {
        if (containerRef.current) {
            const element = containerRef.current.children[index];
            const scrollPosition = element.offsetTop - 20;
            containerRef.current.scrollTo({ top: scrollPosition, behavior: 'smooth' });
        }
    };

    const toggleDetailsModal = () => {
        // console.log("addd yt details")
        if ((displayState.adDetails.id !== ad.id) && displayState.detailsModal) {
            displayDispatch({ type: 'SET_AD_DETAILS', value: ad })
        } else {
            displayDispatch({ type: 'SET_AD_DETAILS', value: ad })
            displayDispatch({ type: 'UPDATE_DETAILS_MODAL', value: !displayState.detailsModal })
        }
        setTimeout(() => {
            scrollToElement(idx)
        }, 100);
    }
    const onRemoveSavedAd = (ad) => {
        useSaveAdsHook.RemoveSavedAdMutate({
            projectId: authState.defaultProjectInfo.projectId,
            adId:  `${ad.id}`,
            platform: "display"
        })
    }
    const convertTimestamp = (timestamp) => {
        // Create a new Date object based on the timestamp
        var date = new Date(timestamp);

        // Get the month name
        var monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        var month = monthNames[date.getMonth()];

        // Get the year
        var year = date.getFullYear();

        // Assemble the formatted date string
        var formattedDate = date.getDate() + ' ' + month + ' ' + year;

        return formattedDate;
    }


    const onSave = (ad) => {
        useSaveAdsHook.SaveAdMutate({
            projectId: authState.defaultProjectInfo.projectId,
            adId: `${ad.id}`,
            platform: "display"
        })
        // authDispatch({type:'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'display'})
        // authDispatch({type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id})
        // authDispatch({type:'UPDATE_SAVE_AD_POPUP', value: true})
    }

    useEffect(() => {
        const img = new Image();
        img.onload = function () {
            setTimeout(() => {
                setHeight(img.height)
                setWidth(img.width)
            }, 1000);
        }
        img.src = `https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${ad.id}`;
    }, [])

    return <Card detailsOpen={displayState.adDetails.id == ad.id} key={
        ad.id
    }>
         <HoverCard className='hover-element' onClick={() => toggleDetailsModal(ad, idx)}>
            <HoverCardWrapper>
                <AdDetailsButton onClick={() => toggleDetailsModal(ad, idx)}>
                    <img src={RightUpperBlackArrow} />
                    <span>Ad Details</span>
                </AdDetailsButton>
                <SaveAdsButton isSaved={authState.savedAdsIds.includes(ad.id)} onClick={(e) => {
                    e.stopPropagation();
                    if (authState.savedAdsIds.includes(ad.id)) {
                        onRemoveSavedAd(ad)
                    } else {
                        onSave(ad);
                    }

                }}>
                    {authState.savedAdsIds.includes(ad.id) ? "Saved" : "Save"}
                </SaveAdsButton>
            </HoverCardWrapper>
        </HoverCard>
        {/* <FloatingButtonsContainer>
            <Favorite isFav={idx == 1} onClick={() => onSave(ad)}>
                <img src={authState.favAdsList["display"]?.includes(ad.id) ? FavouriteIconActive : FavorietIcon} />
            </Favorite> */}
            {/* |
            <DropdownIcon>
                <span/>
            </DropdownIcon> */}
        {/* </FloatingButtonsContainer> */}
        <CardBody>
            <ImageContainer>
                {ad.image ? <img onLoad={(e) => {
                    // console.log(e.width,e.target.width, e.target.height)
                }} src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${ad.id}`} onError={() => { }} /> : ad.video ? <video src={`https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${ad.id}`} preload="auto" controls loop style={{ width: "100%", height: "100%" }} /> : <img src={AltImage} onError={() => { }} />}
                {/* <ImageResolution>
                    {height ? height : ""} x {width ? width : ""}
                </ImageResolution> */}
            </ImageContainer>
        </CardBody>
        <CardFooter>
            <FooterLeft>
                <AdDate>
                    {ad.firstSeen && ad.lastSeen && <img src={colourCalender} />}{convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
                </AdDate>
                {ad.country && <AdCountries>
                    <img src={CountryIcon} />{ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
                </AdCountries>}
            </FooterLeft>
            {/* <FooterRight>
                <DetailsButton onClick={toggleDetailsModal}>
                    More details
                </DetailsButton>
            </FooterRight> */}
        </CardFooter>
    </Card>
}

// const DetailsElement = () => {
//     // console.log("details")

//     const { displayState, displayDispatch } = useDisplay()

//     const [height, setHeight] = useState(0)
//     const [width, setWidth] = useState(0)

//     const convertTimestamp = (timestamp) => {
//         // Create a new Date object based on the timestamp
//         var date = new Date(timestamp);

//         // Get the month name
//         var monthNames = [
//             'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
//             'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
//         ];
//         var month = monthNames[date.getMonth()];

//         // Get the year
//         var year = date.getFullYear();

//         // Assemble the formatted date string
//         var formattedDate = date.getDate() + ' ' + month + ' ' + year;

//         return formattedDate;
//     }

//     useEffect(() => {
//         const img = new Image();
//         img.onload = function () {
//             setTimeout(() => {
//                 setHeight(img.height)
//                 setWidth(img.width)
//             }, 1000);
//         }
//         img.src = `https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${displayState.adDetails.id}`;
//     }, [])

//     return <DetailsContainer>
//         <DetailsHeader>
//             <h3>Ad details</h3> <CloseDetails onClick={() => {
//                 displayDispatch({ type: 'UPDATE_DETAILS_MODAL', value: false })
//                 displayDispatch({ type: 'SET_AD_DETAILS', value: {} })
//             }}><img src={CloseBtn} /></CloseDetails>
//         </DetailsHeader>
//         <AdDetailsBody>
//             <DetailsImageContainer>
//                 {displayState.adDetails.image ? <img src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${displayState.adDetails.id}`} onError={() => { }} /> : displayState.adDetails.video ? <video src={`https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${displayState.adDetails.id}`} preload="auto" controls loop style={{ width: "100%", height: "100%" }} /> : <img src={AltImage} onError={() => { }} />}
//                 {/* {<img onLoad={(e)=>{}} src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${displayState.adDetails.id}`} onError={() => {}}/> } */}
//             </DetailsImageContainer>
//             <DetailsHeadline>
//                 <label>Title:</label>{displayState.adDetails?.title && displayState.adDetails.title != "no title" && displayState.adDetails.title}
//             </DetailsHeadline>
//             <DestinationUrl>
//                 <label>Destination URL:</label>{displayState.adDetails?.url}
//             </DestinationUrl>
//             <MediaType>
//                 <label>Media Type:</label>{displayState.adDetails?.image ? "Image" : displayState.adDetails?.video ? "Video" : "-"}
//             </MediaType>
//             <MediaSize>
//                 <label>Media Size:</label>{height ? height : ""} x {width ? width : ""}
//             </MediaSize>
//             {displayState.adDetails.agency && <AgencyName>
//                 <label>Agency:</label>{displayState.adDetails?.agency[0]}
//             </AgencyName>}
//             <DateRange>
//                 <label>Date:</label>{convertTimestamp(displayState.adDetails.firstSeen)} - {convertTimestamp(displayState.adDetails.lastSeen)}
//             </DateRange>
//             {displayState.adDetails.country && <CountriesContainer>
//                 {displayState.adDetails.country && <><label>Country:</label><CountriesList>{displayState.adDetails.country.map((country, idx) => {
//                     return <span>{country}</span>
//                 })}</CountriesList>
//                 </>}
//             </CountriesContainer>}
//             {/* <LandingPageScreenShot>
//             <label>Landing page screenshot</label>
//             <ScreenshotContainer>
//                 <img src={ApplePage}/>
//             </ScreenshotContainer>
//         </LandingPageScreenShot> */}
//         </AdDetailsBody>
//     </DetailsContainer>
// }

const Results = () => {
    const containerRef = useRef(null);
    const useRequestDataHook = useRequestData()

    const { displayState, displayDispatch } = useDisplay()
    const { searchState, searchDispatch } = useSearch()
    const { authState, authDispatch } = useAuth()
    const useSaveAdsHook = useSaveAds()


    const onSave = (ad) => {
        useSaveAdsHook.SaveAdMutate({
            projectId: authState.defaultProjectInfo.projectId,
            adId: `${ad.id}`,
            platform: "display"
        })
        // authDispatch({ type: 'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'youtube' })
        // authDispatch({ type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id })
        // authDispatch({ type: 'UPDATE_SAVE_AD_POPUP', value: true })
    }
    const onDataRequest = () => {
        if (!useRequestDataHook.isDataRequested) {
            useRequestDataHook.requestDataMutate({ id: displayState.queryId })
        }
    }
    const onDetailsClose = () => {
        displayDispatch({ type: 'SET_AD_DETAILS', value: {} })
        displayDispatch({ type: 'UPDATE_DETAILS_MODAL', value: false })
    }
    if (authState.isRestrictedPopup) {
        return <ResultsContainer restricted={true} detailsModal={displayState.detailsModal}>
            <PlatformRestrictedPopup err={searchState.searchError} />
        </ResultsContainer>
    }

    if (searchState.limitExceeded) {
        return <ResultsContainer restricted={true} detailsModal={displayState.detailsModal}>
            <LimitExceededPopup err={searchState.searchError} />
        </ResultsContainer>
    }

    return <ResultsContainer resultLoading={displayState.loadingResults} noResults={displayState.noResults} detailsModal={displayState.detailsModal}>
        {displayState.loadingResults ? <div className='loader'>
            <SearchLoader />
        </div> : displayState.noResults ? <NoResults>
            <img src={NoResultsLogo} />
            <h2>No Results Found</h2>
            <p>We're sorry, but we don't currently have the data matching your search parameters. Our database is constantly updated, so please try again later or try modifying your search.</p>
            <RequestDataBtn
                // dataRequested={authState.isDataRequested}
                isDisabled={useRequestDataHook.isRequestDataLoading || authState.isDataRequested}
                onClick={onDataRequest}
            >
                {authState.isDataRequested ? <p>Your request for data crawl has been accepted<img src={TickImg} /></p> : <p>{useRequestDataHook.isRequestDataLoading ? "Requesting..." : "Request data crawl for this query"}</p>}
            </RequestDataBtn>
        </NoResults> :
            <ResultsWraper>
                {authState.saveAdsPopupOpen && <SaveAdsPopup />}
                <CardsList ref={containerRef} detailsModal={displayState.detailsModal}> {
                    displayState.ads && displayState.ads.length > 0 && displayState.ads.map((ad, idx) => {
                        return <CardElement containerRef={containerRef} ad={ad} idx={idx} />
                    })
                } </CardsList>
                  {
                        displayState.detailsModal  &&
                        <AdDetails
                            // navigateTo={navigateTo}
                            platform="display"
                            basicDetail={displayState.adDetails}
                            onDetailsClose={onDetailsClose}
                            highlightWords={displayState.highlightWords}
                            onSave={onSave}
                        />
                    }
                {/* {
                    displayState.detailsModal && <DetailsElement />
                } */}
            </ResultsWraper>}
    </ResultsContainer>
}

export default Results
