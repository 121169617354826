import styled from "styled-components";

export const SelectAdWrapper = styled.div``
export const SelectAdTitleWrap = styled.div`
display: flex;
padding: 40px 0 0 34px;
column-gap: 24px;
img{
    cursor: pointer;
}
`
export const SelectAdTitle = styled.div`
color: #000;
font-family: Inter;
font-size: 20px;
font-weight: 600;

`
export const SelectContentWrap = styled.div`
padding: 19px 0 0 82px;
`
export const SelectAdDescription = styled.div`
max-width: 958px;
color: #000;
font-family: Inter;
font-size: 14px;
font-weight: 300;
line-height: 150%;
margin-bottom: 18px;
a{
color: #1679DB;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%;
}
`
