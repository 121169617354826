import styled from "styled-components";

export const DropdownContainer = styled.div`
width: 100%;
padding: 16px;
height: 350px;
position: absolute;
top: 48px;
right: 0;
border-radius: 4px;
border: 1px solid #E3E3E3;
background: rgba(255, 255, 255);
z-index: 4;
overflow: auto;
`;

export const SearchBar = styled.input`
width: 78%;
padding: 8px;
border-radius: 4px;
border: 1px solid #E8E8E8;
color: #1C1C1C;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */

::focus{
outline: none;
border: 1px solid #E8E8E8;
}

::placeholder{
color: #9C9C9C;
}
`;

export const List = styled.div`
height: ${(props) => props.withoutSearchBar ? "264x" : "226px"};
overflow: auto;
margin: 16px 0;
`;

export const Divider = styled.hr`
border-top: 1px solid #E8E8E8;
margin: 13px 0;
`

export const Input = styled.input`
width: 20px;
accent-color: #FF711E;
color: #fff;
`;

export const Item = styled.div`
display: flex;
gap: 16px;
cursor: pointer;
padding: 5px 3px;
&:hover{
  background-color: rgb(243 243 243);
}
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #E3E3E3;
  background-color: #00000010;
}

input[type="checkbox"]:checked {
  background-color: #FF711E; /* Background color when checked */
  border: none;
}

input[type="checkbox"]:checked::before {
  content: '✓';
  display: block;
  text-align: center;
  color: #fff; /* Tick color */
  font-size: 16px;
  line-height: 20px;
}

`;

export const LangName = styled.div`
color: #1C1C1C;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
cursor: pointer;
`;

export const SubHeading = styled.label`
color: #1C1C1C;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
cursor: pointer;
`;

export const AddPersona = styled.div`
  width: 18%;
  height: 36px;
  background: #ff711e;
  border-radius: 4px;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;

export const SearchBarContainer = styled.div`
  display: flex;
  gap: 4px;
  justify-content: space-between;
`;

// export const 