import styled from "styled-components"

export const DayTrendWrapper = styled.div`
    margin-top: 16px;
    display: flex;
    column-gap: 16px;
    flex-wrap: wrap;
    row-gap: 12px;
    width: 966px;
    max-width: 100%;
    `
export const DayTrendContainer = styled.div`
    width: 475px;
    height: 356px;
    border-radius: 16px;
    background: #FFF;
    padding: 24px;
    max-width: 100%;
`
export const DayTrend = styled.div`
    color: #7D7D7D;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
        display: flex;
    align-items: center;
    gap: 10px;
`
export const GoogleTrendWrapper = styled.div`
    width: 475px;
    height: 356px;
    border-radius: 16px;
    background: #FFF;
    padding: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
h2{
    color: rgb(125, 125, 125);
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
        display: flex;
    align-items: center;
    gap: 10px;
}
`

export const PlatformContainer = styled.div`
    width: 100%;
    margin-top: 50px;
`;

export const AdProgressWrap = styled.div`
width: 50%;
height: 100%;
`

export const AdProgressDiv = styled.div`
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    height: 39px;
    -webkit-box-align: center;
    align-items: center;
    border-bottom: 1px solid rgb(227, 232, 238);
`
export const AdProgressName = styled.div`
  width: 50%;
  font-size: 14px;
  font-weight: 400;
  color: #1C1C1CCC;
`
export const AdProgressBar = styled.div`
display: flex;
column-gap: 16px;
width: 50%;
align-items: center;
`
export const AdProgressPercentage = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #1C1C1CB2;
  width: 24%;
`

export const GoogleProgress = styled.progress`
accent-color: ${props => props.color};
inline-size: 6.6em ;
height: 5px;
appearance: none;
border: none;
/* background-color: #ddd; */
border-radius: 5px;
/* accent-color: #FFC78A;
inline-size: 6.6em; */
&::-webkit-progress-bar {
    background-color: #ddd;
    border-radius: 10px;
    height: 8px;
}

&::-webkit-progress-value {
    background-color: ${props => props.color};
    border-radius: 10px;
    height: 8px;
}

&::-moz-progress-bar {
    /* background-color: #3498db; */
    border-radius: 10px;
    height: 8px;
}

&::-ms-fill {
    /* background-color: #3498db; */
    border-radius: 10px;
    height: 8px;
}

`
export const SkeletonWrap = styled.div`
margin-top: 50px;
width: 100%;
height: 200px;
`
export const AdGradeWrap = styled.div`
color: var(--Light-Green, #25CD25);
font-family: Inter;
font-size: 14px;
font-weight: 400;
display: flex;
`