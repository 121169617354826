import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom"

import { ModeContainer, KeywordButtonContainer, Container, Wrapper, Button, SearchContainer, InputContainer, Input, ToolTipImageContainer, ToolTip, BasicAnalysisContainer, 
    AudienceStatsContainer, CountryChartContainer, MapsContainer, MapsContainerBody, 
    PieChartContainer, PieChartAndTableContainer, TopKeywordsContainer, TopKeywordHeader, HeatMapContainer, SearchAdStatsContainer, 
    AdCopiesContainer, PlatformResults, ViewMoreAds, NoResultsMsg, FunnelContainer, AdDataContainer, FunnelList, FunnelOption, LeftWhiteBox, 
    RightWhiteBox, FunnelImg, ViewFunnelBtn, FunnelWrap, CloseFunnel, FunnelNavBar, FunnelNavItem, FunnelNavDivider, AdSpaceContainer, FunnelDomain } from "./index.styled";

import AvailablePlatforms from "./components/AvailablePlatforms";
import BlueRightArrow from '../../../../media/Dashboard/blue-right-arrow.svg'
import ToolTipLogo from "../../../../media/Dashboard/tooltip.svg"
import FunnelLogo from "../../../../media/Dashboard/funnel.svg"
import FunnelCloseLogo from "../../../../media/Dashboard/funnelClose.svg"
import RightFunnelFrame from "../../../../media/Dashboard/rightfunnelframe.svg"

import {
    useMutation
} from "@tanstack/react-query"

import CustomPieChart from "./components/PieChart"
import Table from "./components/PieChartTable"
import MapChartTable from "./components/MapChartTable";
import TopKeywords from "./components/TopKeywords"
import CampaignData from "./components/CampaignData"
import KeywordsTable from "./components/KeywordTable";

import AdcopyInputPopup from "./components/AdcopyInputPopup"

import MapChart from "./components/MapChart"
import { useUrlAnalysis } from "../context/urlanalysis";
import {useAuth} from "../../../../context/auth"

import ValidateAndAddHttps from "../../../../utils/urlValidate"

import {appClient} from '../../../../utils/axios-utils'
import HeatMapChart from "./components/HeatMapChart";
import AdCopies from "./components/AdsCopies";
import { useGetUserInfo } from './../../../hooks/useGetUserInfo'
import {Helmet} from "react-helmet"
import FunnelContent from "./components/FunnelContent";
import { getSearchParams, getDomainName } from "../../../../utils/helper.js"

const AdStat = async (data) => {
    const config = {
        url: `/aggs/url/${data.type}/adstat`,
        method: "POST",
        data
    }

    return appClient(config)
}

const geoAdStat = async (data) => {
    const config = {
        url: `/aggs/url/${data.type}/mapstat`,
        method: "POST",
        data
    }

    return appClient(config)
}

const KeywordAdStat = async (data) => {
    const config = {
        url: `/aggs/url/${data.type}/keywords`,
        method: "POST",
        data
    }

    return appClient(config)
}


const domainAnalysis = async (data) => {
    const config = {
        url: "/keyword/domain/analysis",
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordAnalysis = async (data) => {
    const config = {
        url: "/keyword/analysis",
        method: "POST",
        data
    }

    return appClient(config)
}

const HeatMapAnalysis = async (data) => {
    const config = {
        url: `/aggs/url/${data.type}/heatmap`,
        method: "POST",
        data
    }

    return appClient(config)
}

const FunnelAdsData = async (data) => {
    if(data.platform == "meta"){
        data.platform = "facebook"
    }

    const config = {
        url: `/intelligence/${data.platform}/ads`,
        method: "POST",
        data
    }

    return appClient(config)
}

const FunnelStatsData = async (data) => {
    if(data.platform == "meta"){
        data.platform = "facebook"
    }
    
    const config = {
        url: `/intelligence/${data.platform}/funnel-info`,
        method: "POST",
        data
    }

    return appClient(config)
}

const urlDomainFilterMatch = async (data) => {

    if(data.platform == "meta"){
        data.platform = "facebook"
    }else if(data.platform == "googlepla"){
        data.platform = "google-ecom"
    }else if(data.platform == "bingpla"){
        data.platform = "bing-ecom"
    }

    const config = {
        url: `/search/${data.platform}/url/domain?limit=6`,
        method: "POST",
        data: data.body
    }

    return appClient(config)
}

const urlExactFilterMatch = async (data) => {
    if(data.platform == "meta"){
        data.platform = "facebook"
    }else if(data.platform == "googlepla"){
        data.platform = "google-ecom"
    }else if(data.platform == "bingpla"){
        data.platform = "bing-ecom"
    }
    const config = {
        url: `/search/${data.platform}/url/exact?limit=6`,
        method: "POST",
        data: data.body
    }

    return appClient(config)
}

const FunnelOptions = ["Awareness","Interest","Desire","Action","Loyalty"]
const FunnelNavOptions = ["Ad Copies", "CTAs", "Target Audience", "Important Keywords"]

const KeywordAnalysisDetails = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const location = useLocation()
    const { authState, authDispatch } = useAuth()
    const [localUrl, setLocalUrl] = useState('')
    const [localSearchMode, setLocalSearchMode] = useState('domain')
    const userInfoHook = useGetUserInfo()

    const { urlAnalysisState, urlAnalysisDispatch } = useUrlAnalysis()

    const parseAdStatsData = (data) => {
        
    }

    const { mutate: getAdStat, isLoading: isStatLoading } = useMutation(AdStat, {
        onSuccess: (data) => {
            if(data){
                urlAnalysisDispatch({ type: 'SET_STATS', value: data})
            }else{
                urlAnalysisDispatch({ type: 'SET_STATS', value: {}})
            }
        },
        onError: (err) => {
            urlAnalysisDispatch({ type: 'SET_STATS', value: {

            }})

        },
        onMutate: ()=>{
            
        }
    })

    const { mutate: getGeoStat, isLoading: isGeoStatLoading } = useMutation(geoAdStat, {
        onSuccess: (data) => {
            if(data){
                urlAnalysisDispatch({ type: 'SET_GEO_STATS', value: data})
            }else{
                urlAnalysisDispatch({ type: 'SET_GEO_STATS', value: {}})
            }
        },
        onError: (err) => {
            urlAnalysisDispatch({ type: 'SET_GEO_STATS', value: {}})
        },
        onMutate: ()=>{
            
        }
    })

    const { mutate: getKeywordStat, isLoading: isKeywordStatLoading } = useMutation(KeywordAdStat, {
        onSuccess: (data) => {
            if(data){
                urlAnalysisDispatch({ type: 'SET_KEYWORD_STATS', value: data})
            }else{
                urlAnalysisDispatch({ type: 'SET_KEYWORD_STATS', value: []})
            }
        },
        onError: (err) => {
            
        },
        onMutate: ()=>{
            
        }
    })

    const { mutate: getDomainKeywords, isLoading: isDomainKeywordsLoading } = useMutation(domainAnalysis, {
        onSuccess: (data) => {
            if(data && data.length > 0){
                urlAnalysisDispatch({type: 'SET_DOMAIN_KEYWORDS', value: data})
                getKeywordsDetails({keywords: data})
            }else{
                urlAnalysisDispatch({type: 'SET_DOMAIN_KEYWORDS', value: []})
            }
        },
        onError: (err) => {
            
        }
    })

    const { mutate: getKeywordsDetails, isLoading: isKeywordsDetailsLoading } = useMutation(keywordAnalysis, {
        onSuccess: (data) => {
            if(data){
                urlAnalysisDispatch({type: 'SET_DOMAIN_KEYWORDS', value: data})
            }else{
                urlAnalysisDispatch({type: 'SET_DOMAIN_KEYWORDS', value: []})
            }
                
        },
        onError: (err) => {
            
        }
    })

    const { mutate: getHeatMapDetails, isLoading: isHeatMapDetailsLoading } = useMutation(HeatMapAnalysis, {
        onSuccess: (data) => {
            if(data){
                urlAnalysisDispatch({type: 'SET_HEATMAP_DATA', value: data})
            }else{
                urlAnalysisDispatch({type: 'SET_HEATMAP_DATA', value: []})
            }
        },
        onError: (err) => {
            
        }
    })

    const { mutate: urlDomainFilterMutate, isLoading: isUrlDomainFilterLoading } = useMutation(urlDomainFilterMatch, {
        onSuccess: (data) => {
            if(data.documents.length > 0){
                urlAnalysisDispatch({ type: "SET_AD_COPIES", value: data.documents })
                urlAnalysisDispatch({ type: "SET_EMPTY_RESULT_ID", value: data.queryId})
                userInfoHook.getUserPlanData()
            }else{
                urlAnalysisDispatch({ type: "SET_AD_COPIES", value: [] })
                urlAnalysisDispatch({ type: "SET_EMPTY_RESULT_ID", value: ''})
            }
        },
        onError: (err) => {

        }
    })

    const { mutate: urlExactFilterMatchMutate, isLoading: isUrlExactFilterLoading } = useMutation(urlExactFilterMatch, {
        onSuccess: (data) => {
            // console.log(data)
            urlAnalysisDispatch({ type: "SET_AD_COPIES", value: data.documents })
            urlAnalysisDispatch({ type: "SET_EMPTY_RESULT_ID", value: data.queryId})
            userInfoHook.getUserPlanData()
        },
        onError: (err) => {

        }
    })

    const { mutate: getFunnelAds, isLoading: isFunnelAdsLoading } = useMutation(FunnelAdsData, {    
        onSuccess: (data) => {
            if(data){
                // console.log(data)
                if(data.processed){
                    // console.log("inside 1")
                    urlAnalysisDispatch({type: 'SET_FUNNEL_ADS', value: data.ads})
                    if(Object.keys(urlAnalysisState.funnelAdsCount).length == 0){
                        urlAnalysisDispatch({type: 'SET_FUNNEL_ADS_COUNT', value: data.funnelCount})
                    }
                    getFunnelStats({
                        "query": ValidateAndAddHttps(urlAnalysisState.searchUrl),
                        "funnelType": urlAnalysisState.selectedFunnel.toLowerCase(),
                        "platform": urlAnalysisState.adCopyPlatform
                    })
                    urlAnalysisDispatch({type: 'SET_FUNNEL_ADS_LOADING', value: false})
                }else{
                    // console.log("inside 2")
                    setTimeout(() => {
                        urlAnalysisDispatch({type: 'SET_FUNNEL_ADS_LOADING', value: true})
        
                        getFunnelAds({
                            "query": ValidateAndAddHttps(urlAnalysisState.searchUrl),
                            "funnelType": urlAnalysisState.selectedFunnel.toLowerCase(),
                            "count": 300,
                            "platform": urlAnalysisState.adCopyPlatform
                        })
                        
                    
                    }, 30000);
                }
            }else{
                urlAnalysisDispatch({type: 'SET_FUNNEL_ADS', value: []})
                // urlAnalysisDispatch({type: 'SET_FUNNEL_ADS_COUNT', value: {}})
                urlAnalysisDispatch({type: 'SET_FUNNEL_ADS_LOADING', value: false})
            }    
        },
        onError: (err) => {
            
        }
    })

    const { mutate: getFunnelStats, isLoading: isFunnelStatsLoading } = useMutation(FunnelStatsData, {    
        onSuccess: (data) => {
            if(data){
                urlAnalysisDispatch({type: 'SET_FUNNEL_CTA_DATA', value: data.ctaText})
                urlAnalysisDispatch({type: 'SET_FUNNEL_KEYWORDS_DATA', value: data.keywords})
                urlAnalysisDispatch({type: 'SET_FUNNEL_TARGET_AUDIENCE_DATA', value: { 
                    demographicAudience: data.demographicAudience,
                    psychographicAudience: data.psychographicAudience
                }})
                urlAnalysisDispatch({type: 'SET_FUNNEL_STATS_LOADING', value: false})
                urlAnalysisDispatch({type: 'SET_INITIAL_FUNNEL_LOADING', value: false})

            }    
        },
        onError: (err) => {
            
        }
    })

    const handleSearch = () => {
        
        // console.log(urlAnalysisState.isValidUrl)

        if(urlAnalysisState.isValidUrl && ValidateAndAddHttps(localUrl)){
            urlAnalysisDispatch({type: 'SET_FUNNEL_ADS', value: []})
            urlAnalysisDispatch({type: 'SET_FUNNEL_ADS_COUNT', value: {}})
            urlAnalysisDispatch({type: 'SET_FUNNEL_CTA_DATA', value: []})
            urlAnalysisDispatch({type: 'SET_FUNNEL_KEYWORDS_DATA', value: []})
            urlAnalysisDispatch({type: 'SET_FUNNEL_TARGET_AUDIENCE_DATA', value: []})
            urlAnalysisDispatch({ type: 'SET_FUNNEL_OPEN', value: false})

            searchParams.set('url',ValidateAndAddHttps(localUrl))
            // searchParams.set('adCopyPlatform','google')
            searchParams.set('searchMode',localSearchMode)
            navigate(`/url-analysis/details?${searchParams.toString()}`)
        }else{
            urlAnalysisDispatch({type: 'SET_ERROR', value: true})
        }
    }

    const handleOnEnterSearch = (event) => {
        if (event.key === 'Enter') {
            if(urlAnalysisState.isValidUrl && ValidateAndAddHttps(localUrl)){
                urlAnalysisDispatch({type: 'SET_FUNNEL_ADS', value: []})
                urlAnalysisDispatch({type: 'SET_FUNNEL_ADS_COUNT', value: {}})
                urlAnalysisDispatch({type: 'SET_FUNNEL_CTA_DATA', value: []})
                urlAnalysisDispatch({type: 'SET_FUNNEL_KEYWORDS_DATA', value: []})
                urlAnalysisDispatch({type: 'SET_FUNNEL_TARGET_AUDIENCE_DATA', value: []})
                urlAnalysisDispatch({ type: 'SET_FUNNEL_OPEN', value: false})
                
                searchParams.set('url',ValidateAndAddHttps(localUrl))
                // searchParams.set('adCopyPlatform','google')
                searchParams.set('searchMode',localSearchMode)
                navigate(`/url-analysis/details?${searchParams.toString()}`)
            }else{
                urlAnalysisDispatch({type: 'SET_ERROR', value: true})
            }
        }else{
            urlAnalysisDispatch({ type: 'SET_AD_COPY_PLATFORM', value: ''})
        }
    }

    const getAddCopy = () => {
        if(urlAnalysisState.adCopyPlatform.length > 0){
            if(urlAnalysisState.searchMode == "path"){
                const reqBody = {
                    query: urlAnalysisState.searchUrl,
                    page: 1,
                    countries: [],
                    startDate: '',
                    endDate: '',
                    sort: 'latest'
                }
    
                urlExactFilterMatchMutate({body: reqBody, platform: urlAnalysisState.adCopyPlatform})

            }else if(urlAnalysisState.searchMode == "domain"){
                const reqBody = {
                    query: urlAnalysisState.searchUrl,
                    page: 1,
                    countries: [],
                    startDate: '',
                    endDate: '',
                    sort: 'latest'
                }

                urlDomainFilterMutate({body: reqBody, platform: urlAnalysisState.adCopyPlatform})
            }
        } 
    }

    useEffect(() => {
        const searchParamFromState = getSearchParams(location.search)
        const {
            url,
            searchMode
        } = searchParamFromState
        // if(!authState.features?.isKeywordAnalysisAllowed){
        //     authDispatch({type: 'UPDATE_IS_RESTRICTED', value: true})
        // }else{
            if(url){
                    setLocalUrl(url)
                    setLocalSearchMode(searchMode)
                    if(ValidateAndAddHttps(url)){
                        urlAnalysisDispatch({type: 'SET_SEARCH_MODE', value: searchMode})
                        urlAnalysisDispatch({type: 'SET_SEARCH_URL', value: url})
                        urlAnalysisDispatch({type: 'SET_DOMAIN_NAME', value: getDomainName(urlAnalysisState.searchUrl)})
                        getAdStat({query: url, type: searchMode})
                        getGeoStat({query: url, type: searchMode})
                        getKeywordStat({ query: url, type: searchMode})
                        getDomainKeywords({ query: url, page: 1, limit: 8 })
                        getHeatMapDetails({ query: url, type: searchMode })
                        urlAnalysisDispatch({ type: 'SET_AD_COPY_PLATFORM', value: 'google'})
                    }else{
                        urlAnalysisDispatch({type: 'SET_ERROR', value: true})
                    }
            }
        // }
    }, [location.search])
    
    // useEffect(() => {
    //     if(urlAnalysisState.funnelOpen){
    //         urlAnalysisDispatch({type: 'SET_FUNNEL_ADS_LOADING', value: true})
    //         urlAnalysisDispatch({type: 'SET_FUNNEL_STATS_LOADING', value: true})
    //         getFunnelAds({
    //             "query": urlAnalysisState.searchUrl,
    //             "funnelType": urlAnalysisState.selectedFunnel.toLowerCase(),
    //             "count": 100,
    //             "platform": urlAnalysisState.adCopyPlatform
    //         })
    //     }
    // }, [urlAnalysisState.funnelOpen])
    
    useEffect(() => {
        if(urlAnalysisState.searchUrl){
            urlAnalysisDispatch({type: 'SET_FUNNEL_ADS_LOADING', value: true})
            urlAnalysisDispatch({type: 'SET_FUNNEL_STATS_LOADING', value: true})
            getFunnelAds({
                "query": ValidateAndAddHttps(urlAnalysisState.searchUrl),
                "funnelType": urlAnalysisState.selectedFunnel.toLowerCase(),
                "count": 100,
                "platform": urlAnalysisState.adCopyPlatform
            })
        }
    }, [urlAnalysisState.selectedFunnel])
    
    return  <Container>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{localUrl}: URL/Domain Analysis | AdSpyder</title>
                    <meta name="title" content={`${localUrl} - URL/Domain Analysis | AdSpyder`} />
                    <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
                </Helmet>
                <SearchContainer>
                    <Wrapper>
                        <ModeContainer>
                            <KeywordButtonContainer 
                                active={localSearchMode === "path"} 
                                onClick={() => {setLocalSearchMode('path');urlAnalysisDispatch({ type: 'SET_AD_COPY_PLATFORM', value: ''})}}>
                                <input type="radio" checked={localSearchMode === "path" && "checked"} id="path" name="mode"/>
                                <label for="path">path</label>
                            </KeywordButtonContainer>
                            <KeywordButtonContainer 
                                active={localSearchMode === "domain"} 
                                onClick={() => {setLocalSearchMode('domain');urlAnalysisDispatch({ type: 'SET_AD_COPY_PLATFORM', value: ''})}}>
                                <input type="radio" checked={localSearchMode === "domain" && "checked"} id="domain" name="mode"/>
                                <label for="domain">domain</label>
                            </KeywordButtonContainer>
                        </ModeContainer>
                        <InputContainer>
                                <Input
                                    onKeyDown={handleOnEnterSearch}
                                    error={urlAnalysisState.error}
                                    name="domain-url-input"
                                    type="text"
                                    placeholder="Enter an URL" 
                                    autoComplete="off"
                                    value={localUrl}
                                    onChange={(e) => { 
                                        
                                        // const isTrueVal = urlPatternValidation(e.target.value)
                                        const isTrueVal = ValidateAndAddHttps(e.target.value)

                                        urlAnalysisDispatch({type: 'SET_ERROR', value: false})

                                        setLocalUrl(e.target.value)
                                        // urlAnalysisDispatch({type: 'SET_SEARCH_URL', value: e.target.value})

                                        urlAnalysisDispatch({type: 'SET_IS_VALID_URL', value: isTrueVal ? true : false})
                                    }}
                                /> 
                                <ToolTipImageContainer
                                    error={urlAnalysisState.error} className="tooltip-container"
                                    >
                                    <img src={ToolTipLogo}/>
                                    <ToolTip>
                                        Please enter a url
                                    </ToolTip>
                                </ToolTipImageContainer>
                        </InputContainer>
                    </Wrapper>
                    <Button onClick={handleSearch}>Search</Button>
                </SearchContainer>
                {urlAnalysisState.funnelAdCopyLimitPopup && <AdcopyInputPopup/>}
                <h1>Url Analysis {urlAnalysisState.mapdata.length == 1 && <NoResultsMsg>No results for this domain (you can request for data crawl by searching the url in adspy)</NoResultsMsg>}</h1>
                {/* <WebsiteBasic>
                    <WebsiteSnap>

                    </WebsiteSnap>
                    <WebsiteName>
                        <h2>
                            Apple
                        </h2>
                        <p>
                            {urlAnalysisState.domain}
                        </p>
                    </WebsiteName>
                </WebsiteBasic> */}
                <BasicAnalysisContainer>

                    <CampaignData isDataLoading={isStatLoading}/>

                    <PieChartContainer>
                        <h4> Ad distribution by platform </h4>
                        <PieChartAndTableContainer>
                            <CustomPieChart isDataLoading={isStatLoading}/>
                            <Table isDataLoading={isStatLoading}/>
                        </PieChartAndTableContainer>
                    </PieChartContainer>

                    <TopKeywordsContainer>
                        <TopKeywordHeader><h4>Top 5 Keywords</h4><h4>Occurrences</h4></TopKeywordHeader>
                        <TopKeywords isDataLoading={isKeywordStatLoading}/>
                    </TopKeywordsContainer>

                    <HeatMapContainer>
                        <h4>Day and Hour trend</h4>
                        <HeatMapChart isDataLoading={isHeatMapDetailsLoading}/>
                    </HeatMapContainer>

                    {/* <AreaChartContainer>
                        <h4>Average ad position</h4>
                        <SimpleAreaChart/>
                    </AreaChartContainer> */}
                </BasicAnalysisContainer>

                <AudienceStatsContainer>

                    <MapsContainer>
                        <h4>Ad distribution by country</h4>
                        <MapsContainerBody>
                            <CountryChartContainer>
                                <MapChart isDataLoading={isGeoStatLoading}/>
                            </CountryChartContainer>
                            <MapChartTable isDataLoading={isGeoStatLoading}/>
                        </MapsContainerBody>
                    </MapsContainer>
                    
                </AudienceStatsContainer>

                <SearchAdStatsContainer>
                    <h2>Search ad stats</h2>
                    <KeywordsTable isDataLoading={(isKeywordsDetailsLoading || isDomainKeywordsLoading)}/>
                </SearchAdStatsContainer>

                <AdCopiesContainer>
                    <h2>Ad copies</h2>
                    <AvailablePlatforms/> 
                    <PlatformResults>
                        <FunnelContainer funnelOpen={urlAnalysisState.funnelOpen}>
                            {urlAnalysisState.funnelOpen ? 
                            <>
                                <LeftWhiteBox src={RightFunnelFrame}/>
                                <FunnelList>
                                    {FunnelOptions.map((option)=>{
                                        return <FunnelOption disabled={urlAnalysisState.initialFunnelLoading} isActive={urlAnalysisState.selectedFunnel == option} onClick={()=>{
                                            // urlAnalysisDispatch({type: 'SET_INITIAL_FUNNEL_LOADING', value: true})
                                            urlAnalysisDispatch({ type: 'SET_SELECTED_FUNNEL', value: option})}}>
                                                {option} {urlAnalysisState.funnelAdsCount[option.toLocaleLowerCase()] && <span>{urlAnalysisState.funnelAdsCount[option.toLocaleLowerCase()]}</span>}
                                        </FunnelOption>
                                    })}
                                </FunnelList>
                                <RightWhiteBox src={RightFunnelFrame}/>
                                <CloseFunnel onClick={()=>urlAnalysisDispatch({ type: 'SET_FUNNEL_OPEN', value: false})}>
                                    x Close Funnel Analytics
                                </CloseFunnel>
                            </> 
                            : 
                            <FunnelWrap>
                                <FunnelImg src={urlAnalysisState.unFunnelSupportedPlatform ? FunnelCloseLogo : FunnelLogo}/>
                                <p>
                                    {urlAnalysisState.unFunnelSupportedPlatform ? "Funnel Analytics not available for this ad network" : "Click to view Funnel Analytics"}
                                </p>
                                {!urlAnalysisState.unFunnelSupportedPlatform && <ViewFunnelBtn onClick={()=>{
                                    urlAnalysisDispatch({type:'SET_FUNNEL_ADCOPY_LIMIT_POPUP', value: true});
                                    // urlAnalysisDispatch({ type: 'SET_FUNNEL_OPEN', value: true})
                                }}>
                                    View Funnel Analytics
                                </ViewFunnelBtn>}
                            </FunnelWrap>}
                        </FunnelContainer>
                        <AdSpaceContainer>
                            {urlAnalysisState.funnelOpen && <FunnelDomain>Funnel analytics: <span>{ValidateAndAddHttps(urlAnalysisState.searchUrl)}</span></FunnelDomain>}
                            <AdDataContainer>
                                {urlAnalysisState.funnelOpen ?  
                                <>
                                    <FunnelNavBar>
                                        {FunnelNavOptions.map((item, idx)=>{
                                            return <FunnelNavItem disabled={urlAnalysisState.initialFunnelLoading} onClick={()=>urlAnalysisDispatch({ type: 'SET_SELECTED_FUNNEL_SUB_FILTER', value: item})} isSelected={item == urlAnalysisState.selectedFunnelSubFilter}>
                                                {item} {idx != (FunnelNavOptions.length - 1) && <FunnelNavDivider isSelected={item == urlAnalysisState.selectedFunnelSubFilter}/>}
                                            </FunnelNavItem>
                                        })}
                                    </FunnelNavBar>
                                    <FunnelContent/>
                                </> :
                                    <>
                                        <h2>{urlAnalysisState.adCopyPlatform} Search ads</h2>
                                        <AdCopies isDataLoading={isUrlDomainFilterLoading || isUrlExactFilterLoading}/>
                                        <ViewMoreAds onClick={()=>{window.open(`https://app.adspyder.io/adspy/${urlAnalysisState.adCopyPlatform}?searchMode=url&urlSearchType=${urlAnalysisState.searchMode == 'path' ? 'exact' : urlAnalysisState.searchMode}&domainUrlInput=${urlAnalysisState.searchUrl}&page=1&sortType=relevant`)}}>View More Ads <img src={BlueRightArrow}/></ViewMoreAds>
                                    </>
                                }
                                
                            </AdDataContainer>
                        </AdSpaceContainer>
                    </PlatformResults>
                </AdCopiesContainer>
            </Container>
}

export default KeywordAnalysisDetails