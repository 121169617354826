import styled from "styled-components";


export const PersonaPageConatiner = styled.div`
  /* width:100%; */
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 24px 35px 0px 30px;
/* @media screen and (max-width: 1700px) {
  margin: auto;
  margin-top: 24px;
}; */
`;

export const PersonaIntroductionCard = styled.div`
width:100%;
border-radius: 20px;
background: #EFEEEE;
display:flex;
justify-content:center;
align-items:center;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
 padding: 5%;


  h2 {
    font-size: 24px;
    color: #1c1c1c;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 16px;
  }

  p {
    color: rgba(28, 28, 28, 0.5);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  button {
    background-color: #ff6b00;
    color: #fff;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    border-radius: 4px;
    cursor: pointer;
    align-self: center;
    margin-top: 24px;

    &:hover {
      background-color: #e65c00;
    }
  }
`;

