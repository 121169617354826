import styled from "styled-components";

export const ResultsContainer = styled.div`
    display: flex;
    gap: 12px;
    height: calc(100vh - 245px);
    background: #006ecc00;
    position: absolute;
    left: 0;
    top: 0;
    .loader{
        height: 200px;
        margin: auto;
    }
`;


