import styled from "styled-components";

export const PopupBackground = styled.div`
width: 100vw;
height: 100vh;
position: fixed;
top: 0;
left: 0;
background: rgba(28, 28, 28, 0.6);
z-index: 4;
`;

export const PopupContent = styled.div`
width: 750px;
height: 517px;
background: #FFF;
position: absolute;
top: 204px;
transform: translateX(-50%);
left: 50%;
border-radius: 6px;
`;

export const AddNewProjectContainer = styled.div`
width: 100%;
height: 100%;
flex-shrink: 0;
border-radius: 6px;
background: #FFF;
position: relative; 
padding-top: 32px;
`;

export const Title = styled.div`
color: #1C1C1C;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 26px; /* 144.444% */
margin-bottom: 8px;
text-align: center;
`;

export const Description = styled.div`
color: #737373;
text-align: center;
font-family: Nunito;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: 150%; /* 21px */
letter-spacing: 0.14px;
text-align: center;
`;

export const AddNewForm = styled.div`
margin-top: 40px;
margin-left: 148px;
`;

export const Field = styled.div`
margin-bottom: 32px;
`;

export const Label = styled.div`
color: #646464;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
margin-bottom: 8px;
`;

// export const InputContainer = styled.div`
// position: relative;
// width: 452px;
// height: 52px;
// `;

export const InputContainer = styled.div`
  border-radius: 0px 8px 8px 0px;
  border: ${(props) => props.hasError ? "1px solid #F04438" : props.isFocussed ? "1px solid #FFB258" : "1px solid #D0D5DD"};
  background: #FFF;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 81px);
  min-width: 195px;
  position: relative;
  padding: 0 14px;
  box-shadow: ${(props) => props.isFocussed ? "0px 1px 2px 0px rgba(28, 28, 28, 0.05), 0px 0px 4px 0px rgba(255, 178, 88, 0.25)" : "unset"};
`;

export const PlaceholderWithInput = styled.div`
width: 452px;
height: 44px;
display: flex;
// width: calc(100% - 95px - 125px - 32px);
`;
export const HttpsLabel = styled.div`
display: flex;
justify-content: center;
align-items: center;
border-radius: 8px 0px 0px 8px;
border-top: 1px solid #D0D5DD;
border-bottom: 1px solid #D0D5DD;
border-left: 1px solid #D0D5DD;
background: #F9FAFB;
color: rgba(28, 28, 28, 0.50);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
height: 44px;
width: 81px;
`;

export const ErrorBox = styled.div`
color: #F04438;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 21px */
letter-spacing: -0.14px;
margin-top: 8px;
display: flex;
justif-content: center;
`;
export const DomainInput = styled.input`
  color: rgba(28, 28, 28, 0.90);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  height: 100%;
  width: 100%;
  // border: 1px solid #D0D5DD;
  border: none;
  outline: none;
  // border-radius: 8px;
  caret-color: #FFB258;
  // :focus{
  //   border: 1px solid #FFB258;
  //   box-shadow: 0px 1px 2px 0px rgba(28, 28, 28, 0.05), 0px 0px 4px 0px rgba(255, 178, 88, 0.25);
  // }
  ::placeholder{
    color: rgba(28, 28, 28, 0.50);
  }
`;
export const RightIcons = styled.div`
  display: flex;
  gap: 8px;
`;
export const ErrorIcon = styled.img``

export const TrackButton = styled.button`
  border-radius: 6px;
  background: #FF711E;
  height: 44px;
  color: #FFF;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  width: 95px;
  :hover {
    filter: brightness(90%);
  }
`;


export const Create = styled.div`
margin-top: 64px;
width: 155px;
height: 44px;
border-radius: 4px;
background: #FF711E;
display: flex;
align-items: center;
justify-content: center;
margin-left: 298px;
color: #FFF;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 24px */
cursor: pointer;
`;

export const Close = styled.img`
position: absolute;
top: 16px;
right: 16px;
cursor: pointer;
`;