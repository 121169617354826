import axios from "axios";
import { customToast } from "./toast-utils";

import _static from "../config/static";

const { baseUrl, lighthouseBaseUrl } = _static;

/* Common error handler for both clients */
const errorHandler = (err) => {
  console.log("errrr", err);

  if(err.response.status == 429){
    customToast({
      type: "error",
      message: err?.response?.data?.errors[0].message,
    });
  }

  
  if (
    err?.config?.url?.includes("/auth/local/current-user") &&
    err?.code === "ERR_NETWORK"
  ) {
    return Promise.reject(err);
  }

  if (err?.config?.url?.includes("/local/email-verification-code")) {
    return Promise.reject(err);
  }

  if (err?.config?.url?.includes("/local/phone-verification-code")) {
    return Promise.reject(err);
  }

  if (err?.config?.url?.includes("/local/phone-verify-code")) {
    return Promise.reject(err);
  }

  if (err?.config?.url?.includes("/local/email-verify-code")) {
    return Promise.reject(err);
  }

  if (err?.config?.url?.includes("/local/cp-email-verification-code")) {
    return Promise.reject(err);
  }

  if (err?.config?.url?.includes("/auth/local/login")) {
    return Promise.reject(err);
  }

  if (err?.config?.url?.includes("/auth/local/register")) {
    return Promise.reject(err);
  }

  if (err?.config?.url?.includes("/search")) {
    return Promise.reject(err);
  }

  if (err?.config?.url?.includes("/detail/")) {
    return Promise.reject(err);
  }

  if(err?.config?.url?.includes("/landing/individual-snapshot")){
    return Promise.reject(err);
  }

  if (err.code === "ERR_NETWORK") {
    customToast({
      type: "error",
      message: "Connection lost",
    });
    return Promise.reject(err);
  }

  if (err?.response?.status === 401) {
    return Promise.reject(err);
  }

  let errors = err?.response?.data?.errors;

  errors.forEach((errObj) => {
    customToast({
      type: "error",
      message: errObj?.message || "Something went wrong",
    });
  });

  return Promise.reject(err);
};

const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
// Send this `clientTimeZone` value to your Node.js server.

/* App client */
export const appClient = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    'client-time-zone': clientTimeZone,
  },
  withCredentials: true,
});

appClient.defaults.headers.common["Cache-Control"] = "no-cache";
appClient.defaults.headers.common["Pragma"] = "no-cache";

appClient.interceptors.response.use((response) => {
  return response.data;
}, errorHandler);

/* lightHouse client */
export const lighthouseClient = axios.create({
  baseURL: lighthouseBaseUrl,
});

lighthouseClient.interceptors.response.use((response) => {
  return response.data;
}, errorHandler);
