import styled from "styled-components";

export const AdCopiesWrapper = styled.div`
display: flex;
justify-content: center;
`
export const AdCopiesContainer = styled.div`
width: 966px;
border-radius: 12px 12px 0px 0px;
background: linear-gradient(180deg, #FFF7E8 0%, rgba(255, 247, 232, 0.20) 100%);
max-height: 700px;
    min-height: 450px;
`
export const AdCopiesDiv = styled.div`
height: 68px;
padding 24px;
display: flex;
justify-content: space-between;
border-radius: 12px 12px 0px 0px;
background: #FFF7E8;
`
export const AdCopiesName = styled.div`
color: rgba(115, 74, 0, 0.80);
font-family: Inter;
font-size: 16px;
font-weight: 500;
`
export const FilterWrap = styled.div`
display: flex;
align-items: center;
column-gap: 8px;
`
export const ToggleArrowWrap = styled.div`
border-radius: 6px;
width: 46px;
height: 40px;
background: #FF711E;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
`