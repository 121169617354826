import React, { useState, useEffect, useRef } from 'react'
import { Container, Down, Row1, Row, Drop } from './index.styled'

import DropDownArrow from '../../../../../../../media/Dashboard/drop-down-arrow.svg'

import { useAdGenrator } from "../../../../context/adgenerator";

function useOutsideAlerter(ref, setShow) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setShow(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref])
}

// const LanguageList = [
//     {
//         name: "English",
//     },{
//         name: "Tamil",
//     },{
//         name: "German",
//     }
// ]

const LanguageDropDown = () => {
    const {adGenratorState, adGenratorDispatch} = useAdGenrator()
    const [languageInput, setLanguageInput] = useState("")
    const [languageList, setLanguageList] = useState([
        {
            "code": "albanian",
            "name": "Albanian"
        },
        {
            "code": "arabic",
            "name": "Arabic"
        },
        {
            "code": "armenian",
            "name": "Armenian"
        },
        {
            "code": "awadhi",
            "name": "Awadhi"
        },
        {
            "code": "azerbaijani",
            "name": "Azerbaijani"
        },
        {
            "code": "bashkir",
            "name": "Bashkir"
        },
        {
            "code": "basque",
            "name": "Basque"
        },
        {
            "code": "belarusian",
            "name": "Belarusian"
        },
        {
            "code": "bengali",
            "name": "Bengali"
        },
        {
            "code": "bhojpuri",
            "name": "Bhojpuri"
        },
        {
            "code": "bosnian",
            "name": "Bosnian"
        },
        {
            "code": "brazilian portuguese",
            "name": "Brazilian portuguese"
        },
        {
            "code": "bulgarian",
            "name": "Bulgarian"
        },
        {
            "code": "cantonese",
            "name": "Cantonese"
        },
        {
            "code": "catalan",
            "name": "Catalan"
        },
        {
            "code": "chhattisgarhi",
            "name": "Chhattisgarhi"
        },
        {
            "code": "chinese",
            "name": "Chinese"
        },
        {
            "code": "croatian",
            "name": "Croatian"
        },
        {
            "code": "czech",
            "name": "Czech"
        },
        {
            "code": "danish",
            "name": "Danish"
        },
        {
            "code": "dogri",
            "name": "Dogri"
        },
        {
            "code": "dutch",
            "name": "Dutch"
        },
        {
            "code": "english",
            "name": "English"
        },
        {
            "code": "estonian",
            "name": "Estonian"
        },
        {
            "code": "faroese",
            "name": "Faroese"
        },
        {
            "code": "finnish",
            "name": "Finnish"
        },
        {
            "code": "french",
            "name": "French"
        },
        {
            "code": "galician",
            "name": "Galician"
        },
        {
            "code": "georgian",
            "name": "Georgian"
        },
        {
            "code": "german",
            "name": "German"
        },
        {
            "code": "greek",
            "name": "Greek"
        },
        {
            "code": "gujarati",
            "name": "Gujarati"
        },
        {
            "code": "haryanvi",
            "name": "Haryanvi"
        },
        {
            "code": "hindi",
            "name": "Hindi"
        },
        {
            "code": "hungarian",
            "name": "Hungarian"
        },
        {
            "code": "indonesian",
            "name": "Indonesian"
        },
        {
            "code": "irish",
            "name": "Irish"
        },
        {
            "code": "italian",
            "name": "Italian"
        },
        {
            "code": "japanese",
            "name": "Japanese"
        },
        {
            "code": "javanese",
            "name": "Javanese"
        },
        {
            "code": "kannada",
            "name": "Kannada"
        },
        {
            "code": "kashmiri",
            "name": "Kashmiri"
        },
        {
            "code": "kazakh",
            "name": "Kazakh"
        },
        {
            "code": "konkani",
            "name": "Konkani"
        },
        {
            "code": "korean",
            "name": "Korean"
        },
        {
            "code": "kyrgyz",
            "name": "Kyrgyz"
        },
        {
            "code": "latvian",
            "name": "Latvian"
        },
        {
            "code": "lithuanian",
            "name": "Lithuanian"
        },
        {
            "code": "macedonian",
            "name": "Macedonian"
        },
        {
            "code": "maithili",
            "name": "Maithili"
        },
        {
            "code": "malay",
            "name": "Malay"
        },
        {
            "code": "maltese",
            "name": "Maltese"
        },
        {
            "code": "mandarin",
            "name": "Mandarin"
        },
        {
            "code": "mandarin chinese",
            "name": "Mandarin chinese"
        },
        {
            "code": "marathi",
            "name": "Marathi"
        },
        {
            "code": "marwari",
            "name": "Marwari"
        },
        {
            "code": "min nan",
            "name": "Min nan"
        },
        {
            "code": "moldovan",
            "name": "Moldovan"
        },
        {
            "code": "mongolian",
            "name": "Mongolian"
        },
        {
            "code": "montenegrin",
            "name": "Montenegrin"
        },
        {
            "code": "nepali",
            "name": "Nepali"
        },
        {
            "code": "norwegian",
            "name": "Norwegian"
        },
        {
            "code": "oriya",
            "name": "Oriya"
        },
        {
            "code": "pashto",
            "name": "Pashto"
        },
        {
            "code": "persian",
            "name": "Persian"
        },
        {
            "code": "polish",
            "name": "Polish"
        },
        {
            "code": "portuguese",
            "name": "Portuguese"
        },
        {
            "code": "punjabi",
            "name": "Punjabi"
        },
        {
            "code": "rajasthani",
            "name": "Rajasthani"
        },
        {
            "code": "romanian",
            "name": "Romanian"
        },
        {
            "code": "russian",
            "name": "Russian"
        },
        {
            "code": "sanskrit",
            "name": "Sanskrit"
        },
        {
            "code": "santali",
            "name": "Santali"
        },
        {
            "code": "serbian",
            "name": "Serbian"
        },
        {
            "code": "sindhi",
            "name": "Sindhi"
        },
        {
            "code": "sinhala",
            "name": "Sinhala"
        },
        {
            "code": "slovak",
            "name": "Slovak"
        },
        {
            "code": "slovene",
            "name": "Slovene"
        },
        {
            "code": "slovenian",
            "name": "Slovenian"
        },
        {
            "code": "spanish",
            "name": "Spanish"
        },
        {
            "code": "swahili",
            "name": "Swahili"
        },
        {
            "code": "swedish",
            "name": "Swedish"
        },
        {
            "code": "tajik",
            "name": "Tajik"
        },
        {
            "code": "tamil",
            "name": "Tamil"
        },
        {
            "code": "tatar",
            "name": "Tatar"
        },
        {
            "code": "telugu",
            "name": "Telugu"
        },
        {
            "code": "thai",
            "name": "Thai"
        },
        {
            "code": "turkish",
            "name": "Turkish"
        },
        {
            "code": "turkmen",
            "name": "Turkmen"
        },
        {
            "code": "ukrainian",
            "name": "Ukrainian"
        },
        {
            "code": "urdu",
            "name": "Urdu"
        },
        {
            "code": "uzbek",
            "name": "Uzbek"
        },
        {
            "code": "vietnamese",
            "name": "Vietnamese"
        },
        {
            "code": "welsh",
            "name": "Welsh"
        },
        {
            "code": "wu",
            "name": "Wu"
        }
    ])

    const [filteredLanduageList, setFilteredLanguageList] = useState([])

    const filterLanguages = (text) => {
        setLanguageInput(text)
        if(text.length > 0){
            let filteredLanguage = languageList.filter((lan)=>{
                return lan.code.toLocaleLowerCase().includes(text.toLocaleLowerCase())
            })
    
            setFilteredLanguageList(filteredLanguage)
        }else{
            setFilteredLanguageList([])
        }

    }

    const wrapperRef = useRef(null)
    const [show, setShow] = useState(false)

    useOutsideAlerter(wrapperRef, setShow)

    return <Container ref={wrapperRef}>

        <Drop onClick={() => { !show && setShow(prev => !prev) }}>
            {!show && <span>{adGenratorState.selectedLanguage ? adGenratorState.selectedLanguage : "Language"}</span>}
            {show && <input className='language-input' placeholder='search' type="text" onChange={(e)=>filterLanguages(e.target.value)}/>}
            <img src={DropDownArrow} />
        </Drop>

        <Down show={show}>
            {/* <Row1>
                <p>Description Framework</p>
            </Row1> */}

            {languageInput.length > 0 ? filteredLanduageList.length > 0 ? filteredLanduageList.map((platform, index) => {
                    return <Row key={index}
                        onClick={() => {
                            // console.log(platform)
                            adGenratorDispatch({type:'SET_SELECTED_LANGUAGE', value: platform.code})
                            setShow(false)
                        }}>
                            <p>{platform.name}</p>
                        </Row>
                })
            : <Row>
                <p>no result</p>
            </Row> : languageList.map((platform, index) => {
                return <Row key={index}
                    onClick={() => {
                        // console.log(platform)
                        adGenratorDispatch({type:'SET_SELECTED_LANGUAGE', value: platform.code})
                        setShow(false)
                    }}>
                        <p>{platform.name}</p>
                    </Row>
            })
            }
            </Down>
        </Container>
    }

export default LanguageDropDown