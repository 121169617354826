import React from 'react'
import { Route, Routes} from 'react-router-dom'

import {Helmet} from "react-helmet"
import Layout from "../../layout"

import { useAuth } from '../../../context/auth'
import NotFound from '../NotFoundPage';
import TextAd from './TextAd';
import ImageAd from './ImageAd';

const AdGenerationPage = () => {
    return <Routes>
            <Route path='*' element={
                    <NotFound />
            } />
             <Route path="text-ad/*" element={
                <Layout>
                    <TextAd />
                </Layout>
            } />
            <Route path="/image-ad/*" element={
                <Layout>
                    <ImageAd />
                </Layout>
            } />
        </Routes>
    
}

const AdGenerationWrapper = () => {
    const {authState, authDispatch} = useAuth()

    return <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ad Generator | AdSpyder</title>
                <meta name="title" content="Ad Generator | AdSpyder" />
                <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
            </Helmet>
            <AdGenerationPage/>
    </>
}

export default AdGenerationWrapper