import React, { useState, useEffect, useRef } from 'react'
import { Container, Down, Row, Drop, Wrapper, ToolTipImageContainer, ToolTip } from './index.styled'
import { useNavigate } from 'react-router-dom'

import DropDownArrow from '../../../../../../../../../media/Dashboard/DropDownArrowGrey.svg'
import ToolTipLogo from '../../../../../../../../../media/Dashboard/tooltip.svg'



function useOutsideAlerter(ref, setShow) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setShow(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref])
}

const PlatformList = [
    "google",
    "bing",
    // "youtube",
    // "meta",
    // "linkedin",
    // "reddit",
    // "googlepla",
    // "bingpla",
    // "flipkart",
    // "amazon",
    // "display"
]


const PlatformDropdown = ({keywordData, updateKeywordPlatform , countryIndex}) => {
    const wrapperRef = useRef(null)
    const [show, setShow] = useState(false)
    const [fixedElementPosition, setFixedElementPosition] = useState({ top: 0, left: 0, right: 0, bottom: 0 });

    const navigate = useNavigate()
    const targetRef = useRef(null)
    useOutsideAlerter(wrapperRef, setShow)
    
    
let updatePosition = () => {
    if (targetRef.current) {
        // console.log(targetRef,'targetRef.current')
      const rect = targetRef.current.getBoundingClientRect();
    //   console.log(rect,'rectrect')
      setFixedElementPosition({
        top: rect.top,
        left: rect.left,
        right: rect.right,
        bottom: rect.bottom,
      });

    }
  };


useEffect(() => {
  // Initial position update
  updatePosition();

  // Listen for resize or scroll events if the position might change dynamically
  window.addEventListener('resize', updatePosition);
  window.addEventListener('scroll', updatePosition);

  return () => {
    window.removeEventListener('resize', updatePosition);
    window.removeEventListener('scroll', updatePosition);
  };
}, []); // R

    return <Wrapper>
        <Container platformSelected={keywordData.platform} error={!keywordData.platform} ref={targetRef} >
        
        <Drop error={keywordData.platformError}  onClick={() => { 
            setShow(prev => !prev)
            updatePosition();
        }}>
            {keywordData.platform ?             
                <span><p>{keywordData.platform}</p></span>
            : <p>Platform</p>}  
            <img className='dropdown-arrow' src={DropDownArrow} />
            <ToolTipImageContainer
                error={keywordData.platformError}
                className="tooltip-container">
                <img src={ToolTipLogo}/>
                <ToolTip>
                    Please select a platform
                </ToolTip>
            </ToolTipImageContainer>
        </Drop>
        </Container>
        <Down show={show} height={30} left={fixedElementPosition.left - 13} top={fixedElementPosition.top+52}>
            {PlatformList.map((data, index) => {
                return <Row key={index}
                    onClick={() => {
                        updateKeywordPlatform(countryIndex,data)
                        setShow(false)
                    }}
                >
                    <p>{data}</p>
                </Row>
                })}
            </Down>
    </Wrapper>
   
    }

export default PlatformDropdown

