import styled from "styled-components";

export const Container = styled.div`
    width: 90%;
    max-width: 1220px;
    margin: auto;

    margin-top: 32px;
    border-radius: 8px;
    align-items: flex-end;
`;

export const Header = styled.div`
    padding: 28px 32px;
    background: #FFFFFF;
    h1{
        font-size: 20px;
        color: #12558D;
        font-weight: 600;
        margin: 0;
        line-height: 30px;
    }
    h3{
        font-size: 16px;
        font-weight: 300;
        color: #6B777E;
        margin-top: 4px;
        line-height: 24px;
    }
`;

export const Content = styled.div`
    /* padding: 28px 32px; */
    background: #FFFFFF;
    margin-top: 36px;
    padding: 48px auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;

    display: flex;
    flex-direction: column;
    align-items: center;
    /* overflow-x: auto; */
    /* height: 65vh; */

    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }

`;

export const CollectionContainer = styled.div`
    width: 100%;

   
`;

export const CollectionListContainer = styled.div`
    height: 56vh;
    overflow-y: auto;
    .loader{
        margin-top: 200px;
    }
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const CollectionList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    padding: 30px 32px;
    h3{
        color: #888;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }
`;

export const Project = styled.div`
    width: 260px;
    display: flex;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #ECECEC;
    align-items: center;
    cursor: pointer;
    img{
        margin-right: 20px;
    }
`;

export const ProjectContent = styled.div`
    h3{
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #535455;
    }
    span{
        font-size: 14px;
        line-height: 20px;
        color: #A4A4A4;
        font-weight: 400;
    }
`;

export const BreadCrumb = styled.div`
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    /* margin-bottom: 14px; */

    padding: 30px 32px 18px;
    color: rgb(46, 46, 46);

    display: flex;
    align-items: center;

    h2{
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        /* padding: 30px 32px 18px; */
        color: #2E2E2E;
        margin-right: 8px;
        cursor: pointer;
        /* border-bottom: 1px solid rgb(234 234 234 / 61%); */
    }

    span{
        margin: 3px;
        color: #828282;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
    }
`;

export const AdsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    /* height: 70vh;
    overflow-y: auto; */
    padding: 30px 32px;
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }

`;

export const ContentHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(234, 234, 234, 0.61);
`;

export const LoadButton = styled.div`
    border-radius: 8px;
    background: ${props => props.isDisabled ? "#CDCDCD" : "#FF711E"};
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    padding: 8px 16px;
    color: white;
    margin-right: 32px;
    cursor: pointer;
    pointer-events: ${props => props.isDisabled ? "none" : "auto"};
`;

export const ResultsContainer = styled.div`
    display: flex;
    gap: 12px;
    height: calc(100vh - 245px);
    background: #006ecc00;
    position: absolute;
    left: 0;
    top: 0;
    .loader{
        height: 200px;
        margin: auto;
    }
`;