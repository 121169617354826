import styled from "styled-components";

export const ContentHeading = styled.h2`
    color: #2E2E2E;
    font-size: 18px;
    line-height: normal;
    font-weight: 600;
    margin-bottom: 15px;
`;

export const HistoryContainer = styled.div`
    max-width: 910px;
    width: 100%; 
`;

export const TableContainer = styled.div`

`;

export const TableHeader = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #747474;
    font-weight: 300;
    line-height: normal;
    border-radius: 16px 16px 0px 0px;
    border-top: 1px solid #DDE0E9;
    border-right: 1px solid #DDE0E9;
    border-left: 1px solid #DDE0E9;
    background: #F8F9FB;
    height: 60px;
    
    img{
        height: 16px;
        margin-left: 5px;
    }
`;

export const QueryHeader = styled.div`
    padding: 9px 0px 9px 0px;
    width: 40%;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const TimeHeader = styled.div`
    padding: 9px 20px 9px 20px;
    width: 30%;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const PlatformHeader = styled.div`
    padding: 9px 0px 9px 0px;
    width: 15%;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const AdsHeader = styled.div`
    padding: 9px 20px 9px 20px;
    border-radius: 0px 8px 0px 0px;
    /* border: 1px solid #E2E2E2;
    background: #E2E2E2; */
    width: 15%;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const TableBody = styled.div`
    overflow-y: auto;
    height: calc(-330px + 100vh);
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    border: 1px solid #DDE0E9;
    background: #FFF;
    height: 61px;

    font-size: 14px;
    font-weight: 300;
    line-height: normal;

    cursor: pointer;    
    &:hover{
        /* background: #F6F6F6; */
        .search-now-btn{
            display: flex;
        }
        .ads-data{
            padding: 3px 20px;
            /* justify-content: space-between; */
        }
    }

    &:active{
        background: #F2F2F2;
    }
`;


export const QueryData = styled.div`
    padding: 9px 0px 9px 0px;
    width: 40%;
    overflow: hidden;
    color: #434343;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
`;

export const TimeData = styled.div`
    padding: 9px 20px 9px 20px;
    width: 30%;
    display: flex;
    align-items: center;
`;

export const PlatformData = styled.div`
    padding: 9px 0px 9px 0px;
    width: 15%;
    display: flex;
    align-items: center;
`;

export const AdsData = styled.div`
    padding: 9px 20px 9px 20px;
    /* border-right: 1px solid #ECECEC;
    border-bottom: 1px solid #ECECEC;
    background: #F6F6F6; */
    width: 15%;
    text-align: end;
    display: flex;
    justify-content: end;
    align-items: center;
    display: flex;
    align-items: center;
`;

export const SearchNow = styled.div`
    cursor: pointer;
    width: fit-content;
    display: none;
    img{
        height: 20px;
        width: 20px;
    }
`;