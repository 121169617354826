import { useImageAdGenerator } from "../../../../context/imageAdGeneration";
import { Cancel, FooterContainer, Save } from "./Footer.styled";

function Footer({popupSelectedGoal, newlySelectedCountry, newDescription}) {
    const { imageAdGeneratorState, imageAdGeneratorDispatch } = useImageAdGenerator()


    const onCancel = () => {
        imageAdGeneratorDispatch({
            type: "UPDATE_ACTIVE_CUSTOMIZE_POPUP",
            value: null
        })
    }
    const saveCategory = () => {
        if(imageAdGeneratorState.category)
        {
            imageAdGeneratorDispatch({type: "SET_CATEGORY", value: imageAdGeneratorState.category})
            imageAdGeneratorDispatch({type: "SET_AD_DETAILS_UPDATED", value: true})
            imageAdGeneratorDispatch({type: "AD_CATEGORY_UPDATE", value: true})

        }
    }
    const saveSelectedGoal = () => {
        if(imageAdGeneratorState.adGoal)
        {
            imageAdGeneratorDispatch({type: "SET_AD_GOAL", value: imageAdGeneratorState.adGoal})
            imageAdGeneratorDispatch({type: "SET_AD_DETAILS_UPDATED", value: true})
        }
    }

    const saveNewTargetAudience = () => {
        if(imageAdGeneratorState.targetAudience){
            imageAdGeneratorDispatch({type: "SET_TARGET_AUDIENCE", value: imageAdGeneratorState.targetAudience})
            imageAdGeneratorDispatch({type: "SET_AD_DETAILS_UPDATED", value: true})
        }
    }

    const saveNewDescription = () => {
        if(imageAdGeneratorState.brandDetails){
            imageAdGeneratorDispatch({type: "SET_BRAND_DETAILS", value: imageAdGeneratorState.brandDetails})
            imageAdGeneratorDispatch({type: "SET_AD_DETAILS_UPDATED", value: true})
        }
    }

    const saveAdtype = () => {
        if(imageAdGeneratorState.adType ){
            imageAdGeneratorDispatch({type: "SET_AD_TYPE", value: imageAdGeneratorState.adType})
            imageAdGeneratorDispatch({type: "SET_AD_DETAILS_UPDATED", value: true})
        }
    }
    const handleSave = () => {
        switch(imageAdGeneratorState.activeCustomizePopup?.id){
            case 1:
                saveCategory()
                onCancel()
            break

            case 2:
                saveSelectedGoal()
                onCancel()
            break

            case 3:
                saveNewTargetAudience()
                onCancel()
            break

            case 4: 
                saveNewDescription()
                onCancel()
            break
            case 5: 
                saveAdtype()
                onCancel()
        break
            default:
            break
            
        }
    }
    return ( 
        <FooterContainer isAdGoal={imageAdGeneratorState.activeCustomizePopup?.id === 1} >
            <Cancel onClick={onCancel} >Cancel</Cancel>
            <Save onClick={handleSave} >Save</Save>
        </FooterContainer>
     );
}

export default Footer;