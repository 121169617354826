import React, {useState} from 'react'
import { AddKeyword, HoverWrapper, KeyData, KeyHeader, KeywordTracking, KeywordWrap, Row, StatusData, StatusHeader, TableBody, TableHeader, TableWrap, UpdatedData, UpdatedHeader, Wrapper, NoHeader, NumberData, NoResult } from './keywordTable.styled'
import plus from '../../../../../../../media/Dashboard/plus-small.svg'
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    SortingState,
    ColumnDef,
    getSortedRowModel
  } from '@tanstack/react-table'
import FilterIcon from "../../../../../../../media/Dashboard/filter.png";
import UpArrow from "../../../../../../../media/Dashboard/uparrow.png";
import DownArrow from "../../../../../../../media/Dashboard/downarrow.png";
import { useLandingPageAnalysis } from '../../../../context/landingPageAnalysis';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getNumberDateFromInput } from '../../../../../../../utils/dateFormat';

const KeywordTable = () => {
    const columnHelper = createColumnHelper();
    const {landingPageAnalysisState, landingPageAnalysisDispatch} = useLandingPageAnalysis()
    const [sorting, setSorting] = useState([]);

    const navigate = useNavigate()

    const columns = [
        columnHelper.accessor('no', {
            id: 'no',
            header: () => 'No',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('url', {
            id: 'url',
            header: () => 'Url',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('status', {
            id: 'status',
            header: () => 'Status',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor(row => `${new Date(row.requestedOn).getTime()}`, {
            id: 'requestedOn',
            header: () => 'Requested Date',
            cell: info => info.getValue(),
        })
    ];

    const table = useReactTable({
        data: landingPageAnalysisState.landingTable,
        columns,
        state: {
          sorting,
          sortBy: [{ id: "datetime", desc: false }],
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    const landingDetailNewFn = (data) =>{
        // console.log(data)
        navigate(`/landing-page-analysis/details?url=${data.url}&date=${getNumberDateFromInput(data.requestedDate   )}`)
    }

  return (
    <Wrapper>
        <KeywordWrap>
            <KeywordTracking>Requested Landing Pages</KeywordTracking>
            <AddKeyword onClick={()=>{
                landingPageAnalysisDispatch({type: 'SET_URL_INPUT_LIST', value: [{ url: landingPageAnalysisState.urlInput, event: {} }]})
                landingPageAnalysisDispatch({type: 'SET_URL_INPUT_MODAL_OPEN', value: true})}}>
                <img src={plus}/>
                Add URL
            </AddKeyword>
        </KeywordWrap>
        <TableWrap>
                    {table.getHeaderGroups().map(headerGroup => {
                        return <TableHeader>
                            <NoHeader>No.</NoHeader>
                        <KeyHeader onClick={headerGroup.headers[1].column.getToggleSortingHandler()}>
                            Domain{" "}
                            <img
                                src={
                                headerGroup.headers[1].column.getIsSorted() == "asc"
                                    ? UpArrow
                                    : headerGroup.headers[1].column.getIsSorted() == "desc"
                                    ? DownArrow
                                    : FilterIcon
                                }
                            />
                        </KeyHeader>
                        <StatusHeader onClick={headerGroup.headers[2].column.getToggleSortingHandler()}>
                            Status{" "}
                            <img
                                src={
                                headerGroup.headers[2].column.getIsSorted() == "asc"
                                    ? UpArrow
                                    : headerGroup.headers[2].column.getIsSorted() == "desc"
                                    ? DownArrow
                                    : FilterIcon
                                }
                            />
                        </StatusHeader>
                        <UpdatedHeader onClick={headerGroup.headers[3].column.getToggleSortingHandler()}>
                            Requested Date{" "}
                            <img
                                src={
                                headerGroup.headers[3].column.getIsSorted() == "asc"
                                    ? UpArrow
                                    : headerGroup.headers[3].column.getIsSorted() == "desc"
                                    ? DownArrow
                                    : FilterIcon
                                }
                            />
                        </UpdatedHeader>
                    </TableHeader>
                       
                            })}
                  <TableBody>
                  {table.getRowModel().rows.length > 0 ? (
                    table.getRowModel().rows.map((row, rowIndex) => {
                        return <Row key={rowIndex}>
                            <HoverWrapper onClick={()=>landingDetailNewFn(row.original)}>
                                <NumberData>{rowIndex + 1}</NumberData>
                                <KeyData>{row.original.url}</KeyData>
                                <StatusData status={row.original.status}>
                                 <p>{row.original.status}</p>
                                </StatusData>   
                                <UpdatedData>{moment(row.original.requestedOn).format('YYYY-MM-DD HH:mm')}</UpdatedData>
                            </HoverWrapper>
                        </Row>
                        }
                    )):
                  (
                    <Row>
                <NoResult>No data available</NoResult>
              </Row>
                  )
                }
                    </TableBody>
        </TableWrap>
    </Wrapper>
  )
}

export default KeywordTable
