import React from "react";
import {
  TableWrapper,
  AllHeadCount,
  CountryStatsData,
  PlatformIndicator,
} from "./index.styled";
import { useUrlAnalysis } from "../../../context/urlanalysis";
import Skeleton from 'react-loading-skeleton';

const Table = ({isDataLoading}) => { 
  const { urlAnalysisState, urlAnalysisDispatch  } = useUrlAnalysis()
  const colorsArray = ["#1679DB", "#A158E0", "#E24593", "#02B6FB", "#C4C4C4"];

  //   const JSONData = authState?.domainAnaysisData?.percentageArray;
  const JSONData = [{
        "docCount": 32234,
        "platform": "google",
        "percentage": 77
      },
      {
        "docCount": 14122,
        "platform": "google-ecom",
        "percentage": 20
      },
      {
        "docCount": 2063,
        "platform": "bing",
        "percentage": 1
      },
      {
        "docCount": 4,
        "platform": "facebook",
        "percentage": 2
  }]
      
  const data = [...JSONData]?.sort((a, b) => b.percentage - a.percentage); 

  const totalPercentage = data?.reduce((total, item) => total + item.percentage, 0);
  const totalDocCount = data?.reduce((total, item) => total + item.docCount, 0);

  
    return isDataLoading ? 
    <Skeleton style={{
        lineHeight: 10,
        width: 175,
      }}/> :
    <TableWrapper>
        <AllHeadCount>
            <p>
                All
            </p>
            <p className="percentage">{100}%</p>
            {/* <p className="totalcount">
                {formatNumber(totalDocCount)}
            </p> */}
        </AllHeadCount>
        {urlAnalysisState.adsDistribution.map((item, idx)=>{
            return <CountryStatsData>
                <p>
                    <PlatformIndicator color={colorsArray[idx % colorsArray.length]} />
                        {item.key.replaceAll("-","").replace("full","").replaceAll("new","")}
                </p>
                <p className="percentage">{item.percentage}%</p>
                {/* <p className="totalcount">
                    {formatNumber(item.docCount)}
                </p> */}
            </CountryStatsData>
        })}
    </TableWrapper>
};

export default React.memo(Table);




