import { useEffect, useRef, useState } from "react";
import { countryNewData } from "../../../../../../../../utils/countryData";
import languages from "../../../../../../../../utils/languages";
import statics from "../../../../../statics";
import {
  DropdownContainer,
  Input,
  Item,
  LangName,
  List,
  SearchBar,
  AddPersona,
  SearchBarContainer,
  SubHeading,
  Divider,
} from "./index.styled";
import { useAdGenerator } from "../../../../../context/adGeneration";
import { customToast } from "../../../../../../../../utils/toast-utils";

function Dropdown({
  dropdownFor,
  onSelect,
  onDeselect,
  selectedItems,
  withoutSearchBar = false,
  filteringText,
}) {
  const { steps } = statics;
  // const { occupations } = steps[4]
  const [list, setList] = useState();
  const [filteredList, setFilteredList] = useState();
  const searchBarRef = useRef(null);
  const checkBoxesRefs = useRef([]);
  const {
    adGeneratorState: { occupationItem },
    adGeneratorDispatch,
  } = useAdGenerator();

  const filterList = () => {
    setFilteredList(
      list.filter((item) =>
        item.toLowerCase().includes(searchBarRef.current.value.toLowerCase())
      )
    );
  };

  const handleChange = (e, item) => {
    if (e.target.checked) onSelect(item);
    else onDeselect(item);
  };

  const handleClickOnLabel = (item, index) => {
    const target = checkBoxesRefs.current[index];
    if (!target.checked) {
      target.checked = true;
      onSelect(item);
    } else {
      target.checked = false;
      onDeselect(item);
    }
  };

  const renderList = (listOfItems) => {
    return listOfItems?.map((item, index) => {
      return (
        <Item
          key={index}
          onBlur={(e) => e.stopPropagation()}
          onClick={(e) => {
            if (e.target.tagName != 'INPUT') { 
              dropdownFor != "occupation"
                ? onSelect(item)
                : handleClickOnLabel(item, index);
            }
            e.stopPropagation();
          }}
        >
          {dropdownFor == "occupation" && (
            <Input
              type={"checkbox"}
              checked={selectedItems?.includes(item)}
              onChange={(e) => handleChange(e, item)}
              ref={(el) => (checkBoxesRefs.current[index] = el)}
            />
          )}
          <LangName

          >
            {item}
          </LangName>
        </Item>
      );
    });
  };

  const filterListByText = () => {
    if (filteringText && list) {
      setFilteredList(
        list.filter((item) =>
          item.toLowerCase().includes(filteringText.toLowerCase())
        )
      );
    }
  };

  const AddNewPersona = () => {
    if (searchBarRef.current.value.toLowerCase()) {
      onSelect(searchBarRef.current.value.toLowerCase());
      adGeneratorDispatch({
        type: "SET_OCCUPATION_ITEM",
        value: [searchBarRef.current.value.toLowerCase(), ...occupationItem],
      });
      searchBarRef.current.value = "";
    } else {
      customToast({ message: "Please enter a persona to add", type: "error" });
    }
  };
  // const AddNewPersona = (e) => {
  //   e.stopPropagation()
  //   if(searchBarRef.current.value.toLowerCase()){
  //     onSelect(searchBarRef.current.value.toLowerCase())
  //     adGeneratorDispatch({ type: "SET_OCCUPATION_ITEM", value: [searchBarRef.current.value.toLowerCase(), ...occupationItem] })
  //     searchBarRef.current.value = ""
  //   }else{
  //     customToast({ message: "Please enter a persona to add", type: "error" });
  //   }
  // };

  useEffect(() => {
    switch (dropdownFor) {
      case "language":
        setList(languages.map((item) => item.name));
        break;

      case "occupation":
        setList(occupationItem);
        break;

      case "country":
        setList(countryNewData.map((country) => country.official_name));
        break;

      default:
        break;
    }
  }, [occupationItem]);

  useEffect(() => {
    filterListByText();
  }, [filteringText]);

  return (
    <DropdownContainer
      onClick={(e) => e.stopPropagation()}
      style={{ height: dropdownFor === "occupation" ? "365px" : "350px" }}
    >
      {dropdownFor !== "occupation" && !withoutSearchBar && (
        <SearchBarContainer>
          <SearchBar
            ref={searchBarRef}
            type={"text"}
            placeholder={
              dropdownFor === "occupation"
                ? "Add your custom persona"
                : `Search ${dropdownFor}...`
            }
            onChange={() => {
              filterList();
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onBlur={(e) => e.stopPropagation()}
          />
          {dropdownFor == "occupation" && (
            <AddPersona
              isDisabled={searchBarRef.current?.value?.length == 0}
              onClick={AddNewPersona}
            >
              Add
            </AddPersona>
          )}
        </SearchBarContainer>
      )}
      {dropdownFor === "occupation" && (
        <SubHeading>Competitor persona</SubHeading>
      )}
      <List withoutSearchBar={withoutSearchBar}>
        {!filteredList && list && renderList(list)}
        {filteredList && renderList(filteredList)}
      </List>

      {dropdownFor === "occupation" && !withoutSearchBar && (
        <>
          <Divider />
          <SearchBarContainer>
            <SearchBar
              ref={searchBarRef}
              type={"text"}
              placeholder={
                dropdownFor === "occupation"
                  ? "Add your custom persona"
                  : `Search ${dropdownFor}...`
              }
              // onChange={() => {
              //   filterList();
              // }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onBlur={(e) => e.stopPropagation()}
            />
            {dropdownFor == "occupation" && (
              <AddPersona
                isDisabled={searchBarRef.current?.value?.length == 0}
                onClick={(e) => AddNewPersona(e)}
              >
                Add
              </AddPersona>
            )}
          </SearchBarContainer>
        </>
      )}
    </DropdownContainer>
  );
}

export default Dropdown;
