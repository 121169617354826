import React, { useRef } from 'react'
import NoResultsLogo from "../../../../../../../media/Dashboard/noresults.svg"
import { useRequestData } from '../../../../../../hooks/useRequestData'
// Styles
import {
    CountriesList,
    TagsList,
    TagsContainer,
    CountriesContainer,
    AvgPosition,
    DateRange,
    AdDetailsBody,
    CloseDetails,
    DetailsHeader,
    DetailsContainer,
    DetailsButton,
    FooterRight,
    AdCountries,
    AdDate,
    FooterLeft,
    CardFooter,
    CardBody,
    FloatingButtonsContainer,
    Card,
    CardsList,
    ResultsContainer,
    ResultsWraper,
    VideoContainer,
    VideoTitle,
    ChannelDetails,
    LogoContainer,
    ChannelName,
    VideoDescription,
    PublishedOn,
    DetailsVideoContainer,
    Category,
    PublishedOnDetails,
    NoResults,
    RequestDataBtn
} from "./index.styled"
import SearchLoader from '../../../../../../HOC/SearchLoader'

import WebsiteIcon from '../../../../../../../media/Dashboard/websiteicon.png'

//Icons
import CloseBtn from "../../../../../../../media/Dashboard/detailsPaneClose.svg"
import TickImg from '../../../../../../../media/Dashboard/tick-grey.svg'


import DateIcon from "../../../../../../../media/Images/DateIcon.svg"
import CountryIcon from "../../../../../../../media/Images/CountryIcon.svg"

import { useUrlAnalysis } from '../../../../context/urlanalysis'

// const requestDataCrawl = async (data) => {
//     const config = {
//         url: `/track/search/update-search-query`,
//         method: "POST",
//         data
//     }
  
//     return appClient(config)
// }

const convertTimestamp = (timestamp) => {
    // Create a new Date object based on the timestamp
    var date = new Date(timestamp);
  
    // Get the month name
    var monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    var month = monthNames[date.getMonth()];
  
    // Get the year
    var year = date.getFullYear();
  
    // Assemble the formatted date string
    var formattedDate = date.getDate() + ' ' + month  + ' ' + year;
  
    return formattedDate;
}

const CardElement = ({ad,idx, containerRef}) => {
    const { urlAnalysisState, urlAnalysisDispatch } = useUrlAnalysis()

    const scrollToElement = (index) => {
        const element = containerRef.current.children[index];
        const scrollPosition = element.offsetTop - 20;
        containerRef.current.scrollTo({ top: scrollPosition, behavior: 'smooth' });
      };

    const toggleDetailsModal = (ad, idx) => {
        // console.log("addd yt details")
        if((urlAnalysisState.adDetails.id !== ad.id) && urlAnalysisState.detailsModal){ 
            urlAnalysisDispatch({type:'SET_AD_DETAILS', value: ad})
        }else{
            urlAnalysisDispatch({type:'SET_AD_DETAILS', value: ad})
            urlAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: !urlAnalysisState.detailsModal})
        }
        setTimeout(() => {
            scrollToElement(idx)
        }, 100);
    }
    

    return <Card detailsOpen={urlAnalysisState.adDetails.id == ad.id} key={
        ad.id
    }>
        <VideoContainer>
            <iframe width="100%" height="243" style={{borderRadius: "11px 11px 0px 0px", border: "none"}}
                src={`https://www.youtube.com/embed/${ad.videoId}`}>
            </iframe>
        </VideoContainer>
        <CardBody>
            <VideoTitle>
                {ad.title}
            </VideoTitle>
            <ChannelDetails>
                <LogoContainer>
                    <img src={WebsiteIcon}/>
                </LogoContainer>
                <ChannelName>
                    {ad.channel}
                </ChannelName>
            </ChannelDetails>
            <VideoDescription>
                {ad.content}
            </VideoDescription>
            <FloatingButtonsContainer>
                {/* <Favorite isFav={idx == 1}>
                    <img src={FavorietIcon}/>
                </Favorite>
                |
                <DropdownIcon>
                    <span/>
                </DropdownIcon> */}
            </FloatingButtonsContainer>
        </CardBody>
        <CardFooter>
            <FooterLeft>
                <AdDate>
                {ad.firstSeen && ad.lastSeen && <img src={DateIcon}/>}{convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
                </AdDate>
                {ad.country && <AdCountries>
                    <img src={CountryIcon}/>{ ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
                </AdCountries>}
            </FooterLeft>
            <FooterRight>
                <PublishedOn>
                    <label>Published on:</label>
                    {convertTimestamp(ad.publishedOn)}
                </PublishedOn>
                <DetailsButton onClick={()=>toggleDetailsModal(ad,idx)}>
                    {(urlAnalysisState.adDetails.id == ad.id) && urlAnalysisState.detailsModal ? "Close details" : "More details"}
                </DetailsButton>
            </FooterRight>
        </CardFooter>
    </Card>
}

const DetailsElement = () => {

    const { urlAnalysisState, urlAnalysisDispatch } = useUrlAnalysis()

    return <DetailsContainer>
    <DetailsHeader>
        <h3>Ad details</h3> <CloseDetails onClick={()=>{urlAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: false})
                urlAnalysisDispatch({type:'SET_AD_DETAILS', value: {}})
            }}><img src={CloseBtn}/></CloseDetails>
    </DetailsHeader>
    <AdDetailsBody>
        <DetailsVideoContainer>
            <iframe width="100%" height="378" style={{borderRadius: "11px 11px 0px 0px", border: "none"}}
                src={`https://www.youtube.com/embed/${urlAnalysisState.adDetails.videoId}`}>
            </iframe>
        </DetailsVideoContainer>
        <VideoTitle>
                {urlAnalysisState.adDetails.title}
            </VideoTitle>
            <ChannelDetails>
                <LogoContainer>
                    <img src={WebsiteIcon}/>
                </LogoContainer>
                <ChannelName>
                    {urlAnalysisState.adDetails.channel}
                </ChannelName>
            </ChannelDetails>
            <VideoDescription>
                {urlAnalysisState.adDetails.content}
            </VideoDescription>
        <Category>
            <label>Category:</label>{urlAnalysisState.adDetails.genre}
        </Category>
        <DateRange>
            <label>Date Range:</label>{convertTimestamp(urlAnalysisState.adDetails.firstSeen)} - {convertTimestamp(urlAnalysisState.adDetails.lastSeen)}
        </DateRange>
        <AvgPosition>
            <label>Average position:</label>{urlAnalysisState.adDetails.position}
        </AvgPosition>
        {urlAnalysisState.adDetails.tags && <TagsContainer>
            <label>Tags:</label><TagsList>{urlAnalysisState.adDetails.tags.map((tag,idx)=>{
                return <span>{tag}</span>
            })}</TagsList>
        </TagsContainer>}
        <PublishedOnDetails>
            <label>Published on:</label>{convertTimestamp(urlAnalysisState.adDetails.publishedOn)}            
        </PublishedOnDetails>
        {urlAnalysisState.adDetails.country && <CountriesContainer>
            <label>Country:</label><CountriesList>{urlAnalysisState.adDetails.country.map((tag,idx)=>{
                return <span>{tag}</span>
            })}</CountriesList>
        </CountriesContainer>}
    </AdDetailsBody>
</DetailsContainer>
}

const YoutubeResults = ({isDataLoading}) => {
    const containerRef = useRef(null);
    const useRequestDataHook = useRequestData()

    const { urlAnalysisState, urlAnalysisDispatch } = useUrlAnalysis()

    const onDataRequest = () => {
        if(!useRequestDataHook.dataRequested){
            useRequestDataHook.requestDataMutate({id: urlAnalysisState.queryId})
        }
    }

    // if(authState.isRestrictedPopup){
    //     return  <ResultsContainer detailsModal={urlAnalysisState.detailsModal}>
    //         <PlatformRestrictedPopup err={searchState.searchError}/>
    //     </ResultsContainer>
    // }

    // if(searchState.limitExceeded){
    //     return  <ResultsContainer detailsModal={urlAnalysisState.detailsModal}>
    //         <LimitExceededPopup err={searchState.searchError}/>
    //     </ResultsContainer>
    // }

    return  <ResultsContainer detailsModal={urlAnalysisState.detailsModal}>
        {isDataLoading ? <div className='loader'>
        <SearchLoader/>
      </div> : urlAnalysisState.ads.length == 0 ? <NoResults>
                        <img src={NoResultsLogo}/>
                        <h2>No Results Found</h2>
                        <p>We're sorry, but we don't currently have the data matching your search parameters. Our database is constantly updated, so please try again later or try modifying your search.</p>
                        <RequestDataBtn dataRequested={useRequestDataHook.dataRequested ? useRequestDataHook.dataRequested : ""} disable={useRequestDataHook.isRequestDataLoading} onClick={onDataRequest}>{useRequestDataHook.dataRequested ? <p>Your request for data crawl has been accepted<img src={TickImg}/></p> : <p>{useRequestDataHook.isRequestDataLoading ? "Requesting...": "Request data crawl for this query"}</p>}</RequestDataBtn>
                    </NoResults> :
                <ResultsWraper>
                    <CardsList ref={containerRef} detailsModal={urlAnalysisState.detailsModal}> {
                        urlAnalysisState.ads && urlAnalysisState.ads.length > 0 && urlAnalysisState.ads.map((ad,idx) => {
                            return <CardElement containerRef={containerRef} ad={ad} idx={idx}/>
                        })
                    } </CardsList>
                    {
                        urlAnalysisState.detailsModal && <DetailsElement/>
                    }
                </ResultsWraper>
                }
            </ResultsContainer>
}

export default YoutubeResults



