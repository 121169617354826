import adgoals1 from "./assets/adgoals1.svg"
import adgoals2 from "./assets/adgoals2.svg"
import adgoals3 from "./assets/adgoals3.svg"
import activeadgoal1 from "./assets/activeadgoal1.svg"
import activeadgoal2 from "./assets/activeadgoal2.svg"
import activeadgoal3 from "./assets/activeadgoal3.svg"
const statics = {
    pages: [
        {
            no: 1,
            name: "Add Website",
            query: ""
        },
        {
            no: 2,
            name: "Add brand description",
            query: "Why description?"
        },
        {
            no: 3,
            name: "Define ad goal",
            query: "Why to define ad goal?"
        },
        {
            no: 4,
            name: "Enter target location",
            query: "Why to target location?"
        },
        
        {
            no: 5,
            name: "Enter seed keywords",
            query: "Why seed keywords?"
        },
        {
            no: 6,
            name: "Generating ads...",
            description: "Please be patient. This process may take a few minutes..."
        },
        {
            no: 7,
            name: "Results"
        }
    ],
    adGoals: [
        {
            id: 1,
            name: "Sales",
            subName: "sales",
            activeIcon: activeadgoal1,
            inActiveIcon: adgoals1
        },
        {
            id: 2,
            name: "Leads",
            subName: "leads",
            activeIcon: activeadgoal2,
            inActiveIcon: adgoals2
        },
        {
            id: 3,
            name: "Website Traffic",
            subName: "website-traffic",
            activeIcon: activeadgoal3,
            inActiveIcon: adgoals3
        },
//         {
//             id: 4,
//             name: `Product and
// brand considerations`,
//             activeIcon: activeadgoal4,
//             inActiveIcon: adgoals4
//         },
//         {
//             id: 5,
//             name: `Brand awareness and
// reach`,
//             activeIcon: activeadgoal5,
//             inActiveIcon: adgoals5
//         },
//         {
//             id: 6,
//             name: "App promotions",
//             activeIcon: activeadgoal6,
//             inActiveIcon: adgoals6
//         },
//         {
//             id: 7,
//             name: "Local store visits",
//             activeIcon: activeadgoal7,
//             inActiveIcon: adgoals7
//         },
    ],
    enteringDomain: {
        header: "Text ad generator",
        description: "Revolutionize your advertising with smart text ad generation. Craft compelling, targeted copies using advanced algorithms.",
        placeHolder: "Enter a target domain",
        examplesLabel: "Examples:",
        example: "apple.com",
        generate: "Generate"
    },
    // processes: [
    //     {
    //         id: 'collectingAdCopies',
    //         name: "Collecting ad copies...",
    //         isCompleted: false
    //     },
    //     {
    //         id: 'pairingPersonas',
    //         name: "Pairing Personas and Sub Personas",
    //         isCompleted: false
    //     },
    //     {
    //         id: 'calculateScore',
    //         name: "Calculating Persona-Sub Persona Matching Score",
    //         isCompleted: false
    //     },
    //     {
    //         id: 'identifyKeywords',
    //         name: "Identifying Keywords",
    //         isCompleted: false
    //     },
    //     {
    //         id: 'generateAdCopies',
    //         name: "Generating Ad Copies",
    //         isCompleted: false
    //     },
    //     {
    //         id: 'landingPageChanges',
    //         name: "Implementing Landing Page Changes",
    //         isCompleted: false
    //     },
    // ],
    processes: [
        {
            id: 1,
            name: "Collecting ad copies...",
            isCompleted: false
        },
        {
            id: 2,
            name: "Pairing Personas and Sub Personas",
            isCompleted: false
        },
        {
            id: 3,
            name: "Calculating Persona-Sub Persona Matching Score",
            isCompleted: false
        },
        {
            id: 4,
            name: "Identifying Keywords",
            isCompleted: false
        },
        {
            id: 5,
            name: "Generating Ad Copies",
            isCompleted: false
        }
    ],
    generatedAds: [
        {
            id: 1,
            title: "Ad group 1",
            location: "India",
            titlesList: [
                "Top Deals & Coupons",
                "Save Big with Hot Coupons",
                "Daily Discounts on Everything!",
                "Grab Your Savings Today!",
                "Find the Perfect Coupon",
                "Unlock Exclusive Deals",
                "Big Brands, Bigger Savings!",
                "Don't Pay Full Price, Use Coupons!",
                "Free Shipping Coupons Galore",
                "Save Money on Every Purchase",
                "Your One-Stop Coupon Shop",
                "Discount Codes & Promo Offers",
                "Slash Prices with These Coupons",
                "Maximize Your Savings with Us",
                "Daily Deals & Hot Promotions"
            ],
            descriptionList: [
                `Verified coupons for top brands! Save up to 70% & find deals on everything`,
                `Exclusive discounts you won't find elsewhere. Shop smarter & save more!`,
                `Exclusive discounts you won't find elsewhere. Shop smarter & save more!`,
                `Daily deals & free shipping! Don't miss out on incredible savings.`
            ],
            keywords: [
                "refer my friends", "hello", "my friends and family", "narendra modi", "Rahul gandhi", "Rajinikanth", "Mahendra sing Dhoni", "ICC t20 world cup", "Ipl 2024", "Chennai Super Kings", "Mumbai Indians", "Dravida Munnetra Kazhagam", "TVK Vijay", "Thalapathy Vijay", "CouponsWala", "Adspyder", "Visual studio"
            ]
        },
        {
            id: 2,
            title: "Ad group 2",
            location: "India",
            titlesList: [
                "Top Deals & Coupons",
                "Save Big with Hot Coupons",
                "Daily Discounts on Everything!",
                "Grab Your Savings Today!",
                "Find the Perfect Coupon",
                "Unlock Exclusive Deals",
                "Big Brands, Bigger Savings!",
                "Don't Pay Full Price, Use Coupons!",
                "Free Shipping Coupons Galore",
                "Save Money on Every Purchase",
                "Your One-Stop Coupon Shop",
                "Discount Codes & Promo Offers",
                "Slash Prices with These Coupons",
                "Maximize Your Savings with Us",
                "Daily Deals & Hot Promotions"
            ],
            descriptionList: [
                `Verified coupons for top brands! Save up to 70% & find deals on everything`,
                `Exclusive discounts you won't find elsewhere. Shop smarter & save more!`,
                `Exclusive discounts you won't find elsewhere. Shop smarter & save more!`,
                `Daily deals & free shipping! Don't miss out on incredible savings.`
            ],
            keywords: [
                "refer my friends", "hello", "my friends and family", "narendra modi", "Rahul gandhi", "Rajinikanth", "Mahendra sing Dhoni", "ICC t20 world cup", "Ipl 2024", "Chennai Super Kings", "Mumbai Indians", "Dravida Munnetra Kazhagam", "TVK Vijay", "Thalapathy Vijay", "CouponsWala", "Adspyder", "Visual studio"
            ]
        },
        {
            id: 3,
            title: "Ad group 3",
            location: "India",
            titlesList: [
                "Top Deals & Coupons",
                "Save Big with Hot Coupons",
                "Daily Discounts on Everything!",
                "Grab Your Savings Today!",
                "Find the Perfect Coupon",
                "Unlock Exclusive Deals",
                "Big Brands, Bigger Savings!",
                "Don't Pay Full Price, Use Coupons!",
                "Free Shipping Coupons Galore",
                "Save Money on Every Purchase",
                "Your One-Stop Coupon Shop",
                "Discount Codes & Promo Offers",
                "Slash Prices with These Coupons",
                "Maximize Your Savings with Us",
                "Daily Deals & Hot Promotions"
            ],
            descriptionList: [
                `Verified coupons for top brands! Save up to 70% & find deals on everything`,
                `Exclusive discounts you won't find elsewhere. Shop smarter & save more!`,
                `Exclusive discounts you won't find elsewhere. Shop smarter & save more!`,
                `Daily deals & free shipping! Don't miss out on incredible savings.`
            ],
            keywords: [
                "refer my friends", "hello", "my friends and family", "narendra modi", "Rahul gandhi", "Rajinikanth", "Mahendra sing Dhoni", "ICC t20 world cup", "Ipl 2024", "Chennai Super Kings", "Mumbai Indians", "Dravida Munnetra Kazhagam", "TVK Vijay", "Thalapathy Vijay", "CouponsWala", "Adspyder", "Visual studio"
            ]
        },
        {
            id: 4,
            title: "Ad group 4",
            location: "India",
            titlesList: [
                "Top Deals & Coupons",
                "Save Big with Hot Coupons",
                "Daily Discounts on Everything!",
                "Grab Your Savings Today!",
                "Find the Perfect Coupon",
                "Unlock Exclusive Deals",
                "Big Brands, Bigger Savings!",
                "Don't Pay Full Price, Use Coupons!",
                "Free Shipping Coupons Galore",
                "Save Money on Every Purchase",
                "Your One-Stop Coupon Shop",
                "Discount Codes & Promo Offers",
                "Slash Prices with These Coupons",
                "Maximize Your Savings with Us",
                "Daily Deals & Hot Promotions"
            ],
            descriptionList: [
                `Verified coupons for top brands! Save up to 70% & find deals on everything`,
                `Exclusive discounts you won't find elsewhere. Shop smarter & save more!`,
                `Exclusive discounts you won't find elsewhere. Shop smarter & save more!`,
                `Daily deals & free shipping! Don't miss out on incredible savings.`
            ],
            keywords: [
                "refer my friends", "hello", "my friends and family", "narendra modi", "Rahul gandhi", "Rajinikanth", "Mahendra sing Dhoni", "ICC t20 world cup", "Ipl 2024", "Chennai Super Kings", "Mumbai Indians", "Dravida Munnetra Kazhagam", "TVK Vijay", "Thalapathy Vijay", "CouponsWala", "Adspyder", "Visual studio"
            ]
        }
    ],
    navItems: [
        {
            id: 1,
            name: "Title",
            limit: "15"
        },
        {
            id: 2,
            name: "Description",
            limit: "04"
        },
        {
            id: 3,
            name: "Keywords",
            limit: "20"
        }
    ],
    customizeNavItems: [
        {
            id: 1,
            name: "Customize",
            // limit: "15"
        },
        {
            id: 2,
            name: "Export",
            // limit: "04"
        }      
    ],
    customizeItems: [
        {
            id: 1,
            name: "Ad goal"
        },
        {
            id: 2,
            name: "Target location"
        },
        {
            id: 3,
            name: "Description"
        },
        {
            id: 4,
            name: "Seed keywords"
        },
    ]
}

export default statics