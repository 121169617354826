import React from "react";
import { Route, Routes } from "react-router-dom";

import {Helmet} from "react-helmet"

import {
  DomainFunnelAnalysisProvider
} from "./context/domainFunnelAnalysis";

// Layout
import Layout from "../../layout";

// Pages

import DomainFunnelAnalysisResult from "./components/result/index";
import DomainFunnelAnalysisHome from "./components/home";
// import { getSearchErrCode } from "../../../helper/getSearchErrCode"

// Not found page
import NotFound from "../NotFoundPage";

// import {appClient} from '../../../../utils/axios-utils'

const DomainFunnelAnalysisComponent = () => {
  // const {searchState, searchDispatch} = useSearch()

  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route
        path="/"
        element={
          <Layout>
            <DomainFunnelAnalysisHome />
          </Layout>
        }
      />
      <Route
        path="/details"
        element={
          <Layout>
            <DomainFunnelAnalysisResult />
          </Layout>
        }
      />
    </Routes>
  );
};

const DomainFunnelAnalysisProviderWrapper = () => {
  return (
    <DomainFunnelAnalysisProvider>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Domain Funnel Analysis | AdSpyder</title>
          <meta name="title" content="Domain Funnel Analysis | AdSpyder" />
          <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
      </Helmet>
      <DomainFunnelAnalysisComponent />
    </DomainFunnelAnalysisProvider>
  );
};

export default DomainFunnelAnalysisProviderWrapper;
