import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Container, HeaderSection,BackButton, FavButton, TemplatesContainer, TemplatesList, TemplateCard, RequestTemplateButton, FilterSection, ApplyFilterButton } from "./index.styled"
import { useNavigate, useLocation, useParams } from "react-router-dom"
import {
    useMutation
} from "@tanstack/react-query";
import {appClient} from '../../../../../../utils/axios-utils'
import DentistryLogo from "../../../../../../media/Dashboard/DentistryLogo.png"
import UserIcon from "../../../../../../media/Dashboard/userIcon.svg"
import BackArrow from "../../../../../../media/Dashboard/backarrow.svg"
import HeartIcon from "../../../../../../media/Dashboard/headIcon.svg"
import CreativeLogo from "../../../../../../media/Dashboard/ImageAdGenerationCreative.svg"


import SubPersonaDropDown from "./components/SubPersonaDropDown";
import PersonaDropDown from "./components/PersonaDropDown";
import { useAdGenrator } from "../../../context/adgenerator";

const getTemplates = async (data) => {
    const config = {
        url: `/imagead/getalltemplates`,
        method: "POST",
        data
    }

    return appClient(config)
}

const requestTemplatesTemplates = async (data) => {
    const config = {
        url: `/imagead/request-template`,
        method: "POST",
        data
    }

    return appClient(config)
}

const getPersonaData = async (data) => {
    const config = {
        url: `/imagead/personas`,
        method: "POST",
        data
    }

    return appClient(config)
}

const getSubPersonaData = async (data) => {
    const config = {
        url: `/imagead/subpersonas`,
        method: "POST",
        data
    }

    return appClient(config)
}

const SubPersonaPage = () => {
    const { adGenratorState, adGenratorDispatch } = useAdGenrator()

    const [templatesList, setTemplatesList] = useState([])
    // const [category, setCategory] = useState('');
    const [requestedTemplate, setRequestedTemplate] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);

    const { category } = useParams();
    let persona = searchParams.get('persona')
    let subPersona = searchParams.get('subpersona')

    const {mutate: getTemplatesMutate, isLoading: isCategoryTemplatesLoading} = useMutation(getTemplates, {
        onSuccess: (data) => {
            setTemplatesList(data.templates)
        },
        onError:  ((e)=>{

        })
    })

    const {mutate: requestTemplateMutate, isLoading: isResuestTemplateLoading} = useMutation(requestTemplatesTemplates, {
        onSuccess: (data) => {
            setRequestedTemplate(true)
        },
        onError:  ((e)=>{
            
        })
    })

    const {mutate: getPersonaMutate, isLoading: isPersonaLoading} = useMutation(getPersonaData, {
        onSuccess: (data) => {
            adGenratorDispatch({type:"SET_PERSONA_DATA", value: data})
        },
        onError:  ((e)=>{
           
        })
    })

    const {mutate: getSubPersonaMutate, isLoading: isSubPersonaLoading} = useMutation(getSubPersonaData, {
        onSuccess: (data) => {
            adGenratorDispatch({type:"SET_SUB_PERSONA_DATA", value: data})
        },
        onError:  ((e)=>{
           
        })
    })

    useEffect(() => {
        if(adGenratorState.selectedImageAdPersona?.length > 0){
            adGenratorDispatch({type:"SET_SELECTED_SUB_PERSONA", value: ""})
            adGenratorDispatch({type:"SET_SUB_PERSONA_DATA", value: ""})
            getSubPersonaMutate({personaSlug: adGenratorState.selectedImageAdPersona})
        }
    }, [adGenratorState.selectedImageAdPersona])

    useEffect(() => {
        getPersonaMutate({categorySlug: category})
        adGenratorDispatch({type:'SET_SELECTED_PERSONA', value: persona})
        adGenratorDispatch({type:'SET_SELECTED_SUB_PERSONA', value: subPersona})
    }, [])
    
    useEffect(() => {
        getTemplatesMutate({categorySlug: category, personaSlug: persona ? persona : "", subpersonaSlug: subPersona ? subPersona : ""})
    }, [persona, subPersona])
    

    return <Container>
        <HeaderSection backgrounfImg={CreativeLogo}>
            <BackButton onClick={()=>navigate('/adgenerator/image-ad')}>
                <img src={BackArrow}/>
            </BackButton>
            <h2>{category}</h2>
            {/* <FavButton>
                <img src={HeartIcon}/>
            </FavButton> */}
        </HeaderSection>
        <FilterSection>
            <h3>
                Persona selected:
            </h3>
            <PersonaDropDown/>
            <SubPersonaDropDown/>
            <ApplyFilterButton onClick={()=> {
                navigate(`/adgenerator/image-ad/${category}?persona=${adGenratorState.selectedImageAdPersona}&subpersona=${adGenratorState.selectedImageAdSubPersona}`)
            }}>
                Apply
            </ApplyFilterButton>
        </FilterSection>
        <TemplatesContainer>
            <h2>
                <span>Get inspired with our AI generated ads</span>
                    {/* <RequestTemplateButton isDisabled={isResuestTemplateLoading ||  requestedTemplate} onClick={()=>{requestTemplateMutate({categorySlug: subPersonaName, subpersonaSlug: category})}}>
                        <button>{isResuestTemplateLoading ? "loading..." : requestedTemplate ? "Request sent" : "Request more templates"}</button>
                    </RequestTemplateButton> */}
            </h2>
            <TemplatesList>
                {isCategoryTemplatesLoading ? [...Array(8)].map(d => {
                    return  <Skeleton style={{
                        lineHeight: 14,
                        width: 232,
                      }}/>
                }): 
                templatesList.map((data,idx)=>{
                    return <TemplateCard onClick={()=>navigate(`/adgenerator/image-ad/edit?id=${data.id}`)}>
                        <img src={data.imageUrl}/>
                    </TemplateCard>
                })}
            </TemplatesList>
        </TemplatesContainer>

    </Container>
}

export default SubPersonaPage