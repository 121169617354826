import React from 'react'
import { ActiveCamp, ActiveCount, ActiveCountWrap, ActiveDiv, ActiveGraph, ActiveWrapper, UpdatedHour } from './index.styled'
import { LineChartGradient } from './AreaChart/index'
import { useDomainOverview } from '../../../context/domainoverview'
import Skeleton from "react-loading-skeleton";
import helpCircle from '../../../../../../media/Dashboard/help-circle.svg'
import Tooltip from 'rc-tooltip';

const ActiveCampaign = ({isTableDataLoading}) => {
    const {domainOverviewState, domainOverviewDispatch} = useDomainOverview()

  return (
        <ActiveWrapper>
            <ActiveDiv>
                <ActiveCamp>Active Campaigns <Tooltip trigger={['hover']} overlayStyle={{ width: "100" }} overlay={<span>Total active Campaigns that were found in last few weeks.</span>}>
                <img src={helpCircle}/>     
                    </Tooltip></ActiveCamp>
                {isTableDataLoading ? 
                 <> 
                <Skeleton height={"45px"} width= {'203px'}/> 
                <Skeleton height={"20px"} width= {'203px'} style={{'marginTop': '10px'}}/> 
                </> 
                :
                <>
                <ActiveCountWrap>   
                    <ActiveCount>{domainOverviewState?.activeCampaigns?.totalCampaigns}</ActiveCount>
                    {/* <ActivePercent><img src={greenArrow}/>12%</ActivePercent> */}
                </ActiveCountWrap>
                <UpdatedHour>updated just now</UpdatedHour>
                </>
}
            </ActiveDiv>
            <ActiveGraph>
                <LineChartGradient />
            </ActiveGraph>
        </ActiveWrapper>
  )
}

export default ActiveCampaign