import React,{useState, useEffect, useRef} from "react";
import styled from "styled-components";
import {useForm} from "react-hook-form"
import { useTracking } from "../../context/tracking";
import {appClient} from '../../../../../utils/axios-utils'
import {
    useMutation
} from "@tanstack/react-query"

const PopupContainer = styled.div`
    position: absolute;
    background: #00000045;
    left: 0;
    top: 54px;
    width: 100vw;
    height: calc(100vh - 54px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
`;

const PopupBody = styled.div`
    max-width: 350px;
    width: 80%;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px #0000003D;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    position: relative;
    img{
        width: 46px;
        margin-bottom: 24px;
    }
    h2{
        margin-bottom: 16px;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        color: #2E2E2E;       
    }
    p{
        margin-bottom: 30px;
        font-size: 14px;
        font-weight: 300;
        color: #4E4E4E;
        text-align: center;
        line-height: 21px;
    }
`;

const Input = styled.input`
    background: #FFFFFF;
    border: ${props => props.error ? "1px solid #FF5180" : "1px solid #ECECEC"};
    outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};
    border-radius: 8px;
    width: 100%;
    height: 40px;
    margin-top: 8px;
    padding: 9px 12px;
    font-weight: 300;
    margin-bottom: 8px;
    color: ${props => props.theme.colors.lightBlack};
    &:focus{
        border: ${props => props.error ? "1px solid #FF5180" : "1px solid #5AB3FF"};
        outline: ${props => props.error ? "2px solid #FFB7CB" : "2px solid #B9DDFF"};
    }
`;

const PopupHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const CloseButton = styled.div`
    cursor: pointer;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    margin-top: 16px;
`;

const CancelButton = styled.div`
    margin-right: 20px;
    cursor: pointer;
`;

const AddDomainButton = styled.div`
 border-radius: 8px;
    background: #0A66C2;
    color: #FFF;
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    cursor: pointer;
    padding: 6px 12px;
    &:hover{
        background: #0059B2;
    }

    &:active{
        background: #0052A3;
    }
`;

const Error = styled.p`
    color: #FF0045;
    margin-top: 6px;
    font-size: 14px;
    font-weight: 300;
`;

const AddDomains = async (data) => {
    const config = {
        url: "/track/domain/add",
        method: "POST",
        data
    }

    return appClient(config)
}

function useOutsideAlerter(ref, trackingDispatch) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            trackingDispatch({type: 'SET_ADD_DOMAIN_POPUP', value: false})
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref])
}


const AddDomainModal = ({getTrackingDomains}) => {
    const { trackingState, trackingDispatch } = useTracking()
    const [inputDomain, setInputDomain] = useState('')
    const wrapperRef = useRef(null)

    const {register, handleSubmit, formState: {
        errors
    }} = useForm()

    useOutsideAlerter(wrapperRef, trackingDispatch)

    const { mutate: AddTrackingDomains, isLoading: isAddingDomainsLoading } = useMutation(AddDomains, {
        onSuccess: (data) => {
            trackingDispatch({type:'SET_ADD_DOMAIN_LOADER_POPUP', value: true})
        },
        onError: (err) => {
            
        }
    })

    const submit = () => {
        AddTrackingDomains({url: inputDomain})
        // trackingDispatch({type: 'SET_ADD_DOMAIN_POPUP', value: false})
    }

    return <PopupContainer>
        <PopupBody ref={wrapperRef}>
            <PopupHeader>
                <h2>Add Domain</h2>
                <CloseButton onClick={()=>{
                    trackingDispatch({type: 'SET_ADD_DOMAIN_POPUP', value: false})
                }}>
                    x
                </CloseButton>
            </PopupHeader>
            
            <Input  
                    onCopy={(e)=>setInputDomain(e.target.value)}
                    onInput={(e)=>setInputDomain(e.target.value)}
                    onChange={(e)=>setInputDomain(e.target.value)}
                    placeholder="Enter a domain eg: https://www.apple.com"
                    type="text"
                    error={false}
                    {...register("domain", { required: {
                        value: true, 
                        message: "Enter a valid domain"
                    },
                    })}
                />
                {errors.domain && <Error>
                    {errors.domain.message}
                </Error>}
            <ButtonContainer>
                <CancelButton>
                    cancel
                </CancelButton>
                <AddDomainButton onClick={submit}>
                    Add domain
                </AddDomainButton>
            </ButtonContainer>
        </PopupBody>
    </PopupContainer>
}

export default AddDomainModal