import styled from "styled-components";

export const Wrapper = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  h2 {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    color: #1c1c1c;
  }
`;

export const HeadingDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  label {
    font-size: 16px;
    font-weight: 400;
    color: #1c1c1c;
  }
  textarea {
    border-radius: 2px;
    background: #f5f5f5;
    width: 100%;
    min-height: 138px;
    outline: none;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    border: ${(props) =>
      props.hasError
        ? "1px solid #F04438"
        : props.isFocussed
        ? "1px solid #FFB258"
        : "1px solid #e8e8e8"};
    box-shadow: ${(props) =>
      props.isFocussed
        ? "0px 1px 2px 0px rgba(28, 28, 28, 0.05), 0px 0px 4px 0px rgba(255, 178, 88, 0.25)"
        : "unset"};
  }
`;
