import { appClient } from "../../../../../utils/axios-utils";
import { useMutation } from "@tanstack/react-query";
import { useImageAdGenerator } from "../context/imageAdGeneration";
import { useNavigate } from "react-router-dom";
import statics from "../statics";
import { mergeArrays } from "../../../../../utils/imageAd";

const SaveCampaignDetails = async (data) => {
    const config = {
        url: `adgeneration/image/save-campaign-details`,
        method: "POST",
        data
    }
    return appClient(config)
}

const getCampaignDetails = async (data) => {
    const config = {
        url: `/adgeneration/image/get-saved-details/${data.id}`,
        method: "GET"
    }

    return appClient(config)
}

const getAllCategoryTemplates = async (data) => {
    const config = {
        url: `/adgeneration/image/list-template-images/${data.adType}`,
        method: "GET"
    }

    return appClient(config)
}

const getTemplateHtml = async (data) => {
    const config = {
        url: `/adgeneration/image/get-base-template/${data.id}`,
        method: "GET",
        data
    }

    return appClient(config)
}

const getAiGeneratedText = async (data) => {
    const config = {
        url: `/adgeneration/image/generate-texts`,
        method: "POST",
        data
    }
    return appClient(config)
}

const GetSavedSpecificAdDetails = async (data) => {
    const config = {
        url: `/dashboard-overview/banner-image-ads/saved-details/${data.id}`,
        method: "GET"
    }
    return appClient(config)
}

const saveGeneratedTemplate = async (data) => {
    const config = {
        url: `/adgeneration/image/save-template`,
        method: "POST",
        data
    }
    return appClient(config)
}

export const useImageAdInfo = () => {
    const { enteringDomain } = statics

    const { imageAdGeneratorState, imageAdGeneratorDispatch } = useImageAdGenerator()
    const navigate = useNavigate()

    const {mutate: SaveCampaignDetailsMutate, isLoading: isSaveCampaignDetailsLoading} = useMutation(SaveCampaignDetails, {
        onSuccess: (data) => {
            imageAdGeneratorDispatch({type: "UPDATE_RESULT_GENERATED_FLAG", value: true})

            imageAdGeneratorDispatch({type: "SET_SAVED_CAMPAIGN_ID", value: data.campaignId.id})

            navigate(`/adgenerator/image-ad/result?id=${data.campaignId.id}`)
        },
        onError:  ((e)=>{
        
        })
    })

    const { mutate: GetAllCategoryTemplatesMutate, isLoading: isGetAllCategoryTemplatesLoading } = useMutation(getAllCategoryTemplates, {
        onSuccess: (data) => {
            imageAdGeneratorDispatch({ type: 'SET_CATEGORY_TEMPLATE_LIST', value: data }) 
        },
        onError: ((e) => {

        })
    })

    // const { mutate: GetTemplateHtmlMutate, isLoading: isGetTemplateHtmlLoading } = useMutation(getTemplateHtml, {
    //     onSuccess: (data) => {
    //         extractFields(data)
    //     },
    //     onError: ((e) => {

    //     })
    // })

    const { mutate: GetCampaignDetailsMutate, isLoading: isGetCampaignDetailsLoading } = useMutation(getCampaignDetails, {
        onSuccess: (data) => {

            imageAdGeneratorDispatch({ type: 'UPDATE_CAMPAIGN_DETAILS', value: data})

            GetAllCategoryTemplatesMutate({adType: data.adCategory})

        },
        onError: ((e) => {

        })
    })

    const MergeAiTextIntoFields = (inputFields = imageAdGeneratorState.inputFields, data) => {
        console.log(inputFields, data)
        let inputArrayWithText = mergeArrays(inputFields, data)

        console.log(inputArrayWithText)
        imageAdGeneratorDispatch({type: 'SET_INPUT_FEILDS', value: inputArrayWithText})
    }

    const {mutate: GetAiGeneratedTextMutate, isLoading: isGetAiGeneratedTextLoading} = useMutation(getAiGeneratedText, {
        onSuccess: (data) => {
            MergeAiTextIntoFields(imageAdGeneratorState.inputFields, data)
        },
        onError:  ((e)=>{
        
        })
    })

    const {mutate: GetSavedSpecificAdDetailsMutate, isLoading: isGetSavedSpecificAdDetailsLoading} = useMutation(GetSavedSpecificAdDetails, {
        onSuccess: (data) => {
            imageAdGeneratorDispatch({type: "SET_OVERVIEW_TEMPLATE_DATA", value: data})

            imageAdGeneratorState.categoryTemplateList.map((template, idx) => {
                if(data.templateId == template.id){
                    imageAdGeneratorDispatch({type: "SET_CURRENT_TEMPLATE", value: idx})
                }
            })
        },
        onError:  ((e)=>{
        
        })
    })

    const {mutate: SaveGeneratedTemplateMutate, isLoading: isSaveGeneratedTemplateLoading} = useMutation(saveGeneratedTemplate, {
        onSuccess: (data) => {
            
        },
        onError:  ((e)=>{
        
        })
    })

    return {
        SaveCampaignDetailsMutate,
        isSaveCampaignDetailsLoading,
        GetAllCategoryTemplatesMutate,
        isGetAllCategoryTemplatesLoading,
        SaveGeneratedTemplateMutate,
        isSaveGeneratedTemplateLoading,
        GetCampaignDetailsMutate,
        isGetCampaignDetailsLoading,
        GetSavedSpecificAdDetailsMutate,
        isGetSavedSpecificAdDetailsLoading,
        GetAiGeneratedTextMutate,
        isGetAiGeneratedTextLoading,
        MergeAiTextIntoFields
    };
};
