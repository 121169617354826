import styled from "styled-components";

export const LevelThreeDetailContainer = styled.div`
  background: #f7f7f7;
  padding: 24px 40px;
  h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: rgba(28, 28, 28, 0.9);
    margin-bottom: 16px;
  }
`;

export const LevelThreeRow = styled.div`
  /* height: 248px; */
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  > span {
    width: 100%;
  }
`;

export const GoogleSearchTermContainer = styled.div`
  border-radius: 16px;
  background: #fff;
  padding: 24px;
  width: 384px;
  /* width: 35%; */
  height: 312px;
  display: flex;
  flex-direction: column;
`;

export const GoogleSearchTermHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: #7d7d7d;
    display: flex;
    gap: 8px;
  }
  p {
    border-radius: 6px;
    background: #fff7e8;
    width: fit-content;
    padding: 4px 8px;
    display: flex;
    gap: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    color: #734a00;
    cursor: pointer;
    margin-left: 8px;
  }
`;

export const SearchTermList = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  overflow: auto;
  max-height: 85%;
  p {
    border-radius: 10px;
    background:  rgb(242 242 242);
    padding: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: #707070;
    height: fit-content;
    cursor: pointer;
  }
  ::-webkit-scrollbar-track {
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
    background-color: rgb(245 245 245 / 28%);
    border-radius: 10px;
  }

  ::-webkit-scrollbar {
    width: 5px;
    background-color: rgb(245 245 245 / 92%);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(0 0 0 / 7%);
    border-radius: 10px;
  }
`;

export const TargetAudienceContainer = styled.div`
  width: 296px;
  height: 312px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CtaContainer = styled.div`
  border-radius: 16px;
  background: #fff;
  padding: 16px;
  width: 296px;
  /* width: 35%; */
  height: 269px;
`;

export const CtaHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: #7d7d7d;
    display: flex;
    gap: 8px;
  }
`;

export const CtaContent = styled.div`
  display: flex;
  width: 264px;
  height: 196px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 16px;
  background: #f9f9f9;
  color: #707070;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const AudienceContainer = styled.div`
  border-radius: 16px;
  background: #fff;
  padding: 16px;
  width: 100%;
  /* width: 35%; */
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const AudienceHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: #7d7d7d;
    display: flex;
    gap: 8px;
  }
`;

export const ItemHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 13px;
`;

export const Icon = styled.img``;
export const Title = styled.div`
  color: #5e5e5e;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const KeywordsBox = styled.ul`
  width: 264px;
  height: 78px;
  flex-shrink: 0;
  border-radius: 4px;
  background: ${(props) => (props.demographic ? "#F3FFFE" : "#FFFCF3")};
  padding: 4px 10px;
  margin-bottom: 13px;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
    background-color: rgb(245 245 245 / 28%);
    border-radius: 10px;
  }

  ::-webkit-scrollbar {
    width: 5px;
    background-color: rgb(245 245 245 / 92%);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(0 0 0 / 7%);
    border-radius: 10px;
  }
`;

export const Keyword = styled.li`
  text-transform: capitalize;
  color: #666;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-left: 12px;
`;

export const OtherAiDetailsContainer = styled.div`
  width: 296px;
  height: 312px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FunnelContainer = styled.div`
  border-radius: 16px;
  background: #fff;
  padding: 16px;
  width: 100%;
  /* width: 35%; */
  height: 142px;
`;
export const NoDataWrap = styled.div`
  color: #898989;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.2px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const FunnelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: #7d7d7d;
    display: flex;
    gap: 8px;
  }
`;

export const FunnelList = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  p {
    border-radius: 16px;
    background: #f9f9f9;
    padding: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: #707070;
    height: fit-content;
  }
`;

export const AdHealthContainer = styled.div`
  border-radius: 16px;
  background: #fff;
  padding: 16px;
  width: 100%;
  /* width: 35%; */
  height: 152px;
  display: flex;
  flex-direction: column;
`;

export const AdHealthHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
  h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: #7d7d7d;
  }
  p {
    border-radius: 18px;
    background: #01b714;
    padding: 2px 8px;
    display: flex;
    align-items: baseline;
    gap: 4px;
    /* width: 115px; */
    img {
      height: 10px;
    }
  }
  span {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
  }
`;

export const AdHealthData = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  /* overflow: auto; */
  max-height: 71%;
`;

export const AdvancedFilter = styled.div`
  width: 696px;
  height: 269px;
  flex-shrink: 0;
  border-radius: 16px;
  background: #fff;
  padding: 24px 28px;
`;

export const Header = styled.div`
  color: #7d7d7d;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.2px;
`;

export const SemiBold = styled.span`
  color: #7d7d7d;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.2px;
`;

export const FilterLists = styled.div`
  display: flex;
  gap: 16px 50px;
  margin-top: 24px;
  flex-wrap: wrap;
  overflow: auto;
  height: 80%;
`;

export const Filter = styled.div`
  width: 240px;
`;
export const AdHealthInfo = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
  img {
    cursor: pointer;
  }
`;
export const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  width: 240px;
`;

export const FilterName = styled.div`
  color: #707070;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.3px;
  display: flex;
  gap: 6px;
  position: relative;
`;

export const InfoContainer = styled.div`
  position: relative;
  img {
    cursor: pointer;
  }
`;

export const FilterResult = styled.div`
  color: #707070;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.3px;
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 8px;
  background: #e0e0e0;
`;

export const ProgressValue = styled.div`
  width: ${(props) =>
    props.adCopySentiment ? "100%" : props.value ? `${props.value}%` : "0"};
  height: 100%;
  background: ${(props) =>
    props.adCopySentiment === "Positive"
      ? "#9EE89E"
      : props.adCopySentiment === "Negative"
      ? "#E89E9E"
      : props.adCopySentiment === "Neutral"
      ? "#E8B49E"
      : "#78A8CF"};
  border-radius: 8px;
`;

// export const KeywordsWrap = styled.div`

// `
export const KeywordsContainer = styled.div`
  border-radius: 16px;
  background: #fff;
  padding: 16px;
  gap: 12px;
  width: 276px;
  height: 312px;
  display: flex;
  flex-direction: column;
  h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: #7d7d7d;
    display: flex;
  }
`;
export const KeywordsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  height: fit-content;
  overflow-y: auto;
  p {
    border-radius: 10px;
    background: rgb(242 242 242);
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: rgb(112, 112, 112);
    height: fit-content;
  }
`;
