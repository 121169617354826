import styled from "styled-components";

export const Wrapper = styled.div`
// padding: 32px 112px 0 82px;
display: flex;
flex-direction: column;
align-items: center;
width: 1012px;
`
export const KeywordWrap  = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
max-width: 966px;
width: 100%;

`
export const KeywordTracking  = styled.div`
color: #000;
font-family: 'Inter';
font-size: 20px;
font-weight: 600;
`
export const AddKeyword  = styled.div`
border-radius: 6px;
background: #FF711E;
color: #FFF;
font-family: 'Inter';
font-size: 14px;
font-weight: 500;
width: 154px;
height: 40px;
display: flex;
align-items: center;
justify-content: center;
column-gap: 8px;
cursor: pointer;
`
export const TableWrap  = styled.div`
max-width: 100%;
border-radius: 10px;
background: #FFF;
margin-top: 19px;
width: 100%;
min-height: 136px;
`
export const TableHeader  = styled.div`
display: flex;
// justify-content: space-between;
align-items: center;
padding: 0 24px;
color: #000;
font-family: Inter;
font-size: 14px;
font-weight: 400;
border-radius: 6px 6px 0px 0px;
// background: #E3E8EE;
// max-width: 966px;
height: 68px;
border-bottom: 1px solid #EEE;
img{
        height: 16px;
        margin-left: 5px;
    }   
`
export const KeyHeader  = styled.div`
width: 32%;
cursor: pointer;
display: flex;
`
export const StatusHeader  = styled.div`
// text-align: center;
width: 32%;
cursor: pointer;
// justify-content: center;
display: flex;
`
export const PlatformHeader  = styled.div`
// text-align: center;
width: 15%;
display: flex;
// justify-content: center;
cursor: pointer;
`
export const UpdatedHeader  = styled.div`
text-align: center;
width: 20%;
cursor: pointer;
justify-content: center;
display: flex;
`
export const CountryHeader  = styled.div`
text-align: center;
width: 17%;
cursor: pointer;
justify-content: center;
display: flex;
`

export const TableBody  = styled.div`
height: 90%;
    overflow-y: auto;
`
export const Row  = styled.div`
/* width: 100%; */
display: flex;
align-items: center;
border-bottom: 1px solid #E3E8EE;
height:69px;
margin: 0px 24px;

`
export const HoverWrapper  = styled.div`
display: flex;
align-items: center;
width: 100%;
height:45px;
padding: 0 10px;
&:hover{
    background: #FFF7E8;
    cursor: pointer;
}
`
export const KeyData  = styled.div`
width: 32%;
color: rgba(28, 28, 28, 0.50);
font-family: Inter;
font-size: 14px;
// font-style: italic;
font-weight: 400;
`
export const StatusData  = styled.div`
// width: 15%;
display: flex;
color: ${props => props.connected ? 'rgba(49, 128, 72, 1)' : ' rgba(28, 28, 28, 0.30)'};
font-family: Inter;
font-size: 14px;
font-weight: 400;
border-radius: 4px;
background-color: ${props => props.connected ? '#E1FAEA' : ' rgba(218, 223, 228, 0.20)'};
// background: rgba(218, 223, 228, 0.20);
display: inline-flex;
padding: 4px 12px;
justify-content: center;
align-items: center;
p{
width: fit-content;
border-radius: 4px;
background: #E1FAEA;
padding: 4px 12px;
color: #318048;
font-family: 'Inter';
font-size: 14px;
font-weight: 400;
&.inactive{
    color: #B00020;
font-family: 'Inter';
font-size: 14px;
font-weight: 400;
border-radius: 4px;
background: #F9EBED;
padding: 4px 12px;

}
}
`
export const PlatformData  = styled.div`
text-align: center;
width: 16%;
button{
border-radius: 6px;
background: #FF711E;
color: #FFF;
font-family: Poppins;
font-size: 14px;
font-weight: 400;
border: none;
padding: 6px 12px;
}

`
export const UpdatedData  = styled.div`
text-align: center;
width: 20%;
color: rgba(28, 28, 28, 0.60);
font-family: 'Inter';
font-size: 14px;
font-weight: 500;
`
export const CountryData  = styled.div`
text-align: center;
width: 17%;
color: rgba(28, 28, 28, 0.60);
font-family: 'Inter';
font-size: 14px;
font-weight: 500;
`