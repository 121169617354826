import styled from "styled-components";

export const SortDropDown = styled.div`
    height: 100%;
    width: 112px;
    position: relative;
`;

export const SelectedSortType = styled.div`
    border-radius: 4px;
    border: 1px solid #CFD0D1;
    background: #FFF;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
`;

export const SortTypeDropDown = styled.div`
    position: absolute;
    top: 44px;
    z-index: 5;
    width: 280px;
    height: 278px;
    border-radius: 4px;
    border: 1px solid #CFD0D1;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const SortOptionList = styled.div`
    padding: 16px;
    h3{
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 4px;
        color: rgba(28, 28, 28, 0.90);
        letter-spacing: 0.14px;
    }
`;

export const SortOption = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(28, 28, 28, 0.80);
    letter-spacing: 0.14px;
    padding: 12px 0px;
    border-bottom: 1px solid #DADFE4;
    display: flex;
    justify-content: space-between;
    padding-right: 5px;
    cursor: pointer;
`;

export const Footer = styled.div`
    padding: 12px;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #CFD0D1;
`;

export const ApplyBtn = styled.button`
    width: 108px;
    height: 26px;
    border-radius: 3px;
    background: #FF711E;
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    outline: none;
    border: none;
    color: #FFF;
`;
