import projectLogo from "../../assests/projectLogo.svg";
import projectAlign from "../../assests/projectAlign.svg";
import searchKeyword from "../../assests/search-status.svg";
import competitorsLogo from "../../assests/global-search.svg";
import textAdsLogo from "../../assests/textAds.svg";
import imageAdsLogo from "../../assests/ImageAds.svg";
import folder from "../../assests/folder.svg";

export default {
  project: `Project Dashboard`,
  totalProjectCreated: "5",
  heading: [
    {
      id: 1,
      projectLogo: projectAlign,
      name: "Project",
      widthValue: "16.1%",
    },
    {
      id: 2,
      projectLogo: searchKeyword,
      name: "Keyword Tracking",
      widthValue: "23.3%",
    },
    {
      id: 3,
      projectLogo: competitorsLogo,
      name: "Competitors Ontrack",
      widthValue: "24.2%",
    },
    {
      id: 4,
      projectLogo: textAdsLogo,
      name: "Text Ads",
      widthValue: "11.91%",
    },
    {
      id: 5,
      projectLogo: imageAdsLogo,
      name: "Image Ads",
      widthValue: "11.91%",
    },
    { id: 6, projectLogo: folder, name: "saved Ads", widthValue: "11.91%" },
  ],
  projectDetails: [
    {
      projectImage: projectLogo,
      projectName: "Flonnect",
      updateNo: 5,
      updateStatus: "new updates",
      projectUrl: "couponswala.com",
      keywordTrack: [
        "extension",
        "screen recorder extension",
        "extension",
        "screen recorder extension",
        "screen recorder extension",
        "screen recorder extension",
      ],
      Competitors: [
        "loom.cam",
        "wordpress.cam",
        "storyxpress.cam",
        "hikemoun.cam",
        "hikemoun.cam",
      ],
      textAds: "25",
      imageAds: "",
      savedAds: "25",
    },
  ],
};
