
import React, { useState, useRef, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import Skeleton from "react-loading-skeleton";
import { useNavigate, useLocation } from "react-router-dom"
import html2canvas from 'html2canvas'
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

import { parseHTML } from '../../../../../../utils/parseHtml';
import {
    useMutation
} from "@tanstack/react-query";
import { appClient } from '../../../../../../utils/axios-utils'
import { Container, BackButton, BodyContainer, BodyLeft, ImageCarousel, UpArrow, DownArrow, CurrentImage, BodyRight, FooterContainer, CarouselContainer, CarouselImageContainer, InputDetailsContainer, ToggleInputContainer, InputType, InputWrapper, InputRow, DownloadButton } from "./index.styled";

import BackArrow from "../../../../../../media/Dashboard/backarrow.svg"

import DownArrowLogo from "../../../../../../media/Dashboard/downarrow.svg"
import TIcon from "../../../../../../media/Dashboard/Ticon.svg"
import StartIcon from "../../../../../../media/Dashboard/startsIcon.svg"
import UploadLogo from "../../../../../../media/Dashboard/UploadLogo.svg"
import { ImageAdGenerationProvider } from "./context/imageAdGeneration";
import { useImageAdGeneration } from "./context/imageAdGeneration";
import { downloadBase64Image } from "../../../../../../utils/imageDownload"
import { getSearchParams } from "../../../../../../utils/helper";

const getTemplateDetails = async (data) => {
    const config = {
        url: `/imagead/individual-template`,
        method: "POST",
        data
    }

    return appClient(config)
}

const AdGeneration = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const { imageAdGenerationState, imageAdGenerationDispatch } = useImageAdGeneration()
    const [selectedTemplateVariation, setSelectedTemplateVariation] = useState(0)
    const [downloadingStatus, setDownLoadingStatus] = useState(false)
    const [scaleValue, setScaleValue] = useState(1)
    const activeVariation = imageAdGenerationState.template.variants[selectedTemplateVariation]
    const totalItems = imageAdGenerationState.template.variants.length

    const trackRef = useRef(null)
    const baseImageRef = useRef(null)

    const { mutate: getTemplateDetailsMutate, isLoading: isTemplateDetailsLoading } = useMutation(getTemplateDetails, {
        onSuccess: (data) => {
            imageAdGenerationDispatch({ type: 'UPDATE_TEMPLATE', value: data[0] })

            setTimeout(() => {
                setSelectedTemplateVariation(0)
            }, 1000);
        },
        onError: ((e) => {

        })
    })

    const downloadImage = async () => {
        if (baseImageRef.current) {
            document.querySelectorAll(`div`).forEach((element) => {
                element.style.outline = "none";
            })
            setDownLoadingStatus(true)
            // const fontEmbedCss = await htmlToImage.getFontEmbedCSS(baseImageRef.current);
            toPng(baseImageRef.current, { 
                    cacheBust: true, 
                    pixelRatio: scaleValue,             
                    // fontEmbedCss: fontEmbedCss // Pass fontEmbedCss option here
                })
                .then((dataUrl) => {
                    const link = document.createElement('a')
                    link.download = 'my-image-name.png'
                    link.href = dataUrl
                    link.click()
                    setDownLoadingStatus(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const parsedHtmlRef = useRef(null)

    const [inputFields, setInputFields] = useState([])
    // const [currentBgColorIndex, setCurrentBgColorIndex] = useState(0)

    useEffect(() => {
        parsedHtmlRef.current = parseHTML(imageAdGenerationState.template.htmlString)

        const fields = []

        if(parsedHtmlRef.current.querySelector('[scale]')){
            let templateScaleVal = parsedHtmlRef.current.querySelector('[scale]').getAttribute('scale')
            setScaleValue(Number(templateScaleVal))
        }       

        parsedHtmlRef.current.querySelectorAll('*').forEach((element) => {
            const attributes = element.attributes;

            for (let i = 0; i < attributes.length; i++) {
                const attributeName = attributes[i].name;

                if (attributeName.startsWith('input-')) {

                    const uniqueId = `field-${uuidv4()}`;
                    element.setAttribute('data-field-id', uniqueId)

                    // console.log(element, element.innerText)
                    fields.push({
                        tagName: element.tagName,
                        attributeName,
                        attributeValue: attributes[i].value ? attributes[i].value : "",
                        uniqueId,
                        innerText: element.innerText ? element.innerText : ""
                    })
                }
            }
        })

        setInputFields(fields)

    }, [imageAdGenerationState.template.htmlString])

    useEffect(() => {
        let document = parseHTML(imageAdGenerationState.template.htmlString)
     
        document.querySelectorAll("*").forEach((item) => {
          const attributes = item.attributes;
    
          for (let i = 0; i < attributes.length; i++) {
            const attribute = attributes[i];
            const attributeName = attribute.name;
     
            const [prefix, type] = attributeName.split('-');
     
            if (prefix === 'change') {
              switch (type) {
                case 'background':
                    item.style.backgroundColor = activeVariation.hex;
                    break;
                case 'text':
                    item.style.color = activeVariation.hex;
                    break;
                case 'svg':
                    item.style.fill = activeVariation.hex;
                    break;
                case 'border':
                    item.style.borderColor = activeVariation.hex;
                    break;
                case 'gradient':
                    item.style.stopColor = activeVariation.hex;
                    break;
                default:
                  break;
              }
            }
          }
        })
    
        const modifiedHtml = new XMLSerializer().serializeToString(document);
    
        imageAdGenerationDispatch({ type: 'UPDATE_HTML', modifiedHtml });
    }, [selectedTemplateVariation])

    useEffect(() => {
        let searchData = getSearchParams(location.search)

        const { id } = searchData

        getTemplateDetailsMutate({templateId: id})
    }, [])

    // HANDLE CHANGE
    const handleInputImageChange = (event, { tagName, uniqueId }) => {

        console.log(event, tagName, uniqueId)
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];


            if (file) {
              const reader = new FileReader();
        
              reader.onload = () => {
                let baseImageData = reader.result

                parsedHtmlRef.current.querySelectorAll(`[data-field-id="${uniqueId}"]`).forEach((element) => {

                    if (tagName.toLowerCase() === "img") {
                        element.src = baseImageData
                    } else {
                        element.style.backgroundImage = `url(${baseImageData})`
                    }
    
                    const modifiedHtml = new XMLSerializer().serializeToString(parsedHtmlRef.current)
    
                    imageAdGenerationDispatch({ type: 'UPDATE_HTML', modifiedHtml })
                })
              };
        
              reader.readAsDataURL(file);

              
            }

            
        }

    }

    const handleInputTextChange = (event, { uniqueId }) => {
        parsedHtmlRef.current.querySelectorAll(`[data-field-id="${uniqueId}"]`).forEach((element) => {
            element.textContent = event.target.value
            const modifiedHtml = new XMLSerializer().serializeToString(parsedHtmlRef.current)

            imageAdGenerationDispatch({ type: 'UPDATE_HTML', modifiedHtml })
        })
    }

    const handleItemClick = (index) => {
        setSelectedTemplateVariation(index)
        const track = trackRef.current
        if (track) {
            const itemHeight = track.scrollHeight / totalItems
            track.scrollTo({ top: index * itemHeight, behavior: 'smooth' })
        }
    }

    const handlePrevClick = () => {
        if (selectedTemplateVariation > 0) {
            setSelectedTemplateVariation((prevIndex) => prevIndex - 1)
            const track = trackRef.current
            if (track) {
                const itemHeight = track.scrollHeight / totalItems
                track.scrollTo({ top: (selectedTemplateVariation - 1) * itemHeight, behavior: 'smooth' })
            }
        }
    }

    const handleNextClick = () => {
        if (selectedTemplateVariation < totalItems - 1) {
            setSelectedTemplateVariation((prevIndex) => prevIndex + 1)
            const track = trackRef.current
            if (track) {
                const itemHeight = track.scrollHeight / totalItems
                track.scrollTo({ top: (selectedTemplateVariation + 1) * itemHeight, behavior: 'smooth' })
            }
        }
    }

    const onFocusInput = (event, { attributeValue }) => {
        document.querySelectorAll(`*`).forEach((element) => {
            const attributeKeys = Array.from(element.attributes).map(attr => attr.name);
            if (attributeKeys.some(key => key.includes("input-"))) {
                const matchingAttribute = attributeKeys.find(key => key.includes("input-"));
                if(element.getAttribute(matchingAttribute) == attributeValue){
                    element.style.outline = "solid 2px black";
                }else{
                    element.style.outline = "none"
                }
            }
        })
    }

    return <Container>
        <BackButton onClick={() => navigate(-1)}>
            <img src={BackArrow} />
        </BackButton>
        <BodyContainer>
            <BodyLeft>
                <ImageCarousel>
                    <UpArrow onClick={handlePrevClick} isDisabled={isTemplateDetailsLoading || selectedTemplateVariation == 0}>
                        <img src={DownArrowLogo} />
                    </UpArrow>
                    <CarouselContainer ref={trackRef}>
                        {
                            isTemplateDetailsLoading ? [...Array(5)].map(d => {
                                return <Skeleton style={{
                                    lineHeight: 3,
                                    width: 50,
                                }} />
                            })
                            :
                            imageAdGenerationState.template.variants.map((data, idx) => {
                                return <CarouselImageContainer isSelected={selectedTemplateVariation == idx} color={data.hex} onClick={() => handleItemClick(idx)}>
                                    <img src={data.imageUrl} />
                                </CarouselImageContainer>
                            })
                        }
                    </CarouselContainer>
                    <DownArrow onClick={handleNextClick} isDisabled={isTemplateDetailsLoading || selectedTemplateVariation == (imageAdGenerationState.template.variants.length - 1)}>
                        <img src={DownArrowLogo} />
                    </DownArrow>
                </ImageCarousel>
                <CurrentImage>
                    {isTemplateDetailsLoading ? <Skeleton style={{
                        lineHeight: 20,
                        width: 540,
                    }} /> :
                        <div ref={baseImageRef} dangerouslySetInnerHTML={{ __html: `${imageAdGenerationState.template.htmlString}` }}></div>
                        }
                        {/* <img src={templateArr[selectedTemplateVariation]}/> */}
                </CurrentImage>
            </BodyLeft>
            <BodyRight>
                <h2>
                    Edit Panel
                </h2>
                {isTemplateDetailsLoading ?
                    <Skeleton style={{
                        lineHeight: 20,
                        width: 540,
                    }} /> :
                    <InputDetailsContainer>
                        <InputWrapper>
                            {inputFields.map((field, index) => (
                                <InputRow isFileInput={(field.tagName.toLowerCase() === "img" || field.attributeName === "input-bg")} key={index}>
                                    <label>{field.attributeValue}</label>
                                    {(field.tagName.toLowerCase() === "img" || field.attributeName === "input-bg") ? (
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={(event) => handleInputImageChange(event, field)}
                                        />
                                    ) : (
                                        <input
                                            type="text"
                                            placeholder="Enter text here"
                                            value={field.innerText ? field.innerText : ""}
                                            onChange={(event) => {
                                                handleInputTextChange(event, field);
                                            }}
                                            onFocus={(event) => onFocusInput(event, field)}
                                        />
                                    )}
                                </InputRow>
                            ))}
                            {/* <InputRow>
                                <label>Logo</label>
                                <input type="file" id="upload" hidden/>
                                <label className="logo-label" for="upload"><img src={UploadLogo}/>Upload</label>
                            </InputRow>
                            <InputRow>
                                <label>Heading 1*</label>
                                <input placeholder="Enter text here"/>  
                            </InputRow> */}
                        </InputWrapper>
                        <ToggleInputContainer>
                            <InputType disabled={true}>
                                <img className="star-icon" src={StartIcon} />Edit with <span>Adspyder AI</span>(soon)
                            </InputType>
                            <InputType>
                                <img src={TIcon} />Edit Manually
                            </InputType>
                        </ToggleInputContainer>
                    </InputDetailsContainer>}
            </BodyRight>
        </BodyContainer>
        <FooterContainer>
            {!isTemplateDetailsLoading && <DownloadButton isDisabled={downloadingStatus} onClick={downloadImage}>Download{downloadingStatus ? "...": ""}</DownloadButton>}
        </FooterContainer>
    </Container>
}

const AdGenerationPage = () => {
    return <ImageAdGenerationProvider>
        <AdGeneration />
    </ImageAdGenerationProvider>
}

export default AdGenerationPage



