import { useTextAdGenerator } from "../../../../context/textAdGeneration";
import { Cancel, FooterContainer, Save } from "./Footer.styled";

function Footer({popupSelectedGoal, newlySelectedCountry, newDescription, newSeedKeywordsList}) {
    const { textAdGeneratorState, textAdGeneratorDispatch } = useTextAdGenerator()


    const onCancel = () => {
        textAdGeneratorDispatch({
            type: "UPDATE_ACTIVE_CUSTOMIZE_POPUP",
            value: null
        })
    }
    
    const saveSelectedGoal = () => {
        if(textAdGeneratorState.adGoal && textAdGeneratorState.adGoal?.id !== popupSelectedGoal.id)
        {
            textAdGeneratorDispatch({type: "SET_AD_GOAL", value: popupSelectedGoal})
            textAdGeneratorDispatch({type: "SET_AD_DETAILS_UPDATED", value: true})
        }
    }

    const saveNewTargetLocation = () => {
        if(textAdGeneratorState.targetLocation && textAdGeneratorState.targetLocation !== newlySelectedCountry){
            textAdGeneratorDispatch({type: "SET_TARGET_LOCATION", value: newlySelectedCountry})
            textAdGeneratorDispatch({type: "SET_AD_DETAILS_UPDATED", value: true})
        }
    }

    const saveNewDescription = () => {
        if(textAdGeneratorState.brandDescription && textAdGeneratorState.brandDescription !== newDescription){
            textAdGeneratorDispatch({type: "SET_BRAND_DESCRIPTION", value: newDescription})
            textAdGeneratorDispatch({type: "SET_AD_DETAILS_UPDATED", value: true})
        }
    }

    const saveNewSeedKeywords = () => {
        if(textAdGeneratorState.seedKeywordsList && textAdGeneratorState.seedKeywordsList !== newSeedKeywordsList){
            // textAdGeneratorDispatch({type: "SET_BRAND_DESCRIPTION", value: newDescription})
            textAdGeneratorDispatch({type: "SET_AD_DETAILS_UPDATED", value: true})
        }
    }

    const handleSave = () => {
        switch(textAdGeneratorState.activeCustomizePopup?.id){
            case 1:
                saveSelectedGoal()
                onCancel()
            break

            case 2:
                saveNewTargetLocation()
                onCancel()
            break

            case 3: 
                saveNewDescription()
                onCancel()
            break

            case 4: 
                saveNewSeedKeywords()
                onCancel()
            break

            default:
            break
            
        }
    }
    return ( 
        <FooterContainer isAdGoal={textAdGeneratorState.activeCustomizePopup?.id === 1} >
            <Cancel onClick={onCancel} >Cancel</Cancel>
            <Save onClick={handleSave} >Save</Save>
        </FooterContainer>
     );
}

export default Footer;