import React, {useEffect} from "react";
import {useLocation} from 'react-router-dom'
import { Routes, Route } from "react-router-dom";

import { SavedAdsProvider } from "./context/savedads";
import SavedAdsHome from "./components/SavedAdsHome";
// import {useAccount} from "../../context/account";
import IndividualProject from "./components/IndividualAds";
// import NotFound from "../../../NotFoundPage";
import Layout from "../../layout/index";

const SavedAds = () => {
    // const {savedAdsState, savedAdsDispatch} = useSavedAds()
    // const { accountState, accountDispatch } = useAccount()
    // const location = useLocation()

    // useEffect(() => {
    //     savedAdsDispatch({type:'SET_SELECTED_COLLECTION', value: {}})
    // }, [])
    
    // useEffect(() => {
    //     if(location.pathname.includes("saved-ads")){
    //         accountDispatch({type: 'UPDATE_CURRENT_PAGE', value: 'saved-ads'})
    //     }
    // }, [])

    // return <>
    //         <Helmet>
    //             <meta charSet="utf-8" />
    //             <title>Profile | Saved Ads</title>
    //             <meta name="title" content="Profile | Saved Ads" />
    //             <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
    //         </Helmet>
    //         <BreadCrumb>
    //             <span>Account & Activity</span>{">"}
    //             <span>Saved Items</span>
    //             {savedAdsState.selectedCollection.id ? <>{">"}</> : ""}
    //             {savedAdsState.selectedCollection.id ? <span>{savedAdsState.selectedCollection.name}</span> : ""}
    //         </BreadCrumb>
    //         {!savedAdsState.selectedCollection.id && <SavedAdsHome/>}
    //         {savedAdsState.selectedCollection.id && <IndividualAds/>}
    //     </>
    // const { accountState, accountDispatch } = useAccount()

    const location = useLocation()

    useEffect(() => {
        if(location.pathname.includes("saved-ads")){
            // accountDispatch({type: 'UPDATE_CURRENT_PAGE', value: 'saved-ads'})
        }
    }, [])

    return <Routes>
                {/* <Route path='*' element={
                    <NotFound/>
                } /> */}
                <Route path="/" element={
                    <SavedAdsHome />
                } />
                <Route path="/details" element={
                    <IndividualProject />
                } />
            </Routes>
}


const SavedAdsProviderWrapper = () => {
    return <SavedAdsProvider>
        <Layout>
            <SavedAds/>
        </Layout>
    </SavedAdsProvider>
}

export default SavedAdsProviderWrapper


