import styled, { keyframes } from 'styled-components';

export const ResultsContainer = styled.div`
    display: flex;
    /* gap: 12px; */
    flex-direction: column;
    position: relative;
    height: ${props => props.noResults ? "70%" : props.restricted ? "100%" : props.resultLoading ? "calc(100vh - 402px)" : "unset"};
    /* height: calc(100vh - 245px); */
    background: #006ecc00;
    width: 100%;
    .loader{
        height: 200px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    /* position: relative; */
    h2{
        margin-bottom: 2px;
        font-size: 22px;
        font-weight: 600;
        line-height: 38px;
        color: #000;

        display: flex;
        align-items: center;
        max-width: 1532px;
        width: calc(100% - 108px);
        margin: 24px 54px;
    }
    ::-webkit-scrollbar {
        width: ${props => props.detailsModal ? "0px" : "8px"};               /* width of the entire scrollbar */
    }

    body::-webkit-scrollbar-track {
        /* background: red;        color of the tracking area */
    }
    ::-webkit-scrollbar-thumb {
        background-color: grey;    /* color of the scroll thumb */
        border-radius: 10px;     
    }
`;
export const ResultsWraper = styled.div`
    display: flex;
    flex-direction: column;
    /* flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    max-width: 1330px; */
    display: flex;
    align-items: center;
    max-width: 1532px;
    width: calc(100% - 108px);
    margin: 24px 54px;
`;
export const Card = styled.div`
    background: #FFFFFF;
    border: ${props => props.detailsOpen ? "2px solid #006ECC" : "1px solid #F2F2F2"} ;
    border-radius: 12px;
    /* width: 434px; */
    width: 475px;
    height: 496px;
    position: relative;
    
    .hover-element{
        display: none;
        
    }
    :hover{
        .hover-element{
            display: block;
            transition: all 0.5s ease-in-out;   
        }
    }
`;

export const AdDetailsBody = styled.div`
    overflow-y: scroll;
    height: calc(100vh - 330px);
    position: relative;
    padding-right: 5px;
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 16px 0px 0px;
    .title-wrapper-span{
        font-size: 18px;
        color: #1A0DAB;
        line-height: 27px;
        margin-bottom: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        /* max-width: 420px; */
        font-weight: 400;
        
        display: flex;
        flex-wrap: wrap;
    }

    .title-highlight-bold{
        font-weight: 400;
        font-size: 18px;
        padding: 0px 3px;
    }

    .content-wrapper-span{
        font-size: 14px;
        color: #2E2E2E;
        line-height: 22px;
        /* margin-bottom: 8px; */
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2; 
        -webkit-box-orient: vertical;
        height: 44px;
        font-weight: 300;
        display: flex;
        flex-wrap: wrap;
    }

    .content-highlight-bold{
        color: #2E2E2E;
        font-weight: 600;
        font-size: 14px;
        padding: 0px 2px;
    }
    
`;

export const CardFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid #F2F2F2;
    padding: 10px 16px;
`;

export const FooterLeft = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-direction: row;
    gap: 8px;
`;

export const FooterRight = styled.div`
    display: flex;
    margin-top: 16px;
    justify-content: flex-end;
    flex-direction: column;
`;

export const AdDate = styled.div`
    display: flex;
    font-size: 14px;
    color: #202124;
    line-height: 22px;
    align-items: center;
    margin-bottom: 16px;
    img{
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
`;

export const AdCountries = styled.div`
    display: flex;
    font-size: 14px;
    color: #202124;
    line-height: 22px;
    align-items: center;
    margin-bottom: 16px;
    img{
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
`;

export const DetailsButton = styled.div`
    display: flex;
    color: #006ECC;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
`;

export const CardsList = styled.div`
    display: flex;
    max-width: ${props => props.detailsModal ? "890px" : "1330px"};
    flex-wrap: wrap;
    gap: 12px;
    position: relative;
    
    @media screen and (max-width: 1650px) {
        max-width: ${props => props.detailsModal ? "445px" : "1330px"};
    }
    margin-top: 18px;
    overflow-y: scroll;
    height: calc(100vh - 335px);
    padding-right: 10px;
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const CloseDetails = styled.div`
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 15px;
    &:hover{
        background: #E2E2E2;
    }
`;

export const DetailsHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const DetailsContainer = styled.div`
    /* max-width: 724px; */
    position: sticky;
    top: 0px;
    width: 724px;
    /* min-width: 600px; */
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    border-radius: 12px;
    padding: 20px;
    padding-right: 15px;
    height: fit-content;
    h3{
        color: #707070;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 16px;
    }
`;

export const DetailsHeadline = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    display: flex;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    color: #707070;
    font-size: 14px;
`;

export const DetailDescription = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    display: flex;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    color: #707070;
    font-size: 14px;
`;

export const DestinationUrl = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    word-wrap: break-word;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    color: #707070;
    font-size: 14px;
`;

export const DateRange = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    color: #707070;
    font-size: 14px;
`;

export const AvgPosition = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    color: #707070;
    font-size: 14px;
`;

export const TagsContainer = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    display: flex;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
        margin-top: 5px;
    }
    color: #707070;
    font-size: 14px;
`;

export const CountriesContainer = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    display: flex;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
        margin-top: 5px;
    }
    color: #707070;
    font-size: 14px;
`;


export const CountriesList = styled.div`
    line-height: 22px;
    color: #707070;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
        margin-top: 5px;
    }
    span{
        background: #F9F9F9;
        border-radius: 8px;
        color: #707070;
        padding: 6px 12px;
    }
`;


export const TagsList = styled.div`
    line-height: 22px;
    color: #707070;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    span{
        background: #F9F9F9;
        border-radius: 8px;
        color: #707070;
        padding: 6px 12px;
    }
`;

export const SearchTermsContainer = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
        margin-top: 5px;
    }
    color: #707070;
    font-size: 14px;
`;

export const SearchTermsList = styled.div`
    margin-bottom: 16px;
    margin-top: 12px;
    line-height: 22px;
    color: #707070;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    span{
        background: #F9F9F9;
        border-radius: 8px;
        color: #707070;
        padding: 6px 12px;
        margin-right: 8px;
        margin-bottom: 10px;
    }
`;

export const LandingPageScreenShot = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
        margin-top: 5px;
    }
    color: #707070;
    font-size: 14px;
`;

export const ScreenshotContainer = styled.div`
    margin-top: 12px;
    img{
        max-width: 100%;
        max-height: 100%;
        display: block;
    }
`;


export const FloatingButtonsContainer = styled.div`
    position: absolute;
    right: 12px;
    top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F9F9F9;
    color: #D4D4D4;
    font-size: 8px;
    border-radius: 4px;
`;

export const Favorite = styled.div`
    background: #F9F9F9;
    width: 32px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    border-radius: 4px 0px 0px 4px;
    img{
        margin: 25%;
    }
    &:hover{
        background: #ECECEC;
    }
    &:active{
        background: #DCEEFF;
    }
    
`;

export const DropdownIcon = styled.div`
    background: #F9F9F9;
    width: 32px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    border-radius: 0px 4px 4px 0px;
    span{
        border: solid black;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        margin: 30%;
    }
    &:hover{
        background: #ECECEC;
    }
    &:active{
        background: #DCEEFF;
    }
`;

export const VideoContainer = styled.div`
    height: 225px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    border-radius: 5px;
    video{
        border-radius: 5px;
    }
`;

export const DetailsVideoContainer = styled.div`
    margin-bottom: 16px;
    height: 528px;
`;

export const DetailsImageContainer = styled.div`
    margin-bottom: 16px;
    height: 528px;
    width: 100%;
    background: #ECECEC;
    position: relative;
    img{
        width: 100%; /* Makes the image width 100% of the container width */
        height: 100%; /* Makes the image height 100% of the container height */
        object-fit: contain; 
    }
    span{
        position: absolute;
        right: 8px;
        background: #050505;
        border-radius: 4px;
        opacity: 0.5;
        color: #FFFFFF;
        font-size: 12px;
        padding: 4px;
    }
`;

export const ImageContainer = styled.div`
    height: 225px;
    margin: 0px 16px;
    /* width: 100%; */
    background: ${props => props.noImage ? "none" : "black"};
    /* margin-bottom: 20px; */

    img{
        width: 100%; /* Makes the image width 100% of the container width */
        height: 100%; /* Makes the image height 100% of the container height */
        object-fit: contain; 
    }
`;

export const ImageResolution = styled.div`
    position: absolute;
    top: 270px;
    right: 8px;
    background: #050505;
    border-radius: 4px;
    opacity: 0.5;
    color: #FFFFFF;
    font-size: 12px;
    padding: 4px;
`;

export const PageName = styled.p`
    margin-bottom: 16px;
    line-height: 22px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    color: #707070;
    font-size: 14px;
`;

export const ChannelDetails = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding: 0px 16px;
`;

export const DetailsChannelDetails = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`;

export const LogoContainer = styled.div`
    height: 36px;
    img{
        height: 36px;
        width: 36px;
        border-radius: 50%;

    }
`;

export const ChannelName = styled.p`
    width: 70%;
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #050505;
    display: flex;
    flex-direction: column;
    line-height: 24px;
    span{
        color: #65676B;
        font-size: 12px;
        font-weight: 300;
    }

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const Description = styled.p`
    margin-bottom: 16px;
    line-height: 22px;
    padding: 0px 16px;
    min-height: 42px;
    font-weight: 300;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    color: #2E2E2E;
    font-size: 14px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

export const PrimaryText = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    display: flex;

    /* padding: 0px 16px; */
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    color: #707070;
    font-size: 14px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`

export const Category = styled.div`
    margin-bottom: 16px;
    margin-top: 16px;
    line-height: 22px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    color: #707070;
    font-size: 14px;
`;

export const PublishedOnDetails = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    color: #707070;
    font-size: 14px;
`;

export const SecondaryDetails = styled.div`
    background: #F0F2F5;
    padding: 12px 15px;
    height: 80px;
    display: flex;
    justify-content: space-between;

    margin: 0px 16px;
    margin-top: 8px;
`;

export const InnerContent = styled.div`

`;

export const InnerDesc = styled.div`
    color: #050505;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`;

export const InnerDescription = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    display: flex;

    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    color: #707070;
    font-size: 14px;
`;

export const InnerTitle = styled.div`
    color: #707070;
    font-size: 12px;
    font-weight: 300;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`;

export const CallToActionButton = styled.div`

`;

export const CTA = styled.div`
    padding: 8px 10px;
    background: #9e9e9e29;
    border-radius: 5px;
    width: max-content;
`;

export const Headline = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    display: flex;

    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    color: #707070;
    font-size: 14px;
`;

export const ShortUrl = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    color: #707070;
    font-size: 14px;
`;

export const CallToAction = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    color: #707070;
    font-size: 14px;
`;

export const MediaType = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    color: #707070;
    font-size: 14px;
`;

export const NetworkTypes = styled.div`
    display: flex;
    margin-bottom: 16px;
    label{
        padding-top: 5px;
        color: rgb(46, 46, 46);
        font-size: 14px;
        margin-right: 8px;
    }
`;


export const NoResults = styled.div`
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
        width: 200px;
        margin-bottom: 32px;
    }
    h2{
        font-size: 22px;
        font-weight: 500;
        color: #505050;
        text-align: center;
        margin-bottom: 12px;
    }
    p{
        font-size: 14px;
        font-weight: 300;
        color: #4E4E4E;
        max-width: 360px;
        text-align: center;
    }
    a{
        font-size: 14px;
        font-weight: 300;
        color: #006ECC;
        text-align: center;
        margin-top: 16px;
    }
`;

export const RequestDataBtn = styled.div`
    height: 100%;
    margin-left: 8px;
    border-radius: 8px;
    background-color: ${props => props.dataRequested ? "none" : "rgb(255, 113, 30)"};
    font-size: 14px;
    border: none;
    cursor: pointer;
    padding: 9px 16px;
    margin-top: 20px;
    pointer-events: ${props => props.dataRequested || props.disable ? "none" : "auto"};
    width: ${props => props.dataRequested ? "auto" : "265px"};
    p{
        display: flex;
        max-width: 360px;
        justify-content: center;
        /* text-decoration: ${props => props.dataRequested || props.disable ? "none" : "underline"}; */
        /* color: ${props => props.dataRequested ? "#B0B0B0" : "#006ECC"}; */
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        color: ${props => props.dataRequested ? "#4e4e4e" : "#FFF"};
        pointer-events: ${props => props.dataRequested || props.disable ? "none" : "auto"};
        img{
            height: 14px;
            width: 14px;
            margin-left: 4px;
        }
    }
`;

export const CopyImg = styled.img`
    height: 18px;
    margin: 3px 6px;
    cursor: pointer;
`;

export const InnerUrl = styled.div`
    color: #B1B1B1;
    font-size: 14px;
    font-weight: 400;
`;

export const HoverCard = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: #1C1C1C59;
    border-radius: 12px;
    cursor: pointer;
    padding: 16px;

    transition-duration: 0.8s;
`;

export const AdDetailsButton = styled.div`
    border-radius: 24px;
    background: #FFF;
    width: fit-content;
    display: flex;
    padding: 10px 12px;
    gap: 4px;
    justify-content: center;
    align-items: center;
`;

export const SaveAdsButton = styled.div`
    border-radius: 24px;
    background: #FF711E;
    color: #FFF;
    display: flex;
    padding: 10px 12px;
    gap: 4px;
    justify-content: center;
    align-items: center;
    width: 75px;
`;

export const HoverCardWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
`;

export const BackToSearchContainer = styled.div`
    height: 65px;
    border-radius: 6px;
    background: #FFF7E8;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    max-width: 1457px;
    color: rgba(28, 28, 28, 0.80);
    display: flex;
    align-items: center;
    padding: 24px;
    margin: 24px 54px;

    display: flex;
    align-items: center;
    max-width: 1532px;
    width: calc(100% - 108px);
    
    span{
        color: #734A00;
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;
        margin-left: 4px;
    }
`;

export const AdCount = styled.div`
    height: 1px;
    background: #DADFE4;
    position: relative;
    display: flex;
    align-items: center;
    max-width: 1532px;
    width: calc(100% - 108px);
    margin: 24px 54px;
`;

export const AdCountWrap = styled.div`
    color: rgba(28, 28, 28, 0.50);
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    display: flex;
    gap: 4px;
    position: absolute;
    top: -10px;
    background: #f6f7fbfa;
    padding-right: 10px;
    p{
        color: rgba(115, 74, 0, 0.80);
    }
`;

export const CrawlRequestGreetContainer = styled.div`
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin: auto;
    height: 100%;
    margin-top: 15%;
    div{
        font-size: 14px;
        font-weight: 400;
        width: 100%;
        max-width: 1457px;
        color: rgba(28, 28, 28, 0.80);
        text-align: center;
        display: inline;
        span{
            color: #734A00;
            font-weight: 500;
            text-decoration: underline;
            cursor: pointer;
            margin-left: 4px;
        }
    }
`;

const slideInAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const ContentContainer = styled.div`
    /* opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.5s, transform 0.5s;
    ${({ greetingScreenOpen }) =>
        !greetingScreenOpen &&
        `opacity: 1;
        transform: translateY(0);
    `}
    animation-name: ${slideInAnimation} */
`;
