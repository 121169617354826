import React from 'react'
import { useReducer, createContext, useContext } from 'react'

import produce from 'immer'

import statics from '../statics'

const initialState = {
    currentPage: statics.pages[0],
    category: null,
    otherCategory: null,
    adGoal: null,
    targetAudience: {
        ageGroup: null,
        gender: null
    },
    brandDetails: {
        name: null,
        description: null
    },
    adType: null,
    savedCampaignId: null,
    downloadedHistory: null,
    selectedDownloadedTemplate: null,
    seedKeywords: "",
    seedKeywordsList: [],
    generatingProcesses: statics.processes,
    isResultGenerated: false,

    activeAdTemplate: null,
    currentTemplate: null,
    categoryTemplateList: [],

    activeNavItem: statics.navItems[0],
    activeCustomizeNavItem: statics.customizeNavItems[1],
    activeCustomizePopup: null,
    activeCustomStockImage:false,
    activeCustomAiImage:false,
    isAdDetailsUpdated: false,

    campaignId: null,
    activeColor: null,
    template: {
        html: null,
        variants: []
    },

    selectedStockImgUploadElement: {
        
    },

    scaleValue: 1,
    inputFields: [],
    updateStockImage: [],
    updateAiImage:[],
    adGoalToggle: false,
    adCategoryUpdate: false,

    overviewTemplateData: {}
}

const imageAdGeneratorReducer = produce((draft, action) => {
    switch(action.type) {

        case "RESET_PAGE":
            draft.currentPage = statics.pages[0]
            draft.category = null
            draft.adGoal = null
            draft.targetAudience = {
                ageGroup : null,
                gender : null
            }
            draft.brandDetails = {
                name : null,
                description : null
            }
            draft.adType = null
            draft.savedCampaignId = null
            draft.seedKeywords = ""
            draft.seedKeywordsList = []
            draft.generatingProcesses = statics.processes
            draft.isResultGenerated = false

            draft.activeAdTemplate = null
            draft.currentTemplate = null
            draft.categoryTemplateList = []

            draft.activeNavItem = statics.navItems[0]
            draft.activeCustomizeNavItem = statics.customizeNavItems[1]
            draft.activeCustomizePopup = null
            draft.isAdDetailsUpdated = false

            draft.campaignId = null
            draft.activeColor = null
            draft.template = {
                html : null,
                variants : []
            }

            draft.scaleValue = 1
            draft.inputFields = []
            draft.overviewTemplateData = {}
        break

        case "SET_CATEGORY":
            draft.category = action.value
        break

        case "SET_CAMPAIGNID":
            draft.campaignId = action.value
        break

        case "UPDATE_CURRENT_PAGE": 
            draft.currentPage = action.value
        break

        case "UPDATE_CAMPAIGN_DETAILS":
            draft.category = action.value.category
            draft.adGoal = action.value.goal
            draft.targetAudience.ageGroup = action.value.ageGroup
            draft.targetAudience.gender = action.value.gender
            draft.brandDetails.name = action.value.brandName
            draft.brandDetails.description = action.value.brandDescription
            draft.adType = action.value.adCategory
        break

        case "SET_AD_GOAL": 
            draft.adGoal = action.value
        break

        case "SET_TARGET_AUDIENCE": 
            draft.targetAudience = { ...draft.targetAudience, ...action.value}
        break

        case "SET_BRAND_DETAILS": 
            draft.brandDetails = {...draft.brandDetails, ...action.value}
        break

        case "SET_AD_TYPE": 
            draft.adType = action.value
        break

        case "SET_DOWNLOADED_HISTORY":
            draft.downloadedHistory = action.value
        break

        case "SET_SEED_KEYWORDS": 
            draft.seedKeywords = action.value
        break

        case "ADD_SEED_KEYWORD":
            draft.seedKeywordsList.push(action.value)
        break

        case "REMOVE_SEED_KEYWORD":
            draft.seedKeywordsList.splice(action.value.index)
        break

        case "UPDATE_PROCESSING_STATUS":
            draft.generatingProcesses[action.value.index].isCompleted = action.value.value
        break

        case "UPDATE_RESULT_GENERATED_FLAG":
            draft.isResultGenerated = action.value
        break

        case "SET_SAVED_CAMPAIGN_ID":
            draft.savedCampaignId = action.value
        break

        case "SET_CATEGORY_TEMPLATE_LIST":
            draft.categoryTemplateList = action.value
        break

        case "SET_ACTIVE_AD_TEMPLATE":
            draft.activeAdTemplate = action.value
        break

        case "SET_ACTIVE_NAV_ITEM":
            draft.activeNavItem = action.value
        break

        case "SET_ACTIVE_CUSTOMIZE_NAV_ITEM":
            draft.activeCustomizeNavItem = action.value
        break

        case "UPDATE_ACTIVE_CUSTOMIZE_POPUP":
            draft.activeCustomizePopup = action.value
        break

        case "SET_AD_DETAILS_UPDATED":
            draft.isAdDetailsUpdated = action.value
        break

        case "SET_SELECTED_DOWNLOADED_TEMPLATE":
            draft.selectedDownloadedTemplate = action.value
        break
        
        case "SET_ACTIVE_COLOR":
            draft.activeColor = action.value
        break

        case 'UPDATE_TEMPLATE':
            draft.template = action.value
        break

        case 'UPDATE_HTML':
            draft.template.html = action.modifiedHtml
        break

        case "SET_INPUT_FEILDS":
            draft.inputFields = action.value
        break

        case "SET_SCALE_VALUE":
            draft.scaleValue = action.value
        break

        case "UPDATE_ACTIVE_STOCKIMAGE_POPUP":
            draft.activeCustomStockImage = action.value
        break

        case "UPDATE_ACTIVE_AI_IMAGE_POPUP":
            draft.activeCustomAiImage = action.value
        break   

        case "UPDATE_STOCK_IMAGE":
            draft.updateStockImage = action.value
        break

        case "UPDATE_STOCK_IMG_UPLOAD_ELEMENT":
            draft.selectedStockImgUploadElement = action.value
        break

        case "AD_GOAL_TOGGLE":
            draft.adGoalToggle = action.value
        break

        case "AD_CATEGORY_UPDATE":
            draft.adCategoryUpdate = action.value
        break

        case "SET_CURRENT_TEMPLATE":
            draft.currentTemplate = action.value
        break

        case "SET_OVERVIEW_TEMPLATE_DATA":
            draft.overviewTemplateData = action.value
        break

        default: 
        break
    }
})

const useImageAdGeneratorReducer = () => {
    return useReducer(imageAdGeneratorReducer, initialState)
}

const ImageAdGeneratorContext = createContext()

export const ImageAdGeneratorProvider = ({children}) => {
    const [state, dispatch] = useImageAdGeneratorReducer()
    const value = { imageAdGeneratorState: state, imageAdGeneratorDispatch: dispatch }

    return <ImageAdGeneratorContext.Provider value={value}>
        {children}
    </ImageAdGeneratorContext.Provider>
}

export const useImageAdGenerator = () => {
    return useContext(ImageAdGeneratorContext)
}