import { useRef, useState } from "react";
import { useAdGenerator } from "../../../../context/adGeneration";
import statics from "../../../../statics";
import { Container, Controllers, DescriptionBox, Next, Optional, Skip, SubHeader } from "./index.styled";

function Description({onNext}) {
    const { steps } = statics
    const [adGoal, setAdGoal] = useState("")
    const { adGeneratorState, adGeneratorDispatch } = useAdGenerator()
    const handleNext = () => {
        adGeneratorDispatch({
            type: "SET_DESCRIPTION",
            value: adGoal
        })
        onNext()
    }
    
    return ( 
        <Container>
            <SubHeader>
                {steps[1].header}
                <Optional> (Optional)</Optional>
            </SubHeader>
            <DescriptionBox onChange={(e)=>setAdGoal(e.target.value)} ></DescriptionBox>
            <Controllers>
                <Skip onClick={onNext}>Skip</Skip>
                <Next isDisabled={!adGoal} onClick={handleNext} >Next</Next>
            </Controllers>
        </Container>
     );
}

export default Description;