import styled from "styled-components";

export const CampaignCount = styled.div`
    width: fit-content;
    padding: 16px 20px;
    height: 240px;

    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #F2F2F2;
    border-radius: 8px;

    h4{
        font-size: 14px;
        font-weight: 400;
        color: #2E2E2E;
    }
`;

export const Divider = styled.div`
    width: 12px;
    height: 1px;
    margin: 12px 0px;
    background: #D4D4D4;
`;

export const ActiveCampaigns = styled.div`
    margin-top: 8px;
    p{
        font-size: 28px;
        color: #2E2E2E;
        font-weight: 400;
        line-height: 42px;
    }
    span{
        font-size: 16px;
        color: #119F29;
    }
`;

export const TotalAds = styled.div`
    margin-top: 8px;
    p{
        font-size: 28px;
        color: #2E2E2E;
        font-weight: 400;
        line-height: 42px;
    }
    span{
        font-size: 16px;
        color: #119F29;
    }
`;