import React from "react"
import styled from "styled-components"
import { useSearchParams, useNavigate } from "react-router-dom"

import UrlSearchType from "./Type"
import UrlInput from "./Input"

import {useSearch} from "../../../context/search"
import _static from "../../../../../../../config/static"
import { useAuth } from "../../../../../../../context/auth"
import { useGetUserInfo } from "../../../../../../hooks/useGetUserInfo"


const Button = styled.button `
// width: 15%;
//     min-width: 75px;
//     max-width: 80px;
padding: 10px 18px;
    height: 44px;
    /* margin-left: 8px; */

    background-color: #FF711E;
    cursor: pointer;
    /* margin-top: 20px; */
    border-radius: 8px;
border: 1px solid #FF711E;
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
color: #FFF;
font-family: Inter;
font-size: 16px;
font-weight: 600;
margin-left: 7px;
    :hover {
        // filter: brightness(90%);
border: 1px solid #EE6819;
background: #EE6819;
    }
`;

const ExampleSearchContainer = styled.div`
display: flex;
justify-content: center;
gap: 8px;
margin: 18px 0 73px 0;
h3 {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #1c1c1c;
  /* margin-right: 6px; */
}

span {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #368cd5;
  cursor: pointer;
  margin-right: 4px;
}`

const ExampleWrap = styled.div`
display: flex;
flex-direction: column;
// width: 100%;
`

const UrlComponent = ({setCreditsExhaustedPopupActive}) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const {authState, authDispatch} = useAuth()
    const { canAccessFeature } = useGetUserInfo()
    const navigate = useNavigate()
    
    const { searchState, searchDispatch} = useSearch()  

    const redirectUrl = (input, mode) => {
      if(!canAccessFeature({type: "adspy"})){
        setCreditsExhaustedPopupActive(true)
        return
      }
        if (mode == "keyword") {
          navigate(
            `/adspy/${authState.searchPlatform}?searchMode=${mode}&keywordSearchType=phrase&phraseInput=${input}&page=1&excludedTerm=&searchIn=title%2Ccontent&sortType=latest`
          );
        } else if (mode == "url") {
          navigate(
            `/adspy/${authState.searchPlatform}?searchMode=${mode}&urlSearchType=exact&domainUrlInput=${input}&page=1&sortType=latest`
          );
        }
      };

    const handleSearch = () => {
      
        if(!canAccessFeature({type: "adspy"})){
          setCreditsExhaustedPopupActive(true)
          return
        }
        const {searchMode} = authState

        searchDispatch({type: "CLEAR_FILTERS"})

        if(!authState.searchPlatform){
          return authDispatch({type: 'SET_SEARCH_PLATFORM_ERROR', value: true})
        }

        if (searchMode === "url") {
            const {urlSearchType} = authState
            searchDispatch({type: "CLEAR_FILTERS"})

            if (urlSearchType === "exact") {
                const {domainUrlInput} = authState

                if (domainUrlInput.valid) {
                    authDispatch({type: "SET_HIGHLIGHT_WORDS", value: []})
                    authDispatch({type: 'SET_URL_INPUT_ERROR', value: false})


                    searchParams.set('searchMode',"url")
                    searchParams.set('urlSearchType',"exact")
                    searchParams.set('domainUrlInput', domainUrlInput.value);
                    searchParams.set('page', 1);
                    searchParams.set('sortType', 'latest');
                    searchParams.delete('selectedCountries');
                    searchParams.delete('startDate');
                    searchParams.delete('endDate'); 
                    searchParams.delete('cta'); 
                    searchParams.delete('tone'); 
                    searchParams.delete('otherFilters'); 
                    searchParams.delete('sentiment'); 
                    // setSearchParams(searchParams)
                    navigate(`/adspy/${authState.searchPlatform}?${searchParams.toString()}`)

                } else {
                  authDispatch({type: 'SET_URL_INPUT_ERROR', value: true})
                }
            } else if (urlSearchType === "domain") {
                const {domainUrlInput} = authState
                
                if (domainUrlInput.valid) {
                  authDispatch({type: "SET_HIGHLIGHT_WORDS", value: []})
                  authDispatch({type: 'SET_URL_INPUT_ERROR', value: false})

                    searchParams.set('searchMode',"url")
                    searchParams.set('urlSearchType',"domain")
                    searchParams.set('domainUrlInput', domainUrlInput.value);
                    searchParams.set('page', 1);
                    searchParams.set('sortType', 'latest');
                    searchParams.delete('selectedCountries');
                    searchParams.delete('startDate');
                    searchParams.delete('endDate'); 
                    // setSearchParams(searchParams)
                    navigate(`/adspy/${authState.searchPlatform}?${searchParams.toString()}`)

                } else {
                  authDispatch({type: 'SET_URL_INPUT_ERROR', value: true})
                }
            } else if (urlSearchType === "text") {
                const {domainUrlInput} = authState

                authDispatch({type: "SET_HIGHLIGHT_WORDS", value: []})

                searchParams.set('searchMode',"url")
                searchParams.set('urlSearchType',"text")
                searchParams.set('domainUrlInput', domainUrlInput.value);
                searchParams.set('page', 1);
                searchParams.set('sortType', 'latest');
                searchParams.delete('selectedCountries');
                searchParams.delete('startDate');
                searchParams.delete('endDate'); 
                // setSearchParams(searchParams)
                navigate(`/adspy/${authState.searchPlatform}?${searchParams.toString()}`)

            }

        }
    }

return <React.Fragment>
    <UrlSearchType/>
    <ExampleWrap>
    <UrlInput/>
    {authState.searchMode === "keyword" ? (
          <ExampleSearchContainer>
            <h3>
              Example:
              {/* <img src={TrendingIcon}/>:  */}
            </h3>
            <span onClick={() => redirectUrl("apple", "keyword")}>apple</span>
            <span onClick={() => redirectUrl("amazon", "keyword")}>amazon</span>
            <span onClick={() => redirectUrl("adspyder", "keyword")}>
              adspyder
            </span>
          </ExampleSearchContainer>
        ) : (
          <ExampleSearchContainer>
            <h3>Example:</h3>
            <span onClick={() => redirectUrl("apple.com", "url")}>
              apple.com
            </span>
            <span onClick={() => redirectUrl("amazon.in", "url")}>
              amazon.in
            </span>
            <span onClick={() => redirectUrl("flipkart.com", "url")}>
              flipkart.com
            </span>
          </ExampleSearchContainer>
        )}
    </ExampleWrap>
    <Button onClick={handleSearch}>Search</Button>
</React.Fragment>}

export default UrlComponent
