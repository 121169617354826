import React from "react";
import { ModalWrapper, DetailContainer, BackButton, NextButton, CloseButton, DetailWrapper } from "./index.styled"
import CrossImageWhite from "../../../media/Dashboard/crossWhite.svg"
import RightArrowWhite from "../../../media/Dashboard/RightArrowRight.svg";
import LeftArrowWhite from "../../../media/Dashboard/LeftArrowWhite.svg";

import GoogleDetail from "./components/Google"
import BingDetail from "./components/Bing"
import FacebookDetail from "./components/Facebook"
import YoutubeDetail from "./components/Youtube";
import LinkedInDetail from "./components/LinkedIn";
import GoogleEcomDetails from "./components/GoogleEcom";
import BingEcomDetails from "./components/BingEcom";
import AmazonDetail from "./components/amazon";
import DisplayDetail from "./components/Display";

const AdDetails = ({platform, basicDetail, onDetailsClose, highlightWords, navigateTo, onSave, crawledAd,noSaveNeeded, advancedFilters}) => {
    return <ModalWrapper>
        <DetailWrapper>
            <CloseButton onClick={()=>onDetailsClose()}  className={platform === "bing" ? "bingDetailContainer" : ""}>
                <img src={CrossImageWhite}/>
            </CloseButton>
            {/* <BackButton onClick={()=>navigateTo(basicDetail.id, "back")}> 
                <img src={LeftArrowWhite}/>
            </BackButton> */}
            <DetailContainer>
                {platform == "google" && <GoogleDetail onSave={onSave} highlightWords={highlightWords} basicDetail={basicDetail} advancedFilters={advancedFilters}/>}
                {platform == "bing" && <BingDetail onSave={onSave} highlightWords={highlightWords}  basicDetail={basicDetail}/>}
                {(platform == "meta" || platform == "facebook") && <FacebookDetail crawledAd={crawledAd} onSave={onSave} highlightWords={highlightWords}  basicDetail={basicDetail}/>}
                {platform == "youtube" && <YoutubeDetail onSave={onSave} highlightWords={highlightWords}  basicDetail={basicDetail}/>}
                {platform == "linkedin" && <LinkedInDetail onSave={onSave} highlightWords={highlightWords}  basicDetail={basicDetail}/>}
                {(platform == "googleEcom" || platform == "google-ecom") && <GoogleEcomDetails onSave={onSave} highlightWords={highlightWords}  basicDetail={basicDetail}/>}
                {platform == "bingEcom" && <BingEcomDetails onSave={onSave} highlightWords={highlightWords}  basicDetail={basicDetail}/>}
                {platform == "amazon" && <AmazonDetail onSave={onSave} highlightWords={highlightWords}  basicDetail={basicDetail}/>}
                {platform == "display" && <DisplayDetail onSave={onSave} highlightWords={highlightWords}  basicDetail={basicDetail}/>}

            </DetailContainer>
            {/* <NextButton onClick={()=>navigateTo(basicDetail.id, "next")}>
                <img src={RightArrowWhite}/>
            </NextButton> */}
        </DetailWrapper>
    </ModalWrapper>
}

export default AdDetails