import styled from "styled-components";

export const RootContainer = styled.div`
    height: 100%;
    padding: 34px 0px;
    max-width: 1096px;
`;

export const HomeRootContainer = styled.div`
position: absolute;
left: 0;
top: 64px;
width: 100%;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between; 
    align-items: end;
    margin-bottom: 15px;
`;

export const Title = styled.div`
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    margin-left: 6px;
`;

export const Query = styled.div`
    color: #8A8B8C;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-decoration-line: underline;
    cursor: pointer;
`;

export const Body = styled.div`
    display: flex;
    gap: 16px;
`;

export const WalthroughContainer = styled.div`
    width: 306px;
    height: 477px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #FFF;
    padding: 44px 37px;
`;

export const StepContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    position: relative;
    margin-bottom: 34px;
    cursor: pointer;
    pointer-events: ${(props) => props.isActive ? "pointer" : "none" };
`;

export const LogoContainer = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background: #FFA500;
`;

export const Logo = styled.img``

export const StepNoContainer = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border: 1px solid ${(props) => props.isCurrent ? "#FFA500" : "rgba(138, 139, 140, 0.20)"};
    background: #FFF;
`;

export const StepNo = styled.div`
    color: ${(props) => props.isCurrent ? "#FFA500" : "#8A8B8C"};
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
`;

export const Step = styled.div`
    color: ${(props) => props.isCurrent || props.isCompleted ? "#000" : "#8A8B8C"};
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
`;

export const CompletedLine = styled.div`
    width: 2px;
    height: 36px;
    background: ${(props) => props.isCompleted ? "#FFA500" : "rgba(138, 139, 140, 0.20)" };
    position: absolute;
    top: 24px;
    left: 11px;
    display: ${(props) => props.isLast ? "none" : "unset"};
`;

export const Content = styled.div`

`;

export const PageNavigation = styled.div`
    width: 141px;
    height: 40px;
    display: flex;
    gap: 17px;
    float: right;
    margin-top: 16px;
`;

export const BackContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    cursor: pointer;
`;

export const BackIcon = styled.img``;

export const BackText = styled.div`
    color: #8A8B8C;
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export const NextButton = styled.div`
    width: 72px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    border-radius: 6px;
    cursor: pointer;
    background: ${(props) => props.isActive ? "#FF711E" : "#DADFE4"};
    pointer-events: ${(props) => props.isActive ? "unset" : "none" };
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;