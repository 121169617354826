import { useState } from "react";
import { ContentWrap, ExitWrap, Header, ImgWrapper, StockImageContainer, OddWidthDiv, EvenWidthDiv, SearchWrapper, SearchButton, SearchContainer } from "./StockImagePopup.styled";
import { useImageAdGenerator } from "../../../../context/imageAdGeneration";
import cross from '../../../../assets/cross-small.svg';
import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import Masonry from "react-responsive-masonry"
import Skeleton from "react-loading-skeleton";
import { appClient } from "../../../../../../../../utils/axios-utils";
import SearchIcon from "../../../../../../../../media/Dashboard/SearchIconAdGen.svg"

const UpdateImgData = async (data) => {
    const config = {
        url: `/adgeneration/image/generate-stock-images`,
        method: "POST",
        data
    }
    return appClient(config)
}

const getStockImage = async (data) => {
    const config = {
        url: `adgeneration/image/generate-stock-images-on-query`,
        method: "POST",
        data
    }
    return appClient(config)
}

const StockImagePopup = () => {
    const { imageAdGeneratorState, imageAdGeneratorDispatch } = useImageAdGenerator()
    const [stockImageSearch, setStockImageSearch] = useState("")

    const { mutate: getStockImageSuggestion, isLoading: isGetStockImageSuggestionLoading } = useMutation(UpdateImgData, {
        onSuccess: (data) => {
            imageAdGeneratorDispatch({ type: 'UPDATE_STOCK_IMAGE', value: data })
        },
        onError: ((e) => {

        })
    })

    const { mutate: getStockImageMutate, isLoading: isGetStockImageLoading } = useMutation(getStockImage, {
        onSuccess: (data) => {
            imageAdGeneratorDispatch({ type: 'UPDATE_STOCK_IMAGE', value: data })
        },
        onError: ((e) => {

        })
    })

    const cancelStockFn = () => {
        imageAdGeneratorDispatch({
            type: "UPDATE_ACTIVE_STOCKIMAGE_POPUP",
            value: false
        })
    }

    const handleImageClick = (image) => {
        let baseImageData = image
        let htmlContent = document.querySelector(".final-image")

        htmlContent.querySelectorAll(`[data-field-id="${imageAdGeneratorState.selectedStockImgUploadElement.uniqueId}"]`).forEach((element) => {

            if (imageAdGeneratorState.selectedStockImgUploadElement.tagName.toLowerCase() === "img") {
                element.src = baseImageData
            } else {
                element.style.backgroundImage = `url(${baseImageData})`
            }

            const modifiedHtml = new XMLSerializer().serializeToString(htmlContent)

            imageAdGeneratorDispatch({ type: 'UPDATE_HTML', modifiedHtml })
        })

    }

    const onStockImageSearch = (value) => {
        setStockImageSearch(value)
    }

    const imageArr = [1, 2, 3, 4, 5, 6];

    useEffect(() => {
        getStockImageSuggestion({ "campaignId": imageAdGeneratorState.campaignId, templateId: imageAdGeneratorState.categoryTemplateList[imageAdGeneratorState.currentTemplate].id })
    }, [])


    return (
        <StockImageContainer>
            <ExitWrap>
                <img src={cross} onClick={cancelStockFn} />
            </ExitWrap>
            <ContentWrap>
                <Header>Stock images</Header>
                <SearchWrapper>
                    <SearchContainer>
                        <img src={SearchIcon} />
                        <input placeholder="Search Images" onChange={(e) => onStockImageSearch(e.target.value)} />
                    </SearchContainer>
                    <SearchButton isDisabled={(isGetStockImageSuggestionLoading || isGetStockImageLoading)} onClick={() => { getStockImageMutate({ query: stockImageSearch, "campaignId": imageAdGeneratorState.campaignId, templateId: imageAdGeneratorState.categoryTemplateList[imageAdGeneratorState.currentTemplate].id }) }}>
                        {(isGetStockImageSuggestionLoading || isGetStockImageLoading) ? "Loading..." : "Search"}
                    </SearchButton>
                </SearchWrapper>
                {(isGetStockImageSuggestionLoading || isGetStockImageLoading) ?
                    <ImgWrapper>
                        <Masonry gutter={'12px'}>
                            {imageArr.map((item, index) => (
                                index % 2 === 0 ? (
                                    <EvenWidthDiv key={index}>
                                        <Skeleton height={'100%'} />
                                    </EvenWidthDiv>
                                ) : (
                                    <OddWidthDiv key={index}>
                                        <Skeleton height={'100%'} />
                                    </OddWidthDiv>
                                )
                            ))}
                        </Masonry>
                    </ImgWrapper> :
                    <ImgWrapper>
                        <Masonry gutter={'12px'}>
                            {
                                imageAdGeneratorState.updateStockImage.length > 0 && imageAdGeneratorState.updateStockImage.map((item) => (
                                    <img onClick={() => handleImageClick(item)} src={item} />

                                ))
                            }

                        </Masonry>
                    </ImgWrapper>

                }
            </ContentWrap>
        </StockImageContainer>

    );
}

export default StockImagePopup;