import statics from "../../statics";
import { InputContainer, ExamplesContainer, Label, Example, HeadingLeft, HeadLeftWrap, HeaderRight, SearchContainer, HowItWork, HeadingWrapper, Wrapper, Container, DomainInput, PlaceholderWithInput, HttpsLabel, TrackButton } from "./EnteringDomain.styled";
import tooltip from "../../assets/tooltip.svg"
import { useEffect, useRef, useState } from "react";
import { useTextAdGenerator } from "../../context/textAdGeneration";
import { appClient } from "../../../../../../utils/axios-utils"
import {
    useMutation
} from "@tanstack/react-query"
import { OurOwnToolTip, ToolTipImageContainer } from "../../../../../layout/components/ResultPageSearch/components/Url/Input/index.styled";
import Tooltip from 'rc-tooltip';
import textAdGeneration from "../../assets/textAdGeneration.png"
import RollingLoad from "../../assets/RollingLoad.svg"

import { useAuth } from "../../../../../../context/auth";

function isValidDomain(url) {
    const domainAndUrlRegEx = /^(((http|https):\/\/|)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?)$/;

    return domainAndUrlRegEx.test(url);
}

const AddDomainData = async (data) => {
    const config = {
        url: `/text-ad-generation/generate/add-domain`,
        method: "POST",
        data
    }

    return appClient(config)
}
function EnteringDomain() {
    const { enteringDomain: {header, description, placeHolder, examplesLabel, example, generate}, pages } = statics
    const [inputError, setInputError] = useState(false)
    const domainRef = useRef(null)
    const { textAdGeneratorState, textAdGeneratorDispatch } = useTextAdGenerator()
    const { authState: {isNavCollapsed} } = useAuth()
    const [inputFocussed, setInputFocussed] = useState(false)

    const onGenerate = () => {
        if(domainRef.current){
            const domain = domainRef.current.value
            if(isValidDomain(domain)){
                textAdGeneratorDispatch({type: "SET_TARGET_DOMAIN", value: domain})
                getAddDomainData({"domain": domain})
            }
            else{
                setInputError(true)
            }
        }
    }
    const handleInputChange = () => {
        if(domainRef.current){
            const domain = domainRef.current.value
            textAdGeneratorDispatch({type: "SET_TARGET_DOMAIN", value: domain})
            if(isValidDomain(domain)){
                setInputError(false)
            }
            else setInputError(true)
        }
    }
    const { mutate: getAddDomainData, isLoading } = useMutation(AddDomainData, {    
        onSuccess: (data) => {
            if(data){
             textAdGeneratorDispatch({type: "UPDATE_CURRENT_PAGE", value: pages[1]})
             textAdGeneratorDispatch({type: "SET_ADD_DOMAIN_LIST", value: data.userAdId})
             textAdGeneratorDispatch({type: "SET_BRAND_DESCRIPTION", value: data.metaDescription})
             textAdGeneratorDispatch({type: "SET_SEED_KEYWORDS", value: data.suggestedSeedKeywords})
            }    
        },
        onError: (err) => {
            
        }
    })
    const handleExampleClick = () => {
        domainRef.current.value = example
        onGenerate()
    }
    useEffect(() => {
        if(domainRef.current)
        domainRef.current.value = textAdGeneratorState.targetDomain
    }, [])

    const headingText = "Text Ad Generation"
    const descriptionText = "Elevate your advertising strategy with our intelligent text ad generation feature. Harness the power of advanced algorithms to create compelling, targeted advertisements that capture your audience's attention. This innovative approach enhances ad relevance and effectiveness, ensuring optimal engagement and increased conversion rates. "


    const toolTipFunction = ()  =>{
        return (
            <div>
                <div style={{marginBottom: '16px',fontSize: '14px', color: 'var(--black-100, #1C1C1C)',fontWeight: '500'}}>How to use:</div>
                <ul style={{listStyleType: 'decimal',marginLeft: '16px'}}>
                    <li  style={{marginBottom: '16px'}}>Enter the domain name in the search bar and click on the “Generate” button. </li>
                    <li  style={{marginBottom: '16px'}}>In the “Define Ad Goal” section, click on the desired ad goal you want to proceed with. The options are “Sales”, “Leads”, or “Website Traffic”. Click on the “Next” button. </li>
                    <li  style={{marginBottom: '16px'}}>In the “Enter Target Location” section, type in the location city, state or country and select the location. Click on the “Next” button. </li>
                    <li  style={{marginBottom: '16px'}}>In the “Add brand description” section, type in your details regarding the product/brand/service. Provide more information for optimum results from AdSpyder’s AI. Click on the “Next” button. </li>
                    <li  style={{marginBottom: '16px'}}>In the “Enter Seed Keywords” section, type in your target seed keywords separated by commas. Click on the “Next” button. You will be directed to the Text Ad Generation status page, where you can track the progress of the ad generation process.
                    </li>
                    <li  style={{marginBottom: '16px'}}>In the results page, you can view generated ads for your input. You can switch between ad groups using the Ad Groups Tab. Clicking on the desired Ad Group tab will show you details on generated titles, descriptions and keywords needed to run the ad group. </li>
                    <li  style={{marginBottom: '16px'}}>To check out the titles generated in the ad group, click on the “Titles” tab. To copy the title, simply hover over the title and click on the copy icon at the end of the title. </li>
                    <li  style={{marginBottom: '16px'}}>To check out the descriptions generated in the ad group, click on the “Descriptions” tab. To copy the description, simply hover over the description and click on the copy icon at the end of the description. </li>
                    <li  style={{marginBottom: '16px'}}>To check out the keywords generated in the ad group, click on the “Keywords” tab. To copy the keyword, simply hover over the keyword and click on the copy icon at the end of the keyword. </li>
                    <li  style={{marginBottom: '16px'}}>To export the generated ad copy to your connected Google Ad Account, simply click on the “Export” tab at the top right corner of the workspace and click on the “Publish Ad Group” button. (Note: Google Ad Account needs to be connected with AdSpyder to export directly. See Ad Campaigns feature to know more on how to link your Google AD Account to your AdSpyder account) </li>
                    <li  style={{marginBottom: '16px'}}>To make any changes in the generated ads, or to refine your ad copy, click on the “Customize” tab at the top right corner of the workspace. To change any input to AdSpyder’s AI, click on the “Change” option at the end of the required input to edit it. After finishing, click on the “Save” button. After finishing the edits, click on the “Update” button to get new and improved results based on your edits to the inputs. </li>
                </ul>
            </div>
        )
    }

    const tooltipStyle = {
        marginTop: '100px'
    };
    
    const overlayStyle = {

    };

    const overlayInnerStyle = {
        backgroundColor: '#FFF7E8',
        color: 'rgba(28, 28, 28, 0.80)',
        padding: '24px 20px 44px 20px',
        fontSize: '14px',
        marginTop: '120px',
        width: '400px',
        maxHeight: '510px',
        paddingBottom: '44px',
        overflowY: 'auto'
    };     
console.log(textAdGeneratorState.targetDomain == null,'textAdGeneratorState.targetDomain',textAdGeneratorState.targetDomain)
    return(
        <Container>
          
            <Wrapper isNavCollapsed={isNavCollapsed}>
            
            
                <HeadingWrapper>
                    <HeadingLeft>
                        <HeadLeftWrap>
                        <h2>{headingText}</h2>
                        <Tooltip 
                            trigger={['hover']}  
                            style={tooltipStyle}
                            overlayStyle={overlayStyle}
                            overlayInnerStyle={overlayInnerStyle} 
                            showArrow={false}
                            arrowContent={null}
                            overlay={toolTipFunction()}
                        >
                            <HowItWork>Quick guide</HowItWork>
                        </Tooltip>
                        </HeadLeftWrap>
                        <p>{descriptionText}</p>
                    </HeadingLeft>
                    <HeaderRight>
                        <img src={textAdGeneration} />
                    </HeaderRight>
                </HeadingWrapper>
                <SearchContainer isNavCollapsed={isNavCollapsed}>
                    <PlaceholderWithInput style={{position: "relative"}} >
                        <HttpsLabel>https://</HttpsLabel>
                        <InputContainer isFocussed={inputFocussed} hasError={inputError}>
                            <DomainInput 
                                ref={domainRef} 
                                placeholder={placeHolder} 
                                isActive={domainRef?.current?.value !== "" ? true : false} 
                                value={
                                    textAdGeneratorState.targetDomain !== null ?
                                    textAdGeneratorState.targetDomain?.startsWith("https://") ? 
                                    textAdGeneratorState.targetDomain.split("https://")[1] 
                                    : textAdGeneratorState.targetDomain
                                    :""
                                } 
                                onChange={handleInputChange}
                                onFocus={() => setInputFocussed(true)}
                                onBlur={() => setInputFocussed(false)}
                            />
                            <ToolTipImageContainer
                                error={inputError} className="tooltip-container"
                            >
                                <img src={tooltip} />
                                <OurOwnToolTip>
                                    {"Please enter a valid domain"}
                                </OurOwnToolTip>
                            </ToolTipImageContainer>
                        </InputContainer>
                        <ExamplesContainer>
                                <Label>{examplesLabel}</Label>
                                <Example onClick={handleExampleClick}>{example}</Example>
                        </ExamplesContainer>
                    </PlaceholderWithInput>
                    
                    <TrackButton isActive={!!textAdGeneratorState.targetDomain && !inputError} onClick={onGenerate} >
                        {!isLoading ? generate : <img src={RollingLoad} height={'25'}/>}
                    </TrackButton>
                   
                </SearchContainer>
                
            </Wrapper>

        </Container>
    )
}

export default EnteringDomain;