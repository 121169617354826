import { useMutation } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import { appClient } from "../../../../../../../utils/axios-utils";
import { HistoryContainer, Header, Gallery, NavButton, NavImg, WrappingContainer, Holder, HoveredContainer, Details, Download } from "./index.styled";
import left from "../../../assets/left.svg"
import right from "../../../assets/right.svg"
import { useImageAdGenerator } from "../../../context/imageAdGeneration";
import DetailPopup from "./detail";

function History() {
    const { imageAdGeneratorState: {downloadedHistory}, imageAdGeneratorDispatch } = useImageAdGenerator()

    const templateHolderRefs = useRef([])


    const fetchDownloadedHistory = async() => {
        const config = {
            url: `adgeneration/image/generated-history`,
            method: "GET"
        }

        return appClient(config)
    }

    
    const { mutate: getHistory } = useMutation(fetchDownloadedHistory, {
        onSuccess: async (data) => {
            imageAdGeneratorDispatch({
                type: "SET_DOWNLOADED_HISTORY",
                value: data
            })
        },
        onError: ((e) => {})
    }) 

    
    useEffect(() => {
        getHistory()
    }, [])


    if(downloadedHistory && downloadedHistory.length){
        const isGalleryNavigationNeeded = downloadedHistory.length > 4

        const handleMouseEnter = (idx) => {
            const holderRef = templateHolderRefs.current[idx];
            if (holderRef) {
                holderRef.style.display = "block";
            }
        }

        const handleMouseLeave = (idx) => {
            const holderRef = templateHolderRefs.current[idx];
            if (holderRef) {
                holderRef.style.display = "none";
            }
        }

        const handleClick = (idx) => {
            imageAdGeneratorDispatch({
                type: "SET_SELECTED_DOWNLOADED_TEMPLATE",
                value: downloadedHistory[idx]
            })
        }
        
        const downloadTemplate = async (e,templateUrl) => {
            e.stopPropagation()
            try {
                // Fetch the image data as a blob
                const response = await fetch(`${templateUrl}?v=${new Date().getTime()}`);
                const blob = await response.blob();

                // Convert the blob to a Base64-encoded string
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    const base64Url = reader.result;
                    const link = document.createElement('a');
                    link.href = base64Url;
                    link.download = 'template_image.png';

                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                };
            } catch (error) {
                console.error('Error downloading template:', error);
            }
        };


        const renderTemplates = () => {
            return downloadedHistory.map((item, idx) => {
                return (
                    <Holder 
                    key={idx} 
                    onClick={() => handleClick(idx)}
                    url={item.templateUrl} 
                    onMouseEnter={() => handleMouseEnter(idx)}
                    onMouseLeave={() => handleMouseLeave(idx)}
                    className="downloaded-template" 
                    >
                        <HoveredContainer
                        ref={(el) => {
                            templateHolderRefs.current[idx] = el
                        }}>
                            <Details>View Details</Details>
                            <Download onClick={(e) => {
                                downloadTemplate(e,item.templateUrl)
                            }} >Download</Download>
                        </HoveredContainer>
                    </Holder>
                )
            })
        }

        return ( 
            <HistoryContainer>
                <WrappingContainer>
                    <Header>Download history</Header>
                
                    <Gallery>
                        {renderTemplates()}
                        {isGalleryNavigationNeeded && <NavButton isLeft>
                            <NavImg src={left} alt="left" />
                        </NavButton>}
                        {isGalleryNavigationNeeded && <NavButton isRight>
                            <NavImg src={right} alt="right" />
                        </NavButton>}
                    
                    </Gallery>
                </WrappingContainer>
                <DetailPopup />
            </HistoryContainer>
        );
    }
}

export default History;