import styled from "styled-components";

export const CustomizeContainer = styled.div`
    width: 400px;
    height: 100%;
    border-left: 1px solid #DADFE4;
    background: #FFF;
    position: absolute;
    right: 0;
    top: 0;
    margin-left: 109px;
    /* padding-left: 16px; */
`;

// export const Header = styled.div`
//     height: 58px;
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// `;

// export const Item = styled.div`
//     color: #1C1C1C;
//     font-family: Inter;
//     font-size: 16px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 150%; /* 24px */
// `;

export const Header = styled.div`
    margin: 30px 0 0 84px;
    color: #1C1C1C;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
`;


export const NavContainer = styled.div`
    display: flex;
    justify-content: space-around;
    height: 58px;
    align-items: center;
`;

export const NavItem = styled.div`
    width: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    cursor: pointer;
    p{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
`;

export const HighlightLine = styled.div`
    width: 100%;
    height: ${props => props.isActive ? "4px" : "2px"};
    background: ${props => props.isActive ? "#FF711E" : "rgba(218, 223, 228, 0.50)"};
    /* visibility: ${(props) => props.isActive ? "auto" : "hidden"}; */
`;