import awarenessLogo from "./assets/awareness.svg"
import interestLogo from "./assets/interest.svg"
import actionLogo from "./assets/action.svg"
import desireLogo from "./assets/desire.svg"
import loyaltyLogo from "./assets/loyalty.svg"

export default {
    heading: "Keyword Analysis",
    subHeadingLabel: (count) => { return `Over ${count} results for ` },
    funnelList: [
        {
            id: 1,
            name: "Awareness",
            logo: awarenessLogo,
            logoBgColor: "#FFF7E8",
        },
        {
            id: 2,
            name: "Interest",
            logo: interestLogo,
            logoBgColor: "#FFEEEE",
        },
        {
            id: 3,
            name: "Desire",
            logo: desireLogo,
            logoBgColor: "#F3F9FF",
        },
        {
            id: 4,
            name: "Action",
            logo: actionLogo,
            logoBgColor: "#E9FBF5",
        },
        {
            id: 5,
            name: "Loyalty",
            logo: loyaltyLogo,
            logoBgColor: "#FFF7EA",
        }
    ],
    navItems: [
        {
            id: 1,
            name: "Ad Copies"
        },
        {
            id: 2,
            name: "CTA's"
        },
        {
            id: 3,
            name: "Target Audience"
        },
        {
            id: 4,
            name: "Important keywords"
        }
    ],
    platforms:[
        {
            id: 1,
            name: "Google"
        },
        // {
        //     id: 2,
        //     name: "Bing"
        // }
    ],
    tempFunnelAdsData: {
        "processed": true,
        "ads": [
            {
                "country": [],
                "lastSeen": 1695138345814,
                "firstSeen": 1695138345814,
                "campaignId": "ADPR-YYDBGHCAHJ",
                "created_at": "2023-10-16T06:59:32.480Z",
                "id": "ADPR-YYDBGHCAHJ-bbdefa2950f49882f295b1285d4fa9dec45fc4144bfb07ee6acc68762d12c2e3-208",
                "position": 1,
                "title": "Apple Watch - Official Site - Watch line-up",
                "domainId": "apple.com",
                "url": "https://www.apple.com/in/watch",
                "content": "Introducing Apple Watch Series 9, Apple Watch SE and the new Apple Watch Ultra 2. It’s the ultimate device for a healthy life. Learn more."
            },
            {
                "country": [
                    "Brazil"
                ],
                "lastSeen": 1692719313493,
                "firstSeen": 1692719313493,
                "campaignId": "ADPR-RYVAVGNSFO",
                "created_at": "2023-11-27T09:25:50.554Z",
                "id": "ADPR-RYVAVGNSFO-bbdefa2950f49882f295b1285d4fa9dec45fc4144bfb07ee6acc68762d12c2e3-2",
                "position": 1,
                "title": "Apple - Site oficial",
                "domainId": "apple.com",
                "url": "https://www.apple.com/br/store",
                "content": "Compre agora os novos iPhone 15 Pro e iPhone 15. Saiba mais. Compre também o Mac, Apple Watch, iPad, AirPods, Apple TV, acessórios e muito mais. Fale com um especialista. Frete grátis."
            },
            {
                "country": [],
                "lastSeen": 1694676115928,
                "firstSeen": 1694676115928,
                "campaignId": "ADPR-FLNKBABMBE",
                "created_at": "2023-10-10T07:13:31.242Z",
                "id": "ADPR-FLNKBABMBE-bbdefa2950f49882f295b1285d4fa9dec45fc4144bfb07ee6acc68762d12c2e3-2",
                "position": 1,
                "title": "Yeni iPhone 15 ile tanışın | Apple Resmi Sitesi",
                "domainId": "apple.com",
                "url": "https://www.apple.com/tr/iphone-15",
                "content": "Bambaşka. Dynamic Island. 2 kat Telefoto, 48 MP Ana kamera. Ön sipariş tarihi 22 Eylül. Fark yaratmak için tasarlandı. iPhone 15’i yakından tanıyın. Yeni 48 MP..."
            },
            {
                "country": [],
                "lastSeen": 1695138345814,
                "firstSeen": 1695138345814,
                "campaignId": "ADPR-YYDBGHCAHJ",
                "created_at": "2023-10-16T06:59:32.480Z",
                "id": "ADPR-YYDBGHCAHJ-bbdefa2950f49882f295b1285d4fa9dec45fc4144bfb07ee6acc68762d12c2e3-208",
                "position": 1,
                "title": "Apple Watch - Official Site - Watch line-up",
                "domainId": "apple.com",
                "url": "https://www.apple.com/in/watch",
                "content": "Introducing Apple Watch Series 9, Apple Watch SE and the new Apple Watch Ultra 2. It’s the ultimate device for a healthy life. Learn more."
            },
            {
                "country": [
                    "Brazil"
                ],
                "lastSeen": 1692719313493,
                "firstSeen": 1692719313493,
                "campaignId": "ADPR-RYVAVGNSFO",
                "created_at": "2023-11-27T09:25:50.554Z",
                "id": "ADPR-RYVAVGNSFO-bbdefa2950f49882f295b1285d4fa9dec45fc4144bfb07ee6acc68762d12c2e3-2",
                "position": 1,
                "title": "Apple - Site oficial",
                "domainId": "apple.com",
                "url": "https://www.apple.com/br/store",
                "content": "Compre agora os novos iPhone 15 Pro e iPhone 15. Saiba mais. Compre também o Mac, Apple Watch, iPad, AirPods, Apple TV, acessórios e muito mais. Fale com um especialista. Frete grátis."
            },
            {
                "country": [],
                "lastSeen": 1694676115928,
                "firstSeen": 1694676115928,
                "campaignId": "ADPR-FLNKBABMBE",
                "created_at": "2023-10-10T07:13:31.242Z",
                "id": "ADPR-FLNKBABMBE-bbdefa2950f49882f295b1285d4fa9dec45fc4144bfb07ee6acc68762d12c2e3-2",
                "position": 1,
                "title": "Yeni iPhone 15 ile tanışın | Apple Resmi Sitesi",
                "domainId": "apple.com",
                "url": "https://www.apple.com/tr/iphone-15",
                "content": "Bambaşka. Dynamic Island. 2 kat Telefoto, 48 MP Ana kamera. Ön sipariş tarihi 22 Eylül. Fark yaratmak için tasarlandı. iPhone 15’i yakından tanıyın. Yeni 48 MP..."
            }
        ],
        "funnelCount": {
            "interest": 99,
            "awareness": 89,
            "decision": 88,
            "action": 62,
            "loyalty": 39
        }
    },
    tempFunnelStats: {
        "ctaText": [
            {
                "name": "Learn more",
                "count": 1
            },
            {
                "name": "Kup teraz",
                "count": 1
            },
            {
                "name": "Compre agora",
                "count": 1
            },
            {
                "name": "Ön sipariş tarihi 22 Eylül",
                "count": 1
            }
        ],
        "keywords": [
            {
                "name": "iPhone 15 Pro",
                "count": 1
            },
            {
                "name": "iPhone 15",
                "count": 2
            },
            {
                "name": "Apple Watch Series 9",
                "count": 1
            },
            {
                "name": "Apple Watch Ultra 2",
                "count": 1
            },
            {
                "name": "Yeni",
                "count": 1
            },
            {
                "name": "Apple Resmi Sitesi",
                "count": 1
            },
            {
                "name": "Bambaşka",
                "count": 1
            },
            {
                "name": "Dynamic Island",
                "count": 1
            },
            {
                "name": "2 kat Telefoto",
                "count": 1
            },
            {
                "name": "48 MP Ana kamera",
                "count": 1
            }
        ],
        "demographicAudience": [
            {
                "name": "General",
                "count": 1
            },
            {
                "name": "general",
                "count": 1
            }
        ],
        "psychographicAudience": [
            {
                "name": "Tech enthusiasts",
                "count": 1
            },
            {
                "name": "Innovators",
                "count": 1
            },
            {
                "name": "technology enthusiasts",
                "count": 1
            },
            {
                "name": "early adopters",
                "count": 1
            }
        ],
        "targetAudience": [
            {
                "name": "Tech enthusiasist",
                "description": `Early adopters embracing innovation across various industries.`,
                "audiencePercentage": {
                    "value": "72%",
                    "description": {
                        "highlight": "Just 6%",
                        "normalText": `are over 60yrs of age`
                    }
                },
                "ageBreakdown": {
                    "30-44": "48%",
                    "45-59": "21%",
                    "60-74": "6%",
                    "75+": "0%"
                }
            },
            {
                "name": "Innovators",
                "description": `Early adopters embracing innovation across various industries.`,
                "audiencePercentage": {
                    "value": "45%",
                    "description": {
                        "highlight": "Just 9%",
                        "normalText": `are over 60yrs of age`
                    }
                },
                "ageBreakdown": {
                    "30-44": "23%",
                    "45-59": "61%",
                    "60-74": "16%",
                    "75+": "13%"
                }
            },
            {
                "name": "Early adopters",
                "description": `Early adopters embracing innovation across various industries.`,
                "audiencePercentage": {
                    "value": "63%",
                    "description": {
                        "highlight": "Just 5%",
                        "normalText": `are over 60yrs of age`
                    }
                },
                "ageBreakdown": {
                    "30-44": "73%",
                    "45-59": "6%",
                    "60-74": "27%",
                    "75+": "40%"
                }
            },
            {
                "name": "Innovators",
                "description": `Early adopters embracing innovation across various industries.`,
                "audiencePercentage": {
                    "value": "45%",
                    "description": {
                        "highlight": "Just 9%",
                        "normalText": `are over 60yrs of age`
                    }
                },
                "ageBreakdown": {
                    "30-44": "23%",
                    "45-59": "61%",
                    "60-74": "16%",
                    "75+": "13%"
                }
            }
        ]
    },
    tempTableList: [
        {
            "id": "0d725eb8-cf0e-11ee-9c39-498ad35d9e4b",
            "keyword": "apple",
            "country": "AM",
            "platform": "google",
            "updatedOn": "2024-02-19T10:02:55.628Z",
            "status": true
        },
        {
            "id": "70b89ad2-cf0e-11ee-9c39-498ad35d9e4b",
            "keyword": "blood tests",
            "country": "IN",
            "platform": "google",
            "updatedOn": "2024-02-19T10:05:42.269Z",
            "status": true
        }
    ]
}