import React, {useEffect} from "react"
import {getTodayDate} from '../../../../../../utils/dateFormat'
import { useLandingPageAnalysis } from './../../../context/landingPageAnalysis'

import Google from '../../components/PlatformResults/Google'
import Bing from "../PlatformResults/Bing"
import Youtube from "../PlatformResults/Youtube"
import Facebook from "../PlatformResults/Facebook"
import GoogleEcom from "../PlatformResults/GoogleEcom"
import BingEcom from "../PlatformResults/BingEcom"
import DisplayAds from "../PlatformResults/Display"
import { useLandingPageAdsInfo } from "../../../hooks/useLandingPageAdsInfo"

const AdCopies = ({isDataLoading}) =>{
    const {landingPageAnalysisState, landingPageAnalysisDispatch} = useLandingPageAnalysis()
    const useLandingPageAdsInfoHook = useLandingPageAdsInfo()

    useEffect(() => {
        // console.log("inside adcopy useeffect", landingPageAnalysisState)
        if(landingPageAnalysisState?.adCopyPlatform?.length > 0){
            if(landingPageAnalysisState.searchMode == "path"){
                const reqBody = {
                    query: landingPageAnalysisState.searchUrl,
                    page: "1",
                    countries: [],
                    startDate: landingPageAnalysisState.selectedYear + "-"+ landingPageAnalysisState.selectedMonth + "-" + landingPageAnalysisState.selectedDay,
                    endDate: getTodayDate(),
                    sort: "relevant",
                    limit: 6
                }
    
                useLandingPageAdsInfoHook.urlExactFilterMatchMutate({body: reqBody, platform: landingPageAnalysisState.adCopyPlatform})
                
    
            }else if(landingPageAnalysisState.searchMode == "domain"){
                const reqBody = {
                    query: landingPageAnalysisState.searchUrl,
                    page: "1",
                    countries: [],
                    startDate: landingPageAnalysisState.selectedYear + "-"+ landingPageAnalysisState.selectedMonth + "-" + landingPageAnalysisState.selectedDay,
                    endDate: getTodayDate(),
                    sort: "relevant",
                    limit: 6
                }
    
                useLandingPageAdsInfoHook.urlDomainFilterMutate({body: reqBody, platform: landingPageAnalysisState.adCopyPlatform})
            }
        } 
    }, [landingPageAnalysisState.adCopyPlatform])
    

    if(landingPageAnalysisState.adCopyPlatform == "google" || landingPageAnalysisState.adCopyPlatform == ""){
        return <Google isDataLoading={isDataLoading || useLandingPageAdsInfoHook.isUrlExactFilterLoading || useLandingPageAdsInfoHook.isUrlDomainFilterLoading} />
    }else if(landingPageAnalysisState.adCopyPlatform == "bing"){
        return <Bing isDataLoading={isDataLoading || useLandingPageAdsInfoHook.isUrlExactFilterLoading || useLandingPageAdsInfoHook.isUrlDomainFilterLoading} />
    }else if(landingPageAnalysisState.adCopyPlatform == "meta"){
        return <Facebook isDataLoading={isDataLoading || useLandingPageAdsInfoHook.isUrlExactFilterLoading || useLandingPageAdsInfoHook.isUrlDomainFilterLoading} />
    }else if(landingPageAnalysisState.adCopyPlatform == "flipkart"){
        return <Google isDataLoading={isDataLoading || useLandingPageAdsInfoHook.isUrlExactFilterLoading || useLandingPageAdsInfoHook.isUrlDomainFilterLoading} />
    }else if(landingPageAnalysisState.adCopyPlatform == "amazon"){
        return <Google isDataLoading={isDataLoading || useLandingPageAdsInfoHook.isUrlExactFilterLoading || useLandingPageAdsInfoHook.isUrlDomainFilterLoading} />
    }else if(landingPageAnalysisState.adCopyPlatform == "googlepla"){
        return <GoogleEcom isDataLoading={isDataLoading || useLandingPageAdsInfoHook.isUrlExactFilterLoading || useLandingPageAdsInfoHook.isUrlDomainFilterLoading} />
    }else if(landingPageAnalysisState.adCopyPlatform == "bingpla"){
        return <BingEcom isDataLoading={isDataLoading || useLandingPageAdsInfoHook.isUrlExactFilterLoading || useLandingPageAdsInfoHook.isUrlDomainFilterLoading} />
    }else if(landingPageAnalysisState.adCopyPlatform == "linkedin"){
        return <Google isDataLoading={isDataLoading || useLandingPageAdsInfoHook.isUrlExactFilterLoading || useLandingPageAdsInfoHook.isUrlDomainFilterLoading} />
    }else if(landingPageAnalysisState.adCopyPlatform == "youtube"){
        return <Youtube isDataLoading={isDataLoading || useLandingPageAdsInfoHook.isUrlExactFilterLoading || useLandingPageAdsInfoHook.isUrlDomainFilterLoading} />
    }else if(landingPageAnalysisState.adCopyPlatform == "reddit"){
        return <Google isDataLoading={isDataLoading || useLandingPageAdsInfoHook.isUrlExactFilterLoading || useLandingPageAdsInfoHook.isUrlDomainFilterLoading} />
    }else if(landingPageAnalysisState.adCopyPlatform == "display"){
        return <DisplayAds isDataLoading={isDataLoading || useLandingPageAdsInfoHook.isUrlExactFilterLoading || useLandingPageAdsInfoHook.isUrlDomainFilterLoading} />
    }
}

export default AdCopies