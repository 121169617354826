
import SelectAd from './SelectAd';
import SelectAccount from './SelectAccount';
import CheckMailPopup from './components/CheckEmailPopup';
import { useAdManagement } from '../../context/AdManagementContext';
function SelectAdDetails() {
    const { adManagementState, adManagementDispatch } = useAdManagement()

    return ( 
        <>
            <SelectAd />
            <SelectAccount />
            {adManagementState.isCheckEmailPopup && <CheckMailPopup />}
       </>
    );
}

export default SelectAdDetails;