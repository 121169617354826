import styled from "styled-components"

export const Container = styled.div`
    /* width: 85px; */
    margin-top: 8px;
    height: auto;
    border-radius: 8px;
    border: 1px solid rgb(236 236 236 / 0%);
    /* margin-left: 8px; */
    background-color: white;
    /* margin-right: 8px; */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    :hover {
        border: 1px solid #5AB3FF;
    }

    cursor: pointer;
    position: relative;

     p {
        font-size: 14px;
     }

`;

export const Drop = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(236, 236, 236);
    outline: none;
    border-radius: 8px;
    width: 100%;
    height: 40px;
    padding: 9px 12px;
    font-weight: 300;
    color: rgb(46, 46, 46);

    .platform-logo{
        height: 16px;
    }
`;

export const Down = styled.div`
    width: 100%;
    min-height: 40px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 2px 6px #0000001A;

    position: absolute;
    left: 0;
    top: calc(100% + 6px);
    padding: 5px;
    cursor: auto;

    display: ${props => props.show ? 'flex': 'none'};
    flex-direction: column;
    justify-content: center;
`;

export const Row1 = styled.div`
    width: 100%;
    height: 32px;
    padding: 10px;

    display: flex;
    align-items: center;

    p {
        color: #919191;
    }
`;

export const Row = styled(Row1)`
    cursor: pointer;
    height: 32px;
    transition: 0.2s all ease-in-out;
    border-radius: 4px;
    padding: 10px;
    opacity: ${props => props.disabled ? "40%" : 1};
    pointer-events: ${props => props.disabled ? "none" : ""};

    img{
        height: 12px;
        margin-right: 8px;
    }

    :hover {
        background-color: #F6F6F6;
    }

    p {
        color: black;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
`;

export const Separator = styled.div`
    width: 10px;
    border-bottom: 1px solid #ECECEC;
    margin-left: 10px;
`;
