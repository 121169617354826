import React from 'react'
import { DayTrend, DayTrendContainer, DayTrendWrapper, GoogleTrendWrapper, AdProgressDiv, AdProgressBar, AdProgressName, GoogleProgress, AdProgressPercentage, PlatformContainer, SkeletonWrap } from './index.styled'
import HeatMapChart from './HeatMapChart/index'
import { useDomainOverview } from '../../../context/domainoverview';
import Skeleton from "react-loading-skeleton";
import Tooltip from 'rc-tooltip';
import helpCircle from '../../../../../../media/Dashboard/help-circle.svg'

const Trend = ({ isHeatMapDetailsLoading, isTableDataLoading }) => {
  const { domainOverviewState, domainOverviewDispatch } = useDomainOverview()
  const colorsArray = [
    "#a158e0",
    "#e24593",
    "#2802fb",
    "#00879d",
    "#1679db"
  ]

  return (
    <DayTrendWrapper>
      <DayTrendContainer>
        <DayTrend>Day and Hour trend <Tooltip trigger={['hover']} overlayStyle={{ width: "100" }} overlay={<span>Hour trends of the ads found.</span>}>
          <img src={helpCircle} />
        </Tooltip></DayTrend>
        {isHeatMapDetailsLoading ?
          <SkeletonWrap>
            <Skeleton height={"200px"} />
          </SkeletonWrap>
          : <HeatMapChart isHeatMapDetailsLoading={isHeatMapDetailsLoading} />}
      </DayTrendContainer>
      <GoogleTrendWrapper>
        <h2>Ad Distribution by Platform<Tooltip trigger={['hover']} overlayStyle={{ width: "100" }} overlay={<span>Ads found for this domain in each platform.</span>}>
          <img src={helpCircle} />
        </Tooltip></h2>
        <PlatformContainer>
          {isTableDataLoading ?
            <Skeleton height={'220px'} />
            :
            domainOverviewState?.adDistribution.map((item, idx) => {
              return <AdProgressDiv>
                <AdProgressName>{item.key}</AdProgressName>
                <AdProgressBar>
                  <GoogleProgress color={colorsArray[idx]} id="file" value={item.percentage} max="100" ></GoogleProgress>
                  <AdProgressPercentage>{item.percentage}%</AdProgressPercentage>
                  {/* <AdGradeWrap><img src={greenArrow} />3</AdGradeWrap> */}
                </AdProgressBar>
              </AdProgressDiv>
            })}
        </PlatformContainer>
      </GoogleTrendWrapper>
    </DayTrendWrapper>
  )
}

export default Trend

//<script type="text/javascript" src="https://ssl.gstatic.com/trends_nrtr/3620_RC01/embed_loader.js"></script> <script type="text/javascript"> trends.embed.renderExploreWidget("TIMESERIES", {"comparisonItem":[{"keyword":"apple coupon","geo":"","time":"now 1-d"}],"category":0,"property":""}, {"exploreQuery":"date=now%201-d&q=apple%20coupon&hl=en","guestPath":"https://trends.google.com:443/trends/embed/"}); </script>