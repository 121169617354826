import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  LandingPageAnalysisProvider,
} from "./context/landingPageAnalysis";
import {Helmet} from "react-helmet"

// Layout
import Layout from "../../layout";

// Pages
import LandingPageAnalysisHome from "./LandingPageAnalysisHome";
import LandingPageAnalysisDetails from "./LandingPageAnalysisDetails";

// Not found page
import NotFound from "../NotFoundPage";

const LandingPageAnalysisComponent = () => {

  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route
        path="/"
        element={
          <Layout>
            <LandingPageAnalysisHome />
          </Layout>
        }
      />
      <Route
        path="/details"
        element={
          <Layout>
            <LandingPageAnalysisDetails />
          </Layout>
        }
      />
    </Routes>
  );
};

const LandingPageAnalysisProviderWrapper = () => {
  return (
    <LandingPageAnalysisProvider>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Landing Page Analysis | AdSpyder</title>
          <meta name="title" content="Landing Page Analysis | AdSpyder" />
          <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
      </Helmet>
      <LandingPageAnalysisComponent />
    </LandingPageAnalysisProvider>
  );
};

export default LandingPageAnalysisProviderWrapper;
