import { Description, GeneratingContainer, Header, Icon, Name, Process, ProcessingContainer, Spinner, Title, TryAgain, Wrapper, WrongContainer, WrongDescription, WrongDiv } from "./index.styled";

import completed from "../../assets/completed.svg"

import { useTextAdGenerator } from "../../context/textAdGeneration";
import { useEffect } from "react";
import { appClient } from "../../../../../../utils/axios-utils"
import {
    useMutation
} from "@tanstack/react-query"
import { useNavigate } from "react-router-dom";
import wentWrong from '../../assets/wentWrongImg.svg'
import statics from "../../statics";
import BouncingDotsLoader from "../../../../../HOC/BouncingDotsLoader";
const AddGeneratingPage = async (data) => {
    const config = {
        url: `text-ad-generation/generate/loaded-process`,
        method: "POST",
        data
    }

    return appClient(config)
}

function GeneratingPage() {
    const { textAdGeneratorState, textAdGeneratorDispatch } = useTextAdGenerator()
    const navigate = useNavigate()
    const { pages } = statics
    const { mutate: getAddGeneratingPage, isLoading: isAddGeneratingPage } = useMutation(AddGeneratingPage, {    
        onSuccess: (data) => {
            if(data){
                console.log(data,'data')
             
                if(data.notSucceeded === true){
                    // console.log('checkkkkkkk')
                    textAdGeneratorDispatch(({ type: "SET_WENT_WRONG", value: true }))
                }else{
                    data.processed.map((item, index) => ({ ...item, index })).forEach(({ id, name, isCompleted }) => {
                        if (isCompleted === true) {
                            textAdGeneratorDispatch(({ type: "UPDATE_PROCESSING_STATUS", value: { id: id-1, value: true } }))
                        } 
                });
                }
            
            }    
        },
        onError: (err) => {
            
        }
    })
    const getProcessesStatus = () => {
        return(
            <ProcessingContainer>
                {
                    textAdGeneratorState.generatingProcesses.map((process) => {
                        return (
                            <Process>
                                <Name>{process.name}</Name>
                                {
                                    process.isCompleted
                                    ? <Icon src={completed} alt="complted" />
                                    : <Spinner />
                                }
                            </Process>
                        )
                    })
                }
            </ProcessingContainer>
        )
    }
    const getSomethingWrong = () => {
        const initialPageFn = () =>{
            textAdGeneratorDispatch({type: "UPDATE_CURRENT_PAGE", value: pages[0]})
        }
        return(
            <WrongContainer>
                <img src={wentWrong} />
                <WrongDiv>Something went wrong!</WrongDiv>
                <WrongDescription>AdSpyder encountered an error while generating an ad. Please try again. If the problem persists, please reach out to support for assistance</WrongDescription>
                <TryAgain onClick={initialPageFn}>Try again</TryAgain>
            </WrongContainer>
        )
    }
    useEffect(() => {
        if(!textAdGeneratorState.getWentWrong){
            var generateInterval = setInterval(() => {
                getAddGeneratingPage({"userAdId": textAdGeneratorState.isAddDomainList});
            }, 2000);
        
            const isPending = textAdGeneratorState.generatingProcesses.some((process) => process.isCompleted === false);
            
            if (!isPending) {
                // setTimeout(() => {
                    clearInterval(generateInterval);
                    navigate(`/adgenerator/text-ad/result?id=${textAdGeneratorState.isAddDomainList}`)
    
                    // textAdGeneratorDispatch({type: "UPDATE_RESULT_GENERATED_FLAG", value: true}); 
                // }, 5000);
                
            }else{
                console.log('process checking')
            }
        
            return () => {
                clearInterval(generateInterval); 
            }
        }
       
    }, [textAdGeneratorState.generatingProcesses]);
    

    useEffect(() => {
        //programmatically changing the process status
        // setTimeout(() => {
        //     textAdGeneratorDispatch(({type: "UPDATE_PROCESSING_STATUS", value: {
        //         index: 2,
        //         // value: true
        //     }}))
        //     setTimeout(() => {
        //         textAdGeneratorDispatch(({type: "UPDATE_PROCESSING_STATUS", value: {
        //             index: 5,
        //             // value: true
        //         }}))
        //         setTimeout(() => {
        //             textAdGeneratorDispatch(({type: "UPDATE_PROCESSING_STATUS", value: {
        //                 index: 1,
        //                 // value: true
        //             }}))
        //             setTimeout(() => {
        //                 textAdGeneratorDispatch(({type: "UPDATE_PROCESSING_STATUS", value: {
        //                     index: 4,
        //                     // value: true
        //                 }}))
        //                 setTimeout(() => {
        //                     textAdGeneratorDispatch(({type: "UPDATE_PROCESSING_STATUS", value: {
        //                         index: 3,
        //                         // value: true
        //                     }}))
        //                     setTimeout(() => {
        //                         textAdGeneratorDispatch(({type: "UPDATE_PROCESSING_STATUS", value: {
        //                             index: 0,
        //                             value: true
        //                         }}))
        //                     }, 1000)
        //                 }, 1000)
        //             }, 1000)
        //         }, 1000)
        //     }, 1000)
        // }, 1000)
    }, [])

    return ( 
        <Wrapper>
            <GeneratingContainer>
            
                {!textAdGeneratorState.getWentWrong ?
                <>
                    <Header>
                        <Title>Generating ads 
                            <BouncingDotsLoader style={{marginBottom: "6px"}} />
                        </Title>
                        <Description>Please be patient. This process may take a few minutes...</Description>
                    </Header>
                    {getProcessesStatus()}
                </> 
                : getSomethingWrong()}
                
            </GeneratingContainer>
        </Wrapper>
        
     );
}

export default GeneratingPage;