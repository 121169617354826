import { ImageAdGeneratorProvider } from "./context/imageAdGeneration";
import {Route, Routes} from 'react-router-dom'
import { ImageAdGeneration } from "./index.styled";
import Root from "./components";


function ImageAdGeneratorWrapper() {
    return ( 
        <ImageAdGeneratorProvider>
            <ImageAdGeneration>
                <Routes>
                    <Route path="/*" element={<Root />} />
                </Routes>
            </ImageAdGeneration>
        </ImageAdGeneratorProvider>
    );
}

export default ImageAdGeneratorWrapper;