import React, {useState} from 'react'
import { CountryHeader,KeyData, KeyHeader, PlatformHeader, Row, StatusHeader, TableBody, TableHeader, TableWrap, UpdatedHeader, Wrapper } from './index.styled';
import plus from '../../../../../../../../media/Dashboard/plus-small.svg'
import {
    createColumnHelper,
    getCoreRowModel,
    useReactTable,
    getSortedRowModel
  } from '@tanstack/react-table'

import { useNavigate } from "react-router-dom"

const CampaignTable = () => {
    const columnHelper = createColumnHelper();
    const [sorting, setSorting] = useState([]);

    // const {domainTrackingState, domainTrackingDispatch} = useDomainTracking()
    const navigate = useNavigate()

    const columns = [
        columnHelper.accessor('no', {
            id: 'no',
            header: () => 'No',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('keyword', {
            id: 'keyword',
            header: () => 'Keyword',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('status', {
            id: 'status',
            header: () => 'Status',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('platform', {
            id: 'platform',
            header: () => 'Platform',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('country', {
            id: 'country',
            header: () => 'Country',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor(row => `${new Date(row.updatedOn).getTime()}`, {
            id: 'updatedOn',
            header: () => 'updatedOn',
            cell: info => info.getValue(),
        }),
    ];

    const table = useReactTable({
        data: ['no campaigns to show'],
        columns,
        state: {
            sorting,
            sortBy: [{ id: "datetime", desc: false }],
          },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });

    const domainDetailsFn = (data) => {
        navigate(`/domain-tracking/details?domainId=${data.id}`)
        // tableListApi({ query: domain });
        // topDomains({ query: domain })
    }


    return (
    <Wrapper>
        <TableWrap>
                    {table.getHeaderGroups().map(headerGroup => {
                        return <TableHeader>
                        <KeyHeader onClick={headerGroup.headers[1].column.getToggleSortingHandler()}>
                        Campaign name{" "}
                        </KeyHeader>
                        <StatusHeader onClick={headerGroup.headers[2].column.getToggleSortingHandler()}>
                        Account{" "}
                        </StatusHeader>
                        <PlatformHeader onClick={headerGroup.headers[3].column.getToggleSortingHandler()}>
                        Type{" "}                              
                        </PlatformHeader>
                        <CountryHeader onClick={headerGroup.headers[4].column.getToggleSortingHandler()}>
                        Impressions{" "}                              
                        </CountryHeader>
                        <UpdatedHeader onClick={headerGroup.headers[5].column.getToggleSortingHandler()}>
                        Clicks{" "}                               
                        </UpdatedHeader>
                        <UpdatedHeader onClick={headerGroup.headers[5].column.getToggleSortingHandler()}>
                        Amount spent{" "}                              
                        </UpdatedHeader>
                    </TableHeader>
                       
                            })}
                  <TableBody>
                  {/* {table.getRowModel().rows.map((row, rowIndex) => ( */}
                        <Row>
                            {/* <HoverWrapper> */}
                            <KeyData>no campaigns to show</KeyData>
                                {/* <KeyData>{row.original.hostname}</KeyData>
                                <StatusData>
                                {row.original.status ? <p>{'Active'}</p>:<p className='inactive'>{'InActive'}</p>}
                                </StatusData>
                                <PlatformData>{row.original.platform}</PlatformData>
                                <CountryData>{row.original.country}</CountryData>
                                <UpdatedData> {moment(row.original.updatedOn).format('YYYY-MM-DD HH:mm')}</UpdatedData> */}
                            {/* </HoverWrapper> */}
                        </Row>
                    {/* ))} */}
                    </TableBody>
        </TableWrap>
    </Wrapper>
  )
}

export default CampaignTable
