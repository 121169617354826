import { DomainPopupContent, PopupBackground } from "../index.styled";
import { Container, Header, Input, InputContainer, PrimaryText, Proceed, SecondaryText } from "./index.styled";
import adspyder from "./Images/adspyder.svg"
import { useRef, useState } from "react";
import { useOverview } from "../../../context/overview";
import { appClient } from "../../../../../../utils/axios-utils"
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../../context/auth";
import tooltip from "./Images/tooltip.svg"
import { OurOwnToolTip, ToolTipImageContainer } from "../../../../../layout/components/ResultPageSearch/components/Url/Input/index.styled";
function ProjectNamePopup({projectId}) {
    const { overviewDispatch } = useOverview()
    const { authDispatch } = useAuth()
    const navigate = useNavigate()
    const inputRef = useRef(null)
    const [inputError, setInputError] = useState(false)    
     const addProjectName = (data) => {
        const config = {
            url: `/app/onboarding/project-name`,
            method: "post",
            data
        }
        return appClient(config)
    }
    const { mutate: addProjectNameMutate } = useMutation(addProjectName, {
        onSuccess: (data) => {
            // navigate(`/overview`)
            authDispatch({
                type: "UPDATE_DEFAULT_PROJECT_INFO",
                value: data 
            })
        }
    })
    const handleProceed = () => {
        if (inputRef.current.value !== ""){
            addProjectNameMutate({
                projectName: inputRef.current.value,
                projectId
            })
            overviewDispatch({
                type: "SET_ONBOARDING_POPUP_ACTIVE",
                value: false
            })
        }
        else setInputError(true)
    }

    const handleKeyDown = (e) => {
        if(e.key === "Enter") handleProceed()
    }

    return (
        <PopupBackground onKeyDown={handleKeyDown} >
            <DomainPopupContent>
                <Header>
                    <img src={adspyder} alt="adspyder" />
                    {`Preparing your account`}
                </Header>
                <PrimaryText>Let’s name your project</PrimaryText>
                <SecondaryText>Manage domain-focused ad campaigns within this project</SecondaryText>
                <Container>
                    <InputContainer>
                        <ToolTipImageContainer
                                error={inputError} className="tooltip-container"
                            >
                                <img src={tooltip} />
                                <OurOwnToolTip>
                                    {"Please enter a project name"}
                                </OurOwnToolTip>
                            </ToolTipImageContainer>
                        <Input
                            placeholder="assign a name for your project"
                            ref={inputRef}
                            error={inputError}
                        />
                    </InputContainer>
                    <Proceed onClick={handleProceed} >Proceed</Proceed>
                </Container>
            </DomainPopupContent>
        </PopupBackground>
    )
}

export default ProjectNamePopup