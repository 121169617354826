import styled from "styled-components";

export const Container = styled.div`
    padding: 20px;
    width: 100%;
    h1{
        font-size: 18px;
        font-weight: 500;
        color: #2E2E2E;
        padding: 20px 4px;
        margin: 0;
        span{
            color: #808080;
            font-weight: 300;
            text-decoration: underline;
            margin-left: 8px;
        }
    }
    .loader{
        height: calc(100vh - 185px);
    }
`;

export const Wrapper = styled.div`
    display: flex;
    gap: 40px;
`;

export const ModeContainer = styled.div`
    height: 40px;
    gap: 4px;
    border-radius: 8px;
    display: flex;
    margin-right: 8px;
`;

export const TableWrapperMain = styled.div`
    max-width: 629px;
    width: 100%; 
    overflow-y: auto;
    height: calc(100vh - 200px);
    h2{
        font-size: 16px;
        font-weight: 500;
        color: #2E2E2E;
        padding: 12px 4px;
    }
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const TableWrapperSub = styled.div`
    max-width: 499px;
    width: 100%; 
    background: #FFFFFF;
    min-height: 565px;
    height: max-content;

    h2{
        font-size: 16px;
        font-weight: 500;
        color: #2E2E2E;
        padding: 12px 4px;
    }
`

export const SimilarKeywordsTable = styled.div`

`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    /* padding: 16px 20px; */

    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    border-radius: 8px 8px 0px 0px;
`;


export const HeaderText = styled.div`
    display: flex;
    gap: 24px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 300;
    color: #2E2E2E;
    margin-bottom: 18px;
    padding: 16px 20px 0px;
`;


export const TableContainer = styled.div`

`;

export const TableHead = styled.div`
    display: flex;
    background: #F2F2F2;
    border: 1px solid #F2F2F2;
    padding: 10px 20px;
    font-size: 12px;
    font-weight: 300;
    color: #434343;
    width: 100%;
    img{
        height: 16px;
        margin-left: 5px;
    }
`;

export const TableBody = styled.div`
    /* max-height: 65vh; */
    overflow-y: auto;
    height: calc(100vh - 330px);
    min-height: 300px;
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const Row = styled.div`
    display: flex;
    padding: 10px 20px;
    background: #FFFFFF;
    border-bottom: 1px solid #F2F2F2;
`;

export const KeywordLabel = styled.div`
    width: 55%;
    padding-right: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;
export const VolumeLabel = styled.div`
    width: 15%;
    display: flex;
    align-items: center;
    cursor: pointer;
`;
export const KcsLabel = styled.div`
    width: 20%;
    display: flex;
    align-items: center;
    cursor: pointer;
`;
export const CpcLabel = styled.div`
    width: 16%;
    text-align: end;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const KeywordData = styled.div`
    width: 55%;
    font-size: 14px;
    font-weight: 300;
    color: #434343;
    position: relative;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    line-height: normal;

    span{
        position: absolute;
        display: none;
        right: 10px;
        top: -4px;
        color: #006ECC;
        background: #EAF5FF;
        border-radius: 8px;
        padding: 4px 12px;
        cursor: pointer;
    }

    img{
        cursor: pointer;
        margin-right: 40px;
    }

    &:hover{
        span{
            display: flex;
        }
    }
`;

export const SimilarKeywordData = styled.div`
    width: 65%;
    font-size: 14px;
    font-weight: 300;
    color: #434343;
    position: relative;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: normal;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .tooltip-container{
        width: 80%;
    }

    p{
        white-space: initial;
    }
`;

export const SimilarKeywordDataLoader = styled.div`
    width: 60%;
    font-size: 14px;
    font-weight: 300;
    color: #434343;
    position: relative;
    padding-right: 10px;
`;

export const VolumeData = styled.div`
    width: 12%;
    color: #555555;
    font-size: 14px;
    font-weight: 300;
    padding-right: 10px;
`;
export const KcsData = styled.div`
    width: 17%;
    color: #555555;
    font-size: 14px;
    font-weight: 300;
        padding-right: 10px;
`;

export const CpcData = styled.div`
    width: 16%;
    text-align: end;
    padding-right: 10px;

    color: #707070;
    font-size: 14px;
    font-weight: 300;
`;


export const GoogleLabel = styled.div`
    text-align: end;
    width: 20%;
    padding-right: 10px;
`;

export const BingLabel = styled.div`
    text-align: end;
    width: 20%;
    padding-right: 10px;
`;




export const GoogleData = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: #707070;
    padding-right: 10px;
    width: 15%;
    text-align: end;
    line-height: normal;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const BingData = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: #707070;
    padding-right: 10px;
    width: 15%;
    text-align: end;
    line-height: normal;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const SearchContainer = styled.div`
    display: flex;
`;

export const ToolTipContainer = styled.div`
    font-size: 12px;
    font-weight: 300;
    color: #666;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-bottom: 8px;
    margin-left: 10px;

    img{
        margin-left: 4px;
    }
`;

export const ToolTipTextContainer = styled.div`
    /* position: absolute; */
    right: 8px;
    top: 12px;
    display: flex;
    transition-delay: 2s;
    width: 75%;
    &:hover{
        > div{
            display: flex;
        }

        img{
            display: block;
        }
    }

    p{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition-delay: 2s;
        cursor: default;
    }
`;

export const ToolTip = styled.div`
    background: #555555;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 300;
    position: absolute;
    bottom: -34px;
    padding: 4px 8px;
    width: 125px;
    display: none;
    z-index: 2;
`;


export const InputContainer = styled.div`
    position: relative;
    height: 40px;
`;

export const Input = styled.input`
    width: 600px;
    height: 40px;
    background-color: white;
    border: 1px solid #ECECEC;
    border-radius: 8px;
    box-sizing: border-box;

    padding: 0 12px;
    outline: none;
    color: #2E2E2E;
    color: #2E2E2E;
    font-size: 14px;
    font-weight: 300;
    padding-right: 30px;
    border: ${props => props.error ? "1px solid #FF5180" : "1px solid #ECECEC"};
    outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};
    &:focus{
        border: ${props => props.error ? "1px solid #FF5180" : "1px solid #5AB3FF"};
        outline: ${props => props.error ? "2px solid #FFB7CB" : "2px solid #B9DDFF"};
    }
`;

export const ToolTipImageContainer = styled.div`
    position: absolute;
    right: 8px;
    top: 12px;
    display: ${props => props.error ? "flex": "none"};
    img{
        filter: contrast(1);
    }

    &:hover{
        > div{
            display: flex;
        }

        img{
            display: block;
        }
    }
`;

export const Button = styled.button`
    width: 75px;
    height: 40px;
    margin-left: 8px;

    border-radius: 8px;
    background-color: #FF711E;
    color: white;
    font-size: 14px;
    border: none;
    cursor: pointer;
    :hover {
        filter: brightness(90%);
    }
`;

export const EmptyKeywordContainer = styled.div`
    width: 499px;
    background: #FFFFFF;
    min-height: 565px;
    height: max-content;
    padding-top: 100px;

    p{
        display: flex;
        line-height: 20px;
        font-size: 14px;
        color: #707070;
        font-weight: 300;
        width: 331px;
        margin: auto;
    }

    img{
        padding: 0px 6px;
    }
`;


export const ClearSimilarButton = styled.div`
    font-size: 12px;
    font-weight: 300;
    color: #707070;
    cursor: pointer;
`;

export const CurrentSelection = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 13px;
    padding: 0px 20px;

`;

export const SeletedKeyword = styled.div`

`;

export const ViewDomains = styled.div`
    font-size: 14px;
    font-weight: 300;
    color: #2E2E2E;
    cursor: pointer;
`;

export const DropdownButton = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;

    span{
        font-size: 14px;
        color: ${props => props.isSelected ? "#12558D" : "#2E2E2E"};
    }

    img{
        margin-left: 4px;
    }

    &:hover{
        background: #D4D4D4;
    }

`;

export const DropDownImage = styled.div`
    img{
        height: 11px;
        margin-left: 8px;
    }    
`;

export const DomainsDropdown = styled.div`
    width: 100%; 
    margin-top: 16px;
    h2{
        font-size: 12px;
        font-weight: 300;
        color: #434343;
        padding: 16px 20px 8px;
        
    }
`;

export const DomainName = styled.div`
    font-size: 14px;
    font-weight: 300;
    color: #808080;
    line-height: 20px;
    text-decoration: underline;
    padding: 0px 20px;
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover{
        border-right: 1px solid #ECECEC;
        border-left: 1px solid #ECECEC;
        background: #F9F9F9;
        /* background: #808080; */
        .domainanalysis-btn{
            display: flex;
        }
    }
`

export const RowWrapper = styled.div`

`;

export const InnerDomainsDropdown = styled.div`
    width: 100%; 
    /* margin-top: 16px; */
    border-bottom: 1px solid rgb(242, 242, 242);
    /* padding: 0px 20px; */
    h2{
        font-size: 12px;
        font-weight: 300;
        color: #434343;
        padding: 16px 20px 8px;
    }

`;

export const DomainAnalysisButtons = styled.div`
    /* display: flex; */
    display: none;
`;

export const AnalyseDomain = styled.div`
    font-size: 14px;
    font-weight: 300;
    color: #2E2E2E;
    padding: 6px 10px;
    border-radius: 6px;
    background: #ECECEC;
    margin-right: 8px;
    cursor: pointer;
`;

export const ResultsContainer = styled.div`
    display: flex;
    gap: 12px;
    height: calc(100vh - 245px);
    background: #006ecc00;
    position: absolute;
    left: 0;
    top: 0;
    .loader{
        height: 200px;
        margin: auto;
    }
`;

export const KeywordButtonContainer = styled.div`
    height: 100%;
    border: none;
    /* background: none; */
    background: ${props => props.active ? "#006ECC" : "white"};
    color: ${props => props.active ? "white" : "#707070"};
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ECECEC;
    padding: 6px 12px;
    cursor: pointer;
    
    input[type=radio]
    {
        background: transparent;
        appearance: none;
        width: 100px;
        height: 30px;
        position: absolute;
    }

    input[type=radio] + label
    {
        cursor: pointer;
        position: relative;
        padding-left: 20px;
        line-height: 20px;
    }

    input[type=radio] + label::before
    {
        content: "";
        display: inline-block;
        width: 12px;
        aspect-ratio: 1;
        border: ${props => props.active ? "1px solid white" : "1px solid #E2E2E2"};
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        opacity: 1;
        transition: all 0.3s;
    }

    input[type=radio] + label::after
    {
    content: "";
        display: inline-block;
        width: 6px;
        aspect-ratio: 1;
        border: 1px solid #686de0;
        background: ${props => props.active ? "white" : "#006ECC"};
        border-radius: 50%;
        position: absolute;
        left: 3px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        transition: all 0.3s;
    }

    input[type=radio]:checked + label::after
    {
        opacity: 1;
    }
`;

export const NoResult = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: grey;
    width: 100%;
`;