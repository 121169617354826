import { AdContent, AdDescription, AdTag, AdTitle, Ads, Content, Display, Header, Logo, MobileCamera, MobileOutline, RedirectLink, Scroller, Separator, Title, TopTextAdsContainer, Url, ViewAll, EmptyState } from "./index.styled";
import mobile from "./assets/mobile.svg"
import camera from "./assets/camera.svg"
import googleLogo from "./assets/googleLogo.svg"
import metaLogo from "./assets/metaAdLogo.svg"
import bingLogo from "./assets/bingLogo.svg"
import linkedInLogo from "./assets/linkedInLogo.svg"

import { useMutation } from "@tanstack/react-query"
import { appClient } from "../../../../../utils/axios-utils"
import { useOverview } from "../../context/overview";
import { useEffect } from "react";
import { LineLoader } from "../index.styled";
import { useNavigate } from "react-router-dom";

function TopTextAds() {
    const { overviewState: { projectDetails, topTextAds, isOnboardingPopupActive }, overviewDispatch } = useOverview()
    const navigate = useNavigate()
    const getTextAds = (data) => {
        const config = {
            url: `/dashboard-overview/top-ads/text-ads`,
            method: "POST",
            data
        }
        return appClient(config)
    }
    const { mutate: getTextAdsMutate, isLoading } = useMutation(getTextAds, {
        onSuccess: (data) => {
            overviewDispatch({
                type: "SET_TOP_TEXT_ADS",
                value: data.topTextAds
            })
        },
        onError: (e) => console.log(e)
    })
    const renderScroller = () => {
        return (
            <Scroller>
                <Ads>
                    {topTextAds.map((ad) => {
                        return (
                            <AdContent>
                                <Logo src={ad.platform === "facebook"
                                    ? metaLogo
                                    : ad.platform === "google"
                                        ? googleLogo
                                        : ad.platform === "bing"
                                            ? bingLogo
                                            : linkedInLogo} alt="logo" />
                                <AdTitle>{ad.title/* .length < 85 ? ad.title : `${ad.title.slice(0, 85)}...` */}</AdTitle>
                                <Url>
                                    <AdTag>Ad</AdTag>
                                    <RedirectLink>{ad.url.length < 32 ? ad.url : `${ad.url.slice(0, 32)}...`}</RedirectLink>
                                    <Separator />
                                </Url>
                                <AdDescription>{ad.content/* .length < 100 ? ad.content : `${ad.content.slice(0, 100)}...` */}</AdDescription>
                            </AdContent>
                        )
                    })}
                </Ads>
            </Scroller>
        )
    }

    const extractDomain = (inputUrl) => {
        const parsedUrl = new URL(inputUrl)
        return parsedUrl.hostname 
    }
    const handleViewAll = () => {
        navigate(`/adspy/google?searchMode=url&urlSearchType=domain&domainUrlInput=${extractDomain(projectDetails.website)}&page=1&sortType=relevant`)
    }

    useEffect(() => {
        if (projectDetails?.projectId)
            getTextAdsMutate({ projectId: projectDetails.projectId })
    }, [projectDetails?.projectId, isOnboardingPopupActive])
    
    if(isOnboardingPopupActive || isLoading){
        return <LineLoader width={418} height={232} />
    }
    return (
        <TopTextAdsContainer>
            <Header>
                <Title>Top text ads</Title>
                <ViewAll onClick={handleViewAll} >View all</ViewAll>
            </Header>
            <Content>
                <MobileOutline src={mobile} alt="mobile" />
                <MobileCamera src={camera} alt="camera" />
                <Display>
                    {topTextAds.length ? renderScroller() : <EmptyState>No text ads found for this domain!</EmptyState>}
                </Display>
            </Content>
        </TopTextAdsContainer>
    );
}

export default TopTextAds;