import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    height: 32px;
    align-items: center;
    margin-top: 12px;
    h3{
        color: #2E2E2E;
        font-size: 14px;
        font-weight: 300;
    }
`

export const PlatformsContainer = styled.div`
    display: flex;
`

export const ImgContainer = styled.div`
    padding: 7px 12px;
    background: ${props => props.current ? "#F6FBFF" : "#FFFFFF"} ;
    border: ${props => props.current ? "1px solid #B8D9F5": "1px solid #ECECEC"};
    border-radius: 8px;
    height: 32px;
    margin-left: 8px;
    cursor: pointer;
    opacity: ${props => props.isDisabled ? "30%": "100%"};
    pointer-events: ${props => props.isDisabled ? "none": "auto"};
`