import { Navbar, NavItem, NavItemContainer, UnderLine } from "./Navbar.styled";
import statics from "../../_static"

import { useKeywordFunnelAnalysis } from "../../context/keywordFunnelAnalysis";

function FunnelNavbar() {
    const { navItems } = statics
    const { keywordFunnelAnalysisState, keywordFunnelAnalysisDispatch } = useKeywordFunnelAnalysis()
    const { activeNavItem } = keywordFunnelAnalysisState

    const handleNavItemChange = (navItem) => {
        keywordFunnelAnalysisDispatch({
            type: "SET_ACTIVE_NAV_ITEM",
            value: navItem
        })
    }

    return ( 
        <Navbar>
            {navItems.map((item) => {
                const { id, name } = item
                const isActive = id === activeNavItem.id
                return(
                    <NavItemContainer key={id} isActive={isActive} onClick={() => handleNavItemChange(item)} >
                        <NavItem isActive={isActive} >{name}</NavItem>
                        <UnderLine isActive={isActive} />
                    </NavItemContainer>
                )
            })}
        </Navbar>
     );
}

export default FunnelNavbar;