import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { DomainTrackingProvider } from './context/domaintracking';
import {Helmet} from "react-helmet"

// Layout
import Layout from "../../layout"

// Pages
import DomainAnalysisDetails from './DomainTrackingDetails/index';
import DomainTracking from './DomainTrackingHome';

// Not found page
import NotFound from '../NotFoundPage';


const DomainTrackingComponent = () => {
    return <Routes>
            <Route path='*' element={
                <NotFound />
            } />
            <Route path="/" element={
                <Layout>
                    <DomainTracking/>
                </Layout>
            } />
            <Route path="details" element={
                <Layout>
                    <DomainAnalysisDetails />
                </Layout>
            } />
        </Routes>
};

const DomainTrackingProviderWrapper = () => {
    return <DomainTrackingProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PPC Domain Tracking | AdSpyder</title>
                <meta name="title" content="PPC Domain Tracking | AdSpyder" />
                <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
            </Helmet>
        <DomainTrackingComponent/>
    </DomainTrackingProvider>
}

export default DomainTrackingProviderWrapper