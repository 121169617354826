import { List, Funnel, LogoContainer, Logo, Name, Count } from "./index.styled";
// import statics from "../../_static"
// import { useDomainFunnelAnalysis } from "../../context/domainFunnelAnalysis";
import Skeleton from "react-loading-skeleton";
import conversionsImg from '../../../../assets/document-filter.svg'
import avgCpcImg from '../../../../assets/wallet-money.svg'
import clicksImg from '../../../../assets/mouse-square.svg'
import CTRImg from '../../../../assets/percentage-square.svg'
import eyeImg from '../../../../assets/eye.svg'


const AccountList = ({isFunnelDataLoading}) => {
    // const { domainFunnelAnalysisState, domainFunnelAnalysisDispatch } = useDomainFunnelAnalysis()
    // const { funnelList } = statics

    // const { activeFunnelType, adsCountOnEachFunnel } = domainFunnelAnalysisState
    const handleFunnelTypeChange = (funnelData) => {
        // domainFunnelAnalysisDispatch({
        //     type: "SET_ACTIVE_FUNNEL_TYPE",
        //     value: funnelData
        // })
    }
    const funnelArr = [1,2,3,4,5]
const funnelCheck = {
    "Impressions": "131",
    "Clicks": "179",
    "Avg CPC": "83",
    "CTR": "14",
    "Conversions": "54",
}

const funnelList= [
    {
        id: 'Impressions',
        logo: eyeImg,
        logoBgColor: "#FFF7EA",
    },
    {
        id: 'Clicks',
        logo: clicksImg,
        logoBgColor: "#E9FBF5",
    },
    {
        id: 'Avg CPC',
        logo: avgCpcImg,
        logoBgColor: "#FFF3F3",
    },
    {
        id: 'CTR',
        logo: CTRImg,
        logoBgColor: "#F3F9FF",
    },
    {
        id: 'Conversions',
        logo: conversionsImg,
        logoBgColor: "rgba(30, 66, 255, 0.10)",
    }
]
    return(
        <List>
            
           {
           !isFunnelDataLoading ?
    //        funnelCheck.map((funnel) => {
    //         const { id } = funnel
    //         const isActive = id === activeFunnelType.id
    //         const funnelListItem = funnelList.find(item => item.id === id) // Find matching funnelList item

    // return (
        // <h1>hello</h1>
        Object.entries(funnelCheck).map(([key, value]) => {
            // Find the corresponding funnelListItem
            const funnelListItem = funnelList.find(item => item.id === key);
            return (
              <Funnel key={key}>
                <LogoContainer  bg={funnelListItem.logoBgColor}>
                  {/* Conditionally render the Logo component */}
                  {funnelListItem ? <Logo src={funnelListItem.logo} alt={key} /> : null}
                </LogoContainer>
                <Name>{key}</Name>
                <Count>{value}</Count>
              </Funnel>
            );
          })
       
    // )
// })
 :
        funnelArr.map((item)=>(
        <Funnel>
            <Skeleton height={"100%"} borderRadius= {"16px"}/> 
        </Funnel>
        ))
}

        </List>
    )
}

export default AccountList