import styled from 'styled-components';

export const SponsoredText = styled.div `
color: #434343;
font-size: 14px;
font-weight: 500;
margin-bottom: 12px;
`;

export const PaginationContainer = styled.div `
display: flex;
font-size: 14px;
font-weight: 300;
.previous{
    padding: 6px 12px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 20px;
    img{
        transform: rotate(180deg);
        height: 8px;
        margin-right: 8px;
        height: 8px;
        filter: invert(100%);
    }
        color: ${props => props.prevState ? "#d6d6d6" : "#434343"};
        /* background: ${props => props.prevState ? "none" : "#434343"}; */
        filter: ${props => props.prevState ? "invert(0%)" : "none"};
}
.next{
    padding: 6px 12px;
    /* color: #ff711e; */
    /* background: #434343; */
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
    img{
        height: 8px;
        height: 8px;
        margin-left: 8px;
        filter: invert(100%);
    }
    color: ${props => props.nextState ? "#e3bca5" : "#ff711e"};
    /* background: ${props => props.nextState ? "none" : "#434343"}; */
    filter: ${props => props.nextState ? "invert(0%)" : "none"};
}
.selected{
    cursor: pointer;
    color: white;
    background: #FF711E;
    border-radius: 8px;
}
ul{
    display: flex;
    /* margin: 10px auto; */
    height: 32px;
}
li{
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0px 4px;
    cursor: pointer;
    color: #2E2E2E;
    background: none;
    border-radius: 8px;
}
a{
    padding: 6px 10px;
}
`;

export const PreviousButton = styled.div `
padding: 6px 12px;
color: #FFFFFF;
background: #434343;
border-radius: 8px;
cursor: pointer;
img{
    transform: rotate(180deg);
    height: 8px;
    margin-right: 8px;
    height: 8px;
    filter: invert(100%);
}
&:disabled{
    color: #D4D4D4;
    background: none;
    filter: invert(0%);
}
`;

export const NextButton = styled.div `
padding: 6px 12px;
color: #FFFFFF;
background: #434343;
border-radius: 8px;
cursor: pointer;
img{
    height: 8px;
    height: 8px;
    margin-left: 8px;
    filter: invert(100%);
}
&:disabled{
    color: #D4D4D4;
    background: none;
    filter: invert(0%);
}
`;

export const PageNoContainer = styled.div `
    display: flex;
`;

export const PageNo = styled.div `
    padding: 6px 10px;
    cursor: pointer;
    color: ${props => props.isActive ? "white" : "#2E2E2E"};
    background: ${props => props.isActive ? "#707070" : "none"};
    border-radius: 8px;
`;
