import styled from "styled-components";

export const PopupBackground = styled.div`
width: 100vw;
height: 100vh;
position: fixed;
top: 0;
left: 0;
display: flex;
align-items: center;
justify-content: center;
background: rgba(28, 28, 28, 0.6);
`;

export const DomainPopupContent = styled.div`
width: 584px;
height: 330px;
flex-shrink: 0;
border-radius: 6px;
background: #FFF;
`;

export const Header = styled.div`
display: inline-flex;
height: 56px;
padding: 15px 279px 15px 24.044px;
align-items: center;
flex-shrink: 0;
`;