import styled from "styled-components";

export const AdCopiesWrapper = styled.div`
    display: grid;
    grid-template-columns: 475px 475px;
    gap: 16px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 990px;
    height: 480px;
    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #80808030;
        border-radius: 10px;     
    }
`;

export const AdCopyContainer = styled.div`
    height: 224px;
    flex-shrink: 0;
    border-radius: 16px;
    background: #FFF;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
`;

export const AdHeader = styled.div`
    width: 443px;
    height: 38px;
    flex-shrink: 0;
    display: flex;
    gap: 12px;
    margin: 16px 16px 0 16px;
`;

export const Logo = styled.div`
    display: flex;
    padding: 6px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 16px;
    border: 1px solid #ECECEC;
    background: #F1F3F4;
    height: 32px;
    width: 32px;
    margin-top: 4px;
`

// export const Title = styled.div`
//     display: flex;
//     flex-direction: column;
//     gap: 2px;
// `;

export const Advertiser = styled.div`
    overflow: hidden;
    color: #2E2E2E;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const RedirectUrl = styled.div`
    overflow: hidden;
    color: #555;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
`;

export const AdContent = styled.div`
    overflow: hidden;
    color: #1A0DAB;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 16px;
`;

export const AdDescription = styled.div`
    overflow: hidden;
    color: #2E2E2E;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 0 16px;
`;

export const Footer = styled.div`
    width: 475px;
    height: 53px;
    flex-shrink: 0;
    border-radius: 0px 0px 16px 16px;
    border: 1px solid #E3E8EE;
    position: absolute;
    bottom: 0px;
    padding: 16px;
    display: flex;
    gap: 16px;
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const Text = styled.div`
    overflow: hidden;
    color: #2E2E2E;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;


export const NoDataContainer = styled.div`
text-align: end;
`

export const Card = styled.div`
border-radius: 8px;
background: #FFF;
// padding: 24px;
// display: flex;
// flex-direction: column;
// row-gap: 12px;
// width: 500px;
// height: 230px;
.hover-element {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  :hover .hover-element {
    opacity: 1;
    visibility: visible;
  }
`
export const CardBody = styled.div`
padding: 24px;
display: flex;
flex-direction: column;
row-gap: 12px;
/* width: 500px; */
height: 230px;
`

export const Header = styled.div`
display: flex;
column-gap: 16px;
`
export const LogoWrapper = styled.div`
border-radius: 16px;
border: 1px solid #ECECEC;
background: #F1F3F4;
display: flex;
padding: 6px;
width: 32px;
height: 32px;
justify-content: center;
align-items: center;

img{
    height: 20px;
}
`
export const TitleWrap = styled.div`
display: flex;
flex-direction: column;
row-gap: 2px;
width: 65%;
`
export const Title = styled.div`
overflow: hidden;
color: #2E2E2E;
text-overflow: ellipsis;
font-family: Inter;
font-size: 14px;
font-weight: 400;
`
export const KeywordWrap = styled.div`
overflow: hidden;
color: #3F33C8;
font-family: Inter;
font-size: 16px;
font-weight: 400;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
`
export const DescriptionWrap = styled.div`
overflow: hidden;
color: rgba(28, 28, 28, 0.50);
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 20px; /* 142.857% */
height: 62px;

`

export const FooterWrap = styled.div`
display: flex;
justify-content: space-between;
padding-top: 13px;
align-items: center;
`
export const CountryWrap = styled.div`
display: flex;
column-gap: 12px;
align-items: center;
`
export const Country = styled.div`
overflow: hidden;
color: rgba(28, 28, 28, 0.50);
text-overflow: ellipsis;
white-space: nowrap;
font-family: Inter;
font-size: 14px;
font-weight: 400;
`
export const GoogleIcon = styled.div`
img{
    height: 16px;
}
`
export const LinkWrap = styled.div`
overflow: hidden;
color: #598763;
font-family: Inter;
font-size: 12px;
font-weight: 300;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
`