import React, { useEffect } from 'react'
import { TableWrapper, TableContainer, RelatedKeyword, TableWrap, TableHeader, KeyHeader, StatusHeader, PlatformHeader, CountryHeader, TableBody, Row, HoverWrapper, KeyData, StatusData, PlatformData, CountryData, ShowMoreBtn, ShowMoreBtnWrap } from './tableComponent.styled'
import {
    createColumnHelper,
    getCoreRowModel,
    useReactTable,
    getSortedRowModel
} from '@tanstack/react-table'

import { useLocation, useNavigate } from 'react-router-dom'
import helpCircle from '../../../../../../media/Dashboard/help-circle.svg'
import sort from '../../../../../../media/Dashboard/sort.svg'
import blueDownArrow from '../../../../../../media/Dashboard/blueDownArrow.svg'
import shareData from '../../../../../../media/Dashboard/shareData.svg'
import { useDomainOverview } from '../../../context/domainoverview'
import { getSearchParams } from '../../../../../../utils/helper'
import Skeleton from "react-loading-skeleton";
import { useDomainOverviewInfo } from '../../../hooks/useDomainOverviewInfo'
import Tooltip from 'rc-tooltip';

const TableComponent = () => {
    const columnHelper = createColumnHelper();
    const { domainOverviewState, domainOverviewDispatch } = useDomainOverview()
    const useDomainOverviewInfoHook = useDomainOverviewInfo()
    const navigate = useNavigate()

    const location = useLocation()

    const columns = [
        columnHelper.accessor('no', {
            id: 'no',
            header: () => 'No',
            cell: info => info.getValue(),
        }),
        // columnHelper.accessor('query', {
        //     id: 'query',
        //     header: () => 'Query',
        //     cell: info => info.getValue(),
        // }),
        // columnHelper.accessor('platform', {
        //     id: 'platform',
        //     header: () => 'Platform',
        //     cell: info => info.getValue(),
        // }),
        // columnHelper.accessor(row => `${new Date(row.createdAt).getTime()}`, {
        //     id: 'createdAt',
        //     header: () => 'Time',
        //     cell: info => info.getValue(),
        // }),
        // columnHelper.accessor('newlyadded', {
        //     id: 'newlyadded',
        //     header: () => 'Newly added ads',
        //     cell: info => info.getValue(),
        // })
    ];

    const table = useReactTable({
        data: 'accou',
        columns,

        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),

        debugTable: true,
    });

    useEffect(() => {
        const searchParamFromState = getSearchParams(location.search)

        const {
            domain,
            country
        } = searchParamFromState
        useDomainOverviewInfoHook.getDomainKeywordsMutation({ query: domain, "page": 1 });
    }, [])

    return (
        <TableWrapper>
            <TableContainer>
                <RelatedKeyword>Related Keywords <Tooltip trigger={['hover']} overlayStyle={{ width: "100" }} overlay={<span>Releted keywords found for this domain</span>}>
                    <img src={helpCircle} />
                </Tooltip></RelatedKeyword>
                <TableWrap>
                    {table.getHeaderGroups().map(headerGroup => {
                        // console.log(headerGroup)
                        return <TableHeader>
                            <KeyHeader>
                                Keyword
                            </KeyHeader>
                            <StatusHeader >
                                Search Volume
                                {/* <img  src={sort}/> */}
                            </StatusHeader>
                            <PlatformHeader >
                                Competition
                                {/* <img  src={sort}/> */}
                            </PlatformHeader>
                            <CountryHeader>
                                CPC (avg)
                                {/* <img  src={sort}/> */}
                            </CountryHeader>

                        </TableHeader>

                    })}
                    {useDomainOverviewInfoHook.isGetDomainKeywordsLoading ?
                        <Skeleton style={{ 'height': '490px', marginTop: '30px' }} />
                        :
                        <>
                            <TableBody>
                                {domainOverviewState.relatedKeyword && domainOverviewState.relatedKeyword
                                    .map((row, index) => {
                                        if (!row) return
                                        return (<Row>
                                            <HoverWrapper onClick={() => navigate(`/keyword-overview/details?keyword=${row.keyword}&country=global`)}>
                                                <KeyData>
                                                    {row.keyword}
                                                    <img src={shareData} />
                                                </KeyData>
                                                <StatusData>
                                                    {row.vol}
                                                </StatusData>
                                                <PlatformData>
                                                    {row.competition}
                                                </PlatformData>
                                                <CountryData>{row.cpc.currency} {row.cpc.value}
                                                </CountryData>
                                            </HoverWrapper>
                                        </Row>
                                        )
                                    })}
                            </TableBody>
                            <ShowMoreBtnWrap>
                                {/* <ShowMoreBtn>Show more <img src={blueDownArrow} /></ShowMoreBtn> */}
                            </ShowMoreBtnWrap>
                        </>
                    }

                </TableWrap>
            </TableContainer>
        </TableWrapper>
    )
}

export default TableComponent