import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import WarningIcon from "../../../../../../media/Dashboard/warningicon.svg";
import { appClient } from "../../../../../../utils/axios-utils";
import Layout, { NewLayout } from "../../../Components/LoginLayout/layout";
import { useSurvey } from "./../../context/survey";
import {
  InputLabel,
  MainHeading,
  SubHeading,
  FormWrapper,
  InputField,
  SubmitBtn,
  Flex,
  DomainSection,
  Error,
  BtnWrapper,
  DomainError,
} from "./index.styled";
import { useAuth } from "../../../../../../context/auth";
import { useGetUserInfo } from "../../../../../hooks/useGetUserInfo";
import getCountryByDialingCode from '../../../../Dashboard/utils/getCountryByDialingCode'

const postDomainAdded = (domain) => {
  const data = { ...domain, fromOnBoarding: true };

  const config = {
    url: "/app/onboarding/website",
    method: "POST",
    data,
  };

  return appClient(config);
};

const AddDomain = () => {
    let toastId;
    const navigate = useNavigate()
    const { surveyState, surveyDispatch } = useSurvey()
    const { register, handleSubmit, formState: { errors }, setValue } = useForm()
    const { authState, authDispatch } = useAuth()
    const [domainError, setDomainError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [webiste, setWebsite] = useState("")
    const [submitted, setSubmitted] = useState(false)
    const useGetUserInfoHook = useGetUserInfo()

    useEffect(() => {
        setValue('website', "https://")
    }, [setValue])

    const { mutate: onSubmit } = useMutation(postDomainAdded, {
        onSuccess: (data) => {
            setDomainError(false)
            toast.success("Domain Added Successfully")
            toast.dismiss(toastId)

                    
            localStorage.setItem("feedbackpopup", true);

            authDispatch({type:'UPDATE_FEEDBACK_POPUP', value: true})

            useGetUserInfoHook.getCurrentUser()

        },
        onError: (err) => {
            setErrorMessage(err.response.data.errors[0].message)
            setDomainError(true)
            toast.dismiss(toastId)
        },
        onMutate: () => {
            setSubmitted(true)
            toastId = toast.loading("Adding...")
        },
        onSettled: () => {
            setSubmitted(false)
        }
    })

    return (
        <Layout>
        <DomainSection>
            <MainHeading>Enter your Domain</MainHeading> 
            <SubHeading >AdSpyder will track and analyze ads for your domain </SubHeading>

            <FormWrapper
                onSubmit={
                    handleSubmit((data) => {
                        console.log(data, authState.userLocation)
                        let country = getCountryByDialingCode(authState.userLocation?.countryCode);
                        console.log(country)
                        data.website = data.website;
                        data.country = country?.name;
                        onSubmit(data)
                    }
                    )
                }
                onChange={(e)=>{
                        setWebsite(e.target.value)
                    }}>
                <Flex>  <InputLabel> Domain </InputLabel> </Flex>

                <InputField
                    type="text"
                    value={webiste}
                    error={errors.website || domainError}
                    {...register("website", {
                        required: {
                            value: true,
                            message: "Domain is required"
                        },
                        pattern: {
                            value: /^(https?:\/\/)?([\w\d]+\.)?[\w\d]+\.\w{2,}(\/.*)?$/,
                            message: "Enter valid format"
                        }
                    })}
                    placeholder='Enter Domain eg: https://www.sample.com'
                />

                {errors.website && <Error> {errors.website.message} </Error>}

                {domainError && <DomainError> <img src={WarningIcon} /> <p>{errorMessage}</p> </DomainError>}

                <BtnWrapper>
                    <SubmitBtn type='submit' isDisabled={submitted || webiste.length < 11}> {submitted ? "Completing..." : "Complete Setup"} </SubmitBtn>
                </BtnWrapper>

            </FormWrapper>
        </DomainSection>
        </Layout>
    )
}

export default AddDomain
