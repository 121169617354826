import React from "react";
import Highlighter from "react-highlight-words"

import { Card, CardBody, ChannelDetails, LogoContainer, ChannelName, Description, FloatingButtonsContainer, Favorite, ImageContainer, VideoContainer, InnerContent, InnerDesc, InnerTitle, CTA, CallToActionButton, CardFooter, FooterLeft, FooterRight, AdDate, AdCountries, DetailsButton, SecondaryDetails, SelectButton, AdTypeContainer, InnerUrl } from "./index.styled"

import FavorietIcon from "../../../../../../media/Dashboard/addFavorite.svg"
import LinkedinIcon from "../../../../../../media/Dashboard/LinkedIn16.svg"

import GlobeIcon from '../../../../../../media/Dashboard/globe.svg';
import DateIcon from "../../../../../../media/Images/DateIcon.svg"
import CountryIcon from "../../../../../../media/Images/CountryIcon.svg"
import AltImage from '../../../../../../media/Dashboard/altimage.png'
import { useAdGenrator } from '../../../context/adgenerator';

const LinkedinCard = ({ad, ToggleSelectedAds}) => {
    const {adGenratorState, adGenratorDispatch} = useAdGenrator()

    const convertTimestamp = (timestamp) => {
        if(!timestamp){
            return "-"
        }
        // Create a new Date object based on the timestamp
        var date = new Date(Number(timestamp));
      
        // Get the month name
        var monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        var month = monthNames[date.getMonth()];
      
        // Get the year
        var year = date.getFullYear();
      
        // Assemble the formatted date string
        var formattedDate = date.getDate() + ' ' + month  + ' ' + year;
      
        return formattedDate;
    }

    return <Card key={
        ad.id
    }>
        <CardBody>
            <ChannelDetails>
                <LogoContainer>
                    <img src={`https://aws-adspyder-logos.s3.ap-south-1.amazonaws.com/${ad.id}`}/>
                </LogoContainer>
                <ChannelName>
                    {ad.title}
                    <span>Promoted</span>
                </ChannelName>
            </ChannelDetails>
            <Description>
                {ad.content}
            </Description>
            <AdTypeContainer>
                <img src={LinkedinIcon}/> Linkedin 
                {/* | <img src={ClockIcon}/>  */}
            </AdTypeContainer>
            <FloatingButtonsContainer>
                <SelectButton type="checkbox" id="selected" name="selected" onClick={()=>ToggleSelectedAds(ad.id)} checked={adGenratorState.selectedAds.includes(ad.id)}/>
                {/* <Favorite onClick={()=>{
                    authDispatch({type:'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'linkedin'})
                    authDispatch({type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id})
                    authDispatch({type:'UPDATE_SAVE_AD_POPUP', value: true})}}> 
                    <img src={FavorietIcon}/>
                </Favorite> */}
                {/* |
                <DropdownIcon>
                    <span/>
                </DropdownIcon> */}
            </FloatingButtonsContainer>
            {ad.image && <ImageContainer>
                <img src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${ad.id}`}/>
            </ImageContainer>}
            {ad.video && <VideoContainer>
                <video src={`https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${ad.id}`} preload="auto" controls loop style={{width: "100%", height: "100%"}}/>
            </VideoContainer>}
            {(!ad.video && !ad.image) && <ImageContainer>
                <img src={AltImage}/>
                </ImageContainer>}
            <SecondaryDetails>
                <InnerContent>
                <InnerUrl>
                    {ad.ctaUrl}
                </InnerUrl>
                <InnerDesc>
                    {ad.ctaTitle}
                </InnerDesc>
                <InnerTitle>
                    {ad.ctaContent}
                </InnerTitle>
                </InnerContent>
                <CallToActionButton>
                    <CTA>
                        {ad.ctaButton}
                    </CTA>
                </CallToActionButton>
            </SecondaryDetails>
        </CardBody>
    
        <CardFooter>
            <FooterLeft>
                <AdDate>
                {ad.firstSeen && ad.lastSeen && <img src={DateIcon}/>}{convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
                </AdDate> 
            <AdCountries>
                                                {ad.country.length > 0 && <img src={CountryIcon} />}{ ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
                </AdCountries>
            </FooterLeft>
            <FooterRight>
                <DetailsButton >
                {/* {(urlAnalysisState.adDetails.id == ad.id) && urlAnalysisState.detailsModal ? "Close details" : "More details"} */}
                </DetailsButton>
            </FooterRight>
        </CardFooter>
    </Card>
}

export default LinkedinCard