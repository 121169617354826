import React from 'react'
import { useReducer, createContext, useContext } from 'react'

import produce from 'immer'

import statics from "../_static"

const initialState = {
    activeFunnelType: statics.funnelList[0],
    activeNavItem: statics.navItems[0],
    activePlatform: statics.platforms[0],
    
    searchedKeyword: null,

    funnelAds: [],
    funnelCtaData: [],
    funnelKeywordsData: [],
    funnelTargetAudienceData: {
        demographicAudience: [],
        psychographicAudience: []
    },

    isFunnelAdsLoading: true,
    isFunnelStatsLoading: true,
    adsCountOnEachFunnel: null,

    keywordInputModalOpen: false,
    keywordTableOpen: false,
    keywordInput: '',
    keywordTableList:[],
    keywordInputList: [],
    keywordInputListError: '',
    funnelKeywordData: []
}

const KeywordFunnelAnalysisReducer = produce((draft, action) => {
    switch(action.type) {
        case 'SET_KEYWORD_INPUT':
            draft.keywordInput = action.value
            break

        case 'SET_KEYWORD_INPUT_MODAL_OPEN':
            draft.keywordInputModalOpen = action.value
            break
        
        case 'SET_KEYWORD_TABLE_OPEN':
            draft.keywordTableOpen = action.value
            break

        case 'SET_KEYWORD_TABLE_LIST':
            draft.keywordTableList = action.value
            break

        case 'SET_KEYWORD_INPUT_LIST':
            draft.keywordInputList = action.value
            break

        case 'SET_KEYWORD_INPUT_LIST_ERROR':
            draft.keywordInputListError = action.value
            break

        case 'SET_ACTIVE_FUNNEL_TYPE':
            draft.activeFunnelType = action.value
            break
            
        case 'SET_ACTIVE_NAV_ITEM':
            draft.activeNavItem = action.value
            break

        case 'SET_ACTIVE_PLATFORM':
            draft.activePlatform = action.value
            break

        case 'SET_SEARCHED_KEYWORD':
            draft.searchedKeyword = action.value
            break
        
        case 'SET_FUNNEL_ADS':
            draft.funnelAds = action.value
            break

        case 'SET_FUNNEL_CTA_DATA':
            draft.funnelCtaData = action.value
            break

        case 'SET_FUNNEL_KEYWORDS_DATA':
            draft.funnelKeywordsData = action.value
            break

        case 'SET_FUNNEL_TARGET_AUDIENCE_DATA':
            draft.funnelTargetAudienceData = action.value
            break

        case 'SET_FUNNEL_ADS_LOADING':
            draft.isFunnelAdsLoading = action.value
            break

        case 'SET_FUNNEL_STATS_LOADING':
            draft.isFunnelStatsLoading = action.value
            break

        case 'SET_ADS_COUNT_ON_EACH_FUNNEL': 
            console.log('context adscounteach', action.value)
            draft.adsCountOnEachFunnel = action.value
            break
        case 'SET_FUNNEL_KEYWORD_DATA':
            draft.funnelKeywordData = action.value
            break
        default:
            break
    }
})

const useKeywordFunnelAnalysisReducer = () => {
    return useReducer(KeywordFunnelAnalysisReducer, initialState)
}

const KeywordFunnelAnalysisContext = createContext()

export const KeywordFunnelAnalysisProvider = ({ children }) => {
    const [state, dispatch] = useKeywordFunnelAnalysisReducer()
    
    const value = {
        keywordFunnelAnalysisState: state,
        keywordFunnelAnalysisDispatch: dispatch
    }
    
    return <KeywordFunnelAnalysisContext.Provider value={value}>
        {children}
    </KeywordFunnelAnalysisContext.Provider>
}

export const useKeywordFunnelAnalysis = () => {
    return useContext(KeywordFunnelAnalysisContext)
}
