import styled from 'styled-components';

export const ResultsContainer = styled.div`
    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    width: auto; */
    display: flex;
    column-gap: 16px;
    row-gap: 16px;
    max-width: 1532px;
    position: relative;
        height: ${props => props.noResults ? "70%" : props.restricted ? "100%" : props.resultLoading ? "calc(100vh - 402px)": "unset"};
    /* height: calc(100vh - 245px); */
    background: #006ecc00;
    .loader{
        height: 200px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    body::-webkit-scrollbar-track {
        /* background: red;        color of the tracking area */
    }
    ::-webkit-scrollbar-thumb {
        background-color: grey;    /* color of the scroll thumb */
        border-radius: 10px;     
    }
`;

export const CardContainer = styled.div`
    background: #FFF;
    // border:  ${props => props.detailsOpen ? "2px solid #006ECC" : "1px solid #F2F2F2"};
    border-radius: 8px;
    // width: ${props => props.detailsOpen ? "520px": "250px"};
    // height: 373px;
    width: 500px;
    height: 216px;
    position: relative;
    /* padding: 16px; */
    .hover-element {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;
      }
      :hover .hover-element {
        opacity: 1;
        visibility: visible;
      }
`;

export const ResultsWraper = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
    position: relative;
`;

export const AdContainer = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    column-gap: 20px;
`;

export const AdBodyContainer = styled.div`
    width: 286px;
    margin-top: 24px;
`;

export const ProductName = styled.p`
    font-size: 14px;
    line-height: 21px;
    overflow: hidden;
    display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2; 
   -webkit-box-orient: vertical;
    min-height: 41px;
    // padding-top: 0px 16px;
    color: #3F33C8;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%; /* 24px */
`;

export const DetailsProductName = styled.p`
    color: #1A0DAB;
    font-size: 14px;
    margin-bottom: 4px;
    line-height: 21px;
    overflow: hidden;
    min-height: 41px;
    padding: 0px 16px;
`;


export const ProductPrice = styled.p`
color: rgba(28, 28, 28, 0.70);
font-size: 14px;
font-weight: 500;
`;

export const WebsiteUrl = styled.p`
    color: #598763;
    font-family: Inter;
    font-size: 12px;
    font-weight: 300;
    margin-top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
   -webkit-line-clamp: 1; 
    line-clamp: 1; 
   -webkit-box-orient: vertical;
`;

export const AdBodyBottomContainer = styled.div`
    // border-top: 1px solid #F2F2F2;
    // padding: 16px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 54px;
    // flex-direction: column;
    // gap: 12px;
`
export const AdDate = styled.div`
    display: flex;
    font-size: 14px;
    align-items: center;
    margin-top: 8px;
    overflow: hidden;
    color: rgba(28, 28, 28, 0.50);
    text-overflow: ellipsis;
    font-weight: 400;
    line-height: 20px; 
    letter-spacing: -0.28px;
    img{
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
`;

export const AdDetailsDate = styled.div`
    display: flex;
    font-size: 14px;
    color: #202124;
    line-height: 22px;
    align-items: center;
    padding: 0px 16px;
    margin-bottom: 16px;

    img{
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
`;

export const AdCountries = styled.div`
   display: flex;
column-gap: 12px;
align-items: center;
`;
export const Country = styled.div`
overflow: hidden;
color: rgba(28, 28, 28, 0.50);
text-overflow: ellipsis;
white-space: nowrap;
font-family: Inter;
font-size: 14px;
font-weight: 400;
`
export const AdDetailsCountries = styled.div`
    display: flex;
    font-size: 14px;
    color: #202124;
    line-height: 22px;
    align-items: center;
    padding: 0px 16px;
    margin-bottom: 16px;
    flex-wrap: wrap;
    p{
        color: #919191;
        margin-right: 16px;
    }
    > img{
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
`;

export const AdKeywords = styled.div`
    display: flex;
    font-size: 14px;
    color: #202124;
    line-height: 22px;
    align-items: center;
    padding: 0px 16px;
    flex-wrap: wrap;
    label{
        font-size: 14px;
        color: rgb(32, 33, 36);
        line-height: 22px;
        margin-right: 10px;
    }
    p{
        color: #919191;
        margin-right: 16px;
    }
`;

export const SeeMoreMoreCountries = styled.div`
    cursor: pointer;
    color: #504e4e;
`;

export const SeeMoreMoreKeywords = styled.div`
    cursor: pointer;
    color: #504e4e;
`;

export const AveragePosition = styled.div`
    padding: 0px 16px;
    margin-bottom: 16px;
    font-size: 14px;
    label{
        margin-right: 10px;
    }
`;

export const DetailsButton = styled.div`
    color: #006ECC;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    height: 32px;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 16px 16px 16px;
`;

export const DetailsContainer = styled.div`
    width: 520px;
    height: 100%;
    display: flex;
`;

export const Left = styled.div`
    position: relative;
    width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid #F2F2F2;
`;

export const Right = styled.div`
    width: 270px;
    height: 100%;
`;

export const DetailsHeading = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 20px;
`;

export const CloseButton = styled.div`
    cursor: pointer;
    margin-right: 8px;
    font-size: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const DetailsWraper = styled.div`
    overflow-y: auto;
    height: 300px;
    padding-bottom: 16px;
    margin-right: 10px;
    ::-webkit-scrollbar {
        width: 4px;               /* width of the entire scrollbar */
    }

    body::-webkit-scrollbar-track {
        /* background: red;        color of the tracking area */
    }
    ::-webkit-scrollbar-thumb {
        background-color: #80808047;    /* color of the scroll thumb */
        border-radius: 10px;     
    }
`;

export const DetailsImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    padding: 16px 16px 0px 16px;
    height: 80%;
    img{
        height: 190px;
        object-fit: scale-down;
    }
`; 

export const ImageContainer = styled.div`
width: 160px;
height: 188px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 14px;
    border-radius: 4px;
    background: #F2F2F2;

    img{
        height: 120px;
        object-fit: scale-down;
    }
`;

export const FloatingButtonsContainer = styled.div`
    position: absolute;
    right: 12px;
    top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F9F9F9;
    color: #D4D4D4;
    font-size: 8px;
`;

export const Favorite = styled.div`
    background: #F9F9F9;
    width: 32px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    border-radius: 4px 0px 0px 4px;
    img{
        margin: 25%;
    }
    &:hover{
        background: #ECECEC;
    }
    &:active{
        background: #DCEEFF;
    }
    
`;

export const DropdownIcon = styled.div`
    background: #F9F9F9;
    width: 32px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    border-radius: 0px 4px 4px 0px;
    span{
        border: solid black;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        margin: 30%;
    }
    &:hover{
        background: #ECECEC;
    }
    &:active{
        background: #DCEEFF;
    }
`;


export const NoResults = styled.div`
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
        width: 200px;
        margin-bottom: 32px;
    }
    h2{
        font-size: 22px;
        font-weight: 500;
        color: #505050;
        text-align: center;
        margin-bottom: 12px;
    }
    p{
        font-size: 14px;
        font-weight: 300;
        color: #4E4E4E;
        max-width: 360px;
        text-align: center;
    }
    a{
        font-size: 14px;
        font-weight: 300;
        color: #006ECC;
        text-align: center;
        margin-top: 16px;
    }
`;

export const RequestDataBtn = styled.div`
    border-radius: 8px;
    background-color: rgb(255, 113, 30);
    opacity: ${props => props.isDisabled ? "0.5": "1"};
    font-size: 14px;
    border: none;
    cursor: pointer;
    padding: 9px 16px;
    margin-top: 20px;
    pointer-events: ${props => props.isDisabled ? "none" : "auto"};
    width: ${props => props.isDisabled ? "auto": "265px"};
    p{
        display: flex;
        max-width: 360px;
        justify-content: center;
        /* text-decoration: ${props => props.isDisabled || props.disable ? "none": "underline"}; */
        /* color: ${props => props.isDisabled ? "#B0B0B0": "#006ECC"}; */
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        color: #FFF;
        pointer-events: ${props => props.isDisabled ? "none" : "auto"};
        img{
            height: 14px;
            width: 14px;
            margin-left: 4px;
        }
    }
`;

export const CopyImg = styled.img`
    height: 18px;
    margin: 3px 6px;
    cursor: pointer;
`;

export const HoverCard = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    // background: #1C1C1C59;
    border-radius: 8px;
    cursor: pointer;
    padding: 16px;

    background: linear-gradient(360deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.35) 32.4%, rgba(0, 0, 0, 0.15) 63.4%, rgba(0, 0, 0, 0.05) 100%);
`;

export const AdDetailsButton = styled.div`
    border-radius: 24px;
    background: #FFF;
    width: fit-content;
    display: flex;
    padding: 10px 12px;
    gap: 4px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const SaveAdsButton = styled.div`
    border-radius: 24px;
    background: ${props => props.isSaved ? "#1C1C1C" : "#FF711E"};
    color: #FFF;
    display: flex;
    padding: 10px 12px;
    gap: 4px;
    justify-content: center;
    align-items: center;
    width: 75px;
    cursor: pointer;
`;

export const HoverCardWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 150px;
`;