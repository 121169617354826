import { useImageAdGenerator } from "../../../../context/imageAdGeneration";
import statics from "../../../../statics";
import { AdTypesContainer, AdTypeName, AdTypeContainer, AdTypeIcon, AdTypeIconContainer, Header, AdTypeWrapper, FooterWrap } from "./AdType.styled";
import Footer from "./Footer";
import AdGoalPP from "./AdGoal";
function AdType() {
    const { imageAdGeneratorState, imageAdGeneratorDispatch } = useImageAdGenerator()
    const { adTypes } = statics

    const handleGoalSelection = (adtype) => {
        imageAdGeneratorDispatch({type: "SET_AD_TYPE", value: adtype})
        imageAdGeneratorDispatch({type: "AD_GOAL_TOGGLE", value: true})
    }
    
    return ( 
        <>
        <Header>Ad Type</Header>
        <AdTypeWrapper>
            <AdTypesContainer>
                {adTypes.map((adtype) => {
                    const isSelectedGoal = imageAdGeneratorState.adType === adtype.name
                    console.log(isSelectedGoal,'adtype')

                    return(
                        <AdTypeContainer key={adtype.id} onClick={() => handleGoalSelection(adtype.name)} isSelected={isSelectedGoal} >
                            <AdTypeIconContainer isSelected={isSelectedGoal} >
                                <AdTypeIcon src={ isSelectedGoal ? adtype.activeIcon : adtype.inActiveIcon} alt="adtype" />
                            </AdTypeIconContainer>
                            <AdTypeName isSelected={isSelectedGoal} >{adtype.name}</AdTypeName>
                        </AdTypeContainer>
                    )
                })}
                
            </AdTypesContainer>
            {imageAdGeneratorState.adGoalToggle && <AdGoalPP />}
            <FooterWrap>
                <Footer/>
            </FooterWrap>
        </AdTypeWrapper>
        </>
     );
}

export default AdType;