import {
  CheckBox,
  Choice,
  ChoiceContainer,
  ChoiceDetail,
  ChoicesList,
  ChoicesWindow,
  Close,
  Completed,
  Content,
  ContentHeader,
  CornerImage,
  KeywordInputBox,
  Footer,
  HeaderTitle,
  Key,
  Pair,
  SelectedKeyword,
  SelectedKeywordsList,
  SelectedList,
  SelectedWindow,
  SelectingInstruction,
  SelectingPanel,
  SetupButton,
  TaskNo,
  Value,
  CloseIcon,
  LoaderContainer,
  NotFound,
} from "./index.styled";

import headerBg from "./assets/header-background.svg";
import checked from "./assets/checked.svg";
import unchecked from "./assets/unchecked.svg";
import close from "./assets/close.svg";

import { useEffect, useMemo, useState } from "react";

import { LineLoader } from "../../../../globalStyles";
import SelectedCountry from "../../../pages/Dashboard/components/ProjectOverview/DashboardSetup/components/SelectedCountry";
import { useGetUserInfo } from "../../../hooks/useGetUserInfo";
import LimitExceededPopup from "../../limitExceededPopup";
import { getDomainName, urlPatternValidation } from "../../../../utils/helper";
import { customToast } from "../../../../utils/toast-utils";
import GlobalLoader from "../../../HOC/GlobalLoader";
import Rolling from "./assets/Rolling.svg";
import { useDashboard } from "../../../../context/dashboard";
import { useAuth } from "../../../../context/auth";
import { useDashboardInfo } from "../../../pages/Dashboard/hooks/useDashboardInfo";
// import { useDashboardInfo } from "../../pages/Dashboard/hooks/useDashboardInfo";

function AddNew({
  choices,
  selectingInstruction,
  onSetup,
  task,
  handleClose,
  forPopup = false,
  forKeyword = true,
  isGetKeywordChoicesLoading,
}) {
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [userKeywordList, setUserKeywordList] = useState([]);

  const [userInput, setUserInput] = useState("");
  const {
    isAddKeywordsForTrackingLoading,
    isAddCompetitorsForTrackingLoading,
  } = useDashboardInfo();
  const {
    dashboardState: { setupStatus },
  } = useDashboard();
  const { canAccessFeature, getCurrentUser } = useGetUserInfo();

  const toggleSelection = (selectedIndices, setSelectedIndices) => (index) => {
    setSelectedIndices(
      selectedIndices.includes(index)
        ? selectedIndices.filter((i) => i !== index)
        : [...selectedIndices, index]
    );
  };

  const renderChoices = (
    choices,
    selectedIndices,
    toggleFn,
    isKeyword = true
  ) => {
    return choices?.length > 0 ? (
      choices?.map((choice, index) => {
        const isChecked = selectedIndices.includes(index);
        const { keyword, avgMonthlySearches, totalAds, domainName } = choice;
        return (
          <ChoiceContainer key={index} onClick={() => toggleFn(index)}>
            <Choice isSelected={isChecked}>
              {isKeyword ? keyword : domainName}
              <ChoiceDetail>
                {isKeyword && (
                  <>
                    <Pair>
                      <Key>Search Volume - </Key>
                      <Value>{avgMonthlySearches}</Value>
                    </Pair>
                    <Pair>
                      <Key>no.of adcopies - </Key>
                      <Value>{totalAds}</Value>
                    </Pair>
                  </>
                )}
                {!isKeyword && (
                  <Pair>
                    <Key>no.of adcopies - </Key>
                    <Value>{totalAds}</Value>
                  </Pair>
                )}
              </ChoiceDetail>
            </Choice>
            <CheckBox isChecked={isChecked}>
              <Completed
                src={isChecked ? checked : unchecked}
                draggable={false}
              />
            </CheckBox>
          </ChoiceContainer>
        );
      })
    ) : (
      <NotFound>
        <p>No {isKeyword ? "keyword" : "competitor"} found</p>
        <p>Try adding custom {isKeyword ? "keywords" : "competitors"} </p>
      </NotFound>
    );
  };

  const renderSelectedList = (choices, selectedIndices, setSelectedIndices) => {
    return selectedIndices.map((index) => {
      const { keyword, domainName } = choices[index] || {};
      if (keyword || domainName) {
        return (
          <SelectedKeyword key={index}>
            {keyword || domainName}
            <Close
              src={close}
              draggable={false}
              onClick={() =>
                setSelectedIndices(selectedIndices.filter((i) => i !== index))
              }
            />
          </SelectedKeyword>
        );
      }
    });
  };

  const LoadingScreen = () => {
    return (
      <LoaderContainer>
        <h2>
          Finding {forKeyword ? "keywords" : "competitors"} for your domain
        </h2>
        <span>(usually takes 1 - 2 minute)</span>
        <LineLoader />
      </LoaderContainer>
    );
  };

  const renderUserInputKeywords = () => {
    return (
      userKeywordList &&
      userKeywordList.length > 0 &&
      userKeywordList.map((data, index) => {
        return (
          <SelectedKeyword key={index}>
            {data}
            <Close
              src={close}
              draggable={false}
              onClick={() =>
                setUserKeywordList(userKeywordList.filter((i) => i !== data))
              }
            />
          </SelectedKeyword>
        );
      })
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      let finalList = [];
      if (!userKeywordList.includes(event.target.value)) {
        if (!forKeyword) {
          if (urlPatternValidation(event.target.value)) {
            finalList = [getDomainName(event.target.value), ...userKeywordList];
          } else {
            customToast({ message: "Not a valid url", type: "error" });
          }
        } else {
          finalList = [event.target.value, ...userKeywordList];
        }
      } else {
        finalList = userKeywordList;
      }

      setUserKeywordList(finalList);
      setUserInput("");
    }
  };

  //   const handlePaste = (event) => {
  //     // Prevent the default paste behavior
  //     event.preventDefault();

  //     // Get the pasted data
  //     const pasteData = (event.clipboardData || window.clipboardData).getData('text');

  //     // Split the pasted data by commas, tabs, or new lines
  //     const separatedData = pasteData.split(/[\s,]+/);

  //     let finalList = [];
  //       if (!userKeywordList.includes(event.target.value)) {
  //         if (!forKeyword) {
  //           if (urlPatternValidation(event.target.value)) {
  //             finalList = [ getDomainName(event.target.value),...userKeywordList,];
  //           } else {
  //             customToast({ message: "Not a valid url", type: "error" });
  //           }
  //         } else {
  //           finalList = [event.target.value,...userKeywordList];
  //         }
  //       } else {
  //         finalList = userKeywordList;
  //       }

  //       setUserKeywordList(finalList);
  //       setUserInput("");
  //     setSelectedKeywords([...separatedData,...selectedKeywords])
  // };

  const renderContentHeader = useMemo(() => {
    if (selectedIndices?.length) {
      return `- ${selectedIndices.length} of ${choices.length}`;
    }
    return "";
  }, [selectedIndices]);

  useEffect(() => setSelectedIndices([]), [forKeyword]);

  if (
    !canAccessFeature({
      type: forKeyword ? "keyword-tracking" : "competitor-tracking",
      requestLength: selectedIndices.length + userKeywordList.length,
    })
  )
    return (
      <LimitExceededPopup
        closeEnabled
        onClose={handleClose}
        err={`${forKeyword ? "keyword" : "competitor"}-tracking-quota-expired`}
      />
    );

  return (
    <Content>
      <ContentHeader forCompetitor={!forKeyword}>
        {!forPopup && <TaskNo>{task.sno}</TaskNo>}
        <HeaderTitle>{`Setup ${
          forKeyword ? "keywords" : "competitors"
        } for tracking`}</HeaderTitle>
        {/* <Key>{`- ${
          forKeyword ? keywordsCountStillCanAdd : competitorsCountStillCanAdd
          } more ${forKeyword ? "keywords" : "competitors"} can add`}</Key> */}
        <CornerImage src={headerBg} draggable={false} />
        {forPopup && <CloseIcon src={close} onClick={() => handleClose()} />}
      </ContentHeader>
      <SelectingPanel>
        <ChoicesWindow
          isActive={
            isAddKeywordsForTrackingLoading ||
            isAddCompetitorsForTrackingLoading
          }
        >
          {!choices && isGetKeywordChoicesLoading ? (
            LoadingScreen()
          ) : (
            <ChoicesList>
              {renderChoices(
                choices,
                selectedIndices,
                toggleSelection(selectedIndices, setSelectedIndices),
                forKeyword
              )}
            </ChoicesList>
          )}
        </ChoicesWindow>
        <SelectedWindow forCompetitor={!forKeyword}>
          <SelectedList>
            {selectedIndices.length === 0 && (
              <SelectingInstruction>
                {selectingInstruction}
              </SelectingInstruction>
            )}

            <SelectedKeywordsList>
              {renderSelectedList(choices, selectedIndices, setSelectedIndices)}
              {renderUserInputKeywords()}
            </SelectedKeywordsList>
          </SelectedList>
          <KeywordInputBox>
            <input
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder={`Enter custom ${
                forKeyword ? "keyword" : "domains"
              } here`}
              // onPaste={handlePaste}
            />
          </KeywordInputBox>
          <Footer>
            <SelectedCountry forDashboardSetup />
            <SetupButton
              isActive={
                selectedIndices.length > 0 ||
                (userKeywordList && userKeywordList.length > 0)
              }
              onClick={() => {
                onSetup(selectedIndices, userKeywordList);
                setUserKeywordList([]);
                !forKeyword &&
                  setTimeout(() => {
                    getCurrentUser()
                  }, 1000);
              }}
            >
              {setupStatus ? <img src={Rolling} /> : "Setup now"}
            </SetupButton>
          </Footer>
        </SelectedWindow>
      </SelectingPanel>
    </Content>
  );
}

export default AddNew;
