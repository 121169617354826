import React from "react"
import { useSearchParams, useNavigate } from "react-router-dom"

import { Input, InputContainer, ToolTipImageContainer, OurOwnToolTip, Wrapper, ToolT, OurOwnToolTipipContainer } from './index.styled'
import ToolTipLogo from "../../../../../../../media/Dashboard/tooltip.svg"

import ToolTipIcon from '../../../../../../../media/Dashboard/info_button_tool_tip.svg'
import SearchIcon from '../../../../../../../media/Dashboard/searchIcon.svg'

// import { useSearch } from "../../../../../context/search"
import Tooltip from 'rc-tooltip';
import { urlPatternValidation } from "../../../../../../../utils/helper"
import { useAuth } from "../../../../../../../context/auth"

const UrlInput = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()

    const { authState, authDispatch } = useAuth()
    // const { searchState, searchDispatch} = useSearch()

    const handleSearch = (event) => {
        if (event.key === 'Enter') {
            const { searchMode } = authState

            if (!authState.searchPlatform) {
                return authDispatch({ type: 'SET_SEARCH_PLATFORM_ERROR', value: true })
            }

            if (searchMode === "url") {
                const { urlSearchType } = authState
                authDispatch({ type: 'UPDATE_FILTER_STATUS', value: 'loading' })
                authDispatch({ type: "CLEAR_FILTERS" })

                if (urlSearchType === "exact") {
                    const { domainUrlInput } = authState

                    if (domainUrlInput.valid) {
                        authDispatch({ type: "SET_HIGHLIGHT_WORDS", value: [] })
                        authDispatch({ type: 'SET_URL_INPUT_ERROR', value: false })

                        searchParams.set('searchMode', "url")
                        searchParams.set('urlSearchType', "exact")
                        searchParams.set('domainUrlInput', domainUrlInput.value);
                        searchParams.set('page', 1);
                        searchParams.set('sortType', 'relevant');
                        searchParams.delete('selectedCountries');
                        searchParams.delete('startDate');
                        searchParams.delete('endDate');
                        // setSearchParams(searchParams)
                        navigate(`/adspy/${authState.searchPlatform}?${searchParams.toString()}`)

                    } else {
                        authDispatch({ type: 'SET_URL_INPUT_ERROR', value: true })
                    }
                } else if (urlSearchType === "domain") {
                    const { domainUrlInput } = authState

                    if (domainUrlInput.valid) {
                        authDispatch({ type: "SET_HIGHLIGHT_WORDS", value: [] })
                        authDispatch({ type: 'SET_URL_INPUT_ERROR', value: false })

                        searchParams.set('searchMode', "url")
                        searchParams.set('urlSearchType', "domain")
                        searchParams.set('domainUrlInput', domainUrlInput.value);
                        searchParams.set('page', 1);
                        searchParams.set('sortType', 'relevant');
                        searchParams.delete('selectedCountries');
                        searchParams.delete('startDate');
                        searchParams.delete('endDate');
                        // setSearchParams(searchParams)
                        navigate(`/adspy/${authState.searchPlatform}?${searchParams.toString()}`)

                    } else {
                        authDispatch({ type: 'SET_URL_INPUT_ERROR', value: true })
                    }
                } else if (urlSearchType === "text") {
                    const { domainUrlInput } = authState

                    authDispatch({ type: "SET_HIGHLIGHT_WORDS", value: [] })

                    searchParams.set('searchMode', "url")
                    searchParams.set('urlSearchType', "text")
                    searchParams.set('domainUrlInput', domainUrlInput.value);
                    searchParams.set('page', 1);
                    searchParams.set('sortType', 'relevant');
                    searchParams.delete('selectedCountries');
                    searchParams.delete('startDate');
                    searchParams.delete('endDate');
                    // setSearchParams(searchParams)
                    navigate(`/adspy/${authState.searchPlatform}?${searchParams.toString()}`)

                }

            }
        }
    }

    return <Wrapper>
        {/* <ToolTipContainer>
        {tooltipHeading} <Tooltip placement="right" trigger={['hover']} overlay={<span>{tooltipText}</span>}>
            <img src={ToolTipIcon}/>
        </Tooltip>
    </ToolTipContainer> */}
        <InputContainer>
            <img src={SearchIcon} />
            <Input
                onKeyDown={handleSearch}
                error={authState.urlInputError}
                name="domain-url-input"
                type="text"
                placeholder="Enter an URL"
                autoComplete="off"
                value={authState.domainUrlInput.value}
                onChange={(e) => {
                    const isTrueVal = urlPatternValidation(e.target.value)
                    console.log(isTrueVal)
                    authDispatch({ type: "SET_DOMAIN_URL_INPUT", payload: { value: e.target.value, valid: isTrueVal } })
                    authDispatch({ type: 'SET_URL_INPUT_ERROR', value: false })
                    if (authState.searchDisabled) {
                        authDispatch({ type: 'SET_SEARCH_DISABLED', value: false })
                    }
                }}
            />
            <ToolTipImageContainer
                error={authState.urlInputError} className="tooltip-container"
            >
                <img src={ToolTipLogo} />
                <OurOwnToolTip>
                    {authState.urlSearchType == "text" ? "Please enter a single word" : "Please enter a url"}
                </OurOwnToolTip>
            </ToolTipImageContainer>
        </InputContainer>
    </Wrapper>
}

export default UrlInput