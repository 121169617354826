import React, { useRef, useState } from 'react'
import { useNavigate } from "react-router-dom"
import {
    useMutation
} from "@tanstack/react-query"
import NoResultsLogo from "../../../../../../../media/Dashboard/noresults.svg"
import CountryIcon from "../../../../../../../media/Images/CountryIcon.svg"
import Skeleton from 'react-loading-skeleton'

// Styles
import {
    SearchTermsList,
    SearchTermsContainer,
    CountriesContainer,
    AvgPosition,
    DateRange,
    DestinationUrl,
    DetailDescription,
    DetailsHeadline,
    AdDetailsBody,
    CloseDetails,
    DetailsHeader,
    DetailsContainer,
    FooterRight,
    AdCountries,
    FooterLeft,
    CardFooter,
    AdUrl,
    CardBody,
    Favorite,
    FloatingButtonsContainer,
    Card,
    CardsList,
    ResultsContainer,
    HightlightContent,
    AdHeader,
    WebsiteNameContainer,
    WebsiteName,
    NoResults,
    ViewMoreAds
} from "./index.styled"
import _static from "../../../../../../../config/static"
import SearchLoader from '../../../../../../HOC/SearchLoader'

import Highlighter from "react-highlight-words"

import BlueRightArrow from '../../../../../../../media/Dashboard/blue-right-arrow.svg'

//Icons
import FavorietIcon from "../../../../../../../media/Dashboard/addFavorite.svg"
import CloseBtn from "../../../../../../../media/Dashboard/detailsPaneClose.svg"

import {appClient} from '../../../../../../../utils/axios-utils'
import { useAuth } from '../../../../../../../context/auth'
import { useLandingPageAnalysis } from '../../../../context/landingPageAnalysis'
import { getTodayDate } from '../../../../../../../utils/dateFormat'
import SaveAdsPopup from '../../../../../../components/SaveAdsPopup'

const adDetails = async (id) => {
    const config = {
        url: `/detail/bing/${id}`,
        method: "GET"
    }

    return appClient(config)
}


const requestDataCrawl = async (data) => {
    const config = {
        url: `/track/search/update-search-query`,
        method: "POST",
        data
    }
  
    return appClient(config)
  }

const BingResults = ({isDataLoading}) => {
    const {landingPageAnalysisState, landingPageAnalysisDispatch} = useLandingPageAnalysis()
    const [ basicAdDetails, setBasicAdDetails] = useState({})
    const [ dataRequested, setDataRequested ] = useState(false)
    const navigate = useNavigate()

    const {authState, authDispatch} = useAuth()
    
    const containerRef = useRef(null);

    const toggleDetailsModal = (ad,idx) => {
        if(landingPageAnalysisState.adDetails.id){
            if((landingPageAnalysisState.adDetails.id == ad.id) && landingPageAnalysisState.detailsModal){
                //if details is open and clicked on close button on ads card
                setBasicAdDetails({})
                landingPageAnalysisDispatch({type:'SET_AD_DETAILS', value: {}})
                landingPageAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: false})
                landingPageAnalysisDispatch({type:'UPDATE_COLLAPSE_CALENDER', value: false})
            }else{
                landingPageAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: true})
                landingPageAnalysisDispatch({type:'UPDATE_COLLAPSE_CALENDER', value: true})
                setBasicAdDetails(ad)
                adsDetailsMutate(ad.id)
            }
        }else{
            landingPageAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: true})
            landingPageAnalysisDispatch({type:'UPDATE_COLLAPSE_CALENDER', value: true})
            setBasicAdDetails(ad)
            adsDetailsMutate(ad.id)
        }
        setTimeout(() => {
            scrollToElement(idx)
        }, 100);
    }

    const convertTimestamp = (timestamp) => {
        // Create a new Date object based on the timestamp
        var date = new Date(timestamp);
      
        // Get the month name
        var monthNames = [
          'January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
        ];
        var month = monthNames[date.getMonth()];
      
        // Get the year
        var year = date.getFullYear();
      
        // Assemble the formatted date string
        var formattedDate = date.getDate() + ' ' + month  + ' ' + year;
      
        return formattedDate;
      }
    
    const {mutate: requestData, isLoading: isRequestDataLoading} = useMutation(requestDataCrawl, {
      onSuccess: (data) => {
          setDataRequested(true)
      },
      onError:  ((e)=>{
  
      })
    })

    const {mutate: adsDetailsMutate, isLoading: isDetailsLoading} = useMutation(adDetails, {
        onSuccess: (data) => {
            landingPageAnalysisDispatch({type:'SET_AD_DETAILS', value: data})
            // if(landingPageAnalysisState.adDetails.id == data.id || !landingPageAnalysisState.adDetails.id){
            //     landingPageAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: !landingPageAnalysisState.detailsModal})
            // }
        },
        onError:  ((e)=>{
            landingPageAnalysisDispatch({type:'SET_AD_DETAILS', value: {}})
        })
    })

    const onDataRequest = () => {
        if(!dataRequested){
            requestData({id: landingPageAnalysisState.queryId})
        }
      }

    const onDetailsClose = () => {
        setBasicAdDetails({})
        landingPageAnalysisDispatch({type:'SET_AD_DETAILS', value: {}})
        landingPageAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: false})
        landingPageAnalysisDispatch({type:'UPDATE_COLLAPSE_CALENDER', value: false})
    }

    const scrollToElement = (index) => {
        const element = containerRef.current.children[index];
        const scrollPosition = element.offsetTop - 20;
        containerRef.current.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    };

    // if(authState.isRestrictedPopup){
    //     return  <ResultsContainer detailsModal={landingPageAnalysisState.detailsModal}>
    //         <PlatformRestrictedPopup err={searchState.searchError}/>
    //     </ResultsContainer>
    // }

    // if(searchState.limitExceeded){
    //     return  <ResultsContainer detailsModal={landingPageAnalysisState.detailsModal}>
    //         <LimitExceededPopup err={searchState.searchError}/>
    //     </ResultsContainer>
    // }

    return  <ResultsContainer detailsModal={landingPageAnalysisState.detailsModal}>
         {isDataLoading ? <div className='loader'>
        <SearchLoader/>
      </div> : landingPageAnalysisState.ads.length == 0 ? <NoResults>
                        <img src={NoResultsLogo}/>
                        <h2>No Results Found</h2>
                        <p>No ads found on this specific date. try some other dates</p>
                         {/* <RequestDataBtn dataRequested={dataRequested ? dataRequested : ""} disable={isRequestDataLoading} onClick={onDataRequest}>{dataRequested ? <p>Your request for data crawl has been accepted<img src={TickImg}/></p> : <p>{isRequestDataLoading ? "Requesting...": "Request data crawl for this query"}</p>}</RequestDataBtn> */}
                    </NoResults> : <>
                    {authState.saveAdsPopupOpen && <SaveAdsPopup/>}
                <CardsList ref={containerRef} detailsModal={landingPageAnalysisState.detailsModal}> {
                    landingPageAnalysisState.ads && landingPageAnalysisState.ads.length > 0 && landingPageAnalysisState.ads.slice(0, 4).map((ad, idx) => {
                        return <Card detailsOpen={landingPageAnalysisState.adDetails.id == ad.id} key={
                            ad.id
                        }>
                            <FloatingButtonsContainer>
                                <Favorite isFav={idx == 1} onClick={()=>{
                                    authDispatch({type:'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'bing'})
                                    authDispatch({type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id})
                                    authDispatch({type:'UPDATE_SAVE_AD_POPUP', value: true})}}> 
                                    <img src={FavorietIcon}/>
                                </Favorite>
                                {/* |
                                <DropdownIcon>
                                    <span/>
                                </DropdownIcon> */}
                            </FloatingButtonsContainer>
                            <CardBody>
                                {/* <SponsoredText>
                                    Sponsored
                                </SponsoredText> */}
                                <AdHeader>
                                    {/* <WebsiteLogo>
                                        <img src={GlobeIcon}/>
                                    </WebsiteLogo> */}
                                    <WebsiteNameContainer>
                                        <WebsiteName>
                                            {ad.title}
                                        </WebsiteName>
                                        <AdUrl>
                                            {ad.url ? ad.url : ad.url}
                                        </AdUrl>
                                    </WebsiteNameContainer>
                                </AdHeader>
                                {/* <Highlighter
                                    className="title-wrapper-span"
                                    highlightClassName="title-highlight-bold"
                                    searchWords={landingPageAnalysisState.highlightWords}
                                    autoEscape={true}
                                    textToHighlight={ad.title}
                                    highlightTag={HightlightTitle}
                                /> */}
                                <Highlighter
                                    className="content-wrapper-span"
                                    highlightClassName="content-highlight-bold"
                                    searchWords={[]}
                                    autoEscape={true}
                                    textToHighlight={ad.content}
                                    highlightTag={HightlightContent}
                                />
                            </CardBody>
                            <CardFooter>
                                <FooterLeft>
                                    {/* <AdDate>
                                        {ad.firstSeen && ad.lastSeen && <img src={DateIcon}/>}{convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
                                    </AdDate> */}
                                    <AdCountries>
                                                {ad.country.length > 0 && <img src={CountryIcon} />}{ ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
                                    </AdCountries>
                                </FooterLeft>
                                <FooterRight>
                                    {/* <DetailsButton onClick={()=>toggleDetailsModal(ad,idx)}>
                                        {landingPageAnalysisState.adDetails.id == ad.id ? "Close details" : "More details"}
                                    </DetailsButton> */}
                                </FooterRight>
                            </CardFooter>
                        </Card>
                })
                }
                <ViewMoreAds onClick={()=>window.open(`https://app.adspyder.io/adspy/bing?searchMode=url&keywordSearchType=phrase&phraseInput=&page=1&excludedTerm=&searchIn=title%2Ccontent&sortType=relevant&urlSearchType=domain&domainUrlInput=${landingPageAnalysisState.searchUrl}&startDate=${landingPageAnalysisState.selectedYear+"-"+landingPageAnalysisState.selectedMonth+"-"+landingPageAnalysisState.selectedDay}&endDate=${getTodayDate()}`)}>View More Ads <img src={BlueRightArrow}/></ViewMoreAds> 
                </CardsList>
                {
                    landingPageAnalysisState.detailsModal && <DetailsContainer>
                    <DetailsHeader>
                        <h3>Ad details</h3> <CloseDetails onClick={onDetailsClose}><img src={CloseBtn}/></CloseDetails>
                    </DetailsHeader>
                    <AdDetailsBody>
                        <DetailsHeadline>
                            <label>Heading:</label>{basicAdDetails.title}
                        </DetailsHeadline>
                        <DetailDescription>
                            <label>Description:</label>{basicAdDetails.content}
                        </DetailDescription>
                        <DestinationUrl>
                            <label>Destination URL:</label>{landingPageAnalysisState.adDetails.url}
                        </DestinationUrl>
                        <DateRange>
                            <label>Last seen</label>{convertTimestamp(basicAdDetails.lastSeen)}
                            {/* <label>Date Range:</label>27 Jan 2021 - 21 Nov 2022 */}
                        </DateRange>
                        <AvgPosition>
                            <label>Average position:</label>{basicAdDetails.position}
                        </AvgPosition>
                        <CountriesContainer>
                            {/* <label>Country:</label><CountriesList>{sampleCountryList.map((country,idx)=>{
                                return <span>{country}</span>
                            })}</CountriesList> */}
                            <label>Country:</label>{basicAdDetails.country}
                        </CountriesContainer>
                        <SearchTermsContainer>
                            <label>Bing search terms:</label><SearchTermsList>
                            {isDetailsLoading ? <Skeleton/> : landingPageAnalysisState.adDetails.keywords && landingPageAnalysisState.adDetails.keywords.map((searchTerm,idx)=>{
                                return <span>{searchTerm}</span>
                            })}
                            </SearchTermsList>
                        </SearchTermsContainer>
                        {/* <LandingPageScreenShot>
                            <label>Landing page screenshot</label>
                            <ScreenshotContainer>
                                <img src={ApplePage}/>
                            </ScreenshotContainer>
                        </LandingPageScreenShot> */}
                    </AdDetailsBody>
                </DetailsContainer>
                }
                </>}
            </ResultsContainer>
}

export default BingResults