import { Route, Routes } from "react-router-dom";
import { AdManagementNewProvider } from "./context/AdManagementContext";
import { ImageAdGeneration } from "./index.styled";
import Root from "./components";
import NotFound from "../../NotFoundPage";
import NewCampaign from "./NewCampaign";

function AdManagementComponent() {
  return (
    <AdManagementNewProvider>
      <ImageAdGeneration>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Root />} />
          <Route path="new-campaign" element={<NewCampaign />} />
        </Routes>
      </ImageAdGeneration>
    </AdManagementNewProvider>
  );
}

export default AdManagementComponent;
