import styled from "styled-components";

export const TopDomianWrapper = styled.div`
width: 475px;
height: 356px;
border-radius: 16px;
background: #FFF;
`

export const TableHeadWrapper = styled.div`
display: flex;
padding: 24px;
`
export const TableHead = styled.div`
color: #7D7D7D;
font-family: 'Inter';
font-size: 14px;
font-weight: 500;
display: flex;
align-items: center;
gap: 10px;
&.top{
    width: 77%;
}
`
export const DomainBodyWrap = styled.div`
padding: 0 24px;
height: 80%;
overflow-y: auto;
`
export const DomainBody = styled.div`
display: flex;
height: 43px;
align-items: center;
border-bottom: 1px solid #E3E8EE;
width: 100%;
`
export const DomainName = styled.div`
color: #1679DB;
font-family: 'Inter';
font-size: 14px;
font-weight: 400;
width: 77%;
`
export const DomainValueWrap = styled.div`
display: flex;
column-gap: 10px;
width: 23%
`
export const DomainValue = styled.div`
color: rgba(28, 28, 28, 0.70);
font-family: 'Inter';
font-size: 14px;
font-weight: 500;
width: 43%
`
export const DomainGrade = styled.div`
color: #25CD25;
font-family: 'Inter';
font-size: 14px;
font-weight: 400;

`
export const SkeletonWrap = styled.div`
display: flex;
justify-content: space-between;
width: 100%;
`