
import { Route, Routes } from "react-router-dom";
import Root from "./components";
import { TextAdGeneratorProvider } from "./context/textAdGeneration";
import { TextAdGeneration } from "./index.styled";


function TextAdGeneratorWrapper() {

    return ( 
        <TextAdGeneratorProvider>
            <TextAdGeneration>
                <Routes>
                    <Route path="/*" element={<Root />} />
                </Routes>
            </TextAdGeneration>
        </TextAdGeneratorProvider>
     );
}

export default TextAdGeneratorWrapper;