import uploadImg from "../../../../../media/Dashboard/send-square.svg";
import bookImg from "../../../../../media/Dashboard/book.svg";

const textGenStatic = {
  adGroupDetails: [
    {
      id: 1,
      title:
        "Young entrepreneurs aged 25-35 in major cities are driven, tech-savvy startup founders who constantly seek innovative marketing tools to grow their businesses. They focus on increasing brand awareness, acquiring new customers, and maximizing their marketing ROI while overcoming challenges like limited budgets and high competition.",
      url: "https://couponswala.com/",
      headlineText: [
        "Top Deals & Coupons1",
        "Top Deals & Coupons2",
        "Top Deals & Coupons3",
        "Top Deals & Coupons4",
        "Top Deals & Coupons5",
        "Top Deals & Coupons6",
      ],
      descriptionList: [
        "Verified coupons for top brands! Save up to 70% & find deals on everything",
        "Exclusive discounts you wont find elsewhere. Shop smarter & save more!",
        "Exclusive discounts you wont find elsewhere. Shop smarter & save more!",
      ],
      keywordList: ["screen recorder", "screen recorder"],
    },
    {
      id: 2,
      title:
        "Young entrepreneurs aged 25-35 tools to grow their businesses. They focus on increasing brand awareness, acquiring new customers, and maximizing their marketing ROI while overcoming challenges like limited budgets and high competition.",
      url: "https://couponswala.com/",
      headlineText: [],
      descriptionList: [
        "Verified coupons for top brands! Save up to 70% & find deals on everything",
        "Exclusive discounts you wont find elsewhere. Shop smarter & save more!",
        "Exclusive discounts you wont find elsewhere. Shop smarter & save more!",
      ],
      keywordList: ["screen recorder", "screen recorder"],
    },
    {
      id: 3,
      title:
        "Young  cities are driven, tech-savvy startup founders who constantly seek innovative marketing tools to grow their businesses. They focus on increasing brand awareness, acquiring new customers, and maximizing their marketing ROI while overcoming challenges like limited budgets and high competition.",
      url: "https://couponswala.com/",
      headlineText: ["Top Deals & Coupons", "Top Deals & Coupons"],
      descriptionList: [
        "Verified coupons for top brands! Save up to 70% & find deals on everything",
        "Exclusive discounts you wont find elsewhere. Shop smarter & save more!",
        "Exclusive discounts you wont find elsewhere. Shop smarter & save more!",
      ],
      keywordList: ["screen recorder", "screen recorder"],
    },
    {
      id: 4,
      title:
        "Young entrepreneurs aged 25-35 in major cities are driven,  seek innovative marketing tools to grow their businesses. They focus on increasing brand awareness, acquiring new customers, and maximizing their marketing ROI while overcoming challenges like limited budgets and high competition.",
      url: "https://couponswala.com/",
      headlineText: [
        "Top Deals & Coupons",
        "Top Deals & Coupons",
        "Top Deals & Coupons",
        "Top Deals & Coupons",
      ],
      descriptionList: [
        "Verified coupons for top brands! Save up to 70% & find deals on everything",
        "Exclusive discounts you wont find elsewhere. Shop smarter & save more!",
        "Exclusive discounts you wont find elsewhere. Shop smarter & save more!",
      ],
      keywordList: ["screen recorder", "screen recorder"],
    },
    {
      id: 5,
      title:
        "Young entrepreneurs aged ve marketing tools to grow their businesses. They focus on increasing brand awareness, acquiring new customers, and maximizing their marketing ROI while overcoming challenges like limited budgets and high competition.",
      url: "https://couponswala.com/",
      headlineText: [
        "Top Deals & Coupons",
        "Top Deals & Coupons",
        "Top Deals & Coupons",
      ],
      descriptionList: [
        "Verified coupons for top brands! Save up to 70% & find deals on everything",
        "Exclusive discounts you wont find elsewhere. Shop smarter & save more!",
        "Exclusive discounts you wont find elsewhere. Shop smarter & save more!",
      ],
      keywordList: ["screen recorder", "screen recorder"],
    },
  ],
  publishAdGroup: [
    { title: "Preview", img: uploadImg },
    { title: "Details", img: bookImg },
  ],
  detailsAdGroup: [
    { title: "Brand details" },
    { title: "Target audience" },
    { title: "Seed keywords" },
  ],
};
export default textGenStatic;
