import InputPages from "./InputPages";
import { useTextAdGenerator } from "../context/textAdGeneration";
import GeneratingPage from "./GeneratingPage";
import ResultPage from "./ResultPage";
import { Route, Routes} from 'react-router-dom'

function Root() {
    const { textAdGeneratorState, textAdGeneratorDispatch } = useTextAdGenerator();


    const renderingComponent = () => {
        const { no } = textAdGeneratorState.currentPage

        if(textAdGeneratorState.isResultGenerated) return <ResultPage />
        if(no <= 5) return <InputPages />
        if(no === 6) return <GeneratingPage />
    }

    return (
        <>
            <Routes>
                <Route path="/" element={renderingComponent()}/>
                <Route path="result" element={<ResultPage />} />
            </Routes>
        </>
     );
}

export default Root;