import React, { useRef, useState } from "react";
import CloseIcon from "../../../../../../../media/Dashboard/detailsPaneClose.svg"
import { PopupContainer, PopupBody, LogoutButton, DomainsListContainer, DomainInputContainer, Error, Footer, SetupTrackingButton, ErrorBox, DomainInput, ErrorIcon, HttpsLabel, InputContainer, PlaceholderWithInput, RightIcons } from "./index.styled"
import { useNavigate } from "react-router-dom";
import { useDomainOverview } from '../../../../context/domainoverview'
import PlatformDropDown from "./components/PlatformDropDown"
import CountryDropDown from "./components/CountryDropDown";

import ValidateAndAddHttps from "../../../../../../../utils/urlValidate"
import { useDomainOverviewInfo } from "../../../../hooks/useDomainOverviewInfo";
import errorIcon from "../../../../assets/errorIcon.svg"

function isValidDomain(url) {
    const domainAndUrlRegEx = /^(((http|https):\/\/|)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?)$/;

    return domainAndUrlRegEx.test(url);
}

const DomainInputModal = () => {
    const {domainOverviewState, domainOverviewDispatch} = useDomainOverview()

    const useDomainOverviewInfoHook = useDomainOverviewInfo()
    const navigate = useNavigate()
    const [focussedInputIndex, setFocussedInputIndex] = useState(null)
    const [error, setError] = useState(false)
    const domainsDiv = useRef(null);
    const updateDomainCountry = (updatingIndex,inputCountryData) => {
        const tempDomainList = domainOverviewState.domainInputList.map((data,index) => {
            // console.log(index,'check1',domain)
            if (updatingIndex == index) {
              return { ...data, country: inputCountryData }
            }
            return data;
          });

        //   console.log("updatedomainCountry",tempdomainList);
          domainOverviewDispatch({ type: 'SET_DOMAIN_INPUT_LIST', value: tempDomainList})
    }

    const updateDomainPlatform = (domain,inputPlatformData) => {
        // console.log('inputCountryData')

        const tempDomainList = domainOverviewState.domainInputList.map((data,index) => {
            if (domain == index) {
              // Update the region property for the specific country
              return { ...data, platform: inputPlatformData }
            }
            return data;
          });

        //   console.log("updatedomainPlatform",tempdomainList)

          domainOverviewDispatch({ type: 'SET_DOMAIN_INPUT_LIST', value: tempDomainList}) 
    }

    const addNewDomain = () => {
        if(domainOverviewState.domainInputList.length < 4){
            domainOverviewDispatch({type: 'SET_DOMAIN_INPUT_LIST_ERROR', value: ''})
            domainOverviewDispatch({type: 'SET_DOMAIN_INPUT_LIST', value: [...domainOverviewState.domainInputList, { domain: '', platform: '', country: {}, platformError: '' }]})
        }else{
            domainOverviewDispatch({type: 'SET_DOMAIN_INPUT_LIST_ERROR', value: 'maximum 4 domains at a time'})
        }
    }

    const onDomainInput = (e,index) => {
        let { value } = e.target
        if(!value.startsWith("https://")) value = `https://${value}`

        const tempDomainInputList = domainOverviewState.domainInputList.map((d,id) => {
            if (id === index) {
                // Update the region property for the specific country
                return { ...d, domain: e.target.value };
            }
            return d;
        });

        domainOverviewDispatch({
            type: 'SET_DOMAIN_INPUT_ERROR_OBJ', value: {
                ...domainOverviewState.domainInputErrorsObj, [index]: {
                    status: !isValidDomain(value),
                    message: !isValidDomain(value) ? 'Enter a valid domain' : ''
                }
            }
        })
        domainOverviewDispatch({type: 'SET_DOMAIN_INPUT_LIST', value: tempDomainInputList})
    }

    const setUpTrackingfn = ()=>{
        if (domainOverviewState.domainInputList) {
            const setUpObj = {};
            setUpObj.urls=[];
            console.log(domainOverviewState.domainInputList,'domainInputList')
            domainOverviewState.domainInputList.map((item,idx) => {
                if (item.domain !== '') {
                    if(ValidateAndAddHttps(item.domain)){
                        domainOverviewDispatch({type: 'SET_DOMAIN_INPUT_ERROR_OBJ', value: {...domainOverviewState.domainInputErrorsObj, [idx]: {status:    false,
                            message: ''}}})
                        if( Object.keys(item.country).length !== 0  && item.platform !== ''){
                            // console.log(item, 'itemitem');
                            setUpObj.urls.push({ domain: item.domain,
                                platform: item.platform,
                                country: item?.country?.code ? item.country.name.common : 'global'})
                                domainOverviewDispatch({ type: 'SET_DOMAIN_INPUT_LIST_ERROR', value: '' });
                            }else{
                            // console.log('section checking');
                                domainOverviewDispatch({ type: 'SET_DOMAIN_INPUT_LIST_ERROR', value: 'Please Fill the Empty Section' });
                            }
                    }else{
                        domainOverviewDispatch({type: 'SET_DOMAIN_INPUT_ERROR_OBJ', value: {...domainOverviewState.domainInputErrorsObj, [idx]: {
                            status: true,
                            message: 'Invalid url'
                        }}})
                    }
                }else {
                    domainOverviewDispatch({ type: 'SET_DOMAIN_INPUT_LIST_ERROR', value: 'Please Enter the domain' });
                }              
            });
            setUpObj.urls.length > 0 && useDomainOverviewInfoHook.setTrackingMutation(setUpObj)

        }
        
    }

    const eventEnterHandle = (e) => {
        if (e.key === 'Enter') {
            setUpTrackingfn()
        }
    }

    return <PopupContainer>
        <PopupBody>
        {/* temp change  */}
            <LogoutButton onClick={()=>domainOverviewDispatch({type: 'SET_DOMAIN_INPUT_MODAL_OPEN', value: false})}>
                <img src={CloseIcon}/>
            </LogoutButton>
            <h2>Setup Domain Tracking</h2>
            <DomainsListContainer ref={domainsDiv}>
                {
                    domainOverviewState.domainInputList.map((domain, idx)=> {
                        return (
                            <div>
                                <DomainInputContainer error={domainOverviewState.domainInputErrorsObj[idx]?.status ? true : false}>
                                    <PlaceholderWithInput>
                                        <HttpsLabel>https://</HttpsLabel>
                                        <InputContainer isFocussed={focussedInputIndex === idx} hasError={error} >
                                            <DomainInput
                                                placeholder="Enter a domain"
                                                defaultValue={domain.domain &&  domain.domain.startsWith("https://") ? domain.domain.split("https://")[1] : domain.domain}
                                                onChange={(e) => onDomainInput(e, idx)}
                                                onKeyDown={(e) => {
                                                    eventEnterHandle(e)
                                                }}
                                                onFocus={() => setFocussedInputIndex(idx)}
                                                onBlur={() => setFocussedInputIndex(null)}
                                            />
                                            <RightIcons>
                                                {domainOverviewState.domainInputErrorsObj[idx]?.status && <ErrorIcon src={errorIcon} alt=""/>}
                                            </RightIcons>
                                        </InputContainer>
                                    </PlaceholderWithInput>
                                    <PlatformDropDown domainData={domain} updateDomainPlatform={updateDomainPlatform} countryIndex={idx} />
                                    <CountryDropDown domainData={domain} updateDomainCountry={updateDomainCountry} countryIndex={idx} />
                                </DomainInputContainer>
                                {domainOverviewState.domainInputErrorsObj[idx]?.status && <ErrorBox>Enter a valid domain</ErrorBox>}
                            </div>
                        )
                    })
                }
            </DomainsListContainer>
            <Error>{domainOverviewState.domainInputListError ? domainOverviewState.domainInputListError : ""}</Error>
            <Footer>
                {/* <AddMoreDomainButton isDisabled={domainOverviewState.keywordInputListError} onClick={addNewDomain}>
                    + Add more domain
                </AddMoreDomainButton> */}
                <SetupTrackingButton onClick={setUpTrackingfn}>
                    Setup Tracking
                </SetupTrackingButton>
            </Footer>
        </PopupBody>
    </PopupContainer>
}

export default DomainInputModal
