import styled from "styled-components";

export const Container = styled.div`
    /* max-width: 990px; */
    max-width: 1500px;
`;

export const Header = styled.div`
    h1{
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        color: #2E2E2E;
    }
`;

export const SubNavContainer = styled.div`
    border-bottom: 2px solid #ECECEC;
    display: flex;
    padding: 16px 0px 0px;
    gap: 24px;
    margin-bottom: 20px;
    width: 600px;
    width: 100%;
`;

export const RightNavItem = styled.div`
    font-size: 14px;
    color: ${props => props.isDisabled ? "#2e2e2e85" : "#2E2E2E"};
    font-weight: 500;
    cursor: pointer;
    border-bottom: ${props => props.isActive ? "solid 3px #333" : "none"};
    pointer-events: ${props => props.isDisabled ? "none": "auto"};
    padding-bottom: 12px;
`;

export const SearchContainer = styled.div`
    border-radius: 8px;
    border: 1px solid #ECECEC;
    background: #FFF;
    display: flex;
    /* margin-left: 20px; */
    width: fit-content;
`;

export const SearchWrapper = styled.div`
    display: flex;
    margin-bottom: 16px;
    align-items: center;
`;

export const Input = styled.input`
    border: none;
    outline: none;
    /* margin: 6px 12px; */
    /* color: #B1B1B1; */
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    margin: 0;
    padding: 6px 12px;
    border-radius: 8px 0px 0px 8px;
    border-right: 1px solid #ECECEC;
    background: #FFF;
`;

export const SearchIconContainer = styled.div`
    border-radius: 8px;
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const FilterContainer = styled.div`
    margin-left: 16px;
    h3{
        font-size: 14px;
        font-weight: 300;
        line-height: normal;
        color: #8B8B8B;
    }
`;

export const AdsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    max-height: 85vh;
    overflow-y: auto;
    position: relative;
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }

`;

export const GeneratedAdCard = styled.div`
    background: rgb(255, 255, 255);
    border: 1px solid rgb(242, 242, 242);
    border-radius: 12px;
    width: 47%;
    height: max-content;
`;


export const GeneratedAdTitle = styled.p`
    padding: 10px 13px;
    font-size: 18px;
    color: rgb(26, 13, 171);
    line-height: 27px;
    font-weight: 400;
    display: flex;
    align-items: center;
`;


export const GeneratedAdDescription = styled.p`
    padding: 0px 13px 13px;
    font-size: 14px;
    color: rgb(46, 46, 46);
    line-height: 22px;
    font-weight: 300;
    /* display: flex;
    align-items: center; */
`;

export const TitleCopyImg = styled.img`
    height: 15px;
    margin: 3px 6px;
    cursor: pointer;
`;

export const DescCopyImg = styled.img`
    height: 15px;
    margin: 3px 6px -2px;
    cursor: pointer;
`;

export const IndividualWrapper = styled.div`
padding 0 50px;
`;
