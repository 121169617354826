import React, { useRef, useEffect, useState } from 'react'
import {
    useMutation
} from "@tanstack/react-query"
import NoResultsLogo from "../../../../../../../media/Dashboard/noresults.svg"
import { useRequestData } from "../../../../../../hooks/useRequestData"

// Styles
import {
    CountriesList,
    CountriesContainer,
    DateRange,
    AdDetailsBody,
    CloseDetails,
    DetailsHeader,
    DetailsContainer,
    CardBody,
    Card,
    CardsList,
    ResultsContainer,
    ResultsWraper,
    VideoContainer,
    ImageContainer,
    BusinessName,
    ChannelDetails,
    DetailsChannelDetails,
    LogoContainer,
    ChannelName,
    Description,
    SecondaryDetails,
    DetailsVideoContainer,
    DetailsImageContainer,
    DetailsDescription,
    Headline,
    DestinationUrl,
    ShortUrl,
    InnerContent,
    InnerTitle,
    CallToActionButton,
    CallToAction,
    MediaType,
    NoResults,
    RequestDataBtn,
    CopyImg,
    CTAbutton,
    CardContainer,
    HoverCardWrapper,
    HoverCard,
    AdDetailsButton,
    SaveAdsButton
} from "./index.styled"
import SearchLoader from '../../../../../../HOC/SearchLoader'

import { useLinkedin } from "../../context/linkedin"


//Icons
import RightUpperBlackArrow from "../../../../../../../media/Dashboard/RightUpperBlackArrow.svg"

import CloseBtn from "../../../../../../../media/Dashboard/detailsPaneClose.svg"
import TickImg from '../../../../../../../media/Dashboard/tick-grey.svg'
import CopyBtn from '../../../../../../../media/Dashboard/copy.png'


import AltImage from '../../../../../../../media/Dashboard/altimage.png'
import LimitExceededPopup from '../../../../../../components/limitExceededPopup'
import { appClient } from '../../../../../../../utils/axios-utils'

import PlatformRestrictedPopup from '../../../../../../components/platformRestrictionPopup'
import { useAuth } from '../../../../../../../context/auth'
import { useSearch } from '../../../context/search'
import SaveAdsPopup from '../../../../../../components/SaveAdsPopup'
import AdDetails from '../../../../../../components/AdDetailsModal'
import { useSaveAds } from '../../../../../../hooks/useSaveAds'

const adClassification = async (id) => {
    const config = {
        url: `/detail/linkedin/classification/${id}`,
        method: "GET"
    }

    return appClient(config)
}

const convertTimestamp = (timestamp) => {
    if (!timestamp) {
        return "-"
    }
    // Create a new Date object based on the timestamp
    var date = new Date(Number(timestamp));

    // Get the month name
    var monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    var month = monthNames[date.getMonth()];

    // Get the year
    var year = date.getFullYear();

    // Assemble the formatted date string
    var formattedDate = date.getDate() + ' ' + month + ' ' + year;

    return formattedDate;
}

const CardElement = ({ ad, idx, containerRef }) => {

    const { authState, authDispatch } = useAuth()
    const useSaveAdsHook = useSaveAds()
    const { linkedinState, linkedinDispatch } = useLinkedin()

    const scrollToElement = (index) => {
        const element = containerRef.current.children[index];
        const scrollPosition = element.offsetTop - 20;
        containerRef.current.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    };

    const toggleDetailsModal = (ad, idx) => {
        if ((linkedinState.adDetails.id !== ad.id) && linkedinState.detailsModal) {
            //if details is open and clicked on close button on ads card
            linkedinDispatch({ type: 'SET_AD_DETAILS', value: ad })
            // linkedinDispatch({ type: 'UPDATE_DETAILS_MODAL', value: true })
        } else {
            linkedinDispatch({ type: 'SET_AD_DETAILS', value: ad })
            linkedinDispatch({ type: 'UPDATE_DETAILS_MODAL', value: !linkedinState.detailsModal })
        }
        setTimeout(() => {
            scrollToElement(idx)
        }, 100);
    }

    const onSave = (ad) => {
        useSaveAdsHook.SaveAdMutate({
            projectId: authState.defaultProjectInfo.projectId,
            adId: `${ad.id}`,
            platform: "linkedin"
        })
        // authDispatch({ type: 'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'bing' })
        // authDispatch({ type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id })
        // authDispatch({ type: 'UPDATE_SAVE_AD_POPUP', value: true })
    }

    const onRemoveSavedAd = (ad) => {
        useSaveAdsHook.RemoveSavedAdMutate({
            projectId: authState.defaultProjectInfo.projectId,
            adId:  `${ad.id}`,
            platform: "linkedin"
        })
    }

    const onDetailsClose = () => {
        linkedinDispatch({ type: 'SET_AD_DETAILS', value: {} })
        linkedinDispatch({ type: 'UPDATE_DETAILS_MODAL', value: false })
    }

    return <Card detailsOpen={linkedinState.adDetails.id == ad.id} key={
        ad.id
    }>
        <CardContainer>
            <HoverCard className='hover-element' onClick={() => toggleDetailsModal(ad, idx)
            }>
                <HoverCardWrapper>
                    <AdDetailsButton onClick={() => toggleDetailsModal(ad, idx)}>
                        <img src={RightUpperBlackArrow} />
                        <span>Ad Details</span>
                    </AdDetailsButton>
                    <SaveAdsButton isSaved={authState.savedAdsIds.includes(ad.id)} onClick={(e) => {
                        e.stopPropagation();
                        if (authState.savedAdsIds.includes(ad.id)) {
                            onRemoveSavedAd(ad)
                        } else {
                            onSave(ad);
                        }

                    }}>
                        {authState.savedAdsIds.includes(ad.id) ? "Saved" : "Save"}
                    </SaveAdsButton>
                </HoverCardWrapper>
            </HoverCard>
            <CardBody>
                <ChannelDetails>
                    <LogoContainer>
                        <img src={`https://aws-adspyder-logos.s3.ap-south-1.amazonaws.com/${ad.id}`} />
                    </LogoContainer>
                    <ChannelName>
                        {ad.title}
                        <span>Promoted</span>
                    </ChannelName>
                </ChannelDetails>
                <Description>
                    {ad.content}
                </Description>
                {/* <FloatingButtonsContainer>
                <Favorite isFav={idx == 1} onClick={()=>{
                    authDispatch({type:'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'linkedin'})
                    authDispatch({type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id})
                    authDispatch({type:'UPDATE_SAVE_AD_POPUP', value: true})}}>
                   <img src={authState.favAdsList["linkedin"]?.includes(ad.id) ? FavouriteIconActive : FavorietIcon}/>
                </Favorite> */}
                {/* |
                <DropdownIcon>
                    <span/>
                </DropdownIcon> */}
                {/* </FloatingButtonsContainer> */}
                {ad.image && <ImageContainer>
                    <img src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${ad.id}`} />
                </ImageContainer>}
                {ad.video && <VideoContainer>
                    <video src={`https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${ad.id}`} preload="auto" controls loop style={{ width: "100%", height: "100%" }} />
                </VideoContainer>}
                {(!ad.video && !ad.image) && <ImageContainer>
                    <img src={AltImage} />
                </ImageContainer>}
                {(ad.ctaContent || ad.ctaButton) && <SecondaryDetails>
                    <InnerContent>
                        {/* <InnerUrl>
                        {ad.ctaUrl}
                    </InnerUrl> */}
                        {/* <InnerDesc>
                        {ad.ctaTitle}
                    </InnerDesc> */}
                        <InnerTitle>
                            {ad.ctaContent}
                        </InnerTitle>
                    </InnerContent>
                    <CallToActionButton>
                        {ad.ctaButton && <CTAbutton>
                            {ad.ctaButton}
                        </CTAbutton>
                        }
                    </CallToActionButton>
                </SecondaryDetails>}
                {/* <SecondaryDetails>
                <InnerContent>
                    <InnerDesc>
                        {ad.ctaContent}
                    </InnerDesc>
                    <InnerTitle>
                        {ad.ctaTitle}
                    </InnerTitle>
                </InnerContent>
                <CallToActionButton>
                    <CTA>
                        <LeftCTA>
                            <h3>{ad.ctaContent}</h3>
                            <p>{ad.ctaTitle}</p>
                        </LeftCTA>
                        {ad.ctaButton && <CTAbutton>
                            {ad.ctaButton}
                        </CTAbutton>}
                    </CTA>
                </CallToActionButton>
            </SecondaryDetails> */}
            </CardBody>
        </CardContainer>
        {/* <CardFooter>
            <FooterLeft>
                <AdDate>
                {ad.firstSeen && ad.lastSeen && <img src={DateIcon}/>}{convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
                </AdDate> 
            <AdCountries>
                                                {ad.country.length > 0 && <img src={CountryIcon} />}
                    {ad.country} */}
        {/* { ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""} */}
        {/* </AdCountries>
            </FooterLeft>
            <FooterRight>
                <DetailsButton onClick={()=>toggleDetailsModal(ad, idx)}>
                {(linkedinState.adDetails.id == ad.id) && linkedinState.detailsModal ? "Close details" : "More details"}
                </DetailsButton>
            </FooterRight>
        </CardFooter> */}
        {
            linkedinState.detailsModal &&
            <AdDetails
                // navigateTo={navigateTo}
                platform="linkedin"
                basicDetail={linkedinState.adDetails}
                onDetailsClose={onDetailsClose}
                highlightWords={linkedinState.highlightWords}
            onSave={onSave}
            />
        }
    </Card>
}

const DetailsElement = () => {
    const { linkedinState, linkedinDispatch } = useLinkedin()
    const [showTick, setShowTick] = useState(false)
    const [copiedItem, setCopiedItem] = useState('')

    const [height, setHeight] = useState('')
    const [width, setWidth] = useState('')

    const onCopyClick = (value) => {
        navigator.clipboard.writeText(value);
        setCopiedItem(value)
        setShowTick(true)
        setTimeout(() => {
            setShowTick(false)
        }, 1500);
    }

    const { mutate: adAiClassificationMutate, isLoading: isAiClassificationLoading } = useMutation(adClassification, {
        onSuccess: (data) => {
            if (data.processed || data.id) {
                linkedinDispatch({ type: 'SET_AD_AI_CLASSIFICATION', value: data })
                linkedinDispatch({ type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: false })
            } else {
                setTimeout(() => {
                    adAiClassificationMutate(linkedinState.adDetails.id)
                }, 5000);
            }
        },
        onError: ((e) => {
            linkedinDispatch({ type: 'SET_AD_AI_CLASSIFICATION', value: {} })
            linkedinDispatch({ type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: false })
        })
    })

    useEffect(() => {
        // linkedinDispatch({type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: true})
        // adAiClassificationMutate(linkedinState.adDetails.id)
    }, [])

    return <DetailsContainer>
        <DetailsHeader>
            <h3>Ad details</h3> <CloseDetails onClick={() => {
                linkedinDispatch({ type: 'UPDATE_DETAILS_MODAL', value: false })
                linkedinDispatch({ type: 'SET_AD_DETAILS', value: {} })
            }}><img src={CloseBtn} /></CloseDetails>
        </DetailsHeader>
        <AdDetailsBody>
            <DetailsChannelDetails>
                <LogoContainer>
                    <img src={`https://aws-adspyder-logos.s3.ap-south-1.amazonaws.com/${linkedinState.adDetails.id}`} />
                </LogoContainer>
                <ChannelName>
                    {linkedinState.adDetails.title}
                    <span>Promoted</span>
                </ChannelName>
            </DetailsChannelDetails>
            {linkedinState.adDetails.image && <DetailsImageContainer>
                <img onLoad={(e) => {
                    // console.log(e)
                    setHeight(e.target.naturalHeight)
                    setWidth(e.target.naturalWidth)
                }} src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${linkedinState.adDetails.id}`} />
            </DetailsImageContainer>}
            {linkedinState.adDetails.video && <DetailsVideoContainer>
                <video src={`https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${linkedinState.adDetails.id}`} preload="auto" controls loop style={{ width: "100%", height: "100%" }} />
            </DetailsVideoContainer>}
            <BusinessName>
                <label>Business name:</label>{linkedinState.adDetails.title}{linkedinState.adDetails.title && <CopyImg onClick={() => onCopyClick(linkedinState.adDetails.title)} src={showTick && linkedinState.adDetails.title == copiedItem ? TickImg : CopyBtn} />}
            </BusinessName>
            <DetailsDescription>
                <label>Introductory text:</label>{linkedinState.adDetails.content} {linkedinState.adDetails.content && <CopyImg onClick={() => onCopyClick(linkedinState.adDetails.content)} src={showTick && linkedinState.adDetails.content == copiedItem ? TickImg : CopyBtn} />}
            </DetailsDescription>
            <Headline>
                <label>Headline:</label>{linkedinState.adDetails.ctaContent ? linkedinState.adDetails.ctaContent : "-"}{linkedinState.adDetails.ctaContent && <CopyImg onClick={() => onCopyClick(linkedinState.adDetails.ctaContent)} src={showTick && linkedinState.adDetails.ctaContent == copiedItem ? TickImg : CopyBtn} />}
            </Headline>
            <DestinationUrl>
                <label>Destination URL:</label><a href={linkedinState.adDetails.url}><span>{linkedinState.adDetails.url}</span></a>
            </DestinationUrl>
            <ShortUrl>
                <label>Short URL:</label>{linkedinState.adDetails.ctaTitle ? <a href={linkedinState.adDetails.ctaTitle}>{linkedinState.adDetails.ctaTitle}</a> : "-"}
            </ShortUrl>
            <CallToAction>
                <label>Call to action:</label>{linkedinState.adDetails.ctaButton ? linkedinState.adDetails.ctaButton : "-"}
            </CallToAction>
            <MediaType>
                <label>Media type:</label>{linkedinState.adDetails.video ? "Video" : "Image"}
            </MediaType>
            {linkedinState.adDetails.image && <MediaType>
                <label>Media size:</label>{width + " X " + height}
            </MediaType>}
            <DateRange>
                <label>Date Range:</label>{convertTimestamp(linkedinState.adDetails.firstSeen)} - {convertTimestamp(linkedinState.adDetails.lastSeen)}
            </DateRange>
            {/* <FunnelStage>
            <label>Funnel Stage:</label>{linkedinState.adAiClassificationLoading ? <Skeleton style={{
                lineHeight: "32px",
                width: 150,
            }}/> : <p>{linkedinState.adAiClassification.funnelType}</p>}
        </FunnelStage>
        <CtaList>
            <label>CTAs:</label>
            <CtaContainer>
                {
                    linkedinState.adAiClassificationLoading ?  
                    <Skeleton style={{
                        lineHeight: "32px", 
                        width: 100,
                    }}/>  :
                    linkedinState.adAiClassification.ctaText ? <Cta>
                        {linkedinState.adAiClassification.ctaText}
                    </Cta> : "-"
                }
            </CtaContainer>
        </CtaList>
        <TargetAudience>
            <label>Target Audience</label>
            <AudienceContainer>
                <DemographicAudience>
                    <h3>Demographic</h3>
                    <DemographicContainer>
                    {
                        linkedinState.adAiClassificationLoading ?  [...Array(1)].map((d)=>{
                        return <Skeleton style={{
                            lineHeight: "32px", 
                            width: 100,
                        }}/> 
                        }) : linkedinState.adAiClassification.demographicAudience.length > 0 ? 
                        linkedinState.adAiClassification.demographicAudience.map(function(key,index) {
                            return <Cta>
                                {key}
                            </Cta>
                        }) : "-"
                    }
                    </DemographicContainer>
                </DemographicAudience>
                <PsychographicAudience>
                    <h3>Psychographic</h3>
                    <PsychographicContainer>
                    {
                        linkedinState.adAiClassificationLoading ?  [...Array(1)].map((d)=>{
                        return <Skeleton style={{
                            lineHeight: "32px", 
                            width: 100,
                        }}/> 
                        }) :  linkedinState.adAiClassification?.psychographicAudience?.length > 0 ? 
                        linkedinState.adAiClassification.psychographicAudience.map(function(key,index) {
                            return <Cta>
                                {key}
                            </Cta>
                        }) : "-"
                    }
                    </PsychographicContainer>
                </PsychographicAudience>
            </AudienceContainer>
        </TargetAudience> */}
            {linkedinState.adDetails.country && <CountriesContainer>
                <label>Country:</label><CountriesList>
                    {/* {
                    linkedinState.adDetails.country.map((country,idx) => {
                        return <span>{country}</span>
                    })
                } */}
                    {linkedinState.adDetails.country}
                </CountriesList>
            </CountriesContainer>}
        </AdDetailsBody>
    </DetailsContainer>
}

const Results = () => {
    const { linkedinState, linkedinDispatch } = useLinkedin()
    const [basicAdDetails, setBasicAdDetails] = useState({})
    const useRequestDataHook = useRequestData()

    const { searchState, searchDispatch } = useSearch()
    const { authState, authDispatch } = useAuth()

    const containerRef = useRef(null);

    const onDataRequest = () => {
        if (!useRequestDataHook.isDataRequested) {
            // useRequestDataHook.requestDataMutate({id: linkedinState.queryId})
            useRequestDataHook.requestRealtimeDataForLinkedinMutate({ id: linkedinState.queryId })
        }
    }

    if (authState.isRestrictedPopup) {
        return <ResultsContainer restricted={true} detailsModal={linkedinState.detailsModal}>
            <PlatformRestrictedPopup err={searchState.searchError} />
        </ResultsContainer>
    }

    if (searchState.limitExceeded) {
        return <ResultsContainer restricted={true} detailsModal={linkedinState.detailsModal}>
            <LimitExceededPopup err={searchState.searchError} />
        </ResultsContainer>
    }


    return <ResultsContainer resultLoading={linkedinState.loadingResults} noResults={linkedinState.noResults} detailsModal={linkedinState.detailsModal}>
        {linkedinState.loadingResults ? <div className='loader'>
            <SearchLoader />
        </div> : linkedinState.noResults ? <NoResults>
            <img src={NoResultsLogo} />
            <h2>No Results Found</h2>
            <p>We're sorry, but we don't currently have the data matching your search parameters. Our database is constantly updated, so please try again later or try modifying your search.</p>
            <RequestDataBtn 
                isDisabled={useRequestDataHook.isRequestRealtimetDataForLinkedinLoading || authState.isDataRequested}
                onClick={onDataRequest}
            >
                {authState.isDataRequested ? <p>Your request for data crawl has been accepted<img src={TickImg} /></p> : <p>{useRequestDataHook.isRequestRealtimetDataForLinkedinLoading ? "Requesting..." : "Request data crawl for this query"}</p>}
            </RequestDataBtn>
        </NoResults> :
            <ResultsWraper>
                {authState.saveAdsPopupOpen && <SaveAdsPopup />}
                <CardsList ref={containerRef} detailsModal={linkedinState.detailsModal}> {
                    linkedinState.ads && linkedinState.ads.length > 0 && linkedinState.ads.map((ad, idx) => {
                        return <CardElement containerRef={containerRef} ad={ad} idx={idx} />
                    })
                } </CardsList>
                {
                    // linkedinState.detailsModal && <DetailsElement/>
                }
            </ResultsWraper>}
    </ResultsContainer>
}

export default Results
