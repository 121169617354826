import React, { useState, useEffect, useRef } from 'react'
import { Container, Down, Row1, Row, Drop } from './index.styled'

import DropDownArrow from '../../../../../../../media/Dashboard/drop-down-arrow.svg'

import { useAdGenrator } from "../../../../context/adgenerator";

function useOutsideAlerter(ref, setShow) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setShow(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref])
}

const ToneList = [
    {
        name: "Casual",
    },{
        name: "Friendly",
    }
]

const ToneDropDown = () => {
    const {adGenratorState, adGenratorDispatch} = useAdGenrator()

    const wrapperRef = useRef(null)
    const [show, setShow] = useState(false)

    useOutsideAlerter(wrapperRef, setShow)

    return <Container ref={wrapperRef}>

        <Drop onClick={() => { setShow(prev => !prev) }}>
            <span>{adGenratorState.selectedTone ? adGenratorState.selectedTone : "Tone"}</span>
            <img src={DropDownArrow} />
        </Drop>

        <Down show={show}>
            {/* <Row1>
                <p>Description Framework</p>
            </Row1> */}

            {ToneList.map((platform, index) => {
                    return <Row key={index}
                        onClick={() => {
                            // console.log(platform)
                            adGenratorDispatch({type:'SET_SELECTED_TONE', value: platform.name.toLocaleLowerCase()})
                            setShow(false)
                        }}>
                            <p>{platform.name}</p>
                        </Row>
                })}
            </Down>
        </Container>
    }

export default ToneDropDown