import React, { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import {
  SaveAdsButton,
  DetailContainer,
  LevelOneDetailContainer,
  AdPreviewContainer,
  AdContentContainer,
  TitleContainer,
  TitleHeader,
  DescriptionContainer,
  DescriptionHeader,
  LevelTwoDetailsContainer,
  CopyButton,
  AdPositionContainer,
  AdPositionHeader,
  AvgPosition,
  LevelTwoRow,
  AmountSpentContainer,
  OtherDetailsContainer,
  AmountSpentHeader,
  CountryConainer,
  LastSeenContainer,
  DestinationUrlContainer,
  CountryHeader,
  LastSeenHeader,
  DestinationUrlHeader,
  MapsList,
  FlagLogo,
  ImageContainer,
} from "./index.styled";
import CopyImage from "../../../../../media/Dashboard/CopyImg.svg";
import RedirectOrangeIcon from "../../../../../media/Dashboard/RedirectOrangeIcon.svg";
import AltImage from '../../../../../media/Dashboard/altimage.png'

import { countryList } from "../../../../../utils/maps";
import { appClient } from "../../../../../utils/axios-utils";

import { CircleFlag } from "react-circle-flags";
import { useAuth } from "../../../../../context/auth";
import { useSaveAds } from "../../../../hooks/useSaveAds";
import copiedImage from "../../../../../media/Dashboard/copiedImage.svg";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";

const getAdDetails = async (id) => {
  const config = {
    url: `/detail/google/${id}`,
    method: "GET",
  };

  return appClient(config);
};

const adAmountSpent = async (id) => {
  const config = {
    url: `/detail/google/estimated-budget/${id}`,
    method: "GET",
  };

  return appClient(config);
};

const DisplayDetail = ({ basicDetail, highlightWords, onSave }) => {
  const navigate = useNavigate()

  const [adDetails, setAdDetails] = useState(basicDetail);
  const [detailsLoading, setDetailsLoading] = useState(true);
  const [adAmount, setAdAmount] = useState({})
  const [copyState, setCopyState] = useState(false);
  const [copyContent, setCopyContent] = useState(false);
  const [copyURL, setCopyURL] = useState(false);

  const { authState, authDispatch } = useAuth()
  const useSaveAdsHook = useSaveAds()

  const getFlagEmoji = (countryName) => {
    let countryCode = countryList[countryName];
    if (countryCode) {
      return (
        <FlagLogo>
          <CircleFlag countryCode={countryCode.toLowerCase()} height="24" />
        </FlagLogo>
      );
    }
  };

  const { mutate: adsDetailsMutate, isLoading: isDetailsLoading } = useMutation(
    getAdDetails,
    {
      onSuccess: (data) => {
        setAdDetails(data);
        setDetailsLoading(false);
      },
      onError: (e) => {
        console.log("err", e);
        setDetailsLoading(false);
        setAdDetails({});
      },
    }
  );

  const { mutate: adAmountSpentMutate, isLoading: isAdAmountSpentLoading } = useMutation(
    adAmountSpent,
    {
      onSuccess: (data) => {
        setAdAmount(data)
      },
      onError: (e) => {
        console.log("err", e);
        setAdAmount({})
      },
    }
  );

  const convertTimestamp = (timestamp) => {
    // Create a new Date object based on the timestamp
    var date = new Date(timestamp);

    // Get the month name
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var month = monthNames[date.getMonth()];

    // Get the year
    var year = date.getFullYear();

    // Assemble the formatted date string
    var formattedDate = date.getDate() + " " + month + " " + year;

    return formattedDate;
  };
  const onRemoveSavedAd = (ad) => {
    useSaveAdsHook.RemoveSavedAdMutate({
        projectId: authState.defaultProjectInfo.projectId,
        adId:  `${ad.id}`,
        platform: "display"
    })
}
const analyzeDomian = () =>{
  const url = new URL(basicDetail?.url);
  const domain = url.hostname;
  navigate(`/domain-overview/details?domain=${domain}&country=global`)
}
  useEffect(() => {
    // adsDetailsMutate(basicDetail.id);
    // adAmountSpentMutate(basicDetail.id)
  }, []);

  // if (detailsLoading) {
  //   return <h2>Loading</h2>;
  // } else {
    console.log("basicDetail", basicDetail)
    return (
      <DetailContainer>
        <LevelOneDetailContainer>
          <AdPreviewContainer>
          <SaveAdsButton isSaved={authState.savedAdsIds.includes(basicDetail.id)} onClick={(e) => {
                    e.stopPropagation();
                    if (authState.savedAdsIds.includes(basicDetail.id)) {
                        onRemoveSavedAd(basicDetail)
                    } else {
                        onSave(basicDetail);
                    }

                }}>
                    {authState.savedAdsIds.includes(basicDetail.id) ? "Saved" : "Save"}
                </SaveAdsButton>
            {/* <Card key={basicDetail.id}> */}
            <ImageContainer>
                {basicDetail.image ? <img onLoad={(e) => {
                    // console.log(e.width,e.target.width, e.target.height)
                }} src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${basicDetail.id}`} onError={() => { }} /> : basicDetail.video ? <video src={`https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${basicDetail.id}`} preload="auto" controls loop style={{ width: "100%", height: "100%" }} /> : <img src={AltImage} onError={() => { }} />}
                {/* <ImageResolution>
                    {height ? height : ""} x {width ? width : ""}
                </ImageResolution> */}
            </ImageContainer>
       
            {/* <CardBody>
             <Header>
                <LogoWrapper>
                    <img src={WebsiteIcon}/>
                </LogoWrapper>
                <TitleWrap> 
                    <VideoTitle>{basicDetail.title}</VideoTitle>
                    <ChannelDiv>{basicDetail.channel}</ChannelDiv>
                </TitleWrap>
            </Header> */}
            {/* <VideoTitle>
                {ad.title}
            </VideoTitle>
            <ChannelDetails> */}
                {/* <LogoContainer>
                    <img src={WebsiteIcon}/>
                </LogoContainer> */}
                {/* <ChannelName>
                    {ad.channel}
                </ChannelName>
            </ChannelDetails> */}
            {/* <VideoDescription>
                {basicDetail.content}
            </VideoDescription> */}
            {/* <FloatingButtonsContainer>
                <Favorite isFav={idx == 1} onClick={()=>{
                    authDispatch({type:'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'youtube'})
                    authDispatch({type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id})
                    authDispatch({type:'UPDATE_SAVE_AD_POPUP', value: true})}}>
                    <img src={authState.favAdsList["youtube"]?.includes(ad.id) ? FavouriteIconActive : FavorietIcon}/>
                </Favorite> */}
                {/* |
                <DropdownIcon>
                    <span/>
                </DropdownIcon> */}
            {/* </FloatingButtonsContainer> */}
        {/* </CardBody>
        <CardFooter>
        {basicDetail.country &&<CountryWrap>
                <img src={locationImg}/>
                <Country>{ basicDetail.country.length == 1 ? basicDetail.country[0] : basicDetail.country.length > 1 ? basicDetail.country[0] + " + " + (basicDetail.country.length - 1) : ""}</Country>
            </CountryWrap>} */}
            {/* <GoogleIcon>
                <img src={googleImg}/>
            </GoogleIcon> */}
            {/* <FooterLeft> */}
                {/* <AdDate>
                {ad.firstSeen && ad.lastSeen && <img src={DateIcon}/>}{convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
                </AdDate>
                {ad.country && <AdCountries>
                    <img src={CountryIcon}/>{ ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
                </AdCountries>} */}
            {/* </FooterLeft> */}
            {/* <FooterRight> */}
                {/* <PublishedOn>
                    <label>Published on:</label>
                    {convertTimestamp(ad.publishedOn)}
                </PublishedOn>
                <DetailsButton onClick={()=>toggleDetailsModal(ad,idx)}>
                    {(youtubeState.adDetails.id == ad.id) && youtubeState.detailsModal ? "Close details" : "More details"}
                </DetailsButton> */}
            {/* </FooterRight> */}
        {/* </CardFooter> */}
    {/* </Card> */}
            {/* <Card key={basicDetail.id}>
              <CardBody>
                <AdHeader>
                  <WebsiteLogo>
                    <img src={GlobeIcon} />
                  </WebsiteLogo>
                  <WebsiteNameContainer>
                    <WebsiteName>{basicDetail.domainId}</WebsiteName>
                    <AdUrl>{basicDetail.url ? basicDetail.url : ""}</AdUrl>
                  </WebsiteNameContainer>
                </AdHeader>
                <Highlighter
                  className="title-wrapper-span"
                  highlightClassName="title-highlight-bold"
                  searchWords={highlightWords}
                  autoEscape={true}
                  textToHighlight={basicDetail.title}
                  highlightTag={HightlightTitle}
                />
                <Highlighter
                  className="content-wrapper-span"
                  highlightClassName="content-highlight-bold"
                  searchWords={highlightWords}
                  autoEscape={true}
                  textToHighlight={basicDetail.content}
                  highlightTag={HightlightContent}
                />
              </CardBody>
              <CardFooter>
                <FooterLeft>
                  <AdDate>
                    {basicDetail.firstSeen && basicDetail.lastSeen && (
                      <img src={DateIcon} />
                    )}
                    {convertTimestamp(basicDetail.lastSeen)}
                  </AdDate>
                  <AdCountries>
                    <img src={CountryIcon} />
                    {basicDetail.country.length == 1
                      ? basicDetail.country[0]
                      : basicDetail.country.length > 1
                      ? basicDetail.country[0] +
                        " + " +
                        (basicDetail.country.length - 1)
                      : ""}
                  </AdCountries>
                </FooterLeft>
                <FooterRight></FooterRight>
              </CardFooter>
            </Card> */}
          </AdPreviewContainer>
          <AdContentContainer>
            <h2>Ad Details</h2>
            <TitleContainer>
              <TitleHeader>
                <h3>Title</h3>
                <CopyButton
                           onClick={() => {
                            !copyState && setCopyState(true)
                            navigator.clipboard.writeText(basicDetail.title);
                            !copyState && setTimeout(() => {
                              setCopyState(false)
                            }, 3000);
                          }}
                          data-tooltip-id="title-tooltip"
                          data-tooltip-content={copyState ? 'Copied' : 'Copy'}
                            >
                        {!copyState ? <img src={CopyImage} />:<img src={copiedImage} />}
                        <Tooltip id="title-tooltip" 
                  noArrow={true} 
                  place={'bottom'}  
                  style={{ backgroundColor: "#101828", color: "#FFF",fontFamily: 'Inter', fontSize: '12px', borderRadius: '8px',
                  fontWeight: 600, height: '34px', padding:'8px 12px',display: 'flex', alignItems: 'center' }}/>
                        </CopyButton>
              </TitleHeader>
              <p>{basicDetail.title}</p>
            </TitleContainer>
            <DescriptionContainer>
              <DescriptionHeader>
                <h3>Content</h3>
                <CopyButton
                            onClick={() => {
                              !copyContent && setCopyContent(true)
                              navigator.clipboard.writeText(basicDetail.content);
                              !copyContent && setTimeout(() => {
                                setCopyContent(false)
                              }, 3000);
                            }}
                            data-tooltip-id="content-tooltip"
                            data-tooltip-content={copyContent ? 'Copied' : 'Copy'}
                            >
                            {!copyContent ? <img src={CopyImage} />:<img src={copiedImage} />}
                            <Tooltip id="content-tooltip" 
                  noArrow={true} 
                  place={'bottom'}  
                  style={{ backgroundColor: "#101828", color: "#FFF",fontFamily: 'Inter', fontSize: '12px', borderRadius: '8px',
                  fontWeight: 600, height: '34px', padding:'8px 12px',display: 'flex', alignItems: 'center' }}/>
                        </CopyButton>
              </DescriptionHeader>
              <p>{basicDetail.content}</p>
            </DescriptionContainer>
          </AdContentContainer>
        </LevelOneDetailContainer>
        <LevelTwoDetailsContainer>
          <h2>Ad Analytics</h2>
          <LevelTwoRow>
            <AdPositionContainer>
              <AdPositionHeader>
                <h3>Agency</h3>
                <AvgPosition>
                  {Array.isArray(basicDetail?.agency) ? basicDetail?.agency?.map((item)=>(
                      <p>{item}</p>
                    )) 
                    : basicDetail?.agency
                  }
                </AvgPosition>
              </AdPositionHeader>
              {/* <AvgPosition> */}
                {/* 0{Math.round(basicDetail.position) + 1} */}
                {/* <img src={RightUpperGreenArrow} /> */}
              {/* </AvgPosition> */}
              {/* <LineChartGradient adDetails={adDetails} /> */}
            </AdPositionContainer>
            <AmountSpentContainer>
              <AmountSpentHeader>
                <h3>Media Type:</h3>
              </AmountSpentHeader>
              <p>{basicDetail.image ? "Image" : basicDetail?.video ? "Video" : "-"}</p>

              {/* <AmountSpent>
                {
                  isAdAmountSpentLoading ? <Skeleton
                  style={{
                    lineHeight: 8,
                    width: 220,
                  }}
                  /> : <>
                  <MoneyBagLogoContainer>
                    <img src={MoneyBagLogo} />
                    </MoneyBagLogoContainer>
                    {(adAmount?.low && adAmount?.high) ? <p>${formatNumber(adAmount.low)} - ${formatNumber(adAmount.high)}</p>: <p>-</p>}
                    <span>Cost spent per month</span>
                  </>
                }
                
              </AmountSpent> */}
            </AmountSpentContainer>
            <OtherDetailsContainer>
              <CountryConainer>
                <CountryHeader>
                  <h3>Country</h3>
                </CountryHeader>
                <MapsList>
                  {basicDetail.country.map((c, idx) => {
                    if (basicDetail.country.length > 5 ? idx < 5 : true) {
                      return getFlagEmoji(c);
                    }
                  })}
                  {basicDetail.country.length > 5 &&
                    "1" + (basicDetail.country - 5)}
                </MapsList>
                <p>
                  {basicDetail.country.length == 1
                    ? basicDetail.country[0]
                    : basicDetail.country.length > 1
                    ? basicDetail.country[0] +
                      " + " +
                      (basicDetail.country.length - 1)
                    : ""}
                </p>
              </CountryConainer>
              <LastSeenContainer>
                <LastSeenHeader>
                  <h3>Last Seen</h3>
                </LastSeenHeader>
                <div>
                  <p>
                    {convertTimestamp(basicDetail.lastSeen).split(" 20")[0]}
                  </p>
                  <span>
                    20{convertTimestamp(basicDetail.lastSeen).split(" 20")[1]}
                  </span>
                </div>
              </LastSeenContainer>
              <DestinationUrlContainer>
                <DestinationUrlHeader>
                  <h3>
                    Destination URL
                    <p onClick={analyzeDomian}>
                      <img src={RedirectOrangeIcon} />
                      analyze domain
                    </p>
                  </h3>
                  <CopyButton
                            onClick={() => {
                              !copyURL && setCopyURL(true)
                              navigator.clipboard.writeText(adDetails.url);
                              !copyURL && setTimeout(() => {
                                setCopyURL(false)
                              }, 3000);
                            }}
                            data-tooltip-id="url-tooltip"
                    data-tooltip-content={copyURL ? 'Copied' : 'Copy'}
                        >
                            {!copyURL ? <img src={CopyImage} />:<img src={copiedImage} />}
                            <Tooltip id="url-tooltip" 
                  noArrow={true} 
                  place={'bottom'}  
                  style={{ backgroundColor: "#101828", color: "#FFF",fontFamily: 'Inter', fontSize: '12px', borderRadius: '8px',
                  fontWeight: 600, height: '34px', padding:'8px 12px',display: 'flex', alignItems: 'center' }}/>
                        </CopyButton>
                </DestinationUrlHeader>
                <p>{basicDetail?.url}</p>
              </DestinationUrlContainer>
            </OtherDetailsContainer>
          </LevelTwoRow>
        </LevelTwoDetailsContainer>
        {/* <AiDetails
          basicDetail={basicDetail}
          adDetails={adDetails}
          //  getAdDetails={getAdDetails}
        /> */}
        {/* <AdGenie
          basicDetail={basicDetail}
          adDetails={adDetails}
          //  getAdDetails={getAdDetails}
        /> */}
      </DetailContainer>
    );
  }
// };

export default DisplayDetail;
