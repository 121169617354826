import styled from "styled-components";

export const ClientProfileContainer = styled.div`
width: 256px;
height: 232px;
flex-shrink: 0;
border-radius: 8px;
background: #FFF;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
`;

export const UpdatedProfile = styled.div`
width: 100%;
height: 100%;
position: relative;
`;

export const TopImage = styled.img`
border-radius: 8px 8px 0 0;
`;

export const EditIcon = styled.img`
position: absolute;
top: 10px;
right: 10px;
cursor: pointer;
`;

export const LogoContainer = styled.div`
width: 36px;
height: 36px;
flex-shrink: 0;
border-radius: 50%;
display: flex; 
align-items: center;
justify-content: center;
position: absolute;
top: 62px;
left: 110px;
border: 1px solid #EAEAEA;
background: #fff;
`;

export const Logo = styled.img`
width: 20px;
height: 20px;
object-fit: contain;
`;

export const Brand = styled.div`

`;

export const BrandName = styled.div`
color: #181E39;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 24px */
position: absolute;
top: 102px;
transform: translateX(-50%);
left: 50%;
`;

export const Category = styled.div`
color: rgba(28, 28, 28, 0.40);
text-align: center;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 18px */
position: absolute;
top: 130px;
transform: translateX(-50%);
left: 50%;
white-space: nowrap;
`;

export const UpdatedText = styled.div`
color: #42AC66;
text-align: center;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 18px */
position: absolute;
bottom: 14px;
transform: translateX(-50%);
left: 50%;
`;

export const AskToUpdate = styled.div`
width: 240px;
height: 216px;
flex-shrink: 0;
border-radius: var(--8, 8px);
border: 1px dashed #B9B9B9;
background: #F6F7FB;
`;

export const UploadImage = styled.img`
    margin-top: 47px;
    margin-left: 107px;
`;

export const PrimaryText = styled.div`
    color: #181E39;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 21px */
text-align: center;
margin-top: 12.5px;
`;

export const SecondaryText = styled.div`
color: rgba(28, 28, 28, 0.40);
text-align: center;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 18px */
margin-top: 4px;
text-align: center;
`;

export const UpdateButton = styled.div`
color: #FF8D4B;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 18px */
text-decoration-line: underline;
margin-top: 34px;
text-align: center;
content: Upload now;
`;