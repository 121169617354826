import styled from "styled-components";
import bgLayer from "./assets/background.png"
export const Container = styled.div`
width: 100%;
height: 100%;
background: #FFF;
`;

export const BackgroundLayer = styled.div`
width: 100%;
height: 100%;
background: url(${bgLayer});
background-size: cover;
background-position: center;
padding: 4.310344828% 10.086206897% 4.310344828% 4.310344828%;
overflow: auto;
`;