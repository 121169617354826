import React, {useEffect, useState} from "react";
import {PasswordChangeConfirmation, ProceedButton} from "./index.styled"
import {useNavigate} from 'react-router-dom'

const SuccessPage = () => {
    const [timeLeft, setTimeLeft] = useState(3);
    const navigate = useNavigate()

    useEffect(() => {
        if (timeLeft === 0) {
            navigate("/")
            return;
        }
    
        const timer = setTimeout(() => {
          setTimeLeft(prevTime => prevTime - 1);
        }, 1000); // Decrease the timer every 1 second
    
        return () => clearTimeout(timer);
      }, [timeLeft]);
    
    
    return <PasswordChangeConfirmation>
    <h3>Your password has been changed successfully.</h3>
    <p>Taking you to Log in <span>{timeLeft}s</span></p>
    <ProceedButton onClick={()=>{
            navigate("/")
        }}>Proceed to Login</ProceedButton>
</PasswordChangeConfirmation>
}

export default SuccessPage