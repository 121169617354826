import React, {useState} from "react";
import { Wrapper, MetaAdNetword, GoogleAdNetword, DownloadContainer, DownloadButton } from "./ExportList.styled"
import { toPng } from 'html-to-image';
import { useImageAdGenerator } from "../../../context/imageAdGeneration";
import { useImageAdInfo } from "../../../hooks/useImageAdInfo";
import { getSearchParams } from "../../../../../../../utils/helper";
import { useLocation } from "react-router-dom";

const ExportList = () => {
    const { imageAdGeneratorState, imageAdGeneratorDispatch } = useImageAdGenerator()
    const [downloadingStatus, setDownLoadingStatus] = useState(false)
    const location = useLocation()

    const useImageAdInfoHook = useImageAdInfo()

    const downloadImage = async () => {
        const { id } = getSearchParams(location.search)

        let htmlContent = document.querySelector(".final-image")
        if (htmlContent) {
            // document.querySelectorAll(`div`).forEach((element) => {
            //     element.style.outline = "none";
            // })
            
            setDownLoadingStatus(true)
            // const fontEmbedCss = await htmlToImage.getFontEmbedCSS(parsedHtmlRef.current);
            toPng(htmlContent, { 
                    cacheBust: true, 
                    pixelRatio: imageAdGeneratorState.scaleValue,             
                    // fontEmbedCss: fontEmbedCss // Pass fontEmbedCss option here
                })
                .then((dataUrl) => {
                    const link = document.createElement('a')
                    link.download = 'my-image-name.png'
                     useImageAdInfoHook.SaveGeneratedTemplateMutate({
                        campaignId: id,
                        templateId: imageAdGeneratorState.activeAdTemplate.id,
                        base64String: dataUrl.replace("data:image/png;base64,","")
                    })
                    link.href = dataUrl
                    link.click()
                    setDownLoadingStatus(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    return <Wrapper>
        <h2>Publish (coming soon)</h2>
        <MetaAdNetword>
            Meta ad network
        </MetaAdNetword>
        <GoogleAdNetword>
            Google ad network
        </GoogleAdNetword>

        <DownloadContainer>
            <h2>Download</h2>
            <DownloadButton isDisabled={downloadingStatus} onClick={downloadImage}>
                {downloadingStatus ? "Downloading...": "PNG"}
            </DownloadButton>
        </DownloadContainer>
    </Wrapper>
}

export default ExportList