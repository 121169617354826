
import React from 'react';
import { Chart } from "react-google-charts";
import Skeleton from 'react-loading-skeleton';
import { useUrlAnalysis } from "../../../context/urlanalysis";

const MapChart = ({isDataLoading}) => {
    const { urlAnalysisState, urlAnalysisDispatch  } = useUrlAnalysis()


    const data = [
        [
          "Country",
          "Ads Count"
        ],
        [
          "United States",
          23238
        ],
        [
          "Canada",
          11580
        ],
        [
          "India",
          11401
        ],
        [
          "Mexico",
          9934
        ],
        [
          "Australia",
          7246
        ],
        [
          "United Kingdom",
          5397
        ],
        [
          "Brazil",
          5117
        ],
        [
          "Thailand",
          4461
        ],
        [
          "Turkey",
          3713
        ],
        [
          "Chile",
          3350
        ],
        [
          "Singapore",
          2944
        ],
        [
          "Spain",
          2773
        ],
        [
          "Colombia",
          2534
        ],
        [
          "New Zealand",
          2501
        ],
        [
          "Hong Kong",
          2347
        ],
        [
          "France",
          2307
        ],
        [
          "Italy",
          2280
        ],
        [
          "Germany",
          2130
        ],
        [
          "Taiwan",
          1925
        ],
        [
          "Japan",
          1914
        ],
        [
          "United Arab Emirates",
          1685
        ],
        [
          "Malaysia",
          1622
        ],
        [
          "South Korea",
          979
        ],
        [
          "Poland",
          955
        ],
        [
          "Netherlands",
          921
        ],
        [
          "Belgium",
          797
        ],
        [
          "Ireland",
          770
        ],
        [
          "Denmark",
          688
        ],
        [
          "no country",
          677
        ],
        [
          "Switzerland",
          604
        ],
        [
          "Norway",
          521
        ],
        [
          "Philippines",
          403
        ],
        [
          "Portugal",
          391
        ],
        [
          "Saudi Arabia",
          376
        ],
        [
          "Argentina",
          360
        ],
        [
          "Hungary",
          355
        ],
        [
          "Austria",
          334
        ],
        [
          "Sweden",
          326
        ],
        [
          "Peru",
          276
        ],
        [
          "Czechia",
          258
        ],
        [
          "Russia",
          188
        ],
        [
          "Finland",
          172
        ],
        [
          "Ecuador",
          116
        ],
        [
          "Vietnam",
          93
        ],
        [
          "Indonesia",
          91
        ],
        [
          "Romania",
          74
        ],
        [
          "Ukraine",
          66
        ],
        [
          "Pakistan",
          65
        ],
        [
          "Puerto Rico",
          52
        ],
        [
          "Costa Rica",
          41
        ],
        [
          "Dominican Republic",
          37
        ],
        [
          "Bulgaria",
          31
        ],
        [
          "Greece",
          30
        ],
        [
          "Israel",
          29
        ],
        [
          "Guatemala",
          28
        ],
        [
          "South Africa",
          26
        ],
        [
          "Kuwait",
          25
        ],
        [
          "China",
          22
        ],
        [
          "Isle of Man",
          22
        ],
        [
          "Panama",
          21
        ],
        [
          "Slovakia",
          20
        ],
        [
          "Egypt",
          19
        ],
        [
          "Bolivia",
          18
        ],
        [
          "El Salvador",
          17
        ],
        [
          "Luxembourg",
          17
        ],
        [
          "Bangladesh",
          16
        ],
        [
          "Venezuela",
          16
        ],
        [
          "Honduras",
          14
        ],
        [
          "Nigeria",
          14
        ],
        [
          "Georgia",
          13
        ],
        [
          "Lebanon",
          13
        ],
        [
          "Cyprus",
          12
        ],
        [
          "Moldova",
          12
        ],
        [
          "Qatar",
          12
        ],
        [
          "Oman",
          11
        ],
        [
          "Kenya",
          10
        ],
        [
          "Sint Maarten",
          9
        ],
        [
          "Bahrain",
          8
        ],
        [
          "Mauritius",
          8
        ],
        [
          "Uruguay",
          8
        ],
        [
          "Jamaica",
          7
        ],
        [
          "Jordan",
          7
        ],
        [
          "Malta",
          7
        ],
        [
          "Nepal",
          7
        ],
        [
          "Paraguay",
          7
        ],
        [
          "Albania",
          6
        ],
        [
          "Azerbaijan",
          6
        ],
        [
          "Grenada",
          6
        ],
        [
          "Iran",
          6
        ],
        [
          "Laos",
          6
        ],
        [
          "Lithuania",
          6
        ],
        [
          "Mongolia",
          6
        ],
        [
          "Morocco",
          6
        ],
        [
          "Serbia",
          6
        ],
        [
          "Andorra",
          5
        ],
        [
          "Angola",
          5
        ],
        [
          "Brunei",
          5
        ],
        [
          "Cambodia",
          5
        ],
        [
          "Croatia",
          5
        ],
        [
          "Guadeloupe",
          5
        ],
        [
          "Ghana",
          4
        ],
        [
          "Guyana",
          4
        ],
        [
          "Monaco",
          4
        ],
        [
          "Nicaragua",
          4
        ],
        [
          "Trinidad and Tobago",
          4
        ],
        [
          "Bahamas",
          3
        ],
        [
          "Bermuda",
          3
        ],
        [
          "Estonia",
          3
        ],
        [
          "Ethiopia",
          3
        ],
        [
          "Iceland",
          3
        ],
        [
          "Latvia",
          3
        ],
        [
          "Madagascar",
          3
        ],
        [
          "Seychelles",
          3
        ],
        [
          "Yemen",
          3
        ],
        [
          "Algeria",
          2
        ],
        [
          "Aruba",
          2
        ],
        [
          "Cayman Islands",
          2
        ],
        [
          "Gambia",
          2
        ],
        [
          "Gibraltar",
          2
        ],
        [
          "Libya",
          2
        ],
        [
          "Namibia",
          2
        ],
        [
          "Papua New Guinea",
          2
        ],
        [
          "Sierra Leone",
          2
        ],
        [
          "Slovenia",
          2
        ],
        [
          "Sri Lanka",
          2
        ],
        [
          "Tanzania",
          2
        ],
        [
          "Togo",
          2
        ],
        [
          "Tunisia",
          2
        ],
        [
          "Vatican City",
          2
        ],
        [
          "Anguilla",
          1
        ],
        [
          "Antigua and Barbuda",
          1
        ],
        [
          "Barbados",
          1
        ],
        [
          "Cuba",
          1
        ],
        [
          "DR Congo",
          1
        ],
        [
          "Gabon",
          1
        ],
        [
          "Iraq",
          1
        ],
        [
          "Jersey",
          1
        ],
        [
          "Liberia",
          1
        ],
        [
          "Maldives",
          1
        ],
        [
          "Myanmar",
          1
        ],
        [
          "Northern Mariana Islands",
          1
        ],
        [
          "Sudan",
          1
        ],
        [
          "Uzbekistan",
          1
        ]
    ]; 

    return isDataLoading && urlAnalysisState.mapdata.length > 1 ? 
        <Skeleton style={{
            lineHeight: 19,
            width: 610,
          }}/> : 
        <Chart
            chartEvents={[
                {
                eventName: "select",
                callback: ({ chartWrapper }) => {
                    const chart = chartWrapper.getChart();
                    const selection = chart.getSelection();
                    if (selection.length === 0) return;
                    const region = urlAnalysisState.mapdata[selection[0].row + 1];
                    // console.log("Selected : " + region);
                },
                },
            ]}
            
            options={{colorAxis: { colors: ["#eff8ff", "#0058A3"] },
            tooltip: {
              isHtml: true,
              textStyle: { color: '#333' },
              // Define a custom tooltip format using placeholders
              // Use data.getColumnLabel() to access column labels
              // Use data.getFormattedValue() to access cell values
              // You can use HTML here to format the tooltip content
              // For example: `<div>${data.getFormattedValue(rowIndex, columnIndex)}</div>`
              // Use data.getValue(rowIndex, columnIndex) to access raw cell values
              // For example: `<div>${data.getValue(rowIndex, columnIndex)}</div>`
              // You can also include custom HTML and CSS to style the tooltip
              // For example:
              // `<div style="color: blue;">${data.getFormattedValue(rowIndex, columnIndex)}</div>`
              // `<div><span>Value: ${data.getFormattedValue(rowIndex, columnIndex)}</span></div>`
            },}}
            chartType="GeoChart"
            width="608px"
            height="300px"
            data={urlAnalysisState.mapdata}
            />
}

export default MapChart;

