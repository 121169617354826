import styled from "styled-components";



export const ResultWrapper = styled.div`
    width: 90%;
    margin: 34px auto;
`;

export const Heading = styled.div`
    color: #000;
    font-family: 'Inter';
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    margin-bottom: 4px;
`;

export const SubHeading = styled.div`
    // width: 277px;
    color: rgba(28, 28, 28, 0.50);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
`;

export const searchedDomain = styled.span`
    color: rgba(115, 74, 0, 0.80);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
`;

export const OptionsContainer = styled.div`
    display: flex;
    height: 40px;
    margin: 16px 0;
    gap: 8px;
    max-width: 990px;
    margin: 48px 0px 24px 0;
    justify-content: space-between;
`;
