import styled, { keyframes } from "styled-components";

export const Analyzing = styled.div`
width: 100%;
height: 75px;
border-radius: 6px;
border: 1px solid #E8E8E8;
background: rgba(255, 255, 255, 0.50);
padding: 14px 24px;
display: flex;
align-items: center;
gap: 16px;
position: relative;
overflow: hidden;
`;

const skeleton = keyframes`
0%{
   left: -5%; 
}
100%{
    left: 100%;
}
`;

export const Loader = styled.div`
width: 37.413px;
height: 131.418px;
transform: rotate(23.402deg);
flex-shrink: 0;
background: rgba(255, 255, 255, 0.40);
position: absolute;
animation: 2s linear ${skeleton} infinite;
`;

export const Logo = styled.img`

`;

export const Text = styled.div`
color: #1C1C1C;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
`;
