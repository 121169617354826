import styled from 'styled-components'

export const Input = styled.input`
    height: 44px;
    background-color: ${props => props.isDisabled ? "rgba(247, 249, 250, 1)": "FFF"};
    border: 1px solid #ECECEC;
    box-sizing: border-box;
    padding: 0px 40px 0px 48px;
    margin: 0 7px;
    outline: none;
    color: #2E2E2E;
    font-size: 14px;
    font-weight: 300;
    width: 434px;
    border: ${props => props.error ? "1px solid #F04438" : "1px solid #D0D5DD"};
    // outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};
    border-radius: 8px;
// border: 1px solid #D0D5DD;
    &:focus{
        border: ${props => props.error ? "1px solid #FF5180" : "1px solid #FFB258"};
        // outline: ${props => props.error ? "2px solid #FFB7CB" : "2px solid #B9DDFF"};
    }
`;


export const InputContainer = styled.div`
    position: relative;
    width: 98%;
    pointer-events: ${props => props.isDisabled ? "none": "all"};
    /* test design */
    /* height: 100%; */

    img{
        position: absolute;
        left: 9px;
        top: 12px;
        height: 16px;
        padding: 0px 12px 0px 20px;
    }
    
`
export const ExampleSearchContainer = styled.div`
display: flex;
justify-content: center;
gap: 8px;
margin: 18px 0 73px 0;
h3 {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #1c1c1c;
  /* margin-right: 6px; */
}

span {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #368cd5;
  cursor: pointer;
  margin-right: 4px;
}
`;
export const ExampleWrap = styled.div`
display: flex;
flex-direction: column;
width: 100%;
`

export const ToolTipImageContainer = styled.div`
    position: absolute;
    right: 0px;
    height: 40px;
    width: 40px;
    top: 0px;
    display: ${props => props.error ? "flex": "none"};
    img{
        filter: contrast(1);
        padding-left: 0;
    }

    &:hover{
        > div{
            display: flex;
        }

        img{
            display: block;
        }
    }
`;

export const ToolTip = styled.div`
    background: #555555;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 300;
    position: absolute;
    bottom: -34px;
    padding: 4px 8px;
    width: 125px;
    display: none;
`;

export const ToolTipContainer = styled.div`
    font-size: 12px;
    font-weight: 300;
    color: #666;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;

    img{
        margin-left: 4px;
    }
`;

export const Wrapper = styled.div`
    width: 100%;
`;