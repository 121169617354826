import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    background: #0000005e;width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    background: #0000005e;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
`;

export const SelectContainer = styled.div`
    height: 80vh;
    width: 70vw;
    /* overflow-y: auto; */
    border-radius: 10px;
    padding: 40px 48px;
    background: #FFFFFF;
    position: relative;
`;

export const CloseButton = styled.div`
    position: absolute;
    cursor: pointer;
    right: 25px;
    color: grey;
    top: 20px;
    font-family: monospace;
    font-size: 16px;
    height: 28px;
    width: 28px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Heading = styled.div`
    font-size: 18px;
    font-weight: 500;
    color: #2E2E2E;
    margin-bottom: 32px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    span{
        color: #0051AE
    }
    h1{
        font-size: 28px;
        font-weight: 600;
        line-height: 40px;
        margin: 0;
    }
`;

export const PlatformsContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const PlatformAvailable = styled.div`
    cursor: pointer;
    background: ${props => props.isSelected ? "#F0F7FF" : "#FFFFF"};
    border: ${props => props.isSelected ? "1px solid #B4DAFF" : "1px solid #F2F2F2"};
    border-radius: 8px;
    color: ${props => props.isSelected ? "#0051AE" : "#505050"};
    font-size: 16px;
    font-weight: 400;
    width: 45%;
    height: 103px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 52px;
`;

export const CancelButton = styled.div`
    width: 87px;
    height: 40px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const ContinueButton = styled.button`
    width: auto;
    height: 40px;
    border-radius: 8px;
    background-color: #FF711E;
    color: white;
    font-size: 14px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    :hover {
        filter: brightness(90%);
    }

    &:disabled{
        background: #ECECEC;
        color: #FFFFFF;
    }
`;

export const AdsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    /* height: 70vh;
    overflow-y: auto; */
    /* padding: 30px 32px; */

    padding: 30px 0px 20px!important;
    overflow-y: auto;
    height: 90%;
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }

`;

export const LoadSelectedAdsButton = styled.div`
    border-radius: 8px;
    background: ${props => props.isDisabled ? "#CDCDCD" : "#FF711E"};
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    padding: 8px 16px;
    color: white;
    margin-right: 32px;
    cursor: pointer;
    pointer-events: ${props => props.isDisabled ? "none" : "auto"};

    &:hover{
        background: ${props => props.isDisabled ? "none" : "#E75500"};
    }

    &:active{
        background: ${props => props.isDisabled ? "none" : "#C74A00"};
    }
`;