import { Description, GeneratingContainer, Header, Icon, Name, Process, ProcessingContainer, Spinner, Title, TryAgain, Wrapper, WrongContainer, WrongDescription, WrongDiv } from "./index.styled";

import completed from "./assets/completed.svg"

// import { useTextAdGenerator } from "../../context/textAdGeneration";
import { useEffect } from "react";
// import { appClient } from "../../../../../../utils/axios-utils"
import {
    useMutation
} from "@tanstack/react-query"
import { useNavigate } from "react-router-dom";
import wentWrong from './assets/wentWrongImg.svg'
// import statics from "../../statics";
import BouncingDotsLoader from "../../../../HOC/BouncingDotsLoader";
import { useAdGenerator } from "../../context/adGeneration";
// const AddGeneratingPage = async (data) => {
//     const config = {
//         url: `text-ad-generation/generate/loaded-process`,
//         method: "POST",
//         data
//     }

//     return appClient(config)
// }

function GeneratingPage() {
    // const { textAdGeneratorState, textAdGeneratorDispatch } = useTextAdGenerator()
    const {
        adGeneratorState,
        adGeneratorDispatch,
      } = useAdGenerator();
    const navigate = useNavigate()

    const getProcessesStatus = () => {
        return(
            <ProcessingContainer>
                {
                    adGeneratorState.generatingProcesses.map((process) => {
                        return (
                            <Process>
                                <Name>{process.name}</Name>
                                {
                                    process.isCompleted
                                    ? <Icon src={completed} alt="complted" />
                                    : <Spinner />
                                }
                            </Process>
                        )
                    })
                }
            </ProcessingContainer>
        )
    }
    const getSomethingWrong = () => {
        // const initialPageFn = () =>{
        //     textAdGeneratorDispatch({type: "UPDATE_CURRENT_PAGE", value: pages[0]})
        // }
        return(
            <WrongContainer>
                <img src={wentWrong} />
                <WrongDiv>Something went wrong!</WrongDiv>
                <WrongDescription>AdSpyder encountered an error while generating an ad. Please try again. If the problem persists, please reach out to support for assistance</WrongDescription>
                {/* <TryAgain onClick={initialPageFn}>Try again</TryAgain> */}
            </WrongContainer>
        )
    }

    return ( 
        <Wrapper>
            <GeneratingContainer>
            
                {!adGeneratorState.getWentWrong ?
                <>
                    <Header>
                        <Title>Generating ads 
                            <BouncingDotsLoader style={{marginBottom: "6px"}} />
                        </Title>
                        <Description>Please be patient. This process may take a few minutes...</Description>
                    </Header>
                    {getProcessesStatus()}
                </> 
                : getSomethingWrong()}
                
            </GeneratingContainer>
        </Wrapper>
        
     );
}

export default GeneratingPage;