import { useImageAdGenerator } from "../../../context/imageAdGeneration";
import { AdGoalContent, AdTypeContainer, AdTypeContent, AdTypeIcon, AdTypeIconContainer, AdTypeName, AudienceLabel, BrandDetailsContent, BrandLabel, Category, CategoryContainer, Choice, Close, Content, DetailContainer, Header, HighlightLine, Item, Label, Line, Name, NavItem, PopupBackground, SubNavbar, TargetAudienceContent, Template } from "./detail.styled";
import closeIcon from "../../../assets/close.svg"
import { useEffect, useState } from "react";
import statics from "../../../statics";
function DetailPopup() {
    const { imageAdGeneratorState: {selectedDownloadedTemplate}, imageAdGeneratorDispatch } = useImageAdGenerator()
    const { detailNavItems, adTypes, enteringDomain: {categories} } = statics
    const [activeId, setActiveId] = useState(1)

    useEffect(() => {
        setActiveId(1)
    }, [selectedDownloadedTemplate])

    if(!selectedDownloadedTemplate) return

    const { category, goal, ageGroup, gender, adCategory, brandName, brandDescription, templateUrl } = selectedDownloadedTemplate

    const handleClose = () => {
        imageAdGeneratorDispatch({
            type: "SET_SELECTED_DOWNLOADED_TEMPLATE",
            value: null
        })
    }

    const getCategory = () => {
        return categories.find((c) => c.backendName || c.name === category)
    }

    const getAdType = () => {
        return adTypes.find((type) => type.name.toLowerCase() === adCategory.toLowerCase())
    }

    const renderSubNavBar = () => {
        
        return(
            <SubNavbar>
                {detailNavItems.map((item) => {
                    const isActive = item.id === activeId
                    return(
                        <NavItem onClick={() => setActiveId(item.id)} >
                            <Item>{item.name}
                            </Item>
                            <HighlightLine isActive={isActive} />
                        </NavItem>
                    )
                })}
            </SubNavbar>
        )
    }

    const renderContent = () => {
        switch(activeId){
            case 1: 
            return (
                <AdGoalContent>
                    {goal}
                </AdGoalContent>
            )

            case 2:
            return (
                <TargetAudienceContent>
                    <AudienceLabel>Age group</AudienceLabel>
                    <Choice>{ageGroup}</Choice>
                    <AudienceLabel>Gender</AudienceLabel>
                    <Choice>{gender}</Choice>
                </TargetAudienceContent>
            )

            case 3: 
            return (
                <BrandDetailsContent>
                    <BrandLabel>Brand name:</BrandLabel>
                    <Name>{brandName}</Name>
                    <BrandLabel>Brand message / brand description:</BrandLabel>
                    <Name>{brandDescription}</Name>
                </BrandDetailsContent>
            )

            case 4:
                const adType = getAdType()
            return(
                <AdTypeContent>
                    <AdTypeContainer>
                        <AdTypeIconContainer>
                            <AdTypeIcon src={adType.activeIcon} alt="adType" />
                        </AdTypeIconContainer>
                         <AdTypeName>{adType.name}</AdTypeName>
                    </AdTypeContainer>
                </AdTypeContent>
            )
        }
    }


    return ( 
        <PopupBackground >
            <Content>
                <DetailContainer>
                    <Header>Ad details:</Header>
                    <Close src={closeIcon} alt="close" onClick={handleClose} />
                    <Template templateUrl={templateUrl} />
                    <CategoryContainer>
                        <Label>Category:</Label>
                        <Category>{getCategory().name}</Category>
                    </CategoryContainer>
                    <Line />
                    {renderSubNavBar()}
                    {renderContent()}
                </DetailContainer>
            </Content>
        </PopupBackground>
    );
}

export default DetailPopup;