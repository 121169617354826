import styled from "styled-components";

export const Container = styled.div`
width: 100%;
`

export const SubHeader = styled.div`
color: #1C1C1C;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom: 18px;
`;

export const Optional = styled.span`
color: #9C9C9C;
font-family: Inter;
font-size: 16px;
font-style: italic;
font-weight: 300;
line-height: normal;
`;

export const DescriptionBox = styled.textarea`
padding: 22px;
width: 85.8%;
max-width: 997px;
height: 162px;
border-radius: 6px;
border: 1px solid #ECECEC;
background: #FFF;
color: #1C1C1C;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: 150%; /* 21px */
resize: none;

:focus {
    outline: none;
    border: 1px solid #ECECEC;
}
`;

export const Controllers = styled.div`
display: flex;
align-items: center;
justify-content: end;
margin-top: 10px;
gap: 12px;
width: 85.8%;
max-width: 997px;
`;

export const Skip = styled.div`
display: inline-flex;
padding: 16px 28px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 6px;
border: 1px solid #E8E8E8;
background: #FFF;
color: #161616;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
cursor: pointer;
`;

export const Next = styled.div`
    display: inline-flex;
    padding: 16px 28px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: #FF711E;
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    cursor: pointer;
    opacity: ${props => props.isDisabled ? "0.5": "1"};
    pointer-events: ${props => props.isDisabled ? "none": "all"};
`;