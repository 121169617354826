import React, {useEffect, useState} from 'react'

import {
    Container,
    SearchContainer,
    ResultsContainer,
    Wrapper,
    HeadingWrapper,
    KeywordInput,
    TrackButton,
    InputContainer,
    ClearButton,
    HeadingLeft,
    HeadLeftWrap,
    HeaderRight,
    HowItWork,
    RightIcons,
    ErrorIcon,
    ErrorBox
} from "./index.styled"
import Tooltip from 'rc-tooltip';

import PPCRestrictionPopup from '../../../components/PPCRestrictionPopup';
import SearchIcon from "../../../../media/Dashboard/searchIcon.svg"
import CloseIcon from "../../../../media/Dashboard/detailsPaneClose.svg"
import { useAuth } from '../../../../context/auth';

import { useKeywordTracking } from '../context/keywordtracking';
import KeywordInputModal from './components/KeywordInputModal';
import KeywordTable from './components/keywordTable/components/keywordTable';

import { useKeywordTrackingInfo } from '../hooks/useKeywordTrackingInfo';
import tracking from "./assets/tracking.png"
import errorIcon from "./assets/errorIcon.svg"
import AddNewPopup from '../../../components/AddNewPopup';
import { useDashboard } from '../../../../context/dashboard';
import { useDashboardInfo } from '../../Dashboard/hooks/useDashboardInfo';
import KeywordsTable from '../../Dashboard/components/ProjectOverview/KeywordsTable';


const KeywordTracking = () => {
    const {keywordTrackingState, keywordTrackingDispatch} = useKeywordTracking()
    const useKeywordTrackingInfoHook = useKeywordTrackingInfo()
    const {authState, authDispatch} = useAuth()
    const [inputFocussed, setInputFocussed] = useState(null)
    const [error, setError] = useState(false)
    
    const { dashboardState: {keywordChoices, selectedCountry, selectedProject} } = useDashboard()
    const useDashboardInfoHook = useDashboardInfo()
    useEffect(() => {
        useKeywordTrackingInfoHook.getTrackingKeywordsMutation()
        keywordTrackingDispatch({type: 'SET_KEYWORD_INPUT', value: ''})
    }, [])
    
    const eventEnterHandle = (e) =>{
        if (e.key === 'Enter') {
            keywordTrackingDispatch({type: 'SET_KEYWORD_INPUT_LIST', value: [{ keyword: keywordTrackingState.keywordInput, platform: '', country: {}, platformError: '' }]})
            keywordTrackingDispatch({type: 'SET_KEYWORD_INPUT_MODAL_OPEN', value: true})
        }
    }
        

    const headingText = "Keyword Tracking "
    const descriptionText = "Keep ahead of your target PPC keywords effortlessly. Utilize the Keyword Tracking feature to manage your preferred keywords, receive regular updates, and access comprehensive campaign data, all in one convenient place. This tool simplifies monitoring and enhances your strategic approach to keyword optimization. "


    const toolTipFunction = ()  =>{
        return (
            <div>
                <div style={{marginBottom: '16px',fontSize: '14px', color: 'var(--black-100, #1C1C1C)',fontWeight: '500'}}>How to use:</div>
                <ul style={{listStyleType: 'decimal',marginLeft: '16px'}}>
                    <li  style={{marginBottom: '16px'}}>Click on “Add Keyword” button. In the popup, type in the keyword of your choice and select the platform and country from the dropdown. </li>
                    <li  style={{marginBottom: '16px'}}>You can add more keywords by using the “Add more keyword” option. Once the keywords are added, click on the “Setup Tracking” button.  </li>
                    <li  style={{marginBottom: '16px'}}>To view the analytics of the tracked keyword, simply click on the keyword and view campaign and platform level metrics, with geo-location and seasonality indicators. </li>
                </ul>
            </div>
        )
    }

    const tooltipStyle = {
        marginTop: '100px'
    };
    
    const overlayStyle = {

    };
  
    const overlayInnerStyle = {
        backgroundColor: '#FFF7E8',
        color: 'rgba(28, 28, 28, 0.80)',
        padding: '24px 20px 44px 20px',
        fontSize: '14px',
        marginTop: '120px',
        width: '400px',
        maxHeight: '510px',
        paddingBottom: '44px',
        overflowY: 'auto'
    }; 

    const closeInputModal = () => {
        keywordTrackingDispatch({type: 'SET_KEYWORD_INPUT_MODAL_OPEN', value: false})
    }

    const handleSetup = (chosenChoicesIndices, userSelectedList) => { 
        const keywordData = chosenChoicesIndices.map((index) => {
            return{
            keyword: keywordChoices[index].keyword,
            source: "suggested",
            country: selectedCountry
            }
        })

        const userEnteredKeywordData = userSelectedList.map((data) => {
            return{
            keyword: data,
            source: "user",
            country: selectedCountry
            }
        })
        useDashboardInfoHook.addKeywordsForTrackingMutate({
            projectId: selectedProject.id,
            keywords: [...keywordData,...userEnteredKeywordData]
        })
        closeInputModal()
    }


    return (
        <Container>
            <Wrapper>
                <KeywordsTable forKeywordTracking={true} />
            </Wrapper>
        </Container>
    )

    return <Container>
         
                {authState.isPPCRestrictedPopup && 
                    <ResultsContainer>
                        <PPCRestrictionPopup/>
                    </ResultsContainer>
                }
                {
                    keywordTrackingState.keywordInputModalOpen && 
                    <AddNewPopup 
                        handleSetup={handleSetup}
                        handleClose={closeInputModal}
                    />
                }
                
               { !keywordTrackingState.keywordTableOpen && <Wrapper isNavCollapsed={authState.isNavCollapsed} >
                    <HeadingWrapper>
                        <HeadingLeft>
                            <HeadLeftWrap>
                            <h2>{headingText}</h2>
                            <Tooltip 
                                trigger={['hover']}  
                                style={tooltipStyle}
                                overlayStyle={overlayStyle}
                                overlayInnerStyle={overlayInnerStyle} 
                                showArrow={false}
                                arrowContent={null}
                                overlay={toolTipFunction()}
                            >
                                <HowItWork>Quick guide</HowItWork>
                            </Tooltip>
                            </HeadLeftWrap>
                            <p>{descriptionText}</p>
                        </HeadingLeft>
                        <HeaderRight>
                            <img src={tracking} />
                        </HeaderRight>
                    </HeadingWrapper>
                    <SearchContainer>
                        <InputContainer isFocussed={inputFocussed}>
                            <img src={SearchIcon}/>
                            <KeywordInput
                                value={keywordTrackingState.keywordInput}
                                placeholder='Enter a specific keyword to track' 
                                onChange={(e)=>{
                                    keywordTrackingDispatch({type: 'SET_KEYWORD_INPUT', value: e.target.value})
                                    setError(!e.target.value > 0)
                                }}   
                                onKeyDown={(e) => {
                                    eventEnterHandle(e)
                                }}   
                                onFocus={() => setInputFocussed(true)}
                                onBlur={() => setInputFocussed(false)}
                            />
                            <RightIcons>
                                {keywordTrackingState.keywordInput.length > 0 && <ClearButton  onClick={()=>{keywordTrackingDispatch({type: 'SET_KEYWORD_INPUT', value: ""})}} src={CloseIcon}/>}
                                {error && <ErrorIcon src={errorIcon} alt=""/>}
                            </RightIcons>
                        </InputContainer>
                        <TrackButton onClick={()=>{
                            keywordTrackingDispatch({type: 'SET_KEYWORD_INPUT_LIST', value: [{ keyword: keywordTrackingState.keywordInput, platform: '', country: {}, platformError: '' }]})
                            keywordTrackingDispatch({type: 'SET_KEYWORD_INPUT_MODAL_OPEN', value: true})}}>
                            Track Keyword
                        </TrackButton>
                    </SearchContainer>
                    {error && <ErrorBox>Enter a keyword</ErrorBox>}
                </Wrapper>}
                { keywordTrackingState.keywordTableOpen && 
                <KeywordTable/>
                }
            </Container>
};
    

export default KeywordTracking