import { countryNewData } from "../../../../utils/countryData";
function getCountryByDialingCode(dialingCode) {
  // for (const region of countryNewData) {
  //   for (const country of region.countries) {
  //     if (country.dialling_code === dialingCode) {
  //       return country;
  //     }
  //   }
  // }
  // return null;

  const country = countryNewData.find((c) => c.dial_code === dialingCode);

  if (country) {
    return country;
  }

  return null;
}

export default getCountryByDialingCode;
