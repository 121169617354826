import React from 'react'
import { useReducer, createContext, useContext } from 'react'
import produce from 'immer'

const initialState = {
    domainInput: '',
    domainCountry: {},
    error: '',
    adDistribution:[],
    activeCampaigns: {},
    topDomains:[],
    heatMapData: [],
    relatedKeyword: [],
    domainInputList: [],
    domainInputListError: '',
    domainTableList:[],
    domainTableOpen: false,
    domainInputModalOpen: false,
    domainInputErrorsObj: {

    },
    domainMapData: []
}

const DomainOverviewReducer = produce((draft, action) => {
    switch(action.type) {
        case 'SET_DOMAIN_INPUT':
            draft.domainInput = action.value
            break
        case 'SET_DOMAIN_COUNTRY':
            draft.domainCountry = action.value
            break
        case 'SET_DOMAIN_ERROR':
            draft.error = action.value
            break
        case 'SET_ADDISTRIBUTION_LIST':
            draft.adDistribution = action.value
            break
        case 'SET_RELATED_KEYWORD_INPUT':
            draft.relatedKeyword = action.value
            break
        case 'SET_AD_STATS':
            draft.activeCampaigns = action.value.activeCampaigns
            draft.adDistribution = action.value.adsDistribution
            break
        case 'SET_TOPDOMAINS_LIST':
            draft.topDomains = action.value
            break
        case 'SET_HEATMAP_DATA':
            draft.heatMapData = action.value
            break
        case 'SET_DOMAIN_INPUT_LIST':
            draft.domainInputList = action.value
            break

        case 'SET_DOMAIN_INPUT_LIST_ERROR':
            draft.domainInputListError = action.value
            break
        case 'SET_DOMAIN_TABLE_OPEN':
            draft.domainTableOpen = action.value
            break
        case 'SET_DOMAIN_TABLE_LIST':
            draft.domainTableList = action.value
            break
        case 'SET_DOMAIN_INPUT_MODAL_OPEN':
            draft.domainInputModalOpen = action.value
            break
        case 'SET_DOMAIN_INPUT_ERROR_OBJ':
            draft.domainInputErrorsObj = action.value
            break
        case 'SET_DOMAIN_MAP_DATA':
            draft.domainMapData = action.value
            break
        default:
            break
    }
})

const useDomainOverviewReducer = () => {
    return useReducer(DomainOverviewReducer, initialState)
}

const DomainOverviewContext = createContext()

export const DomainOverviewProvider = ({ children }) => {
    const [state, dispatch] = useDomainOverviewReducer()
    
    const value = {
        domainOverviewState: state,
        domainOverviewDispatch: dispatch
    }
    
    return <DomainOverviewContext.Provider value={value}>
        {children}
    </DomainOverviewContext.Provider>
}

export const useDomainOverview = () => {
    return useContext(DomainOverviewContext)
}
