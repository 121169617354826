import styled from "styled-components";

export const SavedAdsContainer = styled.div`
width: 234px;
height: 232px;
flex-shrink: 0;
border-radius: 8px;
background: #FFF;
padding: 16px 16px 21px 16px;
overflow: hidden;
position: relative;
`;

export const Header = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
margin-bottom: 16px;
`;

export const Title = styled.div`
color: #4F4F4F;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;

export const SavedCount = styled.div`
color: #B7B7B7;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

export const EmptyState = styled.div`
width: 202px;
height: 170px;
flex-shrink: 0;
background: #F5F5F5;
display: flex;
align-items: center;
justify-content: center;
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 16.94px;
text-align: left;
color: #1c1c1c60;
`;

export const AdsGrid = styled.div`
display: grid;
grid-template-columns: 97px 97px;
gap: 8px;
`;



export const AdItem = styled.div`
display: inline-flex;
height: 81.429px;
padding: 6.429px 8.571px;
flex-direction: column;
align-items: flex-start;
gap: 5.357px;
flex-shrink: 0;
border-radius: 2.143px;
background: #FAFBED;
margin-bottom: 2.57px;
overflow: hidden;
position: relative;
cursor: pointer;
`;

export const AdHeader = styled.div`
display: flex;
align-items: flex-end;
gap: 4.286px;
`;

export const AdLogo = styled.img`
width: 8.571px;
height: 8.571px;
`;


export const AdTitle = styled.div`
width: 192.857px;
color: #434343;
font-family: Inter;
font-size: 8.571px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 12.857px */
`;

export const AdDescription = styled.div`
width: 192.857px;
color: rgba(28, 28, 28, 0.50);
font-family: Inter;
font-size: 7.5px;
font-style: normal;
font-weight: 400;
line-height: 11.786px; /* 157.143% */
`;

export const MoreAds = styled.div`
width: 97px;
height: 83px;
position: absolute;
top: 0;
left: 0;
display: flex;
align-items: center;
justify-content: center;
color: #FFF;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
background: rgba(0, 0, 0, 0.49);
border-radius: 2.143px;
cursor: pointer;
`;