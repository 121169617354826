import { BackgroundLayer, Container } from "./index.styled";

function GradientBackground({children}) {
    return (
        <Container>
            <BackgroundLayer>{children}</BackgroundLayer>
        </Container>
    )
}

export default GradientBackground;