import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../../../../../../context/auth";
import { appClient } from "../../../../../../utils/axios-utils";

const TrackingKeywords = (projectId) => {
  const config = {
    url: `/dashboard-overview/competitors/get-tracking-keywords/${projectId}`,
    method: "GET",
  };

  return appClient(config);
};

const TrackingCompetitors = (projectId) => {
  const config = {
    url: `/dashboard-overview/competitors/get-competitor-tracking-domains/${projectId}`,
    method: "GET",
  };

  return appClient(config);
};

export const useTrackingKeywords = () => {
  const { authDispatch } = useAuth()
  const {
      mutate: getTrackingKeywords,
      isLoading: isTrackingKeywordLoading,
  } = useMutation(TrackingKeywords, {
      onSuccess: (data) => {
      authDispatch({ type: "SET_TRACKING_KEYWORDS", value: data.map((k) => k.keyword) });
      },
      onError: (err) => {
      console.log(err);
      },
      onMutate: () => {},
  });

  return { getTrackingKeywords, isTrackingKeywordLoading}
}

export const useTrackingCompetitors = () => {
  const { authDispatch } = useAuth()
  const {
      mutate: getTrackingCompetitors,
      isLoading: isTrackingCompetitorLoading,
  } = useMutation(TrackingCompetitors, {
      onSuccess: (data) => {
      authDispatch({ type: "SET_TRACKING_COMPETITORS", value: data.map((k) => k.domainName) });
      },
      onError: (err) => {
      console.log(err);
      },
      onMutate: () => {},
  });

  return { getTrackingCompetitors, isTrackingCompetitorLoading}
}