import React, { useEffect, useState } from "react";
import statics from "../../../statics";
import { Wrapper, ColorsContainer, Label, ColorItems, ColorItem, Color, UploadImagesContainer, TextInputContainer, TextInputRow, InputWrapper, InputContainer, UploadInputContainer, SelectedDropDown, UploadDropDownItem } from "./CustomizeList.styled"
import { useImageAdGenerator } from "../../../context/imageAdGeneration";
import UploadLogo from "../../../assets/upload.svg"
import PictureLogo from "../../../assets/picture.svg"
import AiLogo from "../../../assets/ai.svg"


const CustomizeList = () => {
    const { colorsList } = statics
    const { imageAdGeneratorState, imageAdGeneratorDispatch } = useImageAdGenerator()
    const [localInputFields, setLocalInputFields] = useState([])
    const [inputFocussed, setInputFocussed] = useState(null)
    const toggleColor = (color) => {
        imageAdGeneratorDispatch({ type: 'SET_ACTIVE_COLOR', value: color })
    }

    const handleInputImageChange = (event, { tagName, uniqueId }) => {

        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    let baseImageData = reader.result
                    let htmlContent = document.querySelector(".final-image")

                    htmlContent.querySelectorAll(`[data-field-id="${uniqueId}"]`).forEach((element) => {

                        if (tagName.toLowerCase() === "img") {
                            element.src = baseImageData
                        } else {
                            element.style.backgroundImage = `url(${baseImageData})`
                        }

                        const modifiedHtml = new XMLSerializer().serializeToString(htmlContent)

                        imageAdGeneratorDispatch({ type: 'UPDATE_HTML', modifiedHtml })
                    })
                }

                reader.readAsDataURL(file);
            }
        }

    }

    const handleInputTextChange = (event, { uniqueId }) => {

        let htmlContent = document.querySelector(".final-image")
        htmlContent.querySelectorAll(`[data-field-id="${uniqueId}"]`).forEach((element) => {
            element.textContent = event.target.value
            const modifiedHtml = new XMLSerializer().serializeToString(htmlContent)

            imageAdGeneratorDispatch({ type: 'UPDATE_HTML', modifiedHtml })
        })
    }

    const renderColors = () => {
        return <ColorsContainer>
            <Label>
                Edit color
            </Label>
            <ColorItems>
                {
                    imageAdGeneratorState.template.variants?.map((color) => {
                        return <ColorItem isActive={imageAdGeneratorState.activeColor == color} onClick={() => toggleColor(color)}>
                            <Color color={color}>

                            </Color>
                        </ColorItem>
                    })
                }
            </ColorItems>
        </ColorsContainer>
    }

    const renderUploadImages = () => {

        const onDropDownSelect = (field, event) => {
            let updatedFields = localInputFields.map(obj => {
                obj = Object.assign({}, obj);
                if (obj.attributeName === field.attributeName && obj.attributeValue === field.attributeValue) {

                    if (obj.hasOwnProperty('dropDownOpen')) {
                        obj.dropDownOpen = !obj.dropDownOpen;
                    } else {
                        obj.dropDownOpen = true;
                    }

                    if (event) {
                        obj.fileName = event.target.files[0].name
                    }

                } else {
                    obj.dropDownOpen = false;
                }
                return obj;
            });

            setLocalInputFields(updatedFields)
        }

        console.log(localInputFields)
        
        return <UploadImagesContainer>
            <Label>
                Edit Images
            </Label>
            {localInputFields.map((field, index) => {
                const isLogoImage = field.attributeValue == "Logo";
                return (field.tagName.toLowerCase() === "img" || field.attributeName === "input-bg") && (
                    <UploadInputContainer key={index}>
                        <h2>
                            {field.attributeValue}
                        </h2>
                        {/* <ImageUploadDropDownContainer> */}
                            <SelectedDropDown onClick={() => onDropDownSelect(field)}>
                            <UploadDropDownItem>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(event) => {
                                            handleInputImageChange(event, field)
                                            onDropDownSelect(field, event)
                                        }}
                                    />
                                    <label for="uploadBtn"><img src={UploadLogo} />Upload</label>
                                </UploadDropDownItem>
                               {!isLogoImage && <UploadDropDownItem className="stockImages" onClick={() => {
                                    onDropDownSelect(field)
                                    imageAdGeneratorDispatch({
                                        type: 'UPDATE_STOCK_IMG_UPLOAD_ELEMENT', value: {
                                            uniqueId: field.uniqueId,
                                            tagName: field.tagName
                                        }
                                    })
                                    imageAdGeneratorDispatch({ type: 'UPDATE_ACTIVE_STOCKIMAGE_POPUP', value: true })
                                }}>
                                    <label for="uploadBtn"><img src={PictureLogo} />Stock images</label>
                                </UploadDropDownItem >}
                                {!isLogoImage && <UploadDropDownItem className="aiClass" onClick={() => {
                                    onDropDownSelect(field)
                                    imageAdGeneratorDispatch({
                                        type: 'UPDATE_STOCK_IMG_UPLOAD_ELEMENT', value: {
                                            uniqueId: field.uniqueId,
                                            tagName: field.tagName
                                        }
                                    })
                                    imageAdGeneratorDispatch({ type: 'UPDATE_ACTIVE_AI_IMAGE_POPUP', value: true })
                                }}>
                                    <label for="uploadBtn"><img src={AiLogo} />AI</label>
                                </UploadDropDownItem>}
                                {/* <p>
                                    <span>{field.fileName ? field.fileName : "Select"}</span>
                                    <img src={DownArrow} />
                                </p> */}
                            </SelectedDropDown>
                            {/* {field.dropDownOpen && <UploadDropDown>
                                <UploadDropDownItem>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(event) => {
                                            handleInputImageChange(event, field)
                                            onDropDownSelect(field, event)
                                        }}
                                    />
                                    <label for="uploadBtn"><img src={UploadLogo} />Upload</label>
                                </UploadDropDownItem>
                                {!isLogoImage && <UploadDropDownItem onClick={() => {
                                    onDropDownSelect(field)
                                    imageAdGeneratorDispatch({
                                        type: 'UPDATE_STOCK_IMG_UPLOAD_ELEMENT', value: {
                                            uniqueId: field.uniqueId,
                                            tagName: field.tagName
                                        }
                                    })
                                    imageAdGeneratorDispatch({ type: 'UPDATE_ACTIVE_STOCKIMAGE_POPUP', value: true })
                                }}>
                                    <label for="uploadBtn"><img src={PictureLogo} />Stock images</label>
                                </UploadDropDownItem >}
                                {!isLogoImage && <UploadDropDownItem onClick={() => {
                                    onDropDownSelect(field)
                                    imageAdGeneratorDispatch({
                                        type: 'UPDATE_STOCK_IMG_UPLOAD_ELEMENT', value: {
                                            uniqueId: field.uniqueId,
                                            tagName: field.tagName
                                        }
                                    })
                                    imageAdGeneratorDispatch({ type: 'UPDATE_ACTIVE_AI_IMAGE_POPUP', value: true })
                                }}>
                                    <label for="uploadBtn"><img src={AiLogo} />AI images</label>
                                </UploadDropDownItem>}
                            </UploadDropDown>} */}
                        {/* </ImageUploadDropDownContainer> */}
                    </UploadInputContainer>
                )
                // </InputContainer> activeCustomAiImage
            })}
        </UploadImagesContainer>
    }

    const renderTextInputs = () => {
        const textArray = imageAdGeneratorState.inputFields;

        return <TextInputContainer>
            <Label>
                Edit Text
            </Label>
            {textArray.map((field, index) => (
                <InputContainer key={index}>
                    {(field.tagName?.toLowerCase() === "img" || field.attributeName === "input-bg") ? "" : (
                        <>
                            <TextInputRow>
                                <label>
                                    {field.attributeValue}
                                </label>
                                <InputWrapper isFocussed={inputFocussed === field.attributeValue} >
                                    <textarea
                                        placeholder="Enter text here"
                                        type="text"
                                        defaultValue={field.result}
                                        // maxLength={field.maxCharacters}
                                        onChange={(event) => handleInputTextChange(event, field)}
                                        onFocus={() => setInputFocussed(field.attributeValue)}
                                        onBlur={() => setInputFocussed(null)}
                                    />
                                    {/* <CharLength>
                                        00/34
                                    </CharLength> */}
                                </InputWrapper>
                            </TextInputRow>
                        </>
                    )}
                </InputContainer>
            ))}
        </TextInputContainer>
    }

    useEffect(() => {
        setLocalInputFields(imageAdGeneratorState.inputFields)
    }, [imageAdGeneratorState.inputFields])

    return <Wrapper>
        {renderColors()}
        {renderUploadImages()}
        {renderTextInputs()}
    </Wrapper>
}

export default CustomizeList