import styled from 'styled-components'

export const Container = styled.div`
    .heatmap-cell{
        height: 16px;
        width: 16px;
        cursor: pointer;
    }
`;  


export const XaxisTimeContainer = styled.div`
    width: 350px;
    display: flex;
    justify-content: space-between;
    margin-left: 34px;
    margin-top: 15px;
    p{
        font-size: 12px;
        color: #777777;
    }
`;