import { useEffect, useRef } from "react";
import { AddIconContainer, AddNew, AddText, Desc, Description, Details, DetailsDropdown, Icon, Image, Item, Logo, LogoContainer, MoreDetails, Pinned, PrimaryDetails, PrimaryText, ProjectCard, Projects, RootContainer, Row, SecondaryDetails, SecondaryText, Title } from "./index.styled";
import { useManageProjects } from "../context/manageProjects";
import add from "../assets/add.svg"
import task from "../assets/task.svg"
import details from "../assets/details.svg"
import pinned from "../assets/pinned.svg"

import calender from "../assets/calender.svg"
import { appClient } from "../../../../utils/axios-utils"
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../../../../context/auth";
import { useNavigate } from "react-router-dom";
import Popup from "./popup";
import { getStaticLogo } from "../../Overview/utils";
import { useGetUserInfo } from "../../../hooks/useGetUserInfo";

export function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    const parts = formattedDate.split(' ');
    const day = parts[1].startsWith('0') ? parts[1].slice(1) : parts[1];
    return `${day} ${parts[0]} ${parts[2]}`;
}

function Root() {
    const { manageProjectsState: { projects, isPopupActive }, manageProjectsDispatch } = useManageProjects()
    const { authState: { username, defaultProjectInfo, activeProjectInfo }, authDispatch } = useAuth()
    const { getCurrentUser } = useGetUserInfo()
    const dropdownRefs = useRef([])
    const projectNameRefs = useRef([])
    const selectedProjectRef = useRef(null)
    const navigate = useNavigate()
    const getProjects = () => {
        const config = {
            url: `/dashboard-overview/client-profile/get-projects`,
            method: "GET",
        }
        return appClient(config)
    }

    const changeProjectName = (data) => {
        const config = {
            url: `/dashboard-overview/client-profile/update-project-name`,
            method: "POST",
            data
        }
        return appClient(config)
    }

    const pinProject = (data) => {
        const config = {
            url: `/dashboard-overview/client-profile/pin-project`,
            method: "POST",
            data
        }
        return appClient(config)
    }

    const { mutate: getProjectsMutate } = useMutation(getProjects, {
        onSuccess: (data) => {
            manageProjectsDispatch({
                type: "SET_PROJECTS",
                value: data.projects
            })
            const pinnedProject = data.projects.find((project) => project.pinned)
            console.log('pinned or default', pinnedProject)
            authDispatch({
                type: "UPDATE_DEFAULT_PROJECT_INFO",
                value: {
                    projectId: pinnedProject.projectId,
                    projectName: pinnedProject.projectName,
                    website: pinnedProject.website
                }
            })
        },
        onError: (e) => console.log(e)
    })

    const { mutate: pinProjectMutate, isLoading: isPinProjectLoading } = useMutation(pinProject, {
        onSuccess: (data) => {
            console.log(data)
            // getProjectsMutate()
            navigate('/overview')
        },
        onError: (e) => console.log(e)
    })

    const { mutate: changeProjectNameMutate } = useMutation(changeProjectName, {
        onSuccess: (data) => {
            getProjectsMutate()
            authDispatch({
                type: "UPDATE_ACTIVE_PROJECT_INFO",
                value: {
                    projectId: data.id,
                    projectName: data.projectName,
                    website: data.website
                }
            })
        },
        onError: (e) => console.log(e)
    })

    const handleProjectClick = (selectedProject, idx) => {
        pinProjectMutate({ projectId: selectedProject.id })
        selectedProjectRef.current = selectedProject
        authDispatch({
            type: "UPDATE_DEFAULT_PROJECT_INFO",
            value: {
                projectId: selectedProject.id,
                projectName: selectedProject.projectName,
                website: selectedProject.website
            }
        })
    }

    const openDetailsDropdown = (e, idx) => {
        e.stopPropagation()
        dropdownRefs.current[idx].style.display = "unset"
    }

    const closeDetailsDropdown = (idx) => {
        dropdownRefs.current[idx].style.display = "none"
    }

    const closeAllDetailsDropdown = () => {
        dropdownRefs.current.map((ref) => ref.style.display = "none")
    }

    const handleEdit = (e, idx) => {
        e.stopPropagation()
        closeDetailsDropdown(idx)
        projectNameRefs.current[idx].select()
    }

    const handlePin = (e, projectId, idx) => {
        e.stopPropagation()
        closeDetailsDropdown(idx)
        pinProjectMutate({ projectId })
    }

    const activatePopup = () => {

        manageProjectsDispatch({
            type: "SET_POPUP_ACTIVE",
            value: true
        })
    }

    const handleProjectName = (e,idx) => {
        projectNameRefs.current[idx].value = e.target.value
    }

    const handleProjectNameBlur = (project, idx) => {
        if(project.projectName !== projectNameRefs.current[idx].value){
            const data = {
                projectId: project.id,
                projectName: projectNameRefs.current[idx].value
            }

            changeProjectNameMutate(data)
            return
        }
    }

    useEffect(() => {
        getProjectsMutate()
    }, [])

    useEffect(() => {
        if(selectedProjectRef.current && selectedProjectRef.current.id === activeProjectInfo.projectId){
            navigate(`/overview`)
        }
    }, [activeProjectInfo])

    useEffect(() => {
        projects?.forEach((element, idx) => {
            if(projectNameRefs.current[idx]){
                projectNameRefs.current[idx].value = element.projectName
            }
        });
    },[projects, projectNameRefs])

    return (
        <RootContainer>
            <Title>{`Hello ${username}!`}</Title>
            <Description>Access your marketing tools efficiently!</Description>
            <Projects onClick={closeAllDetailsDropdown} >
                <AddNew onClick={activatePopup} >
                    <AddIconContainer>
                        <Icon src={add} alt="add" />
                    </AddIconContainer>
                    <AddText>Add new project</AddText>
                    <Desc>Looking for more? Click on a title to start a new project!</Desc>
                </AddNew>
                {projects && projects.map((project, idx) => {
                    return (
                        <ProjectCard key={project.id} onClick={() => handleProjectClick(project, idx)} >
                            <MoreDetails onClick={(e) => openDetailsDropdown(e, idx)} src={details} alt="details" />
                            <DetailsDropdown
                                ref={el => dropdownRefs.current[idx] = el}
                            >
                                {/* <Item onClick={(e) => handlePin(e, project.id, idx)} key={1}>
                                    {`Make it default`}
                                </Item> */}
                                <Item onClick={(e) => handleEdit(e, idx)} key={2}>
                                    {`Edit Project name`}
                                </Item>
                            </DetailsDropdown>
                            {project.pinned && <Pinned src={pinned} alt="pinned" />}

                            <LogoContainer>
                                <Logo src={project.logo ?? getStaticLogo(project.website)} alt="add" />
                            </LogoContainer>
                            <Details>
                                <PrimaryDetails>
                                    <PrimaryText 
                                        ref={el => projectNameRefs.current[idx] = el} 
                                        onChange={(e) => handleProjectName(e,idx)}
                                        onClick={(e) => e.stopPropagation()}
                                        onBlur={(e) => handleProjectNameBlur(project,idx)}
                                    />
                                    <SecondaryText style={{ color: "#7BAE86" }} >{project.website}</SecondaryText>
                                </PrimaryDetails>
                                <SecondaryDetails>
                                    <Row>
                                        <Image src={task} alt="task" />
                                        <SecondaryText>{`${project.savedAdsCount ?? 10} saved ads`}</SecondaryText>
                                    </Row>
                                    <Row>
                                        <Image src={calender} alt="calender" />
                                        <SecondaryText>{`Created on : ${formatDate(project.createdAt)}`}</SecondaryText>
                                    </Row>
                                </SecondaryDetails>
                            </Details>

                        </ProjectCard>
                    )
                })}
            </Projects>
            <Popup getProjectsMutate={getProjectsMutate} />
        </RootContainer>
    )
}

export default Root