import styled from "styled-components";

export const Container = styled.div`
    padding: 20px;
    width: 100%;
    overflow-y: auto;
    position: relative;
    height: 100%;
    h1{
        font-size: 18px;
        font-weight: 500;
        color: #2E2E2E;
        padding: 20px 4px;
        margin: 0;
        display: flex;
        align-items: center;
        span{
            color: #808080;
            font-weight: 300;
            text-decoration: underline;
            margin-left: 8px;
        }
    }
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const TrackingDomainsList = styled.div`
    width: fit-content;
`;

export const CardContainer = styled.div`

`;

export const WebsiteBasic = styled.div`

`;

export const WebsiteSnap = styled.div`

`;

export const WebsiteName = styled.div`
    margin-left: 12px;
    h2{
        color: #2E2E2E;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
    }
    p{
        color: #808080;
        font-size: 14px;
        font-weight: 300;
        line-height: normal;
    }
`;

export const CardHeader = styled.div`
    height: 48px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
`;

export const Options = styled.div`
    position: relative;
    img{
        border-radius: 4px;
        cursor: pointer;
        :hover{
            background: #ECECEC;
        }
    }
`;

export const DropDown = styled.div`
    position: absolute;
    width: 144px;
    height: 72px;
    background: #FFFFFF;
    padding: 4px;
    box-shadow: 0px 2px 6px #0000003D;
    border-radius: 8px;
    display: ${props => props.open ? "unset" : "none"};
    top: 30px;
    right: 0px;
`;

export const AnalyseButton = styled.div`
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border-radius: 4px;
    color: #2E2E2E;
    font-weight: 300;
    cursor: pointer;
    &:hover{
        background: #F6F6F6;
    }
    &:active{
        background: #EFEFEF ;
    }
`;

export const RemoveButton = styled.div`
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #2E2E2E;
    font-weight: 300;
    cursor: pointer;
    &:hover{
        background: #F6F6F6;
    }
    &:active{
        background: #EFEFEF ;
    }
`;

export const DomainCount = styled.div`
    border-radius: 8px;
    background: #EFEFEF;
    color: #595959;
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    padding: 5px 8px;
    margin-right: 24px;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px;
    h1{
        padding: 0px;
        margin-right: 8px;
    }
`;

export const HeaderLeft = styled.div`
    display: flex;
    align-items: center;
`;

export const AddDomain = styled.div`
    border-radius: 8px;
    background: #0A66C2;
    color: #FFF;
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    cursor: pointer;
    padding: 6px 12px;
    &:hover{
        background: #0059B2;
    }

    &:active{
        background: #0052A3;
    }
`;

export const ResultsContainer = styled.div`
    display: flex;
    gap: 12px;
    height: calc(100vh - 245px);
    background: #006ecc00;
    position: absolute;
    left: 0;
    top: 0;
    .loader{
        height: 200px;
        margin: auto;
    }
`;