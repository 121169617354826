import ImageAd from "./sub-components/ImageAd";
import { BannersContainer } from "./index.styled";
import TextAd from "./sub-components/TextAd";

function PromotionBanners() {
    return ( 
        <BannersContainer>
            <ImageAd />
            <TextAd />
        </BannersContainer>
     );
}

export default PromotionBanners;