import styled from "styled-components";

export const Container = styled.div`
    padding: 36px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const KeywordContainer = styled.div`
    
`;

export const KeywordContent = styled.div`
    label{
        font-size: 14px;
        font-weight: 400;
        color: rgba(28, 28, 28, 0.50);
        line-height: 150%;
    }
`;

export const InputKeywordContainer = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 12px;
    input{
        border-radius: 6px;
        border: 1px solid #DADFE4;
        background: #FFF;
        padding: 12px 16px;  
        width: 70%;
        max-width: 470px; 
    }
`;

export const MatchTypeDropDownContainer = styled.div`
    width: 186px;
    height: 44px;
    position: relative;
`;

export const SelectedMatchType = styled.div`
    height: 100%;
    width: 100%;
    padding: 12px 16px;
    border-radius: 4px;
    border: 1px solid #E3E8EE;
    background: #F7F7F9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: rgba(28, 28, 28, 0.70);
    cursor: pointer;
    img{
        transform: ${props => props.rotateImg ? "rotate(180deg)" : ""};
    }
`;

export const MatchDropDown = styled.div`
    position: absolute;
    border-radius: 0px 0px 12px 12px;
    border: 1px solid #E3E8EE;
    background: #FFF;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.15);
    z-index: 2;
    width: 100%;
    top: 48px;
`;

export const MatchType = styled.div`
    height: 44px;
    padding: 12px 16px;
    border-bottom: solid 1px #E3E8EE;
    cursor: pointer;
`;

export const AddKeyword = styled.div`
color: #1679DB;
font-family: Inter;
font-size: 16px;
font-weight: 500;
cursor: pointer;
`;