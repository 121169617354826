import React from "react";

import {  
    CardFooter,
    CardBody,
    Card,
    VideoContainer,
    VideoTitle,
    VideoDescription,
    Header,
    LogoWrapper,
    TitleWrap,
    CountryWrap,
    Country,
    ChannelDiv,
    HoverCardWrapper,
    AdDetailsButton,
    HoverCard} from "./index.styled"

import WebsiteIcon from '../../../../../../media/Dashboard/websiteicon.png'
import RightUpperBlackArrow from "../../../../../../media/Dashboard/RightUpperBlackArrow.svg"
import locationImg from '../../../../../../media/Dashboard/locationCard.svg'

import { useSavedAds } from "../../../context/savedads";

const YoutubeCard = ({ ad }) => {
    const { savedAdsDispatch } = useSavedAds()

    const convertTimestamp = (timestamp) => {
        // Create a new Date object based on the timestamp
        var date = new Date(timestamp);

        // Get the month name
        var monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        var month = monthNames[date.getMonth()];

        // Get the year
        var year = date.getFullYear();

        // Assemble the formatted date string
        var formattedDate = date.getDate() + ' ' + month + ' ' + year;

        return formattedDate;
    }

    const toggleDetailsModal = (ad) => {
        savedAdsDispatch({type: 'SET_SELECTED_AD_FOR_DETAIL', value: ad})
        savedAdsDispatch({ type: 'UPDATE_DETAILS_MODAL', value: true })
    }

    return <Card key={
        ad.id
    } onClick={() => toggleDetailsModal(ad)}>
        <HoverCard className='hover-element' >
            <HoverCardWrapper>
                <AdDetailsButton onClick={() => toggleDetailsModal(ad)}>
                    <img src={RightUpperBlackArrow} />
                    <span>Ad Details</span>
                </AdDetailsButton>
            </HoverCardWrapper>
        </HoverCard>
        <VideoContainer>
            <iframe width="100%" height="190" style={{ borderRadius: "11px 11px 0px 0px", border: "none" }}
                src={`https://www.youtube.com/embed/${ad.videoId}`} controls="0">
            </iframe>
        </VideoContainer>

        <CardBody>
            <Header>
                <LogoWrapper>
                    <img src={WebsiteIcon} />
                </LogoWrapper>
                <TitleWrap>
                    <VideoTitle>{ad.title}</VideoTitle>
                    <ChannelDiv>{ad.channel}</ChannelDiv>
                </TitleWrap>
            </Header>
            <VideoDescription>
                {ad.content}
            </VideoDescription>
        </CardBody>
        <CardFooter>
            {ad.country && <CountryWrap>
                <img src={locationImg} />
                <Country>{ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}</Country>
            </CountryWrap>}
        </CardFooter>
    </Card>
}

export default YoutubeCard