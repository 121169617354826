import React from 'react'
import { useReducer, createContext, useContext } from 'react'

import produce from 'immer'

const initialState = {

    searchUrl: '',
    searchMode: 'domain',
    domain: '',

    error: false,
    isValidUrl: false,

    selectedYear: '',
    selectedMonth: '',
    selectedDay: '',

    showDomainsList: false,

    domainUrls: [],
    selectedUrl: '',
    selectedSimilarUrl: '',
 
    adsDistribution: [],
    activeCampaigns: {
        totalCampaigns: 0,
        totalCampaignAds: 0
    },
    mapdata:[],
    mapTableData: [],
    keywordStats: [],
    domainKeywords: [],

    adCopyPlatform: '',
    ads: [],
    noResults: false,
    loadingResults: false,
    adDetails: {

    },

    queryId: '',
    detailsModal: false,

    heatmapData:[],


    ///
    currentPage: 'calender',
    currentDetailsType: 'landingpage',
    collapseCalender: false,
    isScreenshotLoading: false,
    screenShotUrl: '',
    screenshotFullPage: false,

    screenshotDate: '',


    ///
    adsAvailability: {
        google: false,
        googleEcom: false,
        bing: false,
        bingEcom: false,
        display: false,
        facebook: false
    },
    noAds: false,
    fetchScreenshotError: 'Requested Url not found',









    urlInput: '',
    urlInputList: [],
    urlPlatformList: [],
    urlInputListError: '',
    urlInputErrorsObj: {},
    keywordList: [],
    landingTable: [],

    dataCaptured: false,
    landingTableOpen: false,
    urlInputModalOpen: false,
    toggleCopies: false,
    selectedDate: '',
    platformDropdown: false,
    filterState: false,
    eventDateList: [
        {
          name: "Black friday 2023",
          date: "2023-11-24"
        },
        {
          name: "Pongal 2023",
          date: "2023-01-16"
        }
    ]
}

const LandingPageAnalysisReducer = produce((draft, action) => {
    switch(action.type) {
        case 'SET_CURRENT_PAGE':
            draft.currentPage = action.value
            break
        
        case 'SET_SELECTED_YEAR':
            draft.selectedYear = action.value
            break

        case 'SET_CURRENT_DETAILS_TYPE':
            draft.currentDetailsType = action.value
            break

        case 'SET_SELECTED_MONTH':
            draft.selectedMonth = action.value
            break

        case 'SET_SELECTED_DAY':
            draft.selectedDay = action.value
            break

        case 'SET_SEARCH_URL':
            draft.searchUrl = action.value
            break
        case 'SET_SEARCH_MODE':
            draft.searchMode = action.value
            break
        case 'SET_ERROR':
            draft.error = action.value
            break
        case 'SET_IS_VALID_URL':
            draft.isValidUrl = action.value
            break

        case 'SET_SHOW_DOMAINS_LIST':
            draft.showDomainsList = action.value
            break
        case 'SET_DOMAIN_NAME':
            draft.domain = action.value
            break

        case 'SET_IS_SCREENSHOT_LOADING':
            draft.isScreenshotLoading = action.value
            break

        case 'SET_SCREENSHOT_URL':
            draft.screenShotUrl = action.value
            break

        case 'SET_SCREENSHOT_FULL_PAGE':
            draft.screenshotFullPage = action.value
            break

        case 'SET_SCREENSHOT_DATE':
            draft.screenshotDate = action.value
            break
            
        case 'SET_CURRENT_DETAIL_SECTION':
            draft.currentDetailSection = action.value
            break

        case 'SET_AD_COPIES':
            draft.ads = action.value
            break

        case 'UPDATE_LOADING_RESULTS':
            draft.loadingResults = action.value
            break

        case 'UPDATE_NO_RESULTS':
            draft.noResults = action.value
            break

        case 'UPDATE_DETAILS_MODAL':
            draft.detailsModal = action.value
            break

        case 'UPDATE_COLLAPSE_CALENDER':
            draft.collapseCalender = action.value
            break
        
        case 'SET_AD_COPY_PLATFORM':
            draft.adCopyPlatform = action.value
            break
        case 'SET_TOGGLE_COPIES':
            draft.toggleCopies = action.value
            break
        case 'SET_AD_DETAILS':
            draft.adDetails = action.value
            break
        
        case 'SET_ADS_AVAILABILITY':
            draft.adsAvailability = {
                google: action.value.google,
                googleEcom: action.value.googleEcom,
                bing: action.value.bing,
                bingEcom: action.value.bingEcom,
                display: action.value.display,
                meta: action.value.meta
            }
            break

        case 'UPDATE_NO_ADS':
            draft.noAds = action.value
            break

        case 'SET_RESET_URL_ANALYSIS':
            draft.searchUrl = ''
            draft.domain = ''

            draft.error = false
            draft.isValidUrl = false

            draft.showDomainsList = false

            draft.domainUrls = []
            draft.selectedUrl = ''
            draft.selectedSimilarUrl = ''

            draft.urlDomains = {

            }
            break
          
        case 'SET_FETCH_SCREENSHOT_ERROR':
            draft.fetchScreenshotError = action.value
            break







        case 'SET_URL_INPUT':
            draft.urlInput = action.value
            break
        case 'SET_URL_INPUT_ERROR_OBJ':
            draft.urlInputErrorsObj = action.value
            break
        case 'SET_PLATFORM_DROPDOWN':
            draft.platformDropdown = action.value
            break
        case 'SET_FILTER_STATE':
            draft.filterState = action.value
            break
        case 'SET_URL_INPUT_LIST':
            draft.urlInputList = action.value
            break
        case 'SET_URL_PLATFORM_LIST':
            draft.urlPlatformList = action.value
            break
        case 'SET_URL_INPUT_LIST_ERROR':
            draft.urlInputListError = action.value
            break

        case 'SET_URL_LIST':
            draft.urlList = action.value
            break
        case 'SET_LANDING_TABLE_OPEN':
            draft.landingTableOpen = action.value
            break
        case 'SET_LANDING_TABLE_LIST':
            draft.landingTable = action.value
            break
        case 'SET_URL_INPUT_MODAL_OPEN':
            draft.urlInputModalOpen = action.value
            break

        case 'SET_SELECTED_DATE':
            draft.selectedDate = action.value
            break
        case 'EVENT_LIST':
            draft.eventDateList = action.value
            break

        case 'SET_DATA_CAPTURED':
            draft.dataCaptured = action.value
            break
        default:
            break
    }
})

const useLandingPageAnalysisReducer = () => {
    return useReducer(LandingPageAnalysisReducer, initialState)
}

const LandingPageAnalysisContext = createContext()

export const LandingPageAnalysisProvider = ({ children }) => {
    const [state, dispatch] = useLandingPageAnalysisReducer()
    
    const value = {
        landingPageAnalysisState: state,
        landingPageAnalysisDispatch: dispatch
    }
    
    return <LandingPageAnalysisContext.Provider value={value}>
        {children}
    </LandingPageAnalysisContext.Provider>
}

export const useLandingPageAnalysis = () => {
    return useContext(LandingPageAnalysisContext)
}
