import React from 'react'
import { Container, EditWrap, ProductName, ProductNameWrap, ProductWrap, RefreshWrap, TrackingDiv, UpdatedDiv, UpdatedWrap, BackButton, TrackKeywordButton, ResultsContainer } from './index.styled'
import refresh from '../../../../../../media/Dashboard/refresh.svg'
import { useKeywordOverview } from '../../../context/keywordOverview'
import LeftArrow from "../../../../../../media/Dashboard/backarrow.svg"
import CrossHair from "../../../../../../media/Dashboard/crosshair.svg"
import CountryDropDown from '../CountryDropDown'
import KeywordInputModal from './keywordInputModal/index'
import { useNavigate } from 'react-router-dom'

const Tracking = () => {

  const { keywordOverviewState, keywordOverviewDispatch } = useKeywordOverview()
  const navigate = useNavigate()

  return (
    <Container>
       {
          keywordOverviewState.keywordInputModalOpen && 
          <ResultsContainer>
              <KeywordInputModal/>
          </ResultsContainer>
        }
    <EditWrap>
      <BackButton src={LeftArrow} onClick={() => navigate('/keyword-overview')}/>
      <TrackingDiv>
        <CountryDropDown/>
        <TrackKeywordButton onClick={()=> {
          navigate('/keyword-tracking')
          // keywordOverviewDispatch({type: 'SET_KEYWORD_INPUT_LIST', value:  [{ 
          //   keyword: keywordOverviewState.overviewInput, platform: '', country: keywordOverviewState.overviewCountry, platformError: '' }]})

          //   keywordOverviewDispatch({type: 'SET_KEYWORD_INPUT_MODAL_OPEN', value: true})

        }}>
          <img src={CrossHair}/>Track Keyword
        </TrackKeywordButton>
      </TrackingDiv>
    </EditWrap>
    <ProductWrap>
      <ProductNameWrap>
        <ProductName>{keywordOverviewState.overviewInput}</ProductName>
        <UpdatedWrap>
          <RefreshWrap><img src={refresh}/></RefreshWrap>
          <UpdatedDiv>updated recently</UpdatedDiv>
        </UpdatedWrap>
      </ProductNameWrap>
    </ProductWrap>
    </Container>
  )
}

export default Tracking