import styled from "styled-components";

export const Container = styled.div`
    
`;

export const SearchContainer = styled.div`
    width: 185px;
    height: 28px;
    border-radius: 4px;
    border: 1px solid #DADFE4;
    background: #FFF;
    display: flex;
    align-items: center;
    padding: 6px 10px;
    img{
        height: 16px;
        width: 16px;
        margin-right: 8px;
    }
    input{
        width: 100%;
        outline: none;
        border: none;
        font-size: 13px;
        font-weight: 300;
        line-height: 20px;
        letter-spacing: 0.13px;
        /* color: rgba(28, 28, 28, 0.40); */
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
    padding: 8px 16px 12px;
    align-items: center;
`;

export const ClearAll = styled.div`
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    color: #9D9D9D;
    cursor: pointer;
`;

export const Content = styled.div`
    height: 140px;
    overflow-y: auto;
`;

export const RegionSection = styled.div`
    
`;

export const LeftLine = styled.div`
    border-left: 1px solid #DADFE4;
    position: absolute;
    height: calc(100% + 15px);
    top: -15px;
    left: 22px;
`;

export const RegionSectionHeader = styled.div`
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    p{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        color: #1C1C1C;
    }
`;

export const Checkbox = styled.input`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
`;

export const SubCheckbox = styled.input`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
`;

export const RegionSectionContent = styled.div`
    position: relative;
`;

export const RegionSectionContentWrapper = styled.div`
    padding: 0px 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;
`;

export const CheckBoxContainer = styled.div`
    cursor: pointer;
    p{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        color: #1C1C1C;
    }
`;