import React from 'react'
import { useReducer, createContext, useContext } from 'react'

import produce from 'immer'

import statics from '../statics'

const initialState = {
    currentPage: statics.pages[0],
    targetDomain: null,
    adGoal: null,
    targetLocation: [],
    brandDescription: null,
    seedKeywords: null,
    seedKeywordsList: [],
    generatingProcesses: statics.processes,
    isResultGenerated: false,
    activeAdTemplate: null,
    activeNavItem: statics.navItems[0],
    activeCustomizePopup: null,
    isAdDetailsUpdated: false,
    isAddDomainList: null,
    isAdGroupData: [],
    activeCustomizeNavItem: statics.customizeNavItems[1],
    getWentWrong: false,
    openedLocationSuggestion: false,
    locationsList: [],
    updatedTargetLocation: []
}

const textAdGeneratorReducer = produce((draft, action) => {
    switch(action.type) {
        case "SET_TARGET_DOMAIN":
            draft.targetDomain = action.value
        break

        case "UPDATE_CURRENT_PAGE": 
            draft.currentPage = action.value
        break

        case "SET_AD_GOAL": 
            draft.adGoal = action.value
        break

        case "SET_TARGET_LOCATION": 
            draft.targetLocation = action.value
        break

        case "SET_BRAND_DESCRIPTION": 
            draft.brandDescription = action.value
        break

        case "SET_SEED_KEYWORDS": 
            draft.seedKeywords = action.value
        break

        case "UPDATE_SEED_KEYWORDS":
            draft.seedKeywordsList = action.value
            break
            
        case "ADD_SEED_KEYWORD":
            draft.seedKeywordsList.push(action.value)
            // if(draft.seedKeywordsList)
            //     draft.seedKeywordsList.push(action.value)
            // else draft.seedKeywordsList = [action.value]
        break

        case "REMOVE_SEED_KEYWORD":
            draft.seedKeywordsList.splice(action.value.index, 1)
        break

        case "UPDATE_PROCESSING_STATUS":   
            draft.generatingProcesses[action.value.id].isCompleted = action.value.value
        break

        case "UPDATE_RESULT_GENERATED_FLAG":
            draft.isResultGenerated = action.value
        break

        case "SET_ACTIVE_AD_TEMPLATE":
            draft.activeAdTemplate = action.value
        break

        case "SET_ACTIVE_NAV_ITEM":
            draft.activeNavItem = action.value
        break

        case "UPDATE_ACTIVE_CUSTOMIZE_POPUP":
            draft.activeCustomizePopup = action.value
        break

        case "SET_AD_DETAILS_UPDATED":
            draft.isAdDetailsUpdated = action.value
        break
        case "SET_ADD_DOMAIN_LIST":
            draft.isAddDomainList = action.value
        break
        case "SET_ADGROUPS_DATA":
            draft.isAdGroupData = action.value
        break
        case "SET_ACTIVE_CUSTOMIZE_NAV_ITEM":
            draft.activeCustomizeNavItem = action.value
        break
  
        case "SET_WENT_WRONG":
            draft.getWentWrong = action.value
        break
        case "SET_OPENED_LOCATION_SUGGESTION":
            draft.openedLocationSuggestion = action.value
        break
        case "SET_LOCATIONS_LIST":
            draft.locationsList = action.value
        break

        case "SET_UPDATED_TARGET_LOCATION": 
            draft.updatedTargetLocation = action.value
        break

        default: 
        break
    }
})

const useTextAdGeneratorReducer = () => {
    return useReducer(textAdGeneratorReducer, initialState)
}

const TextAdGeneratorContext = createContext()

export const TextAdGeneratorProvider = ({children}) => {
    const [state, dispatch] = useTextAdGeneratorReducer()
    const value = { textAdGeneratorState: state, textAdGeneratorDispatch: dispatch }

    return <TextAdGeneratorContext.Provider value={value}>
        {children}
    </TextAdGeneratorContext.Provider>
}

export const useTextAdGenerator = () => {
    return useContext(TextAdGeneratorContext)
}