import React from 'react'
import { useSearchParams } from 'react-router-dom'

// Styles
import {
    PaginationContainer
} from "./index.styled"

import {useFacebook} from "../../context/facebook"
import ReactPaginate from 'react-paginate';


const Pagination = () => {
    const {facebookState, facebookDispatch} = useFacebook()
    const [searchParams, setSearchParams] = useSearchParams()

    const handleSpecificPage = (e) => {
        facebookDispatch({type:'SET_AD_DETAILS', value: {}})
        facebookDispatch({type:'UPDATE_DETAILS_MODAL', value: false})
        facebookDispatch({ type: "SET_CURRENT_PAGE_NO", value: e.selected + 1 })
        searchParams.set("page", e.selected + 1)
        setSearchParams(searchParams)
    }
    
    return <PaginationContainer 
    prevState={facebookState.currentPageNo == 1}
    nextState={facebookState.currentPageNo == Math.ceil((facebookState.totalAds / 20))}>
        <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            forcePage={facebookState.currentPageNo - 1}
            onPageChange={handleSpecificPage}
            pageRangeDisplayed={3}
            pageCount={(facebookState.totalAds / 20)}
            previousLabel="<"
            renderOnZeroPageCount={null}
        />
  </PaginationContainer>

}

export default Pagination
