import { Helmet } from "react-helmet";
import DashboardLayout from "../../layout";
import Root from "./components";
import { ManageProjectsProvider } from "./context/manageProjects";
import { ManageProjectsContainer } from "./index.styled";

const ManageProjectsComponent = () => {

    return (
        <DashboardLayout>
            <ManageProjectsContainer>
                <Root />
            </ManageProjectsContainer>
        </DashboardLayout>
    )
}

function ManageProjectsProviderWrapper() {
    return (
        <ManageProjectsProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title> Manage Projects | AdSpyder</title>
                <meta name="title" content="Project Manage Projects | AdSpyder" />
                <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
            </Helmet>
            <ManageProjectsComponent />
        </ManageProjectsProvider>
    );
}

export default ManageProjectsProviderWrapper;