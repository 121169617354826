import { CopyButton, TitleContainer, TitlesListContainer, Title } from "./TitleList.styled";
import copy from "../../../assets/copy.svg"
import { useTextAdGenerator } from "../../../context/textAdGeneration";
import { useRef } from "react";
import { customToast } from "../../../../../../../utils/toast-utils";

function TitlesList({fromOverview}) {
    const { textAdGeneratorState, textAdGeneratorDispatch } = useTextAdGenerator()
    const { activeAdTemplate } = textAdGeneratorState
    const copyRefs = useRef([])
    const handleMouseEnter = (idx) => {
        const copyRef = copyRefs.current[idx];
        if (copyRef) {
            copyRef.style.visibility = "unset";
        }
    }

    const handleMouseLeave = (idx) => {
        const copyRef = copyRefs.current[idx];
        if (copyRef) {
            copyRef.style.visibility = "hidden";
        }
    }

    const handleTitleClick = (title) => {
        navigator.clipboard.writeText(title).then(() => {
            customToast({message: "Selected title copied to your clipboard"})
        }).catch((err) => {
            console.error('Failed to copy:', err);
        });
    }
    return ( 
        <TitlesListContainer fromOverview={fromOverview}>
            { activeAdTemplate?.titles?.map((title, idx) => {
                return(
                    <TitleContainer key={idx} onMouseEnter={() => handleMouseEnter(idx)} onMouseLeave={() => handleMouseLeave(idx)} onClick={() => handleTitleClick(title)}>
                        <Title>{`${idx + 1}. ${title}`}</Title>
                        <CopyButton ref={(el) => (copyRefs.current[idx] = el)} src={copy} alt="copy" />
                    </TitleContainer>
                )
            }) }    
        </TitlesListContainer>
     );
}

export default TitlesList;