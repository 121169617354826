import styled from "styled-components";

export const DetailsWrapper = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
`;

export const Details = styled.div`
  color: #000;
  font-size: 24px;
  font-weight: 600;
  line-height: 38px; /* 158.333% */
`;

export const OptionWrap = styled.div`
  border-radius: var(--8, 8px);
  background: #fff;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
`;

export const LevelDiv = styled.div`
  display: flex;
  gap: 16px;
`;
export const OptionList = styled.div`
  border-radius: 4px;
  border: ${(props) =>
    props.isSelected ? "1px solid #87A0E5" : "1px solid #d1d1d1"};
  background: ${(props) => (props.isSelected ? "#E8EFFD" : "#fff")};
  display: flex;
  width: 100px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: rgba(28, 28, 28, 0.8);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
`;
export const ImpressionWrap = styled.div`
  display: flex;
  gap: 8px;
`;
export const DetailsContainer = styled.div`
  padding-top: 16px;
  display: grid;
  grid-template-columns: repeat(2, 49.4%);
  /* flex-wrap: wrap; */
  gap: 16px;
  @media screen and (max-width: 1750px) {
    /* justify-content: center; */
  }
`;
export const DetailsDiv = styled.div`
  border-radius: var(--8, 8px);
  border: 1px solid #e3e3e3;
  background: #fff;
  width: 100%;
  height: 280px;
  @media screen and (max-width: 1750px) {
    /* width: 100%; */
  }
`;

export const TableHeader = styled.div`
  border-radius: 8px 8px 0 0;
  display: flex;
  border-bottom: 1px solid #eaecf0;
  background: #f9f9f9;
`;
export const HeadTitle = styled.div`
  color: #667085;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px; /* 150% */
  display: flex;
  /* height: 44px; */
  padding: 0px 24px;
  margin: 12px 0;
  align-items: center;
  &.statusClass {
    width: 12.2%;
  }
  &.campaignClass {
    width: 66.6%;
  }
  &.profitClass {
    width: 21%;
    justify-content: end;
  }
  &.campaignBody {
    color: rgba(28, 28, 28, 0.8);
    font-size: 14px;
    line-height: 20px; /* 142.857% */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    padding-right: 0;
    /* white-space: nowrap; */
  }
  &.profitBody {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  img {
    height: 12px;
  }
`;
export const TableBody = styled.div``;
export const TableRow = styled.div`
  border-bottom: 1px solid #eaecf0;
  display: flex;
`;
export const ImpressionList = styled.div`
  border-radius: 4px;
  border: ${(props) =>
    props.isSelect ? "1px solid #87A0E5" : "1px solid #d1d1d1"};
  background: ${(props) => (props.isSelect ? "#E8EFFD" : "#fff")};
  display: flex;
  width: 100px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: rgba(28, 28, 28, 0.8);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
`;
// export const StatusDiv = styled.div``;
// export const StatusDiv = styled.div``;
