import styled, { createGlobalStyle, keyframes } from 'styled-components'
import { normalize } from 'styled-normalize'
import PoppinsRegular from "./fonts/Poppins-Regular.ttf";
import PoppinsMedium from "./fonts/Poppins-Medium.ttf";
import PoppinsLight from "./fonts/Poppins-Light.ttf";


export const GlobalStyle = createGlobalStyle`
  ${normalize}

     @font-face {
    font-family: 'Poppins';
    src: url(${PoppinsRegular});
    }

    @font-face {
    font-family: 'Poppins-Medium';
    src: url(${PoppinsMedium});
    }

    @font-face {
    font-family: 'Poppins-Light';
    src: url(${PoppinsLight});
    }

  :root {
    --fs-btn: 16px;

    --fs-h2: 20px;
    --fs-h3: 18px;
    --fs-h4: 16px;
    --fs-h5: 12px;

    --fs-h6: 10px;

    --fs-li: 14px;
  }
 
  div{
    cursor: default;
  }

  div::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

  div::-webkit-scrollbar
    {
        width: 5px;
        height: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

  div::-webkit-scrollbar-thumb
    {
        background-color: #00000030;
        border-radius: 10px;
    }
    
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  * {
    box-sizing: border-box;
    margin: 0;  
    padding: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    /* line-height: normal; */
  }

  button {
    font-size: var(--fs-btn);
    cursor: pointer;
  }

  body{
    background-color: #f9f9f9;
  }

  h2 {
    font-size: var(--fs-h2);
  }

  h3 {
    font-size: var(--fs-h3)
  }

  h4 {
    font-size: var(--fs-h4)
  }

  h5 {
    font-size: var(--fs-h5)
  }

  h6 {
    font-size: var(--fs-h6)
  }

  li {
    font-size: var(--fs-li)
  }

  a {
    cursor: pointer;
  }

  input::placeholder{
    font-weight: 400px;
    color: rgba(28, 28, 28, 0.40);
  }

  .rc-tooltip{
    opacity: 1;
  }

`;

const SpinAnime =  keyframes`
  0% { -webkit-transform: rotate(0deg);}
  100% { -webkit-transform: rotate(360deg); }
`;

export const Spinner = styled.div`
  margin: auto;
  border: 2px dotted #dbf2ff; 
  width: 22px;
  height: 22px;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border-right: 2px solid #018df7;
  text-align:center;
  animation-name: ${SpinAnime};
  animation-duration: 900ms;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.53, 0.21, 0.29, 0.67);
`;

const LineAnim =  keyframes`
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
`;

export const LineLoader = styled.div`
  width: 60%;
  height: 3px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  margin: 1rem auto;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;

  ::before {
    content: "";
    position: absolute;
    left: -50%;
    height: 3px;
    width: 40%;
    background-color: #FF711E;
    animation-name: ${LineAnim};
    animation-duration: 900ms;
    animation-iteration-count: infinite;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }
`;

export const TextHighlight = styled.span`
  color: ${props => props.theme.newColors.themeBlue};
`;

const waveLines = keyframes`
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
`;

export const SkeletonBox = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  margin: ${(props) => (props.margin ? props.margin : "unset")};
  border-radius: 2px;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  background-size: 800px 100px;
  animation: ${waveLines} 2s infinite ease-out;

  position: ${(props) => (props.absolute ? "absolute" : "unset")};
  top: ${(props) => (props.top ? props.top : "unset")};
  left: ${(props) => (props.left ? props.left : "unset")};
  bottom: ${(props) => (props.bottom ? props.bottom : "unset")};
  right: ${(props) => (props.right ? props.right : "unset")};
`;