import React from 'react'
import { useReducer, createContext, useContext } from 'react'
import produce from 'immer'

const initialState = {
    domainInput: '',
    domainInputList: [],
    domainInputListError: '',
    domainInputErrorsObj: {

    },
    domainList: [],
    domainInputModalOpen: false,
    domainTableOpen: false,
    domainTableList:[],
    adDistribution:[],
    activeCampaigns: {},
    topDomains:[],

    domainTrackingData: {

    },
    heatMapData: [],
    mapStat:[]
}

const DomainTrackingReducer = produce((draft, action) => {
    switch(action.type) {
        case 'SET_DOMAIN_INPUT':
            draft.domainInput = action.value
            break
        
        case 'SET_DOMAIN_INPUT_LIST':
            draft.domainInputList = action.value
            break

        case 'SET_DOMAIN_INPUT_LIST_ERROR':
            draft.domainInputListError = action.value
            break

        case 'SET_DOMAIN_INPUT_ERROR_OBJ':
            draft.domainInputErrorsObj = action.value
            break
        
        case 'SET_DOMAIN_LIST':
            draft.domainList = action.value
            break

        case 'SET_DOMAIN_INPUT_MODAL_OPEN':
            draft.domainInputModalOpen = action.value
            break
        case 'SET_DOMAIN_TABLE_OPEN':
            draft.domainTableOpen = action.value
            break
        case 'SET_DOMAIN_TABLE_LIST':
            draft.domainTableList = action.value
            break
        case 'SET_ADDISTRIBUTION_LIST':
            draft.adDistribution = action.value
            break
        case 'SET_AD_STATS':
            draft.activeCampaigns = action.value.activeCampaigns
            draft.adDistribution = action.value.adsDistribution
            break
        case 'SET_HEATMAP_DATA':
            draft.heatMapData = action.value
            break
        case 'SET_TOPDOMAINS_LIST':
            draft.topDomains = action.value
            break

        case 'SET_DOMAIN_TRACKING_DATA':
            draft.domainTrackingData = action.value
            break
        case 'SET_MAP_DISTRIBUTION':
            draft.mapStat = action.value
            break 
        default:
            break
    }
})

const useDomainTrackingReducer = () => {
    return useReducer(DomainTrackingReducer, initialState)
}

const DomainTrackingContext = createContext()

export const DomainTrackingProvider = ({ children }) => {
    const [state, dispatch] = useDomainTrackingReducer()
    
    const value = {
        domainTrackingState: state,
        domainTrackingDispatch: dispatch
    }
    
    return <DomainTrackingContext.Provider value={value}>
        {children}
    </DomainTrackingContext.Provider>
}

export const useDomainTracking = () => {
    return useContext(DomainTrackingContext)
}
