import React, {useState, useEffect, useRef} from 'react'
// import {Select, Checkbox} from "antd"
import { useSearchParams } from 'react-router-dom'

// Styles
import {
    PopupBodyContainer,
    BodyHeader,
    CountrySearchBar,
    CountriesListContainer,
    CheckBoxContainer,
    CountriesContainer,
    DropdownContainer,
    DropdownButton,
    DropDownImage,
    Flag,
    CountryName,
    FlagContainer,
    Country
} from "./index.styled"

// Images
import UpArrow from "../../../../../../../../../media/Dashboard/uparrow.png"
import DownArrow from "../../../../../../../../../media/Dashboard/downarrow.png"

import { countriesList } from '../../../../../../../../../utils/countryData'
import { useOutsideAlerter } from '../../../../../../../../../utils/outsiderAlert'
import GlobeIcon from "../../../../../../assets/country.svg"

const CountryDropDown = ({domainData, updateDomainCountry,countryIndex}) => {
    const targetRef = useRef(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const [filteredCountries, setFilteredCountries] = useState([])
    const [show, setShow] = useState(false)
    const [fixedElementPosition, setFixedElementPosition] = useState({ top: 0, left: 0, right: 0, bottom: 0 });

    const handleCountrySearch = (e) => {
        let searchTerm = e.target.value;
        let filteredCountriesList = [];

        // countryData.map((region, idx) => {
        //     let tempRegionCountry = region.countries.filter((country) => {
        //         return country.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) || country.name.toLocaleLowerCase() === searchTerm.toLocaleLowerCase()
        //     })

        //     if (tempRegionCountry.length > 0) {
        //         filteredCountriesList.push({region: region.region, countries: tempRegionCountry})
        //     }
        // })

        filteredCountriesList = countriesList.filter((country, idx) => {
            return country.name.common.toLocaleLowerCase() == searchTerm.toLocaleLowerCase() || country.name.common.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
        })

        setFilteredCountries(filteredCountriesList)
    }

    let updatePosition = () => {
        if (targetRef.current) {
          const rect = targetRef.current.getBoundingClientRect();
        //   console.log(rect,'rectrect')
          setFixedElementPosition({
            top: rect.top,
            left: rect.left,
            right: rect.right,
            bottom: rect.bottom,
          });

        }
      };
  

    useEffect(() => {
      // Initial position update
      updatePosition();
  
      // Listen for resize or scroll events if the position might change dynamically
      window.addEventListener('resize', updatePosition);
      window.addEventListener('scroll', updatePosition);
  
      // Cleanup the event listeners when the component is unmounted
      return () => {
        window.removeEventListener('resize', updatePosition);
        window.removeEventListener('scroll', updatePosition);
      };
    }, []); 

    useOutsideAlerter(targetRef, setShow)

    return (
        <DropdownContainer 
            // isSelected={
            //     domainData.country.name
            // }
            ref={targetRef}
            isDropdownOpened={show}
            >
            <DropdownButton onClick={
                    () => {
                        setShow(prev => !prev)
                        updatePosition()
                    }
                }
                
                // isSelected={
                //     domainData.country?.name
                // }
                >
                <span>{
                    // domainData.country?.country ? getFlagEmoji(domainData.country?.code) + " " + domainData.country?.code : "All Country"
                    domainData.country?.code ? (
                        <span >
                            <FlagContainer>
                                <Flag src={domainData.country.flags.svg} />
                            </FlagContainer>
                            <CountryName>{domainData.country.code}</CountryName>
                    </span>
                    ) : <span><img src={GlobeIcon}/> Global</span>
                }</span>
                {
                !domainData.country?.country && <DropDownImage><img src={
                        show ? UpArrow : DownArrow
                    }/></DropDownImage> 
            } </DropdownButton>
            {show && 
                <PopupBodyContainer height={30} left={fixedElementPosition.left - 127} top={fixedElementPosition.top+52} className='country-dropdown-options'>
                    <BodyHeader>
                        <CountrySearchBar onChange ={handleCountrySearch} placeholder="Search country"/> 
                            <Country
                                onClick={() => {
                                    updateDomainCountry(countryIndex, "global")
                                    setShow(false)
                                }}
                            >
                                <FlagContainer>
                                    <Flag src={GlobeIcon}  />
                                </FlagContainer>
                                <CountryName>Global</CountryName>
                            </Country>
                    </BodyHeader>
                    <CountriesListContainer> {
                        filteredCountries.length > 0 ? filteredCountries.map((country, idx) => {
                            return (
                                
                                <CountriesContainer> {
                                    // country.countries.map((country, id) => { 
                                    //     return 
                                        <Country key={idx}
                                        onClick={() => {
                                            updateDomainCountry(countryIndex, country)
                                            setShow(false)
                                        }}
                                    >
                                        <FlagContainer>
                                            <Flag src={country.flags.svg}  />
                                        </FlagContainer>
                                        <CountryName>{country.name.common}</CountryName>
                                    </Country>
                                    // })
                                } </CountriesContainer>
                            
                            )
                    }) : countriesList.map((country, idx) => {
                        return (
                                
                                <CountriesContainer> {
                                    // country.countries.map((country, id) => { 
                                    //     return 
                                        <Country key={idx}
                                        onClick={() => {
                                            updateDomainCountry(countryIndex, country)
                                            setShow(false)
                                        }}
                                    >
                                        <FlagContainer>
                                            <Flag src={country.flags.svg}  />
                                        </FlagContainer>
                                        <CountryName>{country.name.common}</CountryName>
                                    </Country>
                                    // })
                                } </CountriesContainer>
                            
                            )
                })
                    } </CountriesListContainer>
                </PopupBodyContainer>
            } 
        </DropdownContainer>
)}

export default CountryDropDown

// const CountryDropDown = ({domainData, updateDomainCountry,countryIndex}) => {
//     const targetRef = useRef(null)
//     const [filteredCountries, setFilteredCountries] = useState([])
//     const [show, setShow] = useState(false)
//     const [fixedElementPosition, setFixedElementPosition] = useState({ top: 0, left: 0, right: 0, bottom: 0 });

//     const handleCountrySearch = (e) => {
//         let searchTerm = e.target.value;
//         let filteredCountriesList = [];

//         // countryData.map((region, idx) => {
//         //     let tempRegionCountry = region.countries.filter((country) => {
//         //         return country.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) || country.name.toLocaleLowerCase() === searchTerm.toLocaleLowerCase()
//         //     })

//         //     if (tempRegionCountry.length > 0) {
//         //         filteredCountriesList.push({region: region.region, countries: tempRegionCountry})
//         //     }
//         // })

//         filteredCountriesList = countriesList.filter((country, idx) => {
//             return country.country.toLocaleLowerCase() == searchTerm.toLocaleLowerCase() || country.country.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
//         })

//         setFilteredCountries(filteredCountriesList)
//     }

//     let updatePosition = () => {
//         if (targetRef.current) {
//           const rect = targetRef.current.getBoundingClientRect();
//         //   console.log(rect,'rectrect')
//           setFixedElementPosition({
//             top: rect.top,
//             left: rect.left,
//             right: rect.right,
//             bottom: rect.bottom,
//           });

//         }
//     };
  
//     useEffect(() => {
//       // Initial position update
//       updatePosition();
  
//       // Listen for resize or scroll events if the position might change dynamically
//       window.addEventListener('resize', updatePosition);
//       window.addEventListener('scroll', updatePosition);
  
//       // Cleanup the event listeners when the component is unmounted
//       return () => {
//         window.removeEventListener('resize', updatePosition);
//         window.removeEventListener('scroll', updatePosition);
//       };
//     }, []); 

//     useOutsideAlerter(targetRef, setShow)

//     return (
//         <DropdownContainer 
//             // isSelected={
//             //     domainData.country.name
//             // }
//             ref={targetRef}
//             >
//             <DropdownButton onClick={
//                     () => {
//                         setShow(prev => !prev)
//                         updatePosition()
//                     }
//                 }
                
//                 // isSelected={
//                 //     domainData.country?.name
//                 // }

//                 >
//                 <span>{
//                     domainData.country?.country ? getFlagEmoji(domainData.country?.code) + " " + domainData.country?.code : "All Country"
//                 }</span>
//                 {
//                 !domainData.country?.country && <DropDownImage><img src={
//                         show ? UpArrow : DownArrow
//                     }/></DropDownImage> 
//             } </DropdownButton>
//             {
//             show && <PopupBodyContainer height={30} left={fixedElementPosition.left - 127} top={fixedElementPosition.top+52} className='country-dropdown-options'>
//                 <BodyHeader>
//                     <CountrySearchBar onChange ={handleCountrySearch} placeholder="Search country"/>
//                         {
//                         <CheckBoxContainer
//                         onClick={() => {
//                             updateDomainCountry(domainData.keyword, {})
//                             setShow(false)
//                         }}
//                         >
//                             <p>All Country</p>
//                         </CheckBoxContainer>
//                         } 
//                 </BodyHeader>
//                 <CountriesListContainer> {
//                     filteredCountries.length > 0 ? filteredCountries.map((region, idx) => {
//                         return <CheckboxGroup>
//                                 {/* <label>{region.region}</label> */}
//                             <CountriesContainer> {
//                                 // region.countries.map((country, id) => { 
//                                 //     return 
//                                     <CheckBoxContainer key={idx}
//                                     onClick={() => {
//                                         updateDomainCountry(countryIndex, region)
//                                         setShow(false)
//                                     }}
//                                 >
//                                     <p>{region.country}</p>
//                                 </CheckBoxContainer>
//                                 // })
//                             } </CountriesContainer>
//                         </CheckboxGroup>
//                 }) : countriesList.map((region, idx) => {
//                     return <CheckboxGroup>
//                             {/* <label>{region.region}</label> */}
//                         <CountriesContainer> {
//                             // region.countries.map((country, id) => {
//                             //     return 
//                                 <CheckBoxContainer key={idx}
//                                     onClick={() => {
//                                         updateDomainCountry(countryIndex, region)
//                                         setShow(false)
//                                     }}
//                                 >
//                                     <p>{region.country}</p>
//                                 </CheckBoxContainer>
//                             // })
//                         } </CountriesContainer>
//                     </CheckboxGroup>
//             })
//                 } </CountriesListContainer>
//             </PopupBodyContainer>
//         } </DropdownContainer>
// )}

// export default CountryDropDown
