import styled from "styled-components";

export const PlatformchartWrap = styled.div`
  border-radius: var(--8, 8px);
  background: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 85px;
  height: 548px;
`;
export const ChartHeadWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const TabOptions = styled.div`
  display: flex;
  gap: 20px;
  padding-left: 28px;
  border-bottom: 1px solid #dadfe4;
  width: 435px;
`;
export const TabDiv = styled.div`
  color: ${(props) => (props.isActive ? "#1C1C1C" : "rgba(28, 28, 28, 0.5)")};
  font-size: 14px;
  font-weight: 500;
  line-height: 150%; /* 21px */
  padding: 20px 8px 15px 4px;
  border-bottom: ${(props) => (props.isActive ? "2px solid #ff711e" : "")};
  cursor: pointer;
  text-transform: capitalize;
`;

export const PlatformDiv = styled.div`
  display: flex;
  gap: 24px;
  padding-right: 32px;
  align-items: center;
`;
export const PlatformName = styled.div`
  text-transform: uppercase;
  color: #7f7f7f;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%; /* 18px */
  display: flex;
  align-items: center;
  gap: 8px;
  p {
    width: 10px;
    height: 10px;
    background: #1f4dda;
    border-radius: 50%;
  }
`;
export const GraphContainer = styled.div`
  width: 100%;
  padding: 0 33px 0px 32px;
  height: 370px;
  position: relative;
  /* @media (max-width: 700px) {
    width: 311px;
    height: 160px;
  } */
  canvas {
    /* width: 100% !important; */
    /* height: calc(100% - 130px) !important; */
  }
`;
