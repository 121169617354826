const defaultPersonas = [
    "Software Developer",
    "Marketing Manager",
    "Data Analyst",
    "Graphic Designer",
    "Project Manager",
    "Sales Representative",
    "Product Manager",
    "Financial Analyst",
    "Human Resources Specialist",
    "Customer Support Specialist",
    "UX/UI Designer",
    "Content Writer",
    "SEO Specialist",
    "Digital Marketing Specialist",
    "Business Analyst",
    "Accountant",
    "Civil Engineer",
    "Electrical Engineer",
    "Mechanical Engineer",
    "Architect",
    "Teacher",
    "Professor",
    "Healthcare Professional",
    "Nurse",
    "Doctor",
    "Pharmacist",
    "Lawyer",
    "Paralegal",
    "Retail Manager",
    "Sales Associate",
    "Real Estate Agent",
    "Freelancer",
    "Entrepreneur",
    "Small Business Owner",
    "Chef",
    "Event Planner",
    "Fitness Trainer",
    "Artist",
    "Musician",
    "Photographer",
    "Videographer",
    "Blogger",
    "Influencer",
    "Student",
    "Retired"
]

export default defaultPersonas