import React, {useState} from 'react'
import { useSearchParams, useNavigate } from "react-router-dom"

import {
    Container,
    Header,
    SearchContainer,
    Button,
    Content,
    ResultsContainer,
    CreativeContainer,
    CreativeLeft,
    CreativeRight,
    Input, 
    InputContainer, 
    ToolTipImageContainer, 
    ToolTip, 
    Wrapper, 
    ToolTipContainer,
    ModeContainer,
    KeywordButtonContainer
} from "./index.styled"

import DomainAnalysisRestrictionPopup from '../../../components/domainAnalysisRestrictionPopup'

import { useAuth } from '../../../../context/auth'

import CreativeImage1 from "./../../../../media/Dashboard/url-creative1.svg"
import CreativeImage2 from "./../../../../media/Dashboard/url-creative2.svg"
import CreativeImage3 from "./../../../../media/Dashboard/url-creative3.svg"

import ToolTipLogo from "../../../../media/Dashboard/tooltip.svg"

import ToolTipIcon from '../../../../media/Dashboard/info_button_tool_tip.svg'
import Tooltip from 'rc-tooltip';

import ValidateAndAddHttps from "../../../../utils/urlValidate"

import { useUrlAnalysis } from './../context/urlanalysis'

const UrlAnalysisHome = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const {urlAnalysisState, urlAnalysisDispatch} = useUrlAnalysis()
    const [localUrl, setLocalUrl] = useState('')
    const [localSearchMode, setLocalSearchMode] = useState('domain')

    const {authState, authDispatch} = useAuth()
    
    const [url, setUrl] = useState('')
    const [error, setError] = useState(false)
    const [isValidUrl, setIsValidUrl] = useState(false)

    const navigate = useNavigate()

    const handleSearch = () => {
        if(urlAnalysisState.isValidUrl){
            // tempchanges
            // if(!authState.features?.isKeywordAnalysisAllowed){
            //     authDispatch({type: 'UPDATE_IS_PPC_RESTRICTED', value: true})
            // }else{
                searchParams.set('url',localUrl)
                searchParams.set('searchMode',localSearchMode)
                navigate(`/url-analysis/details?${searchParams.toString()}`)
            // }
        }else{
            urlAnalysisDispatch({type: 'SET_ERROR', value: true})
        }
    }

    const handleOnEnterSearch = (event) => {
        if (event.key === 'Enter') {
            if(urlAnalysisState.isValidUrl){
                // tempchanges
                // if(!authState.features?.isKeywordAnalysisAllowed){
                //     authDispatch({type: 'UPDATE_IS_PPC_RESTRICTED', value: true})
                // }else{
                    searchParams.set('url',ValidateAndAddHttps(localUrl))
                    searchParams.set('searchMode',localSearchMode)
                    navigate(`/url-analysis/details?${searchParams.toString()}`)
                // }
            }else{
                urlAnalysisDispatch({type: 'SET_ERROR', value: true})
            }
        }   
    }

    return <Container>
        
        {/* {authState.isDomainAnalysisRestrictedPopup &&  */}
                {!authState.isPPCRestrictedPopup && 
                    <ResultsContainer>
                        <DomainAnalysisRestrictionPopup/>
                    </ResultsContainer>
                }
                    <Header>
                        <h1>
                            Domain and Url Analysis
                        </h1>
                        <h3>Uncover the most profitable keywords in your industry</h3>
                        <SearchContainer>
                                <ModeContainer>
                                    <KeywordButtonContainer 
                                        active={localSearchMode === "path"} 
                                        onClick={() => setLocalSearchMode('path')}>
                                        <input type="radio" checked={localSearchMode === "path" && "checked"} id="path" name="mode"/>
                                        <label for="path">path</label>
                                    </KeywordButtonContainer>
                                    <KeywordButtonContainer 
                                        active={localSearchMode === "domain"} 
                                        onClick={() =>  setLocalSearchMode('domain')}>
                                        <input type="radio" checked={localSearchMode === "domain" && "checked"} id="domain" name="mode"/>
                                        <label for="domain">domain</label>
                                    </KeywordButtonContainer>
                                </ModeContainer>
                                <Wrapper>
                                <ToolTipContainer>
                                    Enter a domain <Tooltip placement="right" trigger={['hover']} overlay={<span>Enter any domain you want the ad copies in the search results to have as a part of their redirect address.</span>}>
                                        <img src={ToolTipIcon}/>
                                    </Tooltip>
                                </ToolTipContainer>
                                <InputContainer>
                                        <Input
                                            onKeyDown={handleOnEnterSearch}
                                            error={urlAnalysisState.error}
                                            name="domain-url-input"
                                            type="text"
                                            placeholder="Enter an URL" 
                                            autoComplete="off"
                                            value={localUrl}
                                            onChange={(e) => {
                                                 
                                                // const isTrueVal = urlPatternValidation(e.target.value)
                                                const isTrueVal = ValidateAndAddHttps(e.target.value)

                                                urlAnalysisDispatch({type: 'SET_ERROR', value: false})

                                                setLocalUrl(e.target.value)

                                                // urlAnalysisDispatch({type: 'SET_SEARCH_URL', value: e.target.value})

                                                urlAnalysisDispatch({type: 'SET_IS_VALID_URL', value: isTrueVal ? true : false})
                                            }}
                                        /> 
                                        <ToolTipImageContainer
                                            error={urlAnalysisState.error} className="tooltip-container"
                                            >
                                            <img src={ToolTipLogo}/>
                                            <ToolTip>
                                                Please enter a url
                                            </ToolTip>
                                        </ToolTipImageContainer>
                                </InputContainer>
                            </Wrapper>
                            <Button onClick={handleSearch}>Search</Button>
                        </SearchContainer>
                    </Header>
                    <Content>
                        <CreativeContainer>
                            <CreativeLeft>
                                <img src={CreativeImage1}/>
                            </CreativeLeft>
                            <CreativeRight>
                                <h2>
                                    Comprehensive Domain Overview
                                </h2>
                                <p>
                                    See total ads and campaigns, plus their distribution across 6 ad platforms. Ideal for understanding a domain's ad strategy.
                                </p>
                            </CreativeRight>
                        </CreativeContainer>

                        <CreativeContainer>
                            <CreativeLeft>
                                <h2>
                                    Global Ad Reach by Country
                                </h2>
                                <p>
                                    Explore the geographic footprint of ads. The map shows distribution across countries to help you gauge market focus.
                                </p>
                            </CreativeLeft>
                            <CreativeRight>
                                <img src={CreativeImage2}/>
                            </CreativeRight>
                        </CreativeContainer>

                        <CreativeContainer>
                            <CreativeLeft>
                                <img src={CreativeImage3}/>
                            </CreativeLeft>
                            <CreativeRight>
                                <h2>
                                    Demographic Insights
                                </h2>
                                <p>
                                    Get insights into ads targeting various genders and age groups. Useful for grasping a domain's audience focus.
                                </p>
                            </CreativeRight>
                        </CreativeContainer>
                    </Content>
                </Container>
}

export default UrlAnalysisHome      