import styled from "styled-components";

export const TargetLocationContainer = styled.div`
    width: 738px;
    // height: 260px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #FFF;
    padding: 40px 32px;
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    justify-content: space-between;
`;

// export const LocationInputContainer = styled.input`
//     width: 674px;
//     height: 44px;
//     flex-shrink: 0;
//     border-radius: 6px;
//     border: 1px solid #DADFE4;
//     padding: 12px 12px 12px 48px;
//     color: ${(props) => props.isActive ? "#000" : "rgba(28, 28, 28, 0.40)"};
//     font-family: Inter;
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 20px;
//     margin-bottom: 16px;
//     outline: none;

//     :focus{
//         outline: none;
//     }
// `;

// export const SearchIcon = styled.img`
//     position: absolute;
//     left: 48px;
//     top: 54px;
// `;

export const SuggestionContainer = styled.div`
    height: 32px;
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const SuggestionLabel = styled.div`
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    /* font-family: Roboto; */
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15px;
`;

export const CountryContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    background: #F7F7F7;
    gap: 8px;
    padding: 6px 8px 6px 12px;
    cursor: pointer;
`;

export const Country = styled.div`
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    /* font-family: Roboto; */
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15px;
`;

export const AddIcon = styled.img`

`;

export const LocationInputContainer = styled.div`
    margin-top: 12px;
    position: relative;
`;
export const LocationInput = styled.div`
    height: 44px;
    padding: 0px 16px;
    border-radius: 6px;
    border: 1px solid #DADFE4;
    display: flex;
    align-items: center;
    gap: 16px;
    img{
        height: 16px;
    }
    input{
        border: none;
        outline: none;
        height: 100%;
    }
`;
export const InputContainer = styled.div`
  border-radius: 8px;
  border: ${(props) => props.hasError ? "1px solid #F04438" : props.isFocussed ? "1px solid #FFB258" : "1px solid #D0D5DD"};
  background: #FFF;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 195px;
  position: relative;
  padding: 0 14px;
  box-shadow: ${(props) => props.isFocussed ? "0px 1px 2px 0px rgba(28, 28, 28, 0.05), 0px 0px 4px 0px rgba(255, 178, 88, 0.25)" : "unset"};
`;

export const SearchIcon = styled.img`
  margin-right: 8px;
`;
export const KeywordInput = styled.input`
margin-left: 8px;
  color: rgba(28, 28, 28, 0.90);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  height: 100%;
  width: 100%;
  // border: 1px solid #D0D5DD;
  border: none;
  outline: none;
  // border-radius: 8px;
  caret-color: #FFB258;
  ::placeholder{
    color: rgba(28, 28, 28, 0.50);
  }
`;
export const LocationSelectContainer = styled.div`
    label{
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        color: rgba(28, 28, 28, 0.50);
    }
`;
export const LocationSuggestionContainer = styled.div`
    position: absolute;
    border-radius: 0px 0px 12px 12px;
    border: 1px solid #E3E8EE;
    background: #FFF;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.15);
    z-index: 2;
    width: 100%;
    top: 48px;
`;
export const SuggestionElement = styled.div`
    height: 44px;
    padding: 12px 16px;
    border-bottom: solid 1px #E3E8EE;
    cursor: pointer;
`;
export const SelectedLocationsConatiner = styled.div`
    margin-top: 24px;
`;
export const SelectedLocationList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 12px;
`;
export const SelectedLocationItem = styled.div`
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15px;
    padding: 6px 8px 6px 12px;
    border-radius: 16px;
    background: #F7F7F7;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    img{
        cursor: pointer;
    }
`;
// import styled from "styled-components";

// export const Container = styled.div`
//     width: 738px;
//     height: 324px;
//     position: relative;
//     padding-top: 36px;
// `;

// export const Header = styled.div`
// color: var(--black-100, #1C1C1C);
// font-family: Inter;
// font-size: 20px;
// font-style: normal;
// font-weight: 600;
// line-height: 38px; /* 190% */
// margin: 0 0 20px 35px;
// `;

// export const TargetLocationContainer = styled.div`
//     width: 738px;
//     height: 230px;
//     flex-shrink: 0;
//     border-radius: 10px;
//     background: #FFF;
//     padding: 40px 32px;
//     position: relative;
//     padding-top: 0;
// `;

// export const LocationInputContainer = styled.input`
//     width: 674px;
//     height: 44px;
//     flex-shrink: 0;
//     border-radius: 6px;
//     border: 1px solid #DADFE4;
//     padding: 12px 12px 12px 48px;
//     color: ${(props) => props.isActive ? "#000" : "rgba(28, 28, 28, 0.40)"};
//     font-family: Inter;
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 20px;
//     margin-bottom: 16px;
//     outline: none;

//     :focus{
//         outline: none;
//     }
// `;

// export const SearchIcon = styled.img`
//     position: absolute;
//     left: 48px;
//     top: 14px;
// `;

// export const SuggestionContainer = styled.div`
//     height: 32px;
//     display: flex;
//     align-items: center;
//     gap: 12px;
// `;

// export const SuggestionLabel = styled.div`
//     color: #000;
//     font-feature-settings: 'clig' off, 'liga' off;
//     /* font-family: Roboto; */
//     font-size: 15px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 20px;
//     letter-spacing: 0.15px;
// `;

// export const CountryContainer = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     border-radius: 2px;
//     background: #F7F7F7;
//     gap: 8px;
//     padding: 6px 8px 6px 12px;
//     cursor: pointer;
// `;

// export const Country = styled.div`
//     color: #000;
//     font-feature-settings: 'clig' off, 'liga' off;
//     /* font-family: Roboto; */
//     font-size: 15px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 20px;
//     letter-spacing: 0.15px;
// `;

// export const AddIcon = styled.img`

// `;