const statics = {
    title :  "Setup your Flonnect Dashboard",
    tasks: [
        {
            sno: 1,
            title: "Create a project",
            description: "Initiate and Customize Your Project"
        },
        {
            sno: 2,
            title: "Setup Keywords",
            description: "Select and Setup Keywords",
            contentHeader: "Setup Keywords for tracking"
        },
        {
            sno: 3,
            title: "Setup Competitors",
            description: "Identify and Analyze Competitors",
            contentHeader: "Setup Competitors for tracking"
        }
    ],
    keywordChoices: [
        {
            keyword: "screen recorder",
            searchVolume: "5700",
            adCopiesCount: "76"
        },
        {
            keyword: "screen recorder app",
            searchVolume: "5700",
            adCopiesCount: "76"
        },
        {
            keyword: "flonnect",
            searchVolume: "5700",
            adCopiesCount: "76"
        },
        {
            keyword: "meeting",
            searchVolume: "5700",
            adCopiesCount: "76"
        },
        {
            keyword: "google meet",
            searchVolume: "5700",
            adCopiesCount: "76"
        },
        {
            keyword: "zoom meeting",
            searchVolume: "5700",
            adCopiesCount: "76"
        },
        {
            keyword: "screen recorder",
            searchVolume: "5700",
            adCopiesCount: "76"
        },
    ],
    keywordSelectingInstruction: "Add keywords from the left panel",
    competitorChoices: [
        {
            competitor: "loom.com",
            searchVolume: "5700",
            adCopiesCount: "76"
        },
        {
            competitor: "zoom.com",
            searchVolume: "5700",
            adCopiesCount: "76"
        },
        {
            competitor: "google.meet.io",
            searchVolume: "5700",
            adCopiesCount: "76"
        },
        {
            competitor: "apple.com",
            searchVolume: "5700",
            adCopiesCount: "76"
        },
        {
            competitor: "playr.io",
            searchVolume: "5700",
            adCopiesCount: "76"
        },
        {
            competitor: "kgkg.com",
            searchVolume: "5700",
            adCopiesCount: "76"
        }
    ],
    competitorSelectingInstruction: "Add competitors from the left panel"
}

export default statics