import React from "react";
import {
    CountyStatsTable,
    AllHeadCount,
    CountryStatsData,
    Progress
} from "./index.styled";
import { useAuth } from "../../../../../../context/auth";
import { useUrlAnalysis } from "../../../context/urlanalysis";
import { countryList } from "./maps";
import Skeleton from 'react-loading-skeleton';

const MapChartTable = ({ isDataLoading }) => { 
    const { urlAnalysisState, urlAnalysisDispatch  } = useUrlAnalysis()

  const { authState } = useAuth();

  const getFlagEmoji = (countryName) => {
    let countryCode = countryList[countryName]
    if(countryCode){
        const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map(char =>  127397 + char.charCodeAt());
      return String.fromCodePoint(...codePoints);
    }
    
  }

  let adsByCountryData = [
    {
        country: "United States",
        percentage: "73",
        countryCode: "US"
    },
    {
        country: "India",
        percentage: "7.5",
        countryCode: "IN"
    },
    {
        country: "Japan",
        percentage: "6.2",
        countryCode: "JP"
    },
    {
        country: "United Kingdom",
        percentage: "4.1",
        countryCode: "UK"
    },
    {
        country: "China",
        percentage: "3.1",
        countryCode: "CN"
    },
    {
        country: "Others",
        percentage: "6.1"
    }    
    ]

  return (isDataLoading && !urlAnalysisState.mapdata ? 
    <Skeleton style={{
        lineHeight: 19,
        width: 610,
      }}/> : 
    <CountyStatsTable>
        {/* <HeadNames>
            <p>
                Countries
            </p>
            <p>
                Ads share
            </p>
        </HeadNames> */}
        <AllHeadCount>
            <p>
                Countries
            </p>
            <Progress value="100" max="100"/>
            <p className="percentage">
                Ads share
            </p>
        </AllHeadCount>
        {urlAnalysisState.mapTableData.length > 1 && urlAnalysisState.mapTableData.map((country, idx)=>{
            return <CountryStatsData>
                <p>
                    <span>{country.key && getFlagEmoji(country.key)}</span>{country.key}
                </p>
                <Progress value={country.percentage.replace("%","")} max="100"/>
                <p className="percentage">
                    {country.percentage}
                </p>
            </CountryStatsData>
        })}
    </CountyStatsTable>
  );
};

export default React.memo(MapChartTable);




