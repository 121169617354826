import { AdCopyContainer, AdCopiesWrapper, AdHeader, Title, Logo, Advertiser, RedirectUrl, AdContent, AdDescription, Footer, Container, Text,NoDataContainer, CardBody, Card, TitleWrap, LogoWrapper, LinkWrap, KeywordWrap, DescriptionWrap, FooterWrap, CountryWrap, Country, GoogleIcon, Header } from "./AdCopies.styled";
import GlobalIcon from "../../../../../media/Dashboard/globe.svg"
import calender from "../../assets/calender.svg"
import country from "../../assets/country.svg"
import { useKeywordFunnelAnalysis } from "../../context/keywordFunnelAnalysis";
import Skeleton from "react-loading-skeleton";
import locationImg from '../../../../../media/Dashboard/locationCard.svg'
import googleImg from "../../../../../media/Dashboard/google.svg"

function AdCopies({isFunnelKeywordLoading}) {
    const { keywordFunnelAnalysisState, keywordFunnelAnalysisDispatch } = useKeywordFunnelAnalysis()

    const { funnelAds,activeFunnelType } = keywordFunnelAnalysisState
    const funnelArr = [1,2,3,4]
    const convertTimestamp = (timestamp) => {
        // Create a new Date object based on the timestamp
        var date = new Date(timestamp);
    
        // Get the month name
        var monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        var month = monthNames[date.getMonth()];
      
        // Get the year
        var year = date.getFullYear();
      
        // Assemble the formatted date string
        var formattedDate = date.getDate() + ' ' + month  + ' ' + year;
      
        return formattedDate;
    }

    return ( 
        <AdCopiesWrapper>
             {isFunnelKeywordLoading ? 
            funnelArr.map((item)=>(
            <AdCopyContainer>
                <Skeleton height={"224px"} borderRadius= {"16px"}/>
            </AdCopyContainer>
            ))
            :            
            keywordFunnelAnalysisState?.funnelKeywordData?.funnelData && keywordFunnelAnalysisState?.funnelKeywordData?.funnelData?.map((item) => {
                 const { id } = item
                 const isActive = id === activeFunnelType.id
                return(
                    <>
                    {(isActive && item?.adCopies.length > 0 ) ? item?.adCopies.map((ad)=>(
                         <Card key={
                            ad.id
                        }>                
                            <CardBody>
                                <Header>
                                    <LogoWrapper>
                                        <img src={GlobalIcon} />
                                    </LogoWrapper>
                                    <TitleWrap> 
                                        <Title>{ad.domainId}</Title>
                                        <LinkWrap>{ad.url}</LinkWrap>
                                    </TitleWrap>
                                </Header>
                                <KeywordWrap>{ad.title}</KeywordWrap>
                                <DescriptionWrap>
                                    {`${convertTimestamp(ad.lastSeen)} __ ${ad.content}`}
                                </DescriptionWrap>
                                <FooterWrap>
                                    <CountryWrap>
                                    <img src={locationImg}/>
                                        <Country>{ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : "--"}</Country>
                                    </CountryWrap>
                                    <GoogleIcon>
                                        <img src={googleImg}/>
                                    </GoogleIcon>
                                </FooterWrap>
                            </CardBody>
                        </Card>
                    // <AdCopyContainer key={ad.id} >
                    //     <AdHeader>
                    //         <Logo>
                    //             <img src={GlobalIcon} alt="" />
                    //         </Logo>
                    //         <Title>
                    //             <Advertiser>{ad.domainId}</Advertiser>
                    //             <RedirectUrl>{ad.url}</RedirectUrl>
                    //         </Title>
                    //     </AdHeader>
                    //     <AdContent>
                    //         {ad.title}
                    //     </AdContent>
                    //     <AdDescription>{ad.content.substring(0, 130)}</AdDescription>
                    //     <Footer>
                    //         <Container>
                    //             <img src={calender} />
                    //             <Text>
                    //                 {convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
                    //             </Text>
                    //         </Container>
                    //         <Container>
                    //             <img src={country} />
                    //             <Text>
                    //                 { ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : "All"}
                    //             </Text>
                    //         </Container>
                    //     </Footer>
                    // </AdCopyContainer>
                 )):
                 isActive &&
                 <NoDataContainer>No Data Found</NoDataContainer>}
                 </>
             )
         })}
        </AdCopiesWrapper>
     );
}

export default AdCopies;