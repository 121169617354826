import React from 'react'
import { useReducer, createContext, useContext } from 'react'

import produce from 'immer'

const initialState = {
    query: '',
    domain: '',

    currentResusltsFor: '',

    error: false,
    isValidUrl: false,

    showDomainsList: false,

    domainKeywords: [],
    selectedKeyword: '',
    selectedSimilarKeyword: '',
    showSimilarKeywords: false,

    relatedKeywords: {

    },
    keywordDomains: {

    },


    // ppc data
    loadingResults: false,
    noResults: false,
    broadMinShouldMatch: {
        name: 'Min. match',
        value: 0
    },
    /* Keyword phrase match state */
    phraseSearchIn: [
        {
            name: "Title",
            value: "title",
            checked: true
        }, {
            name: "Content",
            value: "content",
            checked: true
        }
    ],
    searchInError: false,
    urlSearchType: "exact",

    ads: [],
    totalAds: 0,
    currentPageNo: 1,
    highlightWords: [],

    adDetails: {},
    adAiClassification: {},
    adAiClassificationLoading: false,
    detailsModal: false,

    queryId: '',

    // Country filter
    isCountrySelected: false,
    availableCountries: [],
    selectedCountries: [],
    filteredCountries: [],
    selectedRegion: [],
    openDropDown: false,

    // Date filter
    startDate: '',
    endDate: '',
    dateFilterType: '',

    // Agency filter
    agencies: [],
    selectedAgency: '',

    // Aspect ratio filter
    selectedAspectRatio: '',

    //SortBy filter
    selectedSortType: {
        name:'Relevance',
        type: 'relevant'
    },
    limitExceeded: false,
    searchPlatform: 'google'

}

const KeywordAnalysisReducer = produce((draft, action) => {
    switch(action.type) {
        case 'SET_SEARCH_QUERY':
            draft.query = action.value
            break
        case 'SET_CURRENT_RESULTS_FOR':
            draft.currentResusltsFor = action.value
            break
        case 'SET_ERROR':
            draft.error = action.value
            break
        case 'SET_IS_VALID_URL':
            draft.isValidUrl = action.value
            break

        case 'SET_SHOW_DOMAINS_LIST':
            draft.showDomainsList = action.value
            break
        case 'SET_DOMAIN_NAME':
            draft.domain = action.value
            break

        case 'SET_DOMAIN_KEYWORDS':
            draft.domainKeywords = action.value
            break
        case 'SET_KEYWORD_DOMAINS':
            draft.keywordDomains = action.value
            break

        case 'SET_SELECTED_KEYWORD':
            draft.selectedKeyword = action.value
            break
        case 'SET_RELATED_KEYWORDS':
            draft.relatedKeywords = action.value
            break

        case 'SET_SELECTED_SIMILAR_KEYWORD':
            draft.selectedSimilarKeyword = action.value
            break
        
        case 'SET_SHOW_SIMILAR_KEYWORD':
            draft.showSimilarKeywords = action.value
            break

        case 'SET_RESET_KEYWORD_ANALYSIS':
            draft.query = ''
            draft.domain = ''

            draft.error = false
            draft.isValidUrl = false

            draft.showDomainsList = false

            draft.domainKeywords = []
            draft.selectedKeyword = ''
            draft.selectedSimilarKeyword = ''

            draft.relatedKeywords = {

            }
            draft.keywordDomains = {

            }
            break

        case 'UPDATE_LOADING_RESULTS':
            draft.loadingResults = action.value
            break
    
        case 'UPDATE_NO_RESULTS':
            draft.noResults = action.value
            break
            
        case 'SET_SEARCH_MODE':
            draft.searchMode = action.value
            break
        case 'SET_KEYWORD_SEARCHTYPE':
            draft.keywordSearchType = action.value
            break
        case 'SET_KEYWORDS':
            draft.keywords = action.value
            break
        case 'SET_KEYWORD_INPUT_ERROR':
            draft.keywordInputError = action.value
            break
    
        // BROAD ACTIONS
        case 'SET_BROAD_MIN_SHOULD_MATCH':
            draft.broadMinShouldMatch = action.value
            break
        case 'UPDATE_EXCLUDED_TERM':
            draft.excludedTerm = action.value
            break
        case 'SET_BROAD_SEARCH_IN':
            if (action.payload.value === "title") {
                draft.broadSearchIn = draft.broadSearchIn.filter(cb => cb.value !== action.payload.value)
                draft.broadSearchIn.unshift(action.payload)
            } else if (action.payload.value === "content") {
                draft.broadSearchIn = draft.broadSearchIn.filter(cb => cb.value !== action.payload.value)
                draft.broadSearchIn.push(action.payload)
            }
            break
    
        // PHRASE ACTIONS
        case 'SET_PHRASE_SEARCH_IN':
            if (action.payload.value === "title") {
                draft.phraseSearchIn = draft.phraseSearchIn.filter(cb => cb.value !== action.payload.value)
                draft.phraseSearchIn.unshift(action.payload)
            } else if (action.payload.value === "content") {
                draft.phraseSearchIn = draft.phraseSearchIn.filter(cb => cb.value !== action.payload.value)
                draft.phraseSearchIn.push(action.payload)
            }
            break
    
        case 'SET_SEARCH_IN_ERROR':
            draft.searchInError = action.value
            break
    
        case 'SET_PHRASE_INPUT':
            draft.phraseInput = action.value
            break
        case 'SET_HIGHLIGHT_WORDS':
            draft.highlightWords = action.value         
            break
    
        // DOMAIN
        case 'SET_DOMAIN_URL_INPUT':
            draft.domainUrlInput = action.payload
            break
        case 'SET_URL_SEARCHTYPE':
            draft.urlSearchType = action.value
            break
        case 'SET_URL_INPUT_ERROR':
            draft.urlInputError = action.value
            break
    
        case 'SET_ADS':
            draft.ads = action.value
            break
        case 'SET_TOTAL_ADS':
            draft.totalAds = action.value
            break
        case 'SET_CURRENT_PAGE_NO':
            draft.currentPageNo = action.value
            break
        case 'SET_AVAILABLE_COUNTRIES':
            draft.availableCountries = action.value
            break
    
        case 'UPDATE_DETAILS_MODAL':
            draft.detailsModal = action.value
            break
    
        // Filter Status
        case 'UPDATE_FILTER_STATUS':
            draft.filterStatus = action.value
            break
    
    
        // Filters
        case 'UPDATE_SELECTED_COUNTRIES':
            draft.selectedCountries = action.value
            break
        case 'UPDATE_FILTERED_COUNTRIES':
            draft.filteredCountries = action.value
            break
        case 'UPDATE_IS_COUNTRY_SELECTED':
            draft.isCountrySelected = action.value
            break
        case 'UPDATE_SELECTED_REGION':
            draft.selectedRegion = action.value
            break
        case 'UPDATE_OPEN_DROP_DOWN':
            draft.openDropDown = action.value
            break
    
        //Date filter
        case 'UPDATE_START_DATE':
            draft.startDate = action.value
            break
        case 'UPDATE_END_DATE':
            draft.endDate = action.value
            break
        case 'UPDATE_DATE_FILTER_TYPE':
            draft.dateFilterType = action.value
            break
        // AgencyFilter
        case 'UPDATE_AGENCIES':
            draft.agencies = action.value
            break
        case 'UPDATE_SELECTED_AGENCY':
            draft.selectedAgency = action.value
            break
        // Aspect Ratio Filter
        case 'UPDATE_ASPECT_RATIO':
            draft.selectedAspectRatio = action.value
            break
        // Sort type
        case 'UPDATE_SELECTED_SORT_TYPE':
            draft.selectedSortType = action.value
            break
        case 'UPDATE_SEARCHED':
            draft.searched = action.value
            break
    
        case 'CLEAR_FILTERS':
            // draft.currentPageNo = 1
            // draft.highlightWords = []
            draft.availableCountries = []
            draft.selectedCountries = []
            draft.filteredCountries = []
            draft.selectedRegion = []
            draft.dateFilterType = ''
            draft.startDate = ''
            draft.endDate = ''
            draft.selectedSortType = {name:'Relevance',type:'relevant'}
            // draft.filterStatus = 'loaded'
            break
    
        case 'UPDATED_PARAMS':
            draft.updatedParams = action.value
            break
    
        case 'SET_AD_DETAILS':
            draft.adDetails = action.value
            break
        
        case 'SET_AD_AI_CLASSIFICATION':
            draft.adAiClassification = action.value
            break
    
        case 'SET_AD_AI_CLASSIFICATION_LOADING':
            draft.adAiClassificationLoading = action.value
            break
            
        case 'SET_EMPTY_RESULT_ID':
            draft.queryId = action.value
            break
        
        case 'SET_LIMIT_EXCEEDED':
            draft.limitExceeded = action.value
            break

        case 'SET_SEARCH_PLATFORM':
            draft.limitExceeded = action.value
            break

        case 'CLEAR_ALL':
            return initialState;
                   
        default:
            break
    }
})

const useKeywordAnalysisReducer = () => {
    return useReducer(KeywordAnalysisReducer, initialState)
}

const KeywordAnalysisContext = createContext()

export const KeywordAnalysisProvider = ({ children }) => {
    const [state, dispatch] = useKeywordAnalysisReducer()
    
    const value = {
        keywordAnalysisState: state,
        keywordAnalysisDispatch: dispatch
    }
    
    return <KeywordAnalysisContext.Provider value={value}>
        {children}
    </KeywordAnalysisContext.Provider>
}

export const useKeywordAnalysis = () => {
    return useContext(KeywordAnalysisContext)
}
