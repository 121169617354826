import React from "react";
import { SurveyProvider } from "./context/survey";
import SurveyBody from "./components"
import {Helmet} from "react-helmet"

const PostLoginSurvey = () => {
    return <SurveyProvider>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Welcome to AdSpyder</title>
            <meta name="title" content="Welcome to AdSpyder" />
            <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
        </Helmet>

        <SurveyBody/>
    </SurveyProvider>
}


export default PostLoginSurvey
