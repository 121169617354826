import styled from "styled-components";

export const ContentBody = styled.div`
    padding: 40px;
    /* border: 1px solid rgb(242, 242, 242); */
    border-radius: 16px;
    background: #FFF;
    max-width: 648px;
    width: 80%;
    
`;

export const ContentHeading = styled.div`
    border-bottom: 1px solid #F2F2F2;
    color: #2E2E2E;
    font-size: 16px;
    font-weight: 500;
    padding: 16px 20px;
    width: 600px;
`;

export const NameContainer = styled.div`
    display: flex;
    margin-bottom: 16px;
    label{
        width: 158px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        color: rgba(28, 28, 28, 0.80);
        font-weight: 500;
        line-height: 26px;
        margin-right: 24px;
    }
    p{
        font-size: 16px;
        color: rgba(28, 28, 28, 0.50);
        font-weight: 500;
        line-height: 26px;
    }
`;

export const EmailContainer = styled.div`
    display: flex;
    margin-bottom: 16px;
    label{
        width: 158px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        color: rgba(28, 28, 28, 0.80);
        font-weight: 500;
        line-height: 26px;
        margin-right: 24px;
    }
    p{
        font-size: 16px;
        color: rgba(28, 28, 28, 0.50);
        font-weight: 500;
        line-height: 26px;
    }
`;

export const ChangePasswordForm = styled.form`
    
`;

export const InputContainer = styled.div`
    
`;

export const PasswordInputContainer = styled.div`
    display: flex;
    margin-bottom: 16px;

    label{
        width: 158px;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        align-items: center;
        font-size: 16px;
        color: rgba(28, 28, 28, 0.8);
        font-weight: 500;
        line-height: 26px;
        margin-right: 24px;
    }
`;

export const PasswordContainer = styled.div`
    
`;

export const PasswordInput = styled.input`
    border: 1px solid #DADFE4;
    background: #FFF;
    height: 31px;
    outline: none;
    padding: 0px 8px;
`;

export const Error = styled.p`
    color: #FF0045;
    margin-top: 6px;
    font-size: 14px;
    font-weight: 300;
`;

export const SubmitContainer = styled.div`
    
`;

export const ChangePasswordBtn = styled.button`
    font-size: 14px;
    font-weight: 400;
    color: #FF711E;
    line-height: 150%;
    outline: none;
    border: none;
    background: none;
`;

export const UpdatePassword = styled.button`
    font-size: 14px;
    font-weight: 400;
    color: #FFF;
    height: 34px;
    line-height: 150%;
    outline: none;
    margin-left: 180px;
    border-radius: 4px;
    border: 1px solid #DADFE4;
    background: #FF711E;
    padding: 0px 16px;
    outline: none;

`;


