import React, {useEffect, useRef, useState} from 'react'
import {useLocation, Route, Routes} from 'react-router-dom'
import {
    useMutation
} from "@tanstack/react-query";
import {Helmet} from "react-helmet"
import Layout from "../../layout"

import {ResultsContainer} from "./index.styled"
import { useAuth } from '../../../context/auth'
import {appClient} from '../../../utils/axios-utils'
import { AdGenratorProvider } from './context/adgenerator';
import NotFound from '../NotFoundPage';
import TextAd from './TextAd';
import ImageAd from './ImageAd';

const getAllProjects = async (data) => {
    const config = {
        url: `/adgeneration/project/all`,
        method: "GET"
    }

    return appClient(config)
}

const getProjectAds = (data) => {
    const config = {
      url: "/adgeneration/project/adcopies",
      method: "POST",
      data
    };
  
    return appClient(config);
}

const AdGenerationPage = () => {
    return <Routes>
            <Route path='*' element={
                    <NotFound />
            } />
             <Route path="/text-ad" element={
                <Layout>
                    <TextAd />
                </Layout>
            } />
            <Route path="/image-ad/*" element={
                <Layout>
                    <ImageAd />
                </Layout>
            } />
        </Routes>
    
}

const AdGenerationWrapper = () => {
    const {authState, authDispatch} = useAuth()

    return <AdGenratorProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ad Generator | AdSpyder</title>
                <meta name="title" content="Ad Generator | AdSpyder" />
                <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
            </Helmet>
            <AdGenerationPage/>
    </AdGenratorProvider>
}

export default AdGenerationWrapper