import React, { useState, useEffect, useRef } from 'react'
import { Container, Down, Row1, Row, Drop, ToolTipContainer, Wrapper, ToolTipImageContainer, ToolTip } from './index.styled'
import { useNavigate } from 'react-router-dom'

import { useAuth } from "../../../../../../../../context/auth"
import DropDownArrow from '../../../../../../../../media/Dashboard/drop-down-arrow.svg'

import { Separator } from './index.styled'
import Tooltip from 'rc-tooltip';
import { useAdGenrator } from '../../../../../context/adgenerator'


function useOutsideAlerter(ref, setShow) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setShow(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref])
}


const SubPersonaDropDown = () => {
    const { adGenratorState, adGenratorDispatch } = useAdGenrator()

    const { authState, authDispatch } = useAuth()
    const wrapperRef = useRef(null)
    const [show, setShow] = useState(false)
    const navigate = useNavigate()

    useOutsideAlerter(wrapperRef, setShow)

    return <Wrapper>
        <Container platformSelected={adGenratorState.selectedImageAdSubPersona} ref={wrapperRef}>
        
        <Drop onClick={() => { setShow(prev => !prev) }}>
            {adGenratorState.selectedImageAdSubPersona ?             
                <span><p>{adGenratorState.selectedImageAdSubPersona}</p></span>
            : <p>SubPersona</p>}
            <img className='dropdown-arrow' src={DropDownArrow} />
        </Drop>

        <Down show={show}>
            {/* <Row1>
                <p>Platform</p>
            </Row1> */}

            {adGenratorState.subPersonaData.length > 0 && adGenratorState.subPersonaData.map((data, index) => {
                return <Row key={index}
                    disabled={data.slug == adGenratorState.selectedImageAdSubPersona}
                    onClick={() => {
                        adGenratorDispatch({type:'SET_SELECTED_SUB_PERSONA', value: data.slug})
                        setShow(false)
                    }}
                >
                    <p>{data.name}</p>
                </Row>
                })}
            </Down>
        </Container>
    </Wrapper>
   
    }

export default SubPersonaDropDown

