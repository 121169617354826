import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    >div{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

`;

export const Loginbutton = styled.button`
    pointer-events: ${props => props.isActive ? "all" : "none"};
    opacity: ${props => props.isActive ? "1" : "0.5"};
    width: 400px;

    padding: 10px 10px;
    border: none;
    outline: none;
    background: #ff711e;
    color: #FFF;
    border-radius: 5px;
`;

export const LoginContainer = styled.div`
    

`;


export const PolicyContainer = styled.div`
    input{
        cursor: pointer;
    }

`;

