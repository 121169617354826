import styled from "styled-components";

export const Layout = styled.div`

`;
export const DomainSection = styled.div`
    margin-top: 60px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

export const MainHeading = styled.div`
color: #2C2C2C;
font-family: Nunito;
font-size: 34px;
font-style: normal;
font-weight: 400;
line-height: 150%;
margin-bottom: 16px;
`;

export const SubHeading = styled.div`
color: #737373;
text-align: center;
font-family: Nunito;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
letter-spacing: 0.16px;
margin-bottom: 63px;
`;

export const FormWrapper = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    max-width: 452px;
    width: 100%;
`;

export const Flex = styled.div`
display: flex;
width: 100%;
`
export const InputLabel = styled.div`
font-family: 'Roboto';
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;
color: #646464;
`;

export const InputField = styled.input`
width: 100%;
height: 52px;
padding: 14px 18px;
gap: 0px;
border-radius: 4px;
/* border: 1px solid #DADFE4; */
opacity: 0px;
color: #646464;

border: ${props => props.error ? "1px solid #FF5180" : "1px solid #ECECEC"};
    outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};
    &:focus{
        border: ${props => props.error ? "1px solid #FF5180" : "1px solid #5AB3FF"};
        outline: ${props => props.error ? "2px solid #FFB7CB" : "2px solid #B9DDFF"};
    }

`;

export const Error = styled.p`
    color: #FF0045;
    margin-top: 2px;
    font-size: 14px;
    font-weight: 300;
`;

export const BtnWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
`;


export const SubmitBtn = styled.button`
/* width: 155px; */
height: 44px;
padding: 10px 20px 10px 20px;
color: #FFFFFF;
background: #FF711E;
border-radius: 4px;
border: none; 
margin-top: 53px;
opacity: ${props => props.isDisabled ? "0.5": "1"};
pointer-events: ${props => props.isDisabled ? "none": "all"};

&:hover{
        background: #E75500;
    }

    &:active{
        background: #C74A00;
    }
`;

export const DomainError = styled.div`
    display: flex;
    align-items: flex-start;
    background: #FFF3F7;
    border: 1px solid #FFB7CB;
    border-radius: 8px;
    padding: 12px;
    font-size: 14px;
    font-weight: 300;
    color: #2E2E2E;
    line-height: 21px;
    margin-top: 20px;
    p{
        margin-left: 8px;
    }
    a{
        color: #003FBC;
    }
`;