import { Helmet } from "react-helmet";
import DashboardLayout from "../../layout";
import Root from "./components";
import { OverviewProvider } from "./context/overview";
import { OverviewContainer } from "./index.styled";

const OverviewComponent = () => {
    
    return (
        <DashboardLayout>
            <OverviewContainer>
                <Root />
            </OverviewContainer>
        </DashboardLayout>
    )
}

function OverviewProviderWrapper() {
    return ( 
        <OverviewProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Overview | AdSpyder</title>
                <meta name="title" content="Project Overview | AdSpyder" />
                <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
            </Helmet>
            <OverviewComponent />
        </OverviewProvider>
     );
}

export default OverviewProviderWrapper;