import styled from "styled-components";

export const CardContainer = styled.div`
    background: #FFFFFF;
    border:  ${props => props.detaislOpen ? "2px solid #006ECC" : "1px solid #F2F2F2"};
    border-radius: 12px;
    width: ${props => props.detaislOpen ? "520px": "250px"};
    height: 373px;
    position: relative;
    /* padding: 16px; */
`;

export const AdContainer = styled.div`
    width: 250px;
`;

export const ImageContainer = styled.div`
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    padding: 16px 16px 0px 16px;
    img{
        height: 120px;
        object-fit: scale-down;
    }
`;

export const FloatingButtonsContainer = styled.div`
    position: absolute;
    right: 12px;
    top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F9F9F9;
    color: #D4D4D4;
    font-size: 8px;
`;

export const AdBodyContainer = styled.div`
    
`;

export const ProductName = styled.p`
    color: #1A0DAB;
    font-size: 14px;
    margin-bottom: 4px;
    line-height: 21px;
    overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2; 
   -webkit-box-orient: vertical;
   min-height: 41px;
   padding: 0px 16px;
`;

export const ProductPrice = styled.div`
    margin-top: 16px;
    padding: 0px 16px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
    }
    p{
        margin-top: 8px;
        color: #707070;
        font-size: 14px;
        line-height: normal;
        font-weight: 300;
    }
`;

export const WebsiteUrl = styled.div`
    margin-top: 16px;
    padding: 0px 16px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
    }
    p{
        margin-top: 8px;
        color: #707070;
        font-size: 14px;
        line-height: normal;
        font-weight: 300;
        color: #1651C7;
        font-size: 14px;
        font-weight: 400;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        cursor: pointer;
    }
`;

export const AdBodyBottomContainer = styled.div`
    border-top: 1px solid #F2F2F2;
    padding: 16px 0px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`

export const AdDate = styled.div`
    display: flex;
    font-size: 14px;
    color: #202124;
    line-height: 22px;
    align-items: center;
    padding: 0px 16px;
    /* margin-bottom: 16px; */

    img{
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
`;

export const AdCountries = styled.div`
    display: flex;
    font-size: 14px;
    color: #202124;
    line-height: 22px;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    padding: 0px 16px;
    p{
        color: #919191;
        margin-right: 16px;
    }
    > img{
        width: 16px;
        height: 16px;
        /* margin-right: 8px; */
    }
`;

export const DetailsButton = styled.div`
    color: #006ECC;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    height: 32px;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 16px 16px 16px;
`;

export const Favorite = styled.div`
    background: #F9F9F9;
    width: 32px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    border-radius: 4px 0px 0px 4px;
    img{
        margin: 25%;
    }
    &:hover{
        background: #ECECEC;
    }
    &:active{
        background: #DCEEFF;
    }
    
`;


export const SelectButton = styled.input`
    height: 20px;
    width: 20px;
    cursor: pointer;
`;

export const AdTypeContainer = styled.div`
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
    width: max-content;
    position: absolute;
    top: -20px;
    left: 12px;
    padding: 6px;
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 5px;

    img{
        height: 12px;
    }
`;