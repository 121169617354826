import React, { useEffect } from "react";
// import { useAdManagement } from "../context/AdManagementContext";
// import GeneratingPage from "./GeneratingPage";
// import ResultPage from "./ResultPage";
import { Route, Routes } from "react-router-dom";
import InputPages from "./InputPages";
import ResultPages from "./resultPage";
import { useAdIntegrationInfo } from "../hooks/useAdManagementInfo";
import { useAdIntegration } from "../context/AdManagementContext";
import GlobalLoader from "../../../../HOC/GlobalLoader";
import { LoaderWrap } from "./index.styled";
import { useAuth } from "../../../../../context/auth";
import moment from "moment";
// import SelectAdDetails from './SelectAdAccount'
// import SelectPlatform from './InputPages/SelectPlatform'
// import { useEffect } from "react";
// import { useEffect } from "react";

const Root = () => {
  const today = moment();
  const {
    newAdManagementState: { connectByProjectId },
    newAdManagementDispatch,
  } = useAdIntegration();
  const { authState, authDispatch } = useAuth();
  const adManagementHook = useAdIntegrationInfo();

  const renderingComponent = () => {
    if (!adManagementHook.isProjectIdByConnectLoading && connectByProjectId) {

      if (connectByProjectId.details.length == 0) {
        return <InputPages />;
      } else {
        return <ResultPages />;
      }
    } else {
      return (
        <LoaderWrap>
          <GlobalLoader />
        </LoaderWrap>
      );
    }

    // if (connectedAdAccount) return <ResultPages />;
    // if(adManagementState.isAdAccount) return <SelectAdDetails />
  };

  useEffect(() => {
    let startEndDate = moment.range(
      today.clone().subtract(3, "months"),
      today.clone()
    );
    adManagementHook.setProjectIdByConnect({
      startDate: startEndDate.start.format("YYYY-MM-D"),
      endDate: startEndDate.end.format("YYYY-MM-D"),
      projectId: authState.defaultProjectInfo.projectId,
    });
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={renderingComponent()} />
        {/* <Route path="result" element={<ResultPage />} /> */}
      </Routes>
    </>
  );
};

export default Root;
