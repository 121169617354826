import styled, { keyframes } from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
`;

export const GeneratingContainer = styled.div`
    width: 966px;
    height: 544px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 33px;
    padding: 32px 40px;
    margin-top: 34px;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3.09px;
    align-items: center;
    justify-content: center;
    margin-top: 50.91px;
`;

export const Title = styled.div`
    display: flex;
    align-items: end;
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px; /* 172.727% */
`;

export const Description = styled.div`
    width: 559px;
    color: rgba(28, 28, 28, 0.50);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
`;

export const ProcessingContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    justify-content: center;
`;

export const Process = styled.div`
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #DADFE4;
    width: 658px;
    padding: 0px 23px;
`;

export const Name = styled.div`
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px; /* 237.5% */
`;

export const Icon = styled.img`

`;

const rotation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const rotationBack = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
`;

export const Spinner = styled.span`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 2px solid;
    border-color: #000 #000 transparent transparent;
    box-sizing: border-box;
    animation: ${rotation} 1s linear infinite;

    ::after, ::before {
        content: '';  
        box-sizing: border-box;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        border: 3px solid;
        border-color: transparent transparent #FF3D00 #FF3D00;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        box-sizing: border-box;
        animation: ${rotationBack} 0.5s linear infinite;
        transform-origin: center center;
    };

    ::before {
        width: 12px;
        height: 12px;
        border-color: #000 #000 transparent transparent;
        animation: ${rotation} 1.5s linear infinite;
    }
`;

export const WrongDiv = styled.div`
color: #000;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 33px */
margin-top: 8px;
`
export const WrongDescription = styled.div`
color: rgba(28, 28, 28, 0.50);
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 157.143% */
margin-top: 20px;
width: 482px;
`
export const TryAgain = styled.div`
cursor: pointer;
border-radius: 6px;
background: #FF711E;
color: #FFF;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
width: 103px;
height: 40px;
margin-top: 24px;
justify-content: center;
align-items: center;
display: flex;
`
export const WrongContainer = styled.div`
justify-content: center;
align-items: center;
display: flex;
height: 100%;
flex-direction: column;
`