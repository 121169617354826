import { useAuth } from '../../context/auth';
import { appClient } from '../../utils/axios-utils';
import {useMutation, useQuery} from "@tanstack/react-query"
import { useStripe, CardElement, useElements } from "@stripe/react-stripe-js";

const CreateSubscription = async (data) => {
    const config = {
      url: "/payment/session/checkout-session",
      method: "POST",
      data,
    };
  
    return appClient(config);
  };

  
export const useSubscription = () => {
  function popitup(url) {
    const width = 700;
    const height = 600;

    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);

    const newwindow = window.open(
      url,
      'name',
      `height=${height},width=${width},top=${top},left=${left}`
    );

    if (window.focus) {
      newwindow.focus();
    }

    return false;
  }

  const { mutate: CreateSubscriptionMutate, isLoading: isSubscriptionLoading } = useMutation(CreateSubscription, {
    onSuccess: (data) => {
      // popitup(data);
      // stripe.redirectToCheckout({ sessionId: data });
      window.open(data, '_blank').focus();
    },
    onError: (err) => {
      console.log(err);
    },
  });

  return {
    CreateSubscriptionMutate,
    isSubscriptionLoading
  };
};
