import { parseHTML } from './parseHtml';
import { v4 as uuidv4 } from 'uuid';

export const mergeArrays = (arr1, arr2) => {
    const mergedArray = [];

    // Merge items from array1
    arr1.forEach(item1 => {
        const matchingItem = arr2.find(item2 => item1.attributeName === item2.attributeName && item1.attributeValue === item2.attributeValue);
        if (matchingItem) {
            mergedArray.push({...item1, ...matchingItem});
        } else {
            mergedArray.push(item1);
        }
    });

    // Merge items from array2 that are not already in mergedArray
    arr2.forEach(item2 => {
        const matchingItem = arr1.find(item1 => item1.attributeName === item2.attributeName && item1.attributeValue === item2.attributeValue);
        if (!matchingItem) {
            mergedArray.push(item2);
        }
    });

    return mergedArray;
}

export const extractFields = (data) => {
    let htmlData = parseHTML(data.html)
    // parsedHtmlRef.current = parseHTML(data.html)

    const fields = []
    let templateScaleVal = ""

    if(htmlData.querySelector('[scale]')){
        templateScaleVal = htmlData.querySelector('[scale]').getAttribute('scale')
    }     

    htmlData.querySelectorAll('*').forEach((element) => {
        const attributes = element.attributes;

        for (let i = 0; i < attributes.length; i++) {
            const attributeName = attributes[i].name;

            if (attributeName.startsWith('input-')) {

                const uniqueId = `field-${uuidv4()}`;
                element.setAttribute('data-field-id', uniqueId)

                let maxWords = element.getAttribute("max-words")

                if (!maxWords) {
                    maxWords = null;
                }

                fields.push({
                    tagName: element.tagName,
                    attributeName,
                    attributeValue: attributes[i].value,
                    uniqueId,
                    maxWords
                })

            }
        }
    }) 


    return {
        fields, 
        templateScaleVal
    }
    
}