import React, { useEffect, useState } from 'react'
import { FileterContainer, FilterSubContainer, OverViewConatiner, OverViewHeader, Filters, ApplyButtonContainer } from './CompetitorTargetOverview.styled'
import CustomDropdown from '../custom components/CustomDropDown'
import TotalAudienceSlider from '../custom components/TotalAudienceSlider'
import PersonaList from '../PersonaList/PersonaList'
import { appClient } from '../../../../../utils/axios-utils'
import { useQuery } from '@tanstack/react-query'
import { useAuth } from '../../../../../context/auth'


const gettingPersonas = (personaId) => {
  const config = {
    url: `/persona/competitor-personas/${personaId}`,
    method: "GET",
  };

  return appClient(config);

}

const CompetitorTargetOverview = () => {
  const { authState, authDispatch } = useAuth()
  const { isLoading, error, data } = useQuery(["personas", authState.defaultProjectInfo.projectId], () => gettingPersonas(authState.defaultProjectInfo.projectId))
  const [ageCategory, setAgeCategory] = useState([]);
  const [gender, setGender] = useState('');
  const [incomeCategory, setIncomeCategory] = useState([]);
  const [totalAudience, setTotalAudience] = useState(1000);

  const ageOptions = ['18-24', '25-34', '35-44', '45-54', '55+'];
  const genderOptions = ['Male', 'Female', 'Other'];
  const incomeOptions = ['$100', '$200', '$300', '$400', '$500+'];

  useEffect(() => {
    console.log("data", data)
  }, [])

  return (
    <OverViewConatiner>
      <OverViewHeader>
        <h2>Competitor Target Personas Overview</h2>
        <div className='persona-category-count'>
          <p>Competitors added : <span>{isLoading ? "---" : data.competitorsAdded}</span></p>
          <p>Total ads analyzed : <span>{isLoading ? "---" : data.totalAdsAnalyzed}</span></p>
        </div>
      </OverViewHeader>
      <p>See Which Personas Your Competitors Are Targeting and Identify Opportunities to Enhance Your Ad Strategy</p>
      <FileterContainer>
        <FilterSubContainer>
          <Filters>
            <CustomDropdown
              options={ageOptions}
              value={ageCategory}
              onChange={setAgeCategory}
              placeholder="Select Age"
              dropdownname={"Age Category :"}
              isMulti={true}
              gap={false} />

            <CustomDropdown
              options={incomeOptions}
              value={incomeCategory}
              onChange={setIncomeCategory}
              placeholder="category"
              dropdownname={"Income Category :"}
              isMulti={true}
              gap={false}
            />

          </Filters>
          <Filters>
            <CustomDropdown
              options={genderOptions}
              value={gender}
              onChange={setGender}
              placeholder="gender"
              dropdownname={"Gender :"}
              isMulti={false}
              gap={true} />

            <TotalAudienceSlider value={totalAudience} onChange={setTotalAudience} />
          </Filters>
          <ApplyButtonContainer>
            <p>Reset filters</p>
            <button>Apply filter</button>
          </ApplyButtonContainer>
        </FilterSubContainer>
      </FileterContainer>
      {isLoading ? <h1>loading</h1> : <PersonaList data={data?.competitorPersonas} />}

    </OverViewConatiner>
  )
}

export default CompetitorTargetOverview