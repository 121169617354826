import React, { useCallback, useMemo } from "react";
import { useReducer, createContext, useContext } from "react"

import produce from 'immer'

let initialState = {
    progress: 50,
    currentStep: 1,
    // questions: [{
    //     question: "Which of these best describes your role?",
    //     step: 1
    // },{
    //     question: "Verify your phone number",
    //     step: 2
    // },{
    //     question: "Tell us more about you",
    //     step: 3
    // },{
    //     question: "Add your competitor domains to track",
    //     step: 3
    // }],
    questions: [{
            question: "Verify your phone number",
            step: 1
        }, {
            question: "Tell us more about you",
            step: 2
        },
        {
            question: "Enter a domain",
            step: 3
        }
    ],
    selectedRole: "",
    otherRole: "",
    timer: 0,
    otpSent: false,
    otpError: "",
    selectedCountryCode: "+91",
    selectedCountryFlag: "🇮🇳",
    countryDropdownOpen: false,
    numberVerified: false,
    domainsList: [],
    roles: [
        { name: "Agency", value: "Agency", active: false },
        { name: "Freelancer", value: "freelancer", active: false },
        { name: "Brand", value: "brand", active: false },
        { name: "Other", value: "", active: false }
    ],
    surveyDetails: {
        role: "",
        otherRole: "",
        phone: "",
        name: "",
        teamSize: "",
        annualRevenue: "",
        phone_verified: false,
        website: ""
    }
}

const rolesList = ["Agency", "Freelancer", "Brand"];

const SurveyReducer = produce((draft, action) => {
    switch (action.type) {
        case 'SET_PROGRESS':
            draft.progress = action.value
            break
        case 'SET_CURRENT_STEP':
            draft.currentStep = action.value
            break
        case 'UPDATE_ROLE':
            draft.surveyDetails.role = action.value
            break
        case 'UPDATE_PHONE':
            draft.surveyDetails.phone = action.value
            break
        case 'UPDATE_NAME':
            draft.surveyDetails.name = action.value
            break
        case 'UPDATE_NAME':
            draft.surveyDetails.name = action.value
            break
        case 'UPDATE_NUMBER_VERIFIED':
            draft.numberVerified = action.value
            break
        // case 'UPDATE_FIRST_NAME':
        //     draft.surveyDetails.firstName = action.value
        //     break
        // case 'UPDATE_LAST_NAME':
        //     draft.surveyDetails.lastName = action.value
        //     break    
        case 'UPDATE_TEAM_SIZE':
            draft.surveyDetails.teamSize = action.value
            break
        case 'UPDATE_ANNUAL_REVENUE':
            draft.surveyDetails.annualRevenue = action.value
            break
        case 'UPDATE_WEBSITE':
            draft.surveyDetails.website = action.value
            break
        case 'UPDATE_CURRENT_ROLE':
            draft.surveyDetails.role = action.value
            break
        case 'UPDATE_OTHER_ROLE':
            draft.surveyDetails.otherRole = action.value
            break
        case 'UPDATE_ROLE':
            draft.roles = action.roles
            break
        case 'UPDATE_COUNTRY_CODE':
            draft.selectedCountryCode = action.value
            break
        case 'UPDATE_PHONE':
            draft.phone = action.value
            break
        case 'UPDATE_COUNTRY_FLAG':
            draft.selectedCountryFlag = action.value
            break
        case 'COUNTRY_DROPDOWN_OPEN':
            draft.countryDropdownOpen = action.value
            break
        case 'SET_OTP_SENT':
            draft.otpSent = action.value
            break
        case 'SET_OTP_ERROR':
            draft.otpError = action.value
            break
        case 'UPDATE_PHONE_VERFIED':
            draft.surveyDetails.phone_verified = action.value
            break
        case 'UPDATE_DOMAINS_LIST':
            draft.domainsList = action.value
            break
        case 'SET_SURVEY_DETAILS':
            draft.surveyDetails.role = rolesList.includes(action.data.role) ? action.data.role : "Other"
            draft.surveyDetails.otherRole = rolesList.includes(action.data.role) ? "" : action.data.role
            draft.surveyDetails.name = action.data.name
            draft.surveyDetails.teamSize = action.data.teamSize
            draft.surveyDetails.annualRevenue = action.data.annualRevenue
            draft.domainsList = action.data.domainsList
            // draft.surveyDetails.phoneVerified = action.data.phoneVerified
            break
        case 'UPDATE_TIMER':
            draft.timer = action.value
            break
        default:
            break
    }
})

const useSurveyReducer = () => {
    return useReducer(SurveyReducer, initialState)
}

const SurveyContext = createContext()

export const SurveyProvider = ({ children }) => {
    const [state, dispatch] = useSurveyReducer()
    // const value = useMemo(()=>({surveyState: state,surveyDispatch: dispatch}), [state])
    const value = {
        surveyState: state,
        surveyDispatch: dispatch
    }

    return <SurveyContext.Provider value={value}>
        {children}
    </SurveyContext.Provider>
}

export const useSurvey = () => {
    return useContext(SurveyContext)
}
