import React, { useState, useEffect, useRef } from "react"
import Skeleton from "react-loading-skeleton"
import {Container, SearchWrapper, SearchContainer, SearchInput, CreativeImg, TemplateCategoryContainer, TemplateCategoryList, TemplateCategoryCard, CategoryImgContainer, RandomTemplatesContainer, RandomTemplatesList, TemplateCard, ResultsContainer, PersonaSelectionContainer, PersonaListContainer, PersonaList, SubPersonaListContainer, SubPersonaList, PersonaCard, CategorySubContainer, SubPersonaCard, ExploreButton } from "./index.styled"
import { useNavigate } from "react-router-dom"
import StarLogo from "../../../../../../media/Dashboard/startsIcon.svg"
import SearchIcon from "../../../../../../media/Dashboard/SearchIconAdGen.svg"
import CreativeLogo from "../../../../../../media/Dashboard/ImageAdGenerationCreative.svg"
import { useAuth } from "../../../../../../context/auth"
import {
    useMutation
} from "@tanstack/react-query";
import {appClient} from '../../../../../../utils/axios-utils'
 
import DentistryLogo from "../../../../../../media/Dashboard/DentistryLogo.png"
import TravelLogo from "../../../../../../media/Dashboard/TravelLogo.png"
import RealEstateLogo from "../../../../../../media/Dashboard/RealEstateLogo.png"
 
import ToothLogo from "../../../../../../media/Dashboard/ToothLogo.svg"
import PlaneLogo from "../../../../../../media/Dashboard/PlaneLogo.svg"
import HomeLogo from "../../../../../../media/Dashboard/HomeLogo.svg"
import DressLogo from "../../../../../../media/Dashboard/FashinLogo.svg"

 
import RightArrow from "../../../../../../media/Dashboard/RightArrowImageGeneration.svg"
import { useAdGenrator } from "../../../context/adgenerator"
 
const getAllCategory = async (data) => {
    const config = {
        url: `/imagead/categories`,
        method: "GET"
    }
 
    return appClient(config)
}
 
const getAllPopularTemplates = async (data) => {
    const config = {
        url: `/imagead/popular`,
        method: "GET"
    }
 
    return appClient(config)
}
 
const getPersonaData = async (data) => {
    const config = {
        url: `/imagead/personas`,
        method: "POST",
        data
    }
 
    return appClient(config)
}
 
const getSubPersonaData = async (data) => {
    const config = {
        url: `/imagead/subpersonas`,
        method: "POST",
        data
    }
 
    return appClient(config)
}
 
const HomePage = () => {
    const [popularTemplateList, setPopularTemplateList] = useState([])
    const myRef = useRef(null)
 
    const navigate = useNavigate()
    const { adGenratorState, adGenratorDispatch } = useAdGenrator()
 
    const {mutate: getAllCategoryMutate, isLoading: isAllCategoryLoading} = useMutation(getAllCategory, {
        onSuccess: (data) => {
            adGenratorDispatch({type:"SET_CATEGORY_DATA", value: data})
        },
        onError:  ((e)=>{
           
        })
    })
 
    const {mutate: getAllPopularTemplatesMutate, isLoading: isPopularTemplatesLoading} = useMutation(getAllPopularTemplates, {
        onSuccess: (data) => {
            setPopularTemplateList(data)
        },
        onError:  ((e)=>{
           
        })
    })
 
    const {mutate: getPersonaMutate, isLoading: isPersonaLoading} = useMutation(getPersonaData, {
        onSuccess: (data) => {
            adGenratorDispatch({type:"SET_PERSONA_DATA", value: data})
            executeScroll()
        },
        onError:  ((e)=>{
           
        })
    })
 
    const {mutate: getSubPersonaMutate, isLoading: isSubPersonaLoading} = useMutation(getSubPersonaData, {
        onSuccess: (data) => {
            adGenratorDispatch({type:"SET_SUB_PERSONA_DATA", value: data})
        },
        onError:  ((e)=>{
           
        })
    })
 
    let category = [
        {
            name: "Dentistry",
            slug: "dentistry",
            comingSoon: false
        },
        {
            name: "Travel",
            slug: "travel",
            comingSoon: true
        },
        {
            name: "Real estate",
            slug: "real-estate",
            comingSoon: true
        },
        {
            name: "Fashion",
            slug: "fashion",
            comingSoon: true
        }
    ]
 
    useEffect(() => {
        getAllCategoryMutate()
        getAllPopularTemplatesMutate()
    }, [])
 
    useEffect(() => {
        if(adGenratorState.selectedImageAdCategory){
            getPersonaMutate({
                categorySlug: adGenratorState.selectedImageAdCategory
            })
        }
    }, [adGenratorState.selectedImageAdCategory])
 
    useEffect(() => {
        if(adGenratorState.selectedImageAdPersona){
            getSubPersonaMutate({
                personaSlug: adGenratorState.selectedImageAdPersona
            })
        }
    }, [adGenratorState.selectedImageAdPersona])
 
    const executeScroll = () => {
        myRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        })    
    }
 
    const onCategoryClick = () => {
 
    }
 
    return <Container>
 
        <SearchWrapper backgrounfImg={CreativeLogo}>
            {/* <CreativeImg src={CreativeLogo}/>    */}
            <h1>Create Customized Image Ads for Your Business</h1>
            <h2><img src={StarLogo}/>Adspyder AI</h2>
 
            {/* <SearchContainer>  
                <img src={SearchIcon}/>
                <SearchInput
                    placeholder="Search categories like  travel, real estate, dentistry...."
                />
            </SearchContainer> */}
        </SearchWrapper>
        <TemplateCategoryContainer ref={myRef}>
            <h2>
                Browse by Category
            </h2>
            <TemplateCategoryList>
                {isAllCategoryLoading ? [...Array(4)].map(d => {
                    return  <Skeleton style={{
                        lineHeight: 10,
                        width: 224,
                      }}/>
                }) :
                    category.map((data)=>{
                        if(data.slug){
                            return <TemplateCategoryCard
                                comingSoon={data.comingSoon}
                                img={`https://image-adgeneration-assets.s3.ap-south-1.amazonaws.com/dentistry/${data.slug}.svg`}
                                onClick={()=>{
                                    adGenratorDispatch({type: 'SET_SELECTED_CATEGORY', value: data.slug})
                                    navigate(`/adgenerator/image-ad/${data.slug}`)
                                }}    
                            >
                                {data.comingSoon && <p>Coming Soon</p>}
                            </TemplateCategoryCard>
                        }
                       
                    })
                }
            </TemplateCategoryList>
        </TemplateCategoryContainer>
        {/* {adGenratorState.selectedImageAdCategory && <PersonaSelectionContainer>
            <h2>Tailor Your Ad to Perfection</h2>
            <CategorySubContainer>
                <PersonaListContainer>
                    <h3>Select your Persona on {adGenratorState.selectedImageAdCategory}</h3>
                    {isPersonaLoading ? <PersonaList>
                        <Skeleton
                            style={{
                                lineHeight: "100%",
                                width: "100%",
                            }}
                        />
                    </PersonaList> :
                    <PersonaList>
                    {  
                        adGenratorState.personaData.length > 0 && adGenratorState.personaData.map((persona)=>{
                            return <PersonaCard isSelected={adGenratorState.selectedImageAdPersona == persona.slug} onClick={()=>{
                                adGenratorDispatch({type: 'SET_SELECTED_PERSONA', value: persona.slug})
                            }}>
                                <p>{persona.slug}</p>
                                <img src={RightArrow}/>
                            </PersonaCard>
                        })
                    }
                    </PersonaList>
                    }
                </PersonaListContainer>
                {adGenratorState.selectedImageAdPersona && <SubPersonaListContainer>
                    <h3>Sub-Category</h3>
                    {isSubPersonaLoading ? <SubPersonaList>
                        <Skeleton
                            style={{
                                lineHeight: "100%",
                                width: "100%",
                            }}
                        />
                    </SubPersonaList> :
                    <SubPersonaList>
                        {
                            adGenratorState.subPersonaData.length > 0 && adGenratorState.subPersonaData.map((subpersona)=>{
                                return <SubPersonaCard onClick={()=>navigate(`/adgenerator/image-ad/${adGenratorState.selectedImageAdCategory}?persona=${adGenratorState.selectedImageAdPersona}&subpersona=${subpersona.slug}`)}>
                                    <p>{subpersona.slug}</p><ExploreButton className="explore-btn">
                                            Explore
                                        </ExploreButton>
                                </SubPersonaCard>
                            })
                        }
                    </SubPersonaList>}
                </SubPersonaListContainer>}
            </CategorySubContainer>
        </PersonaSelectionContainer>} */}
        {/* <RandomTemplatesContainer>
            <h2>
                Get inspired with our AI generated ads
            </h2>
            <RandomTemplatesList>
                {isPopularTemplatesLoading ? [...Array(8)].map(d => {
                    return  <Skeleton style={{
                        lineHeight: 14,
                        width: 232,
                      }}/>
                })  :
                popularTemplateList.map((data,idx)=>{
                    return <TemplateCard onClick={()=>navigate(`/adgenerator/image-ad/edit?id=${data.id}`)}>
                        <img src={data.variants.sort((a, b) => b.default - a.default)[0].imageUrl}/>
                    </TemplateCard>
                })}
            </RandomTemplatesList>
        </RandomTemplatesContainer> */}
    </Container>
}  
 
export default HomePage