import { useEffect, useRef, useState } from "react";
import { useImageAdGenerator } from "../../context/imageAdGeneration";
import CustomizeBar from "./components/CustomizeBar";
import Popup from "./components/popup";
import CustomizePopup from './components/customizePopup'
import LoadingScreen from "./components/LoadingScreen"

import { v4 as uuidv4 } from 'uuid';
import { parseHTML } from '../../../../../../utils/parseHtml';
import { useMutation } from "@tanstack/react-query";
import { appClient } from "../../../../../../utils/axios-utils";


import { AdTemplate, AdTemplatesContainer, Heading, ResultContainer, ResultPageContainer,PreviewImage, LeftArrow, RightArrow, AdTemplateWrapper, PreviewImageContainer } from "./index.styled";
import LeftArrowIcon from "./../../assets/leftarrow.svg"
import RightArrowIcon from "./../../assets/rightarrow.svg"
import { getSearchParams } from "../../../../../../utils/helper";
import { useLocation } from "react-router-dom";
import { useImageAdInfo } from "../../hooks/useImageAdInfo";

const getTemplateHtml = async (data) => {
    const config = {
        url: `/adgeneration/image/get-base-template/${data.id}`,
        method: "GET",
        data
    }

    return appClient(config)
}

const ResultPage = () => {
    const { imageAdGeneratorState, imageAdGeneratorDispatch } = useImageAdGenerator()
    const useImageAdInfoHook = useImageAdInfo()
    const fromOverviewRef = useRef(false)

    const [firstInstanceOfOverview, setFirstInstanceOfOverview] = useState(false)
    const templateRef = useRef();

    const location = useLocation()

    const trackRef = useRef(null)
    const parsedHtmlRef = useRef(null)

    const setActiveAdTemplate = (adTemplate) => {
        imageAdGeneratorDispatch(({
            type: "SET_ACTIVE_AD_TEMPLATE",
            value: adTemplate
        }))
    }

    const ontemplateClick = (ad, idx) => {
        if(!firstInstanceOfOverview){
            imageAdGeneratorDispatch({type: "SET_OVERVIEW_TEMPLATE_DATA", value: {}})
        }

        imageAdGeneratorDispatch({ type: "SET_CURRENT_TEMPLATE", value: idx })
        setActiveAdTemplate(ad)

        const track = trackRef.current
        if (track) {
            const itemWidth = track.scrollWidth / imageAdGeneratorState.categoryTemplateList.length
            track.scrollTo({ left: ad.id * itemWidth, behavior: 'smooth' })
        }

    }

    const renderAdsTemplate = () => {

        const handlePrevClick = () => {
            if (imageAdGeneratorState.currentTemplate > 0) {
                imageAdGeneratorDispatch({type: "SET_OVERVIEW_TEMPLATE_DATA", value: {}})
                imageAdGeneratorDispatch({ type: "SET_CURRENT_TEMPLATE", value: imageAdGeneratorState.currentTemplate - 1 })
            }
        }

        const handleNextClick = () => {
            if (imageAdGeneratorState.currentTemplate < imageAdGeneratorState.categoryTemplateList.length - 1) {
                imageAdGeneratorDispatch({type: "SET_OVERVIEW_TEMPLATE_DATA", value: {}})
                imageAdGeneratorDispatch({ type: "SET_CURRENT_TEMPLATE", value: imageAdGeneratorState.currentTemplate + 1 })
            }
        }
        
        const { activeAdTemplate } = imageAdGeneratorState

        return (
            <AdTemplatesContainer>
                <AdTemplateWrapper ref={trackRef}>
                    <LeftArrow onClick={handlePrevClick}>
                        <img src={LeftArrowIcon} />
                    </LeftArrow>
                    {imageAdGeneratorState.categoryTemplateList.map((ad, idx) => {
                        const isActive = activeAdTemplate ? activeAdTemplate.id === ad.id : idx === 0
                        return (
                            <AdTemplate ref={templateRef} isActive={isActive} onClick={() => ontemplateClick(ad, idx)} >
                                <img src={ad.url} />
                            </AdTemplate>
                        )
                    })}
                    <RightArrow onClick={handleNextClick}>
                        <img src={RightArrowIcon} />
                    </RightArrow>
                </AdTemplateWrapper>
            </AdTemplatesContainer>
        )
    }

    const renderPreview = () => {
        return (
            <PreviewImageContainer>
                <h2>
                    Preview
                </h2>
                <PreviewImage >
                    {useImageAdInfoHook.isGetAiGeneratedTextLoading && <LoadingScreen message={"Generating Content"} />}
                    <div ref={parsedHtmlRef} className="final-image" dangerouslySetInnerHTML={{ __html: `${imageAdGeneratorState.template.html}` }}></div>
                </PreviewImage>
            </PreviewImageContainer>
        )
    }

    const extractFields = (data) => {
        parsedHtmlRef.current = parseHTML(data.html)

        const fields = []

        if (parsedHtmlRef.current.querySelector('[scale]')) {
            let templateScaleVal = parsedHtmlRef.current.querySelector('[scale]').getAttribute('scale')
            imageAdGeneratorDispatch({ type: "SET_SCALE_VALUE", value: Number(templateScaleVal) })
        }

        parsedHtmlRef.current.querySelectorAll('*').forEach((element) => {
            const attributes = element.attributes;

            for (let i = 0; i < attributes.length; i++) {
                const attributeName = attributes[i].name;

                if (attributeName.startsWith('input-')) {

                    const uniqueId = `field-${uuidv4()}`;
                    element.setAttribute('data-field-id', uniqueId)

                    let maxWords = element.getAttribute("max-words")

                    if (!maxWords) {
                        maxWords = null;
                    }

                    fields.push({
                        tagName: element.tagName,
                        attributeName,
                        attributeValue: attributes[i].value,
                        uniqueId,
                        maxWords
                    })

                }
            }
        })

        imageAdGeneratorDispatch({ type: 'UPDATE_TEMPLATE', value: data })

        imageAdGeneratorDispatch({ type: 'SET_INPUT_FEILDS', value: fields })

        if (imageAdGeneratorState.overviewTemplateData.templateId) {
            setFirstInstanceOfOverview(false)
            // let htmlContent = document.querySelector(`.final-image`)
            // console.log("htmlContent", htmlContent, htmlContent.querySelector(`img`))

            // htmlContent.querySelector(`[input-img="Image"]`) && (htmlContent.querySelector(`[input-img="Image"]`).src = `${imageAdGeneratorState.overviewTemplateData.images[0].url}`)

            // const modifiedHtml = new XMLSerializer().serializeToString(htmlContent)

            // imageAdGeneratorDispatch({ type: 'UPDATE_TEMPLATE', modifiedHtml })
            useImageAdInfoHook.MergeAiTextIntoFields(fields, imageAdGeneratorState.overviewTemplateData.htmlTextContent)
            
        } else {    
            console.log(imageAdGeneratorState.categoryTemplateList, imageAdGeneratorState.currentTemplate)
            useImageAdInfoHook.GetAiGeneratedTextMutate({
                campaignId: imageAdGeneratorState.campaignId,
                templateId: imageAdGeneratorState.categoryTemplateList[imageAdGeneratorState.currentTemplate].id,
                fields: fields.filter(obj => obj.maxWords)
            })
        }
    }

    const { mutate: getTemplateHtmlMutate, isLoading: isGetTemplateHtmlLoading } = useMutation(getTemplateHtml, {
        onSuccess: (data) => {
            extractFields(data)
        },
        onError: ((e) => {

        })
    })

    const toggleColor = (color) => {
        imageAdGeneratorDispatch({ type: 'SET_ACTIVE_COLOR', value: color })
    }

    useEffect(() => {
        imageAdGeneratorDispatch({ type: "SET_CURRENT_TEMPLATE", value: null })
        imageAdGeneratorDispatch({ type: 'RESET_PAGE' })

        const { id, fromOverview } = getSearchParams(location.search)
        if (fromOverview) {
            fromOverviewRef.current = true
            setFirstInstanceOfOverview(true)
        } else {
            fromOverviewRef.current = false
        }
        imageAdGeneratorDispatch({ type: "SET_CAMPAIGNID", value: id })
        useImageAdInfoHook.GetCampaignDetailsMutate({ id })
    }, [location.search])

    useEffect(() => {
        if (imageAdGeneratorState.categoryTemplateList.length > 0) {
            if (fromOverviewRef.current) {
                useImageAdInfoHook.GetSavedSpecificAdDetailsMutate({ id: imageAdGeneratorState.campaignId })
            } else {
                imageAdGeneratorDispatch({ type: "SET_CURRENT_TEMPLATE", value: 0 })
            }
            toggleColor(imageAdGeneratorState.template.variants[0])
        }
    }, [imageAdGeneratorState.categoryTemplateList])

    useEffect(() => {
        if (imageAdGeneratorState.currentTemplate !== null) {
            imageAdGeneratorDispatch({ type: 'SET_INPUT_FEILDS', value: [] })
            ontemplateClick(imageAdGeneratorState.categoryTemplateList[imageAdGeneratorState.currentTemplate], imageAdGeneratorState.currentTemplate)
            getTemplateHtmlMutate({ id: imageAdGeneratorState.categoryTemplateList[imageAdGeneratorState.currentTemplate].id })
        }
    }, [imageAdGeneratorState.currentTemplate])

    useEffect(() => {
        if (imageAdGeneratorState.inputFields.length > 0) {

            const parsedHtml = parsedHtmlRef.current;

            imageAdGeneratorState.inputFields.forEach(item => {
                const elements = parsedHtml.querySelectorAll(`[${item.attributeName}]`);

                elements.forEach(element => {
                    const attributeValue = element.getAttribute(item.attributeName)

                    if ((attributeValue === item.attributeValue) && item.result) {
                        element.textContent = item.result;
                    }

                });
            });

            const modifiedHtml = new XMLSerializer().serializeToString(parsedHtmlRef.current)

            imageAdGeneratorDispatch({ type: 'UPDATE_HTML', modifiedHtml })
        }
    }, [imageAdGeneratorState.inputFields])

    useEffect(() => {
        let document = parseHTML(imageAdGeneratorState.template.html)

        document.querySelectorAll("*").forEach((item) => {
            const attributes = item.attributes;

            for (let i = 0; i < attributes.length; i++) {
                const attribute = attributes[i];
                const attributeName = attribute.name;

                const [prefix, type] = attributeName.split('-');

                if (prefix === 'change') {
                    switch (type) {
                        case 'background':
                            item.style.backgroundColor = imageAdGeneratorState.activeColor;
                            break;
                        case 'text':
                            item.style.color = imageAdGeneratorState.activeColor;
                            break;
                        case 'svg':
                            item.style.fill = imageAdGeneratorState.activeColor;
                            break;
                        case 'border':
                            item.style.borderColor = imageAdGeneratorState.activeColor;
                            break;
                        case 'gradient':
                            item.style.stopColor = imageAdGeneratorState.activeColor;
                            break;
                        default:
                            break;
                    }
                }
            }
        })

        const modifiedHtml = new XMLSerializer().serializeToString(document);

        imageAdGeneratorDispatch({ type: 'UPDATE_HTML', modifiedHtml });

    }, [imageAdGeneratorState.activeColor])


    return (
        <ResultPageContainer>
            <ResultContainer>
                <Heading>Results:</Heading>
                {renderAdsTemplate()}
                {renderPreview()}
            </ResultContainer>
            <CustomizeBar />
            <Popup />
            <CustomizePopup />
        </ResultPageContainer>
    );
}

export default ResultPage;