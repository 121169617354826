import React, { useRef, useEffect } from "react";
import { Container, Header, AdTypeContainer, AdTypeList, AdType, ActiveType, InactiveType, AdAccountContainer, AccountsDropDownContainer, SelectedAccount, AccountsDropDown, AccountOption, CampaignNameContainer, CampaignNameInput, ContinueButton, Footer } from "./index.styled"
import LeftArrow from "../../../../../../../media/Dashboard/backarrow.svg";
import GoogleAdsIcon from "../../../../../../../media/Dashboard/GoogleAds.svg";
import DownArrow from "../../../../../../../media/Dashboard/drop-down-arrow.svg"
import { useAdManagement } from "../../../context/AdManagementContext";
import { useOutsideAlerter } from "../../../../../../../utils/outsiderAlert";
import { useAdManagementInfo } from "../../../hooks/useAdManagementInfo";
import { useNavigate } from "react-router-dom";

const AdCampaignInput = () => {
    const { adManagementState, adManagementDispatch } = useAdManagement()
    const adManagementHook = useAdManagementInfo()
    const navigate = useNavigate()
    let wrapperRef = useRef(null)

    const setDropDown = (bool) => {
        adManagementDispatch({ type: "SET_ADACCOUNT_DROPDOWN_OPEN", value: bool })
    }

    useOutsideAlerter(wrapperRef, setDropDown)
    useEffect(()=>{
        adManagementHook.setConnectedData();
    },[])

    const renderAdType = () => {

        const adTypes = [{
            name: "Text ad",
            icon: GoogleAdsIcon
        },
        {
            name: "Display ad",
            icon: null
        },
        {
            name: "Shopiing ad",
            icon: null
        }]
        adManagementState.isConnectedData.length < 0 && navigate(`/admanagement/ad-campaigns`)

        const onAdTypeClick = (adType) => {
            // adManagementHook.setConnectedData()
            adManagementDispatch({ type: 'SET_SELECTED_ADTYPE', value: adType.name })
            adManagementDispatch({ type: 'SET_SELECTAD_TYPE_TOGGLE', value: true })
        }

        return <AdTypeContainer>
            <Header>
                <img src={LeftArrow} onClick={() => navigate(-1)}/>
                <h2>Select ad type</h2>
            </Header>
            <AdTypeList>
                {
                    adTypes.map((adType) => {
                        return <AdType isActive={adType.icon && (adManagementState.selectedAdType == adType.name)} onClick={() => adType.icon && onAdTypeClick(adType)}>
                            {adType.icon ? <ActiveType isActive={adManagementState.selectedAdType == adType.name}>
                                <img src={adType.icon} />
                                <p>{adType.name}</p>
                            </ActiveType>
                                :
                                <InactiveType>
                                    <p>{adType.name}</p>
                                    <span>Available soon</span>
                                </InactiveType>}
                        </AdType>
                    })
                }
            </AdTypeList>
        </AdTypeContainer>
    }

    const adAccounts = () => {

        let accountsList = adManagementState.isConnectedData

        const toggleDropDown = () => {
            adManagementDispatch({ type: "SET_ADACCOUNT_DROPDOWN_OPEN", value: !adManagementState.adAccountDropDownOpen })
        }



        return <AdAccountContainer>
            <Header>
            <h2>Select ad account</h2>
            </Header>
            <AccountsDropDownContainer ref={wrapperRef}>
                <SelectedAccount rotateImg={adManagementState.adAccountDropDownOpen} onClick={toggleDropDown}>
                    {adManagementState.selectedAdAccount ? adManagementState.selectedAdAccount : "Select Ad Account"}
                    <img
                        src={DownArrow}
                    />
                </SelectedAccount>
                {adManagementState.adAccountDropDownOpen && <AccountsDropDown>
                    {
                        accountsList.map((adAccount) => {
                            return <AccountOption onClick={() => {
                                adManagementDispatch({ type: "SET_SELECTED_ACCOUNT", value: adAccount.customerId })
                                adManagementDispatch({ type: "SET_ADACCOUNT_DROPDOWN_OPEN", value: false })
                            }}>
                                {adAccount.customerId}
                            </AccountOption>
                        })
                    }
                </AccountsDropDown>}
            </AccountsDropDownContainer>
        </AdAccountContainer>
    }

    const renderCampaignNameInput = () => {

        const isContinueEnabled = adManagementState.selectedAdType && adManagementState.selectedAdAccount && adManagementState.campaignName;
        const handleSearch = (event) => {
            if (event.key === 'Enter') {
                adManagementDispatch({ type: "SET_CURRENT_CAMPAIGN_STEP", value: 2 })
                adManagementDispatch({ type: "SET_ERROR_MESSAGE", value: null})
            }
        }
        return <CampaignNameContainer>
            <Header>
                <h2>Campaign name</h2>
            </Header>
            <CampaignNameInput onChange={(e) => adManagementDispatch({ type: "SET_CAMPAIGN_NAME", value: e.target.value })} 
            placeholder="Enter campaign name" onKeyDown={handleSearch} value={adManagementState.campaignName}/>
            <Footer>
                <ContinueButton
                    isActive={isContinueEnabled}
                    onClick={() =>{
                        adManagementDispatch({ type: "SET_CURRENT_CAMPAIGN_STEP", value: 2 })
                        adManagementDispatch({ type: "SET_ERROR_MESSAGE", value: null})}
                    }>
                    Continue
                </ContinueButton>
            </Footer>
        </CampaignNameContainer>
    }


    return <Container>
        {renderAdType()}
        {adManagementState.isSelectAdTypeToggle && adAccounts()}
        {adManagementState.isSelectAdTypeToggle && renderCampaignNameInput()}
    </Container>
}

export default AdCampaignInput