import { appClient } from '../../../../utils/axios-utils';
import {useMutation} from "@tanstack/react-query"
import { useLandingPageAnalysis } from '../context/landingPageAnalysis';
import { useGetUserInfo } from '../../../hooks/useGetUserInfo';

const urlDomainFilterMatch = async (data) => {

    if(data.platform == "meta"){
        data.platform = "facebook"
    }else if(data.platform == "googlepla"){
        data.platform = "google-ecom"
    }else if(data.platform == "bingpla"){
        data.platform = "bing-ecom"
    }

    const config = {
        url: `/search/${data.platform}/url/domain/filter?limit=6`,
        method: "POST",
        data: data.body
    }

    return appClient(config)
}

const urlExactFilterMatch = async (data) => {
    if(data.platform == "meta"){
        data.platform = "facebook"
    }else if(data.platform == "googlepla"){
        data.platform = "google-ecom"
    }else if(data.platform == "bingpla"){
        data.platform = "bing-ecom"
    }
    const config = {
        url: `/search/${data.platform}/url/exact/filter?limit=6`,
        method: "POST",
        data: data.body
    }

    return appClient(config)
}


export const useLandingPageAdsInfo = () => {
    const {landingPageAnalysisState, landingPageAnalysisDispatch} = useLandingPageAnalysis()
    const userInfoHook = useGetUserInfo()

    const { mutate: urlDomainFilterMutate, isLoading: isUrlDomainFilterLoading } = useMutation(urlDomainFilterMatch, {
        onSuccess: (data) => {
            landingPageAnalysisDispatch({ type: "SET_AD_COPIES", value: data.documents })
            landingPageAnalysisDispatch({ type: "SET_EMPTY_RESULT_ID", value: data.queryId})
            userInfoHook.getUserPlanData()
        },
        onError: (err) => {

        }
    })

    const { mutate: urlExactFilterMatchMutate, isLoading: isUrlExactFilterLoading } = useMutation(urlExactFilterMatch, {
        onSuccess: (data) => {
            // console.log(data)
            landingPageAnalysisDispatch({ type: "SET_AD_COPIES", value: data.documents })
            landingPageAnalysisDispatch({ type: "SET_EMPTY_RESULT_ID", value: data.queryId})
            userInfoHook.getUserPlanData()
        },
        onError: (err) => {

        }
    })

    return {
        urlDomainFilterMutate,
        isUrlDomainFilterLoading,
        urlExactFilterMatchMutate,
        isUrlExactFilterLoading
    };
};
