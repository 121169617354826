import React, { useEffect, useRef, useState } from "react";
import {
  LevelThreeDetailContainer,
  LevelThreeRow,
  GoogleSearchTermContainer,
  GoogleSearchTermHeader,
  SearchTermList,
  TargetAudienceContainer,
  CtaContainer,
  CtaHeader,
  AudienceContainer,
  AudienceHeader,
  OtherAiDetailsContainer,
  FunnelContainer,
  FunnelHeader,
  FunnelList,
  AdHealthContainer,
  AdHealthHeader,
  AdHealthData,
  CtaContent,
  ItemHeader,
  Icon,
  Title,
  KeywordsBox,
  Keyword,
  AdvancedFilter,
  Header,
  SemiBold,
  FilterLists,
  Filter,
  FilterName,
  FilterResult,
  FilterHeader,
  ProgressBarContainer,
  ProgressValue,
  InfoContainer,
  NoDataWrap,
  AdHealthInfo,
  KeywordsContainer,
  KeywordsWrap,
  KeywordsList,
} from "./index.styled";
import psychographicIcon from "../../../../../../../media/Dashboard/psychographic.svg";
import demographicIcon from "../../../../../../../media/Dashboard/demographic.svg";
import details from "../../../../../../../media/Dashboard/details.svg";
// import Tooltip from 'rc-tooltip';
import { Tooltip } from "react-tooltip";

import { appClient } from "../../../../../../../utils/axios-utils";
import { useMutation } from "@tanstack/react-query";
import Skeleton from "react-loading-skeleton";
import ApexChartFn from "../../components/GaugeChart";
import { useNavigate } from "react-router-dom";
// import Tooltip from "../../../../../../HOC/Tooltip";

const adClassification = async (id) => {
  const config = {
    url: `/detail/google/classification/${id}`,
    method: "GET",
  };

  return appClient(config);
};

const adHealthData = async (id) => {
  const config = {
    url: `/detail/google/score/${id}`,
    method: "GET",
  };

  return appClient(config);
};

const AiDetails = ({ basicDetail, adDetails, advancedFilters }) => {
  // console.log("ad details", basicDetail, adDetails)
  const [aiDetails, setAiDetails] = useState({});
  const [adHealthLoading, setAdHealthLoading] = useState(true);
  const [aiClassificationloading, setAiClassificationLoading] = useState(true);
  const [aiHealthDetails, setAiHealthDetails] = useState([]);
  const [avgScore, setAvgScore] = useState(0);
  const tooltipRefs = useRef([]);
  const navigate = useNavigate();
  const [adHealthBasedOn, setAdHealthBasedOn] = useState("");

  function findScoreByKey(arr, key) {
    const foundItem = arr.find(
      (item) => item.value === key.replace(" ", "").toLowerCase()
    );
    return foundItem ? foundItem.score : null;
  }

  const {
    mutate: adAiClassificationMutate,
    isLoading: isAiClassificationLoading,
  } = useMutation(adClassification, {
    onSuccess: (data) => {
      if (data.processed || data.id) {
        setAiDetails(data);
        setAiClassificationLoading(false);
      } else {
        setTimeout(() => {
          adAiClassificationMutate(basicDetail.id);
        }, 5000);
      }
    },
    onError: (e) => {
      setAiDetails({});
      setAiClassificationLoading(false);
    },
  });

  const { mutate: adAiHealth, isLoading: isAiHealthLoading } = useMutation(
    adHealthData,
    {
      onSuccess: (data) => {
        setAiHealthDetails(data);
        setAdHealthLoading(false);
        setAdHealthBasedOn(getAdHealthBasedOnValues(data));
      },
      onError: (error) => {
        console.error("adAiHealth Error:", error);
        setAiHealthDetails({});
      },
    }
  );

  const enableTooltip = (idx) => {
    tooltipRefs.current[idx].style.display = "unset";
  };

  const disableTooltip = (idx) => {
    tooltipRefs.current[idx].style.display = "none";
  };

  useEffect(() => {
    adAiClassificationMutate(basicDetail.id);
    if (!adDetails?.adScore) {
      setAdHealthLoading(true);
      adAiHealth(basicDetail.id);
    } else {
      setAdHealthLoading(false);
      setAdHealthBasedOn(getAdHealthBasedOnValues(adDetails.adScore));
    }

    return () => {};
  }, []);
  const getAdHealthBasedOnValues = (scoreArray) =>
    scoreArray.map((item) => item.key).join(", ");

  const getFilterLists = () => {
    return (
      <FilterLists>
        {advancedFilters.map((filter, idx) => {
          const value = basicDetail.aiAnalysis[filter].score;

          const adCopySentimentValue =
            filter === "adCopySentiment"
              ? value > 0
                ? "Positive"
                : value < 0
                ? "Negative"
                : "Neutral"
              : undefined;
          return (
            <Filter>
              <FilterHeader
                data-tooltip-id="advanced-tooltip"
                data-tooltip-content={`${basicDetail.aiAnalysis[filter].justification}`}
              >
                <FilterName>
                  {filter}
                  <InfoContainer>
                    {/* <Tooltip trigger={['hover']} overlayStyle={{width: "300px"}} overlay={<span>{basicDetail.aiAnalysis[filter].justification}</span>}> */}
                    <img src={details} />
                    <Tooltip
                      id="advanced-tooltip"
                      noArrow={true}
                      style={{
                        backgroundColor: "#101828",
                        color: "#FFF",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        borderRadius: "8px",
                        fontWeight: 600,
                        height: "34px",
                        padding: "8px 12px",
                        display: "flex",
                        alignItems: "center",
                        width: "200px",
                        height: "auto",
                        lineHeight: "17px",
                      }}
                    />
                    {/* </Tooltip> */}
                    {/* <InfoIcon onMouseEnter={() => enableTooltip(idx)} onMouseLeave={() => enableTooltip(idx)} src={details} alt="details" />
                                        <TooltipWrapper ref={el => tooltipRefs.current[idx] = el} >
                                            <Tooltip content={basicDetail.aiAnalysis[filter].justification} />
                                        </TooltipWrapper> */}
                  </InfoContainer>
                </FilterName>
                <FilterResult>{adCopySentimentValue ?? value}</FilterResult>
              </FilterHeader>
              <ProgressBarContainer>
                <ProgressValue
                  value={value}
                  adCopySentiment={adCopySentimentValue}
                />
              </ProgressBarContainer>
            </Filter>
          );
        })}
      </FilterLists>
    );
  };

  return (
    <LevelThreeDetailContainer>
      <h2>Adspyder AI analysis</h2>
      {aiClassificationloading ? (
        <LevelThreeRow>
          <Skeleton
            style={{
              lineHeight: "312px",
              width: "100%",
            }}
          />
        </LevelThreeRow>
      ) : (
        <LevelThreeRow>
          <GoogleSearchTermContainer>
            <GoogleSearchTermHeader>
              <h3>
                Google search terms
                {/* <p>
                                <img src={RedirectOrangeIcon} />
                                paid keywords
                            </p> */}
              </h3>
            </GoogleSearchTermHeader>
            {adDetails?.keywords?.length > 0 ? (
              <SearchTermList>
                {adDetails.keywords.map((term) => {
                  return (
                    <p
                      onClick={() =>
                        navigate(
                          `/keyword-overview/details?keyword=${term}&country=global`
                        )
                      }
                    >
                      {term}
                    </p>
                  );
                })}
              </SearchTermList>
            ) : (
              <NoDataWrap>
                <p>no data found</p>
              </NoDataWrap>
            )}
          </GoogleSearchTermContainer>
          <TargetAudienceContainer>
            <AudienceContainer>
              <AudienceHeader>
                <h3>Targeted Audience</h3>
              </AudienceHeader>
              <ItemHeader>
                <Icon src={demographicIcon} alt="demographic" />
                <Title>Demographic Target</Title>
              </ItemHeader>
              <KeywordsBox demographic>
                {aiDetails.demographicAudience?.length > 0 &&
                  aiDetails?.demographicAudience.map((audience) => {
                    if (audience !== "") {
                      return <Keyword>{audience}</Keyword>;
                    } else {
                      return <div>---</div>;
                    }
                  })}
              </KeywordsBox>
              <ItemHeader>
                <Icon src={psychographicIcon} alt="psychographic" />
                <Title>Psychographic Target</Title>
              </ItemHeader>
              <KeywordsBox>
                {aiDetails.psychographicAudience?.length > 0 &&
                  aiDetails?.psychographicAudience.map((audience) => {
                    if (audience !== "") {
                      return <Keyword>{audience}</Keyword>;
                    } else {
                      return <div>---</div>;
                    }
                  })}
              </KeywordsBox>
              {/* <AudienceList>
                            {aiDetails.demographicAudience?.length > 0 && aiDetails?.demographicAudience.map((audience)=>{
                                return <p><img src={demographicIcon}/>{audience}</p>
                            })}
                            {aiDetails.psychographicAudience?.length > 0 && aiDetails?.demographicAudience.map((audience)=>{
                                return <p><img src={psychographicIcon}/>{audience}</p>
                            })}
                        </AudienceList> */}
            </AudienceContainer>
          </TargetAudienceContainer>
          <KeywordsContainer>
            <h3> Keywords </h3>
            {aiDetails.keywords.length > 0 ? (
              <KeywordsList>
                {aiDetails.keywords.map((item) => (
                  <p>{item}</p>
                ))}
              </KeywordsList>
            ) : (
              <NoDataWrap>
                <p>No keywords found</p>
              </NoDataWrap>
            )}
          </KeywordsContainer>
          <OtherAiDetailsContainer>
            <FunnelContainer>
              <FunnelHeader>
                <h3>Funnel stage</h3>
              </FunnelHeader>
              <FunnelList>
                <p>{aiDetails?.funnelType}</p>
              </FunnelList>
            </FunnelContainer>
            <AdHealthContainer>
              <AdHealthHeader>
                <AdHealthInfo>
                  <h3>Ad Health</h3>
                  <img
                    src={details}
                    data-tooltip-id="health-tooltip"
                    data-tooltip-content={`Ad Health based on : ${adHealthBasedOn}`}
                  />
                  <Tooltip
                    id="health-tooltip"
                    noArrow={true}
                    style={{
                      backgroundColor: "#101828",
                      color: "#FFF",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      borderRadius: "8px",
                      fontWeight: 600,
                      height: "34px",
                      padding: "8px 12px",
                      display: "flex",
                      alignItems: "center",
                      width: "200px",
                      height: "auto",
                      lineHeight: "18px",
                    }}
                  />
                </AdHealthInfo>
                {avgScore >= 7 && (
                  <p>
                    <span>Recommended</span>
                  </p>
                )}
              </AdHealthHeader>
              <AdHealthData>
                {adHealthLoading ? (
                  <Skeleton
                    style={{
                      lineHeight: 6,
                      width: 262,
                    }}
                  />
                ) : (
                  <ApexChartFn
                    setAvgScore={setAvgScore}
                    aiHealthDetails={aiHealthDetails}
                    adDetails={adDetails}
                  />
                )}
              </AdHealthData>
            </AdHealthContainer>
          </OtherAiDetailsContainer>
          <CtaContainer>
            <CtaHeader>
              <h3>CTAs</h3>
            </CtaHeader>
            <CtaContent>{aiDetails?.ctaText}</CtaContent>
          </CtaContainer>
          {advancedFilters?.length && basicDetail.aiAnalysis ? (
            <AdvancedFilter>
              <Header>
                Advanced Filters Found -<SemiBold> “Adcopies with”</SemiBold>
              </Header>
              {getFilterLists()}
            </AdvancedFilter>
          ) : null}
        </LevelThreeRow>
      )}
    </LevelThreeDetailContainer>
  );
};

export default AiDetails;
