import React, { useEffect, useState } from "react";

import {
  Container,
  SearchContainer,
  ResultsContainer,
  Wrapper,
  HeadingWrapper,
  HeadingLeft,
  HeaderRight,
  HowItWork,
  HeadLeftWrap,
  PlatformWrapper,
  PlatformContainer,
  Platform,
  ActiveBar
} from "./index.styled";

import KeywordComponent from "./../components/Keyword";
import UrlComponent from "./../components/Url";

import PlatformRestrictedPopup from "../../../../../components/platformRestrictionPopup";
import PlanExpiredPopup from "../../../../../components/planExpiredPopup";

import { useAuth } from "../../../../../../context/auth";
import FeedbackPopup from "../../../../../components/feedbackPopup";

import GoogleLogo from '../../../../../../media/Dashboard/Google16.svg'
import BingLogo from '../../../../../../media/Dashboard/Bing16.svg'
import YoutubeLogo from '../../../../../../media/Dashboard/YouTube16.svg'
import MetaLogo from '../../../../../../media/Dashboard/meta16.png'
import LinkedinLogo from '../../../../../../media/Dashboard/LinkedIn16.svg'
import RedditLogo from '../../../../../../media/Dashboard/Reddit16.svg'
import GooglePlaLogo from '../../../../../../media/Dashboard/Googlepla16.svg'
import BingPlaLogo from '../../../../../../media/Dashboard/Bingpla16.svg'
import AmazonLogo from '../../../../../../media/Dashboard/amazon16.svg'
import DisplayLogo from '../../../../../../media/Dashboard/Display16.svg'
import UrlSearchImage from '../../../../../../media/Dashboard/UrlSearchImage.png'

import TextSearchCreative from "../../../../../../media/Dashboard/TextSearchCreative.png";
import SelectPlatform from "./SelectPlatform";
import _static from "../../../../../../config/static";
import Tooltip from 'rc-tooltip';
import LimitExceededPopup from "../../../../../components/limitExceededPopup";
import { useGetUserInfo } from "../../../../../hooks/useGetUserInfo";

const   AdspyHome = () => {
  let feedbackPopupOpen = localStorage.getItem("feedbackpopup");

  // const { searchState, searchDispatch } = useSearch();

  const { authState, authDispatch } = useAuth();

  const [popupActive, setPopupActive] = useState(false)

  const { getRemainingCreditsMutate } = useGetUserInfo()

  let headingText =
    authState.searchMode == "url" ? "URL-based" : "Text-based ";
  let descriptionText =
    authState.searchMode == "url"
      ? "Search for past ad copies across the world for a domain or URL using AdSpyder’s URL-based Ad Library. Gain access to over a billion ad copies and organise the results based on advanced filters using AI. "
      : "The Text-Based Ad Library in AdSpyder allows users to search and analyse over 1 billion+ historical ad copies across 15+ platforms. Users can also sort and filter using over 30 parameters analysed using AdSpyder’s AI. ";

    const PlatformList = [
        {
            logo: GoogleLogo,
            platform: "google",
        },{
            logo: BingLogo,
            platform: "bing",
        },{
            logo: YoutubeLogo,
            platform: "youtube",
        },{
            logo: MetaLogo,
            platform: "meta",
        },{
            logo: LinkedinLogo,
            platform: "linkedin",
        },{
            logo: RedditLogo,
            platform: "reddit"
        },{
            logo: GooglePlaLogo,
            platform: "googlepla"
        },{
            logo: BingPlaLogo,
            platform: "bingpla"
        },
        // {
        //     logo: FlipkartLogo,
        //     platform: "flipkart"
        // },
        {
            logo: AmazonLogo,
            platform: "amazon"
        },{
            logo: DisplayLogo,
            platform: "display",
        },
    ]

  const toolTipFunction = ()  =>{
    return <div>
            <div style={{marginBottom: '16px',fontSize: '14px', color: 'var(--black-100, #1C1C1C)',fontWeight: '500'}}>How to use:</div>
            <ul style={{listStyleType: 'decimal',marginLeft: '16px'}}>
              <li  style={{marginBottom: '16px'}}>Select the Platform and mode of search from the dropdowns and type in the query. To proceed, click on the “Search” button.</li>
              <li  style={{marginBottom: '16px'}}>To see further details of the ad copy, hover over to the ad copy and click on the “Ad details" button. Get ad copy details, Ad analytics and AI driven ad analysis to better understand the ad copy.</li>
              <li  style={{marginBottom: '16px'}}>Use Sort option to sort the ad copies by date or relevance. You can also use filters to view ad copies based on country and date range.</li>
              <li style={{marginBottom: '16px'}}>Click on “More Filters” option to view AI-powered filters which have over 30 parameters, and gain pin-point accuracy on the ad copies you are looking for</li>
              <li style={{marginBottom: '16px'}}>To save ad copies for future reference, click on “Save” option. You can view saved ads in the “Overview” section of your AdSpyder dashboard.</li>
            </ul>
          </div>
  }


  useEffect(() => {
    authDispatch({type: 'SET_IS_CRAWL_REQUEST_PAGE', value: false})

    authState.searchPlatform == "display"
      ? authDispatch({ type: "SET_SEARCH_MODE", value: "url" })
      : authDispatch({ type: "SET_SEARCH_MODE", value: "keyword" });
  }, [authState.searchPlatform]);

  const tooltipStyle = {
    marginTop: '100px'
  };
  
  const overlayStyle = {


  };
  const filteredPlatformList = PlatformList.filter(item => {
    if (authState.searchMode === "url") {
      return item.platform !== "youtube" &&
           item.platform !== "reddit" &&
           item.platform !== "amazon" &&
           item.platform !== "linkedin";

    } else {
      return item.platform !== "display";
    }
  });

  const overlayInnerStyle = {
    backgroundColor: '#FFF7E8',
    color: 'rgba(28, 28, 28, 0.80)',
    padding: '24px 20px 44px 20px',
    fontSize: '14px',
    marginTop: '120px',
    width: '400px',
    maxHeight: '510px',
    paddingBottom: '44px',
    overflowY: 'auto'
  };  

  useEffect(() => {
    getRemainingCreditsMutate()
  }, [])

  return (
    <Container>
      {feedbackPopupOpen && authState.feedbackPopup ? (
        /* authState.features &&
        authState.features.searchesMade &&
        authState.features.searchesMade == 5 ? (
          <FeedbackPopup type={"after-search"} />
        ) :  */(
          <FeedbackPopup type={"after-onboarding"} />
        )
      ) : (
        ""
      )}

      {authState.isRestrictedPopup && (
        <ResultsContainer>
          <PlatformRestrictedPopup />
        </ResultsContainer>
      )}

      {authState?.plan?.status?.length > 0 &&
        authState?.plan?.status != "active" &&
        authState.planExpiredPopup && (
          <ResultsContainer>
            <PlanExpiredPopup />
          </ResultsContainer>
      )}

      {authState.plan.planName.includes("basic") &&
      authState.features.allowedPlatforms.length == 0 && <SelectPlatform />}
      
      <Wrapper isNavCollapsed={authState.isNavCollapsed}>
        <HeadingWrapper>
          <HeadingLeft>
            <HeadLeftWrap>
              <h2>{headingText}</h2>
              <Tooltip 
                trigger={['hover']}  
                style={tooltipStyle}
                overlayStyle={overlayStyle}
                overlayInnerStyle={overlayInnerStyle} 
                showArrow={false}
                arrowContent={null}
                overlay={toolTipFunction()}>
              <HowItWork>Quick guide</HowItWork>
              </Tooltip>
            </HeadLeftWrap>
            <p>{descriptionText}</p>
          </HeadingLeft>
          <HeaderRight>
            { authState.searchMode == "url" ? <img src={UrlSearchImage} /> : <img src={TextSearchCreative} /> }
          </HeaderRight>
        </HeadingWrapper>
        <PlatformWrapper>
          <PlatformContainer>
            {filteredPlatformList.map((item)=>{
              return <Platform
              onClick={()=>{
                authDispatch({type: "SET_SEARCH_PLATFORM", value: item.platform})
                authDispatch({type: 'SET_SEARCH_PLATFORM_ERROR', value: false})
              }}
              platformSelected={authState.searchPlatform == item.platform ? true : false}>
                {(authState.searchPlatform != item.platform ? true : false) ? <Tooltip 
                mouseEnterDelay="0.3"
                placement="top" trigger={['hover']} overlayStyle={{width: "100"}} overlay={<span>{item.platform}</span>}>
                  <img src={item.logo}/>
                </Tooltip> : <img src={item.logo}/> }
                <p className="platform-name">{item.platform}</p>
                <ActiveBar platformSelected={authState.searchPlatform == item.platform ? true : false}/>
              </Platform> 
            })}
          </PlatformContainer>
        </PlatformWrapper>
        <SearchContainer>
          {/* <PlatformDropDown /> */}
          {authState.searchMode === "keyword" ? (
            <KeywordComponent setCreditsExhaustedPopupActive={(v) => setPopupActive(v)} />
          ) : (
            <UrlComponent setCreditsExhaustedPopupActive={(v) => setPopupActive(v)} />
          )}
        </SearchContainer>
        {popupActive && <LimitExceededPopup onClose={() => setPopupActive(false)} closeEnabled />}
      </Wrapper>
    </Container>
  );
};

export default AdspyHome;
