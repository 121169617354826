import styled from "styled-components";

export const GoogleLogin = styled.a`
    font-size: 18px;
    text-decoration: none;
    display: flex;
    color: #FFF;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    align-items: center;
    width: 100%;
    padding: 6px;
    img{
        height: 16px;
    }
    span{
        width: 92%;
        text-align: center;
    }
`;

export const SocialLoginContainer = styled.div`
    margin-bottom: 24px;
    width: 100%;
    background: #4285F4;
    border: 1px solid #ECECEC;
    border-radius: 8px;
    cursor: pointer;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    p{
        display: flex;
    }


    /* &:hover{
        background: #F9F9F9;
        border: 1px solid #ECECEC;
    } */
`;

export const LogoContainer = styled.div`
    height: 40px;
    width: 40px;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    /* margin-right: 40px; */
`;