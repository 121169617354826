import styled from 'styled-components';

// export const Card = styled.div`
//     background: #FFFFFF;
//     border: ${props => props.detailsOpen ? "2px solid #006ECC" : "1px solid #F2F2F2"} ;
//     border-radius: 12px;
//     max-width: 475px;
//     width: 95%;
//     /* width: 642px; */
//     /* min-width: 550px;
//     max-width: 642px;
//     width: 48%; */
//     max-height: 235px;
//     position: relative;
//     .hover-element{
//         display: none;
        
//     }
//     :hover{
//         .hover-element{
//             display: block;
//             transition: all 0.5s ease-in-out;   
//         }
//     }
// `;
export const VideoContainer = styled.div`
width: 100%;
    max-width: 650px;
    // height: 190px;
    // border-radius: 12px 12px 0px 0px;
`;
export const ChannelDetails = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding: 0px 16px;
    width: 70%;
`;

export const LogoContainer = styled.div`
    height: 36px;
    img{
        height: 36px;
        width: 36px;
        border-radius: 50%;

    }
`;

export const ChannelName = styled.p`
    width: 70%;
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #050505;
    display: flex;
    flex-direction: column;
    line-height: 24px;
    span{
        color: #65676B;
        font-size: 12px;
        font-weight: 300;
    }

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const IntractionDetails = styled.div`
    
`;
export const Card = styled.div`
    position: relative;
    border: ${props => props.detailsOpen ? "2px solid #006ECC" : "1px solid #F2F2F2"} ;
    border-radius: 8px;
    background: #FFF;
    width: 371px;
    height: 356px;
   
    .hover-element {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  :hover .hover-element {
    opacity: 1;
    visibility: visible;
  }
`;
export const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 16px;
    padding-bottom: 19px;
    row-gap: 14px;
    .title-wrapper-span{
        font-size: 18px;
        color: #1A0DAB;
        line-height: 27px;
        margin-bottom: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        /* max-width: 420px; */
        font-weight: 400;
        
        display: flex;
        flex-wrap: wrap;
    }

    .title-highlight-bold{
        font-weight: 400;
        font-size: 18px;
        padding: 0px 3px;
    }

    .content-wrapper-span{
        font-size: 14px;
        color: #2E2E2E;
        line-height: 22px;
        /* margin-bottom: 8px; */
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2; 
        -webkit-box-orient: vertical;
        height: 44px;
        font-weight: 300;
        display: flex;
        flex-wrap: wrap;
    }

    .content-highlight-bold{
        color: #2E2E2E;
        font-weight: 600;
        font-size: 14px;
        padding: 0px 2px;
    }
    
`;
export const Header = styled.div`
display: flex;
column-gap: 16px;
`
export const LogoWrapper = styled.div`
border-radius: 16px;
border: 1px solid #ECECEC;
background: #F1F3F4;
display: flex;
padding: 6px;
width: 32px;
height: 32px;
`
export const TitleWrap = styled.div`
display: flex;
flex-direction: column;
row-gap: 2px;
width: 100%;
`
export const ChannelDiv = styled.div`
overflow: hidden;
color: #598763;
text-overflow: ellipsis;
font-family: Inter;
font-size: 12px;
font-weight: 400;
`
export const VideoDescription = styled.p`
    font-size: 14px;
    min-height: 44px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
color: rgba(28, 28, 28, 0.50);
// white-space: nowrap;
font-family: Inter;
font-weight: 400;
line-height: 20px; 
`;
export const CountryWrap = styled.div`
display: flex;
column-gap: 12px;
align-items: center;
`
export const Country = styled.div`
overflow: hidden;
color: rgba(28, 28, 28, 0.50);
text-overflow: ellipsis;
white-space: nowrap;
font-family: Inter;
font-size: 14px;
font-weight: 400;
`
export const VideoTitle = styled.p`
    font-size: 16px;
    font-weight: 500;
    color: #2E2E2E;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    // width: 80%;
`;
export const AdUrl = styled.p`
    font-size: 12px;
    font-weight: 300;
    color: #555555;
    line-height: 21px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 420px;
`;

export const AdDetailsBody = styled.div`
    overflow-y: scroll;
    height: calc(100vh - 330px);
    position: relative;
    padding-right: 5px;
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

// export const CardBody = styled.div`
//     padding: 16px;
//     padding-bottom: 8px;
//     display: flex;
//     flex-direction: column;

//     .title-wrapper-span{
//         font-size: 18px;
//         color: #1A0DAB;
//         line-height: 27px;
//         margin-bottom: 8px;
//         overflow: hidden;
//         white-space: nowrap;
//         text-overflow: ellipsis;
//         /* max-width: 420px; */
//         font-weight: 400;
        
//         display: flex;
//         flex-wrap: wrap;
//     }

//     .title-highlight-bold{
//         font-weight: 400;
//         font-size: 18px;
//         padding: 0px 3px;
//     }

//     .content-wrapper-span{
//         font-size: 14px;
//         color: #2E2E2E;
//         line-height: 22px;
//         /* margin-bottom: 8px; */
//         overflow: hidden;
//         text-overflow: ellipsis;
//         display: -webkit-box;
//         -webkit-line-clamp: 2; /* number of lines to show */
//         line-clamp: 2; 
//         -webkit-box-orient: vertical;
//         height: 44px;
//         font-weight: 300;
//         display: flex;
//         flex-wrap: wrap;

//     }

//     .content-highlight-bold{
//         color: #2E2E2E;
//         font-weight: 600;
//         font-size: 14px;
//         padding: 0px 2px;
//     }
    
// `;

export const CardFooter = styled.div`
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #F2F2F2;
    padding: 10px 16px;
`;

export const FooterLeft = styled.div`
    display: flex;
`;

export const FooterRight = styled.div`
    display: flex;
`;

export const AdDate = styled.div`
    display: flex;
    font-size: 14px;
    margin-right: 20px;
    color: #202124;
    line-height: 22px;
    align-items: center;

    img{
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
`;

export const AdCountries = styled.div`
    display: flex;
    font-size: 14px;
    color: #202124;
    line-height: 22px;
    align-items: center;
    padding: 0px 16px;
    flex-wrap: wrap;

    p{
        color: #919191;
        margin-right: 16px;
    }
    > img{
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
`;

export const CopyImg = styled.img`
    height: 18px;
    margin: 3px 6px;
    cursor: pointer;
`;

export const HoverCard = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: #1C1C1C59;
    border-radius: 12px;
    cursor: pointer;
    padding: 16px;

    transition-duration: 0.8s;
`;

export const AdDetailsButton = styled.div`
    border-radius: 24px;
    background: #FFF;
    width: fit-content;
    display: flex;
    padding: 10px 12px;
    gap: 4px;
    justify-content: center;
    align-items: center;
`;

export const SaveAdsButton = styled.div`
    border-radius: 24px;
    background: ${props => props.isSaved ? "#1C1C1C" : "#FF711E"};
    display: flex;
    padding: 10px 12px;
    gap: 4px;
    justify-content: center;
    align-items: center;
    width: 75px;
    color: #FFF;
    /* position: absolute; */
    top: 14px;
    left: 14px;
    cursor: pointer;
`;

export const HoverCardWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
`;

export const AdHeader = styled.div`
    display: flex;
    margin-bottom: 8px;
`;

export const WebsiteLogo = styled.div`
    background: #F1F3F4;
    height: 32px;
    border-radius: 20px;
    margin-right: 12px;
    img{
        height: 20px;
        margin: 6px;
        border-radius: 10px;
    }
`;

export const HightlightTitle = styled.span`

`;

export const HightlightContent = styled.span`
    
`;

export const WebsiteNameContainer = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

export const WebsiteName = styled.div`

`;
export const VideoWrapper = styled.div`
display: flex;
height: 516px;
align-items: center;
background: #F7F7F7;
justify-content: center;
`;
export const DetailContainer = styled.div`
    margin-top: 22px;
    background: rgb(255, 255, 255);
    border-radius: 20px;
    padding-bottom: 24px;
`;

export const LevelOneDetailContainer = styled.div`
    display: flex;
    padding: 40px 40px 24px 40px;
    gap: 24px;
    // height: 438px;
    flex-direction: column;
`;

export const AdPreviewContainer = styled.div`
    width: 100%;
    /* height: 100%; */
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    position: relative;
    justify-content: space-between;
`;

export const AdContentContainer = styled.div`
    // width: 40%;
    // height: 100%;
    display: flex;
    column-gap: 14px;
    h2{
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        color: #1C1C1C;
        margin-bottom: 24px;
    }
`;

export const TitleContainer = styled.div`
    padding: 10px 14px;
    border-radius: 4px;
    border: 1px solid #E3E8EE;
    background: #FFF;
   height: 132px;
    display: flex;
    flex-direction: column;
    width: 42.2%;
    p{
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: rgba(28, 28, 28, 0.70);
        overflow: hidden;
    }
    
`;

export const TitleHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    h3{
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        color: rgba(28, 28, 28, 0.80);
    }
`;

export const DescriptionContainer = styled.div`
    padding: 10px 14px;
    border-radius: 4px;
    border: 1px solid #E3E8EE;
    background: #FFF;
   height: 132px;
    display: flex;
    flex-direction: column;
    width: 55%;
    p{
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: rgba(28, 28, 28, 0.70);
        overflow: hidden;
    }
`;

export const DescriptionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    h3{
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        color: rgba(28, 28, 28, 0.80);
    }
`;

export const LevelTwoDetailsContainer = styled.div`
    background: #F7F7F7;
    padding: 24px 40px 40px;
    h2{
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        color: rgba(28, 28, 28, 0.90);
        margin-bottom: 16px;
    }
`;

export const CopyButton = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    &:hover{
        height: 24px;
        width: 24px;
        background: rgba(28, 28, 28, 0.05);
    }
`;

export const AdPositionContainer = styled.div`
margin-top: 16px;
    border-radius: 16px;
    background: #FFF;
    padding: 24px;
    // width: 354px;
    /* width: 35%; */
    // min-height: 120px;
    width: 1008px;
    height: 249px;
`;

export const AdPositionHeader = styled.div`
    // display: flex;
    // justify-content: space-between;
    h3{
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        color: #7D7D7D;
    }
    p{
        border-radius: 18px;
        background: #01B714;
        padding: 2px 8px;
        display: flex;
        align-items: baseline;
        gap: 4px;
        width: 115px;
        img{
            height: 10px;
        }
    }
    span{
        color: #FFF;
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
    }
`;

export const AvgPosition = styled.div`
 display: flex;
//  flex-direction: column;
flex-wrap: wrap;
 font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(28, 28, 28, 0.7);
    margin-top: 13px;
    gap: 12px;
   
p{
    background: rgb(249, 249, 249);
    padding: 8px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 41px;
}   
`;

export const LevelTwoRow = styled.div`
    /* height: 248px; */
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
`;

export const AmountSpentContainer = styled.div`
    border-radius: 16px;
    background: #FFF;
    padding: 16px;
    width: 220px;
    /* width: 30%; */
    height: 122px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p{
        font-size: 14px;
        font-weight: 400;
    line-height: 22px;
    color: rgba(28, 28, 28, 0.7);
    display: flex;
padding: 8px 12px;
// justify-content: center;
align-items: center;
gap: 10px;
border-radius: 16px;
background: #F9F9F9;
    }
`;

export const OtherDetailsContainer = styled.div`
    // width: 356px;
    /* width: 35%; */
    // height: 248px;
    display: flex;
    flex-wrap: wrap;
    gap: 13px 16px;
`;

export const AmountSpentHeader = styled.div`
    display: flex;
    justify-content: space-between;
    h3{
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        color: #7D7D7D;
    }
`;

export const AmountSpent = styled.div`
    p{
        font-size: 24px;
        font-weight: 600;
        line-height: normal;
        color: rgba(28, 28, 28, 0.80);
        margin-bottom: 12px;
    }
    span{
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
        color: rgba(28, 28, 28, 0.50);
        margin-bottom: 6px;
    }
`;

export const MoneyBagLogoContainer = styled.div`
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ECFFF4;
    border-radius: 50%;
    margin-bottom: 12px;
`;

export const CountryConainer = styled.div`
    border-radius: 16px;
    background: #FFF;
    padding: 16px;
    width: 214px;
    height: 122px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p{
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
        color: rgba(28, 28, 28, 0.50);
    }
`;

export const LastSeenContainer = styled.div`
    border-radius: 16px;
    background: #FFF;
    padding: 16px;
    width: 170px;
    height: 122px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p{
        font-size: 24px;
        font-weight: 600;
        line-height: normal;
        color: rgba(28, 28, 28, 0.80);
    }

    span{
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        color: rgba(28, 28, 28, 0.40);
    }
`;

export const DestinationUrlContainer = styled.div`
    border-radius: 16px;
    background: #FFF;
    padding: 16px;
    max-width: 356px;
    height: 122px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p{
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        color: rgba(28, 28, 28, 0.50);

        display: -webkit-box;
        max-width: 300px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const CountryHeader = styled.div`
    h3{
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        color: #7D7D7D;
    }
`;

export const LastSeenHeader = styled.div`
    h3{
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        color: #7D7D7D;
    }
`;

export const DestinationUrlHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3{
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        color: #7D7D7D;
        display: flex;
        gap: 8px;
    }
    p{
        border-radius: 6px;
        background: #FFF7E8;
        width: fit-content;
        padding: 4px 8px;
        display: flex;
        gap: 4px;
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        color: #734A00;
        cursor: pointer;
        margin-left: 8px;
    }
`;

export const MapsList = styled.div`
    display: flex;
    gap: 4px;
`;

export const FlagLogo = styled.div`

`;
export const TagsWrapper = styled.div`
height: 180px;
overflow-y: auto;
`;