import styled from "styled-components";

export const MaxSizeWrapper = styled.div`
  width: 1436px;
  height: 100%;
  max-width: 100%;
  display: flex;
  margin: auto;
  flex-direction: column;

`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 50px;
`;
