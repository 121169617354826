import styled from "styled-components";

export const Container = styled.div`
    padding: 36px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const BudgetContainer = styled.div`
    label{
        color: rgba(28, 28, 28, 0.50);
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
    }
`;

export const BudgetInputContainer = styled.div`
    display: flex;
    gap: 12px;
    margin-top: 12px;
`;

export const Currency = styled.div`
justify-content: center;
    border-radius: 4px;
    border: 1px solid #E3E8EE;
    background: #F7F7F9;
    height: 44px;
    width: 137px;
    padding: 0px 16px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: rgba(28, 28, 28, 0.70);
`;

export const AmountInput = styled.input`
    height: 44px;
    width: 183px;
    font-size: 14px;
    font-weight: 400;
    color: #1C1C1C;
    border-radius: 6px;
    border: 1px solid #DADFE4;
    background: #FFF;
    outline: none;
    padding: 0px 16px;
`;

export const StrategyContainer = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
`;

export const StrategyDropDownContainer = styled.div`        
    width: 240px;
    height: 44px;
    margin-top: 12px;
    position: relative;
`;

export const SelectedStrategy = styled.div`
    height: 100%;
    width: 100%;
    padding: 12px 16px;
    border-radius: 4px;
    border: 1px solid #E3E8EE;
    background: #F7F7F9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: rgba(28, 28, 28, 0.70);
    cursor: pointer;
    img{
        transform: ${props => props.rotateImg ? "rotate(180deg)" : ""};
    }
`;

export const StrategyDropDown = styled.div`
    position: absolute;
    border-radius: 0px 0px 12px 12px;
    border: 1px solid #E3E8EE;
    background: #FFF;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.15);
    z-index: 2;
    width: 100%;
    top: 48px;
`;

export const MatchType = styled.div`
    height: 44px;
    padding: 12px 16px;
    border-bottom: solid 1px #E3E8EE;
    cursor: pointer;
`;