import React, {useState} from 'react'
import { useSearchParams, useNavigate } from "react-router-dom"

import {
    Container,
    Header,
    SearchContainer,
    Button,
    Content,
    ResultsContainer,
    CreativeContainer,
    CreativeLeft,
    CreativeRight,
    Input, 
    InputContainer, 
    ToolTipImageContainer, 
    ToolTip, 
    Wrapper, 
    ToolTipContainer,
    ModeContainer,
    KeywordButtonContainer
} from "./index.styled"


import PPCRestrictionPopup from '../../../components/PPCRestrictionPopup'

import { useAuth } from '../../../../context/auth'

import CreativeImage1 from "./../../../../media/Dashboard/keywords-creative1.svg"
import CreativeImage2 from "./../../../../media/Dashboard/keywords-creative2.svg"
import CreativeImage3 from "./../../../../media/Dashboard/keywords-creative3.svg"

import ToolTipLogo from "../../../../media/Dashboard/tooltip.svg"

import ToolTipIcon from '../../../../media/Dashboard/info_button_tool_tip.svg'
import Tooltip from 'rc-tooltip';

import { useKeywordAnalysis } from './../context/keywordanalysis'
import {urlPatternValidation} from "../../../../utils/helper"

const KeywordAnalysis = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const {keywordAnalysisState, keywordAnalysisDispatch} = useKeywordAnalysis()
    const [localSearchMode, setLocalSearchMode] = useState('domain')

    const {authState, authDispatch} = useAuth()
    
    const [url, setUrl] = useState('')
    const [error, setError] = useState(false)
    const [isValidUrl, setIsValidUrl] = useState(false)

    const navigate = useNavigate()

    const handleSearch = () => {
        if(localSearchMode == "domain"){
            if(keywordAnalysisState.isValidUrl){
                // if(!authState.features?.isKeywordAnalysisAllowed){
                //     authDispatch({type: 'UPDATE_IS_PPC_RESTRICTED', value: true})
                // }else{
                    searchParams.set('query',keywordAnalysisState.query)
                    searchParams.set('page',1)
                    searchParams.set('type','domain')
                    navigate(`/keyword-analysis/details?${searchParams.toString()}`)
                // }
            }else{
                keywordAnalysisDispatch({type: 'SET_ERROR', value: true})
            }
        }else{
            // if(!authState.features?.isKeywordAnalysisAllowed){
            //     authDispatch({type: 'UPDATE_IS_PPC_RESTRICTED', value: true})
            // }else{
                keywordAnalysisDispatch({type: 'SET_ERROR', value: false})
                searchParams.set('query',keywordAnalysisState.query)
                searchParams.set('page',1)
                searchParams.set('type','keyword')
                navigate(`/keyword-analysis/details?${searchParams.toString()}`)
            // }
        }
    }

    const handleOnEnterSearch = (event) => {
        if (event.key === 'Enter') {
            if(localSearchMode == "domain"){
                if(keywordAnalysisState.isValidUrl){
                    // if(!authState.features?.isKeywordAnalysisAllowed){
                    //     authDispatch({type: 'UPDATE_IS_PPC_RESTRICTED', value: true})
                    // }else{
                        searchParams.set('query',keywordAnalysisState.query)
                        searchParams.set('page',1)
                        searchParams.set('type','domain')
                        navigate(`/keyword-analysis/details?${searchParams.toString()}`)
                    // }
                }else{
                    keywordAnalysisDispatch({type: 'SET_ERROR', value: true})
                }
            }else{
                // if(!authState.features?.isKeywordAnalysisAllowed){
                //     authDispatch({type: 'UPDATE_IS_PPC_RESTRICTED', value: true})
                // }else{
                    keywordAnalysisDispatch({type: 'SET_ERROR', value: false})
                    searchParams.set('query',keywordAnalysisState.query)
                    searchParams.set('page',1)
                    searchParams.set('type','keyword')
                    navigate(`/keyword-analysis/details?${searchParams.toString()}`)
                // }
            }
        }   
    }


    return <Container>
                {authState.isPPCRestrictedPopup && 
                    <ResultsContainer>
                        <PPCRestrictionPopup/>
                    </ResultsContainer>
                }
                    <Header>
                        <h1>
                            Paid Keyword Analysis
                        </h1>
                        <h3>Uncover the most profitable keywords in your industry</h3>
                        <SearchContainer>
                            <ModeContainer>
                                <KeywordButtonContainer 
                                    active={localSearchMode === "keyword"} 
                                    onClick={() => setLocalSearchMode('keyword')}>
                                    <input type="radio" checked={localSearchMode === "keyword" && "checked"} id="keyword" name="mode"/>
                                    <label for="keyword">keyword</label>
                                </KeywordButtonContainer>
                                <KeywordButtonContainer 
                                    active={localSearchMode === "domain"} 
                                    onClick={() =>  setLocalSearchMode('domain')}>
                                    <input type="radio" checked={localSearchMode === "domain" && "checked"} id="domain" name="mode"/>
                                    <label for="domain">domain</label>
                                </KeywordButtonContainer>
                            </ModeContainer>
                            <Wrapper>
                                <ToolTipContainer>
                                    {localSearchMode == "keyword" ? "Enter keyword" : "Enter a domain"} <Tooltip placement="right" trigger={['hover']} overlay={<span>{localSearchMode == "keyword" ? "Enter a keyword for which you want the ads were displayed." : "Enter any domain you want the ad copies in the search results to have as a part of their redirect address."}</span>}>
                                        <img src={ToolTipIcon}/>
                                    </Tooltip>
                                </ToolTipContainer>
                                <InputContainer>
                                        <Input
                                            onKeyDown={handleOnEnterSearch}
                                            error={keywordAnalysisState.error}
                                            name="domain-url-input"
                                            type="text"
                                            placeholder={localSearchMode == "keyword" ? "Enter a PPC Keyword" : "Enter a URL"} 
                                            autoComplete="off"
                                            value={keywordAnalysisState.query}
                                            onChange={(e) => { 
                                                const isTrueVal = urlPatternValidation(e.target.value)

                                                keywordAnalysisDispatch({type: 'SET_ERROR', value: false})

                                                keywordAnalysisDispatch({type: 'SET_SEARCH_QUERY', value: e.target.value})

                                                keywordAnalysisDispatch({type: 'SET_IS_VALID_URL', value: isTrueVal})
                                            }}
                                        /> 
                                        <ToolTipImageContainer
                                            error={keywordAnalysisState.error} className="tooltip-container"
                                            >
                                            <img src={ToolTipLogo}/>
                                            <ToolTip>
                                                Please enter a url
                                            </ToolTip>
                                        </ToolTipImageContainer>
                                </InputContainer>
                            </Wrapper>
                            <Button onClick={handleSearch}>Search</Button>
                        </SearchContainer>
                    </Header>
                    <Content>
                        <CreativeContainer>
                            <CreativeLeft>
                                <img src={CreativeImage1}/>
                            </CreativeLeft>
                            <CreativeRight>
                                <h2>
                                    Discover a Domain's Keyword Blueprint
                                </h2>
                                <p>
                                    Simply enter any domain to uncover all the paid keywords fueling their ad campaigns. Dive deep into their advertising tactics with a single search.
                                </p>
                            </CreativeRight>
                        </CreativeContainer>

                        <CreativeContainer>
                            <CreativeLeft>
                                <h2>
                                    Comprehensive Keyword Insights
                                </h2>
                                <p>
                                    For every keyword, we offer insights into 10 related keywords. Additionally, discover how these keywords are distributed across various ad platforms, helping you understand market trends.
                                </p>
                            </CreativeLeft>
                            <CreativeRight>
                                <img src={CreativeImage2}/>
                            </CreativeRight>
                        </CreativeContainer>

                        <CreativeContainer>
                            <CreativeLeft>
                                <img src={CreativeImage3}/>
                            </CreativeLeft>
                            <CreativeRight>
                                <h2>
                                    Top Ad-Running Domains Revealed
                                </h2>
                                <p>
                                    Select a keyword and instantly see the top 5 domains actively advertising with it. This powerful insight is also available for each of the 10 similar keywords, letting you analyze the competitive landscape effortlessly.
                                </p>
                            </CreativeRight>
                        </CreativeContainer>
                    </Content>
                </Container>
}

export default KeywordAnalysis