import styled from "styled-components";

export const PopupContainer = styled.div`
    position: fixed;
    background: #00000045;
    left: 0;
    top: ${props => props.noMarginTop ? "0px": "54px"};
    width: 100vw;
    height: calc(100vh - ${props => props.noMarginTop ? "0px": "54px"});
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
`;

export const PopupBody = styled.div`
    max-width: 400px;
    width: 368px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px #0000003D;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 16px 0px;
        /* img{
            width: 46px;
            margin-bottom: 24px;
        } */
    h2{
        margin-bottom: 16px;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        color: #2E2E2E;       
    }
    p{
        margin-bottom: 30px;
        font-size: 14px;
        font-weight: 300;
        color: #4E4E4E;
        text-align: center;
        line-height: 21px;
    }
`;

export const PopupHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 15px;
    padding: 0px 16px;
    h2{
        font-size: 14px;
        line-height: normal;
        color: #2E2E2E;
        font-weight: 400;
    }
    input[type=checkbox]{
        height: 0;
        width: 0;
        visibility: hidden;
    }

    label {
        cursor: pointer;
        text-indent: -9999px;
        width: 36px;
        height: 20px;
        background: grey;
        display: block;
        border-radius: 100px;
        position: relative;    
    }

    label:after {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: 16px;
        height: 16px;
        background: #fff;
        border-radius: 90px;
        transition: 0.3s;
    }

    input:checked + label {
        background: #006ECC;
    }

    input:checked + label:after {
        left: calc(100% - 5px);
        transform: translateX(-100%);
    }

    label:active:after {
        /* width: 130px; */
    }
`;

export const ToggleTagsContainer = styled.div`
    display: flex;
    span{
        font-size: 14px;
        font-weight: 300;
        color: #707070;
        line-height: normal;
        margin-right: 6px;
    }
    input{
        color: #FFF;
    }
`;

export const PopupContent = styled.div`
    width: 100%;
`;

export const TagsInputContainer = styled.div`
    width: 100%;
    padding: 0px 16px;
    h3{
        font-size: 14px;
        font-weight: 300;
        color: #707070;
        line-height: normal;
        margin-bottom: 6px;
    }
`;

export const InputTags = styled.input`

`;

export const Container = styled.div`
    width: 100%;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #ECECEC;

    display: flex;
    align-items: center;
    padding: 3px 10px 3px 5px;
    z-index: 2;
    align-items: flex-start;
    justify-content: flex-end;
    padding-right: 15px;
    min-height: 100%;
    height: ${props => props.inputExpand ? "fit-content": "auto"};
    overflow: hidden;
    /* input{
        padding: 0px 8px;
        min-width: 75px;
        margin: 7px;

    } */

    /* .rti--container{
        display: ${props => props.inputExpand ? "": "contents"};
        gap: 5px 0px!important;
    } */

    /* .rti--tag{
        height: 24px!important;
        margin: 7px 3px;
        background: #555!important;
        color: white!important;
        width: max-content;
        .button{
            color: white!important;
        }
        span{
            width: max-content;
        }
    } */

    border: ${props => props.error ? "1px solid #FF5180" : "1px solid #ECECEC"};
    outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};

    &:focus{
        border: ${props => props.error ? "1px solid #FF5180" : "1px solid #5AB3FF"};
        outline: ${props => props.error ? "2px solid #FFB7CB" : "2px solid #B9DDFF"};
    }
    /* overflow-x: auto; */
`;

export const ArrowButtons = styled.div`
    width: 20px;
    height: 20px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-top: 5px;
    margin-left: 10px;
    img{
        height: 10px;
        transform: ${props => props.inputExpand ? "": "rotate(180deg)"};
    }
`

export const ToolTipImageContainer = styled.div`
    /* position: relative; */
    top: 2px;
    right: -5px;
    display: ${props => props.error ? "flex": "none"};

    
    img{
        width: 14px!important;
        margin-bottom: 0px!important;
        margin-top: 3px;
        filter: contrast(1);
    }

    &:hover{
        > div{
            display: flex;
        }

        img{
            display: block;
        }
    }
`;

export const ToolTip = styled.div`
    background: #555555;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 300;
    position: absolute;
    bottom: -34px;
    padding: 4px 8px;
    width: 210px;
    display: none;

    height: 20px;
    top: 90px;
`;


export const TagsSelectionContainer = styled.div`
    width: 100%;
    margin-top: 10px;
    padding: 0px 16px;
    h3{
        font-size: 14px;
        font-weight: 300;
        color: #707070;
        line-height: normal;
        margin-bottom: 6px;
    }
`;

export const TagsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
`;

export const Tag = styled.div`
    background: #ECECEC;
    color: #2E2E2E;
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    padding: 4px 6px;
    width: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    border-radius: 4px;
    cursor: pointer;    
`;

export const LibraryContainer = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    padding: 0px 16px;
    label{
        font-size: 14px;
        font-weight: 300;
        line-height: normal;
        color: #2E2E2E;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    input{
        margin-right: 8px;
    }
`;

export const AddToLibrabryBtn = styled.div`
    color: #006ECC;
    background: #EAF5FF;
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    padding: 6px 12px;

    width: fit-content;
    border-radius: 4px;
    cursor: pointer;

`;

export const CollectionContainer = styled.div`
    width: 100%;
    margin-top: 10px;
    h3{
        font-size: 14px;
        font-weight: 300;
        color: #707070;
        line-height: normal;
        margin-bottom: 13px;
        padding: 0px 16px;
    }
`;

export const LibraryList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 13px;
    margin-bottom: 13px;
    margin: 0px 16px;
    height: 168px;
    overflow-y: auto;
    margin-bottom: 6px;
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const Library = styled.div`
    label{
        font-size: 14px;
        font-weight: 300;
        line-height: normal;
        color: #2E2E2E;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    input{
        margin-right: 8px;
    }

`;

export const AddLibrary = styled.div`
    display: flex;
    font-size: 14px;
    line-height: normal;
    font-weight: 500;
    color: #2E2E2E;
    margin-top: 6px;
    cursor: pointer;
    align-items: center;
    padding: 0px 16px;
    margin-bottom: 8px;
`;

export const PlusIcon = styled.div`
    margin-right: 8px;
    height: 16px;
    width: 16px;
    background: #E2E2E2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
`;

export const AddLibraryInputContainer = styled.div`
    padding: 13px 16px;
    background: #F9F9F9;
    h3{
        padding: 0px;
    }
`;

export const NameInput = styled.input`
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    padding: 6px 12px;
    outline: none;
    border: 1px solid rgb(236, 236, 236);
    border-radius: 4px;
    margin-top: 6px;
    margin-bottom: 13px;
`;

export const AddLibraryFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const AddLibraryCancel = styled.div`
    width: fit-content;
    font-size: 14px;
    color: #2E2E2E;
    font-weight: 300;
    line-height: normal;
    padding: 7px 13px;
    border-radius: 4px;
    cursor: pointer;
`;

export const CreateLibraryButton = styled.div`
    width: fit-content;
    color: ${props => props.isDisabled ? "#D4D4D4" : "#006ECC"};
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    cursor: pointer;
    background: ${props => props.isDisabled ? "#F2F2F2" : "#EAF5FF"};
    pointer-events: ${props => props.isDisabled ? "none" : "auto"};
    padding: 7px 13px;
    border-radius: 4px;
`;

export const Footer = styled.div`
    margin: 13px 13px 0px;
    display: flex;
    justify-content: flex-end;
`;

export const CancelPopup = styled.div`
    width: fit-content;
    font-size: 14px;
    color: #2E2E2E;
    font-weight: 300;
    line-height: normal;
    padding: 7px 13px;
    border-radius: 4px;
    cursor: pointer;
`;

export const SaveCollectionButton = styled.div`
    width: fit-content;
    color: #FFF;
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    cursor: pointer;
    background: ${props => props.isDisabled ? "#D4D4D4" : "#006ECC"};
    pointer-events: ${props => props.isDisabled ? "none" : "auto"};
    padding: 7px 13px;
    border-radius: 4px;
`;

