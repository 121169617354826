// import styled from "styled-components";

// export const PopupBodyContainer = styled.div`
//     width: 250px;
//     /* height: 444px; */
//     height: ${props => props.height+"vh"};
//     position: fixed;
//     background: #FFFFFF 0% 0% no-repeat padding-box;
//     box-shadow: 0px 2px 6px #0000001A;
//     border-radius: 8px;
//     padding: 16px;
//     z-index: 2;
//     /* right: 0; */
//     /* top: 52px; */
//     left: ${props => props.left+"px"};
//     top: ${props => props.top+"px"};
// `;

// export const BodyHeader = styled.div`
//     margin-bottom: 18px;
//     display: flex;
//     justify-content: space-between;
//     flex-direction: column;
// `;

// export const CountrySearchBar = styled.input`
//     background: #FFFFFF 0% 0% no-repeat padding-box;
//     border: 1px solid #ECECEC;
//     border-radius: 8px;
//     height: 32px;
//     outline: none;
//     padding: 6px 12px;
//     color: rgb(46, 46, 46);
//     font-size: 14px;
//     font-weight: 300;
//     width: 100%!important;
//     margin-bottom: 10px;
// `;

// export const ClearSection = styled.div`
//     background: #F6F6F6 0% 0% no-repeat padding-box;
//     border-radius: 8px;
//     padding: 6px 12px;
//     color: #2E2E2E;
//     cursor: pointer;
//     font-size: 14px;

//     &:hover{
//         background: #ECECEC 0% 0% no-repeat padding-box;
//     }
//     &:active{
//         background: #E2E2E2 0% 0% no-repeat padding-box;
//     }
// `;

// export const CountriesListContainer = styled.div`
//     /* height: 320px; */
//     height: calc(100% - 100px);
//     overflow-y: scroll;
//     ::-webkit-scrollbar-track
//     {
//         -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 11%);
//         box-shadow: inset 0 0 6px rgb(0 0 0 / 11%);
//         background-color: #f5f5f570;
//         border-radius: 5px
//     }

//     ::-webkit-scrollbar
//     {
//         width: 5px;
//         background-color: #F5F5F5;
//         border-radius: 5px;
//     }

//     ::-webkit-scrollbar-thumb
//     {
//         background-color: #00000040;
//         border-radius: 5px;
//     }
// `;

// export const CheckboxGroup = styled.div`

//     > label{
//         margin-left: 8px;
//         cursor: pointer;
//     }
//     .region-name{
//         margin-bottom: 8px;
//     }
//     margin-bottom: 18px;
// `;

// export const CheckBoxContainer = styled.div`
    
//     background: ${props => props.checked ? "#DCEEFF" : "white"};
//     /* border: 1px solid #F2F2F2; */
//     border-radius: 8px;
//     padding: 6px 12px;
//     display: flex;
//     align-items: center;
//     color: #707070;
//     font-size: 14px;
//     font-weight: 300;
//     cursor: pointer;

//     label{
//         cursor: pointer;
//         display: flex;
//     }
//     .ant-checkbox-wrapper-checked{
//         background: #EEF7FF 0% 0% no-repeat padding-box;
//         color: #12558D;
//     }
//     p{
//         text-align: left;
//     }
//     :hover {
//         background-color: #F6F6F6;
//     }
// `;

// export const Checkbox = styled.input`
//     margin-right: 8px;
//     cursor: pointer;
// `;

// export const CountriesContainer = styled.div`
//     display: flex;
//     flex-direction: row;
//     flex-direction: column;   
//     gap: 8px;
//     margin-top: 14px;
//     /* margin-bottom: 8px; */
// `;

// export const BodyFooter = styled.div`
//     margin-top: 12px;
//     display: flex;
//     justify-content: flex-end;
//     height: 32px;
// `;

// export const CancelButton = styled.button`
//     outline: none;
//     color: #2E2E2E;
//     border-radius: 8px;
//     padding: 6px 12px;
//     cursor: pointer;
//     border: none;
//     font-size: 14px;
//     &:hover{
//         background: #F2F2F2 0% 0% no-repeat padding-box;
//     }
//     &:active{
//         background: #ECECEC 0% 0% no-repeat padding-box;
//     }
//     &:disabled{
//         /* bac */
//     }
// `;

// export const ApplyButton = styled.button`
//     outline: none;
//     background: #006ECC 0% 0% no-repeat padding-box;
//     border-radius: 8px;
//     color: #FFFFFF;
//     padding: 6px 12px;
//     cursor: pointer;
//     border: none;
//     margin-left: 8px;
//     font-size: 14px;
//     &:hover{
//         background: #0059A5 0% 0% no-repeat padding-box;
//     }
//     &:active{
//         background: #004A89 0% 0% no-repeat padding-box;
//     }
//     &:disabled{
//         background: #D4D4D4 0% 0% no-repeat padding-box;
//         color: #FFFFFF;
//     }
// `;


// export const DropdownContainer = styled.div`
//     background: ${props => props.isSelected ? "#F6FBFF" : "#FFFFFF"};
//     border: ${props => !props.isSelected ? "1px solid #ECECEC" : "1px solid #B8D9F5"};
//     border-radius: 8px;

//     height:44px;
//     display: flex;

//     position: relative;
//     width: 125px;
//     &:hover{
//         background: ${props => props.isSelected ? "#E8F4FF" : ""};
//     }
// `;

// export const DropdownButton = styled.div`
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     cursor: pointer;
//     width: 100%;
//     padding: 6px 12px;
//     span{
//         font-size: 14px;
//         color: rgba(28, 28, 28, 0.70);
//         font-weight: 400;
//         line-height: 20px;
//         color: rgba(28, 28, 28, 0.70);
//     }

// `;

// export const DropdownOptions = styled.div`
//     position: absolute;
//     top: 34px;
//     z-index: 5;
//     width: 100%;
//     left: 0;
//     background: #FFFFFF 0% 0% no-repeat padding-box;
//     border: 1px solid #ECECEC;
//     border-radius: 8px;
//     padding: 4px;
// `;


// export const DropDownImage = styled.div`
//     img{
//         height: 11px;
//         margin-left: 8px;
//     }    
// `;

// export const CrossImageContainer = styled.div`
//     height: 18px;
//     width: 18px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin-left: 4px;
//     border-radius: 10px;
//     img{
//         height: 12px;
//     }
//     &:hover{
//         background: #A8D0EF;
//     }
// `;


// import styled from "styled-components";

// export const PopupBodyContainer = styled.div`
//     width: 250px;
//     /* height: 444px; */
//     height: ${props => props.height+"vh"};
//     position: fixed;
//     background: #FFFFFF 0% 0% no-repeat padding-box;
//     box-shadow: 0px 2px 6px #0000001A;
//     border-radius: 8px;
//     padding: 16px;
//     z-index: 2;
//     /* right: 0; */
//     /* top: 52px; */
//     left: ${props => props.left+"px"};
//     top: ${props => props.top+"px"};
// `;

// export const BodyHeader = styled.div`
//     margin-bottom: 18px;
//     display: flex;
//     justify-content: space-between;
//     flex-direction: column;
// `;

// export const CountrySearchBar = styled.input`
//     background: #FFFFFF 0% 0% no-repeat padding-box;
//     border: 1px solid #ECECEC;
//     border-radius: 8px;
//     height: 32px;
//     outline: none;
//     padding: 6px 12px;
//     color: rgb(46, 46, 46);
//     font-size: 14px;
//     font-weight: 300;
//     width: 100%!important;
//     margin-bottom: 10px;
// `;

// export const ClearSection = styled.div`
//     background: #F6F6F6 0% 0% no-repeat padding-box;
//     border-radius: 8px;
//     padding: 6px 12px;
//     color: #2E2E2E;
//     cursor: pointer;
//     font-size: 14px;

//     &:hover{
//         background: #ECECEC 0% 0% no-repeat padding-box;
//     }
//     &:active{
//         background: #E2E2E2 0% 0% no-repeat padding-box;
//     }
// `;

// export const CountriesListContainer = styled.div`
//     /* height: 320px; */
//     height: calc(100% - 100px);
//     overflow-y: scroll;
//     ::-webkit-scrollbar-track
//     {
//         -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 11%);
//         box-shadow: inset 0 0 6px rgb(0 0 0 / 11%);
//         background-color: #f5f5f570;
//         border-radius: 5px
//     }

//     ::-webkit-scrollbar
//     {
//         width: 5px;
//         background-color: #F5F5F5;
//         border-radius: 5px;
//     }

//     ::-webkit-scrollbar-thumb
//     {
//         background-color: #00000040;
//         border-radius: 5px;
//     }
// `;

// export const CheckboxGroup = styled.div`

//     > label{
//         margin-left: 8px;
//         cursor: pointer;
//     }
//     .region-name{
//         margin-bottom: 8px;
//     }
//     margin-bottom: 18px;
// `;

// export const CheckBoxContainer = styled.div`
    
//     background: ${props => props.checked ? "#DCEEFF" : "white"};
//     /* border: 1px solid #F2F2F2; */
//     border-radius: 8px;
//     padding: 6px 12px;
//     display: flex;
//     align-items: center;
//     color: #707070;
//     font-size: 14px;
//     font-weight: 300;
//     cursor: pointer;

//     label{
//         cursor: pointer;
//         display: flex;
//     }
//     .ant-checkbox-wrapper-checked{
//         background: #EEF7FF 0% 0% no-repeat padding-box;
//         color: #12558D;
//     }
//     p{
//         text-align: left;
//     }
//     :hover {
//         background-color: #F6F6F6;
//     }
// `;

// export const Checkbox = styled.input`
//     margin-right: 8px;
//     cursor: pointer;
// `;

// export const CountriesContainer = styled.div`
//     display: flex;
//     flex-direction: row;
//     flex-direction: column;   
//     gap: 8px;
//     margin-top: 14px;
//     /* margin-bottom: 8px; */
// `;

// export const BodyFooter = styled.div`
//     margin-top: 12px;
//     display: flex;
//     justify-content: flex-end;
//     height: 32px;
// `;

// export const CancelButton = styled.button`
//     outline: none;
//     color: #2E2E2E;
//     border-radius: 8px;
//     padding: 6px 12px;
//     cursor: pointer;
//     border: none;
//     font-size: 14px;
//     &:hover{
//         background: #F2F2F2 0% 0% no-repeat padding-box;
//     }
//     &:active{
//         background: #ECECEC 0% 0% no-repeat padding-box;
//     }
//     &:disabled{
//         /* bac */
//     }
// `;

// export const ApplyButton = styled.button`
//     outline: none;
//     background: #006ECC 0% 0% no-repeat padding-box;
//     border-radius: 8px;
//     color: #FFFFFF;
//     padding: 6px 12px;
//     cursor: pointer;
//     border: none;
//     margin-left: 8px;
//     font-size: 14px;
//     &:hover{
//         background: #0059A5 0% 0% no-repeat padding-box;
//     }
//     &:active{
//         background: #004A89 0% 0% no-repeat padding-box;
//     }
//     &:disabled{
//         background: #D4D4D4 0% 0% no-repeat padding-box;
//         color: #FFFFFF;
//     }
// `;


// export const DropdownContainer = styled.div`
//     background: ${props => props.isSelected ? "#F6FBFF" : "#FFFFFF"};
//     border: ${props => !props.isSelected ? "1px solid #ECECEC" : "1px solid #B8D9F5"};
//     border-radius: 8px;

//     height: 44px;
//     display: flex;

//     position: relative;
//     width: 125px;
//     &:hover{
//         background: ${props => props.isSelected ? "#E8F4FF" : ""};
//     }
// `;

// export const DropdownButton = styled.div`
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     cursor: pointer;
//     width: 100%;
//     padding: 6px 12px;
//     span{
//         font-size: 14px;
//         color: rgba(28, 28, 28, 0.70);
//         font-weight: 400;
//         line-height: 20px;
//         color: rgba(28, 28, 28, 0.70);
//         &.globe{
//             display: flex;
//     column-gap: 8px 
//         }
//     }

// `;

// export const DropdownOptions = styled.div`
//     position: absolute;
//     top: 34px;
//     z-index: 5;
//     width: 100%;
//     left: 0;
//     background: #FFFFFF 0% 0% no-repeat padding-box;
//     border: 1px solid #ECECEC;
//     border-radius: 8px;
//     padding: 4px;
// `;


// export const DropDownImage = styled.div`
//     img{
//         height: 11px;
//         margin-left: 8px;
//     }    
// `;

// export const CrossImageContainer = styled.div`
//     height: 18px;
//     width: 18px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin-left: 4px;
//     border-radius: 10px;
//     img{
//         height: 12px;
//     }
//     &:hover{
//         background: #A8D0EF;
//     }
// `;


import styled from "styled-components";

export const PopupBodyContainer = styled.div`
    width: 254px;
    /* height: 444px; */
    height: ${props => props.height+"vh"};
    position: fixed;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #0000001A;
    border-radius: 8px;
    padding: 4px;
    z-index: 2;
    /* right: 0; */
    /* top: 52px; */
    left: ${props => props.left+"px"};
    top: ${props => props.top+"px"};
`;

export const BodyHeader = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

export const CountrySearchBar = styled.input`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #ECECEC;
    border-radius: 8px;
    height: 32px;
    outline: none;
    padding: 6px 12px;
    color: rgb(46, 46, 46);
    font-size: 14px;
    font-weight: 300;
    width: 100%!important;
    margin-bottom: 10px;
`;


export const CountriesListContainer = styled.div`
    /* height: 320px; */
    height: calc(100% - 100px);
    overflow-y: scroll;
    ::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 11%);
        box-shadow: inset 0 0 6px rgb(0 0 0 / 11%);
        background-color: #f5f5f570;
        border-radius: 5px
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: #F5F5F5;
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: #00000040;
        border-radius: 5px;
    }
`;


export const CheckBoxContainer = styled.div`
    
    background: ${props => props.checked ? "#DCEEFF" : "white"};
    /* border: 1px solid #F2F2F2; */
    border-radius: 8px;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    color: #707070;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;

    label{
        cursor: pointer;
        display: flex;
    }
    .ant-checkbox-wrapper-checked{
        background: #EEF7FF 0% 0% no-repeat padding-box;
        color: #12558D;
    }
    p{
        text-align: left;
    }
    :hover {
        background-color: #F6F6F6;
    }
`;


export const CountriesContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-direction: column;   
    gap: 8px;
    /* margin-top: 14px; */
    /* margin-bottom: 8px; */
`;

export const Country = styled.div`
display: flex;
align-items: center;
gap: 8px;
height: 44px;
cursor: pointer;
:hover{
    background: #F9FAFB;
}
padding: 0 8px;
`;

export const FlagContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 20px;
height: 20px;
`;

export const Flag = styled.img`
height: 20px;
width: 20px;
border-radius: 50%;
`;

export const CountryName = styled.div`
color: rgba(28, 28, 28, 0.90);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
`;



export const DropdownContainer = styled.div`
    // background: ${props => props.isSelected ? "#F6FBFF" : "#FFFFFF"};
    // border: ${props => !props.isSelected ? "1px solid #ECECEC" : "1px solid #B8D9F5"};
    border-radius: 8px;
    border: ${(props) => props.isDropdownOpened ? "1px solid #FFB258" : "1px solid #D0D5DD" };
    box-shadow: ${(props) => props.isDropdownOpened ? "0px 1px 2px 0px rgba(28, 28, 28, 0.05), 0px 0px 4px 0px rgba(255, 178, 88, 0.25)" : "unset"};
    background: #FFF;

    height: 44px;
    display: flex;

    position: relative;
    max-width: 140px;
    // &:hover{
    //     background: ${props => props.isSelected ? "#E8F4FF" : ""};
    // }
`;

export const DropdownButton = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 125px;
    padding: 0px 8px;
    span{
        color: rgba(28, 28, 28, 0.50);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        span{
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

`;



export const DropDownImage = styled.div`
    img{
        height: 11px;
        margin-left: 8px;
    }    
`;

