import { useTextAdGenerator } from "../../../context/textAdGeneration";
import { Copy, CopyButtonContainer, Description, DescriptionListContainer } from "./DescriptionList.styled";
import copy from "../../../assets/copy.svg"
import { useRef } from "react";
import { customToast } from "../../../../../../../utils/toast-utils";

function DescriptionList() {
    const { textAdGeneratorState, textAdGeneratorDispatch } = useTextAdGenerator()
    const { activeAdTemplate } = textAdGeneratorState

    const copyRefs = useRef([])
    const handleMouseEnter = (idx) => {
        const copyRef = copyRefs.current[idx];
        if (copyRef) {
            copyRef.style.display = "flex";
        }
    }

    const handleMouseLeave = (idx) => {
        const copyRef = copyRefs.current[idx];
        if (copyRef) {
            copyRef.style.display = "none";
        }
    }

    const handleDescriptionClick = (description) => {
        navigator.clipboard.writeText(description).then(() => {
            customToast({message: "Selected description copied to your clipboard"})
        }).catch((err) => {
            console.error('Failed to copy:', err);
        });
    }

    return ( 
        <DescriptionListContainer>
            {
                activeAdTemplate?.descriptions.map((desc, idx) => {
                    copyRefs.current[idx] = null; 
                    return(
                        <Description key={idx} onMouseEnter={() => handleMouseEnter(idx)} onMouseLeave={() => handleMouseLeave(idx)} onClick={() => handleDescriptionClick(desc)}>
                             {desc}
                            <CopyButtonContainer ref={(el) => (copyRefs.current[idx] = el)}>
                                <Copy src={copy} alt="copy" />
                            </CopyButtonContainer>
                        </Description>
                    )
                })
            }
        </DescriptionListContainer>
     );
}

export default DescriptionList;