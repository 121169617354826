import React, {useEffect} from "react"
import styled from "styled-components"
import {useMutation} from '@tanstack/react-query'
import { useSearchParams, useNavigate } from "react-router-dom"

import UrlSearchType from "./Type"
import UrlInput from "./Input"

// import { useSearch } from "../../context/search"
import _static from "../../../../../../config/static"
import { useAuth } from "../../../../../../context/auth"
import { useGetUserInfo } from "../../../../../hooks/useGetUserInfo"


const Button = styled.button `
    width: 75px;
    height: 40px;
    margin-left: 8px;

    border-radius: 0px 8px 8px 0px;
    background-color: ${props => props.isDisabled ? "#E7E7E7" : "#FF711E"};
    pointer-events: ${props => props.isDisabled ? "none": "all"};
    color: white;
    font-size: 14px;
    border: none;
    cursor: pointer;
    /* margin-top: 20px; */
    :hover {
        filter: brightness(90%);
    }
`;


const UrlComponent = ({setCreditLimitReachedPopup}) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const {authState, authDispatch} = useAuth()
    const navigate = useNavigate()

    // const { searchState, searchDispatch} = useSearch()
    const { canAccessFeature } = useGetUserInfo()
    const handleSearch = () => {
        if(!canAccessFeature({type: "adspy"})){
            setCreditLimitReachedPopup(true)
            return
        }
        const {searchMode} = authState

        authDispatch({type: "CLEAR_FILTERS"})

        if(!authState.searchPlatform){
            return authDispatch({type: 'SET_SEARCH_PLATFORM_ERROR', value: true})
        }

        if (searchMode === "url") {
            const {urlSearchType} = authState
            authDispatch({type: "CLEAR_FILTERS"})

            if (urlSearchType === "exact") {
                const {domainUrlInput} = authState

                if (domainUrlInput.valid) {
                    authDispatch({type: "SET_HIGHLIGHT_WORDS", value: []})
                    authDispatch({type: 'SET_URL_INPUT_ERROR', value: false})

                    searchParams.set('searchMode',"url")
                    searchParams.set('urlSearchType',"exact")
                    searchParams.set('domainUrlInput', domainUrlInput.value);
                    searchParams.set('page', 1);
                    searchParams.set('sortType', 'relevant');
                    searchParams.delete('language');
                    searchParams.delete('selectedCountries');
                    searchParams.delete('startDate');
                    searchParams.delete('endDate'); 
                    searchParams.delete('cta'); 
                    searchParams.delete('tone'); 
                    searchParams.delete('otherFilters'); 
                    searchParams.delete('sentiment'); 
                    searchParams.delete('competitorsArray'); 
                    searchParams.delete('keywordsArray'); 
                    searchParams.delete('sortArray'); 
                    // setSearchParams(searchParams)
                    navigate(`/adspy/${authState.searchPlatform}?${searchParams.toString()}`)

                } else {
                    authDispatch({type: 'SET_URL_INPUT_ERROR', value: true})
                }
            } else if (urlSearchType === "domain") {
                const {domainUrlInput} = authState
                
                if (domainUrlInput.valid) {
                    authDispatch({type: "SET_HIGHLIGHT_WORDS", value: []})
                    authDispatch({type: 'SET_URL_INPUT_ERROR', value: false})

                    searchParams.set('searchMode',"url")
                    searchParams.set('urlSearchType',"domain")
                    searchParams.set('domainUrlInput', domainUrlInput.value);
                    searchParams.set('page', 1);
                    searchParams.set('sortType', 'relevant');
                    searchParams.delete('selectedCountries');
                    searchParams.delete('language');
                    searchParams.delete('startDate');
                    searchParams.delete('endDate'); 
                    searchParams.delete('cta'); 
                    searchParams.delete('tone'); 
                    searchParams.delete('otherFilters'); 
                    searchParams.delete('sentiment'); 
                    searchParams.delete('competitorsArray'); 
                    searchParams.delete('keywordsArray'); 
                    searchParams.delete('sortArray'); 
                    // setSearchParams(searchParams)
                    navigate(`/adspy/${authState.searchPlatform}?${searchParams.toString()}`)

                } else {
                    authDispatch({type: 'SET_URL_INPUT_ERROR', value: true})
                }
            } else if (urlSearchType === "text") {
                const {domainUrlInput} = authState

                authDispatch({type: "SET_HIGHLIGHT_WORDS", value: []})

                searchParams.set('searchMode',"url")
                searchParams.set('urlSearchType',"text")
                searchParams.set('domainUrlInput', domainUrlInput.value);
                searchParams.set('page', 1);
                searchParams.set('sortType', 'relevant');
                searchParams.delete('selectedCountries');
                searchParams.delete('language');
                searchParams.delete('startDate');
                searchParams.delete('endDate'); 
                searchParams.delete('cta'); 
                searchParams.delete('tone'); 
                searchParams.delete('otherFilters'); 
                searchParams.delete('sentiment'); 
                searchParams.delete('competitorsArray'); 
                searchParams.delete('keywordsArray'); 
                searchParams.delete('sortArray'); 
                // setSearchParams(searchParams)
                navigate(`/adspy/${authState.searchPlatform}?${searchParams.toString()}`)

            }

        }
    }

return <React.Fragment>
    <UrlSearchType/>
    <UrlInput/>
    <Button isDisabled={authState.searchDisabled} onClick={handleSearch}>Search</Button>
</React.Fragment>}

export default UrlComponent
