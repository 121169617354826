import React from "react";
import {
    CampaignCount,
    ActiveCampaigns,
    Divider,
    TotalAds
} from "./index.styled";
import { useAuth } from "../../../../../context/auth";
import Skeleton from 'react-loading-skeleton'
import { useTracking } from "../../context/tracking";

const CampaignData = ({ activeCampaignStat, isDataLoading }) => { 
  const { authState } = useAuth();
  const { trackingState, trackingDispatch  } = useTracking()

  return (
    <CampaignCount>
        <h4>Active Campaigns</h4>
        <ActiveCampaigns>
            {isDataLoading ? <p><Skeleton style={{
        lineHeight: 2,
        width: 100,
      }}/></p> : <p>{activeCampaignStat.totalCampaigns}
      </p>
      }
        </ActiveCampaigns>
        <Divider/>
        <h4>Total ads</h4>
        <TotalAds>
          {isDataLoading ? <p><Skeleton style={{
        lineHeight: 2,
        width: 100,
      }}/></p> :<p>{activeCampaignStat.totalCampaignAds}</p>}
        </TotalAds>
    </CampaignCount>
  );
};

export default CampaignData;




