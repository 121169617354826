import React, { useEffect, useState } from "react";
import {
  ChartHeadWrap,
  GraphContainer,
  PlatformDiv,
  PlatformName,
  PlatformchartWrap,
  TabDiv,
  TabOptions,
} from "./index.styled";
import adResultData from "../../../../../static";
import { Line } from "react-chartjs-2";
import { useAdIntegration } from "../../../../../context/AdManagementContext";
import { useAdIntegrationInfo } from "../../../../../hooks/useAdManagementInfo";

const AccountDetailsGraph = () => {
  const {
    newAdManagementState: { dateStartEnd, isMetricChart,isStatusDetail },
    newAdManagementDispatch,
  } = useAdIntegration();
  const { setMetricChart } = useAdIntegrationInfo();
  const { tabOptions } = adResultData;
  const [tabToggle, setTabToggle] = useState("impressions");

  useEffect(() => {
    setMetricChart({
      startDate: dateStartEnd.start.format("YYYY-MM-D"),
      endDate: dateStartEnd.end.format("YYYY-MM-D"),
      metric: tabToggle == "total Spent" ? "totalSpent" : tabToggle,
      status: isStatusDetail.toUpperCase(),
    });
  }, [dateStartEnd, tabToggle,isStatusDetail]);

  let dataset = {
    data: isMetricChart,
    // data: [
    //   {
    //     name: "data1",

    //     data: [
    //       { value: 85, data: "05-06-2025" },
    //       { value: 75, data: "06-06-2025" },
    //       { value: 142, data: "07-06-2025" },
    //       { value: 28, data: "08-06-2025" },
    //       { value: 185, data: "09-06-2025" },
    //       { value: 63, data: "10-06-2025" },
    //       { value: 37, data: "11-06-2025" },
    //       { value: 200, data: "18-06-2025" },
    //       { value: 64, data: "19-06-2025" },
    //       { value: 0, data: "20-06-2025" },
    //       { value: 77, data: "21-06-2025" },
    //       { value: 109, data: "22-06-2025" },
    //       { value: 127, data: "23-06-2025" },
    //       { value: 29, data: "25-06-2025" },
    //       { value: 67, data: "27-06-2025" },
    //       { value: 192, data: "28-06-2025" },
    //       { value: 181, data: "29-06-2025" },
    //       { value: 175, data: "30-06-2025" },
    //       { value: 189, data: "01-07-2025" },
    //       { value: 52, data: "02-07-2025" },
    //       { value: 112, data: "03-07-2025" },
    //       { value: 0, data: "04-07-2025" },
    //       { value: 86, data: "05-07-2025" },
    //       { value: 35, data: "06-07-2025" },
    //       { value: 168, data: "07-07-2025" },
    //       { value: 0, data: "08-07-2025" },
    //       { value: 112, data: "09-07-2025" },
    //       { value: 151, data: "10-07-2025" },
    //       { value: 20, data: "11-07-2025" },
    //       { value: 79, data: "12-07-2025" },
    //       { value: 73, data: "13-07-2025" },
    //       { value: 41, data: "14-07-2025" },
    //       { value: 88, data: "15-07-2025" },
    //       { value: 37, data: "16-07-2025" },
    //       { value: 55, data: "17-07-2025" },
    //     ],
    //   },
    //   {
    //     name: "Data2",

    //     data: [
    //       { value: 137, data: "29-04-2025" },
    //       { value: 140, data: "30-04-2025" },
    //       { value: 97, data: "01-05-2025" },
    //       { value: 74, data: "02-05-2025" },
    //       { value: 40, data: "03-05-2025" },
    //       { value: 147, data: "04-05-2025" },
    //       { value: 113, data: "05-05-2025" },
    //       { value: 23, data: "06-05-2025" },
    //       { value: 49, data: "07-05-2025" },
    //       { value: 79, data: "08-05-2025" },
    //       { value: 197, data: "09-05-2025" },
    //       { value: 27, data: "10-05-2025" },
    //       { value: 173, data: "11-05-2025" },
    //       { value: 26, data: "12-05-2025" },
    //       { value: 82, data: "13-05-2025" },
    //       { value: 143, data: "14-05-2025" },
    //       { value: 81, data: "15-05-2025" },
    //       { value: 77, data: "16-05-2025" },
    //       { value: 129, data: "17-05-2025" },
    //       { value: 47, data: "18-05-2025" },
    //       { value: 88, data: "19-05-2025" },
    //       { value: 64, data: "20-05-2025" },
    //       { value: 144, data: "21-05-2025" },
    //       { value: 121, data: "22-05-2025" },
    //       { value: 185, data: "23-05-2025" },
    //       { value: 42, data: "24-05-2025" },
    //       { value: 104, data: "25-05-2025" },
    //       { value: 100, data: "26-05-2025" },
    //       { value: 180, data: "27-05-2025" },
    //       { value: 121, data: "28-05-2025" },
    //       { value: 163, data: "29-05-2025" },
    //       { value: 122, data: "30-05-2025" },
    //       { value: 0, data: "31-05-2025" },
    //       { value: 45, data: "01-06-2025" },
    //       { value: 0, data: "02-06-2025" },
    //       { value: 89, data: "03-06-2025" },
    //       { value: 60, data: "04-06-2025" },
    //       { value: 38, data: "05-06-2025" },
    //       { value: 132, data: "06-06-2025" },
    //       { value: 86, data: "07-06-2025" },
    //       { value: 85, data: "08-06-2025" },
    //       { value: 113, data: "09-06-2025" },
    //       { value: 156, data: "10-06-2025" },
    //       { value: 57, data: "11-06-2025" },
    //       { value: 135, data: "12-06-2025" },
    //       { value: 78, data: "13-06-2025" },
    //       { value: 73, data: "14-06-2025" },
    //       { value: 127, data: "15-06-2025" },
    //       { value: 157, data: "16-06-2025" },
    //       { value: 89, data: "17-06-2025" },
    //       { value: 96, data: "18-06-2025" },
    //       { value: 36, data: "19-06-2025" },
    //       { value: 73, data: "20-06-2025" },
    //       { value: 107, data: "21-06-2025" },
    //       { value: 36, data: "22-06-2025" },
    //       { value: 123, data: "23-06-2025" },
    //       { value: 97, data: "24-06-2025" },
    //       { value: 159, data: "25-06-2025" },
    //       { value: 56, data: "26-06-2025" },
    //       { value: 181, data: "27-06-2025" },
    //       { value: 72, data: "28-06-2025" },
    //       { value: 0, data: "29-06-2025" },
    //       { value: 0, data: "30-06-2025" },
    //       { value: 181, data: "01-07-2025" },
    //       { value: 99, data: "03-07-2025" },
    //       { value: 176, data: "04-07-2025" },
    //       { value: 0, data: "10-07-2025" },
    //       { value: 0, data: "11-07-2025" },
    //       { value: 0, data: "12-07-2025" },
    //       { value: 0, data: "13-07-2025" },
    //       { value: 0, data: "14-07-2025" },
    //       { value: 0, data: "15-07-2025" },
    //       { value: 151, data: "16-07-2025" },
    //       { value: 0, data: "17-07-2025" },
    //     ],
    //   },
    // ],
  };

  const aggregateData = (data, interval) => {
    const aggregated = {};

    data.forEach((point) => {
      // Parse the date from dd-mm-yyyy format
      const [year, month, day] = point.data.split("-");
      const date = new Date(`${year}-${month}-${day}`); // Convert to yyyy-mm-dd format

      // Determine the aggregation key based on the interval
      let key;
      if (interval === "daily") {
        key = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`; // Daily: yyyy-mm-dd
      } else if (interval === "weekly") {
        const week = Math.ceil(date.getDate() / 7); // Get the week of the month
        key = `${date.getFullYear()}-${date.getMonth() + 1}-W${week}`; // Weekly: yyyy-mm-Wweek
      } else if (interval === "monthly") {
        key = `${date.getFullYear()}-${date.getMonth() + 1}`; // Monthly: yyyy-mm
      }

      if (!aggregated[key]) {
        aggregated[key] = { value: 0, count: 0 };
      }

      aggregated[key].value += Number(point.value);
      aggregated[key].count += 1;
    });

    return Object.entries(aggregated).map(([key, { value, count }]) => ({
      data: key, // Aggregated date (key)
      value: value, // Average value for the interval
    }));
  };

  const countDaysInRange = (range) => {
    let count = 0;
    for (let day of range.by("days")) {
      count++;
    }
    if (count <= 30) {
      return "daily";
    } else if (count > 30 && count <= 90) {
      return "weekly";
    } else {
      return "monthly";
    }
  };
  // Prepare labels and datasets from the incoming data
  const prepareChartData = () => {
    const labelsSet = new Set();
    const dayCount = countDaysInRange(dateStartEnd);

    // Aggregate data for both datasets
    const aggregatedData = dataset.data.map((item) => {
      // console.log(item,'item')
      return aggregateData(item.data, dayCount); // Change to 'weekly' if needed
    });

    // Collect unique labels from all aggregated datasets
    aggregatedData.forEach((item) => {
      item.forEach((dataPoint) => {
        labelsSet.add(dataPoint.data);
      });
    });

    // Convert Set to Array and sort
    const labels = Array.from(labelsSet).sort();

    // Now map each dataset to align with the labels and fill missing months with 0
    const datasets = aggregatedData.map((item, index) => {
      // Create a map of the aggregated data for easier access
      const dataMap = item.reduce((acc, dataPoint) => {
        acc[dataPoint.data] = dataPoint.value;
        return acc;
      }, {});

      // For each label (month), if no data is available, use 0
      const data = labels.map((label) => dataMap[label] || 0);

      return {
        label: "", // Keeping the label empty to maintain the original design
        data, // Data now aligned with labels, filled with 0 for missing values
        fill: "start",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);

          if (index === 0) {
            gradient.addColorStop(0, "rgba(37, 205, 37, 0.40)"); // Green for first dataset
            gradient.addColorStop(0.9959, "rgba(37, 205, 37, 0)");
          } else {
            gradient.addColorStop(0.0522, "rgba(232, 230, 249, 0.00)"); // Light gray for second dataset
            gradient.addColorStop(0.9675, "rgba(232, 230, 249, 0.80)");
          }

          return gradient;
        },
        borderColor: index === 0 ? "#25CD25" : "#1F4DDA",
        borderWidth: 1,
        pointBackgroundColor: "#fff",
        lineTension: 0.4,
      };
    });

    return {
      labels,
      datasets,
    };
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "category", // Ensure x-axis is treated as categories
        grid: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 10,
          font: {
            family: "Inter",
            size: 12,
            weight: 400,
          },
        },
      },
      y: {
        border: {
          display: false,
        },
        grid: {
          tickColor: "#fff",
        },
        ticks: {
          font: {
            family: "Inter",
            size: 12,
            weight: 400,
          },
        },
      },
    },
    elements: {
      point: {
        radius: 3,
        // hoverRadius: 8, // Keeping point radius intact
      },
    },
    plugins: {
      filler: {
        propagate: false,
      },
      legend: {
        display: false, // Keeping legend hidden as per original design
      },
    },
  };

  return (
    <PlatformchartWrap>
      <ChartHeadWrap>
        <TabOptions>
          {tabOptions.map((item, index) => (
            <TabDiv
              key={index}
              onClick={() => setTabToggle(item)}
              isActive={tabToggle === item}
            >
              {item}
            </TabDiv>
          ))}
        </TabOptions>
        <PlatformDiv>
          {isMetricChart &&
            isMetricChart.map((plat) => (
              <PlatformName>
                <p></p>
                {plat.platform}
              </PlatformName>
            ))}
        </PlatformDiv>
      </ChartHeadWrap>
      <GraphContainer>
        <Line data={prepareChartData()} options={options} />
      </GraphContainer>
    </PlatformchartWrap>
  );
};

export default AccountDetailsGraph;
