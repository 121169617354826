import styled from "styled-components";

export const BreadCrumb = styled.div`
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    color: #8B8B8B;
    margin-bottom: 14px;
    margin-top: 32px;
    span{
        margin: 3px;
    }
`;