import React, { useState } from "react";
import {
  AdGroupContainer,
  Container,
  CollapseParent,
  CollapseBody,
  DestinationUrlContainer,
  TitleContainer,
  TitleContent,
  DescContainer,
  DescContent,
  AddTitleContainer,
  AddDescriptionContainer,
  AdGroupParentLeft,
  AdGroupParentRight,
  TitleContentLeft,
  TitleContentRight,
  DescContentLeft,
  DescContentRight,
} from "./AdGroups.styled";
import DownArrow from "../../../../../../../../media/Dashboard/drop-down-arrow.svg";
import { useAdIntegration } from "../../../../context/AdManagementContext";
import DeleteIcon from "../../../../../../../../media/Dashboard/DeleteIcon.svg";
import ValidateAndAddHttps from "../../../../../../../../utils/urlValidate";

const AdGroups = () => {
  const [isOpen, setIsOpen] = useState(0);
  const { newAdManagementState, newAdManagementDispatch } = useAdIntegration();

  const [titleErrors, setTitleErrors] = useState([]);
  const [descErrors, setDescErrors] = useState(() =>
    new Array(newAdManagementState.adGroupList.length).fill(null)
  );

  // let adGroupList = [{
  //     id: 1,
  //     destinationUrl: "https://adspyder.io/",
  //     titleList: newAdManagementState?.isSingleAdGroupData?.adGroupRow?.titles,
  //     descriptionList: newAdManagementState?.isSingleAdGroupData?.adGroupRow?.descriptions
  // }]

  const onCollapseClick = (id) => {
    if (isOpen == id) {
      setIsOpen(null);
    } else {
      setIsOpen(id);
    }
  };
  const addEmptyTitle = ({ id }) => {
    const updatedAdGroupList = newAdManagementState.adGroupList.map((data) => {
      if (data.id === id) {
        if (data.titleList.length < 15) {
          return {
            ...data,
            titleList: [...data.titleList, ""],
          };
        }
      }
      return data;
    });
    newAdManagementDispatch({
      type: "SET_ADGROUP_LIST",
      value: updatedAdGroupList,
    });
  };
  const deleteEmptyTitle = (id, idx) => {
    const deleteAdGroupTitle = newAdManagementState.adGroupList.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          titleList: data.titleList.filter((d, i) => i !== idx),
        };
      }
      return data;
    });
    newAdManagementDispatch({
      type: "SET_ADGROUP_LIST",
      value: deleteAdGroupTitle,
    });
  };
  const addEmptyDescripyion = ({ id }) => {
    const updatedAdGroupList = newAdManagementState.adGroupList.map((data) => {
      if (data.id === id && data.descriptionList.length < 4) {
        return {
          ...data,
          descriptionList: [...data.descriptionList, ""],
        };
      }
      return data;
    });

    newAdManagementDispatch({
      type: "SET_ADGROUP_LIST",
      value: updatedAdGroupList,
    });
  };
  const deleteDescription = (id, idx) => {
    const deleteAdGroupList = newAdManagementState.adGroupList.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          descriptionList: data.descriptionList.filter((d, i) => i !== idx),
        };
      }
      return data;
    });
    newAdManagementDispatch({
      type: "SET_ADGROUP_LIST",
      value: deleteAdGroupList,
    });
  };

  const DeleteAd = (id) => {
    let finalAdGroup = newAdManagementState.adGroupList.filter((data) => {
      return data.id != id;
    });

    newAdManagementDispatch({ type: "SET_ADGROUP_LIST", value: finalAdGroup });
  };

  const handleTitle = (value, idx) => {
    const newTitleErrors = [...titleErrors];
    if (value.trim() === "") {
      newTitleErrors[idx] = "Title is required!";
    } else {
      newTitleErrors[idx] = null;
    }
    setTitleErrors(newTitleErrors);
    const updatedAdGroupList = newAdManagementState.adGroupList.map(
      (adGroupData) => {
        if (adGroupData.id === isOpen + 1) {
          const updatedTitleList = [...adGroupData.titleList];
          updatedTitleList[idx] = value;
          return {
            ...adGroupData,
            titleList: updatedTitleList,
          };
        }
        return adGroupData;
      }
    );

    newAdManagementDispatch({
      type: "SET_ADGROUP_LIST",
      value: updatedAdGroupList,
    });
  };

  const handleDescription = (value, idx) => {
    const newDescripErrors = [...descErrors];
    if (value.trim() === "") {
      newDescripErrors[idx] = "Description is required!";
    } else if (value.trim().length > 90) {
      newDescripErrors[idx] = "Description should not exceed 90 characters";
    } else {
      newDescripErrors[idx] = null;
    }
    setDescErrors(newDescripErrors);

    const updatedAdGroupList = newAdManagementState.adGroupList.map(
      (adGroupData) => {
        if (adGroupData.id === isOpen + 1) {
          // isOpen starts from 0, but id starts from 1
          const updatedDescriptionList = [...adGroupData.descriptionList];
          updatedDescriptionList[idx] = value;
          return {
            ...adGroupData,
            descriptionList: updatedDescriptionList,
          };
        }
        return adGroupData;
      }
    );

    newAdManagementDispatch({
      type: "SET_ADGROUP_LIST",
      value: updatedAdGroupList,
    });
  };
//   const handleEnterUrl = (e) => {
//     const urlValue = e.target.value;
//     newAdManagementDispatch({ type: "SET_DESTINATION_URL", value: urlValue });

//     try {
//       if (ValidateAndAddHttps(urlValue)) {
//         newAdManagementDispatch({ type: "SET_DESTINATION_ERROR", value: null });
//       } else if (newAdManagementState.isDestinationUrl == (null || "")) {
//         newAdManagementDispatch({
//           type: "SET_DESTINATION_ERROR",
//           value: "Destination url is required",
//         });
//       } else {
//         newAdManagementDispatch({
//           type: "SET_DESTINATION_ERROR",
//           value: "Enter a valid Url",
//         });
//       }
//     } catch (_) {
//       newAdManagementDispatch({
//         type: "SET_DESTINATION_ERROR",
//         value: "Enter a valid Url",
//       });
//     }
//   };

  const renderAdGroup = (data, index) => {
    const isAddTitleDisabled = data.titleList.length >= 15;
    const isAddDescripDisabled = data.descriptionList.length >= 4;

    if (data.titleList.length === 0) {
      // Add an empty title
      data.titleList.push("");
    }
    if (data.descriptionList.length === 0) {
      // Add an empty title
      data.descriptionList.push("");
    }
    return (
      <AdGroupContainer>
        <CollapseParent
          isOpen={isOpen == index}
          onClick={() => onCollapseClick(index)}
        >
          <AdGroupParentLeft isOpen={isOpen == index}>
            <img src={DownArrow} />
            <p>Ad {index + 1}</p>
          </AdGroupParentLeft>
          {/* <AdGroupParentRight onClick={() => DeleteAd(data.id)}>
                    <img src={DeleteIcon} />
                </AdGroupParentRight> */}
        </CollapseParent>
        {isOpen == index && (
          <CollapseBody>
            <DestinationUrlContainer>
              <label>Destination URL</label>
              <input
                // onChange={(e) => handleEnterUrl(e)}
                placeholder="Enter the destination url"
                value={data.destinationUrl}
              />
              {newAdManagementState.isDestinationError && (
                <small>{newAdManagementState.isDestinationError}</small>
              )}
            </DestinationUrlContainer>
            <TitleContainer>
              {data?.titleList?.map((title, idx) => {
                return (
                  <TitleContent>
                    <TitleContentLeft>
                      <label>Title {idx + 1}</label>
                      <input
                        placeholder={"Enter title " + (idx + 1)}
                        value={title}
                        onChange={(e) => {
                          handleTitle(e.target.value, idx);
                        }}
                        maxLength={30}
                        error={
                          title.length > 30 || titleErrors[idx] != undefined
                        }
                      />
                      {titleErrors[idx] && <small>{titleErrors[idx]}</small>}
                    </TitleContentLeft>
                    <TitleContentRight>
                      <img
                        src={DeleteIcon}
                        onClick={() => deleteEmptyTitle(data.id, idx)}
                      />
                    </TitleContentRight>
                  </TitleContent>
                );
              })}
              <AddTitleContainer
                onClick={() => addEmptyTitle(data)}
                isDisabled={isAddTitleDisabled}
              >
                + add title
              </AddTitleContainer>
            </TitleContainer>
            <DescContainer>
              {/* {
                    data?.descriptionList?.length > 5 && adManagementDispatch({type: "SET_ERROR_MESSAGE", value: 'Only upto 5 descriptions are allowed'})
                    } */}
              {data?.descriptionList?.map((desc, idx) => {
                return (
                  <DescContent>
                    <DescContentLeft>
                      <label>Desc {idx + 1}</label>
                      <input
                        placeholder={"Enter desc " + (idx + 1)}
                        value={desc}
                        onChange={(e) => {
                          handleDescription(e.target.value, idx);
                        }}
                        maxLength={90}
                        error={
                          desc.trim().length > 90 ||
                          descErrors[idx] != undefined
                        }
                      />
                      {descErrors[idx] && <small>{descErrors[idx]}</small>}
                    </DescContentLeft>
                    <DescContentRight>
                      <img
                        src={DeleteIcon}
                        onClick={() => deleteDescription(data.id, idx)}
                      />
                    </DescContentRight>
                  </DescContent>
                );
              })}
              <AddDescriptionContainer
                onClick={() => addEmptyDescripyion(data)}
                isDisabled={isAddDescripDisabled}
              >
                + add description
              </AddDescriptionContainer>
            </DescContainer>
          </CollapseBody>
        )}
      </AdGroupContainer>
    );
  };
  return (
    <Container>
      {newAdManagementState.adGroupList.map((adGroupData, index) => {
        return renderAdGroup(adGroupData, index);
      })}
    </Container>
  );
};

export default AdGroups;
