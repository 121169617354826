import styled from "styled-components";

export const AddCompetitorContainer = styled.div`
display: inline-flex;
width: 206px;
height: 121px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 19px;
border-radius: 8px;
background: #FFF;
cursor: pointer;
`;

export const Text = styled.pre`
color: #4F4F4F;
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;

export const AddButton = styled.div`
cursor: pointer;
color: #FE9456;
text-align: center;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;

export const CompetitorContainer = styled.div`
width: 206px;
height: 232px;
flex-shrink: 0;
border-radius: 8px;
background: #FFF;
padding: 16px;
cursor: pointer;
`;

export const CompetitorName = styled.div`
color: #4F4F4F;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;

export const CampaignsLabel = styled.div`
color: rgba(28, 28, 28, 0.50);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 8px;
`;

export const Counts = styled.div`
color: #1C1C1C;
font-family: Inter;
font-size: 44px;
font-style: normal;
font-weight: 500;
line-height: 56px; /* 127.273% */
letter-spacing: -2px;
margin-top: 73px;
display: flex;
gap: 13px;
align-items: start;
`;

export const New = styled.div`
width: 68px;
height: 25px;
flex-shrink: 0;
border-radius: 4px;
background: #E1FAEA;
display: flex;
align-items: center;
justify-content: center;
margin-top: 9px;
color: #318048;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.2px;
`;

export const LastUpdated = styled.div`
color: rgba(28, 28, 28, 0.30);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.2px;
margin-top: 4px;
`;