import { useManageProjects } from "../../context/manageProjects";
import AddNewProject from "./AddNew/index.js";
import { useAuth } from "../../../../../context/auth.js";

function Popup({getProjectsMutate}) {
    const { manageProjectsState: { isPopupActive }, manageProjectsDispatch } = useManageProjects()
    const { authState: { defaultProjectInfo } } = useAuth()
    if (!isPopupActive) return
    return <AddNewProject getProjectsMutate={getProjectsMutate} />
}

export default Popup