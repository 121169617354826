import React, { useEffect } from 'react'

import { Navigate, Outlet, useLocation } from "react-router-dom"


import _static from '../../config/static'
import { useAuth } from '../../context/auth'

const { adspyPage } = _static

const ProtectedEmailVerify = () => {
    const { authState } = useAuth()
    const location = useLocation()

    console.log("protected email verify layout", authState)
    if(!authState.isAuthenticated){
        return <Navigate to={`/`} state={{ from: location }} replace/>
    }else if(authState.old && !authState.notified){
        return <Navigate to={`/welcome`} state={{ from: location }} replace/>
    }else if(!authState.emailVerified){
        return <Outlet/>
    }else if(!authState.postloginSurvey){
        return <Navigate to={`/loginsurvey`} state={{ from: location }} replace/>
    }else if(authState.plan?.active){
        return <Navigate to={`/overview`} state={{ from: location }} replace/>
    }else{
        return <Navigate to={'/account/plans-billing'} state={{ from: location }} replace />
    }
}

export default ProtectedEmailVerify