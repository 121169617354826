import styled from "styled-components";

export const Wrapper = styled.div`

`;

export const SelectedAnimateDropdown = styled.div`
    border-radius: 6px;
    border: solid 1px #DADFE4;
    background: #FFF;
    height: 44px;
    width: 100%;
    position: relative;

`;

export const DropdownWrapper = styled.div`
    margin-bottom: 36px;
    h2{
        font-size: 16px;
        font-weight: 400;
        color: #1C1C1C;
        margin-bottom: 12px;
    }
`;


export const SelectedAnimationContainer = styled.div`
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
    cursor: pointer;
    div{
        display: flex;
        gap: 10px;
    }
    img{
        rotate: ${props => props.dropDownOpen ? "0deg": "180deg"};
        height: 18px;
    }
    p{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #1C1C1C;
    }
`;

export const DropDown = styled.div`
    position: absolute;
    z-index: 1;
    width: 100%;
    background: #FFF;
    top: 45px;
    border: solid 1px #DADFE4;
    border-radius: 5px;
`;

export const DropDownOption = styled.div`
    height: 40px;
    padding: 5px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;   
    cursor: pointer;
    border-bottom: solid 1px #dadfe4bb;
    font-size: 14px;
    font-weight: 400;
    img{
        height: 18px;
    }
`;
