
import React from 'react';
import { Chart } from "react-google-charts";
import Skeleton from 'react-loading-skeleton';
// import { useUrlAnalysis } from "../../../context/urlanalysis";
import { useDomainTracking } from '../../../context/domaintracking';
import { useDomainTrackingInfo } from '../../../hooks/useDomainTrackingInfo';
  
const MapChart = ({isDataLoading}) => {
  const {domainTrackingState, domainTrackingDispatch} = useDomainTracking()
  const data = domainTrackingState?.mapStat?.mapDistribution;
  const useDomainTrackingInfoHook = useDomainTrackingInfo()

  return useDomainTrackingInfoHook.isGetMapDataLoading ?
        <Skeleton style={{
            lineHeight: 13,
            width: 360,
          }}/> : 
        <Chart
            chartEvents={[
                {
                eventName: "select",
                callback: ({ chartWrapper }) => {
                    const chart = chartWrapper.getChart();
                    const selection = chart.getSelection();
                    if (selection.length === 0) return;
                    // const region = urlAnalysisState.mapdata[selection[0].row + 1];
                    // console.log("Selected : " + region);
                },
                },
            ]}
            
            options={{colorAxis: { colors: ["#ffe8da", "#FFA26B"] },
            tooltip: {
              isHtml: true,
              textStyle: { color: '#333' },
              // Define a custom tooltip format using placeholders
              // Use data.getColumnLabel() to access column labels
              // Use data.getFormattedValue() to access cell values
              // You can use HTML here to format the tooltip content
              // For example: `<div>${data.getFormattedValue(rowIndex, columnIndex)}</div>`
              // Use data.getValue(rowIndex, columnIndex) to access raw cell values
              // For example: `<div>${data.getValue(rowIndex, columnIndex)}</div>`
              // You can also include custom HTML and CSS to style the tooltip
              // For example:
              // `<div style="color: blue;">${data.getFormattedValue(rowIndex, columnIndex)}</div>`
              // `<div><span>Value: ${data.getFormattedValue(rowIndex, columnIndex)}</span></div>`
            },}}
            chartType="GeoChart"
            width="360px"
            height="200px"
            data={data}
            />
}

export default MapChart;

