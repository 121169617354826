import React, { useState, useEffect, useRef } from 'react'


import { Wrapper,  Down, Row, DropDownImage, DropdownContainer, DropdownButton  } from './index.styled'
import UpArrow from "../../../../../../../../media/Dashboard/down-arrow-grey.svg"
import { useAuth } from '../../../../../../../../context/auth'

function useOutsideAlerter(ref, setShow) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setShow(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref])
}

const KeywordSearchType = () => {
    const { authState, authDispatch } = useAuth()
    const wrapperRef = useRef(null)
    const [show, setShow] = useState(false)

    let tempSortTypes = [{
        name:"Broad",
        type: 'broad'
    }, {
        name:"Phrase",
        type: 'phrase'
    }];

    useOutsideAlerter(wrapperRef, setShow)

    return <Wrapper>
    {/* <ToolTipContainer>
        match type <Tooltip placement="right" trigger={['hover']} overlay={<span>Choose between Broad and Phrase matches for Keyword input. Broad match lets you input up-to 10 keywords as individual terms and Phrase match lets you input one keyword, having multiple terms</span>}>
        <img src={ToolTipIcon}/>
      </Tooltip>
    </ToolTipContainer> */}
    {/* <KeywordSearchTypeContainer>
        <KeywordButtonContainer 
            active={authState.keywordSearchType === "broad"} 
            onClick={() =>  authDispatch({ type: "SET_KEYWORD_SEARCHTYPE", value: "broad" })}>
            <input type="radio" checked={authState.keywordSearchType === "broad" && "checked"} id="broad" name="searchtype"/>
            <label for="broad">broad</label>
        </KeywordButtonContainer>
        <KeywordButtonContainer 
            active={authState.keywordSearchType === "phrase"} 
            onClick={() =>  authDispatch({ type: "SET_KEYWORD_SEARCHTYPE", value: "phrase" })}>
            <input type="radio" checked={authState.keywordSearchType === "phrase" && "checked"} id="phrase" name="searchtype"/>
            <label for="phrase">phrase</label>
        </KeywordButtonContainer>
    </KeywordSearchTypeContainer> */}
    <DropdownContainer 
        show={show}
        ref={wrapperRef}>
            <DropdownButton show={show} onClick={() => setShow(prev => !prev)}
                >
                <span>{
                    authState.keywordSearchType
                }</span>
                {
                    <DropDownImage show={show}><img src={UpArrow}/></DropDownImage>
                }
            </DropdownButton>

            <Down show={show}>
                {tempSortTypes.map((keyword, index) => {
                    return <Row isSelected={authState.keywordSearchType == keyword.name} key={index} onClick={() => {
                        setShow(false)
                        authDispatch({ type: "SET_KEYWORD_SEARCHTYPE", value: keyword.type })
                    }}>
                        <p>{keyword.name}</p>
                    </Row>
                })}
            </Down>
        </DropdownContainer>
    </Wrapper>
}


export default KeywordSearchType