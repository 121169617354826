import { Container, Left, LogoContainer, ChildrenHolder, Right, ChildrenContainer } from "./index.styled"
import AdspyderWhiteLogo from "../../../../../media/Dashboard/logo_white.svg"
const Layout = ({children}) => {
    return <Container>
    <Left>
      <LogoContainer>
        <img src={AdspyderWhiteLogo}/>
      </LogoContainer>
      <ChildrenContainer>
        <ChildrenHolder>
            {children}
        </ChildrenHolder>
      </ChildrenContainer>
    </Left>
    <Right>

    </Right>
  </Container>
}

export default Layout;