import styled from "styled-components";

export const SwitchBoardContainer = styled.div`
    /* width: 182px; */
    width: auto;
    height: 40px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #E3E7EA;
    padding: 5px;
    display: flex;
`;

export const PlatformContainer = styled.div`
    width: 86px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 6px;
    background: ${(props) => props.isActive ? "#fff" : "#e3e7ea"};
    box-shadow: ${(props) => props.isActive ? "0px 2px 2px 0px rgba(0, 0, 0, 0.20)" : "none"};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const Platform = styled.div`
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
`;
