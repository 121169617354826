import React from 'react'
import { useSearchParams } from 'react-router-dom'


// Styles
import {
    PaginationContainer
} from "./index.styled"

import {useLinkedin} from "../../context/linkedin"
import ReactPaginate from 'react-paginate';


const Pagination = () => {
    const {linkedinState, linkedinDispatch} = useLinkedin()
    const [searchParams, setSearchParams] = useSearchParams()

    const handleSpecificPage = (e) => {
        linkedinDispatch({type:'SET_AD_DETAILS', value: {}})
        linkedinDispatch({type:'UPDATE_DETAILS_MODAL', value: false})
        linkedinDispatch({ type: "SET_CURRENT_PAGE_NO", value: e.selected + 1 })
        searchParams.set("page", e.selected + 1)
        setSearchParams(searchParams)
    }
    
    return <PaginationContainer 
    prevState={linkedinState.currentPageNo == 1}
    nextState={linkedinState.currentPageNo == Math.ceil((linkedinState.totalAds / 20))}>
        <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            forcePage={linkedinState.currentPageNo - 1}
            onPageChange={handleSpecificPage}
            pageRangeDisplayed={3}
            pageCount={(linkedinState.totalAds / 20)}
            previousLabel="<"
            renderOnZeroPageCount={null}
        />
  </PaginationContainer>

}

export default Pagination
