import React, { memo, useCallback } from "react"
import { useSearchParams, useNavigate } from "react-router-dom"

import { Input, InputContainer, ToolTipImageContainer, ToolTip, Wrapper, ToolTipContainer, PopularSearches } from './index.styled'
import ToolTipLogo from "../../../../../../../../media/Dashboard/tooltip.svg"

import ToolTipIcon from '../../../../../../../../media/Dashboard/info_button_tool_tip.svg'
import SearchIcon from '../../../../../../../../media/Dashboard/searchIcon.svg'
import Tooltip from 'rc-tooltip';

// import { useSearch } from "../../../../context/search";
import { useAuth } from "../../../../../../../../context/auth"

const PhraseInput = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const { authState, authDispatch } = useAuth()
    const navigate = useNavigate()

    // const { searchState, searchDispatch } = useSearch()


    const handleSearch = (event) => {
        if (event.key === 'Enter') {
            const { searchMode, excludedTerm } = authState

            if (!authState.searchPlatform) {
                return authDispatch({ type: 'SET_SEARCH_PLATFORM_ERROR', value: true })
            }

            // if (!authState.features?.allowedPlatforms.includes(authState.searchPlatform)) {
            //     authDispatch({ type: 'UPDATE_IS_RESTRICTED', value: true })
            // } else 
            if (searchMode === "keyword") {
                // console.log("2", authState)
                const { keywordSearchType } = authState
                if (keywordSearchType === "broad") {
                    const { broadMinShouldMatch, keywords } = authState

                    if (keywords.length == 0) {
                        return authDispatch({ type: 'SET_KEYWORD_INPUT_ERROR', value: true })
                    }

                    let broadSearchIn = ["content", "title"]
                    if (authState.searchPlatform == "meta") {
                        broadSearchIn = broadSearchIn.concat(["innertitle", "innerdescription"])
                    }

                    authDispatch({ type: "SET_HIGHLIGHT_WORDS", value: keywords })

                    let paramQuery = keywords.join(",")

                    searchParams.set('searchMode', "keyword")
                    searchParams.set('keywordSearchType', "broad")
                    searchParams.set('keywords', paramQuery);
                    searchParams.set('page', 1);
                    searchParams.set('excludedTerm', "");
                    searchParams.set('minimumShouldMatch', broadMinShouldMatch.value);
                    searchParams.set('searchIn', broadSearchIn.join(","));
                    searchParams.set('sortType', 'relevant');
                    searchParams.delete('selectedCountries');
                    searchParams.delete('startDate');
                    searchParams.delete('endDate');
                    searchParams.delete('cta');
                    searchParams.delete('tone');
                    searchParams.delete('otherFilters');
                    searchParams.delete('sentiment');
                    searchParams.delete('competitorsArray'); 
                    searchParams.delete('keywordsArray'); 
                    searchParams.delete('sortArray'); 
                    // setSearchParams(searchParams)                

                    navigate(`/adspy/${authState.searchPlatform}?${searchParams.toString()}`)

                } else if (keywordSearchType === "phrase") {
                    const { phraseInput } = authState
                    if (phraseInput.trim().length == 0) {
                        return authDispatch({ type: 'SET_KEYWORD_INPUT_ERROR', value: true })
                    }

                    let phraseSearchIn = ["content", "title"]
                    if (authState.searchPlatform == "meta") {
                        phraseSearchIn = phraseSearchIn.concat(["innertitle", "innerdescription"])
                    }

                    authDispatch({ type: "SET_HIGHLIGHT_WORDS", value: phraseInput.split(" ") })

                    let paramQuery = phraseInput.trim()

                    searchParams.set('searchMode', "keyword")
                    searchParams.set('keywordSearchType', "phrase")
                    searchParams.set('phraseInput', paramQuery);
                    searchParams.set('page', 1);
                    searchParams.set('excludedTerm', "");
                    searchParams.set('searchIn', phraseSearchIn.join(","));
                    searchParams.set('sortType', 'relevant');
                    searchParams.delete('selectedCountries');
                    searchParams.delete('startDate');
                    searchParams.delete('endDate');
                    searchParams.delete('cta');
                    searchParams.delete('tone');
                    searchParams.delete('otherFilters');
                    searchParams.delete('sentiment');
                    searchParams.delete('competitorsArray'); 
                    searchParams.delete('keywordsArray'); 
                    searchParams.delete('sortArray'); 
                    // setSearchParams(searchParams)
                    navigate(`/adspy/${authState.searchPlatform}?${searchParams.toString()}`)

                }
            }
        }
    }

    const inputChange = useCallback((e) => {
        authDispatch({ type: "SET_PHRASE_INPUT", value: e.target.value })
        authDispatch({ type: 'SET_KEYWORD_INPUT_ERROR', value: false })
        if (authState.searchDisabled) {
            authDispatch({ type: 'SET_SEARCH_DISABLED', value: false })
        }
    })

    return <Wrapper>
        {/* <ToolTipContainer>
            enter phrase <Tooltip placement="right" trigger={['hover']} overlay={<span>Enter any keyword having 1 term or more.</span>}>
            <img src={ToolTipIcon}/>
        </Tooltip>
        </ToolTipContainer> */}
        <InputContainer>
            <img src={SearchIcon} />
            <Input
                onKeyDown={handleSearch}
                error={authState.keywordInputError}
                name="phrase-input"
                type="text"
                autoComplete="off"
                placeholder="Enter a phrase"
                value={authState.phraseInput}
                onChange={inputChange}
            />
            <ToolTipImageContainer
                error={authState.keywordInputError}
                className="tooltip-container">
                <img src={ToolTipLogo} />
                <ToolTip>
                    You can enter only 1 term
                </ToolTip>
            </ToolTipImageContainer>
        </InputContainer>
        {/* <PopularSearches>
                <label>Popular Searches:</label> <span onClick={()=>navigate('/adspy/google?searchMode=keyword&keywordSearchType=phrase&phraseInput=apple&page=1&excludedTerm=&searchIn=title%2Ccontent&sortType=relevant')}>apple</span><span onClick={()=>navigate('/adspy/google?searchMode=keyword&keywordSearchType=phrase&phraseInput=rummy+online&page=1&excludedTerm=&searchIn=title%2Ccontent&sortType=relevant')}>rummy online</span>
            </PopularSearches> */}
    </Wrapper>
}

export default memo(PhraseInput)