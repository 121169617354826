import styled from "styled-components";

export const Wrapper = styled.div`
    padding: 0px 24px;
`;

export const AdDetailsLabel = styled.div`
    margin: 33px 0 6px 0;
    color: #1C1C1C;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
`;

export const InputsList = styled.div`
    
`;

export const Container = styled.div`
    margin-top: 8px;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px 0 20px;
    border-radius: 5px;
    border: 1px solid rgba(218, 223, 228, 0.50);
`;

export const InputName = styled.div`
    color: #1C1C1C;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
`;

export const ChangeButton = styled.div`
    color: #1679DB;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    cursor: pointer;
`;

export const UpdateButton = styled.div`
    margin: 16px auto;
    width: 245px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    border-radius: 6px;
    background: ${(props) => props.isActive ? "#FF711E" : "#E7E7E7"} ;
    pointer-events: ${props => props.isActive ? "all" : "none"};
    cursor: pointer;
`;