import React, { useState } from "react";
import {
  Details,
  DetailsContainer,
  DetailsDiv,
  DetailsWrapper,
  HeadTitle,
  ImpressionList,
  ImpressionWrap,
  LevelDiv,
  OptionList,
  OptionWrap,
  TableBody,
  TableHeader,
  TableRow,
} from "./index.styled";
import adResultData from "../../../../static";
import greenLiveImg from "../../../../../../../../media/Dashboard/greenLive.svg";
import pauseButton from "../../../../../../../../media/Dashboard/round-pause.svg";

const DetailsComponent = () => {
  const { detailsOption, tableDetails } = adResultData;
  const [levelState, setLevelState] = useState(0);
  const [activeState, setActiveState] = useState(3);

  return (
    <DetailsWrapper>
      <Details>Details</Details>
      <OptionWrap>
        <LevelDiv>
          <OptionList
            isSelected={levelState == 0 && true}
            onClick={() => setLevelState(0)}
          >
            Highest
          </OptionList>
          <OptionList
            isSelected={levelState == 1 && true}
            onClick={() => setLevelState(1)}
          >
            Lowest
          </OptionList>
        </LevelDiv>
        <ImpressionWrap>
          {detailsOption.map((item, index) => (
            <ImpressionList
              isSelect={index == activeState && true}
              onClick={() => setActiveState(index)}
            >
              {item.option}
            </ImpressionList>
          ))}
        </ImpressionWrap>
      </OptionWrap>
      <DetailsContainer>
        {tableDetails.map((row) => {
          return (
            <DetailsDiv>
              <TableHeader>
                <HeadTitle className="statusClass">Status</HeadTitle>
                <HeadTitle className="campaignClass">
                  {Object.keys(row)[0]}
                </HeadTitle>
                <HeadTitle className="profitClass">Profits</HeadTitle>
              </TableHeader>
              <TableBody>
                {Object.entries(row)[0][1].map((check) => {
                  return (
                    <TableRow>
                      <HeadTitle className="statusClass">
                        <img src={check.status ? greenLiveImg : pauseButton} />
                      </HeadTitle>
                      <HeadTitle className="campaignBody campaignClass">
                        {check.description}
                      </HeadTitle>
                      <HeadTitle className="profitBody profitClass">
                        ${check.profits}
                      </HeadTitle>
                    </TableRow>
                  );
                })}
              </TableBody>
            </DetailsDiv>
          );
        })}
      </DetailsContainer>
    </DetailsWrapper>
  );
};

export default DetailsComponent;
