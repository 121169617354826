import styled from "styled-components";

export const Container = styled.div`
display: flex;
flex-direction: column;
gap: 24px;
`;

export const Label = styled.div`
color: #161616;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

export const Value = styled.div`
display: grid;
width: calc(100% - 18px);
gap: 18px;
grid-template-columns: 67% 33%;
`;

export const Column = styled.div`
display: flex;
flex-direction: column;
gap: 16px;
`;

export const Type = styled.div`
border-radius: 9px;
background: rgba(255, 255, 255, 0.80);
border: ${(props) => props.isSelected ? "1px solid #FF711E" : "none"};
height: 139px;
padding: 22px 36px;
display: flex;
justify-content: space-between;
cursor: pointer;
`;

export const Details = styled.div`
display: flex;
align-items: center;
height: 100%;
gap: 36px;
color: #1C1C1C;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

export const PlatfrormsConatiner = styled.div`
color: #1C1C1C;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
width: 164px;
`;

export const Platforms = styled.div`
margin-top: 11px;
display: flex;
flex-wrap: wrap;
gap: 16px;
`;

export const Image = styled.img`

`;

export const TypeDescription = styled.div`
width: 100%;
padding: 24px;
height: 139px;
border-radius: 8px;
background: rgba(255, 255, 255, 0.80);
`;

export const Title = styled.div`
color: #1C1C1C;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 111.111% */
margin-bottom: 16px;
`;

export const Description = styled.div`
width: 262px;
height: 59px;
`;

export const GenerateButton = styled.div`
display: flex;
justify-content: center;
align-items: center;
border-radius: 6px;
background: linear-gradient(180deg, #6907BA 0%, #D057C6 83.33%);
color: #FFF;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
height: 52px;
cursor: pointer;
`;