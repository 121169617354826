import styled from "styled-components";

export const RelatedKeywordsContainer = styled.div`
width: 338px;
height: 232px;
flex-shrink: 0;
border-radius: 8px;
background: #FFF;
padding: 16px;
::-webkit-scrollbar-track
{
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
    background-color: rgb(245 245 245 / 28%);
    border-radius: 10px;
}

::-webkit-scrollbar
{
    width: 5px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb
{
    background-color: #00000030;
    border-radius: 10px;
}
`

export const Container = styled.div`
width: calc(100% - 5px);
height: calc(100% - 47px);
overflow-y: hidden;
::-webkit-scrollbar-track
{
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
    background-color: rgb(245 245 245 / 28%);
    border-radius: 10px;
}

::-webkit-scrollbar
{
    width: 5px;
    border-radius: 10px;
    background-color: #fff;
}

::-webkit-scrollbar-thumb
{
    background-color: #00000030;
    border-radius: 10px;
}
`;

export const Title = styled.div`
color: #4F4F4F;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-bottom: 18px;
`;

export const Keywords = styled.div`
display: flex;
gap: 12px 18px;
flex-wrap: wrap;
width: 100%;
`;

export const Keyword = styled.div`
border-radius: 18px;
border: 1px solid #E3E8EE;
/* display: flex;
align-items: center;
justify-content: center; */
padding: 10px 16px;
color: rgba(28, 28, 28, 0.60);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
cursor: pointer;
&:hover{
    background: #F9F8FF;

}
`;