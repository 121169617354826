import React from 'react'
import { LinkedinProvider } from './context/linkedin'
import LinkedinAdspy from './components'
import {Helmet} from "react-helmet"
import { getSearchParams } from "../../../../../utils/helper.js"

const Linkedin = () => {

    const params = getSearchParams(window.location.search)
    const searchText = params.searchMode == "url" ? params.domainUrlInput : params.keywordSearchType == "phrase" ? params.phraseInput : ""
    const metaDesc = `Linkedin Ad Spy ${searchText ? ":" : ""} ${searchText} | AdSpyder`


    return <LinkedinProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{metaDesc}</title>
                <meta name="title" content={metaDesc} />
                <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization." />
            </Helmet>
        <LinkedinAdspy />
    </LinkedinProvider>
}

export default Linkedin