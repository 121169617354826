import React, { useState, useEffect, useRef } from "react";
import { Container, FilterBody, Header, FilterContent, FilterFooter, ClearAllBtn, ApplyFiltersBtn, CtaInputContainer, SentimentInputContainer, AdSentimentDropDownContainer, SelectedSentiment, AdSentimentDropDown, SentimentOption, ToneInputContainer, AdToneDropDownContainer, SelectedTone, AdToneDropDown, ToneOption, OtherFiltersContainer, OtherFilters, IndividualOtherFilter, CheckBox, CtaDropDownContainer, SelectedCta, CtaDropDown, CtaOption, LanguageInputContainer, LanguageDropDownContainer, SelectedLanguage, LanguageDropDown, LanguageOption } from "./index.styled";
import CloseIcon from "../../../../../../../../media/Dashboard/CloseDark.svg"
import { useGoogleSearch } from "../../../context/google";
import DownArrow from "../../../../../../../../media/Dashboard/DropDownArrowGrey.svg"
import { useSearchParams } from "react-router-dom";
import { LanguageFilter } from "../../../../../../../../utils/filterLang";

function useOutsideAlerter(ref, setOpenedDropDown) {
    useEffect(() => {
      function handleClickOutside(event) {
        console.log("inside ref", event.target.className)
        // if (ref.current && !ref.current.contains(event.target)) {
            if(!event.target.className.includes("tone-option") && !event.target.className.includes("language-option") && !event.target.className.includes("cta-option") && !event.target.className.includes("sentiment-option")){
                setOpenedDropDown("")
            }
        // }
      }
      document.addEventListener("mousedown", handleClickOutside)

      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      };
    }, [ref])
}

const AdvancerFilter = () => {
    const { googleSearchState, googleSearchDispatch } = useGoogleSearch()
    const [ openedDropDown, setOpenedDropDown ] = useState("")
    const [searchParams, setSearchParams] = useSearchParams()
    const wrapperRef = useRef(null)

    const filtersAdded = googleSearchState.selectedOtherFilters.length + googleSearchState.selectedLanguage.length + googleSearchState.selectedTone.length + googleSearchState.selectedCta.length + (googleSearchState.sentimentFilter ? 1 : 0);

    const sentimentOptionsList = [
        "neutral",
        "positive",
        "negative"
    ]

    const otherFilterList = [
        {
            displayName: "Ad copy sentiment",
            value: "adCopySentiment",
        },
        {
            displayName: "Price Mention",
            value: "priceMention",
        },
        {
            displayName: "Benefit Highlighting",
            value: "benefitHighlighting",
        },
     
        {
            displayName: "Use Of Numbers Or Statistics",
            value: "useOfNumbersOrStatistics",
        },
        {
            displayName: "Question Inclusion",
            value: "questionInclusion",
        },
        {
            displayName: "Urgency Words",
            value: "urgencyWords",
        },
     
        {
            displayName: "Feature Listing",
            value: "featureListing",
        },
        {
            displayName: "Problem Solution Dynamic",
            value: "problemSolutionDynamic",
        },
        {
            displayName: "Emotional Appeal",
            value: "emotionalAppeal",
        },
        {
            displayName: "Social Proof",
            value: "socialProof",
        },
        {
            displayName: "Comparison With Competitors",
            value: "comparisonWithCompetitors",
        },
        {
            displayName: "Target Audience Identification",
            value: "targetAudienceIdentification",
        },
        {
            displayName: "Localization Elements",
            value: "localizationElements",
        },
        {
            displayName: "Seasonal References",
            value: "seasonalReferences",
        },
        {
            displayName: "Discounts And Offers",
            value: "discountsAndOffers",
        },
        {
            displayName: "Guarantee Mentions",
            value: "guaranteeMentions",
        },
        {
            displayName: "Brand Name Placement",
            value: "brandNamePlacement",
        },
     
        {
            displayName: "Cross Sell Opportunities",
            value: "crossSellOpportunities",
        },
        {
            displayName: "Technological Compatibility",
            value: "technologicalCompatibility",
        },
        {
            displayName: "Ad Score",
            value: "adScore"
        }
    ]

    useOutsideAlerter(wrapperRef, setOpenedDropDown) 

    const handleCheckedChange = ({value}) => {
        if(googleSearchState.selectedOtherFilters.includes(value)){
            let tempArray = googleSearchState.selectedOtherFilters.filter((filter)=>{
                return filter != value
            });
            googleSearchDispatch({type: "SET_SELECTED_OTHER_FILTERS", value: tempArray})
        }else{
            googleSearchDispatch({type: "SET_SELECTED_OTHER_FILTERS", value: [...googleSearchState.selectedOtherFilters, value]})
        }
    }

    let languages = []; 
    let lanArray = document.querySelectorAll("tbody tr");
    for(let i = 0; i < lanArray.length; i++){
        languages.push({
            name: lanArray[i].querySelectorAll("td")[0].innerText
        })
    }

    const handleCtaCheckedChange = (cta) => {
        if(googleSearchState.selectedCta.includes(cta)){
            let tempArray = googleSearchState.selectedCta.filter((filter)=>{
                return filter != cta
            });
            googleSearchDispatch({type: "SET_SELECTED_CTA", value: tempArray})
        }else{
            googleSearchDispatch({type: "SET_SELECTED_CTA", value: [...googleSearchState.selectedCta, cta]})
        }
    }

    const handleToneCheckedChange = (tone) => {
        if(googleSearchState.selectedTone.includes(tone)){
            console.log("in 1 tone")
            let tempArray = googleSearchState.selectedTone.filter((filter)=>{
                return filter != tone
            });
            googleSearchDispatch({type: "SET_SELECTED_TONE", value: tempArray})
        }else{
            console.log("in 2 tone")
            googleSearchDispatch({type: "SET_SELECTED_TONE", value: [...googleSearchState.selectedTone, tone]})
        }
    }

    const handlelanguageCheckedChange = (language) => {
        if(googleSearchState.selectedLanguage.includes(language.code)){
            let tempArray = googleSearchState.selectedLanguage.filter((filter)=>{ return filter != language.code });
            googleSearchDispatch({type: "SET_SELECTED_LANGUAGE", value: tempArray})
        }else{
            googleSearchDispatch({type: "SET_SELECTED_LANGUAGE", value: [...googleSearchState.selectedLanguage, language.code]})
        }
    }

    const OnApplyFilter = () => {
        searchParams.set("language", (googleSearchState.selectedLanguage?.length > 0 ?  googleSearchState.selectedLanguage.join(","): ""))
        searchParams.set("tone", (googleSearchState.selectedTone?.length > 0 ? googleSearchState.selectedTone?.join(","): ""))
        searchParams.set("cta", (googleSearchState.selectedCta?.length > 0 ? googleSearchState.selectedCta?.join(","): ""))
        searchParams.set("sentiment", googleSearchState.sentimentFilter ? googleSearchState.sentimentFilter : "")
        searchParams.set("otherFilters", googleSearchState.selectedOtherFilters.join(","))
        setSearchParams(searchParams)

        googleSearchDispatch({type:"SET_ADVANCED_FILTERS", value: false})
    }

    return <Container>
        <FilterBody>
            <Header>
                <h2>Advanced filters</h2>
                <img onClick={()=>googleSearchDispatch({type:"SET_ADVANCED_FILTERS", value: false})} src={CloseIcon}/>
            </Header>
            <FilterContent>
                {/* <CtaInputContainer>
                    <label>
                        Call to Action (CTA) Text
                    </label>
                    <CtaDropDownContainer>
                        <SelectedCta onClick={()=>{
                            if(openedDropDown == "cta"){
                                setOpenedDropDown("")
                            }else{
                                setOpenedDropDown("cta")
                            }
                        }}>
                            <p>{googleSearchState.selectedCta.length > 0 ? googleSearchState.selectedCta[0] : "Select CTA"}</p>
                            <img src={DownArrow}/>
                        </SelectedCta>
                        {openedDropDown == "cta" && <CtaDropDown>
                            {
                                googleSearchState.availableCta.map((cta)=>{
                                    return <CtaOption onClick={()=>{googleSearchDispatch({type: "SET_SELECTED_CTA", value: [cta]})
                                    setOpenedDropDown("")
                                    }}>
                                        {cta}
                                    </CtaOption>
                                })
                            }
                        </CtaDropDown>}
                    </CtaDropDownContainer>
                </CtaInputContainer> */}
                <CtaInputContainer>
                    <label>
                        Call to Action (CTA) Text
                    </label>
                    <CtaDropDownContainer ref={wrapperRef} >
                        <SelectedCta onClick={()=>{
                            if(openedDropDown == "cta"){
                                setOpenedDropDown("")
                            }else{
                                setOpenedDropDown("cta")
                            }
                        }}>
                            <p>{googleSearchState.selectedCta.length > 0 ? (googleSearchState.selectedCta[0] + ((googleSearchState.selectedCta.length > 1) ? (" +" + (googleSearchState.selectedCta.length - 1)) : "")) : "Select CTA"}</p>
                            <img src={DownArrow}/>
                        </SelectedCta>
                        {openedDropDown == "cta" && <CtaDropDown>
                            {
                                googleSearchState.availableCta.map((cta, idx)=>{
                                    return <CtaOption htmlFor={idx} className="cta-option">
                                        <CheckBox type="checkbox" 
                                            checked={googleSearchState.selectedCta.includes(cta)}
                                            id={idx}
                                            className="cta-option"
                                            onChange={(e)=>handleCtaCheckedChange(cta)}
                                        />
                                        <label className="cta-option" htmlFor={idx}>{cta}</label>
                                    </CtaOption>
                                })
                            }
                        </CtaDropDown>}
                    </CtaDropDownContainer>
                    {/* <input value={googleSearchState.ctaFilter} onChange={(e)=> googleSearchDispatch({type: "SET_CTA_ADVANCED_FILTER", value: e.target.value})} placeholder="Search"/> */}
                </CtaInputContainer>
                {/* <CategoryInputContainer>
                    <label>
                        Product/Service Category
                    </label>
                    <input value={googleSearchState.categroyFilter} onChange={(e)=> googleSearchDispatch({type: "SET_CATEGORY_ADVANCED_FILTER", value: e.target.value})} placeholder="Category"/>
                </CategoryInputContainer> */}
                <SentimentInputContainer>
                    <label>
                        Ad Copy Sentiment
                    </label>
                    <AdSentimentDropDownContainer ref={wrapperRef}>
                        <SelectedSentiment onClick={()=>{
                            if(openedDropDown == "sentiment"){
                                setOpenedDropDown("")
                            }else{
                                setOpenedDropDown("sentiment")
                            }
                        }}>
                            <p>{googleSearchState.sentimentFilter ? googleSearchState.sentimentFilter : "Select Sentiment"}</p>
                            <img src={DownArrow}/>
                        </SelectedSentiment>
                        {openedDropDown == "sentiment" && <AdSentimentDropDown>
                            {
                                sentimentOptionsList.map((sentiment)=>{
                                    return <SentimentOption className="sentiment-option" onClick={()=>{googleSearchDispatch({type: "SET_SENTIMENT_ADVANCED_FILTER", value: sentiment})
                                    setOpenedDropDown("")
                                    }}>
                                        {sentiment}
                                    </SentimentOption>
                                })
                            }
                        </AdSentimentDropDown>}
                    </AdSentimentDropDownContainer>
                </SentimentInputContainer>
                {/* {googleSearchState.availableTone?.length > 0 && <ToneInputContainer>
                    <label>
                        Ad Copy Tone
                    </label>
                    <AdToneDropDownContainer>
                        <SelectedTone onClick={()=>{
                            if(openedDropDown == "tone"){
                                setOpenedDropDown("")
                            }else{
                                setOpenedDropDown("tone")
                            }
                        }}>
                            <p>{googleSearchState.selectedTone.length > 0 ? googleSearchState.selectedTone[0] : "tone"}</p>
                            <img src={DownArrow}/>
                        </SelectedTone>
                        {openedDropDown == "tone" && <AdToneDropDown>
                            {
                                googleSearchState.availableTone.map((tone)=>{
                                    return <ToneOption onClick={()=>{googleSearchDispatch({type: "SET_SELECTED_TONE", value: [tone]})
                                    setOpenedDropDown("")
                                    }}>
                                        {tone}
                                    </ToneOption>
                                })
                            }
                        </AdToneDropDown>}
                    </AdToneDropDownContainer>
                </ToneInputContainer>} */}
                {googleSearchState.availableTone?.length > 0 && <ToneInputContainer>
                    <label>
                        Ad Copy Tone
                    </label>
                    <AdToneDropDownContainer ref={wrapperRef}>
                        <SelectedTone onClick={()=>{
                            if(openedDropDown == "tone"){
                                setOpenedDropDown("")
                            }else{
                                setOpenedDropDown("tone")
                            }
                        }}>
                            <p>{googleSearchState.selectedTone.length > 0 ? (googleSearchState.selectedTone[0] + ((googleSearchState.selectedTone.length > 1) ? (" +" + (googleSearchState.selectedTone.length - 1)) : "")) : "tone"}</p>
                            <img src={DownArrow}/>
                        </SelectedTone>
                        {openedDropDown == "tone" && <AdToneDropDown>
                            {
                                googleSearchState.availableTone.map((tone, idx)=>{
                                    return <ToneOption className="tone-option" htmlFor={idx}>
                                         <CheckBox type="checkbox" 
                                            checked={googleSearchState.selectedTone.includes(tone)}
                                            id={idx}
                                            className="tone-option"
                                            onChange={(e)=>handleToneCheckedChange(tone)}
                                        />
                                        <label className="tone-option" htmlFor={idx}>{tone}</label>
                                    </ToneOption>
                                })
                            }
                        </AdToneDropDown>}
                    </AdToneDropDownContainer>
                </ToneInputContainer>}
                {/* <LanguageInputContainer>
                    <label>
                        Language
                    </label>
                    <LanguageDropDownContainer>
                        <SelectedLanguage onClick={()=>{
                            if(openedDropDown == "language"){
                                setOpenedDropDown("")
                            }else{
                                setOpenedDropDown("language")
                            }
                        }}>
                            <p>{googleSearchState.selectedLanguage.length > 0 ? googleSearchState.selectedLanguage[0] : "Select Language"}</p>
                            <img src={DownArrow}/>
                        </SelectedLanguage>
                        {openedDropDown == "language" && <LanguageDropDown>
                            {
                                googleSearchState.availableLanguage.map((language)=>{
                                    return <LanguageOption onClick={()=>{googleSearchDispatch({type: "SET_SELECTED_LANGUAGE", value: [language]})
                                    setOpenedDropDown("")
                                    }}>
                                        {language}
                                    </LanguageOption>
                                })
                            }
                        </LanguageDropDown>}
                    </LanguageDropDownContainer>
                </LanguageInputContainer> */}
                {googleSearchState.availableLanguage?.length > 0 &&  <LanguageInputContainer>
                    <label>
                        Language
                    </label>
                    <LanguageDropDownContainer ref={wrapperRef}>
                        <SelectedLanguage onClick={()=>{
                            if(openedDropDown == "language"){
                                setOpenedDropDown("")
                            }else{
                                setOpenedDropDown("language")
                            }
                        }}>
                            <p>{googleSearchState.selectedLanguage.length > 0 ? (LanguageFilter([googleSearchState.selectedLanguage[0]])[0].name + ((googleSearchState.selectedLanguage.length > 1) ? (" +" + (googleSearchState.selectedLanguage.length - 1)) : "")) : "Select Language"}</p>
                            <img src={DownArrow}/>
                        </SelectedLanguage>
                        {openedDropDown == "language" && <LanguageDropDown>
                            {
                                googleSearchState.availableLanguage.map((language, idx)=>{
                                    return <LanguageOption className="language-option" htmlFor={idx}>
                                        <CheckBox type="checkbox" 
                                            checked={googleSearchState.selectedLanguage.includes(language.code)}
                                            id={idx}
                                            className="language-option"
                                            onChange={(e)=>handlelanguageCheckedChange(language)}
                                        />
                                        <label className="language-option" htmlFor={idx}>{language.name}</label>
                                    </LanguageOption>
                                })
                            }
                        </LanguageDropDown>}
                    </LanguageDropDownContainer>
                </LanguageInputContainer>}
                <OtherFiltersContainer>
                    <label>
                        Show adcopies with 
                    </label>
                    <OtherFilters>
                        {otherFilterList.map((filter, idx)=>{
                            return <IndividualOtherFilter htmlFor={idx}>
                                <CheckBox type="checkbox" 
                                    checked={googleSearchState.selectedOtherFilters.includes(filter.value)}
                                    id={idx}
                                    onChange={(e)=>handleCheckedChange(filter)}
                                />
                                <label htmlFor={idx}>{filter.displayName}</label>
                            </IndividualOtherFilter>
                        })}
                    </OtherFilters>
                </OtherFiltersContainer>
            </FilterContent>
            <FilterFooter>
                <ClearAllBtn onClick={()=> {
                    googleSearchDispatch({type:"CLEAR_ALL_ADVANCED_FILTER"})
                }}>
                    Clear all {filtersAdded > 0 ? "(" + filtersAdded + ")" : ""}
                </ClearAllBtn>
                <ApplyFiltersBtn onClick={OnApplyFilter}>
                    Apply Filter
                </ApplyFiltersBtn>
            </FilterFooter>
        </FilterBody>
    </Container>
}

export default AdvancerFilter