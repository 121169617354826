import { useState } from "react";
// import { useAccount } from "../pages/AccountPages/context/account";
import { appClient } from "../../utils/axios-utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { usePasswordChange } from "../pages/AuthPages/ChangePassword/context/passwordChange";

const emailOtpVerify = (data) => {
  const config = {
    url: "/auth/local/cp-email-verify-code",
    method: "POST",
    data,
  };

  return appClient(config);
};

const getVerificationCode = (data) => {
  const config = {
    url: "/auth/local/cp-email-verification-code",
    method: "GET",
    data: { email: data },
  };

  return appClient(config);
};

export const useChangePassword = () => {
  const { passwordChangeState, passwordChangeDispatch } = usePasswordChange();

  const [otpError, setOtpError] = useState(false);
  let toastId;

  const { mutate: onSubmit } = useMutation(emailOtpVerify, {
    onSuccess: (data) => {
      setOtpError(false);
      passwordChangeDispatch({ type: "UPDATE_OTP_VERIFIED", value: true });
      toast.success("Successfully Verified OTP");
      toast.dismiss(toastId);
    },
    onError: (err) => {
      setOtpError(true);
      toast.dismiss(toastId);
    },
    onMutate: () => {
      toastId = toast.loading("Signing in");
    },
  });

  const { mutate: onSendVerificationCode } = useMutation(
    () => {
      return getVerificationCode(passwordChangeState.email);
    },
    {
      onSuccess: (data) => {
        // console.log("confirm email onSendVerificationCode",data)
        toast.dismiss(toastId);
        if (data) {
          toast.success("Email sent!");
          passwordChangeDispatch({
            type: "UPDATE_TIMER",
            value: new Date(data.tokenExpireTime).getTime(),
          });
          toast.success("OTP sent");
        }
      },
      onError: (err) => {
        // console.log("confirm email onSendVerificationCode",err)
        toast.dismiss(toastId);
        if (err.response.data.errors[0].errCode == "OTP_ERR") {
          passwordChangeDispatch({
            type: "UPDATE_TIMER",
            value: Math.floor(
              (new Date(err.response.data.errors[0].tokenExpiresAt).getTime() -
                Date.now()) /
                1000
            ),
          });
        } else if (err.response.data.errors[0].errCode == "USER_ERR") {
          passwordChangeDispatch({
            type: "UPDATE_TIMER",
            value: Math.floor(
              (new Date(err.response.data.errors[0].tokenExpiresAt).getTime() -
                Date.now()) /
                1000
            ),
          });
        }
      },
      onMutate: () => {
        toastId = toast.loading("Sending OTP");
      },
    }
  );
  return {
    onSubmit,
    onSendVerificationCode,
    otpError,
  };
};
