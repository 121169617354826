import styled, { keyframes } from "styled-components";

export const TopTextAdsContainer = styled.div`
width: 418px;
height: 232px;
flex-shrink: 0;
border-radius: 8px;
background: #FFF;
`;


export const Header = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
width: calc(100% - 21px - 19px);
margin: 16px 19px 14px 21px;
`;

export const Title = styled.div`
color: #4F4F4F;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 24px */
`;

export const ViewAll = styled.div`
color: rgba(28, 28, 28, 0.40);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
cursor: pointer;
`;

export const Content = styled.div`
width: 380px;
height: 154px;
flex-shrink: 0;
position: relative;
margin-left: 19px;
display: flex;
align-items: end;
justify-content: center;
`;

export const MobileOutline = styled.img`
position: absolute;
top: 0;
left: 0;
`;

export const MobileCamera = styled.img`
position: absolute;
transform: translateX(-50%);
left: 50%;
top: 13px;
`;

export const Display = styled.div`
width: 352px;
height: 122px;
flex-shrink: 0;
border: 10px solid #F5F5F5;
`;

export const EmptyState = styled.div`
width: 100%;
height: 100%;
flex-shrink: 0;
background: #F5F5F5;
display: flex;
align-items: center;
justify-content: center;
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 16.94px;
text-align: left;
color: #1c1c1c60;
`;

export const Scroller = styled.div`
width: 100%;
height: 100%;
position: relative;
overflow: hidden;
`;

const scrollerYAnimae = keyframes`
0%{
    transform: translateY(0%);
}

50%{
    transform: translateY(-36%);
}

100%{
    transform: translateY(-72%);
}
`;

export const Ads = styled.div`
position: absolute;
top: 0px;
animation: ${scrollerYAnimae} 18s linear infinite;
`;

export const AdContent = styled.div`
width: 332px;
flex-shrink: 0;
position: relative;
background: #fff;
margin-bottom: 5.49px;
`;

export const Logo = styled.img`
    position: absolute;
    top: 0px;
    right: 8.54px;
`;

export const AdTitle = styled.div`
width: 300px;
color: #553FB8;
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 9.245px */
margin: 11px 3px 2px 8px;
/* height: 40px;
width: 321px;
text-overflow: ellipsis; */
`;

export const Url = styled.div`
    display: flex;
    gap: 5px;
    margin-left: 9px;
    position: relative;
`;

export const AdTag = styled.div`
    width: 17px;
height: 100%;
flex-shrink: 0;
border-radius: 2px;
background: #7BAE86;
color: #FFF;
font-family: Inter;
font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: 150%;
display: flex;
align-items: center;
justify-content: center;
`;

export const RedirectLink = styled.div`
    color: #7BAE86;
font-family: Inter;
font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 7.823px */
`;

export const Separator = styled.div`
width: 333px;
height: 0.5px;
background: rgba(28, 28, 28, 0.20);
position: absolute;
bottom: -6px;
left: -9px;
`;

export const AdDescription = styled.div`
    /* width: 314px;
    height: 36px;
    text-overflow: ellipsis; */
    margin: 14px 0 0 8px;
    color: rgba(28, 28, 28, 0.50);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
`;
