import styled, { keyframes } from "styled-components";

export const ImageAdBanner = styled.div`
width: 508px;
height: 238px;
flex-shrink: 0;
border-radius: 4px;
background: linear-gradient(270deg, #ECD2BD 0.35%, #CDA888 99.65%);
position: relative;
`;

const carouselAnimateVertical = keyframes`
0% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
}
20% {
    transform: translateY(-50%) scale(0.7);
    opacity: 0.4;
    visibility: visible;
}


30%, 50% {
    transform: translateY(15%) scale(1);
    opacity: 1;
    visibility: visible;
}

60% {
    transform: translateY(30%) scale(0.7);
    opacity: 0.1;
    visibility: visible;
}

80% {
    transform: translateY(100%) scale(0.5);
    opacity: 0;
    visibility: visible;
}

100% {
    transform: translateY(100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
}
`;

export const VerticalSlider = styled.div`
    height: 100%;
    width: 188px;
    margin-left: 35px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
`;


export const SliderItem = styled.div`
position: absolute;
width: 0;
height: 188px;
opacity: 1;
filter: drop-shadow(0 2px 2px #555);
will-change: transform, opacity;
/* animation: ${carouselAnimateVertical} 12s linear infinite; */
background: #fff;
transform: translateY(-50%) scale(0.35);
top: 60px;
transform: ;
:nth-child(1) {
  animation-delay: 0;
}

:nth-child(2) {
  animation-delay: 4s;
}

:last-child {
  animation-delay: 8s;
}
`;

