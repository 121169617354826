import React, { useState, useEffect } from "react";
import { Container, TimeOptionsLst, TimeOption } from "./index.styled"
import { useLinkedin } from "../../../context/linkedin";

const TimeFilter = () => {
    const {linkedinState, linkedinDispatch} = useLinkedin()

    const [localSelectedTime, setLocalSelectedTime] = useState("")

    const timeList = [{
        name: "Last 30 days"
    },
    {
        name: "Last 6 months"
    },
    {
        name: "Last 1 year"
    }]

    const getOldMonthDate = (d) => {
        const dateString = new Date();
        const dateObject = new Date(dateString);
        dateObject.setMonth(dateObject.getMonth() + d);
        return dateObject;
    }

    const handleDateTypeSelect = (type) => {

        if(type == "Last 30 days"){
            // Reset Page Number
            linkedinDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loading'})
            linkedinDispatch({type: 'SET_CURRENT_PAGE_NO', value: 1})
            linkedinDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})

            linkedinDispatch({type: 'UPDATE_START_DATE', value: getOldMonthDate(-1)})
            linkedinDispatch({type: 'UPDATE_END_DATE', value: new Date()})
            linkedinDispatch({type:'UPDATE_DATE_FILTER_TYPE', value: type})

        }else if(type == "Last 6 months"){
             // Reset Page Number
             linkedinDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loading'})
             linkedinDispatch({type: 'SET_CURRENT_PAGE_NO', value: 1})
             linkedinDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})

            linkedinDispatch({type: 'UPDATE_START_DATE', value: getOldMonthDate(-6)})
            linkedinDispatch({type: 'UPDATE_END_DATE', value: new Date()})
            linkedinDispatch({type:'UPDATE_DATE_FILTER_TYPE', value: type})

        }else if(type == "Last 1 year"){
             // Reset Page Number
             linkedinDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loading'})
             linkedinDispatch({type: 'SET_CURRENT_PAGE_NO', value: 1})
             linkedinDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})
             
            linkedinDispatch({type: 'UPDATE_START_DATE', value: getOldMonthDate(-12)})
            linkedinDispatch({type: 'UPDATE_END_DATE', value: new Date()})
            linkedinDispatch({type:'UPDATE_DATE_FILTER_TYPE', value: type})

        }
        
    }

    useEffect(() => {
        setLocalSelectedTime(linkedinState.dateFilterType)
    }, [])
    
    return <Container>
        <TimeOptionsLst>
            {timeList.map((time,idx)=> {
                return <TimeOption 
                onClick={()=>{
                    setLocalSelectedTime(time.name)
                    handleDateTypeSelect(time.name)
                }}>
                    <input type="radio" checked={localSelectedTime == time.name}/>         
                    <label>{time.name}</label>               
                </TimeOption>
            })}
        </TimeOptionsLst>
    </Container>
}

export default TimeFilter