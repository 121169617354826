import React, { useState, useEffect, useRef } from "react";
import { Container, LocationSelectContainer, LocationInputContainer, LocationSuggestionContainer, SuggestionElement, SelectedLocationsConatiner, SelectedLocationList, SelectedLocationItem, LocationInput, LanguageSelectContainer, LanguageInputContainer, LanguageInput, LanguageSuggestionContainer, SelectedLanguageConatiner, SelectedLanguageList, SelectedLanguageItem } from "./Targeting.styled"
import { useAdManagement } from "../../../../context/AdManagementContext";
import SearchIcon from "../../../../../../../../media/Dashboard/searchIcon.svg"
import CloseDarkIcon from "../../../../../../../../media/Dashboard/CloseDark.svg"
import { appClient } from '../../../../../../../../utils/axios-utils';
import {useMutation} from "@tanstack/react-query"

const GetLocationSuggestion = async (data) => {
    const config = {
        url: `/admanagement/get-location/${data.query}`,
        method: "GET"
    }

    return appClient(config)
}

const Targeting = () => {
    const [locationSearchInput, setLocationSearchInput] = useState("")
    const [languageSearchInput, setLanguageSearchInput] = useState("")


    const [locationSuggestion, setLocationSuggestion] = useState([])
    const [languageSuggestion, setLanguageSuggestion] = useState([])

    const { adManagementState, adManagementDispatch } = useAdManagement()
    const ref = useRef()

    const suggestionLocationData = [
        "India",
        "Australia",
        "England",
        "France",
        "Ireland",
        "Germany"
    ]

    const suggestionlanguageData = [
        "English",
        "Tamil",
        "Kannada", 
        "Telugu", 
        "Germam"
    ]

    const onLocationSuggestionClick = (location) => {
        
        adManagementDispatch({type: "SET_OPENED_LOCATION_SUGGESTION", value: false})
        adManagementDispatch({type: "SET_LOCATIONS_LIST", value: [...adManagementState.locationsList, location]})
        ref.current.value = "";
    }

    const onLanguageSuggestionClick = (language) => {
        adManagementDispatch({type: "SET_OPENED_LANGUAGE_SUGGESTION", value: false})
        adManagementDispatch({type: "SET_LANGUAGE_LIST", value: [...adManagementState.languageList, language]})
    }

    const removeSelectedLocation = (location) => {
        let filteredLocationList = adManagementState.locationsList.filter((loc)=>{
            return loc != location
        })

        adManagementDispatch({type: "SET_LOCATIONS_LIST", value: filteredLocationList})
    }

    const removeSelectedLangugae = (language) => {
        let filteredLanguageList = adManagementState.languageList.filter((lan)=>{
            return lan != language
        })

        adManagementDispatch({type: "SET_LANGUAGE_LIST", value: filteredLanguageList})
    }

    const { mutate: GetLocationSuggestionMutate, isLoading: isGetLocationSuggestion } = useMutation(GetLocationSuggestion, {
        onSuccess: (data) => {
            // setLocationSuggestion(suggestionLocationData.slice(0,locationSearchInput.length < 6 ? locationSearchInput.length : 6))
            setLocationSuggestion(data)
        },
        onError: (err) => {
            setLocationSuggestion(suggestionLocationData.slice(0,locationSearchInput.length < 6 ? locationSearchInput.length : 6))
        }
    })


    const getLanguageSuggestion = (value) => {
        let filteredLanguage = suggestionlanguageData.filter((language)=>{
            return language.toLocaleLowerCase().includes(value.toLocaleLowerCase())
        })

        setLanguageSuggestion(filteredLanguage)
    } 
    useEffect(()=>{
        adManagementDispatch({type: "SET_LANGUAGE_LIST", value: [suggestionlanguageData[0]]})
    }, [])
    return <Container>
        <LocationSelectContainer>
            <label>Location</label>
            <LocationInputContainer>
                <LocationInput>
                    <img src={SearchIcon}/>
                    <input ref={ref} onChange={(e)=> {
                        const inputValue = e.target.value;
                        adManagementDispatch({type: "SET_OPENED_LOCATION_SUGGESTION", value: true})
                        setLocationSearchInput(inputValue)
                        if (inputValue.length >= 3) {
                            GetLocationSuggestionMutate({query: inputValue});
                        }
                    }}  placeholder="Search city, state or country"/>
                </LocationInput>
                {adManagementState.openedLocationSuggestion && <LocationSuggestionContainer>
                    {
                        locationSuggestion.map((suggestion) => {
                            return <SuggestionElement onClick={()=>onLocationSuggestionClick(suggestion)}>
                                {suggestion.name}
                            </SuggestionElement>
                        })
                    }
                </LocationSuggestionContainer>}
            </LocationInputContainer>

            {(adManagementState.locationsList.length > 0) && <SelectedLocationsConatiner>
                <label>Selected locations:</label>
                <SelectedLocationList>
                    {adManagementState.locationsList.map((location)=>{
                        return <SelectedLocationItem>
                            {location.name} <img onClick={()=>removeSelectedLocation(location)} src={CloseDarkIcon}/>
                        </SelectedLocationItem>
                    })}
                </SelectedLocationList>
            </SelectedLocationsConatiner>}

        </LocationSelectContainer>
        <LanguageSelectContainer>
            <label>Language</label>
            <LanguageInputContainer>
                <LanguageInput>
                    <img src={SearchIcon}/>
                    <input onChange={(e)=> {
                        adManagementDispatch({type: "SET_OPENED_LANGUAGE_SUGGESTION", value: true})
                        setLanguageSearchInput(e.target.value)
                        getLanguageSuggestion(e.target.value)
                    }}  placeholder="Search Language"
                    disabled={true}/>
                </LanguageInput>
                {adManagementState.openedLanguageSuggestion && <LanguageSuggestionContainer>
                    {
                        languageSuggestion.map((suggestion) => {
                            return <SuggestionElement onClick={()=>onLanguageSuggestionClick(suggestion)}>
                                {suggestion}
                            </SuggestionElement>
                        })
                    }
                </LanguageSuggestionContainer>}
            </LanguageInputContainer>

            {(adManagementState.languageList.length > 0) && <SelectedLanguageConatiner>
                <label>Selected languages:</label>
                <SelectedLanguageList>
                    {adManagementState.languageList.map((language)=>{
                        return <SelectedLanguageItem>
                            {language} <img /**onClick={()=>removeSelectedLangugae(language)}**/ src={CloseDarkIcon}/>
                        </SelectedLanguageItem>
                    })}
                </SelectedLanguageList>
            </SelectedLanguageConatiner>}

        </LanguageSelectContainer>
    </Container>
}

export default Targeting