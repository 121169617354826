import styled from "styled-components";

export const FloatingButtonsContainer = styled.div`
    position: absolute;
    right: 12px;
    top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F9F9F9;
    color: #D4D4D4;
    font-size: 8px;
`;

export const SponsoredText = styled.div`
    color: #434343;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;
`;

export const AdHeader = styled.div`
    display: flex;
    margin-bottom: 8px;
`;

export const WebsiteLogo = styled.div`
    background: #F1F3F4;
    height: 32px;
    border-radius: 20px;
    margin-right: 12px;
    img{
        height: 20px;
        margin: 6px;
        border-radius: 10px;
    }
`;

export const WebsiteNameContainer = styled.div`

`;

export const WebsiteName = styled.div`

`;

export const AdUrl = styled.p`
    font-size: 14px;
    font-weight: 300;
    color: #006621;
    line-height: 21px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    /* max-width: 420px; */
    margin-top: 4px;
    width: 350px;
`;

export const CardFooter = styled.div`
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #F2F2F2;
    padding: 16px;
    align-items: center;
`;

export const FooterLeft = styled.div`
    display: flex;
        width: 75%;
`;

export const AdDate = styled.div`
    display: flex;
    align-items: center;
    width: 65%;


    img{
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }

    p{
        font-size: 14px;
        margin-right: 10px;
        color: #202124;
        line-height: 22px;

        display: inline-block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }
`;

export const AdCountries = styled.div`
    display: flex;
    align-items: center;
    width: 35%;
    
    p{
        /* color: #919191; */
        margin-right: 16px;
        font-size: 14px;


        display: inline-block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;

    }
    > img{
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
`;

export const FooterRight = styled.div`
    display: flex;
        width: 25%;
`;

export const DetailsButton = styled.div`
    border-radius: 4px;
    background: rgb(232, 242, 255);
    padding: 4px 12px;
    line-height: 22px;
    color: #006ECC;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;

`;


export const Favorite = styled.div`
    background: #F9F9F9;
    width: 32px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    border-radius: 4px 0px 0px 4px;
    img{
        margin: 25%;
    }
    &:hover{
        background: #ECECEC;
    }
    &:active{
        background: #DCEEFF;
    }
    
`;

////////////////

export const Card = styled.div`
border-radius: 8px;
background: #FFF;
// padding: 24px;
// display: flex;
// flex-direction: column;
// row-gap: 12px;
// width: 500px;
// height: 230px;
.hover-element {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  :hover .hover-element {
    opacity: 1;
    visibility: visible;
  }
`
export const CardBody = styled.div`
padding: 24px;
display: flex;
flex-direction: column;
row-gap: 12px;
width: 500px;
height: 230px;
`

export const CardWrapper = styled.div`
position: relative;
display: flex;
flex-wrap: wrap;
gap: 16px;

// .hover-element {
//     opacity: 0;
//     visibility: hidden;
//     transition: opacity 0.3s ease, visibility 0.3s ease;
//   }
//   :hover .hover-element {
//     opacity: 1;
//     visibility: visible;
//   }
`
export const Header = styled.div`
display: flex;
column-gap: 16px;
`
export const LogoWrapper = styled.div`
border-radius: 16px;
border: 1px solid #ECECEC;
background: #F1F3F4;
display: flex;
padding: 6px;
width: 32px;
height: 32px;
justify-content: center;
align-items: center;

img{
    height: 20px;
}
`
export const TitleWrap = styled.div`
display: flex;
flex-direction: column;
row-gap: 2px;
width: 65%;
`
export const Title = styled.div`
overflow: hidden;
color: #2E2E2E;
text-overflow: ellipsis;
font-family: Inter;
font-size: 14px;
font-weight: 400;
`
export const LinkWrap = styled.div`
overflow: hidden;
color: #598763;
font-family: Inter;
font-size: 12px;
font-weight: 300;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
`
export const KeywordWrap = styled.div`
overflow: hidden;
color: #3F33C8;
font-family: Inter;
font-size: 16px;
font-weight: 400;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
`
export const DescriptionWrap = styled.div`
overflow: hidden;
color: rgba(28, 28, 28, 0.50);
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 20px; /* 142.857% */
height: 62px;

`

export const FooterWrap = styled.div`
display: flex;
justify-content: space-between;
padding-top: 13px;
align-items: center;
`
export const CountryWrap = styled.div`
display: flex;
column-gap: 12px;
align-items: center;
`
export const Country = styled.div`
overflow: hidden;
color: rgba(28, 28, 28, 0.50);
text-overflow: ellipsis;
white-space: nowrap;
font-family: Inter;
font-size: 14px;
font-weight: 400;
`
export const GoogleIcon = styled.div`
img{
    height: 16px;
}
`
export const HoverCardWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 150px;
`;


export const HoverCard = styled.div`
    position: absolute;
    width: 500px;
    height: 230px;
    z-index: 3;
    // background: #1C1C1C59;
    border-radius: 8px;
    cursor: pointer;
    padding: 16px;

    background: linear-gradient(360deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.35) 32.4%, rgba(0, 0, 0, 0.15) 63.4%, rgba(0, 0, 0, 0.05) 100%);
`;

export const AdDetailsButton = styled.div`
    border-radius: 24px;
    background: #FFF;
    width: fit-content;
    display: flex;
    padding: 10px 12px;
    gap: 4px;
    justify-content: center;
    align-items: center;
`;