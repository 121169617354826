export default {
    funnelLoadingMessages: [
        "Searching Our Ad Repository of 1 Billion+ Ads",
        "Getting Ad Copies",
        "Initiating AI ",
        "Classifying Ad Copies Based on Funnel",
        "Analysing Target Audiences",
        "Analysing Keywords and CTAs",
        "Adding Final Touches",
        "Request taking longer than expected, Please wait..."
    ],
    landingPageLoadingMessagesInitial: [
        "Initialising Target Landing Page",
        "Taking Screenshot",
        "Merging the Screenshot",
        "Adding Final Touches",
        "Request taking longer than expected, Please wait"
    ],
    landingPageLoadingMessagesOld: [
        "Searching our database",
        "Fetching Screenshot for the target date",
        "Loading Screenshot",
        "Adding Final Touches",
        "Request taking longer than expected, Please wait..."
    ],
    domainTrackingLoadingMessages: [
        "Initiating Web Crawlers",
        "Scouring through the Internet",
        "Fetching ad copies from around the globe",
        "Updating our Database",
        "Initiating AI",
        "Analysing Ad Copies collected",
        "Adding Final Touches",
        // "Request taking longer than expected, Please wait"
    ]

}