import { Choice, ChoiceList, Header, Item, Label,TargetAudienceContainer } from "./TargetAudience.styled";

import { useImageAdGenerator } from "../../../../context/imageAdGeneration";
import statics from "../../../../statics";
import Footer from "./Footer";

function TargetAudience() {
    const { targetAudienceForm } = statics
    const { imageAdGeneratorState, imageAdGeneratorDispatch } = useImageAdGenerator()
    
    const setTargetAudience = (obj) => {
        imageAdGeneratorDispatch({
            type: "SET_TARGET_AUDIENCE",
            value: obj
        })
    }

    const handleSelectData = (id, choice) => {
        if(id === 1){
            setTargetAudience({ageGroup: choice})
            return
        }
        if(id === 2){
            setTargetAudience({gender: choice})
            return
        }
    }
    return(
        <>
        <TargetAudienceContainer>
        <Header>Target audience</Header>
            {
                targetAudienceForm.map((data) => {
                    return(
                        <Item key={data.id }>
                            <Label>{data.key}</Label>
                            <ChoiceList >
                                {data.choices.map((c) => {
                                    const isSelected = data.id === 1 
                                    ? imageAdGeneratorState.targetAudience.ageGroup === c.choice 
                                    : imageAdGeneratorState.targetAudience.gender === c.choice 
                                    return(
                                        <Choice key={c.choiceId} onClick={() => handleSelectData(data.id, c.choice)} isSelected={isSelected}>{c.choice}</Choice>
                                    )
                                })}
                            </ChoiceList>
                        </Item>
                    )
                })
            }
        </TargetAudienceContainer>
            <Footer />
            </>
    )
}

export default TargetAudience;