import React, { useEffect, useState, useRef } from "react";

import { TagsInput } from "react-tag-input-component"
// import { useSearch } from "../../../../context/search";

import ToolTipLogo from "../../../../../../../../media/Dashboard/tooltip.svg"

import DownArrow from "../../../../../../../../media/Dashboard/uparrow.png"
import SearchIcon from '../../../../../../../../media/Dashboard/searchIcon.svg'

import ToolTipIcon from '../../../../../../../../media/Dashboard/info_button_tool_tip.svg'
import Tooltip from 'rc-tooltip';
import { useNavigate } from 'react-router-dom'

import styled from "styled-components"
import { useAuth } from "../../../../../../../../context/auth";

const Container = styled.div`
    display: flex;
    align-items: flex-start;
    border: 1px solid rgb(236, 236, 236);
    /* border-radius: 8px; */
    position: absolute;
    width: 100%;
    background: #FFF;
    /* height: 40px; */
    input{
      display: ${props => props.limitReached ? "none": "unset"};
      height: 32px;
      width: 100%;
    }
    .rti--container{
        background: none;
        flex-wrap: ${props => props.inputExpand ? "wrap" : "nowrap"};
        overflow: hidden;
        justify-content: ${props => props.inputExpand ? "unset" : "flex-end"};
        padding: ${props => props.inputExpand ? "5px 0px": "0px"};
    }
    .rti--tag{
        height: 30px !important;
        padding: 2px 10px;
        margin: 3px;
    }

    input{
        width: ${props => props.inputExpand ? "100%": "50%"};
    }
    /* width: 99%;
    background: #FFF;
    position: relative ;

    border: 1px solid #ECECEC;
    
    display: flex;
    align-items: ${props => props.expanded ? "unset" : "center"};
    padding: 8px 40px 8px 35px;
    z-index: 2;
    justify-content: flex-end;
    padding-right: 15px;
    min-height: 40px;
    height: ${props => props.inputExpand ? "fit-content": "auto"};
    overflow: hidden;

    input{
        padding: 0px 8px 0px 20px;
        min-width: 75px;
        background: #FFF;
    }

    .rti--container{
        display: ${props => props.inputExpand ? "": "contents"};
        gap: 5px 0px!important;
        margin-left: 15px;
        background: #FFF;
    }

    border: ${props => props.error ? "1px solid #FF5180" : "1px solid #ECECEC"};
    outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};
    &:focus{
        border: ${props => props.error ? "1px solid #FF5180" : "1px solid #5AB3FF"};
        outline: ${props => props.error ? "2px solid #FFB7CB" : "2px solid #B9DDFF"};
    } */

    
    /* overflow-x: auto; */
`;

const ArrowButtons = styled.div`
    height: 38px;
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0px;
    padding: 12px 14px;
    cursor: pointer;
    img{
        height: 10px;
        transform: ${props => props.inputExpand ? "": "rotate(180deg)"};
    }
`

const ToolTipImageContainer = styled.div`
    /* position: relative; */
    top: 0px;
    right: 0px;
    height: 40px;
    width: 40px;
    display: ${props => props.error ? "flex": "none"};

    img{
        margin-top: 3px;
        filter: contrast(1);
    }

    &:hover{
        > div{
            display: flex;
        }

        img{
            display: block;
        }
    }
`;

const ToolTip = styled.div`
    background: #555555;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 300;
    position: absolute;
    bottom: -34px;
    padding: 4px 8px;
    width: 210px;
    display: none;


    height: 20px;
    top: 240px;

`;

const ToolTipContainer = styled.div`
    font-size: 12px;
    font-weight: 300;
    color: #666;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;

    img{
        margin-left: 4px;
    }
`;

const Wrapper = styled.div`
    width: 400px;
    position: relative;
`;

const SearchIconBox = styled.div`
    height: 38px;
    padding: 12px;
    width: 38px;
    /* background: ${(props) => (props.isDisabled ? "#F7F9FA" : "#FFF")}; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: absolute; */
    left: 0;
    padding: 12px 14px;
    > img {
        height: 16px;
    }
`;


function useOutsideAlerter(ref, setInputExpand) {

    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setInputExpand(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref])
}

const KeywordsInput = () => {
    const [initialClassNames, setInitialClassNames] = useState({})
    const [inputExpand, setInputExpand] = useState(false)
    // const { searchState, searchDispatch } = useSearch()
    const { authState, authDispatch } = useAuth()
    const wrapperRef = useRef(null)

    useOutsideAlerter(wrapperRef, setInputExpand)

    useEffect(() => {
        if(authState.keywords.length >= 5) {
            setInitialClassNames({input: "rti-remove-input"})
        } else {
            setInitialClassNames({})
        }
    }, [authState.keywords])

    // console.log(authState)

    return <Wrapper expanded={inputExpand }>
    {/* <ToolTipContainer>
        enter keywords <Tooltip placement="right" trigger={['hover']} overlay={<span>Enter up-to 10 keywords you're looking to find in the ad copies.</span>}>
            <img src={ToolTipIcon}/>
        </Tooltip>
    </ToolTipContainer> */}
    {/* <Container error={authState.keywordInputError} inputExpand={inputExpand} ref={wrapperRef}>
        <TagsInput 
            value={authState.keywords}
            onChange={(newkeywords) => {
                authDispatch({ type: 'SET_KEYWORDS', value: newkeywords })
                authDispatch({type: 'SET_KEYWORD_INPUT_ERROR', value: false})
                if(authState.searchDisabled){
                    authDispatch({type: 'SET_SEARCH_DISABLED', value: false})
                }
            }}
            maxTags={10}
            name="keywords"
            placeHolder={authState.keywords.length == 0 ? "Enter up to 10 keywords" : "Enter up to 10 keywords"}
            separators={["Enter", " ", ","]}
            classNames={initialClassNames}
        />
         
        <SearchIconBox>
        <img src={SearchIcon}/>
        </SearchIconBox>
        
        {authState.keywords.length > 0 && <ArrowButtons onClick={()=>setInputExpand(!inputExpand)}>
            <img src={DownArrow}/>
        </ArrowButtons>}
        <ToolTipImageContainer error={authState.keywordInputError} className="tooltip-container">
            <img src={ToolTipLogo}/>
            <ToolTip>
                You can enter upto 10 keywords
            </ToolTip>
        </ToolTipImageContainer>
    </Container> */}
    <Container 
        limitReached={authState.keywords.length >= 10}
        inputExpand={inputExpand}    
    >
        <SearchIconBox>
          <img src={SearchIcon} />
        </SearchIconBox>       
        <TagsInput
          value={authState.keywords}
          maxTags={10}
          onChange={(newkeywords) => {
            authDispatch({ type: "SET_KEYWORDS", value: newkeywords });
            authDispatch({ type: "SET_KEYWORD_INPUT_ERROR", value: false });
          }}
          name="keywords"
          placeHolder={`+ add more keywords (${authState.keywords.length}/10)`}
        />
        {authState.keywords.length > 0 && <ArrowButtons onClick={()=>setInputExpand(!inputExpand)}>
            <img src={DownArrow}/>
        </ArrowButtons>}
      </Container>

    </Wrapper>
}

export default KeywordsInput