import React from "react";
import {appClient} from '../../../../../../utils/axios-utils'
import {useNavigate} from 'react-router-dom'
import {useForm} from "react-hook-form"
import {useMutation} from "@tanstack/react-query"
import {
    ResetPasswordHeader,
    FormContainer,
    CtaContainer,
    EmailSelected,
    PasswordInputContainer,
    PasswordContainer,
    PasswordInput,
    ChangePasswordButton,
    FooterContainer
} from './index.styled'
import toast from "react-hot-toast"

import { usePasswordChange } from './../../context/passwordChange'

const updatePassword = (data) => {
    delete data.confirmPassword
    const config = {
        url: "/auth/local/change-password",
        method: "POST",
        data
    }

    return appClient(config)
}

const NewPassword = () => {
    const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm()

    const { passwordChangeState, passwordChangeDispatch } = usePasswordChange()

    const navigate = useNavigate()

    let toastId;

    // useEffect(() => { setValue("email", email) }, [])

    const { mutate: onSubmit } = useMutation(updatePassword, {
        onSuccess: (data) => {
            toast.success(data?.message)
            toast.dismiss(toastId)
            passwordChangeDispatch({type:'SET_PASSWORD_CHANGED'})
            passwordChangeDispatch({type: 'UPDATE_TIMER', value: 3})

            // navigate("/")
        },
        onError: (err) => {
            toast.dismiss(toastId)
        },
        onMutate: () => {
            toastId = toast.loading("Changing your password..")
        }
    })

    return <>
        <ResetPasswordHeader>
            <p>Reset your password</p>
            <EmailSelected>
            <p>
            {passwordChangeState.email} <a onClick={()=>{
                passwordChangeDispatch({type: 'SET_EMAIL',value: ''})
                passwordChangeDispatch({type: 'SET_OTP_SENT',value: false})
                passwordChangeDispatch({type: 'UPDATE_OTP_VERIFIED', value: false})
                navigate('/changepassword')
            }}>Change email</a>
            </p>
        </EmailSelected>
        </ResetPasswordHeader>
        

        <FormContainer 
        // onChange={(e)=>{handlePasswordInput(e)}}
        onSubmit={
            handleSubmit((data) => {
                data.email = passwordChangeState.email;
                data.verificationCode = passwordChangeState.otp;
                // console.log(data)
                onSubmit(data)
            }
                )
        }>
            <PasswordInputContainer>
                    <label>New password</label>
                    <PasswordContainer>
                        <PasswordInput className='password-input' //onChange={(e)=>setPasswordEntered(e.target.value)} 
                        // placeholder="Enter a password*"
                            error={errors.password}
                            type={passwordChangeState.passwordType}
                            {...register("password", { 
                                required: "Enter a password",
                                minLength: {
                                    value: 8,
                                    message: "Password must have at least 8 characters"
                                }
                            })}
                        />
                        {passwordChangeState.passwordEntered.length > 0 && <i className={passwordChangeState.passwordType == "password" ? "fa-solid fa-eye": "fa-solid fa-eye-slash"} id="eye" onClick={()=>{
                            passwordChangeDispatch({type: 'UPDATE_PASSWORD_TYPE',value: passwordChangeState.passwordType == "password" ? "text" : "password"})
                        }}></i>}
                    </PasswordContainer>
                </PasswordInputContainer>
                <PasswordInputContainer>
                    <label>Confirm password</label>
                    <PasswordContainer>
                        <PasswordInput  // placeholder="Enter a password*"
                            type={passwordChangeState.passwordType}
                            error={errors.confirmPassword}
                            {...register("confirmPassword", { 
                                required: "Retype password",
                                minLength: {
                                    value: 8,
                                    message: "Password must have at least 8 characters"
                                },
                                validate: (val) => {
                                    if (watch('password') != val) {
                                      return "Your passwords do no match";
                                    }
                                }
                            })}
                        />
                    </PasswordContainer>
                </PasswordInputContainer>
            <CtaContainer>
                <ChangePasswordButton>Change password</ChangePasswordButton>
            </CtaContainer>
            <FooterContainer>
                <p>Please <a href = "mailto: support@adspyder.com">Contact us</a> if you haven't recieved the OTP</p>
            </FooterContainer>
        </FormContainer>
    </>
}

export default NewPassword