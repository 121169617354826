import styled from "styled-components";

export const Container = styled.div`
    

`;

export const SearchInList = styled.div`
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    gap: 14px;
`;

export const SearchInOption = styled.div`
    display: flex;
    gap: 6px;
`;

export const CheckBox = styled.input`
    

`;