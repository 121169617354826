import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const HeaderSection = styled.div`
    border-radius: 16px;
    background: url(${props => props.backgrounfImg});
    height: 124px;
    max-width: 966px;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 25px;
    margin-top: 24px;
    gap: 20px;
    position: relative;

    h2{
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        color: #FFF;
    }

`;

export const CategoryImgContainer = styled.div`
    border-radius: 22.857px;
    border: 1.429px solid #7177EF;
    background: url(${props => props.img});
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 290px;
    background-size: cover;
    width: 100%;
    /* height: 100%; */
    height: 170px;
    
    img{
        width: 100%;
        width: 44px;
    }

    div{
        border-radius: 16px;
        border: 1px solid #DADFE4;
        background: ${props => props.color ? props.color : ""};
        padding: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }
`;

export const BackButton = styled.div`
    border: solid 1px rgba(28, 28, 28, 0.10);
    border-radius: 50%;
    position: absolute;
    left: 12px;
    top: 12px;
    width: 30px;
    height: 30px;
    display: flex;
    cursor: pointer;
`;


export const FavButton = styled.div`
    border-radius: 10px;
    border: 1px solid #E3E8EE;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    position: absolute;
    right: 12px;
    top: 12px;
    img{
        width: 20px;
        height: 20px;
    }
`;


export const TemplatesContainer = styled.div`
    max-width: 966px;
    width: 90%;
    margin: 42px auto auto;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h2{
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: #1C1C1CB2;
        margin-bottom: 24px;
        width: 100%;
        display: flex;
        height: 36px;
        justify-content: space-between;
    }
`;

export const TemplatesList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
`;

export const TemplateCard = styled.div`
    cursor: pointer;
    width: 232px;
    height: 214px;
    background: #8080802e;
    padding: 10px;
    border-radius: 2px;
    img{
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
`;

export const RequestTemplateButton = styled.div`
    button{
        border-radius: 6px;
        background: #FF711E;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #FFF;
        padding: 8px 12px;
        outline: none;
        border: none;
        pointer-events: ${props => props.isDisabled ? "none" : "all"};
    }
`;

export const FilterSection = styled.div`
    height: 74px;
    width: 90%;
    max-width: 966px;
    margin: auto;
    border-radius: 6px;
    border: 1px solid #E3E8EE;
    background: #FFF;
    margin-top: 24px;
    display: flex;
    align-items: center;
    padding: 0px 24px;
    justify-content: space-between;
    h3{
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        color: rgba(28, 28, 28, 0.90);
    }
`

export const ApplyFilterButton = styled.div`
    border-radius: 6px;
    background: #FF711E;
    width: 74px;
    height: 36px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;