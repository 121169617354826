import React, { useState } from "react";
import { SearchContainer } from "./index.styled"
import PlatformDropDown from "./components/Platform"
import KeywordComponent from "./components/Keyword"
import UrlComponent from "./components/Url";
import { useAuth } from "../../../../context/auth";
import LimitExceededPopup from "../../../components/limitExceededPopup";

const ResultPageSearch = () => {

    const {authState, authDispatch} = useAuth()
    const [creditLimitReachedPopup, setCreditLimitReachedPopup] = useState(false)
    return <SearchContainer>
                <PlatformDropDown/>
                {
                    authState.searchMode === "keyword" ?             
                    <KeywordComponent setCreditLimitReachedPopup={setCreditLimitReachedPopup} /> : <UrlComponent setCreditLimitReachedPopup={setCreditLimitReachedPopup} />
                }
                {creditLimitReachedPopup && <LimitExceededPopup closeEnabled onClose={() => setCreditLimitReachedPopup(false)} /> }
            </SearchContainer>
}

export default ResultPageSearch