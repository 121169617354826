import React from "react";
import styled, { keyframes } from "styled-components";

const Container = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 6px;
`;

const bouncingLoader = keyframes`
to {
    opacity: 0.1;
    transform: translateY(-4px);
  }
`;

const Dot = styled.div`
  width: 4px;
  height: 4px;
  margin: 3px;
  border-radius: 50%;
  background-color: #000;
  opacity: 1;
  animation: ${bouncingLoader} 0.6s infinite alternate;
`;



const BouncingDotsLoader = (props) => {
  return (
      <Container className="bouncing-loader">
        <Dot></Dot>
        <Dot style={{animationDelay: "0.2s"}} ></Dot>
        <Dot style={{animationDelay: "0.4s"}}></Dot>
      </Container>
  );
};

export default BouncingDotsLoader;