import { useEffect, useRef, useState } from "react";
import { useOverview } from "../../../../context/overview";
import { Input, Label, ProjectDetailsForm, RequiredMark } from "./index.styled";

const fields = [
    {
        id: 1,
        label: "Project Name",
        isMandatory: false,
        placeHolder: "Eg. iPhone 15 pro",
        programmaticName: "projectName"
    },
    {
        id: 2,
        label: "Budget",
        isMandatory: false,
        placeHolder: "Eg. 10000",
        programmaticName: "budget"
    },
    {
        id: 3,
        label: "Project Description",
        isMandatory: false,
        placeHolder: "Enter the description",
        programmaticName: "projectDescription"
    },
    {
        id: 4,
        label: "Project Goals/Objectives",
        isMandatory: false,
        placeHolder: "Enter your project goal",
        programmaticName: "projectObjective",
    },
    {
        id: 5,
        label: "Timeline/Deadline",
        isMandatory: false,
        placeHolder: "Enter your project deadline",
        programmaticName: "deadline",
    }
]

function ProjectDetails() {
    const fieldsRefs = useRef([])

    const { overviewState: { projectDetails }, overviewDispatch } = useOverview()
    const [inputFocussed, setInputFocussed] = useState(null)

    const handleChange = (field) => {
        overviewDispatch({
            type: "SET_PROJECT_DETAILS",
            value: { [field.programmaticName]: fieldsRefs.current[field.id - 1].value }
        })
    }

    useEffect(() => {
        if(!projectDetails?.projectName){
            overviewDispatch({
                type: "SET_PROJECT_DETAILS",
                value: { projectName: "Aarogyam B pro" }
            })
        }
    }, [])

    return (
        <ProjectDetailsForm>
            {
                fields.map((field) => {
                    const { id, label, isMandatory, placeHolder, programmaticName } = field
                    return (
                        <Label key={id}>
                            {label}
                            {isMandatory ? <RequiredMark> *</RequiredMark> : null}
                            <Input
                                ref={node => fieldsRefs.current[id - 1] = node}
                                required={isMandatory}
                                placeholder={placeHolder}
                                onChange={() => handleChange(field)}
                                value={projectDetails ? projectDetails[programmaticName] : ""}
                                inActive={id === 1}
                                wantBig={id === 3 || id === 4}
                                type={id === 5 ? "date" : "text"}
                                isFocussed={inputFocussed === field.label}
                                onFocus={() => setInputFocussed(field.label)}
                                onBlur={() => setInputFocussed(null)}
                            />
                        </Label>
                    )
                })
            }
        </ProjectDetailsForm>
    );
}

export default ProjectDetails;