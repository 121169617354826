import styled from "styled-components";

export const AdgroupNavWrap = styled.div`
  display: flex;
  width: 35.5%;
`;
export const AdgroupNav = styled.div`
  width: 21.5%;
  min-width: 80px;
  height: 100%;
  padding: 16px 8px 0 8px;
  border-left: 1px solid #f0f0f0;
  background: #fff;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border-left: 1px solid #f0f0f0;
  background: #fff;
  align-items: center;
`;
export const PreviewBtn = styled.div`
  width: 92px;
  border: ${(prop) => (prop.props ? "1px solid #F0F0F0" : "1px solid #e8e8e8")};
  max-width: 100%;
  display: flex;
  height: 64px;
  padding: 12px 20px 12px 19px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;
  border-radius: 4px;
  cursor: pointer;
  background: ${(prop) =>
    prop.props ? "var(--black-100, #1C1C1C)" : "#FDFDFD"};
  p {
    color: ${(prop) => (prop.props ? "#FFFFFF" : "#1C1C1C")};
    text-align: center;
    font-size: 14px;
    font-weight: 400;
  }
  img {
    height: 16px;
    filter: ${(prop) => (prop.props ? "invert(100%)" : "")};
  }
`;
export const Divider = styled.div`
  height: 1px;
  background: #e8e8e8;
  width: 100%;
`;
export const PublishWrap = styled.div`
  border-left: 1px solid #f0f0f0;
  background: #fff;
  width: 78.5%;
  h3 {
    color: var(--black-100, #1c1c1c);
    font-size: 18px;
    font-weight: 600;
    margin: unset;
  }
`;
