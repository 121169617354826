import styled from "styled-components";

export const Wrapper = styled.div`
padding: 32px 112px 0 82px;
display: flex;
flex-direction: column;
align-items: center;
`
export const KeywordWrap = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
max-width: 966px;
width: 100%;

`
export const KeywordTracking = styled.div`
color: #000;
font-family: 'Inter';
font-size: 20px;
font-weight: 600;
`
export const AddKeyword = styled.div`
border-radius: 6px;
background: #FF711E;
color: #FFF;
font-family: 'Inter';
font-size: 14px;
font-weight: 500;
width: 154px;
height: 40px;
display: flex;
align-items: center;
justify-content: center;
column-gap: 8px;
cursor: pointer;
`
export const TableWrap = styled.div`
max-width: 966px;
width: 100%;
height: 617px;
border-radius: 6px;
background: #FFF;
margin-top: 24px;
`
export const TableHeader = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
padding: 0 46px;
color: rgba(28, 28, 28, 0.50);
font-family: 'Inter';
font-size: 14px;
font-weight: 500;
border-radius: 6px 6px 0px 0px;
background: #E3E8EE;
max-width: 966px;
height: 57px;
    img{
        height: 16px;
        margin-left: 5px;
    }  
`
export const KeyHeader = styled.div`
width: 32%;
    display: flex;
    align-items: center;
        cursor: pointer;
`
export const StatusHeader = styled.div`
text-align: center;
    display: flex;
    align-items: center;
        justify-content: center;
width: 25%;
    cursor: pointer;

`
export const UpdatedHeader = styled.div`
text-align: center;
    display: flex;
    align-items: center;
width: 25%;
    justify-content: center;
    cursor: pointer;
`

export const TableBody = styled.div`
height: 90%;
overflow-y: auto;
::-webkit-scrollbar-track
{
    border-radius: 10px;
}

::-webkit-scrollbar
{
    width: 7px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb
{
    background-color: rgb(227, 232, 238);
    border-radius: 10px;
}

`
export const Row = styled.div`
/* width: 100%; */
display: flex;
align-items: center;
border-bottom: 1px solid #E3E8EE;
height:69px;
margin: 0px 36px;

`
export const HoverWrapper = styled.div`
display: flex;
align-items: center;
width: 100%;
height:45px;
padding: 0 10px;
justify-content: space-between;
&:hover{
    background: #FFF7E8;
    cursor: pointer;
}
`
export const KeyData = styled.div`
width: 32%;
color: rgba(28, 28, 28, 0.80);
font-family: 'Inter';
font-size: 14px;
font-weight: 500;
`
export const StatusData = styled.div`
text-align: center;
width: 25%;
display: flex;
justify-content: center;
p{
    width: fit-content;
    border-radius: 4px;
    background: ${props => props.status == "success" ? "#E1FAEA" : props.status == "in-progress" ? "#FFEECE" : "#F9EBED"};
    padding: 4px 12px;
    color: ${props => props.status == "success" ? "#318048" : props.status == "in-progress" ? "#FFAA0A" : "#B00020"};
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
}
`
export const UpdatedData = styled.div`
text-align: center;
width: 25%;
color: rgba(28, 28, 28, 0.60);
font-family: 'Inter';
font-size: 14px;
font-weight: 500;
`

export const NoHeader = styled.div`
    padding: 9px 20px 9px 20px;
    width: 5%;
    display: flex;
    align-items: center;
`;

export const NumberData = styled.div`
    padding: 0px 20px 0px 20px;
    width: 5%;
`;


export const NoResult = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: grey;
`;