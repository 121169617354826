import React from 'react'
import { useReducer, createContext, useContext } from 'react'

import produce from 'immer'

const initialState = {
    trackingDomains: [],
    addDomainPopup: false,
    addDomainLoaderPopup: false
}

const TrackingReducer = produce((draft, action) => {
    switch(action.type) {
        case 'SET_TRACKING_DOMAIN_LIST':
            draft.trackingDomains = action.value
            break
        
        case 'SET_ADD_DOMAIN_POPUP':
            draft.addDomainPopup = action.value
            break

        case 'SET_ADD_DOMAIN_LOADER_POPUP':
            draft.addDomainLoaderPopup = action.value
            break

        default:
            break
    }
})

const useTrackingReducer = () => {
    return useReducer(TrackingReducer, initialState)
}

const TrackingContext = createContext()

export const TrackingProvider = ({ children }) => {
    const [state, dispatch] = useTrackingReducer()
    
    const value = {
        trackingState: state,
        trackingDispatch: dispatch
    }
    
    return <TrackingContext.Provider value={value}>
        {children}
    </TrackingContext.Provider>
}

export const useTracking = () => {
    return useContext(TrackingContext)
}
