import styled from "styled-components";

export const AdsWrapper = styled.div`
  width: calc(50% - 15px);
  height: 412px;
  border-radius: var(--8, 8px);
  border: 1px solid #e3e3e3;
  background: #fff;
`;
export const AdsHeader = styled.div`
  padding: 20px 15px 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
`;
export const Logo = styled.div`
  padding: 5px 6px 6px 5px;
  display: flex;
  width: 26px;
  height: 26px;
  justify-content: center;
  align-items: center;
  border-radius: 2.5px;
  background: ${(props) => (props.isTextAd ? "#fdf1cf" : "#F8D9E0")};
`;
export const ViewAll = styled.div`
  color: rgba(28, 28, 28, 0.4);
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;
export const AdTitleWrap = styled.div`
  display: flex;
  align-items: center;
  column-gap: 14px;
  p {
    color: #4f4f4f;
    font-size: 16px;
    font-weight: 500;
  }
`;
export const AdContent = styled.div`
  background: #f5f5f5;
  width: calc(100% - 40px);
  height: 306px;
  margin-top: 16px;
`;

export const AdContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const SiteWrap = styled.div`
  display: flex;
  column-gap: 10px;
  padding: 16px 0 7px 16px;
  align-items: center;
  p {
    border-radius: 11px;
    border: 1px solid #f2f2f2;
    background: #fff;
    width: 251px;
    height: 24px;
    padding: 4px 12px;
    color: #4b4b4b;
    font-size: 12px;
    font-weight: 500;
  }
`;

export const ContentContainer = styled.div`
  margin-left: 24px;
  border-top: 1px solid #f2f2f2;
  background: #fff;
  width: calc(100% - 32px);
  height: 259px;
  overflow-y: ${(props) => (props.textScroll ? "auto" : "")};
`;

export const AdBtn = styled.div`
  border-radius: 106px;
  background: #ebefff;
  display: flex;
  height: 24px;
  padding: 3.876px 8px;
  justify-content: center;
  align-items: center;
  color: #8c8c8c;
  font-size: 13px;
  font-weight: 500;
`;
