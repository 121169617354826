import styled from "styled-components";

export const DetailsWrap = styled.div`
  height: 93%;
  h3 {
    padding: 24px 0 24px 16px;
    border-bottom: 1px solid #e8e8e8;
  }
`;
export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e8e8e8;
  background: ${(prop) => (prop.props ? "rgba(236, 236, 236, 0.50)" : "#fff")};
  /* max-height: ${(prop) => (prop.props ? "calc(100% - 220px)" : "")}; */

  p {
    color: var(--black-100, #1c1c1c);
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }
`;

export const Details = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 17px;
  height: 67px;
  img {
    transform: ${(prop) => (prop.props ? "rotate(180deg)" : "")};
    cursor: pointer;
  }
`;

export const DetailsAdContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 98%;
`;

export const GenerateBtn = styled.div`
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px; /* 125% */
  font-style: normal;
  padding: ${props => props.isDisabled ? "10px 0px": "16px 0px"} ;
  border-radius: 6px;
  background: linear-gradient(180deg, #6907ba 0%, #d057c6 83.33%);
  margin: 0 14px 0 17px;
  cursor: pointer;  
  opacity: ${props => props.isDisabled ? "0.5": "1"};
  pointer-events: ${props => props.isDisabled ? "none": "unset"};
  img{
    height: 28px;
  }
`;
export const ScrollWraper = styled.div`
  height: calc(100% - 75px);
`;
