import { useImageAdGenerator } from "../../../context/imageAdGeneration";
import statics from "../../../statics";
import {  CustomizeContainer, NavContainer, NavItem, HighlightLine } from "./CustomizeBar.styled";
import CustomizeList from "./CustomizeList";
import DetailsList from "./DetailsList";
import ExportList from "./ExportList";

const CustomizeBar = () => {
    const { customizeItems, customizeNavItems } = statics  
    const { imageAdGeneratorState, imageAdGeneratorDispatch } = useImageAdGenerator()

    const setActiveCustomizeNavItem = (item) => {
        imageAdGeneratorDispatch(({
            type: "SET_ACTIVE_CUSTOMIZE_NAV_ITEM",
            value: item
        }))
    }
    
    const renderCustomizeNav = () => {

        return <NavContainer>
            {
                customizeNavItems.map((item)=> {
                    const isActive = item.id === imageAdGeneratorState.activeCustomizeNavItem.id

                    return <NavItem onClick={() => setActiveCustomizeNavItem(item)}>
                        <p>
                            {item.name}
                        </p>
                        <HighlightLine isActive={isActive} />
                    </NavItem>
                })
            }
        </NavContainer>
    }

    const renderPageComponent = () => {
        switch(imageAdGeneratorState.activeCustomizeNavItem.id) {

            case 1:
            return <DetailsList/>

            case 2: 
            return <CustomizeList/>

            case 3:
            return <ExportList/>

            default:
            return <DetailsList />

        }
    }

    return ( 
        <CustomizeContainer>
            {renderCustomizeNav()}
            {renderPageComponent()}
        </CustomizeContainer>
     );
}

export default CustomizeBar;