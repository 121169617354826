import styled from 'styled-components'

export const Container = styled.div`
`;  

export const XaxisContainer = styled.div`
    width: 204px;
    display: flex;
    justify-content: space-between;
    margin-left: 26px;
    span{
        width: 1px;
        background: #0000001a;
        height: 10px;
    }
`;

export const XaxisTimeContainer = styled.div`
    width: 272px;
    display: flex;
    justify-content: space-around;
    margin-left: -9px;
    p{
        font-size: 12px;
        color: #777777;
    }
`;