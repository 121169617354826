import React from 'react'
import { useReducer, createContext, useContext } from 'react'

import produce from 'immer'

import statics from "../_static"

const initialState = {
    activeFunnelType: statics.funnelList[0],
    activeNavItem: statics.navItems[0],
    activePlatform: statics.platforms[0],
    
    searchedDomain: null,

    funnelAds: [],
    funnelCtaData: [],
    funnelKeywordsData: [],
    funnelTargetAudienceData: [],

    isFunnelAdsLoading: true,
    isFunnelStatsLoading: true,
    adsCountOnEachFunnel: null,
    funnelDomainData: [],

    domainInputModalOpen: false,
    domainTableOpen: false,
    domainInput: '',
    domainCountry: null,
    domainTableList:[],
    domainInputList: [],
    domainInputListError: '',


}

const DomainFunnelAnalysisReducer = produce((draft, action) => {
    switch(action.type) {
        case 'SET_DOMAIN_INPUT':
            draft.domainInput = action.value
            break
        
        case 'SET_DOMAIN_COUNTRY':
            draft.domainCountry = action.value
            break

        case 'SET_DOMAIN_INPUT_MODAL_OPEN':
            draft.domainInputModalOpen = action.value
            break
        
        case 'SET_DOMAIN_TABLE_OPEN':
            draft.domainTableOpen = action.value
            break

        case 'SET_DOMAIN_TABLE_LIST':
            draft.domainTableList = action.value
            break

        case 'SET_DOMAIN_INPUT_LIST':
            draft.domainInputList = action.value
            break

        case 'SET_DOMAIN_INPUT_LIST_ERROR':
            draft.domainInputListError = action.value
            break

        case 'SET_ACTIVE_FUNNEL_TYPE':
            draft.activeFunnelType = action.value
            break
            
        case 'SET_ACTIVE_NAV_ITEM':
            draft.activeNavItem = action.value
            break

        case 'SET_ACTIVE_PLATFORM':
            draft.activePlatform = action.value
            break

        case 'SET_SEARCHED_KEYWORD':
            draft.searchedDomain = action.value
            break
        
        case 'SET_FUNNEL_ADS':
            draft.funnelAds = action.value
            break

        case 'SET_FUNNEL_CTA_DATA':
            draft.funnelCtaData = action.value
            break

        case 'SET_FUNNEL_KEYWORDS_DATA':
            draft.funnelKeywordsData = action.value
            break

        case 'SET_FUNNEL_TARGET_AUDIENCE_DATA':
            draft.funnelTargetAudienceData = action.value
            break

        case 'SET_FUNNEL_ADS_LOADING':
            draft.isFunnelAdsLoading = action.value
            break

        case 'SET_FUNNEL_STATS_LOADING':
            draft.isFunnelStatsLoading = action.value
            break

        case 'SET_ADS_COUNT_ON_EACH_FUNNEL': 
            draft.adsCountOnEachFunnel = action.value
            break
        case 'SET_FUNNEL_DOMAIN_DATA': 
            draft.funnelDomainData = action.value
        break
        default:
            break
    }
})

const useDomainFunnelAnalysisReducer = () => {
    return useReducer(DomainFunnelAnalysisReducer, initialState)
}

const DomainFunnelAnalysisContext = createContext()

export const DomainFunnelAnalysisProvider = ({ children }) => {
    const [state, dispatch] = useDomainFunnelAnalysisReducer()
    
    const value = {
        domainFunnelAnalysisState: state,
        domainFunnelAnalysisDispatch: dispatch
    }
    
    return <DomainFunnelAnalysisContext.Provider value={value}>
        {children}
    </DomainFunnelAnalysisContext.Provider>
}

export const useDomainFunnelAnalysis = () => {
    return useContext(DomainFunnelAnalysisContext)
}
