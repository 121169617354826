import styled from "styled-components";

export const PopupBackground = styled.div`
width: 100vw;
height: 100vh;
position: fixed;
top: 0;
left: 0;
background: rgba(28, 28, 28, 0.6);
`;

export const DomainPopupContent = styled.div`
width: 584px;
height: 330px;
flex-shrink: 0;
border-radius: 6px;
background: #FFF;
position: absolute;
top: 204px;
transform: translateX(-50%);
left: 50%;
padding: 16px;
`;

export const Header = styled.div`
display: inline-flex;
height: 56px;
padding: 15px 279px 15px 24.044px;
align-items: center;
flex-shrink: 0;
gap: 18px;
background: #F0F1FC;
width: 552px;
margin: 16px 16px 0 16px;

`;

export const PrimaryText = styled.div`
color: #2C2C2C;
font-family: Nunito;
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 33px */
text-align: center;
margin-top: 32px;
`;

export const SecondaryText = styled.div`
color: #737373;
text-align: center;
font-family: Nunito;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: 150%; /* 21px */
letter-spacing: 0.14px;
text-align: center;
margin-top: 7px;
`;

export const Container = styled.div`
display: flex;
margin-top: 32px;
width: 100%;
justify-content: center;
`;

export const InputContainer = styled.div`
width: 400px;
height: 52px;
position: relative;
`;

export const Input = styled.input`
outline: none;
display: flex;
width: 400px;
height: 52px;
padding-left: 18px;
padding-right: 10px;
justify-content: flex-end;
align-items: center;
flex-shrink: 0;
/* color: #C1C1C1; */
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
border-radius: 4px;
border: 1px solid #DADFE4;
background: #FFF;
border: ${props => props.error ? "1px solid #FF5180" : "1px solid #DADFE4"};
outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};
&:focus{
    border: ${props => props.error ? "1px solid #FF5180" : "1px solid #DADFE4"};
    outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};
}
`;

export const Proceed = styled.div`
display: flex;
height: 52px;
padding: 14px 39px;
justify-content: center;
align-items: center;
background: #FF711E;
color: #FFF;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 24px */
cursor: pointer;
`;