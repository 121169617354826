import styled from "styled-components";

export const LevelThreeDetailContainer = styled.div`
    background: #F7F7F7;
    padding: 24px 40px;
    h2{
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        color: rgba(28, 28, 28, 0.90);
        margin-bottom: 16px;
    }
`;

export const LevelThreeRow = styled.div`
    /* height: 248px; */
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    > span{
        width: 100%;
    }
`;

export const GoogleSearchTermContainer = styled.div`
    border-radius: 16px;
    background: #FFF;
    padding: 24px;
    width: 384px;
    /* width: 35%; */
    height: 312px;
    display: flex;
    flex-direction: column;
`;

export const GoogleSearchTermHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    h3{
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        color: #7D7D7D;
        display: flex;
        gap: 8px;
    }
    p{
        border-radius: 6px;
        background: #FFF7E8;
        width: fit-content;
        padding: 4px 8px;
        display: flex;
        gap: 4px;
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        color: #734A00;
        cursor: pointer;
        margin-left: 8px;
    }
`;

export const SearchTermList = styled.div`
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    overflow: auto;
    max-height: 85%;
    p{
        border-radius: 16px;
        background: #F9F9F9;
        padding: 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        color: #707070;
        height: fit-content;
    }
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const CtaAudienceContainer = styled.div`
    width: 296px;
    height: 312px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const CtaContainer = styled.div`
    border-radius: 16px;
    background: #FFF;
    padding: 16px;
    width: 100%;
    /* width: 35%; */
    height: 142px;
`;

export const CtaHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    h3{
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        color: #7D7D7D;
        display: flex;
        gap: 8px;
    }
`;

export const CtaList = styled.div`
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    p{
        border-radius: 16px;
        background: #F9F9F9;
        padding: 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        color: #707070;
    }
`;  

export const AudienceContainer = styled.div`
    border-radius: 16px;
    background: #FFF;
    padding: 16px;
    width: 100%;
    /* width: 35%; */
    height: 152px;
    display: flex;
    flex-direction: column;
`;

export const AudienceHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    h3{
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        color: #7D7D7D;
        display: flex;
        gap: 8px;
    }
`;

export const AudienceList = styled.div`
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    overflow: auto;
    /* max-height: 58%; */
    p{
        border-radius: 16px;
        background: #F9F9F9;
        padding: 8px 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        color: #707070;
        display: flex;
        align-items: center;
        gap: 10px;
        height: fit-content;
    }
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const OtherAiDetailsContainer = styled.div`
    width: 296px;
    height: 312px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const FunnelContainer = styled.div`
    border-radius: 16px;
    background: #FFF;
    padding: 16px;
    width: 100%;
    /* width: 35%; */
    height: 142px;
`;

export const FunnelHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    h3{
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        color: #7D7D7D;
        display: flex;
        gap: 8px;
    }
`;

export const FunnelList = styled.div`
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    p{
        border-radius: 16px;
        background: #F9F9F9;
        padding: 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        color: #707070;
        height: fit-content;
    }
`;

export const AdHealthContainer = styled.div`
    border-radius: 16px;
    background: #FFF;
    padding: 16px;
    width: 100%;
    /* width: 35%; */
    height: 152px;
    display: flex;
    flex-direction: column;
`;

export const AdHealthHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    h3{
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        color: #7D7D7D;
    }
    p{
        border-radius: 18px;
        background: #01B714;
        padding: 2px 8px;
        display: flex;
        align-items: baseline;
        gap: 4px;
        /* width: 115px; */
        img{
            height: 10px;
        }
    }
    span{
        color: #FFF;
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
    }
`;

export const AdHealthData = styled.div`
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    
    /* overflow: auto; */
    max-height: 71%;
`;