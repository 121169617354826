import React, { useState } from "react";
import {
  BrandWrap,
  CardHeadWrap,
  CardWrapper,
  Description,
  Divider,
  LogoWrap,
  Platform,
  PlatformWrap,
  PreviewText,
  PublishAdContent,
  PublishButton,
  PublishContainer,
  TargetAmount,
  TargetAudience,
  TargetWrap,
  Title,
  ViewOption,
  ViewOptionWrap,
  ViewWrap,
} from "./index.styled";
import downArrow from "../../../../../../../../../../media/Dashboard/down-arrow-grey.svg";
import platformImg from "../../../../../../../../../../media/Dashboard/google.svg";
import tablet from "../../../../../../../../../../media/Dashboard/Tablet-Telephone.svg";
import desktopImg from "../../../../../../../../../../media/Dashboard/desktop.svg";
import { useAdGenerator } from "../../../../../../../context/adGeneration";
import { formatNumber } from "../../../../../../../../../../utils/formatTotal";
import { useTextAdGenInfo } from "../../../../../../../hooks/useTextAdGenInfo";

const PreviewContent = () => {
  const [publishToggle, setPublishToggle] = useState(true);
  const {
    adGeneratorState: { adGroupItem },
    adGeneratorDispatch,
  } = useAdGenerator();

  const totalSearchVolume = () => {
    return adGroupItem?.adGroupRow?.keywordsArray?.reduce((sum, item) => {
      return sum + (Number(item.searchVolume) || 0); // Add 0 if searchVolume is null
    }, 0);
  };

  const avgCostPerClick = () => {
    return adGroupItem?.adGroupRow?.keywordsArray?.reduce((sum, item) => {
      return sum + (Number(item.averageCpc) || 0); // Add 0 if averageCpc is null
    }, 0);
  };

  const totalKeywordsWithVolume = () => {
    return adGroupItem?.adGroupRow?.keywordsArray?.map((item) => {
      if (item.averageCpc) {
        return item.averageCpc;
      }
    });
  };

  const { setGoogleConnected } = useTextAdGenInfo();
  // console.log(adGroupItem.adGroupRow, "adGroupItem");
  const renderViewCard = () => {
    return (
     <CardWrapper>
        <CardHeadWrap>
          <LogoWrap>
            <img src={platformImg} />
          </LogoWrap>
          <BrandWrap>
            <h4>{adGroupItem?.domain && new URL(adGroupItem?.domain).hostname}</h4>
            <p>{adGroupItem?.domain}</p>
          </BrandWrap>
        </CardHeadWrap>
        <Title>{adGroupItem?.adGroupRow.titles[0]}</Title>
        <Description>{adGroupItem?.adGroupRow.descriptions[0]}</Description>
      </CardWrapper>
    );
  };

  const publishFunction = () => {
    setGoogleConnected();
  };

  return (
    <PublishAdContent>
      <h3>Publish ad group 1</h3>
      <PlatformWrap>
        <Platform>
          <img src={platformImg} />
          <p>Google</p>
        </Platform>
        {/* <img
          src={downArrow}
          onClick={() => setPublishToggle(!publishToggle)}
          props={publishToggle}
        /> */}
      </PlatformWrap>
      {publishToggle && (
        <>
          <PublishContainer>
            <ViewWrap>
              <ViewOptionWrap>
                <ViewOption>
                  <img src={tablet} />
                </ViewOption>
                <ViewOption>
                  <img src={desktopImg} />
                </ViewOption>
              </ViewOptionWrap>
              {renderViewCard()}
            </ViewWrap>
            <PreviewText>
              This preview shows potential ads assembled using your assets. Not
              all combinations are shown. Assets can be shown in any order so
              make sure that they make sense individually or in combination and
              don't violate our policies or local law. Some shortening may also
              occur in some formats. You can make sure certain text appears in
              your ad. Learn more
            </PreviewText>
          </PublishContainer>
          <TargetWrap>
            <TargetAudience>Estimated target audience</TargetAudience>
            <TargetAmount>{formatNumber(totalSearchVolume())}</TargetAmount>
            <Divider></Divider>
            <TargetAudience>Avg CPC (Cost Per Click)</TargetAudience>
            <TargetAmount>
              <span>
                {(
                  avgCostPerClick() / totalKeywordsWithVolume()?.length
                ).toFixed(2)}
              </span>{" "}
              / per day
            </TargetAmount>
          </TargetWrap>
          <PublishButton onClick={() => publishFunction()}>
            Publish
          </PublishButton>
        </>
      )}
    </PublishAdContent>
  );
};

export default PreviewContent;
