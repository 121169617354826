import { appClient } from '../../../../utils/axios-utils';
import {useMutation} from "@tanstack/react-query"
import { useDomainTracking } from '../context/domaintracking';

const getTrackingDomains = async (data) => {
    const config = {
        url: "/track/domain/all",
        method: "GET",
    }

    return appClient(config)
}

const setDomainTracking = async (data) => {
    const config = {
        url: "/track/domain/add",
        method: "POST",
        data
    }

    return appClient(config)
}

const updateDomainTracking = async (data) => {
    const config = {
        url: "/track/domain/update-status",
        method: "POST",
        data
    }

    return appClient(config)
}

const getTrackingDetails = async (data) => {
    // console.log(data)
    const config = {
        url: "/track/domain/get-details",
        method: "POST",
        data
    }
  
    return appClient(config)
}

const getAdStats = async (data) => {
    // console.log(data)
    const config = {
        url: "/aggs/url/domain/adstatcountryfiltertrack",
        method: "POST",
        data
    }

    return appClient(config)
}

const getTopDomains = async (data) => {
    // console.log(data)
    const config = {
        url: "/aggs/url/domain/top-domains-track",
        method: "POST",
        data
    }

    return appClient(config)
}


const getHeatmapData = async (data) => {
    const config = {
        url: `/aggs/url/domain/heatmap`,
        method: "POST",
        data
    }

    return appClient(config)
}

const getMapData = async (data) => {
    const config = {
        url: `/aggs/url/domain/mapstat`,
        method: "POST",
        data
    }

    return appClient(config)
}

export const useDomainTrackingInfo = () => {
    const {domainTrackingState, domainTrackingDispatch} = useDomainTracking()

    const { mutate: getTrackingDomainsMutation, isLoading: isGetTrackingDomainsLoading } = useMutation(getTrackingDomains, {
        onSuccess: (data) => {
            if(data.length > 0){
                domainTrackingDispatch({type: 'SET_DOMAIN_TABLE_OPEN', value: true})
                domainTrackingDispatch({type: 'SET_DOMAIN_TABLE_LIST', value: data})
            }else{
                domainTrackingDispatch({type: 'SET_DOMAIN_TABLE_OPEN', value: false})
                domainTrackingDispatch({type: 'SET_DOMAIN_TABLE_LIST', value: []})
            }
            domainTrackingDispatch({type: 'SET_DOMAIN_INPUT_MODAL_OPEN', value: false})
        },
        onError: (err) => {
            
        }
    })
    
    const { mutate: setDomainTrackingMutation, isLoading: isSetDomainTrackingLoading } = useMutation(setDomainTracking, {
        onSuccess: (data) => {
            getTrackingDomainsMutation()
        },
        onError: (err) => {
            
        }
    })

    const { mutate: updateDomainTrackingMutation, isLoading: isUpdateDomainTrackingLoading } = useMutation(updateDomainTracking, {
        onSuccess: (data) => {
            getTrackingDomainsMutation()
        },
        onError: (err) => {
            
        }
    })

    const { mutate: getTrackingDetailsMutation, isLoading: isGetTrackingDetailsLoading } = useMutation(getTrackingDetails, {
        onSuccess: (data) => {
        domainTrackingDispatch({type: 'SET_DOMAIN_TRACKING_DATA', value: data})
        getAdStatsMutation({ query: data.hostname, country: data.country });
        getTopDomainsMutation({ query: data.hostname, country: data.country, platform: data.platform  });
        getHeatmapDataMutation({query: data.hostname, country: data.country, platform: data.platform });
        getMapDataMutation({ query: data.hostname, platform: data.platform  })
        },
        onError: (err) => {
            
        }
    })

    const { mutate: getTopDomainsMutation, isLoading: isGetTopDomainsLoading } = useMutation(getTopDomains, {
    onSuccess: (data) => {
        domainTrackingDispatch({type: 'SET_TOPDOMAINS_LIST', value: data})
    },
    onError: (err) => {
        
    }
    })

    const { mutate: getAdStatsMutation, isLoading: isGetAdStatsLoading } = useMutation(getAdStats, {
        onSuccess: (data) => {
        domainTrackingDispatch({type: 'SET_AD_STATS', value: data})
        },
        onError: (err) => {
            
        }
    })


    const { mutate: getHeatmapDataMutation, isLoading: isGetHeatmapDataLoading } = useMutation(getHeatmapData, {
        onSuccess: (data) => {
        domainTrackingDispatch({type: 'SET_HEATMAP_DATA', value: data})
        },
        onError: (err) => {
            
        }
    })

    const { mutate: getMapDataMutation, isLoading: isGetMapDataLoading } = useMutation(getMapData, {
    onSuccess: (data) => {
        domainTrackingDispatch({type: 'SET_MAP_DISTRIBUTION', value: data})
    },
    onError: (err) => {
        
    }
    })

    return {
        getTrackingDomainsMutation,
        isGetTrackingDomainsLoading,
        setDomainTrackingMutation,
        isSetDomainTrackingLoading,
        updateDomainTrackingMutation,
        isUpdateDomainTrackingLoading,
        getTrackingDetailsMutation,
        isGetTrackingDetailsLoading,
        getTopDomainsMutation,
        isGetTopDomainsLoading,
        getAdStatsMutation,
        isGetAdStatsLoading,
        getHeatmapDataMutation,
        isGetHeatmapDataLoading,
        getMapDataMutation,
        isGetMapDataLoading
    };
};
