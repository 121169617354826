import styled from "styled-components";

export const SeedKeywordsContainer = styled.div`
    width: 738px;
    /* height: 233px; */
    flex-shrink: 0;
    border-radius: 10px;
    background: #FFF;
    padding: 32px 32px 16px 32px;
    position: relative;
`;

export const Container = styled.div`
    padding: 18px;
    width: 674px;
    height: 141px;
    flex-shrink: 0;
    border-radius: 6px;
    border: ${(props) => props.isFocussed ? "1px solid #FFB258" : "1px solid #D0D5DD"};
    margin-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    overflow-y: auto;
    box-shadow: ${(props) => props.isFocussed ? "0px 1px 2px 0px rgba(28, 28, 28, 0.05), 0px 0px 4px 0px rgba(255, 178, 88, 0.25)" : "unset"};
`;

export const EnteredKeywordsContainer = styled.div`

`;

export const PropperKeyword = styled.div`
    display: flex;
    padding: 6px 8px 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    background: #F7F7F7;
    height: 32px;
`;

export const Keyword = styled.div`
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    letter-spacing: 0.15px;
`;

export const DeleteKeyword = styled.img`

`;

export const KeywordsInput = styled.input`
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
    width: 312px;
    outline: none;
    resize: none;
    border: none;
    height: 32px;
    padding-top: 6px;
`;



export const SuggestionContainer = styled.div`
    min-height: 32px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
`;

export const SuggestionLabel = styled.div`
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15px;
`;

export const KeywordsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    background: #F7F7F7;
    gap: 8px;
    padding: 6px 8px 6px 12px;
    cursor: pointer;
`;

export const Keywords = styled.div`
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15px;
`;

export const AddIcon = styled.img`

`;

export const Instruction = styled.div`
    color: rgba(28, 28, 28, 0.40);
    text-align: right;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 10px;
`;