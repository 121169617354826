import React from 'react'
import { Route, Routes} from 'react-router-dom'

import {Helmet} from "react-helmet"
import Layout from "../../layout"

import { useAuth } from '../../../context/auth'
import NotFound from '../NotFoundPage';
import AdManagementComponentWrapper from './AdCampaign/index';

const AdManagementPage = () => {
    return <Routes>
            <Route path='*' element={
                    <NotFound />
            } />
            <Route path="ad-campaigns/*" element={
                <Layout>
                    <AdManagementComponentWrapper />
                </Layout>
            }/>
        </Routes>
    
}

const AdManagementWrapper = () => {
    const {authState, authDispatch} = useAuth()

    return <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ad Generator | AdSpyder</title>
                <meta name="title" content="Ad Generator | AdSpyder" />
                <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
            </Helmet>
            <AdManagementPage/>
    </>
}

export default AdManagementWrapper