import { AdAccount, AdAccountBtn, AdAccountBtnContainer, AdAccountContainer, AdAccountWrap, AdConnectedWrap } from "./AdConnected.styled";
import AllAccounts from "./components/AllAccounts";
import AccountList from './components/AccountList'
import { useAdManagement } from "../../context/AdManagementContext";
function AdConnected() {
    const { adManagementState, adManagementDispatch } = useAdManagement()

    return ( 
       <AdConnectedWrap>
            <AdAccountWrap>
                <AdAccountContainer>
                    <AdAccount>Ad account:</AdAccount>
                    <AllAccounts/>
                </AdAccountContainer>
                <AdAccountBtnContainer>
                    <AdAccountBtn onClick={() => 
                    {adManagementDispatch({ type: "SET_SELECT_A_PLATFORM", value: true })
                    adManagementDispatch({ type: "SET_INPUT_PAGE", value: false })}
                }>Add an account</AdAccountBtn>
                    {/* <CreateCampaignBtn>Create campaign</CreateCampaignBtn> */}
                </AdAccountBtnContainer>
            </AdAccountWrap>
            <AccountList />
       </AdConnectedWrap>
    );
}

export default AdConnected;