import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { KeywordTrackingProvider } from './context/keywordtracking';
import {Helmet} from "react-helmet"

// Layout
import Layout from "../../layout"

// Pages
import KeywordAnalysisDetails from './KeywordTrackingDetails/index';
import KeywordTracking from './KeywordTrackingHome';
// import { getSearchErrCode } from "../../../helper/getSearchErrCode"

// Not found page
import NotFound from '../NotFoundPage';

// import {appClient} from '../../../../utils/axios-utils'

const KeywordTrackingComponent = () => {
    return <Routes>
            <Route path='*' element={
                <NotFound />
            } />
            <Route path="/" element={
                <Layout>
                    <KeywordTracking/>
                </Layout>
            } />
        </Routes>
};

const KeywordTrackingProviderWrapper = () => {
    return <KeywordTrackingProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PPC Keyword Tracking | AdSpyder</title>
                <meta name="title" content="PPC Keyword Tracking | AdSpyder" />
                <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
            </Helmet>
        <KeywordTrackingComponent/>
    </KeywordTrackingProvider>
}

export default KeywordTrackingProviderWrapper