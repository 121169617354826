import React from "react";
import { DashboardWrapper, LoaderWrap } from "./index.styled";
import DashboardHeader from "./components/dashboardHeader";
import Projects from "./components/projects";
import { useDashboardInfo } from "../../hooks/useDashboardInfo";
import { useEffect } from "react";
// import { useDashboard } from "../../context/dashboard";
import getCountryByDialingCode from "../../utils/getCountryByDialingCode";
import { useAuth } from "../../../../../context/auth";
import { useDashboard } from "../../../../../context/dashboard";

function ProjectList() {
  const {
    dashboardState: { projectList },
    dashboardDispatch,
  } = useDashboard();
  const { authState: {userLocation} } = useAuth()

  const useDashboardInfoHook = useDashboardInfo();
  useEffect(() => {
    useDashboardInfoHook.getProjectDetails();
    const country = getCountryByDialingCode(userLocation.countryCode);
    console.log(country, "country");
    if(country){
      dashboardDispatch({
        type: "SET_SELECTED_COUNTRY",
        value: country.adspyder_name,
      });
    }

    
  }, []);

  useEffect(() => {
    projectList &&
      projectList.length == 1 &&
      useDashboardInfoHook.getIndividualProject(projectList[0].id);
  }, [projectList]);

  return (
    <DashboardWrapper>
      <DashboardHeader />
      <Projects />
    </DashboardWrapper>
  );
}

export default ProjectList;
