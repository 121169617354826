import React, {useEffect} from 'react'

import {Navigate, Outlet, useLocation} from "react-router-dom"

import {useAuth} from '../../context/auth'

const Protected = () => {
    const {authState, authDispatch} = useAuth()
    const location = useLocation()

    // console.log("protected layout",authState)
    // let platformRoute = location.pathname.includes("/adspy/") ? location.pathname.replace("/adspy/","") : ""
    
    // let isRestricted  = platformRoute && 
    // !authState?.features?.allowedPlatforms?.includes(platformRoute) &&
    //  location.pathname.includes("adspy")

    // console.log(platformRoute, isRestricted, location.pathname, authState)/

    // if(isRestricted){
    //     authDispatch({type: 'UPDATE_IS_RESTRICTED', value: true})
    // }

    // console.log("protected layout", authState)

    if(!authState.isAuthenticated){
        return <Navigate to={`/`} state={{ from: location }} replace/>
    }else if(authState.old && !authState.notified){
        return <Navigate to={`/welcome`} state={{ from: location }} replace/>
    }else if(!authState.emailVerified){
        return <Navigate to={`/confirmemail`} state={{ from: location }} replace/>
    }else if(!authState.postloginSurvey){
        return <Navigate to={`/loginsurvey`} state={{ from: location }} replace/>
    }
    // else if(authState.plan?.active 
    //     && isRestricted
    // ){
    //     return <Navigate to={`/overview`} state={{ from: location }} replace/>
    // }
    else if(!authState.plan?.active){
        return <Navigate to={`/account/plans-billing`} state={{ from: location }} replace/>
    }
    // else if(!authState.features.allowedPlatforms){
    //     return <Navigate to={`/account/plans-billing`} state={{ from: location }} replace/>
    // }
    else{
        return <Outlet/>
    }

}

export default Protected

