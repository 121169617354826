import React, { useRef, useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import {
    useMutation
} from "@tanstack/react-query"
import NoResultsLogo from "../../../../../../../media/Dashboard/noresults.svg"
// Styles
import {
    DateRange,
    AdDetailsBody,
    CloseDetails,
    DetailsHeader,
    DetailsContainer,
    FooterRight,
    AdCountries,
    FooterLeft,
    CardFooter,
    CardBody,
    Favorite,
    FloatingButtonsContainer,
    Card,
    CardsList,
    ResultsContainer,
    ResultsWraper,
    VideoContainer,
    ImageContainer,
    PageName,
    ChannelDetails,
    DetailsChannelDetails,
    LogoContainer,
    ChannelName,
    Description,
    InnerDescription,
    SecondaryDetails,
    DetailsVideoContainer,
    DetailsImageContainer,
    PrimaryText,
    Headline,
    DestinationUrl,
    ShortUrl,
    InnerContent,
    InnerDesc,
    InnerTitle,
    CallToActionButton,
    CTA,
    MediaType,
    NetworkTypes,
    NoResults,
    ViewMoreAds,
    InnerUrl
} from "./index.styled"
import SearchLoader from '../../../../../../HOC/SearchLoader'

import GlobeIcon from '../../../../../../../media/Dashboard/globe.svg';
import BlueRightArrow from '../../../../../../../media/Dashboard/blue-right-arrow.svg'

//Icons
import FavorietIcon from "../../../../../../../media/Dashboard/addFavorite.svg"
import CloseBtn from "../../../../../../../media/Dashboard/detailsPaneClose.svg"

import AltImage from '../../../../../../../media/Dashboard/altimage.png'
import {appClient} from '../../../../../../../utils/axios-utils'

import CountryIcon from "../../../../../../../media/Images/CountryIcon.svg"
import { useAuth } from '../../../../../../../context/auth'
import { useLandingPageAnalysis } from '../../../../context/landingPageAnalysis'
import { getTodayDate } from '../../../../../../../utils/dateFormat'
import SaveAdsPopup from '../../../../../../components/SaveAdsPopup'

const adDetails = async (id) => {
    const config = {
        url: `/detail/facebook/${id}`,
        method: "GET"
    }

    return appClient(config)
}

const requestDataCrawl = async (data) => {
    const config = {
        url: `/track/search/update-search-query`,
        method: "POST",
        data
    }
  
    return appClient(config)
}

const convertTimestamp = (timestamp) => {
    if(!timestamp){
        return "-"
    }
    // Create a new Date object based on the timestamp
    var date = new Date(Number(timestamp));
  
    // Get the month name
    var monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    var month = monthNames[date.getMonth()];
  
    // Get the year
    var year = date.getFullYear();
  
    // Assemble the formatted date string
    var formattedDate = date.getDate() + ' ' + month  + ' ' + year;
  
    return formattedDate;
}

const CardElement = ({ad,idx, basicAdDetails,setBasicAdDetails, containerRef}) => {
    const {landingPageAnalysisState, landingPageAnalysisDispatch} = useLandingPageAnalysis()
    const { authState, authDispatch } = useAuth()

    const scrollToElement = (index) => {
        const element = containerRef.current.children[index];
        const scrollPosition = element.offsetTop - 200;
        containerRef.current.scrollTo({ top: scrollPosition, behavior: 'smooth' });
      };

    const {mutate: adsDetailsMutate, isLoading: isDetailsLoading} = useMutation(adDetails, {
        onSuccess: (data) => {
            landingPageAnalysisDispatch({type:'SET_AD_DETAILS', value: data})
            if(landingPageAnalysisState.adDetails.id == data.id || !landingPageAnalysisState.adDetails.id){
                landingPageAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: !landingPageAnalysisState.detailsModal})
            }
        },
        onError:  ((e)=>{
        })
    })

    const toggleDetailsModal = (ad,idx) => {
        if((landingPageAnalysisState.adDetails.id !== ad.id) && landingPageAnalysisState.detailsModal){
            // console.log(landingPageAnalysisState.detailsModal, landingPageAnalysisState.adDetails.id, ad.id)
            //if details is open and clicked on close button on ads card
            landingPageAnalysisDispatch({type:'SET_AD_DETAILS', value: ad})
            landingPageAnalysisDispatch({type:'UPDATE_COLLAPSE_CALENDER', value: false})
        }else{
            landingPageAnalysisDispatch({type:'SET_AD_DETAILS', value: ad})
            landingPageAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: !landingPageAnalysisState.detailsModal})
            landingPageAnalysisDispatch({type:'UPDATE_COLLAPSE_CALENDER', value: true})
        }
        setTimeout(() => {
            scrollToElement(idx)
        }, 100);
    }
    
    // console.log(landingPageAnalysisState.adDetails, ad)
    return <Card detailsOpen={landingPageAnalysisState.adDetails.id == ad.id} key={
        ad.id
    }>
        <CardBody>
            <ChannelDetails>
                <LogoContainer>
                    <img src={ad.logo ? `https://aws-adspyder-logos.s3.ap-south-1.amazonaws.com/${ad.id}`: GlobeIcon}/>
                </LogoContainer>
                <ChannelName>
                    {ad.title}
                    <span>Sponsored</span>
                </ChannelName>
            </ChannelDetails>
            <Description>
                {ad.content}
            </Description>
            <FloatingButtonsContainer>
                <Favorite isFav={idx == 1} onClick={()=>{
                    authDispatch({type:'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'facebook'})
                    authDispatch({type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id})
                    authDispatch({type:'UPDATE_SAVE_AD_POPUP', value: true})}}> 
                    <img src={FavorietIcon}/>
                </Favorite>
                {/* |
                <DropdownIcon>
                    <span/>
                </DropdownIcon> */}
            </FloatingButtonsContainer>
            {ad.image && <ImageContainer>
                <img src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${ad.id}`}/>
            </ImageContainer>}
            {ad.video && <VideoContainer>
                <video src={`https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${ad.id}`} preload="auto" controls loop style={{width: "100%", height: "100%"}}/>
            </VideoContainer>}
            {(!ad.video && !ad.image) && <ImageContainer>
                <img src={AltImage}/>
                </ImageContainer>}
            <SecondaryDetails>
                <InnerContent>
                <InnerUrl>
                    {ad.ctaUrl}
                </InnerUrl>
                <InnerDesc>
                    {ad.ctaTitle}
                </InnerDesc>
                <InnerTitle>
                    {ad.ctaContent}
                </InnerTitle>
                </InnerContent>
                <CallToActionButton>
                    <CTA>
                        {ad.ctaButton}
                    </CTA>
                </CallToActionButton>
            </SecondaryDetails>
        </CardBody>
    
        <CardFooter>
            <FooterLeft>
                {/* <AdDate>
                    {ad.firstSeen && ad.lastSeen && <img src={DateIcon}/>}{convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
                </AdDate> */}
            <AdCountries>
                {ad.country.length > 0 && <img src={CountryIcon} />}{ ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
            </AdCountries>
            </FooterLeft>
            <FooterRight>
                {/* <DetailsButton onClick={()=>toggleDetailsModal(ad,idx)}>
                {(landingPageAnalysisState.adDetails.id == ad.id) && landingPageAnalysisState.detailsModal ? "Close details" : "More details"}
                </DetailsButton> */}
            </FooterRight>
        </CardFooter>
    </Card>
}

const DetailsElement = () => {
    const {landingPageAnalysisState, landingPageAnalysisDispatch} = useLandingPageAnalysis()

    const [height, setHeight] = useState('')
    const [width, setWidth] = useState('')
    useEffect(() => {
        if(landingPageAnalysisState.adDetails.image){
            const img = new Image();
            img.onload = function() {
                // setTimeout(() => {
                    setHeight(img.height)
                    setWidth(img.width)
                // }, 1000);
            }
            img.src = `https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${landingPageAnalysisState.adDetails.id}`;
        }
        if(landingPageAnalysisState.adDetails.video){
            var video = document.createElement('video');
            video.src = `https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${landingPageAnalysisState.adDetails.id}`;

            video.addEventListener('loadedmetadata', function() {
                // console.log(video)
                setHeight(video.videoHeight)
                setWidth(video.videoWidth)
            });
        }
      }, [])
    return <DetailsContainer>
    <DetailsHeader>
        <h3>Ad details</h3> <CloseDetails onClick={()=>{landingPageAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: false})
        landingPageAnalysisDispatch({type:'SET_AD_DETAILS', value: {}})
        landingPageAnalysisDispatch({type:'UPDATE_COLLAPSE_CALENDER', value: false})
    }}><img src={CloseBtn}/></CloseDetails>
    </DetailsHeader>
    <AdDetailsBody>
        <DetailsChannelDetails>
            <LogoContainer>
                <img src={`https://aws-adspyder-logos.s3.ap-south-1.amazonaws.com/${landingPageAnalysisState.adDetails.id}`}/>
            </LogoContainer>
            <ChannelName>
                {landingPageAnalysisState.adDetails.title}
                <span>Promoted</span>
            </ChannelName>
            </DetailsChannelDetails>
        {landingPageAnalysisState.adDetails.image && <DetailsImageContainer>
            <img onLoad={(e)=>{
                    setHeight(e.target.naturalHeight)
                    setWidth(e.target.naturalWidth)
                }} src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${landingPageAnalysisState.adDetails.id}`}/>
                <span>{height} X {width}</span>
        </DetailsImageContainer>}
        {landingPageAnalysisState.adDetails.video && <DetailsVideoContainer>
            <video src={`https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${landingPageAnalysisState.adDetails.id}`} preload="auto" controls loop style={{width: "100%", height: "100%"}}/>
        </DetailsVideoContainer>}
        <PageName>
            <label>Page name:</label>{landingPageAnalysisState.adDetails.title}
        </PageName>
        <PrimaryText>
            <label>Primary text:</label>{landingPageAnalysisState.adDetails.content}
        </PrimaryText>
        <Headline>
            <label>Headline:</label>{landingPageAnalysisState.adDetails.innerdescription ? landingPageAnalysisState.adDetails.innerdescription : "-"}
        </Headline>
        <InnerDescription>
            <label>Description:</label>{landingPageAnalysisState.adDetails.innertitle ? landingPageAnalysisState.adDetails.innertitle : "-"}
        </InnerDescription>
        <DestinationUrl>
            <label>Destination URL:</label><a href={landingPageAnalysisState.adDetails.url}>{landingPageAnalysisState.adDetails.url}</a>
        </DestinationUrl>
        <ShortUrl>
            <label>Display Link:</label>{landingPageAnalysisState.adDetails.innertitle ? <a href={landingPageAnalysisState.adDetails.innertitle}>{landingPageAnalysisState.adDetails.innertitle}</a> : "-"}
        </ShortUrl>
        <MediaType>
            <label>Media Type:</label>{landingPageAnalysisState.adDetails.image ? "Image" : landingPageAnalysisState.adDetails.video ? "Video" : "-"}
        </MediaType>
        <NetworkTypes>
            {/* <label>Netword Types:</label> <TagsList>{landingPageAnalysisState.adDetails.platform.map((platformType,idx)=>{
                return <span>{platformType}</span>
            })}</TagsList> */}
        </NetworkTypes>
        <DateRange>
        <label>Date:</label>{convertTimestamp(landingPageAnalysisState.adDetails.firstSeen)} - {convertTimestamp(landingPageAnalysisState.adDetails.lastSeen)}
        </DateRange>
        {/* <label>Country:</label><CountriesList>{landingPageAnalysisState?.adDetails?.country.map((country,idx)=>{
                                    return <span>{country}</span>
                                })}</CountriesList> */}
        {/* {landingPageAnalysisState.adDetails.country && <CountriesContainer>
            <label>Country:</label><CountriesList><span>{landingPageAnalysisState.adDetails.country}</span></CountriesList>
        </CountriesContainer>} */}
    </AdDetailsBody>
</DetailsContainer>
}

const FacebookResults = ({isDataLoading}) => {
    const {landingPageAnalysisState, landingPageAnalysisDispatch} = useLandingPageAnalysis()
    const [ basicAdDetails, setBasicAdDetails] = useState({})
    const [ dataRequested, setDataRequested ] = useState(false)
    const navigate = useNavigate()

    const {authState, authDispatch} = useAuth()
    // const {searchState, searchDispatch} = useSearch()

    const containerRef = useRef(null);
    
    const {mutate: requestData, isLoading: isRequestDataLoading} = useMutation(requestDataCrawl, {
        onSuccess: (data) => {
            setDataRequested(true)
        },
        onError:  ((e)=>{
  
        })
    })
  
    const onDataRequest = () => {
        if(!dataRequested){
            requestData({id: landingPageAnalysisState.queryId})
        }
    }

    // if(authState.isRestrictedPopup){
    //     return  <ResultsContainer detailsModal={landingPageAnalysisState.detailsModal}>
    //         <PlatformRestrictedPopup err={searchState.searchError}/>
    //     </ResultsContainer>
    // }

    // if(searchState.limitExceeded){
    //     return  <ResultsContainer detailsModal={landingPageAnalysisState.detailsModal}>
    //         <LimitExceededPopup err={searchState.searchError}/>
    //     </ResultsContainer>
    // }

    return  <ResultsContainer detailsModal={landingPageAnalysisState.detailsModal}>
                 {isDataLoading ? <div className='loader'>
        <SearchLoader/>
      </div> : landingPageAnalysisState.ads.length == 0 ? <NoResults>
                        <img src={NoResultsLogo}/>
                        <h2>No Results Found</h2>
                        <p>No ads found on this specific date. try some other dates</p>
                         {/* <RequestDataBtn dataRequested={dataRequested ? dataRequested : ""} disable={isRequestDataLoading} onClick={onDataRequest}>{dataRequested ? <p>Your request for data crawl has been accepted<img src={TickImg}/></p> : <p>{isRequestDataLoading ? "Requesting...": "Request data crawl for this query"}</p>}</RequestDataBtn> */}
                    </NoResults>  : <ResultsWraper>
                    {authState.saveAdsPopupOpen && <SaveAdsPopup/>}
                    <CardsList ref={containerRef} detailsModal={landingPageAnalysisState.detailsModal}> {
                        landingPageAnalysisState.ads && landingPageAnalysisState.ads.length > 0 && landingPageAnalysisState.ads.slice(0, 2).map((ad, idx) => {
                            return <CardElement containerRef={containerRef} basicAdDetails={basicAdDetails} setBasicAdDetails={setBasicAdDetails} ad={ad} idx={idx}/>
                        })
                    } 
                    <ViewMoreAds onClick={()=>window.open(`https://app.adspyder.io/adspy/meta?searchMode=url&keywordSearchType=phrase&phraseInput=&page=1&excludedTerm=&searchIn=title%2Ccontent&sortType=relevant&urlSearchType=domain&domainUrlInput=${landingPageAnalysisState.searchUrl}&startDate=${landingPageAnalysisState.selectedYear+"-"+landingPageAnalysisState.selectedMonth+"-"+landingPageAnalysisState.selectedDay}&endDate=${getTodayDate()}`)}>View More Ads <img src={BlueRightArrow}/></ViewMoreAds>
                    </CardsList>
                    {
                    landingPageAnalysisState.detailsModal && <DetailsElement />
                    }
                </ResultsWraper>
                }
            </ResultsContainer>
}

export default FacebookResults
