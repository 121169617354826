import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    background: #0000005e;width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    background: #0000005e;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
`;

export const SelectContainer = styled.div`
    width: 548px;
    padding: 40px 48px;
    background: #FFFFFF;
    position: relative;
`;

export const CloseButton = styled.div`
    position: absolute;
    cursor: pointer;
    right: 25px;
    color: grey;
    top: 20px;
    font-family: monospace;
    font-size: 16px;
    height: 28px;
    width: 28px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Heading = styled.div`
    font-size: 18px;
    font-weight: 500;
    color: #2E2E2E;
    margin-bottom: 32px;
    span{
        color: #0051AE
    }
`;

export const PlatformsContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const PlatformAvailable = styled.div`
    cursor: pointer;
    background: ${props => props.isSelected ? "#F0F7FF" : "#FFFFF"};
    border: ${props => props.isSelected ? "1px solid #B4DAFF" : "1px solid #F2F2F2"};
    border-radius: 8px;
    color: ${props => props.isSelected ? "#0051AE" : "#505050"};
    font-size: 16px;
    font-weight: 400;
    width: 45%;
    height: 103px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 52px;
`;

export const CancelButton = styled.div`
    width: 87px;
    height: 40px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const ContinueButton = styled.button`
    width: auto;
    height: 40px;
    border-radius: 8px;
    background-color: #FF711E;
    color: white;
    font-size: 14px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    :hover {
        filter: brightness(90%);
    }

    &:disabled{
        background: #ECECEC;
        color: #FFFFFF;
    }
`;