import React, { useEffect } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom"

import DatePicker from 'react-datepicker';

import { Container, SearchContainer,  ScreenshotImageContainer, DateContainer, LeftArrow, RightArrow, DateValue, HeaderContainer, ContentContainer, Description, AdsCount,  TopBarWrap, AddressWrap } from "./index.styled";

import { useLandingPageAnalysis } from './../context/landingPageAnalysis'
import {useAuth} from "../../../../context/auth"
import {Helmet} from "react-helmet"
import Image from 'react-graceful-image'
import {ISTDateformat, getTodayDate, getNumberDateFromInput} from "../../../../utils/dateFormat"
import { getSearchParams, getDomainName } from "../../../../utils/helper.js"
import CalenderIcon from "../.././../../media/Dashboard/calender.svg"
import AltImage from '../../../../media/Dashboard/altimage.png'
import AdCopiesList from './components/AdCopiesList/index.js'
import IconLock from '../../../../media/Dashboard/IconLock.svg'
import IconUpdate from '../../../../media/Dashboard/IconUpdate.svg'
import LandingLoader from './components/landingLoader/index.js'
import { useLandingPageAnalysisInfo } from "../hooks/useLandingPageAnalysisInfo.js";

const KeywordAnalysisDetails = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const useLandingPageAnalysisInfoHook = useLandingPageAnalysisInfo()
    const navigate = useNavigate()
    const location = useLocation()
    const { authState, authDispatch } = useAuth() 

    const {landingPageAnalysisState, landingPageAnalysisDispatch} = useLandingPageAnalysis()

    useEffect(() => {
        {Object.entries(landingPageAnalysisState?.adsAvailability || {}).map(([platform, value], index) => {
            if (value) {
                landingPageAnalysisDispatch({ type: 'SET_FILTER_STATE', value: true })
            }})}  
      }, [landingPageAnalysisState.adsAvailability]);

    useEffect(() => {
        // console.log("inside landing index")
        landingPageAnalysisDispatch({type: 'SET_CURRENT_PAGE', value: 'calender'})

        const searchParamFromState = getSearchParams(location.search)
        
        const {
            url,
            date,
            sessionId
        } = searchParamFromState
        const formattedDate = new Date(date);
        landingPageAnalysisDispatch({ type: 'SET_SELECTED_DATE', value: formattedDate});              

        if(sessionId){
            // if(!authState.features?.isKeywordAnalysisAllowed){
            //     authDispatch({type: 'UPDATE_IS_RESTRICTED', value: true})
            // }else{
                console.log("make session call")
                useLandingPageAnalysisInfoHook.getSessionDetailsMutate(sessionId)
            // }
        }if(url){
            // if(!authState.features?.isKeywordAnalysisAllowed){
            //     authDispatch({type: 'UPDATE_IS_RESTRICTED', value: true})
            // }else
             if(date){
                // console.log("in 1", date.split("-")[0], date.split("-")[1])
                landingPageAnalysisDispatch({type: 'SET_SEARCH_URL', value: url})
                landingPageAnalysisDispatch({type: 'SET_DOMAIN_NAME', value: getDomainName(landingPageAnalysisState.searchUrl)})
                landingPageAnalysisDispatch({type: 'SET_SELECTED_YEAR', value: date.split("-")[0]})
                landingPageAnalysisDispatch({type: 'SET_SELECTED_MONTH', value: date.split("-")[1]})
                landingPageAnalysisDispatch({type: 'SET_SELECTED_DAY', value: date.split("-")[2]})
                // getDomainKeywords({query: url})
                landingPageAnalysisDispatch({type: 'SET_IS_SCREENSHOT_LOADING', value: true})
                useLandingPageAnalysisInfoHook.getDateScreenshotMutate({date: ISTDateformat(date), query: url})
                landingPageAnalysisDispatch({ type: 'SET_CURRENT_DETAILS_TYPE', value: 'landingpage'})
                useLandingPageAnalysisInfoHook.getAdsAvailabilityMutate({query: url, startDate: date})
            }else{
                // console.log("in 2")
                let newDate = getTodayDate()
                landingPageAnalysisDispatch({type: 'SET_SELECTED_YEAR', value: newDate.split("-")[0]})
                landingPageAnalysisDispatch({type: 'SET_SELECTED_MONTH', value: newDate.split("-")[1]})
                landingPageAnalysisDispatch({type: 'SET_SELECTED_DAY', value: newDate.split("-")[2]})
                searchParams.set('url', landingPageAnalysisState.searchUrl)
                searchParams.set('date', newDate)
                searchParams.delete('sessionId')
                navigate(`/landing-page-analysis/details?${searchParams.toString()}`)
            }
        }
    }, [location.search])

    const selectedEvent = landingPageAnalysisState.eventDateList.find(event => event.date === getNumberDateFromInput(landingPageAnalysisState.selectedDate));

    return <Container>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{landingPageAnalysisState.searchUrl}: Landing Page Analysis | AdSpyder</title>
            <meta name="title" content={`${landingPageAnalysisState.searchUrl} - Landing Page Analysis | AdSpyder`} />
            <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
        </Helmet>
            {!landingPageAnalysisState.dataCaptured ? 
            <LandingLoader/>
            : 
            <>
                <HeaderContainer>
                    <SearchContainer>
                        {selectedEvent ? <h2>{selectedEvent.name} Event Snapshot</h2> : null}
                        <DateContainer>
                            <LeftArrow>
                                {"<"}
                            </LeftArrow>
                            <DateValue>
                                <img src={CalenderIcon}/>
                                {/* <p>{landingPageAnalysisState.selectedDate}</p> */}
                                <DatePicker style={{ width: "100px" }} selected={landingPageAnalysisState.selectedDate} onChange={(date) => {
                                    landingPageAnalysisDispatch({ type: 'SET_SELECTED_DATE', value: date});
                                    searchParams.set('date',getNumberDateFromInput(date))
                                    navigate(`/landing-page-analysis/details?${searchParams.toString()}`)
                                }}/>
                            </DateValue>
                            <RightArrow>
                                {">"}
                            </RightArrow>
                        </DateContainer>
                    </SearchContainer>
                </HeaderContainer>
                <ContentContainer>
                    <Description>
                        Previewing snapshot of "<span>{landingPageAnalysisState.searchUrl}</span>"
                    </Description>
                    {!landingPageAnalysisState.toggleCopies ? 
                    <AdsCount>
                        {landingPageAnalysisState.filterState ? 
                        <p>Adcopies Found On This Day</p>
                        :
                        <p>No Ads Found</p>}
                            {landingPageAnalysisState.filterState && <button onClick={()=>landingPageAnalysisDispatch({ type: 'SET_TOGGLE_COPIES', value: true })}>
                            See all
                        </button>}
                        
                    </AdsCount>
                    :<AdCopiesList />}
                    <ScreenshotImageContainer>
                        <TopBarWrap>
                            <AddressWrap>
                                <img src={IconLock} />
                                <p>{landingPageAnalysisState.searchUrl}</p>
                            </AddressWrap>
                            <img src={IconUpdate} />
                            
                        </TopBarWrap>
                        {landingPageAnalysisState.screenShotUrl ? <Image src={
                            landingPageAnalysisState.screenShotUrl
                        }
                        alt="landing page screensot"
                        retry={
                            {
                                count: 15,
                                delay: 1,
                                accumulate: 'add'
                            }
                        }/> : <img className="altimg"
                        src={AltImage}/>}
                    </ScreenshotImageContainer>
                </ContentContainer>
            </>}
    </Container>
}

export default KeywordAnalysisDetails


