import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    height: 100vh;
`;

export const Left = styled.div`
    /* @media screen and (max-width: ${props => props.theme.breakPoints.extraLargeScreen}) {
        
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.largeScreen}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.desktop}) {
        width: 100%;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileSmall}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileExtraSmall}) {

    } */

    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ContentWrapper = styled.div`
    width: calc(100% - 50px);
    height: calc(100% - 50px);
    flex-direction: column;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: ${props => props.theme.breakPoints.extraLargeScreen}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.largeScreen}) {
       
    }
    
    @media screen and (max-width: ${props => props.theme.breakPoints.desktop}) {
     
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileSmall}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileExtraSmall}) {
        width: calc(100% - 25px);
        height: calc(100% - px);
    }
`;

export const LogoContainer = styled.div`
    /* position: absolute; */
    width: 100%;
    /* margin-bottom: 25px; */
    top: 0;
    left: 0;

    img{
        width: 147px;
    }
`;

export const ChildrenContainer = styled.div`
    height: 100%;
    width: 100%;
    margin-top: 60px;
    display: flex;
    padding-left: 100px;
    /* justify-content: center;
    align-items: center; */

`;
export const ChildrenHolder = styled.div`
    width: 615px;
    /* height: 480px; */
    
    /* @media screen and (max-width: ${props => props.theme.breakPoints.extraLargeScreen}) {

    }
    
    @media screen and (max-width: ${props => props.theme.breakPoints.largeScreen}) {
        width: 344px;
    }
    
    @media screen and (max-width: ${props => props.theme.breakPoints.desktop}) {
     
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {
        width: 320px;
        height: 100%;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileSmall}) {
        width: 280px;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileExtraSmall}) {

    } */
`;

export const Right = styled.div`
    /* width: 60%; */
    background: #F9F9F9;

    @media screen and (max-width: ${props => props.theme.breakPoints.extraLargeScreen}) {
        
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.largeScreen}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.desktop}) {
        display: none;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileSmall}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileExtraSmall}) {

    }
`;