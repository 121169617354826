import React,{useEffect, useState} from 'react';
import { Doughnut } from 'react-chartjs-2';
import {Chart, ArcElement } from 'chart.js';
import { useKeywordOverview } from '../../../../context/keywordOverview';

const DoughnutChart = () => {
  const {keywordOverviewState, keywordOverviewDispatch} = useKeywordOverview();
  const [doughData, setDoughData] = useState([]);
  const doughDataArr = []
  // setDoughData
  useEffect(() => {
  keywordOverviewState?.adDistribution?.length > 0 &&
    keywordOverviewState?.adDistribution.map((item)=>{
      doughDataArr.push(item.percentage)
// console.log(doughData,'doughData',item.percentage)
  })
  setDoughData(doughDataArr)
  }, [])
  
  
// console.log(doughData,'dough Data')
  Chart.register(ArcElement);
  const data = {
    labels: [
              
    ],
    datasets: [{
      label: 'Ad Distribution',
      data: doughData,
      backgroundColor: [
        "#1679db",
        "#e24593",
        "#2802fb",
        "#00879d",
        "#a158e0"
      ],
      hoverOffset: 4,
      cutout: '70%'
    }]
  };


  return <Doughnut data={data}  />;
};

export default DoughnutChart;
