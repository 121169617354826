import React, { useState, useEffect } from 'react'
import {
  useMutation
} from "@tanstack/react-query"
import NoResultsLogo from "../../../../../../../media/Dashboard/noresults.svg"
import { useRequestData } from "../../../../../../hooks/useRequestData"
import locationImg from '../../../../../../../media/Dashboard/locationCard.svg'

// Styles
import {
  ResultsContainer,
  CardContainer,
  ResultsWraper,
  AdContainer,
  ImageContainer,
  AdBodyContainer,
  ProductName,
  ProductPrice,
  WebsiteUrl,
  AdBodyBottomContainer,
  AdDate,
  AdCountries,
  NoResults,
  RequestDataBtn,
  Country,
  HoverCard,
  HoverCardWrapper,
  AdDetailsButton,
  SaveAdsButton
} from "./index.styled"
import _static from "../../../../../../../config/static"
import SearchLoader from '../../../../../../HOC/SearchLoader'
import TickImg from '../../../../../../../media/Dashboard/tick-grey.svg'

import { useBingEcomSearch } from "../../context/bingecom"

import RightUpperBlackArrow from "../../../../../../../media/Dashboard/RightUpperBlackArrow.svg"

import AltImage from '../../../../../../../media/Dashboard/altimage.png'
import { appClient } from '../../../../../../../utils/axios-utils'
import LimitExceededPopup from '../../../../../../components/limitExceededPopup'
import PlatformRestrictedPopup from '../../../../../../components/platformRestrictionPopup'
import { useAuth } from '../../../../../../../context/auth'
import { useSearch } from '../../../context/search'
import SaveAdsPopup from '../../../../../../components/SaveAdsPopup'
import AdDetails from '../../../../../../components/AdDetailsModal'
import { useSaveAds } from '../../../../../../hooks/useSaveAds'

const adDetails = async (id) => {
  const config = {
    url: `/detail/bing-ecom/${id}`,
    method: "GET"
  }

  return appClient(config)
}

const Card = ({ ad, idx, basicAdDetails, setBasicAdDetails, containerRef }) => {
  const { bingEcomSearchState, bingEcomSearchDispatch } = useBingEcomSearch()
  const [detailsOpen, setDetailsOpen] = useState(false)
  const [seeMoreCountries, setSeeMoreCountries] = useState(false)
  const [seeMoreKeywords, setSeeMoreKeyword] = useState(false)
  const { authState, authDispatch } = useAuth()
  const useSaveAdsHook = useSaveAds()

  const image = ad.logo ? <img src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${ad.id}`} onError={() => { }} /> : <img src={AltImage} onError={() => { }} />;

  const convertTimestamp = (timestamp) => {
    // Create a new Date object based on the timestamp
    var date = new Date(timestamp);

    // Get the month name
    var monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    var month = monthNames[date.getMonth()];

    // Get the year
    var year = date.getFullYear();

    // Assemble the formatted date string
    var formattedDate = date.getDate() + ' ' + month + ' ' + year;

    return formattedDate;
  }

  const { mutate: adsDetailsMutate, isLoading: isDetailsLoading } = useMutation(adDetails, {
    onSuccess: (data) => {
      bingEcomSearchDispatch({ type: 'SET_AD_DETAILS', value: data })
      if (bingEcomSearchState.adDetails.id == data.id || !bingEcomSearchState.adDetails.id) {
        bingEcomSearchDispatch({ type: 'UPDATE_DETAILS_MODAL', value: !bingEcomSearchState.detailsModal })
      }
    },
    onError: ((e) => {
      console.log(e)
    })
  })

  const toggleDetailsModal = (ad) => {
    // if(bingEcomSearchState.adDetails.id){
    //   if((bingEcomSearchState.adDetails.id == ad.id) && bingEcomSearchState.detailsModal){
    //if details is open and clicked on close button on ads card
    setBasicAdDetails(ad)
    bingEcomSearchDispatch({ type: 'SET_AD_DETAILS', value: ad })
    bingEcomSearchDispatch({ type: 'UPDATE_DETAILS_MODAL', value: !bingEcomSearchState.detailsModal })
    //   }else{
    //       setBasicAdDetails(ad)
    //       adsDetailsMutate(ad.id)
    //   }
    // }else{
    //     setBasicAdDetails(ad)
    //     adsDetailsMutate(ad.id)
    // }
  }

  const onSave = (ad) => {
    useSaveAdsHook.SaveAdMutate({
      projectId: authState.defaultProjectInfo.projectId,
      adId: `${ad.id}`,
      platform: "bing-ecom"
    })
    // authDispatch({ type: 'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'bing-ecom' })
    // authDispatch({ type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id })
    // authDispatch({ type: 'UPDATE_SAVE_AD_POPUP', value: true })
  }

  const onRemoveSavedAd = (ad) => {
    useSaveAdsHook.RemoveSavedAdMutate({
      projectId: authState.defaultProjectInfo.projectId,
      adId:  `${ad.id}`,
      platform: "bing-ecom"
    })
  }


  useEffect(() => {
    if (bingEcomSearchState.adDetails.locations && Array.from(new Set(bingEcomSearchState.adDetails.locations.map(item => item.country))).length > 5) {
      setSeeMoreCountries(true)
    }

    if (bingEcomSearchState.adDetails.keywords && bingEcomSearchState.adDetails.keywords.length > 5) {
      setSeeMoreKeyword(true)
    }
  }, [])
  return <CardContainer noResults={bingEcomSearchState.noResults} detailsOpen={bingEcomSearchState.adDetails.id == ad.id}>
    <HoverCard className='hover-element' onClick={() => toggleDetailsModal(ad, idx)}>
      <HoverCardWrapper>
        <AdDetailsButton onClick={() => toggleDetailsModal(ad, idx)}>
          <img src={RightUpperBlackArrow} />
          <span>Ad Details</span>
        </AdDetailsButton>
        <SaveAdsButton isSaved={authState.savedAdsIds.includes(ad.id)} onClick={(e) => {
          e.stopPropagation();
          if (authState.savedAdsIds.includes(ad.id)) {
            onRemoveSavedAd(ad)
          } else {
            onSave(ad);
          }

        }}>
          {authState.savedAdsIds.includes(ad.id) ? "Saved" : "Save"}
        </SaveAdsButton>
      </HoverCardWrapper>
    </HoverCard>
    {bingEcomSearchState.adDetails.id != ad.id && <AdContainer>
      <ImageContainer>
        {image}
      </ImageContainer>
      {/* <FloatingButtonsContainer>
          <Favorite isFav={idx == 1} onClick={()=>{
                authDispatch({type:'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'bing-ecom'})
                authDispatch({type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id})
                authDispatch({type:'UPDATE_SAVE_AD_POPUP', value: true})}}>
              <img src={authState.favAdsList["bing-ecom"]?.includes(ad.id) ? FavouriteIconActive : FavorietIcon}/>
          </Favorite> */}
      {/* |
          <DropdownIcon>
              <span/>
          </DropdownIcon> */}
      {/* </FloatingButtonsContainer> */}
      <AdBodyContainer>
        <ProductName>
          {ad.title}
        </ProductName>
        <WebsiteUrl>
          {ad.url}
        </WebsiteUrl>
        <AdDate>
          {ad.firstSeen && ad.lastSeen ? (
            <>
              {convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
            </>
          ) : (
            '--'
          )}
        </AdDate>
        {/* <ProductPrice>
            {ad.price}
          </ProductPrice> */}

        <AdBodyBottomContainer>
          <AdCountries>
            <img src={locationImg} />
            <Country>{ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : "--"}</Country>
          </AdCountries>
          <ProductPrice>
            {ad.price}
          </ProductPrice>
          {/* <AdDate>
            {ad.lastSeen && <img src={DateIcon}/>}{convertTimestamp(ad.lastSeen)}
            </AdDate> */}
          {/* <AdCountries>
              <img src={CountryIcon}/>{ ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}  
            </AdCountries> */}
        </AdBodyBottomContainer>
        {/* <DetailsButton onClick={()=>toggleDetailsModal(ad)}>
            {(bingEcomSearchState.adDetails.id == ad.id) && bingEcomSearchState.detailsModal ? "Close details" : "More details"}
          </DetailsButton> */}
      </AdBodyContainer>
    </AdContainer>}
    
    {/* {bingEcomSearchState.adDetails.id == ad.id && <DetailsContainer >
        <Left>
          <FloatingButtonsContainer> */}
    {/* <Favorite isFav={idx == 1}>
                <img src={FavorietIcon}/>
            </Favorite>
            |
            <DropdownIcon>
                <span/>
            </DropdownIcon> */}
    {/* </FloatingButtonsContainer>
          <DetailsImageContainer>
            {image}
          </DetailsImageContainer>
          <DetailsButton onClick={()=>closeDetailsModal(ad.id)}>
              Close details
            </DetailsButton>
        </Left>
        
        <Right>
          <DetailsHeading>
            Ad Detail
            <CloseButton onClick={()=>closeDetailsModal(ad.id)}>
              ×
            </CloseButton>
          </DetailsHeading>
          <DetailsWraper>
            <DetailsProductName>
              {ad.title}
            </DetailsProductName>
            <ProductPrice>
              {ad.price}
            </ProductPrice>
            <WebsiteUrl>  
              {ad.sitename}
            </WebsiteUrl>
            <AdDetailsDate>
              <span></span>{convertTimestamp(ad.lastSeen)}
            </AdDetailsDate>
            <AdDetailsCountries>
              <span></span>{Array.from(new Set(bingEcomSearchState.adDetails.locations.map(item => item.country))).map((country,idx)=>{
                if(seeMoreCountries){
                  if(idx < 6){
                    return <p>{country}</p>
                  }
                }else{
                  return <p>{country}</p>
                }
              })}
              <SeeMoreMoreCountries onClick={()=>setSeeMoreCountries(prev => !prev)}>
                {seeMoreCountries ? `... ${Array.from(new Set(bingEcomSearchState.adDetails.locations.map(item => item.country))).length - 5}+ see more` : Array.from(new Set(bingEcomSearchState.adDetails.locations.map(item => item.country))).length > 5 ? "see less" : ""}
              </SeeMoreMoreCountries>
            </AdDetailsCountries>
            
            <AveragePosition>
              <label>Average position:</label>{ad.position}
            </AveragePosition>
            <AdKeywords>
              <label>Search terms:</label>{bingEcomSearchState.adDetails.keywords.map((term,idx)=>{
                if(seeMoreKeywords){
                  if(idx < 6){
                    return <p>{term}</p>
                  }
                }else{
                  return <p>{term}</p>
                }
              })}
              <SeeMoreMoreKeywords onClick={()=>setSeeMoreKeyword(prev => !prev)}>
                {seeMoreKeywords ? `... ${bingEcomSearchState.adDetails.keywords.length - 5}+ see more` : bingEcomSearchState.adDetails.keywords.length > 5 ? "see less" : ""}
              </SeeMoreMoreKeywords>
            </AdKeywords>
            
          </DetailsWraper>
        </Right>
      </DetailsContainer>} */}
  </CardContainer>
}

const Results = () => {
  const { bingEcomSearchState, bingEcomSearchDispatch } = useBingEcomSearch()
  const [basicAdDetails, setBasicAdDetails] = useState({})
  const useRequestDataHook = useRequestData()
  const useSaveAdsHook = useSaveAds()

  const { searchState, searchDispatch } = useSearch()
  const { authState, authDispatch } = useAuth()


  const closeDetailsModal = () => {
    setBasicAdDetails({})
    bingEcomSearchDispatch({ type: 'SET_AD_DETAILS', value: {} })
    bingEcomSearchDispatch({ type: 'UPDATE_DETAILS_MODAL', value: false })
  }

  const onSave = (ad) => {
    useSaveAdsHook.SaveAdMutate({
      projectId: authState.defaultProjectInfo.projectId,
      adId: `${ad.id}`,
      platform: "bing-ecom"
    })
    // authDispatch({ type: 'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'bing-ecom' })
    // authDispatch({ type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id })
    // authDispatch({ type: 'UPDATE_SAVE_AD_POPUP', value: true })
  }

  const onDataRequest = () => {
    if (!useRequestDataHook.isDataRequested) {
      useRequestDataHook.requestDataMutate({ id: bingEcomSearchState.queryId })
    }
  }

  if (authState.isRestrictedPopup) {
    return <ResultsContainer restricted={true} detailsModal={bingEcomSearchState.detailsModal}>
      <PlatformRestrictedPopup err={searchState.searchError} />
    </ResultsContainer>
  }

  if (searchState.limitExceeded) {
    return <ResultsContainer restricted={true} detailsModal={bingEcomSearchState.detailsModal}>
      <LimitExceededPopup err={searchState.searchError} />
    </ResultsContainer>
  }

  return <ResultsContainer resultLoading={bingEcomSearchState.loadingResults} noResults={bingEcomSearchState.noResults}>
    {bingEcomSearchState.loadingResults ? <div className='loader'>
      <SearchLoader />
    </div> : bingEcomSearchState.noResults ? <NoResults>
      <img src={NoResultsLogo} />
      <h2>No Results Found</h2>
      <p>We're sorry, but we don't currently have the data matching your search parameters. Our database is constantly updated, so please try again later or try modifying your search.</p>
      <RequestDataBtn
        // dataRequested={authState.isDataRequested}
        isDisabled={useRequestDataHook.isRequestDataLoading || authState.isDataRequested}
        onClick={onDataRequest}
      >
        {authState.isDataRequested ? <p>Your request for data crawl has been accepted<img src={TickImg} /></p> : <p>{useRequestDataHook.isRequestDataLoading ? "Requesting..." : "Request data crawl for this query"}</p>}
      </RequestDataBtn>
    </NoResults> : <>
      {authState.saveAdsPopupOpen && <SaveAdsPopup />}
      <ResultsWraper>
        {(bingEcomSearchState.ads.length > 0) && bingEcomSearchState.ads.map((ad, idx) => {
          return <Card basicAdDetails={basicAdDetails} setBasicAdDetails={setBasicAdDetails} ad={ad} idx={idx} />
        })}
      </ResultsWraper>
    </>}

    {bingEcomSearchState.detailsModal &&
      <AdDetails
        // navigateTo={navigateTo}
        platform="bingEcom"
        basicDetail={basicAdDetails}
        onDetailsClose={closeDetailsModal}
        highlightWords={bingEcomSearchState.highlightWords}
        onSave={onSave}
      />
    }
  </ResultsContainer>
}

export default Results