import React from 'react'
import Layout from '../../Components/LoginLayout/layout'
import { usePasswordChange } from './../context/passwordChange'

import GetOTP from './GetOtp'
import NewPassword from './NewPassword'
import EnterOtp from './EnterOtp'
import SuccessPage from './SuccessPage'

const ChangePasswordPage = () => {

    const { passwordChangeState, passwordChangeDispatch } = usePasswordChange();

    // console.log(passwordChangeState)
    return (
        <Layout>

            {/* Initial UI */}
            {
            !passwordChangeState.otpSent && !passwordChangeState.otpVerified && <GetOTP />
            }

            {/* Verify OTP */}
            {
            passwordChangeState.otpSent && !passwordChangeState.otpVerified && <EnterOtp />
            }

            {/* After OTP Sent */}
            {
            passwordChangeState.otpSent && passwordChangeState.otpVerified && !passwordChangeState.passwordChanged && <NewPassword />
            }            

            {
            passwordChangeState.otpSent && passwordChangeState.otpVerified && passwordChangeState.passwordChanged && <SuccessPage />
            }
            
        </Layout>
)}

export default ChangePasswordPage
