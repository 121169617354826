import styled from "styled-components";

export const ContentWrap = styled.div`
  padding: 14px 0px 10px 8px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;
export const AdUrl = styled.div`
  color: #7bae86;
  font-size: 11px;
  font-weight: 400;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  span {
    border-radius: 2px;
    background: #7bae86;
    width: 17px;
    height: 12px;
    color: #fff;
    font-size: 11px;
    font-weight: 400;
    padding: 0px 1px;
    margin-right: 5px;
  }
`;
export const TextTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 32px;
  h2 {
    color: #553fb8;
    font-size: 13px;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    /* width: 18px; */
    /* height: 18px; */
    fill: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const ContentDiv = styled.div`
  padding: 11px 18px 20px 8px;
  p {
    color: rgba(28, 28, 28, 0.5);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px; /* 150% */
    margin-top: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const OopsDiv = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: rgb(95 95 95);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px; /* 150% */
    margin-top: 8px;
  }
`;
export const LoadingWrap = styled.div`
  padding: 11px 18px 10px 8px;
  display: flex;
  flex-direction: column;
`;
