import React, { useState, useEffect } from "react";
import {
  AdHeadWrap,
  AdHeader,
  AdHeaderContainer,
  AdResultWrap,
  CalendarContainer,
  CalendarDropDown,
  CalenderWrap,
  ManageButton,
  ManageWrap,
  PlatformAccount,
  PlatformAccountWrap,
  ResultWrapper,
  StatusContainer,
  StatusDiv,
} from "./index.styled";
import calendarImg from "../../../../../../media/Dashboard/calendarAd.svg";
import rightArrowImg from "../../../../../../media/Dashboard/arrow-right.svg";
import downArrowImg from "../../../../../../media/Dashboard/angle-down.svg";
import checkImg from "../../../../../../media/Dashboard/check.svg";
import markImg from "../../../../../../media/Dashboard/mark.svg";
import adsIcon from "../../../../../../media/Dashboard/ads-1.svg";
import adResultData from "../../static";
import AccountDataDetails from "./components/AccountDataDetails";
import CampaignComponent from "./components/CampaignComponent";
import DetailsComponent from "./components/DetailsComponent";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import moment from "moment";
import { extendMoment } from "moment-range";
import { useAdIntegration } from "../../context/AdManagementContext";
import ManagePopup from "./components/ManagePopup";
import SelectAccount from "../InputPages/components/selectAccount";
import { useAdIntegrationInfo } from "../../hooks/useAdManagementInfo";

// const moment = extendMoment(originalMoment);

const ResultPages = () => {
  const { platformArray } = adResultData;
  const today = moment();
  const [isOpen, setIsOpen] = useState(false);
  // const [value, setValue] = useState(
  //   moment.range(today.clone().subtract(3, "months"), today.clone())
  // );
  const {
    newAdManagementState: {
      manageAccount,
      selectAdAccount,
      connectByProjectId,
      dateStartEnd,
      isStatusDetail,
    },
    newAdManagementDispatch,
  } = useAdIntegration();
  const { setUpdateCampaignMetric } = useAdIntegrationInfo();

  const onSelect = (val, states) => {
    // console.log(val);
    newAdManagementDispatch({ type: "SET_DATE_START_END", value: val });
    setUpdateCampaignMetric({
      customerId: connectByProjectId.details[0].customerId,
      startDate: dateStartEnd.start.format("YYYY-MM-D"),
      endDate: dateStartEnd.end.format("YYYY-MM-D"),
    });
    setIsOpen(false)

  };

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  // useEffect(() => {
   
  // }, [dateStartEnd]);

  return (
    <ResultWrapper>
      <AdResultWrap>
        <AdHeadWrap>
          <AdHeaderContainer>
            <AdHeader>
              Ad Account Overview - <span>flonnect</span>
            </AdHeader>
            <p>
              Detailed Insights into Campaign Performance, Audience Behavior,
              and Budget Utilization
            </p>
          </AdHeaderContainer>
          <StatusContainer>
            <StatusDiv
              isSelected={isStatusDetail == "all"}
              onClick={() =>
                newAdManagementDispatch({
                  type: "SET_STATUS_DETAIL",
                  value: "all",
                })
              }
            >
              all
            </StatusDiv>
            <StatusDiv
              isSelected={isStatusDetail == "active"}
              onClick={() =>
                newAdManagementDispatch({
                  type: "SET_STATUS_DETAIL",
                  value: "active",
                })
              }
            >
              active
            </StatusDiv>
            <StatusDiv
              isSelected={isStatusDetail == "paused"}
              onClick={() =>
                newAdManagementDispatch({
                  type: "SET_STATUS_DETAIL",
                  value: "paused",
                })
              }
            >
              paused
            </StatusDiv>
            <CalenderWrap>
              <CalendarContainer CalendarContainer onClick={onToggle}>
                <img src={calendarImg} />
                {dateStartEnd.start.format("MMM D, YYYY")}
                <img src={rightArrowImg} />
                {dateStartEnd.end.format("MMM D, YYYY")}
                <img src={downArrowImg} />
              </CalendarContainer>
              {isOpen && (
                <CalendarDropDown>
                  <DateRangePicker
                    value={dateStartEnd}
                    onSelect={onSelect}
                    singleDateRange={true}
                  />
                </CalendarDropDown>
              )}
            </CalenderWrap>
          </StatusContainer>
        </AdHeadWrap>
        <ManageWrap>
          <PlatformAccountWrap>
            {platformArray.map((item) => (
              <PlatformAccount isConnect={item.connected}>
                <img src={item.platform} height={"20px"} />
                <p>{item.title}</p>
                <img
                  src={item.connected ? checkImg : markImg}
                  height={"24px"}
                />
              </PlatformAccount>
            ))}
          </PlatformAccountWrap>
          <ManageButton
            onClick={() =>
              newAdManagementDispatch({
                type: "SET_MANAGE_ACCOUNT",
                value: true,
              })
            }
          >
            <img src={adsIcon} />
            Manage
          </ManageButton>
        </ManageWrap>
        <AccountDataDetails />
        <CampaignComponent value={65} maxValue={100} />
        <DetailsComponent />
        {manageAccount && <ManagePopup />}
        {selectAdAccount && <SelectAccount />}
      </AdResultWrap>
    </ResultWrapper>
  );
};

export default ResultPages;
