import { appClient } from "../../../../../../utils/axios-utils";
import { useMutation } from "@tanstack/react-query";
import { useGetUserInfo } from "../../../../../hooks/useGetUserInfo";
import { useSearch } from "../../context/search";
import { useBingSearch } from "../context/bing";

const keywordBoardFilterMatch = async (data) => {
    const config = {
        url: "/search/bing/keyword/broad/filter",
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordPhraseFilterMatch = async (data) => {
    const config = {
        url: "/search/bing/keyword/phrase/filter",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlDomainFilterMatch = async (data) => {
    const config = {
        url: "/search/bing/url/domain/filter",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlTextFilterMatch = async (data) => {
    const config = {
        url: "/search/bing/url/text/filter",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlExactFilterMatch = async (data) => {
    const config = {
        url: "/search/bing/url/exact/filter",
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordBroadMatch = async (data) => {
    const config = {
        url: "/search/bing/keyword/broad",
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordPhraseMatch = async (data) => {
    const config = {
        url: "/search/bing/keyword/phrase",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlDomainMatch = async (data) => {
    const config = {
        url: "/search/bing/url/domain",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlExactMatch = async (data) => {
    const config = {
        url: "/search/bing/url/exact",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlTextMatch = async (data) => {
    const config = {
        url: "/search/bing/url/text",
        method: "POST",
        data
    }

    return appClient(config)
}

export const useBingInfo = () => {
    const { bingSearchState,bingSearchDispatch} = useBingSearch()
    const {searchState, searchDispatch} = useSearch()

    const userInfoHook = useGetUserInfo()

    const { mutate: keywordBoardFilterMutate, isLoading: isKeywordBoardFilterLoading } = useMutation(keywordBoardFilterMatch, {
        onSuccess: (data) => {
            bingSearchDispatch({ type: "SET_ADS", value: data.documents })
            bingSearchDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            bingSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                bingSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                bingSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            
            if(data.totalNumberOfDocuments == 0){
                bingSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                bingSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                bingSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                bingSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            
            bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const { mutate: keywordPhraseFilterMutate, isLoading: isKeywordPhraseFilterLoading } = useMutation(keywordPhraseFilterMatch, {
        onSuccess: (data) => {
            // console.log(data)
            bingSearchDispatch({ type: "SET_ADS", value: data.documents })
            bingSearchDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            bingSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                bingSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                bingSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                bingSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                bingSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                bingSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                bingSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const { mutate: urlDomainFilterMutate, isLoading: isUrlDomainFilterLoading } = useMutation(urlDomainFilterMatch, {
        onSuccess: (data) => {
            bingSearchDispatch({ type: "SET_ADS", value: data.documents })
            bingSearchDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            bingSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                bingSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                bingSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                bingSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                bingSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                bingSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                bingSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const { mutate: urlTextFilterMutate, isLoading: isUrlTextFilterLoading } = useMutation(urlTextFilterMatch, {
        onSuccess: (data) => {
            bingSearchDispatch({ type: "SET_ADS", value: data.documents })
            bingSearchDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            bingSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                bingSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                bingSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                bingSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                bingSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                bingSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                bingSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const { mutate: urlExactFilterMatchMutate, isLoading: isUrlExactFilterLoading } = useMutation(urlExactFilterMatch, {
        onSuccess: (data) => {
            bingSearchDispatch({ type: "SET_ADS", value: data.documents })
            bingSearchDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            bingSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                bingSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                bingSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                bingSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                bingSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                bingSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                bingSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const {mutate: keywordBroadMutate, isLoading: isKeywordBroadLoading} = useMutation(keywordBroadMatch, {
        onSuccess: (data) => {
            bingSearchDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})

            // Update search results
            bingSearchDispatch({type: "SET_ADS", value: data.documents})
            bingSearchDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            bingSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                bingSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                bingSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                bingSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                bingSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                bingSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                bingSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError:  ((e)=>{
            bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        })
    })

    const {mutate: keywordPhraseMutate, isLoading: isKeywordPhraseLoading} = useMutation(keywordPhraseMatch, {
        onSuccess: (data) => {
            // console.log(data)
            bingSearchDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})

            // Update search results
            bingSearchDispatch({type: "SET_ADS", value: data.documents})
            bingSearchDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            bingSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                bingSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                bingSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                bingSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                bingSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                bingSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                bingSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError:  ((e)=>{
            bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        })
    })

    const {mutate: urlDomainMutate, isLoading: isUrlDomainLoading} = useMutation(urlDomainMatch, {
        onSuccess: (data) => {
            // bingSearchDispatch({type: "CLEAR_FILTERS"}) // Clear filter before main search data updated
    
            // Update search results
            bingSearchDispatch({type: "SET_ADS", value: data.documents})
            bingSearchDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            bingSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})
    
            if(data.documents && data.documents.length > 0){
                bingSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                bingSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                bingSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                bingSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                bingSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                bingSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })
    
    const {mutate: urlExactMutate, isLoading: isUrlExactLoading} = useMutation(urlExactMatch, {
        onSuccess: (data) => {
            // bingSearchDispatch({type: "CLEAR_FILTERS"}) // Clear filter before main search data updated
            bingSearchDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})
    
            // Update search results
            bingSearchDispatch({type: "SET_ADS", value: data.documents})
            bingSearchDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            bingSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})
    
            if(data.documents && data.documents.length > 0){
                bingSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                bingSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                bingSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                bingSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                bingSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                bingSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })
    
    const {mutate: urlTextMutate, isLoading: IsUrlTextLoading} = useMutation(urlTextMatch, {
        onSuccess: (data) => {
            // bingSearchDispatch({type: "CLEAR_FILTERS"}) // Clear filter before main search data updated
            bingSearchDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})
    
            // Update search results
            bingSearchDispatch({type: "SET_ADS", value: data.documents})
            bingSearchDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            bingSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})
    
            if(data.documents && data.documents.length > 0){
                bingSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                bingSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                bingSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                bingSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                bingSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                bingSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

  
    return {
        keywordBoardFilterMutate,
        isKeywordBoardFilterLoading,
        keywordPhraseFilterMutate,
        isKeywordPhraseFilterLoading,
        keywordBroadMutate,
        isKeywordBroadLoading,
        keywordPhraseMutate,
        isKeywordPhraseLoading,
        urlDomainFilterMutate,
        isUrlDomainFilterLoading,
        urlTextFilterMutate,
        isUrlTextFilterLoading,
        urlExactFilterMatchMutate,
        isUrlExactFilterLoading,
        urlDomainMutate,
        isUrlDomainLoading,
        urlExactMutate,
        isUrlExactLoading,
        urlTextMutate,
        IsUrlTextLoading
    };
};
