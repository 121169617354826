import backArrow from '../../assets/backArrow.svg'
import { useAdManagement } from '../../context/AdManagementContext';
import { SelectAdDescription, SelectAdTitle, SelectAdTitleWrap, SelectAdWrapper, SelectContentWrap } from './SelectAd.styled';

function SelectAd() {
    const { adManagementState, adManagementDispatch } = useAdManagement()

    return ( 
        <SelectAdWrapper>
           <SelectAdTitleWrap>
            <img src={backArrow} onClick={ () => {
                adManagementDispatch({ type: "SET_SELECT_A_PLATFORM", value: true })
                adManagementDispatch({type: 'SET_SELECT_AD_ACCOUNT', value: false})
            }}/>
            <SelectAdTitle>Select ad account</SelectAdTitle>
           </SelectAdTitleWrap>
           <SelectContentWrap>
                <SelectAdDescription>Your selected Google Ad account(s) will be added to Adspyder's your  account. You will receive an email from Google confirming this. By connecting your Google Ads account to Adspyder you agree that Google may share information about your use of Google Ads with Adspyder including the amount you spend on Google Ads. Google may use this information to further its business relationship with Adspyder including to calculate or pay commissions or bonuses owed to Adspyder. <a>Learn more</a>
                </SelectAdDescription>
                {/* <SelectAdDescription>Newly connected accounts may be eligible for $500 USD in ad credits. <a>Find out more about this offer and see terms &
conditions here</a>
                </SelectAdDescription> */}
           </SelectContentWrap>
       </SelectAdWrapper>
    );
}

export default SelectAd;