import styled from "styled-components";

export const InputWrapper = styled.div`
display: flex;
flex-direction: column;
row-gap: 8px;
align-items: center;
`

export const NoConnectedDiv = styled.div`
margin-top: 64px;
color: #000;
text-align: center;
font-family: Inter;
font-size: 20px;
font-weight: 600;
`
export const NoConnectedDescription = styled.div`
color: rgba(28, 28, 28, 0.50);
text-align: center;
font-family: Inter;
font-size: 14px;
font-weight: 400;
max-width: 559px;
line-height: 22px;
`
export const NoConnectedBtn = styled.div`
width: 170px;
padding: 13px 28px;
border-radius: 6px;
background: #FF711E;
gap: 10px;
color: #FFF;
font-family: Inter;
font-size: 14px;
font-weight: 400;
margin-top: 16px;
cursor: pointer;
`
export const AdsInfoWrapper = styled.div`
display: flex;
width: 965px;
height: 59px;
padding: 18px 25px 19px 25px;
justify-content: center;
align-items: center;
column-gap: 6px;
border-radius: 6px;
border: 1px solid #FFA500;
background: #FFF;
color: var(--black-100, #1C1C1C);
font-family: Inter;
font-size: 16px;
font-weight: 500;
margin-top: 20px;
a{
color: var(--black-100, #FF711E);
font-family: Inter;
font-size: 16px;
font-weight: 400;
text-decoration-line: underline;
}
`
