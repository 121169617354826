import { useImageAdGenerator } from "../../../../context/imageAdGeneration";
import BrandDescriptionPP from "./BrandDescription";
import ChoosingCategoryPP from './ChoosingCategory'
import { Content, PopupContainer } from "./index.styled";
import AdTypePP from "./AdType";
import TargetAudiencePP from "./TargetAudience";

function Popup() {
    const { imageAdGeneratorState, imageAdGeneratorDispatch } = useImageAdGenerator()


    const renderContent = () => {
        switch(imageAdGeneratorState.activeCustomizePopup.id){
            case 1: 
            return <ChoosingCategoryPP />

            case 2: 
            return <AdTypePP />

            case 3:
            return <TargetAudiencePP />

            case 4:
            return <BrandDescriptionPP />

            // case 5:
            // return <AdTypePP />

            default:
            return
        }
    }

    if(!imageAdGeneratorState.activeCustomizePopup) return

    return ( 
        <PopupContainer>
            <Content>
               {renderContent()}
            </Content>
        </PopupContainer>
     );
}

export default Popup;