// import React, { useEffect, useState } from "react";
// import { Chart } from "react-google-charts";
// import { useAdGenerator } from "../../../../../context/adGeneration";

// const initialData = [
//   ["Country", "Popularity"],
//   ["India", 900]
// ];

// function MapChart() {

//   const { adGeneratorState: {targetAudience: {locations}} } = useAdGenerator()
//   const [data, setData] = useState(initialData)
//   useEffect(() => {
//     setData(locations.map((l) => [...data, [l, 900]]))
//   }, [locations])

//   if(data){
//       return (
//       <Chart
//         chartEvents={[
//           {
//             eventName: "select",
//             callback: ({ chartWrapper }) => {
//               const chart = chartWrapper.getChart();
//               const selection = chart.getSelection();
//               if (selection.length === 0) return;
//               const region = data[selection[0].row + 1];
//               console.log("Selected : " + region);
//             },
//           },
//         ]}
//         chartType="GeoChart"
//         width="100%"
//         height="275px"
//         data={data}
//         options={{
//           backgroundColor: {
//               fill: "transparent"
//           },
//           legend: "none",
//           colorAxis: {colors:['#FFA26B']},
//           tooltip:{trigger: "none"} 
//         }}
//       />
//     );
//   }
  
// }

// export default MapChart


import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useAdGenerator } from "../../../../../context/adGeneration";

const initialData = [
  ["Country", "Popularity"]
];

function MapChart() {
  const {
    adGeneratorState: { targetAudience: { locations } },
  } = useAdGenerator();
  const [data, setData] = useState(initialData);

  useEffect(() => {
    const updatedData = locations.map((l) => [l, 900]);
    setData(() => [...initialData, ...updatedData]);
  }, [locations]);

  return (
    <Chart
      chartEvents={[
        {
          eventName: "select",
          callback: ({ chartWrapper }) => {
            const chart = chartWrapper.getChart();
            const selection = chart.getSelection();
            if (selection.length === 0) return;
            const region = data[selection[0].row + 1];
            console.log("Selected : " + region);
          },
        },
      ]}
      chartType="GeoChart"
      width="100%"
      height="275px"
      data={data}
      options={{
        backgroundColor: {
          fill: "transparent",
        },
        legend: "none",
        colorAxis: { colors: ["#FFA26B"] },
        tooltip: { trigger: "none" },
      }}
    />
  );
}

export default MapChart;
