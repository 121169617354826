import styled from "styled-components";

export const ResultPageContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

export const ResultContainer = styled.div`
    width: calc(100% - 280px - 109px); //280px 
    height: 100%;
    padding-left: 43px;
    padding-top: 44px;
`;

export const Heading = styled.div`
    color: rgba(28, 28, 28, 0.50);
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px; /* 237.5% */
    margin-bottom: 26px;
`;

export const AdTemplatesContainer = styled.div`
    display: flex;
    gap: 16px;
    margin-bottom: 46px;
    overflow-x: scroll;
    overflow-y: hidde
    n;
    padding-bottom: 3px;
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: #F6F7FB;
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        height: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const AdTemplate = styled.div`
    width: 170px;
    flex-shrink: 0;
    border-radius: 15px;
    border: 1px solid #DADFE4;
    background: ${(props) => props.isActive ? "#1679DB" : "#fff"};  
    padding-left: 20px;
    cursor: pointer;
    height: 114px;
`;

export const Title = styled.div`
    margin: 18px 0 16px 0;
    color: ${(props) => !props.isActive ? "#1c1c1c" : "#fff"};
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
`;

export const LocationLabel = styled.div`
    color: ${(props) => !props.isActive ? "rgba(28, 28, 28, 0.50)" : "#fff"};
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 2px;
    line-height: 150%;
`;

export const Country = styled.div`
    color: ${(props) => !props.isActive ? "#1c1c1c" : "#fff"};;
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
`;

export const SubNavbar = styled.div`
    display: flex;
    gap: 35px;
    margin-bottom: 24px;
`;

export const NavItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    cursor: pointer;
`;

export const Item = styled.div`
    color: #1C1C1C;
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    gap: 6px;
    line-height: 150%; 
`;

export const Count = styled.div`
    color: rgba(28, 28, 28, 0.50);
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
`;

export const HighlightLine = styled.div`
    width: 100%;
    height: 4px;
    background: #FF711E;
    visibility: ${(props) => props.isActive ? "auto" : "hidden"};
`;

