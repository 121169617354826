import styled from "styled-components";

export const ProjectContainer = styled.div`
  width: 100%;
  background: #fff;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0px;
    background-color: rgba(245, 245, 245, 0.92);
    border-radius: 10px;
    height: 5px;
  }
`;
export const ScrollWrapper = styled.div`
  min-width: 1100px;
`;
export const ProjectTopDiv = styled.div`
  border-radius: 2px 2px 0px 0px;
  border-bottom: 1px solid #eaecf0;
  background: #fcfcfd;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeadList = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.widths ? props.widths : "")};
  p {
    color: #343e56;
    font-size: 14px;
    font-weight: 500;
  }
`;
export const ProjectContent = styled.div`
  display: flex;
  border-bottom: 1px solid rgb(242, 242, 242);
  border-left: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;
`;
export const KeywordWrap = styled.div`
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 17px;
  column-gap: 16px;
  row-gap: 8px;
`;
export const ProjectDiv = styled.div`
  width: 16.3%;
  min-height: 160px;
  padding: 38px 10px 10px 10px;
  display: flex;
  justify-content: center;
  border-radius: 0px 0px 0px 2px;
  color: #4f4f4f;
  font-size: 16px;
  font-weight: 500;
  word-break: break-all;
  &#setupNow {
    width: 100%;
  }
`;
export const KeywordDiv = styled.div`
  width: 23.5%;
  min-height: 160px;
  border-left: 1px solid #f2f2f2;

  p {
    border-radius: 5.814px;
    background: #eff8ff;
    display: flex;
    height: 29.07px;
    padding: 3.876px 8px;
    justify-content: center;
    align-items: center;
    color: #222;
    font-size: 14px;
    font-weight: 500;
  }
`;
export const CompetitorsDiv = styled.div`
  width: 24.2%;
  min-height: 160px;
  border-left: 1px solid #f2f2f2;
`;
export const CompetitorsList = styled.div`
  border-radius: 5.814px;
  background: #fbe7e9;
  display: flex;
  height: 29.07px;
  padding: 3.876px 11.628px;
  justify-content: center;
  align-items: center;
  gap: 9.69px;
  color: #222;
  font-size: 14px;
  font-weight: 500;
  &.evenClass {
    background: #ede7fb;
  }
`;
export const TextAdsDiv = styled.div`
  width: 12%;
  min-height: 160px;
  border-left: 1px solid #f2f2f2;
  padding-top: 38px;
  display: flex;
  justify-content: center;
  color: #4f4f4f;
  font-size: 16px;
  font-weight: 500;
`;

export const ImageAdsDiv = styled.div`
  width: 12%;
  min-height: 160px;
  border-left: 1px solid #f2f2f2;
  padding-top: 38px;
  display: flex;
  justify-content: center;
  color: #4f4f4f;
  font-size: 16px;
  font-weight: 500;
`;
export const SavedAdsDiv = styled.div`
  width: 12%;
  min-height: 160px;
  border-radius: 0px 0px 2px 0px;
  border-left: 1px solid #f2f2f2;
  padding-top: 38px;
  display: flex;
  justify-content: center;
  color: #4f4f4f;
  font-size: 16px;
  font-weight: 500;
`;
export const GenerateBtn = styled.div`
  border-radius: 4px;
  background: #7b4bff;
  display: flex;
  padding: 7px 15px 6px 15px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  height: 34px;
  cursor: pointer;
`;
export const ViewMoreWraper = styled.div`
  display: flex;
  color: #909090;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5.814px;
  border: 1px dashed #e8e8e8;
  background: #f6f7fb;
  height: 29.07px;
  padding: 3.876px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  &#saveAdCopy {
    width: 134px;
    min-height: 46px;
    padding: 4px 8px;
    cursor: unset;
  }
`;
