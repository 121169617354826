import React from "react"
import styled from "styled-components"

import { LineLoader } from "../../globalStyles"

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Box = styled.div`
    width: 300px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
`;

const SearchLoader = ({message}) => {
    return <Container>
        <Box>
            <p>{message ? message : "Loading results..."}</p>
            <LineLoader />
        </Box>
    </Container>
}

export default SearchLoader