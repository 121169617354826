import styled from "styled-components";

export const ResultWrapper = styled.div``;

export const AdResultWrap = styled.div`
  max-width: 1438px;
  margin: 33px auto;
  @media screen and (max-width: 1750px) {
    margin: 33px 30px;
  }
`;
export const AdHeader = styled.div`
  color: #000;
  font-size: 24px;
  font-weight: 600;
  line-height: 38px; /* 158.333% */
  span {
    color: #ff711e;
    font-size: 24px;
    font-weight: 600;
    line-height: 38px;
  }
`;
export const AdHeaderContainer = styled.div`
  p {
    color: #9c9c9c;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%; /* 21px */
    margin-top: 8px;
  }
`;
export const AdHeadWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
`;
export const CalenderWrap = styled.div`
  position: relative;
`;
export const PlatformAccountWrap = styled.div`
  display: flex;
  column-gap: 16px;
`;
export const PlatformAccount = styled.div`
  border-radius: 24px;
  border: ${(props) =>
    props.isConnect ? "1px solid #adddc5" : "1px solid #FFDBDB"};
  background: ${(props) => (props.isConnect ? "#f2faf6" : " #FFFBFB")};
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  p {
    color: rgba(28, 28, 28, 0.7);
    font-size: 14px;
    font-weight: 500;
    line-height: 150%; /* 21px */
  }
`;
export const CalendarContainer = styled.div`
  border-radius: var(--8, 8px);
  border: 1px solid #dadfe4;
  background: #fff;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  img {
    height: 16px;
  }
`;
export const CalendarDropDown = styled.div`
  position: absolute;
  background: #fff;
  top: 42px;
  right: 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 9px 3px;
`;
export const ManageButton = styled.div`
  border-radius: 24px;
  border: 1px solid #dadfe4;
  background: #fff;
  display: flex;
  column-gap: 8px;
  justify-content: center;
  align-items: center;
  width: 132px;
  padding: 10px;
  cursor: pointer;
  img {
    height: 18px;
  }
`;
export const ManageWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  flex-wrap: wrap;
  gap: 10px;
`;
export const StatusContainer = styled.div`
  display: flex;
  gap: 10px;
`;
export const StatusDiv = styled.div`
  cursor: pointer;
  width: 100px;
  height: 40px;
  padding: 10px;
  gap: 10px;
  border-radius: var(--8, 8px);
  border: ${(props) =>
    props.isSelected ? "1px solid #87A0E5" : "1px solid #dadfe4"};
  background: ${(props) => (props.isSelected ? "#E8EFFD" : "#fff")};
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
`;
