import React, {useEffect, useState} from "react";
import {BasicAnalysisContainer, PieChartContainer, PieChartAndTableContainer, TopKeywordsContainer, TopKeywordHeader, HeatMapContainer} from "./index.styled"
import {
    useMutation
} from "@tanstack/react-query"
import {appClient} from '../../../../../utils/axios-utils'

import CustomPieChart from "../PieChart"
import Table from "../PieCharTable"
import TopKeywords from "../TopKeywords"
import CampaignData from "../CampaignData"
import HeatMapChart from "../HeatMap"

const AdStat = async (data) => {
    const config = {
        url: `/aggs/url/domain/adstat`,
        method: "POST",
        data
    }

    return appClient(config)
}

const KeywordAdStat = async (data) => {
    const config = {
        url: `/aggs/url/domain/keywords`,
        method: "POST",
        data
    }

    return appClient(config)
}

const HeatMapAnalysis = async (data) => {
    const config = {
        url: `/aggs/url/domain/heatmap`,
        method: "POST",
        data
    }

    return appClient(config)
}

const DomainCard = ({domain}) => {
    const [activeCampaignStat, setActiveCampaignStat] = useState({

    })
    const [heatmapData, setHeatmapData] = useState([])
    const [keywordStats, setKeywordStats] = useState([])
    const [adsDistribution, setAdsDistribution] = useState([])

    const { mutate: getAdStat, isLoading: isStatLoading } = useMutation(AdStat, {
        onSuccess: (data) => {
            // console.log(data)
            setActiveCampaignStat(data.activeCampaigns)
            setAdsDistribution(data.adsDistribution)
        },
        onError: (err) => {
            
        },
        onMutate: ()=>{
            
        }
    })

    const { mutate: getKeywordStat, isLoading: isKeywordStatLoading } = useMutation(KeywordAdStat, {
        onSuccess: (data) => {
            if(data){
                setKeywordStats(data)
            }else{
                setKeywordStats([])
            }
        },
        onError: (err) => {
            
        },
        onMutate: ()=>{
            
        }
    })

    const { mutate: getHeatMapDetails, isLoading: isHeatMapDetailsLoading } = useMutation(HeatMapAnalysis, {
        onSuccess: (data) => {
            // console.log(data)
            setHeatmapData(data)
        },
        onError: (err) => {
            
        }
    })

    useEffect(() => {
        // console.log("domain card console")
        getAdStat({query: domain.hostname, type: "domain"})
        getHeatMapDetails({query: domain.hostname, type: "domain"})
        getKeywordStat({query: domain.hostname, type: "domain"})
    }, [])
    

    return <BasicAnalysisContainer>

                <CampaignData activeCampaignStat={activeCampaignStat} isDataLoading={isStatLoading}/>

                <PieChartContainer>
                    <h4> Ad distribution by platform </h4>
                    <PieChartAndTableContainer>
                        <CustomPieChart adsDistribution={adsDistribution} isDataLoading={isStatLoading}/>
                        <Table adsDistribution={adsDistribution} isDataLoading={isStatLoading}/>
                    </PieChartAndTableContainer>
                </PieChartContainer>

                <TopKeywordsContainer>
                    <TopKeywordHeader><h4>Top 5 Keywords</h4><h4>Occurrences</h4></TopKeywordHeader>
                    <TopKeywords keywordStats={keywordStats} isDataLoading={isKeywordStatLoading}/>
                </TopKeywordsContainer>

                <HeatMapContainer>
                    <h4>Day and Hour trend</h4>
                    <HeatMapChart heatmapData={heatmapData} isDataLoading={isHeatMapDetailsLoading}/>
                </HeatMapContainer>

                {/* <AreaChartContainer>
                    <h4>Average ad position</h4>
                    <SimpleAreaChart/>
                </AreaChartContainer> */}
            </BasicAnalysisContainer>
}

export default DomainCard