import React from "react";

import { Card, CardBody, ChannelDetails, LogoContainer, ChannelName, Description, FloatingButtonsContainer, ImageContainer, VideoContainer, SecondaryDetails, InnerContent, InnerDesc, InnerTitle, CallToActionButton, CTA, CardFooter, AdDate, AdCountries, FooterRight, FooterLeft, DetailsButton, SelectButton, AdTypeContainer, InnerUrl } from "./index.styled"

import FavorietIcon from "../../../../../../media/Dashboard/addFavorite.svg"
import FacebookIcon from "../../../../../../media/Dashboard/meta16.png"

import GlobeIcon from '../../../../../../media/Dashboard/globe.svg';
import DateIcon from "../../../../../../media/Images/DateIcon.svg"
import CountryIcon from "../../../../../../media/Images/CountryIcon.svg"
import AltImage from '../../../../../../media/Dashboard/altimage.png'
import { useAdGenrator } from '../../../context/adgenerator';

const FacebookCard = ({ad, ToggleSelectedAds}) => {
    const {adGenratorState, adGenratorDispatch} = useAdGenrator()

    const convertTimestamp = (timestamp) => {
        // Create a new Date object based on the timestamp
        var date = new Date(timestamp);
    
        // Get the month name
        var monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        var month = monthNames[date.getMonth()];
      
        // Get the year
        var year = date.getFullYear();
      
        // Assemble the formatted date string
        var formattedDate = date.getDate() + ' ' + month  + ' ' + year;
      
        return formattedDate;
    }

    return <Card key={
        ad.id
    }>
        <CardBody>
            <ChannelDetails>
                <LogoContainer>
                    <img src={ad.logo ? `https://aws-adspyder-logos.s3.ap-south-1.amazonaws.com/${ad.id}`: GlobeIcon}/>
                </LogoContainer>
                <ChannelName>
                    {ad.title}
                    <span>Sponsored</span>
                </ChannelName>
            </ChannelDetails>
            <Description>
                {ad.content}
            </Description>
            <AdTypeContainer>
                <img src={FacebookIcon}/> Meta
                {/* | <img src={ClockIcon}/>  */}
            </AdTypeContainer>
            <FloatingButtonsContainer>
                <SelectButton type="checkbox" id="selected" name="selected" onClick={()=>ToggleSelectedAds(ad.id)} checked={adGenratorState.selectedAds.includes(ad.id)}/>
                {/* <Favorite isFav={idx == 1}>
                    <img src={FavorietIcon}/>
                </Favorite>
                |
                <DropdownIcon>
                    <span/>
                </DropdownIcon> */}
            </FloatingButtonsContainer>
            {ad.image && <ImageContainer>
                <img src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${ad.id}`}/>
            </ImageContainer>}
            {ad.video && <VideoContainer>
                <video src={`https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${ad.id}`} preload="auto" controls loop style={{width: "100%", height: "100%"}}/>
            </VideoContainer>}
            {(!ad.video && !ad.image) && <ImageContainer>
                <img src={AltImage}/>
                </ImageContainer>}
            <SecondaryDetails>
                <InnerContent>
                <InnerUrl>
                    {ad.ctaUrl}
                </InnerUrl>
                <InnerDesc>
                    {ad.ctaTitle}
                </InnerDesc>
                <InnerTitle>
                    {ad.ctaContent}
                </InnerTitle>
                </InnerContent>
                <CallToActionButton>
                    <CTA>
                        {ad.ctaButton}
                    </CTA>
                </CallToActionButton>
            </SecondaryDetails>
        </CardBody>
    
        <CardFooter>
            <FooterLeft>
                <AdDate>
                {ad.firstSeen && ad.lastSeen && <img src={DateIcon}/>}{convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
                </AdDate>
            <AdCountries>
                                                {ad.country.length > 0 && <img src={CountryIcon} />}{ ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
                </AdCountries>
            </FooterLeft>
            <FooterRight>
                <DetailsButton>
                {/* {(urlAnalysisState.adDetails.id == ad.id) && urlAnalysisState.detailsModal ? "Close details" : "More details"} */}
                </DetailsButton>
            </FooterRight>
        </CardFooter>
    </Card>
}

export default FacebookCard