import React, {useEffect} from "react";
import { Container, KeywordContainer, KeywordContent, InputKeywordContainer, MatchTypeDropDownContainer, SelectedMatchType, MatchDropDown, MatchType, AddKeyword } from "./Keywords.styled"
import { useAdIntegration } from "../../../../context/AdManagementContext";
import DownArrow from "../../../../../../../../media/Dashboard/drop-down-arrow.svg"

const Keywords = () => {
    const {
        newAdManagementState,
        newAdManagementDispatch,
      } = useAdIntegration();

    let keywordsList =[];
    // newAdManagementState?.isSingleAdGroupData?.adGroupRow?.keywords.map((item,index)=>{
    //     keywordsList.push({
    //         keyword: item,
    //         type: "Phrase",
    //         id: `${index + 1}`
    //     })
    // })

    // const keywordsList = [
    //     {
    //         keyword: "apple iphone",
    //         type: "Broad",
    //         id: 1
    //     },
    //     {
    //         keyword: "new iphone",
    //         type: "Exact",
    //         id: 2
    //     },
    //     {
    //         keyword: "iphone",
    //         type: "Broad",
    //         id: 3
    //     },
    //     {
    //         keyword: "apple launch date",
    //         type: "Broad",
    //         id: 4
    //     },
    //     {
    //         keyword: "apple macbook",
    //         type: "Phrase",
    //         id: 5
    //     }
    // ]

    const addKeyword = () => {
        const updatedKeywordList = [...newAdManagementState.keywordsList, {
            keyword: "",
            type: "",
            id: newAdManagementState.keywordsList.length + 1
        }]

        newAdManagementDispatch({type: "SET_KEYWORDS_LIST", value: updatedKeywordList})
    }

    useEffect(() => {
        // newAdManagementState.keywordsList.length === 0 &&
        // adManagementDispatch({type: "SET_KEYWORDS_LIST", value: keywordsList})
    }, [])

    const toggleDropDown = (id) => {
        if(newAdManagementState.openedKeywordTypeDropDown == id){
            newAdManagementDispatch({ type: "SET_OPENED_KEYWORD_TYPE_DROPDOWN", value: null })
        }else{
            newAdManagementDispatch({ type: "SET_OPENED_KEYWORD_TYPE_DROPDOWN", value: id })
        }
    }
    const onSelectMatchType = (type, id) => {
        const updatedKeywordsList = newAdManagementState.keywordsList.map((data) => {
            if (data.id === id) {
                return {
                    ...data,
                    type: type
                };
            }
            return data;
        });
        newAdManagementDispatch({type: "SET_KEYWORDS_LIST", value: updatedKeywordsList})
    }
    const onKeywordCampaign = (keyword, id) => {
        const updatedKeywordsList = newAdManagementState.keywordsList.map((data) => {
            if (data.id === id) {
                return {
                    ...data,
                    keyword: keyword
                };
            }
            return data;
        });
        newAdManagementDispatch({type: "SET_KEYWORDS_LIST", value: updatedKeywordsList})
    }
    
    const renderAdGroup = (data, index) => {
        const matchTypes = ["Broad", "Phrase", "Exact"]
        return <KeywordContainer>
            <KeywordContent>
                <label>
                    Keyword {index+1}
                </label>
                <InputKeywordContainer>
                    <input placeholder={"Enter keyword "+ index} onChange={(e)=> onKeywordCampaign(e.target.value, data.id)} value={data.keyword}/>
                    <MatchTypeDropDownContainer>
                        <SelectedMatchType onClick={()=>toggleDropDown(data.id)} rotateImg={newAdManagementState.openedKeywordTypeDropDown == data.id}>
                            {data.type ? data.type : "Match type"}
                            <img
                                src={DownArrow}
                            />
                        </SelectedMatchType>
                        {newAdManagementState.openedKeywordTypeDropDown == data.id && <MatchDropDown>
                            {matchTypes.map((matchType)=>{
                                return <MatchType
                                    onClick={() => {
                                        onSelectMatchType(matchType, data.id)
                                        newAdManagementDispatch({ type: "SET_OPENED_KEYWORD_TYPE_DROPDOWN", value: false })
                                    }}
                                >
                                    {matchType}
                                </MatchType>
                            })}
                        </MatchDropDown>}
                    </MatchTypeDropDownContainer>
                </InputKeywordContainer>
            </KeywordContent>
        </KeywordContainer>
    }

    return <Container>
    {newAdManagementState.keywordsList.map((keyword, index)=>{
        return <>
        {renderAdGroup(keyword, index)}
        </>
    })}
    <AddKeyword onClick={addKeyword}> + add Keyword</AddKeyword>
</Container>
}

export default Keywords