import { appClient } from '../../../../../utils/axios-utils';
import {useMutation} from "@tanstack/react-query"
import { useNavigate } from 'react-router-dom';
import { useAdManagement } from '../context/AdManagementContext';
import { getSearchParams } from "../../../../../utils/helper";
import { useLocation } from "react-router-dom";

const getAllConnected = async (data) => {
    const config = {
        url: "/admanagement/get-connected-accounts",
        method: "GET",
    }

    return appClient(config)
}
const getAuthLogin = async (data) => {
    const config = {
        url: "/admanagement/login",
        method: "GET",
    }

    return appClient(config)
}
const getRecentConnection = async (data) => {
    const config = {
        url: "/admanagement/get-recent-connection",
        method: "GET",
    }

    return appClient(config)
}
const getListAdAccounts = async (data) => {
    const config = {
        url: "/admanagement/list-adaccounts",
        method: "POST",
        data
    }

    return appClient(config)
}
const getConnectAccount = async (data) => {
    const config = {
        url: "/admanagement/connect-account",
        method: "POST",
        data
    }

    return appClient(config)
}
const getConnectedData = async (data) => {
    const config = {
        url: "/admanagement/get-connected-accounts",
        method: "GET",
        
    }

    return appClient(config)
}
const getSingleAdGroup = async (data) => {
    const config = {
        url: "/text-ad-generation/generate/get-single-ad-group",
        method: "POST",
        data
    }

    return appClient(config)
}
const getCreateCampaign = async (data) => {
    const config = {
        url: "/admanagement/create-campaign",
        method: "POST",
        data
    }

    return appClient(config)
}
const getCreateAd = async (data) => {
    const config = {
        url: "/admanagement/create-ad",
        method: "POST",
        data
    }

    return appClient(config)
}
const getCampaignCriteria = async (data) => {
    const config = {
        url: "/admanagement/update-campaign-criteria",
        method: "POST",
        data
    }

    return appClient(config)
}
const getCheckMailVerified = async (data) => {
    const config = {
        url: "/admanagement/get-manager-link",
        method: "POST",
        data
    }

    return appClient(config)
}
export const useAdManagementInfo = () => {
    const {adManagementState, adManagementDispatch} = useAdManagement()
    const navigate = useNavigate()
    const location = useLocation();
    
    const { mutate: setAllConnected, isLoading: isSetAllConnectedLoading } = useMutation(getAllConnected, {
        onSuccess: (data) => {
            adManagementDispatch({type: 'SET_AD_ACCOUNT_LIST', value: data})
            if(data.length>0){
                // adManagementDispatch({type: 'SET_SELECT_AD_ACCOUNT', value: true})
                adManagementDispatch({type: 'SET_AD_DATA_PAGE', value: true})
            }
           
        },
        onError: (err) => {
            
        }
    })
    const { mutate: setAuthLogin, isLoading: isAuthLoginLoading } = useMutation(getAuthLogin, {
        onSuccess: (data) => {
           
        },
        onError: (err) => {
            
        }
    })
    const { mutate: setRecentConnection, isLoading: isRecentConnectionLoading } = useMutation(getRecentConnection, {
        onSuccess: (data) => {
            adManagementDispatch({type: 'SET_INTEGRATION_ID', value: data.integrationId})
            setListAdAccounts({"integrationId": data.integrationId})
        },
        onError: (err) => {
            
        }
    })
    const { mutate: setListAdAccounts, isLoading: isListAdAccountsLoading } = useMutation(getListAdAccounts, {
        onSuccess: (data) => {
            console.log(data,'check')
            adManagementDispatch({type: 'SET_LIST_AD_ACCOUNTS', value: data})
        },
        onError: (err) => {
            
        }
    })
    const { mutate: setConnectAccount, isLoading: isConnectAccountLoading } = useMutation(getConnectAccount, {
        onSuccess: (data) => {
            const searchParamFromState = getSearchParams(location.search)
            const {
                id
            } = searchParamFromState
            if (id) {
                navigate(`/admanagement/ad-campaigns/new-campaign?id=${id}`);
            } else {
                adManagementDispatch({ type: 'SET_SELECT_AD_ACCOUNT', value: false });
                adManagementDispatch({ type: 'SET_INPUT_PAGE', value: true });
                adManagementDispatch({ type: 'SET_AD_DATA_PAGE', value: true });
            }
        },
        onError: (err) => {
            if(err.response.data.errors[0].message == "check email"){
                adManagementDispatch({ type: 'SET_CHECK_EMAIL_POPUP', value: true });
                
                console.log(err.response.data.errors[0].message,'err.response.data.errors[0].message')
            }
        }
    })
    const { mutate: setConnectedData, isLoading: isConnectedDataLoading } = useMutation(getConnectedData, {
        onSuccess: (data) => {
            adManagementDispatch({type: 'SET_CONNECTED_DATA', value: data})
        },
        onError: (err) => {
            
        }
    })
    const { mutate: setSingleAdGroup, isLoading: isSingleAdGroupLoading } = useMutation(getSingleAdGroup, {
        onSuccess: (data) => {
            adManagementDispatch({type: 'SET_ADGROUP_LIST', value: [{
                id: 1,
                destinationUrl: data.domain,
                titleList: data?.adGroupRow?.titles,
                descriptionList: data?.adGroupRow?.descriptions
            }]})
            adManagementDispatch({type: 'SET_SINGLE_AD_GROUP', value: data})
            let keywordsList = [];
            data.adGroupRow?.keywords.map((item,index)=>{
                keywordsList.push({
                    keyword: item,
                    type: "Phrase",
                    id: `${index + 1}`
                })
            }) 
            adManagementDispatch({type: "SET_KEYWORDS_LIST", value: keywordsList })
           

        },
        onError: (err) => {
            
        }
    })
    const { mutate: setCreateCampaign, isLoading: isCreateCampaignLoading } = useMutation(getCreateCampaign, {
        onSuccess: (data) => {
            adManagementDispatch({type: 'SET_CREATE_CAMPAIGN', value: data})
            setCreateAd({
                "customerId":adManagementState.isConnectedData[0].customerId,
                "connectionId": adManagementState.isConnectedData[0].connectionId,
                "budgetId": data.budgetId,
                "campaignId": data.campaignId,
                "adGroupId": data.adGroupId,
                "adGroupAdData": {
                  "ad": {
                    "responsiveSearchAd": {
                      "headlines": adManagementState.isCreateAdHeadline,
                      "descriptions": adManagementState.isCreateAdDescription
                    },
                    "finalUrls": adManagementState.isDestinationUrl
                  }
                },
                "keywords": adManagementState.isTransformedKeyword
              }
              )
            // adManagementDispatch({type: 'SET_SINGLE_AD_GROUP', value: data})
        },
        onError: (err) => {
            adManagementDispatch({type: "SET_ERROR_MESSAGE", value: err.response.data.errors[0].message})
            
        }
    })
    const { mutate: setCreateAd, isLoading: isCreateAdLoading } = useMutation(getCreateAd, {
        onSuccess: (data) => {
            setCampaignCriteria({
                "customerId": adManagementState.isConnectedData[0].customerId,
                "campaignId": adManagementState.isCreateCampaign.campaignId,
                "languages": [1000],
                "locations": [20460, 20461]
              })
            // adManagementDispatch({type: 'SET_SINGLE_AD_GROUP', value: data})
        },
        onError: (err) => {
            adManagementDispatch({type: "SET_ERROR_MESSAGE", value: err.response.data.errors[0].message})
            
        }
    })
    const { mutate: setCampaignCriteria, isLoading: isCampaignCriteriaLoading } = useMutation(getCampaignCriteria, {
        onSuccess: (data) => {
            adManagementDispatch({type: "SET_CAMPAIGN_PUBLISHED", value: true})
        },
        onError: (err) => {
            
        }
    })
    const { mutate: setCheckMailVerified, isLoading: isCheckMailVerifiedLoading } = useMutation(getCheckMailVerified, {
        onSuccess: (data) => {
            adManagementDispatch({type: "SET_CHECK_EMAIL_ERROR", value: false})
            const searchParamFromState = getSearchParams(location.search)
            const {
                id
            } = searchParamFromState
            if (id) {
                navigate(`/admanagement/ad-campaigns/new-campaign?id=${id}`);
            } else {
                adManagementDispatch({ type: 'SET_SELECT_AD_ACCOUNT', value: false });
                adManagementDispatch({ type: 'SET_INPUT_PAGE', value: true });
                adManagementDispatch({ type: 'SET_AD_DATA_PAGE', value: true });
            }
        },
        onError: (err) => {
            if(err.response.data.errors[0].message == "Not connected"){
                console.log(err.response.data.errors[0].message,'err.response.data.errors[0].message')
                adManagementDispatch({type: "SET_CHECK_EMAIL_ERROR", value: true})
            }
        }
    })
    return {
        setAllConnected,
        isSetAllConnectedLoading,
        setAuthLogin,
        isAuthLoginLoading,
        setRecentConnection,
        isRecentConnectionLoading,
        setListAdAccounts,
        isListAdAccountsLoading,
        setConnectAccount,
        isConnectAccountLoading,
        setConnectedData,
        isConnectedDataLoading,
        setSingleAdGroup,
        isSingleAdGroupLoading,
        setCreateCampaign,
        isCreateCampaignLoading,
        setCheckMailVerified,
        isCheckMailVerifiedLoading
    };
};
