import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    h1 {
        color: #333;
        font-size: 24px;
        width: 95%;
        margin: auto;
        padding: 50px 40px 20px;
    }

    form {
        background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        margin-top: 20px;
    } 

    label {
        display: block;
        margin-bottom: 8px;
        color: #333;
    }

    input {
        width: 100%;
        padding: 8px;
        margin-bottom: 16px;
        box-sizing: border-box;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    select {
        width: 100%;
        padding: 8px;
        margin-bottom: 16px;
        box-sizing: border-box;
        border: 1px solid rgb(204, 204, 204);
        border-radius: 4px;
        cursor: pointer;
    }

    button {
        background-color: #4caf50;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

    button:hover {
        background-color: #45a049;
    }
`

export const Divider = styled.div`
    height: 50px;
`

export const ComponentWrapper = styled.div`
    width: 95%;
    margin: auto;
    background: #FFF;
    padding: 20px 30px;
    box-shadow: 0px 0px 7px 2px #8080802b;
    border-radius: 5px;
    h2{
        margin-bottom: 25px;
    }
`

export const KeywordRow = styled.div`
    display: flex;
    /* box-shadow: #808080b0 0px 0px 3px 0px; */
    border-radius: 3px;
    padding: 15px;
    justify-content: space-between;

    input{
        width: 50%;
        margin-bottom: 0px;
        pointer-events: ${props => props.editableItem ? "all": "none"};
        opacity: ${props => props.editableItem ? "1": "0.5"};
    }
    select{
        width: 20%;
        margin-bottom: 0px;
        pointer-events: ${props => props.editableItem ? "all": "none"};
        opacity: ${props => props.editableItem ? "1": "0.5"};
        cursor: pointer;
    }
`;

export const Footer = styled.div`
    width: 95%;
    margin: auto;
    padding: 20px 30px;
    display: flex;
    justify-content: flex-end;
`

export const ContentContainer = styled.div`
    
`;

export const InputKeywordRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    input{
        width: 70%;
        margin-bottom: 0;
    }
    select{
        width: 30%;
        margin-bottom: 0;
        cursor: pointer;
    }
`;

export const AddKeyword = styled.div`
    height: 100%;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2196F3;
    color: rgb(255, 255, 255);
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
`;

export const KeywordInputContainer = styled.div`
    width: 70%;
    display: flex;
`;

export const Edit = styled.div`
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #03A9F4;
    border-radius: 3px;
    color: #FFF;
    cursor: pointer;
`;

export const Delete = styled.div`
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ff5722;
    border-radius: 3px;
    color: #FFF;
    cursor: pointer;
`;