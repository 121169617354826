import AddCompetitorPopup from "./Popup";
import { AddButton, AddCompetitorContainer, CampaignsLabel, CompetitorContainer, CompetitorName, Counts, LastUpdated, New, Text } from "./index.styled";
import { useMutation } from "@tanstack/react-query"
import { appClient } from "../../../../../utils/axios-utils"
import { useOverview } from "../../context/overview";
import { useEffect } from "react"
import { LineLoader } from "../index.styled";
import { useNavigate } from "react-router-dom";

function Competitors() {
    const { overviewState: { projectDetails, isAddCompetitorPopupActive, competitorsCampaignCount, isOnboardingPopupActive }, overviewDispatch } = useOverview()
    const navigate = useNavigate()
    const getCompetitorsCampaignCounts = (data) => {
        const config = {
            url: `/dashboard-overview/competitors/getactivecounts`,
            method: "POST",
            data
        }
        return appClient(config)
    }
    const { mutate: getCompetitorsCampaignCountsMutate, isLoading } = useMutation(getCompetitorsCampaignCounts, {
        onSuccess: (data) => {
            overviewDispatch({
                type: "SET_COMPETITORS_CAMPAIGN_COUNT",
                value: data.activeCampaignCounts
            })
        },
        onError: (e) => console.log(e)
    })

    const activateCompetitorPopup = () => {
        if (!isAddCompetitorPopupActive)
            overviewDispatch({
                type: "SET_COMPETITOR_POPUP_ACTIVE",
                value: true
            })
    }

    useEffect(() => {
        if (projectDetails?.projectId && !isAddCompetitorPopupActive)
            getCompetitorsCampaignCountsMutate({ projectId: projectDetails.projectId })
    }, [projectDetails?.projectId, isAddCompetitorPopupActive])

    const handleCompetitorClick = (domain) => {
        navigate(`/domain-overview/details?domain=${domain}&country=global`)
    }

    if(isOnboardingPopupActive || isLoading){
        return <LineLoader width={206} height={232} />
    }

    return (
        <>
            {competitorsCampaignCount.map((competitor) => {
                return (
                    <CompetitorContainer onClick={() => handleCompetitorClick(competitor.domain)} >
                        <CompetitorName>{`${competitor.domain}'s`}</CompetitorName>
                        <CampaignsLabel>Active campaigns</CampaignsLabel>
                        <Counts>
                            {competitor.totalCampaignsCount}
                            {competitor?.totalIncreaseCampaignsCount !=0 && <New>{ `+ ${competitor.totalIncreaseCampaignsCount} new`}</New>}
                        </Counts>
                        <LastUpdated>{`Updated few hours ago`}</LastUpdated>
                    </CompetitorContainer>
                )
            })}
            <AddCompetitorContainer onClick={activateCompetitorPopup} >
                <Text>{`Need to spy your
competitor?`}</Text>
                <AddButton>{`+ add comp`}</AddButton>
                <AddCompetitorPopup />
            </AddCompetitorContainer>
        </>
    );
}

export default Competitors;