import React, {} from 'react'
import { useSearchParams } from 'react-router-dom'

// Styles
import {
    PaginationContainer
} from "./index.styled"

import {useGoogleSearch} from "../../context/google"
import ReactPaginate from 'react-paginate';


const Pagination = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const {googleSearchState, googleSearchDispatch} = useGoogleSearch()

    const handleSpecificPage = (e) => {
        googleSearchDispatch({type:'SET_AD_DETAILS', value: {}})
        googleSearchDispatch({type:'UPDATE_DETAILS_MODAL', value: false})
        googleSearchDispatch({ type: "SET_CURRENT_PAGE_NO", value: e.selected + 1 })
        searchParams.set("page", e.selected + 1)
        setSearchParams(searchParams)
    }
    
    return <PaginationContainer 
    prevState={googleSearchState.currentPageNo == 1}
    nextState={googleSearchState.currentPageNo == Math.ceil((googleSearchState.totalAds / 20))}>
        <ReactPaginate
            breakLabel="..."
            forcePage={googleSearchState.currentPageNo - 1}
            nextLabel=">"
            onPageChange={handleSpecificPage}
            pageRangeDisplayed={3}
            pageCount={(googleSearchState.totalAds / 20)}
            previousLabel="<"
            renderOnZeroPageCount={null}
        />
  </PaginationContainer>

}

export default Pagination
