import styled from 'styled-components';



export const MainContainer = styled.div`
display:flex;
align-items:center;
justify-content:${props => !props.gap && "space-between"};
gap:16px;
height:40px;

div {
color: rgba(28, 28, 28, 0.80);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px;}
`;

export const DropdownContainer = styled.div`
  position: relative;
  width: 129px;
height: 40px;
flex-shrink: 0;
border-radius: 6px;
border: 1px #DADFE4;

`;

export const DropdownButton = styled.button`
  width: 100%;
  height:40px;
  padding: 8px ;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:after {
    content: '▼';
    font-size: 12px;
    color: #666;
  }
`;

export const DropdownList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-top: none;
  border-radius: 0 0 4px 4px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  z-index: 1000;
`;

export const DropdownItem = styled.li`
  padding: 8px 12px;
  font-size: 14px;
  color: #333;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const CheckboxItem = styled(DropdownItem)`
  display: flex;
  align-items: center;
`;

export const Checkbox = styled.input`
  margin-right: 8px;
`;