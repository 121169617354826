import React from "react";
import { Card, CardBody, Header, LogoWrapper, TitleWrap, Title, LinkWrap, KeywordWrap, DescriptionWrap, CountryWrap, Country, GoogleIcon, FooterWrap, HoverCardWrapper, AdDetailsButton, HoverCard } from "./index.styled"


import GlobeIcon from '../../../../../../media/Dashboard/globe.svg';
import locationImg from '../../../../../../media/Dashboard/locationCard.svg'
import BingImg from "../../../../../../media/Dashboard/bing.svg"
import RightUpperBlackArrow from "../../../../../../media/Dashboard/RightUpperBlackArrow.svg"
import { useSavedAds } from "../../../context/savedads";

const BingCard = ({ad}) => {
    const { savedAdsDispatch } = useSavedAds()

    const convertTimestamp = (timestamp) => {
        // Create a new Date object based on the timestamp
        var date = new Date(timestamp);
    
        // Get the month name
        var monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        var month = monthNames[date.getMonth()];
        
        // Get the year
        var year = date.getFullYear();
      
        // Assemble the formatted date string
        var formattedDate = date.getDate() + ' ' + month  + ' ' + year;
      
        return formattedDate;
    }

    const toggleDetailsModal = (ad) => {
        savedAdsDispatch({type: 'SET_SELECTED_AD_FOR_DETAIL', value: ad})
        savedAdsDispatch({ type: 'UPDATE_DETAILS_MODAL', value: true })
    }

    return <Card key={
        ad.id
    }>
        <HoverCard className='hover-element' >
            <HoverCardWrapper>
                <AdDetailsButton onClick={() => toggleDetailsModal(ad)}>
                    <img src={RightUpperBlackArrow} />
                    <span>Ad Details</span>
                </AdDetailsButton>
            </HoverCardWrapper>
        </HoverCard>
        <CardBody>
            <Header>
                <LogoWrapper>
                    <img src={GlobeIcon} />
                </LogoWrapper>
                <TitleWrap> 
                    <Title>{ad.domainId}</Title>
                    <LinkWrap>{ad.url}</LinkWrap>
                </TitleWrap>
            </Header>
            <KeywordWrap>{ad.title}</KeywordWrap>
            <DescriptionWrap>
                {`${convertTimestamp(ad.lastSeen)} __ ${ad.content}`}
            </DescriptionWrap>
            <FooterWrap>
                <CountryWrap>
                <img src={locationImg}/>
                    <Country>{ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : "--"}</Country>
                </CountryWrap>
                <GoogleIcon>
                    <img src={BingImg}/>
                </GoogleIcon>
            </FooterWrap>
        </CardBody>
        
    </Card>
}

export default BingCard