import React from 'react'
import { DomainBody, DomainBodyWrap, DomainName, DomainValue, DomainValueWrap, TableHead, TableHeadWrapper, TopDomianWrapper, SkeletonWrap } from './index.styled'
import { useDomainOverview } from '../../../context/domainoverview';
import Skeleton from "react-loading-skeleton";
import Tooltip from 'rc-tooltip';
import helpCircle from '../../../../../../media/Dashboard/help-circle.svg'

const TopDomains = ({ isTopDomainsLoading }) => {
    const { domainOverviewState, domainOverviewDispatch } = useDomainOverview()
    const tableArr = [1, 2, 3, 4, 5, 6]
    return (
        <TopDomianWrapper>
            <TableHeadWrapper>
                <TableHead className='top'>Similar domains <Tooltip trigger={['hover']} overlayStyle={{ width: "100" }} overlay={<span>Similar domains that are found running ads, based on keywords found for this domain.</span>}>
                    <img src={helpCircle} />
                </Tooltip></TableHead>
                <TableHead>Occurences <Tooltip trigger={['hover']} overlayStyle={{ width: "100" }} overlay={<span>Ads found on this similar domains.</span>}>
                    <img src={helpCircle} />
                </Tooltip></TableHead>
            </TableHeadWrapper>
            <DomainBodyWrap>
                {isTopDomainsLoading ?
                    tableArr.map((index) => (
                        <DomainBody>
                            <SkeletonWrap key={index}>
                                <Skeleton height={"16px"} width={'200px'} />
                                <Skeleton height={"16px"} width={'100px'} />
                            </SkeletonWrap>
                        </DomainBody>
                    ))
                    :
                    <>
                        {domainOverviewState?.topDomains?.length > 0 &&
                            domainOverviewState?.topDomains?.map((item) => (
                                <DomainBody>
                                    <DomainName>{item?.key}</DomainName>
                                    <DomainValueWrap>
                                        <DomainValue>{item?.percentage}</DomainValue>
                                        {/* <DomainGrade><img src={upGreenArrow} />3</DomainGrade> */}
                                    </DomainValueWrap>
                                </DomainBody>
                            ))

                        }
                    </>
                }
                {/* <DomainBody>
                <DomainName>www.adspyder.io</DomainName>
                <DomainValueWrap>
                    <DomainValue>20.06%</DomainValue>
                    <DomainGrade><img src={upGreenArrow} />3</DomainGrade>
                </DomainValueWrap>
            </DomainBody>
            <DomainBody>
                <DomainName>www.flonnect.com</DomainName>
                <DomainValueWrap>
                    <DomainValue>15.81%</DomainValue> */}
                {/* <DomainGrade><img src={upGreenArrow} />3</DomainGrade> */}
                {/* </DomainValueWrap>
            </DomainBody>
            <DomainBody>
                <DomainName>www.couponswala.com  </DomainName>
                <DomainValueWrap>
                    <DomainValue>14.66%</DomainValue> */}
                {/* <DomainGrade><img src={upGreenArrow} />3</DomainGrade> */}
                {/* </DomainValueWrap>
            </DomainBody>
            <DomainBody>
                <DomainName>www.similarweb.com  </DomainName>
                <DomainValueWrap>
                    <DomainValue>13.77%</DomainValue> */}
                {/* <DomainGrade><img src={upGreenArrow} />3</DomainGrade> */}
                {/* </DomainValueWrap>
            </DomainBody> */}

            </DomainBodyWrap>
        </TopDomianWrapper>
    )
}

export default TopDomains