import styled from "styled-components";

export const HistoryContainer = styled.div`
    /* width: 990px; */
    height: 293px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    width: 100%;
    min-Height: 368px;
    max-width: 996px;
    border-radius: 16px;
    /* background: #fff; */
    padding: 32px 40px;
    margin: 24px 24px 24px 200px;
    flex-direction: column;
    @media screen and (max-width: 1700px) {
        margin: auto;
        margin-top: 24px;
    };
    ${(props) =>
        !props.isNavCollapsed
        ? `
            @media screen and (max-width: 1332px) {
                margin: 24px 35px 0 30px;
            }
            `
        : `
            @media screen and (max-width: 1147px) {
                margin: 24px 35px 0 30px;
            }
            `
    }
  
      
`;

export const WrappingContainer = styled.div`
    width: 100%;
    height: 100%;
`;

export const Header = styled.div`
color: rgba(28, 28, 28, 0.50);
leading-trim: both;
text-edge: cap;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 38px; /* 237.5% */
margin-bottom: 18px;
`;

export const Gallery = styled.div`
    display: flex;
    width: 100%;
    height: 222px;
    gap: 16px;
    padding-bottom: 22px;
    overflow-x: scroll;
    ::-webkit-scrollbar {
        height: 4px;               /* width of the entire scrollbar */
    }

    body::-webkit-scrollbar-track {
        /* background: red;        color of the tracking area */
    }
    ::-webkit-scrollbar-thumb {
        background-color: #D4D4D4;    /* color of the scroll thumb */
        border-radius: 10px;     
    }
`;

export const NavButton = styled.div`
    width: 24px;
    height: 24px;
    border: 1px solid #DADFE4;
    border-radius: 50%;
    background: #fff;
    display: flex;
    align-items: center; 
    justify-content: center;
    position: absolute;
    bottom: 125px;
    left: ${(props) => props.isLeft ? "0" : "unset"};
    right: ${(props) => props.isRight ? "0" : "unset"};
    cursor: pointer;
    z-index: 10;
`;

export const NavImg = styled.img``;

export const Holder = styled.div`
    height: 100%;
    border: 1px solid #DADFE4;
    cursor: pointer;
    background: url(${(props) => props.url}) lightgray 50% / cover no-repeat;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), url(${(props) => props.url}) lightgray 50% / cover no-repeat;
    }
`;

export const Template = styled.img`
    height: 100%;
`;

export const HoveredContainer = styled.div`
    display: none;
`;

export const Details = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 26px;
    background: #FFF;
    padding: 4px 18px;
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
`;

export const Download = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 26px;
    background: #FF711E;
    padding: 4px 18px;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
`;

