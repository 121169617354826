import styled, { keyframes } from "styled-components";
const waveLines = keyframes`
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
`;
export const RootContainer = styled.div`
    width: 1132px;
    height: 100%;
    cursor: default;
    padding: 0 50px;
`;

export const Header = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin-top: 32px;
`;

export const HeaderLoader = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100px;
    width: 350px;
    gap: 10px;
`;

export const Square = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: 2px;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(90deg, #F5F8F7 0%, #E6E8EC 49.5%, #F4F7F6 100%);
  background-size: 800px 100px;
  animation: ${waveLines} 2s infinite ease-out;
`;

export const LineLoader = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  margin: ${(props) => (props.margin ? props.margin : "unset")};
  border-radius: 2px;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  background-size: 800px 100px;
  animation: ${waveLines} 2s infinite ease-out;

  position: ${(props) => (props.absolute ? "absolute" : "unset")};
  top: ${(props) => (props.top ? props.top : "unset")};
  left: ${(props) => (props.left ? props.left : "unset")};
  bottom: ${(props) => (props.bottom ? props.bottom : "unset")};
  right: ${(props) => (props.right ? props.right : "unset")};
`;

export const Today = styled.div`
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.14px;
`;

export const Welcome = styled.div`
    color: #000;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px; /* 135.714% */
`;

export const ProjectDetails = styled.div`
position: relative;
`;

export const Name = styled.span`
    color: rgba(28, 28, 28, 0.50);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.14px;
`;

export const Overview = styled.span`
color: #FF711E;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 22px;
letter-spacing: -0.14px;
cursor: pointer;
`;

export const ContentHeader = styled.div`
    margin-top: 40px;
    margin-bottom: 16px;
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    letter-spacing: -0.16px;
`;

export const ContentGrid = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`

export const ProjectDropdownButton = styled.img`
display: inline;
margin-left: 5px;
cursor: pointer;
`;

export const ProjectDropdownContainer = styled.div`
width: 200px;
flex-shrink: 0;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
padding: 8px;
position: absolute;
top: 30px;
right: -48px;
z-index: 1;
display: none;
`;

export const ProjectList = styled.div`
max-height: 104px;
display: flex;
flex-direction: column;
overflow-y: auto;
::-webkit-scrollbar-track
{
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
    background-color: rgb(245 245 245 / 28%);
    border-radius: 10px;
}

::-webkit-scrollbar
{
    width: 1.5px;
    border-radius: 10px;
    background-color: #DADFE4;
}

::-webkit-scrollbar-thumb
{
    background-color: #00000030;
    border-radius: 10px;
}
`;

export const ProjectName = styled.div`
height: 25px;
width: 95%;
display: flex;
align-items: center;
justify-content: start;
padding-left: 6px;
color: rgba(28, 28, 28, 0.70);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 157.143% */
letter-spacing: -0.14px;
:hover{
    border-radius: 4px;
    background: #FFE8BF;
}
padding-right: 6px;
cursor: pointer;
`;

