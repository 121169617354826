import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    border-radius: 8px;
`;

export const ResultsContainer = styled.div`
    display: flex;
    gap: 12px;
    height: calc(100vh - 245px);
    background: #006ecc00;
    position: absolute;
    left: 0;
    top: 0;
    .loader{
        height: 200px;
        margin: auto;
    }
`;

export const SearchContainer = styled.div`
    display: flex;
    /* height: 62px; */
    /* margin-top: 32px; */
    align-items: center;
    width: calc(100% - 205px);
    gap: 16px;
    ${(props) =>
    !props.isNavCollapsed
      ? `
          @media screen and (max-width: 1332px) {
              width: 100%;
          }
        `
      : `
          @media screen and (max-width: 1147px) {
              width: 100%;
          }
        `
    }
`;

export const PlaceholderWithInput = styled.div`
display: flex;
width: calc(100% - 95px - 125px - 32px);
`;

export const Wrapper = styled.div`
  min-height: 368px;
  max-width: 996px;
  border-radius: 16px;
  background: #fff;
  padding: 32px 40px;
  margin: 24px 24px 24px 200px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1700px) {
    margin: auto;
    margin-top: 24px;
  };
  ${(props) =>
    !props.isNavCollapsed
      ? `
          @media screen and (max-width: 1332px) {
              margin: 24px 35px 0 30px;
          }
        `
      : `
          @media screen and (max-width: 1147px) {
              margin: 24px 35px 0 30px;
          }
        `
    }
  
`;


export const HeadingWrapper = styled.div`
  display: flex;
  margin-bottom: 40px;
  column-gap: 100px ;
`;

export const HeadingLeft = styled.div`
  h3 {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #ff711ecc;
    margin-bottom: 24px;
  }
  h2 {
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    color: #1c1c1c;
    /* margin-bottom: 20px; */
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px; /* 190% */
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #1c1c1c80;
  }
`;

export const HeadLeftWrap = styled.div`
  display: flex;
  column-gap: 14px;
  align-items: center;
  margin-bottom: 16px;
`;

export const HowItWork = styled.div`
  width: 104px;
  height: 24px;
  border-radius: 16px;
  background: #f5f6fa;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8592a2;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
`;

export const HeaderRight = styled.div`
  /* padding: 10px 30px; */
  // border-radius: 10px;
  // background: #f7f7f7;
  // display: flex;
  // align-items: center;
  img {
    width: 205px;
    height: 135px;
  }
`;

export const HttpsLabel = styled.div`
display: flex;
justify-content: center;
align-items: center;
border-radius: var(--8, 8px) 0px 0px var(--8, 8px);
border-top: 1px solid #D0D5DD;
border-bottom: 1px solid #D0D5DD;
border-left: 1px solid #D0D5DD;
background: #F9FAFB;
color: rgba(28, 28, 28, 0.50);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
height: 44px;
width: 81px;
`;

export const DomainInput = styled.input`
  color: rgba(28, 28, 28, 0.90);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  height: 100%;
  width: 100%;
  // border: 1px solid #D0D5DD;
  border: none;
  outline: none;
  // border-radius: 8px;
  caret-color: #FFB258;
  // :focus{
  //   border: 1px solid #FFB258;
  //   box-shadow: 0px 1px 2px 0px rgba(28, 28, 28, 0.05), 0px 0px 4px 0px rgba(255, 178, 88, 0.25);
  // }
  ::placeholder{
    color: rgba(28, 28, 28, 0.50);
  }
`;

export const TrackButton = styled.button`
  border-radius: 6px;
  background: #FF711E;
  height: 44px;
  color: #FFF;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  width: 95px;
  :hover {
    filter: brightness(90%);
  }
`;

export const InputContainer = styled.div`
  border-radius: 0px 8px 8px 0px;
  border: ${(props) => props.hasError ? "1px solid #F04438" : props.isFocussed ? "1px solid #FFB258" : "1px solid #D0D5DD"};
  background: #FFF;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 81px);
  min-width: 195px;
  position: relative;
  padding: 0 14px;
  box-shadow: ${(props) => props.isFocussed ? "0px 1px 2px 0px rgba(28, 28, 28, 0.05), 0px 0px 4px 0px rgba(255, 178, 88, 0.25)" : "unset"};
`;


export const RightIcons = styled.div`
  display: flex;
  gap: 8px;
`;
export const ErrorIcon = styled.img``

export const ClearButton = styled.img`
  cursor: pointer;  
`;

export const ErrorBox = styled.div`
color: #F04438;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 21px */
letter-spacing: -0.14px;
margin-top: 8px;
`;
