import React from "react";
import { Container} from "./index.styled"
import { useAdManagement } from "../context/AdManagementContext";
import AdCampaignInput from "./components/AdCampaignInput";
import AdDetailsInput from "./components/AdDetailsInput";

const NewCampaign = () => {
    
    const { adManagementState, adManagementDispatch } = useAdManagement()
    
    const renderContent = () => {
        switch (adManagementState.currentCampaignStep){
            case 1:
            return <AdCampaignInput />

            case 2:
            return <AdDetailsInput />

            default:
            return

        }
    }
    
    return <Container>
        {renderContent()}
    </Container>

}

export default NewCampaign