import { BannerDetails, Description, NavigateButton, Title } from "../index.styled";
import { imageAdDetail } from "../statics";
import { ImageAdBanner, SliderItem, VerticalSlider } from "./ImageAd.styled";
import { Link } from "react-router-dom";
import { appClient } from "../../../../../../utils/axios-utils"
import { useMutation } from "@tanstack/react-query"
import { useOverview } from "../../../context/overview";
import { useEffect } from "react";
import { LineLoader } from "../../index.styled";
function ImageAd() {
    const { overviewState: { bannerImageAds, projectDetails, isOnboardingPopupActive }, overviewDispatch } = useOverview()
    const { title, desc, button } = imageAdDetail
    const getImageBanners = (data) => {
        const config = {
            url: `/dashboard-overview/banner-image-ads/list`,
            method: "POST",
            data
        }
        return appClient(config)
    }
    const { mutate: getImageBannersMutate, isLoading } = useMutation(getImageBanners, {
        onSuccess: (data) => {
            overviewDispatch({
                type: "SET_BANNER_IMAGE_ADS",
                value: data
            })
        },
        onError: (e) => console.log(e)
    })

    useEffect(() => {
        if (projectDetails?.projectId)
            getImageBannersMutate({ projectId: projectDetails.projectId })
    }, [projectDetails?.projectId, isOnboardingPopupActive])

    
    if(isLoading || isOnboardingPopupActive){
        return(
            <LineLoader width={508} height={238} />
        )
    }
    return (
        <ImageAdBanner>
            {/* <VerticalSlider>
                <SliderItem src={template1} alt="t1" />
                <SliderItem src={template2} alt="t2" />
                <SliderItem src={template3} alt="t3" />
            </VerticalSlider> */}
            <VerticalSlider>
                {bannerImageAds && bannerImageAds.map((item) => {
                    return (
                        <SliderItem className="slider-item" key={item.id} dangerouslySetInnerHTML={{ __html: `${item.html}` }} ></SliderItem>
                    )
                })}
            </VerticalSlider>
            {/* {bannerImageAds && } */}
            <BannerDetails>
                <Title>{title}</Title>
                <Description>{desc}</Description>
                <Link to={ bannerImageAds ? `/adgenerator/image-ad/result?id=${bannerImageAds[0].campaignId}&fromOverview=true` : "/adgenerator/image-ad"} style={{ textDecoration: "none" }}>
                    <NavigateButton>{button}</NavigateButton>
                </Link>
            </BannerDetails>
        </ImageAdBanner>
    );
}

export default ImageAd;