import React from 'react'
import { DayTrend, DayTrendContainer, DayTrendWrapper, GoogleTrendWrapper, SkeletonWrap } from './index.styled'
import HeatMapChart from './HeatMapChart/index'
import GoogleTrends from "./GoogleTrends/index";
import Skeleton from "react-loading-skeleton";
import Tooltip from 'rc-tooltip';
import helpCircle from '../../../../../../media/Dashboard/help-circle.svg'

const Trend = ({keyword, isHeatMapDetailsLoading}) => {
 
  return (
    <DayTrendWrapper>
      <DayTrendContainer>
        <DayTrend>Day and Hour trend <Tooltip trigger={['hover']} overlayStyle={{ width: "100" }} overlay={<span>Hour trends of the ads found.</span>}>
          <img src={helpCircle} />
        </Tooltip></DayTrend>
        {isHeatMapDetailsLoading ? 
         <SkeletonWrap>
         <Skeleton height={"200px"}/>
       </SkeletonWrap>
        : <HeatMapChart isHeatMapDetailsLoading={isHeatMapDetailsLoading}/>}
      </DayTrendContainer>
      <GoogleTrendWrapper id="widget">
        <GoogleTrends
            type="TIMESERIES"
            keyword={keyword}
            url="https://ssl.gstatic.com/trends_nrtr/3620_RC01/embed_loader.js"
        />
      </GoogleTrendWrapper>
    </DayTrendWrapper>
  )
}

export default Trend

//<script type="text/javascript" src="https://ssl.gstatic.com/trends_nrtr/3620_RC01/embed_loader.js"></script> <script type="text/javascript"> trends.embed.renderExploreWidget("TIMESERIES", {"comparisonItem":[{"keyword":"apple coupon","geo":"","time":"now 1-d"}],"category":0,"property":""}, {"exploreQuery":"date=now%201-d&q=apple%20coupon&hl=en","guestPath":"https://trends.google.com:443/trends/embed/"}); </script>