import styled from "styled-components";

export const Wrapper = styled.div`
    width: 966px;
    height: 262px;
    border-radius: 16px;
    background: #FFF;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    padding: 24px;
    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #80808030;
        border-radius: 10px;     
    }
    gap: 20px;
`

export const KeywordContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    height: 40px;
    border-radius: 18px;
    border: 1px solid #E3E8EE;
    padding: 0 24px;
`;

export const Keyword = styled.div`
    color: rgba(28, 28, 28, 0.60);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: auto;
`;

export const Count = styled.div`
     padding: 0 10px;
    height: 24px;
    border-radius: 10px;
    background: #F2F2F2;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const NoDataContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
`