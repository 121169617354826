import { useState } from "react";
import { useTextAdGenerator } from "../../../../context/textAdGeneration";
import statics from "../../../../statics";
import { AdGoalPopup, GoalsContainer, Header, Goal, GoalContainer, GoalIcon, GoalIconContainer } from "./AdGoal.styled";
import Footer from "./Footer";

function AdGoalPP() {
    const { adGoals } = statics
    const { textAdGeneratorState, textAdGeneratorDispatch } = useTextAdGenerator()
    const [ popupSelectedGoal, setPopupSelectedGoal ] = useState(textAdGeneratorState.adGoal)

    const handlePopupGoalSelection = (goal) => {
        setPopupSelectedGoal(goal)
    }

    const saveSelectedGoal = () => {
        if(textAdGeneratorState.adGoal && textAdGeneratorState.adGoal?.id !== popupSelectedGoal.id)
        {
            textAdGeneratorDispatch({type: "SET_AD_GOAL", value: popupSelectedGoal})
            textAdGeneratorDispatch({type: "SET_AD_DETAILS_UPDATED", value: true})
        }
    }
    return ( 
        <AdGoalPopup>
            <Header>Define Ad goal</Header>
            <GoalsContainer>
                {adGoals.map((goal) => {
                    const isSelectedGoal = popupSelectedGoal?.id === goal.id
                    return(
                        <GoalContainer key={goal.id} onClick={() => handlePopupGoalSelection(goal)} isSelected={isSelectedGoal} >
                            <GoalIconContainer >
                                <GoalIcon src={ isSelectedGoal ? goal.activeIcon : goal.inActiveIcon} alt="goal" />
                            </GoalIconContainer>
                            <Goal isSelected={isSelectedGoal} >{goal.name}</Goal>
                        </GoalContainer>
                    )
                })}
            </GoalsContainer>
            <Footer popupSelectedGoal={popupSelectedGoal} />
        </AdGoalPopup>
     );
}

export default AdGoalPP;