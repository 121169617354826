import styled from "styled-components";

export const Card = styled.div`
    background: #FFFFFF;
    border: ${props => props.detailsOpen ? "2px solid #006ECC" : "1px solid #F2F2F2"} ;
    border-radius: 12px;
    width: 432px;
    height: 462px;
`;

export const VideoContainer = styled.div`
    height: 243px;
    border-radius: 12px 12px 0px 0px;
`;

export const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 16px;
    padding-bottom: 8px;
    .title-wrapper-span{
        font-size: 18px;
        color: #1A0DAB;
        line-height: 27px;
        margin-bottom: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        /* max-width: 420px; */
        font-weight: 400;
        
        display: flex;
        flex-wrap: wrap;
    }

    .title-highlight-bold{
        font-weight: 400;
        font-size: 18px;
        padding: 0px 3px;
    }

    .content-wrapper-span{
        font-size: 14px;
        color: #2E2E2E;
        line-height: 22px;
        /* margin-bottom: 8px; */
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2; 
        -webkit-box-orient: vertical;
        height: 44px;
        font-weight: 300;
        display: flex;
        flex-wrap: wrap;
    }

    .content-highlight-bold{
        color: #2E2E2E;
        font-weight: 600;
        font-size: 14px;
        padding: 0px 2px;
    }
    
`;

export const VideoTitle = styled.p`
    font-size: 16px;
    font-weight: 500;
    color: #2E2E2E;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 80%;
`;

export const ChannelDetails = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`;

export const LogoContainer = styled.div`
    height: 30px;
    img{
        height: 30px;
        width: 30px;
    }
`;

export const ChannelName = styled.p`
    margin-left: 8px;
    font-size: 14px;
    font-weight: 300;
    color: #050505;
`;

export const VideoDescription = styled.p`
    font-size: 14px;
    min-height: 44px;
    font-weight: 300;
    color: #050505;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

export const FloatingButtonsContainer = styled.div`
    position: absolute;
    right: 12px;
    top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F9F9F9;
    color: #D4D4D4;
    font-size: 8px;
    border-radius: 4px;
`;

export const CardFooter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: 1px solid #F2F2F2;
    padding: 16px;
`;

export const FooterLeft = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const AdDate = styled.div`
    display: flex;
    font-size: 14px;
    color: #202124;
    line-height: 22px;
    align-items: center;
    img{
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
`;

export const AdCountries = styled.div`
    display: flex;
    font-size: 14px;
    color: #202124;
    line-height: 22px;
    align-items: center;
    img{
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
`;

export const FooterRight = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
`;

export const PublishedOn = styled.div`
    color: #202124;
    font-size: 14px;
    label{
        color: #707070;
        font-size: 14px;
        font-weight: 300;
        margin-right: 5px;
    }
`;

export const DetailsButton = styled.div`
    border-radius: 4px;
    background: rgb(232, 242, 255);
    padding: 4px 12px;
    line-height: 22px;
    color: #006ECC;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;

`;


export const SelectButton = styled.input`
    height: 20px;
    width: 20px;
    cursor: pointer;
`;

export const AdTypeContainer = styled.div`
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
    width: max-content;
    position: absolute;
    top: -20px;
    left: 12px;
    padding: 6px;
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 5px;

    img{
        height: 12px;
    }
`;