import styled from "styled-components";

export const Container = styled.div`
    padding: 20px;
    width: 100%;
    overflow-y: auto;
    height: calc(100vh - 80px);

    h1{
        font-size: 18px;
        font-weight: 500;
        color: #2E2E2E;
        padding: 20px 4px;
        margin: 0;
        display: flex;
        align-items: center;
        span{
            color: #808080;
            font-weight: 300;
            text-decoration: underline;
            margin-left: 8px;
        }
    }

    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    gap: 40px;
`;

export const Button = styled.button`
    width: 75px;
    height: 40px;
    margin-left: 8px;

    border-radius: 8px;
    background-color: #FF711E;
    color: white;
    font-size: 14px;
    border: none;
    cursor: pointer;
    :hover {
        filter: brightness(90%);
    }
`;

export const SearchContainer = styled.div`
    display: flex;
`;

export const NoResultsMsg = styled.div`
    margin-left: 12px;
    color: darkgrey;
    font-weight: 300;
    text-decoration: none!important;
    font-size: 16px;
`;

export const InputContainer = styled.div`
    position: relative;
    height: 40px;
`;

export const Input = styled.input`
    width: 600px;
    height: 40px;
    background-color: white;
    border: 1px solid #ECECEC;
    border-radius: 8px;
    box-sizing: border-box;

    padding: 0 12px;
    outline: none;
    color: #2E2E2E;
    color: #2E2E2E;
    font-size: 14px;
    font-weight: 300;
    padding-right: 30px;
    border: ${props => props.error ? "1px solid #FF5180" : "1px solid #ECECEC"};
    outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};
    &:focus{
        border: ${props => props.error ? "1px solid #FF5180" : "1px solid #5AB3FF"};
        outline: ${props => props.error ? "2px solid #FFB7CB" : "2px solid #B9DDFF"};
    }
`;

export const ModeContainer = styled.div`
    /* width: 170px; */
    height: 100%;
    gap: 4px;

    /* background-color: white; */
    border-radius: 8px;
    /* border: 1px solid #ECECEC; */

    display: flex;
`;

export const KeywordButtonContainer = styled.div`
    height: 100%;
    border: none;
    /* background: none; */
    background: ${props => props.active ? "#006ECC" : "white"};
    color: ${props => props.active ? "white" : "#707070"};
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ECECEC;
    padding: 6px 12px;
    cursor: pointer;
    
    input[type=radio]
    {
        background: transparent;
        appearance: none;
        width: 100px;
        height: 30px;
        position: absolute;
    }

    input[type=radio] + label
    {
        cursor: pointer;
        position: relative;
        padding-left: 20px;
        line-height: 20px;
    }

    input[type=radio] + label::before
    {
        content: "";
        display: inline-block;
        width: 12px;
        aspect-ratio: 1;
        border: ${props => props.active ? "1px solid white" : "1px solid #E2E2E2"};
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        opacity: 1;
        transition: all 0.3s;
    }

    input[type=radio] + label::after
    {
    content: "";
        display: inline-block;
        width: 6px;
        aspect-ratio: 1;
        border: 1px solid #686de0;
        background: ${props => props.active ? "white" : "#006ECC"};
        border-radius: 50%;
        position: absolute;
        left: 3px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        transition: all 0.3s;
    }

    input[type=radio]:checked + label::after
    {
        opacity: 1;
    }
`;

export const ToolTipImageContainer = styled.div`
    position: absolute;
    right: 8px;
    top: 12px;
    display: ${props => props.error ? "flex": "none"};
    img{
        filter: contrast(1);
    }

    &:hover{
        > div{
            display: flex;
        }

        img{
            display: block;
        }
    }
`;

export const ToolTip = styled.div`
    background: #555555;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 300;
    position: absolute;
    bottom: -34px;
    padding: 4px 8px;
    width: 125px;
    display: none;
`;

export const BasicAnalysisContainer = styled.div`
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 32px;
    display: flex;
`;




export const AudienceStatsContainer = styled.div`
    display: flex;
`;

export const MapsContainer = styled.div`
    /* display: flex; */
    padding: 16px 0px 16px 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #F2F2F2;
    border-radius: 8px;
    h4{
        margin-bottom: 32px;
        color: #2E2E2E;
        font-size: 14px;
        font-weight: 400;
    }
`;

export const CountryChartContainer = styled.div`
    /* padding: 16px 20px; */
    background: #FFFFFF;
    width: fit-content;

`;


export const MapsContainerBody = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const PieChartContainer = styled.div`
    width: 381px;
    /* margin-left: 12px; */
    padding: 16px 0px 0px 20px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(242, 242, 242);
    h4{
        font-size: 14px;
        font-weight: 400;
        color: #2E2E2E;
    }
`;

export const PieChartAndTableContainer = styled.div`
    margin-top: 16px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
`;

export const TopKeywordsContainer = styled.div`
    width: 264px;
    height: 240px;
    /* margin-left: 12px; */
    padding: 16px 20px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(242, 242, 242);
`

export const TopKeywordHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    h4{
        font-size: 14px;
        color: #2E2E2E;
        font-weight: 400;
    }
`;

export const HeatMapContainer = styled.div`
    width: 286px;
    height: 240px;
    /* margin-left: 12px; */
    padding: 16px 20px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(242, 242, 242);
    h4{
        font-size: 14px;
        color: #2E2E2E;
        font-weight: 400;
        margin-bottom: 16px;
    }
`;


export const SearchAdStatsContainer = styled.div`
    margin-top: 32px;
    h2{
        color: #2E2E2E;
        font-size: 16px;
        font-weight: 500;
    }
`;


export const AdCopiesContainer = styled.div`
    margin-top: 32px;
    h2{
        color: #2E2E2E;
        font-size: 16px;
        font-weight: 500;
    }
`;

export const PlatformResults = styled.div`
    display: flex;
    gap: 24px;
    margin-top: 32px;
    h2{
        color: #2E2E2E;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 18px;
        margin-top: 24px;
    }
`;

export const ViewMoreAds = styled.div`
    margin: auto;
    width: 170px;
    height: 40px;
    margin-top: 16px;
    color: #0A66C2;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img{
        margin-left: 8px;
    }
`;

export const FunnelContainer = styled.div`
    width: auto;
    height: 396px;
    height: fit-content;
    position: relative;
    border-radius: 12px;

    p{
        font-size: 14px;
        color: #FFF;
        font-weight: 300;
        line-height: normal;
        text-align: center;
    }
`;

export const AdDataContainer = styled.div`
    padding: 20px 24px;
    background: #FFF;
    max-width: 1350px;
    width: 100%;
    h2{
        margin: 0;
    }
`;

export const FunnelList = styled.div`
    width: 275px;
    
`;

export const FunnelOption = styled.div`
    height: 78px;
    overflow: hidden;
    color: ${props => props.isActive ? "#FFF" : "#2E2E2E"};
    text-align: center;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    background: ${props => props.isActive ? "#006ECC" : "#FFF"};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    pointer-events: ${props => props.disabled ? "none" : "auto"};
    opacity: ${props => props.disabled ? "30%" : "100%"};
    &:nth-child(1){
        border-radius: 10px 10px 0px 0px;
    }

    &:nth-child(5){
        border-radius: 0px 0px 10px 10px;
    }

    &:nth-child(6){

    }

    span{
        border-radius: 15px;
        margin-left: 8px;
        padding: 3px 9px;
        min-width: 30px;
        color: ${props => props.isActive ? "#404040" : "#FFF"};
        background: ${props => props.isActive ? "#FFF" : "#404040"};
    }
`;

export const LeftWhiteBox = styled.img`
    position: absolute;
    top: 0;
    left: -2px;
    height: 390px;
    transform: scaleX(-1);
`;

export const RightWhiteBox = styled.img`
    position: absolute;
    top: 0;
    right: -2px;
    height: 390px;
`;

export const FunnelImg = styled.img`
    margin-bottom: 16px;
`;

export const ViewFunnelBtn = styled.div`
    border-radius: 8px;
    background: #0A66C2;
    font-size: 14px;
    color: #FFF;
    line-height: 20px;
    font-weight: 300;
    margin-top: 16px;
    padding: 6px 12px;
    cursor: pointer;
`;

export const FunnelWrap = styled.div`
    width: 196px;
    height: 396px;
    border-radius: 12px;
    background: #2E2E2E;
    padding: 80px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

`;

export const CloseFunnel = styled.div`
    border-radius: 8px;
    background: #ECECEC;
    font-size: 12px;
    font-weight: 300;
    color: #434343;
    line-height: normal;

    margin-top: 28px;
    width: fit-content;
    padding: 6px 12px;
    cursor: pointer;
`;

export const FunnelNavBar = styled.div`
    border-radius: 12px;
    border: 1px solid #F2F2F2;
    background: #F9F9F9;
    display: flex;
    height: 48px;
    margin-bottom: 24px;
`;

export const FunnelNavItem = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    border-radius: 12px;
    background: ${props => props.isSelected ? "#404040": "none"};
    color: ${props => props.isSelected ? "#FFF": "#404040"};

    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    pointer-events: ${props => props.disabled ? "none" : "auto"};
    opacity: ${props => props.disabled ? "30%" : "100%"};
`;

export const FunnelNavDivider = styled.div`
    height: 16px;
    width: 1px;
    background: ${props => props.isSelected ? "none": "#D0D0D0"};
    position: absolute;
    right: 0;
`;

export const FunnelDomain = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: #2E2E2E;
    line-height: normal;
    margin-bottom: 16px;
    span{
        color: #808080;
        font-size: 16px;
        font-weight: 300;
        line-height: normal;
        text-decoration: underline;
        margin-left: 8px;
    }
`;

export const AdSpaceContainer = styled.div`
    max-width: 1350px;
    width: 100%;
`;

