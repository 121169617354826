import styled from "styled-components";

export const Wrapper = styled.div`
    font-size: 18px;
    font-weight: 600;
    line-height: 20px; 
    h2{
        margin-bottom: 12px;
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        color: #1C1C1C;
    } 
`;

export const InputElement = styled.textarea`
    border-radius: 2px;
    border: solid 1px #E8E8E8;
    background: #F5F5F5;
    width: 100%;
    min-height: 138px;
    outline: none;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
`;