import { useState } from "react";
import { useAccount } from "../pages/AccountPages/context/account";
import { appClient } from "../../utils/axios-utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useAuth } from "../../context/auth";

const userPlan = () => {
  const config = {
    url: "/payment/user-info",
    method: "GET",
  };

  return appClient(config);
};

const UserCrawlRequest = async () => {
  const config = {
    url: "/account/crawl-requests",
    method: "GET",
  };

  return appClient(config);
};
const UserSummaryKeywords = async () => {
  const config = {
    url: "/account/keyword-searches",
    method: "GET",
  };

  return appClient(config);
};

const UserSimilarKeywords = async (data) => {
  const config = {
    url: "/keyword/related",
    method: "POST",
    data,
  };

  return appClient(config);
};

const UserKeywordsDomain = async (data) => {
  const config = {
    url: "/keyword/domains",
    method: "POST",
    data,
  };

  return appClient(config);
};

const changeAccountPassword = (data) => {
  const config = {
    url: "/auth/local/change-account-password",
    method: "POST",
    data,
  };

  return appClient(config);
};

const UserSearchHistory = async () => {
  const config = {
    url: "/account/search-history",
    method: "GET",
  };

  return appClient(config);
};

const UserSearchHistoryAdsCount = async (data) => {
  const config = {
    url: "/account/new-ads",
    method: "POST",
    data,
  };

  return appClient(config);
};

const getAllProjects = async (data) => {
  const config = {
    url: `/dashboard-overview/client-profile/get-projects`,
    method: "GET",
  };

  return appClient(config);
};

const renameProject = async (data) => {
  const config = {
    url: `/adgeneration/project/rename`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const deleteProject = async (data) => {
  const config = {
    url: `/adgeneration/project/remove`,
    method: "POST",
    data,
  };

  return appClient(config);
};
const PlansPricing = async () => {
  let currency = localStorage.getItem("usercurrency");
  const config = {
    url: "/payment/price/all",
    method: "POST",
    data: {
      currency,
    },
  };

  return appClient(config);
};


export const useGetAccount = () => {
  const { accountState, accountDispatch } = useAccount();
  const [errorMessage, setErrorMessage] = useState("");
  const [crawlRequest, setCrawlRequest] = useState([]);
  const { authState, authDispatch } = useAuth();

  const navigate = useNavigate();

  const { mutate: getUserAccountInfo, isLoading: isPlansDataLoading } =
    useMutation(userPlan, {
      onSuccess: (data) => {
        accountDispatch({ type: "UPDATE_USER_DATA", value: data });
        accountDispatch({ type: "UPDATE_IS_USER_DATA_LOADING", value: false });
      },
      onError: (err) => {
        setErrorMessage(err.response.data.errors[0].message);
      },
      onMutate: () => {},
    });

  const { mutate: getUserCrawlRequest, isLoading: isCrawlRequestLoading } =
    useMutation(UserCrawlRequest, {
      onSuccess: (data) => {
        setCrawlRequest(data);
      },
      onError: (err) => {},
    });

  const { mutate: getUserKeywordSummary, isLoading: isKeywordSummaryLoading } =
    useMutation(UserSummaryKeywords, {
      onSuccess: (data) => {
        accountDispatch({ type: "UPDATE_SUMMARY_KEYWORDS", value: data });
      },
      onError: (err) => {},
    });

  const {
    mutate: getUserSimilarKeywords,
    isLoading: isSimilarKeywordsLoading,
  } = useMutation(UserSimilarKeywords, {
    onSuccess: (data) => {
      accountDispatch({ type: "UPDATE_SIMILAR_KEYWORDS", value: data });
    },
    onError: (err) => {},
  });

  const {
    mutate: getUserKeywordsDomains,
    isLoading: isKeywordsDomainsLoading,
  } = useMutation(UserKeywordsDomain, {
    onSuccess: (data) => {
      accountDispatch({ type: "UPDATE_KEYWORD_DOMAINS", value: data });
    },
    onError: (err) => {},
  });

  const { mutate: onSubmit } = useMutation(changeAccountPassword, {
    onSuccess: (data) => {
      toast.success("Password updated succesfully");
      // toast.dismiss(toastId)
      accountDispatch({type: "SET_ENABLE_CHANGE_PASSWORD", value: false})
    },
    onError: (err) => {
      // console.log(err)
      setErrorMessage(err.response.data.errors[0].message);
      // toast.dismiss(toastId)
    },
    onMutate: () => {
      // toastId = toast.loading("Loading...")
    },
  });

  const {
    mutate: getUserSearchNewAds,
    isLoading: isSearchHistoryNewAdsLoading,
  } = useMutation(UserSearchHistoryAdsCount, {
    onSuccess: (data) => {
      accountDispatch({
        type: "UPDATE_SEARCH_HISTORY_NEWADS_COUNT",
        value: data,
      });


      const finalData = accountState.searchHistory.map((d) => ({
        ...d,
        newlyadded: data[d.id] ? Number(data[d.id]) : 0,
      }));


      accountDispatch({ type: "UPDATE_SEARCH_HISTORY", value: finalData });
    },
    onError: (err) => {},
  });

  const { mutate: getUserSearchHistory, isLoading: isSearchHistoryLoading } =
    useMutation(UserSearchHistory, {
      onSuccess: (data) => {
        if (data.length > 0) {
          accountDispatch({ type: "UPDATE_SEARCH_HISTORY", value: data });
          // getUserSearchNewAds({ keywords: data });
        }
      },
      onError: (err) => {},
    });

  const { mutate: getProjectsMutate, isLoading: isProjectsLoading } =
    useMutation(getAllProjects, {
      onSuccess: (data) => {
        authDispatch({ type: "UPDATE_PROJECT_COLLECTIONS", value: data.projects });
      },
      onError: (e) => {},
    });

  const { mutate: renameProjectMutate, isLoading: isRenameProjectLoading } =
    useMutation(renameProject, {
      onSuccess: (data) => {
        getProjectsMutate();
      },
      onError: (e) => {},
    });



  const { mutate: deleteProjectMutate, isLoading: isDeleteProjectLoading } =
    useMutation(deleteProject, {
      onSuccess: (data) => {
        getProjectsMutate();
      },
      onError: (e) => {},
    });

    const { mutate: plansBillingMutate, isLoading: isplansBillingLoading } =
    useMutation(PlansPricing, {
      onSuccess: (data) => {
        accountDispatch({ type: "SET_PLANS_LIST", value: data });

      },
      onError: (e) => {},
    });

  return {
    getUserAccountInfo,
    isPlansDataLoading,
    getUserCrawlRequest,
    isCrawlRequestLoading,
    crawlRequest,
    getUserKeywordSummary,
    isKeywordSummaryLoading,
    getUserSimilarKeywords,
    isSimilarKeywordsLoading,
    getUserKeywordsDomains,
    isKeywordsDomainsLoading,
    onSubmit,
    getUserSearchNewAds,
    getUserSearchHistory,
    isSearchHistoryNewAdsLoading,
    isProjectsLoading,
    renameProjectMutate,
    getProjectsMutate,
    deleteProjectMutate,
    plansBillingMutate,
    isplansBillingLoading
  };
};
