import React from "react";
import WarningIcon from "../../media/Dashboard/warning.svg"
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";
import GreenTickIcon from "../../media/Dashboard/GreenTick.svg"
import AiProcessedBanner1 from "../../media/Dashboard/AiProcessedBanner1.png"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const PopupContainer = styled.div`
    position: fixed;
    z-index: 3;

    background: #00000045;
    left: 0;
    /* top: 54px; */
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

`;

const PopupBody = styled.div`
    max-width: 750px;
    width: 90%;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px #0000003D;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 24px;
    position: relative;
    img{
        width: 26px;
        margin-bottom: 24px;
    }
    h2{
        margin-bottom: 16px;
        text-align: center;
        font-size: 18px;
        line-height: 26px
        font-weight: 500;
        color: #1C1C1C;
        max-width: 500px;       
    }
    p{
        margin-bottom: 30px;
        font-size: 14px;
        font-weight: 300;
        color: #4E4E4E;
        text-align: center;
        line-height: 21px;
    }
`;

const LogoutButton = styled.button`
    border: none;
    outline: none;
    background: none;
    position: absolute;
    right: 25px;
    top: 20px;
    color: #2E2E2E;
    font-size: 14px;
    font-weight: 300;
`;

const UpgradeButton = styled.button`
    width: 276px;
    height: 32px;
    margin-left: 8px;
    border-radius: 8px;
    background-color: rgb(255, 113, 30);
    color: white;
    font-size: 14px;
    border: none;
    cursor: pointer;
    margin-bottom: 8px;
`;

const AccountsButton = styled.button`
    width: 276px;
    height: 32px;
    margin-left: 8px;
    border-radius: 8px;
    color: #2E2E2E;
    font-size: 14px;
    border: none;
    cursor: pointer;
`;

const FeaturesContainer = styled.div`
    width: 100%;
    height: 100%;
    h3{
        color: rgba(28, 28, 28, 0.9);
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        margin-bottom: 20px;
        text-align: center;
    }

    .react-multi-carousel-list.container {
        width: 100%;
        height: 276px;
    }

    .react-multi-carousel{
        width: 702px;
    }

    .react-multi-carousel img{
        height: 100%;
        margin: auto;
        width: 100%;
        object-fit: contain;
    }

    .react-multi-carousel-track {
        height: 100%;
    }

    ul li img{
        object-fit: contain;
    }
`;

const AiProcessDoneModal = () => {
    const {authState, authDispatch} = useAuth()
    const navigate = useNavigate()

    return <PopupContainer>
        <PopupBody>
            <LogoutButton onClick={()=>{
                authDispatch({type: 'SET_SHOW_AI_PROCESSED', value: false})
            }}>
                close
            </LogoutButton>
            <img className="green-tick" src={GreenTickIcon}/>
            <h2>
                Setup Complete! Your Ad Personas and Results Are Fully 
                Optimized - Enhanced Features Unlocked
            </h2>
            <p>
                Consider subscribing to one of our higher plans to access the dashboard.
            </p>
            <FeaturesContainer>
                <h3>Explore Advanced Filters: Redirect to AdSpy search with advanced filtering options highlighted.</h3>
                <Carousel
                    additionalTransfrom={0}
                    arrows
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className=""
                    containerClass="container"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                        desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 1
                        },
                        mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1
                        },
                        tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 1
                        }
                    }}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    showDots
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                    >
                    <img
                        src={AiProcessedBanner1}
                        style={{
                        display: 'block',
                        height: '100%',
                        margin: 'auto',
                        width: '100%'
                        }}
                    />
                    <img
                        src="https://images.unsplash.com/photo-1549396535-c11d5c55b9df?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                        style={{
                        display: 'block',
                        height: '100%',
                        margin: 'auto',
                        width: '100%'
                        }}
                    />
                    <img
                        src="https://images.unsplash.com/photo-1550133730-695473e544be?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
                        style={{
                        display: 'block',
                        height: '100%',
                        margin: 'auto',
                        width: '100%'
                        }}
                    />
                    <img
                        src="https://images.unsplash.com/photo-1550167164-1b67c2be3973?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
                        style={{
                        display: 'block',
                        height: '100%',
                        margin: 'auto',
                        width: '100%'
                        }}
                    />
                </Carousel>
                {/* <img src={AiProcessedBanner1}/> */}
            </FeaturesContainer>
        </PopupBody>
    </PopupContainer>
}

export default AiProcessDoneModal