import React, { useEffect } from "react";
import {
  AddProject,
  DashboardHeadWrap,
  ProjectCreationWrap,
  ProjectName,
  ProjectNameWrap,
  ProjectInfoBtn,
  AdsWrap,
  ChartsContainer,
} from "./index.styled";
import infoImg from "./assets/info.svg";
import whitePlus from "./assets/whitePlus.svg";
import Statics from "./_static";
import ProjectDetails from "./components/ProjectDetails";
import TopAds from "./components/topAds";
import SelectedCountry from "../DashboardSetup/components/SelectedCountry";
import TopImageAds from "./components/TopImageAds";
import KeywordsTable from "../KeywordsTable";
import CompetitorsTable from "../CompetitorsTable";
import ContentList from "./components/topAds/components/contentList";
import KeywordVolumeChart from "../KeywordVolumeChart";
import CompetitorsChart from "../CompetitorsChart";
import { useGetUserInfo } from "../../../../../hooks/useGetUserInfo";
import { useAuth } from "../../../../../../context/auth";
import { useDashboard } from "../../../../../../context/dashboard";

const DashboardOverview = () => {
  const {
    projectData: { project },
    topAds: { text, image },
  } = Statics;

  const {
    dashboardState: { selectedProject },
    dashboardDispatch,
  } = useDashboard();

  const { authState: {plan} } = useAuth()
  
  const { getRemainingCreditsMutate } = useGetUserInfo()
  useEffect(() => {
    getRemainingCreditsMutate()
  }, [])

  useEffect(() => {
    console.log('plan useff', plan);
  }, [plan])

  return (
    <>
      <DashboardHeadWrap>
        <ProjectNameWrap>
          <ProjectName>
            {project} - <span>{new URL(selectedProject.website).hostname}</span>
          </ProjectName>
          <p>
            Access all your domain based overview, analytics and related
            keywords in one place
          </p>
        </ProjectNameWrap>
        <ProjectCreationWrap>
          {/* <SelectedCountry /> */}
          {/* <ProjectInfoBtn>
            <img src={infoImg} />
            <p>Project info</p>
          </ProjectInfoBtn> */}
          <AddProject
            onClick={() => {
              dashboardDispatch({
                type: "SET_POPUP_ACTIVE",
                value: true,
              })
            }}
          >
            <img src={whitePlus} />
            <p>Add a Project</p>
          </AddProject>
        </ProjectCreationWrap>
      </DashboardHeadWrap>
      <ProjectDetails />
      <AdsWrap>
        <TopAds headerLogo={text.headerLogo} headerText={text.headerText}>
          <ContentList />
        </TopAds>
        <TopAds
          headerLogo={image.headerLogo}
          headerText={image.headerText}
          isTextAd={false}
        >
          <TopImageAds />
        </TopAds>
      </AdsWrap>
      <ChartsContainer>
        <KeywordVolumeChart />
        <CompetitorsChart />
      </ChartsContainer>
      <KeywordsTable />
      <CompetitorsTable />
    </>
  );
};

export default DashboardOverview;
