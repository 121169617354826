import styled from "styled-components";

export const PopupContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(28, 28, 28, 0.60);
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
`;

export const Content = styled.div`
flex-shrink: 0;
border-radius: 10px;
background: #FFF;
position: absolute;
top: 124px;
right: 238px;
`;



