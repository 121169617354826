import React from "react";
import {
    CountyStatsTable,
    CountryStatsData,
    Progress
} from "./index.styled";
import { useAuth } from "../../../../../../context/auth";
// import { useUrlAnalysis } from "../../../context/urlanalysis";
import { countryList } from "./maps";
import Skeleton from 'react-loading-skeleton';
import { useDomainOverview } from '../../../context/domainoverview'

const MapChartTable = ({ isDataLoading }) => { 
    // const { urlAnalysisState, urlAnalysisDispatch  } = useUrlAnalysis()
    const {domainOverviewState, domainOverviewDispatch} = useDomainOverview()

  const { authState } = useAuth();

    const getFlagEmoji = (countryName) => {
        let countryCode = countryList[countryName]
        if(countryCode){
            const codePoints = countryCode
            .toUpperCase()
            .split('')
            .map(char =>  127397 + char.charCodeAt());
        return String.fromCodePoint(...codePoints);
        }
        
    }

let adsByCountryData = domainOverviewState?.domainMapData?.countriesPercentage

//   let adsByCountryData = [
//     {
//         country: "United States",
//         percentage: "73",
//         countryCode: "US"
//     },
//     {
//         country: "India",
//         percentage: "7.5",
//         countryCode: "IN"
//     },
//     {
//         country: "Japan",
//         percentage: "6.2",
//         countryCode: "JP"
//     },
//     {
//         country: "United Kingdom",
//         percentage: "4.1",
//         countryCode: "UK"
//     },
//     {
//         country: "China",
//         percentage: "3.1",
//         countryCode: "CN"
//     }
//     ]

  return (isDataLoading  ? 
    <CountyStatsTable>
    <Skeleton style={{
         lineHeight: 13,
        // width: 610,
      }}/>
      </CountyStatsTable> : 
    <CountyStatsTable>
        {/* <HeadNames>
            <p>
                Countries
            </p>
            <p>
                Ads share
            </p>
        </HeadNames> */}
        {/* <AllHeadCount>
            <p>
                Countries
            </p>
            <Progress value="100" max="100"/>
            <p className="percentage">
                Ads share
            </p>
        </AllHeadCount> */}
        {adsByCountryData?.length > 1 && adsByCountryData?.map((country, idx)=>{
            return <CountryStatsData>
                <p>
                    <span>{country.country && getFlagEmoji(country.key)}</span>{country.key}
                </p>
                <Progress value={country.percentage.replace("%","")} max="100"/>
                <p className="percentage">
                    {country.percentage}
                </p>
            </CountryStatsData>
        })}
    </CountyStatsTable>
  );
};

export default React.memo(MapChartTable);




