import { countryNewData } from "./countryData";

export function GetCountryData(countryName){
    console.log(countryName)
    let filteredCountry = countryNewData.filter((data) => {
        return (countryName == data.adspyder_name)
    })

    return filteredCountry

}