import styled from "styled-components";

export const AdDistributionWrap = styled.div`
  width: 966px;
  height: 303px;
  border-radius: 16px;
  background: #FFF;
  padding: 24px;
  margin-top: 16px;
  max-width: 100%;
`
export const DoughnutWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`
export const DoughnutContainer = styled.div`
  width: 174px;
  height: 174px;
`
export const AdDistributionDiv = styled.div`
  color: #7D7D7D;
  font-size: 14px;
         display: flex;
        align-items: center;
        gap: 10px;
  font-weight: 400;
`
export const AdDistributionContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;

`
export const AdProgressWrap = styled.div`
  width: 50%;
  height: 85%;
  padding-left: 20px;
  
`
export const AdProgressDiv = styled.div`
display: flex;
justify-content: space-between;
height: 39px;
align-items: center;
border-bottom: solid 1px #E3E8EE;
 /* progress {
      width: 105px; 
      height: 15px;
    } */
`
export const AdProgressName = styled.div`
  width: 50%;
  font-size: 14px;
  font-weight: 400;
  color: #1C1C1CCC;
`
export const AdProgressBar = styled.div`
display: flex;
column-gap: 16px;
width: 50%;
align-items: center;
`
export const AdProgressPercentage = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #1C1C1CB2;
  width: 24%;
`
export const AdGradeWrap = styled.div`
color: var(--Light-Green, #25CD25);
font-family: Inter;
font-size: 14px;
font-weight: 400;
display: flex;
`
export const GoogleProgress = styled.progress`
accent-color: ${props => props.color};
inline-size: 6.6em ;
  height: 5px;
        appearance: none;
        border: none;
        /* background-color: #ddd; */
        border-radius: 5px;
        /* accent-color: #FFC78A;
        inline-size: 6.6em; */
        &::-webkit-progress-bar {
            background-color: #ddd;
            border-radius: 10px;
            height: 8px;
        }

        &::-webkit-progress-value {
            background-color: ${props => props.color};
            border-radius: 10px;
            height: 8px;
        }

        &::-moz-progress-bar {
            /* background-color: #3498db; */
            border-radius: 10px;
            height: 8px;
        }

        &::-ms-fill {
            /* background-color: #3498db; */
            border-radius: 10px;
            height: 8px;
        }

`

