import React from "react";
import { Container} from "./index.styled"
import { useAdIntegration } from "../context/AdManagementContext";
import AdCampaignInput from "./components/AdCampaignInput";
import AdDetailsInput from "./components/AdDetailsInput";

const NewCampaign = () => {
    
    const {
        newAdManagementState,
        newAdManagementDispatch,
      } = useAdIntegration();

    const renderContent = () => {
        switch (newAdManagementState.currentCampaignStep){
            case 1:
            return <AdCampaignInput />

            case 2:
            return <AdDetailsInput />

            default:
            return

        }
    }
    
    return <Container>
        {renderContent()}
    </Container>

}

export default NewCampaign