import styled from "styled-components";

export const TargetAudienceContainer = styled.div`
width: 738px;
height: 246px;
flex-shrink: 0;
border-radius: 10px;
background: #FFF;
padding: 24px 32px;
`;

export const Item = styled.div`
    margin-bottom: 35px;
`;

export const Label = styled.div`
color: #1E1E1E;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 133.333% */
letter-spacing: 0.15px;
margin-bottom: 16px;
`;

export const ChoiceList = styled.div`
    display: flex;
    gap: 12px;
`;

export const Choice = styled.div`
padding: 10px 24px;
color:${(props) => props.isSelected ? "#734A00" : "#000"};
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
height: 37px;
border-radius: 40px;
background: ${(props) => props.isSelected ? "#FFF7E8" : "#F2F2F2"};
cursor: pointer;
border: ${props => props.isSelected ? "solid 1px rgba(115, 74, 0, 0.10)": "solid 1px #FFF"}
`;
