import { useEffect, useRef, useState } from "react"
import { useTextAdGenerator } from "../../../../context/textAdGeneration"
import { AddIcon, Country, CountryContainer, InputContainer, KeywordInput, LocationInputContainer, LocationSelectContainer, LocationSuggestionContainer, SearchIcon, SelectedLocationItem, SelectedLocationList, SelectedLocationsConatiner, SuggestionContainer, SuggestionElement, SuggestionLabel, TargetLocationContainer } from "./TargetLocation.styled";
import add from "../../../../assets/add.svg"
import searchIcon from "../../../../assets/search.svg"
import closeIcon from "../../../../../../../../media/Dashboard/CloseDark.svg"

import { useMutation } from "@tanstack/react-query";
import { appClient } from "../../../../../../../../utils/axios-utils";
import Footer from "./Footer";

function TargetLocationPP() {
    const locationRef = useRef(null)
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [inputFocussed, setInputFocussed] = useState(false)

    const getLocationSuggestion = async (data) => {
        const config = {
            url: `/admanagement/get-location/${data.query}`,
            method: "GET"
        }

        return appClient(config)
    }
    const { mutate: getLocationSuggestionMutate, isLoading: isGetingLocationSuggestion } = useMutation(getLocationSuggestion, {
        onSuccess: (data) => {
          setSuggestions(data)
        },
        onError: (err) => {
            // setSuggestions(suggestionLocationData.slice(0,locationSearchInput.length < 6 ? locationSearchInput.length : 6))
        }
    })

    const { textAdGeneratorState: {updatedTargetLocation}, textAdGeneratorDispatch } = useTextAdGenerator()

    const countrySuggestion = () => {
      const countries = [{
          "name": "Germany",
          "criteriaId": "2276"
        },  {
          "name": "Canada",
          "criteriaId": "2124"
        },
      ]
      const handleCountrySelection = (country) => {
        const isCountrySelected = updatedTargetLocation.some(
            (location) => location.name === country.name
          );
        if(!isCountrySelected){
          setInputValue('');
          setSuggestions([]);
        //   setEnteredCountry([...updatedTargetLocation, country.name])
          textAdGeneratorDispatch({
              type: 'SET_UPDATED_TARGET_LOCATION',
              value: [...updatedTargetLocation, country.name],
            });
        }else{

        }
      } 
      return countries.map((country) => {
          return (
              <CountryContainer onClick={() => handleCountrySelection(country)} >
                  <Country>{country.name}</Country>
                  <AddIcon src={add} alt="add" />
              </CountryContainer>
          )
      })
    }

    const handleInputChange = (e) => {
        const value = e.target.value.toLowerCase();
        setInputValue(value);
    
        if (value.length >= 3) {
          // const filteredSuggestions = countryArray.filter((country) =>
          //   country.country.toLowerCase().includes(value)
          // );
          // setSuggestions(filteredSuggestions);
          getLocationSuggestionMutate({query: value})
        } else {
          setSuggestions([]);
        }
    };

    const handleSuggestionClick = (country) => {
        const isAlreadySelected = updatedTargetLocation.some(
            (location) => location.name === country.name
          );
          if (!isAlreadySelected) {
            setInputValue('');
            setSuggestions([]);
            // setEnteredCountry([...updatedTargetLocation, country.name])
            textAdGeneratorDispatch({
              type: 'SET_UPDATED_TARGET_LOCATION',
              value: [...updatedTargetLocation, country.name],
            });
          } else {
          }
      };
      const handleRemoveLocation = (location) => {
        const filteredList = updatedTargetLocation.filter(
          (loc) => loc !== location
        );
        // setEnteredCountry(filteredList)
        textAdGeneratorDispatch({
            type: 'SET_UPDATED_TARGET_LOCATION',
            value: filteredList,
        });
      };

    const handleTargetLocationInputChange = () => {
        // setEnteredCountry(locationRef.current.value)
    }

    useEffect(() => {
        if(locationRef.current){
            locationRef.current.value = updatedTargetLocation
        }
    }, [])

    return ( 
        <TargetLocationContainer>
            <LocationSelectContainer>
              <label>Location</label>
              <LocationInputContainer>
                <InputContainer isFocussed={inputFocussed} >
                    <SearchIcon src={searchIcon} alt="Search" />
                    <KeywordInput
                        ref={locationRef}
                        value={inputValue}
                        placeholder="Search city, state or country"
                        onChange={handleInputChange}
                        onFocus={() => setInputFocussed(true)}
                        onBlur={() => setInputFocussed(false)}
                    />
                </InputContainer>
                {inputValue.length > 0 && 
                  <LocationSuggestionContainer>
                    {inputValue.length < 3 
                      ? <SuggestionElement>Enter atleast 3 characters to show suggestion</SuggestionElement>
                      : suggestions.length !== 0
                        ?
                          suggestions.map((suggestion) => (
                            <SuggestionElement
                              key={suggestion.criteriaId}
                              onClick={() => handleSuggestionClick(suggestion)}
                            >
                              {suggestion.name}
                            </SuggestionElement>
                          ))
                        : !isGetingLocationSuggestion && <SuggestionElement>No results found</SuggestionElement>
                    }
                  </LocationSuggestionContainer>
                }
              </LocationInputContainer>

              {updatedTargetLocation.length > 0 && (
                <SelectedLocationsConatiner>
                  <label>Selected locations:</label>
                  <SelectedLocationList>
                    {updatedTargetLocation.map((location) => (
                      <SelectedLocationItem key={location}>
                        {location}{' '}
                        <img
                          src={closeIcon}
                          onClick={() => handleRemoveLocation(location)}
                        />
                      </SelectedLocationItem>
                    ))}
                  </SelectedLocationList>
                </SelectedLocationsConatiner>
              )}
            </LocationSelectContainer>
            {!updatedTargetLocation.length && (
              <SuggestionContainer>
                <SuggestionLabel>Suggestions:</SuggestionLabel>
                {countrySuggestion()}
            </SuggestionContainer>
            )}
            <Footer />
        </TargetLocationContainer>
     );
}

export default TargetLocationPP;