import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    background: #EAEAEC;
    width: 100%;
    height: 100%;
`;

export const LeftNav = styled.div`
    display: flex;
    width: 40%;
    max-width: 486px;
`;

export const Content = styled.div`
    width: 70%;
`;

export const NavList = styled.div`
    /* width: 86px; */
    min-width: 86px;
    height: calc(100vh - 54px);
    background: #242424;

`;

export const NavItem = styled.div`
    display: flex;
    flex-direction: column;
    color: ${props => props.isActive ? "#FFF" : "#D3D3D3"};
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    line-height: normal;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90px;
    background: ${props => props.isActive ? "#FF711E": "#242424"};
    img{
        height: 24px;
        margin-bottom: 6px;
        filter:  ${props => props.isActive ? "brightness(0) invert(1)": "none"};
    }
`;

export const PromptInput = styled.div`
    width: calc(100% - 86px);
    min-width: 250px;
    background: #FFF;
    h2{
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        color: #080808;
        text-align: center;
        background: #F7F7F9;
        padding: 10px;
    }
    h4{
        color: #555;
        font-size: 14px;
        font-weight: 500;
        margin: 14px 0px;
        line-height: normal;
    }
`;

export const ContentConatiner = styled.div`
    padding: 30px 32px;
    height: calc(100vh - 186px);
    overflow-y: auto;
    border-bottom: 1px solid #DFDFDF;

    h3{
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #888;
    }

    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const InputContainer = styled.div`
    margin-top: 24px;
`;

export const InputName = styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: #2E2E2E;
`;

export const InputField = styled.input`
    background: #FFFFFF;
    border: ${props => props.error ? "1px solid #FF5180" : "1px solid #ECECEC"};
    outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};
    border-radius: 8px;
    width: 100%;
    height: 40px;
    margin-top: 8px;
    padding: 9px 12px;
    font-weight: 300;
    color: ${props => props.theme.colors.lightBlack};
    &:focus{
        border: ${props => props.error ? "1px solid #FF5180" : "1px solid #5AB3FF"};
        outline: ${props => props.error ? "2px solid #FFB7CB" : "2px solid #B9DDFF"};
    }
`;

export const InputTextArea = styled.textarea`
    resize: none;
    height: 120px;
    background: #FFFFFF;
    border: ${props => props.error ? "1px solid #FF5180" : "1px solid #ECECEC"};
    outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};
    border-radius: 8px;
    width: 100%;
    /* height: 40px; */
    margin-top: 8px;
    padding: 9px 12px;
    font-weight: 300;
    color: ${props => props.theme.colors.lightBlack};
    &:focus{
        border: ${props => props.error ? "1px solid #FF5180" : "1px solid #5AB3FF"};
        outline: ${props => props.error ? "2px solid #FFB7CB" : "2px solid #B9DDFF"};
    }
`;

export const ParallelInputs = styled.div`
    display: flex;
    gap: 24px;
`;

export const Footer = styled.div`

`;

export const GenerateAdButton = styled.div`
    margin: 29px 32px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;

    background: ${props => props.isDisabled ? "#CDCDCD" : "#FF711E"};
    border-radius: 8px;
    outline: none;
    border: none;
    color: ${props => props.theme.colors.white};
    font-size: 16px;

    pointer-events: ${props => props.isDisabled ? "none" : "auto"};

    &:hover{
        background: ${props => props.isDisabled ? "#CDCDCD" : "#E75500"};
    }

    &:active{
        background: ${props => props.isDisabled ? "#CDCDCD" : "#C74A00"};
    }
`;

export const SelectTypeButton = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: #757575;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid rgba(64, 87, 109, 0.09);
    background: #F7F7F9;
    margin-top: 24px;
    cursor: pointer;

`;

export const Divider = styled.div`
    text-align: center;
    border-bottom: solid 1px #40576D17;
    height: 10px;
    margin: 18px 0px;
    span{
        background: #FFF;
        padding: 0px 10px;
    }
`;

export const Project = styled.div`
    display: flex;
    border-radius: 8px;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 18px;
    img{
        margin-right: 20px;
    }
`;

export const ProjectContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;

    h3{
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #535455;
    }
    span{
        font-size: 14px;
        line-height: 20px;
        color: #A4A4A4;
        font-weight: 400;
    }
`;

export const ProjectListContainer = styled.div`
    overflow-y: auto;
    max-height: 58vh;
    height: 65%;
    span{
        text-align: center;
        display: flex;
        justify-content: center;
    }

    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const AdContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    width: 70%;
    margin: auto;
    margin-top: 10%;

`;

export const HomeContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    width: 80%;
    margin: auto;
    max-width: 740px;
`;

export const CreativeContainer = styled.div`
    display: flex;
    gap: 15%;
    margin-bottom: 70px;

    h2{
        font-size: 24px;
        font-weight: 600;
        line-height: 38px;
        color: #080808;
    }
    span{
        font-size: 24px;
        font-weight: 600;
        line-height: 38px;
        color: #FF711E;
    }
    p{
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        color: #6F6F6F;
        margin-top: 16px;
    }
`;

export const LeftCreative = styled.div`
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;

`;

export const StepsContainer = styled.div`
    h2{
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        text-align: center;
        color: #434343;
        margin-bottom: 30px;
    }
`;

export const StepsList = styled.div`
    display: flex;
    gap: 14px;
`;

export const Step = styled.div`
    max-width: 160px;
    padding: 18px 23px;
    border-radius: 14px;
    border: 1px solid #D7D7D7;
    box-shadow: -3px -3px 0px 3px #D7D7D7;
    div{
        margin-bottom: 10px;
        font-size: 13px;
        font-weight: 400;
        line-height: normal;
        color: #969696;
        width: 24px;
        height: 24px;
        background: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
    }
    h2{
        text-align: start;
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
        color: #515151;
        margin-bottom: 4px;
    }
    p{
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: #898989;
    }
    
`;


export const ResultWrapper = styled.div`
    width: 100%;
    h2{
        padding: 18px 24px;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: #080808;
        border-bottom: 1px solid #ECECEC;
    }
    height: 70%;
    border-radius: 5px;
    background: #FFF;
`;

export const GeneratedAdsContainer = styled.div`
    padding: 20px 27px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: calc(100% - 80px);
    overflow-y: auto;

    .loader{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    h3{
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
        color: #8F8F8F;
    }

    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;


export const GeneratedAdCard = styled.div`
    background: rgb(255, 255, 255);
    border: 1px solid rgb(242, 242, 242);
    border-radius: 12px;
`;


export const GeneratedAdTitle = styled.p`
    padding: 10px 13px;
    font-size: 18px;
    color: rgb(26, 13, 171);
    line-height: 27px;
    font-weight: 400;
`;


export const GeneratedAdDescription = styled.p`
    padding: 0px 13px;
    font-size: 14px;
    color: rgb(46, 46, 46);
    line-height: 22px;
    font-weight: 300;
`;

export const GeneratedAdCardFooter = styled.div`
    margin-top: 26px;
    border-top: solid 1px #ECECEC;
    padding: 13px;

`;

export const SaveAd = styled.div`
    border-radius: 4px;
    border:  ${props => props.saved ? "1px solid #ECECEC" : "1px solid #ECECEC"};
    background: #FFF;
    padding: 3px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #2E2E2E;
    display: flex;
    justify-content: center;
    width: max-content;
    cursor: pointer;
    filter: ${props => props.saved ? "invert(0.1)": "none"};

    pointer-events: ${props => props.saved ? "none": "auto"};
    img{
        margin-right: 6px;
    }
`;