import React, { useState, useEffect, useRef } from 'react'
import {  Wrapper, Down, Row, DropDownImage, DropdownContainer, DropdownButton  } from './index.styled'

import UpArrow from "../../../../../../../../media/Dashboard/uparrow.png"
import DownArrow from "../../../../../../../../media/Dashboard/downarrow.png"

import { useAuth } from '../../../../../../../../context/auth'

function useOutsideAlerter(ref, setShow) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setShow(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref])
}

const UrlSearchType = () => {
    const { authState, authDispatch } = useAuth()
    const wrapperRef = useRef(null)
    const [show, setShow] = useState(false)

    let tempSortTypes = [{
        name:"Exact",
        type: 'exact'
    }, {
        name:"Domain",
        type: 'domain'
    }, {
        name:"Text",
        type: 'text'
    }];

    useOutsideAlerter(wrapperRef, setShow)

    return <Wrapper>
    {/* <ToolTipContainer>
        Input type <Tooltip placement="right" trigger={['hover']} overlay={<span>Choose among Full URL, Domain, and Text input types for URL search mode.</span>}>
            <img src={ToolTipIcon}/>
        </Tooltip>
    </ToolTipContainer> */}
    {/* <UrlSearchTypeContainer>
      <UrlButtonContainer
        active={authState.urlSearchType === "exact"} 
        onClick={() =>  authDispatch({ type: "SET_URL_SEARCHTYPE", value: "exact" })}>
        <input type="radio" checked={authState.urlSearchType === "exact" && "checked"} id="exact" name="searchtype"/>
        <label for="exact">Exact</label>
    </UrlButtonContainer>
    <UrlButtonContainer
        active={authState.urlSearchType === "domain"} 
        onClick={() =>  authDispatch({ type: "SET_URL_SEARCHTYPE", value: "domain" })}>
        <input type="radio" checked={authState.urlSearchType === "domain" && "checked"} id="domain" name="searchtype"/>
        <label for="domain">Domain</label>
    </UrlButtonContainer>
    <UrlButtonContainer 
        active={authState.urlSearchType === "text"} 
        onClick={() =>  authDispatch({ type: "SET_URL_SEARCHTYPE", value: "text" })}>
        <input type="radio" checked={authState.urlSearchType === "text" && "checked"} id="text" name="searchtype"/>
        <label for="text">Text</label>
    </UrlButtonContainer>
    </UrlSearchTypeContainer> */}
        <DropdownContainer 
            ref={wrapperRef}>

            <DropdownButton onClick={() => setShow(prev => !prev)}
                >
                <span>{
                    authState.urlSearchType
                }</span>
                {
                    <DropDownImage><img src={
                        show ? UpArrow : DownArrow
                    }/></DropDownImage>
                }
            </DropdownButton>

            <Down show={show}>
                {tempSortTypes.map((keyword, index) => {
                    return <Row isSelected={authState.urlSearchType == keyword.name} key={index} onClick={() => {
                        setShow(false)
                        authDispatch({ type: "SET_URL_SEARCHTYPE", value: keyword.type })
                    }}>
                        <p>{keyword.name}</p>
                    </Row>
                })}
            </Down>
        </DropdownContainer>
</Wrapper>
}



export default UrlSearchType