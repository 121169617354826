import React, { useRef, useState } from "react";
import CloseIcon from "../../../../../../media/Dashboard/detailsPaneClose.svg"
import { PopupContainer, PopupBody, LogoutButton, KeywordsListContainer, KeywordInputContainer, Error, Footer, SetupTrackingButton, ErrorBox } from "./index.styled"
import { useNavigate } from "react-router-dom";
import { useLandingPageAnalysis } from "../../../context/landingPageAnalysis";
import PlatformDropDown from "./components/PlatformDropDown"

import { getNumberDateFromInput } from '../../../../../../utils/dateFormat';
import ValidateAndAddHttps from "../../../../../../utils/urlValidate";
import { ErrorIcon, HttpsLabel, InputContainer, PlaceholderWithInput, RightIcons, UrlInput } from "../../index.styled";
import errorIcon from "../../assets/errorIcon.svg"

function isValidDomain(url) {
    console.log(url)
    const domainAndUrlRegEx = /^(((http|https):\/\/|)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?)$/;

    return domainAndUrlRegEx.test(url);
}

const KeywordInputModal = () => {
    const {landingPageAnalysisState, landingPageAnalysisDispatch} = useLandingPageAnalysis()
    const navigate = useNavigate()
    const keywordsDiv = useRef(null);

    const [focussedInputIndex, setFocussedInputIndex] = useState(null)

    const updateUrlData = (idx,inputDate) => {
        console.log('dx,inputDatedx,inputDate')
        const tempUrlList = landingPageAnalysisState.urlInputList.map((data, id) => {
            if (id === idx) {
              // Update the region property for the specific country
              return { ...data, event: inputDate }
            }
            return data;
          });

          landingPageAnalysisDispatch({ type: 'SET_URL_INPUT_LIST', value: tempUrlList }) 
    }

    const onUrlInput = (e,index) => {
        let { value } = e.target

        console.log('changing triggered', value)
        if(value.includes("https://")){
            if(value.includes("www.")){
                value = value
            }else{
                value = `https://www.${value.replace("https://","")}`
            }
        }else{
            if(value.includes("www.")){
                value = `https://${value}`
            }else{
                value = `https://www.${value}`
            }
        }
        
        // const tempurlInputList = landingPageAnalysisState.urlInputList
        const tempurlInputList = landingPageAnalysisState.urlInputList.map((d,id) => {
            if (id === index) {
              // Update the region property for the specific country
              return { ...d, url: value };
            }
            return d;
        });

        e.target.value = e.target.value.startsWith("https://") ? e.target.value.split("https://")[1] : e.target.value

        console.log(e.target.value)
        landingPageAnalysisDispatch({
            type: 'SET_URL_INPUT_ERROR_OBJ', value: {
                ...landingPageAnalysisState.urlInputErrorsObj, [index]: {
                    status: !isValidDomain(e.target.value),
                    message: !isValidDomain(e.target.value) ? 'Enter a valid url' : ''
                }
            }
        })

        landingPageAnalysisDispatch({type: 'SET_URL_INPUT_LIST', value: tempurlInputList})
    }

    const fetchScreenshotFn = () => {
        if (landingPageAnalysisState.urlInputList) {
            console.log(landingPageAnalysisState.urlInputList)

            landingPageAnalysisState.urlInputList.map((item,idx) => {
                if (item.url !== '' && item.event.date !== ('' || undefined)) {
                    let finalUrl = item.url

                    if(finalUrl.includes("https://")){
                        if(finalUrl.includes("www.")){
                            finalUrl = finalUrl
                        }else{
                            finalUrl = `https://www.${finalUrl.replace("https://","")}`
                        }
                    }else{
                        if(finalUrl.includes("www.")){
                            finalUrl = `https://${finalUrl}`
                        }else{
                            finalUrl = `https://www.${finalUrl}`
                        }
                    }

                    if(isValidDomain(item.url)){
                        landingPageAnalysisDispatch({ type: 'SET_URL_INPUT_LIST_ERROR', value: '' });
                        landingPageAnalysisDispatch({ type: 'SET_LANDING_TABLE_OPEN', value: true });
                        navigate(`/landing-page-analysis/details?url=${finalUrl}&date=${getNumberDateFromInput(item.event.date)}`)
                    }else{
                        landingPageAnalysisDispatch({ type: 'SET_URL_INPUT_LIST_ERROR', value: 'Invalid URL' });
                    }
                } 
                else {
                    landingPageAnalysisDispatch({ type: 'SET_URL_INPUT_LIST_ERROR', value: 'Please Fill the Empty Section' });
                    if(item.url === ""){
                        landingPageAnalysisDispatch({
                            type: 'SET_URL_INPUT_ERROR_OBJ', value: {
                                ...landingPageAnalysisState.urlInputErrorsObj, [idx]: {
                                    status: true,
                                    message: 'Enter a url'
                                }
                            }
                        })
                    }
                }              
            });
        }
    }
    const eventEnterHandle = (e) =>{
        if (e.key === 'Enter') {
            fetchScreenshotFn();
        }
    }
    return <PopupContainer>
        <PopupBody>
        {/* temp change  */}
            <LogoutButton onClick={()=>landingPageAnalysisDispatch({type: 'SET_URL_INPUT_MODAL_OPEN', value: false})}>
                <img src={CloseIcon}/>
            </LogoutButton>
            <h2>Event based Selection</h2>
            <KeywordsListContainer ref={keywordsDiv}>
                {
                    landingPageAnalysisState.urlInputList.map((url, idx)=> {
                        return (
                            <div>
                                <KeywordInputContainer error={landingPageAnalysisState.urlInputErrorsObj[idx]?.status ? true : false}>
                                    <PlaceholderWithInput>
                                        <HttpsLabel>https://</HttpsLabel>
                                        <InputContainer isFocussed={focussedInputIndex === idx} hasError={landingPageAnalysisState.urlInputErrorsObj[idx]?.status ? true : false}>
                                            <UrlInput
                                                placeholder="Add a Url"
                                                defaultValue={url.url.startsWith("https://") ? url.url.split("https://")[1] : url.url}
                                                onChange={(e)=>onUrlInput(e, idx)}
                                                onKeyDown={(e) => {
                                                    eventEnterHandle(e)
                                                }}   
                                                onFocus={() => setFocussedInputIndex(idx)}
                                                onBlur={() => setFocussedInputIndex(null)}
                                            />
                                            <RightIcons>
                                                {landingPageAnalysisState.urlInputErrorsObj[idx]?.status && <ErrorIcon src={errorIcon} alt=""/>}
                                            </RightIcons>
                                            
                                        </InputContainer>
                                    </PlaceholderWithInput>
                                    
                                    <PlatformDropDown idx={idx} urlData={url} updateUrlData={updateUrlData}/>
                                </KeywordInputContainer>
                                {landingPageAnalysisState.urlInputErrorsObj[idx]?.status && <ErrorBox>{landingPageAnalysisState.urlInputErrorsObj[idx].message}</ErrorBox>}
                            </div>
                        )
                    })
                }
            </KeywordsListContainer>
            <Error>{landingPageAnalysisState.urlInputListError ? landingPageAnalysisState.urlInputListError : ""}</Error>
            <Footer>
                <SetupTrackingButton onClick={fetchScreenshotFn}>
                    Fetch Screenshot
                </SetupTrackingButton>
            </Footer>
        </PopupBody>
    </PopupContainer>
}

export default KeywordInputModal