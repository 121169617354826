import React from 'react'
import { useSearchParams } from 'react-router-dom'


// Styles
import {
    PaginationContainer
} from "./index.styled"

import {useReddit} from "../../context/reddit"
import ReactPaginate from 'react-paginate';

const Pagination = () => {
    const {redditState, redditDispatch} = useReddit()
    const [searchParams, setSearchParams] = useSearchParams()

    const handleSpecificPage = (e) => {
        redditDispatch({type:'SET_AD_DETAILS', value: {}})
        redditDispatch({type:'UPDATE_DETAILS_MODAL', value: false})
        redditDispatch({ type: "SET_CURRENT_PAGE_NO", value: e.selected + 1 })
        searchParams.set("page", e.selected + 1)
        setSearchParams(searchParams)
    }
    
    return <PaginationContainer 
    prevState={redditState.currentPageNo == 1}
    nextState={redditState.currentPageNo == Math.ceil((redditState.totalAds / 20))}>
        <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            forcePage={redditState.currentPageNo - 1}
            onPageChange={handleSpecificPage}
            pageRangeDisplayed={3}
            pageCount={(redditState.totalAds / 20)}
            previousLabel="<"
            renderOnZeroPageCount={null}
        />
  </PaginationContainer>

}

export default Pagination
