import { appClient } from '../../../../utils/axios-utils';
import {useMutation} from "@tanstack/react-query"
import { useDomainOverview } from '../context/domainoverview';
import { useNavigate } from 'react-router-dom';

const getAdStats = async (data) => {
    const config = {
        url: "/aggs/url/domain/adstat",
        method: "POST",
        data
    }
  
    return appClient(config)
}

const GetTopDomains = async (data) => {
    const config = {
        url: "/aggs/url/domain/top-domains",
        method: "POST",
        data
    }

    return appClient(config)
}

const HeatMapAnalysis = async (data) => {
    const config = {
        url: `/aggs/url/domain/heatmap`,
        method: "POST",
        data
    }

    return appClient(config)
}

const GetMapData = async (data) => {
    const config = {
        url: "/aggs/url/domain/mapstat",
        method: "POST",
        data
    }
  
    return appClient(config)
}

const GetKeywordsAnalysis = async (data) => {
    const config = {
        url: "/keyword/analysis",
        method: "POST",
        data
    }

    return appClient(config)
}

const GetDomainKeywords = async (data) => {
    const config = {
        url: "/keyword/domain/analysis",
        method: "POST",
        data
    }

    return appClient(config)
}

const SetTracking = async (data) => {
    const config = {
        url: "/track/domain/add",
        method: "POST",
        data
    }

    return appClient(config)
}


export const useDomainOverviewInfo = () => {
    const {domainOverviewState, domainOverviewDispatch} = useDomainOverview()

    const navigate = useNavigate()

    const { mutate: getAdStatsMutation, isLoading: isGetAdStatsLoading } = useMutation(getAdStats, {
        onSuccess: (data) => {
            domainOverviewDispatch({type: 'SET_AD_STATS', value: data})
        },
        onError: (err) => {
            
        }
    })

    const { mutate: getTopDomainsMutation, isLoading: isGetTopDomainsLoading } = useMutation(GetTopDomains, {
        onSuccess: (data) => {
            domainOverviewDispatch({type: 'SET_TOPDOMAINS_LIST', value: data})
        },
        onError: (err) => {
            
        }
    })

    const { mutate: getHeatmapDataMutation, isLoading: isGetHeatmapDataLoading } = useMutation(HeatMapAnalysis, {
        onSuccess: (data) => {
            domainOverviewDispatch({type: 'SET_HEATMAP_DATA', value: data})
        },
        onError: (err) => {
            
        }
    })

    const { mutate: getMapDataMutation, isLoading: isGetMapDataLoading } = useMutation(GetMapData, {
        onSuccess: (data) => {
          domainOverviewDispatch({type: 'SET_DOMAIN_MAP_DATA', value: data})
        },
        onError: (err) => {
            
        }
    })

    const { mutate: getKeywordAnalysisMutation, isLoading: isGetKeywordAnalysisLoading } = useMutation(GetKeywordsAnalysis, {
        onSuccess: (data) => {
            domainOverviewDispatch({ type: 'SET_RELATED_KEYWORD_INPUT', value: data})
        },
        onError: (err) => {
            
        }
    })

    const { mutate: getDomainKeywordsMutation, isLoading: isGetDomainKeywordsLoading } = useMutation(GetDomainKeywords, {
        onSuccess: (data) => {
            if(data.length > 0){
                getKeywordAnalysisMutation({"keywords" : data})
            }
        },
        onError: (err) => {
            
        }
    })

    const { mutate: setTrackingMutation, isLoading: isSetTrackingLoading } = useMutation(SetTracking, {
        onSuccess: (data) => {
            navigate('/domain-tracking')
        },
        onError: (err) => {
            
        }
    })

    return {
        getAdStatsMutation,
        isGetAdStatsLoading,
        getTopDomainsMutation,
        isGetTopDomainsLoading,
        getHeatmapDataMutation,
        isGetHeatmapDataLoading,
        getMapDataMutation,
        isGetMapDataLoading,
        getKeywordAnalysisMutation,
        isGetKeywordAnalysisLoading,
        getDomainKeywordsMutation,
        isGetDomainKeywordsLoading,
        setTrackingMutation,
        isSetTrackingLoading
    };
};
