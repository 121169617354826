import React, { useEffect } from "react";
import {
  AdgroupNav,
  AdgroupNavWrap,
  Divider,
  PreviewBtn,
  PublishWrap,
} from "./index.styled";
import textGenStatic from "../../../static";
import { useTextAdGenerator } from "../../../../context/textAdGeneration";
import PreviewContent from "./components/PreviewContent";
import DetailsContent from "./components/DetailsContent";
const PublishAdInnerContent = () => {
  const { publishAdGroup } = textGenStatic;
  const {
    textAdGeneratorState: { isPublishToggle },
    textAdGeneratorDispatch,
  } = useTextAdGenerator();

  const renderToggleButton = () => {
    switch (isPublishToggle) {
      case 0:
        return <PreviewContent />;

      case 1:
        return <DetailsContent />;

      default:
        break;
    }
  };
  useEffect(() => {
    textAdGeneratorDispatch({
      type: "SET_PUBLISH_TOGGLE",
      value: 0,
    });
  }, []);

  return (
    <AdgroupNavWrap>
      <AdgroupNav>
        {publishAdGroup.map((item, index) => (
          <>
            <PreviewBtn
              onClick={() =>
                textAdGeneratorDispatch({
                  type: "SET_PUBLISH_TOGGLE",
                  value: index,
                })
              }
              props={isPublishToggle == index}
            >
              <img src={item.img} />
              <p>{item.title}</p>
            </PreviewBtn>
            {index == 0 && <Divider></Divider>}
          </>
        ))}
      </AdgroupNav>
      <PublishWrap>{renderToggleButton()}</PublishWrap>
    </AdgroupNavWrap>
  );
};

export default PublishAdInnerContent;
