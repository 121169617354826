import styled from "styled-components";

export const FooterContainer = styled.div`
    width: 135px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: ${(props) => !props.isAdGoal ? "24px" : "42px"};
    bottom: 36px;
`;

export const Cancel = styled.div`
color: #8A8B8C;
leading-trim: both;
text-edge: cap;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
cursor: pointer;
`;

export const Save = styled.div`
    height: 40px;
    width: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #FF711E;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    cursor: pointer;

`;