import { useEffect, useRef } from "react";
import { useTextAdGenerator } from "../../context/textAdGeneration";
import statics from "../../statics";
import CustomizeBar from "./components/CustomizeBar";
import DescriptionList from "./components/DescriptionList";
import KeywordsList from "./components/KeywordsList";
import Popup from "./components/popup";
import TitlesList from "./components/TitleList";
import { AdTemplate, AdTemplatesContainer, Count, Country, Heading, HighlightLine, Item, LocationLabel, NavItem, ResultContainer, ResultPageContainer, SubNavbar, Title } from "./index.styled";
import { appClient } from "../../../../../../utils/axios-utils"
import {
    useMutation
} from "@tanstack/react-query"
import { getSearchParams } from "../../../../../../utils/helper";
import { useLocation } from "react-router-dom";


const adgroupsFn = async (data) => {
    const config = {
        url: `text-ad-generation/generate/get-ad-groups`,
        method: "POST",
        data
    }

    return appClient(config)
}
const adDetailsFn = async (data) => {
    const config = {
        url: `text-ad-generation/generate/get-ad-details`,
        method: "POST",
        data
    }

    return appClient(config)
}
function ResultPage() {
    const { generatedAds, navItems } = statics
    const { textAdGeneratorState, textAdGeneratorDispatch } = useTextAdGenerator()
    const location = useLocation();
    const fromOverviewRef = useRef(false)
    const setActiveAdTemplate = (adTemplate) => {
        textAdGeneratorDispatch(({
            type: "SET_ACTIVE_AD_TEMPLATE",
            value: adTemplate
        }))
    }

    const setActiveNavItem = (item) => {
        textAdGeneratorDispatch(({
            type: "SET_ACTIVE_NAV_ITEM",
            value: item
        }))
    }
    
    const CountryDisplay = ({ isActive, targetLocation }) => {
        if(!targetLocation) return
        let displayText = '';
        if (targetLocation.length === 1) {
            displayText = targetLocation[0]?.name;
        } else if (targetLocation.length > 1) {
            const countriesNames = targetLocation.map((loc) => loc.name)
            const joinedCountries = countriesNames.join(', ');
        console.log(joinedCountries.length,'joinedCountries.length')

            displayText = joinedCountries.length > 19
                ? joinedCountries.slice(0, 19) + "..."
                : joinedCountries;
        }
        
        return (
            <div>
                <Country isActive={isActive}>{displayText}</Country>
            </div>
        );
    };

    const renderAdsTemplate = () => {
        const { activeAdTemplate, targetLocation } = textAdGeneratorState
        return(
            <AdTemplatesContainer>
                {textAdGeneratorState.isAdGroupData.map((ad, idx) => {
                    const isActive = activeAdTemplate ? activeAdTemplate.id === ad.id : idx === 0
                    return(
                        <AdTemplate isActive={isActive} onClick={() => setActiveAdTemplate(ad)} >
                            <Title isActive={isActive}>
                                {`Ad group ${idx+1}`}
                            </Title>
                            <LocationLabel isActive={isActive}>Location:</LocationLabel>
                            {textAdGeneratorState.targetLocation?.length && <CountryDisplay 
                                isActive={isActive}
                                targetLocation={textAdGeneratorState.targetLocation}
                            />}
                        </AdTemplate>
                    )
                })}
            </AdTemplatesContainer>
        )
    }

    const renderSubNavBar = () => {
        return(
            <SubNavbar>
                {navItems.map((item,index) => {
                    const isActive = item.id === textAdGeneratorState.activeNavItem.id
                    let countContent;
                        if (index === 0) {
                            countContent = `(${textAdGeneratorState?.activeAdTemplate?.titles?.length})`;
                        } else if (index === 1) {
                            countContent = `(${textAdGeneratorState?.activeAdTemplate?.descriptions?.length})`;
                        } else if (index === 2) {
                            countContent = `(${textAdGeneratorState?.activeAdTemplate?.keywords?.length})`;
                        }
                    return(
                        <NavItem onClick={() => setActiveNavItem(item)} >
                            <Item>{item.name}
                            <Count>{countContent}</Count>
                            </Item>
                            <HighlightLine isActive={isActive} />
                        </NavItem>
                    )
                })}
            </SubNavbar>
        )
    }

    const renderContent = () => {
        switch (textAdGeneratorState.activeNavItem.id){
            case 1:
            return <TitlesList fromOverview={fromOverviewRef.current} />

            case 2:
            return <DescriptionList />

            case 3:
            return <KeywordsList />

            default:
            return

        }
    }

    const { mutate: getAdgroupsFn, isLoading: isAdgroupsFnLoading } = useMutation(adgroupsFn, {    
        onSuccess: (data) => {
            if(data){
                setActiveAdTemplate(data.adGroups[0])
                textAdGeneratorDispatch(({ type: "SET_ADGROUPS_DATA", value: data.
                adGroups
                }))
            }    
        },
        onError: (err) => {
            
        }
    })

    const { mutate: getAdDetailsFn, isLoading: isAdDetailsFnLoading } = useMutation(adDetailsFn, {    
        onSuccess: (data) => {
            if(data){
                textAdGeneratorDispatch({type: "SET_TARGET_LOCATION", value: data.adDetails.locations.map(item => item.name)})
                textAdGeneratorDispatch({type: "SET_BRAND_DESCRIPTION", value: data.adDetails.brandDescription})
                textAdGeneratorDispatch({type: "UPDATE_SEED_KEYWORDS", value: data.adDetails.seedKeywords})
            }    
        },
        onError: (err) => {
            
        }
    })
    
    useEffect(() => {
        setActiveAdTemplate(textAdGeneratorState.isAdGroupData[0])
        const searchParamFromState = getSearchParams(location.search)
        const {
            id, fromOverview
        } = searchParamFromState
        if(fromOverview) fromOverviewRef.current = true
        getAdgroupsFn({"userAdId": id})
        getAdDetailsFn({"userAdId": id})
    }, [])

    return ( 
        <ResultPageContainer>
            <ResultContainer>
                <Heading>Results:</Heading>
                {renderAdsTemplate()}
                {renderSubNavBar()}
                {renderContent()}
            </ResultContainer>
            <CustomizeBar />
            <Popup />
        </ResultPageContainer>
     );
}

export default ResultPage;