import React from 'react'
import { useSearchParams } from 'react-router-dom'


// Styles
import {
    PaginationContainer
} from "./index.styled"

import {useAmazonSearch} from "../../context/amazon"
import ReactPaginate from 'react-paginate';


const Pagination = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const {amazonSearchState, amazonSearchDispatch} = useAmazonSearch()

    const handleSpecificPage = (e) => {
        amazonSearchDispatch({type:'SET_AD_DETAILS', value: {}})
        amazonSearchDispatch({type:'UPDATE_DETAILS_MODAL', value: false})
        amazonSearchDispatch({ type: "SET_CURRENT_PAGE_NO", value: e.selected + 1 })
        searchParams.set("page", e.selected + 1)
        setSearchParams(searchParams)
    }
    
    return <PaginationContainer 
    prevState={amazonSearchState.currentPageNo == 1}
    nextState={amazonSearchState.currentPageNo == Math.ceil((amazonSearchState.totalAds / 20))}>
        <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            forcePage={amazonSearchState.currentPageNo - 1}
            onPageChange={handleSpecificPage}
            pageRangeDisplayed={3}
            pageCount={(amazonSearchState.totalAds / 20)}
            previousLabel="<"
            renderOnZeroPageCount={null}
        />
  </PaginationContainer>

}

export default Pagination
