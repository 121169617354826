import { useTextAdGenerator } from "../../context/textAdGeneration";
import statics from "../../statics";
import { AdGoalsContainer, Goal, GoalContainer, GoalIcon, GoalIconContainer } from "./AdGoal.styled";

function AdGoal() {
    const { textAdGeneratorState, textAdGeneratorDispatch } = useTextAdGenerator()
    const { adGoals } = statics

    const handleGoalSelection = (goal) => {
        textAdGeneratorDispatch({type: "SET_AD_GOAL", value: goal})
    }
    return ( 
        <AdGoalsContainer>
            {adGoals.map((goal) => {
                const isSelectedGoal = textAdGeneratorState.adGoal?.id === goal.id
                return(
                    <GoalContainer key={goal.id} onClick={() => handleGoalSelection(goal)} isSelected={isSelectedGoal} >
                        <GoalIconContainer >
                            <GoalIcon src={ isSelectedGoal ? goal.activeIcon : goal.inActiveIcon} alt="goal" />
                        </GoalIconContainer>
                        <Goal isSelected={isSelectedGoal} >{goal.name}</Goal>
                    </GoalContainer>
                )
            })}
        </AdGoalsContainer>
     );
}

export default AdGoal;