import React, {useState} from 'react';
import { useAuth } from '../../context/auth';
import { appClient } from '../../utils/axios-utils';
import {useMutation, useQuery} from "@tanstack/react-query"

const requestDataCrawl = async (data) => {
    const config = {
        url: `/track/search/update-search-query`,
        method: "POST",
        data
    }
  
    return appClient(config)
}

const requestRealtimeData = async (data) => {
    const config = {
        url: `/search/facebook/url/realtime`,
        method: "POST",
        data
    }
  
    return appClient(config)
}

const requestRealtimeDataForLinkedin = async (data) => {
    const config = {
        url: `/search/linkedin/keyword/realtime`,
        method: "POST",
        data
    }
  
    return appClient(config)
}

const requestRealtimeDataForGoogle = async (data) => {
    const config = {
        url: `/search/google/url/realtime`,
        method: "POST",
        data
    }
  
    return appClient(config)
}

export const useRequestData = () => {
    // const [dataRequested, setDataRequested] = useState(false)
    const { authState, authDispatch } = useAuth()

    const {mutate: requestDataMutate, isLoading: isRequestDataLoading} = useMutation(requestDataCrawl, {
        onSuccess: (data) => {
            authDispatch({type: "SET_IS_DATA_REQUESTED", value: true})
            // setDataRequested(true)
        },
        onError:  ((e)=>{
  
        })
    })

    const {mutate: requestRealtimeDataForFaceBookMutate, isLoading: isRequestRealtimeDataForFacebookLoading} = useMutation(requestRealtimeData, {
        onSuccess: (data) => {
            authDispatch({type: "SET_IS_DATA_REQUESTED", value: true})
        },
        onError:  ((e)=>{
  
        })
    })

    const {mutate: requestRealtimeDataForLinkedinMutate, isLoading: isRequestRealtimetDataForLinkedinLoading} = useMutation(requestRealtimeDataForLinkedin, {
        onSuccess: (data) => {
            authDispatch({type: "SET_IS_DATA_REQUESTED", value: true})
        },
        onError:  ((e)=>{
  
        })
    })

    const {mutate: requestRealtimeDataForGoogleMutate, isLoading: isRequestRealtimetDataForGoogleLoading} = useMutation(requestRealtimeDataForGoogle, {
        onSuccess: (data) => {
            authDispatch({type: "SET_IS_DATA_REQUESTED", value: true})
        },
        onError:  ((e)=>{
  
        })
    })

    return {
        requestDataMutate,
        isRequestDataLoading,
        requestRealtimeDataForFaceBookMutate,
        isRequestRealtimeDataForFacebookLoading,
        // setDataRequested,
        // dataRequested,

        requestRealtimeDataForLinkedinMutate,
        isRequestRealtimetDataForLinkedinLoading,
        
        requestRealtimeDataForGoogleMutate,
        isRequestRealtimetDataForGoogleLoading
    };
};
