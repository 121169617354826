import styled from "styled-components";

export const BannersContainer = styled.div`
    display: flex;
    gap: 16px;
    margin-top: 24px;
    flex-wrap: wrap;
`;

export const BannerDetails = styled.div`
    position: absolute;
    top: 45px;
    left: 250px;
`;

export const Title = styled.div`
    color: #FFF;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 27px */
letter-spacing: 0.36px;
`;

export const Description = styled.pre`
color: #FFF;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
margin-top: 8px;
`;

export const NavigateButton =styled.div`
width: 92px;
height: 35px;
flex-shrink: 0;
border-radius: 4px;
background: #FFF;
display: flex;
align-items: center;
justify-content: center;

color: ${(props) => props.forTextAd ? "#172434" : "#CEAA8A"};
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 24px;
cursor: pointer;
`;