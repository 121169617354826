import styled from "styled-components";

export const ModalWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 10;
    background: rgba(28, 28, 28, 0.90);
    /* overflow-y: auto; */
    ::-webkit-scrollbar {
        display: none;
    }
`;

export const DetailContainer = styled.div`
    overflow-y: auto;
    height: 100vh;
   
    /* margin-top: 22px; */
    ::-webkit-scrollbar {
        display: none;
    }
`;

export const DetailWrapper = styled.div`
    max-width: 1120px;
    width: 85%;
    /* border-radius: 20px 20px 0px 0px;
    background: #FFF; */
    min-height: calc(100vh - 22px);
    margin: auto;
    /* margin-top: 22px; */
    position: relative;
    animation: slide 0.5s forwards;
    top: 100vh;

    @-webkit-keyframes slide {
        100% { top: 0; }
    }

    @keyframes slide {
        100% { top: 0; }
    }
`;

export const BackButton = styled.div`
    cursor: pointer;
    position: absolute;
    left: -40px;
    top: 50%;
`;


export const NextButton = styled.div`
    cursor: pointer;
    position: absolute;
    right: -40px;
    top: 50%;
`;


export const CloseButton = styled.div`
    position: absolute;
    right: -40px;
    top: 32px;
    cursor: pointer;
    &.bingDetailContainer{
        top: 65px;
    }
`;

