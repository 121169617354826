import React, { useRef, useState } from 'react'
import {
    useMutation
} from "@tanstack/react-query"
import NoResultsLogo from "../../../../../../media/Dashboard/noresults.svg"
import Skeleton from 'react-loading-skeleton'

// Styles
import {
    SearchTermsList,
    SearchTermsContainer,
    CountriesContainer,
    AvgPosition,
    DateRange,
    DestinationUrl,
    DetailDescription,
    DetailsHeadline,
    AdDetailsBody,
    CloseDetails,
    DetailsHeader,
    DetailsContainer,
    DetailsButton,
    FooterRight,
    AdCountries,
    AdDate,
    FooterLeft,
    CardFooter,
    AdUrl,
    CardBody,
    Favorite,
    FloatingButtonsContainer,
    Card,
    CardsList,
    ResultsContainer,
    HightlightTitle,
    HightlightContent,
    AdHeader,
    WebsiteLogo,
    WebsiteNameContainer,
    WebsiteName,
    SponsoredText,
    NoResults,
    CopyImg,
    FunnelStage,
    CtaList,
    CtaContainer,
    Cta,
    TargetAudience,
    AudienceContainer,
    DemographicAudience,
    PsychographicAudience,
    DemographicContainer,
    PsychographicContainer
} from "./index.styled"
import _static from "../../../../../../config/static"
import SearchLoader from '../../../../../HOC/SearchLoader'

import Highlighter from "react-highlight-words"
import GlobeIcon from '../../../../../../media/Dashboard/globe.svg';
import { useKeywordAnalysis } from "../../../context/keywordanalysis"


//Icons
import FavorietIcon from "../../../../../../media/Dashboard/addFavorite.svg"
import FavouriteIconActive from "../../../../../../media/Dashboard/addFavouriteIconActive.svg"
import CloseBtn from "../../../../../../media/Dashboard/detailsPaneClose.svg"
import DateIcon from "../../../../../../media/Images/DateIcon.svg"
import CountryIcon from "../../../../../../media/Images/CountryIcon.svg"
import TickImg from '../../../../../../media/Dashboard/tick-grey.svg'
import CopyBtn from '../../../../../../media/Dashboard/copy.png'

import {appClient} from '../../../../../../utils/axios-utils'
import LimitExceededPopup from '../../../../../components/limitExceededPopup'
import PlatformRestrictedPopup from '../../../../../components/platformRestrictionPopup'
import { useAuth } from '../../../../../../context/auth'
import SaveAdsPopup from '../../../../../components/SaveAdsPopup'

const adDetails = async (id) => {
    const config = {
        url: `/detail/google/${id}`,
        method: "GET"
    }

    return appClient(config)
}
const adClassification = async (id) => {
    const config = {
        url: `/detail/google/classification/${id}`,
        method: "GET"
    }

    return appClient(config)
}

const requestDataCrawl = async (data) => {
    const config = {
        url: `/track/search/update-search-query`,
        method: "POST",
        data
    }

    return appClient(config)
}


const addToFavourite = async (data) => {
    const config = {
        url: `/adgeneration/project/save-ad`,
        method: "POST",
        data
    }

    return appClient(config)
}

const Results = () => {
    const {keywordAnalysisState, keywordAnalysisDispatch} = useKeywordAnalysis()
    const [ basicAdDetails, setBasicAdDetails ] = useState({})
    const [ dataRequested, setDataRequested ] = useState(false)
    const [ showTick, setShowTick ] = useState(false)
    const [ copiedItem, setCopiedItem ] = useState('')
    const { authState, authDispatch } = useAuth()
    const containerRef = useRef(null);
    
    const toggleDetailsModal = (ad,idx) => {
        if(keywordAnalysisState.adDetails.id){
            if((keywordAnalysisState.adDetails.id == ad.id) && keywordAnalysisState.detailsModal){
                //if details is open and clicked on close button on ads card
                setBasicAdDetails({})
                keywordAnalysisDispatch({type:'SET_AD_DETAILS', value: {}})
                keywordAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: false})
            }else{
                keywordAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: true})
                setBasicAdDetails(ad)
                adsDetailsMutate(ad.id)
                keywordAnalysisDispatch({type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: true})
                adAiClassificationMutate(ad.id)
            }
        }else{
            keywordAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: true})
            setBasicAdDetails(ad)
            adsDetailsMutate(ad.id)
            keywordAnalysisDispatch({type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: true})
            adAiClassificationMutate(ad.id)
        }
        setTimeout(() => {
            scrollToElement(idx)
        }, 100);
    }

    const getDomainFromUrl = (url) => {
        var parsedUrl = new URL(url);
        var domain = parsedUrl.hostname;
    
        // Remove subdomains and top-level domain
        var domainParts = domain.split('.');
        if (domainParts.length > 2) {
          domain = domainParts[domainParts.length - 2];
        }
        
        return domain;
      }

    const convertTimestamp = (timestamp) => {
        // Create a new Date object based on the timestamp
        var date = new Date(timestamp);
    
        // Get the month name
        var monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        var month = monthNames[date.getMonth()];
      
        // Get the year
        var year = date.getFullYear();
      
        // Assemble the formatted date string
        var formattedDate = date.getDate() + ' ' + month  + ' ' + year;
      
        return formattedDate;
      }
    
    const {mutate: requestData, isLoading: isRequestDataLoading} = useMutation(requestDataCrawl, {
        onSuccess: (data) => {
            setDataRequested(true)
        },
        onError:  ((e)=>{

        })
    })

    const {mutate: adsDetailsMutate, isLoading: isDetailsLoading} = useMutation(adDetails, {
        onSuccess: (data) => {
            keywordAnalysisDispatch({type:'SET_AD_DETAILS', value: data})
            // if(keywordAnalysisState.adDetails.id == data.id || !keywordAnalysisState.adDetails.id){
            //     keywordAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: !keywordAnalysisState.detailsModal})
            // }
        },
        onError:  ((e)=>{
            keywordAnalysisDispatch({type:'SET_AD_DETAILS', value: {}})
            // keywordAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: true})
            // console.log(e)
        })
    })

    const {mutate: adAiClassificationMutate, isLoading: isAiClassificationLoading} = useMutation(adClassification, {
        onSuccess: (data) => {
            if(data.processed || data.id){
                console.log("data.processed",data.processed)
                keywordAnalysisDispatch({type: 'SET_AD_AI_CLASSIFICATION', value: data})
                keywordAnalysisDispatch({type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: false})
            }else{
                setTimeout(() => {
                    adAiClassificationMutate(basicAdDetails.id)
                }, 5000);
            }
        },
        onError: ((e) => {
            keywordAnalysisDispatch({type: 'SET_AD_AI_CLASSIFICATION', value: {}})
            keywordAnalysisDispatch({type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: false})
        })
    })

    const {mutate: addFavouriteMutate, isLoading: isAddFavouriteLoading} = useMutation(addToFavourite, {
        onSuccess: (data) => {
            
        },
        onError:  ((e)=>{
           
        })
    })
    
    const onDetailsClose = () => {
        setBasicAdDetails({})
        keywordAnalysisDispatch({type:'SET_AD_DETAILS', value: {}})
        keywordAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: false})
    }

    const scrollToElement = (index) => {
        const element = containerRef.current.children[index];
        const scrollPosition = element.offsetTop - 20;
        containerRef.current.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    };

    if(authState.isRestrictedPopup){
        return  <ResultsContainer detailsModal={keywordAnalysisState.detailsModal}>
            <PlatformRestrictedPopup err={keywordAnalysisState.searchError}/>
        </ResultsContainer>
    }

    if(keywordAnalysisState.limitExceeded){
        return  <ResultsContainer detailsModal={keywordAnalysisState.detailsModal}>
            <LimitExceededPopup err={keywordAnalysisState.searchError}/>
        </ResultsContainer>
    }

    const onDataRequest = () => {
        if(!dataRequested){
            requestData({id: keywordAnalysisState.queryId})
        }
    }
    
    const onCopyClick = (value) => {
        navigator.clipboard.writeText(value);
        setCopiedItem(value)
        setShowTick(true)
        setTimeout(() => {
            setShowTick(false)
        }, 1500);
    }

    return  <ResultsContainer detailsModal={keywordAnalysisState.detailsModal}>
                {keywordAnalysisState.loadingResults ? <div className='loader'>
        <SearchLoader/>
      </div> : 
                keywordAnalysisState.noResults ? <NoResults>
                        <img src={NoResultsLogo}/>
                        <h2>No Results Found</h2>
                        <p>We're sorry, but we don't currently have the data matching your search parameters. Our database is constantly updated, so please try again later or try modifying your search.</p>
                        {/* <RequestDataBtn dataRequested={dataRequested ? dataRequested : ""} disable={isRequestDataLoading} onClick={onDataRequest}>{dataRequested ? <p>Your request for data crawl has been accepted<img src={TickImg}/></p> : <p>{isRequestDataLoading ? "Requesting...": "Request data crawl for this query"}</p>}</RequestDataBtn> */}
                    </NoResults> :
                <>
                {authState.saveAdsPopupOpen && <SaveAdsPopup/>}
                    <CardsList ref={containerRef} detailsModal={keywordAnalysisState.detailsModal}> 
                    
                    {
                        keywordAnalysisState.ads && keywordAnalysisState.ads.length > 0 && keywordAnalysisState.ads.map((ad,idx) => {
                            return <Card detailsOpen={basicAdDetails.id == ad.id} key={
                                ad.id
                            }>
                                <FloatingButtonsContainer>
                                    <Favorite isFav={idx == 1} onClick={()=>{
                                        authDispatch({type:'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'google'})
                                        authDispatch({type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id})
                                        authDispatch({type:'UPDATE_SAVE_AD_POPUP', value: true})}}> 
                                        <img src={authState.favAdsList["google"]?.includes(ad.id) ? FavouriteIconActive : FavorietIcon}/>
                                    </Favorite>
                                    {/* |
                                    <DropdownIcon>
                                        <span/>
                                    </DropdownIcon> */}
                                </FloatingButtonsContainer>
                                <CardBody>
                                    <SponsoredText>
                                        Sponsored
                                    </SponsoredText>
                                    <AdHeader>
                                        <WebsiteLogo>
                                            <img src={GlobeIcon}/>
                                        </WebsiteLogo>
                                        <WebsiteNameContainer>
                                            <WebsiteName>
                                                {ad.domainId}
                                            </WebsiteName>
                                            <AdUrl>
                                                {ad.url ? ad.url : ad.url}
                                            </AdUrl>
                                        </WebsiteNameContainer>
                                    </AdHeader>
                                    <Highlighter
                                        className="title-wrapper-span"
                                        highlightClassName="title-highlight-bold"
                                        searchWords={keywordAnalysisState.highlightWords}
                                        autoEscape={true}
                                        textToHighlight={ad.title}
                                        highlightTag={HightlightTitle}
                                    />
                                    <Highlighter
                                        className="content-wrapper-span"
                                        highlightClassName="content-highlight-bold"
                                        searchWords={keywordAnalysisState.highlightWords}
                                        autoEscape={true}
                                        textToHighlight={ad.content}
                                        highlightTag={HightlightContent}
                                    />
                                </CardBody>
                                <CardFooter>
                                    <FooterLeft>
                                        <AdDate>
                                            {ad.firstSeen && ad.lastSeen && <img src={DateIcon}/>}
                                            {/* {convertTimestamp(ad.firstSeen)} -  */}
                                            {convertTimestamp(ad.lastSeen)}
                                        </AdDate>
                                        <AdCountries>
                                            <img src={CountryIcon}/>{ ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
                                        </AdCountries>
                                    </FooterLeft>
                                    <FooterRight>
                                        <DetailsButton onClick={()=>toggleDetailsModal(ad,idx)}>
                                            {keywordAnalysisState.adDetails.id == ad.id ? "Close details" : "More details"}
                                        </DetailsButton>
                                    </FooterRight>
                                </CardFooter>
                            </Card>
                    })
                    } 
                    </CardsList>
                    {keywordAnalysisState.detailsModal && <DetailsContainer>
                        <DetailsHeader>
                            <h3>Ad details</h3> <CloseDetails onClick={onDetailsClose}><img src={CloseBtn}/></CloseDetails>
                        </DetailsHeader>
                        <AdDetailsBody>
                            <DetailsHeadline>
                                <label>Heading:</label>{basicAdDetails.title} <CopyImg onClick={()=>onCopyClick(basicAdDetails.title)} src={showTick && basicAdDetails.title == copiedItem ? TickImg : CopyBtn}/>
                            </DetailsHeadline>
                            <DetailDescription>
                                <label>Description:</label>{basicAdDetails.content} <CopyImg onClick={()=>onCopyClick(basicAdDetails.content)} src={showTick && basicAdDetails.content == copiedItem ? TickImg : CopyBtn}/>
                            </DetailDescription>
                            <DestinationUrl>
                                <label>Destination URL:</label>{keywordAnalysisState.adDetails.url}
                            </DestinationUrl>
                            <DateRange>
                                <label>Last seen</label>{convertTimestamp(basicAdDetails.lastSeen)}
                                {/* <label>Date Range:</label>27 Jan 2021 - 21 Nov 2022 */}
                            </DateRange>
                            <AvgPosition>
                                <label>Average position:</label>{basicAdDetails.position && basicAdDetails.position + 1}
                            </AvgPosition>
                            <FunnelStage>
                                <label>Funnel Stage:</label>{keywordAnalysisState.adAiClassificationLoading ? <Skeleton style={{
                                    lineHeight: "32px",
                                    width: 150,
                                }}/> : keywordAnalysisState.adAiClassification.funnelType ? <p>{keywordAnalysisState.adAiClassification.funnelType}</p>: "-"}
                            </FunnelStage>
                            <CtaList>
                                <label>CTAs:</label>
                                <CtaContainer>
                                    {
                                        keywordAnalysisState.adAiClassificationLoading ?  
                                        <Skeleton style={{
                                            lineHeight: "32px", 
                                            width: 100,
                                        }}/>  :
                                        keywordAnalysisState.adAiClassification.ctaText ? <Cta>
                                            {keywordAnalysisState.adAiClassification.ctaText}
                                        </Cta> : "-"
                                    }
                                </CtaContainer>
                            </CtaList>
                            <TargetAudience>
                                <label>Target Audience</label>
                                <AudienceContainer>
                                    <DemographicAudience>
                                        <h3>Demographic</h3>
                                        <DemographicContainer>
                                        {
                                            keywordAnalysisState.adAiClassificationLoading ?  [...Array(1)].map((d)=>{
                                            return <Skeleton style={{
                                                lineHeight: "32px", 
                                                width: 100,
                                            }}/> 
                                            }) : keywordAnalysisState.adAiClassification.demographicAudience?.length > 0 ? 
                                            keywordAnalysisState.adAiClassification.demographicAudience.map(function(key,index) {
                                                return <Cta>
                                                    {key}
                                                </Cta>
                                            }) : "-"
                                        }
                                        </DemographicContainer>
                                    </DemographicAudience>
                                    <PsychographicAudience>
                                        <h3>Psychographic</h3>
                                        <PsychographicContainer>
                                        {
                                            keywordAnalysisState.adAiClassificationLoading ?  [...Array(1)].map((d)=>{
                                            return <Skeleton style={{
                                                lineHeight: "32px", 
                                                width: 100,
                                            }}/> 
                                            }) :  keywordAnalysisState.adAiClassification?.psychographicAudience?.length > 0 ? 
                                            keywordAnalysisState.adAiClassification.psychographicAudience.map(function(key,index) {
                                                return <Cta>
                                                    {key}
                                                </Cta>
                                            }) : "-"
                                        }
                                        </PsychographicContainer>
                                    </PsychographicAudience>
                                </AudienceContainer>
                            </TargetAudience>
                            <CountriesContainer>
                                {/* <label>Country:</label><CountriesList>{sampleCountryList.map((country,idx)=>{
                                    return <span>{country}</span>
                                })}</CountriesList> */}
                                <label>Country:</label>{basicAdDetails.country}
                            </CountriesContainer>
                            <SearchTermsContainer>
                                <label>Google search terms:</label><SearchTermsList>
                                {isDetailsLoading ? <Skeleton/> : 
                                keywordAnalysisState.adDetails.keywords && keywordAnalysisState.adDetails.keywords.map((searchTerm,idx)=>{
                                    return <span>{searchTerm}</span>
                                })
                                }

                                {isDetailsLoading ? <Skeleton/> : keywordAnalysisState.adDetails.aiKeyword && keywordAnalysisState.adDetails.aiKeyword.map((searchTerm,idx)=>{
                                    return <span>{searchTerm}</span>
                                })
                                }
                                </SearchTermsList>
                            </SearchTermsContainer>
                            {/* <LandingPageScreenShot>
                                <label>Landing page screenshot</label>
                                <ScreenshotContainer>
                                    <img src={ApplePage}/>
                                </ScreenshotContainer>
                            </LandingPageScreenShot> */}
                        </AdDetailsBody>
                    </DetailsContainer>
                    }
                </>
                }
            </ResultsContainer>
}

export default Results