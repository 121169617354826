import React, { useEffect, useState, useRef } from "react";

import { TagsInput } from "react-tag-input-component";


import SearchIcon from "../../../../../../../../../media/Dashboard/searchIcon.svg";

import styled from "styled-components";
import { useAuth } from "../../../../../../../../../context/auth";

const Container = styled.div`
    display: flex;
    align-items: flex-start;
    border: solid 1px #ccc;
    border-radius: 8px;

    input{
      display: ${props => props.limitReached ? "none": "unset"};
      height: 32px;
      width: 100%;
    }
  /* position: relative; */
  /* width: 99%;
  background: ${(props) => (props.isDisabled ? "#F7F9FA" : "#FFF")};
  position: relative;
  border-radius: 8px 0px 0px 8px;
  margin-left: 8px;
  border: 1px solid #ececec;

  display: flex;
  align-items: flex-start;
  padding: 0px 16px 0px 48px;
  z-index: 2;
  justify-content: flex-end;
  padding-right: 15px;
  min-height: 44px;
  height: ${(props) => (props.inputExpand ? "fit-content" : "auto")};
  overflow: hidden;

  input {
    padding: 0px 8px 0px 20px;
    min-width: 75px;
    background: ${(props) => (props.isDisabled ? "#F7F9FA" : "#FFF")};
  }

  &.rti--container {
    display: ${(props) => (props.inputExpand ? "" : "contents")};
    gap: 5px 0px !important;
    margin-left: 15px;

  }

  border: ${(props) =>
    props.error ? "1px solid #FF5180" : "1px solid #ECECEC"};
  outline: ${(props) => (props.error ? "2px solid #FFB7CB" : "none")};
  &:focus {
    border: ${(props) =>
      props.error ? "1px solid #FF5180" : "1px solid #5AB3FF"};
    outline: ${(props) =>
      props.error ? "2px solid #FFB7CB" : "2px solid #B9DDFF"};
  } */

  /* overflow-x: auto; */
`;

const ArrowButtons = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-top: 2px;
  margin-left: 10px;
  margin-right: 5px;
  img {
    height: 10px;
    transform: ${(props) => (props.inputExpand ? "" : "rotate(180deg)")};
  }
`;

const ToolTipImageContainer = styled.div`
  /* position: relative; */
  right: 0px;
  top: 0px;
  display: ${(props) => (props.error ? "flex" : "none")};
  width: 40px;
  height: 40px;
  img {
    margin-top: 3px;
    filter: contrast(1);
  }

  &:hover {
    > div {
      display: flex;
    }

    img {
      display: block;
    }
  }
`;

const ToolTip = styled.div`
  background: #555555;
  border-radius: 4px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: 300;
  position: absolute;
  bottom: -34px;
  padding: 4px 8px;
  width: 210px;
  display: none;

  height: 20px;
  top: 240px;
`;

const ToolTipContainer = styled.div`
  font-size: 12px;
  font-weight: 300;
  color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;

  img {
    margin-left: 4px;
  }
`;

const Wrapper = styled.div`
  width: calc(100% - 5px);
`;

const SearchIconBox = styled.div`
  width: 38px;
  /* background: ${(props) => (props.isDisabled ? "#F7F9FA" : "#FFF")}; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
  left: 0;
  padding: 12px 14px;
  > img {
    height: 16px;
  }
`;

function useOutsideAlerter(ref, setInputExpand) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setInputExpand(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const KeywordsInput = () => {
  const [initialClassNames, setInitialClassNames] = useState({});
  const [inputExpand, setInputExpand] = useState(false);
  const { authState, authDispatch } = useAuth()
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, setInputExpand);

  useEffect(() => {
    if (authState.keywords.length >= 10) {
      setInitialClassNames({ input: "rti-remove-input" });
    } else {
      setInitialClassNames({});
    }
  }, [authState.keywords]);

  // console.log(authState)

  // return (
  //   <Wrapper
  //     expanded={inputExpand}
  //     data-tooltip-id="disabled-tooltip"
  //     data-tooltip-content="Please select a platform first"
  //   >

  //     {!authState.searchPlatform && <Tooltip id="disabled-tooltip" />}
  //     <Container
  //       isDisabled={!authState.searchPlatform}
  //       error={authState.keywordInputError}
  //       inputExpand={inputExpand}
  //       ref={wrapperRef}
  //     >
  //       <TagsInput
  //         value={authState.keywords}
  //         onChange={(newkeywords) => {
  //           authDispatch({ type: "SET_KEYWORDS", value: newkeywords });
  //           authDispatch({ type: "SET_KEYWORD_INPUT_ERROR", value: false });
  //         }}
  //         maxTags={10}
  //         name="keywords"
  //         placeHolder={
  //           authState.searchPlatform ? "Enter upto 5 keywords": "Select a Platform first"
  //         }
  //         separators={["Enter", ","]}
  //         classNames={initialClassNames}
  //       />
  //       <SearchIconBox>
  //         <img src={SearchIcon} />
  //       </SearchIconBox>
  //       {authState.keywords.length > 0 && (
  //         <ArrowButtons onClick={() => setInputExpand(!inputExpand)}>
  //           <img src={DownArrow} />
  //         </ArrowButtons>
  //       )}
       
  //     </Container>
  //   </Wrapper>
  // );


  return (
    <Wrapper
      expanded={inputExpand}
    >
      <Container 
        limitReached={authState.keywords.length >= 10}>
        <SearchIconBox>
          <img src={SearchIcon} />
        </SearchIconBox>       
        <TagsInput
          value={authState.keywords}
          maxTags={10}
          onChange={(newkeywords) => {
            authDispatch({ type: "SET_KEYWORDS", value: newkeywords });
            authDispatch({ type: "SET_KEYWORD_INPUT_ERROR", value: false });
          }}
          name="keywords"
          placeHolder={`+ add more keywords (${authState.keywords.length}/10)`}
        />
        
      </Container>
    </Wrapper>
  );
};

export default KeywordsInput;

{/* <ToolTipImageContainer
error={authState.keywordInputError}
className="tooltip-container"
>
<img src={ToolTipLogo} />
<ToolTip>You can enter upto 10 keywords</ToolTip>
</ToolTipImageContainer> */}
