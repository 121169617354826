import React, {useState} from "react";
import { useAdGenerator } from "../../../../context/adGeneration";
import { Container, Goal, SubHeader, DescriptionBox, Controllers, Skip, Next, Optional } from "./index.styled";
import statics from "../../../../statics";

function AdGoal({onNext, goals}) {
    const { steps } = statics

    const [adGoal, setAdGoal] = useState("")
    const { adGeneratorState: {goal}, adGeneratorDispatch } = useAdGenerator()
    const handleSelectGoal = (goal) => {
        adGeneratorDispatch({
            type: "SET_GOAL",
            value: goal
        })   
    }

    const handleNext = () => {
        adGeneratorDispatch({
            type: "SET_DESCRIPTION",
            value: adGoal
        })
        onNext()
    }

    return ( 
        <Container>
            <div>
            {goals.map((adGoal, index) => {
                return(
                    <Goal 
                        key={index} 
                        onClick={() => handleSelectGoal(adGoal)} 
                        isSelected={goal === adGoal} 
                    >
                        {adGoal}
                    </Goal>
                )
            })}
            </div>
            <SubHeader>
                {steps[0].header2.header}
                <Optional> (Optional)</Optional>
            </SubHeader>
            <DescriptionBox onChange={(e)=>setAdGoal(e.target.value)} ></DescriptionBox>
            <Controllers>
                {/* <Skip onClick={onNext}>Skip</Skip> */}
                <Next isDisabled={!goal} onClick={handleNext} >Next</Next>
            </Controllers>
        </Container>
     );
}

export default AdGoal;