import React,{useState, useEffect} from "react";
import { useHistory, useLocation } from 'react-router-dom';

import {
    useMutation
} from "@tanstack/react-query";
import { Wrapper, LeftNav, Content, NavList, NavItem, PromptInput, ContentConatiner, InputContainer, InputName, InputField, InputTextArea, ParallelInputs, GenerateAdButton, Footer, SelectTypeButton, Divider, Project, ProjectContent, ProjectListContainer, AdContent, HomeContent, CreativeContainer, LeftCreative, StepsContainer, StepsList, Step, ResultWrapper, GeneratedAdsContainer, GeneratedAdCard, GeneratedAdTitle, GeneratedAdDescription, GeneratedAdCardFooter, SaveAd} from "./index.styled"

import FolderIcon from '../../../../../media/Dashboard/folderIcon.svg'
import { useAuth } from '../../../../../context/auth'
import {appClient} from '../../../../../utils/axios-utils'

import SaveIcon from "../../../../../media/Dashboard/saveIcon.svg"
import AdIcon from "../../../../../media/Dashboard/selectAdIcon.svg"
import BrandIcon from "../../../../../media/Dashboard/brandIcon.svg"
import PlatformIcon from "../../../../../media/Dashboard/globeWhiteIcon.svg"
import AudienceIcon from "../../../../../media/Dashboard/audienceIcon.svg"
import { useAdGenrator } from "../../context/adgenerator";
import FormulaDropDown from "./components/FormulaDropdown";
import LanguageDropDown from "./components/LanguageDropdown";
import ToneDropDown from "./components/ToneDropdown";
import SelectProjectAds from "./components/SelectProjectAdsPopup";
import AdGenreratorCreative from "../../../../../media/Dashboard/adgeneratorCreatives.svg"
import SearchLoader from "../../../../HOC/SearchLoader";
import toast from "react-hot-toast";
import FunnelTypeDropDown from "./components/FunnelType";
import { customToast } from "../../../../../utils/toast-utils";

const getAllProjects = async (data) => {
    const config = {
        url: `/adgeneration/project/all`,
        method: "GET"
    }

    return appClient(config)
}


const generateAds = async (data) => {
    const config = {
        url: `/adgeneration/search/create`,
        method: "POST",
        data
    }

    return appClient(config)
}


const saveAds = async (data) => {
    const config = {
        url: `/adgeneration/search/save-generated-ad`,
        method: "POST",
        data
    }

    return appClient(config)
}

const PromptPage = () => {
    const {authState, authDispatch} = useAuth()

    const {adGenratorState, adGenratorDispatch} = useAdGenrator()
    const location = useLocation()

    let navItems = [
        {
            name:"Ad",
            icon: AdIcon
        },
        {
            name:"Home",
            icon: AdIcon
        },
        {
            name:"Brand",
            icon: BrandIcon
        },
        {
            name:"Platform",
            icon: PlatformIcon
        },
        {
            name:"Audience",
            icon: AudienceIcon
        }
    ]

    const {mutate: getProjectsMutate, isLoading: isProjectsLoading} = useMutation(getAllProjects, {
        onSuccess: (data) => {
            authDispatch({type: 'UPDATE_PROJECT_COLLECTIONS', value: data})
        },
        onError:  ((e)=>{
           
        })
    })

    const {mutate: getAiGenerateAds, isLoading: isGenerateAdsLoading} = useMutation(generateAds, {
        onSuccess: (data) => {
            adGenratorDispatch({type: 'SET_GENERATED_ADS', value: data})
        },
        onError:  ((e)=>{
            customToast("Something went wrong. try changing the description.", "error", 'top-center', 5000 )
        })
    })

    const {mutate: saveGenerateAds, isLoading: isSaveGenerateAdsLoading} = useMutation(saveAds, {
        onSuccess: (data,variables) => {
            toast.success("Ads saved to your project.")
            adGenratorDispatch({type:'SET_SAVED_ADS', value: [...adGenratorState.savedAds, variables.adId]})            
        },
        onError:  ((e)=>{
            toast.error("Something went wrong.")
        })
    })

    const onGenerateAdClick = () => {
        if(adGenratorState.currentOpenPrompt == "ad"){
            adGenratorDispatch({type:'SET_CURRENT_PROMPT', value: "home"})
        }else{
            
            getAiGenerateAds({
                projectId: adGenratorState.selectedProject.id,
                productName: adGenratorState.productName,
                adGoal: adGenratorState.adGoal,
                adFormula: adGenratorState.selectedFormula, 
                funnelType: adGenratorState.selectedFunnelType, 
                language: adGenratorState.selectedLanguage,
                tone: adGenratorState.selectedTone
              })
        }
    }

    console.log(!adGenratorState.selectedProject.id.length > 0 , !adGenratorState.productName.length > 0 , !adGenratorState.adGoal.length > 0 , !adGenratorState.selectedFormula.length > 0 , !adGenratorState.selectedLanguage.length > 0 , !adGenratorState.selectedTone.length > 0 , !adGenratorState.selectedFunnelType.length > 0)
    return <Wrapper>
        {adGenratorState.selectProjectAdsPopup && <SelectProjectAds/>}
        <LeftNav>
            <NavList>
                {navItems.map((item)=>{
                    return <NavItem isActive={adGenratorState.currentOpenPrompt == item.name.toLowerCase()} onClick={()=> adGenratorDispatch({type:'SET_CURRENT_PROMPT', value: item.name.toLowerCase()})}>
                        <img src={item.icon}/>
                        <span>{item.name}</span>
                    </NavItem>
                })}
            </NavList>
            <PromptInput>
                <h2>
                    {adGenratorState.currentOpenPrompt == "ad" && "Select Ad Format"}
                    {adGenratorState.currentOpenPrompt == "home" && "Prompt - Inputs for AI"}</h2>
                    {adGenratorState.currentOpenPrompt == "ad" && <ContentConatiner>
                    <h3>Customize Your Saved Ad Preferences or Explore AdSpyder's Sample Ad Templates.</h3>
                    {/* <SelectTypeButton onClick={()=>getProjectsMutate()}>
                        {adGenratorState.searchMode == "saved ads" ? "Sample ad Formats" : "Saved Projects"}
                    </SelectTypeButton>
                    <Divider>
                        <span>or</span>
                    </Divider> */}
                    <h4>Saved Projects</h4>
                    <ProjectListContainer>
                    {isProjectsLoading ? <span>Loading...</span> :
                        authState.project.collections.map((item) => {
                            return <Project onClick={()=>{
                                adGenratorDispatch({type:'SET_SELECTED_PROJECT', value: item})
                                adGenratorDispatch({type:'SET_SELECT_PROJECT_ADS_OPEN', value: true})
                                }}>
                                <img src={FolderIcon}/>
                                <ProjectContent>
                                    <h3>{item.name}</h3>
                                    <span>{item.adsCount} items</span>
                                </ProjectContent>
                            </Project>
                        })
                    }
                    </ProjectListContainer>
                </ContentConatiner>}

                {adGenratorState.currentOpenPrompt == "home" && <ContentConatiner>
                <h3>Elevate your product's visibility with compelling ad copies that entice your audience to click and discover.</h3>

                <InputContainer>
                    <InputName>
                        Company / Product name
                    </InputName>
                    <InputField 
                        value={adGenratorState.productName} onChange={(e)=>
                            adGenratorDispatch({type:'SET_PRODUCT_NAME', value: e.target.value})
                        } 
                        placeholder="Product name"
                        error={false} 
                        type="text"
                    />
                </InputContainer>
               
                <InputContainer>
                    <InputName>
                        Company / Product description
                    </InputName>
                    <InputTextArea 
                        value={adGenratorState.adGoal} onChange={(e)=>
                            adGenratorDispatch({type:'SET_AD_GOAL', value: e.target.value})
                        } 
                        placeholder="Product Description" 
                        error={false} 
                        rows="4"
                    />
                </InputContainer>
                
                <InputContainer>
                    <InputName>
                        Description Framework
                    </InputName>
                    <FormulaDropDown/>
                </InputContainer>

                <InputContainer>
                    <InputName>
                        Funnel Type
                    </InputName>
                    <FunnelTypeDropDown/>
                </InputContainer>
                
                <ParallelInputs>
                    <InputContainer style={{ width: "50%"}}>
                        <InputName>
                            Language
                        </InputName>
                        <LanguageDropDown/>
                    </InputContainer>
                    <InputContainer style={{ width: "50%"}}>
                        <InputName>
                            Tone
                        </InputName>
                        <ToneDropDown/>
                    </InputContainer>
                </ParallelInputs>
                </ContentConatiner>}
                {adGenratorState.currentOpenPrompt == "brand" && <ContentConatiner>
                    <h3>Coming soon!</h3>
                </ContentConatiner>}
                {adGenratorState.currentOpenPrompt == "platform" && <ContentConatiner>
                    <h3>Coming soon!</h3>
                </ContentConatiner>}
                {adGenratorState.currentOpenPrompt == "audience" && <ContentConatiner>
                    <h3>Coming soon!</h3>
                </ContentConatiner>}
                <Footer>
                    <GenerateAdButton isDisabled={ adGenratorState.currentOpenPrompt != "ad" ? 
                        !adGenratorState.selectedProject.id.length > 0 || !adGenratorState.productName.length > 0 || !adGenratorState.adGoal.length > 0 || !adGenratorState.selectedFormula.length > 0 || !adGenratorState.selectedLanguage.length > 0 || !adGenratorState.selectedTone.length > 0 || !adGenratorState.selectedFunnelType.length > 0 : false
                    } onClick={onGenerateAdClick}>
                        {adGenratorState.currentOpenPrompt == "ad" ? "Next" : "Generate Ad Copy"}
                    </GenerateAdButton>
                </Footer>
            </PromptInput>
        </LeftNav>
        <Content>
            
            {adGenratorState.currentOpenPrompt == "ad" && <AdContent>
                <CreativeContainer>
                    <LeftCreative>
                        <h2>Welcome to the</h2>
                        <span>AI Ad Copy Generator!</span>
                        <p>This tool is designed to help you create compelling ad copies for your products or services effortlessly.</p>
                    </LeftCreative>
                    <img src={AdGenreratorCreative}/>
                </CreativeContainer>
                <StepsContainer>
                    <h2>Create Your Ad Copy in 4 Easy Steps</h2>
                    <StepsList>
                        <Step>
                            <div>
                                1
                            </div>
                            <h2>
                                Layout:
                            </h2>
                            <p>
                                Choose a layout that suits your adcopy
                            </p>
                        </Step>
                        <Step>
                            <div>
                                2
                            </div>
                            <h2>
                                AI Prompt:
                            </h2>
                            <p>
                                Share your product's name, description, and more
                            </p>
                        </Step>
                        <Step>
                            <div>
                                3
                            </div>
                            <h2>
                                Customization:
                            </h2>
                            <p>
                                Customize with Brand, Platform, and Audience
                            </p>
                        </Step>
                        <Step>
                            <div>
                                4
                            </div>
                            <h2>
                                Generate Ad:
                            </h2>
                            <p>
                                When you're ready, click to generate your ad copy.
                            </p>
                        </Step>
                    </StepsList>
                </StepsContainer>
            </AdContent>}
            {adGenratorState.currentOpenPrompt == "home" && <HomeContent>
                <ResultWrapper>
                    <h2>AI Generated Results</h2>
                    <GeneratedAdsContainer>
                        {isGenerateAdsLoading ? <div className='loader'>
                            <SearchLoader message={"Generating Ads for You..."}/>
                        </div>: (adGenratorState.generatedAds.length > 0) ? adGenratorState.generatedAds.map((ad)=>{
                            return <GeneratedAdCard>
                                <GeneratedAdTitle>
                                    {ad.title}
                                </GeneratedAdTitle>
                                <GeneratedAdDescription>
                                    {ad.description}
                                </GeneratedAdDescription>
                                <GeneratedAdCardFooter>
                                    <SaveAd saved={adGenratorState.savedAds.includes(ad.id)} onClick={()=>{
                                        saveGenerateAds({
                                            projectId: adGenratorState.selectedProject.id,
                                            adId: ad.id
                                        })
                                    }}>
                                        <img src={SaveIcon}/> {adGenratorState.savedAds.includes(ad.id) ? "saved" : "save"}
                                    </SaveAd>
                                </GeneratedAdCardFooter>
                            </GeneratedAdCard>
                        }) : <h3>Enter your brand name and description, then click 'Generate Ad Copy' to view the result.</h3>}
                    </GeneratedAdsContainer>
                </ResultWrapper>
            </HomeContent>}
        </Content>
    </Wrapper>
}

export default PromptPage