import styled from "styled-components";

export const LoadingScreenWrap = styled.div`
    
    display: flex;
    justify-content: center;
    margin: 0 20px;

`;
export const LoadingScreen = styled.div`
margin-top: 24px;
width: 966px;
height: 314px;
border-radius: 16px;
background: #FFF;
display: flex;
align-items: center;
flex-direction: column;
h2{
    text-align: center;
    margin-top: 36px;
    color: rgba(28, 28, 28, 0.60);
font-family: 'Inter';
font-size: 18px;
font-weight: 600;
}
p{
    color: rgba(28, 28, 28, 0.50);
text-align: center;
font-family: 'Inter';
font-size: 14px;
font-weight: 400;
    margin-top: 47px;
    max-width: 611px;
    line-height: 22px; 
}
`;

export const Progress = styled.progress`
    width: 495px;
    height: 30px;
    margin-top: 44px;
    appearance: none;
    border: none;
    border-radius: 30px;

    &::-webkit-progress-bar {
        background-color: #ddd;
        border-radius: 30px;

        // height: 8px;
    }

    &::-webkit-progress-value {
        background-color: #EE8C22;
        border-radius: 30px;

        // height: 8px;
    }

    &::-moz-progress-bar {
        background-color: #3498db;
        border-radius: 30px;

        // height: 8px;
    }

    &::-ms-fill {
        background-color: #3498db;
        border-radius: 30px;

        // height: 8px;
    }
`;
export const BackToDashboard = styled.div`
color: rgba(28, 28, 28, 0.40);
font-family: 'Inter';
font-size: 14px;
font-weight: 400;
display: flex;
align-items: center;
column-gap: 8px;
margin-top: 28px;
cursor: pointer;
`;