import { useEffect, useState } from "react";
import styled from "styled-components";
import { countryNewData } from "../../../../../../../utils/countryData";
import CountryDropdown from "./country-dropdown";
import dropdown from '../assets/dropdown.svg';
import { useDashboard } from "../../../../../../../context/dashboard";
// import { useDashboard } from "../../../../context/dashboard";

const CountrySelected = styled.div`
display: flex;
align-items: center;
cursor: pointer;
position: relative;
z-index: 2;
`

const Flag = styled.img`
width: 22.667px;
height: 17px;
margin-right: 12px;
`;

const Name = styled.span`
color: rgba(28, 28, 28, 0.70);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.28px;
gap: 8.34px;
display: flex;
align-items: center;
`;

const DropdownButton = styled.img`
margin-top: 4px;
`;

function SelectedCountry({forDashboardSetup = false}) {
    const { dashboardState: {selectedCountry}, dashboardDispatch } = useDashboard()
    const [countrySelected, setCountrySelected] = useState(countryNewData.find((c) => c.adspyder_name === selectedCountry))
    const [activateDropdown, setActivateDropdown] = useState(false)
    const handleSelect = (country) => {
        setCountrySelected(country)
        dashboardDispatch({
            type: "SET_SELECTED_COUNTRY",
            value: country.adspyder_name
        })
    }
    console.log(countrySelected,'selectedCountry')
    
    useEffect(() => {
        setCountrySelected(countryNewData.find((c) => c.adspyder_name === selectedCountry))
    }, [selectedCountry])
    if(!selectedCountry || !countrySelected) return
    return ( 
        <CountrySelected onClick={() => setActivateDropdown(!activateDropdown)} >
            <Flag src={countrySelected.flag} alt={countrySelected.flag} draggable={false} />
            <Name>
                {countrySelected.official_name}
                <DropdownButton src={dropdown} draggable={false} />
            </Name>
            { activateDropdown && <CountryDropdown bottom={forDashboardSetup ? "30px" : "unset"} top={!forDashboardSetup ? "30px" : "unset"} left="-10px" currentlySelected={countrySelected} onSelect={(c) => handleSelect(c)} />}
        </CountrySelected>
     );
}

export default SelectedCountry;