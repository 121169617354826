import { useRef } from "react";
import { Keywords, KeywordsContainer, KeywordsInput, SeedKeywordsContainer, AddIcon, Instruction, Container, PropperKeyword, Keyword, DeleteKeyword, ContentContainer, Header, SuggestionContainer, SuggestionLabel } from "./SeedKeywords.styled";
import add from "../../../../assets/add.svg"
import removeKeyword from "../../../../assets/removeKeyword.svg"
import { useTextAdGenerator } from "../../../../context/textAdGeneration";
import Footer from "./Footer";

function SeedKeywordsPP() {
    const keywordsRef = useRef(null)
    const { textAdGeneratorState, textAdGeneratorDispatch } = useTextAdGenerator()

    const handleKeywordsValueChange = () => {
        if(keywordsRef.current){
            // textAdGeneratorDispatch({type: "SET_SEED_KEYWORDS", value: keywordsRef.current.value})
        }
    }

    const handleKeyDown = (event) => {
        let innerText = keywordsRef.current.value.trim();
        const isAlreadySelected = textAdGeneratorState.seedKeywordsList.some(
            (location) => location === innerText
          );
        if((event.key === "," && keywordsRef.current) || (event.key === "Enter" && innerText !== '')){
            keywordsRef.current.value.split(",").map((keyword) => {
                !isAlreadySelected && keyword !== '' && textAdGeneratorDispatch({
                    type: "ADD_SEED_KEYWORD",
                    value: keyword.trim()
                })
            })
            keywordsRef.current.value = ""
            event.preventDefault()
        }
    }

    const suggestionKeywords = () => {
        const keywords = textAdGeneratorState.seedKeywords
        const handleKeywordSelection = (keyword) => {
            textAdGeneratorDispatch({type: "ADD_SEED_KEYWORD", value: keyword})
        } 
        return keywords?.length > 0 && keywords?.map((keyword) => {
            if(!textAdGeneratorState.seedKeywordsList.includes(keyword))
            return (
                <KeywordsContainer onClick={() => handleKeywordSelection(keyword)} >
                    <Keywords>{keyword}</Keywords>
                    <AddIcon src={add} alt="add" />
                </KeywordsContainer>
            )
        })
    }

    const enteredKeywords = () => {
        const { seedKeywordsList } = textAdGeneratorState
        const handleRemoveKeyword = (index) => {
            textAdGeneratorDispatch({
                type: "REMOVE_SEED_KEYWORD",
                value: {index}
            })
        }
        return seedKeywordsList.map((keyword, index) => {
            return(
                <PropperKeyword>
                    <Keyword>{keyword}</Keyword>
                    <DeleteKeyword onClick={() => handleRemoveKeyword(index)} src={removeKeyword} alt="remove" />
                </PropperKeyword>
            )
        })
    }

    const handleContainerClick = () => {
        if(keywordsRef.current){
            keywordsRef.current.focus()
        }
    }

    const handleInputBlur = () => {
        if(keywordsRef.current?.value!==""){
            textAdGeneratorDispatch({type: "ADD_SEED_KEYWORD", value: keywordsRef.current.value})
            // if(keywordsRef.current.value === ",")
            //     keywordsRef.current.value = ""
        }
    }

    

    return ( 
        <ContentContainer>
            <Header>Edit seed keywords</Header>
            <SeedKeywordsContainer>
                <Container onClick={handleContainerClick} >
                    {enteredKeywords()}
                    <KeywordsInput placeholder="Enter Seed keywords (Eg: Coupons, Discounts)" onChange={handleKeywordsValueChange} ref={keywordsRef} onKeyDown={handleKeyDown} onBlur={handleInputBlur} />
                </Container>
                 {/* {!textAdGeneratorState.seedKeywordsList.length && ( */}
                <SuggestionContainer>
                    <SuggestionLabel>Suggestions:</SuggestionLabel>
                    {suggestionKeywords()}
                </SuggestionContainer>
            {/* )} */}
                <Instruction>Separate keywords with comas</Instruction>
            </SeedKeywordsContainer>
            <Footer />
        </ContentContainer>
     );
}

export default SeedKeywordsPP;