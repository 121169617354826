import React, {useEffect, useRef, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {
    useMutation
} from "@tanstack/react-query";
import {Helmet} from "react-helmet"

import {Container, Header, Content, CollectionContainer, CollectionListContainer, CollectionList, Project, ProjectContent, BreadCrumb, AdsContainer, ContentHeader, LoadButton, ResultsContainer} from "./index.styled"
import { useAuth } from '../../../../context/auth'
import { useAdGenrator } from '../context/adgenerator';
import {appClient} from '../../../../utils/axios-utils'
import FolderIcon from '../../../../../src/media/Dashboard/folderIcon.svg'
import SearchLoader from '../../../HOC/SearchLoader';
import AdCard from './AdCards';
import PromptPage from './PromptPage';

const getAllProjects = async (data) => {
    const config = {
        url: `/adgeneration/project/all`,
        method: "GET"
    }

    return appClient(config)
}

const getProjectAds = (data) => {
    const config = {
        url: "/adgeneration/project/adcopies",
        method: "POST",
        data
    };
  
    return appClient(config);
}

const TextAd = () => {
    const {authState, authDispatch} = useAuth()
    const {adGenratorState, adGenratorDispatch} = useAdGenrator()
    const location = useLocation()
    
    const {mutate: getProjectsMutate, isLoading: isProjectsLoading} = useMutation(getAllProjects, {
        onSuccess: (data) => {
            authDispatch({type: 'UPDATE_PROJECT_COLLECTIONS', value: data})
            
        },
        onError:  ((e)=>{
           
        })
    })

    const {mutate: getProjectAdsMutate, isLoading: isProjectAdsLoading} = useMutation(getProjectAds, {
        onSuccess: (data) => {
            adGenratorDispatch({type:'SET_PROJECT_ADS', value: data})
        },
        onError: (err) => {
            console.log(err)
        },
        onMutate: () => {
        }
    })

    const ToggleSelectedAds = (id) => {
        if(adGenratorState.selectedAds.includes(id)){
            let finalData = adGenratorState.selectedAds.filter((d)=>{
                return d != id
            })
            adGenratorDispatch({type: 'SET_SELECTED_ADS', value: finalData})
        }else{
            let finalData = [...adGenratorState.selectedAds, id]
            adGenratorDispatch({type: 'SET_SELECTED_ADS', value: finalData})
        }
    }

    const ClearData = () => {
        adGenratorDispatch({type:'SET_SELECTED_PROJECT', value: ""})
        adGenratorDispatch({type:'SET_PROJECT_ADS', value: []})
        adGenratorDispatch({type:'SET_SELECTED_ADS', value: []})
    }

    // useEffect(() => {
    
    //     const handleWindowUnload = (event) => {
    //       // Check if the user is on the /generate route
    //         // Display an alert message
    //         event.preventDefault();
    //         event.returnValue = ''; // For older browsers
    
    //         // Customize the alert message
    //         const confirmationMessage = 'Are you sure you want to leave this page? Your progress may be lost.';
    //         // eslint-disable-next-line no-alert
    //         return confirmationMessage;
    //     };
    
    //     window.onbeforeunload = handleWindowUnload;
    
    //     return () => {
    //       // Cleanup: remove the event listener when the component is unmounted
    //       window.onbeforeunload = null;
    //     };
    // }, []);

    useEffect(() => {
        if(adGenratorState.selectedProject.id){
            getProjectAdsMutate({projectId: adGenratorState.selectedProject.id})
        }
    }, [adGenratorState.selectedProject])

    useEffect(() => {
        // if(!authState.features?.isKeywordAnalysisAllowed){
        //     authDispatch({type: 'UPDATE_IS_PPC_RESTRICTED', value: true})
        // }
        // else{
            getProjectsMutate()
        // }
    }, [adGenratorState.searchMode])
    
    useEffect(() => {
        ClearData()
        // adGenratorDispatch({type:'SET_PROMPT_PAGE_OPEN', value: false})
        // window.onbeforeunload = (event) => {
        //     const e = event || window.event;
        //     // Cancel the event
        //     e.preventDefault();
        //     if (e) {
        //       e.returnValue = ''; // Legacy method for cross browser support
        //     }
        //     return ''; // Legacy method for cross browser support
        //   };
    }, [])

    if(!adGenratorState.isPromptPageOpen){
        return <Container>
            <Header>
                <h1>
                    AD Generator
                </h1>
                <h3>Create  best conversion based Ad Copy from Adspyder AI.</h3>

                {/* <SelectionContainer>
                    <h3>Choose an option to initiate ad creation</h3>
                    <ModeContainer>
                        <KeywordButtonContainer 
                            active={adGenratorState.searchMode === "saved ads"} 
                            onClick={() => {ClearData();adGenratorDispatch({type:'SET_SEARCH_MODE', value:'saved ads'})}}>
                            <input type="radio" checked={adGenratorState.searchMode === "saved ads" && "checked"} id="saved ads" name="mode"/>
                            <label for="saved ads">Use Saved Ads</label>
                        </KeywordButtonContainer>
                        <KeywordButtonContainer 
                            active={adGenratorState.searchMode === "sample collections"} 
                            onClick={() => {ClearData();adGenratorDispatch({type:'SET_SEARCH_MODE', value:'sample collections'})}}>
                            <input type="radio" checked={adGenratorState.searchMode === "sample collections" && "checked"} id="sample collections" name="mode"/>
                            <label for="sample collections">Sample Collections</label>
                        </KeywordButtonContainer>
                    </ModeContainer>
                </SelectionContainer> */}
            </Header>
            <Content>
                <CollectionContainer>
                    <ContentHeader>
                        <BreadCrumb>
                        <h2 onClick={ClearData}>Saved Ads</h2> 
                        {adGenratorState.selectedProject.id ? <span>{">"}</span> : ""}
                        {adGenratorState.selectedProject.id ? <span>{adGenratorState.selectedProject.name}</span>: ""}
                        </BreadCrumb>
                        {adGenratorState.selectedProject.id && <LoadButton isDisabled={adGenratorState.selectedAds.length == 0} onClick={()=>adGenratorDispatch({type:'SET_PROMPT_PAGE_OPEN', value: true})}>
                            Load Selected Ads
                        </LoadButton>}
                    </ContentHeader>
                    
                    <CollectionListContainer>
                        {isProjectsLoading || isProjectAdsLoading ? <div className='loader'>
                            <SearchLoader message={"Loading project Ads"}/>
                        </div>: adGenratorState.projectAds.length > 0 ? <AdsContainer>
                                {
                                    adGenratorState.projectAds.map((ad,idx)=>{
                                        return <AdCard ToggleSelectedAds={ToggleSelectedAds} ad={ad}/>
                                    })
                                }
                            </AdsContainer> : <CollectionList>
                            {authState.project.collections.length > 0 ? authState.project.collections.map((projectData, idx)=>{
                                return <Project onClick={()=>adGenratorDispatch({type:'SET_SELECTED_PROJECT', value: projectData})}>
                                    <img src={FolderIcon}/>
                                    <ProjectContent>
                                        <h3>{projectData.name}</h3>
                                        <span>{projectData.adsCount} items</span>
                                    </ProjectContent>
                                </Project>}
                            ) : <h3>No Saved Ads found</h3>}
                        </CollectionList>}
                    </CollectionListContainer>
                </CollectionContainer>
            </Content>
        </Container>
    }else{
        return <PromptPage/>
    }
}

export default TextAd