import styled from "styled-components";

export const CustomizeContainer = styled.div`
    width: 280px;
    height: 100%;
    border-left: 1px solid #DADFE4;
    background: #FFF;
    position: absolute;
    right: 0;
    top: 0;
    margin-left: 109px;
    padding-left: 16px;
`;

// export const Header = styled.div`
//     height: 58px;
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// `;

// export const Item = styled.div`
//     color: #1C1C1C;
//     font-family: Inter;
//     font-size: 16px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 150%; /* 24px */
// `;

export const Header = styled.div`
    // margin: 30px 0 0 84px;
    color: #1C1C1C;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
`;

export const AdDetailsLabel = styled.div`
    margin: 33px 0 6px 0;
    color: #1C1C1C;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
`;

export const InputsList = styled.div`
    
`;

export const Container = styled.div`
    margin-top: 8px;
    width: 248px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px 0 20px;
    border-radius: 5px;
    border: 1px solid rgba(218, 223, 228, 0.50);
`;

export const InputName = styled.div`
    color: #1C1C1C;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
`;

export const ChangeButton = styled.div`
color: #1679DB;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 18px */
cursor: pointer;
`;

export const UpdateButton = styled.div`
    cursor: pointer;
    margin: 24px 0 0 3px;
    width: 245px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    border-radius: 6px;
    background: ${(props) => props.isActive ? "#FF711E" : "#E7E7E7"} ;
`;

export const NavContainer = styled.div`
    display: flex;
    justify-content: space-around;
    height: 58px;
    align-items: center;
`;

export const NavItem = styled.div`
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    cursor: pointer;
    p{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
`;

export const HighlightLine = styled.div`
    width: 100%;
    height: ${props => props.isActive ? "4px" : "2px"};
    background: ${props => props.isActive ? "#FF711E" : "rgba(218, 223, 228, 0.50)"};
    /* visibility: ${(props) => props.isActive ? "auto" : "hidden"}; */
`;
export const AdGroupBtn = styled.div`
width: 245px;
height: 40px;
border-radius: 6px;
background: #393939;
color: #FFF;
font-family: Inter;
font-size: 14px;
font-weight: 500;
margin-top: 32px;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
`