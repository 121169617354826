import { useKeywordFunnelAnalysis } from "../../context/keywordFunnelAnalysis";
import { Count, Keyword, KeywordContainer, Wrapper,NoDataContainer } from "./CtaAndKeywords.styled";
import Skeleton from "react-loading-skeleton";
import Star from '../../assets/Star.svg';

function CTAs({isFunnelKeywordLoading}) {
    const { keywordFunnelAnalysisState } = useKeywordFunnelAnalysis()
    const { funnelCtaData,activeFunnelType } = keywordFunnelAnalysisState

    return ( 
        <Wrapper>
            {isFunnelKeywordLoading ?
                <Skeleton height= {"212px"} borderRadius= {"16px"} width= {"918px"}/>
            :
            keywordFunnelAnalysisState?.funnelKeywordData?.funnelData && keywordFunnelAnalysisState?.funnelKeywordData?.funnelData?.map((item) => {
                const { id } = item
                const isActive = id === activeFunnelType.id
                const filteredCta = isActive && item?.cta ? item.cta : [];
                const maxDocCount = Math.max(...filteredCta.map(data => data.doc_count));
                const isUniqueAndOne = filteredCta.every(data => data.doc_count === 1);
                return(
                    <>
                    {(isActive && item?.cta.length > 0) ? item?.cta.map((data,index)=>(
                    <KeywordContainer key={index} >
                        <Keyword>{data.key}</Keyword>
                        <Count>{data.doc_count}</Count>
                        {!isUniqueAndOne && data.doc_count === maxDocCount && <img src={Star} alt="Star"/>}
                    </KeywordContainer>
                ))
                :isActive &&
                <NoDataContainer>No Data Found</NoDataContainer>
                }
                </>
            )
        })}
        </Wrapper>
     );
}

function ImportantKeywords({isFunnelKeywordLoading}) {
    const { keywordFunnelAnalysisState } = useKeywordFunnelAnalysis()
    const { funnelKeywordsData,activeFunnelType } = keywordFunnelAnalysisState

    return ( 
        <Wrapper>
             {isFunnelKeywordLoading ?
                <Skeleton height= {"212px"} borderRadius= {"16px"} width= {"918px"}/>
            :
            keywordFunnelAnalysisState?.funnelKeywordData?.funnelData && keywordFunnelAnalysisState?.funnelKeywordData?.funnelData?.map((item) => {
                const { id } = item
                const isActive = id === activeFunnelType.id
                const filteredImportant = isActive && item?.keywordCount ? item.keywordCount : [];
                const maxImportant = Math.max(...filteredImportant.map(data => data.doc_count));
                const isUniqueImportant = filteredImportant.every(data => data.doc_count === 1);
                return(
                    <>
                    {(isActive && item?.keywordCount.length > 0) ? item?.keywordCount.map((data,index)=>(
                    <KeywordContainer key={index} >
                        <Keyword>{data.key}</Keyword>
                        <Count>{data.doc_count}</Count>
                        {!isUniqueImportant && data.doc_count === maxImportant && <img src={Star} alt="Star"/>}
                    </KeywordContainer>
                )):isActive &&
                <NoDataContainer>No Data Found</NoDataContainer>
                }
                </>
          )
      })}
        </Wrapper>
     );
}
export { CTAs, ImportantKeywords };