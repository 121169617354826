import styled from "styled-components"


export const SurveyContainer = styled.div`

    progress {
        background: #F2F2F2;
        border-radius: 14px;
        height: 6px;
    }
    /* webkit */
    progress::-webkit-progress-bar {
        background: transparent;
    }  
    progress::-webkit-progress-value {  
        border-radius: 10px;
        background: #1A68C2;
    } 

    width: 100%;
    /* @media screen and (max-width: ${props => props.theme.breakPoints.extraLargeScreen}) {
        
    }
    @media screen and (max-width: 1500px) {
        width: 344px;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.largeScreen}) {
        
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.desktop}) {
        
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {
        width: 320px;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileSmall}) {
        width: 280px;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileExtraSmall}) {
        width: 280px;
    } */
`; 

export const ProgressHolder = styled.div`
    width: 100%;
    height: 20px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;

    progress {
        background: #F2F2F2;
        border-radius: 14px;
        height: 6px;
    }
    /* webkit */
    progress::-webkit-progress-bar {
        background: transparent;
    }  
    progress::-webkit-progress-value {  
        border-radius: 10px;
        background: #FF711E;
    } 

    @media screen and (max-width: ${props => props.theme.breakPoints.extraLargeScreen}) {
        
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.largeScreen}) {
        margin-bottom: 20px;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.desktop}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileSmall}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileExtraSmall}) {

    } 
`;

export const Progress = styled.progress`
    width: 100%;

    /* @media screen and (max-width: ${props => props.theme.breakPoints.extraLargeScreen}) {
        
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.largeScreen}) {
        margin-bottom: 20px;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.desktop}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileSmall}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileExtraSmall}) {

    } */
`; 
 

export const AboutSection = styled.div`

`; 

export const CompetitorSection = styled.div`

`;  

export const QuestionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    p{
        width: 80%;
        color: #2E2E2E;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.extraLargeScreen}) {
        
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.largeScreen}) {
        margin-bottom: 28px;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.desktop}) {
        
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileSmall}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileExtraSmall}) {

    }
`;

export const Step = styled.div`
    font-size: 12px;
    color: #2E2E2E;
    font-weight: 300;
`;
