import styled from "styled-components";

export const Container = styled.div`
    

`;

export const TimeOptionsLst = styled.div`
    padding: 12px 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 8px;
`;

export const TimeOption = styled.div`
    font-size: 13px;
    font-weight: 400;
    color: #1C1C1C;
    line-height: 20px;
    letter-spacing: -0.13px;
    display: flex;
    align-items: center;
    gap: 3px;
    cursor: pointer;
    width: 50%;
    label{
        cursor: pointer;
    }
`;