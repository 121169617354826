import React, {useEffect, useState} from 'react'

import {
    Container,
    SearchContainer,
    ResultsContainer,
    Wrapper,
    HeadingWrapper,
    UrlInput,
    TrackButton,
    InputContainer,
    ClearButton,
    HeadingLeft,
    HeaderRight,
    HeadLeftWrap,
    RightIcons,
    PlaceholderWithInput,
    HttpsLabel,
    ErrorIcon,
    ErrorBox
} from "./index.styled"

import CloseIcon from "../../../../media/Dashboard/detailsPaneClose.svg";
import { useAuth } from '../../../../context/auth';

import { useLandingPageAnalysis } from '../context/landingPageAnalysis';
import KeywordInputModal from './components/KeywordInputModal';
import KeywordTable from './components/keywordTable/components/keywordTable';
import FeatureRestrictionPopup from '../../../components/featureRestrictionPopup';
import { useLandingPageAnalysisInfo } from '../hooks/useLandingPageAnalysisInfo';
import errorIcon from "./assets/errorIcon.svg"
import overview from "./assets/overview.png"

function isValidDomain(url) {
    const domainAndUrlRegEx = /^(((http|https):\/\/|)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?)$/;

    return domainAndUrlRegEx.test(url);
}

const AdspyHome = () => {
    const {landingPageAnalysisState, landingPageAnalysisDispatch} = useLandingPageAnalysis()

    const useLandingPageAnalysisInfoHook = useLandingPageAnalysisInfo()
    const {authState, authDispatch} = useAuth()
    const [inputFocussed, setInputFocussed] = useState(false)
    const [error, setError] = useState(false)
    useEffect(() => {
        useLandingPageAnalysisInfoHook.getLandingPageHistoryMutation()
    }, [])

    // console.log(authState)

    const eventEnterHandle = (e) =>{
        if (e.key === 'Enter') {
            // if(!authState.features?.isKeywordAnalysisAllowed){
            //     authDispatch({type: 'UPDATE_IS_PPC_RESTRICTED', value: true})
            // } else {
                landingPageAnalysisDispatch({type: 'SET_URL_INPUT_LIST', value: [{ url: landingPageAnalysisState.urlInput, event: {} }]})
                landingPageAnalysisDispatch({type: 'SET_URL_INPUT_MODAL_OPEN', value: true})
            // }
        }
    }

    const headingText = "Landing Page Analysis"
    const descriptionText = "View landing page screenshots for any website on your preferred dates. Journey through web evolution effortlessly!"


    const toolTipFunction = ()  =>{
        return (
            <div>
                <div style={{marginBottom: '16px',fontSize: '14px', color: 'var(--black-100, #1C1C1C)',fontWeight: '500'}}>How to use:</div>
                <ul style={{listStyleType: 'decimal',marginLeft: '16px'}}>
                    <li  style={{marginBottom: '16px'}}>Enter the domain name in the search bar and click on the “Generate” button. </li>
                    <li  style={{marginBottom: '16px'}}>In the “Define Ad Goal” section, click on the desired ad goal you want to proceed with. The options are “Sales”, “Leads”, or “Website Traffic”. Click on the “Next” button. </li>
                    <li  style={{marginBottom: '16px'}}>In the “Enter Target Location” section, type in the location city, state or country and select the location. Click on the “Next” button. </li>
                    <li  style={{marginBottom: '16px'}}>In the “Add brand description” section, type in your details regarding the product/brand/service. Provide more information for optimum results from AdSpyder’s AI. Click on the “Next” button. </li>
                    <li  style={{marginBottom: '16px'}}>In the “Enter Seed Keywords” section, type in your target seed keywords separated by commas. Click on the “Next” button. You will be directed to the Text Ad Generation status page, where you can track the progress of the ad generation process.
                    </li>
                    <li  style={{marginBottom: '16px'}}>In the results page, you can view generated ads for your input. You can switch between ad groups using the Ad Groups Tab. Clicking on the desired Ad Group tab will show you details on generated titles, descriptions and keywords needed to run the ad group. </li>
                    <li  style={{marginBottom: '16px'}}>To check out the titles generated in the ad group, click on the “Titles” tab. To copy the title, simply hover over the title and click on the copy icon at the end of the title. </li>
                    <li  style={{marginBottom: '16px'}}>To check out the descriptions generated in the ad group, click on the “Descriptions” tab. To copy the description, simply hover over the description and click on the copy icon at the end of the description. </li>
                    <li  style={{marginBottom: '16px'}}>To check out the keywords generated in the ad group, click on the “Keywords” tab. To copy the keyword, simply hover over the keyword and click on the copy icon at the end of the keyword. </li>
                    <li  style={{marginBottom: '16px'}}>To export the generated ad copy to your connected Google Ad Account, simply click on the “Export” tab at the top right corner of the workspace and click on the “Publish Ad Group” button. (Note: Google Ad Account needs to be connected with AdSpyder to export directly. See Ad Campaigns feature to know more on how to link your Google AD Account to your AdSpyder account) </li>
                    <li  style={{marginBottom: '16px'}}>To make any changes in the generated ads, or to refine your ad copy, click on the “Customize” tab at the top right corner of the workspace. To change any input to AdSpyder’s AI, click on the “Change” option at the end of the required input to edit it. After finishing, click on the “Save” button. After finishing the edits, click on the “Update” button to get new and improved results based on your edits to the inputs. </li>
                </ul>
            </div>
        )
    }

    const tooltipStyle = {
        marginTop: '100px'
    };
    
    const overlayStyle = {

    };
  
    const overlayInnerStyle = {
        backgroundColor: '#FFF7E8',
        color: 'rgba(28, 28, 28, 0.80)',
        padding: '24px 20px 44px 20px',
        fontSize: '14px',
        marginTop: '120px',
        width: '400px',
        maxHeight: '510px',
        paddingBottom: '44px',
        overflowY: 'auto'
    };    

    return <Container>
                {
                    authState.isPPCRestrictedPopup && 
                    <ResultsContainer>
                        <FeatureRestrictionPopup feature={"Landing Page Analysis"} showCloseBtn={true}/>
                    </ResultsContainer>
                }
                {
                    landingPageAnalysisState.urlInputModalOpen && 
                    <ResultsContainer>
                        <KeywordInputModal/>
                    </ResultsContainer>
                }
                {!landingPageAnalysisState.landingTableOpen && <Wrapper isNavCollapsed={authState.isNavCollapsed}>
                    <HeadingWrapper>
                    <HeadingLeft>
                        <HeadLeftWrap>
                        <h2>{headingText}</h2>
                        {/* <Tooltip 
                            trigger={['hover']}  
                            style={tooltipStyle}
                            overlayStyle={overlayStyle}
                            overlayInnerStyle={overlayInnerStyle} 
                            showArrow={false}
                            arrowContent={null}
                            overlay={toolTipFunction()}
                        >
                            <HowItWork>Quick guide</HowItWork>
                        </Tooltip> */}
                        </HeadLeftWrap>
                        <p>{descriptionText}</p>
                    </HeadingLeft>
                    <HeaderRight>
                        <img src={overview} />
                    </HeaderRight>
                </HeadingWrapper>
                    <SearchContainer isNavCollapsed={authState.isNavCollapsed}>
                        {/* <InputContainer isFocussed={inputFocussed}>
                            <img src={SearchIcon}/>
                            <UrlInput
                                value={landingPageAnalysisState.urlInput}
                                placeholder='Enter a website URL' 
                                onChange={(e)=>{
                                    landingPageAnalysisDispatch({type: 'SET_URL_INPUT', value: e.target.value})
                                }}    
                                onKeyDown={(e) => {
                                    eventEnterHandle(e)
                                }}   
                            />
                            {landingPageAnalysisState.urlInput.length > 0 && <ClearButton  onClick={()=>{landingPageAnalysisDispatch({type: 'SET_URL_INPUT', value: ""})}} src={CloseIcon}/>}
                        </InputContainer> */}
                        <PlaceholderWithInput>
                            <HttpsLabel>https://</HttpsLabel>
                            <InputContainer isFocussed={inputFocussed} hasError={error}>
                                <UrlInput
                                    value={landingPageAnalysisState.urlInput}
                                    placeholder='Enter a website URL' 
                                    onChange={(e)=>{
                                        landingPageAnalysisDispatch({type: 'SET_URL_INPUT', value: e.target.value})
                                        setError(!isValidDomain(e.target.value))
                                        e.target.value = e.target.value.startsWith("https://") ? e.target.value.split("https://")[1] : e.target.value
                                    }}    
                                    onKeyDown={(e) => {
                                        eventEnterHandle(e)
                                    }}   
                                    onFocus={() => setInputFocussed(true)}
                                    onBlur={() => setInputFocussed(false)}
                                />
                                <RightIcons>
                                    {landingPageAnalysisState.urlInput.length > 0 && <ClearButton  onClick={()=>{landingPageAnalysisDispatch({type: 'SET_URL_INPUT', value: ""})}} src={CloseIcon}/>}
                                    {error && <ErrorIcon src={errorIcon} alt=""/>}
                                </RightIcons>
                                
                            </InputContainer>
                        </PlaceholderWithInput>
                        <TrackButton onClick={()=>{
                            // if(!authState.features?.isKeywordAnalysisAllowed){
                            //     authDispatch({type: 'UPDATE_IS_PPC_RESTRICTED', value: true})
                            // }else{
                                landingPageAnalysisDispatch({type: 'SET_URL_INPUT_LIST', value: [{ url: landingPageAnalysisState.urlInput, event: {} }]})
                                landingPageAnalysisDispatch({type: 'SET_URL_INPUT_MODAL_OPEN', value: true})
                            // }
                        }}
                        >
                            View Screenshot
                        </TrackButton>
                    </SearchContainer>
                     {error && <ErrorBox>Enter a valid url</ErrorBox>}
                </Wrapper>}
               {landingPageAnalysisState.landingTableOpen && <KeywordTable/>}
            </Container>
};
    

export default AdspyHome