import styled from "styled-components"


export const Container = styled.div`
    width: 100%;
    max-width: 966px;
    height: auto;
    background: #FFF7E8;
    padding-bottom: 23px;
`
export const EditWrap = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 12px 14px 0 40px;
`
export const TrackingDiv = styled.div`
    color: #7D7D7D;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    margin-top: 12px;
    display: flex;
    gap: 8px;
`

export const ProductWrap = styled.div`
    display: flex;
    margin: 0 40px;
    justify-content: space-between;
    @media screen and (max-width: ${props => props.theme.breakPoints.tab}) {
        flex-wrap: wrap;
        row-gap: 10px;
    }
`
export const ProductNameWrap = styled.div`
`

export const ProductName = styled.div`
    color: #1C1C1C;
    font-family: 'Inter';
    font-size: 38px;
    font-weight: 600;
    padding: 7px 0;
`
export const UpdatedWrap = styled.div`
    display: flex;
    column-gap: 8px;
    margin-top: 7px;
`
export const RefreshWrap = styled.div`
`
export const UpdatedDiv = styled.div`
    color: rgba(28, 28, 28, 0.30);
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
`;



export const BackButton = styled.img`
    cursor: pointer;
`;

export const TrackKeywordButton = styled.div`
    height: 44px;
    border-radius: 6px;
    background: #FF711E;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    gap: 8px;
    cursor: pointer;
`;
export const ResultsContainer = styled.div`
    display: flex;
    gap: 12px;
    height: calc(100vh - 245px);
    background: #006ecc00;
    position: absolute;
    left: 0;
    top: 0;
    .loader{
        height: 200px;
        margin: auto;
    }
`;