import statics from "../../statics";
import { InputContainer, Icon, ExamplesContainer, Label, Example, Category, DropdownContainer, DropdownTitle, DropdownItem, CategoryIcon, Container, Wrapper, HeadingWrapper, HeadingLeft, HeadLeftWrap, HeaderRight, HowItWork, SearchContainer, TrackButton } from "./ChoosingCategory.styled";
import Apps from "../../assets/apps.svg"
import imageAdGeneration from "../../assets/imageAdGeneration.svg"

import { useRef, useState } from "react";
import { useImageAdGenerator } from "../../context/imageAdGeneration";
import History from "./history";
import Tooltip from 'rc-tooltip';
import { useAuth } from "../../../../../../context/auth";


function ChoosingCategory() {
    const { enteringDomain: {header, description, placeHolder, examplesLabel, example, generate, categories}, pages } = statics
    
    const categoryRef = useRef(null)
    const [isDropdownActive, setDropDownActive] = useState(false)
    const { imageAdGeneratorState, imageAdGeneratorDispatch } = useImageAdGenerator()
    const { authState: {isNavCollapsed} } = useAuth()
    const [inputFocussed, setInputFocussed] = useState(false)
    const onNext = () => {
        if(imageAdGeneratorState.category){
            imageAdGeneratorDispatch({type: "UPDATE_CURRENT_PAGE", value: pages[1]})
        }else{
            alert("Choose a category")
        }
    }

    const handleDropdownActivation = () => {
        setDropDownActive(!isDropdownActive)
    }

    const selectCategory = (category) => {
        imageAdGeneratorDispatch({type: "SET_CATEGORY", value: category})
        setDropDownActive(false)
    }

    const renderDropdown = () => {

        return(
            <DropdownContainer isActive={isDropdownActive}>
                <DropdownTitle>Categories</DropdownTitle>
                {
                    categories.map((c) => {
                        return(
                            <DropdownItem onClick={() => selectCategory(c)} >
                                {c.icon && <CategoryIcon src={c.icon} alt="icon" />}
                                {c.name}
                            </DropdownItem>
                        )
                    })
                }
            </DropdownContainer>
        )
    }

    const headingText = "Image Ad Generation"
    const descriptionText = "Revolutionize your marketing with our advanced image ad generation technology. By utilizing state-of-the-art algorithms, you can produce visually captivating advertisements that significantly boost your brand's impact. This innovative tool not only enhances aesthetic appeal but also ensures your ads resonate deeply with your target audience."


    const toolTipFunction = ()  =>{
        return (
            <div>
                <div style={{marginBottom: '16px',fontSize: '14px', color: 'var(--black-100, #1C1C1C)',fontWeight: '500'}}>How to use:</div>
                <ul style={{listStyleType: 'decimal',marginLeft: '16px'}}>
                    <li  style={{marginBottom: '16px'}}>Select a category from the dropdown menu provided and click on the “Next” button. </li>
                    <li  style={{marginBottom: '16px'}}>In the “Primary Goal of Ad” section, click on the desired ad goal. In the “Please specify the goal you want the ad to achieve” section, type in the specifics of your ad goal. After this, click on the “Next” button. </li>
                    <li  style={{marginBottom: '16px'}}>In the “Enter Target Audience” section, click on the age group of your target audience in the “Age Group” section. Select the gender of the target audience in the “Gender” section. After selecting, click on the “Next” button. </li>
                    <li  style={{marginBottom: '16px'}}>In the “Brand Details” section, Type in your brand name in the “Brand Name” section. Afterwards, type in the details of your brand/product/service you want to create ads for, in the “Brand/product/service description” section. These inputs will provide information to AdSpyder’s AI to enable it to provide better results. Click on the “Next” button after completing this. </li>
                    <li  style={{marginBottom: '16px'}}>In the result page, you can select your desired ad template from the top carousel section in the workspace. You can customise the ad template using the “Customise” tab from the side panel. Here are the changes you can do with your generated image ad: 
                        <ul style={{listStyleType: 'lower-alpha',marginLeft: '16px', marginTop: '16px'}}>
                            <li style={{marginBottom: '16px'}}>
                                To change the colour of your image ad, click on the desired colour option from the “Edit Colour” section in the side panel of your results page. 
                            </li>
                            <li style={{marginBottom: '16px'}}>
                                To add an image to your image ad, click on the dropdown in the “Image” section in the side panel. For selecting image from stock photos, select the “Stock Images” option. In the popup window, search for the desired image in the search bar and select the desired image to be used in the image ad. After selecting, close the popup window. 
                            </li>
                            <li style={{marginBottom: '16px'}}>
                                For uploading existing image, select the “Upload” option in the “Image” dropdown menu. Upload your desired image from your device and use it in the image ad easily. 
                            </li>
                            <li style={{marginBottom: '16px'}}>
                                For adding AI-generated image onto your image ad, select the “AI Images” option in the “Images” drop-down menu. Select the desired image, or type in the desired image requirement in the search bar to generate custom AI images for your needs. Select the image and close the popup window. 
                            </li>
                            <li style={{marginBottom: '16px'}}>
                                To add a logo in your image ad, click on the dropdown menu of “Logo” section and select “Upload” option. Upload your logo to add it on to your image ad for better brand visibility. 
                            </li>
                            <li style={{marginBottom: '16px'}}>
                                To edit any text in the image ad generated, go to the relevant sections in the side panel and edit the text from the text box.  
                            </li>
                        </ul>
                    </li>
                    <li  style={{marginBottom: '16px'}}>To export the image ad generated, go to the “Export” tab and select the relevant option. You can export the image ad directly to your connected Google or Meta ad account using the relevant button in the “Publish” section. You can also download the image ad onto your device using the “PNG” option in the “Download” section in the side panel. </li>
                    <li  style={{marginBottom: '16px'}}>To edit or make changes to the inputs to the Image Ad Generator AI, go to the “Details” tab in the side panel. Click on the “Change” option against the input you want to change and click on “Save” button after making the necessary changes. After editing is finished, click on the “Update” button to start the Image Ad generation process again. </li>
                    
                </ul>
            </div>
        )
    }

    const tooltipStyle = {
        marginTop: '100px'
    };

  
    const overlayInnerStyle = {
        backgroundColor: '#FFF7E8',
        color: 'rgba(28, 28, 28, 0.80)',
        padding: '24px 20px 44px 20px',
        fontSize: '14px',
        marginTop: '120px',
        width: '400px',
        maxHeight: '510px',
        paddingBottom: '44px',
        overflowY: 'auto'
    }; 

    return(
        <Container>
            <Wrapper isNavCollapsed={isNavCollapsed}>
                <HeadingWrapper>
                    <HeadingLeft>
                        <HeadLeftWrap>
                        <h2>{headingText}</h2>
                        <Tooltip 
                            trigger={['hover']}  
                            style={tooltipStyle}
                            overlayInnerStyle={overlayInnerStyle} 
                            showArrow={false}
                            arrowContent={null}
                            overlay={toolTipFunction()}
                        >
                            <HowItWork>Quick guide</HowItWork>
                        </Tooltip>
                        </HeadLeftWrap>
                        <p>{descriptionText}</p>
                    </HeadingLeft>
                    <HeaderRight>
                        <img src={imageAdGeneration} />
                    </HeaderRight>
                </HeadingWrapper>
                <SearchContainer isNavCollapsed={isNavCollapsed}>
                    <InputContainer isFocussed={inputFocussed} onClick={handleDropdownActivation}>
                        <Icon src={Apps} alt="icon" />
                        <Category onChange={(e)=> imageAdGeneratorDispatch({type: "SET_CATEGORY", value: e.target.value})} value={imageAdGeneratorState.category} ref={categoryRef} placeholder="Enter a category"
                        onFocus={() => setInputFocussed(true)}
                        onBlur={() => setInputFocussed(false)}
                        />
                        {/* <DropdownIcon src={Dropdown} isActive={!isDropdownActive} alt="dd" />
                        {renderDropdown()} */}
                        <ExamplesContainer>
                            <Label>{examplesLabel}</Label>
                            {
                                example.map((eg)=>{
                                    return <Example onClick={()=>imageAdGeneratorDispatch({type: "SET_CATEGORY", value: eg})}>{eg}</Example>
                                })
                            }
                        </ExamplesContainer>
                    </InputContainer>
                    <TrackButton isActive={imageAdGeneratorState.category?.length > 0} onClick={onNext} >{generate}</TrackButton>
                </SearchContainer>
            </Wrapper>
            <History/>
        </Container>
    )
}

export default ChoosingCategory;