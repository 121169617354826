import React, { useMemo } from 'react'
import { useReducer, createContext, useContext } from 'react'

import produce from 'immer'

const initialState = {
    template: {
      htmlString:'',
      variants:[]
    }
  }
  
  const ImageAdGenerationReducer = produce((draft, action) => {
    switch (action.type) {
      case 'UPDATE_TEMPLATE':
        draft.template = action.value
        break

      case 'UPDATE_HTML':
        draft.template.htmlString = action.modifiedHtml
        break

      default:
        break
    }
  })
  
  const useImageAdGenerationReducer = () => {
    return useReducer(ImageAdGenerationReducer, initialState)
  }
  
  const ImageAdGeneration = createContext()
  
  export const ImageAdGenerationProvider = ({ children }) => {
    const [state, dispatch] = useImageAdGenerationReducer()
  
    const value = useMemo(() => ({
      imageAdGenerationState: state,
      imageAdGenerationDispatch: dispatch
    }), [state, dispatch])
  
    return <ImageAdGeneration.Provider value={value}>
      {children}
    </ImageAdGeneration.Provider>
  }
  
  export const useImageAdGeneration = () => {
    return useContext(ImageAdGeneration)
  }
