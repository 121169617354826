import styled from "styled-components";

export const TitlesListContainer = styled.div`
    width: 100%;
    border-radius: 4px;
    border: 1px solid #DADFE4;
    background: #FFF;
    height: 65%;
    overflow-y: auto;

    height: ${(props) => props.fromOverview ? "unset" : "calc( 100% - 24px - 36px - 46px - 114px - 26px - 38px - 3px - 5px)"};
    overflow-y: auto;
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const TitleContainer = styled.div`
    height: 48px;
    width: 100%;
    position: relative;
    border-bottom: 1px solid #DADFE4;
    display: flex;
    align-items: center;
    padding-left: 24px;
    cursor: pointer;
`;

export const Title = styled.div`
    color: #1C1C1C;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
`;

export const CopyButton = styled.img`
    position: absolute;
    top: 20px;
    right: 24px;
    visibility: hidden;
`;