import { appClient } from "../../../../../../utils/axios-utils";
import { useMutation } from "@tanstack/react-query";
import { useGetUserInfo } from "../../../../../hooks/useGetUserInfo";
import { useSearch } from "../../context/search";
import { useLinkedin } from "../context/linkedin";
import { useAuth } from "../../../../../../context/auth";


const keywordBoardFilterMatch = async (data) => {
    const config = {
        url: `/search/linkedin/keyword/broad/filter`,
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordPhraseFilterMatch = async (data) => {
    const config = {
        url: `/search/linkedin/keyword/phrase/filter`,
        method: "POST",
        data
    }

    return appClient(config)
}

const urlDomainFilterMatch = async (data) => {
    const config = {
        url: "/search/linkedin/url/domain/filter",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlTextFilterMatch = async (data) => {
    const config = {
        url: "/search/linkedin/url/text/filter",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlExactFilterMatch = async (data) => {
    const config = {
        url: "/search/linkedin/url/exact/filter",
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordBroadMatch = async (data) => {
    const config = {
        url: "/search/linkedin/keyword/broad",
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordPhraseMatch = async (data) => {
    const config = {
        url: "/search/linkedin/keyword/phrase",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlDomainMatch = async (data) => {
    const config = {
        url: "/search/linkedin/url/domain",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlExactMatch = async (data) => {
    const config = {
        url: "/search/linkedin/url/exact",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlTextMatch = async (data) => {
    const config = {
        url: "/search/linkedin/url/text",
        method: "POST",
        data
    }

    return appClient(config)
}

const getCrawledData = async (data) => {
    const config = {
        url: "/search/linkedin/keyword/phrase/realtime",
        method: "POST",
        data
    }

    return appClient(config)
}


export const useLinkedinInfo = () => {
    const { linkedinState, linkedinDispatch } = useLinkedin()
    const {searchState, searchDispatch} = useSearch()
    const { authState, authDispatch } = useAuth()

    const userInfoHook = useGetUserInfo()

    const { mutate: keywordBoardFilterMutate, isLoading: isKeywordBoardFilterLoading } = useMutation(keywordBoardFilterMatch, {
        onSuccess: (data) => {
            linkedinDispatch({ type: "SET_ADS", value: data.documents })
            linkedinDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            linkedinDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                linkedinDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                linkedinDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                linkedinDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                linkedinDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                linkedinDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                linkedinDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }
            linkedinDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            linkedinDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const { mutate: keywordPhraseFilterMutate, isLoading: isKeywordPhraseFilterLoading } = useMutation(keywordPhraseFilterMatch, {
        onSuccess: (data) => {
            linkedinDispatch({ type: "SET_ADS", value: data.documents })
            linkedinDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            linkedinDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                linkedinDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                linkedinDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                linkedinDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                linkedinDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                linkedinDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                linkedinDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }
            linkedinDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            linkedinDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const { mutate: urlDomainFilterMutate, isLoading: isUrlDomainFilterLoading } = useMutation(urlDomainFilterMatch, {
        onSuccess: (data) => {
            linkedinDispatch({ type: "SET_ADS", value: data.documents })
            linkedinDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            linkedinDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                linkedinDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                linkedinDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                linkedinDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                linkedinDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                linkedinDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                linkedinDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            linkedinDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            linkedinDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const { mutate: urlTextFilterMutate, isLoading: isUrlTextFilterLoading } = useMutation(urlTextFilterMatch, {
        onSuccess: (data) => {
            linkedinDispatch({ type: "SET_ADS", value: data.documents })
            linkedinDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            linkedinDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                linkedinDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                linkedinDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                linkedinDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                linkedinDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                linkedinDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                linkedinDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            linkedinDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            linkedinDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const { mutate: urlExactFilterMatchMutate, isLoading: isUrlExactFilterLoading } = useMutation(urlExactFilterMatch, {
        onSuccess: (data) => {
            linkedinDispatch({ type: "SET_ADS", value: data.documents })
            linkedinDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            linkedinDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                linkedinDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                linkedinDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                linkedinDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                linkedinDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                linkedinDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                linkedinDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            linkedinDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            linkedinDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const {mutate: keywordBroadMutate, isLoading: isKeywordBroadLoading} = useMutation(keywordBroadMatch, {
        onSuccess: (data) => {
            linkedinDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})

            // Update search results
            linkedinDispatch({type: "SET_ADS", value: data.documents})
            linkedinDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            linkedinDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                linkedinDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                linkedinDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                linkedinDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                linkedinDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                linkedinDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                linkedinDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }
            linkedinDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            linkedinDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const {mutate: keywordPhraseMutate, isLoading: isKeywordPhraseLoading} = useMutation(keywordPhraseMatch, {
        onSuccess: (data) => {
            linkedinDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})

            // Update search results
            linkedinDispatch({type: "SET_ADS", value: data.documents})
            linkedinDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            linkedinDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                linkedinDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                linkedinDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                linkedinDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                linkedinDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                linkedinDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                linkedinDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }
            linkedinDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            linkedinDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const {mutate: urlDomainMutate, isLoading: isUrlDomainLoading} = useMutation(urlDomainMatch, {
        onSuccess: (data) => {
            linkedinDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})

            // Update search results
            linkedinDispatch({type: "SET_ADS", value: data.documents})
            linkedinDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            linkedinDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})
    
            if(data.documents && data.documents.length > 0){
                linkedinDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                linkedinDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                linkedinDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                linkedinDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                linkedinDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                linkedinDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            linkedinDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            linkedinDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })
    
    const {mutate: urlExactMutate, isLoading:isUrlExactLoading} = useMutation(urlExactMatch, {
        onSuccess: (data) => {
            linkedinDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})
            // Update search results
            linkedinDispatch({type: "SET_ADS", value: data.documents})
            linkedinDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            linkedinDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})
    
            if(data.documents && data.documents.length > 0){
                linkedinDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                linkedinDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                linkedinDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                linkedinDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                linkedinDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                linkedinDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            linkedinDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            linkedinDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })
    
    const {mutate: urlTextMutate, isLoading: IsUrlTextLoading} = useMutation(urlTextMatch, {
        onSuccess: (data) => {
            linkedinDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})
    
            // Update search results
            linkedinDispatch({type: "SET_ADS", value: data.documents})
            linkedinDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            linkedinDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})
    
            if(data.documents && data.documents.length > 0){
                linkedinDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                linkedinDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                linkedinDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                linkedinDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                linkedinDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                linkedinDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            linkedinDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            linkedinDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const {mutate: getCrawledDataMutate, isLoading: isGetCrawledDataLoading} = useMutation(getCrawledData, {
        onSuccess: (data) => {
            linkedinDispatch({ type: "SET_ADS", value: data.documents})
            linkedinDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            linkedinDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                linkedinDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                linkedinDispatch({ type: "UPDATE_SEARCHED", value: false })
            }

            if(data.totalNumberOfDocuments == 0){
                linkedinDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                linkedinDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                linkedinDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                linkedinDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }

            linkedinDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            authDispatch({type: 'SET_IS_CRAWL_REQUEST_PAGE', value: true})

        },
        onError: (err) => {
            linkedinDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    return {
        keywordBoardFilterMutate,
        isKeywordBoardFilterLoading,
        keywordPhraseFilterMutate,
        isKeywordPhraseFilterLoading,
        keywordBroadMutate,
        isKeywordBroadLoading,
        keywordPhraseMutate,
        isKeywordPhraseLoading,
        urlDomainFilterMutate,
        isUrlDomainFilterLoading,
        urlTextFilterMutate,
        isUrlTextFilterLoading,
        urlExactFilterMatchMutate,
        isUrlExactFilterLoading,
        urlDomainMutate,
        isUrlDomainLoading,
        urlExactMutate,
        isUrlExactLoading,
        urlTextMutate,
        IsUrlTextLoading,
        getCrawledDataMutate,
        isGetCrawledDataLoading
    };
};
    