import React, { useEffect, useState } from "react";
import { ContentHeading, HistoryContainer, TableContainer, TableHeader, QueryHeader, TimeHeader, AdsHeader, TableBody, Row, QueryData, TimeData, AdsData, PlatformHeader, PlatformData, SearchNow } from "./index.styled";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    SortingState,
    ColumnDef,
    getSortedRowModel
} from '@tanstack/react-table'

import FilterIcon from "../../../../../media/Dashboard/filter.png"
import UpArrow from "../../../../../media/Dashboard/uparrow.png"
import DownArrow from "../../../../../media/Dashboard/downarrow.png"

import { useLocation } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { useAccount } from "../../context/account"
import { useGetAccount } from "../../../../hooks/useGetAccount";
import ShareIcon from "../../../../../media/Dashboard/shareData.svg"

// const UserSearchHistory = async () => {
//     const config = {
//         url: "/account/search-history",
//         method: "GET"
//     }

//     return appClient(config)
// }

// const UserSearchHistoryAdsCount = async (data) => {
//     const config = {
//         url: "/account/new-ads",
//         method: "POST",
//         data
//     }

//     return appClient(config)
// }

const SearchHistory = () => {
    const { accountState, accountDispatch } = useAccount()
    const [sorting, setSorting] = useState([]);

    const [newAdsSortType, setNewAdsSortType] = useState('')

    const location = useLocation()
    const accountInfoHook = useGetAccount();

    // const onNewAdsSort = () => {
    //     if(newAdsSortType == 'asc'){
    //         setNewAdsSortType('desc')

    //         let finalData = accountState.searchHistory.sort((a, b) => accountState.searchHistoryNewAdsCount[a.id] - accountState.searchHistoryNewAdsCount[b.id]);

    //         accountDispatch({type:'UPDATE_SEARCH_HISTORY', value: finalData})

    //     }else if(newAdsSortType == 'desc' || newAdsSortType == ''){

    //         let finalData = accountState.searchHistory.sort((a, b) =>{ accountState.searchHistoryNewAdsCount[b.id] - accountState.searchHistoryNewAdsCount[a.id]});

    //         accountDispatch({type:'UPDATE_SEARCH_HISTORY', value: finalData})

    //         setNewAdsSortType('asc')
    //     }
    // }
    //React table
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor('no', {
            id: 'no',
            header: () => 'No',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('query', {
            id: 'query',
            header: () => 'Query',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('platform', {
            id: 'platform',
            header: () => 'Platform',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor(row => `${new Date(row.createdAt).getTime()}`, {
            id: 'createdAt',
            header: () => 'Time',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('newlyadded', {
            id: 'newlyadded',
            header: () => 'Newly added ads',
            cell: info => info.getValue(),
        })
    ];

    const table = useReactTable({
        data: accountState.searchHistory,
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });

    // const { mutate: getUserSearchNewAds, isLoading: isSearchHistoryNewAdsLoading } = useMutation(UserSearchHistoryAdsCount, {
    //     onSuccess: (data) => {
    //         accountDispatch({type:'UPDATE_SEARCH_HISTORY_NEWADS_COUNT', value: data})

    //         console.log("accountState.searchHistory",accountState.searchHistory)

    //         const finalData = accountState.searchHistory.map((d) => ({
    //             ...d,
    //             newlyadded: data[d.id] ? Number(data[d.id]) : 0,
    //         }));

    //         console.log("finalData",finalData)

    //         accountDispatch({type:'UPDATE_SEARCH_HISTORY', value: finalData})

    //     },
    //     onError: (err) => {

    //     }
    // })

    // const { mutate: getUserSearchHistory, isLoading: isSearchHistoryLoading } = useMutation(UserSearchHistory, {
    //     onSuccess: (data) => {
    //         if(data.length > 0){
    //             accountDispatch({type:'UPDATE_SEARCH_HISTORY', value: data})
    //             getUserSearchNewAds({keywords: data})
    //         }
    //     },
    //     onError: (err) => {

    //     }
    // })

    const redirectToAdsPage = (params) => {
        let paramsObj = {
            phraseInput: params.query ? params.query : "",
            searchMode: params.searchMode ? params.searchMode : "",
            keywordSearchType: params.searchMode == "keyword" ? params.matchType ? params.matchType : "" : "",
            urlSearchType: params.searchMode == "url" ? params.matchType ? params.matchType : "" : "",
            phraseInput: params.searchMode == "keyword" ? params.query ? params.query : "" : "",
            domainUrlInput: params.searchMode == "url" ? params.query ? params.query : "" : "",
            keywords: params.query ? params.query : "",
            page: params.page ? params.page : "",
            excludedTerm: params.exclude ? params.exclude : "",
            searchIn: params.searchIn ? params.searchIn : "",
            selectedCountries: params.countries ? params.countries : "",
            startDate: params.startDate ? params.startDate : "",
            endDate: params.endDate ? params.endDate : "",
            minimumShouldMatch: params.minimumShouldMatch ? params.minimumShouldMatch : "",
            sortType: "recent",
        }

        // console.log(paramsObj)

        const queryString = Object.keys(paramsObj)
            .map((key) => `${encodeURIComponent(key)}=${paramsObj[key] ? encodeURIComponent(paramsObj[key]) : ""}`)
            .join('&');

        let renamedRoutes = {
            facebook: "meta",
            "bing-ecom": "bingpla",
            "google-ecom": "googlepla",
        };

        window.open(`https://app.adspyder.io/adspy/${renamedRoutes[params.platform] ? renamedRoutes[params.platform] : params.platform}?${queryString}`);

        // let finalUrl = `https://app.adspyder.io/adspy/google?searchMode=keyword&keywordSearchType=broad&phraseInput=apple&page=1&excludedTerm=aewa&searchIn=title%2Ccontent&sortType=oldest&keywords=apple%2Carea&minimumShouldMatch=1&startDate=2023-08-21&endDate=2023-09-21&selectedCountries=India%2CTurkey`
    }

    useEffect(() => {
        accountInfoHook.getUserSearchHistory()
    }, [])

    useEffect(() => {
        accountDispatch({ type: "SET_CURRENT_PAGE", value: "search-history" })
    }, [])

    return <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Search History | AdSpyder</title>
            <meta name="title" content="Search History | AdSpyder" />
            <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
        </Helmet>
        <ContentHeading>Search History</ContentHeading>
        <HistoryContainer>
            {/* <TableContainer>
                    <TableHeader>
                        <NoHeader>
                            No.
                        </NoHeader>
                        <QueryHeader>
                            Query
                        </QueryHeader>
                        <PlatformHeader>
                            Platform
                        </PlatformHeader>
                        <TimeHeader>
                            Time
                        </TimeHeader>
                        <AdsHeader>
                            Newly added ads
                        </AdsHeader>
                    </TableHeader>
                    <TableBody>
                        {isSearchHistoryLoading ? [...Array(6).map((data)=>{
                            <Row>
                                <Skeleton/>
                            </Row>
                        })] :
                        accountState.searchHistory.map((data,index) => {
                            return <Row>
                                    <NumberData>
                                        {index + 1}
                                    </NumberData>
                                    <QueryData>
                                        {data.query}
                                    </QueryData>
                                    <PlatformData>
                                        {data.platform}
                                    </PlatformData>
                                    <TimeData>
                                        {data.createdAt}
                                    </TimeData>
                                    <AdsData className="ads-data">
                                        <SearchNow className="search-now-btn" onClick={()=>redirectToAdsPage(data)}>
                                            Search Now
                                        </SearchNow>
                                        {
                                            isSearchHistoryNewAdsLoading ? <Skeleton style={{
                                                lineHeight: 1,
                                                width: 35,
                                              }}/> : accountState.searchHistoryNewAdsCount[data.id] ? formatNumber(Number(accountState.searchHistoryNewAdsCount[data.id])) : "-"
                                        }
                                    </AdsData>
                                </Row>
                            }
                        )}
                    </TableBody>
                </TableContainer> */}
            <TableContainer>
                {table.getHeaderGroups().map(headerGroup => {
                    console.log(headerGroup)
                    return <TableHeader>
                        {/* <NoHeader>
                            No.
                        </NoHeader> */}
                        <TimeHeader onClick={headerGroup.headers[3].column.getToggleSortingHandler()} >
                        Date & Time {!accountInfoHook.isSearchHistoryNewAdsLoading && <img src={headerGroup.headers[3].column.getIsSorted() == 'asc' ? UpArrow : headerGroup.headers[3].column.getIsSorted() == 'desc' ? DownArrow : FilterIcon} />}
                        </TimeHeader>
                        <PlatformHeader onClick={headerGroup.headers[2].column.getToggleSortingHandler()} >
                            Platform {!accountInfoHook.isSearchHistoryNewAdsLoading && <img src={headerGroup.headers[2].column.getIsSorted() == 'asc' ? UpArrow : headerGroup.headers[2].column.getIsSorted() == 'desc' ? DownArrow : FilterIcon} />}
                        </PlatformHeader>
                        <QueryHeader onClick={headerGroup.headers[1].column.getToggleSortingHandler()}>
                            Query {!accountInfoHook.isSearchHistoryNewAdsLoading && <img src={headerGroup.headers[1].column.getIsSorted() == 'asc' ? UpArrow : headerGroup.headers[1].column.getIsSorted() == 'desc' ? DownArrow : FilterIcon} />}
                        </QueryHeader>
                        <AdsHeader
                            onClick={headerGroup.headers[4].column.getToggleSortingHandler()}
                        // onClick={onNewAdsSort}
                        >
                            {/* New ads {!accountInfoHook.isSearchHistoryNewAdsLoading && <img src={headerGroup.headers[4].column.getIsSorted() == 'asc' ? UpArrow : headerGroup.headers[4].column.getIsSorted() == 'desc' ? DownArrow : FilterIcon} />} */}
                        </AdsHeader>
                    </TableHeader>
                    // <TableHead>
                    //             <KeywordLabel onClick={headerGroup.headers[0].column.getToggleSortingHandler()}>
                    //                 Keyword <img src={headerGroup.headers[0].column.getIsSorted() == 'asc' ? UpArrow : headerGroup.headers[0].column.getIsSorted() == 'desc' ? DownArrow : FilterIcon}/>
                    //             </KeywordLabel>
                    //             <VolumeLabel onClick={headerGroup.headers[1].column.getToggleSortingHandler()}>
                    //                 Volume <img src={headerGroup.headers[1].column.getIsSorted() == 'asc' ? UpArrow : headerGroup.headers[1].column.getIsSorted() == 'desc' ? DownArrow : FilterIcon}/>
                    //             </VolumeLabel>
                    //             <KcsLabel onClick={headerGroup.headers[2].column.getToggleSortingHandler()} >
                    //                 Competition <img src={headerGroup.headers[2].column.getIsSorted() == 'asc' ? UpArrow : headerGroup.headers[2].column.getIsSorted() == 'desc' ? DownArrow : FilterIcon}/>
                    //             </KcsLabel>
                    //             <CpcLabel onClick={headerGroup.headers[3].column.getToggleSortingHandler()}>
                    //                 CPC (USD) <img src={headerGroup.headers[3].column.getIsSorted() == 'asc' ? UpArrow : headerGroup.headers[3].column.getIsSorted() == 'desc' ? DownArrow : FilterIcon}/>
                    //             </CpcLabel>
                    //         </TableHead>
                })}
                <TableBody>
                    {table
                        .getRowModel()
                        .rows
                        .map((row, index) => {
                            return (<Row>
                                {/* <NumberData>
                                    {index + 1}
                                </NumberData> */}
                                <TimeData>
                                    {row.original.createdAt}
                                </TimeData>
                                <PlatformData>
                                    {row.original.platform}
                                </PlatformData>
                                <QueryData>
                                    {row.original.query}
                                </QueryData>
                                <AdsData className="ads-data">
                                    
                                    <SearchNow className="search-now-btn" onClick={() => redirectToAdsPage(row.original)}>
                                        <img src={ShareIcon}/>
                                    </SearchNow>
                                    {/* {
                                        isSearchHistoryNewAdsLoading ? <Skeleton style={{
                                            lineHeight: 1,
                                            width: 35,
                                            }}/> : accountState.searchHistoryNewAdsCount[row.original.id] ? formatNumber(Number(accountState.searchHistoryNewAdsCount[row.original.id])) : "-"
                                    } */}
                                    {/* {
                                        accountInfoHook.isSearchHistoryNewAdsLoading ? <Skeleton style={{
                                            lineHeight: 1,
                                            width: 35,
                                        }} /> : row.original.newlyadded == 0 ? "-" : formatNumber(row.original.newlyadded)
                                    } */}
                                </AdsData>
                            </Row>
                            )
                        })}
                </TableBody>
            </TableContainer>
        </HistoryContainer>
    </>
}

export default SearchHistory