//cf587e4f-53be-4624-ba12-c54a1f076c9d
const statics = {
    walkthroughSteps: [
        {
            id: 1,
            name: "Client Profile",
            isCompleted: false
        },
        {
            id: 2,
            name: "Project Details",
            isCompleted: false
        },
        {
            id: 3,
            name: "Brand Assets",
            isCompleted: false
        }
    ],
    popupTitle: "Client Library",
    popupDesc: "Efficiently centralize client data for powerful ad campaigns",
    fontFamilies: [
        {
            id: 1,
            font: "Poppins"
        },
        {
            id: 2,
            font: "Inter"
        },
        {
            id: 3,
            font: "Roboto"
        },
        {
            id: 4,
            font: "Eagle Lake"
        },
        {
            id: 5,
            font: "Tilt Neon"
        },
        {
            id: 6,
            font: "Nanum Gothic Coding"
        },
    ]
}

export default statics