import { appClient } from "../../../../../utils/axios-utils";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useAdIntegration } from "../context/AdManagementContext";
import { getSearchParams } from "../../../../../utils/helper";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useAuth } from "../../../../../context/auth";

const getAllConnected = async (data) => {
  const config = {
    url: "/admanagement/get-connected-accounts",
    method: "GET",
  };

  return appClient(config);
};
const getRecentConnection = async (data) => {
  const config = {
    url: "/admanagement/get-recent-connection",
    method: "GET",
  };

  return appClient(config);
};
const getListAdAccounts = async (data) => {
  const config = {
    url: "/admanagement/list-adaccounts",
    method: "POST",
    data,
  };

  return appClient(config);
};
const getAccountConnected = async (data) => {
  const config = {
    url: "/admanagement/connect-account",
    method: "POST",
    data,
  };

  return appClient(config);
};
const getSingleAdGroup = async (data) => {
  const config = {
    url: "/text-ad-generation/generate/get-single-ad-group",
    method: "POST",
    data,
  };

  return appClient(config);
};
const getCreateCampaign = async (data) => {
  const config = {
    url: "/admanagement/create-campaign",
    method: "POST",
    data,
  };

  return appClient(config);
};
const getCreateAd = async (data) => {
  const config = {
    url: "/admanagement/create-ad",
    method: "POST",
    data,
  };

  return appClient(config);
};
const getCampaignCriteria = async (data) => {
  const config = {
    url: "/admanagement/update-campaign-criteria",
    method: "POST",
    data,
  };

  return appClient(config);
};
const getAllCampaign = async (data) => {
  const config = {
    url: "/admanagement/get-all-campaigns",
    method: "POST",
    data,
  };

  return appClient(config);
};
const getUpdateCampaignMetric = async (data) => {
  const config = {
    url: "/admanagement/update-campaign-metrics",
    method: "POST",
    data,
  };

  return appClient(config);
};
const getProjectIdByConnect = async (data) => {
  const config = {
    url: "/admanagement/connected-accounts-by-project-id",
    method: "POST",
    data,
  };

  return appClient(config);
};
const getMetricChart = async (data) => {
  const config = {
    url: "/admanagement/get-campaign-metrics-chart",
    method: "POST",
    data,
  };

  return appClient(config);
};
const getCampaignSummary = async (data) => {
  const config = {
    url: "/admanagement/get-campaign-summary",
    method: "POST",
    data,
  };

  return appClient(config);
};

export const useAdIntegrationInfo = () => {
  const {
    newAdManagementState: {
      connectedAdAccount,
      isCreateAdHeadline,
      isCreateAdDescription,
      adGroupList,
      isTransformedKeyword,
      isCreateCampaign,
      isCustomerId,
    },
    newAdManagementDispatch,
  } = useAdIntegration();
  const navigate = useNavigate();
  const location = useLocation();
  const today = moment();
  const { authState, authDispatch } = useAuth();

  const { mutate: setGetConnectedList, isLoading: isGetConnectedListLoading } =
    useMutation(getAllConnected, {
      onSuccess: (data) => {
        // adManagementDispatch({ type: "SET_AD_ACCOUNT_LIST", value: data });
        if (data.length > 0) {
          newAdManagementDispatch({ type: "SET_CONNECTED_DATA", value: data });
          //   adManagementDispatch({ type: "SET_AD_DATA_PAGE", value: true });
        } else {
          newAdManagementDispatch({ type: "SET_CONNECTED_DATA", value: [] });
          const searchParamFromState = getSearchParams(location.search);
          const { id } = searchParamFromState;
          navigate(`/admanagement/ad-campaigns${id ? `?id=${id}` : ''}`);
        }
      },
      onError: (err) => {},
    });

  const { mutate: setRecentConnection, isLoading: isRecentConnectionLoading } =
    useMutation(getRecentConnection, {
      onSuccess: (data) => {
        newAdManagementDispatch({
          type: "SET_INTEGRATION_ID",
          value: data.integrationId,
        });
        setListAdAccounts({ integrationId: data.integrationId });
      },
      onError: (err) => {},
    });
  const { mutate: setListAdAccounts, isLoading: isListAdAccountsLoading } =
    useMutation(getListAdAccounts, {
      onSuccess: (data) => {
        newAdManagementDispatch({ type: "SET_LIST_AD_ACCOUNTS", value: data });
      },
      onError: (err) => {},
    });
  const { mutate: setAccountConnected, isLoading: isAccountConnectedLoading } =
    useMutation(getAccountConnected, {
      onSuccess: (data) => {
        let startEndDate = moment.range(
          today.clone().subtract(3, "months"),
          today.clone()
        );
        // newAdManagementDispatch({ type: "SET_CONNECTED_DATA", value: [] });
        // setGetConnectedList();
        const searchParamFromState = getSearchParams(location.search);
        const { id } = searchParamFromState;
        if (id) {
          navigate(`/admanagement/ad-campaigns/new-campaign?id=${id}`);
        } else {
        }

        setAllCampaign({ customerId: isCustomerId });

        setProjectIdByConnect({
          startDate: startEndDate.start.format("YYYY-MM-D"),
          endDate: startEndDate.end.format("YYYY-MM-D"),
          projectId: authState.defaultProjectInfo.projectId,
        });
        newAdManagementDispatch({
          type: "SET_DATE_START_END",
          value: moment.range(
            today.clone().subtract(3, "months"),
            today.clone()
          ),
        });

        newAdManagementDispatch({
          type: "SET_SELECT_AD_ACCOUNT",
          value: false,
        });
      },
      onError: (err) => {
        if (err.response.data.errors[0].message == "check email") {
          // newAdManagementDispatch({ type: "SET_CHECK_EMAIL_POPUP", value: true });

          console.log(
            err.response.data.errors[0].message,
            "err.response.data.errors[0].message"
          );
        }
      },
    });
  const { mutate: setSingleAdGroup, isLoading: isSingleAdGroupLoading } =
    useMutation(getSingleAdGroup, {
      onSuccess: (data) => {
        newAdManagementDispatch({
          type: "SET_ADGROUP_LIST",
          value: [
            {
              id: 1,
              destinationUrl: data.domain,
              titleList: data?.adGroupRow?.titles,
              descriptionList: data?.adGroupRow?.descriptions,
            },
          ],
        });
        newAdManagementDispatch({ type: "SET_SINGLE_AD_GROUP", value: data });
        let keywordsList = [];
        data.adGroupRow?.keywords.map((item, index) => {
          keywordsList.push({
            keyword: item,
            type: "Phrase",
            id: `${index + 1}`,
          });
        });
        newAdManagementDispatch({
          type: "SET_KEYWORDS_LIST",
          value: keywordsList,
        });
      },
      onError: (err) => {},
    });
  const { mutate: setCreateAd, isLoading: isCreateAdLoading } = useMutation(
    getCreateAd,
    {
      onSuccess: (data) => {
        setCampaignCriteria({
          customerId: connectedAdAccount[0].customerId,
          campaignId: isCreateCampaign.campaignId,
          languages: [1000],
          locations: [20460, 20461],
        });
        // adManagementDispatch({type: 'SET_SINGLE_AD_GROUP', value: data})
      },
      onError: (err) => {
        newAdManagementDispatch({
          type: "SET_ERROR_MESSAGE",
          value: err.response.data.errors[0].message,
        });
      },
    }
  );
  const { mutate: setCampaignCriteria, isLoading: isCampaignCriteriaLoading } =
    useMutation(getCampaignCriteria, {
      onSuccess: (data) => {
        newAdManagementDispatch({
          type: "SET_CAMPAIGN_PUBLISHED",
          value: true,
        });
      },
      onError: (err) => {},
    });

  const { mutate: setCreateCampaign, isLoading: isCreateCampaignLoading } =
    useMutation(getCreateCampaign, {
      onSuccess: (data) => {
        newAdManagementDispatch({ type: "SET_CREATE_CAMPAIGN", value: data });
        setCreateAd({
          customerId: connectedAdAccount[0].customerId,
          connectionId: connectedAdAccount[0].connectionId,
          budgetId: data.budgetId,
          campaignId: data.campaignId,
          adGroupId: data.adGroupId,
          adGroupAdData: {
            ad: {
              responsiveSearchAd: {
                headlines: isCreateAdHeadline,
                descriptions: isCreateAdDescription,
              },
              finalUrls: adGroupList[0]?.destinationUrl,
            },
          },
          keywords: isTransformedKeyword,
        });
        // adManagementDispatch({type: 'SET_SINGLE_AD_GROUP', value: data})
      },
      onError: (err) => {
        newAdManagementDispatch({
          type: "SET_ERROR_MESSAGE",
          value: err.response.data.errors[0].message,
        });
      },
    });

  const { mutate: setAllCampaign, isLoading: isAllCampaignLoading } =
    useMutation(getAllCampaign, {
      onSuccess: (data) => {},
      onError: (err) => {},
    });

  const {
    mutate: setUpdateCampaignMetric,
    isLoading: isUpdateCampaignMetricLoading,
  } = useMutation(getUpdateCampaignMetric, {
    onSuccess: (data) => {},
    onError: (err) => {},
  });

  const {
    mutate: setProjectIdByConnect,
    isLoading: isProjectIdByConnectLoading,
  } = useMutation(getProjectIdByConnect, {
    onSuccess: (data) => {
      newAdManagementDispatch({
        type: "SET_CONNECTED_ACC_BY_PROJECTID",
        value: data,
      });
    },
    onError: (err) => {},
  });

  const { mutate: setMetricChart, isLoading: isMetricChartLoading } =
    useMutation(getMetricChart, {
      onSuccess: (data) => {
        newAdManagementDispatch({
          type: "SET_METRIC_CHART",
          value: data,
        });
      },
      onError: (err) => {},
    });
  const { mutate: setCampaignSummary, isLoading: isCampaignSummaryLoading } =
    useMutation(getCampaignSummary, {
      onSuccess: (data) => {
        newAdManagementDispatch({
          type: "SET_CAMPAIGN_SUMMARY",
          value: data,
        });
      },
      onError: (err) => {},
    });

  return {
    setGetConnectedList,
    isGetConnectedListLoading,
    setRecentConnection,
    isRecentConnectionLoading,
    setListAdAccounts,
    isListAdAccountsLoading,
    setAccountConnected,
    isAccountConnectedLoading,
    setSingleAdGroup,
    isSingleAdGroupLoading,
    setCreateCampaign,
    isCreateCampaignLoading,
    setAllCampaign,
    isAllCampaignLoading,
    setUpdateCampaignMetric,
    isUpdateCampaignMetricLoading,
    setProjectIdByConnect,
    isProjectIdByConnectLoading,
    setMetricChart,
    isMetricChartLoading,
    setCampaignSummary,
    isCampaignSummaryLoading,
  };
};
