import styled from "styled-components";

export const AboutYouContainer = styled.div`
    ::-webkit-scrollbar {
        display: none;
    }
    h2{
        color: #2C2C2C;
        font-family: Nunito;
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; 
        margin-top: 16px;
    }
    >p{
        color: #2C2C2C;
        font-family: Nunito;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; /* 27px */
        letter-spacing: 0.18px;
        margin-top: 16px;
        margin-bottom: 60px;
    }
`;

export const NameContainer = styled.div`
    margin-top: 24px;
    margin-bottom: 24px;
    label{
        color: #646464;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; 
    }
`;

export const NameInputs = styled.div`
    display: flex;
    gap: 8px;
    margin-top: 8px;
    
    @media screen and (max-width: ${props => props.theme.breakPoints.extraLargeScreen}) {
        
    }
    @media screen and (max-width: 1600px) {
        flex-direction: column;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.largeScreen}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.desktop}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileSmall}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileExtraSmall}) {

    }
`;

export const FirstName = styled.input`
    /* width: 271px; */
    width: 100%;
    background: #FFFFFF;
    border: ${props => props.error ? "1px solid #FF711E" : "1px solid #ECECEC"};
    outline: none;
    /* outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};     */
    border-radius: 8px;
    color: #2E2E2E;
    font-weight: 300;
    padding: 9px 12px;

    border-radius: 4px;
    border: 1px solid #DADFE4;
    background: #FFF;
    height: 52px;
    max-width: 452px;

    ::placeholder {
        color: #D4D4D4;
    }

    &:focus{
        border: ${props => props.error ? "1px solid #FF5180" : "1px solid #FF711E"};
        /* outline: ${props => props.error ? "2px solid #FFB7CB" : "2px solid #B9DDFF"}; */
    }
`;

export const LastName = styled.input`
    width: 271px;
    background: #FFFFFF;
    border: ${props => props.error ? "1px solid #FF5180" : "1px solid #ECECEC"};
    outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};
    border-radius: 8px;
    height: 40px;
    color: #2E2E2E;
    font-weight: 300;
    padding: 9px 12px;
    
    ::placeholder {
        color: #D4D4D4;
    }
    
    &:focus{
        border: ${props => props.error ? "1px solid #FF5180" : "1px solid #5AB3FF"};
        outline: ${props => props.error ? "2px solid #FFB7CB" : "2px solid #B9DDFF"};
    }

    @media screen and (max-width: ${props => props.theme.breakPoints.extraLargeScreen}) {
        
    }
    @media screen and (max-width: 1600px) {
        width: 100%;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.largeScreen}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.desktop}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileSmall}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileExtraSmall}) {

    }
`;

export const SurveyNavContainer = styled.div`
    margin-top: ${props => props.role == "Other" ? "72px" : "140px"};
    font-size: 12px;
    color: #2E2E2E;
    font-weight: 300;
    display: flex;
    justify-content: space-between;
`;

export const NextButton = styled.div`
    width: 155px;
    height: 44px;
    background: #FF711E;
    border-radius: 4px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 500;
    cursor: pointer;

    opacity: ${props => props.disabled ? "0.5": "1"};
    pointer-events: ${props => props.disabled ? "none": "all"};
`;


export const RolesList = styled.div`
    display: flex;
    flex-direction: column;
    p{
        user-select: text;
        color: #707070;
        font-size: 14px;
        font-weight: 300;
        /* margin-bottom: 8px; */
    }
    label{
        color: #646464;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
    /* @media screen and (max-width: ${props => props.theme.breakPoints.extraLargeScreen}) {
        
    }
    @media screen and (max-width: 1500px) {
        flex-direction: column;
        gap: 12px;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.largeScreen}) {
        
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.desktop}) {
        
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileSmall}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileExtraSmall}) {
        flex-direction: row;
    } */

`; 

export const RoleContainer = styled.div`
    display: flex;
    margin-top: 8px;
    flex-direction: row;
    
    /* @media screen and (max-width: 1500px) {
        flex-direction: column;
        gap: 12px;
    } */
    
`;

export const Role = styled.div`
    width: 150px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.isActive ? "#FFF7E9" : "#FFFFFF"};
    border: ${props => props.isActive ? "1px solid #FF711E" : "1px solid #DADFE4"};
    border-radius: 8px;
    margin-right: 12px;
    font-size: 16px;
    cursor: pointer;
    color: #505050;
    font-weight: 400;

    /* @media screen and (max-width: ${props => props.theme.breakPoints.extraLargeScreen}) {
        
    }
    @media screen and (max-width: 1500px) {
        width: 100%;
        height: 44px;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.largeScreen}) {
        
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.desktop}) {
        
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileSmall}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileExtraSmall}) {

    } */

`; 

export const CustomRole = styled.div`
    margin-top: 16px;
    p{
        user-select: text;
        color: #707070;
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 8px;
    }
`;

export const CustomRoleInput = styled.input`
    user-select: text;
    border-radius: 4px;
    height: 52px;
    width: 100%;
    max-width: 452px;
    background: #FFFFFF;
    border: ${props => props.error ? "1px solid #FF711E" : "1px solid #ECECEC"};
    /* outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};     */
    outline: none;
    border-radius: 8px;
    padding: 14px 17px;
    color: #2E2E2E;
    font-weight: 300;
    font-size: 16px;

    &:focus{
        border: ${props => props.error ? "1px solid #FF5180" : "1px solid #FF711E"};
        /* outline: ${props => props.error ? "2px solid #FF711E" : "2px solid #B9DDFF"}; */
    }
`;

export const NextContainer = styled.div`
    display: flex;
    width: 100%;
`;