import React from "react";
import { PasswordChangeProvider } from "./context/passwordChange";
import ChangePasswordPage from "./components"

const ChangePassword = () => {
    return <PasswordChangeProvider>
        <ChangePasswordPage/>
    </PasswordChangeProvider>
}


export default ChangePassword
