import styled from "styled-components";

export const Container = styled.div`
  overflow-x: auto;
  width: 100%;
`;

export const Header = styled.div`
  margin-bottom: 16px;
`;

export const Greeting = styled.div`
  color: #a3a3a3;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.14px;
  margin-bottom: 8px;
`;

export const Title = styled.div`
  display: flex;
  gap: 16px;
`;

export const Logo = styled.img``;

export const Text = styled.div`
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px; /* 158.333% */
  span {
    text-transform: capitalize;
    /* color: rgb(255, 113, 30); */
  }
`;

export const Body = styled.div`
  width: 100%;
  min-width: 1150px;
  max-width: 1436px;
  height: 682px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px dashed #c2c2c2;
  background: #ececec;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
`;

export const TaskWalkthrough = styled.div`
  display: flex;
  gap: 75px;
`;

export const Task = styled.div`
  pointer-events: ${(props) => (props.isFirst ? "none" : "unset")};
  cursor: pointer;
`;

export const Sno = styled.div`
  color: #a0a0a0;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  margin-bottom: 8px;
`;

export const Details = styled.div`
  width: 324px;
  height: 70px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 19px;
  padding: 14px 16px;
  position: relative;
  border: ${(props) =>
    props.sno === 1
      ? "none"
      : props.sno === 2 && props.currentTaskSno === 2
      ? "1px solid #2D9BFF"
      : props.sno === 3 && props.currentTaskSno === 3
      ? "1px solid #7940FF"
      : "none"};
`;

export const CompletedCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.isCompleted ? "#DBF4E6" : "#fff")};
  border: ${(props) => (props.isCompleted ? "none" : "1px solid #D4D4D4")};
  border-radius: 50%;
  height: 34px;
  width: 34px;
`;

export const Completed = styled.img``;

export const Info = styled.div``;

export const TaskTitle = styled.div`
  color: #4f4f4f;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;
`;

export const TaskDescription = styled.div`
  color: #a3a3a3;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const CompletedArrow = styled.img`
  visibility: ${(props) => (props.isCompleted ? "unset" : "hidden")};
  position: absolute;
  top: 30px;
  right: -75px;
`;

// export const Content = styled.div`
// width: 1065px;
// height: 480px;
// flex-shrink: 0;
// border-radius: 10px;
// border: 1px solid #F2F2F2;
// background: #FFF;
// box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
// margin-top: 32px;
// `;

// export const ContentHeader = styled.div`
// width: 1063px;
// height: 56px;
// flex-shrink: 0;
// background: ${(props) => !props.forCompetitor ? "#E6F3FF" : "#EDE7FB"};
// position: relative;
// padding: 16px;
// display: flex;
// align-items: center;
// gap: 16px;
// border-radius: 10px 10px 0 0;
// overflow: hidden;
// border: 1px solid #fff;
// border-bottom: 1px solid rgb(227, 227, 227);
// padding: 16px;
// `;

// export const TaskNo = styled.div`
// width: 24px;
// height: 24px;
// flex-shrink: 0;
// background: #FFF;
// border: 1px solid #BFBFBF;
// display: flex;
// align-items: center;
// justify-content: center;
// color: #111926;
// font-family: Inter;
// font-size: 14px;
// font-style: normal;
// font-weight: 500;
// line-height: 150%; /* 21px */
// border-radius: 50%;
// `;

// export const HeaderTitle = styled.div`
// color: #343E56;
// font-family: Inter;
// font-size: 16px;
// font-style: normal;
// font-weight: 500;
// line-height: 150%; /* 24px */
// `;

// export const Length = styled.span`
// color: #5A5A5A;
// font-family: Inter;
// font-size: 14px;
// font-style: normal;
// font-weight: 400;
// line-height: 150%; /* 21px */
// `;

// export const CornerImage = styled.img`
// position: absolute;
// top: 0;
// right: 0;
// `;

// export const SelectingPanel = styled.div`
// height: 424px;
// width: 100%;
// padding: 20px 24px 24px 24px;
// display: flex;
// gap: 25px;
// `;

// export const ChoicesWindow = styled.div`
// width: 496px;
// height: 380px;
// flex-shrink: 0;
// border-radius: var(--8, 8px);
// border: 1px solid #DADFE4;
// background: #F5F5F5;
// display: flex;
// align-items: center;
// `;

// export const ChoicesList = styled.div`
// width: 489px;
// height: 348px;
// flex-shrink: 0;
// border-radius: var(--8, 8px);
// // border: 1px solid #DADFE4;
// background: #F5F5F5;
// padding: 0 12px 0 24px;
// overflow: hidden auto;
// display: flex;
// flex-direction: column;
// align-items: center;
// gap: 8px;

// ::-webkit-scrollbar-track{
//     box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
//     background-color: #EFEFEF;;
//     border-radius: 10px;
// }

// ::-webkit-scrollbar{
//     width: 5px;
//     background-color: rgb(245 245 245 / 92%);
//     border-radius: 10px;
// }

// ::-webkit-scrollbar-thumb{
//     background-color: #E0E0E0;
//     border-radius: 10px;
// }
// `;

// export const ChoiceContainer = styled.div`
// width: 448px;
// height: 70px;
// flex-shrink: 0;
// border-radius: 4px;
// background: #FFF;
// padding: 14px 16px;
// display: flex;
// align-items: center;
// justify-content: space-between;
// cursor: pointer;
// `;

// export const Choice = styled.div`
// color: ${(props) => props.isSelected ? "#0070FF" : "#4F4F4F"};
// font-family: Inter;
// font-size: 15px;
// font-style: normal;
// font-weight: 500;
// line-height: normal;
// `;

// export const ChoiceDetail = styled.div`
// display: flex;
// gap: 16px;
// margin-top: 9px;
// `;

// export const Pair = styled.div``

// export const Key = styled.span`
// color: rgba(28, 28, 28, 0.40);
// font-family: Inter;
// font-size: 13px;
// font-style: normal;
// font-weight: 400;
// line-height: normal;
// letter-spacing: -0.26px;
// `;

// export const Value = styled.span`
// color: rgba(28, 28, 28, 0.70);
// font-family: Inter;
// font-size: 12px;
// font-style: normal;
// font-weight: 500;
// line-height: normal;
// `;

// export const CheckBox = styled.div`
// width: 22px;
// height: 22px;
// flex-shrink: 0;
// border-radius: 4px;
// border: ${(props) => props.isChecked ? "none" : "1px solid #D0D4D9"};
// background: ${(props) => !props.isChecked ? "#FFF" : "#0070FF"};
// display: flex;
// align-items: center;
// justify-content: center;
// `;

// export const SelectedWindow = styled.div`
// width: 496px;
// height: 380px;
// flex-shrink: 0;
// border-radius: var(--8, 8px);
// border: 1px solid #DADFE4;
// background: ${(props) => props.forCompetitor ? "#FAF8FF" : "#F3F9FF"};
// padding: 16px 24px 16px 24px;
// overflow: hidden auto;
// display: flex;
// flex-direction: column;
// align-items: center;
// position: relative;
// z-index: 1;
// `;

// export const SelectedList = styled.div`
// width: 448px;
// height: 292px;
// flex-shrink: 0;
// border-radius: 4px;
// background: #FFF;
// padding: 24px;
// `;

// export const SelectingInstruction = styled.div`
// color: rgba(28, 28, 28, 0.40);
// font-family: Inter;
// font-size: 14px;
// font-style: normal;
// font-weight: 400;
// line-height: normal;
// margin-bottom: 21px;
// `;

// export const SelectedKeywordsList = styled.div`
// display: flex;
// gap: 8px;
// flex-wrap: wrap;
// `;

// export const SelectedKeyword = styled.div`
// display: inline-flex;
// height: 29.07px;
// padding: 3.876px 8px;
// justify-content: center;
// align-items: center;
// gap: 4px;
// flex-shrink: 0;
// border-radius: 5.814px;
// background: #EFF8FF;
// color: #222;
// font-family: Inter;
// font-size: 14px;
// font-style: normal;
// font-weight: 500;
// line-height: normal;
// `;

// export const Close = styled.img`
// cursor: pointer;
// `

// export const Footer = styled.div`
// display: flex;
// justify-content: space-between;
// align-items: center;
// margin-top: 14px;
// width: 100%;
// `;

// export const SetupButton = styled.div`
// display: inline-flex;
// padding: 10px;
// justify-content: center;
// align-items: center;
// gap: 8px;
// border-radius: var(--8, 8px);
// background: ${(props) => props.isActive ? "#111926" : "#4F4F4F"};
// color: #FFF;
// leading-trim: both;
// text-edge: cap;
// font-family: Inter;
// font-size: 14px;
// font-style: normal;
// font-weight: 500;
// line-height: 20px; /* 142.857% */
// pointer-events: ${(props) => props.isActive ? "unset" : "none"};
// cursor: pointer;
// `;
