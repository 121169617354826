export function ISTDateformat(inputdate){
    const date = new Date(inputdate); // Create a Date object from the date string
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const formattedDate = `${weekdays[date.getUTCDay()]} ${months[date.getUTCMonth()]} ${date.getUTCDate()} ${date.getUTCFullYear()} ${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()} GMT+0530 (India Standard Time)`;

    return(formattedDate)

}

export function ISTDateformatUsingInput(y,m,d){
    const date = new Date(y,m - 1,d + 1); // Create a Date object from the date string
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const formattedDate = `${weekdays[date.getUTCDay()]} ${months[date.getUTCMonth()]} ${date.getUTCDate()} ${date.getUTCFullYear()} ${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()} GMT+0530 (India Standard Time)`;

    return(formattedDate)

}


export function isDateToday(inputDate) {
    let year = inputDate.split("-")[0]
    let month = inputDate.split("-")[1]
    let day = inputDate.split("-")[2]
    const today = new Date(); // Get today's date

    // Create a Date object for the given date
    const dateToCheck = new Date(year, month - 1, day); // Subtract 1 from month

    // Compare year, month, and day of both dates
    return (
        today.getFullYear() === dateToCheck.getFullYear() &&
        today.getMonth() === dateToCheck.getMonth() &&
        today.getDate() === dateToCheck.getDate()
    );
}

export function getTodayDate(){
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(currentDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate
}

export function getNumberDateFromInput(input){
    const dateString = input;
    const date = new Date(dateString);

    // Get the year, month, and day components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-based
    const day = String(date.getDate()).padStart(2, '0');

    // Construct the desired format 'YYYY-MM-DD'
    const formattedDate = `${year}-${month}-${day}`.toString();
    return formattedDate

}

export function convertTimestamp(timestamp){
    // Create a new Date object based on the timestamp
    var date = new Date(timestamp);
    // Get the month name
    var monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    var month = monthNames[date.getMonth()];
    // Get the year
    var year = date.getFullYear();
    // Assemble the formatted date string
    var formattedDate = date.getDate() + ' ' + month + ' ' + year;
    return formattedDate;
}