import { useRef, useState } from "react";
import { AddNewForm, AddNewProjectContainer, Close, Create, Description, DomainInput, ErrorBox, ErrorIcon, Field, HttpsLabel, InputContainer, Label, PlaceholderWithInput, PopupBackground, PopupContent, RightIcons, Title } from "./index.styled";
import close from "./images/close.svg"
import tooltip from "./images/tooltip.svg"
import { useManageProjects } from "../../../context/manageProjects";
import { useMutation } from "@tanstack/react-query"
import { appClient } from "../../../../../../utils/axios-utils"
import { urlPatternValidation } from "../../../../../../utils/helper";
function AddNewProject({getProjectsMutate}) {
    const domainRef = useRef(null)
    const projectNameRef = useRef(null)
    const { manageProjectsDispatch } = useManageProjects()
    const [inputError, setInputError] = useState(false)
    const [projectError, setProjectError] = useState(false)
    const [inputFocussed, setInputFocussed] = useState(false)

    const addProject = (data) => {
        const config = {
            url: `/dashboard-overview/project/add-project`,
            method: "post",
            data
        }
        return appClient(config)
    }
    const { mutate: addProjectMutate } = useMutation(addProject, {
        onSuccess: (data) => {

            getProjectsMutate()
        },
        onError: (e) => console.log(e)
    })
    const handleCreateProject = () => {
        const domainValue = domainRef.current.value.includes("https") ? domainRef.current.value : `https://${domainRef.current.value}`

        const projectValue = projectNameRef.current.value

        if(inputError || projectError) return

        const domain = domainRef.current
        const projectName = projectNameRef.current
        if (domain.value !== "" && projectName.value !== "") {
            closePopup()
            addProjectMutate({
                projectName: projectValue,
                website: domainValue
            })
        }
    }
    const handleDomainChange = () => {
        const value = domainRef.current.value.includes("https") ? domainRef.current.value : `https://${domainRef.current.value}`
        const isTrueVal = urlPatternValidation(domainRef.current.value)
        setInputError(!isTrueVal)
    }
    const handleProductChange = () => {
        const value = projectNameRef.current.value
        if(value === "") setProjectError(true)
    }
    const closePopup = () => {
        manageProjectsDispatch({
            type: "SET_POPUP_ACTIVE",
            value: false
        })
    }
    const handleKeyDown = (e) => {
        if(e.key === "Enter") handleCreateProject()
    }
    return (
        <PopupBackground>
            <PopupContent onKeyDown={handleKeyDown} >
                <AddNewProjectContainer>
                    <Close onClick={closePopup} src={close} alt="close" />
                    <Title>Add new Project</Title>
                    <Description>Personalize Ad Campaigns Easily</Description>
                    <AddNewForm>
                        <Field>
                            <Label>Domain</Label>
                            <PlaceholderWithInput >
                                <HttpsLabel>https://</HttpsLabel>
                                <InputContainer isFocussed={inputFocussed === "domain"} hasError={inputError}>
                                    <DomainInput 
                                        placeholder="companydomain.com"
                                        ref={domainRef}
                                        onChange={handleDomainChange}
                                        onFocus={() => setInputFocussed("domain")}
                                        onBlur={() => setInputFocussed(null)}
                                    />
                                    <RightIcons>
                                        {inputError && <ErrorIcon src={tooltip} alt=""/>}
                                    </RightIcons>
                                </InputContainer>
                            </PlaceholderWithInput>
                            {inputError && <ErrorBox>Enter a valid domain</ErrorBox>}
                        </Field>
                        <Field>
                            <Label>Project Name</Label>
                            <InputContainer isFocussed={inputFocussed === "project-name" } hasError={projectError} style={{borderRadius: "8px", width: "452px"}} >
                                <DomainInput 
                                    placeholder="assign a name for your project"
                                    ref={projectNameRef}
                                    onChange={handleProductChange}
                                    onFocus={() => setInputFocussed("project-name")}
                                    onBlur={() => setInputFocussed(null)}
                                />
                                <RightIcons>
                                    {projectError && <ErrorIcon src={tooltip} alt=""/>}
                                </RightIcons>
                            </InputContainer>
                            {projectError && <ErrorBox>Enter a project name</ErrorBox>}
                        </Field>
                    </AddNewForm>
                    <Create onClick={handleCreateProject} >Create new</Create>
                </AddNewProjectContainer>
            </PopupContent>
        </PopupBackground>
    )
}

export default AddNewProject