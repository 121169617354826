import styled from "styled-components";

export const Container = styled.div`
    /* padding: 20px; */
    max-width: 990px;
    h1{
        font-size: 18px;
        font-weight: 500;
        color: #2E2E2E;
        /* padding: 20px 4px; */
        margin: 0;
        display: flex;
        align-items: center;
        span{
            color: #808080;
            font-weight: 300;
            text-decoration: underline;
            margin-left: 8px;
        }
    }
`;

export const Header = styled.div`
    display: flex;
    /* height: 32px; */
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(236, 236, 236);
    padding-bottom: 20px;
`;

export const SearchContainer = styled.div`
    border-radius: 8px;
    border: 1px solid #ECECEC;
    background: #FFF;
    display: flex;
    margin-left: 20px;
`;

export const Input = styled.input`
    border: none;
    outline: none;
    /* margin: 6px 12px; */
    /* color: #B1B1B1; */
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    margin: 0;
    padding: 6px 12px;
    border-radius: 8px 0px 0px 8px;
    border-right: 1px solid #ECECEC;
    background: #FFF;
`;

export const SearchIconContainer = styled.div`
    border-radius: 8px;
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const HeaderLeft = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
`;

export const HeaderRight = styled.div`

`;

export const NewProjectBtn = styled.div`
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    color: #FFF;
    border-radius: 8px;
    background: #0A66C2;
    padding: 6px 12px;
    cursor: pointer;

    &:hover{
        background: #0059B2;
    }

    &:active{
        background: #0052A3;
    }
`;

export const ProjectsContainer = styled.div`
    h2{
        font-size: 14px;
        font-weight: 500;
        line-height: 28px;
        color: #767676;
        padding: 16px 8px;
    }
`;

export const ProjectList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 28px 20px;
    padding-bottom: 35px;
    overflow-y: auto;
    height: calc(100vh - 250px);

     ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const Project = styled.div`
    border-radius: 12px;
    border: 1px solid #F2F2F2;
    background: #FFF;
    padding: 16px;
    width: 266px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 168px;
    outline: none;
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    h3{
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        color: #1A1A1A;
        height: 80%;
        cursor: pointer;
    }

    input{
        border: solid 1px #80808054;
        padding: 2px 6px;
        color: #1a1a1a;
        border-radius: 4px;
    }
`;

export const ProjectFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Date = styled.div`
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    color: #6D6D6D;
`;

export const Options = styled.div`
    position: relative;
    img{
        border-radius: 4px;
        cursor: pointer;
        :hover{
            background: #ECECEC;
        }
    }
`;

export const DropDown = styled.div`
    position: absolute;
    width: 144px;
    height: 72px;
    background: #FFFFFF;
    padding: 4px;
    box-shadow: 0px 2px 6px #0000003D;
    border-radius: 8px;
    display: ${props => props.open ? "unset" : "none"};
    top: 30px;
    z-index: 5;
    right: 0px;
`;

export const AnalyseButton = styled.div`
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border-radius: 4px;
    color: #2E2E2E;
    font-weight: 300;
    cursor: pointer;
    &:hover{
        background: #F6F6F6;
    }
    &:active{
        background: #EFEFEF ;
    }
`;

export const RemoveButton = styled.div`
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #2E2E2E;
    font-weight: 300;
    cursor: pointer;
    &:hover{
        background: #F6F6F6;
    }
    &:active{
        background: #EFEFEF ;
    }
`;

export const SaveNameBtn = styled.div`
    cursor: pointer;
    margin-bottom: 30px;
    display: ${props => props.isEnabled ? "none": "flex"};
    width: fit-content;
    color: #006ECC;
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    cursor: pointer;
    background: #EAF5FF;
    padding: 7px 13px;
    border-radius: 4px;
`;

export const NoResult = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    color: grey;
`;


////

export const ProjectCard = styled.div`
width: 330px;
height: 270px;
border-radius: 4px;
background: #FFF;
position: relative;
padding: 28px;
cursor: pointer;
`;

export const LogoContainer = styled.div`
width: 30px;
height: 30px;
flex-shrink: 0;
border-radius: 50%;
background: #F4F5F7;
display: flex;
align-items: center;
justify-content: center;
`;

export const Logo = styled.img`
width: 16px;
height: 16px;
`;

export const PrimaryText = styled.div`
color: #181E39;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 30px */
`;

export const SecondaryText = styled.div`
color: rgba(28, 28, 28, 0.50);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 183.333% */
`;

export const Details = styled.div`
display: flex;
flex-direction: column;
gap: 39px;
margin-top: 24px;

`;

export const PrimaryDetails = styled.div`
display: flex;
flex-direction: column;
gap: 4px;
`;

export const SecondaryDetails = styled.div`
display: flex;
flex-direction: column;
gap: 15px;
`;

export const Row = styled.div`
display: flex;
gap: 8px;
align-items: center;
`;

export const Image = styled.img``;
export const SavedAdsWrapper = styled.div`
   padding: 0 50px;
   width: 100%;
`;