import styled, { keyframes } from "styled-components";

export const TopImageAdsContainer = styled.div`
width: 326px;
height: 232px;
flex-shrink: 0;
border-radius: 8px;
background: #FFF;
`;

export const Header = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
width: calc(100% - 21px - 19px);
margin: 16px 24px 16px 20px;
`;

export const Title = styled.div`
color: #4F4F4F;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 24px */
`;

export const ViewAll = styled.div`
color: rgba(28, 28, 28, 0.40);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
cursor: pointer;
`;

export const EmptyState = styled.div`
width: 326px;
height: 150px;
flex-shrink: 0;
background: #F2F2F2;
position: relative;
display: flex;
align-items: center;
justify-content: center;
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 16.94px;
color: #1c1c1c60;
`;

export const ImageSlider = styled.div`
display: flex;
position: relative;
width: 326px;
height: 232px;
overflow-x: hidden;
`;

export const sliderLeftAnimae = keyframes`
0%{
    transform: translateX(0);
}
50%{
    transform: translateX(-326px);
}
100%{
    transform: translateX(-652px);
}
`;

export const ImageContainer = styled.div`
width: 326px;
height: 150px;
flex-shrink: 0;
background: #F2F2F2;
position: relative;
`;

export const Image = styled.img`
width: 148px;
height: 148px;
position: absolute;
transform: translateX(-50%);
left: 50%;
object-fit: contain;
`;

export const LogoContainer = styled.div`
width: 18px;
height: 18px;
background: #fff;
display: flex;
align-items: center;
justify-content: center;
position: absolute;
top: 8px;
right: 8px;
border-radius: 50%;
`;

export const Logo = styled.img`

`;