import styled from "styled-components";

export const Heading = styled.div`
    color: #646464;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 18px;
    margin-top: 24px;
`;

export const CtaContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px 12px; 
`;


export const Cta = styled.div`
    padding: 7px 6px 7px 20px;
    width: fit-content;
    border-radius: 20px;
    border: 1px solid #CCC;
    background: #FFF;
    color: #808080;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    span{
        border-radius: 13px;
        background: rgb(242, 242, 242);
        margin-left: 8px;
        padding: 3px 9px;
        min-width: 26px;
    }
`;

export const HeadingContainer = styled.div`
     
`;

export const AudienceContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px 12px; 
    overflow-y: auto;
    max-height: 90%;
    ::-webkit-scrollbar {
        width: 4px;               /* width of the entire scrollbar */
    }

    body::-webkit-scrollbar-track {
        /* background: red;        color of the tracking area */
    }
    ::-webkit-scrollbar-thumb {
        background-color: #80808030;    /* color of the scroll thumb */
        border-radius: 10px;     
    }
`;

export const DemograpicDataContainer = styled.div`
    width: 50%;
    height: 100%;
    padding-right: 24px;
`;

export const PsychograpicDataContainer = styled.div`
    width: 50%;
    height: 100%;
    padding-left: 24px;
`;

export const FunnelContentWrapper = styled.div`
    height: 720px;
`;

export const Divider = styled.div`
    background: #D4D4D4;
    width: 1px;
    height: 95%;
`;

export const KeywordsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px 12px; 
`;

