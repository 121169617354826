import styled from "styled-components";
import AdEditBgImage from "../../../../../../../../media/Dashboard/AdEditBg.svg";
import backgroundSheet from "../../../InputPage/assets/background.png";

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const TemplateContainer = styled.div`
  width: calc(100% - 480px);
  background: url(${backgroundSheet});
  background-size: cover;
  background-position: center;
`;

export const TemplateWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* img{
        width: 80%;
        max-width: 500px;
    } */
  &#zoomClass {
    @media (max-width: 1320px) {
      zoom: 0.9;
    }
    @media (max-width: 1260px) {
      zoom: 0.8;
    }
    @media (max-width: 1216px) {
      zoom: 0.7;
    }
  }
`;

export const EditContainer = styled.div`
  width: 480px;
  display: flex;
  background: #fff;
`;

export const NavigationComponent = styled.div`
  width: 80px;
  padding: 16px 8px;
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

export const NavElementContainer = styled.div`
  height: 64px;
  width: 64px;
  display: flex;
  border-radius: 4px;
  border: solid 1px #f0f0f0;
  background: ${(props) => (props.isActive ? "#1C1C1C" : "#FDFDFD")};
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  gap: 8px;
  p {
    font-size: 14px;
    font-weight: 400;
    color: ${(props) => (props.isActive ? "#FFF" : "#1C1C1C")};
  }
  img {
    filter: ${(props) => (props.isActive ? "invert(1)" : "none")};
  }
`;

export const Divider = styled.div`
  height: 1px;
  background: #e8e8e8;
  margin: 2px 0px;
`;

export const EditComponent = styled.div`
  padding: 24px 16px;
  border-left: solid 1px #f0f0f0;
  width: 100%;
  overflow-y: auto;
`;
export const SaveButton = styled.div`
  background: ${(props) =>
    props.isSaved ? "rgb(17, 25, 38)" : "rgb(255, 113, 30)"};
  border-radius: 7px;
  color: #fff;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: ${(props) => (props.isSaved ? "none" : "all")};
`;
