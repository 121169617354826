import statics from "../../../../statics";
import { FlexContainer, Header, MainContainer, InputContainer, Icon, Category, BackgroundContainer, DropdownContainer, DropdownTitle, DropdownItem, CategoryIcon } from "./ChoosingCategory.styled";
import Apps from "../../../../assets/apps.svg"
import Footer from "./Footer";

import { useRef, useState } from "react";
import { useImageAdGenerator } from "../../../../context/imageAdGeneration";


function ChoosingCategory() {
    const { enteringDomain: {header, description, placeHolder, examplesLabel, example, generate, categories}, pages } = statics
    
    const categoryRef = useRef(null)
    const [isDropdownActive, setDropDownActive] = useState(false)
    const { imageAdGeneratorState, imageAdGeneratorDispatch } = useImageAdGenerator()
    const [inputFocussed, setInputFocussed] = useState(false)

    const onNext = () => {
        if(imageAdGeneratorState.category){
            imageAdGeneratorDispatch({type: "UPDATE_CURRENT_PAGE", value: pages[1]})
        }
        else{
            alert("Choose a category")
        }
    }

    const handleDropdownActivation = () => {
        setDropDownActive(!isDropdownActive)
    }

    const selectCategory = (category) => {
        imageAdGeneratorDispatch({type: "SET_CATEGORY", value: category})
        setDropDownActive(false)
    }

    const renderDropdown = () => {

        return(
            <DropdownContainer isActive={isDropdownActive}>
                <DropdownTitle>Categories</DropdownTitle>
                {
                    categories.map((c) => {
                        return(
                            <DropdownItem onClick={() => selectCategory(c)} >
                                <CategoryIcon src={c.icon} alt="icon" />
                                {c.name}
                            </DropdownItem>
                        )
                    })
                }
            </DropdownContainer>
        )
    }
    return ( 
        <BackgroundContainer>
            <MainContainer>
                <Header>Category</Header>
                {/* <Description>{description}</Description> */}
                <FlexContainer>
                    <InputContainer isFocussed={inputFocussed} onClick={handleDropdownActivation}>
                        <Icon src={Apps} alt="icon" />
                        <Category onChange={(e)=> imageAdGeneratorDispatch({type: "SET_CATEGORY", value: e.target.value})} value={imageAdGeneratorState.category} ref={categoryRef} placeholder="Enter a category"
                        onFocus={() => setInputFocussed(true)}
                        onBlur={() => setInputFocussed(false)}
                        />
                        {/* <DropdownIcon src={Dropdown} isActive={!isDropdownActive} alt="dd" />
                        {renderDropdown()} */}
                    </InputContainer>
                    {/* <GenerateButton isActive={!!imageAdGeneratorState.category} onClick={onNext} >{generate}</GenerateButton> */}
                </FlexContainer>
                {/* <ExamplesContainer>
                    <Label>{examplesLabel}</Label>
                    {
                        example.map((eg)=>{
                            return <Example>{eg}</Example>
                        })
                    }
                </ExamplesContainer> */}
                <Footer />
            </MainContainer>
        </BackgroundContainer>
     );
}

export default ChoosingCategory;