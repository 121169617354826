import styled from "styled-components";

export const Container = styled.div`
    width: 738px;
    height: 387px;
    position: relative;
    padding-top: 36px;
`;

export const Header = styled.div`
color: var(--black-100, #1C1C1C);
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 38px; /* 190% */
margin: 0 0 20px 35px;
`;

export const BrandDescriptionContainer = styled.div`
    width: 738px;
    height: 246px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #FFF;
    padding: 0 32px;
`;

export const Label = styled.div`
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    /* Large (default)/Subheadline */
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    letter-spacing: 0.15px;
    margin-bottom: 11px;
`;

export const Description = styled.textarea`
    resize: none;
    padding: 16px 20px;
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    width: 674px;
    height: 162px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid #DADFE4;
    outline: none;
    rows: 6;
`;