import React, { useState } from "react";
import DownArrow from "../../../../../../../../../../media/Dashboard/uparrow.png";
import FacebookIcon from "../../../../../../../../../../media/Dashboard/facebook.svg";
import LinkedinIcon from "../../../../../../../../../../media/Dashboard/linkedin.svg";
import {
  Wrapper,
  SelectedPlatformDropdown,
  SelectedPlatform,
  DropDown,
  DropDownOption,
  PreviewElement,
  AudienceContainer,
  BudgetContainer,
  PublishButton,
  PreviewAdCard,
  PreviewAdHeader,
  HeaderTitleWrapper,
  PreviewAdDescription,
  TemplateWrap,
} from "./index.styled";
import ImageAdResultDummy from "../../../../../../../../../../media/Dashboard/ImageAdResultDummy.svg";
import GlobeIcon from "../../../../../../../../../../media/Dashboard/globeIcon.svg";
import TreeDotsIcon from "../../../../../../../../../../media/Dashboard/threedots.svg";

import { Divider } from "../../index.styled";
import { getSearchParams } from "../../../../../../../../../../utils/helper";
import { toPng } from "html-to-image";
import { useAdGenerator } from "../../../../../../../context/adGeneration";
import { useLocation } from "react-router-dom";
import { useImageAdGenInfo } from "../../../../../../../hooks/useImageAdGenInfo";
import { useAuth } from "../../../../../../../../../../context/auth";

const Preview = () => {
  const {
    adGeneratorState: {
      scaleValue,
      activeAdTemplate,
      selectedProject,
      updatetemplate,
    },
    adGeneratorDispatch,
  } = useAdGenerator();
  const { authState, authDispatch } = useAuth()

  const { SaveGeneratedTemplateMutate } = useImageAdGenInfo();

  const [selectedPlatform, setSelectedPlatform] = useState(0);
  const [dropDownOpen, setDropwDownOpen] = useState(false);
  const [downloadingStatus, setDownLoadingStatus] = useState(false);
  const location = useLocation();

  const PlatformList = [
    {
      name: "Facebook",
      icon: FacebookIcon,
    },
    {
      name: "Linkedin",
      icon: LinkedinIcon,
    },
  ];
  const downloadFunction = async () => {
    const { id, variationId } = getSearchParams(location.search);

    let htmlContent = document.querySelector(".outerHtml");
    if (htmlContent) {
      // document.querySelectorAll(`div`).forEach((element) => {
      //     element.style.outline = "none";
      // })

      setDownLoadingStatus(true);
      // const fontEmbedCss = await htmlToImage.getFontEmbedCSS(parsedHtmlRef.current);
      toPng(htmlContent, {
        cacheBust: true,
        pixelRatio: scaleValue,
        // fontEmbedCss: fontEmbedCss // Pass fontEmbedCss option here
      })
        .then((dataUrl) => {
          console.log(dataUrl, "dataUrl");
          const link = document.createElement("a");
          link.download = "my-image-name.png";
          SaveGeneratedTemplateMutate({
            campaignId: id,
            templateId: variationId,
            base64String: dataUrl.replace("data:image/png;base64,", ""),
          });
          link.href = dataUrl;
          link.click();
          setDownLoadingStatus(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Wrapper>
      <h2>Publish this ad</h2>
      <SelectedPlatformDropdown>
        <SelectedPlatform
          dropDownOpen={dropDownOpen}
          onClick={() => setDropwDownOpen((prev) => !prev)}
        >
          <div>
            <img src={PlatformList[selectedPlatform].icon} />
            <p>{PlatformList[selectedPlatform].name}</p>
          </div>
          <img className="arrow" src={DownArrow} />
        </SelectedPlatform>
        {dropDownOpen && (
          <DropDown>
            {PlatformList.map((platform, index) => {
              return (
                <DropDownOption
                  onClick={() => {
                    setSelectedPlatform(index);
                    setDropwDownOpen((prev) => !prev);
                  }}
                >
                  <img src={platform.icon} />
                  {platform.name}
                </DropDownOption>
              );
            })}
          </DropDown>
        )}
      </SelectedPlatformDropdown>
      <PreviewElement>
        <PreviewAdCard>
          <PreviewAdHeader>
            <img
              src={`https://www.google.com/s2/favicons?domain=${authState.defaultProjectInfo.website}`}
            />
            <HeaderTitleWrapper>
              <h3>{selectedProject.projectName}</h3>
              <p>
                Sponsored
                <img src={GlobeIcon} />
              </p>
            </HeaderTitleWrapper>
            <img className="three-dots" src={TreeDotsIcon} />
          </PreviewAdHeader>
          <PreviewAdDescription>
            Watch this HubSpot Academy On-the-go video to discover how LinkedIn
            CRM Sync can streamline your processes and boost you…
          </PreviewAdDescription>
          <TemplateWrap
            // ref={parsedHtmlRef}
            className="outerHtml"
            dangerouslySetInnerHTML={{
              __html: `${updatetemplate}`,
            }}
          />
          {/* <img className="ad-image" src={ImageAdResultDummy} /> */}
        </PreviewAdCard>
      </PreviewElement>
      {/* <AudienceContainer>
        <label>Estimated target audience</label>
        <p>2,458,759</p>
      </AudienceContainer>
      <Divider />
      <BudgetContainer>
        <label>Recommended budget</label>
        <p>
          $5.00 - $25.00 <span>/ per day</span>
        </p>
      </BudgetContainer> */}
      {/* <PublishButton>
            Publish
        </PublishButton> */}
      <PublishButton isDisabled={downloadingStatus} onClick={downloadFunction}>
        {downloadingStatus ? "Downloading..." : "Download"}
      </PublishButton>
    </Wrapper>
  );
};

export default Preview;
