import styled from "styled-components";

export const OverViewConatiner = styled.div`
display:flex;
flex-direction:column;
gap:8px;

p {
color: #9C9C9C;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%;
}
`;

export const OverViewHeader = styled.div`
display:flex;
justify-content:space-between;

h2 {
color: #000;
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 38px;
}

.persona-category-count{
display:flex;
gap:30px;
align-items:center;
}


p {
color: #4F4F4F;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.28px;
}

p>span {
color:#FF711E;
}
`

export const FileterContainer = styled.div`
width:100%;
border-radius: 10px;
border: 1px #DADFE4;
background: #FFF;
`;


export const FilterSubContainer = styled.div`
padding:20px 18px;
display:flex;
justify-content:space-around;
`;



export const Filters = styled.div`
display:flex;
flex-direction:column;
gap:18px;

`;





export const ApplyButtonContainer = styled.div`
width:40%;
display:flex;
justify-content:flex-end;
align-items:center;
gap:17px;
padding-right:21px;


 p{
color: rgba(28, 28, 28, 0.50);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}

 button{
width: 98px;
height: 40px;
flex-shrink: 0;
border-radius: 8px;
background: #111926;
color: #FFF;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;}
`

