export const eventsList = [
    {
        name: "New Year",
        date: "2023-01-01"
    },
    {
        name: "Science Fiction Day",
        date: "2023-01-02"
    },
    {
        name: "National Trivia Day",
        date: "2023-01-04"
    },
    {
        name: "National Bird Day",
        date: "2023-01-05"
    },
    {
        name: "Clean Off Your Desk Day",
        date: "2023-01-11"
    },
    {
        name: "National Sticker Day",
        date: "2023-01-13"
    },
    {
        name: "National Hat Day",
        date: "2023-01-15"
    },
    {
        name: "National Bagel Day",
        date: "2023-01-15"
    },
    {
        name: "Pongal",
        date: "2023-01-16"
    },
    {
        name: "Martin Luther King, Jr. Day",
        date: "2023-01-17"
    },
    {
        name: "Cheese Lovers Day",
        date: "2023-01-20"
    },
    {
        name: "Get to Know Your Customers Day",
        date: "2023-01-20"
    },
    {
        name: "National Hugging Day",
        date: "2023-01-21"
    },
    {
        name: "National Compliment Day",
        date: "2023-01-24"
    },
    {
        name: "Opposite Day",
        date: "2023-01-25"
    },
    {
        name: "Data Privacy Day",
        date: "2023-01-28"
    },
    {
        name: "Groundhog Day",
        date: "2023-02-02"
    },
    {
        name: "World Wetlands Day",
        date: "2023-02-02"
    },
    {
        name: "World Cancer Day",
        date: "2023-02-04"
    },
    {
        name: "National Weatherperson’s Day",
        date: "2023-02-05"
    },
    {
        name: "National Send a Card to a Friend Day",
        date: "2023-02-07"
    },
    {
        name: "National Boy Scouts Day",
        date: "2023-02-08"
    },
    {
        name: "Safer Internet Day",
        date: "2023-02-08"
    },
    {
        name: "National Pizza Day",
        date: "2023-02-09"
    },
    {
        name: "Inventors Day",
        date: "2023-02-11"
    },
    {
        name: "International Day of Women",
        date: "2023-02-11"
    },
    {
        name: "Girls in Science",
        date: "2023-02-11"
    },
    {
        name: "World Radio Day",
        date: "2023-02-13"
    },
    {
        name: "Galentine’s Day",
        date: "2023-02-13"
    },
    {
        name: "Random Acts of Kindness Day",
        date: "2023-02-17"
    },
    {
        name: "National Battery Day",
        date: "2023-02-18"
    },
    {
        name: "World Day of Social Justice",
        date: "2023-02-20"
    },
    {
        name: "Love Your Pet Day",
        date: "2023-02-20"
    },
    {
        name: "National Read Across America Day",
        date: "2023-03-02"
    },
    {
        name: "World Wildlife Day",
        date: "2023-03-03"
    },
    {
        name: "National Grammar Day",
        date: "2023-03-04"
    },
    {
        name: "National Employee Appreciation Day",
        date: "2023-03-04"
    },
    {
        name: "National Day of Unplugging",
        date: "2023-03-04"
    },
    {
        name: "National Dentist’s Day",
        date: "2023-03-06"
    },
    {
        name: "National Be Heard Day",
        date: "2023-03-07"
    },
    {
        name: "National Cereal Day",
        date: "2023-03-07"
    },
    {
        name: "International Women’s Day",
        date: "2023-03-08"
    },
    {
        name: "National Proofreading Day",
        date: "2023-03-08"
    },
    {
        name: "National Pack Your Lunch Day",
        date: "2023-03-10"
    },
    {
        name: "National Worship of Tools Day",
        date: "2023-03-11"
    },
    {
        name: "Popcorn Lover’s Day",
        date: "2023-03-11"
    },
    {
        name: "National Girl Scout Day",
        date: "2023-03-12"
    },
    {
        name: "Pi Day",
        date: "2023-03-14"
    },
    {
        name: "Potato Chip Day",
        date: "2023-03-14"
    },
    {
        name: "World Consumer Rights Day",
        date: "2023-03-15"
    },
    {
        name: "National Freedom of Information Day",
        date: "2023-03-16"
    },
    {
        name: "Awkward Moments Day",
        date: "2023-03-18"
    },
    {
        name: "World Sleep Day",
        date: "2023-03-18"
    },
    {
        name: "National Let’s Laugh Day",
        date: "2023-03-19"
    },
    {
        name: "International Day of Happiness",
        date: "2023-03-20"
    },
    {
        name: "World Storytelling Day",
        date: "2023-03-20"
    },
    {
        name: "International Day for the Elimination of Racial Discrimination",
        date: "2023-03-21"
    },
    {
        name: "World Poetry Day",
        date: "2023-03-21"
    },
    {
        name: "World Water Day",
        date: "2023-03-22"
    },
    {
        name: "National Puppy Day",
        date: "2023-03-23"
    },
    {
        name: "Tolkien Reading Day",
        date: "2023-03-25"
    },
    {
        name: "National Spinach Day",
        date: "2023-03-26"
    },
    {
        name: "Purple Day",
        date: "2023-03-26"
    },
    {
        name: "Earth Hour Day",
        date: "2023-03-27"
    },
    {
        name: "Doctor’s Day",
        date: "2023-03-30"
    },
    {
        name: "National Take a Walk in the Park Day",
        date: "2023-03-30"
    },
    {
        name: "World Backup Day",
        date: "2023-03-31"
    },
    {
        name: "Transgender Day of Visibility",
        date: "2023-03-31"
    },
    {
        name: "April Fools Day",
        date: "2023-04-01"
    },
    {
        name: "World Autism Awareness Day",
        date: "2023-04-02"
    },
    {
        name: "Find a Rainbow Day",
        date: "2023-04-03"
    },
    {
        name: "Hug a Newsperson Day",
        date: "2023-04-04"
    },
    {
        name: "World Health Day",
        date: "2023-04-05"
    },
    {
        name: "National Walking Day",
        date: "2023-04-06"
    },
    {
        name: "World Health Day",
        date: "2023-04-07"
    },
    {
        name: "National Siblings Day",
        date: "2023-04-10"
    },
    {
        name: "Encourage a Young Writer Day",
        date: "2023-04-10"
    },
    {
        name: "National Pet Day",
        date: "2023-04-11"
    },
    {
        name: "International Day of Human Space Flight",
        date: "2023-04-12"
    },
    {
        name: "National Tax Day",
        date: "2023-04-15"
    },
    {
        name: "National Wear Your Pajamas to Work Day",
        date: "2023-04-16"
    },
    {
        name: "Haiku Poetry Day",
        date: "2023-04-17"
    },
    {
        name: "National Columnists’ Day",
        date: "2023-04-18"
    },
    {
        name: "National Look-Alike Day",
        date: "2023-04-20"
    },
    {
        name: "National High-Five Day",
        date: "2023-04-21"
    },
    {
        name: "National Administrative Professionals Day",
        date: "2023-04-21"
    },
    {
        name: "Get to Know Your Customers Day",
        date: "2023-04-21"
    },
    {
        name: "Earth Day",
        date: "2023-04-22"
    },
    {
        name: "National Picnic Day",
        date: "2023-04-23"
    },
    {
        name: "World Book Day",
        date: "2023-04-23"
    },
    {
        name: "National Telephone Day",
        date: "2023-04-25"
    },
    {
        name: "Denim Day",
        date: "2023-04-27"
    },
    {
        name: "National Take Our Daughters",
        date: "2023-04-28"
    },
    {
        name: "Sons to Work Day",
        date: "2023-04-28"
    },
    {
        name: "International Dance Day",
        date: "2023-04-29"
    },
    {
        name: "National Honesty Day",
        date: "2023-04-30"
    },
    {
        name: "National Adopt a Shelter Pet Day",
        date: "2023-04-30"
    },
    {
        name: "International Workers Day",
        date: "2023-05-01"
    },
    {
        name: "Star Wars Day",
        date: "2023-05-04"
    },
    {
        name: "Cinco de Mayo",
        date: "2023-05-05"
    },
    {
        name: "World Password Day",
        date: "2023-05-05"
    },
    {
        name: "National Nurses Day",
        date: "2023-05-06"
    },
    {
        name: "Space Day",
        date: "2023-05-06"
    },
    {
        name: "Mother’s Day ",
        date: "2023-05-08"
    },
    {
        name: "Europe Day",
        date: "2023-05-09"
    },
    {
        name: "National Receptionist Day",
        date: "2023-05-11"
    },
    {
        name: "National Limerick Day",
        date: "2023-05-12"
    },
    {
        name: "International Day of Families",
        date: "2023-05-15"
    },
    {
        name: "Love a Tree Day",
        date: "2023-05-16"
    },
    {
        name: "International Day Against Homophobia",
        date: "2023-05-17"
    },
    {
        name: "Transphobia IDAHOT2023",
        date: "2023-05-17"
    },
    {
        name: "National Memo Day",
        date: "2023-05-21"
    },
    {
        name: "National Scavenger Hunt Day",
        date: "2023-05-24"
    },
    {
        name: "Heat Awareness Day",
        date: "2023-05-27"
    },
    {
        name: "Paperclip Day",
        date: "2023-05-29"
    },
    {
        name: "Global Day of Parents",
        date: "2023-06-01"
    },
    {
        name: "National Donut Day",
        date: "2023-06-03"
    },
    {
        name: "World Environment Day",
        date: "2023-06-05"
    },
    {
        name: "National Cancer Survivors Day",
        date: "2023-06-05"
    },
    {
        name: "Higher Education Day",
        date: "2023-06-06"
    },
    {
        name: "World Oceans Day",
        date: "2023-06-08"
    },
    {
        name: "Best Friends Day",
        date: "2023-06-08"
    },
    {
        name: "International Children’s Day",
        date: "2023-06-12"
    },
    {
        name: "World Blood Donor Day",
        date: "2023-06-14"
    },
    {
        name: "National Flag Day",
        date: "2023-06-14"
    },
    {
        name: "Juneteenth (Freedom Day)",
        date: "2023-06-19"
    },
    {
        name: "World Refugee Day",
        date: "2023-06-20"
    },
    {
        name: "National Selfie Day",
        date: "2023-06-21"
    },
    {
        name: "World Music Day",
        date: "2023-06-21"
    },
    {
        name: "International Yoga Day",
        date: "2023-06-21"
    },
    {
        name: "Take Your Dog to Work Day",
        date: "2023-06-24"
    },
    {
        name: "National Sunglasses Day",
        date: "2023-06-27"
    },
    {
        name: "Social Media Day",
        date: "2023-06-30"
    },
    {
        name: "National Handshake Day",
        date: "2023-06-30"
    },
    {
        name: "National Postal Worker Day",
        date: "2023-07-01"
    },
    {
        name: "World UFO Day",
        date: "2023-07-02"
    },
    {
        name: "World Chocolate Day",
        date: "2023-07-07"
    },
    {
        name: "National Video Games Day",
        date: "2023-07-08"
    },
    {
        name: "Cheer Up the Lonely Day",
        date: "2023-07-11"
    },
    {
        name: "Give Something Away Day",
        date: "2023-07-15"
    },
    {
        name: "World Emoji Day",
        date: "2023-07-17"
    },
    {
        name: "Nelson Mandela International Day",
        date: "2023-07-18"
    },
    {
        name: "Get to Know Your Customers Day",
        date: "2023-07-21"
    },
    {
        name: "Talk in an Elevator Day",
        date: "2023-07-29"
    },
    {
        name: "International Day of Friendship",
        date: "2023-07-30"
    },
    {
        name: "Respect for Parents Day",
        date: "2023-08-01"
    },
    {
        name: "National Coloring Book Day",
        date: "2023-08-02"
    },
    {
        name: "International Cat Day",
        date: "2023-08-08"
    },
    {
        name: "National Book Lovers Day",
        date: "2023-08-09"
    },
    {
        name: "National Lazy Day",
        date: "2023-08-10"
    },
    {
        name: "National Sons",
        date: "2023-08-11"
    },
    {
        name: "Daughters Day",
        date: "2023-08-11"
    },
    {
        name: "International Youth Day",
        date: "2023-08-12"
    },
    {
        name: "World Elephant Day",
        date: "2023-08-12"
    },
    {
        name: "International Lefthanders Day",
        date: "2023-08-13"
    },
    {
        name: "National Financial Awareness Day",
        date: "2023-08-14"
    },
    {
        name: "National Relaxation Day",
        date: "2023-08-15"
    },
    {
        name: "National Tell a Joke Day",
        date: "2023-08-16"
    },
    {
        name: "World Photo Day",
        date: "2023-08-19"
    },
    {
        name: "World Humanitarian Day",
        date: "2023-08-19"
    },
    {
        name: "National Lemonade Day",
        date: "2023-08-20"
    },
    {
        name: "National Dog Day",
        date: "2023-08-26"
    },
    {
        name: "National Women’s Equality Day",
        date: "2023-08-26"
    },
    {
        name: "National Wildlife Day",
        date: "2023-09-04"
    },
    {
        name: "International Day of Charity",
        date: "2023-09-05"
    },
    {
        name: "Read a Book Day",
        date: "2023-09-06"
    },
    {
        name: "International Literacy Day",
        date: "2023-09-08"
    },
    {
        name: "Stand Up To Cancer Day",
        date: "2023-09-09"
    },
    {
        name: "National Day of Service",
        date: "2023-09-11"
    },
    {
        name: "Remembrance",
        date: "2023-09-11"
    },
    {
        name: "National Grandparents Day",
        date: "2023-09-11"
    },
    {
        name: "National Day of Encouragement",
        date: "2023-09-12"
    },
    {
        name: "Talk Like a Pirate Day",
        date: "2023-09-19"
    },
    {
        name: "International Day of Peace",
        date: "2023-09-21"
    },
    {
        name: "Car-Free Day",
        date: "2023-09-22"
    },
    {
        name: "Hobbit Day",
        date: "2023-09-22"
    },
    {
        name: "National Pancake Day",
        date: "2023-09-26"
    },
    {
        name: "World Tourism Day",
        date: "2023-09-27"
    },
    {
        name: "World Rabies Day",
        date: "2023-09-28"
    },
    {
        name: "National Good Neighbor Day",
        date: "2023-09-28"
    },
    {
        name: "National Women’s Health",
        date: "2023-09-28"
    },
    {
        name: "Fitness Day",
        date: "2023-09-28"
    },
    {
        name: "International Podcast Day",
        date: "2023-09-30"
    },
    {
        name: "International Coffee Day",
        date: "2023-10-01"
    },
    {
        name: "World Vegetarian Day",
        date: "2023-10-01"
    },
    {
        name: "International Day of Nonviolence",
        date: "2023-10-02"
    },
    {
        name: "National Techies Day",
        date: "2023-10-03"
    },
    {
        name: "World Animal Day",
        date: "2023-10-04"
    },
    {
        name: "National Taco Day",
        date: "2023-10-04"
    },
    {
        name: "World Teachers Day",
        date: "2023-10-05"
    },
    {
        name: "World Smile Day",
        date: "2023-10-07"
    },
    {
        name: "World Mental Health Day",
        date: "2023-10-10"
    },
    {
        name: "International Day of the Girl",
        date: "2023-10-11"
    },
    {
        name: "World Sight Day",
        date: "2023-10-13"
    },
    {
        name: "National Dessert Day",
        date: "2023-10-14"
    },
    {
        name: "Global Handwashing Day",
        date: "2023-10-15"
    },
    {
        name: "World Food Day",
        date: "2023-10-16"
    },
    {
        name: "Bosses Day",
        date: "2023-10-16"
    },
    {
        name: "International Day for the Eradication of Poverty",
        date: "2023-10-17"
    },
    {
        name: "World Statistics Day",
        date: "2023-10-20"
    },
    {
        name: "Reptile Awareness Day",
        date: "2023-10-21"
    },
    {
        name: "United Nations Day",
        date: "2023-10-24"
    },
    {
        name: "Greasy Foods Day",
        date: "2023-10-25"
    },
    {
        name: "National Publicist Day",
        date: "2023-10-30"
    },
    {
        name: "Checklist Day",
        date: "2023-10-30"
    },
    {
        name: "HHalloween",
        date: "2023-10-31"
    },
    {
        name: "World Vegan Day",
        date: "2023-11-01"
    },
    {
        name: "National Authors Day",
        date: "2023-11-01"
    },
    {
        name: "National Cook For Your Pets Day",
        date: "2023-11-01"
    },
    {
        name: "National Stress Awareness Day",
        date: "2023-11-02"
    },
    {
        name: "National Sandwich Day",
        date: "2023-11-03"
    },
    {
        name: "National Candy Day",
        date: "2023-11-04"
    },
    {
        name: "National Cappuccino Day",
        date: "2023-11-08"
    },
    {
        name: "Science, Technology, Engineering,",
        date: "2023-11-08"
    },
    {
        name: "Medicine (STEM) Day",
        date: "2023-11-08"
    },
    {
        name: "World Kindness Day",
        date: "2023-11-13"
    },
    {
        name: "World Diabetes Day",
        date: "2023-11-14"
    },
    {
        name: "Clean Out Your Refrigerator Day",
        date: "2023-11-15"
    },
    {
        name: "America Recycles Day",
        date: "2023-11-15"
    },
    {
        name: "International Day for Tolerance",
        date: "2023-11-16"
    },
    {
        name: "International Students Day",
        date: "2023-11-17"
    },
    {
        name: "International Men’s Day",
        date: "2023-11-19"
    },
    {
        name: "Universal Children’s Day",
        date: "2023-11-20"
    },
    {
        name: "World Hello Day",
        date: "2023-11-21"
    },
    {
        name: "Black friday",
        date: "2023-11-24"
    },
    {
        name: "National Cake Day",
        date: "2023-11-26"
    },
    {
        name: "Electronic Greeting Card Day",
        date: "2023-11-29"
    },
    {
        name: "Computer Security Day",
        date: "2023-11-30"
    },
    {
        name: "World AIDS Day",
        date: "2023-12-01"
    },
    {
        name: "International Day of Persons with Disabilities",
        date: "2023-12-03"
    },
    {
        name: "National Cookie Day",
        date: "2023-12-04"
    },
    {
        name: "World Soil Day",
        date: "2023-12-05"
    },
    {
        name: "Microwave Oven Day",
        date: "2023-12-06"
    },
    {
        name: "Pretend to Be a Time Traveler Day",
        date: "2023-12-08"
    },
    {
        name: "Human Rights Day",
        date: "2023-12-10"
    },
    {
        name: "Nobel Prize Day",
        date: "2023-12-10"
    },
    {
        name: "International Mountain Day",
        date: "2023-12-11"
    },
    {
        name: "Crossword Puzzle Day",
        date: "2023-12-21"
    },
    {
        name: "Christmas",
        date: "2023-12-25"
    },
    {
        name: "No Interruptions Day",
        date: "2023-12-27"
    }
]