import React from "react";

import { AdBodyBottomContainer, AdBodyContainer, AdContainer, AdContent, AdDate, AdDetailsButton,  Country,  CountryWrap,  HoverCard, HoverCardWrapper, ImageContainer, ProductName, ProductPrice, WebsiteUrl } from "./index.styled";
import { useSavedAds } from "../../../context/savedads";
import RightUpperBlackArrow from "../../../../../../media/Dashboard/RightUpperBlackArrow.svg"
import AltImage from '../../../../../../media/Dashboard/altimage.png'
import locationImg from '../../../../../../media/Dashboard/locationCard.svg'    

const AmazonCard = ({ad}) => {
    const { savedAdsDispatch } = useSavedAds()
    const image = ad.image ? <img src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${ad.id}`} onError={() => { }} /> : <img src={AltImage} onError={() => { }} />;

    const convertTimestamp = (timestamp) => {
        var date = new Date(timestamp);

        var monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        var month = monthNames[date.getMonth()];

        var year = date.getFullYear();

        var formattedDate = date.getDate() + ' ' + month + ' ' + year;

        return formattedDate;
    }
    const toggleDetailsModal = () => {
            savedAdsDispatch({type: 'SET_SELECTED_AD_FOR_DETAIL', value: ad})
            savedAdsDispatch({ type: 'UPDATE_DETAILS_MODAL', value: true })
    }
    return <AdContainer key={
        ad.id
    }>

    <AdContent>
      <HoverCard className='hover-element' onClick={() => toggleDetailsModal(ad)}>
        <HoverCardWrapper>
          <AdDetailsButton onClick={() => toggleDetailsModal(ad)}>
            <img src={RightUpperBlackArrow} />
            <span>Ad Details</span>
          </AdDetailsButton>
        </HoverCardWrapper>
      </HoverCard>
      <ImageContainer>
        {image}
        <span>ad</span>
      </ImageContainer>
      <AdBodyContainer>
        <ProductName>
          {ad.title}
        </ProductName>
        <WebsiteUrl>
          {ad.url}
        </WebsiteUrl>
        <AdDate>
          {convertTimestamp(ad.firstSeen)} -
          {convertTimestamp(ad.lastSeen)}
        </AdDate>
        <AdBodyBottomContainer>
          <CountryWrap>
            <img src={locationImg} />
            <Country>{ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : "--"}</Country>
          </CountryWrap>
          <ProductPrice>
            {ad.price}
          </ProductPrice>
        </AdBodyBottomContainer>
      </AdBodyContainer>
    </AdContent>
  </AdContainer>
}

export default AmazonCard