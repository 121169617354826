import styled from "styled-components";

export const CountyStatsTable = styled.div`
    width: 346px;
    background: #FFFFFF;
`;

export const AllHeadCount = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F6F6F6;
    padding: 6px 20px;
    p{
        width: 50%;
        font-size: 14px;
        color: #555555;
        font-weight: 300;
    }
    .percentage{
        width: 23%;
        font-weight: 400;
        text-align: end;
    } 
    progress{
        width: 17%;
    }
    
`;

export const CountryStatsData = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 20px;
    margin-top: 12px;
    span{
        margin-right: 8px;
    }
    p{
        width: 50%;
        font-size: 14px;
        color: #555555;
        font-weight: 300;
        line-height: normal;
    }
    .percentage{
        font-weight: 400;
        width: 23%;
        text-align: end;
    } 
    
`;

export const Progress = styled.progress`
        width: 17%;
        height: 5px;
        appearance: none;
        border: none;
        /* background-color: #ddd; */
        border-radius: 5px;

        &::-webkit-progress-bar {
            background-color: #ddd;
            border-radius: 10px;
        }

        &::-webkit-progress-value {
            background-color: #006ecc;
            border-radius: 10px;
        }

        &::-moz-progress-bar {
            background-color: #3498db;
            border-radius: 10px;
        }

        &::-ms-fill {
            background-color: #3498db;
            border-radius: 10px;
        }
`;
