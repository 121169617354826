import React from 'react'
import { useSearchParams, useNavigate } from "react-router-dom"

// Styles
import {Container, PlatformsContainer,ImgContainer } from "./index.styled"

import Google from "../../../../../../media/Images/Google.svg"
import Bing from "../../../../../../media/Images/Bing.svg"
import Facebook from "../../../../../../media/Dashboard/meta16.png"
import GoogleEcom from "../../../../../../media/Images/Google Ecom.svg"
import BingEcom from "../../../../../../media/Images/Bing Ecom.svg"
import Display from "../../../../../../media/Dashboard/display.svg"

import { useUrlAnalysis } from '../../../context/urlanalysis'

const AvailablePlatforms = ({pathName}) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()

    const { urlAnalysisState, urlAnalysisDispatch  } = useUrlAnalysis()
    
    const platforms = [{
        img: Google,
        path: "google",
        platforms: ["google"],
        width: "16px",
        height: "16px",
        funnelSupport: true
    },{
        img: Bing,
        path: "bing",
        platforms: ["bing"],
        width: "16px",
        height: "16px",
        funnelSupport: true
    },
    // {
    //     img: Youtube,
    //     path: "youtube",
    //     platforms: ["youtube"],
    //     width: "23px",
    //     height: "16px"
    // },
    {
        img: Facebook,
        path: "meta",
        platforms: ["facebook"],
        width: "21px",
        height: "14px",
        funnelSupport: true
    },
    // {
    //     img: Linkedin,
    //     path: "linkedin",
    //     platforms: ["linkedin"],
    //     width: "16px",
    //     height: "16px"
    // },
    // {
    //     img: Reddit,
    //     path: "reddit",
    //     platforms: ["reddit"],
    //     width: "16px",
    //     height: "16px"
    // },
    {
        img: GoogleEcom,
        path: "googlepla",
        platforms: ["googleecom"],
        width: "53px",
        height: "16px",
        funnelSupport: false
    },{
        img: BingEcom,
        path: "bingpla",
        platforms: ["bingecom"],
        width: "53px",
        height: "16px",
        funnelSupport: false
    }
    // ,{
    //     img: Flipkart,
    //     path: "flipkart",
    //     platforms: ["flipkart"],
    //     width: "16px",
    //     height: "16px"
    // },{
    //     img: Amazon,
    //     path: "amazon",
    //     platforms: ["amazon"],
    //     width: "16px",
    //     height: "16px"
    // }
    ,{
        img: Display,
        path: "display",
        platforms: ["display"],
        width: "16px",
        height: "16px",
        funnelSupport: false
    }
]

    const onPlatformClick = (platform) => {
        urlAnalysisDispatch({ type: 'SET_AD_COPY_PLATFORM', value: platform})
    }

    return (
        <Container>
            <h3>Filter by platform:</h3>
            <PlatformsContainer>
                {
                    platforms.map((platform) => {
                        return <ImgContainer isDisabled={urlAnalysisState.funnelOpen ? platform.funnelSupport ? false : true : false} onClick={()=>onPlatformClick(platform.path)} current={(urlAnalysisState.adCopyPlatform == '' && platform.path == 'google') || (urlAnalysisState.adCopyPlatform == platform.path)}>
                        <img src={platform.img} height={platform.height} width={platform.width}/>
                    </ImgContainer>
                    })
                }
            </PlatformsContainer>
        </Container>
    )
}

export default AvailablePlatforms
