import { useEffect } from "react";
import { List, Funnel, LogoContainer, Logo, Name, Count } from "./FunnelList.styled";
import statics from "../../_static"
import { useDomainFunnelAnalysis } from "../../context/domainFunnelAnalysis";
import Skeleton from "react-loading-skeleton";


const FunnelList = ({isFunnelDataLoading}) => {
    const { domainFunnelAnalysisState, domainFunnelAnalysisDispatch } = useDomainFunnelAnalysis()
    const { funnelList } = statics

    const { activeFunnelType, adsCountOnEachFunnel } = domainFunnelAnalysisState
    const handleFunnelTypeChange = (funnelData) => {
        domainFunnelAnalysisDispatch({
            type: "SET_ACTIVE_FUNNEL_TYPE",
            value: funnelData
        })
    }
    const funnelArr = [1,2,3,4,5]

    useEffect(() => {
    }, [adsCountOnEachFunnel])
    return(
        <List>
            
           {
           !isFunnelDataLoading ?
           domainFunnelAnalysisState?.funnelDomainData?.funnelData?.map((funnel) => {
            const { id } = funnel
            const isActive = id === activeFunnelType.id
            const funnelListItem = funnelList.find(item => item.id === id) // Find matching funnelList item

    return (
        <Funnel key={id} isActive={isActive} onClick={() => handleFunnelTypeChange(funnel)} >
            <LogoContainer bg={!isActive ? funnelListItem.logoBgColor : null} >
                {/* Conditionally render the Logo component */}
                {funnelListItem ? <Logo src={funnelListItem.logo} alt={funnel.funnelType} /> : null}
            </LogoContainer>
            <Name isActive={isActive}>{funnel.funnelType}</Name>
            {<Count isActive={isActive}>{funnel.TotalDocuments}</Count>}
        </Funnel>
    )
}) :
        funnelArr.map((item)=>(
        <Funnel>
            <Skeleton height={"100%"} borderRadius= {"16px"}/> 
        </Funnel>
        ))
}

        </List>
    )
}

export default FunnelList