import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import {
  useMutation
} from "@tanstack/react-query"
import NoResultsLogo from "../../../../../../../media/Dashboard/noresults.svg"// Styles
import {
    ResultsContainer,
    CardContainer,
    ResultsWraper,
    AdContainer,
    ImageContainer,
    AdBodyContainer,
    ProductName,
    ProductPrice,
    WebsiteUrl,
    AdBodyBottomContainer,
    AdDate,
    AdDetailsDate,
    AdCountries,
    AdDetailsCountries,
    AdKeywords,
    AveragePosition,
    SeeMoreMoreCountries,
    SeeMoreMoreKeywords,
    DetailsButton,
    DetailsContainer,
    DetailsProductName,
    Left,
    Right,
    DetailsHeading,
    CloseButton,
    DetailsWraper,
    DetailsImageContainer,
    FloatingButtonsContainer,
    Favorite,
    NoResults,
    DisplayLink,
} from "./index.styled"
import _static from "../../../../../../../config/static"
import SearchLoader from '../../../../../../HOC/SearchLoader'


//Icons
import FavorietIcon from "../../../../../../../media/Dashboard/addFavorite.svg"

import DateIcon from "../../../../../../../media/Images/DateIcon.svg"
import CountryIcon from "../../../../../../../media/Images/CountryIcon.svg"
import AltImage from '../../../../../../../media/Dashboard/altimage.png'

import {appClient} from '../../../../../../../utils/axios-utils'
import { useAuth } from '../../../../../../../context/auth'
import { useUrlAnalysis } from '../../../../context/urlanalysis'
import { useRequestData } from '../../../../../../hooks/useRequestData'
import SaveAdsPopup from '../../../../../../components/SaveAdsPopup'

const adDetails = async (id) => {
  const config = {
    url: `/detail/google-ecom/${id}`,
    method: "GET"
  }

  return appClient(config)
}

// const requestDataCrawl = async (data) => {
//   const config = {
//       url: `/track/search/update-search-query`,
//       method: "POST",
//       data
//   }

//   return appClient(config)
// }

const Card = ({ad, idx, basicAdDetails, setBasicAdDetails, containerRef}) => {
    const { authState, authDispatch } = useAuth()
    const useRequestDataHook = useRequestData()
    const { urlAnalysisState, urlAnalysisDispatch } = useUrlAnalysis()
    const [detaislOpen,setDetailsOpen] = useState(false)
    const [seeMoreCountries, setSeeMoreCountries] = useState(false)
    const [seeMoreKeywords, setSeeMoreKeyword] = useState(false)
    const [ showTick, setShowTick ] = useState(false)
    const [ copiedItem, setCopiedItem ] = useState('')
    
    const image = ad.image ? <img src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${ad.id}`} onError={() => {}}/> : <img src={AltImage} onError={() => {}}/>;

    const convertTimestamp = (timestamp) => {
      // Create a new Date object based on the timestamp
      var date = new Date(timestamp);
    
      // Get the month name
      var monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];
      var month = monthNames[date.getMonth()];
    
      // Get the year
      var year = date.getFullYear();
    
      // Assemble the formatted date string
      var formattedDate = date.getDate() + ' ' + month  + ' ' + year;
    
      return formattedDate;
    }

    const {mutate: adsDetailsMutate, isLoading: isDetailsLoading} = useMutation(adDetails, {
      onSuccess: (data) => {
          urlAnalysisDispatch({type:'SET_AD_DETAILS', value: data})
          if(urlAnalysisState.adDetails.id == data.id || !urlAnalysisState.adDetails.id){
              urlAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: !urlAnalysisState.detailsModal})
          }
      },
      onError:  ((e)=>{
          console.log(e)
      })
    })

    const closeDetailsModal = () => {
      urlAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: !urlAnalysisState.detailsModal})
      urlAnalysisDispatch({type:'SET_AD_DETAILS', value: {}})
    }

    const toggleDetailsModal = (ad) => {
      if(urlAnalysisState.adDetails.id){
        if((urlAnalysisState.adDetails.id == ad.id) && urlAnalysisState.detailsModal){
            //if details is open and clicked on close button on ads card
            setBasicAdDetails({})
            urlAnalysisDispatch({type:'SET_AD_DETAILS', value: {}})
            urlAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: !urlAnalysisState.detailsModal})
        }else{
            setBasicAdDetails(ad)
            adsDetailsMutate(ad.id)
        }
      }else{
          setBasicAdDetails(ad)
          adsDetailsMutate(ad.id)
      }
    }

    useEffect(() => {
      if(ad.country && ad.country.length > 5){
        setSeeMoreCountries(true)
      }

      if(urlAnalysisState.adDetails.keywords && urlAnalysisState.adDetails.keywords.length > 5){
        setSeeMoreKeyword(true)
      }
    }, [])
    
    const onCopyClick = (value) => {
      navigator.clipboard.writeText(value);
      setCopiedItem(value)
      setShowTick(true)
      setTimeout(() => {
          setShowTick(false)
      }, 1500);
    }

    return <CardContainer detaislOpen={urlAnalysisState.adDetails.id == ad.id}>
      
      {urlAnalysisState.adDetails.id != ad.id && <AdContainer>
        <ImageContainer>
          {image}
        </ImageContainer>
        <FloatingButtonsContainer>
          {/* <Favorite isFav={idx == 1}>
              <img src={FavorietIcon}/>
          </Favorite>
          |
          <DropdownIcon>
              <span/>
          </DropdownIcon> */}
        </FloatingButtonsContainer>
        <AdBodyContainer>
          <ProductName>
            {ad.title}
          </ProductName>
          <ProductPrice>
            {ad.price}
          </ProductPrice>
          <WebsiteUrl>
            {ad.sitename}
          </WebsiteUrl>
          <AdBodyBottomContainer>
            <AdDate>
            {ad.firstSeen && ad.lastSeen && <img src={DateIcon}/>}{convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
            </AdDate>
            <AdCountries>
              <img src={CountryIcon}/>{ ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
            </AdCountries>
          </AdBodyBottomContainer>
          <DetailsButton onClick={()=>toggleDetailsModal(ad)}>
          {(urlAnalysisState.adDetails.id == ad.id) && urlAnalysisState.detailsModal ? "Close details" : "More details"}
            </DetailsButton>
        </AdBodyContainer>
      </AdContainer>}
      {urlAnalysisState.adDetails.id == ad.id && <DetailsContainer >
        <Left>
          <FloatingButtonsContainer>
            <Favorite isFav={idx == 1} onClick={()=>{
                authDispatch({type:'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'google'})
                authDispatch({type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id})
                authDispatch({type:'UPDATE_SAVE_AD_POPUP', value: true})}}> 
                <img src={FavorietIcon}/>
            </Favorite>
            {/* |
            <DropdownIcon>
                <span/>
            </DropdownIcon> */}
          </FloatingButtonsContainer>
          <DetailsImageContainer>
            {image}
          </DetailsImageContainer>
          <DetailsButton onClick={()=>closeDetailsModal(ad.id)}>
              Close details
            </DetailsButton>
        </Left>
        
        <Right>
          <DetailsHeading>
            Ad Detail
            <CloseButton onClick={()=>closeDetailsModal(ad.id)}>
              ×
            </CloseButton>
          </DetailsHeading>
          <DetailsWraper>
            <DetailsProductName>
              <label>Title</label>
              <p>{ad.title}</p>
            </DetailsProductName>
            <ProductPrice>
              <label>Price</label>
              <p>{ad.price}</p>
            </ProductPrice>
            <WebsiteUrl>
              <label>Destination URL:</label>
              <p><a href={ad.url} target="_blank">{ad.url}</a></p>
            </WebsiteUrl>
            <DisplayLink>
              <label>Display Link:</label>
              <p>{ad.domainId}</p>
            </DisplayLink>
            <AdDetailsDate>
              <label>Capture Date</label>
              <p>{convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}</p>
            </AdDetailsDate>
            <AdDetailsCountries>
              <label>Country</label>
              <p>{ad.country.map((country,idx)=>{
                if(seeMoreCountries){
                  if(idx < 6){
                    return <span>{country}</span>
                  }
                }else{
                  return <span>{country}</span>
                }
              })}
              <SeeMoreMoreCountries onClick={()=>setSeeMoreCountries(prev => !prev)}>
                {seeMoreCountries ? `... ${ad.country.length - 5}+ see more` : ad.country.length > 5 ? "see less" : ""}
              </SeeMoreMoreCountries>
              </p>
            </AdDetailsCountries>
            
            <AveragePosition>
              <label>Average position:</label><p>{Math.round(ad.position)}</p>
            </AveragePosition>
            <AdKeywords>
              <label>Search terms:</label>
              <p>{urlAnalysisState.adDetails.keywords.map((term,idx)=>{
                  if(seeMoreKeywords){
                    if(idx < 6){
                      return <span>{term}</span>
                    }
                  }else{
                    return <span>{term}</span>
                  }
                })}
                <SeeMoreMoreKeywords onClick={()=>setSeeMoreKeyword(prev => !prev)}>
                  {seeMoreKeywords ? `... ${urlAnalysisState.adDetails.keywords.length - 5}+ see more` : urlAnalysisState.adDetails.keywords.length > 5 ? "see less" : ""}
                </SeeMoreMoreKeywords>
              </p>
            </AdKeywords>
            
          </DetailsWraper>
        </Right>
      </DetailsContainer>}
    </CardContainer>
}

const GoogleEcomResults = ({isDataLoading}) => {
  const { authState, authDispatch } = useAuth()
  const useRequestDataHook = useRequestData()

    const { urlAnalysisState, urlAnalysisDispatch } = useUrlAnalysis()
    const [ basicAdDetails, setBasicAdDetails] = useState({})
    const [ dataRequested, setDataRequested ] = useState(false)
    const navigate = useNavigate()

    // const {searchState, searchDispatch} = useSearch()
    // const {authState, authDispatch} = useAuth()

    const onDataRequest = () => {
      if(!useRequestDataHook.dataRequested){
        useRequestDataHook.requestDataMutate({id: urlAnalysisState.queryId})
      }
    }

//     if(authState.isRestrictedPopup){
//       return  <ResultsContainer detailsModal={urlAnalysisState.detailsModal}>
//           <PlatformRestrictedPopup err={searchState.searchError}/>
//       </ResultsContainer>
//   }

//   if(searchState.limitExceeded){
//       return  <ResultsContainer detailsModal={urlAnalysisState.detailsModal}>
//           <LimitExceededPopup err={searchState.searchError}/>
//       </ResultsContainer>
//   }
    
    return  <ResultsContainer>
                {isDataLoading ? <div className='loader'>
        <SearchLoader/>
      </div> :  urlAnalysisState.ads.length == 0 ? <NoResults>
                        <img src={NoResultsLogo}/>
                        <h2>No Results Found</h2>
                        {/* <p>We're sorry, but we don't currently have the data matching your search parameters. Our database is constantly updated, so please try again later or try modifying your search.</p>
                        <RequestDataBtn dataRequested={dataRequested ? dataRequested : ""} disable={isRequestDataLoading} onClick={onDataRequest}>{dataRequested ? <p>Your request for data crawl has been accepted<img src={TickImg}/></p> : <p>{isRequestDataLoading ? "Requesting...": "Request data crawl for this query"}</p>}</RequestDataBtn> */}
                    </NoResults> :
                <ResultsWraper>
                  {authState.saveAdsPopupOpen && <SaveAdsPopup/>}
                  {(urlAnalysisState.ads.length > 0) && urlAnalysisState.ads.map((ad,idx)=>{
                      return <Card basicAdDetails={basicAdDetails} setBasicAdDetails={setBasicAdDetails} ad={ad} idx={idx}/>
                  })}
                </ResultsWraper>
              }
            </ResultsContainer>
}

export default GoogleEcomResults