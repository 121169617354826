
import React from 'react';
import { HeatMapGrid } from 'react-grid-heatmap'
import { Container, XaxisContainer, XaxisTimeContainer } from './index.styled';
import Skeleton from 'react-loading-skeleton';
// import { useUrlAnalysis } from './../../../context/urlanalysis'

const xLabels = ["00:","00","","06:","00","","12:","00","","18:","00",""]
const xLablesFilledElements = [1,4,7,10]
const yLabels = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
const data = new Array(yLabels.length)
  .fill(0)
  .map(() =>
    new Array(xLabels.length).fill(0).map(() => Math.floor(Math.random() * 50 + 50))
  )

let timeLabels = ["00:00", "08:00", "16:00", "23:59"]
const HeatMapChart = ({heatmapData, isDataLoading}) => {
  // const { urlAnalysisState, urlAnalysisDispatch  } = useUrlAnalysis()

  return (
    isDataLoading && !heatmapData ? 
        <Skeleton style={{
            lineHeight: 19,
            width: 610,
          }}/> : 
          <Container
            style={{
              width: "100%",
              fontFamily: "sans-serif"
            }}
          >
            <HeatMapGrid
              data={heatmapData}
              // xLabels={xLabels}
              yLabels={yLabels}
              // Reder cell with tooltip
              cellRender={(x, y, value) => (
                <div title={`Pos(${x}, ${y}) = ${value}`}>{}</div>
              )}
              xLabelsStyle={index => ({
                color: "#777",
                fontSize: "10px",
                marginTop: "10px",
                paddingLeft: "3px 4px",
                // padding: xLablesFilledElements.includes(index) ? "3px 0px" : "3px 4px",
                paddingRight: xLablesFilledElements.includes(index) ? "5px" : ""
              })}
              yLabelsStyle={() => ({
                fontSize: ".65rem",
                textTransform: "uppercase",
                color: "#777",
                marginRight: "10px"
              })}
              cellStyle={(_x, _y, ratio) => ({
                background: `rgb(17, 131, 229, ${ratio})`,
                fontSize: ".7rem",
              //   color: `rgb(0, 0, 0, ${ratio / 2 + 0.4})`  
              })}
              cellHeight="17px"
              xLabelsPos="bottom"
              // onClick={(x, y) => alert(`Clicked (${x}, ${y})`)}
              // yLabelsPos="s"
              square
            />
            <XaxisContainer>
              {[...Array(4)].map((index)=>{
                return <span></span>
              })}
            </XaxisContainer>
            <XaxisTimeContainer>
              {timeLabels.map((time)=>{
                return <p>{time}</p>
              })}
            </XaxisTimeContainer>
          </Container>
  );
};

export default HeatMapChart;


