import { useRef, useState } from "react";
import { useTextAdGenerator } from "../../context/textAdGeneration";
import { Keywords, KeywordsContainer, KeywordsInput, SeedKeywordsContainer, AddIcon, Instruction, Container, PropperKeyword, Keyword, DeleteKeyword, SuggestionContainer, SuggestionLabel } from "./SeedKeywords.styled";
import add from "../../assets/add.svg"
import removeKeyword from "../../assets/removeKeyword.svg"

function SeedKeywords() {
    const keywordsRef = useRef(null)
    const { textAdGeneratorState, textAdGeneratorDispatch } = useTextAdGenerator()
    const [inputFocussed, setInputFocussed] = useState(false)

    const handlePaste = (event) => {
        event.preventDefault();
        if(keywordsRef.current){
            let paste = (event.clipboardData).getData("text");
            const newKeywords = paste.split(",")
            newKeywords.map((keyword) => {
                textAdGeneratorDispatch({
                    type: "ADD_SEED_KEYWORD",
                    value: keyword.replaceAll("/n","")
                })
            })

            textAdGeneratorDispatch({type: "SET_SEED_KEYWORDS", value: keywordsRef.current.value})
           // Clear the input box
            keywordsRef.current.value = "";

            // Clear the clipboard data
            event.clipboardData.clearData();
        }
    }

    const handleKeyDown = (event) => {
        let innerText = keywordsRef.current.value.trim();
        const isAlreadySelected = textAdGeneratorState.seedKeywordsList.some(
            (location) => location === innerText
          );
        if((event.key === "," && keywordsRef.current) || (event.key === "Enter" && innerText !== '')){
            keywordsRef.current.value.split(",").map((keyword) => {
                !isAlreadySelected && keyword !== '' && textAdGeneratorDispatch({
                    type: "ADD_SEED_KEYWORD",
                    value: keyword.trim()
                })
            })
            keywordsRef.current.value = ""
            event.preventDefault()
        }
        // if(event.key === "Enter" && innerText !== ''){
        //     console.log('success')
        //     event.preventDefault()
        // }
    }

    const suggestionKeywords = () => {
        const keywords = textAdGeneratorState.seedKeywords
        const handleKeywordSelection = (keyword) => {
            textAdGeneratorDispatch({type: "ADD_SEED_KEYWORD", value: keyword})
        } 
        return keywords?.length > 0 && keywords?.map((keyword) => {
            if(!textAdGeneratorState.seedKeywordsList.includes(keyword))
            return (
                <KeywordsContainer onClick={() => handleKeywordSelection(keyword)} >
                    <Keywords>{keyword}</Keywords>
                    <AddIcon src={add} alt="add" />
                </KeywordsContainer>
            )
        })
    }

    const enteredKeywords = () => {
        const { seedKeywordsList } = textAdGeneratorState
        const handleRemoveKeyword = (index) => {
            textAdGeneratorDispatch({
                type: "REMOVE_SEED_KEYWORD",
                value: {index}
            })
        }
        return seedKeywordsList.map((keyword, index) => {

            if( keyword.length < 1 ) return
            return(
                <PropperKeyword>
                    <Keyword>{keyword}</Keyword>
                    <DeleteKeyword onClick={() => handleRemoveKeyword(index)} src={removeKeyword} alt="remove" />
                </PropperKeyword>
            )
        })
    }

    const handleContainerClick = () => {
        if(keywordsRef.current){
            keywordsRef.current.focus()
        }
    }

    const handleInputBlur = () => {
        setInputFocussed(false)
        if(keywordsRef.current?.value.trim() !== ""){
            const blurText = keywordsRef.current?.value.trim();
            const isAlreadySelected = textAdGeneratorState.seedKeywordsList.some(
                (location) => location === blurText
              );
            !isAlreadySelected && textAdGeneratorDispatch({type: "ADD_SEED_KEYWORD", value: keywordsRef.current.value})
            keywordsRef.current.value = "";
            // if(keywordsRef.current.value === ",")
            //     keywordsRef.current.value = ""
        }
    }

    return ( 
        <SeedKeywordsContainer>
            <Container isFocussed={inputFocussed} onClick={handleContainerClick} >
                {enteredKeywords()}
                <KeywordsInput  placeholder="Enter Seed keywords (Eg: Coupons, Discounts)" ref={keywordsRef} onKeyDown={handleKeyDown} 
                // onBlur={handleInputBlur}
                onFocus={() => setInputFocussed(true)}
                onPaste={handlePaste}
                />
                
            </Container>
            {/* {!textAdGeneratorState.seedKeywordsList.length && ( */}
                <SuggestionContainer>
                    <SuggestionLabel>Suggestions:</SuggestionLabel>
                    {suggestionKeywords()}
                </SuggestionContainer>
            {/* )} */}
            <Instruction>Separate keywords with commas</Instruction>
        </SeedKeywordsContainer>
     );
}

export default SeedKeywords;