import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import {
  useMutation
} from "@tanstack/react-query"
import NoResultsLogo from "../../../../../../../media/Dashboard/noresults.svg"// Styles
import {
    ResultsContainer,
    CardContainer,
    ResultsWraper,
    AdContainer,
    ImageContainer,
    AdBodyContainer,
    ProductName,
    ProductPrice,
    WebsiteUrl,
    AdBodyBottomContainer,
    AdDetailsDate,
    AdCountries,
    AdDetailsCountries,
    AdKeywords,
    AveragePosition,
    SeeMoreMoreCountries,
    SeeMoreMoreKeywords,
    DetailsButton,
    DetailsContainer,
    DetailsProductName,
    Left,
    Right,
    DetailsHeading,
    CloseButton,
    DetailsWraper,
    DetailsImageContainer,
    FloatingButtonsContainer,
    Favorite,
    NoResults,
    DisplayLink,
    ViewMoreAds
} from "./index.styled"
import _static from "../../../../../../../config/static"
import SearchLoader from '../../../../../../HOC/SearchLoader'
import BlueRightArrow from '../../../../../../../media/Dashboard/blue-right-arrow.svg'

//Icons
import FavorietIcon from "../../../../../../../media/Dashboard/addFavorite.svg"

import CountryIcon from "../../../../../../../media/Images/CountryIcon.svg"
import AltImage from '../../../../../../../media/Dashboard/altimage.png'

import {appClient} from '../../../../../../../utils/axios-utils'
import { useAuth } from '../../../../../../../context/auth'
import { useLandingPageAnalysis } from '../../../../context/landingPageAnalysis'
import { getTodayDate } from '../../../../../../../utils/dateFormat'
import SaveAdsPopup from '../../../../../../components/SaveAdsPopup'

const adDetails = async (id) => {
  const config = {
    url: `/detail/google-ecom/${id}`,
    method: "GET"
  }

  return appClient(config)
}

const requestDataCrawl = async (data) => {
  const config = {
      url: `/track/search/update-search-query`,
      method: "POST",
      data
  }

  return appClient(config)
}

const Card = ({ad, idx, basicAdDetails, setBasicAdDetails, containerRef}) => {
    const {landingPageAnalysisState, landingPageAnalysisDispatch} = useLandingPageAnalysis()
    const { authState, authDispatch } = useAuth()

    const [detaislOpen,setDetailsOpen] = useState(false)
    const [seeMoreCountries, setSeeMoreCountries] = useState(false)
    const [seeMoreKeywords, setSeeMoreKeyword] = useState(false)

    const image = ad.image ? <img src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${ad.id}`} onError={() => {}}/> : <img src={AltImage} onError={() => {}}/>;

    const convertTimestamp = (timestamp) => {
      // Create a new Date object based on the timestamp
      var date = new Date(timestamp);
    
      // Get the month name
      var monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];
      var month = monthNames[date.getMonth()];
    
      // Get the year
      var year = date.getFullYear();
    
      // Assemble the formatted date string
      var formattedDate = date.getDate() + ' ' + month  + ' ' + year;
    
      return formattedDate;
    }

    const {mutate: adsDetailsMutate, isLoading: isDetailsLoading} = useMutation(adDetails, {
      onSuccess: (data) => {
          landingPageAnalysisDispatch({type:'SET_AD_DETAILS', value: data})
          if(landingPageAnalysisState.adDetails.id == data.id || !landingPageAnalysisState.adDetails.id){
              landingPageAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: !landingPageAnalysisState.detailsModal})
          }
      },
      onError:  ((e)=>{
          // console.log(e)
      })
    })

    const closeDetailsModal = () => {
      landingPageAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: !landingPageAnalysisState.detailsModal})
      landingPageAnalysisDispatch({type:'SET_AD_DETAILS', value: {}})
      landingPageAnalysisDispatch({type:'UPDATE_COLLAPSE_CALENDER', value: false})
    }

    const toggleDetailsModal = (ad) => {
      if(landingPageAnalysisState.adDetails.id){
        if((landingPageAnalysisState.adDetails.id == ad.id) && landingPageAnalysisState.detailsModal){
            //if details is open and clicked on close button on ads card
            setBasicAdDetails({})
            landingPageAnalysisDispatch({type:'SET_AD_DETAILS', value: {}})
            landingPageAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: !landingPageAnalysisState.detailsModal})
            landingPageAnalysisDispatch({type:'UPDATE_COLLAPSE_CALENDER', value: false})
        }else{
            landingPageAnalysisDispatch({type:'UPDATE_COLLAPSE_CALENDER', value: true})
            setBasicAdDetails(ad)
            adsDetailsMutate(ad.id)
        }
      }else{
          landingPageAnalysisDispatch({type:'UPDATE_COLLAPSE_CALENDER', value: true})
          setBasicAdDetails(ad)
          adsDetailsMutate(ad.id)
      }
    }

    useEffect(() => {
      if(ad.country && ad.country.length > 5){
        setSeeMoreCountries(true)
      }

      if(landingPageAnalysisState.adDetails.keywords && landingPageAnalysisState.adDetails.keywords.length > 5){
        setSeeMoreKeyword(true)
      }
    }, [])
    
    return <CardContainer detaislOpen={landingPageAnalysisState.adDetails.id == ad.id}>
      
      {landingPageAnalysisState.adDetails.id != ad.id && <AdContainer>
        <ImageContainer>
          {image}
        </ImageContainer>
        <FloatingButtonsContainer>
          {/* <Favorite isFav={idx == 1}>
              <img src={FavorietIcon}/>
          </Favorite>
          |
          <DropdownIcon>
              <span/>
          </DropdownIcon> */}
        </FloatingButtonsContainer>
        <AdBodyContainer>
          <ProductName>
            {ad.title}
          </ProductName>
          <ProductPrice>
            {ad.price}
          </ProductPrice>
          <WebsiteUrl>
            {ad.sitename}
          </WebsiteUrl>
          <AdBodyBottomContainer>
            {/* <AdDate>
              {ad.firstSeen && ad.lastSeen && <img src={DateIcon}/>}{convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
            </AdDate> */}
            <AdCountries>
              <img src={CountryIcon}/>{ ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
            </AdCountries>
          </AdBodyBottomContainer>
          {/* <DetailsButton onClick={()=>toggleDetailsModal(ad)}>
          {(landingPageAnalysisState.adDetails.id == ad.id) && landingPageAnalysisState.detailsModal ? "Close details" : "More details"}
            </DetailsButton> */}
        </AdBodyContainer>
      </AdContainer>}
      {landingPageAnalysisState.adDetails.id == ad.id && <DetailsContainer >
        <Left>
          <FloatingButtonsContainer>
            <Favorite isFav={idx == 1} onClick={()=>{
                authDispatch({type:'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'google-ecom'})
                authDispatch({type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id})
                authDispatch({type:'UPDATE_SAVE_AD_POPUP', value: true})}}> 
                <img src={FavorietIcon}/>
            </Favorite>
            {/* |
            <DropdownIcon>
                <span/>
            </DropdownIcon> */}
          </FloatingButtonsContainer>
          <DetailsImageContainer>
            {image}
          </DetailsImageContainer>
          <DetailsButton onClick={()=>closeDetailsModal(ad.id)}>
              Close details
            </DetailsButton>
        </Left>
        
        <Right>
          <DetailsHeading>
            Ad Detail
            <CloseButton onClick={()=>closeDetailsModal(ad.id)}>
              ×
            </CloseButton>
          </DetailsHeading>
          <DetailsWraper>
            <DetailsProductName>
              <label>Title</label>
              <p>{ad.title}</p>
            </DetailsProductName>
            <ProductPrice>
              <label>Price</label>
              <p>{ad.price}</p>
            </ProductPrice>
            <WebsiteUrl>
              <label>Destination URL:</label>
              <p><a href={ad.url} target="_blank">{ad.url}</a></p>
            </WebsiteUrl>
            <DisplayLink>
              <label>Display Link:</label>
              <p>{ad.domainId}</p>
            </DisplayLink>
            <AdDetailsDate>
              <label>Capture Date</label>
              <p>{convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}</p>
            </AdDetailsDate>
            <AdDetailsCountries>
              <label>Country</label>
              <p>{ad.country.map((country,idx)=>{
                if(seeMoreCountries){
                  if(idx < 6){
                    return <span>{country}</span>
                  }
                }else{
                  return <span>{country}</span>
                }
              })}
              <SeeMoreMoreCountries onClick={()=>setSeeMoreCountries(prev => !prev)}>
                {seeMoreCountries ? `... ${ad.country.length - 5}+ see more` : ad.country.length > 5 ? "see less" : ""}
              </SeeMoreMoreCountries>
              </p>
            </AdDetailsCountries>
            
            <AveragePosition>
              <label>Average position:</label><p>{Math.round(ad.position)}</p>
            </AveragePosition>
            <AdKeywords>
              <label>Search terms:</label>
              <p>{landingPageAnalysisState.adDetails.keywords.map((term,idx)=>{
                  if(seeMoreKeywords){
                    if(idx < 6){
                      return <span>{term}</span>
                    }
                  }else{
                    return <span>{term}</span>
                  }
                })}
                <SeeMoreMoreKeywords onClick={()=>setSeeMoreKeyword(prev => !prev)}>
                  {seeMoreKeywords ? `... ${landingPageAnalysisState.adDetails.keywords.length - 5}+ see more` : landingPageAnalysisState.adDetails.keywords.length > 5 ? "see less" : ""}
                </SeeMoreMoreKeywords>
              </p>
            </AdKeywords>
            
          </DetailsWraper>
        </Right>
      </DetailsContainer>}
    </CardContainer>
}

const GoogleEcomResults = ({isDataLoading}) => {
    const {landingPageAnalysisState, landingPageAnalysisDispatch} = useLandingPageAnalysis()
    const { authState, authDispatch } = useAuth()

    const [ basicAdDetails, setBasicAdDetails] = useState({})
    const [ dataRequested, setDataRequested ] = useState(false)
    const navigate = useNavigate()

    // const {searchState, searchDispatch} = useSearch()
    // const {authState, authDispatch} = useAuth()

    const {mutate: requestData, isLoading: isRequestDataLoading} = useMutation(requestDataCrawl, {
      onSuccess: (data) => {
          setDataRequested(true)
      },
      onError:  ((e)=>{

      })
    })

    const onDataRequest = () => {
      if(!dataRequested){
          requestData({id: landingPageAnalysisState.queryId})
      }
    }

//     if(authState.isRestrictedPopup){
//       return  <ResultsContainer detailsModal={landingPageAnalysisState.detailsModal}>
//           <PlatformRestrictedPopup err={searchState.searchError}/>
//       </ResultsContainer>
//   }

//   if(searchState.limitExceeded){
//       return  <ResultsContainer detailsModal={landingPageAnalysisState.detailsModal}>
//           <LimitExceededPopup err={searchState.searchError}/>
//       </ResultsContainer>
//   }
    
    return  <ResultsContainer>
                {isDataLoading ? <div className='loader'>
        <SearchLoader/>
      </div> :  landingPageAnalysisState.ads.length == 0 ? <NoResults>
                        <img src={NoResultsLogo}/>
                        <h2>No Results Found</h2>
                        <p>No ads found on this specific date. try some other dates</p>
                         {/* <RequestDataBtn dataRequested={dataRequested ? dataRequested : ""} disable={isRequestDataLoading} onClick={onDataRequest}>{dataRequested ? <p>Your request for data crawl has been accepted<img src={TickImg}/></p> : <p>{isRequestDataLoading ? "Requesting...": "Request data crawl for this query"}</p>}</RequestDataBtn> */}
                    </NoResults> :
                <ResultsWraper>
                  {authState.saveAdsPopupOpen && <SaveAdsPopup/>}
                  {(landingPageAnalysisState.ads.length > 0) && landingPageAnalysisState.ads.slice(0, 4).map((ad, idx) => {
                      return <Card basicAdDetails={basicAdDetails} setBasicAdDetails={setBasicAdDetails} ad={ad} idx={idx}/>
                  })}
                  <ViewMoreAds onClick={()=>window.open(`https://app.adspyder.io/adspy/googlepla?searchMode=url&keywordSearchType=phrase&phraseInput=&page=1&excludedTerm=&searchIn=title%2Ccontent&sortType=relevant&urlSearchType=domain&domainUrlInput=${landingPageAnalysisState.searchUrl}&startDate=${landingPageAnalysisState.selectedYear+"-"+landingPageAnalysisState.selectedMonth+"-"+landingPageAnalysisState.selectedDay}&endDate=${getTodayDate()}`)}>View More Ads <img src={BlueRightArrow}/></ViewMoreAds>
                </ResultsWraper>
              }
            </ResultsContainer>
}

export default GoogleEcomResults