import React, { useState, useEffect } from "react";
import {
  Wrapper,
  ColorDataContainer,
  Header,
  ColorListContainer,
  ColorContainerWrapper,
  ColorContainer,
} from "./index.styled";
import { useAdGenerator } from "../../../../../../../context/adGeneration";
import { parseHTML } from "../../../../../../../../../../utils/parseHtml";

const Color = () => {
  const {
    adGeneratorState: { rawTemplate, updatetemplate, selectedColor },
    adGeneratorDispatch,
  } = useAdGenerator();
  //   const [selectedColor, setSelectedColor] = useState(null);
  const [colorData, setColorData] = useState([
    {
      id: 1,
      name: "Main color",
      colorsList: rawTemplate && rawTemplate.colors,
    },
    // { id: 2, name: "Text 1", colorsList: ["#1C1C1C", "#FFF"] },
    // { id: 3, name: "Text 2", colorsList: ["#1C1C1C", "#FFF"] },
    // {
    //   id: 4,
    //   name: "CTA",
    //   colorsList: [
    //     "#23414E",
    //     "#0AB9F1",
    //     "#2A59E2",
    //     "#0D7183",
    //     "#FF7272",
    //     "#FF711E",
    //   ],
    // },
  ]);

  const onColorChange = (color, name) => {
    // const newObj = {};
    // newObj[name] = color;
    // setSelectedColor({ ...selectedColor, ...newObj });
    adGeneratorDispatch({ type: "SET_SELECTED_COLOR", value: color });
  };

  useEffect(() => {
    console.log("inside selectcolor");
    // let document = parseHTML(rawTemplate.html);
    let documentHtml = document.querySelector(".outerHtml");
    if (selectedColor) {
      documentHtml.querySelectorAll("*").forEach((item) => {
        const attributes = item.attributes;

        for (let i = 0; i < attributes.length; i++) {
          const attribute = attributes[i];
          const attributeName = attribute.name;

          const [prefix, type] = attributeName.split("-");
          if (prefix === "change") {
            switch (type) {
              case "background":
                item.style.backgroundColor = selectedColor;
                break;
              case "text":
                item.style.color = selectedColor;
                break;
              case "svg":
                item.style.fill = selectedColor;
                break;
              case "border":
                item.style.borderColor = selectedColor;
                break;
              case "gradient":
                item.style.stopColor = selectedColor;
                break;
              default:
                break;
            }
          }
        }
      });

      let clonedHtmlContent = documentHtml.cloneNode(true);

      // Remove unwanted xmlns attribute (if exists)
      // clonedHtmlContent.removeAttribute("xmlns");
      // Serialize only the inner HTML if needed
      const modifiedHtml = clonedHtmlContent.innerHTML;
      adGeneratorDispatch({
        type: "SET_UPDATE_TEMPLATE",
        value: modifiedHtml,
      });
    }
  }, [selectedColor]);

  return (
    <Wrapper>
      {colorData?.map((element) => (
        <ColorDataContainer key={element.id}>
          <Header>
            <label>{element.name}</label>
          </Header>
          <ColorListContainer>
            {element?.colorsList.map((c) => {
              return (
                <ColorContainerWrapper
                  onClick={() => onColorChange(c)}
                  isSelected={selectedColor == c}
                >
                  <ColorContainer style={{ background: c }} />
                </ColorContainerWrapper>
              );
            })}
          </ColorListContainer>
        </ColorDataContainer>
      ))}
    </Wrapper>
  );
};

export default Color;
