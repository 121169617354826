import { AskToUpdate, BrandName, Category, ClientProfileContainer, EditIcon, Logo, LogoContainer, PrimaryText, SecondaryText, TopImage, UpdateButton, UpdatedProfile, UpdatedText, UploadImage } from "./index.styled";
import upload from "./assets/upload.svg"
import { useOverview } from "../../context/overview";
import UploadProfilePopup from "./Popup";
import profile from "./assets/updated.png"
import edit from "./assets/edit.svg"
import { LineLoader } from "../index.styled";
import { getStaticLogo } from "../../utils";
function ClientProfile() {
    const { overviewState: { isClientProfileUpdated, clientProfile, brandAssets, isOnboardingPopupActive, projectDetails }, overviewDispatch } = useOverview()
    // const [isUploadMutateLoading, setUploadMutateLoading] = useState(false)
    const openPopup = () => {
        overviewDispatch({
            type: "SET_CLIENT_LIBRARY_POPUP_ACTIVE",
            value: true
        })
    }


    if(isOnboardingPopupActive){
        return <LineLoader width={256} height={232} />
    }


    return (
        <ClientProfileContainer>
            {isClientProfileUpdated
                ? (
                    <UpdatedProfile>
                        <TopImage src={profile} alt="profile" />
                        <EditIcon onClick={openPopup} src={edit} alt="edit" />
                        <LogoContainer>
                            <Logo src={brandAssets?.brandLogo?.logo ?? getStaticLogo(projectDetails?.website)} alt="logo" />
                        </LogoContainer>
                        <BrandName>{clientProfile?.companyName}</BrandName>
                        <Category>{clientProfile?.category}</Category>
                        <UpdatedText>Profile updated</UpdatedText>
                        <UploadProfilePopup /* setLoading={setUploadMutateLoading} */ />
                    </UpdatedProfile>
                )
                : (
                    <>
                        <AskToUpdate onClick={openPopup}>
                            <UploadImage src={upload} alt="upload" />
                            <PrimaryText>Complete your Client Profile</PrimaryText>
                            <SecondaryText>let AdSpyder handle the rest!</SecondaryText>
                            <UpdateButton>Upload now</UpdateButton>
                        </AskToUpdate>
                        <UploadProfilePopup /* setLoading={setUploadMutateLoading} */ />
                    </>
                )
            }

        </ClientProfileContainer>
    );
}

export default ClientProfile;