import styled from "styled-components";

export const RootContainer = styled.div`
    width: 1132px;
    height: 100%;
    padding: 0 50px;
`;

export const Title = styled.div`
color: #000;
font-family: Inter;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: 38px; /* 118.75% */
margin-top: 32px;
`;

export const Description = styled.div`
color: rgba(28, 28, 28, 0.50);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 157.143% */
letter-spacing: -0.14px;
margin-top: 8px;
`;


export const Projects = styled.div`
margin-top: 32px;
display: flex;
gap: 16px;
flex-wrap: wrap;
height: calc(100% - 132px);
`;

export const AddNew = styled.div`
width: 330px;
height: 270px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 4px;
background: #FFF;
flex-direction: column;
cursor: pointer;
`;

export const AddIconContainer = styled.div`
width: 36px;
height: 36px;
flex-shrink: 0;
border-radius: 50%;
background: #F4F5F7;
display: flex;
align-items: center;
justify-content: center;
`;

export const Icon = styled.img``;

export const AddText = styled.div`
color: #181E39;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 30px */
margin: 16px;
`;

export const Desc = styled.div`
color: rgba(28, 28, 28, 0.50);
text-align: center;
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 153.846% */
width: 234px;
`;

export const MoreDetails = styled.img`
position: absolute;
top: 16px;
right: 16px;
cursor: pointer;
`;

export const Pinned = styled.img`
position: absolute;
top: 16px;
right: 48px;
cursor: pointer;
`;

export const DetailsDropdown = styled.div`
position: absolute;
top: 38px;
right: 15px;
width: 184px;
// height: 74px;
flex-shrink: 0;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
padding: 8px;
display: none;
`;

export const Item = styled.div`
width: 168px;
height: 26px;
flex-shrink: 0;
border-radius: 4px;
padding: 2px 6px;
color: rgba(28, 28, 28, 0.70);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 157.143% */
letter-spacing: -0.14px;

:hover{
    background: #FFE8BF;
}
`;

export const ProjectCard = styled.div`
width: 330px;
height: 270px;
border-radius: 4px;
background: #FFF;
position: relative;
padding: 28px;
cursor: pointer;
`;

export const LogoContainer = styled.div`
width: 30px;
height: 30px;
flex-shrink: 0;
border-radius: 50%;
background: #F4F5F7;
display: flex;
align-items: center;
justify-content: center;
`;

export const Logo = styled.img`
width: 16px;
height: 16px;
`;

export const PrimaryText = styled.input`
color: #181E39;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 30px */
cursor: default;
border: none;
outline: none;
pointer-events: none;
:focus{
    outline: none;
    border: none;
}
`;

export const SecondaryText = styled.div`
color: rgba(28, 28, 28, 0.50);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 183.333% */
`;

export const Details = styled.div`
display: flex;
flex-direction: column;
gap: 39px;
margin-top: 24px;

`;

export const PrimaryDetails = styled.div`
display: flex;
flex-direction: column;
gap: 4px;
`;

export const SecondaryDetails = styled.div`
display: flex;
flex-direction: column;
gap: 15px;
`;

export const Row = styled.div`
display: flex;
gap: 8px;
align-items: center;
`;

export const Image = styled.img``;