import styled from "styled-components";

export const Wrapper = styled.div`
  background: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 5px);
  height: calc(100% - 10px);
  overflow-y: auto;
  padding: 15px 5px 10px 15px;
  position: relative;
`;

export const Container = styled.div`
  width: 1436px;
  height: 100%;
  padding: ${(props) => (props.paddingProps ? "" : "32px 0")};
  max-width: 100%;
`;
