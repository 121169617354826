import React, {useEffect} from "react";
import { useAdManagement } from "../context/AdManagementContext";
// import GeneratingPage from "./GeneratingPage";
// import ResultPage from "./ResultPage";
import { Route, Routes} from 'react-router-dom'
import InputPages from './InputPages';
import SelectAdDetails from './SelectAdAccount'
import SelectPlatform from './InputPages/SelectPlatform'
// import { useEffect } from "react";
import { useAdManagementInfo } from "../hooks/useAdManagementInfo";
// import { useEffect } from "react";

// const GetRecentlyConnectedAccount = async (data) => {
//     const config = {
//         url: "/ads/get-recent-connection",
//         method: "GET"
//     }

//     return appClient(config)
// }


const Root = () => {
    const { adManagementState, adManagementDispatch } = useAdManagement()
    const adManagementHook = useAdManagementInfo()
    const renderingComponent = () => {
        if(adManagementState.isInputPage) return <InputPages />
        if(adManagementState.isSelectPlatform) return <SelectPlatform />
        if(adManagementState.isAdAccount) return <SelectAdDetails />
    }
    useEffect(()=>{
        adManagementHook.setAllConnected()
    },[])
    return (
        <>
            <Routes>
                <Route path="/" element={renderingComponent()}/>
                {/* <Route path="result" element={<ResultPage />} /> */}
            </Routes>
        </>
    );
}

export default Root;