import styled from "styled-components";

export const Wrapper = styled.div`
    padding: 42px 24px;
    height: calc(100vh - 122px);
    overflow: auto;
`;

export const InputContainer = styled.div`

`;

export const UploadInputContainer = styled.div`
    margin-bottom: 16px;
`;

export const ColorsContainer = styled.div`
    
`;

export const Label = styled.div`
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: rgba(28, 28, 28, 0.50);
`;

export const ColorItems = styled.div`
    display: flex;
`;

export const ColorItem = styled.div`
    height: 38px;
    width: 38px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: ${props => props.isActive ? "1px solid #1679DB" : "none"};
`;

export const Color = styled.div`
    background: ${props => props.color};
    height: 32px;
    width: 32px;
    border-radius: 50%;
`;

export const UploadImagesContainer = styled.div`
    margin-top: 42px;
    h2{
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: rgba(28, 28, 28, 0.70);
        margin-bottom: 16px;
    }
`;

export const LogoUploadContainer = styled.div`
    position: relative;
    width: 98px;
    height: 36px;
    border-radius: 4px;
    border: 1px solid #E3E8EE;
    background: #F7F7F9;
    margin-bottom: 24px;
    cursor: pointer;
    input{
        opacity: 0;
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
    label{
        position: absolute;
        height: 100%;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
`;

export const ImageUploadWrapper = styled.div`
    display: flex;
    gap: 16px;
`;

export const LocalImageUploadContainer = styled.div`
    position: relative;
    width: 98px;
    height: 36px;
    border-radius: 4px;
    border: 1px solid #E3E8EE;
    background: #F7F7F9;
    cursor: pointer;
    input{
        opacity: 0;
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
    }
    label{
        position: absolute;
        height: 100%;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;
    }
`;


export const StockImageUploadContainer = styled.div`
    position: relative;
    height: 36px;
    width: 138px;
    border-radius: 4px;
    border: 1px solid #E3E8EE;
    background: #F7F7F9;
    cursor: pointer;
    label{
        position: absolute;
        height: 100%;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;
    }
`;

export const AiImageUploadContainer = styled.div`
    position: relative;
    height: 36px;
    width: 61px;
    border-radius: 4px;
    border: 1px solid #E3E8EE;
    background: #F7F7F9;
    cursor: pointer;
    label{
        position: absolute;
        height: 100%;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;
    }
`;

export const TextInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 43px;
`;

export const TextInputRow = styled.div`
    display: flex;
    /* height: 38px; */
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
    label{
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: rgba(28, 28, 28, 0.70);
        margin-bottom: 6px;
    }
`;

export const InputWrapper = styled.div`
    /* width: 258px; */
    width: 100%;
    position: relative;
    /* height: 100%; */
    /* height: 38px; */
    border-radius: 8px;
    border: ${(props) => props.hasError ? "1px solid #F04438" : props.isFocussed ? "1px solid #FFB258" : "1px solid #D0D5DD"};
    box-shadow: ${(props) => props.isFocussed ? "0px 1px 2px 0px rgba(28, 28, 28, 0.05), 0px 0px 4px 0px rgba(255, 178, 88, 0.25)" : "unset"};
    background: rgb(255, 255, 255);
    textarea{
        height: 100%;
        border-radius: 8px;
        outline: none;
        border: none;
        padding: 12px 16px;
        /* width: calc(100% - 45px); */
        width: 100%;
        ::-webkit-scrollbar-track
        {
            box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
            background-color: #F6F7FB;
            border-radius: 10px;
        }

        ::-webkit-scrollbar
        {
            height: 5px;
            background-color: rgb(245 245 245 / 92%);
            border-radius: 10px;
            width: 5px;
        }

        ::-webkit-scrollbar-thumb
        {
            background-color: rgb(0 0 0 / 7%);
            border-radius: 10px;
        }
    }
`;

export const CharLength = styled.div`
    font-size: 12px;
    font-weight: 300;
    color: rgba(28, 28, 28, 0.30);
    position: absolute;
    right: 12px;
    top: 13px;
`;

export const ImageUploadDropDownContainer = styled.div`
    // width: 147px;
    // position: relative;
`;

export const SelectedDropDown = styled.div`
    // border-radius: 4px;
    // border: 1px solid #E3E8EE;
    // background: #F7F7F9;
    // width: 147px;
    // height: 36px;
    // padding: 8px;
    // cursor: pointer;
        display: flex;
column-gap: 12px;
    p{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }

    p span{
        width: 75%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const UploadDropDown = styled.div`
    width: 142px;
    position: absolute;
    z-index: 1;
    left: 2px;
    top: 40px;
    border-radius: 0px 0px 12px 12px;
    border: 1px solid #E3E8EE;
    background: #FFF;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.15);
`;

export const UploadDropDownItem = styled.div`
    position: relative;
    width: 98px;
    height: 36px;
    // border-radius: 4px;
    // border-bottom: solid 1px #E3E8EE;
    // background: #FFF;
    cursor: pointer;
    pointer-events: ${props => props.isDisabled ? "none" : "all"};
    opacity: ${props => props.isDisabled ? "0.5": "1"};
    border-radius: 4px;
    border: 1px solid #E3E8EE;
    background: #F7F7F9;
    &.stockImages{
        width: 138px;
    }
    &.aiClass{
        width: 61px;
    }
    input{
        opacity: 0;
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
    }
    label{
        position: absolute;
        height: 100%;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: rgba(28, 28, 28, 0.70);
        padding: 11px;
        justify-content: flex-start;
    }
`;