import { AddCompetitorContainer,  Close, Content, DomainInput, ErrorBox, ErrorIcon, Footer, HttpsLabel, InputContainer, InputsContainer, PlaceholderWithInput, PopupBackground, RightIcons, Title, TrackButton } from "./index.styled"
import close from "../assets/close.svg"
import errorIcon from "../assets/errorIcon.svg"

import React, { useRef, useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { useOverview } from "../../../context/overview"
import { appClient } from "../../../../../../utils/axios-utils"
function isValidDomain(url) {
    const domainAndUrlRegEx = /^(((http|https):\/\/|)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?)$/;

    return domainAndUrlRegEx.test(url);
}
const AddCompetitorPopup = () => {
    const { overviewState: { isAddCompetitorPopupActive, projectDetails }, overviewDispatch } = useOverview()
    const containerRef = useRef(null)
    const inputRefs = useRef([])
    const [competitorInputs, setCompetitorInputs] = useState([{ key: 0 }]);
    const [inputFocussed, setInputFocussed] = useState(false)
    const [error, setError] = useState(false)
    const [competitor, setCompetitor] = useState("")
    const addCompetitor = (data) => {
        const config = {
            url: `/dashboard-overview/competitors/add-data`,
            method: "POST",
            data
        }

        return appClient(config)
    }

    const { mutate: addCompetitorMutate } = useMutation(addCompetitor, {
        onSuccess: (data) => {

            closePopup()
        },
        onError: (e) => {
            console.log(e)
        }
    })

    const closePopup = () => {
        overviewDispatch({
            type: "SET_COMPETITOR_POPUP_ACTIVE",
            value: false
        })
    }

    const handleAddInput = () => {
        const newKey = inputRefs.current.length;
        inputRefs.current[newKey] = null;
        setCompetitorInputs([...competitorInputs, { key: newKey }]);
    };

    const handleComplete = () => {

        const data = {
            projectId: projectDetails.projectId,
            websiteUrl: competitor
        }

        addCompetitorMutate(data)
        // {competitorInputs.map(input => (
        //     <CompetitorInput
        //         key={input.key}
        //         className="competitor-input"
        //         placeholder="www.couponswala.com"
        //         ref={inputRefs.current[input.key]}
        //     />
        // ))}
    }

    const handleKeyDown = (e) => {
        if(e.key === "Enter") handleComplete()
    }

    const handleInputChange = (e, index) => {
        let { value } = e.target
        if(!value.startsWith("https://")) value = `https://${value}`
        console.log('url is ', value ,'valid url', isValidDomain(value) )
        setCompetitor(value)
        // domainOverviewDispatch({type: 'SET_DOMAIN_INPUT', value})
        // if(domainOverviewState.domainInput.length > 0 )
            setError(!isValidDomain(e.target.value))
    }

    if (!isAddCompetitorPopupActive) return
    return (
        <PopupBackground>
            <Content onKeyDown={handleKeyDown} >
                <AddCompetitorContainer ref={containerRef} >
                    <Close src={close} alt="close" onClick={closePopup} />
                    <Title>Add your competitor website</Title>
                    <div>
                        <InputsContainer>
                            {competitorInputs.map(input => (
                                <PlaceholderWithInput>
                                    <HttpsLabel>https://</HttpsLabel>
                                    {/* <CompetitorInput
                                        key={input.key}
                                        className="competitor-input"
                                        placeholder="www.couponswala.com"
                                        ref={el => inputRefs.current[input.key] = el}
                                        isFocussed={inputFocussed}
                                        onFocus={() => setInputFocussed(true)}
                                        onBlur={() => setInputFocussed(false)}
                                    /> */}
                                    <InputContainer isFocussed={inputFocussed} hasError={error}>
                                        <DomainInput 
                                            key={input.key}
                                            className="competitor-input"
                                            placeholder="www.couponswala.com"
                                            ref={el => inputRefs.current[input.key] = el}
                                            onFocus={() => setInputFocussed(true)}
                                            onBlur={() => setInputFocussed(false)}
                                            onChange={handleInputChange}
                                        />
                                        <RightIcons>
                                            {error && <ErrorIcon src={errorIcon} alt=""/>}
                                        </RightIcons>
                                    </InputContainer>
                                    
                                </PlaceholderWithInput>
                                
                            ))}
                        </InputsContainer>
                        {error && <ErrorBox>Enter a valid domain</ErrorBox>}
                    </div>
                    
                    <Footer>
                        {/* <AddNew onClick={handleAddInput} >+ Add more competitors</AddNew> */}
                        <TrackButton onClick={handleComplete}>Complete</TrackButton>
                    </Footer>
                </AddCompetitorContainer>
            </Content>
        </PopupBackground>
    )
}

export default AddCompetitorPopup