import React from "react";
import {
  AdsHeader,
  AdsWrapper,
  Logo,
  AdTitleWrap,
  ViewAll,
  AdContent,
  AdContainer,
  SiteWrap,
  AdBtn,
  ContentContainer,
} from "./index.styled";
import AdspyderLogo from "../../assets/AdSpyderLogo.svg";
// import { useDashboard } from "../../../../../context/dashboard";
import { useNavigate } from "react-router-dom";
import { useDashboard } from "../../../../../../../../context/dashboard";

const TopAds = ({ headerLogo, headerText, isTextAd = true, children }) => {
  const {
    dashboardState: { selectedProject, competitors },
    dashboardDispatch,
  } = useDashboard();
  const navigate = useNavigate();
  const extractDomain = (inputUrl) => {
    const parsedUrl = new URL(inputUrl);
    return parsedUrl.hostname;
  };
  return (competitors.length > 0) &&
    <AdsWrapper>
      <AdsHeader>
        <AdTitleWrap>
          <Logo isTextAd={isTextAd}>
            <img src={headerLogo} />
          </Logo>
          <p>{headerText}</p>
        </AdTitleWrap>
          {/* <ViewAll
            onClick={() =>
              navigate(
                `/adspy/display?searchMode=url&urlSearchType=domain&domainUrlInput=${extractDomain(
                  selectedProject.website
                )}&page=1&sortType=relevant`
              )
            }
          >
            View all
          </ViewAll> */}
      </AdsHeader>
      <AdContainer>
        <AdContent>
          <SiteWrap>
            <img src={AdspyderLogo} />
            <p>
              {/* {new URL(selectedProject.website).hostname} */}

            </p>
            <AdBtn>{isTextAd ? "text ad" : "image ad"}</AdBtn>
          </SiteWrap>
          <ContentContainer textScroll={isTextAd}>{children}</ContentContainer>
        </AdContent>
      </AdContainer>
    </AdsWrapper>
};

export default TopAds;
