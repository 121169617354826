import { useRef, useState } from "react";
import { useImageAdGenerator } from "../../context/imageAdGeneration";
import { BrandDescriptionContainer, Description, Label, Name } from "./BrandDescription.styled";

function BrandDescription() {
    const descriptionRef = useRef(null)
    const nameRef = useRef(null)

    const { imageAdGeneratorState, imageAdGeneratorDispatch } = useImageAdGenerator()
    const [inputFocussed, setInputFocussed] = useState(null)
    const handleDescriptionValueChange = () => {
        if(descriptionRef.current)
        imageAdGeneratorDispatch({type: "SET_BRAND_DETAILS", value: {description: descriptionRef.current.value}})
    }
    const handleNameValueChange = () => {
        if(nameRef.current)
        imageAdGeneratorDispatch({type: "SET_BRAND_DETAILS", value: {name: nameRef.current.value}})
    }
    return ( 
        <BrandDescriptionContainer>
            <Label>Brand name</Label>
            <Name placeholder="Enter name of your brand" ref={nameRef} onChange={handleNameValueChange} value={imageAdGeneratorState.brandDetails.name} 
            isFocussed={inputFocussed === "name"}
            onFocus={() => setInputFocussed("name")}
            onBlur={() => setInputFocussed(null)}
            />
            <Label>Brand/product/service description:</Label>
            <Description placeholder="Enter description of your brand" onChange={handleDescriptionValueChange} value={imageAdGeneratorState.brandDetails.description} ref={descriptionRef} 
            isFocussed={inputFocussed === "description"}
            onFocus={() => setInputFocussed("description")}
            onBlur={() => setInputFocussed(null)}
            />
        </BrandDescriptionContainer>
     );
}

export default BrandDescription;