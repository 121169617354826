import React from 'react'
import { TotalCamp, TotalCount, TotalCountWrap, TotalDiv, ActiveGraph, TotalPercent, TotalWrapper, UpdatedHour } from './index.styled'
import { TotalAdsAreaChart } from './AreaChart/index'
import greenArrow from '../../../../../../media/Dashboard/greenArrow.svg'
import { useKeywordTracking } from '../../../context/keywordtracking'
import Skeleton from "react-loading-skeleton";
import Tooltip from 'rc-tooltip'
import helpCircle from '../../../../../../media/Dashboard/help-circle.svg'

const TotalAds = ({isTableDataLoading}) => {
    const {keywordTrackingState, keywordTrackingDispatch} = useKeywordTracking()

    const {activeCampaigns} = keywordTrackingState;

    return (
        <TotalWrapper>
            <TotalDiv>
                <TotalCamp>Total Active ads<Tooltip trigger={['hover']} overlayStyle={{ width: "100" }} overlay={<span>Total active ads that were found in last few weeks.</span>}>
                <img src={helpCircle}/>     
                    </Tooltip></TotalCamp>
                {isTableDataLoading ?
                    <> 
                        <Skeleton height={"45px"} width= {'203px'}/> 
                        <Skeleton height={"20px"} width= {'203px'} style={{'marginTop': '10px'}}/> 
                    </> 
                :
                <>
                <TotalCountWrap>
                    <TotalCount>{activeCampaigns.totalCampaignAds}</TotalCount>
                    {activeCampaigns.totalCampaignAdsIncrease > 0 &&<TotalPercent><img src={greenArrow}/>{Math.round(activeCampaigns.totalCampaignAdsIncrease)}%</TotalPercent>}
                </TotalCountWrap>
                </>
                }
                <UpdatedHour>update 20hrs ago</UpdatedHour>
            </TotalDiv>
            <ActiveGraph>
                <TotalAdsAreaChart />
            </ActiveGraph>
        </TotalWrapper>
    )
}

export default TotalAds