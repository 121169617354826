import projectLogo from "../../../assests/projectLogo.svg";
import projectAlign from "../../../assests/projectAlign.svg";
import searchKeyword from "../../../assests/search-status.svg";
import competitorsLogo from "../../../assests/global-search.svg";
import textAdsLogo from "../../../assests/textAds.svg";
import imageAdsLogo from "../../../assests/ImageAds.svg";
import folder from "../../../assests/folder.svg";
import topTextAdsLogo from "./assets/text.svg";
import topImageAdsLogo from "./assets/image.svg";
export default {
  projectData: {
    project: `Project Dashboard`,
    heading: [
      {
        id: 1,
        projectLogo: projectAlign,
        name: "Project",
        widthValue: "16.1%",
      },
      {
        id: 2,
        projectLogo: searchKeyword,
        name: "Keyword Tracking",
        widthValue: "23.3%",
      },
      {
        id: 3,
        projectLogo: competitorsLogo,
        name: "Competitors Ontrack",
        widthValue: "24.2%",
      },
      {
        id: 4,
        projectLogo: textAdsLogo,
        name: "Text Ads",
        widthValue: "11.91%",
      },
      {
        id: 5,
        projectLogo: imageAdsLogo,
        name: "Image Ads",
        widthValue: "11.91%",
      },
      { id: 6, projectLogo: folder, name: "saved Ads", widthValue: "11.91%" },
    ],
  },
  textAdData: [
    {
      title:
        "How to record the screen in one click?  Examples (the actual work video How to record the screen in one click?",
      textUrl: "www.flonnect.com",
      description:
        "High quality screen recording, best meeting recorder, in part capture screen losjudiuo ihsafdua ojh8haefl kjacfijhsfclasmfc ojih",
      platform: "google",
    },
    {
      title:
        "How to record the screen in one click?  Examples (the actual work video",
      textUrl: "www.flonnect.com",
      description:
        "High quality screen recording, best meeting recorder, in part capture screen losjudiuo ihsafdua ojh8haefl kjacfijhsfclasmfc ojih",
      platform: "facebook",
    },
  ],
  topAds: {
    text: {
      name: "text ads",
      headerText: "Top competitor text ads",
      headerLogo: topTextAdsLogo,
    },
    image: {
      name: "image ads",
      headerText: "Top competitor image ads",
      headerLogo: topImageAdsLogo,
    },
  },
};
