import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { appClient } from "../../../../../utils/axios-utils";
import { useMutation } from "@tanstack/react-query";
import { useAccount } from "../../context/account";
import { useAuth } from "../../../../../context/auth";
import toast from "react-hot-toast";
import SearchLoader from "../../../../HOC/SearchLoader";
import { useGetAccount } from "../../../../hooks/useGetAccount";

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  height: calc(100vh - 85px);

  height: 300px;
  flex-direction: column;
  gap: 20px;
  box-shadow: 1px 2px 10px 1px #80808036;
  width: 50%;
  border-radius: 8px;
  margin: 200px auto;
  height: 200px;
  h2 {
    text-align: center;
    font-size: 20px;
    font-weight: 200;
  }
`;

const CancelButton = styled.div`
  height: 40px;
  margin-left: 8px;
  border-radius: 8px;
  background-color: rgb(255, 113, 30);
  color: white;
  font-size: 14px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
`;

const NoButton = styled.div`
  width: 90px;
  height: 40px;
  background: rgb(0, 110, 204);
  color: white;
  pointer-events: all;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border: 1px solid rgb(236, 236, 236);
  padding: 6px 12px;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 30px;
`;

const cancelSub = async () => {
  const config = {
    url: "/payment/session/cancel-subscription",
    method: "GET",
  };

  return appClient(config);
};

const logoutUser = () => {
  const config = {
    url: "/auth/logout",
    method: "DELETE",
  };

  return appClient(config);
};

const userPlan = () => {
  const config = {
    url: "/payment/user-info",
    method: "GET",
  };

  return appClient(config);
};

const CancelPage = () => {
  const { accountState, accountDispatch } = useAccount();
  const { authState, authDispatch } = useAuth();
  const [errorMessage, setErrorMessage] = useState("");

  const accountInfoHook = useGetAccount();

  const navigate = useNavigate();

  const getDaysLeft = (planEndDate) => {
    let endDate = new Date(planEndDate).getTime() / 1000;
    let currentDate = Date.now() / 1000;

    const timestamp = endDate - currentDate; // Replace with your timestamp in seconds
    const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

    const date = new Date(timestamp * 1000); // Convert the timestamp to milliseconds

    // Calculate the number of days
    const days = Math.floor(date.getTime() / millisecondsPerDay);

    return days;
  };

  const {
    mutate: cancelSubscriptionMutate,
    isLoading: isCancelSubscriptionLoading,
  } = useMutation(cancelSub, {
    onSuccess: (data) => {
      // toast.success("Canceled Subscription")
      toast.success("Canceled Subscription successfully");
      //   getUserPlanData();
      navigate("/");
      // onLogout()
    },
    onError: (err) => {},
  });

  // const {mutate: onLogout} = useMutation(logoutUser, {
  //     onSuccess: (data) => {
  //         authDispatch({type: 'LOGOUT_SUCCESS'})
  //         toast.success("Signed out succesfully")
  //         authDispatch({type:'STOP_LOADER'})
  //         navigate('/')
  //     },
  //     onError: (err) => {
  //     },
  //     onMutate: () => {
  //         authDispatch({type:'START_LOADER'})
  //         // toastId = toast.loading("Signing out")
  //     }
  // })

  //   const { mutate: getUserPlanData, isLoading: isPlansDataLoading } =
  //     useMutation(userPlan, {
  //       onSuccess: (data) => {
  //         accountDispatch({ type: "UPDATE_USER_DATA", value: data });
  //         accountDispatch({ type: "UPDATE_IS_USER_DATA_LOADING", value: false });
  //       },
  //       onError: (err) => {
  //         setErrorMessage(err.response.data.errors[0].message);
  //       },
  //       onMutate: () => {},
  //     });

  useEffect(() => {
    // getUserPlanData();
  accountInfoHook.getUserAccountInfo();

  }, []);

  return (
    <Container>
      {accountInfoHook.isPlansDataLoading ? (
        <div className="loader">
          <SearchLoader message={"Loading..."} />
        </div>
      ) : getDaysLeft(accountState.userData.planValidity) > 0 &&
        accountState.userData.type != "free" ? (
        authState.plan.cancelAt ? (
          <h2>
            Your plan cancels at{" "}
            {authState.plan.cancelAt
              .split("T")[0]
              .split("-")
              .reverse()
              .join("-")}
          </h2>
        ) : (
          <>
            <h2>Are you sure you want to cancel the subscription?</h2>
            <ButtonContainer>
              <CancelButton onClick={cancelSubscriptionMutate}>
                Yes, Cancel
              </CancelButton>
              <NoButton onClick={() => navigate("/adspy")}>No</NoButton>
            </ButtonContainer>
          </>
        )
      ) : (
        <h2>You are not subscribed to any plan.</h2>
      )}
    </Container>
  );
};

export default CancelPage;
