import styled from 'styled-components'

export const ResetPasswordHeader = styled.div`
    max-width: 452px;
    margin: 0 auto;
    margin-bottom: 20px;
    width: 100%;
    p{
        color: #2E2E2E;
        font-size: 20px;
    }
`;

export const EmailSelected = styled.p`
    margin-top: 12px;
    line-height: 21px;
    p{
        color: ${props => props.theme.colors.lightBlack};
        font-size: 14px;
        line-height: 21px;
        font-weight: 300;
    }
    span{
        font-weight: 500;
    }
    a{
        margin-left: 8px;
        color: ${props => props.theme.colors.linkBlue};
    }
`;

export const FormContainer = styled.form`
        /* margin-top: 20px; */
        width: 100%;
        max-width: 452px;
        margin: 0 auto;
`;


export const OtpInputContainer = styled.div`
    margin-bottom: 20px;
    
    label{
            color: ${props => props.theme.colors.Grey};
            font-size: 16px;
            margin-bottom: 8px;
        }


    /* button{
        display: flex;
        font-weight: 300;
        background: none;
        border: none;
        outline: none;
        color: ${props => props.theme.colors.linkBlue};
        font-size: 14px;
        margin-right: 8px;
    }

    button:disabled{
        cursor: not-allowed;
        color: ${props => props.theme.colors.lightDimGrey};
    } */
`;


export const ResendBtn = styled.div`
    display: flex;
    font-weight: 300;
    background: none;
    border: none;
    outline: none;
    color: ${props => props.invisible == "true" ? props.theme.colors.lightDimGrey : props.theme.colors.linkBlue};
    font-size: 14px;
    margin-right: 8px;
    cursor: ${props => props.invisible == "true" ? "not-allowed": "pointer"};
`;

export const TimerSection = styled.div`
    display: flex;
    margin-top: 8PX;
    font-size: 14PX;;
    color: #2E2E2E;
    font-weight: 300;
    span{
        margin-left: 3px;
    }
`;

export const OtpInput = styled.input`
    background: #FFFFFF;
    border: ${props => props.error ? "1px solid #FF5180" : "1px solid #ECECEC"};
    outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};
    border-radius: 8px;
    width: 100%;
    height: 40px;
    margin-top: 8px;
    outline: none;
    padding: 9px 12px;
    color: ${props => props.theme.colors.lightBlack};
    &:focus{
        border: ${props => props.error ? "1px solid #FF5180" : "1px solid #5AB3FF"};
        outline: ${props => props.error ? "2px solid #FFB7CB" : "2px solid #B9DDFF"};
    }

`;


export const CtaContainer = styled.div`

`;


export const ChangePasswordButton = styled.button`
    background: #FF711E;
    border-radius: 8px;
    width: 100%;
    height: 40px;
    outline: none;
    border: none;
    color: ${props => props.theme.colors.white};
    font-size: 16px;

    &:hover{
        background: #E75500;
    }

    &:active{
        background: #C74A00;
    }
`;


export const FooterContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    line-height: 21px;
    p{
        color: ${props => props.theme.colors.lightBlack};
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
    }

    a{
        color: ${props => props.theme.colors.linkBlue};
        margin-left: 4px;
    }

    &:hover{
        a{
            text-decoration: underline;
        }
    }
`;


export const Error = styled.p`
    color: #FF0045;
    margin-top: 6px;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 16px;
`;

export const OtpError = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    background: #FFF3F7;
    border: 1px solid #FFB7CB;
    border-radius: 8px;
    padding: 12px;
    font-size: 14px;
    font-weight: 300;
    color: #2E2E2E;
    line-height: 21px;
    margin-top: 20px;
    margin-bottom: 15px;

    p{
        margin-left: 8px;
    }
    a{
        color: #003FBC;
    }
`;