import React, { useRef, useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import {
    useMutation
  } from "@tanstack/react-query"
import NoResultsLogo from "../../../../../../../media/Dashboard/noresults.svg"
// Styles
import {
    CountriesList,
    CountriesContainer,
    DateRange,
    DestinationUrl,
    DetailsHeadline,
    AdDetailsBody,
    CloseDetails,
    DetailsHeader,
    DetailsContainer,
    FooterRight,
    AdCountries,
    FooterLeft,
    CardFooter,
    CardBody,
    Favorite,
    FloatingButtonsContainer,
    Card,
    CardsList,
    ResultsContainer,
    ResultsWraper,
    ImageContainer,
    MediaType,
    MediaSize,
    AgencyName,
    DetailsImageContainer,
    ImageResolution,
    NoResults,
    ViewMoreAds
} from "./index.styled"

import SearchLoader from '../../../../../../HOC/SearchLoader'
import BlueRightArrow from '../../../../../../../media/Dashboard/blue-right-arrow.svg'

//Icons
import FavorietIcon from "../../../../../../../media/Dashboard/addFavorite.svg"
import CloseBtn from "../../../../../../../media/Dashboard/detailsPaneClose.svg"

import CountryIcon from "../../../../../../../media/Images/CountryIcon.svg"
import AltImage from '../../../../../../../media/Dashboard/altimage.png'

import {appClient} from '../../../../../../../utils/axios-utils'

import { useAuth } from '../../../../../../../context/auth'
import { useLandingPageAnalysis } from '../../../../context/landingPageAnalysis'
import { getTodayDate } from '../../../../../../../utils/dateFormat'

const requestDataCrawl = async (data) => {
    const config = {
        url: `/track/search/update-search-query`,
        method: "POST",
        data
    }
  
    return appClient(config)
  }

const CardElement = ({ad,idx, containerRef}) => {
    const { authState, authDispatch } = useAuth()

    const {landingPageAnalysisState, landingPageAnalysisDispatch} = useLandingPageAnalysis()

    const [height, setHeight] = useState('')
    const [width, setWidth] = useState('')

    const scrollToElement = (index) => {
        if(containerRef.current){
            const element = containerRef.current.children[index];
            const scrollPosition = element.offsetTop - 20;
            containerRef.current.scrollTo({ top: scrollPosition, behavior: 'smooth' });
        }
      };

    const toggleDetailsModal = () => {
        // console.log("addd yt details")
        if((landingPageAnalysisState.adDetails.id !== ad.id) && landingPageAnalysisState.detailsModal){ 
            landingPageAnalysisDispatch({type:'SET_AD_DETAILS', value: ad})
            landingPageAnalysisDispatch({type:'UPDATE_COLLAPSE_CALENDER', value: false})
        }else{
            landingPageAnalysisDispatch({type:'SET_AD_DETAILS', value: ad})
            landingPageAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: !landingPageAnalysisState.detailsModal})
            landingPageAnalysisDispatch({type:'UPDATE_COLLAPSE_CALENDER', value: true})
        }
        setTimeout(() => {
            scrollToElement(idx)
        }, 100);
    }

    const convertTimestamp = (timestamp) => {
        // Create a new Date object based on the timestamp
        var date = new Date(timestamp);
      
        // Get the month name
        var monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        var month = monthNames[date.getMonth()];
      
        // Get the year
        var year = date.getFullYear();
      
        // Assemble the formatted date string
        var formattedDate = date.getDate() + ' ' + month  + ' ' + year;
    
        return formattedDate;
      }

      useEffect(() => {
        const img = new Image();
        img.onload = function() {
            setTimeout(() => {
                setHeight(img.height)
                setWidth(img.width)
            }, 1000);
        }
        img.src = `https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${ad.id}`;
      }, [])

    return <Card detailsOpen={landingPageAnalysisState.adDetails.id == ad.id} key={
        ad.id
    }>
        <FloatingButtonsContainer>
            <Favorite isFav={idx == 1} onClick={()=>{
                authDispatch({type:'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'display'})
                authDispatch({type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id})
                authDispatch({type:'UPDATE_SAVE_AD_POPUP', value: true})}}> 
                <img src={FavorietIcon}/>
            </Favorite>
            {/* |
            <DropdownIcon>
                <span/>
            </DropdownIcon> */}
        </FloatingButtonsContainer>
        <CardBody>
            <ImageContainer>
                {ad.image ? <img onLoad={(e)=>{
                    // console.log(e.width,e.target.width, e.target.height)
                    }} src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${ad.id}`} onError={() => {}}/> : ad.video ? <video src={`https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${ad.id}`} preload="auto" controls loop style={{width: "100%", height: "100%"}}/> : <img src={AltImage} onError={() => {}}/>}
                <ImageResolution>
                    {height ? height : ""} x {width ? width : ""}
                </ImageResolution>
            </ImageContainer>
        </CardBody>
        <CardFooter>
            <FooterLeft>
                {/* <AdDate>
                    {ad.firstSeen && ad.lastSeen && <img src={DateIcon}/>}{convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
                </AdDate> */}
                {ad.country && <AdCountries>
                    <img src={CountryIcon}/>{ ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
                </AdCountries>}
            </FooterLeft>
            <FooterRight>
                {/* <DetailsButton onClick={toggleDetailsModal}>
                    More details
                </DetailsButton> */}
            </FooterRight>
        </CardFooter>
    </Card>
}

const DetailsElement = () => {
    const {landingPageAnalysisState, landingPageAnalysisDispatch} = useLandingPageAnalysis()

    const [height, setHeight] = useState(0)
    const [width, setWidth] = useState(0)

    const convertTimestamp = (timestamp) => {
        // Create a new Date object based on the timestamp
        var date = new Date(timestamp);
      
        // Get the month name
        var monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        var month = monthNames[date.getMonth()];
      
        // Get the year
        var year = date.getFullYear();
      
        // Assemble the formatted date string
        var formattedDate = date.getDate() + ' ' + month  + ' ' + year;
      
        return formattedDate;
      }

    useEffect(() => {
        const img = new Image();
        img.onload = function() {
            setTimeout(() => {
                setHeight(img.height)
                setWidth(img.width)
            }, 1000);
        }
        img.src = `https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${landingPageAnalysisState.adDetails.id}`;
      }, [])

    return <DetailsContainer>
    <DetailsHeader>
        <h3>Ad details</h3> <CloseDetails onClick={()=>{landingPageAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: false})
        landingPageAnalysisDispatch({type:'SET_AD_DETAILS', value: {}})
        landingPageAnalysisDispatch({type:'UPDATE_COLLAPSE_CALENDER', value: false})}}
        ><img src={CloseBtn}/></CloseDetails>
    </DetailsHeader>
    <AdDetailsBody>
        <DetailsImageContainer>
            {landingPageAnalysisState.adDetails.image ? <img src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${landingPageAnalysisState.adDetails.id}`} onError={() => {}}/> : landingPageAnalysisState.adDetails.video ? <video src={`https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${landingPageAnalysisState.adDetails.id}`} preload="auto" controls loop style={{width: "100%", height: "100%"}}/> : <img src={AltImage} onError={() => {}}/>}
            {/* {<img onLoad={(e)=>{}} src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${landingPageAnalysisState.adDetails.id}`} onError={() => {}}/> } */}
        </DetailsImageContainer>
        <DetailsHeadline>
            <label>Title:</label>{landingPageAnalysisState.adDetails?.title && landingPageAnalysisState.adDetails.title != "no title" && landingPageAnalysisState.adDetails.title}
        </DetailsHeadline>
        <DestinationUrl>
            <label>Destination URL:</label>{landingPageAnalysisState.adDetails?.url}
        </DestinationUrl>
        <MediaType>
            <label>Media Type:</label>{landingPageAnalysisState.adDetails?.image ? "Image" : landingPageAnalysisState.adDetails?.video ? "Video" : "-"}
        </MediaType>
        <MediaSize>
            <label>Media Size:</label>{height ? height : ""} x {width ? width : ""}
        </MediaSize>
        {landingPageAnalysisState.adDetails.agency && <AgencyName>
            <label>Agency:</label>{landingPageAnalysisState.adDetails?.agency[0]}
        </AgencyName>}
        <DateRange>
            <label>Date:</label>{convertTimestamp(landingPageAnalysisState.adDetails.firstSeen)} - {convertTimestamp(landingPageAnalysisState.adDetails.lastSeen)}
        </DateRange>
        {landingPageAnalysisState.adDetails.country && <CountriesContainer>
            {landingPageAnalysisState.adDetails.country && <><label>Country:</label><CountriesList>{landingPageAnalysisState.adDetails.country.map((country,idx)=>{
                return <span>{country}</span>
            })}</CountriesList>
            </>}
        </CountriesContainer>}
        {/* <LandingPageScreenShot>
            <label>Landing page screenshot</label>
            <ScreenshotContainer>
                <img src={ApplePage}/>
            </ScreenshotContainer>
        </LandingPageScreenShot> */}
    </AdDetailsBody>
</DetailsContainer>
}


const DisplayAdsResults = ({isDataLoading}) => {
    const { authState, authDispatch } = useAuth()

    const containerRef = useRef(null);
    const [ dataRequested, setDataRequested ] = useState(false)
    const navigate = useNavigate()

    const {landingPageAnalysisState, landingPageAnalysisDispatch} = useLandingPageAnalysis()
    // const {searchState, searchDispatch} = useSearch()
    // const {authState, authDispatch} = useAuth()

    const {mutate: requestData, isLoading: isRequestDataLoading} = useMutation(requestDataCrawl, {
        onSuccess: (data) => {
            setDataRequested(true)
        },
        onError:  ((e)=>{
            
        })
    })

    const onDataRequest = () => {
        if(!dataRequested){
            requestData({id: landingPageAnalysisState.queryId})
        }
    }

    // if(authState.isRestrictedPopup){
    //     return  <ResultsContainer detailsModal={landingPageAnalysisState.detailsModal}>
    //         <PlatformRestrictedPopup err={searchState.searchError}/>
    //     </ResultsContainer>
    // }

    // if(searchState.limitExceeded){
    //     return  <ResultsContainer detailsModal={landingPageAnalysisState.detailsModal}>
    //         <LimitExceededPopup err={searchState.searchError}/>
    //     </ResultsContainer>
    // }

    return  <ResultsContainer detailsModal={landingPageAnalysisState.detailsModal}>
        {isDataLoading ? <div className='loader'>
        <SearchLoader/>
      </div> :  landingPageAnalysisState.ads.length == 0 ? <NoResults>
                        <img src={NoResultsLogo}/>
                        <h2>No Results Found</h2>
                        <p>No ads found on this specific date. try some other dates</p>
                         {/* <RequestDataBtn dataRequested={dataRequested ? dataRequested : ""} disable={isRequestDataLoading} onClick={onDataRequest}>{dataRequested ? <p>Your request for data crawl has been accepted<img src={TickImg}/></p> : <p>{isRequestDataLoading ? "Requesting...": "Request data crawl for this query"}</p>}</RequestDataBtn> */}
                    </NoResults> : 
                <ResultsWraper>
                    <CardsList ref={containerRef} detailsModal={landingPageAnalysisState.detailsModal}> {
                        landingPageAnalysisState.ads && landingPageAnalysisState.ads.length > 0 && landingPageAnalysisState.ads.slice(0, 2).map((ad, idx) => {
                            return <CardElement containerRef={containerRef} ad={ad} idx={idx}/>
                        })
                    } 
                    <ViewMoreAds onClick={()=>window.open(`https://app.adspyder.io/adspy/display?searchMode=url&keywordSearchType=phrase&phraseInput=&page=1&excludedTerm=&searchIn=title%2Ccontent&sortType=relevant&urlSearchType=domain&domainUrlInput=${landingPageAnalysisState.searchUrl}&startDate=${landingPageAnalysisState.selectedYear+"-"+landingPageAnalysisState.selectedMonth+"-"+landingPageAnalysisState.selectedDay}&endDate=${getTodayDate()}`)}>View More Ads <img src={BlueRightArrow}/></ViewMoreAds>
                    </CardsList>
                    {
                    landingPageAnalysisState.detailsModal && <DetailsElement/>
                    }
                </ResultsWraper>}
            </ResultsContainer>
}

export default DisplayAdsResults
