import React from 'react';
import { TableWrapperMain, TableContainer, TableHead, KeywordLabel, VolumeLabel, KcsLabel, CpcLabel, TableBody, Row, KeywordData, ToolTipTextContainer, VolumeData, KcsData, CpcData, ToolTip, MoreKeywordButton } from './index.styled';
import { useNavigate } from "react-router-dom"

import { useUrlAnalysis } from '../../../context/urlanalysis';
import Skeleton from 'react-loading-skeleton'
import { formatNumber } from "../../../../../../utils/formatTotal";


const KeywordsTable = ({isDataLoading}) => {
    const navigate = useNavigate()

    const { urlAnalysisState, urlAnalysisDispatch } = useUrlAnalysis()

    return <TableWrapperMain>
    <h4>Keyword traffic</h4>
    <TableContainer>
        <TableHead>
            <KeywordLabel>
                Keyword
            </KeywordLabel>
            <VolumeLabel>
                Volume
            </VolumeLabel>
            <KcsLabel>
                Competition
            </KcsLabel>
            <CpcLabel>
                CPC (USD)
            </CpcLabel>
        </TableHead>
        <TableBody>
            {isDataLoading ? [...Array(8)].map((data, idx)=>{
                    return <Skeleton height={"20px"} style={{marginBottom: "16px"}}/>
                }): 
                urlAnalysisState.domainKeywords.map((data, idx)=>{
                    if(data)
                    return <Row key={idx}>
                        <KeywordData>
                            <ToolTipTextContainer
                                className="tooltip-container">
                                <p>{data.keyword ? data.keyword : data}</p> 
                                <ToolTip>
                                    {data.keyword ? data.keyword : data} 
                                </ToolTip>
                            </ToolTipTextContainer>
                        </KeywordData>
                        <VolumeData>
                            {isDataLoading ? <Skeleton />  : formatNumber(data.vol)}
                        </VolumeData>
                        <KcsData>
                            {isDataLoading ? <Skeleton />  : data.competition}
                        </KcsData>
                        <CpcData>
                            {isDataLoading ? <Skeleton />  : data.cpc.currency + data.cpc.value}
                        </CpcData>
                    </Row>
                })
            }
        </TableBody>
    </TableContainer>
    <MoreKeywordButton>
        <span onClick={()=>navigate(`/keyword-analysis/details?query=${urlAnalysisState.searchUrl}&page=1&type=domain`)
    }>Show more keywords</span>
    </MoreKeywordButton>
    </TableWrapperMain>
};

export default KeywordsTable;


