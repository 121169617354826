import React from "react";
import { PieChart, Pie, Cell } from "recharts";
import { useAuth } from "../../../../../../context/auth";
import { useUrlAnalysis } from "../../../context/urlanalysis";
import Skeleton from 'react-loading-skeleton';

const CustomPieChart = ({isDataLoading}) => {
  const { authState } = useAuth();
  const { urlAnalysisState, urlAnalysisDispatch  } = useUrlAnalysis()
  const colorsArray = ["#1679DB", "#A158E0", "#E24593", "#02B6FB", "#C4C4C4"];

  const JSONData = urlAnalysisState?.adsDistribution;
    // const JSONData = [{
    //   "key": "google-full",
    //   "percentage": 100.00
    // }]
    
    // useEffect(() => {
      const data = [...JSONData]?.sort((a, b) => Number(b.percentage) - Number(a.percentage)); 

      const totalData = data?.length;
      const anglePerEntry = 360 / totalData;
      const endAngle = 90 + anglePerEntry * totalData;
    // }, [])
  
  return isDataLoading ? 
  <Skeleton style={{
      lineHeight: 10,
      width: 140,
    }}/> : 
      <PieChart width={140} height={140}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          startAngle={endAngle}
          innerRadius={30}
          outerRadius={55}
          paddingAngle={1}
          dataKey="percentage"
          endAngle={90}
          isAnimationActive={false}
          animationDuration={500}
        >
          {data.map((entry, index) => (
            <Cell
              key={index}
              fill={colorsArray[index % colorsArray.length]}
              style={{ pointerEvents: "none" }}
            />
          ))}
        </Pie>
      </PieChart>
};

export default React.memo(CustomPieChart);
