import styled from 'styled-components';

export const Container = styled.div`
    width: 90%;
    max-width: 1220px;
    margin: auto;

    margin-top: 32px;
    border-radius: 8px;
    align-items: flex-end;
`;

export const ResultsContainer = styled.div`
    display: flex;
    gap: 12px;
    height: calc(100vh - 245px);
    background: #006ecc00;
    position: absolute;
    left: 0;
    top: 0;
    .loader{
        height: 200px;
        margin: auto;
    }
`;

export const Header = styled.div`
    padding: 28px 32px;
    background: #FFFFFF;
    h1{
        font-size: 20px;
        color: #12558D;
        font-weight: 600;
        margin: 0;
    }
    h3{
        font-size: 16px;
        font-weight: 300;
        color: #2E2E2E;
        margin-top: 4px;
    }
`;

export const SearchContainer = styled.div`
    display: flex;
    height: 62px;
    margin-top: 32px;
    align-items: end;
`;

export const Separator = styled.div`
    width: 1.5px;
    height: 15px;
    background-color: #D4D4D4;
    border-radius: 8px;

    /* margin: 0 8px; */
    margin: 36px 10px 10px 10px;
    
`;


export const Content = styled.div`
    padding: 28px 32px;
    background: #FFFFFF;
    margin-top: 36px;
    padding: 48px auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;

    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: auto;
    height: 65vh;

    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }

`;

export const CreativeContainer = styled.div`
    display: flex;
    width: 800px;
    gap: 24px;
`;

export const CreativeLeft = styled.div`
    h2{
        color: #595959;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 12px;
    }
    
    p{
        color: #666;
        font-size: 16px;
        font-weight: 300;
        line-height: normal;
    }

    img{
        width: 400px;
    }
`;

export const CreativeRight = styled.div`
    h2{
        color: #595959;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 12px;
    }
    
    p{
        color: #666;
        font-size: 16px;
        font-weight: 300;
        line-height: normal;
    }

    img{
        width: 400px;
    }
`;

export const Button = styled.button`
    width: 75px;
    height: 40px;
    margin-left: 8px;

    border-radius: 8px;
    background-color: #FF711E;
    color: white;
    font-size: 14px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    :hover {
        filter: brightness(90%);
    }
`;

export const Input = styled.input`
    width: 600px;
    height: 40px;
    background-color: white;
    border: 1px solid #ECECEC;
    border-radius: 8px;
    box-sizing: border-box;

    padding: 0 12px;
    outline: none;
    color: #2E2E2E;
    color: #2E2E2E;
    font-size: 14px;
    font-weight: 300;
    padding-right: 30px;
    border: ${props => props.error ? "1px solid #FF5180" : "1px solid #ECECEC"};
    outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};
    &:focus{
        border: ${props => props.error ? "1px solid #FF5180" : "1px solid #5AB3FF"};
        outline: ${props => props.error ? "2px solid #FFB7CB" : "2px solid #B9DDFF"};
    }
`;


export const InputContainer = styled.div`
    position: relative;
    height: 40px;
    
`;

export const ToolTipImageContainer = styled.div`
    position: absolute;
    right: 8px;
    top: ${(props) => props.lpa ? "10px" : "12px"};
    display: ${props => props.error ? "flex": "none"};
    img{
        filter: contrast(1);
    }

    &:hover{
        > div{
            display: flex;
        }

        img{
            display: block;
        }
    }
`;

export const ToolTip = styled.div`
    background: #555555;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 300;
    position: absolute;
    bottom: -34px;
    padding: 4px 8px;
    width: ${(props) => props.lpa ? "150px" : "125px"};
    display: none;
`;

export const ToolTipContainer = styled.div`
    font-size: 12px;
    font-weight: 300;
    color: #666;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-bottom: 4px;
    margin-left: 10px;

    img{
        margin-left: 4px;
    }
`;

export const Wrapper = styled.div`

`;

export const ModeContainer = styled.div`
    height: 40px;
    gap: 4px;
    border-radius: 8px;
    display: flex;
    margin-right: 8px;
`;

export const KeywordButtonContainer = styled.div`
    height: 100%;
    border: none;
    /* background: none; */
    background: ${props => props.active ? "#006ECC" : "white"};
    color: ${props => props.active ? "white" : "#707070"};
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ECECEC;
    padding: 6px 12px;
    cursor: pointer;
    
    input[type=radio]
    {
        background: transparent;
        appearance: none;
        width: 100px;
        height: 30px;
        position: absolute;
    }

    input[type=radio] + label
    {
        cursor: pointer;
        position: relative;
        padding-left: 20px;
        line-height: 20px;
    }

    input[type=radio] + label::before
    {
        content: "";
        display: inline-block;
        width: 12px;
        aspect-ratio: 1;
        border: ${props => props.active ? "1px solid white" : "1px solid #E2E2E2"};
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        opacity: 1;
        transition: all 0.3s;
    }

    input[type=radio] + label::after
    {
    content: "";
        display: inline-block;
        width: 6px;
        aspect-ratio: 1;
        border: 1px solid #686de0;
        background: ${props => props.active ? "white" : "#006ECC"};
        border-radius: 50%;
        position: absolute;
        left: 3px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        transition: all 0.3s;
    }

    input[type=radio]:checked + label::after
    {
        opacity: 1;
    }
`;