import React, { useEffect, useState, useRef } from "react";
import { SortDropDown, SelectedSortType, SortTypeDropDown, SortOption, SortOptionList, Footer, ApplyBtn } from "./index.styled"
import SortIcon from "../../../../../../../../media/Dashboard/SortIconBlack.svg"
import { useGoogleSearch } from "../../../context/google";
import { useSearchParams } from "react-router-dom";

function useOutsideAlerter(ref, setShow) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setShow(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref])
}

const SortType = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const { googleSearchState, googleSearchDispatch } = useGoogleSearch()
    const [ localSelectedSort, setLocalSelectedSort ] = useState({})
    const [show, setShow] = useState(false)
    const wrapperRef = useRef(null)

    let tempSortTypes = [{
        name:"Oldest first",
        type: 'oldest'
    }, {
        name:"Relevance",
        type: 'relevant'
    }, {
        name:"Latest first",
        type:'latest'
    }];

    useOutsideAlerter(wrapperRef, setShow)

    useEffect(() => {
        setLocalSelectedSort(googleSearchState.selectedSortType)
    }, [])
    
    console.log(googleSearchState)

    return <SortDropDown ref={wrapperRef}>
        <SelectedSortType onClick={()=>setShow(prev => !prev)}>
            Sort by <img src={SortIcon}/>
        </SelectedSortType>
        {show && <SortTypeDropDown>
            <SortOptionList>
                <h3>Sort by</h3>
                {tempSortTypes.map((option)=>{
                    return <SortOption
                        className="option"
                        isSelected={googleSearchState.selectedSortType.type == option.type}
                        onClick={()=>{
                            setLocalSelectedSort(option)
                        }}>
                        <label>{option.name}</label>
                        <input type="radio" checked={localSelectedSort.type == option.type}/>                        
                    </SortOption>
                })}
            </SortOptionList>
            <Footer>
                <ApplyBtn
                    isActive={localSelectedSort.type !== googleSearchState.selectedSortType.type}
                    onClick={()=>{
                        googleSearchDispatch({type: 'UPDATE_SELECTED_SORT_TYPE', value: localSelectedSort})
                        setShow(false)

                        searchParams.set("sortType", localSelectedSort.type)

                        setSearchParams(searchParams)
                    }}
                >
                    Apply
                </ApplyBtn>
            </Footer>
        </SortTypeDropDown>}    
    </SortDropDown>
}

export default SortType