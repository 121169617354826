import React from "react";
import { useReducer, createContext, useContext } from "react";

import produce from "immer";

const initialState = {
  isPublishToggle: 0,
  isDetailsToggle: null,
  isTargetCountry: [],
  isHeadLineData: [],
  adGroupIndex: 1,
  currentPage: "home",
};

const textAdGeneratorReducer = produce((draft, action) => {
  switch (action.type) {
    case "SET_PUBLISH_TOGGLE":
      draft.isPublishToggle = action.value;
      break;
    case "SET_DETAILS_TOGGLE":
      draft.isDetailsToggle = action.value;
      break;
    case "SET_TARGET_COUNTRY":
      draft.isTargetCountry = draft.isTargetCountry || [];
      draft.isTargetCountry.push(action.value);
      break;
    case "REMOVE_TARGET_COUNTRY":
      draft.isTargetCountry = draft.isTargetCountry.filter(
        (country) => country.code !== action.value.code
      );
      break;
      
    default:
      break;
  }
});

const useTextAdGeneratorReducer = () => {
  return useReducer(textAdGeneratorReducer, initialState);
};

const TextAdGeneratorContext = createContext();

export const TextAdGeneratorProvider = ({ children }) => {
  const [state, dispatch] = useTextAdGeneratorReducer();
  const value = {
    textAdGeneratorState: state,
    textAdGeneratorDispatch: dispatch,
  };

  return (
    <TextAdGeneratorContext.Provider value={value}>
      {children}
    </TextAdGeneratorContext.Provider>
  );
};

export const useTextAdGenerator = () => {
  return useContext(TextAdGeneratorContext);
};
