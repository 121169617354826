import styled from "styled-components";

const activeCategory = {
    containerBg: "#FFF7E8",
    logoBg: "#FFF7E8",
    nameColor: "rgba(115, 74, 0, 0.70)",
    countColor: "#734A00"
}

const inActiveCategory = {
    containerBg: "#FFF",
    nameColor: "rgba(28, 28, 28, 0.60)",
    countColor: "rgba(28, 28, 28, 0.90)"
}

export const List = styled.div`
    display: flex;
    gap: 16px;
    margin: 16px 0;
`;

export const Funnel = styled.div`
    width: 180px;
    height: 122px;
    flex-shrink: 0;
    border-radius: 16px;
    background: ${(props) => props.isActive ? activeCategory.containerBg : inActiveCategory.containerBg};
    cursor: pointer;
    position: relative;
`;

export const LogoContainer = styled.div`
    width: 26px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 4px;
    background: ${(props) => props.bg ?? activeCategory.logoBg};
    
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 14px;
    right: 14px;
`;

export const Logo = styled.img`

`;

export const Name = styled.div`
    color: ${(props) => props.isActive ? activeCategory.nameColor : inActiveCategory.nameColor};
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    position: absolute;
    top: 42px;
    left: 24px;
`;

export const Count = styled.div`
    color: ${(props) => props.isActive ? activeCategory.countColor : inActiveCategory.countColor};
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    position: absolute;
    bottom: 24px;
    left: 24px;
`;