import styled from "styled-components";

export const CampaignWrapper = styled.div`
margin-top: 62px;
padding: 0 46px 0 34px;
display: flex;
flex-direction: column;
align-items: center;
`
export const CampaignLabel = styled.div`
color: rgba(28, 28, 28, 0.50);
font-family: Inter;
font-size: 14px;
font-weight: 400;
`

export const CampaignLabelWrapper = styled.div`
display: flex;
align-items: center;
column-gap: 14px;
    `

export const CampaignHeadWrapper = styled.div`
display: flex;
justify-content: space-between;
width: 1080px;
max-width: 100%;
`
export const SearchWrapper = styled.div`
width: 224px;
height: 44px;
border-radius: 6px;
border: 1px solid #DADFE4;
display: flex;
img{
    width: 16px;
}
`
export const SearchInput = styled.input`
outline: none;
border: none;
background: unset;
padding-left: 16px;
height: 100%;
width: 88%;
`
