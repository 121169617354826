import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { KeywordAnalysisProvider } from './context/keywordanalysis';
import {Helmet} from "react-helmet"

// Layout
import Layout from "../../layout"

// Pages
import KeywordAnalysisDetails from './KeywordAnalysisDetails';
import KeywordAnalysisHome from './KeywordAnalysisHome'
// import { getSearchErrCode } from "../../../helper/getSearchErrCode"

// Not found page
import NotFound from '../NotFoundPage';

// import {appClient} from '../../../../utils/axios-utils'

const KeywordAnalysisComponent = () => {

    // const {searchState, searchDispatch} = useSearch()

    // useEffect(() => {
    //     // Create the interceptor
    //     const interceptor = appClient.interceptors.response.use(
    //       response => {
    //         return response;
    //       },
    //       error => {
    //           console.log(error)
    //         if(error?.config?.url?.includes("/search") && error?.response?.data?.errors[0]?.errCode == "adviews-exhausted"){
    //             searchDispatch({type: 'UPDATE_LIMIT_EXCEEDED', value: true})
    //             searchDispatch({type: 'UPDATE_SEARCH_ERROR', value: getSearchErrCode(error)})
    //         }
    //         return Promise.reject(error);
    //       }
    //     );
    
     
    
    //     // Clean up the interceptor when the component unmounts
    //     return () => {
    //         appClient.interceptors.response.eject(interceptor);
    //     };
    //   }, []);


    return <Routes>
            <Route path='*' element={
                    <NotFound />
            } />
             <Route path="/" element={
                <Layout>
                    <KeywordAnalysisHome />
                </Layout>
            } />
            <Route path="details" element={
                <Layout>
                    <KeywordAnalysisDetails />
                </Layout>
            } />
        </Routes>
};

const KeywordAnalysisProviderWrapper = () => {
    return <KeywordAnalysisProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PPC Keyword Analysis | AdSpyder</title>
                <meta name="title" content="PPC Keyword Analysis | AdSpyder" />
                <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
            </Helmet>
        <KeywordAnalysisComponent/>
    </KeywordAnalysisProvider>
}

export default KeywordAnalysisProviderWrapper