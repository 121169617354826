import React from "react";
import { Container, BudgetContainer, BudgetInputContainer, Currency, AmountInput, StrategyContainer, StrategyDropDownContainer, SelectedStrategy, StrategyDropDown, MatchType } from "./Budget.styled"
import { useAdIntegration } from "../../../../context/AdManagementContext";
import DownArrow from "../../../../../../../../media/Dashboard/drop-down-arrow.svg"

const Budget = () => {

    const {
        newAdManagementState,
        newAdManagementDispatch,
      } = useAdIntegration();

    const strategyList = [
        "maximizeConversions", //maximizeConversions
        "maximizeClicks" //targetSpend
    ]
    return <Container>
        <BudgetContainer>
            <label>Enter your maximum daily budget</label>
            <BudgetInputContainer>
                <Currency>
                   {newAdManagementState.connectedAdAccount[0].currency}
                </Currency>
                <AmountInput placeholder="Enter the daily budget limit" onChange={(e)=> {newAdManagementDispatch({type: "SET_BUDGET_DATA", value: e.target.value})}} value={newAdManagementState.isBudgetData}/>
            </BudgetInputContainer>
        </BudgetContainer>
        <StrategyContainer>
            <label>Big strategy</label>
            <StrategyDropDownContainer>
                <SelectedStrategy 
                    onClick={()=>{
                        newAdManagementDispatch({ type: "SET_OPENED_STRATEGY_DROPDOWN", value: !newAdManagementState.openedStrategyDropDown })
                    }}
                    rotateImg={newAdManagementState.openedStrategyDropDown}
                >
                    {newAdManagementState.selectedStrategy?.length > 0 ? newAdManagementState.selectedStrategy : strategyList[1]}
                    <img
                        src={DownArrow}
                    />
                </SelectedStrategy>
                {newAdManagementState.openedStrategyDropDown && <StrategyDropDown>
                    {strategyList.map((matchType)=>{
                        return <MatchType
                            onClick={() => {
                                newAdManagementDispatch({ type: "SET_SELECTED_STRATEGY", value: matchType })
                                newAdManagementDispatch({ type: "SET_OPENED_STRATEGY_DROPDOWN", value: false })
                            }}
                        >
                            {matchType}
                        </MatchType>
                    })}
                </StrategyDropDown>}
            </StrategyDropDownContainer>
        </StrategyContainer>
    </Container>
}

export default Budget   