import styled from "styled-components";

export const ResultPageContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

export const ResultContainer = styled.div`
    width: calc(100% - 400px - 109px); //280px 
    height: 100%;
    padding-left: 43px;
    padding-top: 44px;

`;

export const Heading = styled.div`
    color: rgba(28, 28, 28, 0.50);
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px; /* 237.5% */
    margin-bottom: 26px;
`;

export const AdTemplatesContainer = styled.div`
    display: flex;
    position: relative;
    gap: 12px;
    margin-bottom: 46px;
    height: 170px;
    border-radius: 4px;
    align-items: center;
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: #F6F7FB;
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        height: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }

`;

export const AdTemplate = styled.div`
    flex-shrink: 0;
    border: ${props => props.isActive ? "1.808px solid #1679DB" : "1px solid #DADFE4"};
    cursor: pointer;
    height: ${props => props.isActive ? "136px" : "124px"};
    img{
        height: 100%;
    }
`;

export const Title = styled.div`
    margin: 18px 0 16px 0;
    color: ${(props) => !props.isActive ? "#1c1c1c" : "#fff"};
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
`;

export const LocationLabel = styled.div`
    color: ${(props) => !props.isActive ? "rgba(28, 28, 28, 0.50)" : "#fff"};
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 2px;
    line-height: 150%;
`;

export const Country = styled.div`
    color: ${(props) => !props.isActive ? "#1c1c1c" : "#fff"};;
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
`;

export const SubNavbar = styled.div`
    display: flex;
    gap: 35px;
    margin-bottom: 24px;
`;

export const NavItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    cursor: pointer;
`;

export const Item = styled.div`
    color: #1C1C1C;
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    gap: 6px;
    line-height: 150%; 
`;

export const Count = styled.div`
    color: rgba(28, 28, 28, 0.50);
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
`;

export const HighlightLine = styled.div`
    width: 100%;
    height: 4px;
    background: #FF711E;
    visibility: ${(props) => props.isActive ? "auto" : "hidden"};
`;

export const PreviewImage = styled.div`
    position: relative;
    /* width: calc(100% - 50px); */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;   
    transform: scale(0.8); 
    > div{
        box-shadow: 0px 0px 12px 1px #8080806b;
    }
    @media screen and (max-width: 1200px) {
        /* > div{ */
            transform: scale(0.7);
        /* } */
    }
    @media screen and (max-width: 850px) {
        /* > div{ */
            transform: scale(0.5);
        /* } */
    }
`;

export const PreviewImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 65%;
    h2{
        font-size: 16px;
        font-weight: 400;
        line-height: 38px;
        color: rgba(28, 28, 28, 0.50);
    }
`;

export const LeftArrow = styled.div`
    width: 24px;
    height: 24px;
    background: #FFF;
    border: solid 1px #DADFE4;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    left: 8px;
    cursor: pointer;
`;

export const RightArrow = styled.div`
    width: 24px;
    height: 24px;
    background: #FFF;
    border: solid 1px #DADFE4;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    right: 8px;
    cursor: pointer;
`;

export const AdTemplateWrapper = styled.div`
    display: flex;
    gap: 12px;
    height: 170px;
    overflow-x: scroll;
    overflow-y: hidden;
    background: white;
    border-radius: 4px;
    padding: 16px 16px;
    border: 1px solid #DADFE4;
    align-items: center;
    width: calc(100% - 40px);
    margin: auto;
    ::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
`;