import React, { useEffect } from "react";
import {
    AboutYouContainer,
    NameContainer,
    NameInputs,
    FirstName,
    RolesList,
    RoleContainer,
    Role,
    CustomRole,
    CustomRoleInput,
    SurveyNavContainer,
    NextButton,
    NextContainer
} from "./index.styled"
import { useSurvey } from "./../../context/survey";
import { useAuth } from "../../../../../../context/auth";
import {appClient} from '../../../../../../utils/axios-utils'
import {useMutation} from "@tanstack/react-query"

const postAboutTeamData = (data) => {
    const config = {
        url: "/app/onboarding/user-info",
        method: "POST",
        data
    }

    return appClient(config)
}

const AboutYou = () => {
    const { surveyState, surveyDispatch } = useSurvey()
    const { authState, authDispatch } = useAuth()

    const roles = [
        { name: "Agency", value: "Agency" },
        { name: "Freelancer", value: "freelancer" },
        { name: "Brand", value: "brand" },
        { name: "Other", value: "" }
    ]

    const updateRole = (role) => {
        surveyDispatch({ type: 'UPDATE_CURRENT_ROLE', value: role.name })
    }

    const temaSize = [
        "1-10",
        "11-50",
        "51-200",
        "201-500",
        "501-1000",
        "1001-5000",
        "5001+"
    ]

    const annualRevenue = [
        "< 10k USD",
        "10k - 100k USD",
        "100k - 1M USD",
        "1M - 10M USD",
        "10M+"
    ]

    const nextLogic = () => {
        surveyDispatch({
            type: 'SET_CURRENT_STEP',
            value: surveyState.currentStep + 1
        })
        surveyDispatch({type: 'SET_PROGRESS', value: (surveyState.currentStep * 33) + 33})
    }

    const {mutate: postAboutTeam} = useMutation(()=>{
        let teamObj = {
            name: surveyState.surveyDetails.name,
            // teamSize: surveyState.surveyDetails.teamSize,
            // annualRevenue: surveyState.surveyDetails.annualRevenue 
        }
        if(surveyState.surveyDetails.role == "Other"){
            teamObj.role = surveyState.surveyDetails.otherRole
        }else{
            teamObj.role = surveyState.surveyDetails.role
        }

        postAboutTeamData(teamObj)

    }, {
        onSuccess: (data) => {
            nextLogic()
        },
        onError: (err) => {

        },
        onMutate: () => {

        }
    })

    const onTeamSizeSelected = (size) => {
        surveyDispatch({ type: 'UPDATE_TEAM_SIZE', value: size })
    }

    const onAnnualRevenueSelected = (revenue) => {
        surveyDispatch({ type: 'UPDATE_ANNUAL_REVENUE', value: revenue })
    }

    const onSubmit = () => {
        postAboutTeam()
    }

    useEffect(() => {
        if (authState.username) {
            surveyDispatch({ type: 'UPDATE_NAME', value: authState.username })
        }
    }, [])

    useEffect(() => {
        if (authState.userInfoAdded) {
            surveyDispatch({
                type: 'SET_CURRENT_STEP',
                value: surveyState.currentStep + 1
            })
            surveyDispatch({type: 'SET_PROGRESS', value: (surveyState.currentStep * 33) + 33})
        }
    })

    return <AboutYouContainer>
        <h2>Profile Setup</h2>
        <p>You’re signing up as {authState.email ? authState.email : authState.formEmail}</p>
        <NameContainer>
            <label>Your name or business name</label>
            <NameInputs>
                <FirstName placeholder="Name" value={surveyState.surveyDetails.name} onChange={(e) =>
                    surveyDispatch({ type: 'UPDATE_NAME', value: e.target.value })} />
                {/* <LastName placeholder="Last name" value={surveyState.surveyDetails.lastName} onChange={(e)=>surveyDispatch({type:'UPDATE_LAST_NAME', value:e.target.value})}/> */}
            </NameInputs>
        </NameContainer>
        <RolesList>
            <label>
                Which of these best describes your role?
            </label>
            <RoleContainer>
                {roles.map((rData, idx) => {
                    return <Role isActive={surveyState.surveyDetails.role == rData.name} onClick={() => updateRole(rData)}>
                        {rData.name}
                    </Role>
                })}
            </RoleContainer>
        </RolesList>
        {surveyState.surveyDetails.role == "Other" && <CustomRole>
            <CustomRoleInput placeholder="your role" onChange={(e) => surveyDispatch({ type: 'UPDATE_OTHER_ROLE', value: e.target.value })} />
        </CustomRole>}
        <SurveyNavContainer role={surveyState.surveyDetails.role} currentStep={surveyState.currentStep} >
                <NextContainer>
                    <NextButton currentStep={surveyState.currentStep} onClick={onSubmit} disabled={!(surveyState.surveyDetails.name.length > 0 && (surveyState.surveyDetails.role == "Other" ? (surveyState.surveyDetails.otherRole.length > 0) : (surveyState.surveyDetails.role.length > 0)))}>
                        Continue
                    </NextButton>
                </NextContainer>
            </SurveyNavContainer>
    </AboutYouContainer>
}

export default AboutYou
