import styled from "styled-components";

export const TotalWrapper = styled.div`
display: flex;
width: 475px;
height: 170px;
border-radius: 16px;
background: #FFF;
padding: 24px;
justify-content: space-between;
`
export const TotalDiv = styled.div`

`
export const TotalCamp = styled.div`
color: #7D7D7D;
font-family: 'Inter';
font-size: 14px;
font-weight: 400;
margin-bottom: 16px;
display: flex;
align-items: center;
gap: 10px;
`
export const TotalCountWrap = styled.div`
display: flex;
column-gap: 16px;
align-items: center;
margin-bottom: 8px;
`
export const TotalCount = styled.div`
color: #1C1C1C;
font-family: Inter;
font-size: 48px;
font-style: normal;
font-weight: 600;
line-height: 56px; /* 116.667% */
letter-spacing: -2px;
`
export const TotalPercent = styled.div`
color: #318048;
font-family: 'Inter';
font-size: 14px;
font-weight: 500;
border-radius: 4px;
background: #E1FAEA;
display: flex;
width: 57px;
height: 25px;
padding: 4px;
column-gap: 2px;
`
export const UpdatedHour = styled.div`
color: rgba(28, 28, 28, 0.30);
font-family: 'Inter';
font-size: 14px;
font-weight: 400;
`
export const ActiveGraph = styled.div`
`