import { Description, GeneratingContainer, Header, Icon, Name, Process, ProcessingContainer, Spinner, Title } from "./index.styled";

import completed from "../../assets/completed.svg"

import { useImageAdGenerator } from "../../context/imageAdGeneration";
import { useEffect } from "react";
import { useImageAdInfo } from "../../hooks/useImageAdInfo";


function GeneratingPage() {
    const { imageAdGeneratorState, imageAdGeneratorDispatch } = useImageAdGenerator()
    const useImageAdInfoHook = useImageAdInfo()

    const getProcessesStatus = () => {
        return (
            <ProcessingContainer>
                {
                    imageAdGeneratorState.generatingProcesses.map((process) => {
                        return (
                            <Process>
                                <Name>{process.name}</Name>
                                {
                                    process.isCompleted
                                        ? <Icon src={completed} alt="complted" />
                                        : <Spinner />
                                }
                            </Process>
                        )
                    })
                }
            </ProcessingContainer>
        )
    }

    useEffect(() => {
        const isPending = imageAdGeneratorState.generatingProcesses.some((process) => process.isCompleted === false)
        if (!isPending)
            imageAdGeneratorDispatch({ type: "UPDATE_RESULT_GENERATED_FLAG", value: true })
    }, [imageAdGeneratorState.generatingProcesses])


    useEffect(() => {
        console.log(imageAdGeneratorState.adType.name,'imageAdGeneratorState.adType.name')
        useImageAdInfoHook.SaveCampaignDetailsMutate({
            category: imageAdGeneratorState.category,
            adGoal: imageAdGeneratorState.adGoal,
            ageGroup: imageAdGeneratorState.targetAudience.ageGroup,
            gender: imageAdGeneratorState.targetAudience.gender,
            brandName: imageAdGeneratorState.brandDetails.name,
            brandDescription: imageAdGeneratorState.brandDetails.description,
            adType: imageAdGeneratorState.adType.name === "Educational or\nInformational" ? "Educational" :imageAdGeneratorState.adType.name
        })

        //programmatically changing the process status
        setTimeout(() => {
            imageAdGeneratorDispatch(({
                type: "UPDATE_PROCESSING_STATUS", value: {
                    index: 2,
                    value: true
                }
            }))
            setTimeout(() => {
                imageAdGeneratorDispatch(({
                    type: "UPDATE_PROCESSING_STATUS", value: {
                        index: 5,
                        value: true
                    }
                }))
                setTimeout(() => {
                    imageAdGeneratorDispatch(({
                        type: "UPDATE_PROCESSING_STATUS", value: {
                            index: 1,
                            value: true
                        }
                    }))
                    setTimeout(() => {
                        imageAdGeneratorDispatch(({
                            type: "UPDATE_PROCESSING_STATUS", value: {
                                index: 4,
                                value: true
                            }
                        }))
                        setTimeout(() => {
                            imageAdGeneratorDispatch(({
                                type: "UPDATE_PROCESSING_STATUS", value: {
                                    index: 3,
                                    value: true
                                }
                            }))
                            setTimeout(() => {
                                imageAdGeneratorDispatch(({
                                    type: "UPDATE_PROCESSING_STATUS", value: {
                                        index: 0,
                                        value: true
                                    }
                                }))
                            }, 100)
                        }, 100)
                    }, 100)
                }, 100)
            }, 100)
        }, 100)
    }, [])

    return (
        <GeneratingContainer>
            <Header>
                <Title>Generating ads...</Title>
                <Description>Please be patient. This process may take a few minutes...</Description>
            </Header>
            {getProcessesStatus()}
        </GeneratingContainer>
    );
}

export default GeneratingPage;