import { useEffect, useState } from 'react';
import ApexChart from 'react-apexcharts';

const ApexChartFn = ({ adDetails, aiHealthDetails, setAvgScore }) => {
  // console.log("basicDetail, aiHealthDetails", adDetails, aiHealthDetails)

  function findScoreByKey(arr, key) {
    const foundItem = arr.find(item => item.value === key);
    return foundItem ? foundItem.score : null;
  }

  let overallScore = findScoreByKey(aiHealthDetails.length > 0 ? aiHealthDetails : adDetails?.adScore, "overallScore")

  setAvgScore(overallScore)

  let graphColor = overallScore <= 7  ?
    overallScore <= 5 ?
      overallScore <= 3 ?
        '#f70d0d' :
        '#a7ad32' :
      '#f78a14' :
    '#25AF60'

    console.log(graphColor)

  const [chartState, setChartState] = useState({
    series: [overallScore ? (overallScore * 10) : 0],
    options: {
      chart: {
        type: 'radialBar',
        offsetY: -20,
        sparkline: {
          enabled: true
        }
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#EBEBEB",
            strokeWidth: '97%',
            margin: 5,

          },
          dataLabels: {
            name: {
              offsetY: 2,
              show: true,
              color: 'rgba(125, 125, 125, 0.50)',
              fontSize: '12px',
              fontWeight: 500,
            },
            value: {
              formatter: function (val) {
                return parseInt(val * 0.1);
              },
              offsetY: -30,
              fontSize: '22px'
            }
          }
        }
      },
      grid: {
        padding: {
          top: -10
        }
      },
      fill: {
        type: 'solid',
        colors: graphColor,
      },
      labels: ['out of 10'],
      stroke: {
        lineCap: 'round',
      },
    },
  });

  useEffect(() => {

  }, []);

  return (
    <div id="chart">
      <ApexChart options={chartState.options} series={chartState.series} type="radialBar" />
    </div>
  );
};

export default ApexChartFn;
