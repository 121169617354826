import React, { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import {
  CardBody,
  Card,
  SaveAdsButton,
  DetailContainer,
  LevelOneDetailContainer,
  AdPreviewContainer,
  AdContentContainer,
  TitleContainer,
  TitleHeader,
  DescriptionContainer,
  DescriptionHeader,
  LevelTwoDetailsContainer,
  CopyButton,
  AdPositionContainer,
  AdPositionHeader,
  AvgPosition,
  LevelTwoRow,
  AmountSpentContainer,
  AmountSpentHeader,
  AmountSpent,
  CountryConainer,
  LastSeenContainer,
  DestinationUrlContainer,
  CountryHeader,
  LastSeenHeader,
  DestinationUrlHeader,
  MapsList,
  FlagLogo,
  Header,
  LogoWrapper,
  TitleWrap,
  Title,
  LinkWrap,
  KeywordWrap,
  DescriptionWrap,
  FooterWrap,
  CountryWrap,
  GoogleIcon,
  Country,
  AmountSpentIconContainer,
  AmountSpentContent,
  SpentAndUrl,
  CountryAndLastSeen,
  MoreCountriesCount,
  Countries,
  NoAvgPositionWrap,
  AvgPositionDiv,
} from "./index.styled";
import GlobeIcon from "../../../../../media/Dashboard/globe.svg";
import CopyImage from "../../../../../media/Dashboard/CopyImg.svg";
import StartImage from "../../../../../media/Dashboard/StarWhite.svg";
import RightUpperGreenArrow from "../../../../../media/Dashboard/RightUpperGreenArrow.svg";
import RedirectOrangeIcon from "../../../../../media/Dashboard/RedirectOrangeIcon.svg";
import amountSpent from "../../../../../media/Dashboard/amountSpent.svg";
import copiedImage from "../../../../../media/Dashboard/copiedImage.svg";

import { countryList } from "../../../../../utils/maps";
import { appClient } from "../../../../../utils/axios-utils";
import locationImg from '../../../../../media/Dashboard/locationCard.svg'
import googleImg from "../../../../../media/Dashboard/google.svg"
import { LineChartGradient } from "./components/AreaChart";
import AiDetails from "./components/AiDetails";
import { CircleFlag } from "react-circle-flags";
import { formatNumber } from "../../../../../utils/formatTotal"
import { SkeletonBox } from "../../../../../globalStyles";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import character from '../../../../../media/Dashboard/Character.svg'
import questionMark from '../../../../../media/Dashboard/Question_Marks.svg'
import shadow from '../../../../../media/Dashboard/Shadow.svg'

const getAdDetails = async (id) => {
  const config = {
    url: `/detail/google/${id}`,
    method: "GET",
  };

  return appClient(config);
};

const adAmountSpent = async (id) => {
  const config = {
    url: `/detail/google/estimated-budget/${id}`,
    method: "GET",
  };

  return appClient(config);
};
const tempCountryList = [
  'Afghanistan',
  'Aland Islands',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra'
]
const GoogleDetail = ({ basicDetail, highlightWords, onSave, advancedFilters }) => {
  console.log(advancedFilters)
  const navigate = useNavigate()

  const [adDetails, setAdDetails] = useState(basicDetail);
  const [detailsLoading, setDetailsLoading] = useState(true);
  const [copyState, setCopyState] = useState(false);
  const [copyContent, setCopyContent] = useState(false);
  const [copyURL, setCopyURL] = useState(false);
  const [adAmount, setAdAmount] = useState({})

  
  const getFlagEmoji = (countryName) => {
    let countryCode = countryList[countryName];
    if (countryCode) {
      return (
        <FlagLogo>
          <CircleFlag countryCode={countryCode.toLowerCase()} height="24" />
        </FlagLogo>
      );
    }
  };

  const { mutate: adsDetailsMutate, isLoading: isDetailsLoading } = useMutation(
    getAdDetails,
    {
      onSuccess: (data) => {
        setAdDetails(data);
        setDetailsLoading(false);
      },
      onError: (e) => {
        console.log("err", e);
        setDetailsLoading(false);
        setAdDetails({});
      },
    }
  );

  const { mutate: adAmountSpentMutate, isLoading: isAdAmountSpentLoading } = useMutation(
    adAmountSpent,
    {
      onSuccess: (data) => {
        setAdAmount(data)
      },
      onError: (e) => {
        console.log("err", e);
        setAdAmount({})
      },
    }
  );

  const convertTimestamp = (timestamp) => {
    // Create a new Date object based on the timestamp
    var date = new Date(timestamp);

    // Get the month name
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var month = monthNames[date.getMonth()];

    // Get the year
    var year = date.getFullYear();

    // Assemble the formatted date string
    var formattedDate = date.getDate() + " " + month + " " + year;

    return formattedDate;
  };

  useEffect(() => {
    adsDetailsMutate(basicDetail.id);
    adAmountSpentMutate(basicDetail.id)
  }, []);
  
const analyzeDomian = () =>{
  const url = new URL(basicDetail?.url);
  const domain = url.hostname;
  navigate(`/domain-overview/details?domain=${domain}&country=global`)
}
console.log(Math.round(basicDetail.position) + 1,'{Math.round(basicDetail.position) + 1')
  if (detailsLoading) {
    return <h2>Loading</h2>;
  } else {
    return (
      <DetailContainer 
      >
        <LevelOneDetailContainer>
          <AdPreviewContainer>
            <SaveAdsButton onClick={() => onSave(basicDetail)}>
              Save
            </SaveAdsButton>
            <Card>
                <CardBody>
                <Header>
                    <LogoWrapper>
                        <img src={GlobeIcon} />
                    </LogoWrapper>
                    <TitleWrap> 
                        <Title>{basicDetail.domainId}</Title>
                        <LinkWrap>{basicDetail.url}</LinkWrap>
                    </TitleWrap>
                </Header>
                <KeywordWrap>{basicDetail.title}</KeywordWrap>
                <DescriptionWrap>
                    {`${convertTimestamp(basicDetail.lastSeen)} __ ${basicDetail.content}`}
                </DescriptionWrap>
                <FooterWrap>
                    <CountryWrap>
                    <img src={locationImg}/>
                        <Country>{basicDetail.country.length == 1 ? basicDetail.country[0] : basicDetail.country.length > 1 ? basicDetail.country[0] + " + " + (basicDetail.country.length - 1) : "--"}</Country>
                    </CountryWrap>
                    <GoogleIcon>
                        <img src={googleImg}/>
                    </GoogleIcon>
                </FooterWrap>
                </CardBody>
            </Card>
          </AdPreviewContainer>
          <AdContentContainer>
            <h2>Ad Details</h2>
            <TitleContainer>
              <TitleHeader>
                <h3>Title</h3>
                <CopyButton
                  onClick={() => {
                    !copyState && setCopyState(true)
                    navigator.clipboard.writeText(basicDetail.title);
                    !copyState && setTimeout(() => {
                      setCopyState(false)
                    }, 3000);
                  }}
                  data-tooltip-id="title-tooltip"
                  data-tooltip-content={copyState ? 'Copied' : 'Copy'}
                >
                  {!copyState ? <img src={CopyImage} />:<img src={copiedImage} />}
                  <Tooltip id="title-tooltip" 
                  noArrow={true} 
                  place={'bottom'}  
                  style={{ backgroundColor: "#101828", color: "#FFF",fontFamily: 'Inter', fontSize: '12px', borderRadius: '8px',
                  fontWeight: 600, height: '34px', padding:'8px 12px',display: 'flex', alignItems: 'center' }}/>
                </CopyButton>
              </TitleHeader>
              <p>{basicDetail.title}</p>
            </TitleContainer>
            <DescriptionContainer>
              <DescriptionHeader>
                <h3>Content</h3>
                <CopyButton
                  onClick={() => {
                    !copyContent && setCopyContent(true)
                    navigator.clipboard.writeText(basicDetail.content);
                    !copyContent && setTimeout(() => {
                      setCopyContent(false)
                    }, 3000);
                  }}
                  data-tooltip-id="content-tooltip"
                  data-tooltip-content={copyContent ? 'Copied' : 'Copy'}
                >
                  {!copyContent ? <img src={CopyImage} />:<img src={copiedImage} />}
                  <Tooltip id="content-tooltip" 
                  noArrow={true} 
                  place={'bottom'}  
                  style={{ backgroundColor: "#101828", color: "#FFF",fontFamily: 'Inter', fontSize: '12px', borderRadius: '8px',
                  fontWeight: 600, height: '34px', padding:'8px 12px',display: 'flex', alignItems: 'center' }}/>
                </CopyButton>
              </DescriptionHeader>
              <p>{basicDetail.content}</p>
            </DescriptionContainer>
          </AdContentContainer>
        </LevelOneDetailContainer>
        <LevelTwoDetailsContainer>
          <h2>Ad Analytics</h2>
          <LevelTwoRow>
            <AdPositionContainer>
              <AdPositionHeader>
                <h3>Average Position</h3>
                {basicDetail.position + 1 < 3 && (
                  <p>
                    <img src={StartImage} />
                    <span>Top performed</span>
                  </p>
                )}
              </AdPositionHeader>
              {basicDetail.position && basicDetail.position != '-1' ? <>
              <AvgPosition>
                0{Math.round(basicDetail.position) + 1}
                <img src={RightUpperGreenArrow} />
              </AvgPosition>
              <LineChartGradient adDetails={adDetails} />
              </> :<NoAvgPositionWrap>
                <p> 
                Data Unavailable
                </p>
                <AvgPositionDiv>
                  <img src={character} className="characterImg"/>
                  <img src={questionMark} className="questionImg"/>
                  <img src={shadow} className="shadowImg"/>
                </AvgPositionDiv>
                </NoAvgPositionWrap>}
            </AdPositionContainer>
            <SpentAndUrl>
                  <AmountSpentContainer>
                    <AmountSpentIconContainer>
                      <img src={amountSpent} alt="icon" draggable="false" />
                    </AmountSpentIconContainer>
                    <AmountSpentContent>
                      <AmountSpentHeader>Amount Spent</AmountSpentHeader>
                      {isAdAmountSpentLoading 
                        ? <SkeletonBox height={27} width={164} />
                        : (
                          <AmountSpent>
                            {(adAmount?.low && adAmount?.high) ? `$${formatNumber(adAmount.low)} - $${formatNumber(adAmount.high)}`: "-"}
                          </AmountSpent>
                        )
                      }
                    </AmountSpentContent>
                  </AmountSpentContainer>
                  <DestinationUrlContainer>
                    <DestinationUrlHeader>
                        <h3>
                          Destination URL
                          <p onClick={analyzeDomian}>
                            <img src={RedirectOrangeIcon} />
                            analyze domain
                          </p>                       </h3>
                        <CopyButton
                          onClick={() => {
                            !copyURL && setCopyURL(true)
                            navigator.clipboard.writeText(adDetails.url);
                            !copyURL && setTimeout(() => {
                              setCopyURL(false)
                            }, 3000);
                          }}
                          data-tooltip-id="url-tooltip"
                  data-tooltip-content={copyURL ? 'Copied' : 'Copy'}
                        >
                           {!copyURL ? <img src={CopyImage} />:<img src={copiedImage} />}
                           <Tooltip id="url-tooltip" 
                  noArrow={true} 
                  place={'bottom'}  
                  style={{ backgroundColor: "#101828", color: "#FFF",fontFamily: 'Inter', fontSize: '12px', borderRadius: '8px',
                  fontWeight: 600, height: '34px', padding:'8px 12px',display: 'flex', alignItems: 'center' }}/>
                  {/* {hoverURLState && (!copyURL ? <TooltipContainer>Copy</TooltipContainer> : <TooltipContainer>Copied</TooltipContainer>)} */}
                        </CopyButton>
                    </DestinationUrlHeader>
                    <p>{basicDetail?.url}</p>
                  </DestinationUrlContainer>
            </SpentAndUrl>
            <CountryAndLastSeen>
                <CountryConainer>
                  <CountryHeader>
                    <h3>Country</h3>
                  </CountryHeader>
                  <MapsList>
                    {/* basicDetail.country.map */basicDetail.country.map((c, idx) => {
                      if (basicDetail.country.length > 5 ? idx < 5 : true) {
                        return getFlagEmoji(c);
                      }
                    })}
                    {basicDetail.country.length > 5 && <MoreCountriesCount>{`+${basicDetail.country.length - 5}`}</MoreCountriesCount>}
                  </MapsList>
                  <Countries>
                    {basicDetail.country.length == 1
                      ? basicDetail.country[0]
                      : basicDetail.country.length > 1
                      ? basicDetail.country.join().length > 42 ? basicDetail.country.join().slice(0, 40) + "..." : basicDetail.country.join()
                      : ""}
                  </Countries>
                </CountryConainer>
                <LastSeenContainer>
                  <LastSeenHeader>
                    <h3>Last Seen</h3>
                  </LastSeenHeader>
                  <div>
                    <p>
                      {convertTimestamp(basicDetail.lastSeen).split(" 20")[0]}
                    </p>
                    <span>
                      20{convertTimestamp(basicDetail.lastSeen).split(" 20")[1]}
                    </span>
                  </div>
                </LastSeenContainer>
            </CountryAndLastSeen>
          </LevelTwoRow>
        </LevelTwoDetailsContainer>
        <AiDetails
          basicDetail={basicDetail}
          adDetails={adDetails}
          advancedFilters={advancedFilters}
          //  getAdDetails={getAdDetails}
        />
        
          {/* <AdGenie
            basicDetail={basicDetail}
            adDetails={adDetails}
            //  getAdDetails={getAdDetails}
          /> */}
      </DetailContainer>
    );
  }
};

export default GoogleDetail;
