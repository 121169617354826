import React, { useEffect, useState } from 'react'
import NoResultsLogo from "../../../../../../../media/Dashboard/noresults.svg"
import { useRequestData } from "../../../../../../hooks/useRequestData"

// Styles
import {
    CountriesList,
    TagsList,
    TagsContainer,
    CountriesContainer,
    AvgPosition,
    DateRange,
    AdDetailsBody,
    CloseDetails,
    DetailsHeader,
    DetailsContainer,
    FooterRight,
    AdCountries,
    AdDate,
    FooterLeft,
    CardFooter,
    CardBody,
    FloatingButtonsContainer,
    Card,
    CardsList,
    ResultsContainer,
    ResultsWraper,
    BusinessName,
    DetailsChannelDetails,
    LogoContainer,
    ChannelName,
    DetailsVideoContainer,
    DetailsImageContainer,
    DetailsDescription,
    Headline,
    DestinationUrl,
    ShortUrl,
    PublishedOnDetails,
    CallToAction,
    MediaType,
    NoResults,
    RequestDataBtn
} from "./index.styled"

import { useReddit } from "../../context/reddit"
import SearchLoader from '../../../../../../HOC/SearchLoader'


import CloseBtn from "../../../../../../../media/Dashboard/detailsPaneClose.svg"
import TickImg from '../../../../../../../media/Dashboard/tick-grey.svg'


import DateIcon from "../../../../../../../media/Images/DateIcon.svg"
import CountryIcon from "../../../../../../../media/Images/CountryIcon.svg"
import LimitExceededPopup from '../../../../../../components/limitExceededPopup'
import PlatformRestrictedPopup from '../../../../../../components/platformRestrictionPopup'
import { useAuth } from '../../../../../../../context/auth'
import { useSearch } from '../../../context/search'
import SaveAdsPopup from '../../../../../../components/SaveAdsPopup'
import { useSaveAds } from '../../../../../../hooks/useSaveAds'

const convertTimestamp = (timestamp) => {
    if (!timestamp) {
        return "-"
    }
    // Create a new Date object based on the timestamp
    var date = new Date(Number(timestamp));

    // Get the month name
    var monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    var month = monthNames[date.getMonth()];

    // Get the year
    var year = date.getFullYear();

    // Assemble the formatted date string
    var formattedDate = date.getDate() + ' ' + month + ' ' + year;

    return formattedDate;
}

const CardElement = ({ ad, idx }) => {
    const { authState, authDispatch } = useAuth()
    const useSaveAdsHook = useSaveAds()

    const { redditState, redditDispatch } = useReddit()
    const embededLink = ad.postUrl ? `${ad.postUrl.replace("www.reddit.com", "www.redditmedia.com")}?ref_source=embed&amp;ref=share&amp;embed=true` : "";

    const toggleDetailsModal = (ad) => {
        if ((redditState.adDetails.id !== ad.id) && redditState.detailsModal) {
            redditDispatch({ type: 'SET_AD_DETAILS', value: ad })
        } else {
            redditDispatch({ type: 'SET_AD_DETAILS', value: ad })
            redditDispatch({ type: 'UPDATE_DETAILS_MODAL', value: !redditState.detailsModal })
        }

    }

    const onSave = (ad) => {
        useSaveAdsHook.SaveAdMutate({
            projectId: authState.defaultProjectInfo.projectId,
            adId: `${ad.id}`,
            platform: "google"
        })
        // authDispatch({type:'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'reddit'})
        // authDispatch({type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id})
        // authDispatch({type:'UPDATE_SAVE_AD_POPUP', value: true})
    }

    return <Card key={
        ad.id
    }>
        <CardBody>

            <FloatingButtonsContainer>
                {/* <Favorite isFav={idx == 1} onClick={()=>{
                    onSave(ad)}}>
                    <img src={authState.favAdsList["reddit"]?.includes(ad.id) ? FavouriteIconActive : FavorietIcon}/>
                </Favorite> */}
                {/* |
                <DropdownIcon>
                    <span/>
                </DropdownIcon> */}
            </FloatingButtonsContainer>
            <iframe id="reddit-embed" src={embededLink} sandbox="allow-scripts allow-same-origin allow-popups" style={{ border: "none" }} height="480" width="480" scrolling="yes"></iframe>
        </CardBody>

        <CardFooter>
            <FooterLeft>
                <AdDate>
                    {ad.firstSeen && ad.lastSeen && <img src={DateIcon} />}{convertTimestamp(ad.date)}
                </AdDate>
                <AdCountries>
                    {ad.country.length > 0 && <img src={CountryIcon} />}{ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
                </AdCountries>
            </FooterLeft>
            <FooterRight>
                {/* <DetailsButton onClick={()=>toggleDetailsModal(ad)}>
                    More details
                </DetailsButton> */}
            </FooterRight>
        </CardFooter>
    </Card>
}

const DetailsElement = () => {
    const { redditState, redditDispatch } = useReddit()

    const [height, setHeight] = useState('')
    const [width, setWidth] = useState('')
    useEffect(() => {
        if (redditState.adDetails.image) {
            const img = new Image();
            img.onload = function () {
                // setTimeout(() => {
                setHeight(img.height)
                setWidth(img.width)
                // }, 1000);
            }
            img.src = `https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${redditState.adDetails.id}`;
        }
        if (redditState.adDetails.video) {
            var video = document.createElement('video');
            video.src = `https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${redditState.adDetails.id}`;

            video.addEventListener('loadedmetadata', function () {
                setHeight(video.videoHeight)
                setWidth(video.videoWidth)
            });
        }
    }, [])

    return <DetailsContainer>
        <DetailsHeader>
            <h3>Ad details</h3> <CloseDetails onClick={() => { redditDispatch({ type: 'UPDATE_DETAILS_MODAL', value: false }) }}><img src={CloseBtn} /></CloseDetails>
        </DetailsHeader>
        <AdDetailsBody>
            <DetailsChannelDetails>
                <LogoContainer>
                    <img src={`https://aws-adspyder-logos.s3.ap-south-1.amazonaws.com/${redditState.adDetails.id}`} />
                </LogoContainer>
                <ChannelName>
                    {redditState.adDetails.title}
                    <span>Promoted</span>
                </ChannelName>
            </DetailsChannelDetails>
            {redditState.adDetails.image && <DetailsImageContainer>
                <img onLoad={(e) => {
                    setHeight(e.height)
                    setWidth(e.width)
                }} src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${redditState.adDetails.id}`} />
            </DetailsImageContainer>}
            {redditState.adDetails.video && <DetailsVideoContainer>
                <video src={`https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${redditState.adDetails.id}`} preload="auto" controls loop style={{ width: "100%", height: "100%" }} />
            </DetailsVideoContainer>}
            <BusinessName>
                <label>Business name:</label>{redditState.adDetails.title}
            </BusinessName>
            <DetailsDescription>
                <label>Introductory text:</label>{redditState.adDetails.content}
            </DetailsDescription>
            <Headline>
                <label>Headline:</label>{redditState.adDetails.innerdescription ? redditState.adDetails.innerdescription : "-"}
            </Headline>
            <DestinationUrl>
                <label>Destination URL:</label><a href={redditState.adDetails.url}>{redditState.adDetails.url}</a>
            </DestinationUrl>
            <ShortUrl>
                <label>Short URL:</label>{redditState.adDetails.innertitle ? <a href={redditState.adDetails.innertitle}>{redditState.adDetails.innertitle}</a> : "-"}
            </ShortUrl>
            <CallToAction>
                <label>Call to action:</label>{redditState.adDetails.cta ? redditState.adDetails.cta : "-"}
            </CallToAction>
            <MediaType>
                <label>Media type:</label>{redditState.adDetails.video ? "Video" : "Image"}
            </MediaType>
            <MediaType>
                <label>Media size:</label>{width + " X " + height}
            </MediaType>
            <DateRange>
                <label>Date Range:</label>27 Jan 2021 - 21 Nov 2022
            </DateRange>
            <AvgPosition>
                <label>Average position:</label>51
            </AvgPosition>
            {redditState.adDetails.tags && <TagsContainer>
                <label>Tags:</label><TagsList>{redditState.adDetails.tags.map((tag, idx) => {
                    return <span>{tag}</span>
                })}</TagsList>
            </TagsContainer>}
            <PublishedOnDetails>
                <label>Published on:</label>{convertTimestamp(redditState.adDetails.publishedOn)}
            </PublishedOnDetails>
            {/* {redditState.adDetails.country && <CountriesContainer>
            <label>Tags:</label><CountriesList>{redditState.adDetails.country.map((tag,idx)=>{
                return <span>{tag}</span>
            })}</CountriesList>
        </CountriesContainer>} */}
            {redditState.adDetails.country && <CountriesContainer>
                <label>Tags:</label><CountriesList>
                    <span>{redditState.adDetails.country}</span>
                </CountriesList>
            </CountriesContainer>}
        </AdDetailsBody>
    </DetailsContainer>
}

const Results = () => {
    const { redditState, redditDispatch } = useReddit()
    const useRequestDataHook = useRequestData()

    const { searchState, searchDispatch } = useSearch()
    const { authState, authDispatch } = useAuth()

    const onDataRequest = () => {
        if (!useRequestDataHook.isDataRequested) {
            useRequestDataHook.requestDataMutate({ id: redditState.queryId })
        }
    }

    if (authState.isRestrictedPopup) {
        return <ResultsContainer restricted={true} detailsModal={redditState.detailsModal}>
            <PlatformRestrictedPopup err={searchState.searchError} />
        </ResultsContainer>
    }

    if (searchState.limitExceeded) {
        return <ResultsContainer restricted={true} detailsModal={redditState.detailsModal}>
            <LimitExceededPopup err={searchState.searchError} />
        </ResultsContainer>
    }

    return <ResultsContainer resultLoading={redditState.loadingResults} noResults={redditState.noResults} detailsModal={redditState.detailsModal}>
        {redditState.loadingResults ? <div className='loader'>
            <SearchLoader />
        </div> : redditState.noResults ? <NoResults>
            <img src={NoResultsLogo} />
            <h2>No Results Found</h2>
            <p>We're sorry, but we don't currently have the data matching your search parameters. Our database is constantly updated, so please try again later or try modifying your search.</p>
            <RequestDataBtn
                // dataRequested={authState.isDataRequested}
                isDisabled={useRequestDataHook.isRequestDataLoading || authState.isDataRequested}
                onClick={onDataRequest}
            >
                {authState.isDataRequested ? <p>Your request for data crawl has been accepted<img src={TickImg} /></p> : <p>{useRequestDataHook.isRequestDataLoading ? "Requesting..." : "Request data crawl for this query"}</p>}
            </RequestDataBtn>
        </NoResults> :
            <ResultsWraper>
                {authState.saveAdsPopupOpen && <SaveAdsPopup />}
                <CardsList detailsModal={redditState.detailsModal}> {
                    redditState.ads && redditState.ads.length > 0 && redditState.ads.map((ad, idx) => {
                        return <CardElement ad={ad} idx={idx} />
                    })
                } </CardsList>
                {
                    redditState.detailsModal && <DetailsElement />
                }
            </ResultsWraper>}
    </ResultsContainer>
}

export default Results
