import React from "react";

import Img1 from "../../../../media/Dashboard/welcomeImg1.svg"
import Img2 from "../../../../media/Dashboard/welcomeImg2.svg"
import Img3 from "../../../../media/Dashboard/welcomeImg3.svg"
import {useNavigate} from 'react-router-dom'

import BgImage from "../../../../media/Dashboard/welcomeBg.png"

import {useAuth} from "../../../../context/auth"

import {appClient} from '../../../../utils/axios-utils'
import {useMutation} from "@tanstack/react-query"

import styled from "styled-components";

const Container = styled.div`
    height: 100vh;
    display: flex;
    background: url(${BgImage});
`;

const MessageContainer = styled.div`
    width: 480px;
    margin: auto;
    background: #FFFFFF;
    padding: 60px;
    box-shadow: 0px 5px 20px #0000000F;
    border-radius: 16px;
    h2{
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        color: #505050;
        span{
            color: #FF711E;
        }
    }
    p{
        margin-top: 20px;
        margin-bottom: 32px;
        line-height: 26px;
        font-size: 16px;
        font-weight: 300;
        color: #434343;
        span{
            color: #434343;
            font-weight: 500;
        }
    }
`;

const Feature = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    img{
        margin-right: 16px;
    }
`;

const FeaturesList = styled.div`

`;

const FeatureContent = styled.div`
    h3{
        font-size: 14px;
        line-height: 30px;
        color: #055CC3;
    }
    p{
        margin: 0;
        color: #505050;
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
    }
`;

const DiscoverButton = styled.button`
    border-radius: 8px;
    background-color: #FF711E;
    color: white;
    font-size: 14px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    margin-top: 12px;
    width: 100%;
    height: 40px;
    :hover {
        filter: brightness(90%);
    }
`;

const updateNotified = (data) => {
    const config = {
        url: "/notification/notified",
        method: "GET"
    }

    return appClient(config)
}

const Welcome = () => {
    const {authState, authDispatch} = useAuth()
    const navigate = useNavigate()

    const {mutate: submit} = useMutation(updateNotified, {
        onSuccess: (data) => {
            authDispatch({type:'UPDATE_NOTIFIED', value: true})
            navigate("/loginsurvey")
        },
        onError: (err) => {

        },
        onMutate: () => {
        }
    })
    
    return <Container>
                <MessageContainer>
                <h2>
                    Welcome to the New <span>AdSpyder,</span>
                </h2>
                <h2>{authState.username}</h2>
                <p>
                    We've reimagined your experience with a focus on <span>simplicity, performance,</span> and <span>innovative features.</span> Here's what you'll get with the new AdSpyder,
                </p>
                <FeaturesList>
                    <Feature>
                        <img src={Img1}/>
                        <FeatureContent>
                            <h3>
                            Improved User Experience
                            </h3>
                            <p>
                            Enjoy a seamless user journey with our revamped UI/UX. Designed for intuitive navigation and simplicity
                            </p>
                        </FeatureContent>
                    </Feature>
                    <Feature>
                        <img src={Img2}/>
                        <FeatureContent>
                            <h3>
                            Enhanced Search Filters
                            </h3>
                            <p>
                            With the introduction of new, powerful search filters, find the perfect ad copy that suits your needs.  
                            </p>
                        </FeatureContent>
                    </Feature>
                    <Feature>
                        <img src={Img3}/>
                        <FeatureContent>
                            <h3>
                            Superior Ad Quality
                            </h3>
                            <p>
                            Enjoy a seamless user journey with our revamped UI/UX. Designed for intuitive navigation and simplicity
                            </p>
                        </FeatureContent>
                    </Feature>
                </FeaturesList>
                <DiscoverButton onClick={submit}>
                    Discover the New AdSpyder
                </DiscoverButton>
            </MessageContainer>
        </Container>
}

export default Welcome