import React from "react";
import GoogleCard from "../components/GoogleCard";
import BingCard from "../components/BingCard";
import YoutubeCard from "../components/YoutubeCard";
import FacebookCard from "../components/FacebookCard";
import LinkedinCard from "../components/Linkedin";
import RedditCard from "../components/RedditCard";
import GoogleEcomCard from "../components/GoogleEcomCard";
import BingEcomCard from "../components/BingEcomCard";
import FlipkartCard from "../components/FlipkartCard";

const AdCard = ({ad, ToggleSelectedAds}) => {
    if(ad.platform == "google"){
        return <GoogleCard ToggleSelectedAds={ToggleSelectedAds} ad={ad}/>
    }else if(ad.platform == "bing"){
        return <BingCard ToggleSelectedAds={ToggleSelectedAds} ad={ad}/>
    }else if(ad.platform == "youtube"){
        return <YoutubeCard ToggleSelectedAds={ToggleSelectedAds} ad={ad}/>
    }else if(ad.platform == "reddit"){
        return <RedditCard ToggleSelectedAds={ToggleSelectedAds} ad={ad}/>
    }else if(ad.platform == "facebook"){
        return <FacebookCard ToggleSelectedAds={ToggleSelectedAds} ad={ad}/>
    }else if(ad.platform == "linkedin"){
        return <LinkedinCard ToggleSelectedAds={ToggleSelectedAds} ad={ad}/>
    }else if(ad.platform == "google-ecom"){
        return <GoogleEcomCard ToggleSelectedAds={ToggleSelectedAds} ad={ad} />
    }else if(ad.platform == "bing-ecom"){
        return <BingEcomCard ToggleSelectedAds={ToggleSelectedAds} ad={ad}/>
    }else if(ad.platform == "flipkart"){
        return <FlipkartCard ToggleSelectedAds={ToggleSelectedAds} ad={ad}/>
    }
    // else if(ad.platform == "amazon"){
    //     return <GoogleCard />
    // }else if(ad.platform == "display"){
    //     return <GoogleCard />
    // }
}

export default AdCard