import React from 'react';
import { SliderMainContainer, SliderContainer, RangeInputContainer, RangeInput, AudienceValue } from './TotalAudienceSlider.styled';



const TotalAudienceSlider = ({ value, onChange }) => {
    const formatValue = (val) => {
        if (val >= 1000000) return '1,000,000+';
        return val.toLocaleString();
    };

    return (
        <SliderMainContainer>
            <p>Total Audience :</p>
            <SliderContainer>
                <RangeInputContainer>
                    <RangeInput
                        type="range"
                        min={1000}
                        max={1000000}
                        value={value}
                        onChange={(e) => onChange(Number(e.target.value))}
                    />
                </RangeInputContainer>
                <AudienceValue>{formatValue(value)}</AudienceValue>
            </SliderContainer>
        </SliderMainContainer>
    );
};

export default TotalAudienceSlider;