import styled from "styled-components";

export const BasicAnalysisContainer = styled.div`
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 32px;
    display: flex;
`;

export const PieChartContainer = styled.div`
    width: 381px;
    /* margin-left: 12px; */
    padding: 16px 0px 0px 20px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(242, 242, 242);
    h4{
        font-size: 14px;
        font-weight: 400;
        color: #2E2E2E;
    }
`;

export const PieChartAndTableContainer = styled.div`
    margin-top: 16px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
`;

export const TopKeywordsContainer = styled.div`
    width: 264px;
    height: 240px;
    /* margin-left: 12px; */
    padding: 16px 20px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(242, 242, 242);
`

export const TopKeywordHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    h4{
        font-size: 14px;
        color: #2E2E2E;
        font-weight: 400;
    }
`;

export const HeatMapContainer = styled.div`
    width: 286px;
    height: 240px;
    /* margin-left: 12px; */
    padding: 16px 20px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(242, 242, 242);
    h4{
        font-size: 14px;
        color: #2E2E2E;
        font-weight: 400;
        margin-bottom: 16px;
    }
`;