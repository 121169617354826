import React from "react";
import { AdCopiesName, AdCopiesContainer, AdCopiesDiv, AdCopiesWrapper, FilterWrap, ToggleArrowWrap } from "./index.styled";
import PlatformDropdown from './components/PlatformDropDown/index'
import { getSearchParams } from "../../../../../../utils/helper"
import { useLocation } from "react-router-dom"
import upToggleArrow from '../../../../../../media/Dashboard/upToggleArrow.svg'
import AdCopies from "../AdsCopies";
import { useLandingPageAnalysis } from "../../../context/landingPageAnalysis";

const AdCopiesList = () => {
    const location = useLocation()
    const searchParamFromState = getSearchParams(location.search)
    const {landingPageAnalysisState, landingPageAnalysisDispatch} = useLandingPageAnalysis()

    const {
        url
    } = searchParamFromState

    {Object.entries(landingPageAnalysisState?.adsAvailability || {}).map(([platform, value], index) => {
        if (value) {
            landingPageAnalysisDispatch({ type: 'SET_FILTER_STATE', value: true })
        }})}
    return <AdCopiesWrapper>
        <AdCopiesContainer>
            <AdCopiesDiv>
                <AdCopiesName>Ad Copies</AdCopiesName>
                <FilterWrap>
                    {landingPageAnalysisState.filterState ?
                    <>
                    <div>Filter:</div>
                    <PlatformDropdown domainData={url}/>
                    </>:<AdCopiesName>No Ads Found</AdCopiesName>}
                    <ToggleArrowWrap onClick={()=>landingPageAnalysisDispatch({ type: 'SET_TOGGLE_COPIES', value: false })}>
                        <img src={upToggleArrow}/>
                    </ToggleArrowWrap>
                </FilterWrap>
            </AdCopiesDiv>
            {landingPageAnalysisState.filterState && <AdCopies />}
        </AdCopiesContainer>
    </AdCopiesWrapper>
}

export default AdCopiesList