import { Container, Keyword, Keywords, RelatedKeywordsContainer, Title } from "./index.styled";
import { useMutation } from "@tanstack/react-query"
import { appClient } from "../../../../../utils/axios-utils"
import { useOverview } from "../../context/overview";
import { useEffect, useRef } from "react";
import { LineLoader } from "../index.styled";
import { useNavigate } from "react-router-dom";
const keywordsList = [
    "hello", "learn more", "Thyrocare salem", "Learn more hgjhj", "Learn"
]
function RelatedKeywords() {
    const { overviewState: { projectDetails, relatedKeywords, isOnboardingPopupActive }, overviewDispatch } = useOverview()
    const navigate = useNavigate()
    const listContainerRef = useRef(null)
    const getRelatedKeywords = (data) => {
        const config = {
            url: `/dashboard-overview/top-ads/keywords`,
            method: "POST",
            data
        }
        return appClient(config)
    }
    const { mutate: getRelatedKeywordsMutate, isLoading } = useMutation(getRelatedKeywords, {
        onSuccess: (data) => {
            overviewDispatch({
                type: "SET_RELATED_KEYWORDS",
                value: data.keywords
            })
        },
        onError: (e) => console.log(e)
    })

    const handleKeywordClick = (keyword) => {
        navigate(`/keyword-overview/details?keyword=${keyword}&country=global`)
    }

    const enableScrolling = () => {
        listContainerRef.current.style.overflowY = "auto"
    }

    const disableScrolling = () => {
        listContainerRef.current.style.overflowY = "hidden"
    }

    useEffect(() => {
        if (projectDetails?.projectId)
            getRelatedKeywordsMutate({ projectId: projectDetails.projectId })
    }, [projectDetails?.projectId, isOnboardingPopupActive])
    if(isLoading || isOnboardingPopupActive){
        return <LineLoader width={338} height={232} />
    }
    return (
        <RelatedKeywordsContainer onMouseEnter={enableScrolling} onMouseLeave={disableScrolling} >
                <Title>Related keywords</Title>
                <Container ref={listContainerRef} >
                    <Keywords>
                        {
                            relatedKeywords.map((keyword) => {
                                return (
                                    <Keyword onClick={() => handleKeywordClick(keyword)} >{keyword}</Keyword>
                                )
                            })
                        }
                    </Keywords>
                </Container>
        </RelatedKeywordsContainer>
    );
}

export default RelatedKeywords;