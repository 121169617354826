import React, { useEffect } from "react";
import {
  ContentHeading,
  KeywordContainer,
  TableContainer,
  TableHeader,
  NoHeader,
  QueryHeader,
  TableBody,
  Row,
  NumberData,
  QueryData,
  RightContainer,
  SimilarKeywordsContainer,
  KeywordDomainContainer,
  SelectedKeyword,
  SimilarKeywordsList,
  SimilarKeyword,
  SimilarKeywordsDomainList,
  SimilarKeywordDomain,
  ResetButton,
  ViewAdsButton,
  SearchNow,
} from "./index.styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useAccount } from "../../context/account";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";

import _static from "../../../../../config/static";
import { useGetAccount } from "../../../../hooks/useGetAccount";

const { frontendUrl } = _static;

// const UserSummaryKeywords = async () => {
//     const config = {
//         url: "/account/keyword-searches",
//         method: "GET"
//     }

//     return appClient(config)
// }

// const UserSimilarKeywords = async (data) => {
//     const config = {
//         url: "/keyword/related",
//         method: "POST",
//         data
//     }

//     return appClient(config)
// }

// const UserKeywordsDomain = async (data) => {
//     const config = {
//         url: "/keyword/domains",
//         method: "POST",
//         data
//     }

//     return appClient(config)
// }

const KeywordSummary = () => {
  const { accountState, accountDispatch } = useAccount();
  const navigate = useNavigate();
  const location = useLocation();
  const accountInfoHook = useGetAccount();

  const onKeywordClick = (data) => {
    accountDispatch({ type: "UPDATE_SELECTED_KEYWORD", value: data });
    accountInfoHook.getUserSimilarKeywords({ keyword: data });
    accountInfoHook.getUserKeywordsDomains({ keyword: data });
  };

  const onClearAll = () => {
    accountDispatch({ type: "UPDATE_SELECTED_KEYWORD", value: "" });
  };

  // const { mutate: getUserKeywordSummary, isLoading: isKeywordSummaryLoading } = useMutation(UserSummaryKeywords, {
  //     onSuccess: (data) => {
  //         accountDispatch({type:'UPDATE_SUMMARY_KEYWORDS', value: data})
  //     },
  //     onError: (err) => {

  //     }
  // })

  // const { mutate: getUserSimilarKeywords, isLoading: isSimilarKeywordsLoading } = useMutation(UserSimilarKeywords, {
  //     onSuccess: (data) => {
  //         accountDispatch({type:'UPDATE_SIMILAR_KEYWORDS', value: data})
  //     },
  //     onError: (err) => {

  //     }
  // })

  // const { mutate: getUserKeywordsDomains, isLoading: isKeywordsDomainsLoading } = useMutation(UserKeywordsDomain, {
  //     onSuccess: (data) => {
  //         accountDispatch({type:'UPDATE_KEYWORD_DOMAINS', value: data})
  //     },
  //     onError: (err) => {

  //     }
  // })

  useEffect(() => {
    // getUserKeywordSummary()
    accountInfoHook.getUserKeywordSummary();
  }, []);

  useEffect(() => {
    if (location.pathname.includes("keywords-summary")) {
      accountDispatch({
        type: "UPDATE_CURRENT_PAGE",
        value: "keywords-summary",
      });
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Keyword Summary | AdSpyder</title>
        <meta name="title" content="Keyword Summary | AdSpyder" />
        <meta
          name="description"
          content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. "
        />
      </Helmet>
      <KeywordContainer>
        <TableContainer>
          <ContentHeading>Keyword Search Summary</ContentHeading>
          <TableHeader>
            <NoHeader>No.</NoHeader>
            <QueryHeader>Query</QueryHeader>
          </TableHeader>
          <TableBody>
            {accountInfoHook.isKeywordSummaryLoading
              ? [...Array(6)].map((data) => {
                  return (
                    <Row>
                      <Skeleton />
                    </Row>
                  );
                })
              : accountState.summaryKeywords.length > 0 &&
                accountState.summaryKeywords.map((data, index) => {
                  return (
                    <Row onClick={() => onKeywordClick(data.query)}>
                      <NumberData>{index + 1}</NumberData>
                      <QueryData>
                        {data.query}
                        <SearchNow
                          className="search-now-btn"
                          onClick={() => {
                            window.open(
                              `https://app.adspyder.io/keyword-analysis/details?query=${data.query}&page=1&type=keyword`
                            );
                          }}
                        >
                          Search on PPC
                        </SearchNow>
                      </QueryData>
                    </Row>
                  );
                })}
            {/* {isKeywordSummaryLoading ? [...Array(6)].map((data)=>{
                            return <Row>
                                <Skeleton/>
                            </Row>
                        }) :
                        accountState.summaryKeywords.length > 0 && accountState.summaryKeywords.map((data,index) => {
                            return <Row onClick={()=>onKeywordClick(data.query)}>
                                    <NumberData>
                                        {index + 1}
                                    </NumberData>
                                    <QueryData>
                                        {data.query}
                                    </QueryData>
                                </Row>
                            }
                        )} */}
          </TableBody>
        </TableContainer>
        {accountState.selectedKeyword && (
          <RightContainer>
            <ResetButton onClick={onClearAll}>Reset All</ResetButton>
            <SimilarKeywordsContainer>
              <h3>Similar Keywords</h3>
              <SelectedKeyword>{accountState.selectedKeyword}</SelectedKeyword>
              <SimilarKeywordsList>
                {accountInfoHook.isSimilarKeywordsLoading
                  ? [...Array(10)].map((key, idx) => {
                      return (
                        <SimilarKeyword>
                          <Skeleton
                            style={{
                              lineHeight: 2,
                              width: 200,
                            }}
                          />
                        </SimilarKeyword>
                      );
                    })
                  : accountState.similarKeywords.map((key, idx) => {
                      return (
                        <SimilarKeyword>
                          <span>{key.keyword}</span>
                          <ViewAdsButton
                            className="view-ads-btn"
                            onClick={() =>
                              window.open(
                                `https://app.adspyder.io/adspy/google?searchMode=keyword&keywordSearchType=phrase&phraseInput=${key.keyword}&page=1&excludedTerm=&searchIn=title%2Ccontent&sortType=relevant`
                              )
                            }
                          >
                            View Ads
                          </ViewAdsButton>
                        </SimilarKeyword>
                      );
                    })}
              </SimilarKeywordsList>
            </SimilarKeywordsContainer>
            <KeywordDomainContainer>
              <h3>Keyword Domains and Ads</h3>
              <SelectedKeyword>{accountState.selectedKeyword}</SelectedKeyword>
              <SimilarKeywordsDomainList>
                {accountInfoHook.isKeywordsDomainsLoading
                  ? [...Array(10)].map((key, idx) => {
                      return (
                        <SimilarKeywordDomain>
                          <Skeleton
                            style={{
                              lineHeight: 2,
                              width: 200,
                            }}
                          />
                        </SimilarKeywordDomain>
                      );
                    })
                  : accountState.keywordDomains.map((key, idx) => {
                      return (
                        <SimilarKeywordDomain>
                          <span>{key}</span>
                          <ViewAdsButton
                            className="view-ads-btn"
                            onClick={() =>
                              window.open(
                                `https://app.adspyder.io/adspy/google?searchMode=url&urlSearchType=domain&domainUrlInput=${key}&page=1&sortType=relevant`
                              )
                            }
                          >
                            View Ads
                          </ViewAdsButton>
                        </SimilarKeywordDomain>
                      );
                    })}
              </SimilarKeywordsDomainList>
            </KeywordDomainContainer>
          </RightContainer>
        )}
      </KeywordContainer>
    </>
  );
};

export default KeywordSummary;
