import { Platform, PlatformContainer, SwitchBoardContainer } from "./SwitchBoard.styled";
import statics from "../../_static"
import { useDomainFunnelAnalysis } from "../../context/domainFunnelAnalysis";

function SwitchBoard() {
    const { platforms } = statics
    const { domainFunnelAnalysisState, domainFunnelAnalysisDispatch } = useDomainFunnelAnalysis()
    const { activePlatform } = domainFunnelAnalysisState

    const handlePlatformChange = (platform) => {
        domainFunnelAnalysisDispatch({
            type: "SET_ACTIVE_PLATFORM",
            value: platform
        })
    }

    return ( 
        <SwitchBoardContainer>
            {platforms.map((platform) => {
                const { id, name } = platform
                const isActive = activePlatform.id === id
                return(
                    <PlatformContainer key={id} isActive={isActive} onClick = {() => handlePlatformChange(platform)} >
                        <Platform>{name}</Platform>
                    </PlatformContainer>
                )
            })}
        </SwitchBoardContainer>
     );
}

export default SwitchBoard;