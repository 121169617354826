
import { CampaignHeadWrapper, CampaignLabel, CampaignLabelWrapper, CampaignWrapper, SearchInput, SearchWrapper } from "./CampaignTableList.styled";
import AllCampaigns from './components/AllCampaigns/index';
import searchIcon from '../../assets/searchIcon.svg';
import CampaignTable from './components/CampaignTable';


function CampaignTableList() {
    return ( 
           <CampaignWrapper>
                <CampaignHeadWrapper>
                    <CampaignLabelWrapper>
                        <CampaignLabel>Campaigns:</CampaignLabel>
                        <AllCampaigns />
                    </CampaignLabelWrapper>
                    <SearchWrapper>
                        <SearchInput placeholder='Search'/>
                        <img src={searchIcon}/>
                    </SearchWrapper>
                </CampaignHeadWrapper>
                <CampaignTable />
           </CampaignWrapper>
               
    );
}

export default CampaignTableList;