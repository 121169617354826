// import { AdsInfoWrapper, InputWrapper, NoConnectedBtn, NoConnectedDescription, NoConnectedDiv } from "./index.styled";
import NoAdConnected from './NoAdConnected';
import AdConnected from './AdConnected';

import CampaignTableList from './CampaignTableList';
import { useAdManagement } from '../../context/AdManagementContext';
function InputPages() {
    const { adManagementState, adManagementDispatch } = useAdManagement()
    return ( 
        <>
        {
            adManagementState.isAdDataPage ? <AdConnected/> : <NoAdConnected />
        }
            <CampaignTableList />
       </>
    );
}

export default InputPages;