import styled from "styled-components";
export const LastSeenContainer = styled.div`
    border-radius: 16px;
    background: #FFF;
    padding: 16px;
    width: 266px;
    height: 113px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p{
        font-size: 24px;
        font-weight: 600;
        line-height: normal;
        color: rgba(28, 28, 28, 0.80);
    }

    span{
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        color: rgba(28, 28, 28, 0.40);
    }
`;
export const PriceContainer = styled.div`
    border-radius: 16px;
    background: #FFF;
    padding: 16px;
    width: 266px;
    height: 122px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p{
        font-size: 24px;
        font-weight: 600;
        line-height: normal;
        color: rgba(28, 28, 28, 0.80);
    }
`;
export const UrlAndCountry = styled.div`
display: flex;
flex-direction: column;
gap: 16px;
`;

export const DestinationUrlContainer = styled.div`
    border-radius: 16px;
    background: #FFF;
    padding: 16px;
    width: 356px;
    height: 113px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p{
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        color: rgba(28, 28, 28, 0.50);

        display: -webkit-box;
        max-width: 300px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
export const CountryConainer = styled.div`
    border-radius: 16px;
    background: #FFF;
    padding: 16px;
    width: 356px;
    height: 122px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p{
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
        color: rgba(28, 28, 28, 0.50);
    }
`;
export const CountryHeader = styled.div`
    h3{
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        color: #7D7D7D;
    }
`;

export const MapsList = styled.div`
    display: flex;
    gap: 5px;
`;
export const MoreCountriesCount = styled.div`
background: #FFF7E8;
border-radius: 50%;
width: 24px;
height: 24px;
display: flex;
align-items: center;
justify-content: center;
color: #734A00;
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.4px;
`;
export const Countries = styled.div`
width: 230px;
color: rgba(28, 28, 28, 0.50);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.3px;
white-space: nowrap;
`;
export const FlagLogo = styled.div`

`;

export const LastSeenAndPrice = styled.div`
display: flex;
flex-direction: column;
gap: 16px;
`;

export const LastSeenHeader = styled.div`
    h3{
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        color: #7D7D7D;
    }
`;

export const DestinationUrlHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3{
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        color: #7D7D7D;
        display: flex;
        gap: 8px;
    }
    p{
        border-radius: 6px;
        background: #FFF7E8;
        width: fit-content;
        padding: 4px 8px;
        display: flex;
        gap: 4px;
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        color: #734A00;
        cursor: pointer;
        margin-left: 8px;
    }
`;

export const DetailContainer = styled.div`
    margin-top: 22px;
    background: rgb(255, 255, 255);
    border-radius: 20px;
    padding-top: 40px;
`;

export const Header = styled.div`
color: #1C1C1C;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 120% */
margin-bottom: 36px;
padding: 0 40px;
`;

export const AdHeader = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
padding: 0 40px;
`;

export const AdInfo = styled.div`
display: flex;
gap: 12px;
align-items: center;
`;

export const LogoContainer = styled.div`
height: 32px;
width: 32px;
border-radius: 50%;
border: 1px solid #ECECEC;
background: #F1F3F4;
display: flex;
align-items: center;
justify-content: center;
`;

export const Logo = styled.img`
`;

export const Advertiser = styled.div`
overflow: hidden;
color: #2E2E2E;
text-overflow: ellipsis;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

export const SaveAdsButton = styled.div`
    border-radius: 24px;
    background: ${props => props.isSaved ? "#1C1C1C" : "#FF711E"};
    display: flex;
    padding: 10px 12px;
    gap: 4px;
    justify-content: center;
    align-items: center;
    width: 75px;
    color: #FFF;
    cursor: pointer;
`;

export const Ad = styled.div`
width: 100%;
display: flex;
flex-direction: column;
gap: 19px;
justify-content: center;
margin-top: 34px;
align-items: center;
`;

export const ProductOuterContainer = styled.div`
width: 752px;
height: 564px;
flex-shrink: 0;
background: #F7F7F7;
display: flex;
align-items: center;
justify-content: center;
`;

export const ProductInnerContainer = styled.div`
width: 502px;
height: 502px;
flex-shrink: 0;
display: flex;
align-items: center;
justify-content: center;
background: #FFF;
`;

export const Product = styled.img`
width: 228px;
height: 228px;
`;

export const TitleBox = styled.div`
width: 752px;
flex-shrink: 0;
border-radius: 4px;
border: 1px solid #E3E8EE;
background: #FFF;
padding: 10px 14px 14px 14px;
`;

export const TitleHeader = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
color: rgba(28, 28, 28, 0.80);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 22px; /* 157.143% */
margin-bottom: 8px;
`;

export const CopyButton = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    &:hover{
        height: 24px;
        width: 24px;
        background: rgba(28, 28, 28, 0.05);
    }
`;


export const TitleBody = styled.div`
overflow: hidden;
color: #2E2E2E;
text-overflow: ellipsis;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: 22px; /* 157.143% */
`;

export const Analytics = styled.div`
width: 100%;
height: 347px;
flex-shrink: 0;
padding: 16px 40px;
background: #F7F7F7;
margin-top: 30px;
`;

export const AnalyticsHeader = styled.div`
color: rgba(28, 28, 28, 0.90);
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 133.333% */
`;

export const Container = styled.div`
display: flex;
flex-wrap: wrap;
gap: 16px;
margin-top: 16px;
`;

export const SearchTerms = styled.div`
width: 354px;
height: 251px;
flex-shrink: 0;
border-radius: 16px;
background: #FFF;
padding: 24px 28px;
`; 

export const ItemHeader = styled.div`
color: #7D7D7D;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.2px;
`;

export const TermsList = styled.div`
display: flex;
flex-wrap: wrap;
gap: 8px;
margin-top: 24px;
`;

export const Term = styled.div`
height: 41px;
flex-shrink: 0;
white-space: nowrap;
border-radius: 16px;
background: #F9F9F9;
color: #707070;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.3px;
padding: 12px;
`;

export const ColumnFlex = styled.div`
display: flex;
flex-direction: column;
gap: 16px;
`;