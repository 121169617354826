import React from "react"
import {
    SocialLoginContainer,
    GoogleLogin,
    LogoContainer
} from "./index.styled.js"
import GoogleIcon from '../../../../media/Images/Google.svg'
import _static from '../../../../config/static'

const { baseUrl } = _static

const SocialLogin = ({page}) => {
    return <SocialLoginContainer>
    <GoogleLogin href={`${baseUrl}/auth/google/login`}>
        <LogoContainer>
            <img src={GoogleIcon}/>
        </LogoContainer>
        <span>{page == "login" ? "Log in with Google" : "Continue with your google work account"}</span>
    </GoogleLogin>
</SocialLoginContainer>
}

export default SocialLogin