import React, {useState,useEffect} from "react"
import {Container, Divider, ComponentWrapper, Footer, ContentContainer, InputKeywordRow, AddKeyword, KeywordRow, KeywordInputContainer, Edit, Delete} from "./index.styled"
import { useAdIntegration } from "../context/adintegration"
import { getSearchParams } from "../../../../utils/helper"
import { useLocation } from "react-router-dom"
import ConfettiExplosion from 'react-confetti-explosion';

const AdPush = () => {
    const { adIntegrationState, adIntegrationDispatch } = useAdIntegration() 
    const [successUi, setSuccessUi] = useState(false)
    const [inputKeyword, setInputKeyword] = useState("")
    const [inputHeadingKeyword, setInputHeadingKeyword] = useState("")
    const [inputDescriptionKeyword, setInputDescriptionKeyword] = useState("")

    const [inputKeywordType, setInputKeywordType] = useState("")
    const [editableItem, setEditableItem] = useState("")

    const location = useLocation()

    const FetchAccount = () => {
        const reqObj = {
            "customerId": adIntegrationState.customerId,
            "campaignBudgetData" : {
                "amountMicros": adIntegrationState.campaignBudgetData.amountMicros,
                "name": adIntegrationState.campaignBudgetData.name,
                "resourceName": `customers/${adIntegrationState.customerId}/campaignBudgets/-${adIntegrationState.campaignBudgetData.resourceName}`
            },
            "campaignData": {
                "name": adIntegrationState.campaignData.name,
                "advertisingChannelType": adIntegrationState.campaignData.advertisingChannelType,
                "resourceName": `customers/${adIntegrationState.customerId}/campaigns/-${adIntegrationState.campaignData.resourceName}`,
                "status": adIntegrationState.campaignData.status,
                "targetSpend": {},
                "campaignBudget": `customers/${adIntegrationState.customerId}/campaignBudgets/-${adIntegrationState.campaignBudgetData.resourceName}`
            },
            "adGroupData": {
                "name": adIntegrationState.adGroupData.name,
                "status": adIntegrationState.adGroupData.status,
                "type": adIntegrationState.adGroupData.type,
                "cpcBidMicros": adIntegrationState.adGroupData.cpcBidMicros,
                "campaign": `customers/${adIntegrationState.customerId}/campaigns/-${adIntegrationState.campaignData.resourceName}`
            },
            "adGroupAdData": {
                "ad": {
                    "responsiveSearchAd": {
                        "headlines": adIntegrationState.adGroupAdData.ad.responsiveSearchAd.headlines.split(",").map(part => part.trim()).map(text => ({ "text": text })),
                        "descriptions": adIntegrationState.adGroupAdData.ad.responsiveSearchAd.descriptions.split(",").map(part => part.trim()).map(text => ({ "text": text }))
                    },
                    "finalUrls": [adIntegrationState.adGroupAdData.ad.finalUrls]
                }
            },
            "keywords": adIntegrationState.keywords
        }
        
        fetch('http://localhost:8000/push-ad', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(reqObj)
            }).then(res => res.json())
            .then(res => {
                setSuccessUi(true);
                setTimeout(() => {
                    setSuccessUi(false)
                }, 3000); 
                alert(res.message)});
    }

    const advertisingChannelTypeInput = [
        "SEARCH",
        "DISPLAY",
        "SHOPPING",
        "HOTEL",
        "VIDEO",
        "MULTI_CHANNEL",
        "LOCAL",
        "SMART",
        "PERFORMANCE_MAX",
        "LOCAL_SERVICES",
        "DISCOVERY",
        "UNSPECIFIED",
        "UNKNOWN",
        "TRAVEL"
    ]

    const campaignStatusInput = [
        "UNSPECIFIED",
        "UNKNOWN",
        "ENABLED",
        "PAUSED",
        "REMOVED"
    ]

    const adGroupStatusInput = [
        "UNSPECIFIED",
        "UNKNOWN",
        "ENABLED",
        "PAUSED",
        "REMOVED"
    ]

    const adGroupTypeInput = [
        "UNSPECIFIED",
        "UNKNOWN",
        "SEARCH_STANDARD",
        "DISPLAY_STANDARD",
        "SHOPPING_PRODUCT_ADS",
        "HOTEL_ADS",
        "SHOPPING_SMART_ADS",
        "VIDEO_BUMPER",
        "VIDEO_TRUE_VIEW_IN_STREAM",
        "VIDEO_TRUE_VIEW_IN_DISPLAY",
        "VIDEO_NON_SKIPPABLE_IN_STREAM",
        "VIDEO_OUTSTREAM",
        "SEARCH_DYNAMIC_ADS",
        "SHOPPING_COMPARISON_LISTING_ADS",
        "PROMOTED_HOTEL_ADS",
        "VIDEO_RESPONSIVE",
        "VIDEO_EFFICIENT_REACH",
        "SMART_CAMPAIGN_ADS",
        "TRAVEL_ADS"
    ]

    const matchTypeKeyword = [
        "UNSPECIFIED",
        "UNKNOWN",
        "EXACT",
        "PHRASE",
        "BROAD"
    ]

    const AddNewKeyword = () => {
        let keywordObj = {}
        keywordObj.text = inputKeyword;
        keywordObj.matchType = inputKeywordType;
        keywordObj.id = 'id' + (new Date()).getTime();

        adIntegrationDispatch({type: 'SET_KEYWORDS', value: [...adIntegrationState.keywords, keywordObj]})
        setInputKeyword("")
        setInputKeywordType("")
    }

    const DeleteKeyword = (data) => {
        let filteredKeywords = adIntegrationState.keywords.filter(item => item.id !== data.id)
        adIntegrationDispatch({type: 'SET_KEYWORDS', value: filteredKeywords})
    }

    const updateKeyword = (data) => {
        adIntegrationDispatch({type: 'SET_KEYWORDS', value: adIntegrationState.keywords.map(obj => obj.id === data.id ? data : obj)})
        // setadIntegrationState.keywords(prevArray => prevArray.map(obj => obj.id === data.id ? data : obj));

        setEditableItem("")
    }

    useEffect(() => {
        const {customerId} = getSearchParams(location.search)
        adIntegrationDispatch({type: "SET_CUSTOMER_ID", value: customerId})
    }, [])

    return <Container>
        <h1>Google Ads Publish</h1>
        <ContentContainer>
            <ComponentWrapper>
                <div>
                    <label for="customerIdInput">Customer ID:</label>
                    <input defaultValue={adIntegrationState.customerId} type="text" id="customerIdInput" onChange={(e)=>{adIntegrationDispatch({type: "SET_CUSTOMER_ID", value: e.target.value})}} placeholder="Enter Customer ID"/>
                </div>
            </ComponentWrapper>
            <Divider/>

            <ComponentWrapper>
                <h2>
                    Campaign Budget
                </h2>
                <div>
                    <label for="campaignBudgetAmountInput">Campaign Budget Amount (Micros):</label>
                    <input type="number" id="campaignBudgetAmountInput" placeholder="Enter Campaign Budget Amount" onChange={(e)=>{adIntegrationDispatch({type: "SET_CAMPAIGN_BUDGET_DATA_AMOUNT", value: e.target.value})}}/>
                </div>

                <div>
                    <label for="campaignBudgetNameInput">Campaign Budget Name:</label>
                    <input onChange={(e)=>{adIntegrationDispatch({type: "SET_CAMPAIGN_BUDGET_DATA_NAME", value: e.target.value})}} type="text" id="campaignBudgetNameInput" placeholder="Enter Campaign Budget Name"/>
                </div>

                <div>
                    <label for="campaignBudgetResourceNameInput">Campaign Budget Resource Name:</label>
                    <input defaultValue={adIntegrationState.campaignBudgetData.resourceName} onChange={(e)=>{adIntegrationDispatch({type: "SET_CAMPAIGN_BUDGET_DATA_RESOURCE_NAME", value: e.target.value})}} type="number" id="campaignBudgetResourceNameInput" placeholder="Enter Campaign Budget Resource Name"/>
                </div>
            </ComponentWrapper>
            <Divider/>

            <ComponentWrapper>
                <h2>
                    Campaign Details
                </h2>
                <div>
                    <label for="campaignNameInput">Campaign Name:</label>
                    <input onChange={(e)=>{adIntegrationDispatch({type: "SET_CAMPAIGN_DATA_NAME", value: e.target.value})}} type="text" id="campaignNameInput" placeholder="Enter Campaign Name"/>
                </div>

                <div>
                    <label for="advertisingChannelTypeInput">Advertising Channel Type:</label>
                    <select onChange={(e)=>{adIntegrationDispatch({type: "SET_CAMPAIGN_DATA_CHANNEL_TYPE", value: e.target.value})}} id="advertisingChannelTypeInput" name="advertising-channel-type">
                        {advertisingChannelTypeInput.map((data,idx)=>{
                            return <option value={data}>{data}</option>
                        })}
                    </select>
                </div>

                <div>
                    <label for="campaignResourceNameInput">Campaign Resource Name:</label>
                    <input defaultValue={adIntegrationState.campaignData.resourceName} onChange={(e)=>{adIntegrationDispatch({type: "SET_CAMPAIGN_DATA_RESOURCE_NAME", value: e.target.value})}} type="number" id="campaignResourceNameInput" placeholder="Enter Campaign Resource Name"/>
                </div>

                <div>
                    <label for="campaignStatusInput">Campaign Status:</label>
                    <select  onChange={(e)=>{
                        adIntegrationDispatch({type: "SET_CAMPAIGN_DATA_STATUS", value: e.target.value})
                    }} name="campaign-status-input" id="campaignStatusInput">
                        {campaignStatusInput.map((data)=>{
                            return <option value={data}>{data}</option> 
                        })}
                    </select>
                </div>

                <div>
                    <label for="targetSpendInput">Target Spend:</label>
                    <input onChange={(e)=>{adIntegrationDispatch({type: "SET_CAMPAIGN_DATA_TARGET_SPEND", value: e.target.value})}} type="text" id="targetSpendInput" placeholder="Enter Target Spend"/>
                </div>

                <div>
                    <label for="campaignBudgetInput">Campaign Budget:</label>
                    <input defaultValue={adIntegrationState.campaignBudgetData.resourceName}  onChange={(e)=>{adIntegrationDispatch({type: "SET_CAMPAIGN_BUDGET_DATA_RESOURCE_NAME", value: e.target.value})}} type="number" id="campaignBudgetInput" placeholder="Enter Campaign Budget"/>
                </div>
            </ComponentWrapper>
            <Divider/>

            <ComponentWrapper>
                <h2>
                    Ad Group Details
                </h2>
                <div>
                    <label for="adGroupNameInput">Ad Group Name:</label>
                    <input onChange={(e)=>{adIntegrationDispatch({type: "SET_ADGROUP_DATA_NAME", value: e.target.value})}} type="text" id="adGroupNameInput" placeholder="Enter Ad Group Name"/>
                </div>

                <div>
                    <label for="adGroupStatusInput">Ad Group Status:</label>
                    <select onChange={(e)=>{adIntegrationDispatch({type: "SET_ADGROUP_DATA_STATUS", value: e.target.value})}} name="ad-group-status-input" id="adGroupStatusInput">
                        {adGroupStatusInput.map((data)=>{
                            return <option value={data}>{data}</option> 
                        })}
                    </select>
                </div>

                <div>
                    <label for="adGroupTypeInput">Ad Group Type:</label>
                    <select onChange={(e)=>{adIntegrationDispatch({type: "SET_ADGROUP_DATA_TYPE", value: e.target.value})}} name="ad-group-type-input" id="adGroupTypeInput">
                        {adGroupTypeInput.map((data)=>{
                            return <option value={data}>{data}</option> 
                        })}
                    </select>
                </div>

                <div>
                    <label for="adGroupCpcBidInput">Ad Group CPC Bid (Micros):</label>
                    <input onChange={(e)=>{adIntegrationDispatch({type: "SET_ADGROUP_DATA_CPC", value: e.target.value})}} type="number" id="adGroupCpcBidInput" placeholder="Enter Ad Group CPC Bid"/>
                </div>

                <div>
                    <label for="adGroupCampaignInput">Ad Group Campaign:</label>
                    <input defaultValue={adIntegrationState.campaignData.resourceName} onChange={(e)=>{adIntegrationDispatch({type: "SET_CAMPAIGN_DATA_RESOURCE_NAME", value: e.target.value})}} type="number" id="adGroupCampaignInput" placeholder="Enter Ad Group Campaign"/>
                </div>

            </ComponentWrapper>
            <Divider/>

            <ComponentWrapper>
                <h2>
                    Ads Details
                </h2>
                <div>
                    <label for="responsiveSearchAdHeadlinesInput">Responsive Search Ad Headlines (comma-separated):</label>
                    <input onChange={(e)=>{adIntegrationDispatch({type: "SET_AD_HEADINGS_DATA", value: e.target.value})}} type="text" id="responsiveSearchAdHeadlinesInput" placeholder="Enter Responsive Search Ad Headlines"/>
                </div>

                <div>
                    <label for="responsiveSearchAdDescriptionsInput">Responsive Search Ad Descriptions (comma-separated):</label>
                    <input onChange={(e)=>{adIntegrationDispatch({type: "SET_AD_DESCRIPTIONS_DATA", value: e.target.value})}} type="text" id="responsiveSearchAdDescriptionsInput" placeholder="Enter Responsive Search Ad Descriptions"/>
                </div>

                <div>
                    <label for="finalUrlsInput">Final URLs (comma-separated):</label>
                    <input onChange={(e)=>{adIntegrationDispatch({type: "SET_AD_FINALURLS_DATA", value: e.target.value})}} type="text" id="finalUrlsInput" placeholder="Enter Final URLs"/>
                </div>
                {/* 
                    <div>
                        <label for="adGroup"></label>
                        <input onChange={(e)=>{adIntegrationDispatch({type: "SET_CAMPAIGN_DATA_STATUS", value: e.target.value})}} type="text" id="adGroup" placeholder="Enter API Endpoint"/>
                    </div> 
                */}
            </ComponentWrapper>
            <Divider/>

            <ComponentWrapper>
                <h2>Keywords List</h2>
                {
                    adIntegrationState.keywords.length > 0 ? adIntegrationState.keywords.map((data)=>{
                        return <KeywordRow editableItem={editableItem == data.id}>
                            <input defaultValue={data.text}/>
                            <select>
                                {matchTypeKeyword.map((typeData)=>{
                                    return <option selected={typeData == data.matchType} value={typeData}>{typeData}</option> 
                                })} 
                            </select>
                            <Edit onClick={()=>{
                                if(editableItem){
                                    updateKeyword(data)
                                }else{
                                    setEditableItem(data.id)
                                }
                            }}>
                                {editableItem ? "Save" : "Edit"}
                            </Edit>
                            <Delete onClick={()=>DeleteKeyword(data)}>
                                Delete
                            </Delete>
                        </KeywordRow>
                    }) : "-"
                }
                <Divider/>
                <h3>
                    Add new Keyword
                </h3>
                <InputKeywordRow>
                    <KeywordInputContainer>
                        <input value={inputKeyword} onChange={(e)=>setInputKeyword(e.target.value)} placeholder="Enter your keyword"/>
                        <select value={inputKeywordType} onChange={(e)=>{setInputKeywordType(e.target.value)}}>
                            {matchTypeKeyword.map((data)=>{
                                return <option value={data}>{data}</option> 
                            })}
                        </select>
                    </KeywordInputContainer>
                    <AddKeyword onClick={AddNewKeyword}>
                        Add Keyword
                    </AddKeyword>
                </InputKeywordRow>
            </ComponentWrapper>
        </ContentContainer>        
        <Footer>
            <button onClick={FetchAccount}>Publish Ad</button>
        </Footer>
        {successUi && <ConfettiExplosion 
            force ={0.8}
            duration = {3000}
            particleCount = {250}
            width = {1600}
        />}
    </Container>
    
}


export default AdPush