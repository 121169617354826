import React from 'react'
import { PersonaDetailsCardConatianer, PersonaDetailsContainer, GenerateAdButton, DetailsHeader, ImgDiv, DetailName, DetailsBody, DetailsValues, PersonaAnalysisCard, AnalysisDetails, ImagePlaceHolder, AnalysisValue, DetailsFooter } from './PersonaList.styled'

const PersonaDetailsCard = ({ storyData }) => {
    return (
        <PersonaDetailsCardConatianer>
            <PersonaDetailsContainer>
                <DetailsHeader>
                    <ImgDiv value="60px"></ImgDiv>
                    <DetailName>
                        <div>{storyData?.persona} details</div>
                        <p>Adspyder AI analysis</p>
                    </DetailName>
                </DetailsHeader>
                <DetailsBody>
                    <DetailsValues>
                        <p>Persona 1 categories</p>
                        <div>Young Fitness Enthusiast, Business Man</div>
                    </DetailsValues>
                    <DetailsValues>
                        <p>Age category</p>
                        <div>{storyData?.age} Yrs</div>
                    </DetailsValues>
                    <DetailsValues>
                        <p>Income Level</p>
                        <div>{storyData?.income} </div>
                    </DetailsValues>
                    <DetailsValues>
                        <p>Justification</p>
                        <div>{storyData.justification}</div>
                    </DetailsValues>
                </DetailsBody>
                <DetailsFooter>
                    <PersonaAnalysisCard>
                        <AnalysisDetails>
                            <ImgDiv value="40px" />
                            <DetailName>
                                <div>Persona 1 Details</div>
                                <p>Adspyder AI analysis</p>
                            </DetailName>
                        </AnalysisDetails>
                        <AnalysisValue>
                            <div></div>
                            <span>When Larissa wakes up, she wants a real-time update on all her shipments in real-time, so that she can plan her day accordingly.
                            </span>
                        </AnalysisValue>
                    </PersonaAnalysisCard>
                </DetailsFooter>
            </PersonaDetailsContainer>
            <GenerateAdButton>Generate Ad on this Persona</GenerateAdButton>
        </PersonaDetailsCardConatianer>
    )
}

export default PersonaDetailsCard