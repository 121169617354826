import { useTextAdGenerator } from "../../../../context/textAdGeneration";
import AdGoalPP from "./AdGoal";
import DescriptionPP from "./Description";

import { Content, PopupContainer } from "./index.styled";
import SeedKeywordsPP from "./SeedKeywords";
import TargetLocationPP from "./TargetLocation";

function Popup() {
    const { textAdGeneratorState, textAdGeneratorDispatch } = useTextAdGenerator()



    const renderContent = () => {
        switch(textAdGeneratorState.activeCustomizePopup.id){
            case 1: 
            return <AdGoalPP />

            case 2:
            return <TargetLocationPP />

            case 3:
            return <DescriptionPP />

            case 4:
            return <SeedKeywordsPP />

            default:
            return
        }
    }

    if(!textAdGeneratorState.activeCustomizePopup) return

    return ( 
        <PopupContainer>
            <Content>
               {renderContent()}
            </Content>
        </PopupContainer>
     );
}

export default Popup;