import styled from "styled-components";

export const Card = styled.div`
    background: #FFFFFF;
    // border: ${props => props.detailsOpen ? "2px solid #006ECC" : "1px solid #F2F2F2"} ;
    // border-radius: 12px;
    width: 371px;
    height: 326px;
    position: relative;
    .hover-element {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;
      }
      :hover .hover-element {
        opacity: 1;
        visibility: visible;
      }
`;
export const HoverCard = styled.div`
    position: absolute;
   width: 100%;
height: 100%;
    z-index: 3;
    // background: #1C1C1C59;
    border-radius: 8px;
    cursor: pointer;
    padding: 20px;
    display: flex;
    justify-content: end;
    align-items: end;
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.35) 32.4%, rgba(0, 0, 0, 0.15) 63.4%, rgba(0, 0, 0, 0.05) 100%);
}
`;

export const HoverCardWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 8px;
`;
export const AdDetailsButton = styled.div`
    border-radius: 24px;
    background: #FFF;
    width: fit-content;
    display: flex;
    padding: 10px 12px;
    gap: 4px;
    justify-content: center;
    align-items: center;
`;

export const CardBody = styled.div`
    display: flex;
    flex-direction: column;
background: rgba(222, 218, 218, 0.2);
    .title-wrapper-span{
        font-size: 18px;
        color: #1A0DAB;
        line-height: 27px;
        margin-bottom: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        /* max-width: 420px; */
        font-weight: 400;
        
        display: flex;
        flex-wrap: wrap;
    }

    .title-highlight-bold{
        font-weight: 400;
        font-size: 18px;
        padding: 0px 3px;
    }

    .content-wrapper-span{
        font-size: 14px;
        color: #2E2E2E;
        line-height: 22px;
        /* margin-bottom: 8px; */
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2; 
        -webkit-box-orient: vertical;
        height: 44px;
        font-weight: 300;
        display: flex;
        flex-wrap: wrap;
    }

    .content-highlight-bold{
        color: #2E2E2E;
        font-weight: 600;
        font-size: 14px;
        padding: 0px 2px;
    }
    
`;
export const ImageContainer = styled.div`
    height: 214px;
    margin: 12px 0;
    /* background: #F2F2F2; */
    // background: #dedada33;
    border-radius: 12px 12px 0px 0px;
    img{
        width: 100%; /* Makes the image width 100% of the container width */
        height: 100%; /* Makes the image height 100% of the container height */
        object-fit: contain; 
    }
`;
export const CardFooter = styled.div`
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #F2F2F2;
    padding: 14px 16px;
`;

export const FooterLeft = styled.div`
    display: flex;
    flex-direction: column;
`;
export const AdDate = styled.div`
    display: flex;
    margin-right: 20px;
    align-items: center;
    margin-bottom: 16px;

    overflow: hidden;
color: rgba(28, 28, 28, 0.50);
text-overflow: ellipsis;
white-space: nowrap;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
    img{
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
`;
export const AdCountries = styled.div`
    display: flex;
    align-items: center;
    /* padding: 0px 16px; */
    flex-wrap: wrap;
    overflow: hidden;
color: rgba(28, 28, 28, 0.50);
text-overflow: ellipsis;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
    p{
        color: #919191;
        margin-right: 16px;
    }
    > img{
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
`;