import React, { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import {
  Card,
  SaveAdsButton,
  DetailContainer,
  LevelOneDetailContainer,
  AdPreviewContainer,
  AdContentContainer,
  TitleContainer,
  TitleHeader,
  DescriptionContainer,
  DescriptionHeader,
  LevelTwoDetailsContainer,
  CopyButton,
  LevelTwoRow,
  CountryConainer,
  LastSeenContainer,
  DestinationUrlContainer,
  CountryHeader,
  LastSeenHeader,
  DestinationUrlHeader,
  MapsList,
  FlagLogo,
  CardContainer,
  ChannelDetails,
  LogoContainer,
  ChannelName,
  ImageContainer,
  VideoContainer,
  IntractionDetails,
  MediaTypeWrapper,
} from "./index.styled";
import GlobeIcon from "../../../../../media/Dashboard/globe.svg";
import CopyImage from "../../../../../media/Dashboard/CopyImg.svg";
import RedirectOrangeIcon from "../../../../../media/Dashboard/RedirectOrangeIcon.svg";
import photoIcon from "../../../../../media/Dashboard/photoIcon.svg";

import { countryList } from "../../../../../utils/maps";
import { appClient } from "../../../../../utils/axios-utils";

import { CircleFlag } from "react-circle-flags";
import AltImage from '../../../../../media/Dashboard/altimage.png'
import { useAuth } from "../../../../../context/auth";
import { useSaveAds } from "../../../../hooks/useSaveAds";
import copiedImage from "../../../../../media/Dashboard/copiedImage.svg";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";

const getAdDetails = async (id) => {
  const config = {
    url: `/detail/google/${id}`,
    method: "GET",
  };

  return appClient(config);
};

const adAmountSpent = async (id) => {
  const config = {
    url: `/detail/google/estimated-budget/${id}`,
    method: "GET",
  };

  return appClient(config);
};

const LinkedInDetail = ({ basicDetail, highlightWords, onSave }) => {
  const navigate = useNavigate()

  const [adDetails, setAdDetails] = useState(basicDetail);
  const [detailsLoading, setDetailsLoading] = useState(true);
  const [copyState, setCopyState] = useState(false);
  const [copyContent, setCopyContent] = useState(false);
  const [copyURL, setCopyURL] = useState(false);

  const [adAmount, setAdAmount] = useState({})
  const { authState, authDispatch } = useAuth()
  const useSaveAdsHook = useSaveAds()
  const getFlagEmoji = (countryName) => {
    let countryCode = countryList[countryName];
    if (countryCode) {
      return (
        <FlagLogo>
          <CircleFlag countryCode={countryCode.toLowerCase()} height="24" />
        </FlagLogo>
      );
    }
  };

  const { mutate: adsDetailsMutate, isLoading: isDetailsLoading } = useMutation(
    getAdDetails,
    {
      onSuccess: (data) => {
        setAdDetails(data);
        setDetailsLoading(false);
      },
      onError: (e) => {
        console.log("err", e);
        setDetailsLoading(false);
        setAdDetails({});
      },
    }
  );

  const { mutate: adAmountSpentMutate, isLoading: isAdAmountSpentLoading } = useMutation(
    adAmountSpent,
    {
      onSuccess: (data) => {
        console.log(data, "adDetails");
        setAdAmount(data)
      },
      onError: (e) => {
        console.log("err", e);
        setAdAmount({})
      },
    }
  );
  const onRemoveSavedAd = (ad) => {
    useSaveAdsHook.RemoveSavedAdMutate({
        projectId: authState.defaultProjectInfo.projectId,
        adId:  `${ad.id}`,
        platform: "linkedin"
    })
}


  const convertTimestamp = (timestamp) => {
    // Create a new Date object based on the timestamp
    console.log("Timestamp:", timestamp);

    // Create a new Date object based on the timestamp
    var date = new Date(timestamp);
    console.log("Date object:", date);
    console.log("Basic Detail End Date:", basicDetail.endDate);
    // Get the month name
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var month = monthNames[date.getMonth()];

    // Get the year
    var year = date.getFullYear();

    // Assemble the formatted date string
    var formattedDate = date.getDate() + " " + month + " " + year;

    return formattedDate;
  };
  const analyzeDomian = () =>{
    const url = new URL(basicDetail?.url);
    const domain = url.hostname;
    navigate(`/domain-overview/details?domain=${domain}&country=global`)
  }
  useEffect(() => {
    // adsDetailsMutate(basicDetail.id);
    // adAmountSpentMutate(basicDetail.id)
  }, []);

  // if (detailsLoading) {
  //   return <h2>Loading</h2>;
  // } else {
    return (
      <DetailContainer>
        <LevelOneDetailContainer>
        <h2>Ad Details</h2>

          <AdPreviewContainer>
             <ChannelDetails>
                    <LogoContainer>
                        <img src={basicDetail.logo ? `https://aws-adspyder-logos.s3.ap-south-1.amazonaws.com/${basicDetail.id}`: GlobeIcon}/>
                    </LogoContainer>
                    <ChannelName>
                        {basicDetail.title}
                        <span>Sponsored</span>
                    </ChannelName>
                </ChannelDetails>
                <IntractionDetails>
                <SaveAdsButton isSaved={authState.savedAdsIds.includes(basicDetail.id)} onClick={(e) => {
                        e.stopPropagation();
                        if (authState.savedAdsIds.includes(basicDetail.id)) {
                            onRemoveSavedAd(basicDetail)
                        } else {
                            onSave(basicDetail);
                        }

                    }}>
                        {authState.savedAdsIds.includes(basicDetail.id) ? "Saved" : "Save"}
                    </SaveAdsButton>
                </IntractionDetails>
                </AdPreviewContainer>
          
            <Card key={basicDetail.id}>
         <CardContainer>
            {basicDetail.image && <ImageContainer>
                <img src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${basicDetail.id}`}/>
            </ImageContainer>}
            {basicDetail.video && <VideoContainer>
                <video src={`https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${basicDetail.id}`} preload="auto" controls loop style={{width: "100%", height: "100%"}}/>
            </VideoContainer>}
            {(!basicDetail.video && !basicDetail.image) && <ImageContainer>
                <img src={AltImage}/>
                </ImageContainer>}
               
    </CardContainer>
    </Card>
          <AdContentContainer>
            <TitleContainer>
              <TitleHeader>
                <h3>Title</h3>
                 <CopyButton
                           onClick={() => {
                            !copyState && setCopyState(true)
                            navigator.clipboard.writeText(basicDetail.title);
                            !copyState && setTimeout(() => {
                              setCopyState(false)
                            }, 3000);
                          }}
                          data-tooltip-id="title-tooltip"
                          data-tooltip-content={copyState ? 'Copied' : 'Copy'}
                            >
                        {!copyState ? <img src={CopyImage} />:<img src={copiedImage} />}
                        <Tooltip id="title-tooltip" 
                  noArrow={true} 
                  place={'bottom'}  
                  style={{ backgroundColor: "#101828", color: "#FFF",fontFamily: 'Inter', fontSize: '12px', borderRadius: '8px',
                  fontWeight: 600, height: '34px', padding:'8px 12px',display: 'flex', alignItems: 'center' }}/>
                        </CopyButton>
              </TitleHeader>
              <p>{basicDetail.title}</p>
            </TitleContainer>
            <DescriptionContainer>
              <DescriptionHeader>
                <h3>Content</h3>
                <CopyButton
                            onClick={() => {
                              !copyContent && setCopyContent(true)
                              navigator.clipboard.writeText(basicDetail.content);
                              !copyContent && setTimeout(() => {
                                setCopyContent(false)
                              }, 3000);
                            }}
                            data-tooltip-id="content-tooltip"
                            data-tooltip-content={copyContent ? 'Copied' : 'Copy'}
                            >
                            {!copyContent ? <img src={CopyImage} />:<img src={copiedImage} />}
                            <Tooltip id="content-tooltip" 
                  noArrow={true} 
                  place={'bottom'}  
                  style={{ backgroundColor: "#101828", color: "#FFF",fontFamily: 'Inter', fontSize: '12px', borderRadius: '8px',
                  fontWeight: 600, height: '34px', padding:'8px 12px',display: 'flex', alignItems: 'center' }}/>
                        </CopyButton>
              </DescriptionHeader>
              <p>{basicDetail.content}</p>
            </DescriptionContainer>
          </AdContentContainer>
        </LevelOneDetailContainer>
        <LevelTwoDetailsContainer>
          <h2>Ad Analytics</h2>
          <LevelTwoRow>
          <DestinationUrlContainer>
                <DestinationUrlHeader>
                  <h3>
                    Destination URL
                    <p onClick={analyzeDomian}>
                      <img src={RedirectOrangeIcon} />
                      analyze domain
                    </p>
                  </h3>
                  <CopyButton
                         onClick={() => {
                          !copyURL && setCopyURL(true)
                          navigator.clipboard.writeText(adDetails.url);
                          !copyURL && setTimeout(() => {
                            setCopyURL(false)
                          }, 3000);
                        }}
                        data-tooltip-id="url-tooltip"
                data-tooltip-content={copyURL ? 'Copied' : 'Copy'}
                        >
                            {!copyURL ? <img src={CopyImage} />:<img src={copiedImage} />}
                            <Tooltip id="url-tooltip" 
                  noArrow={true} 
                  place={'bottom'}  
                  style={{ backgroundColor: "#101828", color: "#FFF",fontFamily: 'Inter', fontSize: '12px', borderRadius: '8px',
                  fontWeight: 600, height: '34px', padding:'8px 12px',display: 'flex', alignItems: 'center' }}/>
                        </CopyButton>
                </DestinationUrlHeader>
                <p>{basicDetail?.url}</p>
              </DestinationUrlContainer>
              <CountryConainer>
                <CountryHeader>
                  <h3>Country</h3>
                </CountryHeader>
                <MapsList>
                {getFlagEmoji(basicDetail.country)}
                </MapsList>
               <p> {basicDetail.country}</p>
              </CountryConainer>
          <MediaTypeWrapper>
          <h3>Media Type</h3>
          <p><img src={photoIcon}/>{basicDetail.image ? 'Image': 'Video'}</p>
          </MediaTypeWrapper>
          <LastSeenContainer>
                <LastSeenHeader>
                  <h3>Last Seen</h3>
                </LastSeenHeader>
                {basicDetail.endDate != undefined ? <div>
                  <p>
                    {convertTimestamp(basicDetail.date).split(" 20")[0]}
                  </p>
                  
                </div>: 
                <p>--</p>
                }
              </LastSeenContainer>
            {/* <OtherDetailsContainer> */}
            {/* <AdPositionContainer>
            <AdPositionHeader>
              <h3>Title</h3>
              {basicDetail.innertitle?<p>{basicDetail.innertitle}</p>:<p>--</p>}

              <h3>Description</h3>
              {basicDetail.innerdescription?<p>{basicDetail.innerdescription}</p>:<p>--</p>}
            </AdPositionHeader>
             
            </AdPositionContainer> */}
         
             
              
            {/* </OtherDetailsContainer> */}
          </LevelTwoRow>
        </LevelTwoDetailsContainer>
      
      </DetailContainer>
    );
  }
// };

export default LinkedInDetail;
