import React, { useState } from "react";
import WarningIcon from "../../media/Dashboard/warning.svg"
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {useMutation} from "@tanstack/react-query"
import {appClient} from '../../utils/axios-utils'
import {useAuth} from "../../context/auth"
import toast from "react-hot-toast"

const PopupContainer = styled.div`
    position: absolute;
    z-index: 5;

    right: 20px;
    bottom: 40px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;

    max-width: 432px;
    width: 100%;
`;

const PopupBody = styled.div`
    /* max-width: 432px; */
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px #0000003D;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 56px 112px; */
    position: relative;
    img{
        width: 46px;
        margin-bottom: 24px;
    }
    h2{
        margin-bottom: 16px;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        color: #2E2E2E;       
    }
    p{
        margin-bottom: 30px;
        font-size: 14px;
        font-weight: 300;
        color: #4E4E4E;
        text-align: center;
        line-height: 21px;
    }
`;

const Header = styled.div`
    padding: 16px 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #555555;
    font-size: 16px;
    font-weight: 600;
    background: #F8F8F8;
    span{
        cursor: pointer;
        color: #707070;
        font-size: 16px;
        padding: 3px 7px;
        border-radius: 15px;
    }
    span:hover{
        background: #E4E4E4;
    }
`;

const Container = styled.div`
    padding: 16px 20px;
    width: 100%;
    h2{
        font-size: 14px;
        font-weight: 300;
        text-align: start;
    }
`;

const Footer = styled.div`
    width: 100%;
    padding: 0px 20px 20px;
    display: flex;
    justify-content: flex-end;
`;

const SubmitButton = styled.button`
    background: #006ECC;
    padding: 10px 16px;
    border-radius: 8px;
    border: none;
    outline: none;
    color: #FFFFFF;
`;

const RatingList = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Rating = styled.div`
    color: ${props => props.isSelected ? "#12558d" : "#2E2E2E"};
    background: ${props => props.isSelected ? "#f0f8ff" : "#FFFFFF"};
    border: ${props => props.isSelected ? "1px solid #b8d9f5" : "1px solid #ECECEC"};
    border-radius: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
`;

const InputContainer = styled.textarea`
    width: 100%;
    margin-top: 16px;
    padding: 8px 12px;
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 8px;
    height: 138px;
    outline: none;
    ::placeholder{
        color: #BFBFBF;
        font-size: 13px;
        font-weight: 300;
    }
`;

const submitFeedback = (data) => {
    const config = {
        url: "/feedback/rate",
        method: "POST",
        data
    }

    return appClient(config)
}

const FeedbackPopup = ({type}) => {
    const {authState, authDispatch} = useAuth()
    const [ ratingNumber, setRatingNumber ] = useState()
    const [ feedback, setFeedback ] = useState("")

    let toastId = "";

    const {mutate: submit} = useMutation(()=>{
        return submitFeedback({ratingNumber, feedback, type})}, {
        onSuccess: (data) => {
            toast.success("Thanks for your feedback")
            toast.dismiss(toastId)
            authDispatch({type:'UPDATE_FEEDBACK_POPUP', value: false})
            localStorage.removeItem("feedbackpopup")
        },
        onError: (err) => {
            toast.dismiss(toastId)
        },
        onMutate: () => {
        }
    })

    let ratingArray = [1,2,3,4,5,6,7,8,9,10]

    return <PopupContainer>
        <PopupBody>
            <Header>
                Your opinion matters to us <span onClick={()=>{authDispatch({type:'UPDATE_FEEDBACK_POPUP', value: false});
                localStorage.removeItem("feedbackpopup")
                }}>x</span>
            </Header>
            <Container>
                <h2>
                    How would you rate your {type == "after-onboarding" ? "onboarding" : "search"} experience?
                </h2>
                <RatingList>
                    {ratingArray.map((rating,idx)=>{
                        return <Rating isSelected={ratingNumber == rating} key={idx} onClick={()=>setRatingNumber(rating)}>
                        {rating}
                    </Rating>
                    })}
                    
                </RatingList>
                <InputContainer onChange={(e)=>setFeedback(e.target.value)} placeholder="Any other feedback (optional)">

                </InputContainer>
            </Container>
            <Footer>
                <SubmitButton onClick={submit}>submit feedback</SubmitButton>
            </Footer>
        </PopupBody>
    </PopupContainer>
}

export default FeedbackPopup    