import { useImageAdGenerator } from "../../../../context/imageAdGeneration";
import { Content, PopupContainer } from "./index.styled";
import StockImagePopup from "./StockImagePopup";
import AiGenImagePopup from './AiGenImagePopup'

function CustomizePopup() {
    const { imageAdGeneratorState } = useImageAdGenerator();

    const renderContent = () => {
        if (imageAdGeneratorState.activeCustomStockImage) {
            return <PopupContainer>
                <Content>
                    <StockImagePopup />
                </Content>
            </PopupContainer>
        }

        if (imageAdGeneratorState.activeCustomAiImage) {
            return <PopupContainer>
                <Content>
                    <AiGenImagePopup />
                </Content>
            </PopupContainer>
        }
        return null;
    };

    return (
        renderContent()
    );
}

export default CustomizePopup;
