import React, {useEffect, useState} from "react";
import styled from "styled-components";
import AnalysingGif from "../../../../../media/Dashboard/aiLoader.gif"
import TextTransition, { presets } from 'react-text-transition';
import LoadingMessages from "../../../../../utils/LoadingMessages"
import {useTracking} from "../../context/tracking"

const PopupContainer = styled.div`
    position: absolute;
    background: #00000045;
    left: 0;
    top: 54px;
    width: 100vw;
    height: calc(100vh - 54px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 12;
`;

const PopupBody = styled.div`
    max-width: 350px;
    width: 80%;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px #0000003D;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    position: relative;
    img{
        width: 46px;
        margin-bottom: 24px;
    }
    h2{
        margin-bottom: 16px;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        color: #2E2E2E;       
    }
    p{
        margin-bottom: 30px;
        font-size: 14px;
        font-weight: 300;
        color: #4E4E4E;
        text-align: center;
        line-height: 21px;
    }
`;

const AnalysingUi = styled.div`
    display: flex;
    padding-top: 20%;
    flex-direction: column;
    align-items: center;
    height: 86%;
    img{
        height: 100px;
        width: 100px;
        margin-bottom: 16px;
    }
    p{
        font-size: 20px;
        font-weight: 600;
        color: #12487F;
        line-height: normal;
    }
`;

const LoadingContainer = ({getTrackingDomains}) => {

    const { trackingState, trackingDispatch } = useTracking()
    const [currentText, setCurrentText] = useState(0)


    useEffect(() => {
        let count = 0; // Initialize a count variable

        const tick = () => {
          if (count < 6) {
            setCurrentText((i) => i + 1);
            count++; // Increment the count
          } else {
            // console.log("inside false case domain loader popup timer")
            getTrackingDomains()
            clearInterval(id); // Stop the interval when count reaches 7
          }
        };
    
        const id = setInterval(tick, 1500);
      
        return () => clearInterval(id);
      }, []);

    return <PopupContainer>
              <PopupBody>
                <AnalysingUi>
                    <img src={AnalysingGif} />
                    <TextTransition springConfig={presets.wobbly}><p>{LoadingMessages.funnelLoadingMessages[currentText]}</p></TextTransition>
                </AnalysingUi>
              </PopupBody>
          </PopupContainer>
}

export default LoadingContainer 