import { useEffect, useRef, useState } from "react";
import { useTextAdGenerator } from "../../../../context/textAdGeneration";
import { BrandDescriptionContainer, Container, Description, Header, Label } from "./Description.styled";
import Footer from "./Footer";

function DescriptionPP() {
    const descriptionRef = useRef(null)
    const { textAdGeneratorState, textAdGeneratorDispatch } = useTextAdGenerator()
    const [newDescription, setNewDescription] = useState(textAdGeneratorState.brandDescription)
    console.log(textAdGeneratorState)
    const handleDescriptionValueChange = () => {
        // if(descriptionRef.current)
        // textAdGeneratorDispatch({type: "SET_BRAND_DESCRIPTION", value: descriptionRef.current.value})
        setNewDescription(descriptionRef.current.value)
    }
    useEffect(() => {
        if(descriptionRef.current){
            descriptionRef.current.value = textAdGeneratorState.brandDescription
        }
    }, [])
    return ( 
        <Container>
            <Header>Edit description</Header>
            <BrandDescriptionContainer>
                <Label>Brand/product/service description:</Label>
                <Description placeholder="Enter description of your brand" onChange={handleDescriptionValueChange} defaultValue={textAdGeneratorState.brandDescription} ref={descriptionRef} />
            </BrandDescriptionContainer>
            <Footer newDescription={newDescription} />
        </Container>
     );
}

export default DescriptionPP;