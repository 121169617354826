import React, { useEffect, useState } from "react";
import {
  ContentHeading,
  HistoryContainer,
  TableContainer,
  TableHeader,
  QueryHeader,
  TimeHeader,
  PlatformHeader,
  StatusHeader,
  TableBody,
  Row,
  QueryData,
  TimeData,
  PlatfomrData,
  StatusData,
  ViewAds,
  NoResult,
  SearchNow,
  ActionData,
  ActionHeader,
} from "./index.styled";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  ColumnDef,
  getSortedRowModel,
} from "@tanstack/react-table";

import FilterIcon from "../../../../../media/Dashboard/filter.png";
import UpArrow from "../../../../../media/Dashboard/uparrow.png";
import DownArrow from "../../../../../media/Dashboard/downarrow.png";
import ShareIcon from "../../../../../media/Dashboard/shareData.svg"

import { useLocation } from "react-router-dom";
import { useAccount } from "../../context/account";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";

import _static from "../../../../../config/static";
import { useGetAccount } from "../../../../hooks/useGetAccount";

const { frontendUrl } = _static;

// const UserCrawlRequest = async () => {
//     const config = {
//         url: "/account/crawl-requests",
//         method: "GET"
//     }

//     return appClient(config)
// }

const CrawlRequest = () => {
  const { accountState, accountDispatch } = useAccount();
  const [sorting, setSorting] = useState([]);
  const accountInfoHook = useGetAccount();

  // const [crawlRequest, setCrawlRequest] = useState([])
  const location = useLocation();

  //React table
  const columnHelper = createColumnHelper();
  
  const columns = [
    columnHelper.accessor("no", {
      id: "no",
      header: () => "No",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("query", {
      id: "query",
      header: () => "Query",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => `${new Date(row.createdAt).getTime()}`, {
      id: "createdAt",
      header: () => "Time",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("platform", {
      id: "platform",
      header: () => "Platform",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("searchMode", {
      id: "searchmode",
      header: () => "Search Mode",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("fullfilled", {
      id: "fullfilled",
      header: () => "Crawl Status",
      cell: (info) => info.getValue(),
    }),
  ];

  const table = useReactTable({
    data: accountInfoHook.crawlRequest,
    columns,
    state: {
      sorting,
      sortBy: [{ id: "datetime", desc: false }],
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  // const { mutate: getUserCrawlRequest, isLoading: isCrawlRequestLoading } = useMutation(UserCrawlRequest, {
  //     onSuccess: (data) => {
  //         setCrawlRequest(data)
  //     },
  //     onError: (err) => {

  //     }
  // })

  function buildUrl(baseUrl, params) {
    const queryString = Object.keys(params)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");

    return `${baseUrl}?${queryString}`;
  }

  const redirectToAdsPage = (params) => {
    let paramsObj = {
      phraseInput: params.query ? params.query : "",
      searchMode: params.searchMode ? params.searchMode : "",
      keywordSearchType:
        params.searchMode == "keyword"
          ? params.matchType
            ? params.matchType
            : ""
          : "",
      urlSearchType:
        params.searchMode == "url"
          ? params.matchType
            ? params.matchType
            : ""
          : "",
      phraseInput:
        params.searchMode == "keyword"
          ? params.query
            ? params.query
            : ""
          : "",
      domainUrlInput:
        params.searchMode == "url" ? (params.query ? params.query : "") : "",
      keywords: params.query ? params.query : "",
      page: params.page ? params.page : "",
      excludedTerm: params.exclude ? params.exclude : "",
      searchIn: params.searchIn ? params.searchIn : "",
      selectedCountries: params.countries ? params.countries : "",
      startDate: params.startDate ? params.startDate : "",
      endDate: params.endDate ? params.endDate : "",
      minimumShouldMatch: params.minimumShouldMatch
        ? params.minimumShouldMatch
        : "",
      sortType: "recent",
    };

    // console.log(paramsObj)

    const queryString = Object.keys(paramsObj)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${paramsObj[key] ? encodeURIComponent(paramsObj[key]) : ""
          }`
      )
      .join("&");

    window.open(`${frontendUrl}/adspy/google?${queryString}`);

    // let finalUrl = `https://app.adspyder.io/adspy/google?searchMode=keyword&keywordSearchType=broad&phraseInput=apple&page=1&excludedTerm=aewa&searchIn=title%2Ccontent&sortType=oldest&keywords=apple%2Carea&minimumShouldMatch=1&startDate=2023-08-21&endDate=2023-09-21&selectedCountries=India%2CTurkey`
  };

  useEffect(() => {
    // getUserCrawlRequest()
    accountInfoHook.getUserCrawlRequest();
  }, []);

  useEffect(() => {
    accountDispatch({ type: "SET_CURRENT_PAGE", value: "crawl-requests" })
  }, [])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Crawl Request | AdSpyder</title>
        <meta name="title" content="Crawl Request | AdSpyder" />
        <meta
          name="description"
          content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. "
        />
      </Helmet>
      <ContentHeading>Data Crawl Request</ContentHeading>
      <HistoryContainer>
        {/* <TableContainer>
                    <TableHeader>
                        <NoHeader>
                            No.
                        </NoHeader>
                        <QueryHeader>
                            Query
                        </QueryHeader>
                        <TimeHeader>
                            Time
                        </TimeHeader>
                        <PlatformHeader>
                            Ad Platform
                        </PlatformHeader>
                        <SearchTypeHeader>
                            Search type
                        </SearchTypeHeader>
                        <StatusHeader>
                            Crawl Status
                        </StatusHeader>
                    </TableHeader>
                    <TableBody>
                        {isCrawlRequestLoading ? [...Array(6)].map((data)=>{
                            <Row>
                                <Skeleton/>
                            </Row>
                        }) : 
                        crawlRequest.map((data,index) => {
                            return <Row>
                                    <NumberData>
                                        {index + 1}
                                    </NumberData>
                                    <QueryData>
                                        {data.query}
                                    </QueryData>
                                    <TimeData>
                                        {data.createdAt}
                                    </TimeData>
                                    <PlatfomrData>
                                        {data.platform}
                                    </PlatfomrData>
                                    <SearchTypeData>
                                        {data.searchMode}
                                    </SearchTypeData>
                                    <StatusData status={data.fullfilled}>
                                        {data.fullfilled ? "Complete" : "In Progress"}
                                        <Progress 
                                            value={data.fullfilled ? 100 : 30}
                                            max="100"
                                        />
                                        {data.fullfilled && <ViewAds onClick={()=>redirectToAdsPage(data)}>
                                            View Ads
                                        </ViewAds>}
                                    </StatusData>
                                </Row>
                            }
                        )}
                    </TableBody>
                </TableContainer> */}
        <TableContainer>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <TableHeader>
                {/* <NoHeader>No.</NoHeader> */}
                <TimeHeader
                  onClick={headerGroup.headers[2].column.getToggleSortingHandler()}
                >
                  Date & Time{" "}
                  <img
                    src={
                      headerGroup.headers[2].column.getIsSorted() == "asc"
                        ? UpArrow
                        : headerGroup.headers[2].column.getIsSorted() == "desc"
                          ? DownArrow
                          : FilterIcon
                    }
                  />
                </TimeHeader>
                <PlatformHeader
                  onClick={headerGroup.headers[3].column.getToggleSortingHandler()}
                >
                  Platform{" "}
                  <img
                    src={
                      headerGroup.headers[3].column.getIsSorted() == "asc"
                        ? UpArrow
                        : headerGroup.headers[3].column.getIsSorted() == "desc"
                          ? DownArrow
                          : FilterIcon
                    }
                  />
                </PlatformHeader>
                <QueryHeader
                  onClick={headerGroup.headers[1].column.getToggleSortingHandler()}
                >
                  Query{" "}
                  <img
                    src={
                      headerGroup.headers[1].column.getIsSorted() == "asc"
                        ? UpArrow
                        : headerGroup.headers[1].column.getIsSorted() == "desc"
                          ? DownArrow
                          : FilterIcon
                    }
                  />
                </QueryHeader>
                {/* <SearchTypeHeader
                  onClick={headerGroup.headers[4].column.getToggleSortingHandler()}
                >
                  Search type{" "}
                  <img
                    src={
                      headerGroup.headers[4].column.getIsSorted() == "asc"
                        ? UpArrow
                        : headerGroup.headers[4].column.getIsSorted() == "desc"
                          ? DownArrow
                          : FilterIcon
                    }
                  />
                </SearchTypeHeader> */}
                <StatusHeader
                  onClick={headerGroup.headers[5].column.getToggleSortingHandler()}
                >
                  Crawl Status{" "}
                  <img
                    src={
                      headerGroup.headers[5].column.getIsSorted() == "asc"
                        ? UpArrow
                        : headerGroup.headers[5].column.getIsSorted() == "desc"
                          ? DownArrow
                          : FilterIcon
                    }
                  />
                </StatusHeader>
                <ActionHeader>Actions</ActionHeader>
              </TableHeader>
            );
          })}
          
          <TableBody>
            {accountInfoHook.isCrawlRequestLoading ? (
              [...Array(6)].map((data) => {
                <Row>
                  <Skeleton />
                </Row>;
              })
            ) : table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map((row, index) => {
                return (
                  <Row>
                    {/* <NumberData>{index + 1}</NumberData> */}
                    <TimeData>{row.original.createdAt}</TimeData>
                    <PlatfomrData>{row.original.platform}</PlatfomrData>
                    <QueryData>{row.original.query}</QueryData>
                    {/* <SearchTypeData>{row.original.searchMode}</SearchTypeData> */}
                    <StatusData status={row.original.fullfilled}>
                      <p>{row.original.fullfilled ? "Complete" : "In Progress"}</p>
                      
                      {/* <Progress 
                          value={row.original.fullfilled ? 100 : 30}
                          max="100"
                      /> */}
                    </StatusData>
                    <ActionData>
                      {row.original.fullfilled && (
                        <ViewAds
                          onClick={() => redirectToAdsPage(row.original)}
                        >
                          View Ads
                        </ViewAds>
                      )}
                      {(row.original.platform == "google" ||
                        row.original.platform == "bing") &&
                        row.original.searchMode == "keyword" && (
                          <SearchNow
                            className="search-now-btn"
                            onClick={() => {
                              window.open(
                                `${frontendUrl}/keyword-analysis/details?query=${row.original.query}&page=1&type=keyword`
                              );
                            }}
                          >
                            <img src={ShareIcon}/>
                          </SearchNow>
                        )}
                    </ActionData>
                  </Row>
                );
              })
            ) : (
              <Row>
                <NoResult>No data available</NoResult>
              </Row>
            )}
          </TableBody>
        </TableContainer>
      </HistoryContainer>
    </>
  );
};

export default CrawlRequest;
