import React from "react";
import { AdDetailsLabel, Container, InputName, ChangeButton, Wrapper, UpdateButton } from "./DetailsList.styled"
import statics from "../../../statics";
import { useImageAdGenerator } from "../../../context/imageAdGeneration";
import { useMutation } from "@tanstack/react-query";
import { appClient } from "../../../../../../../utils/axios-utils";
import { useSearchParams, useNavigate,useLocation } from "react-router-dom";
import { getSearchParams } from "../../../../../../../utils/helper";
import { useImageAdInfo } from "../../../hooks/useImageAdInfo";
const updateGenerateFn = async (data) => {
    const config = {
        url: `/adgeneration/image/save-campaign-details`,
        method: "POST",
        data
    }
    return appClient(config)
}
const updateCategoryFn = async (data) => {
    const config = {
        url: `/adgeneration/image/update-campaign-details`,
        method: "POST",
        data
    }
    return appClient(config)
}
const DetailsList = () => {
    const navigate = useNavigate()
    const { imageAdGeneratorState, imageAdGeneratorDispatch } = useImageAdGenerator()
    const [searchParams, setSearchParams] = useSearchParams()
    const location = useLocation()
    const { customizeItems, customizeNavItems } = statics  
    const useImageAdInfoHook = useImageAdInfo()

    const setActiveCustomizePopup = (item) => {
        imageAdGeneratorDispatch({
            type: "UPDATE_ACTIVE_CUSTOMIZE_POPUP",
            value: item
        })
    }
    const {mutate: getUpdateGenerateFn, isLoading: isUpdateGenerateFnLoading} = useMutation(updateGenerateFn, {
        onSuccess: (data) => {
            // imageAdGeneratorDispatch({type: "UPDATE_RESULT_GENERATED_FLAG", value: true})

            // imageAdGeneratorDispatch({type: "SET_SAVED_CAMPAIGN_ID", value: data.campaignId.id})
            // searchParams.set('id',data.campaignId.id)
            // setSearchParams(searchParams);
            imageAdGeneratorDispatch({type: "AD_CATEGORY_UPDATE", value: false})
            navigate(`/adgenerator/image-ad/result?id=${data.campaignId.id}`)
        },
        onError:  ((e)=>{
        
        })
    })
    const {mutate: getUpdateCategoryFn, isLoading: isUpdateCategoryFnLoading} = useMutation(updateCategoryFn, {
        onSuccess: (data) => {
            // imageAdGeneratorDispatch({type: "UPDATE_RESULT_GENERATED_FLAG", value: true})

            // imageAdGeneratorDispatch({type: "SET_SAVED_CAMPAIGN_ID", value: data.campaignId.id})
            // searchParams.set('id',data.campaignId.id)
            // setSearchParams(searchParams);
            // navigate(`/adgenerator/image-ad/result?id=${data.campaignId.id}`)
            imageAdGeneratorDispatch({ type: "SET_CURRENT_TEMPLATE", value: null })
            imageAdGeneratorDispatch({ type: 'RESET_PAGE' })
    
            const { id } = getSearchParams(location.search)

            imageAdGeneratorDispatch({ type: "SET_CAMPAIGNID", value: id })
            useImageAdInfoHook.GetCampaignDetailsMutate({ id })
        },
        onError:  ((e)=>{
        
        })
    })
const imageAdUpdate = () =>{
    if(imageAdGeneratorState.adCategoryUpdate){
        getUpdateGenerateFn({
            category: imageAdGeneratorState.category,
            adGoal: imageAdGeneratorState.adGoal,
            adGoal: imageAdGeneratorState.adGoal,
            ageGroup: imageAdGeneratorState.targetAudience.ageGroup,
            gender: imageAdGeneratorState.targetAudience.gender,
            brandName: imageAdGeneratorState.brandDetails.name,
            brandDescription: imageAdGeneratorState.brandDetails.description,
            adType: imageAdGeneratorState.adType === 'Educational or\nInformational' ? 'Educational' : imageAdGeneratorState.adType
        })
    }else{
    getUpdateCategoryFn({
        campaignId: imageAdGeneratorState.campaignId,
        adGoal: imageAdGeneratorState.adGoal,
        ageGroup: imageAdGeneratorState.targetAudience.ageGroup,
        gender: imageAdGeneratorState.targetAudience.gender,
        brandName: imageAdGeneratorState.brandDetails.name,
        brandDescription: imageAdGeneratorState.brandDetails.description,
        adType: imageAdGeneratorState.adType === 'Educational or\nInformational' ? 'Educational' : imageAdGeneratorState.adType
    })
    }
    
}

    return <Wrapper>
        <AdDetailsLabel>Ad details:</AdDetailsLabel>
        {
            customizeItems.map((item) => {
                return (
                    <Container>
                        <InputName>{item.name}</InputName>
                        <ChangeButton onClick={() => setActiveCustomizePopup(item)} >Change</ChangeButton>
                    </Container>
                )
            })
        }
        <UpdateButton isActive={imageAdGeneratorState.isAdDetailsUpdated} onClick={imageAdUpdate}>Update</UpdateButton>
    </Wrapper>
}

export default DetailsList