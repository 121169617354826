import styled from "styled-components";

export const Wrapper = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  h2 {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    color: #1c1c1c;
  }
`;

export const ImageDataContainer = styled.div`
  border-bottom: solid 1px #e8e8e8;
  padding: 16px 12px;
`;

export const Header = styled.div`
  margin-bottom: 16px;
  label {
    font-size: 16px;
    font-weight: 400;
    color: #1c1c1c;
  }
`;

export const RecomendedSize = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  margin: 0px 10px;
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  img {
    width: 124px;
    height: 70px;
    /* object-fit: contain; */
    border: solid 1px #d4d4d4;
  }
  label {
    border-radius: 8px;
    border: solid 1px #e3e3e3;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    color: #1c1c1c;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

export const SelectImageContainer = styled.div`
  display: flex;
  margin-top: 30px;
  gap: 12px;

  label {
    border-radius: 4px;
    border: solid 1px #e3e8ee;
    background: #f7f7f9;
    padding: 8px 12px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: rgba(28, 28, 28, 0.7);
    gap: 10px;
    cursor: pointer;
  }

  img {
    height: 15px;
  }
`;

export const UploadStockImageBtn = styled.div`
  border-radius: 4px;
  border: solid 1px #e3e8ee;
  background: #f7f7f9;
  padding: 8px 12px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: rgba(28, 28, 28, 0.7);
  gap: 10px;
  cursor: pointer;
`;

export const AiImageUploadBtn = styled.div`
  border-radius: 4px;
  border: 1px #e3e8ee;
  background: linear-gradient(180deg, #6907ba 0%, #d057c6 100%);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  height: 30px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  gap: 10px;
  cursor: pointer;
`;

export const StockImageContainer = styled.div`
  h2 {
    font-size: 16px;
    font-weight: 400;
    color: #000;
  }
`;

export const BackContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 24px 0px;
  img {
    cursor: pointer;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: #161616;
  }
`;

export const SearchbarContainer = styled.div`
  display: flex;
  gap: 8px;
  div {
    border-radius: 6px;
    border: solid 1px #dadfe4;
    height: 44px;
    width: 296px;
    display: flex;
    gap: 5px;
    img {
      padding: 14px 2px 14px 25px;
    }
  }
  input {
    height: 100%;
    border: none;
    outline: none;
    width: 80%;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const SearchButton = styled.button`
  height: 44px;
  width: 44px;
  outline: none;
  border: none;
  border-radius: 6px;
  background: #ff711e;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.isDisabled ? "0.5" : "1")};
  pointer-events: ${(props) => (props.isDisabled ? "none" : "all")};
  img {
    height: 16px;
  }
`;

export const ImgWrapper = styled.div`
  display: grid;
  gap: 12px;
  overflow-y: auto;
  width: 100%;
  padding-right: 6px;
  height: 340px;
  margin-top: 40px;
  img {
    cursor: pointer;
  }
  ::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(185 185 185); /* color of the scroll thumb */
    border-radius: 10px;
  }
  /* margin-top: 24px; */
  img {
    height: fit-content;
    width: 100%;
  }
`;
export const CustomDiv = styled.div`
  height: 100px; /* default height */
`;

export const OddWidthDiv = styled(CustomDiv)`
  width: 100%;
  height: 180px;
`;

export const EvenWidthDiv = styled(CustomDiv)`
  width: 100%;
  height: 130px;
`;
