import { Route, Routes } from 'react-router-dom'
import { AdManagementProvider } from "./context/AdManagementContext";
import { ImageAdGeneration } from "./index.styled";
import Root from "./components";
import NotFound from '../../NotFoundPage';
import NewCampaign from './NewCampaign';


function AdManagementComponentWrapper() {
    return (
        <AdManagementProvider>
            <ImageAdGeneration>
                <Routes>
                    <Route path='*' element={
                        <NotFound />
                    } />
                    <Route path="/" element={<Root />} />
                    <Route path="new-campaign" element={
                        <NewCampaign />
                } />
                </Routes>
            </ImageAdGeneration>
        </AdManagementProvider>
    );
}

export default AdManagementComponentWrapper;