import React from "react";
import {
  BrandContent,
  BrandDescription,
  BrandDetailContainer,
  BrandDetailWrap,
  BrandName,
} from "./index.styled";
import { useTextAdGenerator } from "../../../../../../../../context/textAdGeneration";
import {useAdGenerator} from "../../../../../../../../../context/adGeneration"

const BrandDetails = () => {
  const {
    textAdGeneratorState,
    textAdGeneratorDispatch,
  } = useTextAdGenerator();
  const { adGeneratorState: {isAdDetails}, adGeneratorDispatch } = useAdGenerator()

  const dataUpdating = (updatedData) => {
    adGeneratorDispatch({
      type: "SET_AD_DETAILS",
      value: updatedData,
    });
  };

  const updateBrandDescrip = (e) => {
    const updatedDescrip = {
      ...isAdDetails,
      adDetails: {
        ...isAdDetails.adDetails,
        brandDescription: e.target.value,
      },
    };
    dataUpdating(updatedDescrip);
  };
  const updateBrandSummary = (e) => {
    const updatedSummary = {
      ...isAdDetails,
      adDetails: {
        ...isAdDetails.adDetails,
        pageDetailSummary: e.target.value,
      },
    };

    dataUpdating(updatedSummary);
  };
  const updateBrandName = (e) => {
    const updatedBrand = {
      ...isAdDetails,
      adDetails: {
        ...isAdDetails.adDetails,
        brandName: e.target.value,
      },
    };

    dataUpdating(updatedBrand);
  };
  return (
    <BrandDetailWrap>
      <BrandDetailContainer>
        <BrandName>Brand name</BrandName>
        <BrandContent>
          <input
            value={isAdDetails.adDetails.brandName}
            onChange={(e) => updateBrandName(e)}
          />
        </BrandContent>
      </BrandDetailContainer>
      <BrandDetailContainer>
        <BrandName>About the brand</BrandName>
        <BrandContent>
          <BrandDescription>
            <textarea
              value={isAdDetails.adDetails.brandDescription}
              onChange={(e) => updateBrandDescrip(e)}
            />
          </BrandDescription>
        </BrandContent>
      </BrandDetailContainer>
      <BrandDetailContainer>
        <BrandName>Product details</BrandName>
        <BrandContent>
          <BrandDescription>
            <textarea
              onChange={(e) => updateBrandSummary(e)}
              value={isAdDetails.adDetails.pageDetailSummary}
            />
          </BrandDescription>
        </BrandContent>
      </BrandDetailContainer>
    </BrandDetailWrap>
  );
};

export default BrandDetails;
