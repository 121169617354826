import styled from "styled-components"

export const Container = styled.div`
    // width: 100%;
    // height: 100%;
    // border-radius: 6px;
    // border: 1px solid #DADFE4;
    // /* margin-left: 8px; */
    // background-color: white;
    // margin-right: 8px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // z-index: 1;

    // border: 1px solid #ECECEC;
    // outline: none;
    // &:focus{
    //     border: 1px solid #5AB3FF;
    //     outline: 2px solid #B9DDFF;
    // }

    // :hover {
    //     border: "";
    // }

    // cursor: pointer;
    // position: relative;

    //  p {
    //     font-size: 14px;
    //  }
    border-radius: 8px;
    border: ${(props) => props.isDropdownOpened ? "1px solid #FFB258" : "1px solid #D0D5DD" };
    box-shadow: ${(props) => props.isDropdownOpened ? "0px 1px 2px 0px rgba(28, 28, 28, 0.05), 0px 0px 4px 0px rgba(255, 178, 88, 0.25)" : "unset"};
    background: #FFF;
    cursor: pointer;
    height: 44px;
    display: flex;

    position: relative;
    width: 125px;

`;

export const Text = styled.div`
color: ${(props) => props.platformSelected ? "rgba(28, 28, 28, 0.90)" : "rgba(28, 28, 28, 0.50)"} ;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
`;

export const Drop = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    padding: 0 8px;
    >span{
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: #1c1c1c;
        margin-left: 8px;
        width: calc(100% - 40px);
        p{
            display: inline-block;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: start;
        }
    }
    
    >p{
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: rgba(28, 28, 28, 0.70);
        margin-left: 8px;
    }

    .dropdown-arrow{
        margin-right: ${props => props.error ? "40px": "8px"};
        height: 20px;
        width: 20px;
    }

    .platform-logo{
        height: 16px;
    }
`;

export const Down = styled.div`
    position: fixed;
    z-index: 2;
    left: ${props => props.left+"px"};
    top: ${props => props.top+"px"};
    width: 150px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 2px 6px #0000001A;
    padding: 4px;
    cursor: auto;
    display: ${props => props.show ? 'flex': 'none'};
    flex-direction: column;
    overflow-y: auto;
    max-height: 100px;

    ::-webkit-scrollbar {
        width: 5px;               
    }

    body::-webkit-scrollbar-track {
        
    }

    ::-webkit-scrollbar-thumb {
        background-color: #80808030;    
        border-radius: 10px;     
    }
`;

export const Row1 = styled.div`
    width: 100%;
    height: 32px;
    padding: 10px;

    display: flex;
    align-items: center;

    p {
        color: #1c1c1c;
    }
`;

export const Row = styled(Row1)`
    cursor: pointer;
    min-height: 32px;
    height: auto;
    transition: 0.2s all ease-in-out;
    border-radius: 4px;
    padding: 8px;
    opacity: ${props => props.disabled ? "40%" : 1};
    pointer-events: ${props => props.disabled ? "none" : ""};
    background: ${props => props.disabled ? "#grey" : ""};;
    img{
        height: 12px;
        margin-right: 8px;
    }

    :hover {
        background-color: #F9FAFB;
    }

    p {
        color: rgba(28, 28, 28, 0.90);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
`;

export const Separator = styled.div`
    width: 10px;
    border-bottom: 1px solid #ECECEC;
    margin-left: 10px;
`;

export const Wrapper = styled.div`
    /* width: 35%; */
    width: 140px;
    max-width: 140px;
    height: 100%;
`;

export const ToolTipImageContainer = styled.div`
    display: ${props => props.error ? "flex": "none"};
    img{
        filter: contrast(1);
    }

    &:hover{
        > div{
            display: flex;
        }

        img{
            display: block;
        }
    }
`;

export const ToolTip = styled.div`
    background: #555555;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 300;
    position: absolute;
    bottom: -34px;
    padding: 4px 8px;
    width: 170px;
    display: none;
`;