import React from "react";
import Highlighter from "react-highlight-words"

import { Card, VideoContainer, CardBody, VideoTitle, ChannelDetails, LogoContainer, ChannelName, VideoDescription, FloatingButtonsContainer,CardFooter, FooterLeft, AdDate, AdCountries, FooterRight, PublishedOn, DetailsButton, SelectButton, AdTypeContainer } from "./index.styled"

import FavorietIcon from "../../../../../../media/Dashboard/addFavorite.svg"
import WebsiteIcon from '../../../../../../media/Dashboard/websiteicon.png'
import YouTubeIcon from "../../../../../../media/Dashboard/YouTube16.svg"

import GlobeIcon from '../../../../../../media/Dashboard/globe.svg';
import DateIcon from "../../../../../../media/Images/DateIcon.svg"
import CountryIcon from "../../../../../../media/Images/CountryIcon.svg"
import { useAdGenrator } from '../../../context/adgenerator';

const YoutubeCard = ({ad, ToggleSelectedAds}) => {
    const {adGenratorState, adGenratorDispatch} = useAdGenrator()

    const convertTimestamp = (timestamp) => {
        // Create a new Date object based on the timestamp
        var date = new Date(timestamp);
      
        // Get the month name
        var monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        var month = monthNames[date.getMonth()];
      
        // Get the year
        var year = date.getFullYear();
      
        // Assemble the formatted date string
        var formattedDate = date.getDate() + ' ' + month  + ' ' + year;
      
        return formattedDate;
    }

    return <Card key={
        ad.id
    }>
        <VideoContainer>
            <iframe width="100%" height="243" style={{borderRadius: "11px 11px 0px 0px", border: "none"}}
                src={`https://www.youtube.com/embed/${ad.videoId}`}>
            </iframe>
        </VideoContainer>
        <CardBody>
            <VideoTitle>
                {ad.title}
            </VideoTitle>
            <ChannelDetails>
                <LogoContainer>
                    <img src={WebsiteIcon}/>
                </LogoContainer>
                <ChannelName>
                    {ad.channel}
                </ChannelName>
            </ChannelDetails>
            <VideoDescription>
                {ad.content}
            </VideoDescription>
            <AdTypeContainer>
                <img src={YouTubeIcon}/> YouTube 
                {/* | <img src={ClockIcon}/>  */}
            </AdTypeContainer>
            <FloatingButtonsContainer>
                <SelectButton type="checkbox" id="selected" name="selected" onClick={()=>ToggleSelectedAds(ad.id)} checked={adGenratorState.selectedAds.includes(ad.id)}/>
                {/* <Favorite isFav={idx == 1}>
                    <img src={FavorietIcon}/>
                </Favorite>
                |
                <DropdownIcon>
                    <span/>
                </DropdownIcon> */}
            </FloatingButtonsContainer>
        </CardBody>
        <CardFooter>
            <FooterLeft>
                <AdDate>
                {ad.firstSeen && ad.lastSeen && <img src={DateIcon}/>}{convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
                </AdDate>
                {ad.country && <AdCountries>
                    <img src={CountryIcon}/>{ ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
                </AdCountries>}
            </FooterLeft>
            <FooterRight>
                <PublishedOn>
                    <label>Published on:</label>
                    {convertTimestamp(ad.publishedOn)}
                </PublishedOn>
                <DetailsButton>
                    {/* {(urlAnalysisState.adDetails.id == ad.id) && urlAnalysisState.detailsModal ? "Close details" : "More details"} */}
                </DetailsButton>
            </FooterRight>
        </CardFooter>
    </Card>
} 

export default YoutubeCard