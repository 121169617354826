import React, { useRef, useEffect, useState } from 'react'
import {
    useMutation
} from "@tanstack/react-query"
import NoResultsLogo from "../../../../../../../media/Dashboard/noresults.svg"
import { useRequestData } from "../../../../../../hooks/useRequestData"
import ConfettiExplosion from 'react-confetti-explosion';

// Styles
import {
    CardBody,
    Card,
    CardsList,
    ResultsContainer,
    ResultsWraper,
    VideoContainer,
    ImageContainer,
    ChannelDetails,
    LogoContainer,
    ChannelName,
    Description,
    SecondaryDetails,
    InnerContent,
    InnerTitle,
    CallToActionButton,
    CTA,
    NoResults,
    RequestDataBtn,
    BackToSearchContainer,
    AdCount,
    AdCountWrap,
    CrawlRequestGreetContainer,
    ContentContainer,
    CardContainer
} from "./index.styled"
import SearchLoader from '../../../../../../HOC/SearchLoader'

import GlobeIcon from '../../../../../../../media/Dashboard/globe.svg';
import { useFacebook } from "../../context/facebook"
import TickImg from '../../../../../../../media/Dashboard/tick-grey.svg'

import CrawlSuccessTick from "../../../../../../../media/Dashboard/CrawlSuccessTick.svg"

import AltImage from '../../../../../../../media/Dashboard/altimage.png'
import { appClient } from '../../../../../../../utils/axios-utils'
import LimitExceededPopup from '../../../../../../components/limitExceededPopup'
import PlatformRestrictedPopup from '../../../../../../components/platformRestrictionPopup'
import { useAuth } from '../../../../../../../context/auth'
import { useSearch } from '../../../context/search'
import AdDetails from '../../../../../../components/AdDetailsModal'
import Pagination from '../Pagination'
import { useNavigate } from 'react-router-dom'

const adDetails = async (id) => {
    const config = {
        url: `/detail/facebook/${id}`,
        method: "GET"
    }

    return appClient(config)
}

const convertTimestamp = (timestamp) => {
    if (!timestamp) {
        return "-"
    }
    // Create a new Date object based on the timestamp
    var date = new Date(Number(timestamp));

    // Get the month name
    var monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    var month = monthNames[date.getMonth()];

    // Get the year
    var year = date.getFullYear();

    // Assemble the formatted date string
    var formattedDate = date.getDate() + ' ' + month + ' ' + year;

    return formattedDate;
}

const CardElement = ({ ad, idx, basicAdDetails, setBasicAdDetails, containerRef }) => {

    const { facebookState, facebookDispatch } = useFacebook()
    const { authState, authDispatch } = useAuth()

    const scrollToElement = (index) => {
        const element = containerRef.current.children[index];
        const scrollPosition = element.offsetTop - 200;
        containerRef.current.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    };

    const { mutate: adsDetailsMutate, isLoading: isDetailsLoading } = useMutation(adDetails, {
        onSuccess: (data) => {
            facebookDispatch({ type: 'SET_AD_DETAILS', value: data })
            if (facebookState.adDetails.id == data.id || !facebookState.adDetails.id) {
                facebookDispatch({ type: 'UPDATE_DETAILS_MODAL', value: !facebookState.detailsModal })
            }
        },
        onError: ((e) => {
            console.log(e)
        })
    })

    const toggleDetailsModal = (ad, idx) => {
        if ((facebookState.adDetails.id !== ad.id) && facebookState.detailsModal) {
            //if details is open and clicked on close button on ads card
            facebookDispatch({ type: 'SET_AD_DETAILS', value: ad })
        } else {
            facebookDispatch({ type: 'SET_AD_DETAILS', value: ad })
            facebookDispatch({ type: 'UPDATE_DETAILS_MODAL', value: !facebookState.detailsModal })
        }
        setTimeout(() => {
            scrollToElement(idx)
        }, 100);
    }

    const onSave = (ad) => {
        authDispatch({ type: 'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'meta' })
        authDispatch({ type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id })
        authDispatch({ type: 'UPDATE_SAVE_AD_POPUP', value: true })
    }

    return  <Card key={
        ad.id
    }>
        <CardContainer>
            {/* <HoverCard className='hover-element' onClick={() => toggleDetailsModal(ad, idx)
            }>
                <HoverCardWrapper>
                    <AdDetailsButton onClick={() => toggleDetailsModal(ad, idx)}>
                        <img src={RightUpperBlackArrow} />
                        <span>Ad Details</span>
                    </AdDetailsButton>
                    <SaveAdsButton isSaved={authState.savedAdsIds.includes(ad.id)} onClick={(e) => {
                        e.stopPropagation();
                        if (authState.savedAdsIds.includes(ad.id)) {
                            // onRemoveSavedAd(ad)
                        } else {
                            onSave(ad);
                        }

                    }}>
                        {authState.savedAdsIds.includes(ad.id) ? "Saved" : "Save"}
                    </SaveAdsButton>
                </HoverCardWrapper>
            </HoverCard> */}
            <CardBody detailsOpen={facebookState.adDetails.id == ad.id} >
                <ChannelDetails>
                    <LogoContainer>
                        <img src={ad.logo ? `https://aws-adspyder-logos.s3.ap-south-1.amazonaws.com/${ad.id}` : GlobeIcon} />
                    </LogoContainer>
                    <ChannelName>
                        {ad.title}
                        <span>Sponsored</span>
                    </ChannelName>
                </ChannelDetails>
                <Description>
                    {`${convertTimestamp(ad.lastSeen)} __ ${ad.content}`}
                </Description>
                {/* <FloatingButtonsContainer>
                    <Favorite isFav={idx == 1} onClick={()=>{
                        authDispatch({type:'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'facebook'})
                        authDispatch({type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id})
                        authDispatch({type:'UPDATE_SAVE_AD_POPUP', value: true})}}>
                        <img src={authState.favAdsList["facebook"]?.includes(ad.id) ? FavouriteIconActive : FavorietIcon}/>
                    </Favorite> */}
                {/* |
                    <DropdownIcon>
                        <span/>
                    </DropdownIcon> */}
                {/* </FloatingButtonsContainer> */}
                {ad.image?.length > 0 && <ImageContainer>
                     <img src={ad.image[0]} />
                 </ImageContainer>}
                 {ad.video?.length > 0 && <VideoContainer>
                     <video src={ad.video[0]} preload="auto" controls loop style={{ width: "100%", height: "100%" }} />
                 </VideoContainer>}
                 {(ad.video?.length == 0 && ad.image?.length == 0) && <ImageContainer noImage={true}>
                     <img src={AltImage} />
                 </ImageContainer>}
                {(ad.ctaContent || ad.ctaButton) && <SecondaryDetails>
                    <InnerContent>
                        {/* <InnerUrl>
                        {ad.ctaUrl}
                    </InnerUrl> */}
                        {/* <InnerDesc>
                        {ad.ctaTitle}
                    </InnerDesc> */}
                        <InnerTitle>
                            {ad.ctaContent}
                        </InnerTitle>
                    </InnerContent>
                    <CallToActionButton>
                        {ad.ctaButton && <CTA>
                            {ad.ctaButton}
                        </CTA>}
                    </CallToActionButton>
                </SecondaryDetails>}
            </CardBody>
        </CardContainer>
        {/* <CardFooter> */}
        {/* <FooterLeft>
                <AdDate>
                {ad.firstSeen && ad.lastSeen && <img src={DateIcon}/>} */}
        {/* {convertTimestamp(ad.firstSeen)} -  */}
        {/* {convertTimestamp(ad.lastSeen)}
                </AdDate>
                <AdCountries>
                    {ad.country.length > 0 && <img src={CountryIcon} />}{ ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
                </AdCountries>
            </FooterLeft>
            <FooterRight> */}
        {/* <DetailsButton onClick={()=>toggleDetailsModal(ad,idx)}>
                {(facebookState.adDetails.id == ad.id) && facebookState.detailsModal ? "Close details" : "More details"}
                </DetailsButton> */}
        {/* </FooterRight> */}
        {/* </CardFooter> */}
    </Card>
    // <Card detailsOpen={facebookState.adDetails.id == ad.id} key={
    //     ad.id
    // }>
    //     {/* <HoverCard className='hover-element' onClick={() => toggleDetailsModal(ad, idx)
    //     }>
    //         <HoverCardWrapper>
    //             <AdDetailsButton onClick={() => toggleDetailsModal(ad, idx)}>
    //                 <img src={RightUpperBlackArrow} />
    //                 <span>Ad Details</span>
    //             </AdDetailsButton>
    //             <SaveAdsButton onClick={() => onSave(ad)
    //             }>
    //                 Save
    //             </SaveAdsButton>
    //         </HoverCardWrapper>
    //     </HoverCard> */}
    //     <CardBody>
    //         <ChannelDetails>
    //             <LogoContainer>
    //                 <img src={ad.logo ? `https://aws-adspyder-logos.s3.ap-south-1.amazonaws.com/${ad.id}` : GlobeIcon} />
    //             </LogoContainer>
    //             <ChannelName>
    //                 {ad.title}
    //                 <span>Sponsored</span>
    //             </ChannelName>
    //         </ChannelDetails>
    //         <Description>
    //             {ad.content}
    //         </Description>
    //         <FloatingButtonsContainer>
    //             {/* <Favorite isFav={idx == 1} onClick={() => {
    //                 authDispatch({ type: 'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'facebook' })
    //                 authDispatch({ type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id })
    //                 authDispatch({ type: 'UPDATE_SAVE_AD_POPUP', value: true })
    //             }}>
    //                 <img src={authState.favAdsList["facebook"]?.includes(ad.id) ? FavouriteIconActive : FavorietIcon} />
    //             </Favorite> */}
    //             {/* |
    //             <DropdownIcon>
    //                 <span/>
    //             </DropdownIcon> */}
    //         </FloatingButtonsContainer>
    //         {ad.image?.length > 0 && <ImageContainer>
    //             <img src={ad.image[0]} />
    //         </ImageContainer>}
    //         {ad.video?.length > 0 && <VideoContainer>
    //             <video src={ad.video[0]} preload="auto" controls loop style={{ width: "100%", height: "100%" }} />
    //         </VideoContainer>}
    //         {(ad.video?.length == 0 && ad.image?.length == 0) && <ImageContainer noImage={true}>
    //             <img src={AltImage} />
    //         </ImageContainer>}
    //         <SecondaryDetails>
    //             <InnerContent>
    //                 <InnerUrl>
    //                     {ad.ctaUrl}
    //                 </InnerUrl>
    //                 <InnerDesc>
    //                     {ad.ctaTitle}
    //                 </InnerDesc>
    //                 <InnerTitle>
    //                     {ad.ctaContent}
    //                 </InnerTitle>
    //             </InnerContent>
    //             <CallToActionButton>
    //                 {ad.ctaButton && <CTA>
    //                     {ad.ctaButton}
    //                 </CTA>}
    //             </CallToActionButton>
    //         </SecondaryDetails>
    //     </CardBody>

    //     <CardFooter>
    //         <FooterLeft>
    //             <AdDate>
    //                 {ad.firstSeen && ad.lastSeen && <img src={DateIcon} />}
    //                 {/* {convertTimestamp(ad.firstSeen)} -  */}
    //                 {convertTimestamp(new Date(ad.lastSeen).getTime())}
    //             </AdDate>
    //             <AdCountries>
    //                 {ad.country && ad.country.length > 0 && <img src={CountryIcon} />}
    //                 {ad.country?.length == 1 ? ad.country[0] : ad.country?.length > 1 ? ad.country[0] + " + " + (ad.country?.length - 1) : ""}
    //             </AdCountries>
    //         </FooterLeft>
    //         <FooterRight>
    //             {/* <DetailsButton onClick={()=>toggleDetailsModal(ad,idx)}>
    //             {(facebookState.adDetails.id == ad.id) && facebookState.detailsModal ? "Close details" : "More details"}
    //             </DetailsButton> */}
    //         </FooterRight>
    //     </CardFooter>
    // </Card>
}

const CrawlRequestResults = () => {
    const { facebookState, facebookDispatch } = useFacebook()
    const [basicAdDetails, setBasicAdDetails] = useState({})
    const useRequestDataHook = useRequestData()
    const navigate = useNavigate()
    const [ greetingScreenOpen, setGreetingScreenOpen ] = useState(true)
    const { authState, authDispatch } = useAuth()
    const { searchState, searchDispatch } = useSearch()

    const containerRef = useRef(null);

    const getSearchTerm = () => {
        const { phraseSearchIn, broadSearchIn} = facebookState
        let fields

        if(searchState.keywordSearchType == "phrase"){
            fields = phraseSearchIn.filter((obj) => {
                return obj.checked && obj.value
            }).map((obj) => obj.value)
        }else{
            fields = broadSearchIn.filter((obj) => {
                return obj.checked && obj.value
            }).map((obj) => obj.value)
        }
        
        if(fields.length == 0){
            return facebookDispatch({type: 'SET_SEARCH_IN_ERROR', value: true})
        }

        let paramSearchIn = fields.join(",")

        return paramSearchIn
    }
    const onDataRequest = () => {
        if (!useRequestDataHook.dataRequested) {
            useRequestDataHook.requestDataMutate({ id: facebookState.queryId })
        }
    }

    const onDetailsClose = () => {
        setBasicAdDetails({})
        facebookDispatch({ type: 'SET_AD_DETAILS', value: {} })
        facebookDispatch({ type: 'UPDATE_DETAILS_MODAL', value: false })
    }

    function findIndexById(arr, searchId) {
        for (let i = 0; i < facebookState.ads.length; i++) {
            if (arr[i].id === searchId) {
                return i; // Return the index when the ID is found
            }
        }
        return -1; // Return -1 if ID is not found in the array
    }

    const navigateTo = (adId, toAd) => {
        console.log("navigateTo", adId, toAd)
        let adIndex = findIndexById(adId)
        console.log(adIndex)
        if (adIndex != -1) {
            if (toAd == "next") {
                console.log("inside next")
                facebookDispatch({ type: 'UPDATE_DETAILS_MODAL', value: true })
                setBasicAdDetails(facebookState.ads[adIndex + 1])
                facebookDispatch({ type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: true })
            } else if (toAd == "back") {
                console.log("inside back")
                facebookDispatch({ type: 'UPDATE_DETAILS_MODAL', value: true })
                setBasicAdDetails(facebookState.ads[adIndex - 1])
                facebookDispatch({ type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: true })
            }
        }
    }

    const onSave = (ad) => {
        authDispatch({ type: 'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'meta' })
        authDispatch({ type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id })
        authDispatch({ type: 'UPDATE_SAVE_AD_POPUP', value: true })
    }

    useEffect(() => {
        setTimeout(() => {
            setGreetingScreenOpen(false)
        }, 2000);
    }, [])

    if (authState.isRestrictedPopup) {
        return <ResultsContainer restricted={true} detailsModal={facebookState.detailsModal}>
            <PlatformRestrictedPopup err={searchState.searchError} />
        </ResultsContainer>
    }

    if (searchState.limitExceeded) {
        return <ResultsContainer restricted={true} detailsModal={facebookState.detailsModal}>
            <LimitExceededPopup err={searchState.searchError} />
        </ResultsContainer>
    }

    return <ResultsContainer resultLoading={facebookState.loadingResults} noResults={facebookState.noResults} detailsModal={facebookState.detailsModal}>
        {greetingScreenOpen 
            ? 
                <CrawlRequestGreetContainer>
                    <img src={CrawlSuccessTick}/>
                    <h3>Results Found<ConfettiExplosion />  </h3>
                    <div>
                        Great news! 🎉 . We've found results matching
                        your search query “<span>{searchState.keywordSearchType == "phrase" ? searchState.phraseInput : searchState.keywordSearchType == "broad" ? searchState.keywords.join(" "): ""}</span>”.
                    </div>
                </CrawlRequestGreetContainer> 
            :
                <ContentContainer greetingScreenOpen={greetingScreenOpen}>
                    <BackToSearchContainer>
                        Your persistence paid off! Keep exploring or try another <span onClick={()=>navigate('/adspy')}>Search</span>
                    </BackToSearchContainer>
                    <h2>Text Search</h2>
                    <AdCount>
                        <AdCountWrap>
                            Over <span>{
                                facebookState.totalAds
                            }</span> resuls for <p>"{searchState.keywordSearchType == "phrase" ? searchState.phraseInput : searchState.keywordSearchType == "broad" ? searchState.keywords.join(" "): ""}"</p>
                        </AdCountWrap>
                    </AdCount>
                    {facebookState.loadingResults ? <div className='loader'>
                    <SearchLoader/>
                    </div> :  facebookState.noResults ? <NoResults>
                                    <img src={NoResultsLogo}/>
                                    <h2>No Results Found</h2>
                                    <p>We're sorry, but we don't currently have the data matching your search parameters. Our database is constantly updated, so please try again later or try modifying your search.</p>
                                    <RequestDataBtn dataRequested={useRequestDataHook.dataRequested ? useRequestDataHook.dataRequested : ""} disable={useRequestDataHook.isRequestDataLoading} onClick={onDataRequest}>{useRequestDataHook.dataRequested ? <p>Your request for data crawl has been accepted<img src={TickImg}/></p> : <p>{useRequestDataHook.isRequestDataLoading ? "Requesting...": "Request data crawl for this query"}</p>}</RequestDataBtn>
                        </NoResults>  : <ResultsWraper>
                        {/* {authState.saveAdsPopupOpen && <SaveAdsPopup noMarginTop={true}/>} */}
                        <CardsList ref={containerRef} detailsModal={facebookState.detailsModal}>
                            {
                            facebookState.ads && facebookState.ads.length > 0 && facebookState.ads.map((ad,idx) => {
                                return <CardElement containerRef={containerRef} basicAdDetails={basicAdDetails} setBasicAdDetails={setBasicAdDetails} ad={ad} idx={idx}/>
                            })
                        } </CardsList>
                        {
                            facebookState.detailsModal && 
                            <AdDetails
                                navigateTo={navigateTo}
                                platform="meta"
                                crawledAd={true} 
                                basicDetail={facebookState.adDetails} 
                                onDetailsClose={onDetailsClose} 
                                highlightWords={facebookState.highlightWords} 
                                onSave={onSave}
                            />
                        }
                        {
                                !searchState.limitExceeded && facebookState.ads && facebookState.ads.length > 0 && <Pagination/>
                            } 
                    </ResultsWraper> 
                    }       
                </ContentContainer>
        }
    </ResultsContainer>
}

export default CrawlRequestResults
