import styled from "styled-components";

export const Wrapper = styled.div`
    padding: 0px 24px;
    display: flex;
    flex-direction: column;
    h2{
        color: #1C1C1C;
        font-size: 16px;
        font-weight: 400;
        line-height: 38px;
        margin-top: 33px;
        margin-bottom: 22px;
    }
`;

export const MetaAdNetword = styled.div`
    border-radius: 6px;
    background: #DADFE4;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #FFF;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    width: 100%;
    cursor: pointer;
`;

export const GoogleAdNetword = styled.div`
    border-radius: 6px;
    background: #DADFE4;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #FFF;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
`;

export const DownloadContainer = styled.div`
    font-size: 16px;
    font-weight: 400;
    line-height: 38px;
    color: #1C1C1C;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const DownloadButton = styled.div`
    background: #464646;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #FFF;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    width: 100%;
    cursor: pointer;
    opacity: ${props => props.isDisabled ? "0.5" : "1"};
    pointer-events: ${props => props.isDisabled ? "none" : ""};
`;