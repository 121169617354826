import styled from "styled-components"

export const Container = styled.div`
    width: 180px;
    height: 40px;
    border: 1px solid #ECECEC;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    border-radius: 6px 0px 0px 6px;
    background: transparent;
    
    border: ${props => props.error ? "1px solid #FF5180" : "1px solid #ECECEC"};
    outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};
    &:focus{
        border: ${props => props.error ? "1px solid #FF5180" : "1px solid #5AB3FF"};
        outline: ${props => props.error ? "2px solid #FFB7CB" : "2px solid #B9DDFF"};
    }

    :hover {
        border: ${props => props.error ? "" : "1px solid #5AB3FF"};
    }

    cursor: pointer;
    position: relative;

     p {
        font-size: 14px;
     }

`;

export const Drop = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: space-around;
    
    >span{
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: #1c1c1c;
    }
    
    >p{
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: rgb(28, 28, 28);
    }

    .dropdown-arrow{
        margin-right: ${props => props.error ? "20px": "none"};
    }

    .platform-logo{
        height: 16px;
    }
`;

export const Down = styled.div`
    width: 100%;
    min-height: 40px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 2px 6px #0000001A;

    position: absolute;
    left: 0;
    top: calc(100% + 6px);
    padding: 5px;
    cursor: auto;

    display: ${props => props.show ? 'flex': 'none'};
    flex-direction: column;
    justify-content: center;
`;

export const Row1 = styled.div`
    width: 100%;
    height: 32px;
    padding: 10px;

    display: flex;
    align-items: center;

    p {
        color: #1c1c1c;
    }
`;

export const Row = styled(Row1)`
    cursor: pointer;
    height: 32px;
    transition: 0.2s all ease-in-out;
    border-radius: 4px;
    padding: 10px;
    opacity: ${props => props.disabled ? "40%" : 1};
    pointer-events: ${props => props.disabled ? "none" : ""};

    img{
        height: 12px;
        margin-right: 8px;
    }

    :hover {
        background-color: #F6F6F6;
    }

    p {
        color: black;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
`;

export const Separator = styled.div`
    width: 10px;
    border-bottom: 1px solid #ECECEC;
    margin-left: 10px;
`;

export const ToolTipContainer = styled.div`
    font-size: 12px;
    font-weight: 300;
    color: #666;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;

    img{
        margin-left: 4px;
    }
`;

export const Wrapper = styled.div`

`;

export const ToolTipImageContainer = styled.div`
    position: absolute;
    right: 8px;
    top: 10px;
    display: ${props => props.error ? "flex": "none"};
    img{
        filter: contrast(1);
    }

    &:hover{
        > div{
            display: flex;
        }

        img{
            display: block;
        }
    }
`;

export const ToolTip = styled.div`
    background: #555555;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 300;
    position: absolute;
    bottom: -34px;
    padding: 4px 8px;
    width: 170px;
    display: none;
`;