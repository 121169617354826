import styled from "styled-components";

export const SelectPlatformWrapper = styled.div``
export const SelectPlatformContainer = styled.div`
padding: 34px 0 0 34px;
display: flex;
column-gap: 24px;
align-items: center;
img{
    cursor: pointer;
}
`
export const SelectPlatformDiv = styled.div`
color: #000;
font-family: Inter;
font-size: 20px;
font-weight: 600;
`
export const PlatformListWrap = styled.div`
display: flex;
gap: 16px;
padding: 24px 0 0 82px;
flex-wrap: wrap;
    max-width: 882px;
`

export const PlatformList = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
row-gap: 18px;
border-radius: 5px;
border: 1px solid #DADFE4;
background: #FFF;
width: 188px;
height: 139px;
cursor: pointer;

`
export const GoogleAdsDiv = styled.div`
color: #000;
font-family: Inter;
font-size: 14px;
font-weight: 400;
`
export const OtherAdsDiv = styled.div`
color: rgba(28, 28, 28, 0.20);
font-family: Inter;
font-size: 12px;
font-weight: 400;
height: 110px;
display: flex;
align-items: center;
justify-content: center;
`
export const AvailableDiv = styled.div`
color: #DADFE4;
font-family: Inter;
font-size: 12px;
font-weight: 400;
text-align: center;
`
export const OtherPlatformList = styled.div`
display: flex;
flex-direction: column;
// justify-content: flex-end;
// align-items: center;
// gap: 31px;
border-radius: 5px;
border: 1px solid #DADFE4;
width: 188px;
height: 139px;
background: #FFF;
`