import styled from 'styled-components';


export const SliderMainContainer = styled.div`
display:flex;
align-items:center;
gap:5px;
height:40px;

p{
color: rgba(28, 28, 28, 0.80);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px;}
`


export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
`;

export const RangeInputContainer = styled.div`
  flex-grow: 1;
  margin-right: 10px;
`;

export const RangeInput = styled.input`
  width: 100%;
  -webkit-appearance: none;
  background: transparent;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #111926;
    cursor: pointer;
    margin-top: -6px;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    background: #e0e0e0;
    border-radius: 2px;
  }

  &:focus {
    outline: none;
  }
`;

export const AudienceValue = styled.span`
  font-size: 14px;
  color: #333;
  min-width: 80px;
  text-align: right;
`;