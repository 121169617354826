import React, {useEffect,  useState} from 'react'
import { useSearchParams, useNavigate } from "react-router-dom"

import {
    Container,
    SearchContainer,
    ResultsContainer,
    Wrapper,
    HeadingWrapper,
    KeywordInput,
    TrackButton,
    InputContainer,
    ClearButton,
    ErrorBox,
    HeadingLeft,
    HeadLeftWrap,
    HeaderRight,
    HowItWork,
    RightIcons,
    ErrorIcon,
} from "./index.styled"

import PPCRestrictionPopup from '../../../../components/PPCRestrictionPopup';
import SearchIcon from "../../../../../media/Dashboard/searchIcon.svg"
import CloseIcon from "../../../../../media/Dashboard/detailsPaneClose.svg"
import { useAuth } from '../../../../../context/auth';

import Tooltip from 'rc-tooltip';

import KeywordInputModal from './components/KeywordInputModal';
import {appClient} from '../../../../../utils/axios-utils'
import {
    useMutation
} from "@tanstack/react-query"
import { useKeywordFunnelAnalysis } from '../../context/keywordFunnelAnalysis';

import Statics from "../../_static"
import CountryDropDown from './components/KeywordInputModal/components/CountryDropDown';
import funnelAnalysis from "./assets/funnelAnalysis.svg"
import errorIcon from "./assets/errorIcon.svg"

const tableData = async (data) => {
    // console.log(data)
    const config = {
        url: "/track/keyword/all",
        method: "GET",
    }

    return appClient(config)
}


const KeywordFunnelAnalysisHome = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    // const {keywordFunnelAnalysisState, keywordFunnelAnalysisDispatch} = useKeywordTracking()
    const {keywordFunnelAnalysisState, keywordFunnelAnalysisDispatch} = useKeywordFunnelAnalysis()
    const {authState, authDispatch} = useAuth()
    const [inputFocussed, setInputFocussed] = useState(false)

    const [error, setError] = useState(false)
    const [isValidUrl, setIsValidUrl] = useState(false)

    const navigate = useNavigate()

    const { tempTableList } = Statics

    const { mutate: tableApi, isLoading: isTableDataLoading } = useMutation(tableData, {
        onSuccess: (data) => {
            if(data.length > 0){
                keywordFunnelAnalysisDispatch({type: 'SET_KEYWORD_TABLE_OPEN', value: true})
                keywordFunnelAnalysisDispatch({type: 'SET_KEYWORD_TABLE_LIST', value: data})
            }else{
                keywordFunnelAnalysisDispatch({type: 'SET_KEYWORD_TABLE_OPEN', value: false})
                keywordFunnelAnalysisDispatch({type: 'SET_KEYWORD_TABLE_LIST', value: []})
            }
            keywordFunnelAnalysisDispatch({type: 'SET_KEYWORD_INPUT_MODAL_OPEN', value: false})
        },
        onError: (err) => {
            
        }
    })

    // const tableApi = () => {
    //     // keywordFunnelAnalysisDispatch({type: 'SET_KEYWORD_TABLE_OPEN', value: true})
    //     // keywordFunnelAnalysisDispatch({type: 'SET_KEYWORD_TABLE_LIST', value: tempTableList})
    //     keywordFunnelAnalysisDispatch({type: 'SET_KEYWORD_TABLE_OPEN', value: false})
    //     keywordFunnelAnalysisDispatch({type: 'SET_KEYWORD_TABLE_LIST', value: []})
    const updateKeywordCountry = (keyword,inputCountryData) => {
        const tempKeywordList = keywordFunnelAnalysisState.keywordInputList.map((data,index) => {
            // console.log(index,'check1',keyword)
            if (keyword == index) {
              return { ...data, country: inputCountryData }
            }
            return data;
          });

        //   console.log("updateKeywordCountry",tempKeywordList);
          keywordFunnelAnalysisDispatch({ type: 'SET_KEYWORD_INPUT_LIST', value: tempKeywordList})
    }

    // }
    const onKeywordInput = (e,index) => {
        // const tempKeywordInputList = keywordFunnelAnalysisState.keywordInputList
        const tempKeywordInputList = keywordFunnelAnalysisState.keywordInputList.map((d,id) => {
            if (id === index) {
                if(e.target.value !== ""){
                    keywordFunnelAnalysisDispatch({ type: 'SET_KEYWORD_INPUT_LIST_ERROR', value: '' });
                }else{
                    keywordFunnelAnalysisDispatch({ type: 'SET_KEYWORD_INPUT_LIST_ERROR', value: 'Enter a keyword' });
                }
              // Update the region property for the specific country
              return { ...d, keyword: e.target.value };
            }
            return d;
          });
        keywordFunnelAnalysisDispatch({type: 'SET_KEYWORD_INPUT_LIST', value: tempKeywordInputList})
    }

    const keywordFunnelFn = () =>{
        if (keywordFunnelAnalysisState.keywordInputList) {
            console.log('funnel input list', keywordFunnelAnalysisState.keywordInputList)
            keywordFunnelAnalysisState.keywordInputList.map((item) => {
                if (item.keyword !== '' ) {
                    navigate(`/keyword-funnel/details?keyword=${item.keyword}`)
                    keywordFunnelAnalysisDispatch({ type: 'SET_KEYWORD_INPUT_LIST_ERROR', value: '' });
                    }else{
                    keywordFunnelAnalysisDispatch({ type: 'SET_KEYWORD_INPUT_LIST_ERROR', value: 'Enter a keyword' });
                    }
            });

        }
    }
    const handleSearch = (event) => {
        if (event.key === 'Enter') {
            if (keywordFunnelAnalysisState.keywordInputList) {
                keywordFunnelAnalysisState.keywordInputList.map((item) => {
                    if (item.keyword !== '' ) {
                        navigate(`/keyword-funnel/details?keyword=${item.keyword}`)
                        keywordFunnelAnalysisDispatch({ type: 'SET_KEYWORD_INPUT_LIST_ERROR', value: '' });
                        }else{
                        keywordFunnelAnalysisDispatch({ type: 'SET_KEYWORD_INPUT_LIST_ERROR', value: 'Enter a keyword' });
                        }
                });
    
            }
        }}
    useEffect(() => {
        keywordFunnelAnalysisDispatch({type: 'SET_KEYWORD_INPUT_LIST', value: [{ keyword: '', country: {}, platformError: '' }]})
        // tableApi()
    }, [])
    

    const headingText = "Keyword Funnel Analysis  "
    const descriptionText = "Transform your approach to user engagement by incorporating our innovative Keyword Funnel Analysis. Using this feature, get to know the classification of ad copies across various stages in the sales funnel based on ad copies from a given keyword. Understand user targeting in relation to their journey with this feature."


    const toolTipFunction = ()  =>{
        return (
            <div>
                <div style={{marginBottom: '16px',fontSize: '14px', color: 'var(--black-100, #1C1C1C)',fontWeight: '500'}}>How to use:</div>
                <ul style={{listStyleType: 'decimal',marginLeft: '16px'}}>
                    <li  style={{marginBottom: '16px'}}>In the search bar, type in the keyword of your choice. Select the country from the dropdown and click on the “Analyse Keyword” button. </li>
                    <li  style={{marginBottom: '16px'}}>In the “Ad Copies” tab, you get to see the ad copies classified as per their stage in the sales funnel. You can alternate between Google and Bing Search ads funnel analysis by clicking on the toggle at the top right corner of the workspace. </li>
                    <li  style={{marginBottom: '16px'}}>To check ad copies of various stages of the sales funnel, just click on the stage you want to see the ad copies for. </li>
                    <li style={{marginBottom: '16px'}}>To check the CTA analysis for a given stage in sales funnel, go to the “CTA’s” tab to view the call-to-action words and phrases used, along with the occurrences on the overall ad copies of that stage. </li>
                    <li style={{marginBottom: '16px'}}>To check the target audience analysis for the ad copies, go to the “Target Audience” tab and check the income, age and gender distribution for these ad copies of the particular stage. </li>
                    <li style={{marginBottom: '16px'}}>To view important keywords and key phrases used in the ad copies of a given stage in the sales funnel, simply go to the “Important keywords” tab and view the keywords and key phrases, along with their occurrences in the ad copies of that stage. </li>
                </ul>
            </div>
        )
    }

    const tooltipStyle = {
        marginTop: '100px'
    };
    
    const overlayStyle = {

    };
  
    const overlayInnerStyle = {
        backgroundColor: '#FFF7E8',
        color: 'rgba(28, 28, 28, 0.80)',
        padding: '24px 20px 44px 20px',
        fontSize: '14px',
        marginTop: '120px',
        width: '400px',
        maxHeight: '510px',
        paddingBottom: '44px',
        overflowY: 'auto'
    }; 

    return <Container>
         
                {authState.isPPCRestrictedPopup && 
                    <ResultsContainer>
                        <PPCRestrictionPopup/>
                    </ResultsContainer>
                }
                {
                    keywordFunnelAnalysisState.keywordInputModalOpen && 
                    <ResultsContainer>
                        <KeywordInputModal/>
                    </ResultsContainer>
                }
                
               {/* { !keywordFunnelAnalysisState.keywordTableOpen &&  */}
               <Wrapper isNavCollapsed={authState.isNavCollapsed} >
                    <HeadingWrapper>
                        <HeadingLeft>
                            <HeadLeftWrap>
                            <h2>{headingText}</h2>
                            <Tooltip 
                                trigger={['hover']}  
                                style={tooltipStyle}
                                overlayStyle={overlayStyle}
                                overlayInnerStyle={overlayInnerStyle} 
                                showArrow={false}
                                arrowContent={null}
                                overlay={toolTipFunction()}
                            >
                                <HowItWork>Quick guide</HowItWork>
                            </Tooltip>
                            </HeadLeftWrap>
                            <p>{descriptionText}</p>
                        </HeadingLeft>
                        <HeaderRight>
                            <img src={funnelAnalysis} />
                        </HeaderRight>
                    </HeadingWrapper>
                    <SearchContainer>
                    {
                        keywordFunnelAnalysisState.keywordInputList.map((keyword, idx)=> {
                            return <>
                            <InputContainer isFocussed={inputFocussed} >
                                <img src={SearchIcon}/>
                                <KeywordInput
                                onKeyDown={handleSearch}
                                    placeholder="Enter a keyword"
                                    value={keyword.keyword}
                                    onChange={(e)=>onKeywordInput(e, idx)}
                                    onFocus={() => setInputFocussed(true)}
                                    onBlur={() => setInputFocussed(false)}
                                />
                                <RightIcons>
                                    {keywordFunnelAnalysisState.keywordInput.length > 0 && <ClearButton  onClick={()=>{keywordFunnelAnalysisDispatch({type: 'SET_KEYWORD_INPUT', value: ""})}} src={CloseIcon}/>}
                                    {keywordFunnelAnalysisState.keywordInputListError && <ErrorIcon src={errorIcon} alt=""/>}
                                </RightIcons>
                                

                            </InputContainer>
                            <CountryDropDown keywordData={keyword} updateKeywordCountry={updateKeywordCountry} countryIndex={idx}/>

                                </>
                        })
                    }
                        <TrackButton  onClick={ () => keywordFunnelFn()}>
                            Analyse Keyword
                        </TrackButton>
                    </SearchContainer>
                    <ErrorBox>{keywordFunnelAnalysisState.keywordInputListError ? keywordFunnelAnalysisState.keywordInputListError : ""}</ErrorBox>
                </Wrapper>
                {/* } */}
                {/* { keywordFunnelAnalysisState.keywordTableOpen && 
                <KeywordTable/>
                } */}
            </Container>
};
    

export default KeywordFunnelAnalysisHome