import React, { useState } from 'react'
import { PersonaListContainer, PersonaListHeader, PersonaCardsContainer, PersonaCards, Card, ImagePlaceHolder, PersonaDetails } from './PersonaList.styled.js'
import PersonaDetailsCard from './PersonaDetailsCard.js'
import { appClient } from '../../../../../utils/axios-utils.js'
import { useMutation } from '@tanstack/react-query'
import { useAuth } from '../../../../../context/auth.js'


const storyCardData = async (postData) => {
    const config = {
        url: "/persona/get-persona-details",
        method: "POST",
        data: JSON.stringify(postData)
    }

    return appClient(config);
}


const PersonaCard = ({ personaDetails, handleCardClick }) => {
    // console.log(personaDetails)
    return (
        <Card onClick={() => handleCardClick(personaDetails?.persona)}>
            <ImagePlaceHolder />
            <PersonaDetails>
                <div>
                    {personaDetails?.persona}
                </div>
                {/* <p>
                    {personaDetails.age?.[0]}, {personaDetails?.gender?.[0]}
                </p> */}
            </PersonaDetails>

        </Card>
    )
}

const PersonaList = ({ data }) => {
    // console.log("list", data)
    // const [personas, setPersonas] = useState(Array(15).fill(null));
  const { authState, authDispatch } = useAuth()

    const [storyData, setStoryData] = useState(null)
    const { mutate: getStoryData, isLoading } = useMutation(
        storyCardData,
        {
            onSuccess: (data) => {
                console.log("story", data);
                setStoryData(data);
            },
        }
    );

    const handleCardClick = (personaName) => {
        console.log(personaName)
        const storyCardPreInfo = {
            projectId: authState.defaultProjectInfo.projectId,
            persona: personaName
        }
        getStoryData(storyCardPreInfo)
    }


    return (
        <PersonaListContainer>
            <PersonaListHeader>
                <div className='bold-words'>Persona List Based on your filter<span>- 1 to 20 of 12,<span className='bold-words'>800</span> results</span></div>
                <div className='middle-line'><span></span></div>
            </PersonaListHeader>
            <PersonaCardsContainer>
                <PersonaCards detailsOpen={storyData}>
                    {data.slice(0, 15).map((personaDetails, index) => (
                        <PersonaCard
                            key={index}
                            personaDetails={personaDetails}
                            handleCardClick={handleCardClick} />
                    )
                    )}

                </PersonaCards>
                {storyData && <PersonaDetailsCard storyData={storyData} />}
            </PersonaCardsContainer>
        </PersonaListContainer>
    )
}

export default PersonaList