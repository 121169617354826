import styled from "styled-components";

export const BrandAssetsForm = styled.form`
display: flex;
width: 624px;
gap: 24px;
flex-wrap: wrap;
`;

export const FieldLabel = styled.div`
color: #A9A9A9;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

export const SelectedLogo = styled.img`
width: 300px;
height: 120px;
display: block;
object-fit: contain;
position: absolute;
transform: translateX(-50%);
left: 50%;
`;

export const BrandAssetsLabel = styled.label`
margin-top: 8px;
width: 300px;
height: 120px;
flex-shrink: 0;
border-radius: 4px;
border: ${(props) => props.isLogoUploaded ? "none" : "0.5px solid #CFCFCF"};
background: ${(props) => props.isLogoUploaded ? "unset" : "#F6F7FB"};
display: flex;
align-items: center;
justify-content: center;
position: relative;

:hover{
  background: rgba(0, 0, 0, 0.70);
}
`;

export const LogoText = styled.div`
color: #D6D6D6;
font-family: "Eagle Lake";
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: normal;
position: absolute;
transform: translate(-50%, -50%);
left: 50%;
top: 50%;
`;

export const InputDummy = styled.input`
visibility: hidden;
display: block;
`;

export const UploadLogoContainer = styled.div`
position: absolute;
transform: translate(-50%, -50%);
left: 50%;
top: 50%;
display: flex;
align-items: center;
justify-content: center;
gap: 8px;
display: none;
cursor: pointer;
`;

export const UploadIcon = styled.img``;

export const UploadText = styled.div`
color: #FFF;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

export const SelectedColor = styled.div`
margin-top: 8px;
cursor: pointer;
width: 100px;
height: 100px;
flex-shrink: 0;
border-radius: 4px;
// background: #FF8D4B;
position: relative;
`;

export const Hex = styled.div`
position: absolute;
bottom: 0;
width: 100%;
height: 23px;
display: flex;
align-items: center;
justify-content: center;
color: #FFF;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
background: rgba(0, 0, 0, 0.20);
`;

export const BrandColorContainer = styled.div`
margin-top: 8px;
width: 300px;
height: 120px;
flex-shrink: 0;
border-radius: 4px;
border: 0.5px solid #CFCFCF;
background: #F6F7FB;
padding: 24px;
position: relative;
`;

export const AddButton = styled.div`
width: 72px;
height: 72px;
flex-shrink: 0;
border-radius: 4px;
background: #FFF;
box-shadow: 0px 1px 4px 0px  rgba(0, 0, 0, 0.18);
display: flex;
align-items: center;
justify-content: center;
`;

export const Icon = styled.img``;

export const ColorpickerContainer = styled.div`
position: absolute;
top: 42px;
left: 58px;
display: none;
width: 195px;
height: 188px;
flex-shrink: 0;
background: #D9D9D9;

.color-picker-container {
  position: relative;
}
.color-picker-palette {
  position: absolute;
  z-index: 100;
}

.color-picker-cover {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}

.color-picker-color-background {
  position: fixed;
  height: 35px;
  width: 35px;
  background-color: #999999;
  float: left;
}

`;

export const SelectedFont = styled.div`
margin-top: 8px;
width: 300px;
height: 36px;
flex-shrink: 0;
border-radius: 4px;
border: 0.5px solid #CFCFCF;
background: #F4F5F7;
color: rgba(86, 86, 86, 0.80);
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
padding-left: 12px;
position: relative;
display: flex;
align-items: center;
`;

export const FontFamilyContainer = styled.label`
margin-top: 8px;
width: 300px;
height: 120px;
flex-shrink: 0;
border-radius: 4px;
border: 0.5px solid #CFCFCF;
background: #F6F7FB;
display: flex;
align-items: center;
justify-content: center;
position: relative;
color: #D6D6D6;
font-family: ${(props) => props.font};
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: normal;
cursor: pointer;
`;

export const FontsDropdown = styled.div`
position: absolute;
top: 42px;
left: 58px;
display: none;
width: 200px;
height: 188px;
flex-shrink: 0;
background: #D9D9D9;
padding: 5px;
overflow-y: auto;
border-radius: 4px;
border: 1px solid #CFD0D1;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
padding: 16px 16px 0 16px;
`;

export const FontsItem = styled.div`
  width: 100%;
  height: 32px;
  color: rgba(28, 28, 28, 0.70);
  font-family: ${(props) => props.font};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.14px;
  cursor: pointer;
  border-radius: 4px;
  padding: 6px;
  :hover{
    background: #FFE8BF;
  }
`;