import { Route, Routes } from "react-router-dom";
import InputPage from "../../Components/InputPage";
import HomePage from "../../Components/HomePage";
import { useTextAdGenerator } from "../context/textAdGeneration";
import ResultPage from "../components/resultPage/index";
import AdsDraft from "../../Components/DraftsPage";

const Root = () => {
  const { textAdGeneratorState, textAdGeneratorDispatch } =
    useTextAdGenerator();

    let historyData = [{
      domainId: "apple.com",
      url: "https://apple.com",
      title: "Visit nearby Apple store",
      lastSeen: 1646005208685,
      content: "Shop Apple Watch Apple & More. Get Apple Watch Apple at Target™ Today. Orders $35+ Ship Free. Shop Apple Watch Apple at Target™. Search Products. Find Stores."
    },{
      domainId: "apple.com",
      url: "https://apple.com",
      title: "Visit nearby Apple store",
      lastSeen: 1646005208685,
      content: "Shop Apple Watch Apple & More. Get Apple Watch Apple at Target™ Today. Orders $35+ Ship Free. Shop Apple Watch Apple at Target™. Search Products. Find Stores."
    },{
      domainId: "apple.com",
      url: "https://apple.com",
      title: "Visit nearby Apple store",
      lastSeen: 1646005208685,
      content: "Shop Apple Watch Apple & More. Get Apple Watch Apple at Target™ Today. Orders $35+ Ship Free. Shop Apple Watch Apple at Target™. Search Products. Find Stores."
    },{
      domainId: "apple.com",
      url: "https://apple.com",
      title: "Visit nearby Apple store",
      lastSeen: 1646005208685,
      content: "Shop Apple Watch Apple & More. Get Apple Watch Apple at Target™ Today. Orders $35+ Ship Free. Shop Apple Watch Apple at Target™. Search Products. Find Stores."
    },{
      domainId: "apple.com",
      url: "https://apple.com",
      title: "Visit nearby Apple store",
      lastSeen: 1646005208685,
      content: "Shop Apple Watch Apple & More. Get Apple Watch Apple at Target™ Today. Orders $35+ Ship Free. Shop Apple Watch Apple at Target™. Search Products. Find Stores."
    }]
    
  const renderingComponent = () => {
    const { currentPage } = textAdGeneratorState;

    switch (currentPage) {
        case "home":
          return <HomePage historyData={historyData} adType={"textad"} />;

      case "input-page":
        return <InputPage forTextAd={true}/>;

      default:
        break;
    }
  };

  return (
    <>
      <Routes>
        <Route path="/" element={renderingComponent()} />
        <Route path="/input" element={<InputPage forTextAd={true}/>} />
        <Route path="result" element={<ResultPage />} />
        <Route path="/drafts" element={<AdsDraft adType={"textad"}/>}/>
      </Routes>
    </>
  );
};

export default Root;
