import React, { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import {
  DetailsButton,
  FooterRight,
  AdCountries,
  AdDate,
  FooterLeft,
  CardFooter,
  AdUrl,
  CardBody,
  Card,
  HightlightTitle,
  HightlightContent,
  AdHeader,
  WebsiteLogo,
  WebsiteNameContainer,
  WebsiteName,
  SponsoredText,
  SaveAdsButton,
  DetailContainer,
  LevelOneDetailContainer,
  AdPreviewContainer,
  AdContentContainer,
  TitleContainer,
  TitleHeader,
  DescriptionContainer,
  DescriptionHeader,
  LevelTwoDetailsContainer,
  CopyButton,
  AdPositionContainer,
  AdPositionHeader,
  AvgPosition,
  LevelTwoRow,
  AmountSpentContainer,
  OtherDetailsContainer,
  AmountSpentHeader,
  AmountSpent,
  MoneyBagLogoContainer,
  CountryConainer,
  LastSeenContainer,
  DestinationUrlContainer,
  CountryHeader,
  LastSeenHeader,
  DestinationUrlHeader,
  LevelThreeDetailContainer,
  MapsList,
  FlagLogo,
  Header,
  LogoWrapper,
  TitleWrap,
  VideoTitle,
  ChannelDiv,
  VideoContainer,
  VideoDescription,
  CountryWrap,
  Country,
  ChannelDetails,
  LogoContainer,
  ChannelName,
  IntractionDetails,
  VideoWrapper,
  TagsWrapper,
  TooltipContainer,
} from "./index.styled";
import Highlighter from "react-highlight-words";
import GlobeIcon from "../../../../../media/Dashboard/globe.svg";
import DateIcon from "../../../../../media/Images/DateIcon.svg";
import CountryIcon from "../../../../../media/Images/CountryIcon.svg";
import CopyImage from "../../../../../media/Dashboard/CopyImg.svg";
import StartImage from "../../../../../media/Dashboard/StarWhite.svg";
import RightUpperGreenArrow from "../../../../../media/Dashboard/RightUpperGreenArrow.svg";
import MoneyBagLogo from "../../../../../media/Dashboard/MoneyBag.svg";
import RedirectOrangeIcon from "../../../../../media/Dashboard/RedirectOrangeIcon.svg";
import CopyImg from "../../../../../media/Dashboard/CopyImg.svg";
import links from "../../../../../media/Dashboard/links.svg";

import { countryList } from "../../../../../utils/maps";
import ReactCountryFlag from "react-country-flag";
import { appClient } from "../../../../../utils/axios-utils";
import { Tooltip } from "react-tooltip";

import { LineChartGradient } from "./components/AreaChart";
import AiDetails from "./components/AiDetails";
import AdGenie from "./components/AdGenieAi";
import ApexChartFn from "./components/GaugeChart";
import { CircleFlag } from "react-circle-flags";
import Skeleton from "react-loading-skeleton";
import { formatNumber } from "../../../../../utils/formatTotal"
import WebsiteIcon from '../../../../../media/Dashboard/websiteicon.png'
import locationImg from '../../../../../media/Dashboard/locationCard.svg'
import { useAuth } from "../../../../../context/auth";
import { useSaveAds } from "../../../../hooks/useSaveAds";
import copiedImage from "../../../../../media/Dashboard/copiedImage.svg";
import { useNavigate } from "react-router-dom";

const getAdDetails = async (id) => {
  const config = {
    url: `/detail/google/${id}`,
    method: "GET",
  };

  return appClient(config);
};

const adAmountSpent = async (id) => {
  const config = {
    url: `/detail/google/estimated-budget/${id}`,
    method: "GET",
  };

  return appClient(config);
};

const YoutubeDetail = ({ basicDetail, highlightWords, onSave }) => {
  const navigate = useNavigate()

  const [adDetails, setAdDetails] = useState(basicDetail);
  const [detailsLoading, setDetailsLoading] = useState(true);
  const [copyState, setCopyState] = useState(false);
  const [copyContent, setCopyContent] = useState(false);
  const [copyURL, setCopyURL] = useState(false);


  const [adAmount, setAdAmount] = useState({})
  const { authState, authDispatch } = useAuth()
  const useSaveAdsHook = useSaveAds()

  const getFlagEmoji = (countryName) => {
    let countryCode = countryList[countryName];
    if (countryCode) {
      return (
        <FlagLogo>
          <CircleFlag countryCode={countryCode.toLowerCase()} height="24" />
        </FlagLogo>
      );
    }
  };
  const onRemoveSavedAd = (ad) => {
    useSaveAdsHook.RemoveSavedAdMutate({
        projectId: authState.defaultProjectInfo.projectId,
        adId:  `${ad.id}`,
        platform: "youtube"
    })
}
  const { mutate: adsDetailsMutate, isLoading: isDetailsLoading } = useMutation(
    getAdDetails,
    {
      onSuccess: (data) => {
        setAdDetails(data);
        setDetailsLoading(false);
      },
      onError: (e) => {
        console.log("err", e);
        setDetailsLoading(false);
        setAdDetails({});
      },
    }
  );

  const { mutate: adAmountSpentMutate, isLoading: isAdAmountSpentLoading } = useMutation(
    adAmountSpent,
    {
      onSuccess: (data) => {
        setAdAmount(data)
      },
      onError: (e) => {
        console.log("err", e);
        setAdAmount({})
      },
    }
  );

  const convertTimestamp = (timestamp) => {
    // Create a new Date object based on the timestamp
    var date = new Date(timestamp);

    // Get the month name
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var month = monthNames[date.getMonth()];

    // Get the year
    var year = date.getFullYear();

    // Assemble the formatted date string
    var formattedDate = date.getDate() + " " + month + " " + year;

    return formattedDate;
  };
  const analyzeDomian = () =>{
    const url = new URL(basicDetail?.url);
    const domain = url.hostname;
    navigate(`/domain-overview/details?domain=${domain}&country=global`)
  }
  useEffect(() => {
    // adsDetailsMutate(basicDetail.id);
    // adAmountSpentMutate(basicDetail.id)
  }, []);

  // if (detailsLoading) {
  //   return <h2>Loading</h2>;
  // } else {
    return (
      <DetailContainer>
        <LevelOneDetailContainer>
        <h2>Ad Details</h2>
          <AdPreviewContainer>
                <ChannelDetails>
                    <LogoContainer>
                        <img src={basicDetail.logo ? `https://aws-adspyder-logos.s3.ap-south-1.amazonaws.com/${basicDetail.id}`: GlobeIcon}/>
                    </LogoContainer>
                    <ChannelName>
                        {basicDetail.title}
                        <span>Sponsored</span>
                    </ChannelName>
                </ChannelDetails>
                <IntractionDetails>
                <SaveAdsButton isSaved={authState.savedAdsIds.includes(basicDetail.id)} onClick={(e) => {
                    e.stopPropagation();
                    if (authState.savedAdsIds.includes(basicDetail.id)) {
                        onRemoveSavedAd(basicDetail)
                    } else {
                        onSave(basicDetail);
                    }

                }}>
                    {authState.savedAdsIds.includes(basicDetail.id) ? "Saved" : "Save"}
                </SaveAdsButton>
                </IntractionDetails>
            </AdPreviewContainer>
          {/* <AdPreviewContainer> */}
         
            {/* <Card key={basicDetail.id}> */}
        <VideoWrapper>
          <VideoContainer>
              <iframe width="100%" height="435" style={{ border: "none"}}
                  src={`https://www.youtube.com/embed/${basicDetail.videoId}`} controls="0">
              </iframe>
          </VideoContainer>
        </VideoWrapper>
          <AdContentContainer>
            {/* <h2>Ad Details</h2> */}
            <TitleContainer>
              <TitleHeader>
                <h3>Title</h3>
                <CopyButton
                             onClick={() => {
                              !copyState && setCopyState(true)
                              navigator.clipboard.writeText(basicDetail.title);
                              !copyState && setTimeout(() => {
                                setCopyState(false)
                              }, 3000);
                            }}
                            data-tooltip-id="title-tooltip"
                            data-tooltip-content={copyState ? 'Copied' : 'Copy'}
                            >
                        {!copyState ? <img src={CopyImage} />:<img src={copiedImage} />}
                        <Tooltip id="title-tooltip" 
                  noArrow={true} 
                  place={'bottom'}  
                  style={{ backgroundColor: "#101828", color: "#FFF",fontFamily: 'Inter', fontSize: '12px', borderRadius: '8px',
                  fontWeight: 600, height: '34px', padding:'8px 12px',display: 'flex', alignItems: 'center' }}/>
                        </CopyButton>
              </TitleHeader>
              <p>{basicDetail.title}</p>
            </TitleContainer>
            <DescriptionContainer>
              <DescriptionHeader>
                <h3>Content</h3>
                <CopyButton
                             onClick={() => {
                              !copyContent && setCopyContent(true)
                              navigator.clipboard.writeText(basicDetail.content);
                              !copyContent && setTimeout(() => {
                                setCopyContent(false)
                              }, 3000);
                            }}
                            data-tooltip-id="content-tooltip"
                            data-tooltip-content={copyContent ? 'Copied' : 'Copy'}
                            >
                            {!copyContent ? <img src={CopyImage} />:<img src={copiedImage} />}
                            <Tooltip id="content-tooltip" 
                  noArrow={true} 
                  place={'bottom'}  
                  style={{ backgroundColor: "#101828", color: "#FFF",fontFamily: 'Inter', fontSize: '12px', borderRadius: '8px',
                  fontWeight: 600, height: '34px', padding:'8px 12px',display: 'flex', alignItems: 'center' }}/>
                        </CopyButton>
              </DescriptionHeader>
              <p>{basicDetail.content}</p>
            </DescriptionContainer>
          </AdContentContainer>
        </LevelOneDetailContainer>
        <LevelTwoDetailsContainer>
          <h2>Ad Analytics</h2>
          <LevelTwoRow>
              <DestinationUrlContainer>
                <DestinationUrlHeader>
                  <h3>
                    Destination URL
                    <p onClick={analyzeDomian}>
                      <img src={RedirectOrangeIcon} />
                      analyze domain
                    </p>
                  </h3>
                  <CopyButton
                        onClick={() => {
                          !copyURL && setCopyURL(true)
                          navigator.clipboard.writeText(adDetails.url);
                          !copyURL && setTimeout(() => {
                            setCopyURL(false)
                          }, 3000);
                        }}
                        data-tooltip-id="url-tooltip"
                data-tooltip-content={copyURL ? 'Copied' : 'Copy'}
                        >
                            {!copyURL ? <img src={CopyImage} />:<img src={copiedImage} />}
                            <Tooltip id="url-tooltip" 
                  noArrow={true} 
                  place={'bottom'}  
                  style={{ backgroundColor: "#101828", color: "#FFF",fontFamily: 'Inter', fontSize: '12px', borderRadius: '8px',
                  fontWeight: 600, height: '34px', padding:'8px 12px',display: 'flex', alignItems: 'center' }}/>
                        </CopyButton>
                </DestinationUrlHeader>
                <p>{basicDetail?.url}</p>
              </DestinationUrlContainer>
              <CountryConainer>
                <CountryHeader>
                  <h3>Country</h3>
                </CountryHeader>
                <MapsList>
                  {basicDetail.country.map((c, idx) => {
                    if (basicDetail.country.length > 5 ? idx < 5 : true) {
                      return getFlagEmoji(c);
                    }
                  })}
                  {basicDetail.country.length > 5 &&
                    "1" + (basicDetail.country - 5)}
                </MapsList>
                <p>
                  {basicDetail.country.length == 1
                    ? basicDetail.country[0]
                    : basicDetail.country.length > 1
                    ? basicDetail.country[0] +
                      " + " +
                      (basicDetail.country.length - 1)
                    : ""}
                </p>
              </CountryConainer>
              <AmountSpentContainer>
              <AmountSpentHeader>
                <h3>Genre</h3>
              </AmountSpentHeader>
              <p><img src={links} />{basicDetail.genre}</p>

              {/* <AmountSpent>
                {
                  isAdAmountSpentLoading ? <Skeleton
                  style={{
                    lineHeight: 8,
                    width: 220,
                  }}
                  /> : <>
                  <MoneyBagLogoContainer>
                    <img src={MoneyBagLogo} />
                    </MoneyBagLogoContainer>
                    {(adAmount?.low && adAmount?.high) ? <p>${formatNumber(adAmount.low)} - ${formatNumber(adAmount.high)}</p>: <p>-</p>}
                    <span>Cost spent per month</span>
                  </>
                }
                
              </AmountSpent> */}
            </AmountSpentContainer>
            <OtherDetailsContainer>
            
              <LastSeenContainer>
                <LastSeenHeader>
                  <h3>Last Seen</h3>
                </LastSeenHeader>
                <div>
                  <p>
                    {convertTimestamp(basicDetail.lastSeen).split(" 20")[0]}
                  </p>
                  <span>
                    20{convertTimestamp(basicDetail.lastSeen).split(" 20")[1]}
                  </span>
                </div>
              </LastSeenContainer>
              
            </OtherDetailsContainer> 
           
           
            
          </LevelTwoRow>
          <AdPositionContainer>
              <AdPositionHeader>
                <h3>Tags</h3>
                <TagsWrapper>
                <AvgPosition>
                  {basicDetail.tags ? basicDetail.tags.map((item)=>(
                      <p>{item}</p>
                    )) :<p>--</p>
                  }
                </AvgPosition>
                </TagsWrapper>
              </AdPositionHeader>
              {/* <AvgPosition> */}
                {/* 0{Math.round(basicDetail.position) + 1} */}
                {/* <img src={RightUpperGreenArrow} /> */}
              {/* </AvgPosition> */}
              {/* <LineChartGradient adDetails={adDetails} /> */}
            </AdPositionContainer>
        </LevelTwoDetailsContainer>
        {/* <AiDetails
          basicDetail={basicDetail}
          adDetails={adDetails}
          //  getAdDetails={getAdDetails}
        /> */}
        {/* <AdGenie
          basicDetail={basicDetail}
          adDetails={adDetails}
          //  getAdDetails={getAdDetails}
        /> */}
      </DetailContainer>
    );
  // }
};

export default YoutubeDetail;
