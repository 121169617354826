import styled from "styled-components";

export const ManageOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ManageContainer = styled.div`
  width: 1120px;
  height: 700px;
  border-radius: 20px;
  background: #fff;
`;
export const PopupHeaderWrap = styled.div`
  padding: 40px 24px 24px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    height: 24px;
    cursor: pointer;
  }
`;
export const PopupHeader = styled.div`
  color: var(--black-100, #1c1c1c);
  font-size: 20px;
  font-weight: 600;
  line-height: 24px; /* 120% */
`;
export const PopupContainer = styled.div`
  padding: 0 40px 40px 40px;
`;
export const AdWrap = styled.div`
  display: flex;
  border-radius: var(--8, 8px);
  background: #fff;
  max-width: 1130px;
  height: 106px;
  align-items: center;
  justify-content: space-between;
  padding: 15px 48px 15px 24px;
  margin-bottom: 24px;
  box-shadow: 0px 2px 6px #0000001a;
  opacity: ${(props) => !props.isActive && "0.6"};
`;
export const AdImg = styled.div`
  border-radius: 4px;
  background: #f6f6f6;
  padding: 15px;
  img {
    height: 46px;
  }
`;
export const AdAccount = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  p {
    color: rgba(28, 28, 28, 0.5);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
`;
export const AccPlatform = styled.div`
  color: #3f3f3f;
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
`;
export const Available = styled.div`
  color: #a2a2a2;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
`;
export const AdContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 32px;
`;
export const ConnectBtn = styled.div`
  border-radius: var(--8, 8px);
  background: #ff711e;
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  img {
    height: 16px;
  }
`;
