import { useRef, useState } from "react";
import { useTextAdGenerator } from "../../context/textAdGeneration";
import { BrandDescriptionContainer, Description, Label } from "./BrandDescription.styled";

function BrandDescription() {
    const descriptionRef = useRef(null)
    const { textAdGeneratorState, textAdGeneratorDispatch } = useTextAdGenerator()
    const handleDescriptionValueChange = () => {
        if(descriptionRef.current)
        textAdGeneratorDispatch({type: "SET_BRAND_DESCRIPTION", value: descriptionRef.current.value})
    }
    const [inputFocussed, setInputFocussed] = useState(false)

    return ( 
        <BrandDescriptionContainer>
            <Label>Brand/product/service description:</Label>
            <Description placeholder="Enter description of your brand" onChange={handleDescriptionValueChange} value={textAdGeneratorState.brandDescription} ref={descriptionRef}
            isFocussed={inputFocussed}
            onFocus={() => setInputFocussed(true)}
            onBlur={() => setInputFocussed(false)}
            />
        </BrandDescriptionContainer>
     );
}

export default BrandDescription;