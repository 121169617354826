import styled from "styled-components";

export const AdContainer = styled.div `
    width: 500px;
    height: 216px;
    background: #FFF;
    border-radius: 8px;
    grid-row-start: ${
        props => props.detaislOpen ? (((props.idx + 1) % 3) == 0) ? Math.floor((props.idx + 1) / 3) : Math.floor((props.idx + 1) / 3) + 1: ""
    };

    grid-row-end: ${
        props => props.detaislOpen ? (((props.idx + 1) % 3) == 0) ? Math.floor((props.idx + 1) / 3) + 2 : Math.floor((props.idx + 1) / 3) + 3: ""
    };

    grid-column: ${props => props.detaislOpen ? (((props.idx + 1) % 3) == 0) ? 3 : ((props.idx + 1) % 3) : ""};

    @media screen and (max-width: 1350px) {
        // width: 100%;
        grid-row-start: ${
            props => props.detaislOpen ? (((props.idx + 1) % 2) == 0) ? Math.floor((props.idx + 1) / 2) : Math.floor((props.idx + 1) / 2) + 1: ""
        };

        grid-row-end: ${
            props => props.detaislOpen ? (((props.idx + 1) % 2) == 0) ? Math.floor((props.idx + 1) / 2) + 2 : Math.floor((props.idx + 1) / 2) + 3: ""
        };

        grid-column: ${props => props.detaislOpen ? (((props.idx + 1) % 2) == 0) ? 2 : ((props.idx + 1) % 2) : ""};
    }

    .hover-element {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;
      }
      :hover .hover-element {
        opacity: 1;
        visibility: visible;
      }

`;
export const AdContent = styled.div `
    display: flex;
    position: relative;
    height: 100%;
    column-gap: 16px;
`;
export const HoverCard = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    // background: #1C1C1C59;
    border-radius: 8px;
    cursor: pointer;
    padding: 16px;

    background: linear-gradient(360deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.35) 32.4%, rgba(0, 0, 0, 0.15) 63.4%, rgba(0, 0, 0, 0.05) 100%);
`;
export const AdDetailsButton = styled.div`
    border-radius: 24px;
    background: #FFF;
    width: fit-content;
    display: flex;
    padding: 10px 12px;
    gap: 4px;
    justify-content: center;
    align-items: center;
`;

export const HoverCardWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 150px;
`;

export const ImageContainer = styled.div `
border-radius: 4px;
background: #F2F2F2;
  width: 160px;
height: 188px;
flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 16px 16px 0px 16px;
    position: relative;
    margin: 14px 0 0 14px;

    img{
        width: 90px;
    height: 156px;
        object-fit: scale-down;
    }
    span{
        position: absolute;
        color: #FFFFFF;
        background: #919191;
        border-radius: 4px;
        padding: 2px 4px;
        font-size: 12px;
        font-weight: 300;
        top: 16px;
        left: 16px;
    }
`;
export const AdBodyContainer = styled.div `
    width: 286px;
    margin-top: 24px;
    // justify-content: space-between;
`;

export const ProductName = styled.p `
    margin-bottom: 4px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2; 
   -webkit-box-orient: vertical;
    min-height: 41px;

color: #3F33C8;
text-overflow: ellipsis;
font-size: 16px;
font-weight: 400;
line-height: 150%; /* 24px */
`;
export const WebsiteUrl = styled.p `
color: #598763;
text-overflow: ellipsis;
overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: 1; /* number of lines to show */
line-clamp: 1; 
-webkit-box-orient: vertical;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 300;
line-height: normal;
`;
export const AdDate = styled.div `
    display: flex;
    font-size: 14px;
    align-items: center;
    color: rgba(28, 28, 28, 0.50);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.28px;
    margin-top: 8px;
    img{
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
`;
export const AdBodyBottomContainer = styled.div `
    // padding: 16px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
margin-top: 54px;

`
export const CountryWrap = styled.div`
display: flex;
column-gap: 12px;
align-items: center;
`
export const Country = styled.div`
overflow: hidden;
color: rgba(28, 28, 28, 0.50);
text-overflow: ellipsis;
white-space: nowrap;
font-family: Inter;
font-size: 14px;
font-weight: 400;
`
export const ProductPrice = styled.p `
    font-size: 14px;
    margin-bottom: 4px;
    /* padding: 0px 16px; */
color: rgba(28, 28, 28, 0.70);
font-weight: 500;
line-height: 20px; /* 142.857% */
`;