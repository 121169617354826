import React from "react";
import {KeywordRow, Keyword, KeywordCount, ToolTip, ToolTipTextContainer } from "./index.styled"
import { useAuth } from "../../../../../../context/auth";
import { useUrlAnalysis  } from "../../../context/urlanalysis";
import Skeleton from "react-loading-skeleton";

const TopKeywords = ({isDataLoading}) => {
    const { authState } = useAuth();
    const { urlAnalysisState, urlAnalysisDispatch  } = useUrlAnalysis()

    const tempData = [{
        keyword: "apple iphone",
        count: 100022
    },
    {
        keyword: "apple",
        count: 34324
    },
    {
        keyword: "airpods",
        count: 4523
    },
    {
        keyword: "apple sale",
        count: 2212
    },
    {
        keyword: "iphone offers",
        count: 875
    },
  ]

  return <>
    {isDataLoading ? tempData.map((data)=>{
        return <Skeleton height={"20px"} style={{marginBottom: "16px"}}/>
    }): urlAnalysisState.keywordStats.map((data,idx)=>{
        return <KeywordRow>
            <Keyword>
                <ToolTipTextContainer
                    className="tooltip-container">
                    <p>{data.key}</p> 
                    <ToolTip>
                        {data.key} 
                    </ToolTip>
                    </ToolTipTextContainer>
            </Keyword>
            <KeywordCount>{data.percentage}%</KeywordCount>
        </KeywordRow>
    })}
    </>
};

export default React.memo(TopKeywords);
