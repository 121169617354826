import { Line } from "react-chartjs-2";
import styled from "styled-components";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ScriptableContext,
} from "chart.js";

import annotationPlugin from "chartjs-plugin-annotation";

const GraphContainer = styled.div`
  width: 306px;
  height: 100px;
  @media (max-width: 700px) {
    width: 311px;
    height: 160px;
  }
`;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  annotationPlugin
);

export function LineChartGradient() {
  const data = () => {
    return {
      labels: ["", "", "", "", "","","","",""],
      datasets: [
        {
          label: "",
          data: [      
            1.8,1.4,1.6,1.5,1.7,1.3,1.7,1.5,1.7,1.8
        ],
          fill: "start",
          backgroundColor: (context: ScriptableContext<"line">) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 200);
            gradient.addColorStop(0, "#25CD2566");
            gradient.addColorStop(0.3633, "#25CD2500");
            // gradient.addColorStop(0.7032, "rgba(229, 234, 252, 0.31)");
            // gradient.addColorStop(1, "rgba(250, 174, 50, 0)");
            return gradient;
          },
          // opacity: 0.3,
          borderColor: "#25CD25",
          borderDash: [2, 2],
          borderWidth: 1,
        },
      ],
    };
  };
  const calculateAveragePercentage = () => {
    const dataset = data().datasets[0].data;
    const sum = dataset.reduce((acc, value) => acc + value, 0);
    const average = sum / dataset.length;
    return average;
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        label: "",
        ticks: {
          font: {
            family: "Inter",
          },
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
        },
      },
    },
    elements: {
      point: {
        radius: 0,
        // hoverRadius: 6,
      },
    },

    plugins: {
      filler: {
        propagate: false,
      },
      legend: {
        display: false,
      },
    //   annotation: {
    //     annotations: {
    //       line1: {
    //         type: "line",
    //         yMin: 60,
    //         yMax: 60,
    //         borderColor: "#079225",
    //         borderWidth: 1,
    //         borderDash: [4, 2],
    //         borderDashOffset: 5,
    //       },
    //       label1: {
    //         type: "label",
    //         xValue: 3.8,
    //         yValue: 57,
    //         content: ["Avg"],
    //         color: "#079225",
    //         font: {
    //           size: 18,
    //         },
    //       },
    //     },
    //   },
    //   tooltip: {
    //     enabled: true,
    //     intersect: false,
    //     backgroundColor: "#ffffff",
    //     borderWidth: 1,
    //     bodyAlign: "center",
    //     borderColor: "rgba(0, 0, 0, 0.20)",
    //     borderRadius: 160,
    //     padding: {
    //       top: 18,
    //       left: 18,
    //       right: 22,
    //       bottom: 15,
    //     },
    //     titleFont: {
    //       family: "Poppins",
    //       size: 16,
    //       weight: "bold",
    //     },
    //     bodyFont: {
    //       family: "Poppins",
    //       size: 28,
    //     },
    //     titleColor: "#575757", // Set the color for the title text
    //     bodyColor: "#242424",
    //     displayColors: false,
    //     callbacks: {
    //       title: function (tooltipItems) {
    //         const index = tooltipItems[0].dataIndex;
    //         return ` ${tooltipItems[0].chart.data.labels[index]}`;
    //       },
    //       label: function (context) {
    //         // Customize the tooltip label here
    //         return `$ 12.6k`;
    //       },
    //     },
    //   },
    },

    interaction: {
      intersect: true,
    },
  };

  return (
    <GraphContainer>
      <Line data={data()} options={options} />
    </GraphContainer>
  );
}