import styled from "styled-components";

export const Container = styled.div`
    padding: 40px 36px;
`;

export const Header = styled.div`
    margin-bottom: 12px;
    display: flex;
    gap: 24px;

    img{
        width: 24px;
        cursor: pointer;
    }
    
    h2{
        font-size: 16px;
        font-weight: 400;
        line-height: 38px;
        color: #000;
    }
`;

export const AdTypeContainer = styled.div`
    
`;

export const AdTypeList = styled.div`
    display: flex;
    gap: 16px;
    padding: 0px 48px;
`;

export const AdType = styled.div`
    border-radius: 5px;
    border: ${props => props.isActive ? "1px solid #1679DB" : "1px solid #DADFE4"};
    background: #FFF;
    width: 188px;
    height: 140px;
`;

export const ActiveType = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 18px;
    height: 100%;
    cursor: pointer;

    p{
        color: ${props => props.isActive ? "#1679DB": "#1C1C1C"};
    }
`;

export const InactiveType = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    p{
        color: rgba(28, 28, 28, 0.20);
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 30px;
    }
    span{
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: #DADFE4;
        margin-bottom: 10px;
    }
`;

export const AccountsDropDownContainer = styled.div`
    width: 392px;
    height: 44px;
    position: relative;
`;

export const SelectedAccount = styled.div`
    border-radius: 6px;
    border: 1px solid #DADFE4;
    border-radius: 6px;
    border: 1px solid #DADFE4;
    background: #FFF;
    width: 392px;
    height: 44px;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    img{
        transform: ${props => props.rotateImg ? "rotate(180deg)" : ""};
    }
`;

export const AccountsDropDown = styled.div`
    // height: 150px;
    border-radius: 0px 0px 12px 12px;
    border: 1px solid #E3E8EE;
    background: #FFF;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.15);
    overflow-y: auto;
    width: 100%;
    top: 47px;
    position: absolute;

    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }

`;

export const AdAccountContainer = styled.div`
    padding: 28px 48px 0px;
`;

export const AccountOption = styled.div`
    height: 45px;
    border-bottom: solid 1px #E3E8EE;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 16px;
    cursor: pointer;
`;


export const CampaignNameContainer = styled.div`
    padding: 28px 48px 0px;
`;

export const CampaignNameInput = styled.input`
    width: 596px;
    border-radius: 6px;
    border: 1px solid #DADFE4;
    background: #FFF;
    padding: 12px 16px;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
`;

export const ContinueButton = styled.button`
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #FFF;
    border-radius: 6px;
    background: #FF711E;
    outline: none;
    border: none;
    padding: 10px 20px;
    opacity: ${props => props.isActive ? "1" : "0.5"};
    pointer-events: ${props => props.isActive ? "all" : "none"};
`;

export const Footer = styled.div`
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    width: 596px;
`;