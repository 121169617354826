import styled from "styled-components";

export const TargetAudienceWrap = styled.div`
  padding: 0 16px 24px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  height: 510px;
  overflow-y: auto;
`;
export const TargetAudienceContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;
export const TargetTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
`;
export const Gender = styled.div`
  color: var(--black-100, #1c1c1c);
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  display: flex;
  width: 80px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border: 1px solid #e8e8e8;
  background: ${(props) =>
    props.isActive ? "#1C1C1C" : "rgba(255, 255, 255, 0.6)"};
  color: ${(props) => (props.isActive ? "#fff" : "#1C1C1C")};
  cursor: pointer;
`;
export const GenderType = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-items: center;
`;
export const AgeCount = styled.div`
  border: 1px solid #e8e8e8;
  display: flex;
  width: 89px;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  border: 1px solid #e8e8e8;
  background: ${(props) =>
    props.isActive ? "var(--black-100, #1C1C1C)" : "rgba(255, 255, 255, 0.6)"};
  color: ${(props) => (props.isActive ? "#fff" : "#1C1C1C")};
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
`;
export const LanguageWrap = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  background: rgba(255, 255, 255, 0.6);
  padding: 12px 17px;
  cursor: pointer;
`;
export const LanguageDiv = styled.div`
  text-transform: capitalize;
`;
export const DropDownContainer = styled.div`
  background: #fff;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: rgb(227, 227, 227);
  box-shadow: 0px 2px 6px #0000001a;
  /* position: absolute; */
  width: 100%;
  z-index: 1;
  max-height: 250px;
  overflow-y: auto;
`;

export const Dropdown = styled.div`
  padding: 5px 15px;
  border-bottom: 1px solid rgb(235 235 235);
  color: rgb(82, 82, 82);
  font-size: 13px;
  font-weight: 400;
`;
export const CountryWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;
export const CountryList = styled.div`
  border-radius: 6px;
  border: 1px solid #dadfe4;
  background: #fff;
  padding: 12px;
  min-height: 100px;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  gap: 6px;
  height: 120px;
  overflow-y: auto;
`;
export const CountryName = styled.div`
  border-radius: 5.814px;
  background: #ede7fb;
  padding: 4px 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 8px;
  color: rgb(82, 82, 82);
  font-size: 13px;
  font-weight: 600;
  img {
    height: 18px;
    cursor: pointer;
  }
  > :first-child {
    height: 10px;
    cursor: auto;
  }
`;
export const PositionWrapper = styled.div`
  position: relative;
`;
