import React from "react";
import {
  CardContainer,
  AdContainer,
  ImageContainer,
  AdBodyContainer,
  ProductName,
  ProductPrice,
  WebsiteUrl,
  AdBodyBottomContainer,
  AdDate,
  AdCountries,
  Country,
  HoverCard,
  HoverCardWrapper,
  AdDetailsButton
} from "./index.styled"
import locationImg from '../../../../../../media/Dashboard/locationCard.svg'

import RightUpperBlackArrow from "../../../../../../media/Dashboard/RightUpperBlackArrow.svg"


import AltImage from '../../../../../../media/Dashboard/altimage.png'
import { useSavedAds } from "../../../context/savedads";

const BingEcomCard = ({ ad }) => {
  const { savedAdsDispatch } = useSavedAds()
  const image = ad.image ? <img src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${ad.id}`} onError={() => { }} /> : <img src={AltImage} onError={() => { }} />;

  const convertTimestamp = (timestamp) => {
    if (!timestamp) {
      return "-"
    }
    // Create a new Date object based on the timestamp
    var date = new Date(Number(timestamp));

    // Get the month name
    var monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    var month = monthNames[date.getMonth()];

    // Get the year
    var year = date.getFullYear();

    // Assemble the formatted date string
    var formattedDate = date.getDate() + ' ' + month + ' ' + year;

    return formattedDate;
  }

  const toggleDetailsModal = (ad) => {
    savedAdsDispatch({ type: 'SET_SELECTED_AD_FOR_DETAIL', value: ad })
    savedAdsDispatch({ type: 'UPDATE_DETAILS_MODAL', value: true })
  }

  return <CardContainer >
    <HoverCard className='hover-element' onClick={() => toggleDetailsModal(ad)}>
      <HoverCardWrapper>
        <AdDetailsButton onClick={() => toggleDetailsModal(ad)}>
          <img src={RightUpperBlackArrow} />
          <span>Ad Details</span>
        </AdDetailsButton>
      </HoverCardWrapper>
    </HoverCard>
    <AdContainer>
      <ImageContainer>
        {image}
      </ImageContainer>
      <AdBodyContainer>
        <ProductName>
          {ad.title}
        </ProductName>
        <WebsiteUrl>
          {ad.url}
        </WebsiteUrl>
        <AdDate>
          {ad.firstSeen && ad.lastSeen ? (
            <>
              {convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
            </>
          ) : (
            '--'
          )}
        </AdDate>

        <AdBodyBottomContainer>
          <AdCountries>
            <img src={locationImg} />
            <Country>{ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : "--"}</Country>
          </AdCountries>
          <ProductPrice>
            {ad.price}
          </ProductPrice>
        </AdBodyBottomContainer>
      </AdBodyContainer>
    </AdContainer>
  </CardContainer>
}

export default BingEcomCard