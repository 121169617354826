import styled from "styled-components";

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
padding: 32px 24px 24px 24px;
`
export const AdsContainer = styled.div`
display: flex;
max-width: 966px;
width: 100%;
justify-content: space-between;
margin-top: 24px;
flex-wrap: wrap;
row-gap: 20px;

/* @media screen and (max-width: 1500px) {
  width: 100%;
}  */
`
export const GraphWrapper = styled.div`
display: flex;
flex-direction: column;
row-gap: 16px;
`

export const TrendWrapper = styled.div`
display: flex;

`