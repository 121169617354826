import React from "react";
import SkipBtnScreenshot from "../../media/Dashboard/skipButtonImg.jpg"
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";

const PopupContainer = styled.div`
    position: absolute;
    z-index: 1;

    background: #00000045;
    left: 0;
    top: 54px;
    width: 100vw;
    height: calc(100vh - 54px);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PopupBody = styled.div`
    max-width: 520px;
    width: 80%;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px #0000003D;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 112px;
    position: relative;
    img{
        width: 500px;
        margin-bottom: 24px;
    }
    h2{
        margin-bottom: 16px;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        color: #2E2E2E;       
    }
    p{
        font-size: 16px;
        font-weight: 400;
        color: #4E4E4E;
        text-align: center;
        line-height: 21px;
        margin-bottom: 20px;
    }
    span{
        color: black;
        font-weight: 500;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ContinueButton = styled.button`
    width: 276px;
    height: 35px;
    margin-left: 8px;
    border-radius: 8px;
    background-color: rgb(255, 113, 30);
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
    font-weight: 500;
    margin-bottom: 8px;
`;

const CancelButton = styled.button`
    width: 120px;
    height: 32px;
    margin-left: 8px;
    border-radius: 8px;
    color: rgb(46, 46, 46);
    font-size: 14px;
    border: none;
    cursor: pointer;
    background: none;
`;

const TryFreePlanPopup = ({setShowPopup, onSkip, stopShowingPopup, setStopShowingPopup}) => {

    return !stopShowingPopup && <PopupContainer>
        <PopupBody>
        {/* temp change  */}
            {/* <img src={SkipBtnScreenshot}/> */}

            <p>Try our Trial version for FREE?</p>

            <ButtonContainer>
                <ContinueButton onClick={()=>{
                    setShowPopup(false)
                    setStopShowingPopup(true)
                    onSkip()
                    }}>
                    Continue
                </ContinueButton>
                <CancelButton onClick={()=>{                    
                    setStopShowingPopup(true)
                    setShowPopup(false)}}>
                    Cancel
                </CancelButton>
            </ButtonContainer>
        </PopupBody>
    </PopupContainer>
}

export default TryFreePlanPopup