import styled from "styled-components";

export const BrandDetailWrap = styled.div`
  padding: 0 18px 28px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;
export const BrandDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;
export const BrandName = styled.div`
  color: var(--black-100, #1c1c1c);
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
`;
export const BrandContent = styled.div`
  border-radius: 6px;
  border: 1px solid #e8e8e8;
  background: #fff;
  padding: 0 17px 0 16px;

  input {
    padding: 15px 0;
    width: 100%;
    color: var(--black-100, #1c1c1c);
    font-size: 14px;
    font-weight: 300;
    border: none;
    outline: none;
  }
`;
export const BrandDescription = styled.div`
  padding: 16px 0;

  textarea {
    width: 100%;
    height: 89px;
    overflow-y: auto;
    resize: none;
    color: var(--black-100, #1c1c1c);
    font-size: 14px;
    font-weight: 300;
    line-height: 150%;
    outline: none;
    border: none;
  }
  textarea::-webkit-scrollbar {
    width: 5px;
    background-color: rgba(245, 245, 245, 0.92);
    /* border-radius: 10px; */
  }
  textarea::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.19);
    /* border-radius: 10px; */
  }
  textarea::-webkit-scrollbar-track {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 6px inset;
    background-color: rgba(245, 245, 245, 0.28);
    /* border-radius: 10px; */
  }
`;
