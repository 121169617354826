import styled from "styled-components"

export const DayTrendWrapper = styled.div`
margin-top: 16px;
display: flex;
column-gap: 16px;
flex-wrap: wrap;
row-gap: 12px;
width: 966px;
max-width: 100%;
    `
export const DayTrendContainer = styled.div`
width: 475px;
height: 356px;
border-radius: 16px;
background: #FFF;
padding: 24px;
max-width: 100%;
`
export const DayTrend = styled.div`
color: #7D7D7D;
font-family: 'Inter';
font-size: 14px;
font-weight: 400;
        display: flex;
    align-items: center;
    gap: 10px;
`
export const GoogleTrendWrapper = styled.div`
width: 475px;
height: 356px;
border-radius: 16px;
background: #FFF;
display: flex;
align-items: center;
iframe{
height: 100% !important;
border-radius: 16px !important;
box-shadow: unset !important;
&.embed-container *{
    fill: red !important;
}
}
`
export const SkeletonWrap = styled.div`
margin-top: 50px;
width: 100%;
height: 200px;
`