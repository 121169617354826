
import React from 'react';
import { HeatMapGrid } from 'react-grid-heatmap'
import { Container, XaxisTimeContainer } from './index.styled';
import Skeleton from 'react-loading-skeleton';
import { useKeywordTracking } from '../../../../context/keywordtracking';

const xLabels = ["00:","00","","06:","00","","12:","00","","18:","00",""]
const xLablesFilledElements = [1,4,7,10]
const yLabels = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
const yLablesFull = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const data = new Array(yLabels.length)
  .fill(0)
  .map(() =>
    new Array(xLabels.length).fill(0).map(() => Math.floor(Math.random() * 50 + 50))
  )

let timeLabels = ["00:00", "08:00", "16:00", "23:59"]
const HeatMapChart = ({isDataLoading}) => {
  const {keywordTrackingState, keywordTrackingDispatch} = useKeywordTracking()

  const data = keywordTrackingState?.heatMapData;
  // const data = [
  //   [855, 888, 849, 814, 805, 859, 995, 1064, 1261, 1366, 1276, 1157],
  //   [1169, 1308, 1156, 1115, 1004, 1005, 1196, 1475, 1803, 1646, 1478, 1317],
  //   [1391, 1226, 1020, 992, 968, 880, 1058, 1426, 1710, 1395, 1345, 1239],
  //   [1330, 1366, 1167, 1012, 958, 941, 1168, 1555, 1696, 1509, 1402, 1306],
  //   [1290, 1248, 1111, 1006, 1011, 965, 1154, 1429, 1649, 1516, 1279, 1166],
  //   [1263, 1223, 1081, 900, 902, 881, 1117, 1323, 1464, 1238, 1108, 890],
  //   [950, 948, 851, 734, 695, 775, 855, 1037, 1166, 1194, 987, 931],
  // ];
  return (
    isDataLoading ? 
        <Skeleton style={{
            lineHeight: 19,
            width: 610,
          }}/> : 
          <Container
            style={{
              width: "100%",
              fontFamily: "sans-serif",
              marginTop:'50px'
            }}
          >
            <HeatMapGrid
              data={data}
              // xLabels={xLabels}
              yLabels={yLabels}
              // Reder cell with tooltip
              cellRender={(x, y, value) => (
                <div className='heatmap-cell' title={`${yLablesFull[x]} ${y}:00 - ${value}`}></div>
              )}
              xLabelsStyle={index => ({
                color: "#777",
                fontSize: "10px",
                marginTop: "10px",
                paddingLeft: "3px 4px",
                // padding: xLablesFilledElements.includes(index) ? "3px 0px" : "3px 4px",
                paddingRight: xLablesFilledElements.includes(index) ? "5px" : ""
              })}
              yLabelsStyle={() => ({
                fontSize: "13px",
                textTransform: "uppercase",
                color: "rgba(28, 28, 28, 0.50)",
                marginRight: "10px",
                height:'28px',
                display:'flex',
                alignItems: 'center'
                })}
              cellStyle={(_x, _y, ratio) => ({
                background: `rgb(17, 131, 229, ${ratio})`,
                fontSize: ".7rem",
                margin: "4px 5px"
              //   color: `rgb(0, 0, 0, ${ratio / 2 + 0.4})`  
              })}
              cellHeight="20px"
              xLabelsPos="bottom"
              // onClick={(x, y) => alert(`Clicked (${x}, ${y})`)}
              // yLabelsPos="s"
              square
            />
            {/* <XaxisContainer>
              {[...Array(4)].map((index)=>{
                return <span></span>
              })}
            </XaxisContainer> */}
            <XaxisTimeContainer>
              {timeLabels.map((time)=>{
                return <p>{time}</p>
              })}
            </XaxisTimeContainer>
          </Container>
  );
};

export default HeatMapChart;


