import { useImageAdGenerator } from "../../context/imageAdGeneration";
import statics from "../../statics";
import { Header, Body, Logo, LogoContainer, Query, RootContainer, StepContainer, StepNoContainer, Title, WalthroughContainer, StepNo, Step, CompletedLine, Content, PageNavigation, BackContainer, BackIcon, BackText, NextButton, HomeRootContainer } from "./index.styled";
import checkMark from "../../assets/checkMark.svg"
import back from "../../assets/back.svg"

import AdGoal from "./AdGoal";
import TargetAudience from "./TargetAudience";
import BrandDescription from "./BrandDescription";
import ChoosingCategory from "./ChoosingCategory";
import AdType from "./AdType";
import Tooltip from 'rc-tooltip';

function InputPages() {
    const { imageAdGeneratorState, imageAdGeneratorDispatch } = useImageAdGenerator()
    const { currentPage } = imageAdGeneratorState
    const { pages } = statics

    const renderWalkthrough = () => {
        const handleActiveSidebar = (pageNo) => {
            switch (pageNo) {
                case 1:
                    if (pageNo == 1) {
                        return true;
                    }
                    break;
                case 2:
                    if (imageAdGeneratorState.adType && imageAdGeneratorState.adGoal && imageAdGeneratorState.adGoal.trim() !== '') {
                        return true;
                    }
                    break;
                case 3:
                    const targetAudience = imageAdGeneratorState.targetAudience;
                    if (targetAudience && targetAudience.ageGroup && targetAudience.gender) {
                        return true;
                    }
                    break;
                case 4:
                    const brandDetails = imageAdGeneratorState.brandDetails;
                    if (brandDetails && brandDetails.name && brandDetails.description) {
                        return true;
                    }
                    break;
                default:
                    return false;
            }
            return false;
        };
        return (
            <WalthroughContainer>
                {pages.map((page) => {
                    if(page.no > 4) return
                    const isCurrent = page.no === currentPage.no
                    const isActive = handleActiveSidebar(page.no);
                    return (
                        <StepContainer onClick={() => imageAdGeneratorDispatch({type: "UPDATE_CURRENT_PAGE", value: pages[(page.no - 1)]})} isActive = {isActive}>
                            {page.no < currentPage.no ?
                                <LogoContainer>
                                    <Logo src={checkMark} alt="check" />
                                </LogoContainer> :
                                <StepNoContainer isCurrent = {isCurrent} >
                                    <StepNo isCurrent = {isCurrent}>{page.no}</StepNo>
                                </StepNoContainer>
                            }
                            <Step isCompleted={page.no < currentPage.no} isCurrent = {isCurrent}>{page.name}</Step>
                            <CompletedLine isCompleted={page.no < currentPage.no} isLast = {page.no === 4} />
                        </StepContainer>
                    )
                })}
            </WalthroughContainer>
        )
    }

    const renderPageComponent = () => {
        switch(currentPage.no) {

            case 2:
            return <AdType />

            case 3: 
            return <TargetAudience />

            case 4:
            return <BrandDescription />

            // case 5:
            // return <AdType />

            default:
            return <AdGoal />

        }
    }

    const handleNext = () => {
        switch(currentPage.no){
            
            case 2:
                if(!imageAdGeneratorState.adType) return
                imageAdGeneratorDispatch({type: "UPDATE_CURRENT_PAGE", value: pages[currentPage.no]})
            break
            case 3:
                if(!imageAdGeneratorState.targetAudience || !imageAdGeneratorState.targetAudience.ageGroup || !imageAdGeneratorState.targetAudience.gender) return
                imageAdGeneratorDispatch({type: "UPDATE_CURRENT_PAGE", value: pages[currentPage.no]})
            break
            case 4:
                if(!imageAdGeneratorState.brandDetails || !imageAdGeneratorState.brandDetails.name || !imageAdGeneratorState.brandDetails.description) return
                imageAdGeneratorDispatch({type: "UPDATE_CURRENT_PAGE", value: pages[currentPage.no]})
            break
            // case 5:
            //     if(!imageAdGeneratorState.adType) return
            //     imageAdGeneratorDispatch({type: "UPDATE_CURRENT_PAGE", value: pages[currentPage.no]})
            // break
            default: 
                imageAdGeneratorDispatch({type: "UPDATE_CURRENT_PAGE", value: pages[currentPage.no]})
            break
        }
    }

    const handleActiveState = () => {
        switch (currentPage.no) {
            case 2:
                if (imageAdGeneratorState.adType &&( imageAdGeneratorState.adGoal &&
                    imageAdGeneratorState.adGoal.trim() !== '')) {
                    return true;
                }
                break; 
            case 3:
                const targetAudience = imageAdGeneratorState.targetAudience;
                if (targetAudience.ageGroup && targetAudience.gender) {
                    return true;
                }
                break;
    
            case 4:
                const brandDetails = imageAdGeneratorState.brandDetails;
                if (brandDetails.name && brandDetails.description) {
                    return true;
                }
                break;
    
            default:
                return false;
        }
    
        return false;
    };
    const handleBack = () => {
        imageAdGeneratorDispatch({type: "UPDATE_CURRENT_PAGE", value: pages[currentPage.no - 2]})
    }
    const toolTipFunction = ()  =>{
        return (
            <div>
                <div style={{marginBottom: '16px',fontSize: '14px', color: 'var(--black-100, #1C1C1C)',fontWeight: '500'}}>
                Primary Ad Goal is the target you want to achieve by using the ad campaign. It provides a direction to the ad creation and messaging so that your set business goals are aligned with your advertising campaigns. 
                </div>
            </div>
        )
    }
    const tooltipStyle = {
        marginTop: '100px'
    };
      

  
    const overlayInnerStyle = {
        backgroundColor: '#FFF7E8',
        color: 'rgba(28, 28, 28, 0.80)',
        padding: '24px 20px 0 20px',
        fontSize: '14px',
        marginTop: '120px',
        width: '400px',
        height: '135px',
        overflowY: 'auto'
    }; 
    return (  
        currentPage.no === 1 
        ?
            <HomeRootContainer>
                <ChoosingCategory />
            </HomeRootContainer>
        :
            <RootContainer>
                <Header>
                    <Title>{`${currentPage.no}. ${currentPage.name}`}</Title>
                    <Tooltip 
                            trigger={['hover']}  
                            style={tooltipStyle}
                            overlayInnerStyle={overlayInnerStyle} 
                            showArrow={false}
                            arrowContent={null}
                            overlay={toolTipFunction()}
                        >
                    <Query>{currentPage.query}</Query>
                    </Tooltip>
                </Header>
                <Body>
                    {renderWalkthrough()}
                    <Content>
                        {renderPageComponent()}
                        <PageNavigation>
                            <BackContainer onClick={handleBack} >
                                <BackIcon src={back} alt="back" />
                                <BackText>back</BackText>
                            </BackContainer>
                            <NextButton onClick={handleNext} isActive={handleActiveState()}>Next</NextButton>
                        </PageNavigation>
                    </Content>
                </Body>
            </RootContainer>
     );
}

export default InputPages;