import { useAuth } from '../../context/auth';
import { appClient } from '../../utils/axios-utils';
import { useMutation, useQuery } from "@tanstack/react-query"
import { useStripe, CardElement, useElements } from "@stripe/react-stripe-js";
import { customToast } from '../../utils/toast-utils';
import { getIdsFromAds } from "../../utils/filterAds"

const SaveAd = async (data) => {
  const config = {
    url: "/dashboard-overview/project/save-ads",
    method: "POST",
    data,
  };

  return appClient(config);
};

const RemoveSaveAd = async (data) => {
  const config = {
    url: "/dashboard-overview/project/remove-saved-ads",
    method: "POST",
    data,
  };

  return appClient(config);
};

const GetSavedAds = async (data) => {
  const config = {
    url: "/dashboard-overview/project/saved-ads",
    method: "POST",
    data,
  };

  return appClient(config);
};

export const useSaveAds = () => {

  const { authState, authDispatch } = useAuth()

  const { mutate: SaveAdMutate, isLoading: isSaveAdLoading } =
    useMutation(SaveAd, {
      onSuccess: (data) => {
        customToast({
          message: "Ad saved successfully",
          type: "success",
          position: "top-right",
          duration: 2000
        })
        GetSavedAdsMutate({projectId: authState.defaultProjectInfo.projectId})
      },
      onError: (err) => {
        console.log(err);
      },
    });

  const { mutate: RemoveSavedAdMutate, isLoading: isRemoveSavedAdLoading } =
    useMutation(RemoveSaveAd, {
      onSuccess: (data) => {
        customToast({
          message: "Removed saved ad",
          type: "success",
          position: "top-right",
          duration: 2000
        })
        GetSavedAdsMutate({projectId: authState.defaultProjectInfo.projectId})
      },
      onError: (err) => {
        console.log(err);
      },
    });

  const { mutate: GetSavedAdsMutate, isLoading: isGetSavedAdsLoading } =
    useMutation(GetSavedAds, {
      onSuccess: (data) => {
        if (data.result && data.result?.length > 0) {
          authDispatch({ type: "SET_SAVED_ADS", value: data.result })
          authDispatch({ type: "SET_SAVED_ADS_IDS", value: getIdsFromAds(data.result)})
        } else {
          authDispatch({ type: "SET_SAVED_ADS", value: [] })
          authDispatch({ type: "SET_SAVED_ADS_IDS", value: [] })
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });

  return {
    SaveAdMutate,
    isSaveAdLoading,
    RemoveSavedAdMutate,
    isRemoveSavedAdLoading,
    GetSavedAdsMutate,
    isGetSavedAdsLoading
  };
};
