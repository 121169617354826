import styled from "styled-components";

export const Container = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding-top: 110px;

`
export const CampaignDiv = styled.div`
color: rgba(28, 28, 28, 0.90);
text-align: center;
font-family: Inter;
font-size: 20px;
font-weight: 600;
line-height: 26px; /* 130% */
margin-top: 24px;
`
export const CampaignDescrip = styled.div`
width: 543px;
color: rgba(28, 28, 28, 0.60);
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 170%; /* 27.2px */
margin-top: 16px;

a{
    color: #1679DB;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 170%;
text-decoration: none;
}
`
export const HomeBtn = styled.div`
width: 80px;
height: 40px;
border-radius: 6px;
background: #FF711E;
color: #FFF;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
display: flex;
justify-content: center;
align-items: center;
margin-top: 38px;
cursor: pointer;
`