import styled from 'styled-components';


export const PersonaListContainer = styled.div`
display:flex;
flex-direction:column;
gap:26px;
padding-top:18px;
`;

export const PersonaListHeader = styled.div`
display:flex;
gap:24px;

.bold-words{
flex-shrink:0;
flex-grow:0;
color: #2E2E2E;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.bold-words > span{
color: #555;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: normal;}

.middle-line{
flex-grow:1;
height:14px;
display:flex;
align-items:center;
}

.middle-line > span{
width:100%;
height:2px;
flex-shrink: 0;
stroke-width: 1px;
background: #DADFE4;
}

`;


export const PersonaCardsContainer = styled.div`
display:flex;
gap:2.5%;`

export const PersonaCards = styled.div`
width: ${props => props.detailsOpen ? "60%": "100%"};
display:flex;
flex-wrap:wrap;
gap:2%;

`;

export const Card = styled.div`
width:32%;
min-width:200px;
height:144px;
display:flex;
padding:10px;
border-radius: 8px;
border-right: 1px #FFF;
border-bottom: 1px #FFF;
background: #FFF;
margin-bottom:16px;
gap:20px;
@media screen and (max-width: 1500px) {
  width:48%;
};
@media screen and (max-width: 768px) {
  width:100%;
};
`

export const ImagePlaceHolder = styled.img`
width:40%;
height:124px;


`

export const PersonaDetails = styled.div`
width:50%;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;

div {
color: #000;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px;
text-align:center;
}

// p{
// color: #7D7D7D;
// font-family: Inter;
// font-size: 14px;
// font-style: normal;
// font-weight: 400;
// line-height: 24px; }

`;

export const PersonaDetailsCardConatianer = styled.div`
width:40%;
max-height:784px;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
padding:34px;
border-radius: 8px;
margin-bottom:16px;
background: linear-gradient(to right, #C6B3E0 0%, #FFFFFF 30%, #E1F6F5 100%);`;

export const PersonaDetailsContainer = styled.div`
width:100%;
// height:85%;
padding:24px;
border-radius: 8px;
border-right: 1px #FFF;
border-bottom: 1px #FFF;
background: #FFF;
display:flex;
flex-direction:column;
gap:29px;`

export const GenerateAdButton = styled.button`
 background-color: #ff6b00;
    color: #fff;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    border-radius: 4px;
    cursor: pointer;
    align-self: center;
    margin-top: 24px;

    &:hover {
      background-color: #e65c00;
    }
`

export const DetailsHeader = styled.div`
display:flex;
gap:26px;`

export const ImgDiv = styled.div`
width: ${props => props.value};
height:  ${props => props.value};
border-radius:50%;
background:#D9D9D9;
`
export const DetailName = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
`

export const DetailsBody = styled.div`
display:flex;
flex-direction:column;
gap:16px;`

export const DetailsValues = styled.div`
display:flex;
flex-direction:column;
gap:8px;`

export const DetailsFooter = styled.div`
padding:0px 20px;`

export const PersonaAnalysisCard = styled.div`
display:flex;
flex-direction:column;
gap:8px;
border-radius: 8px;
border: 2px solid var(--gray-300, #CBCBCB);
background: var(--white, #FFF);

`

export const AnalysisDetails = styled.div`
display:flex;
padding:8px;
gap:16px;


`

export const AnalysisValue = styled.div`
display:flex;
gap:16px;
background: var(--red-50, #FFF4F7);
padding:8px;


div {
width: 10px;
align-self: stretch;
border-radius: 10px;
background: var(--red-400, #EC1E4D);}`