import react, { useState, useEffect } from "react";
import {
  Container,
  SelectContainer,
  CloseButton,
  Heading,
  AdsContainer,
  LoadSelectedAdsButton
} from "./index.styled";
import { useMutation } from "@tanstack/react-query";

import { appClient } from "../../../../../../../utils/axios-utils";
import { useAuth } from "../../../../../../../context/auth";
import {useAdGenrator} from "../../../../context/adgenerator";
import AdCard from "../../../AdCards";
import SearchLoader from "../../../../../../HOC/SearchLoader";

const getProjectAds = (data) => {
    const config = {
      url: "/adgeneration/project/adcopies",
      method: "POST",
      data
    };
  
    return appClient(config);
}

const SelectProjectAds   = () => {
  const { authState, authDispatch } = useAuth();
  const {adGenratorState, adGenratorDispatch} = useAdGenrator()

  const {mutate: getProjectAdsMutate, isLoading: isProjectAdsLoading} = useMutation(getProjectAds, {
        onSuccess: (data) => {
            adGenratorDispatch({type:'SET_PROJECT_ADS', value: data})
        },
        onError: (err) => {
            console.log(err)
        },
        onMutate: () => {
        }
    })

    const ToggleSelectedAds = (id) => {
        if(adGenratorState.selectedAds.includes(id)){
            let finalData = adGenratorState.selectedAds.filter((d)=>{
                return d != id
            })
            adGenratorDispatch({type: 'SET_SELECTED_ADS', value: finalData})
        }else{
            let finalData = [...adGenratorState.selectedAds, id]
            adGenratorDispatch({type: 'SET_SELECTED_ADS', value: finalData})
        }
    }

    useEffect(() => {
        getProjectAdsMutate({projectId: adGenratorState.selectedProject.id})
    }, [])
    


  return (
    <Container>
      <SelectContainer>
        <CloseButton onClick={() => adGenratorDispatch({type:'SET_SELECT_PROJECT_ADS_OPEN', value: false})}>x</CloseButton>
        <Heading>
            <h1>
                {adGenratorState.selectedProject.name}
            </h1>
            <LoadSelectedAdsButton onClick={()=>{
                adGenratorDispatch({type: 'SET_CURRENT_PROMPT', value: "home"});adGenratorDispatch({type:'SET_SELECT_PROJECT_ADS_OPEN', value: false})
            }} isDisabled={adGenratorState.selectedAds.length == 0}>
                Load Selected ads
            </LoadSelectedAdsButton>
        </Heading>
        {isProjectAdsLoading ? <div className='loader'>
                <SearchLoader message={"Loading project Ads"}/>
            </div>: adGenratorState.projectAds.length > 0 ? <AdsContainer style={{padding: 0}}>
                    {
                        adGenratorState.projectAds.map((ad,idx)=>{
                            return <AdCard ToggleSelectedAds={ToggleSelectedAds} ad={ad}/>
                        })
                    }
                </AdsContainer> : ""}
      </SelectContainer>
    </Container>
  );
};

export default SelectProjectAds ;
