import { useDomainFunnelAnalysis } from "../../context/domainFunnelAnalysis";
import { AgeBreakdownContainer, AgeLabel, AgePercentageContainer, Card, CardsWrapper, Header, NoDataContainer, PercentageDistribution, PercentageValue, SerialNo, Title } from "./TargetAudience.styled";
import Skeleton from "react-loading-skeleton";

function TargetAudience({isFunnelDataLoading}) {
    const { domainFunnelAnalysisState } = useDomainFunnelAnalysis()
    const { funnelTargetAudienceData,activeFunnelType } = domainFunnelAnalysisState
    const funnelArr = ['income','age','gender']
    return ( 
        <CardsWrapper>
            {isFunnelDataLoading ?
            funnelArr.map((item,index)=>(
            <Card key={index}>
                 <Header>
                    <SerialNo>{`${(index + 1).toString().padStart(2, 0)}.`}</SerialNo>
                    <Title>{item}</Title>
                </Header>
                <Skeleton height={"90%"} borderRadius= {"16px"} style={{'marginTop': '20px'}}/>
            </Card>
            ))
            
            :
            domainFunnelAnalysisState?.funnelDomainData?.funnelData && domainFunnelAnalysisState?.funnelDomainData?.funnelData?.map((item) => {
                  const { id } = item
                  const isActive = id === activeFunnelType.id
                  
                return(
                    <>
                    {isActive && Object.keys(item.targetAudience).map((data,index)=>{
                        const audienceData = item.targetAudience[data];
                        return(
                    <Card key={index}>
                        <Header>
                            <SerialNo>{`${(index + 1).toString().padStart(2, 0)}.`}</SerialNo>
                            <Title>{data}</Title>
                        </Header>
                        {/* <Description4Title>{data.description}</Description4Title>
                        <PercentageContainer>
                            <Percentage>{data.audiencePercentage.value}</Percentage>
                            <InfoButton src={info} alt="" />
                            <Divider />

                            <PercentageDescription>
                                <HighlightenText>{data.audiencePercentage.description.highlight}</HighlightenText>
                                <NormalText>{data.audiencePercentage.description.normalText}</NormalText>
                            </PercentageDescription>
                            <Divider />
                        </PercentageContainer> */}
                        <AgeBreakdownContainer>
                                {/* <BoldText>Age Breakdown:</BoldText> */}
                                  {audienceData.length > 0 ? audienceData.map((ageData) => (
                                <AgePercentageContainer key={ageData.key}>
                                    <AgeLabel>{ageData.key}</AgeLabel>
                                    <PercentageDistribution>
                                        <PercentageValue value={ageData.percentage} max="100"/>
                                    </PercentageDistribution>
                                </AgePercentageContainer>
                            )):isActive && 
                            <NoDataContainer>
                                No Data Found
                            </NoDataContainer>
                            }
                        </AgeBreakdownContainer>
                    </Card>
                    )
                    })}
                    </>
                )
            })}
        </CardsWrapper>
     );
}

export default TargetAudience;