import React from "react";
import LeftArrowIcon from '../../../../../media/Dashboard/backarrow.svg'
import SampleHistoryImage from '../../../../../media/Dashboard/dummy-downloaded.png'
import CalanderIcon from '../../../../../media/Dashboard/calender.svg'
import EditIcon from '../../../../../media/Dashboard/edit-white.svg'
import GlobeIcon from '../../../../../media/Dashboard/globe.svg';

import {
    Wrapper, HeaderContainer, MaxSizeWrapper, EditNameContainer, NameContainer, DateContainer, HistoryCard, HistoryCardContent, HistoryCardLeft, HistoryCardRight, DescriptionWrap, ImageContainer, CardBody, LogoWrapper, TitleWrap, Title, LinkWrap, KeywordWrap, DraftsList, Header
} from './index.styled'
import { convertTimestamp } from "../../../../../utils/dateFormat";
import { useNavigate } from "react-router-dom";

let historyData = [{
    domainId: "apple.com",
    url: "https://apple.com",
    title: "Visit nearby Apple store",
    lastSeen: 1646005208685,
    content: "Shop Apple Watch Apple & More. Get Apple Watch Apple at Target™ Today. Orders $35+ Ship Free. Shop Apple Watch Apple at Target™. Search Products. Find Stores."
}, {
    domainId: "apple.com",
    url: "https://apple.com",
    title: "Visit nearby Apple store",
    lastSeen: 1646005208685,
    content: "Shop Apple Watch Apple & More. Get Apple Watch Apple at Target™ Today. Orders $35+ Ship Free. Shop Apple Watch Apple at Target™. Search Products. Find Stores."
}, {
    domainId: "apple.com",
    url: "https://apple.com",
    title: "Visit nearby Apple store",
    lastSeen: 1646005208685,
    content: "Shop Apple Watch Apple & More. Get Apple Watch Apple at Target™ Today. Orders $35+ Ship Free. Shop Apple Watch Apple at Target™. Search Products. Find Stores."
}, {
    domainId: "apple.com",
    url: "https://apple.com",
    title: "Visit nearby Apple store",
    lastSeen: 1646005208685,
    content: "Shop Apple Watch Apple & More. Get Apple Watch Apple at Target™ Today. Orders $35+ Ship Free. Shop Apple Watch Apple at Target™. Search Products. Find Stores."
}, {
    domainId: "apple.com",
    url: "https://apple.com",
    title: "Visit nearby Apple store",
    lastSeen: 1646005208685,
    content: "Shop Apple Watch Apple & More. Get Apple Watch Apple at Target™ Today. Orders $35+ Ship Free. Shop Apple Watch Apple at Target™. Search Products. Find Stores."
}]

const AdsDraft = ({ adType }) => {
    const navigate = useNavigate()
    return <Wrapper>
        <MaxSizeWrapper>
            <HeaderContainer>
                <img src={LeftArrowIcon} onClick={()=>navigate(-1)}/>
                <h2>Drafts</h2>
            </HeaderContainer>
            <DraftsList>
                {historyData.map(history => {
                    return <HistoryCard adType={adType}>
                        <ImageContainer >
                            {adType != "imagead" ? <CardBody>
                                <Header>
                                    <LogoWrapper>
                                        <img src={GlobeIcon} />
                                    </LogoWrapper>
                                    <TitleWrap>
                                        <Title>{history.domainId}</Title>
                                        <LinkWrap>{history.url}</LinkWrap>
                                    </TitleWrap>
                                </Header>
                                <KeywordWrap>{history.title}</KeywordWrap>
                                <DescriptionWrap>
                                    {`${convertTimestamp(history.lastSeen)} __ ${history.content}`}
                                </DescriptionWrap>
                            </CardBody> : <img src={SampleHistoryImage} />}
                        </ImageContainer>
                        <HistoryCardContent>
                            <HistoryCardLeft>
                                <DateContainer>
                                    <img src={CalanderIcon} /><span>23-07-2024</span>
                                </DateContainer>
                                <NameContainer>
                                    Couponswala
                                </NameContainer>
                            </HistoryCardLeft>
                            <HistoryCardRight>
                                <EditNameContainer>
                                    <img src={EditIcon} />
                                </EditNameContainer>
                            </HistoryCardRight>
                        </HistoryCardContent>
                    </HistoryCard>
                })}
            </DraftsList>
        </MaxSizeWrapper>
    </Wrapper>
}

export default AdsDraft