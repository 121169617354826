import React from "react";

import CountryIcon from "../../../../../../media/Images/CountryIcon.svg"
import { AdCountries, AdDate, AdDetailsButton, Card, CardBody, CardFooter, FooterLeft, HoverCard, HoverCardWrapper, ImageContainer } from "./index.styled";
import { useSavedAds } from "../../../context/savedads";
import RightUpperBlackArrow from "../../../../../../media/Dashboard/RightUpperBlackArrow.svg"
import AltImage from '../../../../../../media/Dashboard/altimage.png'
import colourCalender from '../../../../../../media/Dashboard/colourCalender.svg'

const DisplayCard = ({ad}) => {
    const { savedAdsDispatch } = useSavedAds()

    const convertTimestamp = (timestamp) => {
        var date = new Date(timestamp);

        var monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        var month = monthNames[date.getMonth()];

        var year = date.getFullYear();

        var formattedDate = date.getDate() + ' ' + month + ' ' + year;

        return formattedDate;
    }
    const toggleDetailsModal = () => {
            savedAdsDispatch({type: 'SET_SELECTED_AD_FOR_DETAIL', value: ad})
            savedAdsDispatch({ type: 'UPDATE_DETAILS_MODAL', value: true })
    }
    return <Card key={
        ad.id
    }>
         <HoverCard className='hover-element' >
            <HoverCardWrapper>
                <AdDetailsButton onClick={() => toggleDetailsModal(ad)}>
                    <img src={RightUpperBlackArrow} />
                    <span>Ad Details</span>
                </AdDetailsButton>
            </HoverCardWrapper>
        </HoverCard>
        <CardBody>
            <ImageContainer>
                {ad.image ? <img onLoad={(e) => {
                }} src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${ad.id}`} onError={() => { }} /> : ad.video ? <video src={`https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${ad.id}`} preload="auto" controls loop style={{ width: "100%", height: "100%" }} /> : <img src={AltImage} onError={() => { }} />}
               
            </ImageContainer>
        </CardBody>
        <CardFooter>
            <FooterLeft>
                <AdDate>
                    {ad.firstSeen && ad.lastSeen && <img src={colourCalender} />}{convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
                </AdDate>
                {ad.country && <AdCountries>
                    <img src={CountryIcon} />{ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
                </AdCountries>}
            </FooterLeft>
        </CardFooter>
    </Card>
}

export default DisplayCard