import React from 'react'
import { PersonaPageConatiner, PersonaIntroductionCard, CardContent } from './Persona.styled'
import CompetitorTargetOverview from './components/CompetitorTargetOverview/CompetitorTargetOverview'


const Persona = () => {
  return (

    <PersonaPageConatiner>
      <PersonaIntroductionCard>
        <CardContent>
          <h2>Want to Know Which Personas You’re Missing in Your Campaigns?</h2>
          <p>Connect Your Google Ad Account Now to Compare with
            Your Competitors’ Targeting.</p>
          <button>Connect your Ad account</button>
        </CardContent>
      </PersonaIntroductionCard>
      <CompetitorTargetOverview />
    </PersonaPageConatiner>

  )
}

export default Persona