import React, {useState} from 'react'
import { CountryHeader, KeyData, KeyHeader,PlatformData, PlatformHeader, Row, StatusData, StatusHeader, TableBody, TableHeader, TableWrap, Wrapper } from './index.styled';
import {
    createColumnHelper,
    getCoreRowModel,
    useReactTable
  } from '@tanstack/react-table'

// import { useDomainTracking } from '../../../../context/domaintracking';
import { useAdManagementInfo } from "../../../../hooks/useAdManagementInfo";
import { useNavigate } from "react-router-dom"
import { useAdManagement } from "../../../../context/AdManagementContext";
import Skeleton from "react-loading-skeleton";

const CampaignTable = () => {
    const columnHelper = createColumnHelper();
    const [sorting, setSorting] = useState([]);
    const adManagementHook = useAdManagementInfo()
    const { adManagementState, adManagementDispatch } = useAdManagement()

    const navigate = useNavigate()

    const columns = [
        columnHelper.accessor('no', {
            id: 'no',
            header: () => 'No',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('keyword', {
            id: 'keyword',
            header: () => 'Keyword',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('status', {
            id: 'status',
            header: () => 'Status',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('platform', {
            id: 'platform',
            header: () => 'Platform',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('country', {
            id: 'country',
            header: () => 'Country',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor(row => `${new Date(row.updatedOn).getTime()}`, {
            id: 'updatedOn',
            header: () => 'updatedOn',
            cell: info => info.getValue(),
        }),
    ];

    const table = useReactTable({
        data: adManagementState.isListAdAccounts,
        columns,
        state: {
            sorting,
            sortBy: [{ id: "datetime", desc: false }],
          },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        // getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });



const handleSelectAccount = (row) =>{
    adManagementDispatch({type: "SET_MANAGER_CUSTOMER_ID", value: row.manager? row.manager : row.id })
    console.log(row)
    adManagementHook.setConnectAccount({
        "integrationId": adManagementState.isSetIntegrationId,
        "managerId": row.manager,
        "customerId": row.id,
        "currency": row.currency ,
        "name": row.name
    })
}
console.log(adManagementHook.isListAdAccountsLoading,'adManagementHook.isListAdAccountsLoading')
    return (
    <Wrapper>
        <TableWrap>
                    {table.getHeaderGroups().map(headerGroup => {
                        return <TableHeader>
                        <KeyHeader>
                        Ad account{" "}
                        </KeyHeader>
                        <StatusHeader >
                        Account ID{" "}
                        </StatusHeader>
                        <PlatformHeader >
                        Status{" "}                              
                        </PlatformHeader>
                        <CountryHeader>
                        {" "}                              
                        </CountryHeader>
                        {/* <UpdatedHeader onClick={headerGroup.headers[5].column.getToggleSortingHandler()}>
                        Clicks{" "}                               
                        </UpdatedHeader>
                        <UpdatedHeader onClick={headerGroup.headers[5].column.getToggleSortingHandler()}>
                        Amount spent{" "}                              
                        </UpdatedHeader> */}
                    </TableHeader>
                       
                            })}
                    {adManagementHook.isListAdAccountsLoading ? 
                        <Skeleton style={{'height':'50px',marginTop:'10px'}}/>
                            :
                        <TableBody>
                        {table.getRowModel().rows.map((row, rowIndex) => (
                                <Row>
                                    {/* <HoverWrapper> */}
                                        <KeyData>{row.original.name}</KeyData>
                                        <KeyData>{row.original.id}</KeyData>
                                        <StatusData connected={row.original.connected}>
                                            {row.original.connected ? 'Connected' : 'Not connected'}
                                        </StatusData>
                                        {!row.original.connected && <PlatformData><button onClick={() => handleSelectAccount(row.original)}>Select</button></PlatformData>}
                                        {/* <CountryData>{row.original.country}</CountryData>
                                        <UpdatedData> {moment(row.original.updatedOn).format('YYYY-MM-DD HH:mm')}</UpdatedData> */}
                                    {/* </HoverWrapper> */}
                                </Row>
                            ))}
                        </TableBody>
                    }
        </TableWrap>
    </Wrapper>
  )
}

export default CampaignTable
