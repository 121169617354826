import { Container, Left, LogoContainer, ChildrenHolder, ChildrenContainer, SubContainer, OnBoardingContainer, OnBoardSubContainer, OnBoardChildrenHolder, Divider, SubDivider } from "./layout.styled"
import AdspyderWhiteLogo from "../../../../../media/Dashboard/logo_white.svg"
import AdspyderLogo2024 from "../../../../../media/Images/AdspyderLogo2024.svg"

const Layout = ({ children }) => {
  return <Container>
  <SubContainer>
    <Left>
      <LogoContainer>
        <img src={AdspyderLogo2024} />
      </LogoContainer>
    </Left>
    <ChildrenContainer>
      <ChildrenHolder>
        {children}
      </ChildrenHolder>
    </ChildrenContainer>

  </SubContainer>
</Container>
}

export const NewLayout = ({ children }) => {
  return <OnBoardingContainer>
    <OnBoardSubContainer>
      <Left>
        <LogoContainer>
          <img src={AdspyderWhiteLogo} />
        </LogoContainer>
      </Left>
      <OnBoardChildrenHolder>
        {/* <Divider><SubDivider></SubDivider></Divider> */}
        <ChildrenHolder>
          {children}
        </ChildrenHolder>
      </OnBoardChildrenHolder>

    </OnBoardSubContainer>
  </OnBoardingContainer>
}

export default Layout;