import styled from "styled-components";

export const ContentHeading = styled.h2`
    color: #2E2E2E;
    font-size: 18px;
    line-height: normal;
    font-weight: 600;
    margin-bottom: 15px;
`;

export const KeywordContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 32px;
`;

export const TableContainer = styled.div`
    max-width: 1206px;
    width: 50%;
`;

export const TableHeader = styled.div`
    display: flex;
    justify-content: space-between;
    border-radius: 8px 8px 0px 0px;
    border: 1px solid #ECECEC;
    background: #ECECEC;
    font-size: 12px;
    color: #434343;
    font-weight: 300;
    line-height: normal;    
`;

export const NoHeader = styled.div`
    padding: 9px 20px 9px 20px;
    width: 25%;
`;

export const QueryHeader = styled.div`
    padding: 9px 0px 9px 0px;
    width: 75%;
`;

export const TableBody = styled.div`
    overflow-y: auto;
    height: calc(100vh - 250px);
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    border-right: 1px solid #ECECEC;
    border-bottom: 1px solid #ECECEC;
    border-left: 1px solid #ECECEC;
    background: #FFF;
    height: 40px;

    font-size: 14px;
    font-weight: 300;
    line-height: normal;

    cursor: pointer;    
    &:hover{
        background: #F6F6F6;
        .search-now-btn{
            display: flex;
        }
        .ads-data{
            padding: 3px 20px;
            justify-content: space-between;
        }
    }

    &:active{
        background: #F2F2F2;
    }
`;

export const NumberData = styled.div`
    padding: 0px 20px 0px 20px;
    width: 25%;
    margin: auto;
`;

export const QueryData = styled.div`
    padding: 0px 0px 0px 0px;
    width: 75%;
    overflow: hidden;
    color: #434343;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: auto;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const RightContainer = styled.div`
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: end;
    /* gap: 20px; */
`;

export const SimilarKeywordsContainer = styled.div`
    height: 38vh;
    border: 1px solid #F2F2F2;
    border-radius: 8px;
    background: #FFF;
    margin-bottom: 20px;
    width: 100%;
    h3{
        font-size: 14px;
        font-weight: 300;
        color: #2E2E2E;
        line-height: normal;
        margin-bottom: 8px;
        padding: 16px 20px 0px;
    }
`;

export const KeywordDomainContainer = styled.div`
    height: 38vh;
    border: 1px solid #F2F2F2;
    border-radius: 8px;
    background: #FFF;
    width: 100%;
    h3{
        font-size: 14px;
        font-weight: 300;
        color: #2E2E2E;
        line-height: normal;
        margin-bottom: 8px;
        padding: 16px 20px 0px;
    }
`;

export const SelectedKeyword = styled.div`
    padding: 0px 20px 16px;
    border-bottom: solid 1px #80808012;

`;

export const SimilarKeywordsList = styled.div`
    overflow: auto;
    height: 25vh;
    margin-right: 5px;
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const SimilarKeywordsDomainList = styled.div`
    overflow: auto;
    height: 25vh;
    margin-right: 5px;
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const SimilarKeyword = styled.div`
    overflow: hidden;
    color: #434343;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: auto;
    line-height: 20px;
    font-size: 14px;
    font-weight: 300;
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    span{
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    cursor: pointer;    
    &:hover{
        background: #F6F6F6;
    }

    &:active{
        background: #F2F2F2;
    }
    &:hover{
        .view-ads-btn{
            display: flex;
        }
    }
`;

export const SimilarKeywordDomain = styled.div`
    overflow: hidden;
    color: #434343;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: auto;
    line-height: 20px;
    font-size: 14px;
    font-weight: 300;
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    span{
        cursor: pointer;
        text-decoration: underline;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    cursor: pointer;    
    &:hover{
        background: #F6F6F6;
    }

    &:active{
        background: #F2F2F2;
    }
    &:hover{
        .view-ads-btn{
            display: flex;
        }
    }
`;

export const ResetButton = styled.div`
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    color: #565656;
    cursor: pointer;
    border-radius: 8px;
    background: #ECECEC;
    padding: 6px 12px;
    margin-bottom: 8px;
    width: 82px;
`;

export const ViewAdsButton = styled.div`
    border-radius: 8px;
    background: #0A66C2;
    font-size: 14px;
    font-weight: 300;
    color: #FFF;
    padding: 6px 12px;
    display: none;
    cursor: pointer;
`;

export const SearchNow = styled.div`
    background: rgb(0, 110, 204);
    border-radius: 8px;
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 300;
    border: none;
    outline: none;
    padding: 6px 12px;
    cursor: pointer;
    width: fit-content;
    display: none;
    margin-right: 50px;
`;