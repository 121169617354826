import React from 'react'
import { AdDistributionWrap, MapsContainer, MapsContainerBody, CountryChartContainer } from './index.styled'

import MapChart from "../MapChart"
import MapChartTable from "../MapChartTable"
import Tooltip from 'rc-tooltip'
import helpCircle from '../../../../../../media/Dashboard/help-circle.svg'

const AdDistribution = ({isMapDistributionLoading}) => {
  return (
    <AdDistributionWrap>
            <MapsContainer>
                <h4>Ad Distribution by country<Tooltip trigger={['hover']} overlayStyle={{ width: "100" }} overlay={<span>Total ads found in each country</span>}>
                <img src={helpCircle}/>     
                    </Tooltip></h4>
                <MapsContainerBody>
                    <CountryChartContainer>
                        <MapChart isDataLoading={isMapDistributionLoading}/>
                    </CountryChartContainer>
                    <MapChartTable isDataLoading={isMapDistributionLoading}/>
                </MapsContainerBody>
            </MapsContainer>
    </AdDistributionWrap>
  )
}

export default AdDistribution