import { appClient } from '../../../../utils/axios-utils';
import {useMutation} from "@tanstack/react-query"
import { useKeywordOverview } from '../context/keywordOverview';

const getAdStats = async (data) => {
    const config = {
        url: "/aggs/keyword/overview/adstat",
        method: "POST",
        data
    }
  
    return appClient(config)
}

const topDomainFn = async (data) => {
    const config = {
        url: "/aggs/keyword/overview/top-domains",
        method: "POST",
        data
    }

    return appClient(config)
}

const HeatMapAnalysis = async (data) => {
    const config = {
        url: `/aggs/keyword/overview/heatmap`,
        method: "POST",
        data
    }

    return appClient(config)
}

const trackingAnalysis = async (data) => {
    // console.log(data)
    const config = {
        url: "/keyword/keyword-overview/analysis",
        method: "POST",
        data
    }
  
    return appClient(config)
  }
  const overviewAnalysis = async (data) => {
    // console.log(data)
    const config = {
        url: "/keyword/analysis",
        method: "POST",
        data
    }
  
    return appClient(config)
  }

export const useKeywordOverviewInfo = () => {
    const {keywordOverviewState, keywordOverviewDispatch} = useKeywordOverview()

    const { mutate: getAdStatsMutation, isLoading: isGetAdStatsLoading } = useMutation(getAdStats, {
        onSuccess: (data) => {
            keywordOverviewDispatch({type: 'SET_ADDISTRIBUTION_OVERVIEW', value: data})
        },
        onError: (err) => {
            
        }
    })

    const { mutate: getTopDomainsMutation, isLoading: isGetTopDomainsLoading } = useMutation(topDomainFn, {
        onSuccess: (data) => {
            keywordOverviewDispatch({type: 'SET_TOPDOMAIN_KEYWORD', value: data})
        },
        onError: (err) => {
            
        }
    })

    const { mutate: getHeatmapDataMutation, isLoading: isGetHeatmapDataLoading } = useMutation(HeatMapAnalysis, {
        onSuccess: (data) => {
        keywordOverviewDispatch({type: 'SET_HEATMAP_DATA', value: data})
        },
        onError: (err) => {
            
        }
    })

    const { mutate: trackingAnalysisApi, isLoading: isTrackingAnalysisLoading } = useMutation(trackingAnalysis, {
        onSuccess: (data) => {
            if(data.length > 0){
                overviewAnalysisApi({keywords: data})
            }
        },
        onError: (err) => {
            
        }
    })
    const { mutate: overviewAnalysisApi, isLoading: isOverviewAnalysisLoading } = useMutation(overviewAnalysis, {
        onSuccess: (data) => {
            keywordOverviewDispatch({ type: 'SET_RELATED_KEYWORD_INPUT', value: data})
            
        },
        onError: (err) => {
            
        }
    })
   
    return {
        getAdStatsMutation,
        isGetAdStatsLoading,
        getTopDomainsMutation,
        isGetTopDomainsLoading,
        getHeatmapDataMutation,
        isGetHeatmapDataLoading,
        trackingAnalysisApi,
        isTrackingAnalysisLoading,
        isOverviewAnalysisLoading
    };
};
