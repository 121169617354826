import React, { useRef, useState } from 'react'
import {
    useMutation
} from "@tanstack/react-query"
import NoResultsLogo from "../../../../../../../media/Dashboard/noresults.svg"
import { useRequestData } from "../../../../../../hooks/useRequestData"
import locationImg from '../../../../../../../media/Dashboard/locationCard.svg'
import bingImg from '../../../../../../../media/Dashboard/bingBlue.svg'

// Styles
import {
  
    CardFooter,
    AdUrl,
    CardBody,
    Card,
    CardsList,
    ResultsContainer,
    HightlightContent,
    AdHeader,
    WebsiteLogo,
    WebsiteNameContainer,
    WebsiteName,
    NoResults,
    RequestDataBtn,
    SaveAdsButton,
    HoverCard,
    AdDetailsButton,
    HoverCardWrapper,
    KeywordWrap,
    CountryWrap,
    Country,
    GoogleIcon
} from "./index.styled"
import _static from "../../../../../../../config/static"
import SearchLoader from '../../../../../../HOC/SearchLoader'
import Highlighter from "react-highlight-words"
import GlobeIcon from '../../../../../../../media/Dashboard/globe.svg';
import { useBingSearch } from "../../context/bing"

import TickImg from '../../../../../../../media/Dashboard/tick-grey.svg'
import { countryList } from "../../../../../UrlAnalysis/UrlAnalysisDetails/components/MapChartTable/maps";
import SaveAdsPopup from '../../../../../../components/SaveAdsPopup'

import RightUpperBlackArrow from "../../../../../../../media/Dashboard/RightUpperBlackArrow.svg"

import { appClient } from '../../../../../../../utils/axios-utils'
import LimitExceededPopup from '../../../../../../components/limitExceededPopup'
import PlatformRestrictedPopup from '../../../../../../components/platformRestrictionPopup'
import { useAuth } from '../../../../../../../context/auth'
import { useSearch } from '../../../context/search'
import AdDetails from '../../../../../../components/AdDetailsModal'
import { useSaveAds } from '../../../../../../hooks/useSaveAds'

const adDetails = async (id) => {
    const config = {
        url: `/detail/bing/${id}`,
        method: "GET"
    }

    return appClient(config)
}

const adClassification = async (id) => {
    const config = {
        url: `/detail/bing/classification/${id}`,
        method: "GET"
    }

    return appClient(config)
}

const Results = () => {
    const { bingSearchState, bingSearchDispatch } = useBingSearch()
    const [basicAdDetails, setBasicAdDetails] = useState({})
    const [showTick, setShowTick] = useState(false)
    const [copiedItem, setCopiedItem] = useState('')
    const useRequestDataHook = useRequestData()
    const useSaveAdsHook = useSaveAds()

    const { searchState, searchDispatch } = useSearch()
    const { authState, authDispatch } = useAuth()

    const containerRef = useRef(null);

    const getFlagEmoji = (countryName) => {
        let countryCode = countryList[countryName]
        if (countryCode) {
            const codePoints = countryCode
                .toUpperCase()
                .split('')
                .map(char => 127397 + char.charCodeAt());
            return String.fromCodePoint(...codePoints);
        }

    }

    const toggleDetailsModal = (ad, idx) => {
        bingSearchDispatch({ type: 'UPDATE_DETAILS_MODAL', value: true })
        setBasicAdDetails(ad)
        // adsDetailsMutate(ad.id)
        bingSearchDispatch({ type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: true })
    }

    const getDomainFromUrl = (url) => {
        var parsedUrl = new URL(url);
        var domain = parsedUrl.hostname;

        // Remove subdomains and top-level domain
        var domainParts = domain.split('.');
        if (domainParts.length > 2) {
            domain = domainParts[domainParts.length - 2];
        }

        return domain;
    }

    const convertTimestamp = (timestamp) => {
        // Create a new Date object based on the timestamp
        var date = new Date(timestamp);

        // Get the month name
        var monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        var month = monthNames[date.getMonth()];

        // Get the year
        var year = date.getFullYear();

        // Assemble the formatted date string
        var formattedDate = date.getDate() + ' ' + month + ' ' + year;

        return formattedDate;
    }

    const { mutate: adsDetailsMutate, isLoading: isDetailsLoading } = useMutation(adDetails, {
        onSuccess: (data) => {
            bingSearchDispatch({ type: 'SET_AD_DETAILS', value: data })
            // if(bingSearchState.adDetails.id == data.id || !bingSearchState.adDetails.id){
            //     bingSearchDispatch({type:'UPDATE_DETAILS_MODAL', value: !bingSearchState.detailsModal})
            // }
        },
        onError: ((e) => {
            bingSearchDispatch({ type: 'SET_AD_DETAILS', value: {} })
            // console.log(e)
        })
    })

    const { mutate: adAiClassificationMutate, isLoading: isAiClassificationLoading } = useMutation(adClassification, {
        onSuccess: (data) => {
            if (data.processed || data.id) {
                bingSearchDispatch({ type: 'SET_AD_AI_CLASSIFICATION', value: data })
                bingSearchDispatch({ type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: false })
            } else {
                setTimeout(() => {
                    adAiClassificationMutate(basicAdDetails.id)
                }, 5000);
            }
        },
        onError: ((e) => {
            bingSearchDispatch({ type: 'SET_AD_AI_CLASSIFICATION', value: {} })
            bingSearchDispatch({ type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: false })
        })
    })

    const onDataRequest = () => {
        if (!useRequestDataHook.isDataRequested) {
            useRequestDataHook.requestDataMutate({ id: bingSearchState.queryId })
        }
    }

    const onDetailsClose = () => {
        setBasicAdDetails({})
        bingSearchDispatch({ type: 'SET_AD_DETAILS', value: {} })
        bingSearchDispatch({ type: 'UPDATE_DETAILS_MODAL', value: false })
    }

    const scrollToElement = (index) => {
        const element = containerRef.current.children[index];
        const scrollPosition = element.offsetTop - 20;
        containerRef.current.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    };

    if (authState.isRestrictedPopup) {
        return <ResultsContainer restricted={true} detailsModal={bingSearchState.detailsModal}>
            <PlatformRestrictedPopup err={searchState.searchError} />
        </ResultsContainer>
    }

    if (searchState.limitExceeded) {
        return <ResultsContainer restricted={true} detailsModal={bingSearchState.detailsModal}>
            <LimitExceededPopup err={searchState.searchError} />
        </ResultsContainer>
    }

    const onCopyClick = (value) => {
        navigator.clipboard.writeText(value);
        setCopiedItem(value)
        setShowTick(true)
        setTimeout(() => {
            setShowTick(false)
        }, 1500);
    }

    function findIndexById(arr, searchId) {
        for (let i = 0; i < bingSearchState.ads.length; i++) {
            if (arr[i].id === searchId) {
                return i; // Return the index when the ID is found
            }
        }
        return -1; // Return -1 if ID is not found in the array
    }

    const navigateTo = (adId, toAd) => {
        console.log("navigateTo", adId, toAd)
        let adIndex = findIndexById(adId)
        console.log(adIndex)
        if (adIndex != -1) {
            if (toAd == "next") {
                console.log("inside next")
                bingSearchDispatch({ type: 'UPDATE_DETAILS_MODAL', value: true })
                setBasicAdDetails(bingSearchState.ads[adIndex + 1])
                bingSearchDispatch({ type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: true })
            } else if (toAd == "back") {
                console.log("inside back")
                bingSearchDispatch({ type: 'UPDATE_DETAILS_MODAL', value: true })
                setBasicAdDetails(bingSearchState.ads[adIndex - 1])
                bingSearchDispatch({ type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: true })
            }
        }
    }

    const onSave = (ad) => {
        useSaveAdsHook.SaveAdMutate({
            projectId: authState.defaultProjectInfo.projectId,
            adId: `${ad.id}`,
            platform: "bing"
        })
        // authDispatch({ type: 'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'bing' })
        // authDispatch({ type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id })
        // authDispatch({ type: 'UPDATE_SAVE_AD_POPUP', value: true })
    }

    const onRemoveSavedAd = (ad) => {
        useSaveAdsHook.RemoveSavedAdMutate({
            projectId: authState.defaultProjectInfo.projectId,
            adId:  `${ad.id}`,
            platform: "bing"
        })
    }

    // console.log(bingSearchState.basicAdDetails)
    return <ResultsContainer resultLoading={bingSearchState.loadingResults} noResults={bingSearchState.noResults} detailsModal={bingSearchState.detailsModal}>
        {bingSearchState.loadingResults ? <div className='loader'>
            <SearchLoader />
        </div> : bingSearchState.noResults ? <NoResults>
            <img src={NoResultsLogo} />
            <h2>No Results Found</h2>
            <p>We're sorry, but we don't currently have the data matching your search parameters. Our database is constantly updated, so please try again later or try modifying your search.</p>
            <RequestDataBtn
                // dataRequested={authState.isDataRequested}
                isDisabled={useRequestDataHook.isRequestDataLoading || authState.isDataRequested}
                onClick={onDataRequest}
            >
                {authState.isDataRequested ? <p>Your request for data crawl has been accepted<img src={TickImg} /></p> : <p>{useRequestDataHook.isRequestDataLoading ? "Requesting..." : "Request data crawl for this query"}</p>}
            </RequestDataBtn>
        </NoResults> : <>
            {authState.saveAdsPopupOpen && <SaveAdsPopup noMarginTop={true} />}
            <CardsList ref={containerRef} detailsModal={bingSearchState.detailsModal}> {
                bingSearchState.ads && bingSearchState.ads.length > 0 && bingSearchState.ads.map((ad, idx) => {
                    return <Card detailsOpen={basicAdDetails.id == ad.id} key={
                        ad.id
                    }>
                        <HoverCard className='hover-element' onClick={() => toggleDetailsModal(ad, idx)}>
                            <HoverCardWrapper>
                                <AdDetailsButton onClick={() => toggleDetailsModal(ad, idx)}>
                                    <img src={RightUpperBlackArrow} />
                                    <span>Ad Details</span>
                                </AdDetailsButton>
                                <SaveAdsButton isSaved={authState.savedAdsIds.includes(ad.id)} onClick={(e) => {
                                    e.stopPropagation();
                                    if (authState.savedAdsIds.includes(ad.id)) {
                                        onRemoveSavedAd(ad)
                                    } else {
                                        onSave(ad);
                                    }

                                }}>
                                    {authState.savedAdsIds.includes(ad.id) ? "Saved" : "Save"}
                                </SaveAdsButton>
                            </HoverCardWrapper>
                        </HoverCard>
                        {/* <FloatingButtonsContainer>
                                <Favorite isFav={idx == 1} onClick={()=>{
                                        authDispatch({type:'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'bing'})
                                        authDispatch({type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id})
                                        authDispatch({type:'UPDATE_SAVE_AD_POPUP', value: true})}}>
                                    <img src={authState.favAdsList["bing"]?.includes(ad.id) ? FavouriteIconActive : FavorietIcon}/>
                                </Favorite> */}
                        {/* |
                                <DropdownIcon>
                                    <span/>
                                </DropdownIcon> */}
                        {/* </FloatingButtonsContainer> */}
                        <CardBody>
                            {/* <SponsoredText>
                                    Sponsored
                                </SponsoredText> */}
                            <AdHeader>
                                <WebsiteLogo>
                                    <img src={GlobeIcon} />
                                </WebsiteLogo>
                                <WebsiteNameContainer>
                                    <WebsiteName>
                                        {ad.domainId}  
                                    </WebsiteName>
                                    <AdUrl>
                                        {ad.url ? ad.url : ad.url}
                                    </AdUrl>
                                </WebsiteNameContainer>
                            </AdHeader>
                            <KeywordWrap>{ad.title}</KeywordWrap>

                            {/* <Highlighter
                                    className="title-wrapper-span"
                                    highlightClassName="title-highlight-bold"
                                    searchWords={bingSearchState.highlightWords}
                                    autoEscape={true}
                                    textToHighlight={ad.title}
                                    highlightTag={HightlightTitle}
                                /> */}
                            <Highlighter
                                className="content-wrapper-span"
                                highlightClassName="content-highlight-bold"
                                searchWords={bingSearchState.highlightWords}
                                autoEscape={true}
                                textToHighlight={ad.content}
                                highlightTag={HightlightContent}
                            />
                        </CardBody>
                        <CardFooter>
                            <CountryWrap>
                                <img src={locationImg} />
                                <Country>{ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : "--"}</Country>
                            </CountryWrap>
                            <GoogleIcon>
                                <img src={bingImg} />
                            </GoogleIcon>
                            {/* <FooterLeft>
                                    <AdDate>
                                    {ad.firstSeen && ad.lastSeen && <img src={DateIcon}/>} */}
                            {/* {convertTimestamp(ad.firstSeen)} -  */}
                            {/* {convertTimestamp(ad.lastSeen)}
                                    </AdDate>
                                    <AdCountries>
                                                {ad.country.length > 0 && <img src={CountryIcon} />}{ ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
                                    </AdCountries>
                                </FooterLeft>
                                <FooterRight> */}
                            {/* <DetailsButton onClick={()=>toggleDetailsModal(ad,idx)}>
                                        {bingSearchState.adDetails.id == ad.id ? "Close details" : "More details"}
                                    </DetailsButton> */}
                            {/* </FooterRight> */}
                        </CardFooter>
                    </Card>
                })
            } </CardsList>
            {/* {
                    bingSearchState.detailsModal && <DetailsContainer>
                    <DetailsHeader>
                        <h3>Ad details</h3> <CloseDetails onClick={onDetailsClose}><img src={CloseBtn}/></CloseDetails>
                    </DetailsHeader>
                    <AdDetailsBody>
                        <DetailsHeadline>
                            <label>Heading:</label>{basicAdDetails.title}<CopyImg onClick={()=>onCopyClick(basicAdDetails.title)} src={showTick && basicAdDetails.title == copiedItem ? TickImg : CopyBtn}/>
                        </DetailsHeadline>
                        <DetailDescription>
                            <label>Description:</label>{basicAdDetails.content}<CopyImg onClick={()=>onCopyClick(basicAdDetails.content)} src={showTick && basicAdDetails.content == copiedItem ? TickImg : CopyBtn}/>
                        </DetailDescription>
                        <DestinationUrl>
                            <label>Destination URL:</label>{bingSearchState.adDetails.url}
                        </DestinationUrl>
                        <DateRange>
                            <label>Last seen</label>{convertTimestamp(basicAdDetails.lastSeen)} */}
            {/* <label>Date Range:</label>27 Jan 2021 - 21 Nov 2022 */}
            {/* </DateRange>
                        <AvgPosition>
                            <label>Average position:</label>{basicAdDetails.position}
                        </AvgPosition>
                        <FunnelStage>
                                <label>Funnel Stage:</label>{bingSearchState.adAiClassificationLoading ? <Skeleton style={{
                                    lineHeight: "32px",
                                    width: 150,
                                }}/> : bingSearchState.adAiClassification.funnelType ? <p>{bingSearchState.adAiClassification.funnelType}</p>: "-"}
                            </FunnelStage>
                            <CtaList>
                                <label>CTAs:</label>
                                <CtaContainer>
                                    {
                                        bingSearchState.adAiClassificationLoading ?  
                                        <Skeleton style={{
                                            lineHeight: "32px", 
                                            width: 100,
                                        }}/>  :
                                        bingSearchState.adAiClassification.ctaText ? <Cta>
                                            {bingSearchState.adAiClassification.ctaText}
                                        </Cta> : "-"
                                    }
                                </CtaContainer>
                            </CtaList>
                            <TargetAudience>
                                <label>Target Audience</label>
                                <AudienceContainer>
                                    <DemographicAudience>
                                        <h3>Demographic</h3>
                                        <DemographicContainer>
                                        {
                                            bingSearchState.adAiClassificationLoading ?  [...Array(1)].map((d)=>{
                                            return <Skeleton style={{
                                                lineHeight: "32px", 
                                                width: 100,
                                            }}/> 
                                            }) : bingSearchState.adAiClassification?.demographicAudience?.length > 0 ? 
                                            bingSearchState.adAiClassification.demographicAudience.map(function(key,index) {
                                                return <Cta>
                                                    {key}
                                                </Cta>
                                            }) : "-"
                                        }
                                        </DemographicContainer>
                                    </DemographicAudience>
                                    <PsychographicAudience>
                                        <h3>Psychographic</h3>
                                        <PsychographicContainer>
                                        {
                                            bingSearchState.adAiClassificationLoading ?  [...Array(1)].map((d)=>{
                                            return <Skeleton style={{
                                                lineHeight: "32px", 
                                                width: 100,
                                            }}/> 
                                            }) :  bingSearchState.adAiClassification?.psychographicAudience?.length > 0 ? 
                                            bingSearchState.adAiClassification.psychographicAudience.map(function(key,index) {
                                                return <Cta>
                                                    {key}
                                                </Cta>
                                            }) : "-"
                                        }
                                        </PsychographicContainer>
                                    </PsychographicAudience>
                                </AudienceContainer>
                            </TargetAudience>
                        <CountriesContainer> */}
            {/* <label>Country:</label><CountriesList>{sampleCountryList.map((country,idx)=>{
                                return <span>{country}</span>
                            })}</CountriesList> */}
            {/* <label>Country:</label>{basicAdDetails.country}
                        </CountriesContainer>
                        <SearchTermsContainer>
                            <label>Bing search terms:</label><SearchTermsList>
                            {isDetailsLoading ? <Skeleton/> : bingSearchState.adDetails.keywords && bingSearchState.adDetails.keywords.map((searchTerm,idx)=>{
                                return <span>{searchTerm}</span>
                            })}
                            </SearchTermsList>
                        </SearchTermsContainer>
                        {basicAdDetails.impressionByCountry && <CountyStatsTable>
                            <label>Countries and Impressions</label> */}
            {/* <HeadNames>
                                <p>
                                    Countries
                                </p>
                                <p>
                                    Ads share
                                </p>
                            </HeadNames> */}
            {/* <AllHeadCount>
                                <p>
                                    Countries
                                </p>
                                <Progress value="100" max="100"/>
                                <p className="percentage">
                                    Ads share
                                </p>
                            </AllHeadCount>
                            { basicAdDetails.impressionByCountry.length > 0 && basicAdDetails.impressionByCountry.map((country, idx)=>{
                                return <CountryStatsData>
                                    <p>
                                        <span>{country.Country && getFlagEmoji(country.Country)}</span>{country.Country}
                                    </p>
                                    <Progress value={country.ImpressionShare.replace("%","")} max="100"/>
                                    <p className="percentage">
                                        {country.ImpressionShare}
                                    </p>
                                </CountryStatsData>
                            })}
                        </CountyStatsTable>} */}
            {/* <LandingPageScreenShot>
                            <label>Landing page screenshot</label>
                            <ScreenshotContainer>
                                <img src={ApplePage}/>
                            </ScreenshotContainer>
                        </LandingPageScreenShot> */}
            {/* </AdDetailsBody>
                </DetailsContainer>
                } */}
            {
                bingSearchState.detailsModal &&
                <AdDetails
                    navigateTo={navigateTo}
                    platform="bing"
                    basicDetail={basicAdDetails}
                    onDetailsClose={onDetailsClose}
                    highlightWords={bingSearchState.highlightWords}
                    onSave={onSave}
                />
            }
        </>}
    </ResultsContainer>
}

export default Results