import React from "react";
import { Route, Routes } from "react-router-dom";

import {Helmet} from "react-helmet"

import {
  KeywordFunnelAnalysisProvider,
} from "./context/keywordFunnelAnalysis";

// Layout
import Layout from "../../layout";

// Pages

import KeywordFunnelAnalysisResult from "./components/result/index";
import KeywordFunnelAnalysisHome from "./components/home";
// import { getSearchErrCode } from "../../../helper/getSearchErrCode"

// Not found page
import NotFound from "../NotFoundPage";

// import {appClient} from '../../../../utils/axios-utils'

const KeywordFunnelAnalysisComponent = () => {
  // const {searchState, searchDispatch} = useSearch()

  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route
        path="/"
        element={
          <Layout>
            <KeywordFunnelAnalysisHome />
          </Layout>
        }
      />
      <Route
        path="/details"
        element={
          <Layout>
            <KeywordFunnelAnalysisResult />
          </Layout>
        }
      />
    </Routes>
  );
};

const KeywordFunnelAnalysisProviderWrapper = () => {
  return (
    <KeywordFunnelAnalysisProvider>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Keyword Funnel Analysis | AdSpyder</title>
          <meta name="title" content="Keyword Funnel Analysis | AdSpyder" />
          <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
      </Helmet>
      <KeywordFunnelAnalysisComponent />
    </KeywordFunnelAnalysisProvider>
  );
};

export default KeywordFunnelAnalysisProviderWrapper;
