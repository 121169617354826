import statics from "../components/ClientLibrary/statics"

const { default: produce } = require("immer")
const { useReducer, createContext, useContext } = require("react")

const initialState = {
    isOnboardingPopupActive: false,
    isClientProfileUpdated: false,
    isClientLibraryPopupActive: false,
    clientLibrarySteps: statics.walkthroughSteps,
    currentClientLibraryStep: statics.walkthroughSteps[0],
    clientProfile: null,
    projectDetails: null,
    brandAssets: null,
    isAddCompetitorPopupActive: false,
    bannerImageAds: null,
    bannerTextAds: null,
    topTextAds: [],
    topImageAds: [],
    relatedKeywords: [],
    savedAdsData: {
        result: [], totalSavedAdsCount: 0
    },
    competitorsCampaignCount: [],
    projects: null
}

const overviewReducer = produce((draft, action) => {
    switch (action.type) {

        case "SET_ONBOARDING_POPUP_ACTIVE":
            draft.isOnboardingPopupActive = action.value
            break

        case "SET_CLIENT_PROFILE_UPDATED":
            draft.isClientProfileUpdated = action.value
            break

        case "SET_CLIENT_LIBRARY_POPUP_ACTIVE":
            draft.isClientLibraryPopupActive = action.value
            break

        case "UPDATE_CLIENT_LIBRARY_STEPS":
            draft.clientLibrarySteps[action.value.index].isCompleted = action.value.value
            break

        case "RESET_CLIENT_LIBRARY_STEPS":
            draft.clientLibrarySteps.forEach(step => {
                step.isCompleted = false
            });
            draft.currentClientLibraryStep = statics.walkthroughSteps[0]
            break

        case "SET_CURRENT_CLIENT_LIBRARY_STEP":
            draft.currentClientLibraryStep = action.value
            break

        case "SET_CLIENT_PROFILE":
            draft.clientProfile = { ...draft.clientProfile, ...action.value }
            break

        case "SET_PROJECT_DETAILS":
            draft.projectDetails = { ...draft.projectDetails, ...action.value }
            break

        case "SET_BRAND_ASSETS":
            draft.brandAssets = { ...draft.brandAssets, ...action.value }
            break

        case "SET_COMPETITOR_POPUP_ACTIVE":
            draft.isAddCompetitorPopupActive = action.value
            break

        case "SET_BANNER_IMAGE_ADS":
            draft.bannerImageAds = action.value
            break

        case "SET_BANNER_TEXT_ADS":
            draft.bannerTextAds = action.value
            break

        case "SET_TOP_TEXT_ADS":
            draft.topTextAds = action.value
            break

        case "SET_TOP_IMAGE_ADS":
            draft.topImageAds = action.value
            break

        case "SET_RELATED_KEYWORDS":
            draft.relatedKeywords = action.value
            break

        case "SET_SAVED_ADS_DATA":
            draft.savedAdsData = action.value
            break

        case "SET_COMPETITORS_CAMPAIGN_COUNT":
            draft.competitorsCampaignCount = action.value
            break

        case "SET_PROJECTS":
            draft.projects = action.value    
        break

        default:
            break
    }
})

const useOverviewReducer = () => useReducer(overviewReducer, initialState)

const OverviewContext = createContext()

export const OverviewProvider = ({ children }) => {
    const [state, dispatch] = useOverviewReducer()

    const value = {
        overviewState: state,
        overviewDispatch: dispatch
    }

    return (
        <OverviewContext.Provider value={value} >
            {children}
        </OverviewContext.Provider>
    )
}

export const useOverview = () => useContext(OverviewContext)