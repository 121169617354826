import React from "react";
import {
  AccPlatform,
  AdAccount,
  AdContainer,
  AdImg,
  AdWrap,
  Available,
  ConnectBtn,
  ManageContainer,
  ManageOverlay,
  PopupContainer,
  PopupHeader,
  PopupHeaderWrap,
} from "./index.styled";
import adResultData from "../../../../static";
import linkImg from "../../../../../../../../media/Dashboard/linkWhite.svg";
import { useAdIntegration } from "../../../../context/AdManagementContext";
import _static from "../../../../../../../../config/static";
import closeImg from "../../../../../../../../media/Dashboard/closeIcon.svg";

const ManagePopup = () => {
  const { platformArray } = adResultData;
  const { baseUrl } = _static;
  const {
    newAdManagementState: { selectAdAccount },
    newAdManagementDispatch,
  } = useAdIntegration();

  const selectPlatfornFn = () => {
    const windowWidth = 600;
    const windowHeight = 500;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    const left = (screenWidth - windowWidth) / 2;
    const top = (screenHeight - windowHeight) / 2;

    const oauthWindow = window.open(
      `${baseUrl}/admanagement/login`,
      "_blank",
      `width=${windowWidth},height=${windowHeight},left=${left},top=${top}`
    );

    const messageListener = function (event) {
      if (event.data === "true") {
        newAdManagementDispatch({ type: "SET_SELECT_AD_ACCOUNT", value: true });
        newAdManagementDispatch({ type: "SET_MANAGE_ACCOUNT", value: false });
        // adManagementDispatch({type: 'SET_SELECT_A_PLATFORM', value: false})

        window.removeEventListener("message", messageListener);
      }
    };

    window.addEventListener("message", messageListener);
  };
  return (
    <ManageOverlay>
      <ManageContainer>
        <PopupHeaderWrap>
          <PopupHeader>Manage your ad accounts</PopupHeader>
          <img
            src={closeImg}
            onClick={() =>
              newAdManagementDispatch({
                type: "SET_MANAGE_ACCOUNT",
                value: false,
              })
            }
          />
        </PopupHeaderWrap>
        <PopupContainer>
          {platformArray.map((acc) => (
            <AdWrap isActive={acc.connected}>
              <AdContainer>
                <AdImg>
                  <img src={acc.platform} />
                </AdImg>
                <AdAccount>
                  <AccPlatform>{acc.title}</AccPlatform>
                  <p>{acc.description}</p>
                </AdAccount>
              </AdContainer>
              {acc.connected ? (
                <ConnectBtn onClick={selectPlatfornFn}>
                  <img src={linkImg} />
                  Connect
                </ConnectBtn>
              ) : (
                <Available>Available soon</Available>
              )}
            </AdWrap>
          ))}
        </PopupContainer>
      </ManageContainer>
    </ManageOverlay>
  );
};

export default ManagePopup;
