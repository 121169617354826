import styled from "styled-components";

export const TableWrapper = styled.table`
    width: 212px;
    /* height: 168px;  */
    border-collapse: collapse;
    font-size: 14px;
`;

export const AllHeadCount = styled.div`
    display: flex;
    justify-content: space-between;
    background: #F6F6F6;
    padding: 6px 20px;
    p{
        width: 70%;
        font-size: 14px;
        color: #555555;
        font-weight: 300;
    }
    .percentage{
        width: 30%;
        font-weight: 400;
        text-align: end;
    } 
    /* .totalcount{
        width: 25%;
        text-align: end;
    } */
`;

export const CountryStatsData = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 8px 20px;
    p{
        display: flex;
        width: 70%;
        font-size: 14px;
        color: #555555;
        font-weight: 300;
    }
    .percentage{
        font-weight: 400;
        width: 30%;
        display: flex;
        justify-content: flex-end;
    } 
    /* .totalcount{
        width: 50px;
        width: 17%;
        display: flex;
        justify-content: flex-end;
    } */
`;

export const PlatformIndicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  padding-right: 8px;
  margin: 5px 5px 0px 0px;
`;
