import styled from "styled-components";

export const AdConnectedWrap = styled.div`
margin-top: 34px;
padding: 0px 46px 0px 34px;
display: flex;
flex-direction: column;
-webkit-box-align: center;
align-items: center;
`
export const AdAccountWrap = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
max-width: 100%;
width: 1080px;

`
export const AdAccountContainer = styled.div`
display: flex;
column-gap: 12px;
align-items: center;
`
export const AdAccount = styled.div`
color: rgba(28, 28, 28, 0.50);
font-family: Inter;
font-size: 14px;
font-weight: 400;
`
export const AdAccountBtnContainer = styled.div`
display: flex;
column-gap: 16px;
align-items: center;
`
export const AdAccountBtn = styled.div`
color: #FFF;
font-family: Inter;
font-size: 14px;
font-weight: 400;
display: inline-flex;
padding: 6px 12px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 4px;
background: #FF711E;
cursor: pointer;
`
export const CreateCampaignBtn = styled.div`
color: #FFF;
font-family: Inter;
font-size: 14px;
font-weight: 400;
border-radius: 4px;
background: #FF711E;
display: inline-flex;
padding: 6px 12px;
justify-content: center;
align-items: center;
gap: 10px;
`