import React, { useEffect, useState } from "react";

import { useAccount } from "../../context/account";
import { ContentHeading, ContentBody, NameContainer, EmailContainer, ChangePasswordBtn, ChangePasswordForm, InputContainer, PasswordInputContainer, PasswordContainer, PasswordInput, Error, SubmitContainer, UpdatePassword } from "./index.styled"
import { useForm } from "react-hook-form";

import { Helmet } from "react-helmet";
import { useAuth } from "../../../../../context/auth";
import { useGetAccount } from "../../../../hooks/useGetAccount";

const Profile = () => {
    const { authState, authDispatch } = useAuth()
    const { accountState, accountDispatch } = useAccount()
    const accountInfoHook = useGetAccount();

    const [enableChangePassword, setEnableChangePassword] = useState(false)
    const [passwordEntered, setPasswordEntered] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [newPasswordType, setNewPasswordType] = useState("password");

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const handlePasswordInput = (e) => {
        if (e.target.className.includes("password-input")) {
            setPasswordEntered(e.target.value);
        }
        if (e.target.className.includes("new-password-input")) {
            setNewPassword(e.target.value);
        }
    };

    useEffect(() => {
        accountDispatch({ type: "SET_CURRENT_PAGE", value: "profile" })
    }, [])

    return <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Profile | AdSpyder</title>
            <meta name="title" content="Profile | AdSpyder" />
            <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
        </Helmet>
        <ContentHeading>
            My Profile
        </ContentHeading>

        <ContentBody>
            <NameContainer>
                <label>Full name<span>:</span></label>
                <p>{authState.username}</p>
            </NameContainer>
            <EmailContainer>
                <label>Email<span>:</span></label>
                <p>{authState.email}</p>
            </EmailContainer>
            {!accountState.enableChangePassword ? <ChangePasswordBtn onClick={() => accountDispatch({ type: "SET_ENABLE_CHANGE_PASSWORD", value: true })}>
                Change password?
            </ChangePasswordBtn>
                :
                <ChangePasswordForm
                    onChange={(e) => {
                        handlePasswordInput(e);
                    }}
                    onSubmit={handleSubmit(accountInfoHook.onSubmit)}>

                    <InputContainer>
                        <PasswordInputContainer>
                            <label>Current password<span>:</span></label>
                            <PasswordContainer>
                                <PasswordInput
                                    className="password-input"
                                    onChange={(e) => setPasswordEntered(e.target.value)}
                                    error={errors.password}
                                    // placeholder="Enter a password*"
                                    type={passwordType}
                                    {...register("password", {
                                        required: "Enter your password",
                                        minLength: {
                                            value: 8,
                                            message: "Password must have at least 8 characters",
                                        },
                                    })}
                                />{" "}
                                {passwordEntered.length > 0 && (
                                    <i
                                        className={
                                            passwordType == "password"
                                                ? "fa-solid fa-eye"
                                                : "fa-solid fa-eye-slash"
                                        }
                                        id="eye"
                                        onClick={() => {
                                            setPasswordType(
                                                passwordType == "password" ? "text" : "password"
                                            );
                                        }}
                                    ></i>
                                )}{" "}
                                {errors.password && <Error>{errors.password.message}</Error>}
                            </PasswordContainer>

                        </PasswordInputContainer>
                    </InputContainer>
                    <InputContainer>
                        <PasswordInputContainer>
                            <label>New Password<span>:</span></label>
                            <PasswordContainer>
                                <PasswordInput
                                    className="new-password-input"
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    error={errors.password}
                                    // placeholder="Enter a password*"
                                    type={newPasswordType}
                                    {...register("newPassword", {
                                        required: "Enter new password",
                                        minLength: {
                                            value: 8,
                                            message: "Password must have at least 8 characters",
                                        },
                                    })}
                                />{" "}
                                {newPassword.length > 0 && (
                                    <i
                                        className={
                                            newPasswordType == "password"
                                                ? "fa-solid fa-eye"
                                                : "fa-solid fa-eye-slash"
                                        }
                                        id="eye"
                                        onClick={() => {
                                            setNewPasswordType(
                                                newPasswordType == "password" ? "text" : "password"
                                            );
                                        }}
                                    ></i>
                                )}{" "}
                                {errors.password && <Error>{errors.password.message}</Error>}
                            </PasswordContainer>

                        </PasswordInputContainer>
                    </InputContainer>

                    <SubmitContainer>
                        <UpdatePassword>Change password</UpdatePassword>
                    </SubmitContainer>
                </ChangePasswordForm>
            }
        </ContentBody>
    </>
}

export default Profile