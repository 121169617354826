import React, { useRef } from "react";
import CloseIcon from "../../../../../../../media/Dashboard/detailsPaneClose.svg"
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import PlatformDropDown from "./components/PlatformDropDown"
import CountryDropDown from "./components/CountryDropDown";
import {appClient} from '../../../../../../../utils/axios-utils'
import {
    useMutation
} from "@tanstack/react-query"
import { useKeywordFunnelAnalysis } from "../../../../context/keywordFunnelAnalysis";

import Statics from "../../../../_static"

const setUpTrackingData = async (data) => {
    // console.log(data)
    const config = {
        url: "/track/keyword/add",
        method: "POST",
        data
    }

    return appClient(config)
}
const tableData = async (data) => {
    // console.log(data)
    const config = {
        url: "/track/keyword/all",
        method: "GET",
    }

    return appClient(config)
}

const PopupContainer = styled.div`
    position: fixed;
    z-index: 10;

    background: #00000045;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PopupBody = styled.div`
    max-width: 750px;
    width: 80%;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px #0000003D;
    border-radius: 12px;
    display: flex;
    min-height: 265px;
    flex-direction: column;
    align-items: center;
    /* padding: 32px 46px; */
    position: relative;
    justify-content: space-between;

    h2{
        margin-bottom: 16px;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        color: #1C1C1C;
        margin-top: 32px;       
    }
    p{
        font-size: 14px;
        font-weight: 300;
        color: #4E4E4E;
        text-align: center;
        line-height: 21px;
    }
`;

const LogoutButton = styled.button`
    border: none;
    outline: none;
    background: none;
    position: absolute;
    right: 25px;
    top: 20px;
    color: #2E2E2E;
    font-size: 14px;
    font-weight: 300;
`;

const KeywordInputContainer = styled.div`
    display: flex;
    gap: 15px;
    width: 100%;
    height: 48px;
    input{
        width: 56%;
        max-width: 365px;
        border-radius: 6px;
        height: 100%;
        border: 1px solid #DADFE4;
        outline: none;
        padding: 12px 20px;
    }
`;

const Footer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
    padding: 32px 46px;
`;

const AddMoreKeywordButton = styled.div`
    color: ${props => props.isDisabled ? "#DADFE4" : "#FF711E"};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
    pointer-events: ${props => props.isDisabled ? "none" : "all"};

`;

const SetupTrackingButton = styled.div`
    border-radius: 6px;
    background: #FF711E;
    color: #FFF;
    height: 40px;
    padding: 10px 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const KeywordsListContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    padding: 32px 46px;
    min-height: 120px;
    /* max-height: 250px; */
    overflow-y: auto;

    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

const Error = styled.p`   
`;

const KeywordInputModal = () => {

    const { keywordFunnelAnalysisState, keywordFunnelAnalysisDispatch } = useKeywordFunnelAnalysis()
    const { tempTableList } = Statics
    const navigate = useNavigate()
    const keywordsDiv = useRef(null);
    const updateKeywordCountry = (keyword,inputCountryData) => {
        const tempKeywordList = keywordFunnelAnalysisState.keywordInputList.map((data,index) => {
            // console.log(index,'check1',keyword)
            if (keyword == index) {
              return { ...data, country: inputCountryData }
            }
            return data;
          });

        //   console.log("updateKeywordCountry",tempKeywordList);
          keywordFunnelAnalysisDispatch({ type: 'SET_KEYWORD_INPUT_LIST', value: tempKeywordList})
    }

    const updateKeywordPlatform = (keyword,inputPlatformData) => {
        // console.log('inputCountryData')

        const tempKeywordList = keywordFunnelAnalysisState.keywordInputList.map((data,index) => {
            if (keyword == index) {
              // Update the region property for the specific country
              return { ...data, platform: inputPlatformData }
            }
            return data;
          });

        //   console.log("updateKeywordPlatform",tempKeywordList)

          keywordFunnelAnalysisDispatch({ type: 'SET_KEYWORD_INPUT_LIST', value: tempKeywordList}) 
    }

    const { mutate: tableApi, isLoading: isTableDataLoading } = useMutation(tableData, {
        onSuccess: (data) => {
            if(data.length > 0){
                keywordFunnelAnalysisDispatch({type: 'SET_KEYWORD_TABLE_OPEN', value: true})
                keywordFunnelAnalysisDispatch({type: 'SET_KEYWORD_TABLE_LIST', value: data})
            }
            keywordFunnelAnalysisDispatch({type: 'SET_KEYWORD_INPUT_MODAL_OPEN', value: false})
        },
        onError: (err) => {
            
        }
    })

    //  const tableApi = () => {
    //     keywordFunnelAnalysisDispatch({type: 'SET_KEYWORD_TABLE_OPEN', value: true})
    //     keywordFunnelAnalysisDispatch({type: 'SET_KEYWORD_TABLE_LIST', value: tempTableList})
    //     // keywordFunnelAnalysisDispatch({type: 'SET_KEYWORD_TABLE_OPEN', value: false})
    //     // keywordFunnelAnalysisDispatch({type: 'SET_KEYWORD_TABLE_LIST', value: []})
    // }

    const { mutate: setUpTrackingApi, isLoading: isSetUpTrackingDataLoading } = useMutation(setUpTrackingData, {
        onSuccess: (data) => {
            // console.log(data)
            tableApi()
            // keywordFunnelAnalysisDispatch({ type: "SET_AD_COPIES", value: data.documents })
        },
        onError: (err) => {
            
        }
    })

    // const setUpTrackingApi = () => {
    //     tableApi()
    // }


    const addNewKeyword = () => {
        if(keywordFunnelAnalysisState.keywordInputList.length < 4){
            keywordFunnelAnalysisDispatch({type: 'SET_KEYWORD_INPUT_LIST_ERROR', value: ''})
            keywordFunnelAnalysisDispatch({type: 'SET_KEYWORD_INPUT_LIST', value: [...keywordFunnelAnalysisState.keywordInputList, { keyword: '', platform: '', country: {}, platformError: '' }]})
        }else{
            keywordFunnelAnalysisDispatch({type: 'SET_KEYWORD_INPUT_LIST_ERROR', value: 'maximum 4 keywords at a time'})
        }
    }

    const onKeywordInput = (e,index) => {
        // const tempKeywordInputList = keywordFunnelAnalysisState.keywordInputList
        const tempKeywordInputList = keywordFunnelAnalysisState.keywordInputList.map((d,id) => {
            if (id === index) {
              // Update the region property for the specific country
              return { ...d, keyword: e.target.value };
            }
            return d;
          });
        // console.log(tempKeywordInputList[index])
        // tempKeywordInputList[index].keyword = e.target.value;
        // console.log(tempKeywordInputList)
        keywordFunnelAnalysisDispatch({type: 'SET_KEYWORD_INPUT_LIST', value: tempKeywordInputList})
    }

    const setUpTrackingfn = ()=>{
        if (keywordFunnelAnalysisState.keywordInputList) {
            const setUpObj = {};
            setUpObj.keywords=[];
            // console.log(setUpObj,'setUpObj')
            keywordFunnelAnalysisState.keywordInputList.map((item) => {
                if (item.keyword !== '' ) {
                    console.log('country', item.country)

                    if( Object.keys(item.country).length !== 0  && item.platform !== ''){
                    // console.log(item, 'itemitem');
                    setUpObj.keywords.push({ keyword: item.keyword,
                        platform: item.platform,
                        country: item.country.code ? item.country.name.common : "global"})
                    keywordFunnelAnalysisDispatch({ type: 'SET_KEYWORD_INPUT_LIST_ERROR', value: '' });
                    }else{
                    // console.log('section checking');
                        keywordFunnelAnalysisDispatch({ type: 'SET_KEYWORD_INPUT_LIST_ERROR', value: 'Please Fill the Empty Section' });
                    }
                    
                } 
                else {
                    // console.log('keyword checking');
                    // keywordFunnelAnalysisDispatch({ type: 'SET_KEYWORD_INPUT_LIST_ERROR', value: 'Please Enter the Keyword' });
                }              
            });
            setUpObj.keywords.length > 0 && setUpTrackingApi(setUpObj)

        }
        
    }
    return <PopupContainer>
        <PopupBody>
        {/* temp change  */}
            <LogoutButton onClick={()=>keywordFunnelAnalysisDispatch({type: 'SET_KEYWORD_INPUT_MODAL_OPEN', value: false})}>
                <img src={CloseIcon}/>
            </LogoutButton>
            <h2>Setup Keyword Tracking</h2>
            <KeywordsListContainer ref={keywordsDiv}>
                {
                    keywordFunnelAnalysisState.keywordInputList.map((keyword, idx)=> {
                        return <KeywordInputContainer>
                            <input
                                placeholder="Add a keyword"
                                defaultValue={keyword.keyword}
                                onChange={(e)=>onKeywordInput(e, idx)}
                            />
                            <PlatformDropDown keywordData={keyword} updateKeywordPlatform={updateKeywordPlatform} countryIndex={idx}/>
                            <CountryDropDown keywordData={keyword} updateKeywordCountry={updateKeywordCountry} countryIndex={idx}/>
                        </KeywordInputContainer>
                    })
                }
            </KeywordsListContainer>
            <Error>{keywordFunnelAnalysisState.keywordInputListError ? keywordFunnelAnalysisState.keywordInputListError : ""}</Error>
            <Footer>
                <AddMoreKeywordButton isDisabled={keywordFunnelAnalysisState.keywordInputListError} onClick={addNewKeyword}>
                    + Add more keyword
                </AddMoreKeywordButton>
                <SetupTrackingButton onClick={setUpTrackingfn}>
                    Setup Tracking
                </SetupTrackingButton>
            </Footer>
        </PopupBody>
    </PopupContainer>
}

export default KeywordInputModal