import styled from "styled-components";

export const ResetPasswordHeader = styled.div`
    margin: 0 auto;
    max-width: 452px;
    margin-bottom: 35px;
    width: 100%;
    p{
        color: #2E2E2E;
        font-size: 20px;
    }
`;

export const FormContainer = styled.form`
    width: 100%;
    margin: 0 auto;
    max-width: 452px;
`;


export const CtaContainer = styled.div`

`;

export const PasswordInputContainer = styled.div`
    margin-bottom: 20px;
    label{
            color: ${props => props.theme.colors.Grey};
            font-size: 16px;
            margin-bottom: 8px;
        }
`;


export const PasswordContainer = styled.div`
    position: relative;
    #eye{
        position: absolute;
        right: 11px;
        top: 20px;
        cursor: pointer;
        color: #a9a9a9;
    }
`;

export const PasswordInput = styled.input`
    background: #FFFFFF;
    border: ${props => props.error ? "1px solid #FF5180" : "1px solid #ECECEC"};
    outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};    border-radius: 8px;
    width: 100%;
    height: 40px;
    margin-top: 8px;
    outline: none;
    padding: 9px 12px;
    color: ${props => props.theme.colors.lightBlack};
    font-weight: 300;
    &:focus{
        border: ${props => props.error ? "1px solid #FF5180" : "1px solid #5AB3FF"};
        outline: ${props => props.error ? "2px solid #FFB7CB" : "2px solid #B9DDFF"};
    }


`;

export const ChangePasswordButton = styled.button`
    background: #FF711E;
    border-radius: 8px;
    width: 100%;
    height: 40px;
    outline: none;
    border: none;
    color: ${props => props.theme.colors.white};
    font-size: 16px;

    &:hover{
        background: #E75500;
    }

    &:active{
        background: #C74A00;
    }
`;

export const FooterContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    p{
        color: ${props => props.theme.colors.lightBlack};
        font-size: 14px;
        font-weight: 300;
    }

    a{
        color: ${props => props.theme.colors.linkBlue};
        margin-left: 4px;
        text-decoration: none;
    }

    &:hover{
        a{
            text-decoration: underline;
        }
    }
`;


export const EmailSelected = styled.p`
    margin-top: 12px;
    line-height: 21px;
    p{
        color: ${props => props.theme.colors.lightBlack};
        font-size: 14px;
        font-weight: 300;
    }

    a{
        margin-left: 8px;
        color: ${props => props.theme.colors.linkBlue};
    }
`;