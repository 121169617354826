import React, { useEffect } from "react";
import {
  AdUrl,
  ContentDiv,
  ContentWrap,
  LoadingWrap,
  OopsDiv,
  TextTitleWrap,
} from "./index.styled";
import Statics from "../../../../_static";
import metaLogo from "../../../../assets/meta-logo.svg";
import bingLogo from "../../../../assets/bingLogo.svg";
import googleLogo from "../../../../assets/googleLogo.svg";
import linkedInLogo from "../../../../assets/linkedInLogo.svg";
import youtubeLogo from "../../../../assets/youtubeLogo.svg";
// import { useDashboard } from "../../../../../../../context/dashboard";
import { useDashboardInfo } from "../../../../../../../hooks/useDashboardInfo";
import Skeleton from "react-loading-skeleton";
import { useDashboard } from "../../../../../../../../../../context/dashboard";

const ContentList = () => {
  const { textAdData } = Statics;
  const logoList = {
    facebook: metaLogo,
    bing: bingLogo,
    google: googleLogo,
    linkedIn: linkedInLogo,
    youtube: youtubeLogo,
  };
  const loadingMap = ["", ""];
  const useDashboardInfoHook = useDashboardInfo();
  const {
    dashboardState: { selectedProject, textAdsData },
    dashboardDispatch,
  } = useDashboard();

  useEffect(() => {
    useDashboardInfoHook.getTextAdsMutate({ projectId: selectedProject.id });
  }, []);

  return useDashboardInfoHook.isTextAdsLoading ? (
    <ContentWrap>
      {loadingMap.map(() => (
        <LoadingWrap>
          <Skeleton
            style={{
              lineHeight: "20px",
              width: "85%",
            }}
          />
          <Skeleton
            style={{
              lineHeight: "17px",
              width: "45%",
              marginTop: "2px",
            }}
          />
          <Skeleton
            style={{
              lineHeight: "36px",
              width: "70%",
              marginTop: "8px",
            }}
          />
        </LoadingWrap>
      ))}
    </ContentWrap>
  ) : textAdsData?.topTextAds.length > 0 ? (
    <ContentWrap>
      {textAdsData?.topTextAds.map((item) => {
        return (
          <ContentDiv>
            <TextTitleWrap>
              <h2>{item.title}</h2>
              {Object.entries(logoList).map(([key, value]) => {
                return (
                  item.platform == key && (
                    <span>
                      <img src={value} />
                    </span>
                  )
                );
              })}
            </TextTitleWrap>
            <AdUrl>
              <span>Ad</span>
              {item.url}
            </AdUrl>
            <p>{item.content}</p>
          </ContentDiv>
        );
      })}
    </ContentWrap>
  ) : (
    <OopsDiv>
      <p>Oops! Ads can't be found.</p>
    </OopsDiv>
  );
};

export default ContentList;
