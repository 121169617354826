import styled from "styled-components";

export const TargetAudienceContainer = styled.div`
width: 738px;
height: 373px;
flex-shrink: 0;
border-radius: 10px;
background: #FFF;
padding: 24px 32px;
`;

export const Item = styled.div`
    margin-bottom: 35px;
`;

export const Label = styled.div`
color: #1E1E1E;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 133.333% */
letter-spacing: 0.15px;
margin-bottom: 16px;
`;

export const ChoiceList = styled.div`
    display: flex;
    gap: 12px;
`;

export const Choice = styled.div`
padding: 10px 24px;
color:${(props) => props.isSelected ? "#FFF" : "#000"};
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
height: 37px;
border-radius: 40px;
background: ${(props) => props.isSelected ? "#1679DB" : "#F2F2F2"};
cursor: pointer;
`;
export const Header = styled.div`
font-family: Inter;
font-size: 20px;
font-weight: 600;
line-height: 38px;
margin-bottom: 24px;
`