import React from "react";
import { useAuth } from "../../../../../context/auth";
import { useSubscription } from "../../../../hooks/useSubscription"
import { Card, CardHeader, HeaderRight, PlanName, Price, BasicDetail, CardBody, Detail, PlanPaymentButton } from "./index.styled"

const PlanCard = ({
  plan,
  setSelectedPlan,
  selectedPlan,
  setSkipClicked,
  onProceed,
}) => {
  const { authState, authDispatch } = useAuth();
  const subscriptionHook = useSubscription()
  console.log("plan",plan, authState.plan, plan.amount)
  console.log(parseFloat((plan.amount / 1000) * 4))

  const buttonText =
    (subscriptionHook.isSubscriptionLoading && (selectedPlan.nickname == plan.nickname))
      ? "Loading..."
      : ((authState.plan.planName == plan.nickname) && authState.plan.active)
      ? "Current Plan"
      : (authState.plan.planName && (authState.plan.planName !== "free"))
      ? "Upgrade Plan"
      : "Select plan and proceed";

  return (
    <Card
      currentPlan={
        authState.plan.planName == plan.nickname && authState.plan.active
      }
      disable={
        subscriptionHook.isSubscriptionLoading ||
        (authState.plan.planName == plan.nickname && authState.plan.active)
      }
      onClick={() => {
        setSkipClicked(false);
        setSelectedPlan(plan);
      }}
    >
      <CardHeader>
        <input
          checked={selectedPlan.id == plan.id}
          type="radio"
          id={plan.nickname}
          name="plan"
          value={plan.nickname.split("-")[0]}
        />
        <HeaderRight>
          <PlanName>{plan.nickname.split("-")[0]}</PlanName>
          <Price>
            <span>{plan.currency == "inr" ? "₹" : "$"}</span>
            <p>
              {/* {console.log(Number(parseFloat(plan.amount / 100).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              }).replace(",",""))*0.25)} */}
              {(plan.amount / 1000)}
              <span>/ {plan.nickname.includes("monthly") ? "mo" : "yr"}</span>
            </p>
          </Price>
          <h3><p>{plan.currency == "inr" ? "₹" : "$"} {(parseFloat((plan.amount / 1000) * 4).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              }))}</p><span>75% off</span></h3>
        </HeaderRight>
      </CardHeader>
      {<BasicDetail>
        Start for free. {plan.currency == "inr" ? "₹" : "$"}
        {parseFloat(plan.amount / 100).toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}
        <span>/ {plan.nickname.includes("monthly") ? "mo" : "yr"}</span> after 3
        days.
      </BasicDetail>}
      <CardBody>
        {plan.content &&
          plan.content.map((content) => {
            return <Detail>✔ {content}</Detail>;
          })}
      </CardBody>

      <PlanPaymentButton
        onClick={() => onProceed(plan)}
        currentPlan={
          authState.plan.planName == plan.nickname && authState.plan.active
        }
        disable={
          subscriptionHook.isSubscriptionLoading ||
          (authState.plan.planName == plan.nickname && authState.plan.active)
        }
      >
        {buttonText}
      </PlanPaymentButton>
    </Card>
  );
};


export default PlanCard