import { useImageAdGenerator } from "../../context/imageAdGeneration";
import statics from "../../statics";
import { AdTypesContainer, AdTypeName, AdTypeContainer, AdTypeIcon, AdTypeIconContainer } from "./AdType.styled";
import AdGoal from "./AdGoal";

function AdType() {
    const { imageAdGeneratorState, imageAdGeneratorDispatch } = useImageAdGenerator()
    const { adTypes } = statics

    const handleGoalSelection = (adtype) => {
        imageAdGeneratorDispatch({type: "SET_AD_TYPE", value: adtype})
        imageAdGeneratorDispatch({type: "AD_GOAL_TOGGLE", value: true})
    }
    
    return ( 
        <AdTypesContainer>
            {adTypes.map((adtype) => {
                const isSelectedGoal = imageAdGeneratorState.adType?.id === adtype.id
                return(
                    <AdTypeContainer key={adtype.id} onClick={() => handleGoalSelection(adtype)} isSelected={isSelectedGoal} >
                        <AdTypeIconContainer isSelected={isSelectedGoal} >
                            <AdTypeIcon src={ isSelectedGoal ? adtype.activeIcon : adtype.inActiveIcon} alt="adtype" />
                        </AdTypeIconContainer>
                        <AdTypeName isSelected={isSelectedGoal} >{adtype.name}</AdTypeName>
                    </AdTypeContainer>
                )
            })}
            {imageAdGeneratorState.adGoalToggle && <AdGoal />}
        </AdTypesContainer>
     );
}

export default AdType;