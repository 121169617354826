import styled from "styled-components";

export const AdDistributionWrap = styled.div`
width: 966px;
height: 303px;
border-radius: 16px;
background: #FFF;
padding: 24px;
margin-top: 16px;
max-width: 100%;
`

export const MapsContainer = styled.div`
    h4{
        margin-bottom: 20px;
        color: #2E2E2E;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        gap: 10px;
    }
`;

export const MapsContainerBody = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export const CountryChartContainer = styled.div`
    /* padding: 16px 20px; */
    background: #FFFFFF;
    width: fit-content;
    height: 100%;
`;



