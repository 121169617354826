import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../../context/auth";
import {Helmet} from "react-helmet"
import { appClient } from "../../../../utils/axios-utils";
import SelectPlatform from "./components/platformSelect";

import AdspyderLogo from "../../../../media/Images/whiteAdspyder.svg";
import TryFreePlanPopup from "../../../components/tryFreePlanPopup";
import { useSubscription } from "../../../hooks/useSubscription"
import PlanCard from "../Components/PlanCard";
import { PlansWrap, PlansContainer, ToggleContainer, MonthlyButton, YearlyButton, PlansListContainer, TermsAndCondition, Note, HomePageButton, FreePopup, FreePopupBody } from "./index.styled"

const PlansPricing = async () => {
  let currency = localStorage.getItem("usercurrency");
  const config = {
    url: "/payment/price/all",
    method: "POST",
    data: {
      currency,
    },
  };

  return appClient(config);
};

const Plans = () => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const subscriptionHook = useSubscription()

  const [stopShowingPopup, setStopShowingPopup] = useState(false)
  const [plansList, setPlansList] = useState([]); 
  const [selectedPlan, setSelectedPlan] = useState({});
  const [selectedType, setSelectedType] = useState("Yearly");
  const [show, setShow] = useState(false);
  const [skipClicked, setSkipClicked] = useState(false);
  const { authState, authDispatch } = useAuth();
  const [selectPlanClicked, setSelectPlanClicked] = useState(false);
  console.log(authState)

  const selectionRemainder = () => {
    const config = {
      url: "/payment/app/sub-remainder",
      method: "GET",
    };

    return appClient(config);
  };

  const mailTriggerForSelectionRemainder = useQuery(
    ["selectionRemainder"],
    selectionRemainder,
    {
      retry: false,
      enabled: false,
    }
  );

  const { mutate: AllPlansMutate, isLoading: isPlansLoading } = useMutation(
    PlansPricing,
    {
      onSuccess: (data) => {
        // console.log(data);
        setPlansList(data);
        setSelectedPlan(filterMonthlyPlans(data)[0]);
      },
    }
  );

  const filterMonthlyPlans = (plans) => {
    return plans.filter((plan) => {
      return plan.id.includes("Monthly");
    });
  };

  const filterYearlyPlans = (plans) => {
    return plans.filter((plan) => {
      return plan.id.includes("Yearly") || plan.id.includes("Day");
    });
  };

  const onProceed = (plan) => {
    // if (plan.id.includes("basic")) {
    //   setShow(true);
    // } else {
      console.log(plan, authState)
      if(plan.id.toLowerCase().includes("standard"))
      subscriptionHook.CreateSubscriptionMutate({
        planId: plan ? plan.id : plan.id,
        planName: plan.id,
        selectedPlatform: "",
      });
      else navigate("/payment-success")
    // }
  };

  const onSkip = () => {
    setSelectedPlan({ id: "free" });
    setSkipClicked(true);
    if (authState.plan.active) {
      navigate("/");
    } else {
      setShow(true);
    }
  };

  useEffect(() => {
    AllPlansMutate(authState.userLocation.currency);

    return () => {
      // console.log("unmount");
      let subReminderMail = localStorage.getItem("sub-reminder-mail");
      if (!selectPlanClicked && !subReminderMail) {
        localStorage.setItem("sub-reminder-mail", true);
        mailTriggerForSelectionRemainder.refetch();
      }
    };
  }, []);

  let body, carousel, cards, tray, indicator, info;

  var getDimensions = function () {
    info.container_width = carousel.clientWidth;
    info.card_width = carousel.firstElementChild.clientWidth;
    info.tray_width = tray.clientWidth;
  };

  var moveIndicator = function (timestamp) {
    var amount_inview =
      info.container_width / (info.card_width * info.numCards); // < 1
    var tray_scale = info.tray_width / info.container_width;

    var indicator_width = info.tray_width * amount_inview;
    var indicator_offset = info.scroll_left * amount_inview * tray_scale;

    indicator.style.width = indicator_width + "px";
    indicator.style.left = indicator_offset + "px";

    requestAnimationFrame(moveIndicator);
  };

  var onScroll = function () {
    info.scroll_left = carousel.scrollLeft;
  };

  useEffect(() => {
    // body = document.body;
    // carousel = document.getElementById('plans-container');
    // console.log(carousel)
    // if(carousel.querySelector("div")){
    //   cards = document.querySelectorAll('.plan-card');
    //   tray = document.getElementById('storetray');
    //   indicator = document.getElementById('storeindicator');
    //   info = {
    //     numCards: cards.length
    //   };
    //   getDimensions();
    //   carousel.addEventListener('scroll', onScroll);
    //   window.addEventListener('resize', getDimensions);
    //   requestAnimationFrame(moveIndicator);
    // }
  }, [plansList]);

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    const tabCloseButtonX = window.innerWidth - 30; // Adjust this based on your tab's close button location
    const tabCloseButtonY = 10; // Adjust this based on your tab's close button location

    if (clientX <= tabCloseButtonX && clientY <= tabCloseButtonY) {
        setShowPopup(true);
    } else {
      // setShowPopup(false);
    }
  };

  useEffect(() => {
    window.addEventListener('mousemove',handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  console.log(plansList,'selectedPlan',selectedPlan)
  return (
    <PlansWrap>
      <Helmet>
          <meta charSet="utf-8" />
          <title>AdSpyder Pricing Plans</title>
          <meta name="title" content="AdSpyder Pricing Plans" />
          <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization." />
      </Helmet>
      
      {showPopup && !authState.plan.planName && <TryFreePlanPopup stopShowingPopup={stopShowingPopup} setStopShowingPopup={setStopShowingPopup} onSkip={onSkip} setShowPopup={setShowPopup}/>}
      {show && (
        <SelectPlatform
          skipClicked={skipClicked}
          selectedPlan={selectedPlan}
          setShow={setShow}
          setSelectPlanClicked={setSelectPlanClicked}
        />
      )}
      {/* <FreePopup>
        <FreePopupBody>
          <h2>🔔 Important Update</h2>
          <p>All AdSpyder features are currently unrestricted. Feel free to continue using the platform without any limitations.</p>
        </FreePopupBody>
      </FreePopup> */}
      <HomePageButton>
        <img onClick={() => navigate("/adspy")} src={AdspyderLogo} />
      </HomePageButton>

      <h1>Choose your trial plan</h1>
      <p>All our plans are free for 3-days.</p>
      {plansList && plansList.length > 0 && (
        <ToggleContainer>
          <MonthlyButton
            onClick={() => {
              setSelectedType("Monthly");
              setSelectedPlan(filterMonthlyPlans(plansList)[0]);
            }}
            isSelected={selectedType == "Monthly"}
          >
            Billed Monthly
          </MonthlyButton>
          <YearlyButton
            onClick={() => {
              setSelectedType("Yearly");
              setSelectedPlan(filterYearlyPlans(plansList)[0]);
            }}
            isSelected={selectedType == "Yearly"}
          >
            <span>2 months free</span>
            Billed Yearly
          </YearlyButton>
        </ToggleContainer>
      )}

      <PlansListContainer>
        <PlansContainer id="plans-container">
          {
          plansList &&
            plansList.length > 0 &&
            plansList.map((plan, idx) => {
              if(selectedType=="Yearly" && plan.id.includes("Day")){
                return <PlanCard
                    className="plan-card"
                    onProceed={onProceed}
                    isSubscriptionLoading={subscriptionHook.isSubscriptionLoading}
                    plan={plan}
                    setSkipClicked={setSkipClicked}
                    setSelectedPlan={setSelectedPlan}
                    selectedPlan={selectedPlan}
                  />
              }else{
                return (
                  plan.id.includes(selectedType) && (
                    <PlanCard
                      className="plan-card"
                      onProceed={onProceed}
                      isSubscriptionLoading={subscriptionHook.isSubscriptionLoading}
                      plan={plan}
                      setSkipClicked={setSkipClicked}
                      setSelectedPlan={setSelectedPlan}
                      selectedPlan={selectedPlan}
                    />
                  )
                );
              }
              
            })}
        </PlansContainer>
        <div id="storetray" class="storetray">
          <div id="storeindicator" class="storeindicator"></div>
        </div>
        {/* <PayButton onClick={()=>onProceed()} disable={subscriptionHook.isSubscriptionLoading}>
          {subscriptionHook.isSubscriptionLoading ? "Loading..." : "Select plan and proceed"}
        </PayButton> */}
          <TermsAndCondition>
          {authState?.isBusinessEmail && <Note>Your 10% discount will be applied in the next step.</Note>}
            {/* {!authState?.plan?.status  && <p>
              You can also <span onClick={onSkip}>skip this step</span> and use
              AdSpyder with limited access for up to 1-month.
            </p>} */}
          </TermsAndCondition>
      </PlansListContainer>
    </PlansWrap>
  );
};

export default Plans;
