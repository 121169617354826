import React, { useState, useEffect } from 'react'
import { appClient } from '../../../../utils/axios-utils'
import Layout from '../Components/LoginLayout/layout'
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useMutation } from "@tanstack/react-query"
import { Helmet } from "react-helmet"

import {
    MainContainer,
    LoginHeader,
    FormContainer,
    Divider,
    DividerWrapper,
    PasswordInputContainer,
    PasswordContainer,
    PasswordInput,
    CtaContainer,
    LoginButton,
    Error,
    SignupError,
    SignUPContainer,
    FormDivContainer,
    MailTextContainer,
    LayoutFooter,
    LayoutEle,
    TermsAndConditions
} from './index.styled'
import toast from "react-hot-toast"

import { useAuth } from "../../../../context/auth"

import SocialLogin from '../SocialLogin'
import WrongBack from '../../../../media/Dashboard/wrongBack.svg'
import WarningIcon from '../../../../media/Dashboard/warningicon.svg'
import circleRightMark from "../../../../media/Dashboard/circleRightMark.svg"


const emailSigninUser = (data) => {
    const config = {
        url: "/auth/local/register",
        method: "POST",
        data
    }

    return appClient(config)
}


const SignUp = () => {
    const [passwordType, setPasswordType] = useState('password')
    const [passwordEntered, setPasswordEntered] = useState('')
    const [signupError, setSignupError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [email, setEmail] = useState("")
    const [termsCondition, setTermsCondition] = useState(true)
    const location = useLocation()

    const { register, handleSubmit, formState: {
        errors
    } } = useForm()
    const navigate = useNavigate()
    const { authDispatch, authState } = useAuth()

    let toastId;
    const { mutate: onSubmit } = useMutation(emailSigninUser, {
        onSuccess: (data) => {
            toast.dismiss(toastId)
            setSignupError(false)
            if (data) {
                authDispatch({
                    type: 'CURRENT_USER_UPDATE',
                    data: data.user,
                    isAuthenticated: true
                })
            }
            toast.success("Successfully Signed in")
            if (data.user && !data.user.emailVerified) {
                authDispatch({ type: 'UPDATE_TIMER', value: Math.floor((new Date(data.user.tokenExpireAt).getTime() - Date.now()) / 1000) })
                navigate('/confirmemail')
            } else {
                navigate('/adspy')
            }
        },
        onError: (err) => {
            console.log(err)
            setErrorMessage(err.response.data.errors[0].message)
            toast.dismiss(toastId)
            setSignupError(true)
        },
        onMutate: () => {
            toastId = toast.loading("Signing in")
        }
    })

    const handleCheckboxChange = () => {
        setTermsCondition(prevState => !prevState);
      };

    const handlePasswordInput = (e) => {
        if (e.target.className.includes("password-input")) {
            setPasswordEntered(e.target.value)
        }
    }

    useEffect(() => {
        if (!authState.formEmail) {
            navigate("/")
        }
    }, [])

    return (
        <Layout>
            <MainContainer>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>AdSpyder Signup</title>
                    <meta name="title" content="AdSpyder Signup" />
                    <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
                </Helmet>
                <SignUPContainer>
                    <LoginHeader>
                        <p>You’re one click away from </p>
                        <p>achieving your marketing goals faster</p>
                    </LoginHeader>
                    <FormDivContainer>
                        <SocialLogin page={"signup"} />
                        <Divider>
                            <DividerWrapper>
                                <span>or Sign up</span>
                            </DividerWrapper>
                        </Divider>
                        <FormContainer onChange={
                            (e) => {
                                handlePasswordInput(e)
                            }
                        }
                            onSubmit={handleSubmit((data) => {
                                onSubmit({
                                    email: authState.formEmail,
                                    password: data.password
                                })
                            })}>
                            {/* <EmailInputContainer>
                    <label>Name</label>
                    <div>
                        <EmailInput // placeholder="Enter your email"
                            type="text"
                            error={errors.username}
                            {...register("username", { required: {
                                        value: true, 
                                        message: "Enter your username"
                                    } })}
                        />
                    </div>
                    
                    {errors.username && <Error>
                        {errors.username.message}
                    </Error>}
                </EmailInputContainer> */}
                            {/* <EmailInputContainer>
                        <label>Email <p>Use your business email and get<span>10% off</span></p></label>
                        <EmailInput
                            // placeholder="Enter your email"
                            type="text"
                            error={errors.email}
                            {...register("email", {
                                required: {
                                    value: true,
                                    message: "Enter your email"
                                }
                            })}
                        />
                        {errors.email && <Error>
                            {errors.email.message}
                        </Error>}
                    </EmailInputContainer> */}
                            <MailTextContainer><span>{authState.formEmail}</span><img src={WrongBack} onClick={()=>navigate("/")}/></MailTextContainer>
                            <PasswordInputContainer>
                                {/* <label>Password</label> */}
                                <PasswordContainer>
                                    <PasswordInput className='password-input' placeholder='Password'
                                        onChange={
                                            (e) => setPasswordEntered(e.target.value)
                                        }
                                        error={errors.password}
                                        // placeholder="Enter a password*"
                                        type={passwordType}
                                        {...register("password", {
                                            required: "Enter a password",
                                            minLength: {
                                                value: 8,
                                                message: "Create a password with at least 8 characters"
                                            }
                                        })} />
                                    {
                                        passwordEntered.length > 0 &&
                                        <i
                                            className={passwordType == "password" ? "fa-solid fa-eye" : "fa-solid fa-eye-slash"}
                                            id="eye"
                                            onClick={
                                                () => {
                                                    setPasswordType(passwordType == "password" ? "text" : "password")
                                                }
                                            }></i>
                                    } </PasswordContainer>
                                {errors.password && <Error>
                                    {errors.password.message}
                                </Error>}
                            </PasswordInputContainer>

                            <TermsAndConditions>
                                {/* <label> */}
                                <input type="checkbox" onChange={handleCheckboxChange} checked={termsCondition} />                                    
                                By signing up, you acknowledge that you understand and accept the terms outlined in the
                                <a href='https://adspyder.io/privacy-policy/' target='_blank'> Privacy Policy</a>.
                                    {/* </label> */}
                            </TermsAndConditions>

                            {signupError && <SignupError>
                                <img src={WarningIcon} /> <p>{errorMessage}. You can also <a onClick={
                                    () => {
                                        navigate("/changepassword")
                                    }
                                }>Reset your password</a></p>
                            </SignupError>}
                            <CtaContainer>
                                <LoginButton isActive={termsCondition}>Sign up</LoginButton>
                            </CtaContainer>
                        </FormContainer>
                    </FormDivContainer>
                </SignUPContainer>
                <LayoutFooter>
                    <LayoutEle><img src={circleRightMark} />
                        <p>1Billion+ adcopies from 100+ countries</p></LayoutEle>
                    <LayoutEle><img src={circleRightMark} />
                        <p>Save 2weeks of research time</p></LayoutEle>
                    <LayoutEle><img src={circleRightMark} />
                        <p>No Credit card required</p></LayoutEle>
                </LayoutFooter>
            </MainContainer>
        </Layout>
    )
}

export default SignUp
