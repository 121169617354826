import styled from "styled-components";

export const Container = styled.div`
    height: 100vh;
    display:flex;
    flex-direction:column;
   align-items:center;
`;

export const OnBoardingContainer = styled.div`
 height: 100vh;
    display:flex;
    flex-direction:column;
    justify-content:start;
    margin-left:90px;
`

export const SubContainer = styled.div`
width:59.622%;
@media(max-width:1100px){
    width:82%;
}
@media(max-width:700px){
    width:97.64%;
}


`;

export const OnBoardSubContainer = styled.div`
width:59.622%;
@media(max-width:1100px){
    width:82%;
}
@media(max-width:700px){
    width:97.64%;
}
`

export const Left = styled.div`
    // padding: 50px;
    width: 100%;
    height:66px;
    display:flex;
    align-items:center;

    @media screen and (max-width: ${props => props.theme.breakPoints.desktop}) {
        // width: 100%;
        // padding: 28px;
        // justify-content:center;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {
        // padding: 40px;
         justify-content:center;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileSmall}) {
        // padding: 20px;
         justify-content:center;
    }
`;

export const LogoContainer = styled.div`
    img{
        width: 147px;
        height: 38px;
    }
`;




export const ChildrenHolder = styled.div`
    height: 100%;
    width:100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    // margin: -20px auto;
    // @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {
    //     width: 100%;
    // }
    // @media(max-width:700px){
    //     width:98%;
    // }
`;

export const OnBoardChildrenHolder = styled.div`
 width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    margin-left:67px; 
    margin-top:52px;
`
export const Divider = styled.div`
width:615px;
height:4px;
background:#E8E8E8;
border-radius:3px;
`
export const SubDivider = styled.div`
width: ${(props) => props.progress}%;
height:100%;
 border-top-left-radius: 3px;
  border-bottom-left-radius:3px;
background:#FF711E;`

export const ChildrenContainer = styled.div`
    width:100%;
    height: calc(100vh - 66px);
    display: flex;
    justify-content: center;
    align-items: center;
`;

