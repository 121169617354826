import React from 'react'
import { useReducer, createContext, useContext } from 'react'

import produce from 'immer'

const initialState = {
    selectedProject: {
        
    },
    savedAds: [],
    projectGeneratedAds: [],
    detailsModal: false,
    selectedAdForDetail: null
}

const SavedAdsReducer = produce((draft, action) => {
    switch(action.type) {
        case 'SET_SELECTED_PROJECT':
            draft.selectedProject = action.value
            break

        case 'SET_SAVED_ADS':
            draft.savedAds = action.value
            break
            
        case 'SET_PROJECT_GENERATED_ADS':
            draft.projectGeneratedAds = action.value
            break
            
        case 'UPDATE_DETAILS_MODAL': 
            draft.detailsModal = action.value
            break
        
        case 'SET_SELECTED_AD_FOR_DETAIL': 
            draft.selectedAdForDetail = action.value
            break

        default:
            break
    }
})

const useSavedAdsReducer = () => {
    return useReducer(SavedAdsReducer, initialState)
}

const SavedAdsContext = createContext()

export const SavedAdsProvider = ({ children }) => {
    const [state, dispatch] = useSavedAdsReducer()
    
    const value = { savedAdsState: state, savedAdsDispatch: dispatch }
    
    return <SavedAdsContext.Provider value={value}>
        {children}
    </SavedAdsContext.Provider>
}

export const useSavedAds = () => {
    return useContext(SavedAdsContext)
}
