import styled from "styled-components";

export const Header = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
margin-bottom: 32px;
`;

export const TitleAndNav = styled.div`
display: flex;
align-items: center;
gap: 21px;
`;

export const Back = styled.img``

export const Name = styled.div`
color: #1C1C1C;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: ${(props) => props.makeBold ? "600" : "300"};
line-height: normal;

span{
    color: #1C1C1C;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
`;

export const Info = styled.div`
color: #1C1C1C;
text-align: right;
font-family: Inter;
font-size: 14px;
font-style: italic;
font-weight: 300;
line-height: normal;
`; 

