import styled from "styled-components";

export const PopupBackground = styled.div`
position: fixed;
background: rgba(0, 0, 0, 0.50);
width: 100vw;
height: 100vh;
z-index: 5;
top: 0;
left: 0;
`;

export const Content = styled.div`
width: 672px;
height: 658px;
flex-shrink: 0;
border-radius: 8px;
background: #FFF;
position: absolute;
transform: translate(-50%, -50%);
left: 50%;
top: 50%;
`;

export const UploadProfileContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    padding: 24px 0 0 24px;
`;

export const Close = styled.img`
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
`;

export const Title =styled.div`
color: #000;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
`;

export const Description = styled.div`
color: rgba(28, 28, 28, 0.40);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16.5px; /* 137.5% */
margin-top: 8px;
`;

export const Footer =styled.div`
width: 672px;
height: 72px;
flex-shrink: 0;
border-radius: 0px 0px 8px 8px;
border-bottom: 0.5px solid #CFCFCF;
background: #F7F7F7;
position: absolute;
bottom: 0;
left: 0;
padding: 16px 24px;
display: flex;
justify-content: end;
align-items: center;
`;  

export const PageNavigation = styled.div`
display: flex;
gap: 16px;
`;

export const Back = styled.div`
display: inline-flex;
height: 40px;
padding: 9px 24px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
color: #D9D9D9;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 21px */
word-spacing: 8px;
pointer-events: ${(props) => props.makeInactive ? "none" : "unset"};
`;

export const Next = styled.div`
display: inline-flex;
height: 40px;
padding: 9px 24px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 4px;
background: #FF711E;
color: #FFF;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 21px */
word-spacing: 8px;
cursor: pointer;
`;

export const Walkthrough = styled.div`
display: flex;
gap: 16px;
margin: 24px 0 32px 0;
`;

export const Step = styled.div`
width: 125px;
`;

export const StepName = styled.span`
color: ${(props) => props.inActive ? "#B9B9B9" : "#FF8D4B"};
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-left: 8px;
`;

export const Sno = styled.span`
color: ${(props) => props.inActive ? "#B9B9B9" : "#FF8D4B"};
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

export const UnderLine = styled.img`
margin-top: 8px;
`;