import React, { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { Ad, AdHeader, AdInfo, Advertiser, Analytics, AnalyticsHeader, Container, CopyButton, Countries, CountryConainer, CountryHeader, DestinationUrlContainer, DestinationUrlHeader, DetailContainer, FlagLogo, Header, ItemHeader, LastSeenAndPrice, LastSeenContainer, LastSeenHeader, Logo, LogoContainer, MapsList, MoreCountriesCount, PriceContainer, Product, ProductInnerContainer, ProductOuterContainer, SaveAdsButton, SearchTerms, Term, TermsList, TitleBody, TitleBox, TitleHeader, UrlAndCountry } from "./index.styled"
import GlobeIcon from "../../../../../media/Dashboard/globe.svg";
import CopyImage from "../../../../../media/Dashboard/CopyImg.svg";
import RedirectOrangeIcon from "../../../../../media/Dashboard/RedirectOrangeIcon.svg";
import AltImage from '../../../../../media/Dashboard/altimage.png'

import { countryList } from "../../../../../utils/maps";
import { appClient } from "../../../../../utils/axios-utils";

import { CircleFlag } from "react-circle-flags";
import { useAuth } from "../../../../../context/auth";
import { useSaveAds } from "../../../../hooks/useSaveAds";
import copiedImage from "../../../../../media/Dashboard/copiedImage.svg";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";

const getAdDetails = async (id) => {
  const config = {
    url: `/detail/bing-ecom/${id}`,
    method: "GET",
  };

  return appClient(config);
};

const adAmountSpent = async (id) => {
  const config = {
    url: `/detail/google/estimated-budget/${id}`,
    method: "GET",
  };

  return appClient(config);
};

const BingEcomDetails = ({ basicDetail, highlightWords, onSave }) => {
  const navigate = useNavigate()

  const [adDetails, setAdDetails] = useState(basicDetail);
  const [detailsLoading, setDetailsLoading] = useState(true);
  const [copyState, setCopyState] = useState(false);
  const [copyURL, setCopyURL] = useState(false);


  const [adAmount, setAdAmount] = useState({})
  const { authState, authDispatch } = useAuth()
  const useSaveAdsHook = useSaveAds()

  const getFlagEmoji = (countryName) => {
    let countryCode = countryList[countryName];
    if (countryCode) {
      return (
        <FlagLogo>
          <CircleFlag countryCode={countryCode.toLowerCase()} height="24" />
        </FlagLogo>
      );
    }
  };

  const { mutate: adsDetailsMutate, isLoading: isDetailsLoading } = useMutation(
    getAdDetails,
    {
      onSuccess: (data) => {
        setAdDetails(data);
        setDetailsLoading(false);
      },
      onError: (e) => {
        console.log("err", e);
        setDetailsLoading(false);
        setAdDetails({});
      },
    }
  );

  const { mutate: adAmountSpentMutate, isLoading: isAdAmountSpentLoading } = useMutation(
    adAmountSpent,
    {
      onSuccess: (data) => {
        setAdAmount(data)
      },
      onError: (e) => {
        console.log("err", e);
        setAdAmount({})
      },
    }
  );
  const image = basicDetail.logo ? <img src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${basicDetail.id}`} onError={() => {}}/> : <img src={AltImage} onError={() => {}}/>;

  const convertTimestamp = (timestamp) => {
    // Create a new Date object based on the timestamp
    var date = new Date(timestamp);

    // Get the month name
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var month = monthNames[date.getMonth()];

    // Get the year
    var year = date.getFullYear();

    // Assemble the formatted date string
    var formattedDate = date.getDate() + " " + month + " " + year;

    return formattedDate;
  };
  const onRemoveSavedAd = (ad) => {
    useSaveAdsHook.RemoveSavedAdMutate({
      projectId: authState.defaultProjectInfo.projectId,
      adId: `${ad.id}`,
      platform: "bing-ecom"
    })
  }
  const analyzeDomian = () =>{
    const url = new URL(basicDetail?.url);
    const domain = url.hostname;
    navigate(`/domain-overview/details?domain=${domain}&country=global`)
  }
  useEffect(() => {
    adsDetailsMutate(basicDetail.id);
    // adAmountSpentMutate(basicDetail.id)
  }, []);

  if (detailsLoading) {
    return <h2>Loading</h2>;
  } else {
    return(
      <DetailContainer>
        <Header>Ad Details</Header>
        <AdHeader>
          <AdInfo>
            <LogoContainer>
              <Logo src={GlobeIcon} alt="" />
            </LogoContainer>
            <Advertiser>{basicDetail.domainId}</Advertiser>
          </AdInfo>
          <SaveAdsButton isSaved={authState.savedAdsIds.includes(basicDetail.id)} onClick={(e) => {
              e.stopPropagation();
              if (authState.savedAdsIds.includes(basicDetail.id)) {
                onRemoveSavedAd(basicDetail)
              } else {
                onSave(basicDetail);
              }
            }}>
              {authState.savedAdsIds.includes(basicDetail.id) ? "Saved" : "Save"}
          </SaveAdsButton>
        </AdHeader>
        <Ad>
          <ProductOuterContainer>
            <ProductInnerContainer>
              <Product
                src={basicDetail.image
                      ? `https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${basicDetail.id}`
                      : AltImage
                }
                alt=""
              />
            </ProductInnerContainer>
          </ProductOuterContainer>
          <TitleBox>
            <TitleHeader>
              Title
              <CopyButton
                           onClick={() => {
                            !copyState && setCopyState(true)
                            navigator.clipboard.writeText(basicDetail.title);
                            !copyState && setTimeout(() => {
                              setCopyState(false)
                            }, 3000);
                          }}
                          data-tooltip-id="title-tooltip"
                          data-tooltip-content={copyState ? 'Copied' : 'Copy'}
                            >
                        {!copyState ? <img src={CopyImage} />:<img src={copiedImage} />}
                        <Tooltip id="title-tooltip" 
                  noArrow={true} 
                  place={'bottom'}  
                  style={{ backgroundColor: "#101828", color: "#FFF",fontFamily: 'Inter', fontSize: '12px', borderRadius: '8px',
                  fontWeight: 600, height: '34px', padding:'8px 12px',display: 'flex', alignItems: 'center' }}/>
                        </CopyButton>
            </TitleHeader>
            <TitleBody>
              {basicDetail.title}
            </TitleBody>
          </TitleBox>
        </Ad>
        <Analytics>
          <AnalyticsHeader>Ad Analytics</AnalyticsHeader>
          <Container>
            <SearchTerms>
              <ItemHeader>Google search terms</ItemHeader>
              <TermsList>
                {adDetails.keywords && adDetails.keywords.map((keyword) => {
                  return <Term>{keyword}</Term>
                })}
              </TermsList>
            </SearchTerms>
            <UrlAndCountry>
                <DestinationUrlContainer>
                  <DestinationUrlHeader>
                    <h3>
                      Destination URL
                      <p onClick={analyzeDomian}>
                        <img src={RedirectOrangeIcon} />
                        analyze domain
                      </p>
                    </h3>
                    <CopyButton
                          onClick={() => {
                            !copyURL && setCopyURL(true)
                            navigator.clipboard.writeText(adDetails.url);
                            !copyURL && setTimeout(() => {
                              setCopyURL(false)
                            }, 3000);
                          }}
                          data-tooltip-id="url-tooltip"
                  data-tooltip-content={copyURL ? 'Copied' : 'Copy'}
                        >
                            {!copyURL ? <img src={CopyImage} />:<img src={copiedImage} />}
                            <Tooltip id="url-tooltip" 
                  noArrow={true} 
                  place={'bottom'}  
                  style={{ backgroundColor: "#101828", color: "#FFF",fontFamily: 'Inter', fontSize: '12px', borderRadius: '8px',
                  fontWeight: 600, height: '34px', padding:'8px 12px',display: 'flex', alignItems: 'center' }}/>
                        </CopyButton>
                  </DestinationUrlHeader>
                  <p>{basicDetail?.url}</p>
                </DestinationUrlContainer>
                <CountryConainer>
                      <CountryHeader>
                        <h3>Country</h3>
                      </CountryHeader>
                      <MapsList>
                        {Array.isArray(basicDetail.country) ? basicDetail.country.map((c, idx) => {
                          if (basicDetail.country.length > 5 ? idx < 5 : true) {
                            return getFlagEmoji(c);
                          }
                        }):''}
                        {basicDetail.country.length > 5 && <MoreCountriesCount>{`+${basicDetail.country.length - 5}`}</MoreCountriesCount>}
                      </MapsList>
                      <Countries>
                        {basicDetail.country.length == 1
                          ? basicDetail.country[0]
                          : basicDetail.country.length > 1
                          ? basicDetail.country.join().length > 42 ? basicDetail.country.join().slice(0, 40) + "..." : basicDetail.country.join()
                          : ""}
                      </Countries>
                </CountryConainer>
            </UrlAndCountry>
            <LastSeenAndPrice>
              <LastSeenContainer>
                <LastSeenHeader>
                  <h3>Last Seen</h3>
                </LastSeenHeader>
                <div>
                  <p>
                    {convertTimestamp(basicDetail.lastSeen).split(" 20")[0]}
                  </p>
                  <span>
                    20{convertTimestamp(basicDetail.lastSeen).split(" 20")[1]}
                  </span>
                </div>
              </LastSeenContainer>
              <PriceContainer>
                <LastSeenHeader>
                  <h3>Price</h3>
                </LastSeenHeader>
                <div>
                  <p>
                    {basicDetail.price}
                  </p>
                </div>
              </PriceContainer>
            </LastSeenAndPrice>
          </Container>
        </Analytics>
      </DetailContainer>
    )
  }
};

export default BingEcomDetails;
