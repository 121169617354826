import styled from "styled-components";

export const Container = styled.div`
    padding: 36px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const LocationSelectContainer = styled.div`
    label{
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        color: rgba(28, 28, 28, 0.50);
    }
`;

export const LanguageSelectContainer = styled.div`
    label{
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        color: rgba(28, 28, 28, 0.50);
    }
`;

export const LocationInputContainer = styled.div`
    margin-top: 12px;
    position: relative;
`;

export const LanguageInputContainer = styled.div`
    margin-top: 12px;
    position: relative;
`;


export const LocationSuggestionContainer = styled.div`
    position: absolute;
    border-radius: 0px 0px 12px 12px;
    border: 1px solid #E3E8EE;
    background: #FFF;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.15);
    z-index: 2;
    width: 100%;
    top: 48px;
`;


export const LanguageSuggestionContainer = styled.div`
    position: absolute;
    border-radius: 0px 0px 12px 12px;
    border: 1px solid #E3E8EE;
    background: #FFF;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.15);
    z-index: 2;
    width: 100%;
    top: 48px;
`;

export const SuggestionElement = styled.div`
    height: 44px;
    padding: 12px 16px;
    border-bottom: solid 1px #E3E8EE;
    cursor: pointer;
`;

export const SelectedLocationsConatiner = styled.div`
    margin-top: 24px;
`;

export const SelectedLanguageConatiner = styled.div`
    margin-top: 24px;
`;

export const SelectedLocationList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 12px;
`;

export const SelectedLocationItem = styled.div`
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15px;
    padding: 6px 8px 6px 12px;
    border-radius: 16px;
    background: #F7F7F7;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    img{
        cursor: pointer;
    }
`;

export const SelectedLanguageList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 12px;
`;

export const SelectedLanguageItem = styled.div`
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15px;
    padding: 6px 8px 6px 12px;
    border-radius: 16px;
    background: #F7F7F7;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    img{
        cursor: pointer;
    }
`;

export const LocationInput = styled.div`
    height: 44px;
    padding: 0px 16px;
    border-radius: 6px;
    border: 1px solid #DADFE4;
    display: flex;
    align-items: center;
    gap: 16px;
    img{
        height: 16px;
    }
    input{
        border: none;
        outline: none;
        height: 100%;
    }
`;


export const LanguageInput = styled.div`
    height: 44px;
    padding: 0px 16px;
    border-radius: 6px;
    border: 1px solid #DADFE4;
    display: flex;
    align-items: center;
    gap: 16px;
    img{
        height: 16px;
    }
    input{
        cursor: not-allowed;
        border: none;
        outline: none;
        height: 100%;
        width: 100%;
    background: none;
    }
`;