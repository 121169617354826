// import adgoals1 from "./assets/adgoals1.svg"
// import adgoals2 from "./assets/adgoals2.svg"
// import adgoals3 from "./assets/adgoals3.svg"
// import adgoals4 from "./assets/adgoals4.svg"
// import adgoals5 from "./assets/adgoals5.svg"
// import adgoals6 from "./assets/adgoals6.svg"
// import adgoals7 from "./assets/adgoals7.svg"
// import activeadgoal1 from "./assets/activeadgoal1.svg"
// import activeadgoal2 from "./assets/activeadgoal2.svg"
// import activeadgoal3 from "./assets/activeadgoal3.svg"
// import activeadgoal4 from "./assets/activeadgoal4.svg"
// import activeadgoal5 from "./assets/activeadgoal5.svg"
// import activeadgoal6 from "./assets/activeadgoal6.svg"
// import activeadgoal7 from "./assets/activeadgoal7.svg"

// import imgAdTemplate1 from "./assets/adTemplate1.png"
// import imgAdTemplate2 from "./assets/adTemplate2.png" 
// import imgAdTemplate3 from "./assets/adTemplate3.png"
// import imgAdTemplate4 from "./assets/adTemplate4.png"
// import imgAdTemplate5 from "./assets/adTemplate5.png"
// import imgAdTemplate6 from "./assets/adTemplate6.png"
// import imgAdTemplate7 from "./assets/adTemplate7.png"

import activeAdType1 from "../../../../media/Dashboard/adtype-product-dark.svg"
import activeAdType2 from "../../../../media/Dashboard/adtype-brand-awareness-dark.svg"
import activeAdType3 from "../../../../media/Dashboard/adtype-promotional-dark.svg"
import activeAdType4 from "../../../../media/Dashboard/adtype-educational-dark.svg"
import activeAdType5 from "../../../../media/Dashboard/adtype-engagement-dark.svg"
import activeAdType6 from "../../../../media/Dashboard/adtype-remarketing-dark.svg"
import activeAdType7 from "../../../../media/Dashboard/adtype-social-media-dark.svg"
import activeAdType8 from "../../../../media/Dashboard/adtype-display-dark.svg"
import activeAdType9 from "../../../../media/Dashboard/adtype-native-dark.svg"

import inactiveAdType1 from "../../../../media/Dashboard/adtype-product.svg"
import inactiveAdType2 from "../../../../media/Dashboard/adtype-brand-awareness.svg"
import inactiveAdType3 from "../../../../media/Dashboard/adtype-promotional.svg"
import inactiveAdType4 from "../../../../media/Dashboard/adtype-educational.svg"
import inactiveAdType5 from "../../../../media/Dashboard/adtype-engagemant.svg"
import inactiveAdType6 from "../../../../media/Dashboard/adtype-remarketing.svg"
import inactiveAdType7 from "../../../../media/Dashboard/adtype-social-media.svg"
import inactiveAdType8 from "../../../../media/Dashboard/adtype-display.svg"
import inactiveAdType9 from "../../../../media/Dashboard/adtype-native.svg"

import id1 from "./assets/categories/id1.svg"
import id2 from "./assets/categories/id2.svg"
import id3 from "./assets/categories/id3.svg"
import id4 from "./assets/categories/id4.svg"
import id5 from "./assets/categories/id5.svg"

// import adgoals1 from "./assets/adgoals1.svg"
// import adgoals2 from "./assets/adgoals2.svg"
// import adgoals3 from "./assets/adgoals3.svg"
// import adgoals4 from "./assets/adgoals4.svg"
// import adgoals5 from "./assets/adgoals5.svg"
// import adgoals6 from "./assets/adgoals6.svg"
// import adgoals7 from "./assets/adgoals7.svg"
// import activeadgoal1 from "./assets/activeadgoal1.svg"
// import activeadgoal2 from "./assets/activeadgoal2.svg"
// import activeadgoal3 from "./assets/activeadgoal3.svg"
// import activeadgoal4 from "./assets/activeadgoal4.svg"
// import activeadgoal5 from "./assets/activeadgoal5.svg"
// import activeadgoal6 from "./assets/activeadgoal6.svg"
// import activeadgoal7 from "./assets/activeadgoal7.svg"

import imgAdTemplate1 from "./assets/adTemplate1.png"
import imgAdTemplate2 from "./assets/adTemplate2.png" 
// import imgAdTemplate3 from "./assets/adTemplate3.png"
import imgAdTemplate4 from "./assets/adTemplate4.png"
import imgAdTemplate5 from "./assets/adTemplate5.png"
import imgAdTemplate6 from "./assets/adTemplate6.png"
import imgAdTemplate7 from "./assets/adTemplate7.png"

const statics = {
    pages: [
        {
            no: 1,
            name: "Select category",
            query: ""
        },
        {
            no: 2,
            name: "Primary Goal of Ad",
            query: "Why to specify primary ad goal ?"
        },
        {
            no: 3,
            name: "Enter target Audience",
            query: "Why to enter taget audience ?"
        },
        {
            no: 4,
            name: "Brand details",
            query: "Why to enter brand details ?"
        },
        {
            no: 6,
            name: "Generating ads...",
            description: "Please be patient. This process may take a few minutes..."
        },
        {
            no: 7,
            name: "Results"
        }
    ],
    adTypes: [
        {
            id: 1,
            name: "Product",
            activeIcon: activeAdType1,
            inActiveIcon: inactiveAdType1
        },
        {
            id: 2,
            name: "Brand Awareness",
            activeIcon: activeAdType2,
            inActiveIcon: inactiveAdType2
        },
        {
            id: 3,
            name: "Promotional",
            activeIcon: activeAdType3,
            inActiveIcon: inactiveAdType3
        },
        {
            id: 4,
            name: `Educational or Informational`,
            activeIcon: activeAdType4,
            inActiveIcon: inactiveAdType4
        },
        {
            id: 5,
            name: `Engagement`,
            activeIcon: activeAdType5,
            inActiveIcon: inactiveAdType5
        },
        {
            id: 6,
            name: "Remarketing",
            activeIcon: activeAdType6,
            inActiveIcon: inactiveAdType6
        },
        {
            id: 7,
            name: "Social Media",
            activeIcon: activeAdType7,
            inActiveIcon: inactiveAdType7
        },
        {
            id: 8,
            name: "Display",
            activeIcon: activeAdType8,
            inActiveIcon: inactiveAdType8
        },
        {
            id: 9,
            name: "Native",
            activeIcon: activeAdType9,
            inActiveIcon: inactiveAdType9
        },
    ],
    enteringDomain: {
        header: "Image ad generator",
        description: "Transform marketing with advanced image ad generation. Craft visually stunning ads using cutting-edge algorithms. Elevate brand impact with our innovation.",
        placeHolder: "Select a category",
        examplesLabel: "Examples:",
        example: ["Dentistry", "Fashion", "Travel"],
        generate: "Next",
        categories: [
            {
                id: 1,
                name: "Dentistry",
                icon: id1,
                backendName: "dentistry"
            },
            {
                id: 2,
                name: "Real Estate",
                icon: id2,
                backendName: "real-estate"
            },
            {
                id: 3,
                name: "Travel",
                icon: id3,
                backendName: "travel"
            },
            {
                id: 4,
                name: "Fashion",
                icon: id4,
                backendName: "fashion"
            },
            {
                id: 5,
                name: "Sports",
                icon: id5,
                backendName: "sports"
            },
            {
                id: 6,
                name: "Others",
                icon: null,
                backendName: null
            }
        ]
    },
    processes: [
        {
            id: 1,
            name: "Collecting ad copies...",
            isCompleted: false
        },
        {
            id: 2,
            name: "Pairing Personas and Sub Personas",
            isCompleted: false
        },
        {
            id: 3,
            name: "Calculating Persona-Sub Persona Matching Score",
            isCompleted: false
        },
        {
            id: 4,
            name: "Identifying Keywords",
            isCompleted: false
        },
        {
            id: 5,
            name: "Generating Ad Copies",
            isCompleted: false
        },
        {
            id: 6,
            name: "Implementing Landing Page Changes",
            isCompleted: false
        },
    ],
    generatedAds: [
        {
            id: 1,
            title: "Ad group 1",
            location: "India",
            titlesList: [
                "Top Deals & Coupons",
                "Save Big with Hot Coupons",
                "Daily Discounts on Everything!",
                "Grab Your Savings Today!",
                "Find the Perfect Coupon",
                "Unlock Exclusive Deals",
                "Big Brands, Bigger Savings!",
                "Don't Pay Full Price, Use Coupons!",
                "Free Shipping Coupons Galore",
                "Save Money on Every Purchase",
                "Your One-Stop Coupon Shop",
                "Discount Codes & Promo Offers",
                "Slash Prices with These Coupons",
                "Maximize Your Savings with Us",
                "Daily Deals & Hot Promotions"
            ],
            descriptionList: [
                `Verified coupons for top brands! Save up to 70% & find deals on everything`,
                `Exclusive discounts you won't find elsewhere. Shop smarter & save more!`,
                `Exclusive discounts you won't find elsewhere. Shop smarter & save more!`,
                `Daily deals & free shipping! Don't miss out on incredible savings.`
            ],
            keywords: [
                "refer my friends", "hello", "my friends and family", "narendra modi", "Rahul gandhi", "Rajinikanth", "Mahendra sing Dhoni", "ICC t20 world cup", "Ipl 2024", "Chennai Super Kings", "Mumbai Indians", "Dravida Munnetra Kazhagam", "TVK Vijay", "Thalapathy Vijay", "CouponsWala", "Adspyder", "Visual studio"
            ]
        },
        {
            id: 2,
            title: "Ad group 2",
            location: "India",
            titlesList: [
                "Top Deals & Coupons",
                "Save Big with Hot Coupons",
                "Daily Discounts on Everything!",
                "Grab Your Savings Today!",
                "Find the Perfect Coupon",
                "Unlock Exclusive Deals",
                "Big Brands, Bigger Savings!",
                "Don't Pay Full Price, Use Coupons!",
                "Free Shipping Coupons Galore",
                "Save Money on Every Purchase",
                "Your One-Stop Coupon Shop",
                "Discount Codes & Promo Offers",
                "Slash Prices with These Coupons",
                "Maximize Your Savings with Us",
                "Daily Deals & Hot Promotions"
            ],
            descriptionList: [
                `Verified coupons for top brands! Save up to 70% & find deals on everything`,
                `Exclusive discounts you won't find elsewhere. Shop smarter & save more!`,
                `Exclusive discounts you won't find elsewhere. Shop smarter & save more!`,
                `Daily deals & free shipping! Don't miss out on incredible savings.`
            ],
            keywords: [
                "refer my friends", "hello", "my friends and family", "narendra modi", "Rahul gandhi", "Rajinikanth", "Mahendra sing Dhoni", "ICC t20 world cup", "Ipl 2024", "Chennai Super Kings", "Mumbai Indians", "Dravida Munnetra Kazhagam", "TVK Vijay", "Thalapathy Vijay", "CouponsWala", "Adspyder", "Visual studio"
            ]
        },
        {
            id: 3,
            title: "Ad group 3",
            location: "India",
            titlesList: [
                "Top Deals & Coupons",
                "Save Big with Hot Coupons",
                "Daily Discounts on Everything!",
                "Grab Your Savings Today!",
                "Find the Perfect Coupon",
                "Unlock Exclusive Deals",
                "Big Brands, Bigger Savings!",
                "Don't Pay Full Price, Use Coupons!",
                "Free Shipping Coupons Galore",
                "Save Money on Every Purchase",
                "Your One-Stop Coupon Shop",
                "Discount Codes & Promo Offers",
                "Slash Prices with These Coupons",
                "Maximize Your Savings with Us",
                "Daily Deals & Hot Promotions"
            ],
            descriptionList: [
                `Verified coupons for top brands! Save up to 70% & find deals on everything`,
                `Exclusive discounts you won't find elsewhere. Shop smarter & save more!`,
                `Exclusive discounts you won't find elsewhere. Shop smarter & save more!`,
                `Daily deals & free shipping! Don't miss out on incredible savings.`
            ],
            keywords: [
                "refer my friends", "hello", "my friends and family", "narendra modi", "Rahul gandhi", "Rajinikanth", "Mahendra sing Dhoni", "ICC t20 world cup", "Ipl 2024", "Chennai Super Kings", "Mumbai Indians", "Dravida Munnetra Kazhagam", "TVK Vijay", "Thalapathy Vijay", "CouponsWala", "Adspyder", "Visual studio"
            ]
        },
        {
            id: 4,
            title: "Ad group 4",
            location: "India",
            titlesList: [
                "Top Deals & Coupons",
                "Save Big with Hot Coupons",
                "Daily Discounts on Everything!",
                "Grab Your Savings Today!",
                "Find the Perfect Coupon",
                "Unlock Exclusive Deals",
                "Big Brands, Bigger Savings!",
                "Don't Pay Full Price, Use Coupons!",
                "Free Shipping Coupons Galore",
                "Save Money on Every Purchase",
                "Your One-Stop Coupon Shop",
                "Discount Codes & Promo Offers",
                "Slash Prices with These Coupons",
                "Maximize Your Savings with Us",
                "Daily Deals & Hot Promotions"
            ],
            descriptionList: [
                `Verified coupons for top brands! Save up to 70% & find deals on everything`,
                `Exclusive discounts you won't find elsewhere. Shop smarter & save more!`,
                `Exclusive discounts you won't find elsewhere. Shop smarter & save more!`,
                `Daily deals & free shipping! Don't miss out on incredible savings.`
            ],
            keywords: [
                "refer my friends", "hello", "my friends and family", "narendra modi", "Rahul gandhi", "Rajinikanth", "Mahendra sing Dhoni", "ICC t20 world cup", "Ipl 2024", "Chennai Super Kings", "Mumbai Indians", "Dravida Munnetra Kazhagam", "TVK Vijay", "Thalapathy Vijay", "CouponsWala", "Adspyder", "Visual studio"
            ]
        }
    ],
    navItems: [
        {
            id: 1,
            name: "Title",
            limit: "15"
        },
        {
            id: 2,
            name: "Description",
            limit: "04"
        },
        {
            id: 3,
            name: "Keywords",
            limit: "20"
        }
    ],
    customizeNavItems: [
        {
            id: 1,
            name: "Details",
            limit: "15"
        },
        {
            id: 2,
            name: "Customize",
            limit: "04"
        },
        {
            id: 3,
            name: "Export",
            limit: "20"
        }
    ],
    customizeItems: [
        {
            id: 1,
            name: "Category"
        },
        {
            id: 2,
            name: "Primary Goal"
        },
        {
            id: 3,
            name: "Target Audience"
        },
        {
            id: 4,
            name: "Brand details"
        }
    ],
    imageAdTemplate: [
        {
            id: 1,
            templateHtml: "",
            img: imgAdTemplate1
        },
        {
            id: 2,
            templateHtml: "",
            img: imgAdTemplate2
        },
        {
            id: 3,
            templateHtml: "",
            img: imgAdTemplate4
        },
        {
            id: 4,
            templateHtml: "",
            img: imgAdTemplate4
        },
        {
            id: 5,
            templateHtml: "",
            img: imgAdTemplate5
        },
        {
            id: 6,
            templateHtml: "",
            img: imgAdTemplate6
        },
        {
            id: 7,
            templateHtml: "",
            img: imgAdTemplate7
        }
    ],
    colorsList: [
        {
            color: "#23414E"
        },
        {
            color: "#0AB9F1"
        },
        {
            color: "#2A59E2"
        },
        {
            color: "#0D7183"
        },
        {
            color: "#FF7272"
        },
        {
            color: "#FF711E"
        }
    ],
    targetAudienceForm: [
        {
            id: 1,
            key: "Age group",
            programmaticName: "ageGroup",
            choices: [
                {
                    choiceId: 1,
                    choice: "18-24"
                },
                {
                    choiceId: 2,
                    choice: "25-34",
                },
                {
                    choiceId: 3,
                    choice: "35-44",
                },
                {
                    choiceId: 4,
                    choice: "44-54",
                },
                {
                    choiceId: 5,
                    choice: "55-64",
                },
                {
                    choiceId: 6,
                    choice: "65+"
                }, 
            ]
        },
        {
            id: 2,
            key: "Gender",
            programmaticName: "gender",
            choices: [
                {
                    choiceId: 1,
                    choice: "Male"
                },
                {
                    choiceId: 2,
                    choice: "Female",
                },
                {
                    choiceId: 3,
                    choice: "Both",
                }
            ]
        }
    ],
    stockImage: [
        {
            id: 1,
            name: "Stock Images"
        }
        
    ],
    detailNavItems: [
        {
            id: 1,
            name: "Ad goal",
        },
        {
            id: 2,
            name: "Target location",
        },
        {
            id: 3,
            name: "Brand details",
        },
        {
            id: 4,
            name: "Ad type",
        }
    ]
}

export default statics