import React, {useState, useRef, useEffect} from "react"
import {
    VerifyNumberSection,
    NextButton,
    SurveyNavContainer,
    InputSection,
    VerifyNumberHeader,
    InputContainer,
    CountryCodeDropdown,
    DropDownHeader,
    DropDownList,
    CountryContainer,
    Albhabet,
    CountriesList,
    Country,
    PhoneInput,
    DropDownListWraper,
    PhoneInputWrap,
    Error,
    InputWrapper,
    VerifyCodeContainer,
    VerifyCodeHeader,
    ResendBtn,
    TimerSection,
    NextContainer,
    SkipButton
} from "./index.styled"
import {appClient} from '../../../../../../utils/axios-utils'
import {useMutation} from "@tanstack/react-query"
import OtpInput from 'react-otp-input';

import toast from "react-hot-toast"
import CountryJSON from "./country.json"
import {useForm} from "react-hook-form"

import {useSurvey} from "./../../context/survey";

import { useAuth } from "../../../../../../context/auth"

const getVerificationCode = (data) => {
    const config = {
        url: "/auth/local/phone-verification-code",
        method: "POST",
        data
    }

    return appClient(config)
}

const phoneOtpVerify = (data) => {
    const config = {
        url: "/auth/local/phone-verify-code",
        method: "POST",
        data
    }

    return appClient(config)
}

const VerifyNumber = () => {
    const [numberVerified, setNumberVerified] = useState(false)
    const { authState, authDispatch } = useAuth()
    const {surveyState, surveyDispatch} = useSurvey()
    const wrapperRef = useRef(null)
    const [otpError, setOtpError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const [tempPhone, setTempPhone] = useState('')

    let alphabets = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z"
    ]

    const {register, handleSubmit, formState: {
        errors
    }} = useForm()
    let toastId;

    const onCountryClicked = (country) => {
        surveyDispatch({type: 'UPDATE_COUNTRY_FLAG', value: country.emoji})
        surveyDispatch({type: 'UPDATE_COUNTRY_CODE', value: country.dial_code})
        surveyDispatch({type: 'COUNTRY_DROPDOWN_OPEN', value: false})
    }

    const onDropDownClicked = () => {
        surveyDispatch({
            type: 'COUNTRY_DROPDOWN_OPEN',
            value: !surveyState.countryDropdownOpen
        })
    }

    const onSendOtp = () => {
        surveyDispatch({type: 'UPDATE_OTP_SENT', value: true})
    }

    const onSubmit = () => {
        console.log("onSubmit")

        surveyDispatch({type: 'UPDATE_PHONE', value: authState.formPhoneNo})

        authDispatch({
            type: "UPDATE_USER_LOCATION",
            value: {countryCode: surveyState.selectedCountryCode}
          })


        onSendVerificationCode({
            phone: authState.formPhoneNo, countryCode: surveyState.selectedCountryCode
        })

    }
    // const {mutate: onSubmit} = useMutation(()=>{
    //     surveyDispatch({type: 'UPDATE_PHONE', value: authState.formPhoneNo})
    //     return getVerificationCode({phone: authState.formPhoneNo, countryCode: surveyState.selectedCountryCode})
    // }, {
    //     onSuccess: (data) => {
    //         surveyDispatch({type: 'UPDATE_TIMER', value: Math.floor((new Date(data.tokenExpiresAt).getTime() - Date.now())/1000)})
    //         surveyDispatch({type:'SET_OTP_SENT',value: true})
    //         toast.dismiss(toastId)
    //         toast.success("OTP sent")
    //     },
    //     onError: (err) => {
    //         console.log(err)
    //         if(err.response.data.errors[0].tokenExpiresAt){
    //             surveyDispatch({type: 'UPDATE_TIMER', value: Math.floor((new Date(err.response.data.errors[0].tokenExpiresAt).getTime() - Date.now())/1000)})
    //             surveyDispatch({type: 'SET_OTP_SENT', value: true})
    //             toast.success("OTP sent already!")
    //         }else{
    //             setOtpError(true)
    //             setErrorMessage(err.response.data.errors[0].message)
    //         }
    //         toast.dismiss(toastId)
    //     },
    //     onMutate: () => {
    //         toastId = toast.loading("Sending OTP...")
    //     }
    // })


    const { mutate: onSendVerificationCode } = useMutation(() => {
        return getVerificationCode({
            phone: authState.formPhoneNo, countryCode: surveyState.selectedCountryCode
        })
    }, {
        onSuccess: (data) => {
            // console.log("confirm email onSendVerificationCode", data)
            toast.dismiss(toastId)
            // if (data) {
            //     surveyDispatch({type:'SET_OTP_SENT',value: true})

            //     authDispatch({
            //         type: 'UPDATE_TIMER',
            //         value: Math.floor(
            //             (new Date(data.tokenExpiresAt).getTime() - Date.now()) / 1000
            //         )
            //     })
            //     toast.success("OTP sent")
            // }
            surveyDispatch({type: "SET_OTP_ERROR", value: ""})
            surveyDispatch({type:'UPDATE_PHONE_VERFIED',value: true})
            authDispatch({type:'SET_PHONE_VERIFIED'})
            surveyDispatch({type: 'UPDATE_NUMBER_VERIFIED', value: true})
            nextLogic()
        },
        onError: (err) => {
            // console.log("confirm email onSendVerificationCode", err)
            toast.dismiss(toastId)
            // if (err.response.data.errors[0].errCode == "OTP_ERR") {
            //     surveyDispatch({type:'SET_OTP_SENT',value: true})

            //     authDispatch({
            //         type: 'UPDATE_TIMER',
            //         value: Math.floor(
            //             (new Date(err.response.data.errors[0].tokenExpiresAt).getTime() - Date.now()) / 1000
            //         )
            //     })
            // } else if (err.response.data.errors[0].errCode == "USER_ERR") {
            //     surveyDispatch({type:'SET_OTP_SENT',value: true})

            //     authDispatch({
            //         type: 'UPDATE_TIMER',
            //         value: Math.floor(
            //             (new Date(err.response.data.errors[0].tokenExpiresAt).getTime() - Date.now()) / 1000
            //         )
            //     })
            // }
        },
        onMutate: () => {
            toastId = toast.loading("Sending OTP")
        }
    })


    const { mutate: verifyMobileOtp } = useMutation(
        phoneOtpVerify ,{
        onSuccess: (data) => {
            console.log(data)
            toast.dismiss(toastId)
            // setOtpError(false)  
            surveyDispatch({type: "SET_OTP_ERROR", value: ""})
            surveyDispatch({type:'UPDATE_PHONE_VERFIED',value: true})
            authDispatch({type:'SET_PHONE_VERIFIED'})
            surveyDispatch({type: 'UPDATE_NUMBER_VERIFIED', value: true})
            nextLogic()
            // setNumberVerified(true)
        },
        onError: (err) => {
            // toast.dismiss(toastId)
            console.log(err)
            toast.dismiss(toastId)
            surveyDispatch({type: "SET_OTP_ERROR", value: err.response.data.errors[0].message})
            // toast.error(err.response.data.errors[0].message)
            // setOtpError(true)
            // setErrorMessage(err.response.data.errors[0].message)
        },
        onMutate: () => {
            toastId = toast.loading("Verifying your phone..")
        }
    })

    const fmtMSS = (s) => {
        return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s
    }
    
    useEffect(() => {
        const TimerInt = authState.timer > 0 && setInterval(() => {
            authDispatch({
                type: 'UPDATE_TIMER',
                value: authState.timer - 1
            })
        }, 1000);
        return () => {
            clearInterval(TimerInt)
        }
    }, [authState.timer]);

    const OnVerifyOtp = () => {
        verifyMobileOtp({verificationCode: "000000", phone: authState.formPhoneNo, countryCode: surveyState.selectedCountryCode })
        nextLogic()
    }

    const OnSendOtp = () => {
        console.log("sendOtp")
        authDispatch({
            type: "UPDATE_USER_LOCATION",
            value: {countryCode: surveyState.selectedCountryCode}
          })
        onSendVerificationCode({ phone: authState.formPhoneNo, countryCode: surveyState.selectedCountryCode })
        nextLogic()
    }

    const nextLogic = () => {
        surveyDispatch({
            type: 'SET_CURRENT_STEP',
            value: surveyState.currentStep + 1
        })
        surveyDispatch({type: 'SET_PROGRESS', value: (surveyState.currentStep * 33) + 33})
    }

    console.log(authState.formPhoneOtp)

    return <VerifyNumberSection> 
        {/* {
        (numberVerified || authState.phoneVerified) ? <VerifiedContainer>
            <img src={SuccessfulVerify}/><p>Your phone number has been successfully verified.</p>
        </VerifiedContainer> : !surveyState.otpSent ? <EnterPhone/>: <EnterOtp setNumberVerified={setNumberVerified}/>
    } */}

    <h2>OTP Verification</h2>
    <p>Enter your phone no to verify you are not bot. </p>
    <InputSection>
            <VerifyNumberHeader>
                {!surveyState.otpSent && <p>
                    Enter phone number</p>}
            </VerifyNumberHeader>
            <InputContainer onSubmit={
                        handleSubmit(onSubmit)
                    }
                    onChange={(e)=>{
                        authDispatch({type: "UPDATE_FORM_PHONE_NUMBER", value: e.target.value})
                    }}
                    >
                <InputWrapper>
                        
                    <CountryCodeDropdown ref={wrapperRef} >
                        <DropDownHeader onClick={onDropDownClicked}>
                            {surveyState.selectedCountryFlag} <span>{surveyState.selectedCountryCode}</span>
                        </DropDownHeader>
                        {surveyState.countryDropdownOpen && <DropDownList>
                            <DropDownListWraper>
                            {alphabets.map((alphabet,idx)=>{
                                return <CountryContainer>
                                    <Albhabet>
                                        {alphabet}
                                    </Albhabet>
                                    <CountriesList>
                                        {CountryJSON[alphabet] && CountryJSON[alphabet].map((country,idx)=>{
                                            return <Country onClick={()=>onCountryClicked(country)}>
                                                {country.emoji} <span>{country.name}</span> {country.dial_code}
                                            </Country>
                                        })}
                                    </CountriesList>
                                </CountryContainer>
                            })}
                            </DropDownListWraper>
                        </DropDownList>}
                    </CountryCodeDropdown>
                    <PhoneInputWrap>
                        <PhoneInput 
                                type="number"
                                minLength={4}
                                maxLength={12}
                                placeholder="Enter phone number (without country code)" 
                                error={errors.phone}
                                className='otp-phone'
                                {...register("phone", { required: {
                                    value: true, 
                                    message: "Enter your Phone number"
                                } })}
                                />
                            {errors.phone && <Error>
                                {errors.phone.message}
                            </Error>}
                    </PhoneInputWrap>
                </InputWrapper>
                {/* <SendOtpBtn invisible={surveyState.otpSent ? (authState.timer > 0 ? "true" : "false"): false}>
                    Send OTP
                </SendOtpBtn> */}
            </InputContainer>
            
        </InputSection>
        {surveyState.otpSent && <VerifyCodeContainer>
            <VerifyCodeHeader>
                Verification code
            </VerifyCodeHeader>
            <OtpInput
                value={authState.formPhoneOtp}
                onChange={(e)=> authDispatch({type: "UPDATE_FORM_PHONE_OTP", value: e})}
                numInputs={6}
                // onPaste={true}
                inputType={"number"}
                renderSeparator={false}
                containerStyle={"InputListContainer"}
                renderInput={(props) => <input {...props} />}
            />
            <TimerSection>
                <p>
                    <ResendBtn onClick={onSendVerificationCode} invisible={authState.timer > 0 ? "true" : "false"}>Resend code</ResendBtn>  in 
                    <span>{fmtMSS(authState.timer)}</span>
                </p>
            </TimerSection>
            </VerifyCodeContainer>}
            <SurveyNavContainer currentStep={surveyState.currentStep} >
                <NextContainer>
                    {(surveyState.otpSent && !authState.phoneVerified) && <SkipButton onClick={nextLogic}>
                        Verify later
                    </SkipButton>}
                    <NextButton currentStep={surveyState.currentStep} onClick={OnSendOtp} disabled={
                        // (!surveyState.otpSent || authState.formPhoneOtp.length < 6)
                        (authState.formPhoneNo.length < 10)
                        }>
                        {/* Verify OTP  */}
                        Next
                    </NextButton>
                </NextContainer>
            </SurveyNavContainer>
    </VerifyNumberSection>

}


export default VerifyNumber
