import React, {useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom"

import {
    Container,
    SearchContainer,
    ResultsContainer,
    Wrapper,
    HeadingWrapper,
    KeywordInput,
    TrackButton,
    InputContainer,
    ClearButton,
    ErrorBox,
    HeadingLeft,
    HowItWork,
    HeaderRight,
    HeadLeftWrap,
    RightIcons,
    PlaceholderWithInput,
    HttpsLabel,
    ErrorIcon
} from "./index.styled"

import PPCRestrictionPopup from '../../../../components/PPCRestrictionPopup';
import CloseIcon from "../../../../../media/Dashboard/detailsPaneClose.svg"
import { useAuth } from '../../../../../context/auth';

import Tooltip from 'rc-tooltip';

import KeywordInputModal from './components/KeywordInputModal';
import {appClient} from '../../../../../utils/axios-utils'

import { useDomainFunnelAnalysis } from '../../context/domainFunnelAnalysis';

import CountryDropDown from "./components/KeywordInputModal/components/CountryDropDown";
import ValidateAndAddHttps from "../../../../../utils/urlValidate"
import funnelAnalysis from "./assets/funnelAnalysis.svg"
import errorIcon from "./assets/errorIcon.svg"

const tableData = async (data) => {
    // console.log(data)
    const config = {
        url: "/track/domain/all",
        method: "GET",
    }

    return appClient(config)
}

function isValidDomain(url) {
    const domainAndUrlRegEx = /^(((http|https):\/\/|)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?)$/;

    return domainAndUrlRegEx.test(url);
}

const KeywordFunnelAnalysisHome = () => {
    const {domainFunnelAnalysisState, domainFunnelAnalysisDispatch} = useDomainFunnelAnalysis()
    const {authState, authDispatch} = useAuth()
    
    const [error, setError] = useState(false)
    const [inputFocussed, setInputFocussed] = useState(false)

    const navigate = useNavigate()

    const handleInputChange = (e, idx) => {
        let { value } = e.target
        if(!value.startsWith("https://")) value = `https://${value}`
        console.log('url is ', value ,'valid url', isValidDomain(value) )
        domainFunnelAnalysisDispatch({type: 'SET_DOMAIN_INPUT', value})
        if(domainFunnelAnalysisState.domainInput.length > 0 )
            setError(!isValidDomain(e.target.value))
    }

    const domainFunnelFn = () => {
        if(domainFunnelAnalysisState.domainInput.length > 0 && !error){
            console.log('isvalid',domainFunnelAnalysisState.domainInput)

            const validUrl = ValidateAndAddHttps(domainFunnelAnalysisState.domainInput);
            
            navigate(`/domain-funnel/details?keyword=${domainFunnelAnalysisState.domainInput}&country=${domainFunnelAnalysisState.domainCountry.iso_alpha_2 ? domainFunnelAnalysisState.domainCountry.adspyder_name : "global"}`)
        }
    }

    const handleSearch = (event) => {
        if (event.key === 'Enter') {
            if(domainFunnelAnalysisState.domainInput.length > 0 && !error){
                console.log('isvalid',domainFunnelAnalysisState.domainInput)
    
                const validUrl = ValidateAndAddHttps(domainFunnelAnalysisState.domainInput);
                
                navigate(`/domain-funnel/details?keyword=${domainFunnelAnalysisState.domainInput}`)
            }
        }}

    useEffect(() => {
        domainFunnelAnalysisDispatch({type: 'SET_DOMAIN_INPUT_LIST', value: [{ keyword: '', country: {} }]})
        domainFunnelAnalysisDispatch({type: 'SET_DOMAIN_INPUT', value: ""})
    }, [])

    const headingText = "Domain Funnel Analysis  "
    const descriptionText = "Transform your user engagement strategy by integrating the Keyword Funnel Analysis tool. This innovative approach ensures that each click contributes to significant interactions and enhances conversion opportunities. Harness detailed insights to guide your actions, tailoring every aspect of the customer journey for optimal effectiveness and results. "


    const toolTipFunction = ()  =>{
        return (
            <div>
                <div style={{marginBottom: '16px',fontSize: '14px', color: 'var(--black-100, #1C1C1C)',fontWeight: '500'}}>How to use:</div>
                <ul style={{listStyleType: 'decimal',marginLeft: '16px'}}>
                    <li  style={{marginBottom: '16px'}}>In the search bar, type in the domain of your choice. Select the country from the dropdown and click on the “Analyse Domain” button. </li>
                    <li  style={{marginBottom: '16px'}}>In the “Ad Copies” tab, you get to see the ad copies classified as per their stage in the sales funnel. You can alternate between Google and Bing Search ads funnel analysis by clicking on the toggle at the top right corner of the workspace.</li>
                    <li  style={{marginBottom: '16px'}}>To check ad copies of various stages of the sales funnel, just click on the stage you want to see the ad copies for. </li>
                    <li style={{marginBottom: '16px'}}>To check the CTA analysis for a given stage in sales funnel, go to the “CTA’s” tab to view the call-to-action words and phrases used, along with the occurrences on the overall ad copies of that stage. </li>
                    <li style={{marginBottom: '16px'}}>To check the target audience analysis for the ad copies, go to the “Target Audience” tab and check the income, age and gender distribution for these ad copies of the particular stage. </li>
                    <li style={{marginBottom: '16px'}}>To view important keywords and key phrases used in the ad copies of a given stage in the sales funnel, simply go to the “Important keywords” tab and view the keywords and key phrases, along with their occurrences in the ad copies of that stage. </li>
                </ul>
            </div>
        )
    }

    const tooltipStyle = {
        marginTop: '100px'
    };
    
    const overlayStyle = {

    };
  
    const overlayInnerStyle = {
        backgroundColor: '#FFF7E8',
        color: 'rgba(28, 28, 28, 0.80)',
        padding: '24px 20px 44px 20px',
        fontSize: '14px',
        marginTop: '120px',
        width: '400px',
        maxHeight: '510px',
        paddingBottom: '44px',
        overflowY: 'auto'
    }; 

    return <Container>
         
                {authState.isPPCRestrictedPopup && 
                    <ResultsContainer>
                        <PPCRestrictionPopup/>
                    </ResultsContainer>
                }
                {
                    domainFunnelAnalysisState.domainInputModalOpen && 
                    <ResultsContainer>
                        <KeywordInputModal/>
                    </ResultsContainer>
                }

               <Wrapper isNavCollapsed={authState.isNavCollapsed}>
                    <HeadingWrapper>
                        <HeadingLeft>
                            <HeadLeftWrap>
                            <h2>{headingText}</h2>
                            <Tooltip 
                                trigger={['hover']}  
                                style={tooltipStyle}
                                overlayStyle={overlayStyle}
                                overlayInnerStyle={overlayInnerStyle} 
                                showArrow={false}
                                arrowContent={null}
                                overlay={toolTipFunction()}
                            >
                                <HowItWork>Quick guide</HowItWork>
                            </Tooltip>
                            </HeadLeftWrap>
                            <p>{descriptionText}</p>
                        </HeadingLeft>
                        <HeaderRight>
                            <img src={funnelAnalysis} />
                        </HeaderRight>
                    </HeadingWrapper>
                    <SearchContainer isNavCollapsed={authState.isNavCollapsed}>
                        <PlaceholderWithInput>
                            <HttpsLabel>https://</HttpsLabel>
                            <InputContainer isFocussed={inputFocussed} hasError={error}>
                                <KeywordInput
                                    onKeyDown={handleSearch}
                                    placeholder="Enter a domain"
                                    value={domainFunnelAnalysisState.domainInput.startsWith("https://") ? domainFunnelAnalysisState.domainInput.split("https://")[1] : domainFunnelAnalysisState.domainInput}
                                    onChange={handleInputChange}
                                    onFocus={() => setInputFocussed(true)}
                                    onBlur={() => setInputFocussed(false)}
                                />
                                <RightIcons>
                                    {domainFunnelAnalysisState.domainInput.length > 0 && <ClearButton  onClick={()=>{domainFunnelAnalysisDispatch({type: 'SET_DOMAIN_INPUT', value: ""})}} src={CloseIcon}/>}
                                    {error && <ErrorIcon src={errorIcon} alt=""/>}
                                </RightIcons>
                                
                            </InputContainer>
                        </PlaceholderWithInput>
                        <CountryDropDown />
                        <TrackButton 
                            onClick={() => domainFunnelFn()}
                        >
                            Analyze domain
                        </TrackButton>
                    </SearchContainer>
                    {error && <ErrorBox>Enter a valid domain</ErrorBox>}

                </Wrapper>
            </Container>
};
    

export default KeywordFunnelAnalysisHome