import React from "react";

import { Card, CardBody, FloatingButtonsContainer, CardFooter, FooterLeft, FooterRight, AdDate, AdCountries } from "./index.styled"


import DateIcon from "../../../../../../media/Images/DateIcon.svg"
import CountryIcon from "../../../../../../media/Images/CountryIcon.svg"

const RedditCard = ({ad}) => {
    
    const convertTimestamp = (timestamp) => {
        if(!timestamp){
            return "-"
        }
        // Create a new Date object based on the timestamp
        var date = new Date(Number(timestamp));
      
        // Get the month name
        var monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        var month = monthNames[date.getMonth()];
      
        // Get the year
        var year = date.getFullYear();
      
        // Assemble the formatted date string
        var formattedDate = date.getDate() + ' ' + month  + ' ' + year;
      
        return formattedDate;
    }

    const embededLink = ad.postUrl ? `${ad.postUrl.replace("www.reddit.com","www.redditmedia.com")}?ref_source=embed&amp;ref=share&amp;embed=true` :  "";

    
    return <Card key={
        ad.id
    }>
        <CardBody>

            <FloatingButtonsContainer>
                {/* <Favorite isFav={idx == 1} onClick={()=>{
                    authDispatch({type:'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'reddit'})
                    authDispatch({type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id})
                    authDispatch({type:'UPDATE_SAVE_AD_POPUP', value: true})}}>
                    <img src={authState.favAdsList["reddit"]?.includes(ad.id) ? FavouriteIconActive : FavorietIcon}/>
                </Favorite> */}
                {/* |
                <DropdownIcon>
                    <span/>
                </DropdownIcon> */}
            </FloatingButtonsContainer>
            <iframe id="reddit-embed" src={embededLink} sandbox="allow-scripts allow-same-origin allow-popups" style={{border: "none"}} height="480" width="480" scrolling="yes"></iframe>
        </CardBody>
    
        <CardFooter>    
            <FooterLeft>
                <AdDate>
                {ad.firstSeen && ad.lastSeen && <img src={DateIcon}/>}{convertTimestamp(ad.date)}
                </AdDate>
            <AdCountries>
                                                {ad.country.length > 0 && <img src={CountryIcon} />}{ ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
                </AdCountries>
            </FooterLeft>
            <FooterRight>
                {/* <DetailsButton onClick={()=>toggleDetailsModal(ad)}>
                    More details
                </DetailsButton> */}
            </FooterRight>
        </CardFooter>
    </Card>
}

export default RedditCard