import styled from "styled-components";

export const DropdownContainer = styled.div`
display: flex;
width: 250px;
height: 320px;
align-items: flex-start;
border-radius: 8px;
border: 1px solid var(--Gray-100, #F2F4F7);
background: var(--White, #FFF);
box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
position: absolute;
top: ${(props) => props.top ?? "unset"};
bottom: ${(props) => props.bottom ?? "unset"};
left: ${(props) => props.left ?? "unset"};
right: ${(props) => props.right ?? "unset"};
z-index: 3;
`;

export const Dropdown = styled.div`
width: calc(100% - 4px);
height: 100%;
border-radius: 8px 0 0 8px;
overflow-y: auto;

::-webkit-scrollbar-track{
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
    background-color: #fff;
    border-radius: 10px;
}

::-webkit-scrollbar{
    width: 8px;
    background-color: rgb(245 245 245 / 92%);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb{
    background-color: #EAECF0;
    border-radius: 10px;
}

`;

export const CountryContainer = styled.div`
display: flex;
padding: 10px 14px;
align-items: center;
gap: 8px;
align-self: stretch;
position: relative;
:hover{
    background: var(--Gray-50, #F9FAFB);
}
`;

export const Check = styled.img`
position: absolute;
right: 0;
top: 50%;
transform: translateY(-50%);
margin-right: 14px;
`;

export const Country = styled.div`
color: var(--Gray-900, #101828);

/* Text md/Medium */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
`;