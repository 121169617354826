import { useEffect, useRef } from "react";
import { Analyzing, Loader, Logo, Text } from "./index.styled";
import analyzer1 from "./assets/analyzer1.svg"
import analyzer2 from "./assets/analyzer2.svg"
import analyzer3 from "./assets/analyzer3.svg"
import analyzer4 from "./assets/analyzer4.svg"
function AnalyzingLoader({text}) {
    const loaderRef = useRef()

    const analyzingLoader = () => {
        const loader = loaderRef.current
        const loaderImages = [analyzer1, analyzer2, analyzer3, analyzer4]
        if(loader){
            let index = 1
            setInterval(() => {
                loader.setAttribute("src", loaderImages[index - 1])
                if(index < 4) index++
                else index = 1
            },[2000])
        }
    }

    useEffect(() => {
        analyzingLoader()
    }, [])

    return ( 
        <Analyzing>
            <Logo ref={loaderRef} src={analyzer1} draggable={false} />
            <Text>{text}</Text>
            <Loader />
        </Analyzing>
     );
}

export default AnalyzingLoader;