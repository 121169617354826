import { useTextAdGenerator } from "../../context/textAdGeneration";
import statics from "../../statics";
import { Header, Body, Logo, LogoContainer, Query, RootContainer, StepContainer, StepNoContainer, Title, WalthroughContainer, StepNo, Step, CompletedLine, Content, PageNavigation, BackContainer, BackIcon, BackText, NextButton, HomeRootContainer } from "./index.styled";
import checkMark from "../../assets/checkMark.svg"
import back from "../../assets/back.svg"
import { appClient } from "../../../../../../utils/axios-utils"
import {
    useMutation
} from "@tanstack/react-query"
import AdGoal from "./AdGoal";
import TargetLocation from "./TargetLocation";
import BrandDescription from "./BrandDescription";
import SeedKeywords from "./SeedKeywords";
import EnteringDomain from "./EnteringDomain";
import { useEffect } from "react";
import Tooltip from 'rc-tooltip';

const AdGoadData = async (data) => {
    const config = {
        url: `/text-ad-generation/generate/add-user-data`,
        method: "POST",
        data
    }

    return appClient(config)
}

function InputPages() {
    const { textAdGeneratorState, textAdGeneratorDispatch } = useTextAdGenerator()
    const { currentPage } = textAdGeneratorState
    const { pages } = statics
    
    const { mutate: getAdGoadData, isLoading: isAdGoadData } = useMutation(AdGoadData, {    
        onSuccess: (data) => {
            if(data){
                
            }    
        },
        onError: (err) => {
            
        }
    })
    const renderWalkthrough = () => {
        const handleActiveSidebar = (pageNo) => {
            // console.log(textAdGeneratorState.brandDescription.length.trim(),'textAdGeneratorState.targetLocation')
            switch (pageNo) {
                case 1:
                    if (pageNo == 1) {
                        return true;
                    }
                    break;
                case 2:
                    if (textAdGeneratorState.brandDescription.trim()) {
                        return true;
                    }
                    break;
                case 3:
                    if (textAdGeneratorState.adGoal) {
                        return true;
                    }
                    break;
                case 4:
                    if (textAdGeneratorState.targetLocation.length > 0) {
                        return true;
                    }
                    break;
               
                default:
                    return false;
            }
            return false;
        };
        return (
            <WalthroughContainer>
                {pages.map((page) => {
                    if(page.no > 5) return
                    const isCurrent = page.no === currentPage.no
                    const isActive = handleActiveSidebar(page.no);
                    return (
                        <StepContainer 
                            onClick={() => textAdGeneratorDispatch({type: "UPDATE_CURRENT_PAGE", value: pages[(page.no - 1)]})} isActive = {isActive}
                        >
                            {page.no < currentPage.no ?
                                <LogoContainer>
                                    <Logo src={checkMark} alt="check" />
                                </LogoContainer> :
                                <StepNoContainer isCurrent = {isCurrent} >
                                    <StepNo isCurrent = {isCurrent}>{page.no}</StepNo>
                                </StepNoContainer>
                            }
                            <Step isCompleted={page.no < currentPage.no} isCurrent = {isCurrent}>{page.name}</Step>
                            <CompletedLine isCompleted={page.no < currentPage.no} isLast = {page.no === 5} />
                        </StepContainer>
                    )
                })}
            </WalthroughContainer>
        )
    }

    const renderPageComponent = () => {
        switch(currentPage.no) {

            case 2:
            return <BrandDescription />

            case 3: 
            return <AdGoal />

            case 4:
            return <TargetLocation />

            case 5:
            return <SeedKeywords />

            default:
            return <AdGoal />

        }
    }
    const handleNext = () => {
        switch(currentPage.no){
            case 2:
                if(!textAdGeneratorState.brandDescription) return
                textAdGeneratorDispatch({type: "UPDATE_CURRENT_PAGE", value: pages[currentPage.no]})
            break
            case 3:
                if(!textAdGeneratorState.adGoal) return
                textAdGeneratorDispatch({type: "UPDATE_CURRENT_PAGE", value: pages[currentPage.no]})

            break
            case 4:
                if(!textAdGeneratorState.targetLocation) return
                textAdGeneratorDispatch({type: "UPDATE_CURRENT_PAGE", value: pages[currentPage.no]})
            break
           
            case 5:
                if(!textAdGeneratorState.seedKeywordsList.length) return
                console.log('onNext', textAdGeneratorState.seedKeywordsList)
                textAdGeneratorDispatch({type: "UPDATE_CURRENT_PAGE", value: pages[currentPage.no]})
                getAdGoadData({"userAdId": textAdGeneratorState.isAddDomainList,
                    "adGoal": textAdGeneratorState.adGoal.subName,
                    "brandDescription": textAdGeneratorState.brandDescription,
                    "targetLocation": textAdGeneratorState.targetLocation,
                    "seedKeywords": textAdGeneratorState.seedKeywordsList,
                    "personas": ["targeted audienecs"]})
            break
            default: 
                textAdGeneratorDispatch({type: "UPDATE_CURRENT_PAGE", value: pages[currentPage.no]})
            break
        }
    }
    const handleIsActive = () => {
        switch(currentPage.no){
            case 2:
                if(textAdGeneratorState.brandDescription.trim()){
                    return true
                }
            break
            case 3:
                if(textAdGeneratorState.adGoal){
                     return true
                }
            break
            case 4:
                if(textAdGeneratorState.targetLocation.length > 0){
                    return true
                }
            break
           
            case 5:
                if(textAdGeneratorState.seedKeywordsList.length){
                    return true
                }
            break
            default:
                return false;
        }
        return false;
    }
    const handleBack = () => {
        textAdGeneratorDispatch({type: "UPDATE_CURRENT_PAGE", value: pages[currentPage.no - 2]})
    }
    useEffect(()=>{
        textAdGeneratorDispatch(({ type: "SET_WENT_WRONG", value: false }))
        textAdGeneratorDispatch(({ type: "SET_TARGET_DOMAIN", value: null }))
        textAdGeneratorDispatch(({ type: "SET_AD_GOAL", value: null }))
        textAdGeneratorDispatch(({ type: "SET_BRAND_DESCRIPTION", value: null }))
        textAdGeneratorDispatch(({ type: "SET_TARGET_LOCATION", value: [] }))
        // textAdGeneratorDispatch(({ type: "ADD_SEED_KEYWORD", value: [] }))
    },[])
    const toolTipFunction = ()  =>{
        return (
            <div>
                <div style={{marginBottom: '16px',fontSize: '14px', color: 'var(--black-100, #1C1C1C)',fontWeight: '500'}}>
                Ad goal provides a context to the kind of ads you want to create. With ad goals, you can ensure that the ad campaigns are aligned with your business goals and what you aim to achieve with the ads.
                </div>
            </div>
        )
    }
    const overlayInnerStyle = {
        backgroundColor: '#FFF7E8',
        color: 'rgba(28, 28, 28, 0.80)',
        padding: '24px 20px 0px 20px',
        fontSize: '14px',
        marginTop: '120px',
        width: '400px',
        height: '135px',
        overflowY: 'auto'
    }; 
    return (  
        currentPage.no === 1 
        ?
            <HomeRootContainer> 
                <EnteringDomain />
            </HomeRootContainer>
        : 
            <RootContainer>
                <Header>
                    <Title>{`${currentPage.no}. ${currentPage.name}`}</Title>
                    <Tooltip 
                            trigger={['hover']}  
                            overlayInnerStyle={overlayInnerStyle} 
                            showArrow={false}
                            arrowContent={null}
                            overlay={toolTipFunction()}
                        >
                    <Query>{currentPage.query}</Query>
                    </Tooltip>
                </Header>
                <Body>
                    {renderWalkthrough()}
                    <Content>
                        {renderPageComponent()}
                        <PageNavigation>
                            <BackContainer onClick={handleBack} >
                                <BackIcon src={back} alt="back" />
                                <BackText>back</BackText>
                            </BackContainer>
                            <NextButton onClick={handleNext} isActive={handleIsActive()}>Next</NextButton>
                        </PageNavigation>
                    </Content>
                </Body>
            </RootContainer>
     );
}

export default InputPages;