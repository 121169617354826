import styled from "styled-components";

export const CheckMailWrapper = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
z-index: 9999;
display: flex;
justify-content: center;
`
export const CheckEmailPopup = styled.div`
padding: 36px 32px;
margin-top: 124px;
width: 738px;
height: 299px;
border-radius: 10px;
background: #FFF;
`
export const CheckEmailTitle = styled.div`
color: var(--black-100, #1C1C1C);
font-size: 20px;
font-weight: 600;
`
export const CheckEmailDescrip = styled.div`
margin-top: 20px;
color: var(--black-100, #1C1C1C);
font-size: 16px;
font-weight: 400;
line-height: 150%; /* 24px */
`
export const CheckEmailVerifyBtn = styled.div`
cursor: pointer;
border-radius: 6px;
background: #FF711E;
width: 155px;
height: 40px;
color: #FFF;
font-size: 14px;
font-weight: 500;
display: flex;
justify-content: center;
align-items:center;
`
export const ButtonWrapper = styled.div`
display: flex;
align-items: center;
margin-top: 32px;
column-gap: 21px;
span{
color: #B00020;
font-size: 12px;
font-weight: 500;
}
`