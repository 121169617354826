import { useTextAdGenerator } from "../../../context/textAdGeneration";
import statics from "../../../statics";
import { AdDetailsLabel, AdGroupBtn, ChangeButton, Container, CustomizeContainer, HighlightLine, InputName, NavContainer, NavItem, UpdateButton } from "./CustomizeBar.styled";
import { appClient } from "../../../../../../../utils/axios-utils"
import {
    useMutation
} from "@tanstack/react-query"
import { useNavigate } from "react-router-dom";

const AddGeneratingPage = async (data) => {
    const config = {
        url: `text-ad-generation/generate/update-user-data`,
        method: "POST",
        data
    }

    return appClient(config)
}
const getGoogleConnected = async (data) => {
    const config = {
        url: "/admanagement/get-connected-accounts",
        method: "GET",
        
    }

    return appClient(config)
}
function CustomizeBar() {
    const { customizeItems , customizeNavItems} = statics  
    const { textAdGeneratorState, textAdGeneratorDispatch } = useTextAdGenerator()
    const { activeAdTemplate } = textAdGeneratorState
    const navigate = useNavigate()
    const setActiveCustomizePopup = (item) => {
        textAdGeneratorDispatch({
            type: "UPDATE_ACTIVE_CUSTOMIZE_POPUP",
            value: item
        })
    }
    const { pages } = statics
    const { mutate: getAddGeneratingPage, isLoading: isAddGeneratingPage } = useMutation(AddGeneratingPage, {    
        onSuccess: (data) => {
            if(data){
                textAdGeneratorDispatch({type: "SET_ADD_DOMAIN_LIST", value: data.userAdId});
                textAdGeneratorDispatch({type: "UPDATE_RESULT_GENERATED_FLAG", value: false});
                textAdGeneratorDispatch({type: "UPDATE_CURRENT_PAGE", value: pages[5]})
                navigate(`/adgenerator/text-ad`)
            }    
        },
        onError: (err) => {
            
        }
    })
    const UpdateDetails = () => {
        textAdGeneratorDispatch({
            type: "UPDATE_RESULT_GENERATED_FLAG",
            value: false
        })
        textAdGeneratorDispatch({
            type: "UPDATE_CURRENT_PAGE",
            value: pages[5]
        })
        getAddGeneratingPage({ "domain": textAdGeneratorState.targetDomain,
        "adGoal": textAdGeneratorState.adGoal?.subName,
        "brandDescription": textAdGeneratorState.brandDescription,
        "targetLocation": textAdGeneratorState.updatedTargetLocation,
        "seedKeywords": textAdGeneratorState.seedKeywordsList,
        "personas": ["targeted audienecs"]})
    }
    const setActiveCustomizeNavItem = (item) => {
        textAdGeneratorDispatch(({
            type: "SET_ACTIVE_CUSTOMIZE_NAV_ITEM",
            value: item
        }))
    }
    const { mutate: setGoogleConnected, isLoading: isGoogleConnectedLoading } = useMutation(getGoogleConnected, {
        onSuccess: (data) => {
            // textAdGeneratorDispatch({type: "SET_GOOGLE_CONNECTED_DATA", value: data})
            data.length > 0 ?
                navigate(`/admanagement/ad-campaigns/new-campaign?id=${activeAdTemplate?.id}`)
            : navigate(`/admanagement/ad-campaigns?id=${activeAdTemplate?.id}`)
        },
        onError: (err) => {
            
        }
    })
    
    const renderAdDetails = () => {

        return<>
            <AdDetailsLabel>Ad details:</AdDetailsLabel>
            {
                customizeItems.map((item) => {
                    return(
                        <Container>
                            <InputName>{item.name}</InputName>
                            <ChangeButton onClick={() => setActiveCustomizePopup(item)} >Change</ChangeButton>
                        </Container>
                    )
                })
            }
            <UpdateButton isActive={textAdGeneratorState.isAdDetailsUpdated} onClick={UpdateDetails}>Update</UpdateButton>
        </>
    }
    const renderExportButton = () => {
        // const { activeAdTemplate } = textAdGeneratorState
         function findIndexById(id) {
            for (let i = 0; i < textAdGeneratorState.isAdGroupData.length; i++) {
                if (textAdGeneratorState.isAdGroupData[i].id === id) {
                    return i;
                }
            }
            return -1; 
        }
        
        const index = findIndexById(activeAdTemplate?.id);
        const publishGroup = () =>{
            setGoogleConnected()
        }
        return <>
        <AdGroupBtn onClick={publishGroup}>Publish ad group {`${index+1}`}</AdGroupBtn>
        </>
    }

    const renderCustomizeNav = () => {

        return <NavContainer>
            {
                customizeNavItems.map((item)=> {
                    const isActive = item.id === textAdGeneratorState.activeCustomizeNavItem.id

                    return <NavItem onClick={() => setActiveCustomizeNavItem(item)}>
                        <p>{item.name}</p>
                        <HighlightLine isActive={isActive} />
                    </NavItem>
                })
            }
        </NavContainer>
    }

    const renderPageComponent = () => {
        switch(textAdGeneratorState.activeCustomizeNavItem.id) {

            case 1:
            return renderAdDetails()

            case 2: 
            return renderExportButton()

            default:
            return renderAdDetails()

        }
    }
    return ( 
        <CustomizeContainer>
            {renderCustomizeNav()}
            {renderPageComponent()}
        </CustomizeContainer>
     );
}

export default CustomizeBar;