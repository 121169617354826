import styled from "styled-components"


export const VerifyNumberSection = styled.div`
    h2{
        color: #2C2C2C;
        font-family: Nunito;
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; 
        margin-top: 16px;
    }
    >p{
        color: #2C2C2C;
        font-family: Nunito;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 27px */
        letter-spacing: 0.18px;
        margin-top: 16px;
        margin-bottom: 60px;
    }
`; 

export const SurveyNavContainer = styled.div`
margin-top: 60px;
font-size: 12px;
color: #2E2E2E;
font-weight: 300;
display: flex;
justify-content: space-between;
align-items: center;

    @media screen and (max-width: ${props => props.theme.breakPoints.extraLargeScreen}) {
        
    }
    @media screen and (max-width: 1500px) {
        margin-top: 145px;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.largeScreen}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.desktop}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileSmall}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileExtraSmall}) {

    }
`;

export const NextButton = styled.div`
width: 155px;
height: 44px;
background: #FF711E;
border-radius: 4px;
font-size: 16px;
display: flex;
justify-content: center;
align-items: center;
color: white;
font-weight: 500;
cursor: pointer;
opacity: ${props => props.disabled ? "0.5": "1"};
pointer-events: ${props => props.disabled ? "none": "all"};
`;

export const VerifiedContainer = styled.div`
    display: flex;
    width: 306px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    p{
        margin-left: 20px;
        color: #707070;
        font-size: 16px;
        font-weight: 300;
    }
`;


// export const SurveyNavContainer = styled.div`
//     margin-top: 60px;
//     font-size: 12px;
//     color: #2E2E2E;
//     font-weight: 300;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;

//     @media screen and (max-width: ${props => props.theme.breakPoints.extraLargeScreen}) {
        
//     }
//     @media screen and (max-width: 1500px) {
//         margin-top: 145px;
//     }
//     @media screen and (max-width: ${props => props.theme.breakPoints.largeScreen}) {

//     }
//     @media screen and (max-width: ${props => props.theme.breakPoints.desktop}) {

//     }
//     @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {

//     }
//     @media screen and (max-width: ${props => props.theme.breakPoints.mobileSmall}) {

//     }
//     @media screen and (max-width: ${props => props.theme.breakPoints.mobileExtraSmall}) {

//     }
// `;

// export const NextButton = styled.div`
// width: 135px;
// height: 40px;
// background: #FF711E;
// border-radius: 8px;
// font-size: 16px;
// display: flex;
// justify-content: center;
// align-items: center;
// color: white;
// font-weight: 500;
// cursor: pointer;
// `;

// export const BackButton = styled.div`
// font-size: 12px;
// color: #2E2E2E;
// font-weight: 300;
// `;


export const InputSection = styled.div`
    margin-bottom: 32px;
    /* margin-bottom: 116px;
    @media screen and (max-width: ${props => props.theme.breakPoints.extraLargeScreen}) {
        
    }
    @media screen and (max-width: 1500px) {
        margin-bottom: 145px;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.largeScreen}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.desktop}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileSmall}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileExtraSmall}) {

    } */
`; 

export const VerifyNumberHeader = styled.div`
    color: #646464;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
`; 

export const InputContainer = styled.form`
    height: 52px;
    display: flex;
    justify-content: space-between;
    
    @media screen and (max-width: ${props => props.theme.breakPoints.extraLargeScreen}) {
        
    }
    /* @media screen and (max-width: 1500px) {
        flex-wrap: wrap;
    } */
    @media screen and (max-width: ${props => props.theme.breakPoints.largeScreen}) {
        flex-wrap: wrap;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.desktop}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileSmall}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileExtraSmall}) {

    }
`; 

export const CountryCodeDropdown = styled.div`
    position: relative;
    height: 52px;
`; 

export const DropDownHeader = styled.div`
    width: 106px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #505050;
    font-weight: 300;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #DADFE4;
    background: #FFF;
    span{
        margin-left: 8px;
    }
`; 

export const DropDownList = styled.div`
    padding-top: 12px;
    padding-bottom: 10px;
    position: absolute;
    width: 310px;
    top: 54px;
    z-index: 2;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px #0000001a;
    border-radius: 8px;
    `; 

export const CountryContainer = styled.div`

`; 

export const Albhabet = styled.div`
    background: #F9F9F9;
    border-radius: 0px 8px 8px 0px;
    height: 32px;
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #505050;
    font-size: 14px;
    margin-bottom: 12px;
`; 

export const CountriesList = styled.div`
    padding: 0px 4px;
`; 

export const Country = styled.div`
    height: 32px;
    display: flex;
    font-size: 14px;
    font-weight: 300;
    padding: 6px 8px;
    align-items: center;
    color: #2E2E2E;
    cursor: pointer;
    span{
        margin: 0px 8px;
        font-size: 14px;
        font-weight: 300;
        color: #707070;
        line-height: 21px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
    }
    &:hover{
        background: #F6F6F6;
        border-radius: 6px;
    }
`; 

export const PhoneInput = styled.input`
    width: 100%;
    background: #FFFFFF;
    /* border: ${props => props.error ? "1px solid #FF5180" : "1px solid #fffcfc"};
    outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};     */
    border: ${props => props.error ? "1px solid #FF711E" : "1px solid #ECECEC"};
    outline: none;
    /* border-radius: 8px; */
    padding: 9px 12px;
    font-weight: 300;
    color: #2E2E2E;
    width: 100%;
    height: 100%;

    &:focus{
        border: ${props => props.error ? "1px solid #FF5180" : "1px solid #FF711E"};

        /* border: ${props => props.error ? "1px solid #FF5180" : "1px solid #5AB3FF"};
        outline: ${props => props.error ? "2px solid #FFB7CB" : "2px solid #B9DDFF"}; */
    }
/* 
    @media screen and (max-width: ${props => props.theme.breakPoints.extraLargeScreen}) {
        
    }
    @media screen and (max-width: 1500px) {
        width: 239px;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.largeScreen}) {
        width: 239px;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.desktop}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {
        width: 215px;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileSmall}) {
        width: 175px;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileExtraSmall}) {
        width: 175px;
    } */
`;

export const PhoneInputWrap = styled.div`
    width: calc(100% - 106px);
    border-radius: 0px;
    border: 1px solid #DADFE4;
    border-left: 0px;
`;

export const SendOtpBtn = styled.button`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    opacity: ${props => props.invisible ? "0.5": "1"};
    background: #0D0E10;
    color: #FFF;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    width: 164px;
`;

export const DropDownListWraper = styled.div`
    overflow: auto;
    height: 400px;

    &::-webkit-scrollbar-track
    {
        background-color: white;
    }

    &::-webkit-scrollbar
    {
        width: 5px;
        background-color: #D4D4D4;
    }

    &::-webkit-scrollbar-thumb
    {
        background-color: #D4D4D4;
        border-radius: 2px;
    }

`;



export const Error = styled.p`
    position: absolute;
    color: #FF0045;
    margin-top: 6px;
    font-size: 14px;
    font-weight: 300;
`;

export const OtpError = styled.div`
    display: flex;
    align-items: flex-start;
    background: #FFF3F7;
    border: 1px solid #FFB7CB;
    border-radius: 8px;
    padding: 12px;
    font-size: 14px;
    font-weight: 300;
    color: #2E2E2E;
    line-height: 21px;
    width: 100%;
    p{
        margin-left: 8px;
    }
    a{
        color: #003FBC;
    }
`;

export const InputWrapper = styled.div`
    width: calc(100% - 164px);
    max-width: 451px;
    /* border-radius: 4px 0px 0px 4px;
    border: 1px solid #DADFE4; */
    background: #FFF;
    display: flex;
`;


export const VerifyCodeHeader = styled.div`
    margin-bottom: 8px;
    color: #646464; 
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
`;


export const VerifyCodeContainer = styled.div`
    margin-bottom: 103px;
    input{
        background-color: #F7F7F6;
        width: 52px!important;
        height: 52px;
        flex-shrink: 0;
        border:none;
        border-bottom:1px solid rgba(0, 0, 0, 0.50);
        outline:none;
        text-align:center;
        color: #000;
        font-family: Nunito;
        font-size: 26px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; /* 39px */
        letter-spacing: 0.26px;
        &:focus{
            border-bottom: 1px solid #000;
        }
    }
    .InputListContainer{
        gap: 16px;
    }
`;

export const ResendBtn = styled.div`
    display: flex;
    background: none;
    border: none;
    outline: none;
    color: ${props => props.invisible == "true" ? "#929292" : "#3C3B3B"};
    margin-right: 8px;
    cursor: pointer;
    pointer-events: ${props => props.invisible == "true" ? "none": "all"};
    letter-spacing: -0.15px;
    text-decoration-line: underline;
    cursor:pointer;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.14px;
`;

export const TimerSection = styled.div`
    display: flex;
    margin-top: 16px;

    span{
        margin-left: 3px;
        width: 40px;
    }

    p{
        width: 100%;
        display: flex;
        justify-content: center;
    }
`;

export const NextContainer = styled.div`
    display: flex;
    width: 100%;
`;

export const SkipButton = styled.button`
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    background: #0A66C2;
    border-radius: 8px;
    border: none;

    border-radius: 4px;
    border: 1px solid #FF711E;
    background: #FFF;
    width: 155px;
    height: 44px;
    color: #FF711E;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    /* &:hover{
        background: #0059B2;
    }

    &:active{
        background: #0052A3;
    } */
`