const languages = [
  {
    "code": "aa",
    "name": "Afar"
  },
  {
    "code": "ab",
    "name": "Abkhazian"
  },
  {
    "code": "af",
    "name": "Afrikaans"
  },
  {
    "code": "am",
    "name": "Amharic"
  },
  {
    "code": "ar",
    "name": "Arabic"
  },
  {
    "code": "ar-ae",
    "name": "Arabic (U.A.E.)"
  },
  {
    "code": "ar-bh",
    "name": "Arabic (Bahrain)"
  },
  {
    "code": "ar-dz",
    "name": "Arabic (Algeria)"
  },
  {
    "code": "ar-eg",
    "name": "Arabic (Egypt)"
  },
  {
    "code": "ar-iq",
    "name": "Arabic (Iraq)"
  },
  {
    "code": "ar-jo",
    "name": "Arabic (Jordan)"
  },
  {
    "code": "ar-kw",
    "name": "Arabic (Kuwait)"
  },
  {
    "code": "ar-lb",
    "name": "Arabic (Lebanon)"
  },
  {
    "code": "ar-ly",
    "name": "Arabic (libya)"
  },
  {
    "code": "ar-ma",
    "name": "Arabic (Morocco)"
  },
  {
    "code": "ar-om",
    "name": "Arabic (Oman)"
  },
  {
    "code": "ar-qa",
    "name": "Arabic (Qatar)"
  },
  {
    "code": "ar-sa",
    "name": "Arabic (Saudi Arabia)"
  },
  {
    "code": "ar-sy",
    "name": "Arabic (Syria)"
  },
  {
    "code": "ar-tn",
    "name": "Arabic (Tunisia)"
  },
  {
    "code": "ar-ye",
    "name": "Arabic (Yemen)"
  },
  {
    "code": "as",
    "name": "Assamese"
  },
  {
    "code": "ay",
    "name": "Aymara"
  },
  {
    "code": "az",
    "name": "Azeri"
  },
  {
    "code": "ba",
    "name": "Bashkir"
  },
  {
    "code": "be",
    "name": "Belarusian"
  },
  {
    "code": "bg",
    "name": "Bulgarian"
  },
  {
    "code": "bh",
    "name": "Bihari"
  },
  {
    "code": "bi",
    "name": "Bislama"
  },
  {
    "code": "bn",
    "name": "Bengali"
  },
  {
    "code": "bo",
    "name": "Tibetan"
  },
  {
    "code": "br",
    "name": "Breton"
  },
  {
    "code": "ca",
    "name": "Catalan"
  },
  {
    "code": "co",
    "name": "Corsican"
  },
  {
    "code": "cs",
    "name": "Czech"
  },
  {
    "code": "cy",
    "name": "Welsh"
  },
  {
    "code": "da",
    "name": "Danish"
  },
  {
    "code": "de",
    "name": "German"
  },
  {
    "code": "de-at",
    "name": "German (Austria)"
  },
  {
    "code": "de-ch",
    "name": "German (Switzerland)"
  },
  {
    "code": "de-li",
    "name": "German (Liechtenstein)"
  },
  {
    "code": "de-lu",
    "name": "German (Luxembourg)"
  },
  {
    "code": "div",
    "name": "Divehi"
  },
  {
    "code": "dz",
    "name": "Bhutani"
  },
  {
    "code": "el",
    "name": "Greek"
  },
  {
    "code": "en",
    "name": "English"
  },
  {
    "code": "en-au",
    "name": "English (Australia)"
  },
  {
    "code": "en-bz",
    "name": "English (Belize)"
  },
  {
    "code": "en-ca",
    "name": "English (Canada)"
  },
  {
    "code": "en-gb",
    "name": "English (United Kingdom)"
  },
  {
    "code": "en-ie",
    "name": "English (Ireland)"
  },
  {
    "code": "en-jm",
    "name": "English (Jamaica)"
  },
  {
    "code": "en-nz",
    "name": "English (New Zealand)"
  },
  {
    "code": "en-ph",
    "name": "English (Philippines)"
  },
  {
    "code": "en-tt",
    "name": "English (Trinidad)"
  },
  {
    "code": "en-us",
    "name": "English (United States)"
  },
  {
    "code": "en-za",
    "name": "English (South Africa)"
  },
  {
    "code": "en-zw",
    "name": "English (Zimbabwe)"
  },
  {
    "code": "eo",
    "name": "Esperanto"
  },
  {
    "code": "es",
    "name": "Spanish"
  },
  {
    "code": "es-ar",
    "name": "Spanish (Argentina)"
  },
  {
    "code": "es-bo",
    "name": "Spanish (Bolivia)"
  },
  {
    "code": "es-cl",
    "name": "Spanish (Chile)"
  },
  {
    "code": "es-co",
    "name": "Spanish (Colombia)"
  },
  {
    "code": "es-cr",
    "name": "Spanish (Costa Rica)"
  },
  {
    "code": "es-do",
    "name": "Spanish (Dominican Republic)"
  },
  {
    "code": "es-ec",
    "name": "Spanish (Ecuador)"
  },
  {
    "code": "es-es",
    "name": "Spanish (España)"
  },
  {
    "code": "es-gt",
    "name": "Spanish (Guatemala)"
  },
  {
    "code": "es-hn",
    "name": "Spanish (Honduras)"
  },
  {
    "code": "es-mx",
    "name": "Spanish (Mexico)"
  },
  {
    "code": "es-ni",
    "name": "Spanish (Nicaragua)"
  },
  {
    "code": "es-pa",
    "name": "Spanish (Panama)"
  },
  {
    "code": "es-pe",
    "name": "Spanish (Peru)"
  },
  {
    "code": "es-pr",
    "name": "Spanish (Puerto Rico)"
  },
  {
    "code": "es-py",
    "name": "Spanish (Paraguay)"
  },
  {
    "code": "es-sv",
    "name": "Spanish (El Salvador)"
  },
  {
    "code": "es-us",
    "name": "Spanish (United States)"
  },
  {
    "code": "es-uy",
    "name": "Spanish (Uruguay)"
  },
  {
    "code": "es-ve",
    "name": "Spanish (Venezuela)"
  },
  {
    "code": "et",
    "name": "Estonian"
  },
  {
    "code": "eu",
    "name": "Basque"
  },
  {
    "code": "fa",
    "name": "Farsi"
  },
  {
    "code": "fi",
    "name": "Finnish"
  },
  {
    "code": "fj",
    "name": "Fiji"
  },
  {
    "code": "fo",
    "name": "Faeroese"
  },
  {
    "code": "fr",
    "name": "French"
  },
  {
    "code": "fr-be",
    "name": "French (Belgium)"
  },
  {
    "code": "fr-ca",
    "name": "French (Canada)"
  },
  {
    "code": "fr-ch",
    "name": "French (Switzerland)"
  },
  {
    "code": "fr-lu",
    "name": "French (Luxembourg)"
  },
  {
    "code": "fr-mc",
    "name": "French (Monaco)"
  },
  {
    "code": "fy",
    "name": "Frisian"
  },
  {
    "code": "ga",
    "name": "Irish"
  },
  {
    "code": "gd",
    "name": "Gaelic"
  },
  {
    "code": "gl",
    "name": "Galician"
  },
  {
    "code": "gn",
    "name": "Guarani"
  },
  {
    "code": "gu",
    "name": "Gujarati"
  },
  {
    "code": "ha",
    "name": "Hausa"
  },
  {
    "code": "he",
    "name": "Hebrew"
  },
  {
    "code": "hi",
    "name": "Hindi"
  },
  {
    "code": "hr",
    "name": "Croatian"
  },
  {
    "code": "hu",
    "name": "Hungarian"
  },
  {
    "code": "hy",
    "name": "Armenian"
  },
  {
    "code": "ia",
    "name": "Interlingua"
  },
  {
    "code": "id",
    "name": "Indonesian"
  },
  {
    "code": "ie",
    "name": "Interlingue"
  },
  {
    "code": "ik",
    "name": "Inupiak"
  },
  {
    "code": "in",
    "name": "Indonesian"
  },
  {
    "code": "is",
    "name": "Icelandic"
  },
  {
    "code": "it",
    "name": "Italian"
  },
  {
    "code": "it-ch",
    "name": "Italian (Switzerland)"
  },
  {
    "code": "iw",
    "name": "Hebrew"
  },
  {
    "code": "ja",
    "name": "Japanese"
  },
  {
    "code": "ji",
    "name": "Yiddish"
  },
  {
    "code": "jw",
    "name": "Javanese"
  },
  {
    "code": "ka",
    "name": "Georgian"
  },
  {
    "code": "kk",
    "name": "Kazakh"
  },
  {
    "code": "kl",
    "name": "Greenlandic"
  },
  {
    "code": "km",
    "name": "Cambodian"
  },
  {
    "code": "kn",
    "name": "Kannada"
  },
  {
    "code": "ko",
    "name": "Korean"
  },
  {
    "code": "kok",
    "name": "Konkani"
  },
  {
    "code": "ks",
    "name": "Kashmiri"
  },
  {
    "code": "ku",
    "name": "Kurdish"
  },
  {
    "code": "ky",
    "name": "Kirghiz"
  },
  {
    "code": "kz",
    "name": "Kyrgyz"
  },
  {
    "code": "la",
    "name": "Latin"
  },
  {
    "code": "ln",
    "name": "Lingala"
  },
  {
    "code": "lo",
    "name": "Laothian"
  },
  {
    "code": "ls",
    "name": "Slovenian"
  },
  {
    "code": "lt",
    "name": "Lithuanian"
  },
  {
    "code": "lv",
    "name": "Latvian"
  },
  {
    "code": "mg",
    "name": "Malagasy"
  },
  {
    "code": "mi",
    "name": "Maori"
  },
  {
    "code": "mk",
    "name": "FYRO Macedonian"
  },
  {
    "code": "ml",
    "name": "Malayalam"
  },
  {
    "code": "mn",
    "name": "Mongolian"
  },
  {
    "code": "mo",
    "name": "Moldavian"
  },
  {
    "code": "mr",
    "name": "Marathi"
  },
  {
    "code": "ms",
    "name": "Malay"
  },
  {
    "code": "mt",
    "name": "Maltese"
  },
  {
    "code": "my",
    "name": "Burmese"
  },
  {
    "code": "na",
    "name": "Nauru"
  },
  {
    "code": "nb-no",
    "name": "Norwegian (Bokmal)"
  },
  {
    "code": "ne",
    "name": "Nepali (India)"
  },
  {
    "code": "nl",
    "name": "Dutch"
  },
  {
    "code": "nl-be",
    "name": "Dutch (Belgium)"
  },
  {
    "code": "nn-no",
    "name": "Norwegian"
  },
  {
    "code": "no",
    "name": "Norwegian (Bokmal)"
  },
  {
    "code": "oc",
    "name": "Occitan"
  },
  {
    "code": "om",
    "name": "(Afan)/Oromoor/Oriya"
  },
  {
    "code": "or",
    "name": "Oriya"
  },
  {
    "code": "pa",
    "name": "Punjabi"
  },
  {
    "code": "pl",
    "name": "Polish"
  },
  {
    "code": "ps",
    "name": "Pashto/Pushto"
  },
  {
    "code": "pt",
    "name": "Portuguese"
  },
  {
    "code": "pt-br",
    "name": "Portuguese (Brazil)"
  },
  {
    "code": "qu",
    "name": "Quechua"
  },
  {
    "code": "rm",
    "name": "Rhaeto-Romanic"
  },
  {
    "code": "rn",
    "name": "Kirundi"
  },
  {
    "code": "ro",
    "name": "Romanian"
  },
  {
    "code": "ro-md",
    "name": "Romanian (Moldova)"
  },
  {
    "code": "ru",
    "name": "Russian"
  },
  {
    "code": "ru-md",
    "name": "Russian (Moldova)"
  },
  {
    "code": "rw",
    "name": "Kinyarwanda"
  },
  {
    "code": "sa",
    "name": "Sanskrit"
  },
  {
    "code": "sb",
    "name": "Sorbian"
  },
  {
    "code": "sd",
    "name": "Sindhi"
  },
  {
    "code": "sg",
    "name": "Sangro"
  },
  {
    "code": "sh",
    "name": "Serbo-Croatian"
  },
  {
    "code": "si",
    "name": "Singhalese"
  },
  {
    "code": "sk",
    "name": "Slovak"
  },
  {
    "code": "sl",
    "name": "Slovenian"
  },
  {
    "code": "sm",
    "name": "Samoan"
  },
  {
    "code": "sn",
    "name": "Shona"
  },
  {
    "code": "so",
    "name": "Somali"
  },
  {
    "code": "sq",
    "name": "Albanian"
  },
  {
    "code": "sr",
    "name": "Serbian"
  },
  {
    "code": "ss",
    "name": "Siswati"
  },
  {
    "code": "st",
    "name": "Sesotho"
  },
  {
    "code": "su",
    "name": "Sundanese"
  },
  {
    "code": "sv",
    "name": "Swedish"
  },
  {
    "code": "sv-fi",
    "name": "Swedish (Finland)"
  },
  {
    "code": "sw",
    "name": "Swahili"
  },
  {
    "code": "sx",
    "name": "Sutu"
  },
  {
    "code": "syr",
    "name": "Syriac"
  },
  {
    "code": "ta",
    "name": "Tamil"
  },
  {
    "code": "te",
    "name": "Telugu"
  },
  {
    "code": "tg",
    "name": "Tajik"
  },
  {
    "code": "th",
    "name": "Thai"
  },
  {
    "code": "ti",
    "name": "Tigrinya"
  },
  {
    "code": "tk",
    "name": "Turkmen"
  },
  {
    "code": "tl",
    "name": "Tagalog"
  },
  {
    "code": "tn",
    "name": "Tswana"
  },
  {
    "code": "to",
    "name": "Tonga"
  },
  {
    "code": "tr",
    "name": "Turkish"
  },
  {
    "code": "ts",
    "name": "Tsonga"
  },
  {
    "code": "tt",
    "name": "Tatar"
  },
  {
    "code": "tw",
    "name": "Twi"
  },
  {
    "code": "uk",
    "name": "Ukrainian"
  },
  {
    "code": "ur",
    "name": "Urdu"
  },
  {
    "code": "us",
    "name": "English"
  },
  {
    "code": "uz",
    "name": "Uzbek"
  },
  {
    "code": "vi",
    "name": "Vietnamese"
  },
  {
    "code": "vo",
    "name": "Volapuk"
  },
  {
    "code": "wo",
    "name": "Wolof"
  },
  {
    "code": "xh",
    "name": "Xhosa"
  },
  {
    "code": "yi",
    "name": "Yiddish"
  },
  {
    "code": "yo",
    "name": "Yoruba"
  },
  {
    "code": "zh",
    "name": "Chinese"
  },
  {
    "code": "zh-cn",
    "name": "Chinese (China)"
  },
  {
    "code": "zh-hk",
    "name": "Chinese (Hong Kong SAR)"
  },
  {
    "code": "zh-mo",
    "name": "Chinese (Macau SAR)"
  },
  {
    "code": "zh-sg",
    "name": "Chinese (Singapore)"
  },
  {
    "code": "zh-tw",
    "name": "Chinese (Taiwan)"
  },
  {
    "code": "zu",
    "name": "Zulu"
  }
]

export default languages