import React, { useRef, useEffect, useState } from 'react'
import {
    useMutation
} from "@tanstack/react-query"
import NoResultsLogo from "../../../../../../../media/Dashboard/noresults.svg"
import { useRequestData } from "../../../../../../hooks/useRequestData"
import ConfettiExplosion from 'react-confetti-explosion';

// Styles
import {
    FooterRight,
    AdDate,
    FooterLeft,
    CardFooter,
    CardBody,
    FloatingButtonsContainer,
    Card,
    CardsList,
    ResultsContainer,
    ResultsWraper,
    VideoContainer,
    ImageContainer,
    ChannelDetails,
    LogoContainer,
    ChannelName,
    Description,
    SecondaryDetails,
    InnerContent,
    InnerDesc,
    InnerTitle,
    CallToActionButton,
    CTA,
    NoResults,
    RequestDataBtn,
    InnerUrl,
    BackToSearchContainer,
    AdCount,
    AdCountWrap,
    CrawlRequestGreetContainer,
    ContentContainer
} from "./index.styled"
import SearchLoader from '../../../../../../HOC/SearchLoader'

import GlobeIcon from '../../../../../../../media/Dashboard/globe.svg';
import { useLinkedin } from "../../context/linkedin"
import TickImg from '../../../../../../../media/Dashboard/tick-grey.svg'

//Icons

import CrawlSuccessTick from "../../../../../../../media/Dashboard/CrawlSuccessTick.svg"

import AltImage from '../../../../../../../media/Dashboard/altimage.png'
import { appClient } from '../../../../../../../utils/axios-utils'
import LimitExceededPopup from '../../../../../../components/limitExceededPopup'
import DateIcon from "../../../../../../../media/Images/DateIcon.svg"
import PlatformRestrictedPopup from '../../../../../../components/platformRestrictionPopup'
import { useAuth } from '../../../../../../../context/auth'
import { useSearch } from '../../../context/search'
import AdDetails from '../../../../../../components/AdDetailsModal'
import Pagination from '../Pagination'
import { useNavigate } from 'react-router-dom'

const adDetails = async (id) => {
    const config = {
        url: `/detail/linkedin/${id}`,
        method: "GET"
    }

    return appClient(config)
}

const convertTimestamp = (timestamp) => {
    if (!timestamp) {
        return "-"
    }
    // Create a new Date object based on the timestamp
    var date = new Date(Number(timestamp));

    // Get the month name
    var monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    var month = monthNames[date.getMonth()];

    // Get the year
    var year = date.getFullYear();

    // Assemble the formatted date string
    var formattedDate = date.getDate() + ' ' + month + ' ' + year;

    return formattedDate;
}

const CardElement = ({ ad, idx, basicAdDetails, setBasicAdDetails, containerRef }) => {

    const { linkedinState, linkedinDispatch } = useLinkedin()
    const { authState, authDispatch } = useAuth()

    const scrollToElement = (index) => {
        const element = containerRef.current.children[index];
        const scrollPosition = element.offsetTop - 200;
        containerRef.current.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    };

    const { mutate: adsDetailsMutate, isLoading: isDetailsLoading } = useMutation(adDetails, {
        onSuccess: (data) => {
            linkedinDispatch({ type: 'SET_AD_DETAILS', value: data })
            if (linkedinState.adDetails.id == data.id || !linkedinState.adDetails.id) {
                linkedinDispatch({ type: 'UPDATE_DETAILS_MODAL', value: !linkedinState.detailsModal })
            }
        },
        onError: ((e) => {
            console.log(e)
        })
    })

    const toggleDetailsModal = (ad, idx) => {
        if ((linkedinState.adDetails.id !== ad.id) && linkedinState.detailsModal) {
            //if details is open and clicked on close button on ads card
            linkedinDispatch({ type: 'SET_AD_DETAILS', value: ad })
        } else {
            linkedinDispatch({ type: 'SET_AD_DETAILS', value: ad })
            linkedinDispatch({ type: 'UPDATE_DETAILS_MODAL', value: !linkedinState.detailsModal })
        }
        setTimeout(() => {
            scrollToElement(idx)
        }, 100);
    }

    const onSave = (ad) => {
        authDispatch({ type: 'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'meta' })
        authDispatch({ type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id })
        authDispatch({ type: 'UPDATE_SAVE_AD_POPUP', value: true })
    }

    return <Card detailsOpen={linkedinState.adDetails.id == ad.id} key={
        ad.id
    }>
        {/* <HoverCard className='hover-element' onClick={() => toggleDetailsModal(ad, idx)
        }>
            <HoverCardWrapper>
                <AdDetailsButton onClick={() => toggleDetailsModal(ad, idx)}>
                    <img src={RightUpperBlackArrow} />
                    <span>Ad Details</span>
                </AdDetailsButton>
                <SaveAdsButton onClick={() => onSave(ad)
                }>
                    Save
                </SaveAdsButton>
            </HoverCardWrapper>
        </HoverCard> */}
        <CardBody>
            <ChannelDetails>
                <LogoContainer>
                    <img src={ad.logo ?? GlobeIcon} />
                </LogoContainer>
                <ChannelName>
                    {ad.title}
                    <span>Sponsored</span>
                </ChannelName>
            </ChannelDetails>
            <Description>
                {ad.content}
            </Description>
            <FloatingButtonsContainer>
                {/* <Favorite isFav={idx == 1} onClick={() => {
                    authDispatch({ type: 'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'linkedin' })
                    authDispatch({ type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id })
                    authDispatch({ type: 'UPDATE_SAVE_AD_POPUP', value: true })
                }}>
                    <img src={authState.favAdsList["linkedin"]?.includes(ad.id) ? FavouriteIconActive : FavorietIcon} />
                </Favorite> */}
                {/* |
                <DropdownIcon>
                    <span/>
                </DropdownIcon> */}
            </FloatingButtonsContainer>
            {ad.mediaContent?.length > 0 && ad.mediaContent[0].imageUrl && <ImageContainer>
                <img src={ad.mediaContent[0].imageUrl} />
            </ImageContainer>}
            {ad.mediaContent?.length > 0 && ad.mediaContent[0].videoUrl && <VideoContainer>
                <video src={ad.mediaContent[0].videoUrl} preload="auto" controls loop style={{ width: "100%", height: "100%" }} />
            </VideoContainer>}
            {(ad.mediaContent?.length == 0) && <ImageContainer noImage={true}>
                <img src={AltImage} />
            </ImageContainer>}
            <SecondaryDetails>
                <InnerContent>
                    <InnerUrl>
                        {ad.ctaUrl}
                    </InnerUrl>
                    <InnerDesc>
                        {ad.ctaTitle}
                    </InnerDesc>
                    <InnerTitle>
                        {ad.ctaContent}
                    </InnerTitle>
                </InnerContent>
                <CallToActionButton>
                    {ad.ctaButton && <CTA>
                        {ad.ctaButton}
                    </CTA>}
                </CallToActionButton>
            </SecondaryDetails>
        </CardBody>

        <CardFooter>
            <FooterLeft>
                <AdDate>
                    {ad.dateRange && <img src={DateIcon} />}
                    {/* {convertTimestamp(ad.firstSeen)} -  */}
                    {ad.dateRange ? ad.dateRange.slice("to")[1] : null}
                </AdDate>
                {/* <AdCountries>
                    {ad.country && ad.country.length > 0 && <img src={CountryIcon} />}
                    {ad.country?.length == 1 ? ad.country[0] : ad.country?.length > 1 ? ad.country[0] + " + " + (ad.country?.length - 1) : ""}
                </AdCountries> */}
            </FooterLeft>
            <FooterRight>
                {/* <DetailsButton onClick={()=>toggleDetailsModal(ad,idx)}>
                {(linkedinState.adDetails.id == ad.id) && linkedinState.detailsModal ? "Close details" : "More details"}
                </DetailsButton> */}
            </FooterRight>
        </CardFooter>
    </Card>
}

const CrawlRequestResults = () => {
    const { linkedinState, linkedinDispatch } = useLinkedin()
    const [basicAdDetails, setBasicAdDetails] = useState({})
    const useRequestDataHook = useRequestData()
    const navigate = useNavigate()
    const [ greetingScreenOpen, setGreetingScreenOpen ] = useState(true)
    const { authState, authDispatch } = useAuth()
    const { searchState, searchDispatch } = useSearch()

    const containerRef = useRef(null);

    const getSearchTerm = () => {
        const { phraseSearchIn, broadSearchIn} = linkedinState
        let fields

        if(searchState.keywordSearchType == "phrase"){
            fields = phraseSearchIn.filter((obj) => {
                return obj.checked && obj.value
            }).map((obj) => obj.value)
        }else{
            fields = broadSearchIn.filter((obj) => {
                return obj.checked && obj.value
            }).map((obj) => obj.value)
        }
        
        if(fields.length == 0){
            return linkedinDispatch({type: 'SET_SEARCH_IN_ERROR', value: true})
        }

        let paramSearchIn = fields.join(",")

        return paramSearchIn
    }
    const onDataRequest = () => {
        if (!useRequestDataHook.dataRequested) {
            useRequestDataHook.requestDataMutate({ id: linkedinState.queryId })
        }
    }

    const onDetailsClose = () => {
        setBasicAdDetails({})
        linkedinDispatch({ type: 'SET_AD_DETAILS', value: {} })
        linkedinDispatch({ type: 'UPDATE_DETAILS_MODAL', value: false })
    }

    function findIndexById(arr, searchId) {
        for (let i = 0; i < linkedinState.ads.length; i++) {
            if (arr[i].id === searchId) {
                return i; // Return the index when the ID is found
            }
        }
        return -1; // Return -1 if ID is not found in the array
    }

    const navigateTo = (adId, toAd) => {
        console.log("navigateTo", adId, toAd)
        let adIndex = findIndexById(adId)
        console.log(adIndex)
        if (adIndex != -1) {
            if (toAd == "next") {
                console.log("inside next")
                linkedinDispatch({ type: 'UPDATE_DETAILS_MODAL', value: true })
                setBasicAdDetails(linkedinState.ads[adIndex + 1])
                linkedinDispatch({ type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: true })
            } else if (toAd == "back") {
                console.log("inside back")
                linkedinDispatch({ type: 'UPDATE_DETAILS_MODAL', value: true })
                setBasicAdDetails(linkedinState.ads[adIndex - 1])
                linkedinDispatch({ type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: true })
            }
        }
    }

    const onSave = (ad) => {
        authDispatch({ type: 'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'meta' })
        authDispatch({ type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id })
        authDispatch({ type: 'UPDATE_SAVE_AD_POPUP', value: true })
    }

    useEffect(() => {
        setTimeout(() => {
            setGreetingScreenOpen(false)
        }, 2000);
    }, [])

    if (authState.isRestrictedPopup) {
        return <ResultsContainer restricted={true} detailsModal={linkedinState.detailsModal}>
            <PlatformRestrictedPopup err={searchState.searchError} />
        </ResultsContainer>
    }

    if (searchState.limitExceeded) {
        return <ResultsContainer restricted={true} detailsModal={linkedinState.detailsModal}>
            <LimitExceededPopup err={searchState.searchError} />
        </ResultsContainer>
    }

    return <ResultsContainer resultLoading={linkedinState.loadingResults} noResults={linkedinState.noResults} detailsModal={linkedinState.detailsModal}>
        {greetingScreenOpen ? 
        <CrawlRequestGreetContainer>
            <img src={CrawlSuccessTick}/>
            <h3>Results Found<ConfettiExplosion />  </h3>
            <div>
                Great news! 🎉 . We've found results matching
                your search query “<span>{searchState.keywordSearchType == "phrase" ? searchState.phraseInput : searchState.keywordSearchType == "broad" ? searchState.keywords.join(" "): ""}</span>”.
            </div>
        </CrawlRequestGreetContainer> : <ContentContainer greetingScreenOpen={greetingScreenOpen}>
        <BackToSearchContainer>
            Your persistence paid off! Keep exploring or try another <span onClick={()=>{
                        navigate('/adspy')
                }}>Search</span>
        </BackToSearchContainer>
        <h2>Text Search</h2>
        <AdCount>
            <AdCountWrap>
                Over <span>{
                    linkedinState.totalAds
                }</span> resuls for <p>"{searchState.keywordSearchType == "phrase" ? searchState.phraseInput : searchState.keywordSearchType == "broad" ? searchState.keywords.join(" "): ""}"</p>
            </AdCountWrap>
        </AdCount>
        {linkedinState.loadingResults ? <div className='loader'>
        <SearchLoader/>
        </div> :  linkedinState.noResults ? <NoResults>
                        <img src={NoResultsLogo}/>
                        <h2>No Results Found</h2>
                        <p>We're sorry, but we don't currently have the data matching your search parameters. Our database is constantly updated, so please try again later or try modifying your search.</p>
                        <RequestDataBtn dataRequested={useRequestDataHook.dataRequested ? useRequestDataHook.dataRequested : ""} disable={useRequestDataHook.isRequestDataLoading} onClick={onDataRequest}>{useRequestDataHook.dataRequested ? <p>Your request for data crawl has been accepted<img src={TickImg}/></p> : <p>{useRequestDataHook.isRequestDataLoading ? "Requesting...": "Request data crawl for this query"}</p>}</RequestDataBtn>
            </NoResults>  : <ResultsWraper>
            {/* {authState.saveAdsPopupOpen && <SaveAdsPopup noMarginTop={true}/>} */}
            <CardsList ref={containerRef} detailsModal={linkedinState.detailsModal}> {
                linkedinState.ads && linkedinState.ads.length > 0 && linkedinState.ads.map((ad,idx) => {
                    return <CardElement containerRef={containerRef} basicAdDetails={basicAdDetails} setBasicAdDetails={setBasicAdDetails} ad={ad} idx={idx}/>
                })
            } </CardsList>
            {
                linkedinState.detailsModal && 
                <AdDetails
                    navigateTo={navigateTo}
                    platform="meta"
                    crawledAd={true} 
                    basicDetail={linkedinState.adDetails} 
                    onDetailsClose={onDetailsClose} 
                    highlightWords={linkedinState.highlightWords} 
                    onSave={onSave}
                />
            }
             {
                    !searchState.limitExceeded && linkedinState.ads && linkedinState.ads.length > 0 && <Pagination/>
                } 
        </ResultsWraper>
        
        }
               
        </ContentContainer>}
    </ResultsContainer>
}

export default CrawlRequestResults
