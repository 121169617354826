import styled from "styled-components";

export const DescriptionListContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    height: 65%;
    overflow-y: auto;
`;

export const Description = styled.div`
    width: 356px;
    // height: 92px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid #DADFE4;
    background: #FFF;

    color: #1C1C1C;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px 32px;

    position: relative;

    cursor: pointer;
`;

export const CopyButtonContainer = styled.div`
    height: 36px;
    width: 36px;
    border-radius: 30px;
    border: 1px solid #DADFE4;
    background: #FFF;
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 12px;
    top: -18px;
`;

export const Copy = styled.img`

`;