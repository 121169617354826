import React, { useState } from "react";
import { Wrapper, HeadingDataContainer } from "./index.styled";
import { useAdGenerator } from "../../../../../../../context/adGeneration";

const Texts = () => {
  const [textData, setTextData] = useState([
    {
      name: "Heading 1",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a venenatis ligula. Sed id lacus lobortis, varius libero vitae, tristique justo.",
    },
    {
      name: "Heading 2",
      content: "Lorem ipsum dolor sit amet.",
    },
    {
      name: "Heading 3",
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
  ]);
  const [inputFocussed, setInputFocussed] = useState(null);

  const {
    adGeneratorState: { inputFields, updatetemplate },
    adGeneratorDispatch,
  } = useAdGenerator();

  const OnHeadngChange = (event, { uniqueId }) => {
    const updatedContent = event.target.value;

    let htmlContent = document.querySelector(".outerHtml");
    htmlContent
      .querySelectorAll(`[data-field-id="${uniqueId}"]`)
      .forEach((element) => {
        element.textContent = updatedContent;

        let clonedHtmlContent = htmlContent.cloneNode(true);
        // Remove unwanted xmlns attribute (if exists)
        // clonedHtmlContent.removeAttribute("xmlns");
        // Serialize only the inner HTML if needed
        const modifiedHtml = clonedHtmlContent.innerHTML;
        adGeneratorDispatch({
          type: "SET_UPDATE_TEMPLATE",
          value: modifiedHtml,
        });
 
      });
  };
  return (
    <Wrapper>
      {inputFields.map((headingData, index) => {
        return headingData.tagName?.toLowerCase() === "img" ||
          headingData.attributeName === "input-bg" ? (
          ""
        ) : (
          <HeadingDataContainer
            isFocussed={inputFocussed === headingData.uniqueId}
          >
            <label>{headingData.attributeValue}</label>
            <textarea
              placeholder="Enter text here"
              type="text"
              defaultValue={headingData.content}
              onChange={(e) => OnHeadngChange(e, headingData)}
              onFocus={() => setInputFocussed(headingData.uniqueId)}
              onBlur={() => setInputFocussed(null)}
              minLength={headingData.minCharacters}
              maxLength={headingData.maxCharacters}

            />
          </HeadingDataContainer>
        );
      })}
    </Wrapper>
  );
};

export default Texts;
