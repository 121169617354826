import React from 'react'
import { TotalCamp, TotalCount, TotalCountWrap, TotalDiv, ActiveGraph, TotalWrapper, UpdatedHour } from './index.styled'
import { TotalAdsAreaChart } from './AreaChart/index'
import { useDomainOverview } from '../../../context/domainoverview'
import Skeleton from "react-loading-skeleton";
import Tooltip from 'rc-tooltip';
import helpCircle from '../../../../../../media/Dashboard/help-circle.svg'

const TotalAds = ({isTableDataLoading}) => {
    const {domainOverviewState, domainOverviewDispatch} = useDomainOverview()

    return <TotalWrapper>
            <TotalDiv>
                <TotalCamp>Total Active ads<Tooltip trigger={['hover']} overlayStyle={{ width: "100" }} overlay={<span>Total active ads that were found in last few weeks.</span>}>
                <img src={helpCircle}/>     
                    </Tooltip></TotalCamp>
                {isTableDataLoading ? 
                    <> 
                        <Skeleton height={"45px"} width= {'203px'}/> 
                        <Skeleton height={"20px"} width= {'203px'} style={{'marginTop': '10px'}}/> 
                    </>
                    :
                    <>
                    <TotalCountWrap>
                        <TotalCount>{domainOverviewState?.activeCampaigns?.totalCampaignAds}</TotalCount>
                        {/* <TotalPercent><img src={greenArrow}/>12%</TotalPercent> */}
                    </TotalCountWrap>
                    <UpdatedHour>updated just now</UpdatedHour>
                    </>
                }
            </TotalDiv>
            <ActiveGraph>
                <TotalAdsAreaChart />
            </ActiveGraph>
        </TotalWrapper>
}

export default TotalAds