import styled from "styled-components";

export const Container = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: #0000004d;
    z-index: 6;
    top: 0px;
    display: flex;
    justify-content: flex-end;
`; 

export const FilterBody = styled.div`
    width: 320px;
    background: #FFF;
`; 

export const Header = styled.div`
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    background: #FFF7E9;
    img{
        cursor: pointer;
    }
    h2{
        font-size: 14px;
        font-weight: 500;
        color: rgba(28, 28, 28, 0.90);
        line-height: 150%;
    }
`; 

export const FilterContent = styled.div`
    padding: 24px;
    height: calc(100vh - 120px);
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    gap: 24px;
`; 

export const FilterFooter = styled.div`
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 22px;

    border-top: 1px solid #CFD0D1;
    background: #FFF;
`; 

export const ClearAllBtn = styled.button`
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    color: #6C95E4;
    border: none;
    outline: none;
    background: none;
`;

export const ApplyFiltersBtn = styled.button`
    border-radius: 3px;
    background: #FF711E;
    width: 108px;
    height: 26px;
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    color: #FFF;
    border: none;
    outline: none;
`;


export const CtaInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    label{
        font-size: 14px;
        font-weight: 400;
        line-height: 15px;
        color: rgba(28, 28, 28, 0.70);
    }
    /* input{
        border-radius: 4px;
        border: 1px solid #DADFE4;
        font-size: 13px;
        background: #FFF;
        padding: 4px 12px;
        width: 214px;
        height: 28px;
        outline: none;
    } */
`;

export const CategoryInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    label{
        font-size: 14px;
        font-weight: 400;
        line-height: 15px;
        color: rgba(28, 28, 28, 0.70);
    }
    input{
        border-radius: 4px;
        border: 1px solid #DADFE4;
        font-size: 13px;
        background: #FFF;
        padding: 4px 12px;
        width: 214px;
        height: 28px;
        outline: none;
    }
`;


export const SentimentInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    label{
        font-size: 14px;
        font-weight: 400;
        line-height: 15px;
        color: rgba(28, 28, 28, 0.70);
    }
`;

export const AdSentimentDropDownContainer = styled.div`
    width: 185px;
    position: relative;
`;

export const SelectedSentiment = styled.div`
    border-radius: 4px;
    border: 1px solid #DADFE4;
    background: #FFF;
    width: 185px;
    height: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 12px;
    cursor: pointer;
    p{
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.13px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`;

export const AdSentimentDropDown = styled.div`
    position: absolute;
    width: 100%;
    top: 32px;
    z-index: 5;
    border-radius: 4px;
    border: 1px solid #CFD0D1;
    background: #FFF;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.20);
    
    max-height: 200px;
    overflow-y: auto;
;`

export const SentimentOption = styled.div`
    padding: 8px 12px;
    border-bottom: solid 1px #DADFE4;
    cursor: pointer;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.13px;
`;



export const ToneInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    label{
        font-size: 14px;
        font-weight: 400;
        line-height: 15px;
        color: rgba(28, 28, 28, 0.70);
    }
`;

export const AdToneDropDownContainer = styled.div`
    width: 185px;
    position: relative;
`;

export const SelectedTone = styled.div`
    border-radius: 4px;
    border: 1px solid #DADFE4;
    background: #FFF;
    width: 185px;
    height: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 12px;
    cursor: pointer;
    p{
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.13px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`;

export const AdToneDropDown = styled.div`
    position: absolute;
    width: 100%;
    top: 32px;
    z-index: 5;
    border-radius: 4px;
    border: 1px solid #CFD0D1;
    background: #FFF;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.20);

    max-height: 200px;
    overflow-y: auto;
;`

export const ToneOption = styled.div`
    padding: 8px 12px;
    border-bottom: solid 1px #DADFE4;
    cursor: pointer;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.13px;

    display: flex;
    align-items: center;
    gap: 5px;

    label{
        width: 134px;
        cursor: pointer;
    }
`;

export const OtherFiltersContainer = styled.div`
    label{
        font-size: 14px;
        font-weight: 500;
        line-height: 15px;
        color: rgba(28, 28, 28, 0.70);
    }
`;

export const OtherFilters = styled.div`
    padding: 16px 0px;
    display: flex;
    gap: 16px;
    flex-direction: column;
`;

export const IndividualOtherFilter = styled.div`
    display: flex;
    gap: 12px;
    cursor: pointer;
    label{
        font-size: 14p;
        font-weight: 400;
        line-height: 15px;
        color: rgba(28, 28, 28, 0.70);
        cursor: pointer;
        
    }
`;

export const CheckBox = styled.input`
    width: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
    accent-color: #ff6200;
    cursor: pointer;
    :checked:before{
        background-color:green;
    }
`;

export const CtaDropDownContainer = styled.div`
    width: 185px;
    position: relative;
`;

export const SelectedCta = styled.div`
    border-radius: 4px;
    border: 1px solid #DADFE4;
    background: #FFF;
    width: 185px;
    height: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 12px;
    cursor: pointer;
    p{
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.13px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`;

export const CtaDropDown = styled.div`
    position: absolute;
    width: 100%;
    top: 32px;
    z-index: 5;
    border-radius: 4px;
    border: 1px solid #CFD0D1;
    background: #FFF;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.20);

    max-height: 200px;
    overflow-y: auto;

    label{
        width: 134px;
        cursor: pointer;
    }
`;

export const CtaOption = styled.div`
    padding: 8px 12px;
    border-bottom: solid 1px #DADFE4;
    cursor: pointer;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.13px;

    display: flex;
    align-items: center;
    gap: 5px;
`;











export const LanguageInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    label{
        font-size: 14px;
        font-weight: 400;
        line-height: 15px;
        color: rgba(28, 28, 28, 0.70);
    }
    /* input{
        border-radius: 4px;
        border: 1px solid #DADFE4;
        font-size: 13px;
        background: #FFF;
        padding: 4px 12px;
        width: 214px;
        height: 28px;
        outline: none;
    } */
`;

export const LanguageDropDownContainer = styled.div`
    width: 185px;
    position: relative;
`;


export const SelectedLanguage = styled.div`
    border-radius: 4px;
    border: 1px solid #DADFE4;
    background: #FFF;
    width: 185px;
    height: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 12px;
    cursor: pointer;
    p{
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.13px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`;

export const LanguageDropDown = styled.div`
    position: absolute;
    width: 100%;
    top: 32px;
    z-index: 5;
    border-radius: 4px;
    border: 1px solid #CFD0D1;
    background: #FFF;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.20);

    max-height: 200px;
    overflow-y: auto;
`;

export const LanguageOption = styled.div`
    padding: 8px 12px;
    border-bottom: solid 1px #DADFE4;
    cursor: pointer;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.13px;

    display: flex;
    align-items: center;
    gap: 5px;
    
    label{
        width: 134px;
        cursor: pointer;
    }
`;

