import React, { useRef, useState } from 'react'
import {
    useMutation
  } from "@tanstack/react-query"
import NoResultsLogo from "../../../../../../../media/Dashboard/noresults.svg"

// Styles
import {
    CountriesList,
    TagsList,
    TagsContainer,
    CountriesContainer,
    DateRange,
    AdDetailsBody,
    CloseDetails,
    DetailsHeader,
    DetailsContainer,
    FooterRight,
    AdCountries,
    FooterLeft,
    CardFooter,
    CardBody,
    FloatingButtonsContainer,
    Card,
    CardsList,
    ResultsContainer,
    ResultsWraper,
    VideoContainer,
    VideoTitle,
    ChannelDetails,
    LogoContainer,
    ChannelName,
    VideoDescription,
    PublishedOn,
    DetailsVideoContainer,
    Category,
    PublishedOnDetails,
    NoResults,
    ViewMoreAds
} from "./index.styled"
import SearchLoader from '../../../../../../HOC/SearchLoader'
import BlueRightArrow from '../../../../../../../media/Dashboard/blue-right-arrow.svg'

import WebsiteIcon from '../../../../../../../media/Dashboard/websiteicon.png'

//Icons
import CloseBtn from "../../../../../../../media/Dashboard/detailsPaneClose.svg"


import CountryIcon from "../../../../../../../media/Images/CountryIcon.svg"
import {appClient} from '../../../../../../../utils/axios-utils'

import { useLandingPageAnalysis } from '../../../../context/landingPageAnalysis'
import { getTodayDate } from '../../../../../../../utils/dateFormat'

const requestDataCrawl = async (data) => {
    const config = {
        url: `/track/search/update-search-query`,
        method: "POST",
        data
    }
  
    return appClient(config)
}

const convertTimestamp = (timestamp) => {
    // Create a new Date object based on the timestamp
    var date = new Date(timestamp);
  
    // Get the month name
    var monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    var month = monthNames[date.getMonth()];
  
    // Get the year
    var year = date.getFullYear();
  
    // Assemble the formatted date string
    var formattedDate = date.getDate() + ' ' + month  + ' ' + year;
  
    return formattedDate;
}

const CardElement = ({ad,idx, containerRef}) => {
    const {landingPageAnalysisState, landingPageAnalysisDispatch} = useLandingPageAnalysis()

    const scrollToElement = (index) => {
        const element = containerRef.current.children[index];
        const scrollPosition = element.offsetTop - 20;
        containerRef.current.scrollTo({ top: scrollPosition, behavior: 'smooth' });
      };

    const toggleDetailsModal = (ad, idx) => {
        // console.log("addd yt details")
        if((landingPageAnalysisState.adDetails.id !== ad.id) && landingPageAnalysisState.detailsModal){ 
            landingPageAnalysisDispatch({type:'SET_AD_DETAILS', value: ad})
            landingPageAnalysisDispatch({type:'UPDATE_COLLAPSE_CALENDER', value: true})
        }else{
            landingPageAnalysisDispatch({type:'SET_AD_DETAILS', value: ad})
            landingPageAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: !landingPageAnalysisState.detailsModal})
            landingPageAnalysisDispatch({type:'UPDATE_COLLAPSE_CALENDER', value: false})
        }
        setTimeout(() => {
            scrollToElement(idx)
        }, 100);
    }
    

    return <Card detailsOpen={landingPageAnalysisState.adDetails.id == ad.id} key={
        ad.id
    }>
        <VideoContainer>
            <iframe width="100%" height="243" style={{borderRadius: "11px 11px 0px 0px", border: "none"}}
                src={`https://www.youtube.com/embed/${ad.videoId}`}>
            </iframe>
        </VideoContainer>
        <CardBody>
            <VideoTitle>
                {ad.title}
            </VideoTitle>
            <ChannelDetails>
                <LogoContainer>
                    <img src={WebsiteIcon}/>
                </LogoContainer>
                <ChannelName>
                    {ad.channel}
                </ChannelName>
            </ChannelDetails>
            <VideoDescription>
                {ad.content}
            </VideoDescription>
            <FloatingButtonsContainer>
                {/* <Favorite isFav={idx == 1}>
                    <img src={FavorietIcon}/>
                </Favorite>
                |
                <DropdownIcon>
                    <span/>
                </DropdownIcon> */}
            </FloatingButtonsContainer>
        </CardBody>
        <CardFooter>
            <FooterLeft>
                {/* <AdDate>
                    {ad.firstSeen && ad.lastSeen && <img src={DateIcon}/>}{convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
                </AdDate> */}
                {ad.country && <AdCountries>
                    <img src={CountryIcon}/>{ ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
                </AdCountries>}
            </FooterLeft>
            <FooterRight>
                <PublishedOn>
                    <label>Published on:</label>
                    {convertTimestamp(ad.publishedOn)}
                </PublishedOn>
                {/* <DetailsButton onClick={()=>toggleDetailsModal(ad,idx)}>
                    {(landingPageAnalysisState.adDetails.id == ad.id) && landingPageAnalysisState.detailsModal ? "Close details" : "More details"}
                </DetailsButton> */}
            </FooterRight>
        </CardFooter>
    </Card>
}

const DetailsElement = () => {
    // console.log("details")

    const { landingPageAnalysisState, landingPageAnalysisDispatch } = useLandingPageAnalysis()

    return <DetailsContainer>
    <DetailsHeader>
        <h3>Ad details</h3> <CloseDetails onClick={()=>                 {landingPageAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: false})
                landingPageAnalysisDispatch({type:'SET_AD_DETAILS', value: {}})
                landingPageAnalysisDispatch({type:'UPDATE_COLLAPSE_CALENDER', value: false})
            }}><img src={CloseBtn}/></CloseDetails>
    </DetailsHeader>
    <AdDetailsBody>
        <DetailsVideoContainer>
            <iframe width="100%" height="378" style={{borderRadius: "11px 11px 0px 0px", border: "none"}}
                src={`https://www.youtube.com/embed/${landingPageAnalysisState.adDetails.videoId}`}>
            </iframe>
        </DetailsVideoContainer>
        <VideoTitle>
                {landingPageAnalysisState.adDetails.title}
            </VideoTitle>
            <ChannelDetails>
                <LogoContainer>
                    <img src={WebsiteIcon}/>
                </LogoContainer>
                <ChannelName>
                    {landingPageAnalysisState.adDetails.channel}
                </ChannelName>
            </ChannelDetails>
            <VideoDescription>
                {landingPageAnalysisState.adDetails.content}
            </VideoDescription>
        <Category>
            <label>Category:</label>{landingPageAnalysisState.adDetails.genre}
        </Category>
        <DateRange>
            <label>Date Range:</label>{convertTimestamp(landingPageAnalysisState.adDetails.firstSeen)} - {convertTimestamp(landingPageAnalysisState.adDetails.lastSeen)}
        </DateRange>
        {/* <AvgPosition>
            <label>Average position:</label>{landingPageAnalysisState.adDetails.position}
        </AvgPosition> */}
        {landingPageAnalysisState.adDetails.tags && <TagsContainer>
            <label>Tags:</label><TagsList>{landingPageAnalysisState.adDetails.tags.map((tag,idx)=>{
                return <span>{tag}</span>
            })}</TagsList>
        </TagsContainer>}
        <PublishedOnDetails>
            <label>Published on:</label>{convertTimestamp(landingPageAnalysisState.adDetails.publishedOn)}            
        </PublishedOnDetails>
        {landingPageAnalysisState.adDetails.country && <CountriesContainer>
            <label>Country:</label><CountriesList>{landingPageAnalysisState.adDetails.country.map((tag,idx)=>{
                return <span>{tag}</span>
            })}</CountriesList>
        </CountriesContainer>}
    </AdDetailsBody>
</DetailsContainer>
}

const YoutubeResults = ({isDataLoading}) => {
    const containerRef = useRef(null);
    const [ dataRequested, setDataRequested ] = useState(false)

    const { landingPageAnalysisState, landingPageAnalysisDispatch } = useLandingPageAnalysis()
    
    const {mutate: requestData, isLoading: isRequestDataLoading} = useMutation(requestDataCrawl, {
        onSuccess: (data) => {
            setDataRequested(true)
        },
        onError:  ((e)=>{
  
        })
    })
  
    const onDataRequest = () => {
        if(!dataRequested){
            requestData({id: landingPageAnalysisState.queryId})
        }
    }

    // if(authState.isRestrictedPopup){
    //     return  <ResultsContainer detailsModal={landingPageAnalysisState.detailsModal}>
    //         <PlatformRestrictedPopup err={searchState.searchError}/>
    //     </ResultsContainer>
    // }

    // if(searchState.limitExceeded){
    //     return  <ResultsContainer detailsModal={landingPageAnalysisState.detailsModal}>
    //         <LimitExceededPopup err={searchState.searchError}/>
    //     </ResultsContainer>
    // }

    return  <ResultsContainer detailsModal={landingPageAnalysisState.detailsModal}>
        {isDataLoading ? <div className='loader'>
        <SearchLoader/>
      </div> : landingPageAnalysisState.ads.length == 0 ? <NoResults>
                        <img src={NoResultsLogo}/>
                        <h2>No Results Found</h2>
                        <p>No ads found on this specific date. try some other dates</p>
                         {/* <RequestDataBtn dataRequested={dataRequested ? dataRequested : ""} disable={isRequestDataLoading} onClick={onDataRequest}>{dataRequested ? <p>Your request for data crawl has been accepted<img src={TickImg}/></p> : <p>{isRequestDataLoading ? "Requesting...": "Request data crawl for this query"}</p>}</RequestDataBtn> */}
                    </NoResults> :
                <ResultsWraper>
                    <CardsList ref={containerRef} detailsModal={landingPageAnalysisState.detailsModal}> {
                        landingPageAnalysisState.ads && landingPageAnalysisState.ads.length > 0 && landingPageAnalysisState.ads.slice(0, 4).map((ad, idx) => {
                            return <CardElement containerRef={containerRef} ad={ad} idx={idx}/>
                        })
                    } 
                    <ViewMoreAds onClick={()=>window.open(`https://app.adspyder.io/adspy/youtube?searchMode=url&keywordSearchType=phrase&phraseInput=&page=1&excludedTerm=&searchIn=title%2Ccontent&sortType=relevant&urlSearchType=domain&domainUrlInput=${landingPageAnalysisState.searchUrl}&startDate=${landingPageAnalysisState.selectedYear+"-"+landingPageAnalysisState.selectedMonth+"-"+landingPageAnalysisState.selectedDay}&endDate=${getTodayDate()}`)}>View More Ads <img src={BlueRightArrow}/></ViewMoreAds>
                    </CardsList>
                    {
                        landingPageAnalysisState.detailsModal && <DetailsElement/>
                    }
                </ResultsWraper>
                }
            </ResultsContainer>
}

export default YoutubeResults



