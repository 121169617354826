import styled from "styled-components";

export const Container = styled.div`
    max-width: 1049px;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
`;

export const HeaderLeft = styled.div`
    display: flex;
    gap: 24px;

    img{
        width: 24px;
        cursor: pointer;
    }
    
    h2{
        font-size: 16px;
        font-weight: 400;
        line-height: 38px;
        color: #000;
        width: 200px;
    }
`;

export const HeaderRight = styled.div`
    display: flex;
    align-items: center;
    column-gap: 13px;
`;

export const PublishButton = styled.button`
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #FFF;
    border-radius: 6px;
    background: #FF711E;
    outline: none;
    border: none;
    padding: 10px 20px;
    opacity: ${props => props.isActive ? "1" : "0.5"};
    pointer-events: ${props => props.isActive ? "all" : "none"};
`;

export const ContentContainer = styled.div`
    
`;

export const CampaignNameContainer = styled.div`
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #1C1C1C;
    border-radius: 6px;
    border: 1px solid #DADFE4;
    background: #FFF;
    margin-bottom: 16px;
`;

export const ContentWrapper = styled.div`
    display: flex;
    border: 1px solid #DADFE4;
    background: #FFF;
    height: calc(100vh - 248px);
`;

export const LeftContentContainer = styled.div`
    width: 215px;
    border-right: 1px solid rgba(218, 223, 228, 0.50);
`;

export const InputTab = styled.div`
    height: 70px;
    padding: 24px 28px;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    border-bottom: solid 1px rgba(218, 223, 228, 0.50);
    background: ${props => props.isActive ? "#2B2B2B": "none"};
    color: ${props => props.isActive ? "#FFF": "rgba(28, 28, 28, 0.50)"};
    cursor: pointer;
`;

export const RightContentContainer = styled.div`
    width: calc(100% - 215px);
    height: 100%;
    overflow-y: auto;
`;

export const ErrorMsg = styled.div`
background: #F8D6DB;
height: 40px;
color: #B00020;
text-align: right;
font-size: 14px;
font-weight: 300;
display: flex;
align-items: center;
padding: 0 16px;
`