import React, { useEffect, useState } from "react";
import {
  Wrapper,
  ImageDataContainer,
  Header,
  Content,
  RecomendedSize,
  SelectImageContainer,
  UploadStockImageBtn,
  AiImageUploadBtn,
  StockImageContainer,
  BackContainer,
  SearchbarContainer,
  SearchButton,
  ImgWrapper,
  EvenWidthDiv,
  OddWidthDiv,
} from "./index.styled";
import Masonry from "react-responsive-masonry";
import DummyImage from "../../../../../../../../../../media/Dashboard/photoIcon.svg";
import UploadImg from "../../../../../../../../../../media/Dashboard/UploadLogo.svg";
import AiImage from "../../../../../../../../../../media/Dashboard/stars.svg";
import LeftArrow from "../../../../../../../../../../media/Dashboard/backArrowGreyIcon.svg";
import SearchIconAdGen from "../../../../../../../../../../media/Dashboard/SearchIconAdGen.svg";
import SearchIconWhite from "../../../../../../../../../../media/Dashboard/search-white.svg";
import { useAdGenerator } from "../../../../../../../context/adGeneration";
import { useImageAdGenInfo } from "../../../../../../../hooks/useImageAdGenInfo";
import { getSearchParams } from "../../../../../../../../../../utils/helper";
import Skeleton from "react-loading-skeleton";

const Images = () => {
  const [otherImagesOpen, setOtherImagesOpen] = useState(null);
  const params = getSearchParams(window.location.search);
  const {
    adGeneratorState: {
      inputFields,
      updatetemplate,
      selectedStockImgUpload,
      generatedAdId,
      rawTemplate,
      updateStockImage,
      updateAiImage
    },
    adGeneratorDispatch,
  } = useAdGenerator();
  const {
    getStockImageSuggestion,
    getStockImageMutate,
    isGetStockImageSuggestionLoading,
    isGetStockImageLoading,
    getAiGenImagesSuggestion,
    isGetAiGenImagesSuggestionLoading,
    getAiGenImagesMutate,
    isGetAiGenImagesLoading
  } = useImageAdGenInfo();

  const [stockImageSearch, setStockImageSearch] = useState("");
  const imageArr = [1, 2, 3, 4, 5, 6];

  const onImageChange = (e, image) => {
    let baseImageData = image;
    let htmlContent = document.querySelector(".outerHtml");

    htmlContent
      .querySelectorAll(`[data-field-id="${selectedStockImgUpload.uniqueId}"]`)
      .forEach((element) => {
        if (selectedStockImgUpload.tagName.toLowerCase() === "img") {
          element.src = baseImageData;
        } else {
          element.style.backgroundImage = `url(${baseImageData})`;
        }
        adGeneratorDispatch({
          type: "SET_INPUT_FEILDS",
          value: inputFields.map((check) =>
            check.uniqueId === selectedStockImgUpload.uniqueId
              ? { ...check, url: baseImageData }
              : check
          ),
        });

        let clonedHtmlContent = htmlContent.cloneNode(true);
        // Remove unwanted xmlns attribute (if exists)
        // clonedHtmlContent.removeAttribute("xmlns");
        // Serialize only the inner HTML if needed
        const modifiedHtml = clonedHtmlContent.innerHTML;
        adGeneratorDispatch({
          type: "SET_UPDATE_TEMPLATE",
          value: modifiedHtml,
        });
      });
  };

  const handleInputImageChange = (event, { tagName, uniqueId, url }) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          let baseImageData = reader.result;
          let htmlContent = document.querySelector(".outerHtml");

          htmlContent
            .querySelectorAll(`[data-field-id="${uniqueId}"]`)
            .forEach((element) => {
              if (tagName.toLowerCase() === "img") {
                element.src = baseImageData;
                url = baseImageData;
              } else {
                element.style.backgroundImage = `url(${baseImageData})`;
                url = baseImageData;
              }
              adGeneratorDispatch({
                type: "SET_INPUT_FEILDS",
                value: inputFields.map((check) =>
                  check.uniqueId === uniqueId
                    ? { ...check, url: baseImageData }
                    : check
                ),
              });

              let clonedHtmlContent = htmlContent.cloneNode(true);
              // Remove unwanted xmlns attribute (if exists)
              // clonedHtmlContent.removeAttribute("xmlns");
              // Serialize only the inner HTML if needed
              const modifiedHtml = clonedHtmlContent.innerHTML;
              adGeneratorDispatch({
                type: "SET_UPDATE_TEMPLATE",
                value: modifiedHtml,
              });
            });
        };

        reader.readAsDataURL(file);
      }
    }
  };

  const queryFunction = () => {
    if (otherImagesOpen == "stock-image") {
      getStockImageMutate({
        query: stockImageSearch,
        campaignId: params.id,
        templateId: params.variationId,
      });

    } else {
      getAiGenImagesMutate({
        query: stockImageSearch,
        campaignId: params.id,
        templateId: params.variationId,
      });
    }
  };

  useEffect(() => {
    if(otherImagesOpen){
      if(otherImagesOpen == "stock-image"){
        // adGeneratorDispatch({
        //   type: "UPDATE_STOCK_IMAGE",
        //   value: [],
        // });
        // getStockImageSuggestion({
        //   campaignId: params.id,
        //   templateId: params.variationId,
        // })
      }else if(otherImagesOpen == "ai-image"){
        adGeneratorDispatch({
          type: "UPDATE_STOCK_IMAGE",
          value: [],
        });
        getAiGenImagesSuggestion({
          campaignId: params.id,
          templateId: params.variationId,
        });
      }
    }
  }, [otherImagesOpen]);

  return (
    <Wrapper>
      {otherImagesOpen ? (
        <StockImageContainer>
          <h2>Image</h2>
          <BackContainer>
            <img
              src={LeftArrow}
              onClick={() => {
                setOtherImagesOpen(null);
                setStockImageSearch("");
                adGeneratorDispatch({
                  type: "UPDATE_STOCK_IMAGE",
                  value: [],
                });
              }}
            />
            <p>
              {otherImagesOpen == "stock-image"
                ? "Stock images"
                : "AI image generation"}
            </p>
          </BackContainer>
          <SearchbarContainer>
            <div>
              <img src={SearchIconAdGen} />
              <input
                placeholder="Search Images"
                onChange={(e) => setStockImageSearch(e.target.value)}
              />
            </div>
            {/* <SearchButton>
              <img src={SearchIconWhite} />
            </SearchButton> */}
            <SearchButton
              isDisabled={
                isGetStockImageLoading || !stockImageSearch.trim().length > 0 || (isGetStockImageSuggestionLoading || isGetAiGenImagesSuggestionLoading || isGetAiGenImagesLoading)
              }
              onClick={queryFunction}
            >
              <img src={SearchIconWhite} />
            </SearchButton>
          </SearchbarContainer>
          <ImgWrapper>
            <Masonry gutter={"12px"}>
              {updateStockImage.length > 0
                ? updateStockImage.map((item) => {
                    return (
                      <img onClick={(e) => onImageChange(e, item)} src={item} />
                    );
                  })
                 : imageArr.map((item, index) =>
                    index % 2 === 0 ? (
                      <EvenWidthDiv key={index}>
                        <Skeleton height={"100%"} />
                      </EvenWidthDiv>
                    ) : (
                      <OddWidthDiv key={index}>
                        <Skeleton height={"100%"} />
                      </OddWidthDiv>
                    )
                  )}
            </Masonry>
          </ImgWrapper>
        </StockImageContainer>
      ) : (
        inputFields.map((field, index) => {
          return (
            (field.tagName?.toLowerCase() === "img" ||
              field.attributeName === "input-bg") && (
              <ImageDataContainer key={field.id}>
                <Header>
                  <label>
                    {field.attributeValue !== ""
                      ? field.attributeValue
                      : "Background Image"}
                  </label>
                </Header>
                <Content>
                  {field.url ? (
                    <img src={field.url} alt={field.name} />
                  ) : (
                    <img src={DummyImage} />
                  )}
                  <RecomendedSize>
                    <p>Recomended size: </p>
                    <p>{field.recomendedSize}</p>
                  </RecomendedSize>
                  <input
                    type="file"
                    accept="image/*"
                    id={`upload-${field.uniqueId}`}
                    onChange={(event) => handleInputImageChange(event, field)}
                    hidden
                  />
                  {/* <label for="upload">Change</label> */}
                </Content>
                <SelectImageContainer>
                  <label for={`upload-${field.uniqueId}`}>
                    <img src={UploadImg} />
                    Upload
                  </label>
                  <UploadStockImageBtn
                    onClick={() => {
                      setOtherImagesOpen("stock-image");
                      adGeneratorDispatch({
                        type: "UPDATE_STOCK_IMG_UPLOAD",
                        value: {
                          uniqueId: field.uniqueId,
                          tagName: field.tagName,
                        },
                      });
                      adGeneratorDispatch({
                        type: "UPDATE_STOCK_IMAGE",
                        value: rawTemplate.stockImages,
                      });
                    }}
                  >
                    <img src={DummyImage} />
                    Stock images
                  </UploadStockImageBtn>
                  <AiImageUploadBtn
                    onClick={() => setOtherImagesOpen("ai-image")}
                  >
                    <img src={AiImage} />
                    AI
                  </AiImageUploadBtn>
                </SelectImageContainer>
              </ImageDataContainer>
            )
          );
        })
      )}
    </Wrapper>
  );
};

export default Images;
