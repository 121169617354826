import styled from "styled-components";

export const CardsWrapper = styled.div`
    width: 966px;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    height: 490px;
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #80808030;
        border-radius: 10px;     
    }
`

export const Card = styled.div`
    width: 270px;
    // height: 490px;
    flex-shrink: 0;
    border-radius: 16px;
    background: #FFF;
    padding: 24px;
`;

export const Header = styled.div`
    display: flex;
    align-items: start;
    justify-content: space-between;
    height: 28px;
    border-bottom: 2px solid rgba(28, 28, 28, 0.50);
`;

export const SerialNo = styled.div`
    color: rgba(28, 28, 28, 0.50);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;

export const Title = styled.div`
    color: #1C1C1C;
    text-align: right;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;





export const AgeBreakdownContainer = styled.div`
height: 97%;
    width: 100%;
    border-top: 1px solid #E3E8EE;
    padding: 8px 0;
`;

export const AgePercentageContainer = styled.div`
    margin-bottom: 10px;
`;

export const AgeLabel = styled.div`
    color: rgba(28, 28, 28, 0.80);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: -0.3px;
`;

export const PercentageDistribution = styled.div`
    width: 190px;
    height: 18px;
    flex-shrink: 0;
    background: #ECEAE8;
`;

export const PercentageValue = styled.progress`
    width: ${(props) => props.value ?? "0"};
    height: 100%;
    border-radius: unset;
    block-size: 1.1em;
    inline-size: 12em;
    appearance: none;
    border: none;
    &::-webkit-progress-bar {
        background-color: #ddd;
    }
    &::-webkit-progress-value {
        background-color: #006ecc;
    }
    &::-moz-progress-bar {
        background-color: #3498db;
    }
    &::-ms-fill {
        background-color: #3498db;
    }
`;
export const NoDataContainer = styled.div`
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
`