import styled, { css } from "styled-components";

export const AdGroupContainer = styled.div`
  border-bottom: solid 1px rgba(218, 223, 228, 0.5);
`;

export const Container = styled.div`
  overflow-y: auto;
  height: 98%;
`;

export const CollapseParent = styled.div`
  cursor: pointer;
  height: 70px;
  display: flex;
  gap: 10px;
  padding: 0px 36px;
  align-items: center;
  justify-content: space-between;
`;

export const CollapseBody = styled.div`
  background: rgba(217, 217, 217, 0.1);
  padding: 12px 36px;
`;

export const DestinationUrlContainer = styled.div`
  margin-bottom: 36px;
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 12px;
    color: rgba(28, 28, 28, 0.5);
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
  }
  input {
    border-radius: 6px;
    border: 1px solid #dadfe4;
    background: #fff;
    padding: 0 16px;
    height: 44px;
    pointer-events: none;
    opacity: 0.4;
  }
  small {
    text-align: end;
    color: #b00020;
    text-align: right;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
  }
`;

export const DescContainer = styled.div`
  margin-bottom: 36px;
  display: flex;
  flex-direction: column;
`;

export const DescContent = styled.div`
  margin-bottom: 36px;
  display: flex;
  flex-direction: column;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DescContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  label {
    margin-bottom: 12px;
    color: rgba(28, 28, 28, 0.5);
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
  }
  input {
    border-radius: 6px;
    border: 1px solid #dadfe4;
    background: #fff;
    padding: 0 16px;
    height: 44px;
    outline: none;
    ${(props) =>
      props.children[1].props.error &&
      css`
        border-color: #b00020; /* Change border color to red if error prop is true */
      `}
  }
  small {
    text-align: end;
    color: #b00020;
    text-align: right;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
  }
`;

export const DescContentRight = styled.div`
  margin-top: 30px;
  cursor: pointer;
`;

export const TitleContainer = styled.div`
  margin-bottom: 36px;
  display: flex;
  flex-direction: column;
`;

export const TitleContent = styled.div`
  margin-bottom: 36px;
  display: flex;
  flex-direction: column;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TitleContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  label {
    margin-bottom: 12px;
    color: rgba(28, 28, 28, 0.5);
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
  }
  input {
    border-radius: 6px;
    border: 1px solid #dadfe4;
    background: #fff;
    padding: 0 16px;
    height: 44px;
    outline: none;
    ${(props) =>
      props.children[1].props.error &&
      css`
        border-color: #b00020; /* Change border color to red if error prop is true */
      `}
  }
  small {
    text-align: end;
    color: #b00020;
    text-align: right;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
  }
`;

export const TitleContentRight = styled.div`
  cursor: pointer;
  margin-top: 30px;
`;

export const AddTitleContainer = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  color: #1679db;
  // cursor: pointer;
  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.isDisabled ? "none" : "auto")};
`;

export const AddDescriptionContainer = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  color: #1679db;
  cursor: pointer;
  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.isDisabled ? "none" : "auto")};
`;

export const AdGroupParentLeft = styled.div`
  cursor: pointer;
  height: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  img {
    transform: ${(props) => (props.isOpen ? "" : "rotate(-90deg)")};
  }
`;

export const AdGroupParentRight = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
