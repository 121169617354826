import styled, { keyframes } from "styled-components";

export const TextAdBanner = styled.div`
width: 508px;
height: 238px;
flex-shrink: 0;
border-radius: 4px;
background: #172434;
position: relative;
overflow: hidden;
`;

export const MobilePhone = styled.img`
position: absolute;
top: 44px;
left: 30px;
`;

export const ScreenBackground = styled.div`
width: 157px;
height: 214px;
flex-shrink: 0;
border-radius: 11.588px;
position: absolute;
left: 41.93px;
top: 67.69px;
z-index: 1;
`;

export const AdContent = styled.div`
width: ${(props) => props.forSlider ? "205px" : "157px"};
flex-shrink: 0;
position: relative;
background: #fff;
margin-bottom: 5.49px;
`;

export const Logo = styled.img`
    position: absolute;
    top: ${(props) => props.forSlider ? "8.04px" : "6.18px"};
    right: ${(props) => props.forSlider ? "5.28px" : "4.09px"};
`;

export const AdTitle = styled.div`
color: #553FB8;
font-family: Inter;
font-size: ${(props) => props.forSlider ? "8.038px" : "6.163px"};
font-style: normal;
font-weight: 400;
line-height: 150%; /* 9.245px */
margin: ${(props) => props.forSlider ? "6.8px 0 1.97px 4.95px" : "0 5px 1.97px 4.89px"};
padding-top: 7.61px;
`;

export const Url = styled.div`
    display: flex;
    gap: 2.48px;
    margin-left: ${(props) => props.forSlider ? "4.95px" : "4.35px"};

`;

export const AdTag = styled.div`
    width: 8.06px;
height: 100%;
flex-shrink: 0;
border-radius: 0.948px;
background: #7BAE86;
color: #FFF;
font-family: Inter;
font-size: 5.215px;
font-style: normal;
font-weight: 400;
display: flex;
align-items: center;
justify-content: center;
`;

export const RedirectLink = styled.div`
    color: #7BAE86;
font-family: Inter;
font-size: 5.215px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 7.823px */
`;

export const Separator = styled.div`
width: 157.874px;
height: 0.237px;
background: rgba(28, 28, 28, 0.20);
margin-top: 2px;
`;

export const AdDescription = styled.div`
    padding: 3px;
    color: rgba(28, 28, 28, 0.50);
    font-family: Inter;
    font-size: 5.689px;
    font-style: normal;
    font-weight: 400;
    line-height: 8.534px; /* 150% */
`;



export const SliderAdTag = styled.div`
    width: 10.511px;
height: 10px;
flex-shrink: 0;
border-radius: 1.237px;
background: #7BAE86;
color: #FFF;
font-family: Inter;
font-size: 6.801px;
font-style: normal;
font-weight: 400;
display: flex;
align-items: center;
justify-content: center;
`;

export const SliderRedirectLink = styled.div`
    color: #7BAE86;
font-family: Inter;
font-size: 6.801px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 10.202px */
`;

export const SliderSeparator = styled.div`
    width: 100%;
height: 0.309px;
background: rgba(28, 28, 28, 0.20);
margin-top: 2px;
`;

export const SliderAdDescription = styled.div`
    color: rgba(28, 28, 28, 0.50);
    font-family: Inter;
    font-size: 7.42px;
    font-style: normal;
    font-weight: 400;
    line-height: 11.129px; /* 150% */
    padding: 5px;
`;

export const Container = styled.div`
width: 205px;
height: 175px;
flex-shrink: 0;
position: absolute;
left: 19px;
top: 69px;
z-index: 3;
`;

export const BlackScreen = styled.div`
width: 173.816px;
height: 214.373px;
flex-shrink: 0;
position: absolute;
top: 52.69px;
left: 31.93px;
border-radius: 11.588px;
background: rgba(0, 0, 0, 0.50);
z-index: 2;
`;

export const VerticalSlider = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
`;


const carouselAnimateVertical = keyframes`
0% {
    // transform: translateY(-100%);
    top: 0;
    opacity: 1;
    // visibility: hidden;
}
// 20% {
//     transform: translateY(-50%);
//     opacity: 0.5;
//     visibility: visible;
// }


// 40% {
//     transform: translateY(35%);
//     opacity: 1;
//     visibility: visible;
// }

// 60% {
//     transform: translateY(35%);
//     opacity: 1;
//     visibility: visible;
// }

// 80% {
//     transform: translateY(160%) scale(0.5);
//     opacity: 0.5;
//     visibility: visible;
// }

100% {
    // transform: translateY(160%) scale(0.5);
    // opacity: 0;
    // visibility: hidden;
    top: 160%;
}
`;

export const testAnim = keyframes`
    0% {
    transform: translateY(100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
}
20% {
    transform: translateY(50%) scale(0.7);
    opacity: 0.4;
    visibility: visible;
}


30%, 50% {
    transform: translateY(40%) scale(1);
    opacity: 1;
    visibility: visible;
}

60% {
    transform: translateY(-70%) scale(0.8);
    opacity: 0.4;
    visibility: visible;
}

80% {
    transform: translateY(-100%) scale(0.8);
    opacity: 0;
    visibility: visible;
}

100% {
    transform: translateY(-120%) scale(0);
    opacity: 0;
    visibility: hidden;
}
`;

export const SliderItem = styled.div`
    opacity: 0;
    // scale: 0.5;
    position: absolute;
    filter: drop-shadow(0 2px 2px #555);
    /* animation: ${testAnim} 15s linear infinite; */


:nth-child(1) {
  animation-delay: 0;
}

:nth-child(2) {
  animation-delay: 5s;
}

:last-child {
  animation-delay: 10s;
}
`;