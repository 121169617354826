import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
  color: white;
`;

const shimmer = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

const Text = styled.p`
  font-size: 24px;
  font-weight: bold;
  background: linear-gradient(to right, #ffe5ad, #ffbf80, #ffad5a);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: ${shimmer} 2s infinite linear;
`;

const LoadingScreen = () => {
  const [messageIndex, setMessageIndex] = useState(0);

  const messages = [
    "Loading...",
    "Please wait a moment...",
    "Preparing your content...",
    "Hang tight, we're almost there...",
    "Just a moment longer...",
    "Getting things ready for you...",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex((prevIndex) =>
        prevIndex === messages.length - 1 ? 0 : prevIndex + 1
      );
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <Text>{messages[messageIndex]}</Text>
      <div className="spinner" style={{ width: "50px", height: "50px" }}></div>
    </Container>
  );
};

export default LoadingScreen;