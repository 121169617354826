import React from 'react'
import { TableWrapper,TableContainer, RelatedKeyword, TableWrap, TableHeader, KeyHeader, StatusHeader, PlatformHeader, CountryHeader, TableBody, Row, HoverWrapper, KeyData, StatusData, PlatformData, CountryData, ShowMoreBtn, ShowMoreBtnWrap } from './tableComponent.styled'
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    SortingState,
    ColumnDef,
    getSortedRowModel
  } from '@tanstack/react-table'
import helpCircle from '../../../../../../media/Dashboard/help-circle.svg'
import sort from '../../../../../../media/Dashboard/sort.svg'
import blueDownArrow from '../../../../../../media/Dashboard/blueDownArrow.svg'
import shareData from '../../../../../../media/Dashboard/shareData.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { useKeywordOverview } from '../../../context/keywordOverview';
import Skeleton from "react-loading-skeleton";
import Tooltip from 'rc-tooltip'

// const trackingAnalysis = async (data) => {
//     // console.log(data)
//     const config = {
//         url: "/keyword/keyword-overview/analysis",
//         method: "POST",
//         data
//     }
  
//     return appClient(config)
//   }
//   const overviewAnalysis = async (data) => {
//     // console.log(data)
//     const config = {
//         url: "/keyword/analysis",
//         method: "POST",
//         data
//     }
  
//     return appClient(config)
//   }
const TableComponent = ({isTrackingAnalysisLoading,isOverviewAnalysisLoading}) => {
    const columnHelper = createColumnHelper();
  const location = useLocation()
  const {keywordOverviewState, keywordOverviewDispatch} = useKeywordOverview()
    // const {keywordTrackingState, keywordTrackingDispatch} = useKeywordTracking()
    const navigate = useNavigate()
    const columns = [
        columnHelper.accessor('no', {
            id: 'no',
            header: () => 'No',
            cell: info => info.getValue(),
        }),
        // columnHelper.accessor('query', {
        //     id: 'query',
        //     header: () => 'Query',
        //     cell: info => info.getValue(),
        // }),
        // columnHelper.accessor('platform', {
        //     id: 'platform',
        //     header: () => 'Platform',
        //     cell: info => info.getValue(),
        // }),
        // columnHelper.accessor(row => `${new Date(row.createdAt).getTime()}`, {
        //     id: 'createdAt',
        //     header: () => 'Time',
        //     cell: info => info.getValue(),
        // }),
        // columnHelper.accessor('newlyadded', {
        //     id: 'newlyadded',
        //     header: () => 'Newly added ads',
        //     cell: info => info.getValue(),
        // })
    ];
    const table = useReactTable({
        data: 'accou',
        columns,
        
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });

    // const { mutate: trackingAnalysisApi, isLoading: isTrackingAnalysisLoading } = useMutation(trackingAnalysis, {
    //     onSuccess: (data) => {
    //         if(data.length > 0){
    //             overviewAnalysisApi({keywords: data})
    //         }
    //     },
    //     onError: (err) => {
            
    //     }
    // })
    // const { mutate: overviewAnalysisApi, isLoading: isOverviewAnalysisLoading } = useMutation(overviewAnalysis, {
    //     onSuccess: (data) => {
    //         keywordOverviewDispatch({ type: 'SET_RELATED_KEYWORD_INPUT', value: data})
            
    //     },
    //     onError: (err) => {
            
    //     }
    // })
    // useEffect(() => {
    //     const searchParamFromState = getSearchParams(location.search)
    
    //     const {
    //       keyword,
    //       country
    //     } = searchParamFromState
       
    //   }, [])

  return (
    <TableWrapper>
        <TableContainer>
            <RelatedKeyword>Related Keywords <Tooltip trigger={['hover']} overlayStyle={{ width: "100" }} overlay={<span>Releted keywords found for this keyword</span>}>
                    <img src={helpCircle} />
                </Tooltip></RelatedKeyword>
        <TableWrap>
                    {table.getHeaderGroups().map(headerGroup => {
                        // console.log(headerGroup)
                        return <TableHeader>
                        <KeyHeader>
                          Keyword
                        </KeyHeader>
                        <StatusHeader >
                        Search Volume <img  src={sort}/>
                        </StatusHeader>
                        <PlatformHeader >
                        Competition <img  src={sort}/>
                        </PlatformHeader>
                        <CountryHeader>
                        CPC (avg) <img  src={sort}/>
                        </CountryHeader>
                     
                    </TableHeader>
                        
                    })}
                        {isTrackingAnalysisLoading || isOverviewAnalysisLoading ? 
                <Skeleton style={{'height':'490px',marginTop:'30px'}}/>
                    :
                    <>
                  <TableBody>
                        {keywordOverviewState.relatedKeyword.map((row,index) => {
                          if(!row) return
                            return (<Row>
                                <HoverWrapper onClick={()=>navigate(`/keyword-overview/details?keyword=${row.keyword}&country=global`)}>
                                    <KeyData>
                                       {row.keyword}
                                        <img src={shareData} />
                                    </KeyData>
                                    <StatusData>
                                    {row.vol}
                                    </StatusData>
                                    <PlatformData>
                                    {row.competition} 
                                    </PlatformData>
                                    <CountryData>
                                    {row.cpc.currency}{row.cpc.value}
                                    </CountryData>
                                </HoverWrapper>
                                    </Row>
                            )})}
                            
                    </TableBody>
                    <ShowMoreBtnWrap>
                        {keywordOverviewState.relatedKeyword.length > 0 &&  <ShowMoreBtn>Show more <img src={blueDownArrow} /></ShowMoreBtn>}
                    </ShowMoreBtnWrap>
                    </>
                }   
    
        </TableWrap>
        </TableContainer>
    </TableWrapper>
  )
}

export default TableComponent