import styled from "styled-components";

export const AdGenieContainer = styled.div`
    height: 520px;
    justify-content: center;
    display: flex;
`;

export const InitialScreen = styled.div`
    height: 100%;
    max-width: 1008px;
    width: 90%;
    border-radius: 16px;
    background: #EBF3FF;
    max-height: 238px;
    margin-top: 42px;
    padding: 50px 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2{
        font-size: 22px;
        font-weight: 400;
        line-height: 24px;
        color: #1C1C1C;
        margin-bottom: 16px;
    }
    p{
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: rgba(28, 28, 28, 0.50);
        margin-bottom: 32px;
        max-width: 453px;
        text-align: center;
    }
`;

export const AiLogo = styled.div`
    position: absolute;
    top: -26px;
    border: solid 1px #B4D2FF;
    border-radius: 50%;
    background: #FFF;
    width: 52px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const GenerateAdsButton = styled.div`
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: #FFF;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 168px;
    background: linear-gradient(91deg, #8E74E5 3.65%, #E28FD5 100%);
    cursor: pointer;

    img{
        animation: rotation 2s infinite linear;
    }

    @keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
    }
`;

export const AiAdResultsContainer = styled.div`
    width: 100%;
    padding: 24px 40px;
`;

export const AiAdResultHeader = styled.div`
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction:column;
    margin-bottom: 24px;
    h3{
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        /* color: #7D7D7D; */
        display: flex;
        gap: 8px;
        color: rgba(28, 28, 28, 0.90);
        font-size: 18px;
    }
    p{
        border-radius: 6px;
        background: #FFF7E8;
        width: fit-content;
        padding: 4px 8px;
        display: flex;
        gap: 4px;
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        color: #734A00;
        cursor: pointer;
        margin-left: 8px;
    }
`;

export const AdListContainer = styled.div`
    display: flex;
    margin-top: 32px;
    column-gap: 24px;
    margin-bottom: 130px;
`;

export const TitleListContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    width: 35%;
    max-width: 100%;
`;

export const DescriptionListContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    width: 60%;
    max-width: 100%;
`;

export const TitleContainer = styled.div`
    max-width: 360px;
    min-height: 98px;
    border-radius: 4px;
    border: 1px solid #E3E8EE;
    background: #FFF;
    padding: 12px 14px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
`;

export const CopyButton = styled.div`
    cursor: pointer;
`;

export const TitleHeader = styled.div`
    display: flex;
    justify-content: space-between;
    h3{
        color: rgba(28, 28, 28, 0.80);
        font-size: 14px;
        font-weight: 600;
        line-height: 22px; /* 157.143% */
    }
`;

export const DescriptionAiContainer = styled.div`
    max-width: 624px;
    min-height: 127px;
    border-radius: 4px;
    border: 1px solid #E3E8EE;
    background: #FFF;
    padding: 12px 14px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;

`

export const DescriptionHead = styled.div`
display: flex;
justify-content: space-between;
h3{
    color: rgba(28, 28, 28, 0.80);
font-size: 14px;
font-weight: 600;
}

`

export const TitleData = styled.div`
    color: rgba(28, 28, 28, 0.70);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
`
