import React from "react";
import Highlighter from "react-highlight-words"

import { AdContainer, ImageContainer, FloatingButtonsContainer, Favorite, AdBodyContainer, ProductName, ProductPrice, WebsiteUrl, AdBodyBottomContainer, AdDate, AdCountries,DetailsButton, CardContainer, SelectButton, AdTypeContainer  } from "./index.styled"

import FavorietIcon from "../../../../../../media/Dashboard/addFavorite.svg"
import GoogleEcomIcon from "../../../../../../media/Dashboard/Googlepla16.svg"

import GlobeIcon from '../../../../../../media/Dashboard/globe.svg';
import DateIcon from "../../../../../../media/Images/DateIcon.svg"
import CountryIcon from "../../../../../../media/Images/CountryIcon.svg"
import AltImage from '../../../../../../media/Dashboard/altimage.png'
import { useAdGenrator } from '../../../context/adgenerator';

const GoogleEcomCard = ({ad, ToggleSelectedAds}) => {
  const {adGenratorState, adGenratorDispatch} = useAdGenrator()

    const image = ad.image ? <img src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${ad.id}`} onError={() => {}}/> : <img src={AltImage} onError={() => {}}/>;

    const convertTimestamp = (timestamp) => {
        if(!timestamp){
            return "-"
        }
        // Create a new Date object based on the timestamp
        var date = new Date(Number(timestamp));
      
        // Get the month name
        var monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        var month = monthNames[date.getMonth()];
      
        // Get the year
        var year = date.getFullYear();
      
        // Assemble the formatted date string
        var formattedDate = date.getDate() + ' ' + month  + ' ' + year;
      
        return formattedDate;
    }

    
    return <CardContainer><AdContainer>
    <ImageContainer>
      {image}
    </ImageContainer>
    <AdTypeContainer>
      <img src={GoogleEcomIcon}/> Google Ecom 
      {/* | <img src={ClockIcon}/>  */}
    </AdTypeContainer>
    <FloatingButtonsContainer>
      <SelectButton type="checkbox" id="selected" name="selected" onClick={()=>ToggleSelectedAds(ad.id)} checked={adGenratorState.selectedAds.includes(ad.id)}/>
      {/* <Favorite isFav={idx == 1} onClick={()=>{
            authDispatch({type:'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'google-ecom'})
            authDispatch({type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id})
            authDispatch({type:'UPDATE_SAVE_AD_POPUP', value: true})}}>
          <img src={authState.favAdsList["google-ecom"]?.includes(ad.id) ? FavouriteIconActive : FavorietIcon}/>
      </Favorite> */}
      {/* |
      <DropdownIcon>
          <span/>
      </DropdownIcon> */}
    </FloatingButtonsContainer>
    <AdBodyContainer>
      <ProductName>
        {ad.title}
      </ProductName>
      <ProductPrice>
        {ad.price}
      </ProductPrice>
      <WebsiteUrl>
        {ad.sitename}
      </WebsiteUrl>
      <AdBodyBottomContainer>
        <AdDate>
        {ad.firstSeen && ad.lastSeen && <img src={DateIcon}/>}{convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
        </AdDate>
        <AdCountries>
          <img src={CountryIcon}/>{ ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
        </AdCountries>
      </AdBodyBottomContainer>
      <DetailsButton >
      {/* {(googleEcomSearchState.adDetails.id == ad.id) && googleEcomSearchState.detailsModal ? "Close details" : "More details"} */}
        </DetailsButton>
    </AdBodyContainer>
  </AdContainer>
  </CardContainer>
}

export default GoogleEcomCard