import React from "react";
import { useAdManagement } from "../../../../context/AdManagementContext";
import tick from '../../../../assets/tickCampaign.svg'
import link from '../../../../assets/link.svg'
import ConfettiExplosion from 'react-confetti-explosion';

import { CampaignDescrip, CampaignDiv, Container, HomeBtn } from "./CampaignPublish.styled";
import { useNavigate } from "react-router-dom";
const CampaignPublished = () => {

    const { adManagementState, adManagementDispatch } = useAdManagement()
    const navigate = useNavigate()

  
    return <Container>
        <img src={tick} /><ConfettiExplosion/>
        <CampaignDiv>Campaign published</CampaignDiv>
        <CampaignDescrip>Your campaign has been successfully published.
            To view and make any modifications, simply log in to your<br/>
            <a href="https://ads.google.com/">Google ads account <img src={link} /></a>
        </CampaignDescrip>
        <HomeBtn onClick={()=> {
            navigate(`/admanagement/ad-campaigns/`)
            adManagementDispatch({type: 'SET_INPUT_PAGE', value: true})
            }}>Home</HomeBtn>
    </Container>
}

export default CampaignPublished