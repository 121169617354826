import react from "react";
import { Container, Left, LogoContainer, ChildrenHolder, Right, ContentWrapper, ChildrenContainer } from "./layout.styled"
import AdspyderWhiteLogo from "../../../../../media/Images/AdspyderLogo2024.svg"
const Layout = ({children}) => {
    return <Container>
    <Left>
      <ContentWrapper>
        <LogoContainer>
          <img src={AdspyderWhiteLogo}/>
        </LogoContainer>
        <ChildrenContainer>
          <ChildrenHolder>
            {children}
          </ChildrenHolder>
        </ChildrenContainer>
      </ContentWrapper>
    </Left>
    <Right>

    </Right>
  </Container>
}

export default Layout;