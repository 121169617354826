import React, { useEffect } from "react";
import { GraphContainer, Header } from "./index.styled";
import { Line } from "react-chartjs-2";
import { useDashboardInfo } from "../../../hooks/useDashboardInfo";
import { useDashboard } from "../../../../../../context/dashboard";
// import { useDashboard } from "../../../context/dashboard";

const CompetitorsChart = () => {
  let dataLabel = [];
  let dataResult = [];
  let colorsArray = [
    "#9362FC",
    "#62A9FC",
    "#FCD384",
    "#00bbb4",
    "#ff76ae",
    "#ed7218",
  ];
  const { getCompetitorChartValue, isCompetitorChartValue } =
    useDashboardInfo();
  const {
    dashboardState: {
      selectedProject: { id, competitors },
      competitorsChart,
    },
  } = useDashboard();

  competitorsChart.length > 0 &&
    competitorsChart.map((item, index) => {
      index == 0 &&
        item.chartResult.map((result) => {
          dataLabel.push(result.month);
        });
      const value = item.chartResult.map((val) => val.value);
      const color = colorsArray.map((col, idx) => index == idx && col);
      dataResult.push({
        label: item.domainName,
        fill: false,
        lineTension: 0.05,
        backgroundColor: color[index],
        borderColor: color[index],
        pointRadius: 3,
        pointBackgroundColor: '#fff',
        pointBorderWidth: 2,
        pointBorderColor: color[index],
        pointHoverBorderColor: color[index],
        pointHoverRadius: 5,
        pointHoverBorderWidth: 2,
        pointHoverBackgroundColor: "#fff",
        borderWidth: 3,
        data: value,
      });
    });
  const data = {
    labels: dataLabel,
    datasets: dataResult,
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#373737",
        titleFontColor: "#ffffff", // Title font color
        bodyFontColor: "#ffffff", // Body font color
        titleFont: { size: "12", family: "Inter", weight: "500" }, // Body font size
        bodyFont: { family: "Inter" },
        padding: 8, // Padding inside tooltip
        caretSize: 6, // Size of the tooltip arrow
        borderWidth: 1, // Border width of tooltip
        cornerRadius: 4, // Border radius of tooltip,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },

        ticks: {
          maxTicksLimit: 8,
        },
      },
      y: {
        display: true,
        border: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 5,
          callback: function (value) {
            if (value >= 1000000) {
              return (value / 1000000).toFixed(1) + "M";
            } else if (value >= 1000) {
              return (value / 1000).toFixed(1) + "k";
            }
            return value;
          },
        },
      },
    },
  };

  useEffect(() => {
    getCompetitorChartValue({
      projectId: id,
    });
  }, [competitorsChart.length]);

  return (
    competitors?.length > 0 && (
      <GraphContainer>
        <Header>
          <h2>Competitor ads Tracker (Top 4) </h2>
          <p>Average ads runs in last 1 year</p>
        </Header>
        <Line data={data} options={options} />
      </GraphContainer>
    )
  );
};

export default CompetitorsChart;
