import React from "react";
import {
   
    CardBody,
    
    Card,
    
    VideoContainer,
    ImageContainer,
    ChannelDetails,
    LogoContainer,
    ChannelName,
    Description,
    SecondaryDetails,
   
    InnerContent,
    InnerTitle,
    CallToActionButton,
   
    CTAbutton,
    CardContainer,
    HoverCardWrapper,
    HoverCard,
    AdDetailsButton,
} from "./index.styled"

import RightUpperBlackArrow from "../../../../../../media/Dashboard/RightUpperBlackArrow.svg"


import AltImage from '../../../../../../media/Dashboard/altimage.png'
import { useSavedAds } from "../../../context/savedads";

const LinkedinCard = ({ad}) => {

    const { savedAdsDispatch } = useSavedAds()
    const convertTimestamp = (timestamp) => {
        if(!timestamp){
            return "-"
        }
        // Create a new Date object based on the timestamp
        var date = new Date(Number(timestamp));
      
        // Get the month name
        var monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        var month = monthNames[date.getMonth()];
      
        // Get the year
        var year = date.getFullYear();
      
        // Assemble the formatted date string
        var formattedDate = date.getDate() + ' ' + month  + ' ' + year;
      
        return formattedDate;
    }

    const toggleDetailsModal = (ad) => {
        savedAdsDispatch({type: 'SET_SELECTED_AD_FOR_DETAIL', value: ad})
        savedAdsDispatch({ type: 'UPDATE_DETAILS_MODAL', value: true })
    }


    return <Card key={
        ad.id
    }>
        <CardContainer>
            <HoverCard className='hover-element' onClick={() => toggleDetailsModal(ad)
            }>
                <HoverCardWrapper>
                    <AdDetailsButton onClick={() => toggleDetailsModal(ad)}>
                        <img src={RightUpperBlackArrow} />
                        <span>Ad Details</span>
                    </AdDetailsButton>
                </HoverCardWrapper>
            </HoverCard>
            <CardBody>
                <ChannelDetails>
                    <LogoContainer>
                        <img src={`https://aws-adspyder-logos.s3.ap-south-1.amazonaws.com/${ad.id}`} />
                    </LogoContainer>
                    <ChannelName>
                        {ad.title}
                        <span>Promoted</span>
                    </ChannelName>
                </ChannelDetails>
                <Description>
                    {ad.content}
                </Description>
                {ad.image && <ImageContainer>
                    <img src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${ad.id}`} />
                </ImageContainer>}
                {ad.video && <VideoContainer>
                    <video src={`https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${ad.id}`} preload="auto" controls loop style={{ width: "100%", height: "100%" }} />
                </VideoContainer>}
                {(!ad.video && !ad.image) && <ImageContainer>
                    <img src={AltImage} />
                </ImageContainer>}
                {(ad.ctaContent || ad.ctaButton) && <SecondaryDetails>
                    <InnerContent>
                        <InnerTitle>
                            {ad.ctaContent}
                        </InnerTitle>
                    </InnerContent>
                    <CallToActionButton>
                        {ad.ctaButton && <CTAbutton>
                            {ad.ctaButton}
                        </CTAbutton>
                        }
                    </CallToActionButton>
                </SecondaryDetails>}
            </CardBody>
        </CardContainer>
    </Card>
}

export default LinkedinCard