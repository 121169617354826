import { CircleFlag } from "react-circle-flags";
import { countryNewData } from "../../../../../../../../utils/countryData";
import { Check, Country, CountryContainer, Dropdown, DropdownContainer } from "./index.styled";
import check from "./assets/check.svg"
function CountryDropdown({top, bottom, left, right, currentlySelected, onSelect}) {
    return ( 
        <DropdownContainer 
            top={top} bottom={bottom} left={left} right={right}
        >
            <Dropdown>
                {countryNewData.map((country) => {
                    console.log(currentlySelected,'currentlySelected',country)
                    return(
                        <CountryContainer onClick={() => onSelect(country)} >
                            <CircleFlag countryCode={country.iso_alpha_2.toLowerCase()} height={24} />
                            <Country>{country.official_name}</Country>
                            {currentlySelected.iso_alpha_2 === country.iso_alpha_2 && <Check src={check} draggable={false} />}
                        </CountryContainer>
                    )
                })}
            </Dropdown>
        </DropdownContainer>
     );
}

export default CountryDropdown;