import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useAuth } from "./context/auth";

const ChatBot = () => {
    const { authState } = useAuth();

    useEffect(() => {
        // Function to set user details
        const setUserDetails = () => {
            if (window.fcWidget) {
                window.fcWidget.user.setFirstName(authState.username ? authState.username : 'unknown');
                window.fcWidget.user.setEmail(authState.email ? authState.email : 'unknown');
            }
        };

        // Check if the widget script is already loaded
        if (window.fcWidget) {
            setUserDetails();
        } else {
            // Wait for the widget to load
            window.addEventListener('fcWidget:loaded', setUserDetails);
        }

        // Cleanup event listener
        return () => {
            window.removeEventListener('fcWidget:loaded', setUserDetails);
        };
    }, [authState?.email]);

    return (
        <>
            <Helmet>
                <script src='//in.fw-cdn.com/31655900/837803.js' chat='true'></script>
            </Helmet>
        </>
    );
};

export default ChatBot;
