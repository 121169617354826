import styled from "styled-components";

export const ClientProfileForm = styled.form`
display: grid;
grid-template-columns: repeat(2, 300px);
gap: 16px 24px;
`;

export const Field = styled.div`

`

export const Label = styled.label`
color: #A9A9A9;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
display: block;
pointer-events: ${(props) => props.disable ? "none" : "unset"};
`;

export const RequiredMark = styled.span`
color: #FF8B8B;
`;

export const Input = styled.input`
margin-top: 8px;
display: block;
width: 300px;
height: 34px;
flex-shrink: 0;
border-radius: 4px;
 border: ${(props) => props.hasError ? "1px solid #F04438" : props.isFocussed ? "1px solid #FFB258" : "1px solid #D0D5DD"};
box-shadow: ${(props) => props.isFocussed ? "0px 1px 2px 0px rgba(28, 28, 28, 0.05), 0px 0px 4px 0px rgba(255, 178, 88, 0.25)" : "unset"};
caret-color: #FFB258;
background: #F6F7FB;
outline: none;

font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
color: rgba(28, 28, 28, 0.90);
pointer-events: ${(props) => props.inActive ? "none" : "unset"};

::focus{
    outline: none;
}
::placeholder{
    color: rgba(28, 28, 28, 0.50);
}
padding: 8px;
`;

export const SelectedCountry = styled.div`
margin-top: 8px;
display: block;
width: 300px;
height: 34px;
flex-shrink: 0;
border-radius: 4px;
border: ${(props) => props.isDropdownOpened ? "1px solid #FFB258" : "1px solid #D0D5DD" };
box-shadow: ${(props) => props.isDropdownOpened ? "0px 1px 2px 0px rgba(28, 28, 28, 0.05), 0px 0px 4px 0px rgba(255, 178, 88, 0.25)" : "unset"};
background: #F6F7FB;
outline: none;
position: relative;
color: ${(props) => props.active ? "rgba(28, 28, 28, 0.90)" : "rgba(28, 28, 28, 0.50)"};
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
cursor: pointer;
::focus{
    outline: none;
}
padding: 8px;
`;

export const DropdownIcon = styled.img`
position: absolute;
top: 5px;
right: 12px;
cursor: pointer;
`;

export const Dropdown = styled.div`
width: 100%;
height: 260px;
flex-shrink: 0;
position: absolute;
top: 37px;
left: 0;
display: none;
border-radius: 4px;
border: 1px solid #CFD0D1;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
padding: 8px;
`;

export const DropdownName = styled.div`
color: rgba(28, 28, 28, 0.60);
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin: 0 0 12px 6px;
`;

export const CountriesList = styled.div`
height: calc(100% - 38px - 30px);
overflow-y: auto;
width: 277px;
::-webkit-scrollbar-track
{
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
    background-color: rgb(245 245 245 / 28%);
    border-radius: 10px;
}

::-webkit-scrollbar
{
    width: 2px;
    border-radius: 10px;
    background-color: #fff;
}

::-webkit-scrollbar-thumb
{
    background-color: #00000030;
    border-radius: 10px;
}
`;

export const Country = styled.div`
width: 270px;
height: 32px;
color: rgba(28, 28, 28, 0.70);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
letter-spacing: 0.14px;
display: flex;
align-items: center;
padding: 0 8px;
cursor: pointer;
:hover {
    border-radius: 4px;
    background: #F9FAFB;
}
`;

export const SearchBox = styled.input`
width: 100%;
height: 28px;
flex-shrink: 0;
border-radius: 4px;
border: 1px solid #DADFE4;
background: #FFF;
padding-left: 10px;
color: rgba(28, 28, 28, 0.90);
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 153.846% */
letter-spacing: 0.13px;
margin-bottom: 10px;
outline: none;
caret-color: #FFB258;
:focus{
    outline: none;
    border: 1px solid #DADFE4;
}
::placeholder{
color: rgba(28, 28, 28, 0.50);
}
`;