import styled from "styled-components";

export const PublishAdContent = styled.div`
  padding: 24px 16px;
`;
export const PublishContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 12px 0;
  border-bottom: 1px solid #e8e8e8;
`;
export const PlatformWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #dadfe4;
  background: #fff;
  height: 44px;
  padding: 0 14px;
  margin-top: 12px;
  filter: ${(prop) => (prop.props ? "invert(100%)" : "")};
  div img {
    cursor: auto;
  }
  img{
    height: 16px;
    cursor: pointer;
  }
`;
export const Platform = styled.div`
  display: flex;
  column-gap: 9px;
  align-items: center;
`;
export const ViewWrap = styled.div`
  border-radius: 7px;
  border: 1px solid #e8e8e8;
  background: #ececec;
  padding: 10px 10px 23px 10px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;
export const ViewOptionWrap = styled.div`
  display: flex;
  justify-content: center;
`;
export const ViewOption = styled.div`
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  width: 32px;
  height: 24px;
  padding: 0px 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const CardWrapper = styled.div`
  border-radius: 11.696px;
  background: #fff;
  padding: 11px;
  display: flex;
  flex-direction: column;
  row-gap: 11px;
  height: 125px;
`;
export const CardHeadWrap = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;
export const LogoWrap = styled.div`
  border-radius: 50%;
  border: 0.731px solid #ececec;
  background: #f1f3f4;
  width: 23px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 14px;
  }
`;
export const BrandWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1px;
  h4 {
    color: #2e2e2e;
    font-size: 10.234px;
    font-weight: 400;
    font-family: Poppins;
    line-height: normal;
  }
  p {
    color: #555;
    font-size: 8.772px;
    font-weight: 300;
  }
`;
export const Title = styled.div`
  color: #1a0dab;
  font-size: 11.696px;
  font-weight: 400;
  line-height: normal;
`;
export const Description = styled.div`
  color: #2e2e2e;
  font-size: 10.234px;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: normal;
`;
export const PreviewText = styled.div`
  color: #525252;
  font-size: 10px;
  font-weight: 400;
  line-height: 150%; /* 15px */
`;
export const TargetAudience = styled.div`
  color: var(--black-100, #1c1c1c);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;
export const TargetAmount = styled.div`
  span {
    color: var(--black-100, #1c1c1c);
    font-size: 16px;
    font-weight: 600;
  }
  line-height: 20px; /* 125% */
  padding-top: 8px;
  font-weight: 400;
`;
export const TargetWrap = styled.div`
  padding: 21px 0 24px 0;
`;
export const Divider = styled.div`
  width: 61px;
  background: #e8e8e8;
  height: 1px;
  margin: 14px 0 16px 0;
`;
export const PublishButton = styled.div`
  border-radius: 7px;
  background: #ff711e;
  padding: 14px 28px;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px; /* 125% */
  display: flex;
  justify-content: center;
  cursor: pointer;
`;
