import React from 'react'
import { useReducer, createContext, useContext } from 'react'
import produce from 'immer'

const initialState = {
    keywordInput: '',
    keywordInputList: [],
    keywordInputListError: '',
    keywordInputErrorsObj: {},
    keywordList: [],
    keywordInputModalOpen: false,
    keywordTableOpen: false,
    keywordTrackingData:{

    },
    keywordTableList:[],
    adDistribution:[],
    activeCampaigns:{

    },
    topDomains:[],
    keywordDetails:{

    },
    heatMapData: []
}

const KeywordTrackingReducer = produce((draft, action) => {
    switch(action.type) {
        case 'SET_KEYWORD_INPUT':
            draft.keywordInput = action.value
            break
        
        case 'SET_KEYWORD_INPUT_LIST':
            draft.keywordInputList = action.value
            break

        case 'SET_KEYWORD_INPUT_LIST_ERROR':
            draft.keywordInputListError = action.value
            break

        case 'SET_KEYWORD_INPUT_ERROR_OBJ':
            draft.keywordInputErrorsObj = action.value
            break
        
        case 'SET_KEYWORD_LIST':
            draft.keywordList = action.value
            break

        case 'SET_KEYWORD_INPUT_MODAL_OPEN':
            draft.keywordInputModalOpen = action.value
            break

        case 'SET_KEYWORD_TABLE_OPEN':
            draft.keywordTableOpen = action.value
            break

        case 'SET_KEYWORD_TABLE_LIST':
            draft.keywordTableList = action.value
            break

        case 'SET_ADDISTRIBUTION_LIST':
            draft.adDistribution = action.value.adsDistribution
            draft.activeCampaigns = action.value.activeCampaigns
            break

        case 'SET_TOPDOMAINS_LIST':
            draft.topDomains = action.value
            break

        case 'SET_HEATMAP_DATA':
            draft.heatMapData = action.value
            break

        case 'SET_KEYWORD_TRACKING_DATA':
            draft.keywordTrackingData = action.value
            break
        default:
            break
    }
})

const useKeywordTrackingReducer = () => {
    return useReducer(KeywordTrackingReducer, initialState)
}

const KeywordTrackingContext = createContext()

export const KeywordTrackingProvider = ({ children }) => {
    const [state, dispatch] = useKeywordTrackingReducer()
    
    const value = {
        keywordTrackingState: state,
        keywordTrackingDispatch: dispatch
    }
    
    return <KeywordTrackingContext.Provider value={value}>
        {children}
    </KeywordTrackingContext.Provider>
}

export const useKeywordTracking = () => {
    return useContext(KeywordTrackingContext)
}
