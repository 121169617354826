import styled from 'styled-components';

export const ResultsContainer = styled.div`
    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    width: auto; */
    overflow-y: scroll;
    height: calc(100vh - 245px);
    background: #006ecc00;
    display: flex;
    margin-bottom: 76px;
    margin-top: 16px;
    .loader{
        height: 200px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    ::-webkit-scrollbar {
        width: 8px;               /* width of the entire scrollbar */
    }

    body::-webkit-scrollbar-track {
        /* background: red;        color of the tracking area */
    }
    ::-webkit-scrollbar-thumb {
        background-color: #80808030;    /* color of the scroll thumb */
        border-radius: 10px;     
    }
`;

export const CardContainer = styled.div`
    background: #FFFFFF;
    border:  ${props => props.detaislOpen ? "2px solid #006ECC" : "1px solid #F2F2F2"};
    border-radius: 12px;
    width: ${props => props.detaislOpen ? "520px": "250px"};
    height: 373px;
    position: relative;
    /* padding: 16px; */
`;

export const ResultsWraper = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
    max-width: 1350px;
    position: relative;
`;

export const AdContainer = styled.div`
    width: 250px;
`;

export const AdBodyContainer = styled.div`
    
`;

export const ProductName = styled.p`
    color: #1A0DAB;
    font-size: 14px;
    margin-bottom: 4px;
    line-height: 21px;
    overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2; 
   -webkit-box-orient: vertical;
   min-height: 41px;
   padding: 0px 16px;
`;

export const DetailsProductName = styled.div`
    padding: 0px 16px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
    }
    p{
        margin-top: 8px;
        color: #707070;
        font-size: 14px;
        line-height: normal;
        font-weight: 300;
    }
    
`;


export const ProductPrice = styled.div`
    margin-top: 16px;
    padding: 0px 16px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
    }
    p{
        margin-top: 8px;
        color: #707070;
        font-size: 14px;
        line-height: normal;
        font-weight: 300;
    }
`;

export const WebsiteUrl = styled.div`
    margin-top: 16px;
    padding: 0px 16px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
    }
    p{
        margin-top: 8px;
        color: #707070;
        font-size: 14px;
        line-height: normal;
        font-weight: 300;
        color: #1651C7;
        font-size: 14px;
        font-weight: 400;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        cursor: pointer;
    }
`;

export const DisplayLink = styled.div`
    margin-top: 16px;
    padding: 0px 16px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
    }
    p{
        margin-top: 8px;
        color: #707070;
        font-size: 14px;
        line-height: normal;
        font-weight: 300;
    }
`

export const AdBodyBottomContainer = styled.div`
    border-top: 1px solid #F2F2F2;
    padding: 16px 0px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`
export const AdDate = styled.div`
    display: flex;
    font-size: 14px;
    color: #202124;
    line-height: 22px;
    align-items: center;
    padding: 0px 16px;
    /* margin-bottom: 16px; */

    img{
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
`;

export const AdDetailsDate = styled.div`
    margin-top: 16px;
    padding: 0px 16px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
    }
    p{
        margin-top: 8px;
        color: #707070;
        font-size: 14px;
        line-height: normal;
        font-weight: 300;
    }

    display: flex;
    flex-direction: column;
`;

export const AdCountries = styled.div`
    display: flex;
    font-size: 14px;
    color: #202124;
    line-height: 22px;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    padding: 0px 16px;
    p{
        color: #919191;
        margin-right: 16px;
    }
    > img{
        width: 16px;
        height: 16px;
        /* margin-right: 8px; */
    }
`;

export const AdDetailsCountries = styled.div`

    display: flex;
    flex-direction: column;
    
    margin-top: 16px;
    padding: 0px 16px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
    }
    p{
        margin-top: 8px;
        color: #707070;
        font-size: 14px;
        line-height: normal;
        font-weight: 300;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        flex-wrap: wrap;
        gap: 8px;
    }

    span{
        color: #707070;
        margin-right: 16px;
        border-radius: 8px;
        background: #F9F9F9;
        padding: 6px 12px;
    }
`;

export const AdKeywords = styled.div`
    
    display: flex;
    flex-direction: column;
    
    margin-top: 16px;
    padding: 0px 16px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
    }
    p{
        margin-top: 8px;
        color: #707070;
        font-size: 14px;
        line-height: normal;
        font-weight: 300;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        flex-wrap: wrap;
        gap: 8px;
    }

    span{
        color: #707070;
        margin-right: 16px;
        border-radius: 8px;
        background: #F9F9F9;
        padding: 6px 12px;
    }
`;

export const SeeMoreMoreCountries = styled.div`
    cursor: pointer;
    color: #504e4e;
    margin-top: 8px;
`;

export const SeeMoreMoreKeywords = styled.div`
    cursor: pointer;
    color: #504e4e;
    margin-top: 8px;
`;

export const AveragePosition = styled.div`
    padding: 0px 16px;
    margin-bottom: 16px;
    font-size: 14px;
    display: flex;
    gap: 8px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
    }
    p{
        color: #707070;
        font-size: 14px;
        line-height: normal;
        font-weight: 300;
    }
`;

export const DetailsButton = styled.div`
    color: #006ECC;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    height: 32px;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 16px 16px 16px;
`;

export const DetailsContainer = styled.div`
    width: 520px;
    height: 100%;
    display: flex;
`;

export const Left = styled.div`
    position: relative;
    width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid #F2F2F2;
`;

export const Right = styled.div`
    width: 270px;
    height: 100%;
`;

export const DetailsHeading = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 20px;
    color: #2E2E2E;
    font-size: 14px;
    font-weight: 500;
`;

export const CloseButton = styled.div`
    cursor: pointer;
    margin-right: 8px;
    font-size: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const DetailsWraper = styled.div`
    overflow-y: auto;
    height: 300px;
    padding-bottom: 16px;
    margin-right: 10px;
    ::-webkit-scrollbar {
        width: 4px;               /* width of the entire scrollbar */
    }

    body::-webkit-scrollbar-track {
        /* background: red;        color of the tracking area */
    }
    ::-webkit-scrollbar-thumb {
        background-color: #80808047;    /* color of the scroll thumb */
        border-radius: 10px;     
    }
`;

export const DetailsImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    padding: 16px 16px 0px 16px;
    height: 80%;
    img{
        height: 190px;
        object-fit: scale-down;
    }
`; 

export const ImageContainer = styled.div`
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    padding: 16px 16px 0px 16px;
    img{
        height: 120px;
        object-fit: scale-down;
    }
`;

export const FloatingButtonsContainer = styled.div`
    position: absolute;
    right: 12px;
    top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F9F9F9;
    color: #D4D4D4;
    font-size: 8px;
`;

export const Favorite = styled.div`
    background: #F9F9F9;
    width: 32px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    border-radius: 4px 0px 0px 4px;
    img{
        margin: 25%;
    }
    &:hover{
        background: #ECECEC;
    }
    &:active{
        background: #DCEEFF;
    }
    
`;



export const NoResults = styled.div`
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
        width: 200px;
        margin-bottom: 32px;
    }
    h2{
        font-size: 22px;
        font-weight: 500;
        color: #505050;
        text-align: center;
        margin-bottom: 12px;
    }
    p{
        font-size: 14px;
        font-weight: 300;
        color: #4E4E4E;
        max-width: 360px;
        text-align: center;
    }
    a{
        font-size: 14px;
        font-weight: 300;
        color: #006ECC;
        text-align: center;
        margin-top: 16px;
    }
`;

