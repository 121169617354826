import styled from "styled-components";
import backgroundSheet from "./components/InputPage/assets/background.png";

export const ImageAdGeneration = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  /* align-items: center; */
  overflow-y: auto;
  background: url(${backgroundSheet});
  background-size: cover;
  background-position: center;
  justify-content: center;
`;
