import React, {} from 'react'
import { useSearchParams } from 'react-router-dom'

// Styles
import {
    PaginationContainer
} from "./index.styled"

import { useKeywordAnalysis } from '../../../context/keywordanalysis'; 
import ReactPaginate from 'react-paginate';

// Icons
// import RightArrow from "../../../../../../../media/Dashboard/right-arrow.png"

const Pagination = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const {keywordAnalysisState, keywordAnalysisDispatch} = useKeywordAnalysis()

    const handleSpecificPage = (e) => {
        keywordAnalysisDispatch({type:'SET_AD_DETAILS', value: {}})
        keywordAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: false})
        keywordAnalysisDispatch({ type: "SET_CURRENT_PAGE_NO", value: e.selected + 1 })
        searchParams.set("page", e.selected + 1)
        setSearchParams(searchParams)
    }
    
    return <PaginationContainer 
    prevState={keywordAnalysisState.currentPageNo == 1}
    nextState={keywordAnalysisState.currentPageNo == Math.ceil((keywordAnalysisState.totalAds / 20))}>
        <ReactPaginate
            breakLabel="..."
            forcePage={keywordAnalysisState.currentPageNo - 1}
            nextLabel="next >"
            onPageChange={handleSpecificPage}
            pageRangeDisplayed={3}
            pageCount={(keywordAnalysisState.totalAds / 20)}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
        />
  </PaginationContainer>

}

export default Pagination
