import styled from "styled-components";

export const Container = styled.div`
    height: 100%;
    width: 35px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 20px 20px 0px; */
`;

export const NotificationHead = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    border-radius: 6px;
    width: 35px;
    height: 35px;
    justify-content: center;
    user-select: none;
    img{
        height: 28px;
    }
    &:hover{
        background: #3333330d;
    }
    &:active{
        background: #3333331f;
    }
`;

export const Count = styled.div`
    position: absolute;
    top: 2px;
    right: -1px;
    font-size: 10px;
    font-weight: 700;
    color: rgb(255, 255, 255);
    padding: 2px;
    background: rgb(255, 30, 57);
    border-radius: 10px;
    width: 18px;
    height: 17px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
`;

export const NotificationBody = styled.div`
    width: 432px;
    height: 592px;
    position: absolute;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.32);
    top: 60px;
    right: 10px;
    z-index: 4;

`
export const BodyHeader = styled.div`
    padding: 20px;
    display: flex;
    justify-content: space-between;
`;

export const Content = styled.div`
    height: 500px;
    overflow-y: auto;
    margin: 0px 10px 20px 20px;
    padding-right: 7px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const ToggleContainer = styled.div`
    display: flex;
    border-radius: 8px;
    /* outline: 1px solid #ECECEC; */
    background: #F6F6F6;
    cursor: pointer;
    width: fit-content;
`;


export const ToggleOptionContainer = styled.div`
    border-radius: 8px;
    /* border: ${props => props.active ? "1px solid #B8D9F5": "none"}; */
    background: ${props => props.active ? "#404040": "#F6F6F6"};
    font-size: 14px;
    color: ${props => props.active ? "#FFFFFF" : "#404040" };
    line-height: normal;
    padding: 6px 12px;
    font-weight: 400;
    pointer-events: ${props => props.disabled ? "none" : "auto"};
    label{
        display: flex;
        flex-direction: row;
    }
    p{
        cursor: pointer;
    }
    span{
        width: 50px;
        text-align: center;
        margin-left: 5px;
    }
    &:hover{
        background: ${props => props.active ? "#404040": "#ECECEC"};
    }
    
    &:active{
        background: ${props => props.active ? "#B3B3B3": "#E6E6E6"};
    }
`;

export const MessageContainer = styled.div`
    /* padding: 12px 16px; */
    border-radius: 8px;
    border: ${props => props.unread ? "1px solid #E0F1FF": "1px solid #F2F2F2"};
    background: ${props => props.unread ? "#EBF6FF": "#FFF"};
    cursor: pointer;
    &:hover{
        .notification-status{
            display: flex;
        }
    }
`;

export const MessageContentContainer = styled.div`
    padding: 12px 16px 8px;
    display: flex;
    justify-content: space-between;
    p{
        width: 95%;
        font-size: 14px;
        font-weight: 300;
        line-height: normal;
        color: #2E2E2E;
    }
    span{
        height: 10px;
        width: 10px;
        background: #006ECC;
        border-radius: 6px;
    }
    img{
        height: 25px;
        margin-right: 10px;
    }
`;

export const MessageFooter = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0px 16px 8px;
    align-items: center;
    height: 38px;
`;

export const Time = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    color: #808080;
`;

export const ReadStatus = styled.div`
    width: 32px;
    height: 30px;
    border-radius: 8px;
    display: none;
    justify-content: center;
    align-items: center;
    &:hover{
        background: ${props => props.unread ? "#CDE4F7" : "#E3E3E3"};
    }
    &:active{
        background: ${props => props.unread ? "#C4DFF5" : "#DBDBDB"};
    }
`;

export const MarkAsRead = styled.div`
    border-radius: 8px;
    color: #4D4D4D;
    background: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    opacity: ${props => props.isDisabled ? "50%": "100%"};

    img{
        height: 18px;
        margin-right: 5px;
        padding-bottom: 2px;
    }

    p{
        font-size: 14px;
        color: #4D4D4D;
        font-weight: 300;
        line-height: normal;
    }

    &:hover{
        background: ${props => props.isDisabled ? "transparent": "#F2F2F2"};
    }
    &:active{
        background: ${props => props.isDisabled ? "transparent": "#ECECEC"};
    }
`;

export const YellowBellContainer = styled.div`
    width: 120px;
    height: 120px;
    background: #F2F2F2;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
`;

export const NoNotification = styled.div`
    width: 280px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3{
        color: #646464;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 12px;
    }
    p{
        color: #767676;
        text-align: center;
        font-size: 14px;
        font-weight: 300;
        line-height: normal;
    }
`;