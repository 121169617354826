import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAccount } from "../../context/account";
import {
  ContentHeading,
  PlanDetailsContainer,
  DetailsContainer,
  ContentBody,
  UpgradeButton,
} from "./index.styled";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";
import { useGetAccount } from "../../../../hooks/useGetAccount";
import { useGetUserInfo } from "../../../../hooks/useGetUserInfo";
import { useAuth } from "../../../../../context/auth";

// const userPlan = () => {
//     const config = {
//         url: "/payment/user-info",
//         method: "GET"
//     }

//     return appClient(config)
// }

const MySubscription = () => {
  const { accountState, accountDispatch } = useAccount();
  const { authState, authContext } = useAuth()
  const location = useLocation();
  const { getRemainingCreditsMutate, isRemainingCreditsLoading, canAccessFeature } = useGetUserInfo()

  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const accountInfoHook = useGetAccount();

  // const {mutate: getUserPlanData, isLoading: isPlansDataLoading } = useMutation(userPlan, {
  //     onSuccess: (data) => {
  //         accountDispatch({type: 'UPDATE_USER_DATA', value: data})
  //         accountDispatch({type: 'UPDATE_IS_USER_DATA_LOADING', value: false})
  //         // setUserData(data)
  //         // toast.dismiss(toastId)
  //     },
  //     onError: (err) => {
  //         // console.log(err)
  //         setErrorMessage(err.response.data.errors[0].message)
  //         // toast.dismiss(toastId)
  //     },
  //     onMutate: () => {
  //         // toastId = toast.loading("Loading...")
  //     }
  // })

  const getDaysLeft = (planEndDate) => {
    let endDate = new Date(planEndDate).getTime() / 1000;
    let currentDate = Date.now() / 1000;

    const timestamp = endDate - currentDate; // Replace with your timestamp in seconds
    const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

    const date = new Date(timestamp * 1000); // Convert the timestamp to milliseconds

    // Calculate the number of days
    const days = Math.floor(date.getTime() / millisecondsPerDay);

    return days;
  };

  useEffect(() => {
    accountDispatch({ type: "UPDATE_IS_USER_DATA_LOADING", value: true });
    // getUserPlanData()
    // accountInfoHook.getUserAccountInfo();
     getRemainingCreditsMutate()
  }, []);

  // useEffect(() => {
  //   if (location.pathname.includes("subscription")) {
  //     accountDispatch({ type: "UPDATE_CURRENT_PAGE", value: "subscription" });
  //   }
  // }, []);


  useEffect(() => {
    accountDispatch({ type: "SET_CURRENT_PAGE", value: "subscription" })
}, [])

console.log(authState)

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>My Subscription | AdSpyder</title>
        <meta name="title" content="My Subscription | AdSpyder" />
        <meta
          name="description"
          content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. "
        />
      </Helmet>
      <ContentHeading>Subscription Plans</ContentHeading>
      <ContentBody>
        <PlanDetailsContainer>
          <label>My Plan<span>:</span></label>
          {isRemainingCreditsLoading ? (
            <Skeleton />
          ) : (
            <p>
              {authState.plan.planName == "free"
                ? "Free"
                : authState.plan.planName}{" "}
              {
                <UpgradeButton onClick={() => navigate("/account/plans-billing")}>
                  Upgrade
                </UpgradeButton>
              }
            </p>
          )}
        </PlanDetailsContainer>
        {/* <DetailsContainer>
          <label>Platforms<span>:</span></label>
          {accountState.isUserDataLoading ? (
            <Skeleton />
          ) : (
            <p>
              <span>
                {accountState.userData.allowedPlatforms.length > 1
                  ? "All 11 ad platforms"
                  : accountState.userData.allowedPlatforms[0]}
              </span>
            </p>
          )}
        </DetailsContainer>
        {accountState.userData.type == "free" && (
          <DetailsContainer>
            <label>Searches<span>:</span></label>
            {accountState.isUserDataLoading ? (
              <Skeleton />
            ) : (
              <p>
                <span>
                  {accountState.userData.allowedSearches -
                    accountState.userData.searchesMade}
                </span>{" "}
                out of {accountState.userData.allowedSearches} left
              </p>
            )}
          </DetailsContainer>
        )}
        <DetailsContainer>
          <label>Ad copies<span>:</span></label>
          {accountState.isUserDataLoading ? (
            <Skeleton />
          ) : (
            <p>
              <span>
                {accountState.userData.allowedAdViews -
                  accountState.userData.adsViewed}
              </span>{" "}
              out of {accountState.userData.allowedAdViews} left
            </p>
          )}
        </DetailsContainer>
        <DetailsContainer>
          <label>Plan validity<span>:</span></label>
          {accountState.isUserDataLoading ? (
            <Skeleton />
          ) : (
            <p>
              {getDaysLeft(accountState.userData.planValidity) < 0 ? (
                "Expired"
              ) : (
                <>
                  <span>{getDaysLeft(accountState.userData.planValidity)}</span>{" "}
                  days{" "}
                  {accountState.userData.type == "free"
                    ? "free"
                    : accountState.userData.type == "subscription"
                    ? accountState.userData.isTrialing
                      ? "trialing"
                      : "(Renews automatically)"
                    : ""}
                </>
              )}
            </p>
          )}
        </DetailsContainer> */}
         <DetailsContainer>
          <label>Credits<span>:</span></label>
          {isRemainingCreditsLoading ? (
            <Skeleton />
          ) : (
            <p>
              <span>
                {authState.plan.creditLimit - authState.plan.creditsSpent
                  }
              </span>{" "}
              out of {authState.plan.creditLimit} left
            </p>
          )}
        </DetailsContainer>
        <DetailsContainer>
          <label>Status<span>:</span></label>
          {isRemainingCreditsLoading ? (
            <Skeleton />
          ) : (
            <p>
              <span>
              {authState.plan.status}
              </span>
            </p>
          )}
        </DetailsContainer>
      </ContentBody>
    </>
  );
};

export default MySubscription;
