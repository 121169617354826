import React from 'react'
import { useReducer, createContext, useContext } from 'react'

import produce from 'immer'

const initialState = {
    currentPage: "home",
    curentDetailsTab: "Preview"
}

const imageAdGeneratorReducer = produce((draft, action) => {
    switch(action.type) {
        case "SET_CURRENT_DETAILS_TAB":
            draft.curentDetailsTab = action.value
        break

        default: 
        break
    }
})

const useImageAdGeneratorReducer = () => {
    return useReducer(imageAdGeneratorReducer, initialState)
}

const ImageAdGeneratorContext = createContext()

export const ImageAdGeneratorProvider = ({children}) => {
    const [state, dispatch] = useImageAdGeneratorReducer()
    const value = { imageAdGeneratorState: state, imageAdGeneratorDispatch: dispatch }

    return <ImageAdGeneratorContext.Provider value={value}>
        {children}
    </ImageAdGeneratorContext.Provider>
}

export const useImageAdGenerator = () => {
    return useContext(ImageAdGeneratorContext)
}