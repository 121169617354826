import styled from "styled-components";

export const Content = styled.div`
  width: 1065px;
  height: 480px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #f2f2f2;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  margin-top: 32px;
`;

export const ContentHeader = styled.div`
  width: 1063px;
  height: 56px;
  flex-shrink: 0;
  background: ${(props) => (!props.forCompetitor ? "#E6F3FF" : "#EDE7FB")};
  position: relative;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  border: 1px solid #fff;
  border-bottom: 1px solid rgb(227, 227, 227);
  padding: 16px;
`;

export const TaskNo = styled.div`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  background: #fff;
  border: 1px solid #bfbfbf;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #111926;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  border-radius: 50%;
`;

export const HeaderTitle = styled.span`
  color: #343e56;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
`;

export const Length = styled.span`
  color: #5a5a5a;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
`;

export const CornerImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`;

export const CloseIcon = styled.img`
  position: absolute;
  z-index: 2;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  cursor: pointer;
`;

export const Completed = styled.img``;

export const SelectingPanel = styled.div`
  height: 424px;
  width: 100%;
  padding: 20px 24px 24px 24px;
  display: flex;
  gap: 25px;
`;

export const ChoicesWindow = styled.div`
  width: 496px;
  height: 380px;
  flex-shrink: 0;
  border-radius: var(--8, 8px);
  border: 1px solid #dadfe4;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  opacity: ${(props) => (props.isActive ? '0.4' : "")};
  pointer-events: ${(props) => (props.isActive ? 'none' : "")};

`;

export const ChoicesList = styled.div`
  width: 489px;
  height: 348px;
  flex-shrink: 0;
  border-radius: var(--8, 8px);
  // border: 1px solid #DADFE4;
  background: #f5f5f5;
  padding: 0 12px 0 24px;
  overflow: hidden auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  ::-webkit-scrollbar-track {
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
    background-color: #efefef;
    border-radius: 10px;
  }

  ::-webkit-scrollbar {
    width: 5px;
    background-color: rgb(245 245 245 / 92%);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #e0e0e0;
    border-radius: 10px;
  }
`;

export const ChoiceContainer = styled.div`
  width: 448px;
  height: 70px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  padding: 14px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const Choice = styled.div`
  color: ${(props) => (props.isSelected ? "#0070FF" : "#4F4F4F")};
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const NotFound = styled.div`
  color: #4F4F4F;
  font-size: 15px;
  font-weight: 500;
  margin-top: 120px;
  display: flex;
  text-align: center;
  row-gap: 5px;
  flex-direction: column;
`;
export const ChoiceDetail = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 9px;
`;

export const Pair = styled.div``;

export const Key = styled.span`
  color: rgba(28, 28, 28, 0.4);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.26px;
`;

export const Value = styled.span`
  color: rgba(28, 28, 28, 0.7);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const CheckBox = styled.div`
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  border-radius: 4px;
  border: ${(props) => (props.isChecked ? "none" : "1px solid #D0D4D9")};
  background: ${(props) => (!props.isChecked ? "#FFF" : "#0070FF")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SelectedWindow = styled.div`
  width: 496px;
  height: 380px;
  flex-shrink: 0;
  border-radius: var(--8, 8px);
  border: 1px solid #dadfe4;
  background: ${(props) => (props.forCompetitor ? "#FAF8FF" : "#F3F9FF")};
  padding: 16px 24px 16px 24px;
  overflow: hidden auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
`;

export const SelectedList = styled.div`
  width: 448px;
  height: 245px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  padding: 24px;
`;

export const SelectingInstruction = styled.div`
  color: rgba(28, 28, 28, 0.4);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 21px;
`;

export const SelectedKeywordsList = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  height: 100%;
  overflow-y: auto;
`;

export const SelectedKeyword = styled.div`
  display: inline-flex;
  height: 29.07px;
  padding: 3.876px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 5.814px;
  background: #eff8ff;
  color: #222;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const Close = styled.img`
  cursor: pointer;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 14px;
  width: 100%;
`;

export const SetupButton = styled.div`
  display: inline-flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--8, 8px);
  background: ${(props) => (props.isActive ? "#111926" : "#4F4F4F")};
  opacity: ${(props) => (props.isActive ? "1" : "0.3")};
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  pointer-events: ${(props) => (props.isActive ? "unset" : "none")};
  cursor: pointer;
  img {
    height: 20px;
  }
`;

export const KeywordInputBox = styled.div`
  height: 38px;
  width: 100%;
  margin-top: 5px;
  input {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    padding: 2px 10px;
    border-radius: 4px;
  }
`;

export const LoaderContainer = styled.div`
  width: 300px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  h2 {
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
  }
  span {
    font-size: 14px;
    color: grey;
    margin-top: 4px;
    font-weight: 400;
  }
`;
