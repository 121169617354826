import styled from "styled-components";

export const BrandDescriptionContainer = styled.div`
    width: 738px;
    height: 246px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #FFF;
    padding: 30px 32px;
`;

export const Label = styled.div`
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    /* Large (default)/Subheadline */
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    letter-spacing: 0.15px;
    margin-bottom: 11px;
`;

export const Description = styled.textarea`
    resize: none;
    padding: 16px 20px;
    color: rgba(28, 28, 28, 0.90);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
    width: 632px;
    height: 139px;
    flex-shrink: 0;
    border-radius: 8px;
    border: ${(props) => props.isFocussed ? "1px solid #FFB258" : "1px solid #D0D5DD"};
    outline: none;
    rows: 6;
`;