import React from "react";

import { Heading, CtaContainer, Cta, AudienceContainer, DemograpicDataContainer, PsychograpicDataContainer, Wrapper, Divider, FunnelContentWrapper, KeywordsContainer } from "./index.styled";
import AdCopies from "./../AdsCopies";
import { useUrlAnalysis } from "../../../context/urlanalysis";

const FunnelContent = () => {
    const { urlAnalysisState, urlAnalysisDispatch } = useUrlAnalysis()

    // useEffect(() => {
    //     urlAnalysisDispatch({type: 'SET_INITIAL_FUNNEL_LOADING', value: true})
    // }, [])
    
    if(urlAnalysisState.selectedFunnelSubFilter == "Ad Copies"){
        return <FunnelContentWrapper>
        <Heading>{urlAnalysisState.adCopyPlatform} Search ads</Heading>
        <AdCopies isDataLoading={urlAnalysisState.loadingFunnelAds}/>
        </FunnelContentWrapper>
    }else if(urlAnalysisState.selectedFunnelSubFilter == "CTAs"){
        return <FunnelContentWrapper>
        <Heading>Call To Action</Heading>
        <CtaContainer>
            {
                urlAnalysisState.funnelCtaData.map(function(key,index) {
                    return <Cta>
                        {key.name} <span>{key.count}</span>
                     </Cta>
                })
                // [...Array(15)].map((ctatext,idx)=>{
                //     return <Cta>
                //         SignUp Now <span>{idx}</span>
                //     </Cta>
                // })
            }
        </CtaContainer>
        </FunnelContentWrapper>
    }else if(urlAnalysisState.selectedFunnelSubFilter == "Target Audience"){
        return <FunnelContentWrapper>
        <AudienceContainer>
            <DemograpicDataContainer>
                <Heading>Demographic Analysis</Heading>
                <Wrapper>
                    {
                        urlAnalysisState.funnelTargetAudienceData.demographicAudience.map(function(key,index) {
                            return <Cta>
                                {key.name} <span>{key.count}</span>
                             </Cta>
                        })
                        // [...Array(45)].map((ctatext,idx)=>{
                        //     return <Cta>
                        //         Online betters <span>{idx}</span>
                        //     </Cta>
                        // })
                    }
                </Wrapper>
            </DemograpicDataContainer>
            <Divider/>
            <PsychograpicDataContainer>
                <Heading>Psychographic Analysis</Heading>
                <Wrapper>
                    {
                        urlAnalysisState.funnelTargetAudienceData.psychographicAudience.map(function(key,index) {
                            return <Cta>
                                {key.name} <span>{key.count}</span>
                             </Cta>
                        })
                        // [...Array(35)].map((ctatext,idx)=>{
                        //     return <Cta>
                        //         Entertainment seekers <span>{idx}</span>
                        //     </Cta>
                        // })
                    }
                </Wrapper>
            </PsychograpicDataContainer>
        </AudienceContainer>
        
        </FunnelContentWrapper>
    }else if(urlAnalysisState.selectedFunnelSubFilter == "Important Keywords"){
        return <FunnelContentWrapper>
        <Heading>Important Keywords</Heading>
        <KeywordsContainer>
            {
                urlAnalysisState.funnelKeywordsData.map(function(key,index) {
                    return <Cta>
                        {key.name} <span>{key.count}</span>
                     </Cta>
                })
                // [...Array(28)].map((ctatext,idx)=>{
                //     return <Keyword>
                //         Bet360 <span>{idx}</span>
                //     </Keyword>
                // })
            }
        </KeywordsContainer>
        </FunnelContentWrapper>
    }
}

export default FunnelContent



