import { Container, Icon, Next, Placeholder, Url, UrlBox, UrlInput } from "./index.styled";
import globe from "./assets/globe.svg"
import { useAuth } from "../../../../../../../context/auth";
import { useRef } from "react";
import { useAdGenerator } from "../../../../context/adGeneration";
import { useTextAdGenInfo } from "../../../../hooks/useTextAdGenInfo";
import ValidateAndAddHttps from "../../../../../../../utils/urlValidate"
import { customToast } from "../../../../../../../utils/toast-utils";

function DestinationUrl({onNext}) {
    const { authState: {activeProjectInfo, defaultProjectInfo} } = useAuth()
    const { adGeneratorState, adGeneratorDispatch } = useAdGenerator()
    const inputRef = useRef()
    const website = activeProjectInfo?.website ?? defaultProjectInfo.website
    const handleNext = () => {
        const {value} = inputRef.current 
        const stateValue = value !== "" ? `${website}/${value}` : website
        // const stateValue = ValidateAndAddHttps(value)
        // if(stateValue){
            adGeneratorDispatch({
                type: "SET_DESTINATION_URL",
                value: stateValue
            })
            onNext()
        // }else{
        //     customToast({ message: "Not a valid url", type: "error" });
        // }

    }

    return ( 
        <Container>
            <UrlBox>
                <Icon src={globe} draggable={false} />
                <Url>
                    <Placeholder>{website}/</Placeholder>
                    <UrlInput ref={inputRef} />
                </Url>
            </UrlBox>
            <Next onClick={handleNext} >Next</Next>
        </Container>
     );
}

export default DestinationUrl;