import { InputWrapper, NoConnectedBtn, NoConnectedDescription, NoConnectedDiv } from "./NoAdConnected.styled";
import { useAdManagement } from "../../context/AdManagementContext";

function NoAdConnected() {
    const { adManagementState, adManagementDispatch } = useAdManagement()

    const connectFunction = () =>{
        adManagementDispatch({type: 'SET_SELECT_A_PLATFORM', value: true})
        adManagementDispatch({type: 'SET_INPUT_PAGE', value: false})
    }

    return ( 
           
        <InputWrapper>
            <NoConnectedDiv>
                You dont have any ad account connected!!
            </NoConnectedDiv>
            <NoConnectedDescription>
                Connect and manage your google, facebook, linkedin +15 other platform
                ad campaigns within adspyder
            </NoConnectedDescription>
            <NoConnectedBtn onClick={connectFunction}>Connect account</NoConnectedBtn>
            {/* <AdsInfoWrapper>To help you get started with Google Ads, we’ll give you ₹20,000 in free ad credit when you spend ₹20,000.
                <a>Learn more</a>
            </AdsInfoWrapper> */}
        </InputWrapper>
               
    );
}

export default NoAdConnected;