import styled from "styled-components";

export const AccountDataWrap = styled.div`
  padding-top: 24px;
  display: flex;
  gap: 16px;
  @media screen and (max-width: 1400px) {
    flex-direction: column;
  }
`;
export const AccountDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
   @media screen and (max-width: 1400px) {
    flex-direction: row;
  }
`;
export const AccountDataDiv = styled.div`
  border-radius: var(--8, 8px);
  background: #fff;
  width: 294px;
  /* height: 172px; */
  padding: 28px;
  p {
    color: rgba(28, 28, 28, 0.5);
    font-size: 14px;
    font-weight: 500;
    line-height: 150%; /* 21px */
    margin-bottom: 20px;
  }
`;
export const SpendValue = styled.div`
  color: var(--black-100, #1c1c1c);
  font-size: 26px;
  font-weight: 500;
  line-height: 38px; /* 146.154% */
  margin-bottom: 8px;
`;
export const IncreaseWrap = styled.div`
  color: #a9a9a9;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%; /* 21px */
  display: flex;
  align-items: center;
  span {
    padding: 0 8px 0 2px;
    color: #0caa2c;
  }
`;
