import React, { useState, useEffect, useRef } from 'react'
import { Container, Down, Row, Drop, Wrapper, ToolTipImageContainer, ToolTip, Text, EventSearchBarContainer } from './index.styled'
import { useNavigate } from 'react-router-dom'

import DropDownArrow from '../../../../../../../../media/Dashboard/DropDownArrowGrey.svg'
import ToolTipLogo from '../../../../../../../../media/Dashboard/tooltip.svg'

import { useLandingPageAnalysis } from "../../../../../context/landingPageAnalysis";
import { RightIcons } from '../../../../index.styled'
import {eventsList} from "../../../../../../../../utils/eventsList"

function useOutsideAlerter(ref, setShow) {
    useEffect(() => {
      function handleClickOutside(event) {
        console.log(ref,'refffff',event)
        if (ref.current && !ref.current.contains(event.target)) {
            setShow(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref])
}

const EventsList = eventsList

const PlatformDropdown = ({urlData, updateUrlData, idx}) => {
    // const wrapperRef = useRef(null)
    const [filteredEvents, setFilteredEvents] = useState([])
    const [show, setShow] = useState(false)
    const [eventSearch, setEventSearch] = useState("")
    const [fixedElementPosition, setFixedElementPosition] = useState({ top: 0, left: 0, right: 0, bottom: 0 });
    const {landingPageAnalysisState, landingPageAnalysisDispatch} = useLandingPageAnalysis()

    const navigate = useNavigate()
    const targetRef = useRef(null)
    useOutsideAlerter(targetRef, setShow)
    
let updatePosition = () => {
    if (targetRef.current) {
      const rect = targetRef.current.getBoundingClientRect();
    //   console.log(rect,'rectrect')
      setFixedElementPosition({
        top: rect.top,
        left: rect.left,
        right: rect.right,
        bottom: rect.bottom,
      });

    }
  };

useEffect(() => {
  // Initial position update
  updatePosition();

  // Listen for resize or scroll events if the position might change dynamically
  window.addEventListener('resize', updatePosition);
  window.addEventListener('scroll', updatePosition);

  // Cleanup the event listeners when the component is unmounted
  return () => {
    window.removeEventListener('resize', updatePosition);
    window.removeEventListener('scroll', updatePosition);
  };
}, []); // 

const FilterEvents = (value) => {
  let finalEvents = EventsList.filter((event, idx)=>{
    return event.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
  })

  setFilteredEvents(finalEvents)
}

const events = eventSearch.length > 0 ? filteredEvents : EventsList

    return <Wrapper ref={targetRef} >
        <Container isDropdownOpened = {show} CountrySelected={urlData.url} error={!urlData.url} >
        
        <Drop error={urlData.urlError}  onClick={() => { 
            setShow(prev => !prev)
            updatePosition();
        }}>
            <Text platformSelected={!!urlData.event?.date} >{urlData.event?.date ? urlData.event.name : "Events"}</Text>
            <RightIcons>
              <img className='dropdown-arrow' src={DropDownArrow} />
              <ToolTipImageContainer
                  error={urlData.urlError}
                  className="tooltip-container">
                  <img src={ToolTipLogo}/>
                  <ToolTip>
                      Please select an Event
                  </ToolTip>
              </ToolTipImageContainer>

            </RightIcons>
        </Drop>
        </Container>
        <Down show={show} height={30} left={fixedElementPosition.left} top={fixedElementPosition.top+52} className='checking'>
          <EventSearchBarContainer>
            <input placeholder='Search Events' onChange={(e)=> {setEventSearch(e.target.value);FilterEvents(e.target.value)}}/>
          </EventSearchBarContainer>
            {
            events.map((data, index) => {
                return <Row key={index}
                    onClick={() => {
                        updateUrlData(idx,data)
                        setShow(false)
                    }}
                >
                    <p>{data.name}</p>
                </Row>
                })}
        </Down>
    </Wrapper>
   
    }

export default PlatformDropdown

