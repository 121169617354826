import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"

import BackArrowIcon from "../../../../../../media/Dashboard/backArrowGreyIcon.svg"
import {  LoadingScreen, Progress, BackToDashboard, LoadingScreenWrap} from "./index.styled";

const LandingLoader = () => {
    
    const [progress, setProgress] = useState(10);
    const [backToDashBoard, setBackToDashBoard] = useState(false);
    const navigate = useNavigate()
    
    useEffect(() => {
        const interval = setInterval(() => {
          if (progress + 10 <= 70) {
            setProgress(prevProgress => prevProgress + 10);
          } else {
            clearInterval(interval);
          }
        }, 2000);
    
        return () => {
            clearInterval(interval)
        }
    }, [progress]);

    const backToDashboardfn = ()=>{
        navigate(`/landing-page-analysis/new`)
    }
    
      return <LoadingScreenWrap> 
                <LoadingScreen>
                    <h2>Fetching Landing Page Snapshot. Please Wait...</h2>
                    <Progress value={progress} max={100}/>
                    <p>
                        Domain analysis in progress. We'll notify you once it's complete. Feel free to continue your 
                        work or return to  the dashboard in the meantime to have Instant insights, effortless analysis
                    </p>
                    {backToDashBoard && <BackToDashboard onClick={backToDashboardfn}>
                        <img src={BackArrowIcon}/>
                        Back to Dashboard
                    </BackToDashboard>}
                </LoadingScreen> 
                
                </LoadingScreenWrap>
}

export default LandingLoader


