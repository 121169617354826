import styled from "styled-components";

export const PopupBackground = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(28, 28, 28, 0.60);
    position: fixed;
    z-index: 12;
    top: 0;
    left: 0;
`;

export const Content = styled.div`
width: 528px;
height: 464px;
border-radius: 10px;
background: #FFF;
position: absolute;
top: 124px;
transform: translateX(-50%);
left: calc(50% + 115px);
`;

export const DetailContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    padding: 17px 24px;
`;

export const Header = styled.div`
    color: rgba(28, 28, 28, 0.50);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
`;

export const Close = styled.img`
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
`;

export const Template = styled.div`
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    border: 1px solid #DADFE4;
    background: url(${(props) => props.templateUrl}) lightgray 50% / cover no-repeat;
    margin-top: 9px;
`;

export const CategoryContainer = styled.div`
    display: inline-flex;
    padding: 6px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 3px;
    border: 1px solid #DADFE4;
    background: rgba(218, 223, 228, 0.20);
    margin-top: 8px;
`;

export const Label = styled.div`
    color: rgba(28, 28, 28, 0.50);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
`;

export const Category = styled.div`
    color: #1C1C1C;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
`;

export const Line = styled.div`
    width: 100%;
    height: 1px;
    background: rgba(218, 223, 228, 0.50);
    position: absolute;
    top: 197px;
    left: 0;
`;

export const SubNavbar = styled.div`
    position: absolute;
    top: 216px;
    display: flex;
    gap: 24px;
    margin-bottom: 16px;
`;

export const NavItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    gap: 8px;
`;

export const Item = styled.div`
    color: var(--black-100, #1C1C1C);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
`;


export const HighlightLine = styled.div`
    width: 100%;
    height: 4px;
    background: #FF711E;
    visibility: ${(props) => props.isActive ? "auto" : "hidden"};
`;

export const AdGoalContent = styled.div`
    width: 480px;
height: 166px;
flex-shrink: 0;
border-radius: 6px;
border: 1px solid #DADFE4;
background: rgba(218, 223, 228, 0.20);
position: absolute;
bottom: 28px;
color: #1C1C1C;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: 150%; /* 21px */
padding: 12px 16px;
cursor: default;
`;

export const TargetAudienceContent = styled.div`
    position: absolute;
    top: 270px;
`;

export const AudienceLabel = styled.div`
color: #1E1E1E;
font-feature-settings: 'clig' off, 'liga' off;
/* Large (default)/Subheadline */
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 133.333% */
letter-spacing: 0.15px;
margin-bottom: 8px;
`;


export const Choice = styled.div`
padding: 5px 16px;
color: #FFF;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%;
height: 31px;
border-radius: 40px;
background: #1679DB;
cursor: default;
margin-bottom: 14px;
text-align: center;
`;

export const BrandDetailsContent = styled.div`
    position: absolute;
    top: 270px;
`;

export const BrandLabel = styled.div`
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    letter-spacing: 0.15px;
    margin-bottom: 5px;
`;

export const Name = styled.div`
    padding: 9px 14px;
    overflow: hidden;
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 18px */
    width: 480px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid #DADFE4;
    background: rgba(218, 223, 228, 0.20);
    margin-bottom: 11px;
`;

export const Description = styled.div`
    padding: 12px 16px;
    color: #1C1C1C;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 18px */
    width: 480px;
    height: 91px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid #DADFE4;
    background: rgba(218, 223, 228, 0.20);
`;

export const AdTypeContent = styled.div`
    position: absolute;
    top: 270px;
`;

export const AdTypeContainer = styled.div`
    width: 158px;
    height: 120px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid #1679DB;
    background: rgba(22, 121, 219, 0.02);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: default;
`;

export const AdTypeIconContainer = styled.div`
    position: absolute;
    top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 30px;
    background: rgba(22, 121, 219, 0.02);
`;

export const AdTypeIcon = styled.img`
`;

export const AdTypeName = styled.div`
    color: #1679DB;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    position: absolute;
    top: 73px;
`;
