import { SearchInput, SearchWrapper, SelectAdInfo, SelectAdInfoWrap, SelectTableContainer } from "./SelectAccount.styled";
import searchIcon from '../../assets/searchIcon.svg';
import CampaignTable from './components/CampaignTable';
import { useAdManagementInfo } from "../../hooks/useAdManagementInfo";
import { useEffect } from "react";

function SelectAccount() {
    const adManagementHook = useAdManagementInfo()
    
    useEffect(()=>{
        adManagementHook.setRecentConnection()
    },[])
    return ( 
        <SelectTableContainer>
            <SelectAdInfoWrap>
                <SelectAdInfo>You can select only 1 account at a time.</SelectAdInfo>
                <SearchWrapper>
                    <SearchInput placeholder='Search'/>
                    <img src={searchIcon}/>
                </SearchWrapper>
            </SelectAdInfoWrap>
            <CampaignTable />
        </SelectTableContainer>
    );
}

export default SelectAccount;