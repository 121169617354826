import styled from "styled-components";

export const SelectOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const SelectPopup = styled.div`
  border-radius: 20px;
  background: #fff;
  width: 1120px;
  height: 700px;
`;
export const PopupHeaderWrap = styled.div`
  padding: 40px 24px 24px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    height: 24px;
    cursor: pointer;
  }
`;
export const PopupHeader = styled.div`
  color: var(--black-100, #1c1c1c);
  font-size: 20px;
  font-weight: 600;
  line-height: 24px; /* 120% */
`;
export const PopupContainer = styled.div`
  padding: 0 40px 40px 40px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const PopupDescrip = styled.div`
  color: #2e2e2e;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 21px */
`;
export const InfoText = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%; /* 21px */
`;
export const PopupContent = styled.div`
  height: 332px;
  border-radius: var(--8, 8px);
  border: 1px solid #e3e3e3;
  background: #fff;
`;
export const PopupTableHead = styled.div`
  border-radius: var(--8, 8px);
  display: flex;
  border-bottom: 1px solid #eaecf0;
  background: #f9f9f9;
  padding: 12px 24px;
  p {
    color: #667085;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px; /* 150% */
    &:nth-child(1),
    :nth-child(2) {
      width: 38.5%;
    }
    &:nth-child(3) {
      width: 23%;
      text-align: center;
    }
  }
`;
export const PopupTableRow = styled.div`
  display: flex;
  padding: 12px 24px;
  border-bottom: 1px solid #eaecf0;
  p {
    width: 38.5%;
    overflow: hidden;
    color: rgba(28, 28, 28, 0.8);
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    &#connectedStyle {
      width: 23%;
      justify-content: center;
      color: #667085;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-decoration-line: ${(props) => !props.isConnected && "underline"};
      display: flex;
      column-gap: 8px;
      cursor: ${(props) => !props.isConnected && "pointer"};
    }
  }
`;
export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 16px;
  padding-right: 56px;
  gap: 24px;
  align-items: center;
`;
export const RowsList = styled.div`
  color: #adadad;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  span {
    color: var(--Gray-700, #344054);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
`;
export const RightArrow = styled.div`
  border-radius: var(--8, 8px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--White, #fff);
  height: 36px;
  width: 36px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? "none" : "")};

  img {
    height: 20px;
    width: 20px;
    opacity: ${(props) => (props.disabled ? "40%" : 1)};
  }
`;
export const LeftArrow = styled.div`
  border-radius: var(--8, 8px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--White, #fff);
  height: 36px;
  width: 36px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? "none" : "")};
  img {
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
    opacity: ${(props) => (props.disabled ? "40%" : 1)};
  }
`;
export const TableWrap = styled.div`
  height: 220px;
`;
export const NoData = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
`;
export const TableLoader = styled.div``;
