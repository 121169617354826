import styled from 'styled-components'

export const Input = styled.input`
    /* test design */
    /* width: 400px;    */

    /* width: 486px; */
    height: 40px;
    background-color: #F7F9FA;
    border: 1px solid #ECECEC;
    box-sizing: border-box;
    padding: 0px 40px 0px 45px;
    outline: none;
    color: #2E2E2E;
    font-size: 14px;
    font-weight: 300;
    width: 99%;
    border: ${props => props.error ? "1px solid #FF5180" : "1px solid #ECECEC"};
    outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};
    &:focus{
        border: ${props => props.error ? "1px solid #FF5180" : "1px solid #5AB3FF"};
        outline: ${props => props.error ? "2px solid #FFB7CB" : "2px solid #B9DDFF"};
    }
`;


export const InputContainer = styled.div`
    position: relative;
    width: 100%;
    /* test design */
    height: 100%;

    img{
        position: absolute;
        left: 16px;
        top: 12px;
        height: 16px;
    }
`


export const ToolTipImageContainer = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
    height: 40px;
    width: 40px;
    display: ${props => props.error ? "flex": "none"};
    img{
        filter: contrast(1);
    }

    &:hover{
        > div{
            display: flex;
        }

        img{
            display: block;
        }
    }
`;

export const ToolTip = styled.div`
    background: #555555;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 300;
    position: absolute;
    bottom: -34px;
    padding: 4px 8px;
    width: 170px;
    display: none;
`;


export const ToolTipContainer = styled.div`
    font-size: 12px;
    font-weight: 300;
    color: #666;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;

    img{
        margin-left: 4px;
    }
`;

export const Wrapper = styled.div`
    width: 50%;
`;

export const PopularSearches = styled.div`
    margin: 5px 15px;
    label{
        font-size: 14px;
        color: #666666ba;
    }
    span{
        margin: 0px 5px;
        font-size: 14px;
        color: #00559d;
        cursor: pointer;
        text-decoration: underline;
        :hover{
            color: #006ecc;
        }
    }
`;