import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 15px;
`;
export const BackArrowWrap = styled.div`
  display: flex;
  img {
    cursor: pointer;
  }
`;
