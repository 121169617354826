import React from 'react'
import { useReducer, createContext, useContext } from 'react'
import produce from 'immer'

const initialState = {
    overviewCountry: {},
    overviewInput: '',
    relatedKeyword: [],
    topDomainOverview: [],
    activeCampaigns: {},
    heatMapData: [],
    keywordInputModalOpen: false,
    keywordInputList: [],
    keywordInputListError: '',
    keywordInputErrorsObj: {},
}

const KeywordOverviewReducer = produce((draft, action) => {
    switch(action.type) {
        case 'SET_OVERVIEW_COUNTRY':
            draft.overviewCountry = action.value
            break
        case 'SET_KEYWORD_OVERVIEW_INPUT':
            draft.overviewInput = action.value
            break
        case 'SET_RELATED_KEYWORD_INPUT':
            draft.relatedKeyword = action.value
            break
        case 'SET_TOPDOMAIN_KEYWORD':
            draft.topDomainOverview = action.value
            break
        case 'SET_ADDISTRIBUTION_OVERVIEW':
            draft.adDistribution = action.value.adsDistribution
            draft.activeCampaigns = action.value.activeCampaigns

            break
        case 'SET_ACTIVE_CAMPAIGNS':
            draft.activeCampaigns = action.value
            break
        case 'SET_HEATMAP_DATA':
            draft.heatMapData = action.value
            break
        case 'SET_KEYWORD_INPUT_MODAL_OPEN':
            draft.keywordInputModalOpen = action.value
            break
        case 'SET_KEYWORD_INPUT_LIST':
            draft.keywordInputList = action.value
            break

        case 'SET_KEYWORD_INPUT_LIST_ERROR':
            draft.keywordInputListError = action.value
            break
        
        case 'SET_KEYWORD_INPUT_ERROR_OBJ':
            draft.keywordInputErrorsObj = action.value
            break
            
        default:
            break
    }
})

const useKeywordOverviewReducer = () => {
    return useReducer(KeywordOverviewReducer, initialState)
}

const KeywordOverviewContext = createContext()

export const KeywordOverviewProvider = ({ children }) => {
    const [state, dispatch] = useKeywordOverviewReducer()
    
    const value = {
        keywordOverviewState: state,
        keywordOverviewDispatch: dispatch
    }
    
    return <KeywordOverviewContext.Provider value={value}>
        {children}
    </KeywordOverviewContext.Provider>
}

export const useKeywordOverview = () => {
    return useContext(KeywordOverviewContext)
}
