import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

import { GraphContainer, Header } from "./index.styled";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
// import { useDashboard } from "../../../context/dashboard";
import { useDashboardInfo } from "../../../hooks/useDashboardInfo";
import { useDashboard } from "../../../../../../context/dashboard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const KeywordVolumeChart = () => {
  const {
    getKeywordVolumeChartDataMutate,
    isGetKeywordVolumeChartDataLoading,
  } = useDashboardInfo();
  const {
    dashboardState: {
      selectedProject: { id },
      keywordChartData,
      trackingKeywords,
    },
  } = useDashboard();

  const [data, setData] = useState(null);
  const options = {
    borderRadius: 6,
    barPercentage: 0.35,
    categoryPercentage: 0.6,
    backgroundColor: "#8571F4",
    hoverBackgroundColor: "#634ed5",
    responsive: true,
    plugins: {
      legend: {
        position: "none",
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#373737",
        titleFontColor: "#ffffff", 
        bodyFontColor: "#ffffff", 
        titleFont: { size: "12", family: "Inter", weight: "500" }, 
        bodyFont: { family: "Inter" },
        padding: 8, 
        caretSize: 6, 
        borderWidth: 1, 
        cornerRadius: 4, 
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 6,
        },
      },
      y: {
        border: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 5,
          callback: function (value) {
            if (value >= 1000000) {
              return (value / 1000000).toFixed(1) + "M";
            } else if (value >= 1000) {
              return (value / 1000).toFixed(1) + "k";
            }
            return value;
          },
        },
      },
    },
  };

  useEffect(() => {
    getKeywordVolumeChartDataMutate({
      projectId: id,
    });
  }, [trackingKeywords]);

  useEffect(() => {
    if (keywordChartData) {
      const labels = keywordChartData.map((data) => {
        const month = data.month.slice(0, 3).toLowerCase();
        return `${month.charAt(0).toUpperCase() + month.slice(1)} ${data.year}`;
      });

      const dataSet = keywordChartData.map((data) => {
        return data.totalMonthlySearches;
      });

      setData({
        labels,
        datasets: [
          {
            data: dataSet,
          },
        ],
      });
    }
  }, [keywordChartData]);

  return (
    trackingKeywords.length > 0 && (
      <GraphContainer>
        <Header>
          <h2>Keyword Volume</h2>
          <p>Average Monthly searches</p>
        </Header>
        <div style={{ height: "calc(100% - 40px)" }}>
          {data && <Bar data={data} options={options} />}
        </div>
      </GraphContainer>
    )
  );
};

export default KeywordVolumeChart;
