import React from 'react'
import { Container, EditDiv, EditWrap, ProductDetails, ProductDetailsDiv, ProductDetailsWrap, ProductName, ProductNameWrap, ProductProgress, ProductProgressIn, ProductWrap, RefreshWrap, TrackingDiv, UpdatedDiv, UpdatedWrap, FlagLogo } from './index.styled'
import edit from '../../../../../../media/Dashboard/edit.svg'
import refresh from '../../../../../../media/Dashboard/refresh.svg'
import google from '../../../../../../media/Dashboard/google.svg'
import bing from '../../../../../../media/Dashboard/bing.svg'
import { useDomainTracking } from '../../../context/domaintracking'
import { countryList } from '../../../../../../utils/maps'
import { CircleFlag } from "react-circle-flags";

import DomainInputModal from '../../../DomainTrackingHome/components/DomainInputModal'
import { ResultsContainer } from '../../../DomainTrackingHome/index.styled'
import { countriesList } from '../../../../../../utils/countryData'

const Tracking = () => {
  const { domainTrackingState, domainTrackingDispatch } = useDomainTracking()

  const { domainTrackingData } = domainTrackingState;

  const getFlagEmoji = (countryName) => {
    let countryCode = countryList[countryName];
    if (countryCode) {
      return (
        <FlagLogo>
          <CircleFlag countryCode={countryCode.toLowerCase()} height="24" />
        </FlagLogo>
      );
    }
  };

  const findCountryByName = (name) => {
    const foundCountry = countriesList.find((country) => country.name.common === name)
    if(foundCountry) return foundCountry
    return null;
  }

  const togglePopup = () => {
    const countryNameToFind = domainTrackingState.domainTrackingData.country
    let country = {}

    if (countryNameToFind !== "global") {
      const foundCountry = findCountryByName(countryNameToFind)
      country = foundCountry || {}
    }

    domainTrackingDispatch({
      type: 'SET_DOMAIN_INPUT_LIST', value: [{ domain: domainTrackingState.domainTrackingData.hostname, platform: domainTrackingState.domainTrackingData.platform, country, platformError: '' }]
    })
    domainTrackingDispatch({ type: 'SET_DOMAIN_INPUT_MODAL_OPEN', value: true })
  }

  return (
    <Container>

      {
        domainTrackingState.domainInputModalOpen &&
        <ResultsContainer>
          <DomainInputModal isEditMode={true}/>
        </ResultsContainer>
      }

      <EditWrap>
        <TrackingDiv>Tracking Domain</TrackingDiv>
        <EditDiv onClick={togglePopup}><img src={edit} /></EditDiv>
      </EditWrap>
      <ProductWrap>
        <ProductNameWrap>
          <ProductName>{domainTrackingData.hostname}</ProductName>
          <UpdatedWrap>
            <RefreshWrap><img src={refresh} /></RefreshWrap>
            <UpdatedDiv>updated 20hrs ago</UpdatedDiv>
          </UpdatedWrap>
        </ProductNameWrap>
        <ProductDetailsWrap>
          <ProductDetailsDiv>
            <ProductDetails>Status:</ProductDetails>
            <ProductProgressIn>{domainTrackingData.status ? "Tracking" : "Paused"}</ProductProgressIn>
          </ProductDetailsDiv>
          <ProductDetailsDiv>
            <ProductDetails>Platform:</ProductDetails>
            <ProductProgress><img src={domainTrackingData.platform == "google" ? google : domainTrackingData.platform == "bing" ? bing : ""} />{domainTrackingData.platform}</ProductProgress>
          </ProductDetailsDiv>
          <ProductDetailsDiv>
            <ProductDetails>Country:</ProductDetails>
            <ProductProgress>{getFlagEmoji(domainTrackingData.country)}{domainTrackingData.country}</ProductProgress>
          </ProductDetailsDiv>
        </ProductDetailsWrap>
      </ProductWrap>
    </Container>
  )
}

export default Tracking