import { createRef, useEffect, useRef, useState } from "react";
import { useOverview } from "../../../../context/overview";
import { ClientProfileForm, Input, Label, RequiredMark, Field } from "./index.styled";

import CountryDropdown from "./CountryDropdown";
const fields = [
    {
        id: 1,
        label: "Brand/Company Name",
        isMandatory: true,
        placeHolder: "Eg. Apple"
    },
    {
        id: 2,
        label: "Category",
        isMandatory: false,
        placeHolder: "Eg. Electronics"
    },
    {
        id: 3,
        label: "Website",
        isMandatory: true,
        placeHolder: "www.apple.com"
    },
    {
        id: 4,
        label: "Location",
        isMandatory: false,
        placeHolder: "Eg. America"
    }
]


function ClientProfile() {
    const brandNameInputRef = useRef()
    const categoryInputRef = useRef()
    const locationInputRef = useRef()
    const dropdownRef = createRef()
    const { overviewState: { clientProfile, projectDetails }, overviewDispatch } = useOverview()
    const [inputFocussed, setInputFocussed] = useState(null)

    const handleChange = (id) => {
        switch (id) {
            case 1:
                overviewDispatch({
                    type: "SET_CLIENT_PROFILE",
                    value: { companyName: brandNameInputRef.current.value }
                })
                break
            case 2:
                overviewDispatch({
                    type: "SET_CLIENT_PROFILE",
                    value: { category: categoryInputRef.current.value }
                })
                break
            case 4:
                overviewDispatch({
                    type: "SET_CLIENT_PROFILE",
                    value: { location: locationInputRef.current.value }
                })
                break
        }
    }

    const assignRefs = (node, id) => {
        switch (id) {
            case 1:
                return brandNameInputRef.current = node

            case 2:
                return categoryInputRef.current = node

            case 4:
                return locationInputRef.current = node
        }
    }

    const gettingValues = (id) => {
        switch (id) {
            case 1:
                return clientProfile?.companyName

            case 2:
                return clientProfile?.category

            case 3:
                return clientProfile?.website

            case 4:
                return clientProfile?.location
        }
    }

    const deActivateDropdown = () => {
        if(dropdownRef.current){
            dropdownRef.current.style.display = "none"
        }
    }

    useEffect(() => {
        overviewDispatch({
            type: "SET_CLIENT_PROFILE",
            value: { website: projectDetails?.website }
        })
    }, [projectDetails?.projectId])

    return (
        <ClientProfileForm onClick={deActivateDropdown} >
            {fields.map((field) => {
                const inputValue = gettingValues(field.id)
                return (
                    <Field key={field.id}>
                        <Label key={field.id} disable={field.label === "Website"}>
                            {field.label}
                            {field.isMandatory ? <RequiredMark> *</RequiredMark> : null}
                            {field.label === "Location"
                                ? (
                                    <CountryDropdown ref={dropdownRef} />
                                )
                                : (
                                    <Input
                                        ref={(node) => assignRefs(node, field.id)}
                                        required={field.isMandatory}
                                        placeholder={field.placeHolder}
                                        onChange={() => handleChange(field.id)}
                                        value={inputValue}
                                        inActive={field.id === 3}
                                        isFocussed={inputFocussed === field.label}
                                        onFocus={() => setInputFocussed(field.label)}
                                        onBlur={() => setInputFocussed(null)}
                                    />
                                )
                            }
                            
                        </Label>
                    </Field>
                )
            })}
        </ClientProfileForm>
    );
}

export default ClientProfile;