import React, { useRef, useState } from "react";
import CloseIcon from "../../../../../../../media/Dashboard/detailsPaneClose.svg"
import { PopupContainer, PopupBody, LogoutButton, KeywordsListContainer, KeywordInputContainer, Error, Footer, SetupTrackingButton, ErrorBox, ErrorIcon, InputContainer, KeywordInput, RightIcons } from "./index.styled"
import { useNavigate } from "react-router-dom";
import { useKeywordOverview } from "../../../../context/keywordOverview";
import PlatformDropDown from "./components/PlatformDropDown"
import CountryDropDown from "./components/CountryDropDown";
import {appClient} from '../../../../../../../utils/axios-utils'
import {
    useMutation
} from "@tanstack/react-query"

import errorIcon from "../../../../assets/errorIcon.svg"


const setUpTrackingData = async (data) => {
    // console.log(data)
    const config = {
        url: "/track/keyword/add",
        method: "POST",
        data
    }

    return appClient(config)
}
const tableData = async (data) => {
    // console.log(data)
    const config = {
        url: "/track/keyword/all",
        method: "GET",
    }

    return appClient(config)
}


const KeywordInputModal = () => {
    const {keywordOverviewState, keywordOverviewDispatch} = useKeywordOverview()
    const navigate = useNavigate()
    const keywordsDiv = useRef(null);
    const [focussedInputIndex, setFocussedInputIndex] = useState(null)
    const [error, setError] = useState(false)
    const updateKeywordCountry = (updatingIndex,inputCountryData) => {
        const tempKeywordList = keywordOverviewState.keywordInputList.map((data,index) => {
            // console.log(index,'check1',keyword)
            if (updatingIndex == index) {
              return { ...data, country: inputCountryData }
            }
            return data;
          });

        //   console.log("updateKeywordCountry",tempKeywordList);
          keywordOverviewDispatch({ type: 'SET_KEYWORD_INPUT_LIST', value: tempKeywordList})
    }

    const updateKeywordPlatform = (keyword,inputPlatformData) => {
        // console.log('inputCountryData')

        const tempKeywordList = keywordOverviewState.keywordInputList.map((data,index) => {
            if (keyword == index) {
              // Update the region property for the specific country
              return { ...data, platform: inputPlatformData }
            }
            return data;
          });

        //   console.log("updateKeywordPlatform",tempKeywordList)

          keywordOverviewDispatch({ type: 'SET_KEYWORD_INPUT_LIST', value: tempKeywordList}) 
    }

    const { mutate: tableApi, isLoading: isTableDataLoading } = useMutation(tableData, {
        onSuccess: (data) => {
            if(data.length > 0){
                keywordOverviewDispatch({type: 'SET_KEYWORD_TABLE_OPEN', value: true})
                keywordOverviewDispatch({type: 'SET_KEYWORD_TABLE_LIST', value: data})
            }
            keywordOverviewDispatch({type: 'SET_KEYWORD_INPUT_MODAL_OPEN', value: false})
        },
        onError: (err) => {
            
        }
    })
    const { mutate: setUpTrackingApi, isLoading: isSetUpTrackingDataLoading } = useMutation(setUpTrackingData, {
        onSuccess: (data) => {
            // console.log(data)
            navigate('/keyword-tracking')
            tableApi()
            // keywordOverviewDispatch({ type: "SET_AD_COPIES", value: data.documents })
        },
        onError: (err) => {
            
        }
    })

    const addNewKeyword = () => {
        if(keywordOverviewState.keywordInputList.length < 4){
            keywordOverviewDispatch({type: 'SET_KEYWORD_INPUT_LIST_ERROR', value: ''})
            keywordOverviewDispatch({type: 'SET_KEYWORD_INPUT_LIST', value: [...keywordOverviewState.keywordInputList, { keyword: '', platform: '', country: {}, platformError: '' }]})
        }else{
            keywordOverviewDispatch({type: 'SET_KEYWORD_INPUT_LIST_ERROR', value: 'maximum 4 keywords at a time'})
        }
    }

    const onKeywordInput = (e,index) => {
        // const tempKeywordInputList = keywordOverviewState.keywordInputList
        const tempKeywordInputList = keywordOverviewState.keywordInputList.map((d,id) => {
            if (id === index) {
              // Update the region property for the specific country
              return { ...d, keyword: e.target.value };
            }
            return d;
          });
          keywordOverviewDispatch({
            type: 'SET_KEYWORD_INPUT_ERROR_OBJ', value: {
                ...keywordOverviewState.keywordInputErrorsObj, [index]: {
                    status: !e.target.value > 0,
                    message: !e.target.value > 0 ? 'Enter a keyword' : ''
                }
            }
        })
        // console.log(tempKeywordInputList[index])
        // tempKeywordInputList[index].keyword = e.target.value;
        // console.log(tempKeywordInputList)
        keywordOverviewDispatch({type: 'SET_KEYWORD_INPUT_LIST', value: tempKeywordInputList})
    }

    const setUpTrackingfn = ()=>{
        if (keywordOverviewState.keywordInputList) {
            const setUpObj = {};
            setUpObj.keywords=[];
            // console.log(setUpObj,'setUpObj')
            keywordOverviewState.keywordInputList.map((item) => {
                if (item.keyword !== '' ) {
                    if( item.platform !== ''){
                    // console.log(item, 'itemitem');
                    setUpObj.keywords.push({ keyword: item.keyword,
                        platform: item.platform,
                        country: item?.country?.name ? item.country.name.common : 'global'})
                    keywordOverviewDispatch({ type: 'SET_KEYWORD_INPUT_LIST_ERROR', value: '' });
                    }else{
                    // console.log('section checking');
                        keywordOverviewDispatch({ type: 'SET_KEYWORD_INPUT_LIST_ERROR', value: 'Please Fill the Empty Section' });
                    }
                    
                } 
                else {
                    // console.log('keyword checking');
                    // keywordOverviewDispatch({ type: 'SET_KEYWORD_INPUT_LIST_ERROR', value: 'Please Enter the Keyword' });
                }              
            });
            setUpObj.keywords.length > 0 && setUpTrackingApi(setUpObj)

        }
        
    }
    const eventEnterHandle = (e) => {
        if (e.key === 'Enter') {
            setUpTrackingfn()
        }
    }
    return <PopupContainer>
        <PopupBody>
        {/* temp change  */}
            <LogoutButton onClick={()=>keywordOverviewDispatch({type: 'SET_KEYWORD_INPUT_MODAL_OPEN', value: false})}>
                <img src={CloseIcon}/>
            </LogoutButton>
            <h2>Setup Keyword Tracking</h2>
            <KeywordsListContainer ref={keywordsDiv}>
                {
                    keywordOverviewState.keywordInputList.map((keyword, idx)=> {
                        return (
                            <div>
                                {/* <KeywordInputContainer>
                                    <input
                                        placeholder="Add a keyword"
                                        defaultValue={keyword.keyword}
                                        onChange={(e)=>onKeywordInput(e, idx)}
                                    />
                                    <PlatformDropDown keywordData={keyword} updateKeywordPlatform={updateKeywordPlatform} countryIndex={idx}/>
                                    <CountryDropDown keywordData={keyword} updateKeywordCountry={updateKeywordCountry} countryIndex={idx}/>
                                </KeywordInputContainer> */}
                                <KeywordInputContainer error={keywordOverviewState.keywordInputErrorsObj[idx]?.status ? true : false}>
                                    <InputContainer isFocussed={focussedInputIndex === idx} hasError={error}>
                                        <KeywordInput
                                            placeholder="Add a keyword"
                                            defaultValue={keyword.keyword}
                                            onChange={(e)=>onKeywordInput(e, idx)}
                                            onKeyDown={(e) => {
                                                eventEnterHandle(e)
                                            }}
                                            onFocus={() => setFocussedInputIndex(idx)}
                                            onBlur={() => setFocussedInputIndex(null)}
                                        />
                                        <RightIcons>
                                            {keywordOverviewState.keywordInputErrorsObj[idx]?.status && <ErrorIcon src={errorIcon} alt=""/>}
                                        </RightIcons>
                                    </InputContainer>
                                    <PlatformDropDown keywordData={keyword} updateKeywordPlatform={updateKeywordPlatform} countryIndex={idx} />
                                    <CountryDropDown keywordData={keyword} updateKeywordCountry={updateKeywordCountry} countryIndex={idx} />
                                </KeywordInputContainer>
                                {keywordOverviewState.keywordInputErrorsObj[idx]?.status && <ErrorBox>Enter a keyword</ErrorBox>}
                            </div>
                        )
                    })
                }
            </KeywordsListContainer>
            <Error>{keywordOverviewState.keywordInputListError ? keywordOverviewState.keywordInputListError : ""}</Error>
            <Footer>
                {/* <AddMoreKeywordButton isDisabled={keywordOverviewState.keywordInputListError} onClick={addNewKeyword}>
                    + Add more keyword
                </AddMoreKeywordButton> */}
                <SetupTrackingButton onClick={setUpTrackingfn}>
                    Setup Tracking
                </SetupTrackingButton>
            </Footer>
        </PopupBody>
    </PopupContainer>
}

export default KeywordInputModal