import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const SearchWrapper = styled.div`
    border-radius: 6px;
    height: 204px;
    /* background: linear-gradient(94deg, #6F77EF 0.58%, #EE0F96 100%); */
    background: url(${props => props.backgrounfImg});
    max-width: 996px;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px 20px 40px 20px;
    margin-top: 24px;

    position: relative;

    h2{
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        /* margin-bottom: 26px; */

        position: absolute;
        bottom: 8px;
        right: 20px;
    }

    h1{
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        color: rgb(255, 255, 255);
        margin-bottom: 45px;
    }
`;

export const SearchContainer = styled.div`
    position: relative;
    width: 60%;
    max-width: 544px;
    img{
        position: absolute;
        top: 14px;
        left: 16px;
    }
`;


export const SearchInput = styled.input`
    border-radius: 20px;
    border: 1px solid #DADFE4;
    background: #FFF;
    padding: 12px 12px 12px 48px;
    width: 100%;
    outline: none;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;


    ::placeholder {
        color: #1C1C1C66;
    }
`;

export const CreativeImg = styled.img`
    position: absolute;
    left: 0;
    top: 0;
`;


export const TemplateCategoryContainer = styled.div`
    max-width: 996px;
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 42px;

    display: flex;
    flex-direction: column;
    align-items: center;


    h2{
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: rgba(28, 28, 28, 0.90);
        margin-bottom: 24px;
    }
`;

export const TemplateCategoryList = styled.div`
    display: flex;
    gap: 16px;
`;

export const TemplateCategoryCard = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 237px;
    height: 138px;
    background: url(${props => props.img});
    pointer-events: ${props => props.comingSoon ? "none": "all"};
    p{
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #FFF;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.60);
        border-radius: 6px;
    }
`;

export const CategoryImgContainer = styled.div`
    height: 120px;
    width: 100%;
    padding: 4px;
    background: url(${props => props.img});
    border-radius: 16px;
    div{
        border-radius: 16px;
        border: 1px solid #DADFE4;
        background: ${props => props.color ? props.color : ""};
        padding: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

`;

export const RandomTemplatesContainer = styled.div`
    max-width: 996px;
    width: 90%;
    margin: 42px auto auto;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h2{
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: #1C1C1CB2;
        margin-bottom: 24px;
    }
`;


export const RandomTemplatesList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
`;

export const TemplateCard = styled.div`
    cursor: pointer;
    width: 232px;
    height: 214px;
    background: #8080802e;
    padding: 10px;
    border-radius: 2px;
    img{
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
`;

export const ResultsContainer = styled.div`
    display: flex;
    gap: 12px;
    height: calc(100vh - 245px);
    background: #006ecc00;
    position: absolute;
    left: 0;
    top: 0;
    .loader{
        height: 200px;
        margin: auto;
    }
`;

export const PersonaSelectionContainer = styled.div`
    max-width: 996px;
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    margin-top: 42px;

    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 6px;
    background: #FFF;
    padding: 24px;

    height: 80vh;
    max-height: 670px;
    margin-bottom: 50px;

    h2{
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        color: rgba(28, 28, 28, 0.90);
        margin-bottom: 24px;
    }
`;

export const PersonaListContainer = styled.div`
    padding: 16px;
    width: 50%;
    border-radius: 6px;
    border: 1px solid #E3E8EE;
    background: #FFF;
    height: 448px;
    h3{
        font-size: 16px;
        padding-bottom: 16px;
        font-weight: 500;
        text-align: center;
        line-height: 20px;
        color: rgba(28, 28, 28, 0.40);
    }
`;

export const PersonaList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;


export const SubPersonaListContainer = styled.div`
    padding: 16px;
    width: 50%;
    border-radius: 6px;
    border: 1px solid #E3E8EE;
    background: #FFF;
    height: 448px;
    h3{
        font-size: 16px;
        padding-bottom: 16px;
        font-weight: 500;
        text-align: center;
        line-height: 20px;
        color: rgba(28, 28, 28, 0.40);
    }
`;

export const SubPersonaList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const PersonaCard = styled.div`
    padding: 16px;
    border-radius: 6px;
    border: ${props => props.isSelected ? "none": "1px solid #E3E8EE"};
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: rgba(28, 28, 28, 0.60);
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 70px;
    background: ${props => props.isSelected ? "#FFF7E8": ""};
`;

export const SubPersonaCard = styled.div`
    padding: 16px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: rgba(28, 28, 28, 0.60);
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 70px;
    border: 1px solid #E3E8EE;

    p{
        width: 80%;
    }

    :hover{
        background: #FFF7E8;
        border: 1px solid #ffffff;

        .explore-btn{
            display: flex;
        }
    }
`;

export const CategorySubContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 16px;
`;

export const ExploreButton = styled.div`
    display: none;
    border-radius: 6px;
    background: #FF711E;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #FFF;
    padding: 8px 12px;
`;