import styled from "styled-components";

export const SortFocusContainer = styled.div`
  position: relative;
`;

export const ButtonContainer = styled.div``;
export const SortContainer = styled.div`
  border-radius: 4px;
  border: 1px solid #cfd0d1;
  background: #fff;
  padding: 0 12px;
  color: rgba(28, 28, 28, 0.8);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 125px;
  height: 38px;
  cursor: pointer;
`;
export const Icon = styled.img``;
export const NewContainer = styled.div`
  height: 20px;
  width: 31px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 40%,
    #f6f7fb 40%
  );
  color: #f6f7fb;
  position: absolute;
  top: -12px;
  right: 9px;
`;

export const NewLabel = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  background: linear-gradient(100deg, #ff6f61 13.09%, #f8913a 89.53%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const FocusPopup = styled.div`
  width: 280px;
  height: 372px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #cfd0d1;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  top: 46px;
  position: absolute;
  left: 0;
  z-index: 1;
  display: ${(props) => (props.visibility ? "unset" : "none")};
`;

export const Content = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 16px;
`;

export const Title = styled.div`
  color: rgba(28, 28, 28, 0.9);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.14px;
  padding-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Clear = styled.div`
  color: #6c95e4;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 115.385% */
  cursor: pointer;
`;

export const Description = styled.div`
  width: 248px;
  height: 56px;
  flex-shrink: 0;
  background: #f7f8f9;
  padding: 8px;
  color: rgba(28, 28, 28, 0.8);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.13px;
  padding: 8px;
  margin-bottom: 12px;
`;

export const BoldSpan = styled.span`
  color: rgba(28, 28, 28, 0.8);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  letter-spacing: 0.13px;
`;

export const PaddedLine = styled.div`
  width: 248px;
  height: 1px;
  background: #dadfe4;
  margin-bottom: 12px;
`;

export const Parameter = styled.div`
  display: flex;
  align-items: center;
  color: rgba(28, 28, 28, 0.8);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.14px;
  margin-bottom: 12px;
  justify-content: space-between;
  cursor: pointer;
`;

export const CircleCheckbox = styled.div`
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #dadfe4;
  background: #fff;
  cursor: pointer;
`;

export const ListContainer = styled.div`
  width: 278px;
  height: 187px;
  flex-shrink: 0;
  background: #f7f8f9;
  padding: 16px 21px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  left: 0;
  overflow-y: auto;
`;

export const ListItem = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--black-100, #1c1c1c);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  letter-spacing: -0.13px;
  cursor: pointer;
`;

export const Checkbox = styled.div`
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  border: ${(props) => (props.checked ? "none" : "1px solid #DADFE4")};
  background: ${(props) => (props.checked ? "#FF711E" : "#FFF")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Footer = styled.div`
  width: 278px;
  height: 50px;
  flex-shrink: 0;
  border-top: 1px solid rgb(207, 208, 209);
  background: rgb(255, 255, 255);
  border-radius: 0px 0px 4px 4px;
  padding: 12px;
  position: absolute;
  bottom: 0px;
  left: 0;
`;

export const ApplyButton = styled.div`
  cursor: pointer;
  width: auto;
  height: 26px;
  flex-shrink: 0;
  border-radius: 3px;
  background: #ff711e;
  color: #fff;
  font-family: Inter;
  font-size: 13px;
  padding: 0px 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px; /* 115.385% */
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  pointer-events: ${(props) => (props.isActive ? "pointer" : "none")};
  opacity: ${(props) => (props.isActive ? "1" : "0.6")};

`;

export const EmptyListContainer = styled.div`
  color: rgba(28, 28, 28, 0.80);
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
`;