import { useAdManagement } from "../../context/AdManagementContext";
import { SelectPlatformContainer, SelectPlatformWrapper,SelectPlatformDiv, PlatformListWrap, PlatformList, GoogleAdsDiv, OtherAdsDiv, OtherPlatformList, AvailableDiv } from "./SelectPlatform.styled";
import backArrow from '../../assets/backArrow.svg';
import googleAds from '../../assets/googleAds.png';
import { useAdManagementInfo } from "../../hooks/useAdManagementInfo";
import _static from "../../../../../../config/static";



const { baseUrl } = _static
function SelectPlatform() {
    const { adManagementState, adManagementDispatch } = useAdManagement()
    const adManagementHook = useAdManagementInfo()

    const platformListData = {
        "Meta ad": "Available soon",
        "Linked ad": "Available soon",
        "Reditt ad": "Available soon",
        "X ad": "Available soon",
        "Flipkart ad": "Available soon",
    }
    
    const goBackFn =() =>{
        adManagementDispatch({type: 'SET_SELECT_A_PLATFORM', value: false})
        adManagementDispatch({type: 'SET_INPUT_PAGE', value: true})
    }

    const selectPlatfornFn = () => {
        const windowWidth = 600; // Width of the window
        const windowHeight = 500; // Height of the window
        const screenWidth = window.screen.width; // Width of the screen
        const screenHeight = window.screen.height; // Height of the screen
    
        const left = (screenWidth - windowWidth) / 2;
        const top = (screenHeight - windowHeight) / 2;
    
        const oauthWindow = window.open(`${baseUrl}/admanagement/login`, '_blank', `width=${windowWidth},height=${windowHeight},left=${left},top=${top}`);
    

        const messageListener = function(event) {
            if (event.data === 'true') {
                adManagementDispatch({type: 'SET_GOOGLE_LOGGED_IN', value: true})
                adManagementDispatch({type: 'SET_SELECT_AD_ACCOUNT', value: true})
                adManagementDispatch({type: 'SET_SELECT_A_PLATFORM', value: false})

            window.removeEventListener('message', messageListener);
            }
        };

        window.addEventListener('message', messageListener);
        };
        

    return ( 
           
        <SelectPlatformWrapper>
           <SelectPlatformContainer>
                <img src={backArrow} onClick={goBackFn}/>
                <SelectPlatformDiv>Select a platform</SelectPlatformDiv>
           </SelectPlatformContainer>

           <PlatformListWrap>
                <PlatformList  onClick={selectPlatfornFn}>
                    <img src={googleAds} />
                    <GoogleAdsDiv>Google ad account</GoogleAdsDiv>
                </PlatformList>
                {Object.entries(platformListData).map(([key, value]) => {
                    return (
                        <OtherPlatformList>
                            <OtherAdsDiv>{key}</OtherAdsDiv>
                            <AvailableDiv>{value}</AvailableDiv>
                        </OtherPlatformList>
                    )
                })}
           </PlatformListWrap>
        </SelectPlatformWrapper>
               
    );
}

export default SelectPlatform;