import styled from "styled-components"


export const Container = styled.div`
width: 100%;
max-width: 966px;
height: auto;
background: #FFF7E8;
padding-bottom: 23px;
`
export const EditWrap = styled.div`
display: flex;
justify-content: space-between;
margin: 12px 14px 0 40px;
`
export const TrackingDiv = styled.div`
color: #7D7D7D;
font-family: 'Inter';
font-size: 14px;
font-weight: 400;
margin-top: 12px;
`
export const EditDiv = styled.div`
cursor: pointer;
`;

export const ProductWrap = styled.div`
display: flex;
margin: 0 40px;
justify-content: space-between;
@media screen and (max-width: ${props => props.theme.breakPoints.tab}) {
flex-wrap: wrap;
row-gap: 10px;
}
`
export const ProductNameWrap = styled.div`
`
export const ProductDetailsWrap = styled.div`
display: flex;
flex-direction: column;
row-gap: 12px;
margin-top: 12px;
`
export const ProductName = styled.div`
color: #1C1C1C;
font-family: 'Inter';
font-size: 38px;
font-weight: 600;
padding: 7px 0;
`
export const UpdatedWrap = styled.div`
display: flex;
column-gap: 8px;
margin-top: 7px;
`
export const RefreshWrap = styled.div`
`
export const UpdatedDiv = styled.div`
color: rgba(28, 28, 28, 0.30);
font-family: 'Inter';
font-size: 14px;
font-weight: 400;
`
export const ProductDetailsDiv = styled.div`
display: flex;
column-gap: 21px;
`
export const ProductDetails = styled.div`
color: #000;
font-family: 'Inter';
font-size: 14px;
font-style: normal;
font-weight: 400;
`
export const ProductProgress = styled.div`
display: flex;
column-gap: 8px;
color: #000;
font-family: 'Inter';
font-size: 14px;
font-weight: 400;
align-items: center;
img{
    height: 13px;
}
`
export const ProductProgressIn = styled.div`
color: #007BFF;
font-family: 'Inter';
font-size: 14px;
font-weight: 400;

`
