import styled from "styled-components";

export const AdGoalsContainer = styled.div`
    width: 738px;
    height: 314px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #FFF;
    padding: 30px 35px;
    display: flex;
    column-gap: 12px;
    row-gap: 14px;
    flex-wrap: wrap;
`;

export const GoalContainer = styled.div`
    width: 158px;
    height: 120px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid ${(props) => props.isSelected ? "rgba(115, 74, 0, 0.10)" : "#F0F0F0"};
    background: ${(props) => props.isSelected ? "#FFF7E8" : "unset"};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
`;

export const GoalIconContainer = styled.div`
    position: absolute;
    top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #F0F0F0;
`;

export const GoalIcon = styled.img`
`;

export const Goal = styled.div`
    color: ${(props) => props.isSelected ? "#734A00" : "#000"};
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    position: absolute;
    top: 73px;
`;
