import { AdDescription, AdHeader, AdItem, AdLogo, AdTitle, AdsGrid, Header, MoreAds, SavedAdsContainer, SavedCount, Title, EmptyState } from "./index.styled";
import { useMutation } from "@tanstack/react-query"
import { appClient } from "../../../../../utils/axios-utils"
import { useOverview } from "../../context/overview";
import { useEffect } from "react";
import { LineLoader } from "../index.styled";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../context/auth";

function formatDate(epochTime) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const date = new Date(epochTime);
    const day = String(date.getDate()).padStart(2, '0');
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
}

function SavedAds() {
    const { authState, authDispatch } = useAuth()

    const { overviewState: { projectDetails, savedAdsData, isOnboardingPopupActive }, overviewDispatch } = useOverview()
    const navigate = useNavigate()
    const getSavedAdsData = (data) => {
        const config = {
            url: `/dashboard-overview/project/saved-ads`,
            method: "POST",
            data
        }
        return appClient(config)
    }
    const { mutate: getSavedAdsDataMutate, isLoading } = useMutation(getSavedAdsData, {
        onSuccess: (data) => {
            overviewDispatch({
                type: "SET_SAVED_ADS_DATA",
                value: data
            })
        },
        onError: (e) => console.log(e)
    })

    const handleClick = () => {
        navigate(`/saved-ads`)
    }

    useEffect(() => {
        if (projectDetails?.projectId)
            getSavedAdsDataMutate({ projectId: projectDetails.projectId, overviewPage: true })
    }, [projectDetails?.projectId, isOnboardingPopupActive])

    if(isLoading || isOnboardingPopupActive){
        return <LineLoader width={234} height={232} />
    }
    return (
        <SavedAdsContainer onClick={handleClick} >
            <Header>
                <Title>Saved Ads</Title>
                <SavedCount>{savedAdsData.totalSavedAdsCount ? `${savedAdsData.totalSavedAdsCount} saved` : null}</SavedCount>
            </Header>
            {savedAdsData.result.length ? 
                <AdsGrid>
                    {savedAdsData.result.map((ad, idx) => {
                        return (
                            <AdItem key={ad.id} >
                                <AdHeader>
                                    <AdLogo src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${ad.id}`} alt="logo" />
                                    {/* <AdvertiserName>{ad.title}</AdvertiserName> */}
                                </AdHeader>
                                <AdTitle>{ad.title}</AdTitle>
                                <AdDescription>{`${formatDate(ad.lastSeen)} -- ${ad.content}`}</AdDescription>
                                {idx === 3 && savedAdsData.totalSavedAdsCount > 4 ?
                                    <MoreAds>{`+ ${savedAdsData.totalSavedAdsCount - 3}`}</MoreAds>
                                    : null}
                            </AdItem>
                        )
                    })}
                </AdsGrid>
            : <EmptyState>No saved ads found</EmptyState>
            }

        </SavedAdsContainer>
    );
}

export default SavedAds;