import React, { useState, useEffect } from "react";
import { appClient } from "../../../../../../utils/axios-utils";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  FooterContainer,
  FormContainer,
  CtaContainer,
  ResetPasswordHeader,
  EmailSelected,
  OtpInputContainer,
  TimerSection,
  OtpInput,
  ChangePasswordButton,
  ResendBtn,
  Error,
  OtpError,
} from "./index.styled";
import { usePasswordChange } from "./../../context/passwordChange";
import WarningIcon from "../../../../../../media/Dashboard/warningicon.svg";
import { useChangePassword } from "../../../../../hooks/useChangePassword";

const logoutUser = (data) => {
  const config = {
    url: "/auth/logout",
    method: "DELETE",
  };

  return appClient(config);
};

const ConfirmEmail = () => {
  //   const [otpError, setOtpError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const changePasswordHook = useChangePassword();
  // The state for our timer
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { passwordChangeState, passwordChangeDispatch } = usePasswordChange();
  const navigate = useNavigate();

  let toastId;

  const fmtMSS = (s) => {
    return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
  };

  useEffect(() => {
    const TimerInt =
      passwordChangeState.timer > 0 &&
      setInterval(() => {
        passwordChangeDispatch({
          type: "UPDATE_TIMER",
          value: passwordChangeState.timer - 1,
        });
      }, 1000);
    return () => {
      clearInterval(TimerInt);
    };
  }, [passwordChangeState.timer]);

  return (
    <>
      <ResetPasswordHeader>
        <p>Reset your password</p>
        <EmailSelected>
          <p>
            Please enter the OTP sent to you at:{" "}
            <span>{passwordChangeState.email}</span>
            <a
              onClick={() => {
                passwordChangeDispatch({ type: "SET_OTP_SENT", value: false });
              }}
            >
              Change email
            </a>
          </p>
        </EmailSelected>
      </ResetPasswordHeader>

      <FormContainer
        onChange={(e) => {
          passwordChangeDispatch({ type: "UPDATE_OTP", value: e.target.value });
        }}
        onSubmit={
          handleSubmit((data) => {
            data.email = passwordChangeState.email;
            changePasswordHook.onSubmit(data);
          })
          // handleSubmit(onSubmit)
        }
      >
        <OtpInputContainer>
          <OtpInput
            placeholder="000-000"
            type="number"
            error={errors.verificationCode}
            {...register("verificationCode", {
              required: {
                value: true,
                message: "Enter your OTP",
              },
            })}
          />
          {errors.verificationCode && (
            <Error>{errors.verificationCode.message}</Error>
          )}
          <TimerSection>
            <ResendBtn
              onClick={changePasswordHook.onSendVerificationCode}
              invisible={passwordChangeState.timer > 0 ? "true" : "false"}
            >
              Resend code
            </ResendBtn>{" "}
            You can resend the code in{" "}
            <span>{fmtMSS(passwordChangeState.timer)}</span>
          </TimerSection>
        </OtpInputContainer>
        {changePasswordHook.otpError && (
          <OtpError>
            <img src={WarningIcon} /> <p>{errorMessage}</p>
          </OtpError>
        )}
        <CtaContainer>
          <ChangePasswordButton type="submit">
            Confirm email
          </ChangePasswordButton>
        </CtaContainer>
        <FooterContainer>
          <p>
            Haven't received the email? Please check your spam folder or
            <a href="mailto: support@adspyder.com">Contact us</a>
          </p>
        </FooterContainer>
      </FormContainer>
    </>
  );
};

export default ConfirmEmail;
