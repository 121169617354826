import React, {useState, useEffect, useRef} from 'react'
// import {Select, Checkbox} from "antd"

// Styles
import {
    PopupBodyContainer,
    BodyHeader,
    CountrySearchBar,
    CountriesListContainer,
    CheckBoxContainer,
    CountriesContainer,
    DropdownContainer,
    DropdownButton,
    DropDownImage,
    Flag,
    CountryName,
    FlagContainer,
    Country
} from "./index.styled"

// Images
import UpArrow from "../../../../../..//media/Dashboard/uparrow.png"
import DownArrow from "../../../../../../media/Dashboard/downarrow.png"

import { countryNewData } from '../../../../../../utils/countryData'
import { useDomainOverview } from '../../../context/domainoverview'
import GlobeIcon from "../../../assets/country.svg"
import { useOutsideAlerter } from '../../../../../../utils/outsiderAlert'

const CountryDropDown = () => {
    const targetRef = useRef(null)
    const { domainOverviewState, domainOverviewDispatch } = useDomainOverview()
    const [filteredCountries, setFilteredCountries] = useState([])
    const [show, setShow] = useState(false)
    const [fixedElementPosition, setFixedElementPosition] = useState({ top: 0, left: 0, right: 0, bottom: 0 });

    const handleCountrySearch = (e) => {
        let searchTerm = e.target.value;
        let filteredCountriesList = [];

        // countryData.map((region, idx) => {
        //     let tempRegionCountry = region.countries.filter((country) => {
        //         return country.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) || country.name.toLocaleLowerCase() === searchTerm.toLocaleLowerCase()
        //     })

        //     if (tempRegionCountry.length > 0) {
        //         filteredCountriesList.push({region: region.region, countries: tempRegionCountry})
        //     }
        // })

        filteredCountriesList = countryNewData.filter((country, idx) => {
            return country.adspyder_name.toLocaleLowerCase() == searchTerm.toLocaleLowerCase() || country.adspyder_name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
        })

        setFilteredCountries(filteredCountriesList)
    }

    let updatePosition = () => {
        if (targetRef.current) {
          const rect = targetRef.current.getBoundingClientRect();
          setFixedElementPosition({
            top: rect.top,
            left: rect.left,
            right: rect.right,
            bottom: rect.bottom,
          });

        }
    };
  
    const updateDomainCountry = (country) => {
        domainOverviewDispatch({type: 'SET_DOMAIN_COUNTRY', value: country});
        setFilteredCountries([])

    }
    
    useEffect(() => {
      // Initial position update
      updatePosition();
      domainOverviewDispatch({type: 'SET_DOMAIN_COUNTRY', value: {}})
      // Listen for resize or scroll events if the position might change dynamically
      window.addEventListener('resize', updatePosition);
      window.addEventListener('scroll', updatePosition);
  
      // Cleanup the event listeners when the component is unmounted
      return () => {
        window.removeEventListener('resize', updatePosition);
        window.removeEventListener('scroll', updatePosition);
      };
    }, []); 

    useOutsideAlerter(targetRef, setShow)
    return (
        <DropdownContainer 
            ref={targetRef}
            isDropdownOpened={show}
            >
            <DropdownButton onClick={
                    () => {
                        setShow(prev => !prev)
                        updatePosition()
                    }
                }
                >
                    
                <span>{
                    domainOverviewState.domainCountry?.iso_alpha_2 ? (
                        <span >
                            <FlagContainer>
                                <Flag src={domainOverviewState.domainCountry.flag} />
                            </FlagContainer>
                            <CountryName>{domainOverviewState.domainCountry.iso_alpha_2}</CountryName>
                    </span>
                    ) : <span><img src={GlobeIcon}/> Global</span>
                }</span>
                {
                !domainOverviewState.domainCountry?.country && <DropDownImage><img src={
                        show ? UpArrow : DownArrow
                    }/></DropDownImage> 
            } </DropdownButton>
            {show && 
                <PopupBodyContainer height={30} left={fixedElementPosition.left - 127} top={fixedElementPosition.top+52} className='country-dropdown-options'>
                    <BodyHeader>
                        <CountrySearchBar onChange ={handleCountrySearch} placeholder="Search country"/>
                        <Country
                            onClick={() => {
                                updateDomainCountry({})
                                setShow(false)
                            }}
                        >
                            <FlagContainer>
                                <Flag src={GlobeIcon}  />
                            </FlagContainer>
                            <CountryName>Global</CountryName>
                        </Country>
                    </BodyHeader>
                    <CountriesListContainer> {
                        filteredCountries.length > 0 ? filteredCountries.map((country, idx) => {
                            return (
                                
                                <CountriesContainer> {
                                    // country.countries.map((country, id) => { 
                                    //     return 
                                        <Country key={idx}
                                        onClick={() => {
                                            updateDomainCountry(country)
                                            setShow(false)
                                        }}
                                    >
                                        <FlagContainer>
                                            <Flag src={country.flag}  />
                                        </FlagContainer>
                                        <CountryName>{country.official_name}</CountryName>
                                    </Country>
                                    // })
                                } </CountriesContainer>
                            
                            )
                    }) : countryNewData.map((country, idx) => {
                        return (
                                
                                <CountriesContainer> {
                                    // country.countries.map((country, id) => { 
                                    //     return 
                                        <Country key={idx}
                                        onClick={() => {
                                            updateDomainCountry(country)
                                            setShow(false)
                                        }}
                                    >
                                        <FlagContainer>
                                            <Flag src={country.flag}  />
                                        </FlagContainer>
                                        <CountryName>{country.official_name}</CountryName>
                                    </Country>
                                    // })
                                } </CountriesContainer>
                            
                            )
                })
                    } </CountriesListContainer>
                </PopupBodyContainer>
            } 
        </DropdownContainer>
)}

export default CountryDropDown
