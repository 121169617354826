import styled from "styled-components";

export const ResetPasswordHeader = styled.div`
    max-width: 452px;
    margin: 0 auto;
    margin-bottom: 35px;
    width: 100%;
    p{
        color: #2E2E2E;
        font-size: 20px;
    }
`;

export const FormContainer = styled.form`
    width: 100%;
    max-width: 452px;
    margin: 0 auto;
`;


export const EmailInputContainer = styled.div`
    margin-bottom: 24px;
    label{
            color: ${props => props.theme.colors.Grey};
            font-size: 16px;
            margin-bottom: 8px;
        }
`;

export const EmailInput = styled.input`
    background: #FFFFFF;
    border: ${props => props.error ? "1px solid #FF5180" : "1px solid #ECECEC"};
    outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};    border-radius: 8px;
    width: 100%;
    height: 40px;
    margin-top: 8px;
    outline: none;
    padding: 9px 12px;
    color: ${props => props.theme.colors.lightBlack};
    font-weight: 300;
    &:focus{
        border: ${props => props.error ? "1px solid #FF5180" : "1px solid #5AB3FF"};
        outline: ${props => props.error ? "2px solid #FFB7CB" : "2px solid #B9DDFF"};
    }
`;

export const CtaContainer = styled.div`

`;

export const SendOtpButton = styled.button`
    background: #FF711E;
    border-radius: 8px;
    width: 100%;
    height: 40px;
    outline: none;
    border: none;
    color: ${props => props.theme.colors.white};
    font-size: 16px;

    &:hover{
        background: #E75500;
    }

    &:active{
        background: #C74A00;
    }
`;

export const BackButton = styled.div`
    background: ${props => props.theme.colors.white};
    border-radius: 8px;
    width: 100%;
    height: 40px;
    outline: none;
    color: ${props => props.theme.colors.lightBlack};
    font-size: 16px;
    margin-top: 17px;
    border: 1px solid #ECECEC;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    a{
        width: 100%;
        text-align: center;
        padding: 10px;
    }
    &:hover{
        background: #F9F9F9;
    }
`;

export const Error = styled.p`
    color: #FF0045;
    margin-top: 6px;
    font-size: 14px;
    font-weight: 300;
`;

export const OtpError = styled.div`
    display: flex;
    align-items: flex-start;
    background: #FFF3F7;
    border: 1px solid #FFB7CB;
    border-radius: 8px;
    padding: 12px;
    font-size: 14px;
    font-weight: 300;
    color: #2E2E2E;
    line-height: 21px;
    margin-top: 20px;
    margin-bottom: 17px;
    p{
        margin-left: 8px;
    }
    a{
        color: #003FBC;
    }
`;