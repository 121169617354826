import React, { useState, useEffect } from 'react'
import { appClient } from '../../../../utils/axios-utils'
import Layout from '../Components/LoginLayout/layout'
import { useNavigate } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useMutation } from "@tanstack/react-query"
import { Helmet } from "react-helmet"

import {
    TimerSection,
    ResendBtn,
     Container, Header, SubContainer, VerifyBtn, FormDiv, FormDivContainer, FooterText
} from './index.styled'
import toast from "react-hot-toast"
import OtpInput from 'react-otp-input';

import { useAuth } from "../../../../context/auth"

const freeEmailDomains = require('free-email-domains')

const emailOtpVerify = (data) => {
    const config = {
        url: "/auth/local/email-verify-code",
        method: "POST",
        data
    }

    return appClient(config)
}

const getVerificationCode = (data) => {
    const config = {
        url: "/auth/local/email-verification-code",
        method: "GET"
    }

    return appClient(config)
}

const userRemainderTrigger = (data) => {
    const config = {
        url: "/auth/local/registration-reminder",
        method: "GET"
    }

    return appClient(config)
}

const logoutUser = (data) => {
    const config = {
        url: "/auth/logout",
        method: "DELETE"
    }

    return appClient(config)
}


const ConfirmEmail = () => {
    const [otp, setOtp] = useState('');

    const [otpError, setOtpError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    // The state for our timer
    const { register, handleSubmit, formState: {
        errors
    } } = useForm()
    const { authState, authDispatch } = useAuth()
    const navigate = useNavigate()

    let toastId;

    const fmtMSS = (s) => {
        return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s
    }

    const { mutate: onSubmit } = useMutation(emailOtpVerify, {
        onSuccess: (data) => {
            setOtpError(false)

            toast.success("Successfully Signed in")
            toast.dismiss(toastId)

            const email = authState.email ? authState.email : authState.formEmail

            let mailProvider = email.slice(email.indexOf("@") + 1, email.length)

            console.log(mailProvider, freeEmailDomains.includes(mailProvider))
            if (freeEmailDomains.includes(mailProvider)) {
                authDispatch({ type: 'SET_EMAIL_VERIFIED', value: true })
                navigate('/loginsurvey')
            } else {
                authDispatch({ type: 'SET_BUSINESS_EMAIL_FOUND', value: true })
                authDispatch({ type: 'UPDATE_IS_BUSINESS_EMAIL', value: true })
            }
        },
        onError: (err) => {
            setOtpError(true)
            setErrorMessage(err.response.data.errors[0].message)
            toast.dismiss(toastId)
        },
        onMutate: () => {
            toastId = toast.loading("Verifying Code")
        }
    })

    const { mutate: onLogout } = useMutation(logoutUser, {
        onSuccess: (data) => {
            authDispatch({ type: 'LOGOUT_SUCCESS' })
            toast.dismiss(toastId)
            setTimeout(() => {
                navigate('/')
            }, 1000);
        },
        onError: (err) => {
            toast.dismiss(toastId)
        },
        onMutate: () => {
            toastId = toast.loading("Loging out")
        }
    })

    const { mutate: onSendVerificationCode } = useMutation(() => {
        return getVerificationCode()
    }, {
        onSuccess: (data) => {
            // console.log("confirm email onSendVerificationCode", data)
            toast.dismiss(toastId)
            if (data) {
                authDispatch({
                    type: 'UPDATE_TIMER',
                    value: Math.floor(
                        (new Date(data.tokenExpiresAt).getTime() - Date.now()) / 1000
                    )
                })
                toast.success("OTP sent")
            }

        },
        onError: (err) => {
            // console.log("confirm email onSendVerificationCode", err)
            toast.dismiss(toastId)
            if (err.response.data.errors[0].errCode == "OTP_ERR") {
                authDispatch({
                    type: 'UPDATE_TIMER',
                    value: Math.floor(
                        (new Date(err.response.data.errors[0].tokenExpiresAt).getTime() - Date.now()) / 1000
                    )
                })
            } else if (err.response.data.errors[0].errCode == "USER_ERR") {
                authDispatch({
                    type: 'UPDATE_TIMER',
                    value: Math.floor(
                        (new Date(err.response.data.errors[0].tokenExpiresAt).getTime() - Date.now()) / 1000
                    )
                })
            }
        },
        onMutate: () => {
            toastId = toast.loading("Sending OTP")
        }
    })

    const { mutate: onReminderTrigger } = useMutation(userRemainderTrigger, {
        onSuccess: (data) => {

        },
        onError: (err) => {

        },
        onMutate: () => {

        }
    })


    useEffect(() => {
        function handleBeforeUnload(ev) {
            ev.preventDefault()
            // Your code here to handle the tab close event
            // Make API calls or perform any necessary cleanup tasks
            //   ev.returnValue = 'Are you sure you want to close?';
            onReminderTrigger()

        }

        window.addEventListener('beforeunload', onReminderTrigger);

        return () => {
            window.removeEventListener('beforeunload', onReminderTrigger);
        };
    }, []);

    useEffect(() => {
        onSendVerificationCode()
    }, [])

    useEffect(() => {
        const TimerInt = authState.timer > 0 && setInterval(() => {
            authDispatch({
                type: 'UPDATE_TIMER',
                value: authState.timer - 1
            })
        }, 1000);
        return () => {
            clearInterval(TimerInt)
        }
    }, [authState.timer]);

    console.log(authState)
    return <Layout>
        <Helmet>
            <meta charSet="utf-8" />
            <title>AdSpyder Email Verification</title>
            <meta name="title" content="AdSpyder Email Verification" />
            <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
        </Helmet>
        {/* 
        {authState.businessEmailFound ? <BusinessMailConfirmation>
                        <h3>Your business email has been verified successfully.</h3>
                        <p>You will be getting a 10% discount on your subscription fee. Proceed to the payments page for details.</p>
                        <ProceedButton onClick={()=>{
                                authDispatch({type: 'SET_EMAIL_VERIFIED', value: true});
                                navigate('/loginsurvey')
                            }}>Proceed</ProceedButton>
                    </BusinessMailConfirmation>
                    :
                    <>
                    <ResetPasswordHeader>
                    <p>Confirm your email</p>
                    <EmailSelected>
                        <p>Please enter the verification code sent to you at: <span>{
                            authState.email
                        }</span>
                            <a onClick={
                                () => {
                                    onLogout()
                                }
                            }>Change email</a>
                        </p>
                    </EmailSelected>
                </ResetPasswordHeader>
        
        
                <FormContainer onSubmit={
                    handleSubmit((data) => {
                        data.email = authState.email;
                        onSubmit(data)}
                        )
                    // handleSubmit(onSubmit)
                }>
                    <OtpInputContainer>
                        <OtpInput placeholder="000-000" type="number" error={errors.verificationCode} {...register("verificationCode", 
                                                { 
                                                    required: {
                                                    value: true, 
                                                    message: "Enter your OTP"
                                            } })}/>
                        {errors.verificationCode && <Error>
                                {errors.verificationCode.message}
                            </Error>}
                        <TimerSection><ResendBtn onClick={onSendVerificationCode} invisible={authState.timer > 0 ? "true" : "false"}>Resend code</ResendBtn> You can resend the code in <span>{fmtMSS(authState.timer)}</span></TimerSection>
                    </OtpInputContainer>
                    {otpError && <OtpError>
                        <img src={WarningIcon}/> <p>{errorMessage}</p>
                    </OtpError>}
                    <CtaContainer>
                        <ChangePasswordButton type='submit'>Confirm email</ChangePasswordButton>
                    </CtaContainer>
                    <FooterContainer>
                        <p>Haven't received the email? Please check your spam folder or
                            <a href="mailto: support@adspyder.com">Contact us</a>
                        </p>
                    </FooterContainer>
                </FormContainer>
                </>
                    } */}

        <Container>
            <Header>
                <p className='headline'>We emailed you a code</p>
                <p className='notify-msg'>we sent a six digit code to {authState.email ? authState.email : authState.formEmail}. Enter the code below or <a onClick={
                        () => {
                            onLogout()
                        }
                    }>Change email</a>  </p>
            </Header>
            <SubContainer>
                <FormDiv onSubmit={
                        handleSubmit((data) => {
                            console.log("on ssubmit",data)
                            // data.email = authState.email;
                            // data.verificationCode = otp.join();
                            onSubmit({
                                email: authState.email ? authState.email : authState.formEmail,
                                verificationCode: otp
                            })
                        }
                    )
                }>
                    <FormDivContainer>
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            // onPaste={true}
                            inputType={"number"}
                            renderSeparator={false}
                            containerStyle={"InputListContainer"}
                            renderInput={(props) => <input {...props} />}
                        />
                        <VerifyBtn type="submit">Verify</VerifyBtn>
                    </FormDivContainer>

                </FormDiv>
                <FooterText>
                    <p>Didn’t receive any email? Try checking your Junk folder.</p>
                    <TimerSection>
                        
                        <p>
                            <ResendBtn onClick={onSendVerificationCode} invisible={authState.timer > 0 ? "true" : "false"}>Resend code</ResendBtn>  in 
                            <span>{fmtMSS(authState.timer)}</span>
                        </p>
                    </TimerSection>
                </FooterText>
            </SubContainer>
        </Container>

    </Layout>
}
export default ConfirmEmail



