import styled from "styled-components";

export const ProjectNameWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  p {
    color: #a0a0a0;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.14px;
  }
  img{
    /* height: 30px; */
  }
`;
export const ProjectCreationWrap = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;
`;
export const ProjectInfoBtn = styled.div`
  border-radius: var(--8, 8px);
  border: 1px solid #111926;
  background: #fff;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  p {
    color: #111926;
    font-size: 14px;
    font-weight: 500;
  }
`;
export const AddProject = styled.div`
  width: 137px;
  height: 40px;
  border-radius: var(--8, 8px);
  background: #111926;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  p {
    color: #fff;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-weight: 500;
  }
`;

export const ProjectName = styled.div`
  color: #000;
  font-size: 24px;
  font-weight: 600;
  span {
    color: #ff711e;
    font-size: 24px;
    font-weight: 600;
  }
`;
export const DashboardHeadWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 17px;
`;
export const AdsWrap = styled.div`
display: flex;
gap: 30px;
width: 100%;
`

export const ChartsContainer = styled.div`
  display: flex;
    gap: 30px;
    width: 100%;
`;