import styled from "styled-components";

export const BrandDescriptionContainer = styled.div`
    width: 738px;
    height: 354PX;
    flex-shrink: 0;
    border-radius: 10px;
    background: #FFF;
    padding: 30px 32px;
`;

export const Label = styled.div`
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    /* Large (default)/Subheadline */
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    letter-spacing: 0.15px;
    margin-bottom: 11px;
`;

export const Name = styled.textarea`
    resize: none;
    padding: 13px 20px;
      color: rgba(28, 28, 28, 0.90);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
    width: 674px;
    height: 53px;
    flex-shrink: 0;
    border-radius: 8px;
    border: ${(props) => props.hasError ? "1px solid #F04438" : props.isFocussed ? "1px solid #FFB258" : "1px solid #D0D5DD"};
    box-shadow: ${(props) => props.isFocussed ? "0px 1px 2px 0px rgba(28, 28, 28, 0.05), 0px 0px 4px 0px rgba(255, 178, 88, 0.25)" : "unset"};
    outline: none;
    rows: 6;
    margin-bottom: 24px;
`;

export const Description = styled.textarea`
    resize: none;
    padding: 16px 20px;
          color: rgba(28, 28, 28, 0.90);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
    width: 674px;
    height: 157px;
    flex-shrink: 0;
    border-radius: 8px;
    border: ${(props) => props.hasError ? "1px solid #F04438" : props.isFocussed ? "1px solid #FFB258" : "1px solid #D0D5DD"};
    box-shadow: ${(props) => props.isFocussed ? "0px 1px 2px 0px rgba(28, 28, 28, 0.05), 0px 0px 4px 0px rgba(255, 178, 88, 0.25)" : "unset"};
    outline: none;
    rows: 6;
`;