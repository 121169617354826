import React, { useEffect, useState } from "react";
import {
  Container,
  Header,
  AdsContainer,
  GeneratedAdCard,
  GeneratedAdTitle,
  GeneratedAdDescription,
  TitleCopyImg,
  DescCopyImg,
  IndividualWrapper,
} from "./index.styled";
import { useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import TickImg from "../../../../../media/Dashboard/tick-grey.svg";
import CopyBtn from "../../../../../media/Dashboard/copy.png";
import { useSavedAds } from "../../context/savedads";
// import SearchIcon from "../../../../../../../media/Dashboard/searchIcon.svg";
import AdCard from "../AdCards";
import { appClient } from "../../../../../utils/axios-utils";
import { useMutation } from "@tanstack/react-query";
import { Helmet } from "react-helmet";
import { BreadCrumb } from "../../index.styled";
import { getSearchParams } from "../../../../../utils/helper.js";
import AdDetails from "../../../../components/AdDetailsModal";

const navItems = [
  {
    name: "Ad Copies",
    id: "ad-copies",
  },
  {
    name: "CTAs",
    id: "ctas",
    disabled: true,
  },
  {
    name: "Target Audience Data",
    id: "target-audience-data",
    disabled: true,
  },
  {
    name: "Generated Ads",
    id: "generated-ads",
    // disabled: true
  },
];

const getProjectAds = (data) => {
  const config = {
    url: "/dashboard-overview/project/saved-ads",
    method: "POST",
    data,
  };

  return appClient(config);
};

const getProjectGeneratedAds = (data) => {
  const config = {
    url: "/adgeneration/search/generated-ads",
    method: "POST",
    data,
  };

  return appClient(config);
};

const IndividualProject = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { savedAdsState, savedAdsDispatch } = useSavedAds();
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [copiedItem, setCopiedItem] = useState("");
  const [showTick, setShowTick] = useState(false);

  const [selectedSubNav, setSelectedSubNav] = useState("");

  const location = useLocation();

  // const onSearchFilter = (value) => {
  //     let finalData = projectNames.filter((project)=>{
  //         if(project.toLocaleLowerCase().includes(value)){
  //             return project
  //         }
  //     })

  //     setFilteredData(finalData)
  // }

  const { mutate: getProjectAdsMutate, isLoading: isProjectAdsLoading } =
    useMutation(getProjectAds, {
      onSuccess: (data) => {
        savedAdsDispatch({ type: "SET_SAVED_ADS", value: data.result });
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => {},
    });

  const {
    mutate: getProjectGeneratedAdsMutate,
    isLoading: isProjectGeneratedAdsLoading,
  } = useMutation(getProjectGeneratedAds, {
    onSuccess: (data) => {
      savedAdsDispatch({ type: "SET_PROJECT_GENERATED_ADS", value: data });
    },
    onError: (err) => {
      console.log(err);
    },
    onMutate: () => {},
  });

  // useEffect(() => {
  //     getProjectAdsMutate({projectId: savedAdsState.selectedCollection.id})
  // }, [])

  const findTab = (tabname) => {
    let tab = navItems.filter((d) => {
      return d.id.replace("-", "").replace("-", "") == tabname;
    });

    return tab;
  };

  const onCopyClick = (value) => {
    navigator.clipboard.writeText(value);
    setCopiedItem(value);
    setShowTick(true);
    setTimeout(() => {
      setShowTick(false);
    }, 1500);
  };

  const onDetailsClose = () => {
      savedAdsDispatch({type: 'SET_SELECTED_AD_FOR_DETAIL', value: null})
      savedAdsDispatch({ type: 'UPDATE_DETAILS_MODAL', value: false })
  }

  useEffect(() => {
    // console.log('search param updated')
    const searchParamFromState = getSearchParams(location.search);

    const { projectid, projectname, tab } = searchParamFromState;
    if(projectid) getProjectAdsMutate({ projectId: projectid });

    // let currentTab = findTab(tab);

    // setSelectedSubNav(currentTab[0].id);

    // savedAdsDispatch({type: 'SET_SELECTED_COLLECTION', value: {
    //     id: projectid,
    //     name: projectname
    // }})

    // if (currentTab[0].id == "ad-copies") {
    //   getProjectAdsMutate({ projectId: projectid });
    // } else if (currentTab[0].id == "generated-ads") {
    //   getProjectGeneratedAdsMutate({ projectId: projectid });
    // }
  }, [location.search]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Profile | Saved Ads</title>
        <meta name="title" content="Profile | Saved Ads" />
        <meta
          name="description"
          content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. "
        />
      </Helmet>
      <IndividualWrapper>
        <BreadCrumb>
          {/* <span>Account & Activity</span>
          {">"} */}
          <span>Saved Items</span>
          {savedAdsState.selectedProject.id ? <>{">"}</> : ""}
          {savedAdsState.selectedProject.id ? (
            <span>{savedAdsState.selectedProject.projectName}</span>
          ) : (
            ""
          )}
        </BreadCrumb>
        <Container>
          <Header>
            <h1>{savedAdsState.selectedProject.projectName}</h1>
            {/* <SubNavContainer>
              {navItems.map((item, idx) => {
                return (
                  <RightNavItem
                    isDisabled={item.disabled}
                    key={idx}
                    isActive={item.id == selectedSubNav}
                    onClick={() => {
                      const params = new URLSearchParams(searchParams);
                      params.set(
                        "tab",
                        item.id.replace("-", "").replace("-", "")
                      );
                      setSearchParams(params);
                    }}
                  >
                    {item.name}
                  </RightNavItem>
                );
              })}
            </SubNavContainer> */}
            {/* <SearchWrapper>
                  <SearchContainer>
                      <Input
                          placeholder="Search"
                          onChange={(e)=>{
                              setSearchText(e.target.value)
                              // onSearchFilter(e.target.value)
                          }}
                      />
                      <SearchIconContainer>
                          <img src={SearchIcon}/>
                      </SearchIconContainer>
                  </SearchContainer>
                  <FilterContainer>
                      <h3>Filter by:</h3>
                  </FilterContainer>
              </SearchWrapper> */}
          </Header>
          <AdsContainer>
            {savedAdsState.savedAds.length > 0 ? 
              <>
                {savedAdsState.detailsModal && savedAdsState.selectedAdForDetail &&
                    <AdDetails 
                        platform={savedAdsState.selectedAdForDetail?.platform}
                        basicDetail={savedAdsState.selectedAdForDetail}
                        onDetailsClose={onDetailsClose}
                        noSaveNeeded
                    />
                }
                {savedAdsState.savedAds.map((ad, idx) => {
                  return (
                    <>
                      <AdCard ad={ad} />
                    </>
                  );
                })}
              </>
                
              : (
                <h3>No Saved Ads</h3>
              )}
            {selectedSubNav === "generated-ads" ? (
              isProjectGeneratedAdsLoading ? (
                <span>Loading...</span>
              ) : savedAdsState.projectGeneratedAds.length > 0 ? (
                savedAdsState.projectGeneratedAds.map((ad, idx) => {
                  return (
                    <GeneratedAdCard>
                      <GeneratedAdTitle>
                        {ad.title}{" "}
                        <TitleCopyImg
                          onClick={() => onCopyClick(ad.title)}
                          src={
                            showTick && ad.title == copiedItem ? TickImg : CopyBtn
                          }
                        />
                      </GeneratedAdTitle>
                      <GeneratedAdDescription>
                        {ad.description}{" "}
                        <DescCopyImg
                          onClick={() => onCopyClick(ad.description)}
                          src={
                            showTick && ad.description == copiedItem
                              ? TickImg
                              : CopyBtn
                          }
                        />
                      </GeneratedAdDescription>
                      {/* <GeneratedAdCardFooter>
                      <SaveAd onClick={()=>{
                          saveGenerateAds({
                              projectId: adGenratorState.selectedProject.id,
                              adId: ad.id
                          })
                      }}>
                          <img src={SaveIcon}/> {adGenratorState.savedAds.includes(ad.id) ? "saved" : "save"}
                      </SaveAd>
                  </GeneratedAdCardFooter> */}
                    </GeneratedAdCard>
                  );
                })
              ) : (
                <h3>No Generated Ads</h3>
              )
            ) : (
              ""
            )}
          </AdsContainer>
        </Container>
      </IndividualWrapper>
    </>
  );
};

export default IndividualProject;
