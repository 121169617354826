import styled from "styled-components"

export const KeywordSearchTypeContainer = styled.div`
    /* width: 170px; */
    height: 40px;
    gap: 4px;
    /* background-color: white; */
    border-radius: 8px;
    /* border: 1px solid #ECECEC; */

    display: flex;
`;

export const KeywordButtonContainer = styled.div`
    height: 40px;
    background: ${props => props.active ? "#F6FBFF" : "white"};
    color: ${props => props.active ? "#12558D" : "#2E2E2E"};
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: ${props => props.active ? "1px solid #B8D9F5": "1px solid #ECECEC"};
    padding: 6px 12px;
    cursor: pointer;
    
    &:hover{
        border: ${props => props.active ? "" : "1px solid #5AB3FF" };
    }

    input[type=radio]
    {
        background: transparent;
        appearance: none;
        width: 100px;
        height: 30px;
        position: absolute;
    }

    input[type=radio] + label
    {
        cursor: pointer;
        position: relative;
        padding-left: 20px;
        line-height: 20px;
    }

    input[type=radio] + label::before
    {
        content: "";
        display: inline-block;
        width: 12px;
        aspect-ratio: 1;
        border: ${props => props.active ? "1px solid #12558D" : "1px solid #E2E2E2"};
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        opacity: 1;
        transition: all 0.3s;
    }

    input[type=radio] + label::after
    {
    content: "";
        display: inline-block;
        width: 6px;
        aspect-ratio: 1;
        border: 1px solid #686de0;
        background: ${props => props.active ? "#12558D" : "white"};
        border-radius: 50%;
        position: absolute;
        left: 3px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        transition: all 0.3s;
    }

    input[type=radio]:checked + label::after
    {
        opacity: 1;
    }


`;


export const ToolTipContainer = styled.div`
    font-size: 12px;
    font-weight: 300;
    color: #666;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;

    img{
        margin-left: 4px;
    }

`;

export const Wrapper = styled.div`

`;




export const Down = styled.div`
    width: 120px;
    min-height: 40px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 2px 6px #0000001A;
    position: absolute;
    z-index: 2;
    top: calc(100% + 6px);
    padding: 5px;
    cursor: auto;

    display: ${props => props.show ? 'flex': 'none'};
    flex-direction: column;
    justify-content: center;
    left: 0;
`;

export const Row1 = styled.div`
    width: 100%;
    height: 32px;
    padding: 10px;

    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 300;
    p {
        color: #919191;
    }
`;

export const Row = styled(Row1)`
    cursor: pointer;
    height: 32px;
    transition: 0.2s all ease-in-out;
    border-radius: 4px;
    padding: 10px;
    background-color: ${props => props.buttonDisabled ? "none" : props.isSelected ? "#E8F5FF" : "none"};

    :hover {
        background-color: ${props => props.buttonDisabled ? "none" : props.isSelected ? "#E8F5FF" : "#F6F6F6"};
    }

    p {
        color: #2E2E2E;
    }
`;

export const DropDownImage = styled.div`
    img{
        height: 11px;
        margin-left: 8px;
    }    
`;

export const CrossImageContainer = styled.div`
    height: 18px;
    width: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
    border-radius: 10px;
    img{
        height: 12px;
    }
    &:hover{
        background: #A8D0EF;
    }
`;

export const DropdownContainer = styled.div`
    background: ${props => props.isSelected ? "transparent" : "transparent"};
    border: ${props => !props.isSelected ? "1px solid #ECECEC" : "1px solid #B8D9F5"};
    padding: 6px 12px;
    height: 40px;
    position: relative;
    cursor: pointer;    
    width: 125px;
    border-radius: 0px;
    background: transparent;

    &:hover{
        border: ${props => props.isSelected ? "1px solid #8AB7DC" : "1px solid #5AB3FF"};
        background: ${props => props.isSelected ? "#E8F4FF" : ""};
    }
`;

export const DropdownButton = styled.div`
    display: flex;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: space-around;

    span{
        font-size: 14px;
        color: ${props => props.isSelected ? "#12558D" : "#2E2E2E"};
        
    }

    span{
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: #1C1C1C;
    }

`;
