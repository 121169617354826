import styled from "styled-components";

export const AdContainer = styled.div `
    width: 420px;
    height: 240px;
    background: #FFFFFF;
    border:  ${
        props => props.detaislOpen ? "2px solid #006ECC" : "1px solid #F2F2F2"
    };
    border-radius: 12px;
    /* grid-row-start: ${
        props => props.detaislOpen ? (((props.idx + 1) % 3) == 0) ? Math.floor((props.idx + 1) / 3) : Math.floor((props.idx + 1) / 3) + 1: ""
    };

    grid-row-end: ${
        props => props.detaislOpen ? (((props.idx + 1) % 3) == 0) ? Math.floor((props.idx + 1) / 3) + 2 : Math.floor((props.idx + 1) / 3) + 3: ""
    };

    grid-column: ${props => props.detaislOpen ? (((props.idx + 1) % 3) == 0) ? 3 : ((props.idx + 1) % 3) : ""};

    @media screen and (max-width: 1350px) {
        width: 100%;
        grid-row-start: ${
            props => props.detaislOpen ? (((props.idx + 1) % 2) == 0) ? Math.floor((props.idx + 1) / 2) : Math.floor((props.idx + 1) / 2) + 1: ""
        };

        grid-row-end: ${
            props => props.detaislOpen ? (((props.idx + 1) % 2) == 0) ? Math.floor((props.idx + 1) / 2) + 2 : Math.floor((props.idx + 1) / 2) + 3: ""
        };

        grid-column: ${props => props.detaislOpen ? (((props.idx + 1) % 2) == 0) ? 2 : ((props.idx + 1) % 2) : ""};
    } */


`;

export const AdContent = styled.div `
    display: flex;
    position: relative;
`;

export const ImageContainer = styled.div `
    width: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    padding: 16px 16px 0px 16px;
    position: relative;
    img{
        height: 120px;
        width: 120px;
        object-fit: scale-down;
    }
    span{
        position: absolute;
        color: #FFFFFF;
        background: #919191;
        border-radius: 4px;
        padding: 2px 4px;
        font-size: 12px;
        font-weight: 300;
        top: 16px;
        left: 16px;
    }
`;

export const FloatingButtonsContainer = styled.div `
    position: absolute;
    right: 12px;
    top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F9F9F9;
    color: #D4D4D4;
    font-size: 8px;
`;

export const Favorite = styled.div `
    background: #F9F9F9;
    width: 32px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    border-radius: 4px 0px 0px 4px;
    img{
        margin: 25%;
    }
    &:hover{
        background: #ECECEC;
    }
    &:active{
        background: #DCEEFF;
    }
    
`;

export const AdBodyContainer = styled.div `
    padding: 16px 16px 16px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const ProductName = styled.p `
    color: #151515;
    font-size: 14px;
    max-width: 80%;
    margin-bottom: 4px;
    line-height: 21px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2; 
   -webkit-box-orient: vertical;
    min-height: 41px;
`;

export const PriceWraper = styled.div `
    display: flex;
`;

export const ProductPrice = styled.p `
    color: #050505;
    font-size: 14px;
    margin-bottom: 4px;
    font-weight: 600;
    line-height: 21px;
    /* padding: 0px 16px; */
`;

export const WebsiteUrl = styled.p `
    color: #919191;
    font-size: 14px;
    font-weight: 300;
    /* line-height: 21px; */
    padding: 0px 16px;
    margin-bottom: 12px;
`;

export const AdFooter = styled.div `

`;

export const AdBodyBottomContainer = styled.div `
    border-top: 1px solid #F2F2F2;
    padding: 16px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const AdDate = styled.div `
    display: flex;
    font-size: 14px;
    color: #202124;
    line-height: 22px;
    align-items: center;
    padding: 0px 16px;
    /* margin-bottom: 16px; */

    img{
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
`;

export const AdCountries = styled.div `
    display: flex;
    font-size: 14px;
    color: #202124;
    line-height: 22px;
    align-items: center;
    padding: 0px 16px;
    flex-wrap: wrap;
    p{
        color: #919191;
        margin-right: 16px;
    }
    > img{
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
`;

export const DetailsButton = styled.div `
    color: #006ECC;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    height: 32px;
    justify-content: flex-end;
    align-items: center;
    padding-right: 16px;
    padding-bottom: 16px;
`;


export const SelectButton = styled.input`
    height: 20px;
    width: 20px;
    cursor: pointer;
`;

export const AdTypeContainer = styled.div`
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
    width: max-content;
    position: absolute;
    top: -20px;
    left: 12px;
    padding: 6px;
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 5px;

    img{
        height: 12px;
    }
`;