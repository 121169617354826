import React from "react";
import { useReducer, createContext, useContext } from "react"

import produce from 'immer'

const initialState = {
    otpSent: false,
    otpVerified: false,
    email: "",
    otp: "",
    passwordType: "password",
    passwordEntered: "",
    timer: 0,
    passwordChanged: false
}

const PasswordChangeReducer = produce((draft,action)=>{
    switch(action.type){
        case 'SET_OTP_SENT':
            draft.otpSent = action.value
            break
        case 'SET_EMAIL':
            draft.email = action.value
            break
        case 'UPDATE_PASSWORD_TYPE':
            draft.passwordType = action.value
            break
        case 'UPDATE_PASSWORD_ENTERED':
            draft.passwordEntered = action.value
            break
        case 'UPDATE_TIMER':
            draft.timer = action.value
            break
        case 'UPDATE_OTP_VERIFIED':
            draft.otpVerified = action.value
            break
        case 'UPDATE_OTP':
            draft.otp = action.value
            break
        case 'SET_PASSWORD_CHANGED':
            draft.passwordChanged = true
            break
        default:
            break
    }
})

const usePasswordChangeReducer = () => {
    return useReducer(PasswordChangeReducer,initialState)
} 

const PasswordChangeContext = createContext()

export const PasswordChangeProvider = ({children}) => {
    const [state, dispatch] = usePasswordChangeReducer()
    // const value = useMemo(()=>({passwordChangeState: state,passwordChangeDispatch: dispatch}), [state])
    const value = {
        passwordChangeState: state,
        passwordChangeDispatch: dispatch
    }

    return <PasswordChangeContext.Provider value={value}>
        {children}
    </PasswordChangeContext.Provider>
}

export const usePasswordChange = () => {
    return useContext(PasswordChangeContext)
}
