import React, { useEffect } from "react";
import Layout from "../../Components/SurveyLayout/layout";
import { appClient } from '../../../../../utils/axios-utils'
import { useMutation } from "@tanstack/react-query"

import {
    SurveyContainer,
    Progress,
    ProgressHolder,
    AboutSection,
    CompetitorSection
} from "./index.styled"

import VerifyNumber from "./VerifyNumber";

import { useSurvey } from "./../context/survey";
import AboutYou from "./AboutYou";
import { useAuth } from "../../../../../context/auth";
import AddDomain from "./AddDomain";

const getOnBoarding = (role) => {
    const config = {
        url: "/app/onboarding/get-onboarding",
        method: "GET"
    }

    return appClient(config)
}

const userRemainderTrigger = (data) => {
    const config = {
        url: "/app/onboarding/onboarding-reminder",
        method: "GET"
    }

    return appClient(config)
}

const PostLoginSurvey = () => {
    const { surveyState, surveyDispatch } = useSurvey()
    const { authState, authDispatch } = useAuth()
    console.log("authState", authState)

    const { mutate: getOnboardingData } = useMutation(getOnBoarding, {
        onSuccess: (data) => {
            if (data.data) {
                surveyDispatch({ type: 'SET_SURVEY_DETAILS', data: data.data })
            }
            // if(data.data.)
        },
        onError: (err) => {

        },
        onMutate: () => {

        }
    })

    const { mutate: onReminderTrigger } = useMutation(userRemainderTrigger, {
        onSuccess: (data) => {

        },
        onError: (err) => {

        },
        onMutate: () => {

        }
    })

    useEffect(() => {
        function handleBeforeUnload(ev) {
            ev.preventDefault()
            // Your code here to handle the tab close event
            // Make API calls or perform any necessary cleanup tasks
            //   ev.returnValue = 'Are you sure you want to close?';
            onReminderTrigger()

        }

        window.addEventListener('beforeunload', onReminderTrigger);

        return () => {
            window.removeEventListener('beforeunload', onReminderTrigger);
        };
    }, []);

    useEffect(() => {
        if (authState.phoneVerified) {
            surveyDispatch({
                type: 'SET_CURRENT_STEP',
                value: surveyState.currentStep + 1
            })
            surveyDispatch({ type: 'SET_PROGRESS', value: (surveyState.currentStep * 33) + 33 })
        }
        getOnboardingData()
    }, [])

    if (surveyState.currentStep == 3) {
        return <AddDomain />
    }

    return <Layout>
        <SurveyContainer>
            <ProgressHolder>
                <Progress
                    value={surveyState.progress}
                    max="100"
                />
            </ProgressHolder>

            {/* <QuestionContainer>
                    <p>{
                        surveyState.questions[surveyState.currentStep - 1].question
                    }</p>
                    <Step>Step {
                        surveyState.currentStep
                     + " "}
                        of {
                        surveyState.questions.length
                    }</Step>
                </QuestionContainer> */}
            {/* {
                    surveyState.currentStep == 1 && <RoleSelection/>
                }

                {
                    surveyState.currentStep == 2 && <VerifyNumber/>
                }

                {
                    surveyState.currentStep == 3 && <AboutYou/>
                }
                
                {
                    surveyState.currentStep == 4 && <DomainSelection/>
                } */}
            {
                surveyState.currentStep == 1 && <VerifyNumber />
            }

            {
                surveyState.currentStep == 2 && <AboutYou />
            }
            <AboutSection></AboutSection>

            <CompetitorSection></CompetitorSection>

        </SurveyContainer>
    </Layout>
}


export default PostLoginSurvey
