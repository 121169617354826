import { useAdManagement } from "../../../../context/AdManagementContext";
import { useAdManagementInfo } from "../../../../hooks/useAdManagementInfo";
import { ButtonWrapper, CheckEmailDescrip, CheckEmailPopup, CheckEmailTitle, CheckEmailVerifyBtn, CheckMailWrapper } from "./index.styled";

function CheckMailPopup() {
    const adManagementHook = useAdManagementInfo()
    const { adManagementState, adManagementDispatch } = useAdManagement()
    return ( 
        <>
        <CheckMailWrapper>
            <CheckEmailPopup>
                <CheckEmailTitle>
                    Additional step required!
                </CheckEmailTitle>
                <CheckEmailDescrip>
                    Google has sent an invitation to your registered email address. Kindly accept this invitation to proceed with the integration process.<br/>
                    <br/>
                    Once you've accepted the invitation, click the button below to verify and continue.
                </CheckEmailDescrip>
                <ButtonWrapper>
                    <CheckEmailVerifyBtn onClick={()=> adManagementHook.setCheckMailVerified({ "customerId": adManagementState.isManagerId})}>
                        Verify & continue
                    </CheckEmailVerifyBtn>
                    {adManagementState.isCheckEmailError && <span>Invitation has not been accepted</span>}
                </ButtonWrapper>
            </CheckEmailPopup>
        </CheckMailWrapper>
       </>
    );
}

export default CheckMailPopup;