import React from 'react'
import { useReducer, createContext, useContext } from 'react'

import produce from 'immer'

const initialState = {
    customerId: "",
    campaignBudgetData: {
        amountMicros: "",
        name: "",
        resourceName: "",
    },
    campaignData: {
        name: "",
        advertisingChannelType: "",
        resourceName: "",
        status: "",
        targetSpend: {},
        campaignBudget: "",
    },
    adGroupData: {
        name: "",
        status: "",
        type: "",
        cpcBidMicros: "",
        campaign: "",
    },
    adGroupAdData: {
        ad: {
            responsiveSearchAd: {
                path1: "",
                path2: "",
                headlines: "",
                descriptions: "",
            },
            finalUrls: "",
        },
    },
    keywords: [
        
    ]
}

const AdIntegrationReducer = produce((draft, action) => {
    switch(action.type) {

        case 'SET_CUSTOMER_ID':
            draft.customerId = action.value
            break

        case 'SET_CAMPAIGN_BUDGET_DATA_AMOUNT':
            draft.campaignBudgetData.amountMicros = action.value
            break

        case 'SET_CAMPAIGN_BUDGET_DATA_NAME':
            draft.campaignBudgetData.name = action.value
            break

        case 'SET_CAMPAIGN_BUDGET_DATA_RESOURCE_NAME':
            draft.campaignBudgetData.resourceName = action.value
            break
    
        case 'SET_CAMPAIGN_DATA_NAME':
            draft.campaignData.name = action.value
            break

        case 'SET_CAMPAIGN_DATA_CHANNEL_TYPE':
            draft.campaignData.advertisingChannelType = action.value
            break

        case 'SET_CAMPAIGN_DATA_RESOURCE_NAME':
            draft.campaignData.resourceName = action.value
            break
        
        case 'SET_CAMPAIGN_DATA_STATUS':
            draft.campaignData.status = action.value
            break

        case 'SET_CAMPAIGN_DATA_TARGET_SPEND':
            draft.campaignData.targetSpend = action.value
            break

        case 'SET_CAMPAIGN_DATA_CAMPAIGN_BUDGET':
            draft.campaignData.campaignBudget = action.value
            break
             
        case 'SET_ADGROUP_DATA_NAME':
            draft.adGroupData.name = action.value
            break

        case 'SET_ADGROUP_DATA_STATUS':
            draft.adGroupData.status = action.value
            break

        case 'SET_ADGROUP_DATA_TYPE':
            draft.adGroupData.type = action.value
            break

        case 'SET_ADGROUP_DATA_CPC':
            draft.adGroupData.cpcBidMicros = action.value
            break

        case 'SET_ADGROUP_DATA_CAMPAIGN':
            draft.adGroupData.campaign = action.value
            break
                
        case 'SET_RESPONSIVE_SEARCH_AD_DATA':
            draft.adGroupData.campaign = action.value
            break
                   
        case 'SET_AD_HEADINGS_DATA':
            draft.adGroupAdData.ad.responsiveSearchAd.headlines = action.value
            break

        case 'SET_AD_DESCRIPTIONS_DATA':
            draft.adGroupAdData.ad.responsiveSearchAd.descriptions = action.value
            break

        case 'SET_AD_FINALURLS_DATA':
            draft.adGroupAdData.ad.finalUrls = action.value
            break

        case 'SET_KEYWORDS':
            draft.keywords = action.value
            break

        default:
            break
    }
})

const useAdIntegrationReducer = () => {
    return useReducer(AdIntegrationReducer, initialState)
}

const AdIntegrationContext = createContext()

export const AdIntegrationProvider = ({ children }) => {
    const [state, dispatch] = useAdIntegrationReducer()
    
    const value = {
        adIntegrationState: state,
        adIntegrationDispatch: dispatch
    }
    
    return <AdIntegrationContext.Provider value={value}>
        {children}
    </AdIntegrationContext.Provider>
}

export const useAdIntegration = () => {
    return useContext(AdIntegrationContext)
}
