import CountryData from "../ui/pages/AuthPages/PostLoginSurvey/components/VerifyNumber/country.json"
import { countryNewData } from "./countryData";

export const getSearchParams = (searchParamsData) => {
    const searchParams = new URLSearchParams(searchParamsData);
    const params = {};
    // console.log(searchParams.entries())
  
    for (const [key, value] of searchParams.entries()) {
        // console.log(params, key, value)
      params[key] = value;
    }
  
    return params;
}

export const getDomainName = (linkUrl) => {
    try{
        let url = new URL(linkUrl);
        return url.hostname
    }catch(e){
        return linkUrl
    }
}

export const urlPatternValidation = URL => {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');    
    return regex.test(URL)
}

export const dateFormatter = (date) => {
    if(!date){
        return ""
    }
    const dateString = date;

    const newDate = new Date(dateString);

    const formattedDate = newDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
    });

    var parts = formattedDate.split("/");
    var rearrangedDate = parts[2] + "-" + parts[0] + "-" + parts[1];

    return rearrangedDate
}

export const shortDateFormatter = (date) => {
    if (!(date instanceof Date) || isNaN(date)) {
        return ''; // or handle the error in an appropriate way
      }
    const day = date.getDate();
    const month = date.toLocaleString('default', {month: 'short'});
    const year = date.getFullYear().toString().slice(-2);
    const formattedDate = `${day} ${month} '${year}`;
    return formattedDate // Output: "18 May '22"
} 


export const formatWithCommas = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
  };

export const findCriteriaIdByCountry = (countryName) => {
    const country = countryNewData.find(
        (c) => c.adspyder_name === countryName
      );
    
      if (country) {
        return {
          name: country.adspyder_name,
          criteriaId: country.geo_targeted_id, 
        };
      }
    
      return null;
}