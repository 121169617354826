import React from "react";
import {Container, RightContent} from './index.styled'
import SideNavBar from "../SideNavBar";
import Layout from "../../../../layout"

const AccountPageLayout = ({children, isSideNavDisabled}) => {
    return <Layout>
                <Container>
                    {!isSideNavDisabled && <SideNavBar/>}
                    <RightContent isSideNavDisabled={isSideNavDisabled}>
                        {children}
                    </RightContent>
                </Container>
            </Layout>
}

export default AccountPageLayout