import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { DomainOverviewProvider } from './context/domainoverview';
import {Helmet} from "react-helmet"

// Layout
import Layout from "../../layout"

// Pages
import DomainOverviewDetails from './DomainOverviewDetails';
import DomainOverview from './DomainOverviewHome';
// import { getSearchErrCode } from "../../../helper/getSearchErrCode"

// Not found page
import NotFound from '../NotFoundPage';

// import {appClient} from '../../../../utils/axios-utils'

const DomainOverviewComponent = () => {
    return <Routes>
            <Route path='*' element={
                <NotFound />
            } />
            <Route path="/" element={
                <Layout>
                    <DomainOverview/>
                </Layout>
            } />
            <Route path="details" element={
                <Layout>
                    <DomainOverviewDetails />
                </Layout>
            } />
        </Routes>
};

const DomainOverviewProviderWrapper = () => {
    return <DomainOverviewProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PPC Domain Anslysis | AdSpyder</title>
                <meta name="title" content="PPC Domain Anslysis | AdSpyder" />
                <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
            </Helmet>
        <DomainOverviewComponent/>
    </DomainOverviewProvider>
}

export default DomainOverviewProviderWrapper