import styled from "styled-components";

export const ExitWrap = styled.div`
  padding: 16px 16px 0 24px;
  display: flex;
  justify-content: end;
  img{
      cursor: pointer;
  }
`
export const ContentWrap = styled.div`
  padding: 0 32px  0 24px;
`
export const Header = styled.div`
  color: var(--black-100, #1C1C1C);
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
`
export const StockImageContainer = styled.div`
  width: 738px;
  height: 509px;
  border-radius: 10px;
  background: #FFF;
`

export const ImgWrapper = styled.div`
  display: grid;
  gap: 12px;
  overflow-y: auto;
  width: 100%;
  padding-right: 6px;
  height: 340px;
  img{
    cursor: pointer;
  }
  ::-webkit-scrollbar {
      width: 8px;               /* width of the entire scrollbar */
  }

  ::-webkit-scrollbar-thumb {
      background-color: rgb(185 185 185);    /* color of the scroll thumb */
      border-radius: 10px;     
  }
  /* margin-top: 24px; */
  img{
    height: fit-content;
      width: 100%
  }
`;

export const CustomDiv = styled.div`
  height: 100px; /* default height */
`;

export const OddWidthDiv = styled(CustomDiv)`
  width: 100%;
  height: 200px;
`;

export const EvenWidthDiv = styled(CustomDiv)`
  width: 100%;
  height: 150px;

`;

export const SearchWrapper = styled.div`
  margin-top: 24px;
  height: 40px;
  display: flex;
  justify-content: space-between;
`;

export const SearchContainer = styled.div`
  width: 74%;
  position: relative;
  img{
    position: absolute;
    top: 12px;
    left: 14px;
  }
  input{
    width: 100%;
    height: 100%;
    padding: 0px 0px 0px 40px;
    border-radius: 6px;
    border: 1px solid #DADFE4;
    outline: none;
  }
`;

export const SearchButton = styled.button`
  font-size: 14px;
  font-weight: 400;
  color: #FFF;
  line-height: 20px;
  border-radius: 6px;
  background: #FF711E;
  width: 25%;
  outline: none;
  border: none;
  opacity: ${props => props.isDisabled ? "0.5": "1"};
  pointer-events: ${props => props.isDisabled ? "none": "all"};
`;