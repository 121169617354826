import { KeywordsContainer, KeywordsListContainer, CopyButton } from "./KeywordsList.styled";
import copy from "../../../assets/copy.svg"
import { useTextAdGenerator } from "../../../context/textAdGeneration";
import { useRef } from "react";
import { customToast } from "../../../../../../../utils/toast-utils";

function KeywordsList() {
    const { textAdGeneratorState, textAdGeneratorDispatch } = useTextAdGenerator()
    const { activeAdTemplate } = textAdGeneratorState
    const copyRefs = useRef([])
    const handleMouseEnter = (idx) => {
        const copyRef = copyRefs.current[idx];
        if (copyRef) {
            copyRef.style.display = "block";
        }
    }

    const handleMouseLeave = (idx) => {
        const copyRef = copyRefs.current[idx];
        if (copyRef) {
            copyRef.style.display = "none";
        }
    }

    const handleKeywordClick = (keyword) => {
        navigator.clipboard.writeText(keyword).then(() => {
            customToast({message: "Selected keyword copied to your clipboard"})
        }).catch((err) => {
            console.error('Failed to copy:', err);
        });
    }
    return ( 
        <KeywordsListContainer>
            {
                activeAdTemplate && activeAdTemplate.keywords.map((keyword, idx) => {
                    copyRefs.current[idx] = null; 
                    return (
                        <KeywordsContainer key={idx} onMouseEnter={() => handleMouseEnter(idx)} onMouseLeave={() => handleMouseLeave(idx)} onClick={() => handleKeywordClick(keyword)}>
                            {keyword}
                            <CopyButton ref={(el) => (copyRefs.current[idx] = el)} src={copy} alt="copy" />
                        </KeywordsContainer>
                    )
                })
            }
        </KeywordsListContainer>
     );
}

export default KeywordsList;