import React from "react";
import {useLocation, Route, Routes} from 'react-router-dom'

import {

} from "./index.styled"

import HomePage from "./components/HomePage";
import CategoryPage from "./components/CategoryPage";
import AdGenerationPage from "./components/AdGenerationPage";
import NotFound from "../../NotFoundPage";
import Layout from "../../../layout"


const ImageAdGenerationPage = () => {
    return <Routes>
    <Route path='*' element={
        <NotFound />
    } />
    <Route path="/" element={
        <HomePage />
    } />
    <Route path="/edit" element={
        <AdGenerationPage />
    } />
     <Route path="/:category" element={
        <CategoryPage />
    } />

</Routes>
}

export default ImageAdGenerationPage

