import React from "react";
import Highlighter from "react-highlight-words"

import { Card, FloatingButtonsContainer, CardBody, SponsoredText, AdHeader, WebsiteLogo, WebsiteNameContainer, WebsiteName, AdUrl, CardFooter, FooterLeft, AdDate, AdCountries, FooterRight, DetailsButton, Favorite, SelectButton, AdTypeContainer } from "./index.styled"

// import FavorietIcon from "../../../../../media/Dashboard/addFavorite.svg"
import BingIcon from "../../../../../../media/Dashboard/Bing16.svg"

// import GlobeIcon from '../../../../../media/Dashboard/globe.svg';
import DateIcon from "../../../../../../media/Images/DateIcon.svg"
import CountryIcon from "../../../../../../media/Images/CountryIcon.svg"
import { useAdGenrator } from '../../../context/adgenerator';

const BingCard = ({ad, ToggleSelectedAds}) => {
    const {adGenratorState, adGenratorDispatch} = useAdGenrator()

    const convertTimestamp = (timestamp) => {
        // Create a new Date object based on the timestamp
        var date = new Date(timestamp);
    
        // Get the month name
        var monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        var month = monthNames[date.getMonth()];
        
        // Get the year
        var year = date.getFullYear();
      
        // Assemble the formatted date string
        var formattedDate = date.getDate() + ' ' + month  + ' ' + year;
      
        return formattedDate;
    }

    return <Card key={
        ad.id
    }>
        <AdTypeContainer>
            <img src={BingIcon}/> Bing search 
            {/* | <img src={ClockIcon}/>  */}
        </AdTypeContainer>
        <FloatingButtonsContainer>
            <SelectButton type="checkbox" id="selected" name="selected" onClick={()=>ToggleSelectedAds(ad.id)} checked={adGenratorState.selectedAds.includes(ad.id)}/>
            {/* <Favorite>
                <img src={FavorietIcon}/>
            </Favorite> */}
            {/* |
            <DropdownIcon>
                <span/>
            </DropdownIcon> */}
        </FloatingButtonsContainer>
        <CardBody>
            {/* <SponsoredText>
                Sponsored
            </SponsoredText> */}
            <AdHeader>
                {/* <WebsiteLogo>
                    <img src={GlobeIcon}/>
                </WebsiteLogo> */}
                <WebsiteNameContainer>
                    <WebsiteName>
                        {ad.title}
                    </WebsiteName>
                    <AdUrl>
                        {ad.url ? ad.url : ad.url}
                    </AdUrl>
                </WebsiteNameContainer>
            </AdHeader>
            {/* <Highlighter
                className="title-wrapper-span"
                highlightClassName="title-highlight-bold"
                searchWords={[]}
                autoEscape={true}
                textToHighlight={ad.title}
                highlightTag={HightlightTitle}
            />  */}
            <Highlighter
                className="content-wrapper-span"
                highlightClassName="content-highlight-bold"
                searchWords={[]}
                autoEscape={true}
                textToHighlight={ad.content}
                // highlightTag={HightlightContent}
            />
        </CardBody>
        <CardFooter>
            <FooterLeft>
                <AdDate>
                {ad.firstSeen && ad.lastSeen && <img src={DateIcon}/>}<p>{convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}</p>
                </AdDate>
            <AdCountries>
                                                {ad.country.length > 0 && <img src={CountryIcon} />}<p>{ ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}</p>
                </AdCountries>
            </FooterLeft>
            <FooterRight>
                <DetailsButton >
                    {/* {urlAnalysisState.adDetails.id == ad.id ? "Close details" : "More details"} */}
                </DetailsButton>
            </FooterRight>
        </CardFooter>
    </Card>
}

export default BingCard