import { useRef, useState } from "react";
import { useImageAdGenerator } from "../../context/imageAdGeneration";
import { AdGoalContainer, AdGoalInput, Label } from "./AdGoal.styled";

function AdGoal() {
    const inputRef = useRef(null)
    const { imageAdGeneratorState, imageAdGeneratorDispatch } = useImageAdGenerator()
    const [inputFocussed, setInputFocussed] = useState(false)
    const handleDescriptionValueChange = () => {
        if(inputRef.current)
        imageAdGeneratorDispatch({type: "SET_AD_GOAL", value: inputRef.current.value})
    }
    return ( 
        <AdGoalContainer>
            <Label>Please specify the goal you want the ad to achieve</Label>
            <AdGoalInput 
            isFocussed={inputFocussed}
            placeholder="Eg: Establish brand authority and credibility within the clean beauty industry" onChange={handleDescriptionValueChange} value={imageAdGeneratorState.adGoal} ref={inputRef} 
            onFocus={() => setInputFocussed(true)}
            onBlur={() => setInputFocussed(false)}
            />
        </AdGoalContainer>
     );
}

export default AdGoal;