import styled from "styled-components";

export const TableWrapperMain = styled.div`
max-width: 629px;
margin-top: 18px;
width: 100%; 
padding: 16px 20px;
background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
border: 1px solid rgb(242, 242, 242);
border-radius: 8px;
h4{
    margin-bottom: 15px;
    color: #2E2E2E;
    font-size: 14px;
    font-weight: 400;
}
`;

export const TableContainer = styled.div`
/* padding: 16px 0px 16px 20px; */
/* background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
border: 1px solid rgb(242, 242, 242); */
border-radius: 8px;
padding-bottom: 25px;
`;

export const TableHead = styled.div`
display: flex;
/* background: #FFFFFF; */
/* border: 1px solid #F2F2F2; */
padding: 10px 0px; 
font-size: 12px;
font-weight: 300;
color: #434343;
`;


export const KeywordLabel = styled.div`
width: 50%;
padding-right: 10px;
`;
export const VolumeLabel = styled.div`
width: 15%;
`;
export const KcsLabel = styled.div`
width: 15%;
`;
export const CpcLabel = styled.div`
width: 15%;
text-align: end;
`;

export const TableBody = styled.div`
/* max-height: 65vh; */
overflow-y: auto;

::-webkit-scrollbar-track
{
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
    background-color: rgb(245 245 245 / 28%);
    border-radius: 10px;
}

::-webkit-scrollbar
{
    width: 5px;
    background-color: rgb(245 245 245 / 92%);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb
{
    background-color: rgb(0 0 0 / 7%);
    border-radius: 10px;
}
`;

export const Row = styled.div`
display: flex;
padding: 10px 0px;
background: #FFFFFF;
/* border-bottom: 1px solid #F2F2F2; */
`;

export const KeywordData = styled.div`
width: 50%;
font-size: 14px;
font-weight: 300;
color: #2E2E2E;
position: relative;
padding-right: 10px;
display: flex;
justify-content: space-between;
line-height: normal;

span{
    position: absolute;
    display: none;
    right: 10px;
    top: -4px;
    color: #006ECC;
    background: #EAF5FF;
    border-radius: 8px;
    padding: 4px 12px;
    cursor: pointer;
}

img{
    cursor: pointer;
    margin-right: 40px;
}

&:hover{
    span{
        display: flex;
    }
}
`;


export const ToolTipTextContainer = styled.div`
/* position: absolute; */
right: 8px;
top: 12px;
display: flex;
transition-delay: 2s;
width: 75%;
    z-index: 2;
&:hover{
    > div{
        display: flex;
    }

    img{
        display: block;
    }
}

p{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition-delay: 2s;
    cursor: default;
}
`;


export const VolumeData = styled.div`
width: 15%;
color: #555555;
font-size: 14px;
font-weight: 300;
padding-right: 10px;
`;

export const KcsData = styled.div`
width: 15%;
color: #555555;
font-size: 14px;
font-weight: 300;
    padding-right: 10px;
`;

export const CpcData = styled.div`
width: 15%;
text-align: end;
padding-right: 10px;

color: #707070;
font-size: 14px;
font-weight: 300;
`;

export const ToolTip = styled.div`
    background: #333232;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 300;
    position: absolute;
    bottom: -34px;
    padding: 4px 8px;
    width: 125px;
    display: none;
`;

export const MoreKeywordButton = styled.div`
    text-align: end;
    span{
        font-size: 14px;
        font-weight: 500;
        color: #006ECC;
        cursor: pointer;
    }
`;