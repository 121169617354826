import React, { useRef, useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import {
    useMutation
  } from "@tanstack/react-query"
import NoResultsLogo from "../../../../../../../media/Dashboard/noresults.svg"
// Styles
import {
    CountriesList,
    CountriesContainer,
    DateRange,
    DestinationUrl,
    DetailsHeadline,
    AdDetailsBody,
    CloseDetails,
    DetailsHeader,
    DetailsContainer,
    DetailsButton,
    FooterRight,
    AdCountries,
    AdDate,
    FooterLeft,
    CardFooter,
    CardBody,
    Favorite,
    FloatingButtonsContainer,
    Card,
    CardsList,
    ResultsContainer,
    ResultsWraper,
    ImageContainer,
    MediaType,
    MediaSize,
    AgencyName,
    DetailsImageContainer,
    ImageResolution,
    NoResults,
    CopyImg
} from "./index.styled"

import SearchLoader from '../../../../../../HOC/SearchLoader'
import CopyBtn from '../../../../../../../media/Dashboard/copy.png'

//Icons
import FavorietIcon from "../../../../../../../media/Dashboard/addFavorite.svg"
import CloseBtn from "../../../../../../../media/Dashboard/detailsPaneClose.svg"

import DateIcon from "../../../../../../../media/Images/DateIcon.svg"
import CountryIcon from "../../../../../../../media/Images/CountryIcon.svg"
import AltImage from '../../../../../../../media/Dashboard/altimage.png'
import TickImg from '../../../../../../../media/Dashboard/tick-grey.svg'

import {appClient} from '../../../../../../../utils/axios-utils'

import { useAuth } from '../../../../../../../context/auth'
import { useUrlAnalysis } from '../../../../context/urlanalysis'

const requestDataCrawl = async (data) => {
    const config = {
        url: `/track/search/update-search-query`,
        method: "POST",
        data
    }
  
    return appClient(config)
  }

const CardElement = ({ad,idx, containerRef}) => {
    const { urlAnalysisState, urlAnalysisDispatch } = useUrlAnalysis()
    const { authState, authDispatch } = useAuth()

    const [height, setHeight] = useState('')
    const [width, setWidth] = useState('')

    const scrollToElement = (index) => {
        if(containerRef.current){
            const element = containerRef.current.children[index];
            const scrollPosition = element.offsetTop - 20;
            containerRef.current.scrollTo({ top: scrollPosition, behavior: 'smooth' });
        }
      };

    const toggleDetailsModal = () => {
        // console.log("addd yt details")
        if((urlAnalysisState.adDetails.id !== ad.id) && urlAnalysisState.detailsModal){ 
            urlAnalysisDispatch({type:'SET_AD_DETAILS', value: ad})
        }else{
            urlAnalysisDispatch({type:'SET_AD_DETAILS', value: ad})
            urlAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: !urlAnalysisState.detailsModal})
        }
        setTimeout(() => {
            scrollToElement(idx)
        }, 100);
    }

    const convertTimestamp = (timestamp) => {
        // Create a new Date object based on the timestamp
        var date = new Date(timestamp);
      
        // Get the month name
        var monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        var month = monthNames[date.getMonth()];
      
        // Get the year
        var year = date.getFullYear();
      
        // Assemble the formatted date string
        var formattedDate = date.getDate() + ' ' + month  + ' ' + year;
    
        return formattedDate;
      }

      useEffect(() => {
        const img = new Image();
        img.onload = function() {
            setTimeout(() => {
                setHeight(img.height)
                setWidth(img.width)
            }, 1000);
        }
        img.src = `https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${ad.id}`;
      }, [])

    return <Card detailsOpen={urlAnalysisState.adDetails.id == ad.id} key={
        ad.id
    }>
        <FloatingButtonsContainer>
            <Favorite isFav={idx == 1} onClick={()=>{
                authDispatch({type:'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'display'})
                authDispatch({type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id})
                authDispatch({type:'UPDATE_SAVE_AD_POPUP', value: true})}}>
                <img src={FavorietIcon}/>
            </Favorite>
            {/* |
            <DropdownIcon>
                <span/>
            </DropdownIcon> */}
        </FloatingButtonsContainer>
        <CardBody>
            <ImageContainer>
                {ad.image ? <img onLoad={(e)=>{
                    // console.log(e.width,e.target.width, e.target.height)
                    }} src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${ad.id}`} onError={() => {}}/> : ad.video ? <video src={`https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${ad.id}`} preload="auto" controls loop style={{width: "100%", height: "100%"}}/> : <img src={AltImage} onError={() => {}}/>}
                <ImageResolution>
                    {height ? height : ""} x {width ? width : ""}
                </ImageResolution>
            </ImageContainer>
        </CardBody>
        <CardFooter>
            <FooterLeft>
                <AdDate>
                {ad.firstSeen && ad.lastSeen && <img src={DateIcon}/>}{convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
                </AdDate>
                {ad.country && <AdCountries>
                    <img src={CountryIcon}/>{ ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
                </AdCountries>}
            </FooterLeft>
            <FooterRight>
                <DetailsButton onClick={toggleDetailsModal}>
                    More details
                </DetailsButton>
            </FooterRight>
        </CardFooter>
    </Card>
}

const DetailsElement = () => {
    const { urlAnalysisState, urlAnalysisDispatch } = useUrlAnalysis()
    const [ showTick, setShowTick ] = useState(false)
    const [ copiedItem, setCopiedItem ] = useState('')
    const [height, setHeight] = useState(0)
    const [width, setWidth] = useState(0)

    const convertTimestamp = (timestamp) => {
        // Create a new Date object based on the timestamp
        var date = new Date(timestamp);
      
        // Get the month name
        var monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        var month = monthNames[date.getMonth()];
      
        // Get the year
        var year = date.getFullYear();
      
        // Assemble the formatted date string
        var formattedDate = date.getDate() + ' ' + month  + ' ' + year;
      
        return formattedDate;
      }

    useEffect(() => {
        const img = new Image();
        img.onload = function() {
            setTimeout(() => {
                setHeight(img.height)
                setWidth(img.width)
            }, 1000);
        }
        img.src = `https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${urlAnalysisState.adDetails.id}`;
      }, [])

    const onCopyClick = (value) => {
        navigator.clipboard.writeText(value);
        setCopiedItem(value)
        setShowTick(true)
        setTimeout(() => {
            setShowTick(false)
        }, 1500);
    }

    return <DetailsContainer>
    <DetailsHeader>
        <h3>Ad details</h3> <CloseDetails onClick={()=>{urlAnalysisDispatch({type:'UPDATE_DETAILS_MODAL', value: false})
        urlAnalysisDispatch({type:'SET_AD_DETAILS', value: {}})}}><img src={CloseBtn}/></CloseDetails>
    </DetailsHeader>
    <AdDetailsBody>
        <DetailsImageContainer>
            {urlAnalysisState.adDetails.image ? <img src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${urlAnalysisState.adDetails.id}`} onError={() => {}}/> : urlAnalysisState.adDetails.video ? <video src={`https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${urlAnalysisState.adDetails.id}`} preload="auto" controls loop style={{width: "100%", height: "100%"}}/> : <img src={AltImage} onError={() => {}}/>}
            {/* {<img onLoad={(e)=>{}} src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${urlAnalysisState.adDetails.id}`} onError={() => {}}/> } */}
        </DetailsImageContainer>
        <DetailsHeadline>
            <label>Title:</label>{urlAnalysisState.adDetails?.title && urlAnalysisState.adDetails.title != "no title" && urlAnalysisState.adDetails.title}{urlAnalysisState.adDetails?.title && urlAnalysisState.adDetails.title != "no title" && <CopyImg onClick={()=>onCopyClick(urlAnalysisState.adDetails.title)} src={showTick && urlAnalysisState.adDetails.title == copiedItem ? TickImg : CopyBtn}/>}
        </DetailsHeadline>
        <DestinationUrl>
            <label>Destination URL:</label>{urlAnalysisState.adDetails?.url}<CopyImg onClick={()=>onCopyClick(urlAnalysisState.adDetails.url)} src={showTick && urlAnalysisState.adDetails.url == copiedItem ? TickImg : CopyBtn}/>
        </DestinationUrl>
        <MediaType>
            <label>Media Type:</label>{urlAnalysisState.adDetails?.image ? "Image" : urlAnalysisState.adDetails?.video ? "Video" : "-"}
        </MediaType>
        <MediaSize>
            <label>Media Size:</label>{height ? height : ""} x {width ? width : ""}
        </MediaSize>
        {urlAnalysisState.adDetails.agency && <AgencyName>
            <label>Agency:</label>{urlAnalysisState.adDetails?.agency[0]}
        </AgencyName>}
        <DateRange>
            <label>Date:</label>{convertTimestamp(urlAnalysisState.adDetails.firstSeen)} - {convertTimestamp(urlAnalysisState.adDetails.lastSeen)}
        </DateRange>
        {urlAnalysisState.adDetails.country && <CountriesContainer>
            {urlAnalysisState.adDetails.country && <><label>Country:</label><CountriesList>{urlAnalysisState.adDetails.country.map((country,idx)=>{
                return <span>{country}</span>
            })}</CountriesList>
            </>}
        </CountriesContainer>}
        {/* <LandingPageScreenShot>
            <label>Landing page screenshot</label>
            <ScreenshotContainer>
                <img src={ApplePage}/>
            </ScreenshotContainer>
        </LandingPageScreenShot> */}
    </AdDetailsBody>
</DetailsContainer>
}


const DisplayAdsResults = ({isDataLoading}) => {
    const containerRef = useRef(null);
    const [ dataRequested, setDataRequested ] = useState(false)
    const navigate = useNavigate()

    const { urlAnalysisState, urlAnalysisDispatch } = useUrlAnalysis()   
    // const {searchState, searchDispatch} = useSearch()
    // const {authState, authDispatch} = useAuth()

    const {mutate: requestData, isLoading: isRequestDataLoading} = useMutation(requestDataCrawl, {
        onSuccess: (data) => {
            setDataRequested(true)
        },
        onError:  ((e)=>{
            
        })
    })

    const onDataRequest = () => {
        if(!dataRequested){
            requestData({id: urlAnalysisState.queryId})
        }
    }

    // if(authState.isRestrictedPopup){
    //     return  <ResultsContainer detailsModal={urlAnalysisState.detailsModal}>
    //         <PlatformRestrictedPopup err={searchState.searchError}/>
    //     </ResultsContainer>
    // }

    // if(searchState.limitExceeded){
    //     return  <ResultsContainer detailsModal={urlAnalysisState.detailsModal}>
    //         <LimitExceededPopup err={searchState.searchError}/>
    //     </ResultsContainer>
    // }

    return  <ResultsContainer detailsModal={urlAnalysisState.detailsModal}>
        {isDataLoading ? <div className='loader'>
        <SearchLoader/>
      </div> :  urlAnalysisState.ads.length == 0 ? <NoResults>
                        <img src={NoResultsLogo}/>
                        <h2>No Results Found</h2>
                        {/* <p>We're sorry, but we don't currently have the data matching your search parameters. Our database is constantly updated, so please try again later or try modifying your search.</p>
                        <RequestDataBtn dataRequested={dataRequested ? dataRequested : ""} disable={isRequestDataLoading} onClick={onDataRequest}>{dataRequested ? <p>Your request for data crawl has been accepted<img src={TickImg}/></p> : <p>{isRequestDataLoading ? "Requesting...": "Request data crawl for this query"}</p>}</RequestDataBtn> */}
                    </NoResults> : 
                <ResultsWraper>
                    <CardsList ref={containerRef} detailsModal={urlAnalysisState.detailsModal}> {
                        urlAnalysisState.ads && urlAnalysisState.ads.length > 0 && urlAnalysisState.ads.map((ad,idx) => {
                            return <CardElement containerRef={containerRef} ad={ad} idx={idx}/>
                        })
                    } </CardsList>
                    {
                    urlAnalysisState.detailsModal && <DetailsElement/>
                    }
                </ResultsWraper>}
            </ResultsContainer>
}

export default DisplayAdsResults
