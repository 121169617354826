import React, {useEffect, useRef} from 'react'
import { useLocation, useNavigate, useSearchParams} from 'react-router-dom'


// Styles
import {
    Container,
    Header,
    Breadcrumb,
    ScrollableContainer,
    HeaderLeft,
    HeaderRight,
    TotalAdsFound,
    Footer,
    TotalResults,
    AppliedFilters,
    AppliedFilterItem,
    Close,
    Divider
} from "./index.styled"
import _static from "../../../../../../config/static"

import Pagination from './Pagination'

import {useGoogleEcomSearch} from "../context/googleecom"
import {useSearch} from "../../context/search"
import { getSearchParams, urlPatternValidation, dateFormatter,formatWithCommas } from "../../../../../../utils/helper.js"

// Icons
import Results from './Results'
import { useAuth } from '../../../../../../context/auth.js'
import { useGoogleEcomInfo } from '../hooks/useGoogleEcomInfo.js'
import SortType from './NewFilters/SortType'
import MajorFilters from './NewFilters/MajorFilters'
import SortFocus from '../../../utils/SortFocus/index.js'
import back from "../../assets/back.svg"

const GoogleSearchAds = () => {
    const {googleEcomSearchState, googleEcomSearchDispatch} = useGoogleEcomSearch()
    const [searchParams, setSearchParams] = useSearchParams()

    const {searchState, searchDispatch} = useSearch()
    const location = useLocation()
    const {authState, authDispatch} = useAuth()
    const useGoogleEcomInfoHook = useGoogleEcomInfo()
    const myRef = useRef(null);
    const navigate = useNavigate()
    useEffect(() => {
        if(location.pathname.includes("googlepla")){
            authDispatch({type: 'SET_SEARCH_PLATFORM', value: 'googlepla'})
        }
    }, [])

    useEffect(() => {
            // console.log('search param updated')
            const searchParamFromState = getSearchParams(location.search)

            const {
                searchMode,
                keywords,
                phraseInput,
                domainUrlInput,
                keywordSearchType,
                urlSearchType,
                searchIn,
                minimumShouldMatch,
                excludedTerm,
                page,
                selectedCountries,
                startDate,
                endDate,
                sortType,
                keywordsArray,
                competitorsArray,
                sortArray
            } = searchParamFromState

            googleEcomSearchDispatch({type:'UPDATE_DETAILS_MODAL', value: false})

            if(searchMode){
                if(searchMode == "keyword"){
                    authDispatch({type: 'SET_SEARCH_MODE', value: searchMode})
                    if(keywordSearchType){
                        authDispatch({type: 'SET_KEYWORD_SEARCHTYPE', value: keywordSearchType })

                        if(keywordSearchType == "phrase"){
                            if(phraseInput){
                                authDispatch({type: 'SET_PHRASE_INPUT', value: phraseInput})
                            }
                            if(searchIn && searchIn.length > 0){
                                let finalSearchIn = ["title","content"].map(si=>{
                                    if(searchIn.includes(si)){
                                        googleEcomSearchDispatch({ type: "SET_PHRASE_SEARCH_IN", payload: {
                                            name: si.charAt(0).toUpperCase() + si.slice(1),
                                            value: si,
                                            checked: true
                                        }})
                                    }else{
                                        googleEcomSearchDispatch({ type: "SET_PHRASE_SEARCH_IN", payload: {
                                            name: si.charAt(0).toUpperCase() + si.slice(1),
                                            value: si,
                                            checked: true
                                        }})
                                    }
                                })
                            }
                        }

                        if(keywordSearchType == "broad"){
                            if(keywords && keywords.length > 0){
                                authDispatch({type: 'SET_KEYWORDS', value: keywords.split(",")})
                            }

                            authDispatch({ type: "SET_BROAD_MIN_SHOULD_MATCH", value: { name: 'Min. match', value: minimumShouldMatch ? minimumShouldMatch : 0 }})
                            
                            if(searchIn && searchIn.length > 0){
                                let finalSearchIn = ["title","content"].map(si=>{
                                    if(searchIn.includes(si)){
                                        googleEcomSearchDispatch({ type: "SET_BROAD_SEARCH_IN", payload: {
                                            name: si.charAt(0).toUpperCase() + si.slice(1),
                                            value: si,
                                            checked: true
                                        }})
                                    }else{
                                        googleEcomSearchDispatch({ type: "SET_BROAD_SEARCH_IN", payload: {
                                            name: si.charAt(0).toUpperCase() + si.slice(1),
                                            value: si,
                                            checked: false
                                        }})
                                    }
                                })
                            }
                        }

                        if(excludedTerm){
                            googleEcomSearchDispatch({ type: 'UPDATE_EXCLUDED_TERM', value: excludedTerm})
                        }

                    }
                }

                if (searchMode == "url"){
                    authDispatch({type: 'SET_SEARCH_MODE', value: searchMode})
                    if(urlSearchType){
                        searchDispatch({type: 'SET_URL_SEARCHTYPE', value: urlSearchType })
                        if(urlSearchType == "exact"){
                            if(domainUrlInput){
                                searchDispatch({type: 'SET_DOMAIN_URL_INPUT', payload: {value: domainUrlInput,valid: urlPatternValidation(domainUrlInput)}})
                            }
                        }
                        if(urlSearchType == "domain"){
                            if(domainUrlInput){
                                searchDispatch({type: 'SET_DOMAIN_URL_INPUT', payload: {value: domainUrlInput,valid: urlPatternValidation(domainUrlInput)}})
                            }
                        }
                        if(urlSearchType == "text"){
                            if(domainUrlInput){
                                searchDispatch({type: 'SET_DOMAIN_URL_INPUT', payload: {value: domainUrlInput,valid: domainUrlInput.length > 0}})
                            }
                        }
                    }
                }

                if(selectedCountries && selectedCountries.length > 0){
                    googleEcomSearchDispatch({type: 'UPDATE_SELECTED_COUNTRIES', value: selectedCountries.split(",")})
                }else{
                    googleEcomSearchDispatch({type: 'UPDATE_SELECTED_COUNTRIES', value: []})
                }

                
                if(startDate){
                    googleEcomSearchDispatch({type: 'UPDATE_START_DATE',value: new Date(Date.parse(startDate))})
                }else{
                    googleEcomSearchDispatch({type: 'UPDATE_END_DATE',value: ''})
                }

                if(endDate){
                    googleEcomSearchDispatch({type: 'UPDATE_END_DATE',value: new Date(Date.parse(endDate))})
                }else{
                    googleEcomSearchDispatch({type: 'UPDATE_END_DATE',value: ''})
                }


                if(sortType){
                    googleEcomSearchDispatch({type: 'UPDATE_SELECTED_SORT_TYPE',value: {
                        name: sortType.charAt(0).toUpperCase() + sortType.slice(1),
                        type: sortType
                    }})
                }

                if(page){
                    googleEcomSearchDispatch({type: 'SET_CURRENT_PAGE_NO', value: page})
                }
            }else{
                googleEcomSearchDispatch({type: 'CLEAR_ALL'})
            }

            // on filter applied
            if((selectedCountries && selectedCountries.length > 0) || (startDate) || (endDate) || sortType != "relevant"){
                // console.log("inside filter")
                if (searchMode == "keyword") {
                    if((keywordSearchType === "broad" && (keywords && keywords.length > 0)) || (keywordSearchType === "phrase" && (phraseInput && phraseInput.length > 0))){
                        if (keywordSearchType == "broad") {
                            let fields = searchIn.split(",")
                            let query = keywords.split(",").join(" ")
                            

                            const reqBody = {
                                query,
                                minimumShouldMatch: minimumShouldMatch,
                                searchIn: fields,
                                exclude: excludedTerm,
                                page: page,
                                countries: selectedCountries ? selectedCountries.split(","): [],
                                startDate: dateFormatter(startDate),
                                endDate: dateFormatter(endDate),
                                sort: sortType,
                                keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                                competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                            }
                            googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                            useGoogleEcomInfoHook.keywordBoardFilterMutate(reqBody)
                        } else if (keywordSearchType == "phrase") {
                            // console.log("innnnnnnnnn")
                            let fields = searchIn.split(",")
                            

                            const reqBody = {
                                query: phraseInput,
                                searchIn: fields,
                                exclude: excludedTerm,
                                page: page,
                                countries: selectedCountries ? selectedCountries.split(","): [],
                                startDate: dateFormatter(startDate),
                                endDate: dateFormatter(endDate),
                                sort: sortType,
                                keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                                competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                            }
                            googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                            useGoogleEcomInfoHook.keywordPhraseFilterMutate(reqBody)
                        }
                    }
                } else if (searchMode == "url") {
                    if((urlSearchType == "domain" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)) || urlSearchType == "text" && (domainUrlInput && domainUrlInput.length > 0) || urlSearchType == "exact" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)){
                        if (urlSearchType == "domain") {
                            
                            const reqBody = {
                                query: domainUrlInput,
                                page: page,
                                countries: selectedCountries ? selectedCountries.split(","): [],
                                startDate: dateFormatter(startDate),
                                endDate: dateFormatter(endDate),
                                sort: sortType,
                                keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                                competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                            }
                            googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                            useGoogleEcomInfoHook.urlDomainFilterMutate(reqBody)
                        } else if (urlSearchType == "text") {

                            const reqBody = {
                                query: domainUrlInput,
                                page: page,
                                countries: selectedCountries ? selectedCountries.split(","): [],
                                startDate: dateFormatter(startDate),
                                endDate: dateFormatter(endDate),
                                sort: sortType,
                                keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                                competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                            }
                            googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                            useGoogleEcomInfoHook.urlTextFilterMutate(reqBody)
                        } else if (urlSearchType == "exact") {
                            
                            const reqBody = {
                                query: domainUrlInput,
                                page: page,
                                countries: selectedCountries ? selectedCountries.split(","): [],
                                startDate: dateFormatter(startDate),
                                endDate: dateFormatter(endDate),
                                sort: sortType,
                                keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                                competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                            }
                            googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                            useGoogleEcomInfoHook.urlExactFilterMatchMutate(reqBody)
                        }
                    }
                }
            }else{
                // console.log("without filter")
                // on not filter applied
                    if (searchMode == "keyword") {
                        if((keywordSearchType === "broad" && (keywords && keywords.length > 0)) || (keywordSearchType === "phrase" && (phraseInput && phraseInput.length > 0))){
                            if (keywordSearchType == "broad") {
                                let fields = searchIn.split(",")
                                let query = keywords.split(",").join(" ")
                        
                                const reqBody = {
                                    query,
                                    minimumShouldMatch: minimumShouldMatch,
                                    searchIn: fields,
                                    exclude: excludedTerm,
                                    page: page,
                                    keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                                    competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                                }
                                googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                                useGoogleEcomInfoHook.keywordBroadMutate(reqBody)
                            } else if (keywordSearchType == "phrase") {
                                let fields = searchIn.split(",")
                                
                                const reqBody = {
                                    query: phraseInput,
                                    searchIn: fields,
                                    exclude: excludedTerm,
                                    page: page,
                                    keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                                    competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                                }
                                googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                                useGoogleEcomInfoHook.keywordPhraseMutate(reqBody)
                            }
                        }
                    } else if (searchMode == "url") {
                        if((urlSearchType == "domain" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)) || urlSearchType == "text" && (domainUrlInput && domainUrlInput.length > 0) || urlSearchType == "exact" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)){
                            if (urlSearchType == "domain") {
                                const reqBody = {
                                    query: domainUrlInput,
                                    page: page,
                                    keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                                    competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                                }
                                googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                                useGoogleEcomInfoHook.urlDomainMutate(reqBody)
                            } else if (urlSearchType == "text") {
                        
                                const reqBody = {
                                    query: domainUrlInput,
                                    page: page,
                                    keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                                    competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                                }
                                googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                                useGoogleEcomInfoHook.urlTextMutate(reqBody)
                            } else if (urlSearchType == "exact") {
                                const reqBody = {
                                    query: domainUrlInput,
                                    page: page,
                                    keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                                    competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                                }
                                googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                                useGoogleEcomInfoHook.urlExactMutate(reqBody)
                            }
                        }
                    }
            }

    }, [location.search])

    const ClearCountry = () => {
        searchParams.set("selectedCountries", "")
        searchParams.set("page", 1)
        setSearchParams(searchParams)
    }

    const ClearSortType = () => {
        searchParams.set("sortType", "relevant")
        searchParams.set("page", 1)
        setSearchParams(searchParams)
    }

    const ClearDateFilter = () => {
        searchParams.set("startDate", "")
        searchParams.set("endDate", "")
        googleEcomSearchDispatch({type: "UPDATE_DATE_FILTER_TYPE", value: ""})
        setSearchParams(searchParams)
    }

    const OpenAdvancedFilter = () => {
        searchParams.set("tone", "")
        searchParams.set("cta", "")
        searchParams.set("sentiment", "")
        searchParams.set("otherFilters", "")
        setSearchParams(searchParams)
    }

    // return <Container ref={myRef}>
    //     <SearchContainer>
    //         {/* <Content>
    //             <PlatformDropDown/>
    //             <SearchMode/>
    //             <Separator/> {
    //             googleEcomSearchState.searchMode === "keyword" ? <KeywordComponent/>: <UrlComponent/>
    //         } </Content>
    //         {
    //         !searchState.limitExceeded && < Filters />
    //         } */}
    //         <ResultPageSearch/>
    //         </SearchContainer>
    //         <ScrollableContainer>
    //         {
    //         !searchState.limitExceeded && < Filters />
    //         }
    //         {
    //         googleEcomSearchState.ads && googleEcomSearchState.ads.length > 0 && (
    //         <AdsCount>
    //             <div><p>
    //                 <span>GoogleEcom Search ads{" "}</span>
    //                 - {(googleEcomSearchState.currentPageNo - 1) * 20 != 0 ? (googleEcomSearchState.currentPageNo - 1) * 20 + 1 : 1} - {(((googleEcomSearchState.currentPageNo - 1) * 20) + 20) > googleEcomSearchState.totalAds ? googleEcomSearchState.totalAds : ((googleEcomSearchState.currentPageNo - 1) * 20) + 20} of <span>{
    //                     googleEcomSearchState.totalAds
    //                 }</span>
    //                     {" "}results
    //             </p></div>
    //             {/* <SearchQuery> {
    //                 googleEcomSearchState.keywords.length > 0 ? (googleEcomSearchState.keywords.map((word, idx) => {
    //                     return <span>{word}</span>;
    //                 })) : googleEcomSearchState.phraseInput ? (
    //                     <span>{
    //                         googleEcomSearchState.phraseInput
    //                     }</span>
    //                 ) : (
    //                     <span>{
    //                         googleEcomSearchState.domainUrlInput.value
    //                     }</span>
    //                 )
    //             } </SearchQuery> */}
    //         </AdsCount>
    //     )
    // }
    //     <Results/> {
    //     !searchState.limitExceeded && googleEcomSearchState.ads && googleEcomSearchState.ads.length > 0 && <Pagination/>
    // } 
    // </ScrollableContainer>
    // </Container>

    return <Container ref={myRef}>
    {
            <>
                <Breadcrumb onClick={() => navigate('/overview')} >
                        <img src={back} />
                    Back to overview
                </Breadcrumb>
                <Header>
                    <HeaderLeft>
                        <h2>{`Google Ecom Search ads (${formatWithCommas(googleEcomSearchState.totalAds)})`}</h2>
                        <AppliedFilters>
                            {googleEcomSearchState.selectedCountries.length > 0 && <AppliedFilterItem>
                                <p>{googleEcomSearchState.selectedCountries.length == 1 ? googleEcomSearchState.selectedCountries[0] : googleEcomSearchState.selectedCountries.length > 1 ? googleEcomSearchState.selectedCountries[0] + " + " + (
                                    googleEcomSearchState.selectedCountries.length - 1
                                ) : "no country"}</p>
                                <Close onClick={()=>ClearCountry()}>
                                    x 
                                </Close>
                            </AppliedFilterItem>}
                            {googleEcomSearchState.selectedSortType.type !== "relevant" && <AppliedFilterItem>
                                <p>{googleEcomSearchState.selectedSortType.name}</p>
                                <Close onClick={()=>ClearSortType()}>
                                    x 
                                </Close>
                            </AppliedFilterItem>}
                            {googleEcomSearchState.dateFilterType.length > 0 && <AppliedFilterItem>
                                <p>{googleEcomSearchState.dateFilterType}</p>
                                <Close onClick={()=>ClearDateFilter()}>
                                    x 
                                </Close>
                            </AppliedFilterItem>}
                            {(googleEcomSearchState.selectedCta?.length > 0 || googleEcomSearchState.selectedTone?.length > 0 || googleEcomSearchState.sentimentFilter?.length > 0 || googleEcomSearchState.selectedOtherFilters?.length > 0) && <AppliedFilterItem>
                                <p>Advanced filters</p>
                                <Close onClick={()=>OpenAdvancedFilter()}>
                                    x 
                                </Close>
                            </AppliedFilterItem>}
                        </AppliedFilters>
                    </HeaderLeft>
                    <HeaderRight>
                        {/* <TotalAdsFound>
                            <label>Total ads found :</label>
                            <span>
                                {formatWithCommas(googleEcomSearchState.totalAds)}
                            </span>
                        </TotalAdsFound> */}
                        <SortFocus />
                        <SortType />
                        <MajorFilters />
                    </HeaderRight>
                </Header>
                <Divider>
                    Display ads, content, etc., sorted based on your selected focus: Keywords or Competitors.
                </Divider>
                
                <ScrollableContainer>
                    <Results />
                    <Footer>
                        {(!authDispatch.limitExceeded && googleEcomSearchState.ads && !googleEcomSearchState.loadingResults && googleEcomSearchState.ads.length > 0) && <TotalResults>
                            <p>Showing {(googleEcomSearchState.currentPageNo - 1) * 20 != 0 ? (googleEcomSearchState.currentPageNo - 1) * 20 + 1 : 1} - {(((googleEcomSearchState.currentPageNo - 1) * 20) + 20) > googleEcomSearchState.totalAds ? googleEcomSearchState.totalAds : ((googleEcomSearchState.currentPageNo - 1) * 20) + 20} of <span>{
                                googleEcomSearchState.totalAds
                            }</span>
                            results</p>
                        </TotalResults>}
                        {!authDispatch.limitExceeded && googleEcomSearchState.ads && !googleEcomSearchState.loadingResults && googleEcomSearchState.ads.length > 0 && <Pagination />}
                    </Footer>
                </ScrollableContainer>
            </>
        }
    </Container>

}

export default GoogleSearchAds
