import React from 'react'
import { ActiveCamp, ActiveCount, ActiveCountWrap, ActiveDiv, ActiveGraph, ActivePercent, ActiveWrapper, UpdatedHour } from './index.styled'
import { LineChartGradient } from './AreaChart/index'
import greenArrow from '../../../../../../media/Dashboard/greenArrow.svg'
import { useDomainTracking } from '../../../context/domaintracking'
import Skeleton from "react-loading-skeleton";
import Tooltip from 'rc-tooltip'
import helpCircle from '../../../../../../media/Dashboard/help-circle.svg'

const ActiveCampaign = ({isGetAdStatsLoading}) => {
    const {domainTrackingState, domainTrackingDispatch} = useDomainTracking()

    const {activeCampaigns} = domainTrackingState;

    return (
        <ActiveWrapper>
            <ActiveDiv>
                <ActiveCamp>Active Campaigns<Tooltip trigger={['hover']} overlayStyle={{ width: "100" }} overlay={<span>Total active Campaigns that were found in last few weeks.</span>}>
                    <img src={helpCircle} />
                </Tooltip></ActiveCamp>
                {isGetAdStatsLoading ?
                    <> 
                        <Skeleton height={"45px"} width= {'203px'}/> 
                        <Skeleton height={"20px"} width= {'203px'} style={{'marginTop': '10px'}}/> 
                    </> 
                :
                <>
                <ActiveCountWrap>
                    <ActiveCount>{activeCampaigns.totalCampaigns}</ActiveCount>
                    {activeCampaigns.totalCampaignsIncrease > 0 && <ActivePercent><img src={greenArrow}/>{Math.round(activeCampaigns.totalCampaignsIncrease)}%</ActivePercent>}
                </ActiveCountWrap>
                <UpdatedHour>update 20hrs ago</UpdatedHour>
                </>
                }
            </ActiveDiv>
            <ActiveGraph>
                <LineChartGradient />
            </ActiveGraph>
        </ActiveWrapper>
  )
}

export default ActiveCampaign