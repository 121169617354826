import React, { useRef, useState } from "react";
import { PopupContainer, PopupBody, LogoutButton, DomainsListContainer, DomainInputContainer, Error, Footer, AddMoreDomainButton, SetupTrackingButton, ErrorBox } from "./index.styled"
import CloseIcon from "../../../../../../media/Dashboard/detailsPaneClose.svg"
import { useDomainTracking } from "../../../context/domaintracking";
import PlatformDropDown from "./components/PlatformDropDown"
import CountryDropDown from "./components/CountryDropDown";
import ValidateAndAddHttps from "../../../../../../utils/urlValidate"
import { useDomainTrackingInfo } from "../../../hooks/useDomainTrackingInfo";
import { getSearchParams } from '../../../../../../utils/helper'
import { useLocation } from 'react-router-dom'
import { DomainInput, ErrorIcon, HttpsLabel, InputContainer, PlaceholderWithInput, RightIcons } from "../../index.styled";
import errorIcon from "../../assets/errorIcon.svg"
function isValidDomain(url) {
    const domainAndUrlRegEx = /^(((http|https):\/\/|)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?)$/;

    return domainAndUrlRegEx.test(url);
}


const DomainInputModal = ({ isEditMode = false }) => {
    const { domainTrackingState, domainTrackingDispatch } = useDomainTracking()
    const useDomainTrackingInfoHook = useDomainTrackingInfo()
    const [focussedInputIndex, setFocussedInputIndex] = useState(null)
    const [error, setError] = useState(false)

    const location = useLocation()
    const domainsDiv = useRef(null);
    const updateDomainCountry = (updatingIndex, inputCountryData) => {
        console.log('updating', updatingIndex, inputCountryData)
        const tempDomainList = domainTrackingState.domainInputList.map((data, index) => {
            if ((updatingIndex === index)) {
                return { ...data, country: inputCountryData }
            }
            return data;
        });

        domainTrackingDispatch({ type: 'SET_DOMAIN_INPUT_LIST', value: tempDomainList })
    }

    const updateDomainPlatform = (domain, inputPlatformData) => {
        // console.log('inputCountryData')

        const tempDomainList = domainTrackingState.domainInputList.map((data, index) => {
            if (domain == index) {
                // Update the region property for the specific country
                return { ...data, platform: inputPlatformData }
            }
            return data;
        });

        //   console.log("updatedomainPlatform",tempdomainList)

        domainTrackingDispatch({ type: 'SET_DOMAIN_INPUT_LIST', value: tempDomainList })
    }

    const addNewDomain = () => {
        if (domainTrackingState.domainInputList.length < 4) {
            domainTrackingDispatch({ type: 'SET_DOMAIN_INPUT_LIST_ERROR', value: '' })
            domainTrackingDispatch({ type: 'SET_DOMAIN_INPUT_LIST', value: [...domainTrackingState.domainInputList, { domain: '', platform: '', country: {}, platformError: '' }] })
        } else {
            domainTrackingDispatch({ type: 'SET_DOMAIN_INPUT_LIST_ERROR', value: 'maximum 4 domains at a time' })
        }
    }

    const onDomainInput = (e, index) => {
        let { value } = e.target
        if(!value.startsWith("https://")) value = `https://${value}`

        const tempDomainInputList = domainTrackingState.domainInputList.map((d, id) => {
            if (id === index) {
                // Update the region property for the specific country
                
                return { ...d, domain: value };
            }
            return d;
        });
        domainTrackingDispatch({
            type: 'SET_DOMAIN_INPUT_ERROR_OBJ', value: {
                ...domainTrackingState.domainInputErrorsObj, [index]: {
                    status: !isValidDomain(value),
                    message: !isValidDomain(value) ? 'Enter a valid domain' : ''
                }
            }
        })
        domainTrackingDispatch({ type: 'SET_DOMAIN_INPUT_LIST', value: tempDomainInputList })
    }

    const setUpTrackingfn = () => {
        if (domainTrackingState.domainInputList) {
            const setUpObj = {};
            setUpObj.urls = [];
            // console.log(setUpObj,'setUpObj')
            domainTrackingState.domainInputList.map((item, idx) => {
                if (item.domain !== '') {
                    let value = item.domain
                    if(!value.startsWith("https://")) value = `https://${value}`
                    if (ValidateAndAddHttps(value)) {
                        domainTrackingDispatch({
                            type: 'SET_DOMAIN_INPUT_ERROR_OBJ', value: {
                                ...domainTrackingState.domainInputErrorsObj, [idx]: {
                                    status: false,
                                    message: ''
                                }
                            }
                        })
                        if (item.platform !== '') {
                            // console.log(item, 'itemitem');
                            const validUrl = ValidateAndAddHttps(value);
                            setUpObj.urls.push({
                                domain: validUrl,
                                platform: item.platform,
                                country: item?.country?.code ? item.country.name.common : 'global'
                            })
                            domainTrackingDispatch({ type: 'SET_DOMAIN_INPUT_LIST_ERROR', value: '' });
                        } else {
                            // console.log('section checking');
                            domainTrackingDispatch({ type: 'SET_DOMAIN_INPUT_LIST_ERROR', value: 'Please Fill the Empty Section' });
                        }
                    } else {
                        domainTrackingDispatch({
                            type: 'SET_DOMAIN_INPUT_ERROR_OBJ', value: {
                                ...domainTrackingState.domainInputErrorsObj, [idx]: {
                                    status: true,
                                    message: 'Invalid url'
                                }
                            }
                        })
                    }
                } else {
                    domainTrackingDispatch({ type: 'SET_DOMAIN_INPUT_LIST_ERROR', value: 'Please Enter the domain' });
                }
            });

            if (!isEditMode) {
                setUpObj.urls.length > 0 && useDomainTrackingInfoHook.setDomainTrackingMutation(setUpObj)
                
            } else {
                const searchParamFromState = getSearchParams(location.search);

                const { domainUserRefId } = searchParamFromState;

                const { domain: hostname, ...urlProps } = setUpObj.urls[0]
                const data = { ...urlProps, domainUserRefId, status: true, hostname }

                useDomainTrackingInfoHook.updateDomainTrackingMutation(data)
            }

        }

    }

    const eventEnterHandle = (e) => {
        if (e.key === 'Enter') {
            setUpTrackingfn()
        }
    }


    return <PopupContainer>
        <PopupBody>
            {/* temp change  */}
            <LogoutButton onClick={() => domainTrackingDispatch({ type: 'SET_DOMAIN_INPUT_MODAL_OPEN', value: false })}>
                <img src={CloseIcon} />
            </LogoutButton>
            <h2>Setup Domain Tracking</h2>
            <DomainsListContainer ref={domainsDiv}>
                {
                    domainTrackingState.domainInputList.map((domain, idx) => {
                        return (
                            <div>
                                <DomainInputContainer error={domainTrackingState.domainInputErrorsObj[idx]?.status ? true : false}>
                                    <PlaceholderWithInput style={{width: "calc(100% - 250px - 28px)"}} >
                                        <HttpsLabel>https://</HttpsLabel>
                                        <InputContainer isFocussed={focussedInputIndex === idx} hasError={error} >
                                            <DomainInput
                                                placeholder="Enter a domain"
                                                defaultValue={domain.domain &&  domain.domain.startsWith("https://") ? domain.domain.split("https://")[1] : domain.domain}
                                                onChange={(e) => onDomainInput(e, idx)}
                                                onKeyDown={(e) => {
                                                    eventEnterHandle(e)
                                                }}
                                                onFocus={() => setFocussedInputIndex(idx)}
                                                onBlur={() => setFocussedInputIndex(null)}
                                            />
                                            <RightIcons>
                                                {domainTrackingState.domainInputErrorsObj[idx]?.status && <ErrorIcon src={errorIcon} alt=""/>}
                                            </RightIcons>
                                        </InputContainer>
                                    </PlaceholderWithInput>
                                    <PlatformDropDown domainData={domain} updateDomainPlatform={updateDomainPlatform} countryIndex={idx} />
                                    <CountryDropDown domainData={domain} updateDomainCountry={updateDomainCountry} countryIndex={idx} />
                                </DomainInputContainer>
                                {domainTrackingState.domainInputErrorsObj[idx]?.status && <ErrorBox>Enter a valid domain</ErrorBox>}
                            </div>
                        )
                    })
                }
            </DomainsListContainer>
            <Error>{domainTrackingState.domainInputListError ? domainTrackingState.domainInputListError : ""}</Error>
            <Footer>
                {!isEditMode && (
                    <AddMoreDomainButton isDisabled={domainTrackingState.keywordInputListError} onClick={addNewDomain}>
                        + Add more domain
                    </AddMoreDomainButton>
                )}
                <SetupTrackingButton onClick={setUpTrackingfn}>
                    {isEditMode ? "Update Tracking" : "Setup Tracking"}
                </SetupTrackingButton>
            </Footer>
        </PopupBody>
    </PopupContainer>
}

export default DomainInputModal