import React from 'react'
import { Route, Routes } from 'react-router-dom'
import {KeywordOverviewProvider} from './context/keywordOverview'
import {Helmet} from "react-helmet"

// Layout
import Layout from "../../layout"

// Pages
import KeywordOverviewDetails from './KeywordOverviewDetails/index';
import KeywordOverview from './KeywordOverviewHome/index';
// import { getSearchErrCode } from "../../../helper/getSearchErrCode"

// Not found page
import NotFound from '../NotFoundPage';

// import {appClient} from '../../../../utils/axios-utils'

const KeywordTrackingComponent = () => {
    return <Routes>
            <Route path='*' element={
                <NotFound />
            } />
            <Route path="/" element={
                <Layout>
                    <KeywordOverview/>
                </Layout>
            } />
            <Route path="details" element={
                <Layout>
                    <KeywordOverviewDetails />
                </Layout>
            } />
        </Routes>
};

const KeywordTrackingProviderWrapper = () => {
    return <KeywordOverviewProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PPC Keyword Analysis | AdSpyder</title>
                <meta name="title" content="PPC Keyword Analysis | AdSpyder" />
                <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
            </Helmet>
        <KeywordTrackingComponent/>
    </KeywordOverviewProvider>
}

export default KeywordTrackingProviderWrapper