import styled from "styled-components";

export const KeywordRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
`;

export const Keyword = styled.p`
    font-size: 14px;
    color: #555555;
    line-height: 21px;
    font-weight: 300;

    width: 60%;
    font-size: 14px;
    font-weight: 300;
    color: #434343;
    position: relative;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    line-height: normal;

    span{
        position: absolute;
        display: none;
        right: 10px;
        top: -4px;
        color: #006ECC;
        background: #EAF5FF;
        border-radius: 8px;
        padding: 4px 12px;
        cursor: pointer;
    }

    img{
        cursor: pointer;
        margin-right: 40px;
    }

    &:hover{
        span{
            display: flex;
        }
    }
`;

export const KeywordCount = styled.p`
    font-size: 14px;
    color: #555555;
    line-height: 21px;
    font-weight: 500;
`;

export const ToolTipTextContainer = styled.div`
    /* position: absolute; */
    right: 8px;
    top: 12px;
    display: flex;
    transition-delay: 2s;
    width: 75%;
    &:hover{
        > div{
            display: flex;
        }

        img{
            display: block;
        }
    }

    p{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition-delay: 2s;
        cursor: default;
    }
`;

export const ToolTip = styled.div`
    background: #555555;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 300;
    position: absolute;
    bottom: -34px;
    padding: 4px 8px;
    width: 125px;
    display: none;
`;