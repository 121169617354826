import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;

    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const BackButton = styled.div`
    border: solid 1px rgba(28, 28, 28, 0.10);
    border-radius: 50%;
    position: absolute;
    left: 4%;
    top: 50px;
    width: 30px;
    height: 30px;
    display: flex;
    cursor: pointer;
`;

export const BodyContainer = styled.div`
    height: calc(100vh - 154px);
    display: flex;
`;

export const BodyLeft = styled.div`
    /* width: 700px; */
    width: 70%;
    min-width: 440px;
    padding: 36px 4%;
    display: flex;
    gap: 4%;
    align-items: center;
    /* @media screen and (max-width: ${props => props.theme.breakPoints.laptop}) {
        width: 100%;
    } */
`;

export const UpArrow = styled.div`
    width: 50px;
    height: 30px;
    border-radius: 4px 4px 0px 0px;
    border: 1px solid #DADFE4;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: ${props => props.isDisabled ? "0.3": "1"};
    img{
        transform: rotate(180deg);
    }
`;

export const DownArrow = styled.div`
    width: 50px;
    height: 30px;
    border-radius: 0px 0px 4px 4px;
    border: 1px solid #DADFE4;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: ${props => props.isDisabled ? "0.3": "1"};
`;

export const ImageCarousel = styled.div`
    width: 50px;
`;

export const CurrentImage = styled.div`
    width: calc(100% - 50px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;    
    > div{
        box-shadow: 0px 0px 12px 1px #8080806b;
    }
    @media screen and (max-width: 1200px) {
        > div{
            transform: scale(0.7);
        }
    }
    @media screen and (max-width: 850px) {
        > div{
            transform: scale(0.5);
        }
    }
`;

export const CarouselContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 225px;
    overflow: auto;
    gap: 10px;
    ::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
`;


export const BodyRight = styled.div`
    /* width: calc(100% - 700px); */
    width: 50%;
    max-width: 600px;
    h2{
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: #1C1C1CB2;
        text-align: center;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const FooterContainer = styled.div`
    width: 100%;
    height: 100px;
    background: #FFF;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button{
        background: #FF711E;
        background-color: none!important;
        color: #fff;
        border-radius: 8px;
        width: 170px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        margin-right: 40px;
        cursor: pointer;
    }
`;

export const CarouselImageContainer = styled.div`
    width: 50px;
    height: 50px;
    padding: 2px;
    border-radius: 1.19px;
    border: ${props => props.isSelected ? "1px solid #12A0FC" : "none"};
    background: #FFF;
    filter: blur(0px);
    img{
        width: 45px;
        cursor: pointer;
    }
`;

export const InputDetailsContainer = styled.div`
    border-bottom: 1px solid #E3E8EE;
    background: #FFF;
    height: calc(100% - 50px);
`;

export const ToggleInputContainer = styled.div`
    display: flex;
    height: 70px;
    align-items: center;
`;

export const InputType = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #E3E8EE;
    cursor: pointer;
    pointer-events: ${props => props.disabled ? "none": "all"};
    background: ${props => props.disabled ? "#F7F7F9": "none"};
    flex-wrap: wrap;
    padding: 0px 15px;
    img{
        margin-right: 8px;
    }

    .star-icon{
        filter: invert(100%);
    }

    span{
        background: linear-gradient(91deg, #6F78F0 44.98%, #EE0F96 100.07%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-left: 8px;
    }
`;

export const InputWrapper = styled.div`
    height: calc(100% - 70px);
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    gap: 24px;
    overflow-y: auto;
    padding: 20px 0px;
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const InputRow = styled.div`
    display: flex;
    justify-content: space-between;

    label{
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: rgba(28, 28, 28, 0.698);
        width: 50%;
        display: flex;
        align-items: center;
        margin-left: 7%;
    }
    input{
        width: 50%;
        border-radius: 4px;
        border: ${props => props.isFileInput ? "none" : "1px solid #E3E8EE"};
        background: #FFF;
        height: 38px;
        padding:  ${props => props.isFileInput ? "0px" : "0px 16px"};
        outline: none;
        margin-right: 7%;
    }

    input[type="file"]::file-selector-button {
        border-radius: 4px;
        padding: 0 16px;
        height: 38px;
        cursor: pointer;
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.16);
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
        margin-right: 16px;
        transition: background-color 200ms;
        }

        /* file upload button hover state */
        input[type="file"]::file-selector-button:hover {
        background-color: #f3f4f6;
        }

        /* file upload button active state */
        input[type="file"]::file-selector-button:active {
        background-color: #e5e7eb;
        }

    .invisible-input{
        display: none;
    }
    .logo-label{
        align-items: center;
        display: flex;
        border-radius: 4px;
        border: 1px solid #E3E8EE;
        background: #F7F7F9;
        padding: 8px 40px;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        width: 170px;
        color: #1C1C1CB2;
        margin-right: 7%;
        cursor: pointer;
    }
`;

export const DownloadButton = styled.div`
    background: #FF711E;
    color: #fff;
    border-radius: 8px;
    width: 170px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-right: 40px;
    cursor: pointer;
    opacity: ${props => props.isDisabled ? "0.5" : "1"};
    pointer-events: ${props => props.isDisabled ? "none" : ""};
`;