import React, { useRef, useEffect, useState } from 'react'
import {
    useMutation
} from "@tanstack/react-query"
import NoResultsLogo from "../../../../../../../media/Dashboard/noresults.svg"
import { useRequestData } from "../../../../../../hooks/useRequestData"

// Styles
import {
    FooterRight,
    AdDate,
    FooterLeft,
    CardFooter,
    AdUrl,
    CardBody,
    Favorite,
    FloatingButtonsContainer,
    Card,
    CardsList,
    ResultsContainer,
    HightlightTitle,
    HightlightContent,
    AdHeader,
    WebsiteLogo,
    WebsiteNameContainer,
    WebsiteName,
    NoResults,
    RequestDataBtn,
    ContentContainer,
    BackToSearchContainer,
    AdCount,
    AdCountWrap
} from "./index.styled"
import _static from "../../../../../../../config/static"
import SearchLoader from '../../../../../../HOC/SearchLoader'

import Highlighter from "react-highlight-words"
import GlobeIcon from '../../../../../../../media/Dashboard/globe.svg';
import { useGoogleSearch } from "../../context/google"


//Icons
import FavorietIcon from "../../../../../../../media/Dashboard/addFavorite.svg"
import FavouriteIconActive from "../../../../../../../media/Dashboard/addFavouriteIconActive.svg"

import DateIcon from "../../../../../../../media/Images/DateIcon.svg"
import TickImg from '../../../../../../../media/Dashboard/tick-grey.svg'
import { appClient } from '../../../../../../../utils/axios-utils'
import LimitExceededPopup from '../../../../../../components/limitExceededPopup'
import PlatformRestrictedPopup from '../../../../../../components/platformRestrictionPopup'
import { useAuth } from '../../../../../../../context/auth'
import { useSearch } from '../../../context/search'

import AdDetails from "../../../../../../components/AdDetailsModal"
import { useNavigate } from 'react-router-dom'

const adDetails = async (id) => {
    const config = {
        url: `/detail/google/${id}`,
        method: "GET"
    }

    return appClient(config)
}
const adClassification = async (id) => {
    const config = {
        url: `/detail/google/classification/${id}`,
        method: "GET"
    }

    return appClient(config)
}

const addToFavourite = async (data) => {
    const config = {
        url: `/adgeneration/project/save-ad`,
        method: "POST",
        data
    }

    return appClient(config)
}

const Results = () => {
    const { googleSearchState, googleSearchDispatch } = useGoogleSearch()
    const navigate = useNavigate()
    const [basicAdDetails, setBasicAdDetails] = useState({})
    const [showTick, setShowTick] = useState(false)
    const [copiedItem, setCopiedItem] = useState('')
    const { searchState, searchDispatch } = useSearch()
    const { authState, authDispatch } = useAuth()
    const containerRef = useRef(null);
    const useRequestDataHook = useRequestData()

    const [ greetingScreenOpen, setGreetingScreenOpen ] = useState(true)

    const toggleDetailsModal = (ad, idx) => {
        googleSearchDispatch({ type: 'UPDATE_DETAILS_MODAL', value: true })
        setBasicAdDetails(ad)
        // adsDetailsMutate(ad.adId)
        googleSearchDispatch({ type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: true })
    }

    const getDomainFromUrl = (url) => {
        var parsedUrl = new URL(url);
        var domain = parsedUrl.hostname;
        // Remove subdomains and top-level domain
        var domainParts = domain.split('.');
        if (domainParts.length > 2) {
            domain = domainParts[domainParts.length - 2];
        }
        return domain;
    }

    const convertTimestamp = (timestamp) => {
        // Create a new Date object based on the timestamp
        var date = new Date(timestamp);
        // Get the month name
        var monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        var month = monthNames[date.getMonth()];
        // Get the year
        var year = date.getFullYear();
        // Assemble the formatted date string
        var formattedDate = date.getDate() + ' ' + month + ' ' + year;
        return formattedDate;
    }

    const { mutate: adsDetailsMutate, isLoading: isDetailsLoading } = useMutation(adDetails, {
        onSuccess: (data) => {
            googleSearchDispatch({ type: 'SET_AD_DETAILS', value: data })
        },
        onError: ((e) => {
            console.log("err", e)

            googleSearchDispatch({ type: 'SET_AD_DETAILS', value: {} })
            // googleSearchDispatch({type:'UPDATE_DETAILS_MODAL', value: true})
        })
    })

    const { mutate: adAiClassificationMutate, isLoading: isAiClassificationLoading } = useMutation(adClassification, {
        onSuccess: (data) => {
            if (data.processed || data.id) {
                googleSearchDispatch({ type: 'SET_AD_AI_CLASSIFICATION', value: data })
                googleSearchDispatch({ type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: false })
            } else {
                setTimeout(() => {
                    adAiClassificationMutate(basicAdDetails.id)
                }, 5000);
            }
        },
        onError: ((e) => {
            googleSearchDispatch({ type: 'SET_AD_AI_CLASSIFICATION', value: {} })
            googleSearchDispatch({ type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: false })
        })
    })

    const { mutate: addFavouriteMutate, isLoading: isAddFavouriteLoading } = useMutation(addToFavourite, {
        onSuccess: (data) => {
        },
        onError: ((e) => {
        })
    })
    const onDetailsClose = () => {
        setBasicAdDetails({})
        googleSearchDispatch({ type: 'SET_AD_DETAILS', value: {} })
        googleSearchDispatch({ type: 'UPDATE_DETAILS_MODAL', value: false })
    }

    const scrollToElement = (index) => {
        const element = containerRef.current.children[index];
        const scrollPosition = element.offsetTop - 20;
        containerRef.current.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    };

    useEffect(() => {
        setTimeout(() => {
            setGreetingScreenOpen(false)
        }, 2000);
    }, [])

    if (authState.isRestrictedPopup) {
        return <ResultsContainer restricted={true} detailsModal={googleSearchState.detailsModal}>
            <PlatformRestrictedPopup err={searchState.searchError} />
        </ResultsContainer>
    }

    if (searchState.limitExceeded) {
        return <ResultsContainer restricted={true} detailsModal={googleSearchState.detailsModal}>
            <LimitExceededPopup err={searchState.searchError} />
        </ResultsContainer>
    }

    const onDataRequest = () => {
        if (!useRequestDataHook.dataRequested) {
            // useRequestDataHook.requestDataMutate({ id: googleSearchState.queryId })
            useRequestDataHook.requestRealtimeDataForGoogleMutate({ id: googleSearchState.queryId })
        }
    }

    const closeDetailModa = () => {
        googleSearchDispatch({ type: 'UPDATE_DETAILS_MODAL', value: false })
    }

    const onCopyClick = (value) => {
        navigator.clipboard.writeText(value);
        setCopiedItem(value)
        setShowTick(true)
        setTimeout(() => {
            setShowTick(false)
        }, 1500);
    }

    function findIndexById(arr, searchId) {
        for (let i = 0; i < googleSearchState.ads.length; i++) {
            if (arr[i].id === searchId) {
                return i; // Return the index when the ID is found
            }
        }
        return -1; // Return -1 if ID is not found in the array
    }
    
    const navigateTo = (adId, toAd) =>{
        let adIndex = findIndexById(adId)
        if(adIndex != -1){
            if(toAd == "next"){
                console.log("inside next")
                googleSearchDispatch({ type: 'UPDATE_DETAILS_MODAL', value: true })
                setBasicAdDetails(googleSearchState.ads[adIndex + 1])
                googleSearchDispatch({ type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: true })
            }else if(toAd == "back"){
                console.log("inside back")
                googleSearchDispatch({ type: 'UPDATE_DETAILS_MODAL', value: true })
                setBasicAdDetails(googleSearchState.ads[adIndex - 1])
                googleSearchDispatch({ type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: true })
            }
        }
    }

    const onSave = (ad) => {
        authDispatch({ type: 'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'google' })
        authDispatch({ type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.adId })
        authDispatch({ type: 'UPDATE_SAVE_AD_POPUP', value: true })
    }

    
    return <ResultsContainer resultLoading={googleSearchState.loadingResults} noResults={googleSearchState.noResults} detailsModal={googleSearchState.detailsModal}>
        {
            <ContentContainer greetingScreenOpen={greetingScreenOpen} >
                <BackToSearchContainer>
                    Your persistence paid off! Keep exploring or try another <span onClick={()=>navigate('/adspy')}>Search</span>
                </BackToSearchContainer>
                <h2>Text Search</h2>
                <AdCount>
                    <AdCountWrap>
                        Over <span>{
                            googleSearchState.totalAds
                        }</span> resuls for <p>"{/* searchState.keywordSearchType == "phrase" ? searchState.phraseInput : searchState.keywordSearchType == "broad" ? searchState.keywords.join(" "): "" */ searchState.domainUrlInput.value}"</p>
                    </AdCountWrap>
                </AdCount>
                {
                    googleSearchState.loadingResults ?
                        <div className='loader'>
                            <SearchLoader />
                        </div> :
                        googleSearchState.noResults ? 
                            <NoResults>
                                <img src={NoResultsLogo} />
                                <h2>No Results Found</h2>
                                <p>We're sorry, but we don't currently have the data matching your search parameters. Our database is constantly updated, so please try again later or try modifying your search.</p>
                                <RequestDataBtn dataRequested={useRequestDataHook.dataRequested ? useRequestDataHook.dataRequested : ""} disable={useRequestDataHook.isRequestDataLoading} onClick={onDataRequest}>{useRequestDataHook.dataRequested ? <p>Your request for data crawl has been accepted<img src={TickImg} /></p> : <p>{useRequestDataHook.isRequestDataLoading ? "Requesting..." : "Request data crawl for this query"}</p>}</RequestDataBtn>
                            </NoResults> :
                            <>
                                {/* {authState.saveAdsPopupOpen && <SaveAdsPopup noMarginTop={true}/>} */}
                                <CardsList ref={containerRef} detailsModal={googleSearchState.detailsModal}>
                                    {
                                        googleSearchState.ads && googleSearchState.ads.length > 0 && googleSearchState.ads.map((ad, idx) => {
                                            return <Card detailsOpen={basicAdDetails.id == ad.adId} key={
                                                ad.adId
                                            }>
                                                {/* <HoverCard className='hover-element'>
                                                    <HoverCardWrapper>
                                                        <AdDetailsButton onClick={() => toggleDetailsModal(ad, idx)}>
                                                            <img src={RightUpperBlackArrow} />
                                                            <span>Ad Details</span>
                                                        </AdDetailsButton>
                                                        <SaveAdsButton onClick={() => onSave(ad)
                                                        }>
                                                            Save
                                                        </SaveAdsButton>
                                                    </HoverCardWrapper>
                                                </HoverCard> */}
                                                <FloatingButtonsContainer>
                                                    <Favorite isFav={idx == 1} onClick={() => {
                                                        authDispatch({ type: 'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'google' })
                                                        authDispatch({ type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.adId })
                                                        authDispatch({ type: 'UPDATE_SAVE_AD_POPUP', value: true })
                                                    }}>
                                                        <img src={authState.favAdsList["google"]?.includes(ad.adId) ? FavouriteIconActive : FavorietIcon} />
                                                    </Favorite>
                                                    {/* |
                                            <DropdownIcon>
                                            <span/>
                                            </DropdownIcon> */}
                                                </FloatingButtonsContainer>
                                                <CardBody>
                                                    {/* <SponsoredText>
                                                        Sponsored
                                                    </SponsoredText> */}
                                                    <AdHeader>
                                                        <WebsiteLogo>
                                                            <img src={GlobeIcon} />
                                                        </WebsiteLogo>
                                                        <WebsiteNameContainer>
                                                            <WebsiteName>
                                                                {ad.domain}
                                                            </WebsiteName>
                                                            <AdUrl>
                                                                {ad.appearanceUrl ? ad.appearanceUrl : ad.appearanceUrl}
                                                            </AdUrl>
                                                        </WebsiteNameContainer>
                                                    </AdHeader>
                                                    <Highlighter
                                                        className="title-wrapper-span"
                                                        highlightClassName="title-highlight-bold"
                                                        searchWords={googleSearchState.highlightWords}
                                                        autoEscape={true}
                                                        textToHighlight={ad.title}
                                                        highlightTag={HightlightTitle}
                                                    />
                                                    <Highlighter
                                                        className="content-wrapper-span"
                                                        highlightClassName="content-highlight-bold"
                                                        searchWords={googleSearchState.highlightWords}
                                                        autoEscape={true}
                                                        textToHighlight={ad.description}
                                                        highlightTag={HightlightContent}
                                                    />
                                                </CardBody>
                                                <CardFooter>
                                                    <FooterLeft>
                                                        <AdDate>
                                                            {ad.lastShown && <img src={DateIcon} />}
                                                            {/* {convertTimestamp(ad.firstSeen)} -  */}
                                                            {convertTimestamp(parseInt(ad.lastShown))}
                                                        </AdDate>
                                                        {
                                                        /*<AdCountries>
                                                            {ad.country.length > 0 && <img src={CountryIcon} />}{ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
                                                        </AdCountries> */
                                                        }
                                                    </FooterLeft>
                                                    <FooterRight>
                                                        {/* <DetailsButton onClick={() => toggleDetailsModal(ad, idx)}>
                                                            {googleSearchState.adDetails.id == ad.adId ? "Close details" : "More details"}
                                                        </DetailsButton> */}
                                                    </FooterRight>
                                                </CardFooter>
                                            </Card>
                                        })
                                    }
                                </CardsList>
                                {
                                    googleSearchState.detailsModal && 
                                    <AdDetails
                                        navigateTo={navigateTo}
                                        platform="google" 
                                        basicDetail={basicAdDetails} 
                                        onDetailsClose={onDetailsClose} 
                                        highlightWords={googleSearchState.highlightWords} 
                                        onSave={onSave}
                                    />
                                }
                            </>
                }
            </ContentContainer>
        }
        
    </ResultsContainer>
}

export default Results