import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { UrlAnalysisProvider } from './context/urlanalysis';
import {Helmet} from "react-helmet"

// Layout
import Layout from "../../layout"

// Pages
import UrlAnalysisDetails from './UrlAnalysisDetails';
import UrlAnalysisHome from './UrlAnalysisHome'

// Not found page
import NotFound from '../NotFoundPage';

const UrlAnalysisComponent = () => {

    return <Routes>
            <Route path='*' element={
                    <NotFound />
            } />
             <Route path="/" element={
                <Layout>
                    <UrlAnalysisHome />
                </Layout>
            } />
            <Route path="details" element={
                <Layout>
                    <UrlAnalysisDetails />
                </Layout>
            } />
        </Routes>
};

const UrlAnalysisProviderWrapper = () => {
    return <UrlAnalysisProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>URL/Domain Analysis | AdSpyder</title>
                <meta name="title" content="URL/Domain Analysis | AdSpyder" />
                <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
            </Helmet>
        <UrlAnalysisComponent/>
    </UrlAnalysisProvider>
}

export default UrlAnalysisProviderWrapper