import { appClient } from "../../../../../../utils/axios-utils";
import { useMutation } from "@tanstack/react-query";
import { useGetUserInfo } from "../../../../../hooks/useGetUserInfo";
import { useSearch } from "../../context/search";
import { useYoutube } from "../context/youtube";

const keywordBoardFilterMatch = async (data) => {
    const config = {
        url: "/search/youtube/keyword/broad/filter",
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordPhraseFilterMatch = async (data) => {
    const config = {
        url: "/search/youtube/keyword/phrase/filter",
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordBroadMatch = async (data) => {
    const config = {
        url: "/search/youtube/keyword/broad",
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordPhraseMatch = async (data) => {
    const config = {
        url: "/search/youtube/keyword/phrase",
        method: "POST",
        data
    }

    return appClient(config)
}

export const useYoutubeInfo = () => {
    const { youtubeState, youtubeDispatch } = useYoutube()
    const {searchState, searchDispatch} = useSearch()

    const userInfoHook = useGetUserInfo()


    const { mutate: keywordBoardFilterMutate, isLoading: isKeywordBoardFilterLoading } = useMutation(keywordBoardFilterMatch, {
        onSuccess: (data) => {
            youtubeDispatch({ type: "SET_ADS", value: data.documents })
            youtubeDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            youtubeDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                youtubeDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                youtubeDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                youtubeDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                youtubeDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                youtubeDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                youtubeDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            youtubeDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            youtubeDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
        }
    })

    const { mutate: keywordPhraseFilterMutate, isLoading: isKeywordPhraseFilterLoading } = useMutation(keywordPhraseFilterMatch, {
        onSuccess: (data) => {
            youtubeDispatch({ type: "SET_ADS", value: data.documents })
            youtubeDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            youtubeDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                youtubeDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                youtubeDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                youtubeDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                youtubeDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                youtubeDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                youtubeDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            youtubeDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            youtubeDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const {mutate: keywordBroadMutate, isLoading: isKeywordBroadLoading} = useMutation(keywordBroadMatch, {
        onSuccess: (data) => {
            // Update search results
            youtubeDispatch({type: "SET_ADS", value: data.documents})
            youtubeDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            youtubeDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if (data.documents && data.documents.length > 0) {
                youtubeDispatch({type: "UPDATE_SEARCHED", value: true})
            }else{
                youtubeDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                youtubeDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                youtubeDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                youtubeDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                youtubeDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            youtubeDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError:  ((e)=>{
            youtubeDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
        })
    })

    const {mutate: keywordPhraseMutate, isLoading: isKeywordPhraseLoading} = useMutation(keywordPhraseMatch, {
        onSuccess: (data) => {
            // Update search results
            youtubeDispatch({type: "SET_ADS", value: data.documents})
            youtubeDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            youtubeDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if (data.documents && data.documents.length > 0) {
                youtubeDispatch({type: "UPDATE_SEARCHED", value: true})
            }else{
                youtubeDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                youtubeDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                youtubeDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                youtubeDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                youtubeDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            youtubeDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError:  ((e)=>{
            youtubeDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
        })
    })


    return {
        keywordBoardFilterMutate,
        isKeywordBoardFilterLoading,
        keywordPhraseFilterMutate,
        isKeywordPhraseFilterLoading,
        keywordBroadMutate,
        isKeywordBroadLoading,
        keywordPhraseMutate,
        isKeywordPhraseLoading
    };
};
    