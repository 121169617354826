import styled from "styled-components";

export const AdDistributionWrap = styled.div`
width: 966px;
height: 303px;
border-radius: 16px;
background: #FFF;
padding: 24px;
margin-top: 16px;
max-width: 100%;
`
export const DoughnutWrapper = styled.div`
width: 50%;
display: flex;
justify-content: center;
`
export const DoughnutContainer = styled.div`
width: 174px;
height: 174px;
`
export const AdDistributionDiv = styled.div`
display: flex;
        align-items: center;
        gap: 10px;
`
export const AdDistributionContainer = styled.div`
display: flex;
height: 100%;
align-items: center;

`
export const AdProgressWrap = styled.div`
width: 50%;
height: 100%;
&.skeleton-data{
height: unset;

}
`
export const AdProgressDiv = styled.div`
display: flex;
justify-content: space-between;
height: 39px;
align-items: center;
border-bottom: solid 1px #E3E8EE;
 /* progress {
      width: 105px; 
      height: 15px;
    } */
`
export const AdProgressName = styled.div`
  width: 50%;
  font-size: 14px;
  font-weight: 400;
  color: #1C1C1CCC;
`
export const AdProgressBar = styled.div`
display: flex;
column-gap: 16px;
width: 50%;

`
export const AdProgressPercentage = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #1C1C1CB2;
`

export const GoogleProgress = styled.progress`
accent-color: ${props => props.color};
inline-size: 6.6em ;
`


