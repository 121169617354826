import styled from "styled-components";

export const SelectTableContainer = styled.div`
padding: 19px 66px 0px 82px;
`
export const SelectAdInfoWrap = styled.div`
display: flex;
// padding: 19px 66px 0 82px;
justify-content: space-between;
align-items: center;
width: 1012px;
    max-width: 100%;
`

export const SelectAdInfo = styled.div`
color: #000;
font-family: Inter;
font-size: 14px;
font-weight: 300;
`
export const SearchWrapper = styled.div`
width: 224px;
height: 44px;
border-radius: 6px;
border: 1px solid #DADFE4;
display: flex;
img{
    width: 16px;
}
`
export const SearchInput = styled.input`
outline: none;
border: none;
background: unset;
padding-left: 16px;
height: 100%;
width: 88%;
`
