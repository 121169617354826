import React from "react";
import { Container, BudgetContainer, BudgetInputContainer, Currency, AmountInput, StrategyContainer, StrategyDropDownContainer, SelectedStrategy, StrategyDropDown, MatchType } from "./Budget.styled"
import { useAdManagement } from "../../../../context/AdManagementContext";
import DownArrow from "../../../../../../../../media/Dashboard/drop-down-arrow.svg"

const Budget = () => {

    const { adManagementState, adManagementDispatch } = useAdManagement()
console.log(adManagementState.selectedStrategy,'adManagementState.selectedStrategy')
    const strategyList = [
        "maximizeConversions", //maximizeConversions
        "maximizeClicks" //targetSpend
    ]
    return <Container>
        <BudgetContainer>
            <label>Enter your maximum daily budget</label>
            <BudgetInputContainer>
                <Currency>
                   {adManagementState.isConnectedData[0].currency}
                </Currency>
                <AmountInput placeholder="Enter the daily budget limit" onChange={(e)=> {adManagementDispatch({type: "SET_BUDGET_DATA", value: e.target.value})}} value={adManagementState.isBudgetData}/>
            </BudgetInputContainer>
        </BudgetContainer>
        <StrategyContainer>
            <label>Big strategy</label>
            <StrategyDropDownContainer>
                <SelectedStrategy 
                    onClick={()=>{
                        adManagementDispatch({ type: "SET_OPENED_STRATEGY_DROPDOWN", value: !adManagementState.openedStrategyDropDown })
                    }}
                    rotateImg={adManagementState.openedStrategyDropDown}
                >
                    {adManagementState.selectedStrategy?.length > 0 ? adManagementState.selectedStrategy : strategyList[1]}
                    <img
                        src={DownArrow}
                    />
                </SelectedStrategy>
                {adManagementState.openedStrategyDropDown && <StrategyDropDown>
                    {strategyList.map((matchType)=>{
                        return <MatchType
                            onClick={() => {
                                adManagementDispatch({ type: "SET_SELECTED_STRATEGY", value: matchType })
                                adManagementDispatch({ type: "SET_OPENED_STRATEGY_DROPDOWN", value: false })
                            }}
                        >
                            {matchType}
                        </MatchType>
                    })}
                </StrategyDropDown>}
            </StrategyDropDownContainer>
        </StrategyContainer>
    </Container>
}

export default Budget   