import React, {useEffect, useState} from "react"
import { useSearchParams } from "react-router-dom"
import {appClient} from '../../../../../../utils/axios-utils'
import {
    useMutation
} from "@tanstack/react-query"
import TextTransition, { presets } from 'react-text-transition';

import styled from "styled-components"
import { useGetUserInfo } from './../../../../../hooks/useGetUserInfo'

import { useUrlAnalysis } from './../../../context/urlanalysis'
import LoadingMessages from "../../../../../../utils/LoadingMessages"

import Google from '../../components/PlatformResults/Google'
import Bing from "../PlatformResults/Bing"
import Youtube from "../PlatformResults/Youtube"
import Facebook from "../PlatformResults/Facebook"
import GoogleEcom from "../PlatformResults/GoogleEcom"
import BingEcom from "../PlatformResults/BingEcom"
import DisplayAds from "../PlatformResults/Display"

import ValidateAndAddHttps from "../../../../../../utils/urlValidate";

import AnalysingGif from "../../../../../../media/Dashboard/aiLoader.gif"

const AnalysingUi = styled.div`
    display: flex;
    padding-top: 20%;
    flex-direction: column;
    align-items: center;
    height: 86%;
    img{
        height: 100px;
        width: 100px;
        margin-bottom: 16px;
    }
    p{
        font-size: 20px;
        font-weight: 600;
        color: #12487F;
        line-height: normal;
    }
`;

const urlDomainFilterMatch = async (data) => {

    if(data.platform == "meta"){
        data.platform = "facebook"
    }else if(data.platform == "googlepla"){
        data.platform = "google-ecom"
    }else if(data.platform == "bingpla"){
        data.platform = "bing-ecom"
    }

    const config = {
        url: `/search/${data.platform}/url/domain?limit=6`,
        method: "POST",
        data: data.body
    }

    return appClient(config)
}

const urlExactFilterMatch = async (data) => {
    if(data.platform == "meta"){
        data.platform = "facebook"
    }else if(data.platform == "googlepla"){
        data.platform = "google-ecom"
    }else if(data.platform == "bingpla"){
        data.platform = "bing-ecom"
    }
    const config = {
        url: `/search/${data.platform}/url/exact?limit=6`,
        method: "POST",
        data: data.body
    }

    return appClient(config)
}

const FunnelAdsData = async (data) => {
    if(data.platform == "meta"){
        data.platform = "facebook"
    }

    const config = {
        url: `/intelligence/${data.platform}/ads`,
        method: "POST",
        data
    }

    return appClient(config)
}

const FunnelStatsData = async (data) => {
    if(data.platform == "meta"){
        data.platform = "facebook"
    }
    
    const config = {
        url: `/intelligence/${data.platform}/funnel-info`,
        method: "POST",
        data
    }

    return appClient(config)
}

const LoadingContainer = () => {
    const [currentText, setCurrentText] = useState(0)


    useEffect(() => {
        let count = 0; // Initialize a count variable

        const tick = () => {
          if (count < 7) {
            setCurrentText((i) => i + 1);
            count++; // Increment the count
          } else {
            clearInterval(id); // Stop the interval when count reaches 5
          }
        };
    
        const id = setInterval(tick, 10000);
      
        return () => clearInterval(id);
      }, []);

    return <AnalysingUi>
        <img src={AnalysingGif} />
        <TextTransition springConfig={presets.wobbly}><p>{LoadingMessages.funnelLoadingMessages[currentText]}</p></TextTransition>
    </AnalysingUi>
}

const AdCopies = ({isDataLoading}) =>{
    const [searchParams, setSearchParams] = useSearchParams()
    const {urlAnalysisState, urlAnalysisDispatch} = useUrlAnalysis()
    const userInfoHook = useGetUserInfo()

    const { mutate: urlDomainFilterMutate, isLoading: isUrlDomainFilterLoading } = useMutation(urlDomainFilterMatch, {
        onSuccess: (data) => {
            urlAnalysisDispatch({ type: "SET_AD_COPIES", value: data.documents })
            urlAnalysisDispatch({ type: "SET_EMPTY_RESULT_ID", value: data.queryId})
            userInfoHook.getUserPlanData()
        },
        onError: (err) => {

        }
    })

    const { mutate: urlExactFilterMatchMutate, isLoading: isUrlExactFilterLoading } = useMutation(urlExactFilterMatch, {
        onSuccess: (data) => {
            // console.log(data)
            urlAnalysisDispatch({ type: "SET_AD_COPIES", value: data.documents })
            urlAnalysisDispatch({ type: "SET_EMPTY_RESULT_ID", value: data.queryId})
            userInfoHook.getUserPlanData()
        },
        onError: (err) => {

        }
    })

    const { mutate: getFunnelAds, isLoading: isFunnelAdsLoading } = useMutation(FunnelAdsData, {    
        onSuccess: (data) => {
            if(data){
                if(data.processed){
                    urlAnalysisDispatch({type: 'SET_FUNNEL_ADS', value: data.ads})
                    getFunnelStats({
                        "query": ValidateAndAddHttps(urlAnalysisState.searchUrl),
                        "funnelType": urlAnalysisState.selectedFunnel.toLowerCase(),
                        "platform": urlAnalysisState.adCopyPlatform
                    })
                    urlAnalysisDispatch({type: 'SET_FUNNEL_ADS_LOADING', value: false})
                }else{
                    setTimeout(() => {
                        urlAnalysisDispatch({type: 'SET_FUNNEL_ADS_LOADING', value: true})
        
                        getFunnelAds({
                            "query": ValidateAndAddHttps(urlAnalysisState.searchUrl),
                            "funnelType": urlAnalysisState.selectedFunnel.toLowerCase(),
                            "count": 100,
                            "platform": urlAnalysisState.adCopyPlatform
                        })
                        
                    
                    }, 30000);
                }
            }else{
                urlAnalysisDispatch({type: 'SET_FUNNEL_ADS', value: []})
                urlAnalysisDispatch({type: 'SET_FUNNEL_ADS_LOADING', value: false})
            }    
        },
        onError: (err) => {
            
        }
    })

    const { mutate: getFunnelStats, isLoading: isFunnelStatsLoading } = useMutation(FunnelStatsData, {    
        onSuccess: (data) => {
            if(data){
                urlAnalysisDispatch({type: 'SET_FUNNEL_CTA_DATA', value: data.ctaText})
                urlAnalysisDispatch({type: 'SET_FUNNEL_KEYWORDS_DATA', value: data.keywords})
                urlAnalysisDispatch({type: 'SET_FUNNEL_TARGET_AUDIENCE_DATA', value: { 
                    demographicAudience: data.demographicAudience,
                    psychographicAudience: data.psychographicAudience
                }})
                urlAnalysisDispatch({type: 'SET_FUNNEL_STATS_LOADING', value: false})
                urlAnalysisDispatch({type: 'SET_INITIAL_FUNNEL_LOADING', value: false})
            }    
        },
        onError: (err) => {
            
        }
    })

    useEffect(() => {
        // console.log("inside adcopy useeffect", urlAnalysisState)
        if(!urlAnalysisState.funnelOpen){
            if(urlAnalysisState.adCopyPlatform.length > 0){
                if(urlAnalysisState.searchMode == "path"){
                    const reqBody = {
                        query: urlAnalysisState.searchUrl,
                        page: 1,
                        countries: [],
                        startDate: '',
                        endDate: '',
                        sort: 'latest'
                    }
        
                    urlExactFilterMatchMutate({body: reqBody, platform: urlAnalysisState.adCopyPlatform})
        
                }else if(urlAnalysisState.searchMode == "domain"){
                    const reqBody = {
                        query: urlAnalysisState.searchUrl,
                        page: 1,
                        countries: [],
                        startDate: '',
                        endDate: '',
                        sort: 'latest'
                    }
        
                    urlDomainFilterMutate({body: reqBody, platform: urlAnalysisState.adCopyPlatform})
                }
            } 
            if(urlAnalysisState.adCopyPlatform == "google" || urlAnalysisState.adCopyPlatform == "bing" || urlAnalysisState.adCopyPlatform == "meta"){
                urlAnalysisDispatch({type:'SET_UNSUPPORTED_FUNNEL_PLATFORM', value: false})
            }else{
                urlAnalysisDispatch({type:'SET_UNSUPPORTED_FUNNEL_PLATFORM', value: true})
            }
            // urlAnalysisDispatch({type:'SET_SELECTED_FUNNEL', value: "Awareness"})
        }else{
            if(urlAnalysisState.adCopyPlatform == "google" || urlAnalysisState.adCopyPlatform == "bing" || urlAnalysisState.adCopyPlatform == "meta"){
                urlAnalysisDispatch({type:'SET_UNSUPPORTED_FUNNEL_PLATFORM', value: false})

                urlAnalysisDispatch({type: 'SET_FUNNEL_ADS_LOADING', value: true})
                urlAnalysisDispatch({type: 'SET_FUNNEL_STATS_LOADING', value: true})
                urlAnalysisDispatch({type: 'SET_INITIAL_FUNNEL_LOADING', value: true})
    
                getFunnelAds({
                    "query": ValidateAndAddHttps(urlAnalysisState.searchUrl),
                    "funnelType": urlAnalysisState.selectedFunnel.toLowerCase(),
                    "count": 100,
                    "platform": urlAnalysisState.adCopyPlatform
                })
            }else{
                urlAnalysisDispatch({type:'SET_UNSUPPORTED_FUNNEL_PLATFORM', value: true})

                urlAnalysisDispatch({type: 'SET_FUNNEL_ADS_LOADING', value: false})
                urlAnalysisDispatch({type: 'SET_FUNNEL_STATS_LOADING', value: false})
                urlAnalysisDispatch({type: 'SET_INITIAL_FUNNEL_LOADING', value: false})
            }
            // urlAnalysisDispatch({type:'SET_SELECTED_FUNNEL', value: "Awareness"})
        }
    }, [urlAnalysisState.adCopyPlatform])
    
    if(urlAnalysisState.initialFunnelLoading){
        return <LoadingContainer/>
    }else if(urlAnalysisState.adCopyPlatform == "google" || urlAnalysisState.adCopyPlatform == ""){
        return <Google isDataLoading={isDataLoading || isUrlExactFilterLoading || isUrlDomainFilterLoading} />
    }else if(urlAnalysisState.adCopyPlatform == "bing"){
        return <Bing isDataLoading={isDataLoading || isUrlExactFilterLoading || isUrlDomainFilterLoading} />
    }else if(urlAnalysisState.adCopyPlatform == "meta"){
        return <Facebook isDataLoading={isDataLoading || isUrlExactFilterLoading || isUrlDomainFilterLoading} />
    }else if(urlAnalysisState.adCopyPlatform == "flipkart"){
        return <Google isDataLoading={isDataLoading || isUrlExactFilterLoading || isUrlDomainFilterLoading} />
    }else if(urlAnalysisState.adCopyPlatform == "amazon"){
        return <Google isDataLoading={isDataLoading || isUrlExactFilterLoading || isUrlDomainFilterLoading} />
    }else if(urlAnalysisState.adCopyPlatform == "googlepla"){
        return <GoogleEcom isDataLoading={isDataLoading || isUrlExactFilterLoading || isUrlDomainFilterLoading} />
    }else if(urlAnalysisState.adCopyPlatform == "bingpla"){
        return <BingEcom isDataLoading={isDataLoading || isUrlExactFilterLoading || isUrlDomainFilterLoading} />
    }else if(urlAnalysisState.adCopyPlatform == "linkedin"){
        return <Google isDataLoading={isDataLoading || isUrlExactFilterLoading || isUrlDomainFilterLoading} />
    }else if(urlAnalysisState.adCopyPlatform == "youtube"){
        return <Youtube isDataLoading={isDataLoading || isUrlExactFilterLoading || isUrlDomainFilterLoading} />
    }else if(urlAnalysisState.adCopyPlatform == "reddit"){
        return <Google isDataLoading={isDataLoading || isUrlExactFilterLoading || isUrlDomainFilterLoading} />
    }else if(urlAnalysisState.adCopyPlatform == "display"){
        return <DisplayAds isDataLoading={isDataLoading || isUrlExactFilterLoading || isUrlDomainFilterLoading} />
    }
}

export default AdCopies