import React, { useRef } from 'react'

import { useKeywordAnalysis } from "../../context/keywordanalysis";
import {
    Container
} from "./index.styled"
import { useAuth } from '../../../../../context/auth';
import Results from './Results';
import Pagination from './Pagination';

const PpcResults = () => {
    const {keywordAnalysisState, keywordAnalysisDispatch} = useKeywordAnalysis()
    const {authState, authDispatch} = useAuth()

    const myRef = useRef(null);

    return <Container ref={myRef}>
    {/* <SearchContainer>
        <Content>
            <PlatformDropDown/>
            <SearchMode/>
            <Separator/> 
            {
            keywordAnalysisState.searchMode === "keyword" ? <KeywordComponent/>: <UrlComponent/>
        } </Content>
        {
        !searchState.limitExceeded && < Filters />
    } </SearchContainer> */}
    {/* {
        keywordAnalysisState.ads && keywordAnalysisState.ads.length > 0 && (
        <AdsCount>
            <p>
                Google Search ads{" "}
                <span>
                - {(keywordAnalysisState.currentPageNo - 1) * 20 != 0 ? (keywordAnalysisState.currentPageNo - 1) * 20 + 1 : 1} - {(((keywordAnalysisState.currentPageNo - 1) * 20) + 20) > keywordAnalysisState.totalAds ? keywordAnalysisState.totalAds : ((keywordAnalysisState.currentPageNo - 1) * 20) + 20} of {
                    keywordAnalysisState.totalAds
                }
                    {" "}results | Queries:{" "} </span>
            </p>
            <SearchQuery> {
                keywordAnalysisState.keywords.length > 0 ? (keywordAnalysisState.keywords.map((word, idx) => {
                    return <span>{word}</span>;
                })) : keywordAnalysisState.phraseInput ? (
                    <span>{
                        keywordAnalysisState.phraseInput
                    }</span>
                ) : (
                    <span>{
                        keywordAnalysisState.domainUrlInput.value
                    }</span>
                )
            } </SearchQuery>
        </AdsCount>
    )
    } */}
        <Results/> {
        !keywordAnalysisState.limitExceeded && keywordAnalysisState.ads && keywordAnalysisState.ads.length > 0 && <Pagination/>
    } 
    </Container>
}

export default PpcResults