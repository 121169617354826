import styled from "styled-components";

export const PopupContainer = styled.div`
    position: fixed;
    z-index: 10;

    background: #00000045;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const PopupBody = styled.div`
    max-width: 750px;
    width: 80%;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px #0000003D;
    border-radius: 12px;
    display: flex;
    min-height: 265px;
    flex-direction: column;
    align-items: center;
    /* padding: 32px 46px; */
    position: relative;
    justify-content: space-between;

    h2{
        margin-bottom: 16px;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        color: #1C1C1C;
        margin-top: 32px;       
    }
    p{
        font-size: 14px;
        font-weight: 300;
        color: #4E4E4E;
        text-align: center;
        line-height: 21px;
    }
`;

export const LogoutButton = styled.button`
    border: none;
    outline: none;
    background: none;
    position: absolute;
    right: 25px;
    top: 20px;
    color: #2E2E2E;
    font-size: 14px;
    font-weight: 300;
`;

export const KeywordInputContainer = styled.div`
    display: flex;
    gap: 14px;
    width: 100%;
    height: 48px;
    position: relative;
`;

export const ErrorBox = styled.div`
color: #F04438;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 21px */
letter-spacing: -0.14px;
margin-top: 5px;
`;

export const Footer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
    padding: 32px 46px;
`;


export const SetupTrackingButton = styled.div`
  border-radius: 6px;
  background: #FF711E;
  height: 44px;
  color: #FFF;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
//   width: 149px;
  :hover {
    filter: brightness(90%);
  }
  cursor: pointer;
`;

export const KeywordsListContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    padding: 32px 46px;
    min-height: 120px;
    /* max-height: 250px; */
    overflow-y: auto;

    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const Error = styled.p`
    
`
export const ErrorIcon = styled.img``

export const KeywordInput = styled.input`
margin-left: 8px;
  color: rgba(28, 28, 28, 0.90);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  height: 100%;
  width: 100%;
  // border: 1px solid #D0D5DD;
  border: none;
  outline: none;
  // border-radius: 8px;
  caret-color: #FFB258;
  ::placeholder{
    color: rgba(28, 28, 28, 0.50);
  }
`;

export const InputContainer = styled.div`
  border-radius: 8px;
  border: ${(props) => props.hasError ? "1px solid #F04438" : props.isFocussed ? "1px solid #FFB258" : "1px solid #D0D5DD"};
  background: #FFF;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 139px - 14px);
  min-width: 195px;
  position: relative;
  padding: 0 14px;
  box-shadow: ${(props) => props.isFocussed ? "0px 1px 2px 0px rgba(28, 28, 28, 0.05), 0px 0px 4px 0px rgba(255, 178, 88, 0.25)" : "unset"};
`;

export const RightIcons = styled.div`
  display: flex;
  gap: 8px;
`;
